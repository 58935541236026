import { Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { Id } from "src/app/helper/id";
import { ScheduleActionEvent, ScheduleDialogActionType } from "src/app/models/dialog-actions.model";
import { ScheduleActionPlan } from "src/app/models/schedule.model";
import { SchedulesService } from "src/app/services/schedules.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { SubSink } from "subsink";

@Component({
	selector: "app-run-schedule-dialog",
	templateUrl: "./run-schedule-dialog.component.html",
	styleUrls: ["./run-schedule-dialog.component.scss"],
	providers: [MessageService]
})
export class RunScheduleDialogComponent implements OnInit, OnDestroy {
	subs = new SubSink();
	displayRunSchedule: boolean = false;
	savingInProgress: boolean = false;
	currentSelectedSchedule?: ScheduleActionPlan;

	constructor(
		private schedulesService: SchedulesService,
		private systemLogService: SystemMessageLogService,
		private messageService: MessageService,
		private translate: TranslateService
	) {}
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngOnInit(): void {
		this.subs.sink = this.schedulesService.scheduleDialogActionSendEmitter.subscribe(
			(res: ScheduleActionEvent) => {
				if(res.actionType === ScheduleDialogActionType.runSchedule) {
					this.currentSelectedSchedule = res.schedule;
					this.displayRunSchedule = res.display;
				}
			},
			(err) => {
				this.systemLogService.handleError(err);
			}
		);
	}

	onRunSchedule(id?: number) {
		try {
			this.runSchedule(id);
		} catch(e) {
			this.systemLogService.handleError(<Error>e)
		}
	}

	runSchedule(id?:number) {
		const sc_id = Id.assertSet(id,new Error("No schedule selected, please select a schedule"));

		this.savingInProgress = true;
		this.subs.sink = this.schedulesService.runScheduleActionPlan(sc_id).subscribe(
			(res: ScheduleActionPlan) => {
				this.schedulesService.runScheduleEmitter.emit(res);

				this.messageService.add({
					severity: "info",
					summary: this.translate.instant("Message.RunScheduleStartedSuccess.Title"),
					detail: this.translate.instant("Message.RunScheduleStartedSuccess.Text1") + res.id +
					this.translate.instant("Message.RunScheduleStartedSuccess.Text2"),
				});

				this.displayRunSchedule = false;
			},
			(err) => {
				this.systemLogService.handleError(err);
			}, () => {
				this.savingInProgress = false;
			}
		);
	}
}
