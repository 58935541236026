<div id="mainView">
    <div *ngIf="connector_specs?.Info?.IsPushSource" id="fileView">
        <label for="file-upload" class="custom-file-upload">
            {{'Select file' | translate}}
          </label>
        <input id="file-upload" type="file" (change)="onFileSelected($event)" accept=".xlsm,.xlx,.xlsx,application/msexcel,.csv" />
        <span *ngIf="selectedFileName"> {{ selectedFileName }}</span>
    </div>

    <div id="settingsView" [ngSwitch]="selected_settings_view">

        <app-excel-adapter *ngSwitchCase="EXCEL_ADAPTER" [config]="model" (configChanged)="onConfigChanged($data)"></app-excel-adapter>

        <app-csv-adapter *ngSwitchCase="CSV_ADAPTER" [config]="model" (configChanged)="onConfigChanged($data)"></app-csv-adapter>

        <div *ngSwitchDefault="">Für diesen Konnektor gibt es keine Konfigurationsanzeige</div>

    </div>
    <!-- <div>
        <p-button label="{{'Submit' | translate}}" (onClick)="submitClicked()"></p-button>
    </div> -->
    <div class="fixed-button">
        <p-button raised="true" (onClick)="submitClicked()" [label]="selectedFileName ? 'Datei hochladen' : 'Bitte Datei hochladen'"  [disabled]="!selectedFileName"></p-button>
    </div>
    
</div>
