import { Component, OnInit } from "@angular/core";
import { NodeConfigComponentBase } from "../node-config-component-base";
import { DatePipe } from "@angular/common";
// import { FieldInfo, WorkflowNodeSettings } from "src/app/models/workflow.model";
import {
	FXConvertSettings,
	FxConvertInfo,
} from "src/app/models/nodeSettings.model";
import { DesignerService } from "src/app/services/designer.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { GuiDropDown } from "src/app/models/helperClass.model";
import { FieldInfo, WorkflowNodeSettings } from "src/app/models/api/com/bion/etl/Workflow";

export class FxConvertInfoView {
	fieldInfo?: string;
	baseCurrency?: GuiDropDown;
	targetCurrencies: GuiDropDown[] = [];
	hasError?: boolean;
}
export class Unit {
	UnitName?: string;
	UnitValue?: string;
	Category?: string;
}

@Component({
	selector: "app-fx-convert-node",
	templateUrl: "./fx-convert-node.component.html",
	styleUrls: ["./fx-convert-node.component.css"],
})
export class FxConvertNodeComponent
	extends NodeConfigComponentBase<FXConvertSettings>
	implements OnInit
{
	settingsToView(settings: WorkflowNodeSettings): void {
		// -- Get Configuration
		let currentConfig = <FXConvertSettings>settings;

		// -- Get Input Meta Data
		const meta_infos =
			this._currentWorkflowNodeAdapter.IWorkflowNode.Properties.MetaInfo.get(
				this.InputPort
			);

		let columnListArray: string[] = [];
		// create SelectInfoViews from DsFields for table
		if (meta_infos != undefined && meta_infos.length > 0) {
			const input_field_infos = meta_infos[0].FieldsInfo;
			//this.currencyColumns = input_field_infos;

			// -- create string array for column dropdown
			columnListArray = input_field_infos.map((fieldInfo) => {
				return fieldInfo.Name;
			});
			this.columnsList = columnListArray;
		}
		// --If currentConfig not available (-> new node)
		if (currentConfig == undefined) {
			this.convertList = [];
		} else {
			// -- otherweise load existing config into view
			currentConfig.FxConversionInfos.map((info) => {
				if (columnListArray.includes(info.ColumnName)) {
					// -- column still exists, do nothing
				} else {
					// column does not exist anymore, meaning column name currently not included -> include
					columnListArray.push(info.ColumnName);
				}
			});
			this.columnsList = columnListArray;
            if(meta_infos !== undefined) {
			this.convertList = this.convertInfoToConvertInfoView(
				currentConfig.FxConversionInfos,
				meta_infos[0].FieldsInfo
			);
            }
			let newDateX = new Date(currentConfig.FxConversionDate);

			this.referenceDate = newDateX;
		}
	}
	viewToSettings(): FXConvertSettings {
		const newSettings = <FXConvertSettings>{
			...this.getCurrentWorkflowNode().Properties.Configuration,
		};
		const convertInfoViews = this.convertList;
		const convertInfos = this.convertInfoViewToConvertInfo(convertInfoViews);

		newSettings.FxConversionInfos = convertInfos;

		newSettings.FxConversionDate = this.referenceDate.toISOString();

		// this.messageService.standardToastMessage({  severity : 'success',
		//                                             summary : 'Conversion successsfully added',
		//                                             detail : 'New conversion was successfully added to the node.'});

		return newSettings;
	}
	onSettingsChanged(settings: any) {
		throw new Error("Method not implemented.");
	}
	constructor(
		protected workflowService: WorkflowsService,
		private datePipe: DatePipe,
		protected designerEventService: DesignerService
	) {
		super(workflowService, designerEventService);
		this.baseCurrencies = [
			{ name: "United Arab Emirates Dirham", value: "AED" },
			{ name: "Afghan Afghani", value: "AFN" },
			{ name: "Albanian Lek", value: "ALL" },
			{ name: "Armenian Dram", value: "AMD" },
			{ name: "Netherlands Antillean Guilder", value: "ANG" },
			{ name: "Angolan Kwanza", value: "AOA" },
			{ name: "Argentine Peso", value: "ARS" },
			{ name: "Australian Dollar", value: "AUD" },
			{ name: "Aruban Florin", value: "AWG" },
			{ name: "Azerbaijani Manat", value: "AZN" },
			{ name: "Bosnia-Herzegovina Convertible Mark", value: "BAM" },
			{ name: "Barbadian Dollar", value: "BBD" },
			{ name: "Bangladeshi Taka", value: "BDT" },
			{ name: "Bulgarian Lev", value: "BGN" },
			{ name: "Bahraini Dinar", value: "BHD" },
			{ name: "Burundian Franc", value: "BIF" },
			{ name: "Bermudan Dollar", value: "BMD" },
			{ name: "Brunei Dollar", value: "BND" },
			{ name: "Bolivian Boliviano", value: "BOB" },
			{ name: "Brazilian Real", value: "BRL" },
			{ name: "Bahamian Dollar", value: "BSD" },
			{ name: "Bitcoin", value: "BTC" },
			{ name: "Bhutanese Ngultrum", value: "BTN" },
			{ name: "Botswanan Pula", value: "BWP" },
			{ name: "Belarusian Ruble", value: "BYR" },
			{ name: "Belize Dollar", value: "BZD" },
			{ name: "Canadian Dollar", value: "CAD" },
			{ name: "Congolese Franc", value: "CDF" },
			{ name: "Swiss Franc", value: "CHF" },
			{ name: "Chilean Unit of Account (UF)", value: "CLF" },
			{ name: "Chilean Peso", value: "CLP" },
			{ name: "Chinese Yuan", value: "CNY" },
			{ name: "Colombian Peso", value: "COP" },
			{ name: "Costa Rican Colón", value: "CRC" },
			{ name: "Cuban Convertible Peso", value: "CUC" },
			{ name: "Cuban Peso", value: "CUP" },
			{ name: "Cape Verdean Escudo", value: "CVE" },
			{ name: "Czech Republic Koruna", value: "CZK" },
			{ name: "Djiboutian Franc", value: "DJF" },
			{ name: "Danish Krone", value: "DKK" },
			{ name: "Dominican Peso", value: "DOP" },
			{ name: "Algerian Dinar", value: "DZD" },
			{ name: "Egyptian Pound", value: "EGP" },
			{ name: "Eritrean Nakfa", value: "ERN" },
			{ name: "Ethiopian Birr", value: "ETB" },
			{ name: "Euro", value: "EUR" },
			{ name: "Fijian Dollar", value: "FJD" },
			{ name: "Falkland Islands Pound", value: "FKP" },
			{ name: "British Pound Sterling", value: "GBP" },
			{ name: "Georgian Lari", value: "GEL" },
			{ name: "Guernsey Pound", value: "GGP" },
			{ name: "Ghanaian Cedi", value: "GHS" },
			{ name: "Gibraltar Pound", value: "GIP" },
			{ name: "Gambian Dalasi", value: "GMD" },
			{ name: "Guinean Franc", value: "GNF" },
			{ name: "Guatemalan Quetzal", value: "GTQ" },
			{ name: "Guyanaese Dollar", value: "GYD" },
			{ name: "Hong Kong Dollar", value: "HKD" },
			{ name: "Honduran Lempira", value: "HNL" },
			{ name: "Croatian Kuna", value: "HRK" },
			{ name: "Haitian Gourde", value: "HTG" },
			{ name: "Hungarian Forint", value: "HUF" },
			{ name: "Indonesian Rupiah", value: "IDR" },
			{ name: "Israeli New Sheqel", value: "ILS" },
			{ name: "Manx pound", value: "IMP" },
			{ name: "Indian Rupee", value: "INR" },
			{ name: "Iraqi Dinar", value: "IQD" },
			{ name: "Iranian Rial", value: "IRR" },
			{ name: "Icelandic Króna", value: "ISK" },
			{ name: "Jersey Pound", value: "JEP" },
			{ name: "Jamaican Dollar", value: "JMD" },
			{ name: "Jordanian Dinar", value: "JOD" },
			{ name: "Japanese Yen", value: "JPY" },
			{ name: "Kenyan Shilling", value: "KES" },
			{ name: "Kyrgystani Som", value: "KGS" },
			{ name: "Cambodian Riel", value: "KHR" },
			{ name: "Comorian Franc", value: "KMF" },
			{ name: "North Korean Won", value: "KPW" },
			{ name: "South Korean Won", value: "KRW" },
			{ name: "Kuwaiti Dinar", value: "KWD" },
			{ name: "Cayman Islands Dollar", value: "KYD" },
			{ name: "Kazakhstani Tenge", value: "KZT" },
			{ name: "Laotian Kip", value: "LAK" },
			{ name: "Lebanese Pound", value: "LBP" },
			{ name: "Sri Lankan Rupee", value: "LKR" },
			{ name: "Liberian Dollar", value: "LRD" },
			{ name: "Lesotho Loti", value: "LSL" },
			{ name: "Lithuanian Litas", value: "LTL" },
			{ name: "Latvian Lats", value: "LVL" },
			{ name: "Libyan Dinar", value: "LYD" },
			{ name: "Moroccan Dirham", value: "MAD" },
			{ name: "Moldovan Leu", value: "MDL" },
			{ name: "Malagasy Ariary", value: "MGA" },
			{ name: "Macedonian Denar", value: "MKD" },
			{ name: "Myanma Kyat", value: "MMK" },
			{ name: "Mongolian Tugrik", value: "MNT" },
			{ name: "Macanese Pataca", value: "MOP" },
			{ name: "Mauritanian Ouguiya", value: "MRO" },
			{ name: "Mauritian Rupee", value: "MUR" },
			{ name: "Maldivian Rufiyaa", value: "MVR" },
			{ name: "Malawian Kwacha", value: "MWK" },
			{ name: "Mexican Peso", value: "MXN" },
			{ name: "Malaysian Ringgit", value: "MYR" },
			{ name: "Mozambican Metical", value: "MZN" },
			{ name: "Namibian Dollar", value: "NAD" },
			{ name: "Nigerian Naira", value: "NGN" },
			{ name: "Nicaraguan Córdoba", value: "NIO" },
			{ name: "Norwegian Krone", value: "NOK" },
			{ name: "Nepalese Rupee", value: "NPR" },
			{ name: "New Zealand Dollar", value: "NZD" },
			{ name: "Omani Rial", value: "OMR" },
			{ name: "Panamanian Balboa", value: "PAB" },
			{ name: "Peruvian Nuevo Sol", value: "PEN" },
			{ name: "Papua New Guinean Kina", value: "PGK" },
			{ name: "Philippine Peso", value: "PHP" },
			{ name: "Pakistani Rupee", value: "PKR" },
			{ name: "Polish Zloty", value: "PLN" },
			{ name: "Paraguayan Guarani", value: "PYG" },
			{ name: "Qatari Rial", value: "QAR" },
			{ name: "Romanian Leu", value: "RON" },
			{ name: "Serbian Dinar", value: "RSD" },
			{ name: "Russian Ruble", value: "RUB" },
			{ name: "Rwandan Franc", value: "RWF" },
			{ name: "Saudi Riyal", value: "SAR" },
			{ name: "Solomon Islands Dollar", value: "SBD" },
			{ name: "Seychellois Rupee", value: "SCR" },
			{ name: "Sudanese Pound", value: "SDG" },
			{ name: "Swedish Krona", value: "SEK" },
			{ name: "Singapore Dollar", value: "SGD" },
			{ name: "Saint Helena Pound", value: "SHP" },
			{ name: "Sierra Leonean Leone", value: "SLL" },
			{ name: "Somali Shilling", value: "SOS" },
			{ name: "Surinamese Dollar", value: "SRD" },
			{ name: "São Tomé and Príncipe Dobra", value: "STD" },
			{ name: "Salvadoran Colón", value: "SVC" },
			{ name: "Syrian Pound", value: "SYP" },
			{ name: "Swazi Lilangeni", value: "SZL" },
			{ name: "Thai Baht", value: "THB" },
			{ name: "Tajikistani Somoni", value: "TJS" },
			{ name: "Turkmenistani Manat", value: "TMT" },
			{ name: "Tunisian Dinar", value: "TND" },
			{ name: "Tongan Paʻanga", value: "TOP" },
			{ name: "Turkish Lira", value: "TRY" },
			{ name: "Trinidad and Tobago Dollar", value: "TTD" },
			{ name: "New Taiwan Dollar", value: "TWD" },
			{ name: "Tanzanian Shilling", value: "TZS" },
			{ name: "Ukrainian Hryvnia", value: "UAH" },
			{ name: "Ugandan Shilling", value: "UGX" },
			{ name: "United States Dollar", value: "USD" },
			{ name: "Uruguayan Peso", value: "UYU" },
			{ name: "Uzbekistan Som", value: "UZS" },
			{ name: "Venezuelan Bolívar Fuerte", value: "VEF" },
			{ name: "Vietnamese Dong", value: "VND" },
			{ name: "Vanuatu Vatu", value: "VUV" },
			{ name: "Samoan Tala", value: "WST" },
			{ name: "CFA Franc BEAC", value: "XAF" },
			{ name: "Silver (troy ounce)", value: "XAG" },
			{ name: "Gold (troy ounce)", value: "XAU" },
			{ name: "East Caribbean Dollar", value: "XCD" },
			{ name: "Special Drawing Rights", value: "XDR" },
			{ name: "CFA Franc BCEAO", value: "XOF" },
			{ name: "CFP Franc", value: "XPF" },
			{ name: "Yemeni Rial", value: "YER" },
			{ name: "South African Rand", value: "ZAR" },
			{ name: "Zambian Kwacha (pre-2013)", value: "ZMK" },
			{ name: "Zambian Kwacha", value: "ZMW" },
			{ name: "Zimbabwean Dollar", value: "ZWL" },
		];
		this.targetCurrencies = [
			{ name: "United Arab Emirates Dirham", value: "AED" },
			{ name: "Afghan Afghani", value: "AFN" },
			{ name: "Albanian Lek", value: "ALL" },
			{ name: "Armenian Dram", value: "AMD" },
			{ name: "Netherlands Antillean Guilder", value: "ANG" },
			{ name: "Angolan Kwanza", value: "AOA" },
			{ name: "Argentine Peso", value: "ARS" },
			{ name: "Australian Dollar", value: "AUD" },
			{ name: "Aruban Florin", value: "AWG" },
			{ name: "Azerbaijani Manat", value: "AZN" },
			{ name: "Bosnia-Herzegovina Convertible Mark", value: "BAM" },
			{ name: "Barbadian Dollar", value: "BBD" },
			{ name: "Bangladeshi Taka", value: "BDT" },
			{ name: "Bulgarian Lev", value: "BGN" },
			{ name: "Bahraini Dinar", value: "BHD" },
			{ name: "Burundian Franc", value: "BIF" },
			{ name: "Bermudan Dollar", value: "BMD" },
			{ name: "Brunei Dollar", value: "BND" },
			{ name: "Bolivian Boliviano", value: "BOB" },
			{ name: "Brazilian Real", value: "BRL" },
			{ name: "Bahamian Dollar", value: "BSD" },
			{ name: "Bitcoin", value: "BTC" },
			{ name: "Bhutanese Ngultrum", value: "BTN" },
			{ name: "Botswanan Pula", value: "BWP" },
			{ name: "Belarusian Ruble", value: "BYR" },
			{ name: "Belize Dollar", value: "BZD" },
			{ name: "Canadian Dollar", value: "CAD" },
			{ name: "Congolese Franc", value: "CDF" },
			{ name: "Swiss Franc", value: "CHF" },
			{ name: "Chilean Unit of Account (UF)", value: "CLF" },
			{ name: "Chilean Peso", value: "CLP" },
			{ name: "Chinese Yuan", value: "CNY" },
			{ name: "Colombian Peso", value: "COP" },
			{ name: "Costa Rican Colón", value: "CRC" },
			{ name: "Cuban Convertible Peso", value: "CUC" },
			{ name: "Cuban Peso", value: "CUP" },
			{ name: "Cape Verdean Escudo", value: "CVE" },
			{ name: "Czech Republic Koruna", value: "CZK" },
			{ name: "Djiboutian Franc", value: "DJF" },
			{ name: "Danish Krone", value: "DKK" },
			{ name: "Dominican Peso", value: "DOP" },
			{ name: "Algerian Dinar", value: "DZD" },
			{ name: "Egyptian Pound", value: "EGP" },
			{ name: "Eritrean Nakfa", value: "ERN" },
			{ name: "Ethiopian Birr", value: "ETB" },
			{ name: "Euro", value: "EUR" },
			{ name: "Fijian Dollar", value: "FJD" },
			{ name: "Falkland Islands Pound", value: "FKP" },
			{ name: "British Pound Sterling", value: "GBP" },
			{ name: "Georgian Lari", value: "GEL" },
			{ name: "Guernsey Pound", value: "GGP" },
			{ name: "Ghanaian Cedi", value: "GHS" },
			{ name: "Gibraltar Pound", value: "GIP" },
			{ name: "Gambian Dalasi", value: "GMD" },
			{ name: "Guinean Franc", value: "GNF" },
			{ name: "Guatemalan Quetzal", value: "GTQ" },
			{ name: "Guyanaese Dollar", value: "GYD" },
			{ name: "Hong Kong Dollar", value: "HKD" },
			{ name: "Honduran Lempira", value: "HNL" },
			{ name: "Croatian Kuna", value: "HRK" },
			{ name: "Haitian Gourde", value: "HTG" },
			{ name: "Hungarian Forint", value: "HUF" },
			{ name: "Indonesian Rupiah", value: "IDR" },
			{ name: "Israeli New Sheqel", value: "ILS" },
			{ name: "Manx pound", value: "IMP" },
			{ name: "Indian Rupee", value: "INR" },
			{ name: "Iraqi Dinar", value: "IQD" },
			{ name: "Iranian Rial", value: "IRR" },
			{ name: "Icelandic Króna", value: "ISK" },
			{ name: "Jersey Pound", value: "JEP" },
			{ name: "Jamaican Dollar", value: "JMD" },
			{ name: "Jordanian Dinar", value: "JOD" },
			{ name: "Japanese Yen", value: "JPY" },
			{ name: "Kenyan Shilling", value: "KES" },
			{ name: "Kyrgystani Som", value: "KGS" },
			{ name: "Cambodian Riel", value: "KHR" },
			{ name: "Comorian Franc", value: "KMF" },
			{ name: "North Korean Won", value: "KPW" },
			{ name: "South Korean Won", value: "KRW" },
			{ name: "Kuwaiti Dinar", value: "KWD" },
			{ name: "Cayman Islands Dollar", value: "KYD" },
			{ name: "Kazakhstani Tenge", value: "KZT" },
			{ name: "Laotian Kip", value: "LAK" },
			{ name: "Lebanese Pound", value: "LBP" },
			{ name: "Sri Lankan Rupee", value: "LKR" },
			{ name: "Liberian Dollar", value: "LRD" },
			{ name: "Lesotho Loti", value: "LSL" },
			{ name: "Lithuanian Litas", value: "LTL" },
			{ name: "Latvian Lats", value: "LVL" },
			{ name: "Libyan Dinar", value: "LYD" },
			{ name: "Moroccan Dirham", value: "MAD" },
			{ name: "Moldovan Leu", value: "MDL" },
			{ name: "Malagasy Ariary", value: "MGA" },
			{ name: "Macedonian Denar", value: "MKD" },
			{ name: "Myanma Kyat", value: "MMK" },
			{ name: "Mongolian Tugrik", value: "MNT" },
			{ name: "Macanese Pataca", value: "MOP" },
			{ name: "Mauritanian Ouguiya", value: "MRO" },
			{ name: "Mauritian Rupee", value: "MUR" },
			{ name: "Maldivian Rufiyaa", value: "MVR" },
			{ name: "Malawian Kwacha", value: "MWK" },
			{ name: "Mexican Peso", value: "MXN" },
			{ name: "Malaysian Ringgit", value: "MYR" },
			{ name: "Mozambican Metical", value: "MZN" },
			{ name: "Namibian Dollar", value: "NAD" },
			{ name: "Nigerian Naira", value: "NGN" },
			{ name: "Nicaraguan Córdoba", value: "NIO" },
			{ name: "Norwegian Krone", value: "NOK" },
			{ name: "Nepalese Rupee", value: "NPR" },
			{ name: "New Zealand Dollar", value: "NZD" },
			{ name: "Omani Rial", value: "OMR" },
			{ name: "Panamanian Balboa", value: "PAB" },
			{ name: "Peruvian Nuevo Sol", value: "PEN" },
			{ name: "Papua New Guinean Kina", value: "PGK" },
			{ name: "Philippine Peso", value: "PHP" },
			{ name: "Pakistani Rupee", value: "PKR" },
			{ name: "Polish Zloty", value: "PLN" },
			{ name: "Paraguayan Guarani", value: "PYG" },
			{ name: "Qatari Rial", value: "QAR" },
			{ name: "Romanian Leu", value: "RON" },
			{ name: "Serbian Dinar", value: "RSD" },
			{ name: "Russian Ruble", value: "RUB" },
			{ name: "Rwandan Franc", value: "RWF" },
			{ name: "Saudi Riyal", value: "SAR" },
			{ name: "Solomon Islands Dollar", value: "SBD" },
			{ name: "Seychellois Rupee", value: "SCR" },
			{ name: "Sudanese Pound", value: "SDG" },
			{ name: "Swedish Krona", value: "SEK" },
			{ name: "Singapore Dollar", value: "SGD" },
			{ name: "Saint Helena Pound", value: "SHP" },
			{ name: "Sierra Leonean Leone", value: "SLL" },
			{ name: "Somali Shilling", value: "SOS" },
			{ name: "Surinamese Dollar", value: "SRD" },
			{ name: "São Tomé and Príncipe Dobra", value: "STD" },
			{ name: "Salvadoran Colón", value: "SVC" },
			{ name: "Syrian Pound", value: "SYP" },
			{ name: "Swazi Lilangeni", value: "SZL" },
			{ name: "Thai Baht", value: "THB" },
			{ name: "Tajikistani Somoni", value: "TJS" },
			{ name: "Turkmenistani Manat", value: "TMT" },
			{ name: "Tunisian Dinar", value: "TND" },
			{ name: "Tongan Paʻanga", value: "TOP" },
			{ name: "Turkish Lira", value: "TRY" },
			{ name: "Trinidad and Tobago Dollar", value: "TTD" },
			{ name: "New Taiwan Dollar", value: "TWD" },
			{ name: "Tanzanian Shilling", value: "TZS" },
			{ name: "Ukrainian Hryvnia", value: "UAH" },
			{ name: "Ugandan Shilling", value: "UGX" },
			{ name: "United States Dollar", value: "USD" },
			{ name: "Uruguayan Peso", value: "UYU" },
			{ name: "Uzbekistan Som", value: "UZS" },
			{ name: "Venezuelan Bolívar Fuerte", value: "VEF" },
			{ name: "Vietnamese Dong", value: "VND" },
			{ name: "Vanuatu Vatu", value: "VUV" },
			{ name: "Samoan Tala", value: "WST" },
			{ name: "CFA Franc BEAC", value: "XAF" },
			{ name: "Silver (troy ounce)", value: "XAG" },
			{ name: "Gold (troy ounce)", value: "XAU" },
			{ name: "East Caribbean Dollar", value: "XCD" },
			{ name: "Special Drawing Rights", value: "XDR" },
			{ name: "CFA Franc BCEAO", value: "XOF" },
			{ name: "CFP Franc", value: "XPF" },
			{ name: "Yemeni Rial", value: "YER" },
			{ name: "South African Rand", value: "ZAR" },
			{ name: "Zambian Kwacha (pre-2013)", value: "ZMK" },
			{ name: "Zambian Kwacha", value: "ZMW" },
			{ name: "Zimbabwean Dollar", value: "ZWL" },
		];
	}

	// transformDate(date) {
	// 	return this.datePipe.transform(date, "yyyy-MM-dd");
	// }
	onAddConverter() {
		// -- create new convertInfo view
		let newConvertInfo = new FxConvertInfoView();

		newConvertInfo.fieldInfo = "";
		newConvertInfo.baseCurrency = this.baseCurrencies[0];
		newConvertInfo.targetCurrencies = [];
		newConvertInfo.hasError = false;

		// -- push new convertInfo to FilterArray
		this.convertList.push(newConvertInfo);

		// -- transform from convertInfoView to convertInfos
		this.convertInfoViewToConvertInfo(this.convertList);
		//this.onUpdateSettings(false);
	}
	onDeleteEntry(index:number) {
		this.convertList = [
			...this.convertList.slice(0, index),
			...this.convertList.slice(index + 1),
		];
		//this.onUpdateSettings(false);
		// this.messageService.standardToastMessage({  severity : 'warning',
		//                                                 summary : 'Filter deleted',
		//                                                 detail : 'The selected filter was deleted successfully!'});
	}
	convertInfoToConvertInfoView(
		list: FxConvertInfo[],
		meta?: FieldInfo[]
	): FxConvertInfoView[] {
		let newConvertInfosView: FxConvertInfoView[] = [];

		list.map((entry: FxConvertInfo) => {
			let newInfoView = new FxConvertInfoView();

			// -- Get respective fieldInfo class from config
			newInfoView.fieldInfo = entry.ColumnName;

			let fieldInfoFound = meta?.find((field) => {
				return field.Name === entry.ColumnName;
			});

			newInfoView.hasError = !fieldInfoFound;

			for (let i = 0; i < this.baseCurrencies.length; i++) {
				if (entry.BaseValue == this.baseCurrencies[i].value) {
					newInfoView.baseCurrency = this.baseCurrencies[i];
				}
			}
			let newTargetUnitsArray: GuiDropDown[] = [];
			for (let i = 0; i < entry.TargetValue.length; i++) {
				for (let j = 0; j < this.targetCurrencies.length; j++) {
					if (entry.TargetValue[i] == this.targetCurrencies[j].value) {
						newTargetUnitsArray.push(this.targetCurrencies[j]);
					}
				}
			}
			newInfoView.targetCurrencies = newTargetUnitsArray;
			newConvertInfosView.push(newInfoView);
		});
		// -- return final view to GUI
		return newConvertInfosView;
	}
	convertInfoViewToConvertInfo(convertList: FxConvertInfoView[]) {
		// -- create new empty array
		let newConvertInfo: FxConvertInfo[] = [];

		// -- Iterate through convertList and create new FilterInfo Rows for each given entry
		convertList.map((entry: FxConvertInfoView) => {
			let newSettings = new FxConvertInfo();
			newSettings.ColumnName = entry.fieldInfo ? entry.fieldInfo : "";
			newSettings.BaseValue = entry.baseCurrency ? entry.baseCurrency.value : "";

			let newTargetArray: string[] = [];
			entry.targetCurrencies.map((unit) => {
				newTargetArray.push(unit.value);
			});
			newSettings.TargetValue = newTargetArray;
			newConvertInfo.push(newSettings);
		});
		// // -- create respective FilterConfig Class

		return newConvertInfo;
	}

	readonly InputPort = "Input";

	// Final Table Object
	convertList: FxConvertInfoView[] = [];
	// DropDowns
	referenceDate: Date = new Date(Date.now());
	columnsList: string[] = [];
	baseCurrencies: GuiDropDown[];
	selectedBaseCurrency?: GuiDropDown;
	targetCurrencies: GuiDropDown[];
	selectedTargetCurrencies: GuiDropDown[] = [];

	ngOnInit(): void {
		super.ngOnInit();
	}
    onFocusLost(event: FocusEvent) {
        const div_left: boolean = (event.relatedTarget === null);

        console.log("Send from Div: ", div_left);

        if (div_left) {
            //this.onCheckDirtyFlag();
            this.onUpdateSettings(true);
        }
    }
}
