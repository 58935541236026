import { Component, OnInit } from "@angular/core";
import { WorkflowNodeSettings } from "src/app/models/api/com/bion/etl/Workflow";
import {
	TableSplitterSettings,
	TableSplitterInfo,
} from "src/app/models/nodeSettings.model";
import { DesignerService } from "src/app/services/designer.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { InfoView } from "../column-aggregator-node/column-aggregator-node.component";
import { NodeConfigComponentBase } from "../node-config-component-base";

export class FlatFieldInfo {
	name: string;
	datatype: string;
	datalength: number;

	constructor(name: string, datatype: string, datalength: number) {
		this.name = name;
		this.datatype = datatype;
		this.datalength = datalength
	}
}

@Component({
	selector: "app-table-splitter-node",
	templateUrl: "./table-splitter-node.component.html",
	styleUrls: ["./table-splitter-node.component.css"],
})
export class TableSplitterNodeComponent
	extends NodeConfigComponentBase<TableSplitterSettings>
	implements OnInit
{
	settingsToView(settings: WorkflowNodeSettings): void {
		let currentConfig = <TableSplitterSettings>settings;

		// -- Get Input Meta Data
		const meta_infos = this.getCurrentWorkflowNode().Properties.MetaInfo.get(
			this.InputPort
		);

		// --create SelectInfoViews from DsFields for table
		if (meta_infos != undefined && meta_infos.length > 0) {
			let fieldInfoList = meta_infos[0].FieldsInfo;

			// -- Create new InfoView Array
			let infoViewsList: InfoView[] = [];

			// -- Append existing metaInfos to full list
			fieldInfoList.map((info) => {
				// let view = new InfoView();
				// view.columnName = info.Name;
				// view.isMissing = false;

				const view = new InfoView(info.Name, false);

				infoViewsList.push(view);
			});

			let currentConfigInfos = currentConfig.TableSplitterInfos;

			// Check if selection does not exist, assign per default
			if (currentConfigInfos === undefined || currentConfigInfos.length === 0) {
				this.leftTableList = infoViewsList;
				this.rightTableList = [];
			} else {
				//-- if selection exists, prepare gui infos
				// -- Get currently included columns
				let currentIncludedColumns = currentConfigInfos[0].RightTable;

				if (currentIncludedColumns) {
					// -- Prepare included List
					let rightViewList = currentIncludedColumns.map(inCol => {
						// if (inCol === "") {
						// }

						// let view = new InfoView();
						// view.columnName = inCol;

						let viewFound = infoViewsList.find(element => element.columnName === inCol);

						// view.isMissing = !viewFound;

						const view = new InfoView(inCol, !viewFound)

						return view;
					});
					let finalRightTableList = rightViewList.filter((value) => {
						return value.columnName !== "";
					});
					this.rightTableList = finalRightTableList;
				}

				// -- Prepare left table List
				//let currentLeftColumns = currentConfigInfos[0].LeftTable;

				let currentLeftColumns = infoViewsList.filter((value) => {
					return !currentIncludedColumns.includes(value.columnName);
				});
				this.leftTableList = currentLeftColumns;
				// -- Filter Column that does not exists in infosview

				// let t = currentLeftColumns.map((value) => {
				// 	let view = new InfoView();
				// 	view.columnName = value;
				// 	view.hasError = false;
				// 	return view;
				// });

				// t = t.filter((r) => {
				// 	return r.columnName !== "";
				// });

				// let infoViewListFiltered = infoViewsList.filter((r) => {
				// 	return !currentLeftColumns.includes(r.columnName);
				// });

				// let leftViewListFiltered = t.concat(infoViewListFiltered);

				// if(t.length === 0 ) {
				// 	this.leftTableList = leftViewListFiltered;
				// } else {
				// 	this.leftTableList = t;
				// }

			}
		}
	}
	viewToSettings(): TableSplitterSettings {
		let newSettings = <TableSplitterSettings>{
			...this.getCurrentWorkflowNode().Properties.Configuration,
		};

		let newConfig = new TableSplitterInfo();
		newConfig.LeftTable = this.leftTableList.map((col) => {
			return col.columnName;
		});
		newConfig.RightTable = this.rightTableList.map((col) => {
			return col.columnName;
		});

		newSettings.TableSplitterInfos = [newConfig];

		return newSettings;
	}

	onSettingsChanged(settings: TableSplitterSettings): void {
		throw new Error("Method not implemented.");
	}

	readonly InputPort = "Input";

	leftTableList: InfoView[] = [];
	rightTableList: InfoView[] = [];
    draggedColumn?: InfoView;

	constructor(
		protected workflowService: WorkflowsService,
		protected designerEventService: DesignerService
	) {
		super(workflowService, designerEventService);
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

    dragStart(product: InfoView) {

		console.log(product);
        this.draggedColumn = product;
    }

    drop(event?: DragEvent) {
        if (this.draggedColumn) {
            let draggedProductIndex = this.findIndex(this.draggedColumn);
            this.rightTableList = [...this.rightTableList, this.draggedColumn];
            this.leftTableList = this.leftTableList.filter((val,i) => i!=draggedProductIndex);
            this.draggedColumn = undefined;

			console.log(this.leftTableList,this.rightTableList);
			this.onUpdateSettings(false);
        }
    }


    dragEnd(event: DragEvent) {
        this.draggedColumn = undefined;
    }
	findIndex(product: InfoView) {
        let index = -1;
        for(let i = 0; i < this.leftTableList.length; i++) {
            if (product.columnName === this.leftTableList[i].columnName) {
                index = i;
                break;
            }
        }
        return index;
    }
    deleteEntry(row: InfoView) {
        this.rightTableList = this.rightTableList.filter((infoview) => { return infoview.columnName !== row.columnName});
        this.leftTableList = [...this.leftTableList, row];

        this.onUpdateSettings(false);
    }

}
