// import { Accessible, Permission, PermissionBase, PlayPermission } from "./authorization.model";
import { Accessible } from "./api/models/authorization/Accessible";
import { PermissionBase } from "./api/models/authorization/PermissionBase";
import { CronFrequency } from "./api/models/scheduler/CronFrequency";
import { PlayJsonInheritance } from "./play.model";

// SCHEDULE ------------------------------------------------------------------------------------------
export class ScheduleActionPlan {
	id: number;
	name: string;
	description?: string;
	isActive: boolean;
}

// ACTIONS ----------------------------------------------------------------------------------------------

export interface IScheduleAction {
	id: number;
	actionPlan: number;
	index: number;
	description?: string;
}
// export class ScheduleExtractAction {
//     id: number;
//     dataSource: number;
// }

// export class ScheduleWorkflowAction {

// }
// export class ScheduleAction implements IScheduleAction{
//     id: number;
//     actionPlan: number;
//     index: number;
//     description?: string;
// }

export interface IScheduleActionView {
	getType(): string;
	getActionTarget(): string;
	getDescription(): string;
	getIndex(): number;
	setIndex(value: number): void;
}
// export class ScheduleExtractActionFull extends ScheduleAction {
//     dataSource: number;
// }
// export class ScheduleWorkflowActionFull extends ScheduleAction {
//     workflow: number;
// }

export interface ScheduleBaseAction
	extends IScheduleAction, PlayJsonInheritance {
	id: number;
	actionPlan: number;
	index: number;
	description?: string;
	user:number;
}

// export class ExtractDataSourceAction implements ScheduleBaseAction {
// 	id: number;
// 	actionPlan: number;
// 	index: number;
// 	description?: string;
// 	dataSource: number;
// 	user:number;
// 	_type: string = "models.scheduler.ExtractDataSourceAction";
// }

export class ExtractDataSourceAction  implements ScheduleBaseAction{
	id: number;
	actionPlan: number;
	index: number;
	description?: string;
	dataSource: number;
	user: number;
	constructor(id: number,actionPlan: number,index: number,dataSource: number,user: number,description?: string) {
		if(id === undefined) throw new Error( "Class 'ExtractDataSourceAction': Required value 'id' is undefined" );
		if(actionPlan === undefined) throw new Error( "Class 'ExtractDataSourceAction': Required value 'actionPlan' is undefined" );
		if(index === undefined) throw new Error( "Class 'ExtractDataSourceAction': Required value 'index' is undefined" );
		if(dataSource === undefined) throw new Error( "Class 'ExtractDataSourceAction': Required value 'dataSource' is undefined" );
		if(user === undefined) throw new Error( "Class 'ExtractDataSourceAction': Required value 'user' is undefined" );
		this.id = id;
		this.actionPlan = actionPlan;
		this.index = index;
		this.description = description;
		this.dataSource = dataSource;
		this.user = user;
	}
	_type: string = "models.scheduler.ExtractDataSourceAction";
}

// export class RunWorkflowAction implements ScheduleBaseAction {
// 	id: number;
// 	actionPlan: number;
// 	index: number;
// 	description?: string;
// 	workflow: number;
// 	user:number;
// 	_type: string = "models.scheduler.RunWorkflowAction";
// }

export class RunWorkflowAction implements ScheduleBaseAction{
	id: number;
	actionPlan: number;
	index: number;
	description?: string;
	workflow: number;
	user: number;
	constructor(id: number,actionPlan: number,index: number,workflow: number,user: number,description?: string) {
		if(id === undefined) throw new Error( "Class 'RunWorkflowAction': Required value 'id' is undefined" );
		if(actionPlan === undefined) throw new Error( "Class 'RunWorkflowAction': Required value 'actionPlan' is undefined" );
		if(index === undefined) throw new Error( "Class 'RunWorkflowAction': Required value 'index' is undefined" );
		if(workflow === undefined) throw new Error( "Class 'RunWorkflowAction': Required value 'workflow' is undefined" );
		if(user === undefined) throw new Error( "Class 'RunWorkflowAction': Required value 'user' is undefined" );
		this.id = id;
		this.actionPlan = actionPlan;
		this.index = index;
		this.description = description;
		this.workflow = workflow;
		this.user = user;
	}
	_type: string = "models.scheduler.RunWorkflowAction";
}

export class ScheduleExtractActionView
	extends ExtractDataSourceAction
	implements IScheduleActionView {
	getActionTarget(): string {
		return "DataSource: " + this.dataSource;
		//throw new Error('Method not implemented.');
	}
	setIndex(value: number): void {
		this.index = value;
		//throw new Error('Method not implemented.');
	}
	getIndex(): number {
		return this.index;
		//throw new Error('Method not implemented.');
	}
	getType(): string {
		return "Extract";
		// throw new Error('Method not implemented.');
	}
	getDescription(): string {
		return "Extract DataSource with ID " + this.dataSource;
		//throw new Error('Method not implemented.');
	}
}
export class ScheduleWorkflowActionView
	extends RunWorkflowAction
	implements IScheduleActionView {
	getActionTarget(): string {
		return "Workflow: " + this.workflow;
		//throw new Error('Method not implemented.');
	}
	setIndex(value: number): void {
		this.index = value;
		//throw new Error('Method not implemented.');
	}
	getIndex(): number {
		return this.index;
		//throw new Error('Method not implemented.');
	}
	getType(): string {
		return "Workflow";
		//throw new Error('Method not implemented.');
	}
	getDescription(): string {
		return "Execute Workflow with ID " + this.id;
		//throw new Error('Method not implemented.');
	}
}

// FREQUENCIES ----------------------------------------------------------------------------------------------

export interface IScheduleFrequency {
	id: number;
	actionPlan: number;
	start: number;
	end?: number;
	description?: string;
}

export class ScheduleFrequency implements IScheduleFrequency {
	id: number;
	actionPlan: number;
	start: number;
	end?: number;
	description?: string;
}



// export class Clock {
// 	Hour: number;
// 	Minute: number;
// 	Second: number;
// }

// /**
//  * The rythm is day oriented
//  */
// export interface BasicDayRhythm extends PlayJsonInheritance {
// 	_type: string;
// 	Days: number[];
// 	UseLastDay: boolean;
// }

// export class WeekRhythm implements BasicDayRhythm {
// 	_type: string = "models.scheduler.WeekRhythm";
// 	Days: number[];
// 	UseLastDay: boolean;
// 	LastDayIndex: number;
// }

// export class MonthRhythm implements BasicDayRhythm {
// 	_type: string = "models.scheduler.MonthRhythm";
// 	Days: number[];
// 	UseLastDay: boolean;
// 	LastDayOffset: number;
// }

// export class RhythmSettings {
// 	Rhythm: BasicDayRhythm;
// }

export interface ScheduleActionFullRow extends PlayJsonInheritance {
	id: number;
	actionPlan: number;
	index: number;
	description?: string;
	user: number;
}

// export class ScheduleExtractActionFull implements ScheduleActionFullRow {
// 	id: number;
// 	actionPlan: number;
// 	index: number;
// 	description?: string;
// 	dataSource: number;
// 	_type: string;
// }

export class ScheduleExtractActionFull implements ScheduleActionFullRow {
	id: number;
	actionPlan: number;
	index: number;
	description?: string;
	dataSource: number;
	user: number;
	constructor(id: number,actionPlan: number,index: number,dataSource: number,user: number,description?: string) {
		if(id === undefined) throw new Error( "Class 'ScheduleExtractActionFullRow': Required value 'id' is undefined" );
		if(actionPlan === undefined) throw new Error( "Class 'ScheduleExtractActionFullRow': Required value 'actionPlan' is undefined" );
		if(index === undefined) throw new Error( "Class 'ScheduleExtractActionFullRow': Required value 'index' is undefined" );
		if(dataSource === undefined) throw new Error( "Class 'ScheduleExtractActionFullRow': Required value 'dataSource' is undefined" );
		if(user === undefined) throw new Error( "Class 'ScheduleExtractActionFullRow': Required value 'user' is undefined" );
		this.id = id;
		this.actionPlan = actionPlan;
		this.index = index;
		this.description = description;
		this.dataSource = dataSource;
		this.user = user;
	}
	_type: string = "models.ScheduleExtractActionFullRow";
}

// export class ScheduleWorkflowActionFull implements ScheduleActionFullRow {
// 	id: number;
// 	actionPlan: number;
// 	index: number;
// 	description?: string;
// 	workflow: number;
// 	_type: string;
// }

export class ScheduleWorkflowActionFull implements ScheduleActionFullRow{
	id: number;
	actionPlan: number;
	index: number;
	description?: string;
	workflow: number;
	user: number;
	constructor(id: number,actionPlan: number,index: number,workflow: number,user: number,description?: string) {
		if(id === undefined) throw new Error( "Class 'ScheduleWorkflowActionFullRow': Required value 'id' is undefined" );
		if(actionPlan === undefined) throw new Error( "Class 'ScheduleWorkflowActionFullRow': Required value 'actionPlan' is undefined" );
		if(index === undefined) throw new Error( "Class 'ScheduleWorkflowActionFullRow': Required value 'index' is undefined" );
		if(workflow === undefined) throw new Error( "Class 'ScheduleWorkflowActionFullRow': Required value 'workflow' is undefined" );
		if(user === undefined) throw new Error( "Class 'ScheduleWorkflowActionFullRow': Required value 'user' is undefined" );
		this.id = id;
		this.actionPlan = actionPlan;
		this.index = index;
		this.description = description;
		this.workflow = workflow;
		this.user = user;
	}
	_type: string = "models.ScheduleWorkflowActionFullRow"
}

export class ActionPlan {
	id: number;
	name: string;
	isActive: boolean;
	description?: string;
	Actions: Array<ScheduleActionFullRow>;
	Frequencies: Array<CronFrequency>;
}

export class TimeStampInfo {
	Frequency: CronFrequency;
	ActionPlan?: ActionPlan;
	Dates: Array<string>;
}

export class SchedulerAction {
	ID: number;
	ActionPlan: number;
	Index: number;
	Description?: string;
	User: number;
	constructor(ID: number, ActionPlan: number, Index: number, User: number, Description?: string) {
		if(ID === undefined) throw new Error( "Class 'ActionInfo': Required value 'ID' is undefined" );
		if(ActionPlan === undefined) throw new Error( "Class 'ActionInfo': Required value 'ActionPlan' is undefined" );
		if(Index === undefined) throw new Error( "Class 'ActionInfo': Required value 'Index' is undefined" );
		if(User === undefined) throw new Error( "Class 'ActionInfo': Required value 'User' is undefined" );
		this.ID = ID;
		this.ActionPlan = ActionPlan;
		this.Index = Index;
		this.Description = Description;
		this.User = User;
	}
}

// export class ActionInfo {
// 	Name: string;
// 	SimpleName: string;
// 	CanonicalName: string;
// 	PackageName: string;
// 	Label: string;
// 	Function: string;
// 	Description?: string;
// 	constructor(Name: string, SimpleName: string, CanonicalName: string, PackageName: string, Label: string, Function: string, Description?: string) {
// 		if(Name === undefined) throw new Error( "Class 'ActionInfo': Required value 'Name' is undefined" );
// 		if(SimpleName === undefined) throw new Error( "Class 'ActionInfo': Required value 'SimpleName' is undefined" );
// 		if(CanonicalName === undefined) throw new Error( "Class 'ActionInfo': Required value 'CanonicalName' is undefined" );
// 		if(PackageName === undefined) throw new Error( "Class 'ActionInfo': Required value 'PackageName' is undefined" );
// 		if(Label === undefined) throw new Error( "Class 'ActionInfo': Required value 'Label' is undefined" );
// 		if(Function === undefined) throw new Error( "Class 'ActionInfo': Required value 'Function' is undefined" );
// 		this.Name = Name;
// 		this.SimpleName = SimpleName;
// 		this.CanonicalName = CanonicalName;
// 		this.PackageName = PackageName;
// 		this.Label = Label;
// 		this.Function = Function;
// 		this.Description = Description;
// 	}
// }

// export class ActionStatus {
// 	Action?: SchedulerAction;
// 	Start?: string;
// 	End?: string;
// 	ActionState: string;
// 	Cancelled: boolean;
// 	Result?: boolean; 
// 	ActionInfo?:ActionInfo;
// 	ErrorInfo?:string;
// 	constructor(ActionState: string, Cancelled: boolean, Action?: SchedulerAction, Start?: string, End?: string, Result?: boolean, ActionInfo?:ActionInfo, ErrorInfo?:string) {
// 		if(ActionState === undefined) throw new Error( "Class 'ActionStatus': Required value 'ActionState' is undefined" );
// 		if(Cancelled === undefined) throw new Error( "Class 'ActionStatus': Required value 'Cancelled' is undefined" );
// 		this.Action = Action;
// 		this.Start = Start;
// 		this.End = End;
// 		this.ActionState = ActionState;
// 		this.Cancelled = Cancelled;
// 		this.Result = Result;
// 		this.ActionInfo = ActionInfo;
// 		this.ErrorInfo = ErrorInfo;
// 	}
// }

// export class TaskStatus {
// 	Start?: string;
// 	End?: string;
// 	State: string;
// 	ActionStates: Array<ActionStatus>;
// 	constructor(State: string, ActionStates: Array<ActionStatus>, Start?: string, End?: string) {
// 		if(State === undefined) throw new Error( "Class 'TaskStatus': Required value 'State' is undefined" );
// 		if(ActionStates === undefined) throw new Error( "Class 'TaskStatus': Required value 'ActionStates' is undefined" );
// 		this.Start = Start;
// 		this.End = End;
// 		this.State = State;
// 		this.ActionStates = ActionStates;
// 	}
// }

// export class SchedulerStatus {
// 	TaskStates: Array<TaskStatus>;
// 	constructor(TaskStates: Array<TaskStatus>) {
// 		if(TaskStates === undefined) throw new Error( "Class 'SchedulerStatus': Required value 'TaskStates' is undefined" );
// 		this.TaskStates = TaskStates;
// 	}
// }

export class ActionPlanAccessible implements Accessible {
	ID: number;
	constructor(ID: number) {
		if(ID === undefined) throw new Error( "Class 'ActionPlanAccessible': Required value 'ID' is undefined" );
		this.ID = ID;
	}
	AccessibleType: string = "";
	Value: string = "";
}

export class ActionPlanPermission implements PermissionBase<ActionPlanAccessible> {
	ID: number;
	Role: number;
	IsOwner: boolean;
	Accessible: ActionPlanAccessible;
	Rights: string[];
	constructor(ID: number, Role: number, IsOwner: boolean, Accessible: ActionPlanAccessible, Rights: string[]) {
		if(ID === undefined) throw new Error( "Class 'ActionPlanPermission': Required value 'ID' is undefined" );
		if(Role === undefined) throw new Error( "Class 'ActionPlanPermission': Required value 'Role' is undefined" );
		if(IsOwner === undefined) throw new Error( "Class 'ActionPlanPermission': Required value 'IsOwner' is undefined" );
		if(Accessible === undefined) throw new Error( "Class 'ActionPlanPermission': Required value 'Accessible' is undefined" );
		if(Rights === undefined) throw new Error( "Class 'ActionPlanPermission': Required value 'Rights' is undefined" );
		this.ID = ID;
		this.Role = Role;
		this.IsOwner = IsOwner;
		this.Accessible = Accessible;
		this.Rights = Rights;
	}
}