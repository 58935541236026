import { Component, OnInit } from '@angular/core';
import { mergeWith } from 'lodash';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Id } from 'src/app/helper/id';
import { DataType } from 'src/app/models/api/com/bion/data/pipeline/DataType';
import { NodeMetaData } from 'src/app/models/api/com/bion/etl/NodeMetaData';
import { ChangeDatatypePlugIn } from 'src/app/models/api/com/bion/etl/transformation/ChangeDatatypePlugIn';
import { FieldInfo, WorkflowNodeSettings } from 'src/app/models/api/com/bion/etl/Workflow';

import { DataTypeInfo } from 'src/app/models/datasource.model';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { DesignerService } from 'src/app/services/designer.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { NodeConfigComponentBase } from '../node-config-component-base';

export class ChangeDatatypeInfoView {
	ColumnName: FieldInfo;
	showNumberConvention: boolean;
	Datatype?: DataTypeInfo;
	NumberConvention?: string;
	hasError?: boolean = false;

	constructor(ColumnName: FieldInfo, showNumberConvention: boolean) {
		this.ColumnName = ColumnName;
		this.showNumberConvention = showNumberConvention;
	}
}

@Component({
	selector: 'app-change-datatype-node',
	templateUrl: './change-datatype-node.component.html',
	styleUrls: ['./change-datatype-node.component.scss']
})
export class ChangeDatatypeNodeComponent extends NodeConfigComponentBase<ChangeDatatypePlugIn.Settings> implements OnInit {
	settingsToView(settings: WorkflowNodeSettings): void {
		console.log("settings", settings);
		let currentSettings = <ChangeDatatypePlugIn.Settings>settings;
		console.log("current workflow node", this._currentWorkflowNodeAdapter.IWorkflowNode.Properties.Configuration);

		// -- Get Input Meta Data
		const meta_infos =
		this._currentWorkflowNodeAdapter.IWorkflowNode.Properties.MetaInfo.get(
			this.InputPort
		);



		// if A then B & C else C 

		// !A & B & C v A & C

		// (!A & B v A) v C

		const obs = this.dataTypesList ? of(this.dataTypesList) : this.bionApi.getDataTypes(); 

		obs.pipe(map(result => {
			let filteredDataTypes = result.filter((dtypes) => {
				return dtypes.Name !== "Number" && dtypes.Name !== "Decimal" && dtypes.Name !== "Bytes"
			});
			this.dataTypesList = filteredDataTypes;

			this._settingsIntoView(currentSettings, meta_infos ? meta_infos : []);
		})).subscribe();

	

		this.createFieldSelectionDropdown(currentSettings,meta_infos ? meta_infos : []);



		//TODO: synchronize methods
		// if (this.dataTypesList === undefined) {
		// 	// -- if no datatypes available, fetch datatypes from api
		// 	this.subs.sink = this.bionApi.getDataTypes().subscribe((result) => {

		// 		let filteredDataTypes = result.filter((dtypes) => {
		// 			return dtypes.Name !== "Number" && dtypes.Name !== "Decimal" && dtypes.Name !== "Bytes"
		// 		});
		// 		this.dataTypesList = filteredDataTypes;

		// 		//console.log("FILTERED DATATYPES:", this.dataTypesList);
		// 		this._settingsIntoView(currentSettings,meta_infos);
		// 	}, (err) => {
		// 		console.log(err);
		// 	});
		// } else {
		// 	// -- if datatypes available, load into view
		// 	this._settingsIntoView(currentSettings,meta_infos);
		// }
	}
	viewToSettings(): ChangeDatatypePlugIn.Settings {
		let newSettings = <ChangeDatatypePlugIn.Settings>{
			...this.getCurrentWorkflowNode().Properties.Configuration,
		};
		console.log(newSettings);

		newSettings.NumberConvention = this.selectedNumberConvention ? this.selectedNumberConvention : newSettings.NumberConvention;

		// -- Get select information
		const selectedInfoViews = this.changeDataTypeInfoViews;
		const selectedInfos = selectedInfoViews.map((v, i, a) => {
			return this.changeDatatypeInfoViewToChangeDatatypeInfo(v);
		});
		newSettings.ChangeDatatypeInfos = selectedInfos;


		return newSettings;

	}
	onSettingsChanged(settings: ChangeDatatypePlugIn.Settings): void {
		throw new Error('Method not implemented.');
	}
	columnsList: FieldInfo[] = [];
	changeDataTypeInfoViews: ChangeDatatypeInfoView[] = [];
	dataTypesList?: DataTypeInfo[];
	numberConventionOptions: string[] = [];
	selectedNumberConvention?: string;
	readonly InputPort = "Input";
	static DummyDataType = new DataType("???", 0,"",true,0);

	constructor(
		protected workflowService: WorkflowsService,

		protected designerService: DesignerService,
		protected bionApi: ApiBackendService
	) {
		super(workflowService, designerService);
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.numberConventionOptions = ["EU", "US"];
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	changeDatatypeInfoViewToChangeDatatypeInfo(v: ChangeDatatypeInfoView): ChangeDatatypePlugIn.ChangeDatatypeInfo {
		const new_data_type = v.Datatype ? v.Datatype.Name : undefined;
		const number_conversion = v.NumberConvention ? v.NumberConvention : undefined;

		return new ChangeDatatypePlugIn.ChangeDatatypeInfo(v.ColumnName.Name, new_data_type,number_conversion);
	}

	setGlobalNumberConvention(settings: ChangeDatatypePlugIn.Settings): string {
		return settings.NumberConvention
	}
	onChangeGlobalNumberConvention(convention: string) {
		console.log("Selected Convention", convention);
		// Kopiere den Wert von der Global Convention in alle lokalen Conventions, reiner GUI Task
		this.changeDataTypeInfoViews = this.changeDataTypeInfoViews.map((view) => {
			if(view.showNumberConvention) {
				view.NumberConvention = convention;
			}

			return view
		});
		this.onUpdateSettings(false);
	}

	onAddEntry() {

		let firstFieldInfo = this.columnsList[0];
		//newEntry.ColumnName = firstFieldInfo;

		const newEntry = new ChangeDatatypeInfoView(firstFieldInfo,true);
		newEntry.NumberConvention = "US";

		this.changeDataTypeInfoViews.push(newEntry);
		this.onUpdateSettings(false);

	}
	onDeleteEntry(ri: number) {
		this.changeDataTypeInfoViews = [
			...this.changeDataTypeInfoViews.slice(0, ri),
			...this.changeDataTypeInfoViews.slice(ri + 1),
		];
		this.onUpdateSettings(false);
	}

	createFieldSelectionDropdown(settings: ChangeDatatypePlugIn.Settings,meta_infos: NodeMetaData[])  {

		// create SelectInfoViews from DsFields for table
		if (meta_infos != undefined && meta_infos.length > 0) {
			const input_field_infos = meta_infos[0].FieldsInfo;
			//this.columnList = input_field_infos;


			let input_field_final = [...input_field_infos];
			const input_field_array = input_field_infos.map((info) => { return info.Name});

			// --If currentConfig not available (-> new node)
			settings.ChangeDatatypeInfos.map((info) => {
				if (input_field_array.includes(info.ColumnName)) {
					// -- column still exists, do nothing
				} else {
					// column does not exist anymore, meaning column name currently not included -> include
					let dummyType = ChangeDatatypeNodeComponent.DummyDataType;
					let newInfo = new FieldInfo(info.ColumnName,dummyType,false);
					input_field_final.push(newInfo);
				}
			});

			// -- create string array for dropdown
			this.columnsList = input_field_final;
		}
	}
	_settingsIntoView(settings: ChangeDatatypePlugIn.Settings, meta_infos: NodeMetaData[]) {
		// Globale Settings setzen
		this.selectedNumberConvention = settings.NumberConvention;

		const input_field_infos = meta_infos.length > 0 ? meta_infos[0].FieldsInfo : [];

		// Für jede DatatypeInfo eine View bauen mit Kombobox

		const views = settings.ChangeDatatypeInfos.map((typeInfo) => {
			const dt_list = Id.assertSet(this.dataTypesList, new Error("The data types are not set. This should never happen!!!"));
			 return ChangeDatatypeNodeComponent.infoToView(typeInfo,input_field_infos, this.columnsList, dt_list);
		})
		this.changeDataTypeInfoViews = views;
	}


	/**
	 * Builds a dummy field info, in case the column does not exist anymore and we need a field info
	 * @param name col name
	 * @returns Dummy field info
	 */
	static makeDummyFieldInfo(name:string) : FieldInfo {
		return new FieldInfo(name, this.DummyDataType, false);
	}

	/**
	 * 
	 * @param info 
	 * @param input_field_infos Field infos from meta data. If none are available, the error object is not set!
	 * @param columnsList 
	 * @param dataTypesList 
	 * @returns 
	 */
	static infoToView(info: ChangeDatatypePlugIn.ChangeDatatypeInfo, input_field_infos: FieldInfo[], columnsList:FieldInfo[], dataTypesList:DataTypeInfo[]): ChangeDatatypeInfoView {
		//const view = new ChangeDatatypeInfoView();
		const ColumnName_opt = columnsList.find((fieldInfo) => { return fieldInfo.Name == info.ColumnName}) ;
		const ColumnName = ColumnName_opt ? ColumnName_opt : ChangeDatatypeNodeComponent.makeDummyFieldInfo(info.ColumnName);

		let showNumberConvention: boolean;
		const showNumberConventionDTypes = ["Float", "Double","Date", "DateTime"];
		if(info.Datatype && showNumberConventionDTypes.includes(info.Datatype))  {
			showNumberConvention = true;
		} else {
			showNumberConvention = false;
		}

		const view = new ChangeDatatypeInfoView(ColumnName,showNumberConvention);

		view.Datatype = info.Datatype ? dataTypesList.find((dt) => { return dt.Name === info.Datatype}) : undefined;

		view.NumberConvention = info.NumberConvention;

		// create SelectInfoViews from DsFields for table
		if (input_field_infos != undefined && input_field_infos.length > 0) {

			const givenFieldInfoArray = input_field_infos.map((info) => { return info.Name});

			if(givenFieldInfoArray.includes(info.ColumnName)) {
				view.hasError = false;
			}else {
				view.hasError = true;
			}
		}

		return view
	}
    onFocusLost(event: FocusEvent) {
        const div_left: boolean = (event.relatedTarget === null);

        console.log("Send from Div: ", div_left);

        if (div_left) {
            //this.onCheckDirtyFlag();
            this.onUpdateSettings(true);
        }
    }

}
