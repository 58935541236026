import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MenuItem, MessageService } from "primeng/api";
import { Observable, Subject } from "rxjs";
import * as dss from "src/app/models/datasource.model";
import { DatasourcesService } from "src/app/services/datasources.service";

import {
	ConnectorSettingsBuilder,
	ConnectorView,
} from "src/app/models/connectorView.model";
import { WriteMode } from "src/app/models/helperClass.model";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { map, mergeMap } from "rxjs/operators";
import { DefineDatasourceComponent } from "../../components/define-datasource/define-datasource.component";
import { PopulatePsaComponent } from "../../components/populate-psa/populate-psa.component";
import { SubSink } from "subsink";
import { DynamicItemInfo } from "src/app/models/com-bion-json";
import { DataSet } from "src/app/models/datamarket.model";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { ChooseConnectorComponent } from "../../components/choose-connector/choose-connector.component";
import { GuiErrorCode, GuiErrorException } from "src/app/models/gui.error.model";
import { FieldInfo } from "src/app/models/datasource.model";
import { AdapterTypeInfo, DataMarketAccess } from "src/app/models/connector.model";
import { CreateNewDatasourceComponent } from "../../components/create-new-datasource/create-new-datasource.component";
import { CreateDataSourceNewLib } from "../create-datasource-dialog-new/createDataSourceNewLib";
import { TranslateService } from "@ngx-translate/core";

export enum dsActionType {
	CreateDataSource,
	LoadToPsa,
	CreateDataSet
}

export enum TabPageNames {
	SelectConnector,
	DefineDataSource,
	SelectColumns,
	LoadData
}

@Component({
	selector: "app-create-datasource-dialog",
	templateUrl: "./create-datasource-dialog.component.html",
	styleUrls: ["./create-datasource-dialog.component.scss"],
	providers: [MessageService],
})


export class CreateDatasourceDialogComponent implements OnInit, OnDestroy {
	loading: boolean = false;
	savingInProgress: boolean;
	buttonHidden: boolean;
	headerText: string;
	displayCreateDataSource: boolean = false;
	isNewDataSource: boolean = true;
	dsActionType: number = dsActionType.CreateDataSource;			// default is always new --> 0, update --> 1, dataset --> 2
	private subs = new SubSink();

	@ViewChild("page_def_datasource")
	page_define_datasource: DefineDatasourceComponent;
	@ViewChild("page_pop_psa") page_pop_psa: PopulatePsaComponent;
	@ViewChild("chooseConnector") chooseConnector: ChooseConnectorComponent;
	@ViewChild("page_ds_dialog") create_datasource: CreateNewDatasourceComponent;

	@Input() psaInfos: dss.PersistentStagingArea[];
	currentSelectedDatasource?: dss.DataSource;
	@Input() selectedDataSet?: DataSet;

	// Step 1 - Choose Connector
	currentConnectorView: ConnectorView<any, any, any>;
	selectedAdapterTypeInfo: AdapterTypeInfo<any,any>;

	//Step 2 - Define DS
	currentDataSourceName: string;
	currentDataSourceDescription: string;
	LoadDataFlag: boolean;

	//Step 3 - Select Columns
	selectedDataSourceFields: dss.FieldInfo[] = [];
	prefixDisabled: boolean; // Suffix DataSource Name
	autoTableName: boolean = true; // Attribute für auto Table Naming
	writeModes: WriteMode[]; // List of all UpdateOptions
	selectedWriteModeOption: WriteMode; // Variable for selectedUpdateOption

	// Step 4 - LOAD PSA
	p4_selectedWriteModeOption: WriteMode;
	p4_psaInfos: dss.PersistentStagingArea[];

	stepsItems: MenuItem[];
	activeIndex: number;

	constructor(
		private datasourceService: DatasourcesService,
		private bionApi: ApiBackendService,
		private messageService: MessageService,
		private errorService: SystemMessageLogService,
		private translate: TranslateService
	) {}

	ngOnInit(): void {
		this.stepsItems = [
			{ label: "Choose Connection" },
			{ label: "Define Datasource" },
			{ label: "Select Columns" },
			//{ label: "Load Data" },
		];
		this.activeIndex = 0;
		this.subs.sink = this.datasourceService.selectedDatasourceEmitter.subscribe(
			(ds: dss.DataSource) => {
				this.currentSelectedDatasource = ds;
			}
		);
		this.writeModes = this.datasourceService.getWriteModes();
		// this.subs.sink = this.datasourceService.displayCreateDatasource.subscribe(
		// 	(res: boolean) => {
		// 		this.displayCreateDataSource = res;

		// 		this.stepsItems = [
		// 			{ label: "Choose Connection" },
		// 			{ label: "Select Columns" },
		// 			{ label: "Define Datasource" },
		// 			//{ label: "Load Data" },
		// 		];
		// 		this.headerText = "CreateNewDatasource";
		// 		this.activeIndex = 0;
		// 	}
		// );
		this.subs.sink = this.datasourceService.displayLoadToPsa.subscribe(
			(res: boolean) => {
				this.displayCreateDataSource = res;
				this.isNewDataSource = false;
				this.dsActionType = dsActionType.LoadToPsa;

				if (!res) return;

				this.stepsItems = [
					{ label: "Choose Connection" },
					{ label: "Load Data" },
				];
				this.headerText = "Load to datapool";


				// select connector
				// data source id
				const ds_id = this.currentSelectedDatasource.id;
				console.log("Current Data Source ID: " + ds_id);
				this.bionApi.getConnectorsInfo(ds_id).subscribe(connector_res => {
					if(connector_res.length == 0)return;

					const con_settings = connector_res[0];
					con_settings.Connector;
					this.cmd_selectConnector(con_settings.Connector, con_settings.settings).subscribe(result => {
						console.log(result);
						this.activeIndex = 0;
					}, error => {console.log(error)});
				})
			}
		);
		this.subs.sink = this.datasourceService.displayCreateDataSet.subscribe((res:[string, DataMarketAccess]) => {

			this.displayCreateDataSource = true;
			console.log("CreateNewDataSet");
			this.dsActionType = dsActionType.CreateDataSet;

			this.stepsItems = [
				{ label: "Choose Connection" },
				{ label: "Select Columns" },
				{ label: "Define Datasource" },
				{ label: "Load Data" },
			];
			this.headerText = "Create new Dataset";

			this.cmd_selectConnector(res[0],res[1]).subscribe(result => {
				console.log(result);
				this.currentConnectorView.connectorSettings = res[1];

			},
			error => {console.log(error)});

		});


	}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	/**
	 * Send a command to this component from outside
	 */
	sendCommand() {}

	cmd_selectConnector<A, B>(name: string, settings?: A, metaSettings?: B) {
		return this.chooseConnector.selectConnector(name, settings, metaSettings);
	}

	cmd_onNext() {}

	// Dialog CONTROLS ---------------------------------------
	onCloseDialog() {
		this.resetCurrentForm();
		this.displayCreateDataSource = false;
	}

	onIndexChanged(index) {
		console.log(index);
	}

	// STEP CONTROLS --------------------------
	resetCurrentForm(currentIndex?: number) {
		if (currentIndex === undefined) {
			this.errorService.clearMessageEventEmitter.emit(0);
			this.currentConnectorView = undefined;
			this.currentDataSourceName = undefined;
			this.currentDataSourceDescription = undefined;
			this.selectedDataSourceFields = undefined;
			this.dataPreviewColumns = undefined;
			this.selectedAdapterTypeInfo = undefined;
			this.isNewDataSource = true;

			this.activeIndex = 0;
            return
		}
		if (this.stepsItems[currentIndex].label === 'Choose Connection' ) {
			this.errorService.clearMessageEventEmitter.emit(0);
			this.currentConnectorView = undefined;
			//this.selectedConnector = undefined;
			this.selectedAdapterTypeInfo = undefined;
			this.activeIndex = currentIndex - 1;
		}
		if (this.stepsItems[currentIndex].label === 'Define Datasource') {
			this.errorService.clearMessageEventEmitter.emit(0);
			this.currentDataSourceName = undefined;
			this.currentDataSourceDescription = undefined;
			this.activeIndex = currentIndex - 1;


		}
		if (this.stepsItems[currentIndex].label === 'Select Columns') {
			this.errorService.clearMessageEventEmitter.emit(0);
			this.selectedDataSourceFields = undefined;
			this.activeIndex = currentIndex - 1;
		}
	}
	validateCurrentForm(currentIndex) {
		this.savingInProgress = true;
		this.buttonHidden = true;

		console.log("onNext");

		if (this.currentConnectorView === undefined) {
			this.errorService.handleError(new GuiErrorException(GuiErrorCode.noConnectorSelected));
			//this.errorService.sendErrorCode(GuiErrorCode.noConnectorSelected);
			this.savingInProgress = false;
			this.buttonHidden = false;
			return;
		}
		if (this.stepsItems[currentIndex].label === 'Choose Connection' ) {
			if (this.currentConnectorView.connectorSettings === undefined) {
				this.errorService.handleError(new GuiErrorException(GuiErrorCode.noSettingsProvided));
				//this.errorService.sendErrorCode(GuiErrorCode.noSettingsProvided);
				this.savingInProgress = false;
				this.buttonHidden = false;
				return;
			}
			if (this.currentConnectorView.isFileBased() && this.currentConnectorView.getBase64FileData() === undefined) {
				this.errorService.handleError(new GuiErrorException(GuiErrorCode.noFileProvided));
				//this.errorService.sendErrorCode(GuiErrorCode.noFileProvided);

				this.savingInProgress = false;
				this.buttonHidden = false;
				return;
			}
			if (
				this.currentConnectorView.connectorSettings !== undefined &&
				( !this.currentConnectorView.isFileBased() ||  this.currentConnectorView.getBase64FileData() !== undefined)
			) {
				this.subs.sink = this.loadTablePreview().subscribe(
					(res: dss.DataTypePreview) => {
						if (this.isNewDataSource) {

							this.activeIndex = currentIndex + 1;
							this.errorService.clearHttpError();
							this.savingInProgress = false;
							this.buttonHidden = false;
						} else {
							//this.getPSA(this.currentSelectedDatasource, res.Columns);
							const psaObs = CreateDataSourceNewLib.getPSA(this.currentSelectedDatasource, res.Columns, this.writeModes,this.bionApi);
							psaObs.subscribe((psaResult) => {

							this.selectedWriteModeOption = psaResult[2];
							this.selectedDataSourceFields = psaResult[1];
							this.activeIndex = currentIndex + 1;
							this.errorService.clearHttpError();
							this.savingInProgress = false;
							this.buttonHidden = false;
							},(error) => {
								this.errorService.handleError(error);
								this.savingInProgress = false;
								this.buttonHidden = true;
							})

						}
					},
					(err) => {
						this.errorService.handleError(err);
						this.savingInProgress = false;
						this.buttonHidden = true;
					}
				);
			}
		}

		if (this.stepsItems[currentIndex].label === 'Select Columns') {

			if (
				this.selectedDataSourceFields &&
				this.selectedDataSourceFields.length > 0
			) {
				//this.createDataSource();
				console.log("Before Create DS");
				this.activeIndex = currentIndex + 1;
				this.savingInProgress = false;
				this.buttonHidden = false;


			} else {
				//this.errorService.sendErrorCode(GuiErrorCode.noColumnsSelected);
				this.errorService.handleError(new GuiErrorException(GuiErrorCode.noColumnsSelected));

				this.savingInProgress = false;
				this.buttonHidden = false;
				return;
			}
		}
		if (this.stepsItems[currentIndex].label === 'Define Datasource') {
			if (this.page_define_datasource.isValid()) {
				let createDSObs = this.createDataSourcesAsync_New();
				const full_obs = createDSObs.pipe(map((psa_res) => {
					this.currentSelectedDatasource = psa_res['currentSelectedDatasource'];
					console.log(psa_res['currentSelectedDatasource']);
					if(this.LoadDataFlag) {
						const base64 = CreateDataSourceNewLib.tryGetFileData(this.currentConnectorView);

						this.subs.sink = CreateDataSourceNewLib.loadPSAStreamAsync(
							this.currentSelectedDatasource,
							this.currentConnectorView,
							this.datasourceService,
							false,
							this.selectedWriteModeOption,
							base64
						).subscribe(load_result => {
							this.messageService.add({
								severity: "info",
								summary: this.translate.instant("Message.LoadDataStartedSuccess.Title"),

								//summary: "Data load has started!",
								detail: this.translate.instant("Message.LoadDataStartedSuccess.Text"),
								//detail: "You will be notified if data load is finished!",
							});
						});
					} else {
						this.messageService.add({
							severity: "success",
							summary: this.translate.instant("Message.CreateNewDataSourceSuccess.Title"),
							detail: this.translate.instant("Message.CreateNewDataSourceSuccess.Text"),
						});
					}
					this.datasourceService.datasourceChangedEmitter.emit(psa_res['currentSelectedDatasource']);
					this.loading = false;
					this.savingInProgress = false;
					this.buttonHidden = false;
					this.displayCreateDataSource = false;
					this.resetCurrentForm();

					return psa_res;
				}));

				this.subs.sink = full_obs.subscribe(
					(result) => {
					   console.log("Extract received from API: ", result);
					},
					(error) => {
						this.loading = false;
						this.savingInProgress = false;
						this.buttonHidden = false;
						this.errorService.handleError(error);
					}
				);
			} else {
				//this.errorService.sendErrorCode(GuiErrorCode.notAllFieldsEntered);
				this.errorService.handleError(new GuiErrorException(GuiErrorCode.notAllFieldsEntered));


				this.savingInProgress = false;
				this.buttonHidden = false;
				return;
			}
		}
		if (this.stepsItems[currentIndex].label === 'Load Data') {
			const base64 = CreateDataSourceNewLib.tryGetFileData(this.currentConnectorView);
			if (this.page_pop_psa.isValid()) {

				CreateDataSourceNewLib.loadPSAStreamAsync(
					this.currentSelectedDatasource,
					this.currentConnectorView,
					this.datasourceService,
					false,
					this.selectedWriteModeOption,
					base64);

			} else {

				CreateDataSourceNewLib.loadPSAStreamAsync(
					this.currentSelectedDatasource,
					this.currentConnectorView,
					this.datasourceService,
					false,
					this.selectedWriteModeOption,
					base64);
			}
			this.savingInProgress = false;
			this.buttonHidden = false;
			//this.displayInfo("Datasource successfully created!","")
		}

		setTimeout(() => {
			this.savingInProgress = false;
			this.buttonHidden = false;
		}, 5000);
	}

	displayInfo(summary:string, detail:string) {
		this.messageService.add({
			severity: "success",
			summary: summary,
			detail: detail,
		});
	}


	// STEP 1 PREVIEW DATA -----------------------------------------------------------
	setCurrentConnectorView(event) {
		this.currentConnectorView = event;
	}
	setSelectedAdapterInfo(event: AdapterTypeInfo<any,any>) {
		this.selectedAdapterTypeInfo = event;

	}

	// STEP 2 DEFINE DATASOURCE -------------------------------------------------------
	setDataSourceName(event) {
		this.currentDataSourceName = event;
	}
	setDataSourceDescription(event) {
		this.currentDataSourceDescription = event;
	}
	setDataFlag(event) {
		this.LoadDataFlag = event;
	}

	// STEP 2 PREVIEW DATA -------------------------------------------------------------

	loadTablePreview(
		fieldSelection?: FieldInfo[]
	): Observable<dss.DataTypePreview> {
		this.savingInProgress = true;
		// let previewAdapterFuture = this.currentConnectorView.getSettingsBuilder();
		const previewAdapterFuture = this.currentConnectorView.getPreviewBuilder(100,0);

		let file_data: string = undefined;
		try {
			file_data = this.currentConnectorView.getBase64FileData();
		} catch (e) {
			console.log(
				"Connector " +
					this.currentConnectorView.getConnectorID() +
					" does not provide file data"
			);
		}

		return previewAdapterFuture.pipe(
			mergeMap((previewAdapter) => {
				return this.updateTablePreview(
					previewAdapter,
					file_data,
					fieldSelection
				);
			})
		);
	}

	dataPreviewColumnsMapping;
	dataPreviewColumnClone = [];
	dataPreviewColumns: dss.FieldInfo[];
	initialPreviewColumns: dss.FieldInfo[];
	dataPreviewRows: any[];
	updateTablePreview(
		previewAdapter: ConnectorSettingsBuilder<any>,
		base64FileData: string,
		fieldSelection?: FieldInfo[]
	): Observable<dss.DataTypePreview> {
		// Initialize extractDataArg Class

		const connector_info = new DynamicItemInfo(
			previewAdapter.getConnectorId(),
			previewAdapter.getConnectorSettings()
		);
		const load_settings = new dss.LoadSettings(true, 100);
		const arg = new dss.ExtractFromConnectorArg(
			connector_info,
			load_settings,
			base64FileData,
			fieldSelection
		);

		let preview = this.datasourceService.extractFromConnector(arg);

		return preview.pipe(
			map((res) => {
				this.data_to_preview(res);
				return res;
			})
		);
	}

	previewRows: any[] = [];
	previewColumns: FieldInfo[] = [];

	data_to_preview(res: dss.DataTypePreview) {
		let previewResult = CreateDataSourceNewLib.data_to_preview(res, this.initialPreviewColumns);
		this.dataPreviewColumns = previewResult[0];
		this.dataPreviewColumnClone = previewResult[1];
		this.dataPreviewColumnsMapping = previewResult[2];
		this.initialPreviewColumns = previewResult[3];
		this.previewRows = previewResult[4];
		this.previewColumns = res.Columns;
	}


	dataExtracted: Subject<any> = new Subject();
	onDataExtracted() {
		this.dataExtracted.next("some value");
	}

	public newDatasourceArg: dss.CreateDataSourceArg;
	public datasourcePrefix: string = "PSAT";
	public suffixConnect: string = "_";

	// STEP 3 - SETUP DATASOURCE -----------------------------------------------------------------

	setWriteMode(event: WriteMode) {
		this.selectedWriteModeOption = event;
	}

	createDataSourcesAsync_New() {
		return CreateDataSourceNewLib.createDataSourcesAsync(this.selectedWriteModeOption,this.selectedDataSourceFields,
			this.currentDataSourceName,this.currentDataSourceDescription,this.datasourceService,
			this.currentConnectorView,this.bionApi,this.datasourcePrefix,this.suffixConnect,this.autoTableName)
	}

	onUpdateDataSourceFields(event) {
		this.selectedDataSourceFields = event;

		// update data preview ->
		this.loadTablePreview(event).subscribe((result) => {
			console.log(result);
		});
	}

	onPsaLoadClicked() {
		this.validateCurrentForm(this.activeIndex);
	}


}
