

export namespace LimitRowsPlugIn {
    export class LimitRowsInfo {

		StartRow?: number;
        MaxRows?: number;
		constructor(StartRow?: number, MaxRows?: number) {
            this.StartRow = StartRow;
            this.MaxRows = MaxRows;
		}
	}
	export class Settings {
		LimitRowsInfos: Array<LimitRowsInfo>;
		constructor(LimitRowsInfos: Array<LimitRowsInfo>) {
			if(LimitRowsInfos === undefined) throw new Error( "Class 'Settings': Required value 'LimitRowsInfos' is undefined" );
			this.LimitRowsInfos = LimitRowsInfos;
		}
	}
}