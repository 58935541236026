import { Component, OnInit } from '@angular/core';
import { ConfigComponent } from '../../../create-ab-source/config.component';

export namespace PostgreSql {

  export interface ReplicationMethod {
    method: string
  }

  export interface SslMode {
    mode: string;
  }

  export interface TunnelMethod {
    tunnel_method: string
  }

  export interface Config {
    database: string,
    host: string,
    password: string,
    port: number,
    replication_method: ReplicationMethod,
    schemas: string[],
    ssl_mode: SslMode
    tunnel_method: TunnelMethod,
    username: string
  }

  //   {
  //     "database": "tests",
  //     "host": "host.docker.internal",
  //     "password": "SA",
  //     "port": 5432,
  //     "replication_method": {
  //         "method": "Standard"
  //     },
  //     "schemas": [
  //         "public"
  //     ],
  //     "ssl_mode": {
  //         "mode": "disable"
  //     },
  //     "tunnel_method": {
  //         "tunnel_method": "NO_TUNNEL"
  //     },
  //     "username": "postgres"
  // }
}

@Component({
  selector: 'app-postgre-sql',
  templateUrl: './postgre-sql.component.html',
  styleUrls: ['./postgre-sql.component.scss']
})
export class PostgreSqlComponent implements OnInit, ConfigComponent {

  data: PostgreSql.Config;   // TODO: check if we need to initialize this here.
  connectorSpecs: any;

  constructor() {
    
  }

  private init_config() {
    console.log("Initlaize Postgres");
    this.data =
    {
      database: "postgres",
      host: "host.docker.internal",
      password: "SA",
      port: 5432,
      replication_method: {
        method: "Standard"
      },
      schemas: [
        "public"
      ],
      ssl_mode: {
        mode: "disable"
      },
      tunnel_method: {
        tunnel_method: "NO_TUNNEL"
      },
      username: "postgres"
    }
  }

  ngOnInit(): void {
    this.init_config();
  }

}
