<div class="p-grid">

    <div class="p-col-8">

        <p-toolbar>
            <span class="p-input-icon-left mb-2 md:mb-0">
                <i class="pi pi-search"></i>
                <input type="search" pInputText placeholder="{{'Search target' | translate}}"
                    (input)="dv.filter($event.target.value)">
            </span>
            <span class="p-input-icon-left mb-2 md:mb-0">
                <i class="pi pi-search"></i>
                <p-multiSelect id="CategoryInputtext" placeholder="{{'Category' | translate}}" [options]="destinationCategories" (onChange)="onSelectCategory($event)" display="chip"></p-multiSelect>

            </span>
            <span class="p-input-icon-left mb-2 md:mb-0">
                <button pButton icon="pi pi-info"> </button>
            </span>
        </p-toolbar>
        <p-scrollPanel [style]="{height: 'calc(65vh)'}">
            <p-dataView #dv [value]="filteredTargetTypeInfoList" [paginator]="false" filterBy="Name, Category" layout="grid">
                <ng-template let-product pTemplate="listItem">
                    <div class="col-12">
                        <div class="p-grid p-shadow-2 product-list-item" style="margin: 15px;">
                            <div class="p-col-4 card-header">
                                <div class="card-title">
                                    <div pTooltip="{{product.Provider}}">
                                        <img src="assets/layout/images/connectors/{{product.Name}}.png"
                                            style="width:40px; height: 40px; margin-right: 5px;"
                                            [alt]=" product.Provider" />
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-8">
                                <div class="product-name">
                                    {{product.Name}}
                                </div>
                                <div>
                                    <p-badge value="{{product.Category}}">{{product.Category}}</p-badge>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template let-product pTemplate="gridItem">
    
                    <div class="p-col-12 p-md-4 p-lg-3" (click)="onDestinationSelected(product)">
                        <div class="p-grid connectorItem" [ngClass]="{'highlight': selectedTargetTypeInfo === product}"
                            style="margin: 10px;">
                            <div class="p-col-12">
                                <div class="card-title p-grid" pTooltip="{{product.Name}}">
                                    <div class="p-col-4">
                                        <img src="assets/layout/images/destinations/{{product.Name}}.png"
                                            style="width:50px; height: 50px; margin-bottom: 5px; margin-top: 5px"
                                            [alt]=" product.Provider" />
                                    </div>
                                    <div class="p-col-8">
                                        <p-badge value="{{product.Category}}">{{product.Category}}
                                        </p-badge><br>
                                        {{product.Name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-dataView>
    
            <p-scrollTop target="parent" styleClass="custom-scrolltop" [threshold]="100" icon="pi pi-arrow-up">
            </p-scrollTop>
        </p-scrollPanel>
        <span style="text-align: center;font: italic;margin-top:10px">
            <p>{{'You are missing a destination? ' | translate}}<a>{{'Request your custom destination' |
                    translate}}</a></p>
        </span>
    
    
    </div>
    
    <div class="p-col">
        <p-toolbar>
            <span class="p-input-icon-left mb-2 md:mb-0">
                <p *ngIf="selectedTargetTypeInfo">{{selectedTargetTypeInfo.Name}} Settings</p>
                <p *ngIf="!selectedTargetTypeInfo">No destination selected. Please select a destination</p>
            </span>
            <span class="p-input-icon-left mb-2 md:mb-0">
                <!-- <p-multiSelect id="CategoryInputtext" placeholder="{{'Category'}}" optionLabel="name" (onChange)="emitFilterChanged()" display="chip"></p-multiSelect> -->
                <button pButton icon="pi pi-info"> </button>
            </span>
        </p-toolbar>

        <div *ngIf="!selectedTargetTypeInfo" style="text-align: center; position:absolute;">
            <!-- <div class="card" style="background: linear-gradient(79deg, rgba(9,160,199,0.3) 0%, rgba(43,95,147,0.3) 51%) ">
                <div class="p-grid">
                    <img class="dsimg" [src]="'assets/layout/images/pages/asset-error.svg'" />
                    <p style="color: rgb(170, 170, 170);">No Target selected. Please select a Target first</p>
                </div>
            </div> -->
            <!-- <img class="dsimg" [src]="'assets/layout/images/pages/asset-error.svg'" />
            <p style="color: rgb(170, 170, 170);">No Target selected. Please select a Target first</p> -->
        </div>
    
    
    
        <div *ngIf="selectedTargetTypeInfo" [ngSwitch]="selectedTargetTypeInfo.Name" >
            <!-- <div *ngSwitchDefault style="text-align: center; top:50%; left:50%">
                <img class="dsimg" [src]="'assets/layout/images/pages/asset-error.svg'" />
                <p style="color: rgb(170, 170, 170);">This Target is not acquired for your account. If you want to
                    use this Target please contact us</p>
            </div> -->
            <div *ngSwitchCase="CSV_URL_ADAPTER" style="margin-top: 20px">
                <div>
                    <app-csv-uri [(viewModel)]="currentDestinationView"></app-csv-uri>
                </div>
            </div>
            <div *ngSwitchCase="POSTGRE_ADAPTER" style="margin-top: 20px">
                <div >
                    <app-postgre-sql-settings [(viewModel)]="currentDestinationView"></app-postgre-sql-settings>
                </div>
            </div>
            <div *ngSwitchCase="EXCEL_ADAPTER">
            </div>
    
        </div>
    
    
    </div>
</div>
