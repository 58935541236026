import { HttpErrorResponse } from "@angular/common/http";
import {
	ChangeDetectorRef,
	Component,
	Input,
	OnInit,
	ViewChild,
} from "@angular/core";
import { CsvUrlAccess } from "src/app/models/api/com/bion/csv/CsvUrlAccess";
import { CsvUrlMetaAccess } from "src/app/models/api/com/bion/csv/CsvUrlMetaAccess";
import { CsvUrlMetaInfo } from "src/app/models/api/com/bion/csv/CsvUrlMetaInfo";
import { ConnectorView } from "src/app/models/connectorView.model";
import {
	DataSourceAdapter,
} from "src/app/models/datasource.model";
import { Table } from "src/app/models/designer.models";
import { PlayErrorResponse } from "src/app/models/play.error.model";

@Component({
	selector: "app-csv-url-connector",
	templateUrl: "./csv-url-connector.component.html",
	styleUrls: ["./csv-url-connector.component.scss"],
})
export class CsvUrlConnectorComponent implements OnInit {
	selectedConnector: DataSourceAdapter;
	loading: boolean;
	@ViewChild("fileListTable") fileListTable: Table;
	@Input() currentConnectorView: ConnectorView<CsvUrlAccess, CsvUrlMetaInfo, CsvUrlMetaAccess>;

	fileListCols;
	selectedSourceTable;

	uploadFilesAsync(event) {
		this.currentConnectorView.fetchMetaDataObs().subscribe(
			(res) => {

				this.cd.detectChanges();

				this.loading = false;
			},
			(error: HttpErrorResponse) => {
				this.handleError(error);
				this.loading = false;
			}
		);
	}

	setUrl(event) {
		console.log(event);
		this.currentConnectorView.connectorSettings.Uri = event;
	}

	handleError(error: HttpErrorResponse) {
		const play_error: PlayErrorResponse = error.error;
		console.log(play_error);
	}

	constructor(private cd: ChangeDetectorRef) { }

	ngOnInit(): void {
	}
}
