import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import * as dss from 'src/app/models/datasource.model';

@Injectable({
    providedIn: 'root'
})
export class UtilFunctionsService {

    constructor(public sanitizer: DomSanitizer) { }

    public static createPsaDataModel(res: dss.DataTypePreview) {

        let data = [];
        for (let i = 0; i < res.Rows.length; i++) {
            let row = [{}];
            for (let j = 0; j < res.Columns.length; j++) {
                row[res.Columns[j]["Name"]] = res.Rows[i][j];
            }
            data.push(row);
        }

        return data;
    }

    /**
     * Converts bytes to a base 64 image.
     * CAUTION: This function may lead to a stack overflow on large arrays. In this case use convertInt8ArrayToBase64ImageIter.
     * @param bytes 
     * @returns 
     */
    static convertInt8ArrayToBase64Image(bytes: number[]): string {
        const content = new Int8Array(bytes);

        // https://stackoverflow.com/questions/9267899/arraybuffer-to-base64-encoded-string/11562550
        // TODO: Create Helper Function to convert Int8Array to Base64 Array

        const u_content = new Uint8Array(content);
        const s_content = String.fromCharCode(...u_content);
        const base64String = btoa(s_content);

        return base64String
    }

    static convertInt8ArrayToBase64ImageIter(bytes: number[]): string {

        let binary = '';
        const u_content = new Uint8Array(bytes);
        const len = u_content.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(u_content[i]);
        }
        return window.btoa(binary);

        // const content = new Int8Array(bytes);

        // https://stackoverflow.com/questions/9267899/arraybuffer-to-base64-encoded-string/11562550
        // TODO: Create Helper Function to convert Int8Array to Base64 Array



        // const u_content = new Uint8Array(content);
        // const s_content = String.fromCharCode(...u_content);
        // const base64String = btoa(s_content);

        // return base64String
    }

    createImageUrl(base64: string) {
        let objectURL = 'data:image/png;base64,' + base64;
        return this.sanitizer.bypassSecurityTrustUrl(objectURL);
    }

    int8ArrayToBase64Image(bytes: number[]): SafeUrl {
        // const content = new Int8Array(bytes);

        // // https://stackoverflow.com/questions/9267899/arraybuffer-to-base64-encoded-string/11562550
        // // TODO: Create Helper Function to convert Int8Array to Base64 Array

        // const u_content = new Uint8Array(content);
        // const s_content = String.fromCharCode(...u_content);
        // const base64String = btoa(s_content);

        const base64String = UtilFunctionsService.convertInt8ArrayToBase64ImageIter(bytes);

        let objectURL = 'data:image/png;base64,' + base64String;
        return this.sanitizer.bypassSecurityTrustUrl(objectURL);
    }
}
