import { Component, OnChanges, OnDestroy, SimpleChanges } from "@angular/core";
import { AppComponent } from "./app.component";
import { AppMainComponent } from "./app.main.component";
import { MenuItem } from "primeng/api";
import { UtilFunctionsService } from "./services/util-functions.service";
import { UserService } from "./services/user.service";
import { TasksService } from "./services/tasks.service";
import { TranslateService } from "@ngx-translate/core";
import { AuthorizationService } from "./services/authorization.service";
import { SubSink } from "subsink";
import { NavigationStart, Router } from "@angular/router";
import { WorkflowsService } from "./services/workflows.service";
import { SystemMessageLogService } from "./services/system-message-log.service";
import { Model } from "./models/api/models/license/model/Model";
import { forkJoin, throwError } from "rxjs";
import { ApiBackendService } from "./services/api-backend.service";
import { Limit } from "./services/user-subscription.service";


@Component({
	selector: "app-topbar",
    styleUrls: ["app.topbar.component.scss"],
	templateUrl: "./app.topbar.component.html"
})
export class AppTopBarComponent implements OnDestroy, OnChanges {
	items: MenuItem[];
    image: any;
	subs = new SubSink;
	selectedLanguage: string;

	currentLicense?: Model.Subscription;

	breadcrumbModel:MenuItem[] = [];
	breadcrumbHome: MenuItem;

	currentLimits: Limit[] = [];
	diffDays: number;
	constructor(public app: AppComponent, public appMain: AppMainComponent, public utilService: UtilFunctionsService, private userService: UserService, private workflowService: WorkflowsService,	public translate: TranslateService,private router: Router, private errorService:SystemMessageLogService, private bionApi: ApiBackendService ) {}

	ngOnInit() {
		this.setBreadcrumbModel();

		this.subs.sink = this.router.events.subscribe(event =>{
			if (event instanceof NavigationStart){
				console.log(event);
				let navEvent: NavigationStart = event;
				this.setBreadcrumbModel(navEvent.url);
			}
		 })
		 console.log(this.appMain.currentUserFull);
		if(this.appMain.currentUserFull) {

			this.subs.sink = this.userService.getUserDetailsRow(this.appMain.currentUserFull.UserInfo.ID).subscribe((userRow) => {

				if(userRow.length > 0) {
					this.image = this.utilService.int8ArrayToBase64Image(userRow[0].avatar);
				} else {
					this.image = undefined;
				}

			});
			this.currentLicense = this.appMain.currentUserFull.Licenses[0];
			this.initLimitStatus(this.appMain.currentUserFull.Licenses);

		}

		this.selectedLanguage = this.translate.getDefaultLang();
	}

	setBreadcrumbModel(url?: string) {
		this.breadcrumbHome = {icon: 'pi pi-home', routerLink: '/'};
		let initbeadCrump = [];

		if(url) {
			initbeadCrump.push(	{label:url.replace('/','').toUpperCase(), routerLink: url});
		} else {
			initbeadCrump.push(	{label:this.router.url.replace('/','').toUpperCase(), routerLink: this.router.url})
		}
		console.log(initbeadCrump);
		if(initbeadCrump[0].label.includes("DESIGNER")) {
			const wfId = parseInt(initbeadCrump[0].label.replace("DESIGNER/",""));
			console.log("wfId", wfId);

			if(isNaN(wfId)) {
				this.breadcrumbModel =initbeadCrump;
			} else {
				this.subs.sink = this.workflowService.getWorkflowObjectList(wfId).subscribe((wfs) => {
					let initbeadCrumpDesigner = [{label: "Designer", routerLink: '/designer'}];

					initbeadCrumpDesigner.push({label: wfs[0].name + " (ID: " + wfs[0].id +")", routerLink: ""})
					//initbeadCrump[1].label = wfs[0].name + " (ID: " + wfs[0].id +")";
					console.log(initbeadCrumpDesigner);
					this.breadcrumbModel = initbeadCrumpDesigner;
				});
			}

		} else {
			if(initbeadCrump[0].label == '/' || initbeadCrump[0].label == '') {
				this.breadcrumbModel = [{label: "Dashboard".toLocaleUpperCase(),routerLink: '/'}]
			} else {
				this.breadcrumbModel = initbeadCrump;
			}
		}
	}
	ngOnChanges(changes:SimpleChanges) {
		if(this.router.url) {
			this.setBreadcrumbModel();
		}
	}

	ngOnDestroy() {
		if(this.subs) {
			this.subs.unsubscribe();

		}
    }

	savingInProgress: boolean =true;

    switchLang(lang: string) {
		this.translate.use(lang);
	}

    openCommunityPage() {
		window.open(
            "https://bion-analytics.com/gruppen/", "_blank");
	}
	reportBug() {
		const subject = this.translate.instant("I want to report a bug");
		const bodyText = this.translate.instant("Hi Bion support, i want to report a bug. Here is a description and a screenshot of the bug:");
		const mailText = "mailto:support@bion-analytics.com?subject="+subject+"&body="+bodyText; // add the links to body
		window.location.href = mailText;
	}
	scheduleMeeting() {
		const baseLink = "https://calendly.com/bion-ckim/meet-me?month=";
		const currentDate = new Date(Date.now());
		const currentYear = currentDate.getFullYear;
		const currentMonth = currentDate.getMonth;

		const fullLink = baseLink+currentYear+"-"+currentMonth;
		window.open(
			fullLink, "_blank");


	}

	initLimitStatus(licenses: Model.Subscription[]) {
		const dsObs = this.bionApi.getDataSources();
		const wfObs = this.bionApi.getWorkflowObjectList();
		const destObs = this.bionApi.getDataStoreObjectList();
		const schedObs = this.bionApi.getScheduleActionPlan();

		forkJoin(dsObs,wfObs,destObs,schedObs).subscribe((result) => {

		  // Data
		  const ds = result[0];
		  const wf = result[1];
		  const dest = result[2];
		  const sched = result[3];

		  const dsCount = ds.length;
		  const wfCount = wf.length;
		  const destCount = dest.length;
		  const schedCount = sched.length;

		  if(!licenses || licenses.length === 0) {
			throwError(new Error("No licenses found"));
		  }
		  if(!licenses[0].Product || licenses[0].Product.length === 0) {
			throwError(new Error("No licenses found"));
		  }

		  const licenseParams = licenses[0].Product[0].MetaData.Data;
		  console.log(licenseParams);

		  const dsMax = parseInt(licenseParams['Datasources']);
		  const wfMax = parseInt(licenseParams['Workflows']);
		  const destMax = parseInt(licenseParams['Cubes']);
		  const schedMax = parseInt(licenseParams['Schedules']);

		  // Metrics
		  const dsPerc = dsCount / dsMax * 100;
		  const wfPerc = wfCount / wfMax* 100;
		  const destPerc = destCount / destMax* 100;
		  const schedPerc = schedCount / schedMax* 100;

		  const validFrom = new Date(licenses[0].ValidFrom);
		  const validTo = new Date(licenses[0].ValidTo);
		  const today = new Date(Date.now());

		  const diff = Math.abs(validTo.getTime() - today.getTime());
		  var diffDays = Math.ceil(diff / (1000 * 3600 * 24));

		  this.diffDays = diffDays;

		  // Final Objects
		  const dsLimit: Limit = {name: "Datasources", max: dsMax, current: dsCount, percentage: dsPerc};
		  const wfLimit: Limit = {name: "Workflows", max: wfMax, current: wfCount, percentage: wfPerc};
		  const destLimit: Limit = {name: "Cubes", max: destMax, current: destCount, percentage: destPerc};
		  const schedLimit: Limit = {name: "Schedules", max: schedMax, current: schedCount, percentage: schedPerc};

		  let limitArray: Limit[] = new Array();
		  limitArray.push(dsLimit,wfLimit,destLimit,schedLimit);


		  this.currentLimits = limitArray;
		  console.log(this.currentLimits);
		  return limitArray
		})
	  }

}
