import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { TreeNode } from "primeng/api";
import { Table } from "primeng/table";
import { forkJoin } from "rxjs";
import { Optionals } from "src/app/helper/optional";
import { BasicFrequency, CronFrequency } from "src/app/models/api/models/scheduler/CronFrequency";
import { BaseFrequencyActionEvent, BaseFrequencyDialogActionType } from "src/app/models/dialog-actions.model";
import {
	ScheduleActionPlan,
} from "src/app/models/schedule.model";
import { SchedulesService } from "src/app/services/schedules.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { SubSink } from "subsink";

@Component({
	selector: "app-schedule-frequency",
	templateUrl: "./schedule-frequency.component.html",
	styleUrls: ["./schedule-frequency.component.scss"],
})
export class ScheduleFrequencyComponent implements OnInit, OnDestroy {
	subs = new SubSink();
	buttonHidden: boolean = false;
	frequencies: BasicFrequency[] = [];
	frequenciesTree: TreeNode[] = [];
	selectedFrequency?: BasicFrequency;
	selectedSchedule?: ScheduleActionPlan;

	readonly WeekRhytm: string = "models.scheduler.WeekRhythm";
	readonly MonthRhytm: string = "models.scheduler.MonthRhythm";

	@ViewChild("dt") table!: Table;

	constructor(private scheduleService: SchedulesService, private errorService: SystemMessageLogService) { }
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngOnInit(): void {
		this.initView();
		this.subs.sink = this.scheduleService.selectedSchedulePlanEmitter.subscribe(
			(res: ScheduleActionPlan) => {

				if (res === this.selectedSchedule) {
					//this.buttonHidden = true;
					this.selectedSchedule = undefined;
				} else {
					//this.buttonHidden = false;
					this.selectedSchedule = res;
				}
				this.initView(this.selectedSchedule);
			},
			(err: Error) => {
				this.errorService.handleError(err);
			}
		);
		this.subs.sink = this.scheduleService.frequenciesChangedEmitter.subscribe(() => {
			this.initView(this.selectedSchedule);
		},
			(err: Error) => {
				this.errorService.handleError(err);
			});

	}

	initView(schedule?: ScheduleActionPlan) {

		let freqsObs = this.scheduleService.getScheduleFrequencies();
		let plansObs = this.scheduleService.getScheduleActionPlan();

		const finalobs = forkJoin(freqsObs, plansObs);
		this.subs.sink = finalobs.subscribe((res) => {

			let finalFrequencies: BasicFrequency[] = new Array();

			if (schedule !== undefined) {
				finalFrequencies = <BasicFrequency[]>res[0].filter((freq: CronFrequency) => freq.ActionPlan === schedule.id);
			} else {
				finalFrequencies = <BasicFrequency[]>res[0];
			}

			this.frequencies = finalFrequencies;

			const plans = schedule ? res[1].filter((plan) => plan.name === schedule.name) : res[1];

			const plansTreeNodes = plans
				.map((plan) => this.plansToTreeNodes(plan, finalFrequencies))
				.filter(planNode => Optionals.getOrElse(planNode.children, []).length != 0);
			//.filter(planNode => planNode.children?.length !== 0);

			this.frequenciesTree = plansTreeNodes;

		},
			(err: Error) => {
				this.errorService.handleError(err);
			});
	}

	plansToTreeNodes(plan: ScheduleActionPlan, frequencies: CronFrequency[]): TreeNode {
		let treeNode: TreeNode = { label: plan.name, data: plan };

		const relatedActions = frequencies.filter((freq) => freq.ActionPlan === plan.id);
		const relatedActionsTreeNodes = relatedActions.map((freq) => this.frequencyToTreeNode(freq)).sort(
			(objA, objB) => objA.data.index - objB.data.index
		);


		treeNode.children = relatedActionsTreeNodes;

		return treeNode

	}
	/**
	 * macht aus einer BaseAction ein TreeNode
	 */
	frequencyToTreeNode(freq: CronFrequency): TreeNode {
		let treeNode: TreeNode = { label: freq.Description, data: freq };

		return treeNode
	}

	displayNewFrequencyDialog() {
		//this.scheduleService.displayCreateFrequency.emit(true);
		this.scheduleService.baseFrequencyDialogActionSendEmitter.emit(new BaseFrequencyActionEvent(true, "Create frequency", BaseFrequencyDialogActionType.createFrequency, "Create", this.selectedFrequency));
	}
	displayDeleteFrequencyDialog() {
		//this.scheduleService.displayDeleteFrequency.emit(true);
		this.scheduleService.baseFrequencyDialogActionSendEmitter.emit(new BaseFrequencyActionEvent(true, "Delete frequency", BaseFrequencyDialogActionType.deleteFrequency, "Delete", this.selectedFrequency));

	}
	displayEditFrequencyDialog() {
		//this.scheduleService.displayEditFrequency.emit(true);
		this.scheduleService.baseFrequencyDialogActionSendEmitter.emit(new BaseFrequencyActionEvent(true, "Edit frequency", BaseFrequencyDialogActionType.editFrequency, "Save", this.selectedFrequency));

	}

	onMenuClicked(node: BasicFrequency) {
		console.log(node);
		this.selectedFrequency = node;
		//this.scheduleService.selectedMenuFrequencyEmitter.emit(node.data);
	}
}
