import { FieldInfo } from "./Workflow";

export interface NodeMetaData {
    FieldsInfo: Array<FieldInfo>;  // actually this is not quite correct, but much easier to use in TypeScript than in Scala.
}

export class MetaInfo implements NodeMetaData {
    FieldsInfo: Array<FieldInfo>;
    EdgeLabel?: string;
    constructor(FieldsInfo: Array<FieldInfo>,EdgeLabel?: string) {
        if(FieldsInfo === undefined) throw new Error( "Class 'MetaInfo': Required value 'FieldsInfo' is undefined" );
        this.FieldsInfo = FieldsInfo;
        this.EdgeLabel = EdgeLabel;
    }
}