import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { DataArtifact } from "src/app/models/designer.models";

@Component({
	selector: "app-workflow-artefact",
	templateUrl: "./workflow-artefact.component.html",
	styleUrls: ["./workflow-artefact.component.scss"],
})
export class WorkflowArtefactComponent implements OnInit {
	@Input() artifacts: DataArtifact[] = [];
	@Input() toggleView: boolean = true;
	@Input() artifactsMap: Array<[string, DataArtifact[]]> = [];
	chartData: any;
	chartOptions: any;

	constructor() { }

	ngOnChanges(changes: SimpleChanges) {

	}

	ngOnInit(): void { }

	downloadFileNew(rowData: DataArtifact) {
		const byte_array = rowData.Bytes;
		//const byteNumbers = new Array(byte_array.length);
		const u_int_array = new Uint8Array(byte_array);
		const byteBlob = new Blob([u_int_array]);

		const target_blob = byteBlob;

		const data = window.URL.createObjectURL(target_blob);

		const link = document.createElement("a");
		link.href = data;
		link.download = rowData.Name + "." + rowData.Extension;

		// this is necessary as link.click() does not work on the latest firefox
		link.dispatchEvent(
			new MouseEvent("click", {
				bubbles: true,
				cancelable: true,
				view: window,
			})
		);
	}
}