import { Component, OnDestroy, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { SubSink } from 'subsink';
import { SystemMessageLogService } from './services/system-message-log.service';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  menuMode = 'sidebar';
  //menuMode = 'horizontal';
  darkMode = 'light';
  topbarTheme = 'light';
  menuTheme = 'dark';
  inputStyle = 'outlined';
  ripple: boolean;

  subs = new SubSink;
  isLoggedIn: boolean;

  constructor(private primengConfig: PrimeNGConfig, public userService: UserService, private errorService: SystemMessageLogService) { }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }


  public ngOnInit() {
    this.primengConfig.ripple = true;
  }

}
