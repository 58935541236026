<form [formGroup]="form" [hidden]="hide_formly" (ngSubmit)="submit()" *ngIf="fields">
    <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
    <p-button type="submit" class="btn btn-primary submit-button" [disabled]="!form.valid">{{ 'Submit' | translate
        }}</p-button>
</form>

<p-button *ngIf="_oauth2Info" (click)="onConfigureOAuth()">{{ 'Fetch Refresh Token' | translate }}</p-button>

<app-oauth2-flow *ngIf="_oauth2Info" #oauth2Flow [authConfig]="oauth_config" (authInfo)="onAuthInfo($event)"
    [showDebugButtons]="false">

</app-oauth2-flow>