
import { EventEmitter } from '@angular/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { ApiBackendService } from '../services/api-backend.service';

/**
 * Connector View Interface.
 *
 * Dieses Interface abstrahiert über den Konnektor Typ und erlaubt es den Views unabhängig vom Typen
 * die Konfiguration zu erledigen.
 * @type T Settings Type
 * @type M Meta Data Type
 * @type MA Meta Access Type
 */
export interface ConnectorView<T, M, MA> {
    connectorSettings: T;
    metaInfo: M;
    metaAccess: MA;
    uploadedFile?: File;

    fetchMetaDataObs(): Observable<M>;
    getSettingsBuilder(): Observable<ConnectorSettingsBuilder<T>>;
    /**
     * Get the Preview Builder for this connector.
     * @param limit Preview Limit
     * @param offset Preview Offset
     */
    getPreviewBuilder(limit:number,offset:number): Observable<ConnectorSettingsBuilder<T>>;
    uploadFile(file: File): void;
    getDataSourceNameSuggestion(): string;
    getConnectorID(): string;
    getBase64FileData(): string;
    setInitialSettings(settings: T): void;
    setInitialMetaSettings(settings: MA): void;

    prüfeSettingsIntegrität(metaData:M, settings:T) : Observable<[M,T]>;

    /**
     * The connector is file based.
     */
    isFileBased(): boolean;

    /**
     * Erlaubt eine initialisierung des Views sofern notwendig.
     *
     * Beispielsweise kann beim Data Market das Data Set vorausgewählt werden.
     */
    initialize() : Observable<any>;

    // Event Section
    // File changed!
    newFileEventEmitter() : EventEmitter<File>;
}

/**
 * Connector View Base Implementations.
 */
export abstract class ConnectorViewBase<T, M, MA> implements ConnectorView<T, M, MA> {

    readonly api: ApiBackendService;
    readonly connectorID: string;
    connectorSettings: T;
    metaInfo: M;
    metaAccess: MA;
    uploadedFile?: File;

    //@Output() _newFileEventEmitter: EventEmitter<File> = new EventEmitter<File>();
    _newFileEventEmitter: EventEmitter<File> = new EventEmitter<File>();

    constructor(api: ApiBackendService, connectorID: string) {
        this.api = api;
        this.connectorID = connectorID;
    }
    newFileEventEmitter(): EventEmitter<File> {
        return this._newFileEventEmitter;
    }

    abstract fetchMetaDataObs(): Observable<M>;
    abstract getSettingsBuilder(): Observable<ConnectorSettingsBuilder<T>>;

    //abstract uploadFile(file: File): void;

    uploadFile(file: File): void {
        if(this.isFileBased()) {
            this.uploadedFile = file;
            this._newFileEventEmitter.emit(file);
        }
    }

    abstract getDataSourceNameSuggestion(): string;
    abstract isFileBased(): boolean;

    prüfeSettingsIntegrität(metaData:M, settings:T) : Observable<[M,T]> {
        return of([metaData,settings]);
    }

    /**
     * Get the Preview Builder for this Connector. If this method isn't overwridden, the settings builder is returned.
     * @param limit Preview Limit
     * @param offset Preview Offset
     */
    getPreviewBuilder(limit:number,offset:number): Observable<ConnectorSettingsBuilder<T>> {
        return this.getSettingsBuilder();
    }

    getConnectorID(): string {
        return this.connectorID;
    }

    abstract getBase64FileData(): string;

    setInitialSettings(settings: T): void {
        this.connectorSettings = settings;
    }
    setInitialMetaSettings(settings: MA): void {
        this.metaAccess = settings;
    }

    initialize() : Observable<any> {
        return of(0);
    }
}

/**
 * Creates Connector Settings for data extraction.
 * @type T Connector Settings Type
 */
export interface ConnectorSettingsBuilder<T> {
    getConnectorId(): string;
    getConnectorSettings(): T;
}

// /**
//  * Creates Connector Settings for data extraction with a limit
//  */
// export interface PreviewSettingsBuilder<T> {
//     getPreviewSettings(limit:number,offset:number) : T;
// }

/**
 * Provides base implementations for a ConnectorSettingsBuilder.
 */
export abstract class ConnectorSettingsBuilderBase<T> implements ConnectorSettingsBuilder<T> {

    constructor(connectorId: string, settings:T, base64FileData:string) {

        this.connectorId = connectorId;
        this.settings = settings;
        this.base64FileData = base64FileData;
    }
    readonly connectorId: string;
    readonly settings:T;
    readonly base64FileData: string;

    getConnectorId(): string {
        return this.connectorId;
    }
    getConnectorSettings() {
        return this.settings;
    }
}

/**
 * Ein Settings Builder für einfache Views.
 */
export class PureConnectorSettingsBuilder<T> implements ConnectorSettingsBuilder<T> {

    constructor(connectorId: string, settings:T) {

        this.connectorId = connectorId;
        this.settings = settings;
    }
    readonly connectorId: string;
    readonly settings:T;

    getConnectorId(): string {
        return this.connectorId;
    }
    getConnectorSettings() {
        return {...this.settings};
    }
}
