import { Observable, forkJoin } from "rxjs";
import { SafeObservableResult, SafeObservables } from "src/app/helper/observables";
import { PermissionBase } from "src/app/models/api/models/authorization/PermissionBase";


/**
 * Unterstützt die API-Calls um Permissions zu verwalten.
 * Die Klasse hat keine API-Abhängigkeiten sondern reduziert den Boilerplate Code für die echten API-Calls.
 */
export class PermissionOps {

    static readonly DELETE_OP = "Delete";
    static readonly UPDATE_OP = "Update";
    static readonly CREATE_OP = "Create";

    /**
     * Erstellt, ändert oder löscht die angebebenen Permissions, je nachdem wie deren Attribute sind.
     * Achtung: Diese Funktion ist eng mit den permission enrichment Klassen verbandelt.
     * Achtung: Funktionen die hier übergeben werden müssen an ihren Kontext gebunden werden, falls es sich um Methoden handelt, weil sonst der this pointer
     *          überschrieben wird.
     * @param to_create Permission zum Erstellen
     * @param to_update Permission zum Aktualisieren
     * @param to_delete Permission zum Löschen
     * @param cf 
     * @param uf 
     * @param df 
     * @returns 
     */
    static processPermissions<A, P extends PermissionBase<A>>(to_create: P[], to_update: P[], to_delete: P[],
        cf: (p: P) => Observable<P>,
        uf: (p: P) => Observable<number>,
        df: (p: P) => Observable<number>): Observable<SafeObservableResult<P, any>[]> {

        const delete_obs = to_delete.map(p => SafeObservables.recoverObs(df(p), p, this.DELETE_OP));
        const update_obs = to_update.map(p => SafeObservables.recoverObs(uf(p), p, this.UPDATE_OP));
        const create_obs = to_create.map(p => SafeObservables.recoverObs(cf(p), p, this.CREATE_OP));

        const all_obs: Observable<SafeObservableResult<P, any>>[] = [];

        delete_obs.forEach(e => all_obs.push(e));
        update_obs.forEach(e => all_obs.push(e));
        create_obs.forEach(e => all_obs.push(e));

        const forked = forkJoin(all_obs);

        return forked;
    }

    /**
     * Die Löschfunktionen unterscheiden sich leicht, das hier ist quasi eine Typfunktion, damit die restlichen Operationen gleich bleiben können.
     * @param fun Originale Löschfunktion
     * @param con Wandelt die Permission in den individuellen Löschparameter um (Contramap)
     * @returns Die angepasste Löschfunktion.
     */
    static mkDeleteOp<A, P extends PermissionBase<A>, B>(fun: (arg: B) => Observable<number>, con: (a: P) => B): (p: P) => Observable<number> {

        const result: (p: P) => Observable<number> = (perm) => {
            return fun(con(perm))
        }

        return result;
    }

}