<p-overlayPanel #op1 [showCloseIcon]="false">
    <ng-template pTemplate>
        <div style="float: left">
            <button pButton pRipple type="button" icon="pi pi-cog" class="p-button-rounded p-button-text p-mr-2 p-mb-2"
                pTooltip="{{'Roles.ChangeRole' | translate}}" (click)="displayEditRoleDialog()"></button>
            <button pButton pRipple type="button" icon="pi pi-trash" pTooltip="{{'Roles.DeleteRole' | translate}}"
                (click)="displayDeleteRoleDialog()"
                class="p-button-rounded p-button-danger p-button-text p-mr-2 p-mb-2"></button>
        </div>
    </ng-template>
</p-overlayPanel>

<p-overlayPanel #op2 [showCloseIcon]="false">
    <ng-template pTemplate>
        <div style="float: left">
            <button pButton pRipple type="button" icon="pi pi-cog" class="p-button-rounded p-button-text p-mr-2 p-mb-2"
                pTooltip="{{'Roles.ChangeRole' | translate}}" (click)="displayEditPermissionDialog()"></button>
            <button pButton pRipple type="button" icon="pi pi-trash" pTooltip="{{'Roles.DeleteRole' | translate}}"
                (click)="displayDeletePermissionDialog()"
                class="p-button-rounded p-button-danger p-button-text p-mr-2 p-mb-2"></button>
        </div>
    </ng-template>
</p-overlayPanel>


<div class="card" style="height: calc(83vh);">
    <div class="card-header">
        <div class="card-title">
            <h5>{{'Roles' | translate}}
            </h5>
        </div>
        <!-- <p class="subtitle">
            <button pButton pRipple type="button" icon="pi pi-user-plus" label="{{'Create' | translate}}"
                pTooltip="{{'Roles.CreateNewRole' | translate}}" (click)="displayNewRoleDialog()"
                class="p-button-rounded p-button p-button-text p-mr-2 p-mb-2"></button>
        </p> -->
    </div>

    <div class="chat">

        <p-tabView styleClass="tabview-custom" [(activeIndex)]="index">
            <p-tabPanel [selected]="true">
                <ng-template pTemplate="header">
                    <i class="pi pi-user"></i>
                    <span class="p-badge">{{'User' | translate}}</span>
                </ng-template>
                <p-table #dtuser [value]="userRolesView" [(selection)]="selectedUserRole"
                    (onRowSelect)="onSelectRole($event)" (onRowUnselect)="onUnselectRole($event)"
                    dataKey="RightHolder.ID" selectionMode="single" styleClass="p-datatable-customers p-datatable-sm"
                    [rows]="8" paginator=true [filterDelay]="0"
                    [globalFilterFields]="['RightHolder.ID','UserInfo.Username']">
                    <ng-template pTemplate="caption">
                        <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text"
                                    (input)="dtuser.filterGlobal($event.target.value, 'contains')"
                                    placeholder="{{'SearchForUser' | translate}}" />
                            </span>
                            <span class="p-input-icon-right">
                                <button pButton pRipple type="button" icon="pi pi-plus" label="{{'Add' | translate}}"
                                    pTooltip="{{'Add' | translate}}" (click)="displayNewRoleDialog()"
                                    class="p-button-rounded p-button p-button-text p-mr-2 p-mb-2"></button>
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="ID" style="width: 20%">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    {{'ID' | translate}}
                                    <p-columnFilter type="text" field="ID" display="menu" class="p-ml-auto">
                                    </p-columnFilter>
                                </div>
                            </th>
                            <th pSortableColumn="Name">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    {{'User' | translate}}
                                    <p-columnFilter type="text" field="RightHolder.ID" display="menu" class="p-ml-auto">
                                    </p-columnFilter>
                                </div>
                            </th>

                            <!-- <th style="width: 20%" pSortableColumn="action">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Type
                            <p-columnFilter type="text" field="action" display="menu" class="p-ml-auto">
                            </p-columnFilter>
                        </div>
                    </th> -->
                            <th style="width: 4rem"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-role>
                        <tr [pSelectableRow]="role">
                            <td>
                                <span class="p-column-title">{{'ID' | translate}}</span>
                                <span style="margin-right: 5px;">
                                    {{role.RightHolder.ID}}
                                </span>
                            </td>
                            <td>
                                <span class="p-column-title">{{'User' | translate}}</span>
                                {{role.userInfo.Username}}
                            </td>
                            <!-- <td>
                        <span class="p-column-title">Type</span>
                        <span [class]="'customer-badge status-' + role._type.Class">{{role._type.Name}}</span>
                    </td> -->
                            <td>
                                <div class="ui-table-buttongroup-right">
                                    <button pButton pRipple type="button" icon="pi pi-ellipsis-v"
                                        class="p-button-rounded p-button-text p-mr-2 p-mb-2"
                                        pTooltip="{{'ChangeRole' | translate}}" (click)="onMenuClicked(role)"
                                        (click)="op1.toggle($event)"></button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="3">{{'NoEntryFound' | translate}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-tabPanel>
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <i class="pi pi-users"></i>
                    <span class="p-badge">{{'Group' | translate}}</span>
                </ng-template>
                <p-table #dtgroup [value]="groupRoles" [(selection)]="selectedGroupRole"
                    (onRowSelect)="onSelectGroupRole($event)" (onRowUnselect)="onUnselectGroupRole($event)"
                    dataKey="RightHolder.ID" selectionMode="single" styleClass="p-datatable-customers p-datatable-sm"
                    [rows]="8" paginator=true [filterDelay]="0" [globalFilterFields]="['label','RightHolder.ID']">
                    <ng-template pTemplate="caption">
                        <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text"
                                    (input)="dtgroup.filterGlobal($event.target.value, 'contains')"
                                    placeholder="{{'SearchForGroup' | translate}}" />
                            </span>
                            <span class="p-input-icon-right">
                                <button pButton pRipple type="button" icon="pi pi-plus" label="{{'Add' | translate}}"
                                    pTooltip="{{'Add' | translate}}" (click)="displayNewRoleDialog()"
                                    class="p-button-rounded p-button p-button-text p-mr-2 p-mb-2"></button>
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="ID" style="width: 20%">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    {{'ID' | translate}}
                                    <p-columnFilter type="text" field="RightHolder.ID" display="menu" class="p-ml-auto">
                                    </p-columnFilter>
                                </div>
                            </th>
                            <th pSortableColumn="Name">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    {{'Group' | translate}}
                                    <p-columnFilter type="text" field="label" display="menu" class="p-ml-auto">
                                    </p-columnFilter>
                                </div>
                            </th>

                            <!-- <th style="width: 20%" pSortableColumn="action">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Type
                            <p-columnFilter type="text" field="action" display="menu" class="p-ml-auto">
                            </p-columnFilter>
                        </div>
                    </th> -->
                            <th style="width: 4rem"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-role>
                        <tr [pSelectableRow]="role">
                            <td>
                                <span class="p-column-title">{{'ID' | translate}}</span>
                                <span style="margin-right: 5px;">
                                    {{role.RightHolder.ID}}
                                </span>
                            </td>
                            <td>
                                <span class="p-column-title">{{'Group' | translate}}</span>
                                {{role.label}}
                            </td>
                            <!-- <td>
                        <span class="p-column-title">Type</span>
                        <span [class]="'customer-badge status-' + role._type.Class">{{role._type.Name}}</span>
                    </td> -->
                            <td>
                                <div class="ui-table-buttongroup-right">
                                    <button pButton pRipple type="button" icon="pi pi-ellipsis-v"
                                        class="p-button-rounded p-button-text p-mr-2 p-mb-2"
                                        pTooltip="{{'ChangeRole' | translate}}" (click)="onMenuGroupClicked(role)"
                                        (click)="op1.toggle($event)"></button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="3">{{'NoEntryFound' | translate}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-tabPanel>
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <i class="pi pi-th-large"></i>
                    <span class="p-badge">{{'View' | translate}}</span>
                </ng-template>

                <p-table #vpt [value]="genericPermissionView" styleClass="p-datatable-customers p-datatable-sm"
                    [rows]="8" dataKey="permission.ID" selectionMode="single" paginator=true [filterDelay]="0"
                    [(selection)]="selectedViewRole" (onRowSelect)="onSelectViewRole($event)"
                    (onRowUnselect)="onUnselectViewRole($event)"
                    [globalFilterFields]="['permission.ID','permission.Role','Accessible.Name','permission.IsOwner','permission.Rights']">
                    <ng-template pTemplate="caption">
                        <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text"
                                    (input)="vpt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="{{'Search' | translate}}" />
                            </span>
                            <span class="p-input-icon-right">
                                <!-- <button type="button" pButton pRipple (click)="displayNewPermissionDialog()">{{'Add' |
                                    translate}}</button> -->
                                <button pButton pRipple type="button" icon="pi pi-plus" label="{{'Add' | translate}}"
                                    pTooltip="{{'Add' | translate}}" (click)="displayNewPermissionDialog()"
                                    class="p-button-rounded p-button p-button-text p-mr-2 p-mb-2"></button>
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th colspan="2">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    {{'ID' | translate}}
                                    <!-- <p-columnFilter type="text" field="Accessible" display="menu" class="p-ml-auto">
                                </p-columnFilter> -->
                                    <p-sortIcon field="permission.permission.Role"></p-sortIcon>
                                </div>
                            </th>
                            <!-- <th colspan="2">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    {{'Type' | translate}}

                                </div>
                            </th> -->
                            <th colspan="2">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    {{'Role' | translate}}
                                    <p-columnFilter type="text" field="permission.roleName" display="menu"
                                        class="p-ml-auto">
                                    </p-columnFilter>
                                </div>
                            </th>

                            <th colspan="2">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    {{'View' | translate}}
                                    <p-sortIcon field="permission.accessibleName"></p-sortIcon>
                                </div>
                            </th>
                            <!-- <th colspan="1">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    {{'IsOwner' | translate}}
                                </div>
                            </th> -->
                            <th colspan="5">
                                <div class="p-d-flex p-jc-between p-ai-center" style="text-align: center;">
                                    {{'Rights' | translate}}
                                </div>
                            </th>
                            <th style="width: 4rem"></th>
                        </tr>
                        <tr>
                            <th colspan="6"></th>
                            <th *ngFor="let mode of rights" colspan="1" style="text-align: center;">{{mode}}</th>
                            <th style="width: 4rem"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-permission>
                        <tr [pSelectableRow]="permission">
                            <td colspan="2">
                                <span class="p-column-title">{{'ID' | translate}}</span>
                                {{permission.permission.Role}}
                            </td>
                            <!-- <td colspan="2">
                                <span class="p-column-title">{{'Type' | translate}}</span>
                                <p-dropdown [autoDisplayFirst]="false" placeholder="Select a role type"
                                    [disabled]="true" [(ngModel)]="permission.Role" [style]="{'width':'100%'}"
                                    [options]="permission.roleTypes" (onChange)="onSetRoleType($event, permission)">
                                </p-dropdown>
                            </td> -->
                            <td colspan="2">
                                <span class="p-column-title">{{'Role' | translate}}</span>
                                {{permission.roleName}}
                                <!-- <p-dropdown [autoDisplayFirst]="false" placeholder="Select a role" [disabled]="true"
                                    [(ngModel)]="permission.Role" [style]="{'width':'100%'}"
                                    [options]="permission.roleOptions" optionalValue="Rightholder.ID"></p-dropdown> -->
                            </td>
                            <td colspan="2">
                                <span class="p-column-title">{{'View' | translate}}</span>
                                <p-chip label="{{'Datasources' | translate}}" styleClass="mr-2" styleClass="custom-chip"
                                    icon="pi pi-table"
                                    *ngIf="permission.accessibleName === 'auth.staging.datasource.view'"></p-chip>
                                <p-chip label="{{'Workflows' | translate}}" styleClass="mr-2" styleClass="custom-chip"
                                    icon="pi pi-share-alt"
                                    *ngIf="permission.accessibleName === 'auth.staging.workflow.view'"></p-chip>
                                <p-chip label="{{'Cubes' | translate}}" styleClass="mr-2" styleClass="custom-chip"
                                    icon="pi pi-share-alt"
                                    *ngIf="permission.accessibleName === 'auth.staging.datastore.view'"></p-chip>
                                <p-chip label="{{'Scheduler' | translate}}" styleClass="mr-2" styleClass="custom-chip"
                                    icon="pi pi-share-alt"
                                    *ngIf="permission.accessibleName === 'auth.scheduler.actionPlan.view'"></p-chip>
                                <!-- {{permission.accessibleName}} -->
                                <!-- <p-dropdown [autoDisplayFirst]="false" placeholder="Select an accessible"
                                    [disabled]="true" [(ngModel)]="permission.Accessible" [style]="{'width':'100%'}"
                                    [options]="permission.accessibles" optionLabel="Name"></p-dropdown> -->
                            </td>
                            <!-- <td colspan="1" style="text-align: center;">
                                <p-checkbox [(ngModel)]="permission.isOwnerFlag" binary=true [disabled]="true">
                                </p-checkbox>
                            </td> -->
                            <td colspan="1" *ngFor="let right of rights" style="text-align: center;">
                                <p-checkbox name="checkboxGroup" value="right" [value]="right" [disabled]="true"
                                    [(ngModel)]="permission.permission.Rights" [inputId]="right"></p-checkbox>
                            </td>
                            <td>
                                <div class="ui-table-buttongroup-right">
                                    <button pButton pRipple type="button" icon="pi pi-ellipsis-v"
                                        class="p-button-rounded p-button-text p-mr-2 p-mb-2"
                                        pTooltip="{{'ChangeRole' | translate}}"
                                        (click)="onMenuViewRoleClicked(permission)"
                                        (click)="op2.toggle($event)"></button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="12">{{'NoEntryFound' | translate}}</td>
                        </tr>
                    </ng-template>
                </p-table>

            </p-tabPanel>
        </p-tabView>
    </div>

</div>
