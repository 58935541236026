import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { GraphTabView, GraphView } from '../sql-workflow-sketch';

@Component({
  selector: 'app-workflow-tab-view',
  templateUrl: './workflow-tab-view.component.html',
  styleUrls: ['./workflow-tab-view.component.scss']
})
export class WorkflowTabViewComponent implements OnInit, GraphTabView {

  constructor() { }
  addGraphView(view: GraphView): Observable<boolean> {
    throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
  }

  getGraphViews() : GraphView[] {
    throw new Error("Not Implemented")
  }
  setCurrentGraphView(view: GraphView): Observable<boolean>{
    throw new Error("Not Implemented")
  }

}
