import {
	trigger,
	state,
	style,
	transition,
	animate,
} from "@angular/animations";
import {
	Component,
	HostListener,
	Inject,
	OnDestroy,
	OnInit,
	ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { MenuItem, MessageService } from "primeng/api";
import { Observable, of, Subscription, throwError } from "rxjs";
import { WorkflowNodeGuiInfo, WorkflowNodeInfo } from "src/app/models/designer.models";
import { NodePlugInInfos } from "src/app/models/nodePlugIn.model";
// import { WorkflowRepositoryEntry } from "src/app/models/workflow.model";
import { DesignerEvent, DesignerService, DesignProgresSpinnerEvent } from "src/app/services/designer.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { SubSink } from "subsink";
import { WorkflowGraphComponent } from "./components/workflow-graph/workflow-graph.component";
import { DOCUMENT } from "@angular/common";
import { concatMap, map } from "rxjs/operators";
import { mxGraph } from "mxgraph";
import { WorkflowGraphEventType, IWorkflowGraphEventData, GraphViewLoadedData } from "./components/workflow-graph/workflow-graph-events";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { WorkflowRepositoryEntry } from "src/app/models/api/models/workflow/WorkflowRepositoryEntry";
import { NodeConfigComponent } from "./components/node-config/node-config.component";
import { Workflow } from "src/app/models/api/com/bion/etl/Workflow";
import { AppMainComponent } from "src/app/app.main.component";
import { WorkflowActionEvent, WorkflowDialogActionType, WorkflowActionEventStatus } from "src/app/models/dialog-actions.model";
import { WorkflowRunInfo } from "src/app/models/api/models/workflow/WorkflowRunInfo";
import { Simulation } from "src/app/models/api/models/workflow/RunWorkflowSettings.ns";
import { TranslateService } from "@ngx-translate/core";
import { NodesListComponent } from "../workflows/components/nodes-list/nodes-list.component";
import { SelectEvent } from "src/app/helper/events";

@Component({
	selector: "app-designer-view",
	templateUrl: "./designer-view.component.html",
	styleUrls: ["./designer-view.component.scss"],
	providers: [MessageService],
	animations: [
		trigger("fade", [
			state("void", style({ opacity: 0 })),
			transition(":enter", [animate(500)]),
			transition(":leave", [animate(500)]),
		]),
		trigger('slideLeft', [
			transition(':enter', [style({ width: 0 }), animate(500)]),
			transition(':leave', [animate(500, style({ width: 0 }))]),
		]),
		trigger('panelInOut', [
			transition('void => *', [
				style({ transform: 'translateY(-100%)' }),
				animate(800),
				transition(":enter", [animate(500)]),
				transition(":leave", [animate(500)]),
			]),
			transition('* => void', [
				animate(800, style({ transform: 'translateY(-100%)' }))
			])
		])
	],
})
export class DesignerViewComponent implements OnInit, OnDestroy {
	subs = new SubSink();
	loading: boolean = false;
	plugInResultSub?: Subscription;
	plugInInfos: WorkflowNodeGuiInfo[] = [];
	//visibleConfig;
	availableProducts: WorkflowNodeGuiInfo[] = [];
	selectedProducts: WorkflowNodeGuiInfo[] = [];
	draggedProduct?: WorkflowNodeGuiInfo;

	selectedWorkflow?: WorkflowRepositoryEntry;
	workflowName: string = "untitled";

	sender: string = "DesignerView";

	displayConfig: boolean = false;
	//display: boolean = false;
	items: MenuItem[] = [];
	dockBasicItems: MenuItem[] = [];

	displayDataPreview: boolean = false;

	selectedNode?: WorkflowNodeInfo;

	isDirtyFlag: boolean = false;
	isSimulationFlag: boolean = false;

	wizzardDialog: boolean = false;

	@ViewChild("workflowGraph") graph!: WorkflowGraphComponent;
	@ViewChild("WorkflowConfig") wfConfig!: NodeConfigComponent;
	@ViewChild("plugInList") plugInList!: NodesListComponent;


	constructor(
		private designerService: DesignerService,
		public appMain: AppMainComponent,
		private workflowService: WorkflowsService,
		private messageService: MessageService,
		private systemLogService: SystemMessageLogService,
		private router: Router,
		public translate: TranslateService,
		@Inject(DOCUMENT) document
	) { }
	ngOnDestroy(): void {
		this.appMain.onRightPanelClick(false);
		this.subs.unsubscribe();
	}
	@HostListener("window:scroll", ["$event"]) onWindowScroll(e) {

		const nav_bar_opt = document.getElementById("DesignerNavbar");

		if (window.pageYOffset > 50) {
			nav_bar_opt?.classList.add("sticky");
			nav_bar_opt?.classList.add("card");
		} else {
			nav_bar_opt?.classList.remove("sticky");
			nav_bar_opt?.classList.remove("card");
		}
	}

	ngOnInit(): void {

		this.subs.sink =
			this.designerService.designerProgressSpinnerEmitter.subscribe(
				(res: DesignProgresSpinnerEvent) => {
					this.loading = res.inProgress;
				}
			);
		this.subs.sink = this.workflowService.selectedWorkflowToOpenEmitter.subscribe((wf: WorkflowRepositoryEntry) => {
			console.log("selectedWorkflowToOpenEmitter");
			this.subs.sink = this.loadWorkflowIntoView(wf).subscribe(() => {

			});
		});

		this.subs.sink = this.workflowService.getNodePlugIns().subscribe((workflow_node_infos) => {
			console.log("getNodePlugIns");
			const rawGuiInfo = NodePlugInInfos.getNodeGuiInfo();
			const workflowNodeGuiInfo = NodePlugInInfos.getWorkflowNodeGuiInfo(
				workflow_node_infos,
				rawGuiInfo
			);
			this.plugInInfos = workflowNodeGuiInfo;
            this.plugInList.buildGui(this.plugInInfos);

			this.availableProducts = workflowNodeGuiInfo;

			// scan url and check if we need to open an existing workflow

			let workflow_to_open_try: number | undefined = undefined;
			const urls = this.router.url.split("/");
			if (urls.length > 0) {
				const url_index_str = urls[urls.length - 1];
				workflow_to_open_try = parseInt(url_index_str);
			}

			if (workflow_to_open_try === undefined || isNaN(workflow_to_open_try)) {
				console.log("Workflow undefined or NaN");
				//this.wizzardDialog = true;
				return;
			}

			const workflow_to_open: number = workflow_to_open_try;

			// -- Try open existing workflow

			const workflow_obs = this.workflowService.getWorkflowObjectList(workflow_to_open).pipe(concatMap(workflows => {
				if (workflows.length == 0)
					return throwError(new Error("The workflow with id" + workflow_to_open + " does not exist"));
				else
					return of(workflows[0]);
			}));

			const open_and_show = workflow_obs.pipe(concatMap(target_workflow => {
				this.selectedWorkflow = target_workflow;
				this.isSimulationFlag = target_workflow.runInfo.simulation.ReadOnly;
				this.maxRows = target_workflow.runInfo.simulation.MaxRows;
				if (this.graph) {
					const e_graph: WorkflowGraphComponent = this.graph;
					const graph_obs = e_graph.setWorkflow(target_workflow.workflowData).pipe(map(wf_graph => {
						const final_result: [WorkflowRepositoryEntry, mxGraph] = [target_workflow, wf_graph];
						return final_result;
					}));

					return graph_obs;
				} else {
					return throwError(new Error("The graph is undefined"));
				}
			}));

			this.subs.sink = open_and_show.subscribe(
				(result) => {
					this.addSuccessMessage(this.translate.instant("Workflow opened!"), this.translate.instant("Workflow with id") + result[0].id + this.translate.instant("was successfully opened"))
				},
				(error: Error) => {
					this.addErrorMessage(this.translate.instant("Workflow updated!"), this.translate.instant("Workflow with id") + workflow_to_open + this.translate.instant("could not be opened:") + error.message)
				});
		}

		);
		this.selectedNode = undefined;
		this.subs.sink = this.designerService.displayConfigEmitter.subscribe(
			(res) => {
				this.displayConfig = res;
				this.appMain.onRightPanelClick(res);
			}
		);
		this.subs.sink = this.designerService.workflowGraphEmitter.subscribe(
			(
				event: DesignerEvent<WorkflowGraphEventType, IWorkflowGraphEventData>
			) => {

				if (event.Type === WorkflowGraphEventType.NodeCellClicked) {
					//this.displayConfig = true;
					this.appMain.onRightPanelClick(true);
				}
				if (event.Type === WorkflowGraphEventType.GraphClicked) {
					//this.displayConfig = false;
					//this.appMain.onRightPanelClick(false);
				}
				if (event.Type === WorkflowGraphEventType.CellConnected) {
					//this.appMain.onRightPanelClick(false);
				}
				if (event.Type === WorkflowGraphEventType.PortCellClicked) {
					//this.appMain.onRightPanelClick(true);
				}
				if (event.Type === WorkflowGraphEventType.GraphViewLoaded) {
					this.onGraphViewLoaded(<GraphViewLoadedData>event.Data);
				}
			});

		this.subs.sink = this.workflowService.workflowDialogActionReceivedEmitter.subscribe((wfEvent) => {
			this.handleWorkflowDialogEvents(wfEvent)
		})
	}

	/**
	 * Handle Events received from Workflow Dialog
	 */
	handleWorkflowDialogEvents(wfEvent: WorkflowActionEvent) {
		// Alle Events müssen hier registriert werden
		if (wfEvent.actionType === WorkflowDialogActionType.createAndSaveWorkflow) {
			this.createAndSaveWorkflow(wfEvent)
		}
		if (wfEvent.actionType === WorkflowDialogActionType.createWorkflow) {
			this.createNewWorkflow(wfEvent)
		}
		if (wfEvent.actionType === WorkflowDialogActionType.saveWorkflow) {
			this.saveCurrentWorkflow(wfEvent)
		}
		if (wfEvent.actionType === WorkflowDialogActionType.saveAsWorkflow) {
			this.createAndSaveWorkflow(wfEvent)
		}
	}


	/**
	 * Erstellt einen neuen und leeren Workflow
	 */
	createNewWorkflow(wfEvent: WorkflowActionEvent) {

		const wf_obs = wfEvent.wf ? of(wfEvent.wf) : throwError(new Error("No workflow entry given"));

		const create_obs = wf_obs.pipe(concatMap(wf => this.workflowService.createWorkflowObject(wf)));

		this.subs.sink = create_obs
			.subscribe((res: WorkflowRepositoryEntry) => {
				this.messageService.add({
					severity: "success",
					summary: this.translate.instant("Message.CreateWorkflowSuccess.Title"),
					detail: this.translate.instant("Message.CreateWorkflowSuccess.Text1") + res.id +
						this.translate.instant("Message.CreateWorkflowSuccess.Text2"),
				});

				this.selectedWorkflow = { ...res };
				this.isSimulationFlag = res.runInfo.simulation.ReadOnly;
				this.maxRows = res.runInfo.simulation.MaxRows;

				this.workflowService.workflowsChangedEmitter.emit("New workflow created");
				this.workflowService.workflowDialogActionStatusEmitter.emit(new WorkflowActionEventStatus(true, wfEvent.actionType, true, false, res));
				//this.createWorkflowSuccess = true;
			}, (error: Error) => {
				this.systemLogService.handleError(error);

			});
	}

	/**
	 * Aktualisiert den bereits geöffneten Workflow
	 */
	saveCurrentWorkflow(wfEvent: WorkflowActionEvent) {

		const wf_obs = wfEvent.wf ? of(wfEvent.wf) : throwError(new Error("No workflow entry given"));
		const wf_ready_obs = wf_obs.pipe(map(wf => {
			const wfEntry: WorkflowRepositoryEntry = { ...wf };
			wfEntry.workflowData = this.graph.getWorkflow();
			return wfEntry;
		}))

		const update_obs: Observable<[WorkflowRepositoryEntry, number]> = wf_ready_obs
			.pipe(concatMap(wf => this.workflowService.updateWorkflowObject(wf)
				.pipe(map(count => {
					const result: [WorkflowRepositoryEntry, number] = [wf, count];
					return result;
				}))));

		this.subs.sink = update_obs.subscribe(
			(res: [WorkflowRepositoryEntry, number]) => {

				const wfEntry = res[0];

				this.messageService.add({
					severity: "success",
					summary: this.translate.instant("Message.UpdateWorkflowSuccess.Title"),
					detail: this.translate.instant("Message.UpdateWorkflowSuccess.Text1") + wfEntry.id +
						this.translate.instant("Message.UpdateWorkflowSuccess.Text2"),
				});
				this.workflowService.workflowsChangedEmitter.emit("workflow updated");
				this.workflowService.workflowDialogActionStatusEmitter.emit(new WorkflowActionEventStatus(false, wfEvent.actionType, true, false, wfEntry));

				//this.displayWorkflowDialog = false;
			},
			(error: Error) => {
				this.systemLogService.handleError(error);

			}
		);

	}

	/**
	 * Erstellt einen neuen und leeren Workflow
	*/
	createAndSaveWorkflow(wfEvent: WorkflowActionEvent) {

		const wf_obs = wfEvent.wf ? of(wfEvent.wf) : throwError(new Error("No workflow entry given"));
		const wf_ready_obs = wf_obs.pipe(map(wf => {
			const wfEntry = { ...wf };
			wfEntry.workflowData = this.graph.getWorkflow();
			return wfEntry;
		}))

		const create_obs = wf_ready_obs.pipe(concatMap(wf => this.workflowService.createWorkflowObject(wf)));

		this.subs.sink = create_obs
			.subscribe((wfEntry: WorkflowRepositoryEntry) => {
				this.messageService.add({
					severity: "success",
					summary: this.translate.instant("Message.CreateWorkflowSuccess.Title"),
					detail: this.translate.instant("Message.CreateWorkflowSuccess.Text1") + wfEntry.id +
						this.translate.instant("Message.CreateWorkflowSuccess.Text2"),
				});

				this.selectedWorkflow = { ...wfEntry };
				this.isSimulationFlag = wfEntry.runInfo.simulation.ReadOnly;
				this.maxRows = wfEntry.runInfo.simulation.MaxRows;

				this.workflowService.workflowsChangedEmitter.emit("New workflow created &  save current workflow");
				this.workflowService.workflowDialogActionStatusEmitter.emit(new WorkflowActionEventStatus(true, wfEvent.actionType, true, false, wfEntry));
			}, (error: Error) => {
				this.systemLogService.handleError(error);

			}, () => {
				//this.savingInProgress = false;
			});
	}

	/**
	 * Adds a success or info message to the message service.
	 * @param summary Short Text or Header
	 * @param detail Detailed Information and Recovery Tips.
	 */
	addSuccessMessage(summary: string, detail: string): void {
		this.messageService.add({
			severity: "success",
			summary: summary,
			detail: detail,
		});
	}

	/**
	 * Adds an error message to the message service.
	 * @param summary Short Text or Header
	 * @param detail Detailed Information and Recovery Tips.
	 */
	addErrorMessage(summary: string, detail: string) {
		this.messageService.add({
			severity: "warn",
			summary: summary,
			detail: detail,
		});
	}

	/**
	 * Asserts that the graph component is set otherwise throws an error.
	 * @param graph Maybe undefined Graph Component
	 * @returns Graph Component
	 */
	static assertGraph(graph?: WorkflowGraphComponent | undefined): WorkflowGraphComponent {
		if (graph) {
			return graph;
		} else {
			throw new Error("The Workflow Graph Component is undefined!");
		}
	}

	loadWorkflowIntoView(wf: WorkflowRepositoryEntry) {

		this.selectedWorkflow = wf;
		this.isSimulationFlag = wf.runInfo.simulation.ReadOnly;
		this.maxRows = wf.runInfo.simulation.MaxRows;

		const g = DesignerViewComponent.assertGraph(this.graph);

		return g.setWorkflow(wf.workflowData).pipe(map(sub_res => {
			let final_result: [WorkflowRepositoryEntry, mxGraph];
			final_result = [wf, sub_res];
			return final_result;
		}));
	}

	dragStart(event: DragEvent, product: WorkflowNodeGuiInfo) {
		this.draggedProduct = product;
	}

	drop(event: DragEvent) {

		if (this.draggedProduct) {
			let draggedProductIndex = this.findIndex(this.draggedProduct);
			this.selectedProducts = [...this.selectedProducts, this.draggedProduct];
			this.availableProducts = this.availableProducts.filter(
				(val, i) => i != draggedProductIndex
			);
			this.draggedProduct = undefined;
		}
	}

	dragEnd(event: DragEvent) {
		this.draggedProduct = undefined;
	}

	findIndex(product: WorkflowNodeGuiInfo) {
		let index = -1;
		for (let i = 0; i < this.availableProducts.length; i++) {
			if (product.Name === this.availableProducts[i].Name) {
				index = i;
				break;
			}
		}
		return index;
	}

	onChangeDirtyFlag(evt: boolean) {
		this.isDirtyFlag = evt;
	}

	onChangeWorkflowName() {
		if (this.selectedWorkflow) {
			let wf = { ...this.selectedWorkflow };
			let wfEvent = new WorkflowActionEvent(true, "Save current workflow", WorkflowDialogActionType.saveWorkflow, "Save", wf, wf.workflowData);
			this.saveCurrentWorkflow(wfEvent);
		} else {
			let wf = new WorkflowRepositoryEntry(-1, this.workflowName, "", "", "", new Workflow("", [], [], ""));
			let wfEvent = new WorkflowActionEvent(true, "Save new workflow", WorkflowDialogActionType.createAndSaveWorkflow, "Create", wf, wf.workflowData);
			this.createAndSaveWorkflow(wfEvent)

		}
	}

	IsSimulationFlag: boolean = false;
	maxRows?: number;

	/**
	 * Aktualisiert den bereits geöffneten Workflow.
	 * @returns
	 */
	onChangeRunMode() {

		if (!this.selectedWorkflow) {
			console.log("No workflow selected or this is a new workflow! Exit");
			return;
		}
		let wf_copy = { ...this.selectedWorkflow };
		if (!this.selectedWorkflow.runInfo) {
			console.log("No runInfo given, create new");
			const runInfo = new WorkflowRunInfo(new Simulation(this.IsSimulationFlag, this.maxRows));
			wf_copy.runInfo = runInfo;
		} else {
			wf_copy.runInfo.simulation.ReadOnly = this.IsSimulationFlag;
			wf_copy.runInfo.simulation.MaxRows = this.IsSimulationFlag ? this.maxRows : undefined;

		}

		let wfEvent = new WorkflowActionEvent(true, "Save current workflow", WorkflowDialogActionType.saveWorkflow, "Save", wf_copy, wf_copy.workflowData);

		const wf_obs = wfEvent.wf ? of(wfEvent.wf) : throwError(new Error("No workflow entry given"));
		const wf_ready_obs = wf_obs.pipe(map(wf => {
			const wfEntry: WorkflowRepositoryEntry = { ...wf };
			wfEntry.workflowData = this.graph.getWorkflow();
			return wfEntry;
		}))

		const update_obs: Observable<[WorkflowRepositoryEntry, number]> = wf_ready_obs
			.pipe(concatMap(wf => this.workflowService.updateWorkflowObject(wf)
				.pipe(map(count => {
					const result: [WorkflowRepositoryEntry, number] = [wf, count];
					return result;
				}))));

		this.subs.sink = update_obs.subscribe(
			(res: [WorkflowRepositoryEntry, number]) => {

				const wfEntry = res[0];

				this.messageService.add({
					severity: "success",
					summary: this.translate.instant("Message.UpdateWorkflowSuccess.Title"),
					detail: this.translate.instant("Message.UpdateWorkflowSuccess.Text1") + wfEntry.id +
						this.translate.instant("Message.UpdateWorkflowSuccess.Text2"),
				});
				this.workflowService.workflowsChangedEmitter.emit("workflow updated");
			},
			(error: Error) => {
				this.systemLogService.handleError(error);

			}
		);

	}

	/**
	 * Wird aufgerufen, sobald ein graph initialisiert wurde
	 */
	onGraphViewLoaded(event: GraphViewLoadedData) {

		const htmlEls: HTMLElement = this.plugInList.table.nativeElement;
		const elChildren = htmlEls.children;
		const elChildNodes = htmlEls.childNodes;

		this.graph.assignDragDrop(htmlEls.children, undefined)
	}


    toggleConfig() {
        if(this.appMain.rightPanelActive === true ) {
            this.appMain.rightPanelActive = false
        } else {
            this.appMain.rightPanelActive = true
        }
    }


	// Wizzard Handlers
	currentView: string = "Select";
	newWorkflow?: WorkflowRepositoryEntry;
	workflows: WorkflowRepositoryEntry[] = []
	wf_to_open?: WorkflowRepositoryEntry;
	backToBegin() {
		this.wf_to_open = undefined;
		this.newWorkflow = undefined;
		this.currentView = "Select";
	}
	onClickNewWorkflow() {
		this.newWorkflow = new WorkflowRepositoryEntry(-1,"",this.appMain.currentUserFull.UserInfo.Username,new Date().toISOString(),"", new Workflow("",[],[],""),"",new WorkflowRunInfo(new Simulation(false)));
		this.currentView = "New"
	}
	onCreateNewWorkflow() {
		const new_wf = this.newWorkflow;

		this.subs.sink = this.workflowService.createWorkflowObject(new_wf).subscribe((wf) =>{
			// Lade den Workflow in mxGraph
			const wf_id = wf.id.toString();
			this.router.navigate(['/', 'designer', wf_id]);
		},(err) => this.systemLogService.handleError(err))

	}
	onClickOpenWorkflow() {
		this.workflowService.getWorkflowObjectList().subscribe((wfs) => {
			this.workflows = wfs
			this.currentView = "Open"
		},(err) => this.systemLogService.handleError(err))
	}
	onSelectWorkflow(evt: SelectEvent<any,WorkflowRepositoryEntry>) {
		let wf_to_open = <WorkflowRepositoryEntry>evt.data;
		this.wf_to_open = wf_to_open;
	}
	onUnselectWorkflow(evt: SelectEvent<any,WorkflowRepositoryEntry>) {
		this.wf_to_open = undefined;
	}
	openWorkflow() {
		console.log("Open Workflow from Dialog");
		// route to designer view
		this.router.navigate(['/', 'designer', this.wf_to_open?.id]);

		// emit selected id
		//this.workflowService.selectedWorkflowToOpenEmitter.emit(this.wf_to_open);
	}
	backToHome() {
		this.router.navigate(["/"]);
	}

}
