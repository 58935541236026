<p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>

<p-dialog header="{{'Delete' | translate}}" [(visible)]="displayDeleteDatasource" modal="modal" showEffect="fade"
    [style]="{width: '600px'}">
    <div style="line-height: 1.5">
        {{'DeleteDatasource?' | translate}}
    </div>
    <app-system-message-log></app-system-message-log>

    <p-footer>
        <button pButton pRipple type="button" icon="pi pi-times" (click)="displayDeleteDatasource=false"
            class="p-button-rounded p-button-text  p-button-outlined p-mr-2 p-mb-2" label="{{'No' | translate}}"></button>
        <button pButton pRipple type="button" icon="pi pi-check" (click)="deleteDatasource(currentSelectedDatasource.id)"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2" label="{{'Yes' | translate}}"
            [icon]="savingInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
            [disabled]="buttonDisabled"></button>
    </p-footer>
</p-dialog>
