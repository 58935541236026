

<div style="position:absolute; bottom:0;left:0; width: calc(100% - 63px);margin-left: 63px;">
    <p-toolbar *ngIf="currentView === 'Datasources'" >
        <button [disabled]="!selectedObj" (click)="displayPsaDialog()" pButton label="{{'ViewData' | translate}}"></button>
        <span style="display: flex; align-items: center;">
            <!-- <span style="margin-right:10px;color: rgb(61, 150, 183)">Relationship</span> -->
            <span style="margin-right:10px">
                <button (click)="displayRelatedObjects()" pTooltip="{{'Related workflow' | translate}}" class="p-button-text" pButton icon="pi pi-share-alt" [label]="objectRelations.ScheduleActionPlans.length"></button>
            </span>
            <span style="margin-right:10px">
                <button (click)="displayRelatedObjects()" pTooltip="{{'Related schedule' | translate}}"class="p-button-text" pButton icon="pi pi-calendar" [label]="objectRelations.Workflows.length"></button>
            </span>
            <!-- <span style="color: rgb(61, 150, 183);margin-right: 15px">Related to <i class="pi pi-share-alt" style="margin-right: 5px;margin-left: 10px"></i>{{objectRelations[0].length}}<span style="margin-right: 5px;margin-left:5px">|</span><i class="pi pi-calendar" style="margin-right: 5px;"></i> {{objectRelations[1].length}} </span> -->
            <i class="pi pi-check-circle"  *ngIf="objectRelations.Matches[0] === true" style="font-size: 24px; color:rgb(109, 195, 112); float: right"></i>
            <i class="pi pi-times-circle"  *ngIf="objectRelations.Matches[0] === false" style="font-size: 24px; color:rgb(195, 109, 109); float: right"></i>
        </span>
    </p-toolbar>
    <p-toolbar *ngIf="currentView === 'Workflows'">
        <button (click)="routeToUrl('/designer')" pButton label="{{'OpenDesigner' | translate}}"></button>
    
        <span style="display: flex; align-items: center;">
            <span style="margin-right:10px">
                <button (click)="displayRelatedObjects()" pTooltip="{{'Related datasource' | translate}}" class="p-button-text" pButton icon="pi pi-table" [label]="objectRelations.PsaInNodes.length"></button>
            </span>
            <span style="margin-right:10px">
                <button (click)="displayRelatedObjects()" pTooltip="{{'Related destination' | translate}}"class="p-button-text" pButton icon="pi pi-th-large" [label]="objectRelations.DataStoreOutNodes.length"></button>
            </span>
            <span style="margin-right:10px">
                <button (click)="displayRelatedObjects()" pTooltip="{{'Related schedule' | translate}}"class="p-button-text" pButton icon="pi pi-calendar" [label]="objectRelations.ScheduleActionPlans.length"></button>
            </span>
            <!-- <span style="color: rgb(61, 150, 183);margin-right: 15px"><i class="pi pi-table" style="margin-right: 5px;"></i> Related to {{objectRelations[0].length}} datasources | <i class="pi pi-microsoft" style="margin-right: 5px;"></i> Related to {{objectRelations[1].length}} destinations<span style="margin-right: 5px;margin-left:5px">|</span><i class="pi pi-calendar"  style="margin-right: 5px;"></i> Planned in {{objectRelations[2].length}} schedules </span> -->
            <i class="pi pi-check-circle"  *ngIf="objectRelations.Matches[0] === true" style="font-size: 24px; color:rgb(109, 195, 112); float: right"></i>
            <i class="pi pi-times-circle"  *ngIf="objectRelations.Matches[0] === false" style="font-size: 24px; color:rgb(195, 109, 109); float: right"></i>
        </span>
    </p-toolbar>
    <p-toolbar *ngIf="currentView === 'Destinations'">
        <button [disabled]="!selectedObj" (click)="displayPsaDialog()" pButton label="{{'ViewData' | translate}}"></button>
    
        <span style="display: flex; align-items: center;">
            <span style="margin-right:10px">
                <button (click)="displayRelatedObjects()" pTooltip="{{'Related datasource' | translate}}" class="p-button-text" pButton icon="pi pi-table" [label]="objectRelations.WorkflowNodes.length"></button>
            </span>
            <span style="margin-right:10px">
                <button (click)="displayRelatedObjects()" pTooltip="{{'Related workflow' | translate}}"class="p-button-text" pButton icon="pi pi-share-alt" [label]="objectRelations.Workflows.length"></button>
            </span>
            <span style="margin-right:10px">
                <button (click)="displayRelatedObjects()" pTooltip="{{'Related schedule' | translate}}"class="p-button-text" pButton icon="pi pi-calendar" [label]="objectRelations.ScheduleActionPlans.length"></button>
            </span>
            <!-- <span style="color: rgb(61, 150, 183);margin-right: 15px"><i class="pi pi-table" style="margin-right: 5px;"></i> Related to {{objectRelations[0].length}} datasources<span style="margin-right: 5px;margin-left:5px">|</span><i class="pi pi-share-alt"  style="margin-right: 5px;"></i> Considered in {{objectRelations[1].length}} workflows<span style="margin-right: 5px;margin-left:5px">|</span><i class="pi pi-calendar"  style="margin-right: 5px;"></i> Planned in {{objectRelations[2].length}} schedules </span> -->
            <i class="pi pi-check-circle"  *ngIf="objectRelations.Matches[0] === true" style="font-size: 24px; color:rgb(109, 195, 112); float: right"></i>
            <i class="pi pi-times-circle"  *ngIf="objectRelations.Matches[0] === false" style="font-size: 24px; color:rgb(195, 109, 109); float: right"></i>
        </span>
    </p-toolbar>
    
    <p-toolbar *ngIf="currentView === 'Schedules'" >
        <button  (click)="routeToUrl('/planner')" pButton label="{{'OpenScheduler' | translate}}"></button>

        <span style="display: flex; align-items: center;">
            <span style="margin-right:10px">
                <button (click)="displayRelatedObjects()" pTooltip="{{'Related datasource' | translate}}" class="p-button-text" pButton icon="pi pi-table" [label]="objectRelations.DataSources.length"></button>
            </span>
            <span style="margin-right:10px">
                <button (click)="displayRelatedObjects()" pTooltip="{{'Related workflow' | translate}}"class="p-button-text" pButton icon="pi pi-share-alt" [label]="objectRelations.Workflows.length"></button>
            </span>
            <span style="margin-right:10px">
                <button (click)="displayRelatedObjects()" pTooltip="{{'Related destination' | translate}}"class="p-button-text" pButton icon="pi pi-th-large" [label]="objectRelations.DataStores.length"></button>
            </span>
            <!-- <span style="color: rgb(61, 150, 183);margin-right: 15px"><i class="pi pi-table" style="margin-right: 5px;"></i> Related to {{objectRelations[0].length}} datasources<span style="margin-right: 5px;margin-left:5px">|</span><i class="pi pi-share-alt"  style="margin-right: 5px;"></i> Considered in {{objectRelations[1].length}}  workflows<span style="margin-right: 5px;margin-left:5px">|</span><i class="pi pi-calendar"  style="margin-right: 5px;"></i> Planned in {{objectRelations[2].length}} destinations </span> -->
            <i class="pi pi-check-circle"  *ngIf="objectRelations.Matches[0] === true" style="font-size: 24px; color:rgb(109, 195, 112); float: right"></i>
            <i class="pi pi-times-circle"  *ngIf="objectRelations.Matches[0] === false" style="font-size: 24px; color:rgb(195, 109, 109); float: right"></i>
        </span>
    </p-toolbar>
</div>
