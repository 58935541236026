import { OAuthLogger } from "angular-oauth2-oidc";

/**
 * Schnelle Implementierung für OAuthLogger.
 */
export class CustomAuthLogger implements OAuthLogger {
    debug(message?: any, ...optionalParams: any[]): void {
      // Implementiere deine eigene Logik für Debug-Meldungen hier
    }
  
    info(message?: any, ...optionalParams: any[]): void {
      // Implementiere deine eigene Logik für Info-Meldungen hier
    }
  
    log(message?: any, ...optionalParams: any[]): void {
      // Implementiere deine eigene Logik für allgemeine Log-Meldungen hier
    }
  
    warn(message?: any, ...optionalParams: any[]): void {
      // Implementiere deine eigene Logik für Warnungen hier
    }
  
    error(message?: any, ...optionalParams: any[]): void {
      // Implementiere deine eigene Logik für Fehlermeldungen hier
    }
  }