<p>create-ab-connection works!</p>
<p-progressBar [mode]="progressMode" [style]="{'height': '6px'}"></p-progressBar>
<p id="ErrorInfo"></p>
<div class="container">

    <div #Connection>
        <input #ConnectionName [(ngModel)]="connection_name" />
    </div>

    <div #StreamsToSync>
        <!-- <p-table [value]="streams" [(selection)]="selectedStreams" dataKey="code" -->
        <p-table [value]="realStreams" [(selection)]="realSelectedStreams" dataKey="stream.name"
            [tableStyle]="{'min-width': '50rem'}">
            <ng-template pTemplate="header">
                <!-- <tr>
                    <th style="width: 4rem"> <p-tableHeaderCheckbox></p-tableHeaderCheckbox> </th>
                    <th>Namespace</th>
                    <th>Stream name</th>
                    <th>Sync Mode</th>
                    <th>Quantity</th>
                </tr> -->
                <tr>
                    <th style="width: 4rem"> <p-tableHeaderCheckbox></p-tableHeaderCheckbox> </th>
                    <th>Namespace</th>
                    <th>Stream name</th>
                    <th>Sync Mode</th>
                    <th>Quantity</th>
                </tr>
            </ng-template> <ng-template pTemplate="body" let-product>
                <!-- <tr>
                    <td> <p-tableCheckbox [value]="product"></p-tableCheckbox> </td>
                    <td>{{product.code}}</td>
                    <td>{{product.name}}</td>
                    <td>{{product.category}}</td>
                    <td>{{product.quantity}}</td>
                </tr> -->
                <tr>
                    <td> <p-tableCheckbox [value]="product"></p-tableCheckbox> </td>
                    <td>{{product.stream.namespace}}</td>
                    <td>{{product.stream.name}}</td>
                    <td>{{product.stream.supportedSyncModes[0]}}</td>
                    <td>{{product.config.selected}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div>
        <button pButton (click)="onCreate()">Create</button>
    </div>

</div>
