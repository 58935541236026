<div class="layout-topbar">
    <div class="layout-topbar-wrapper">
        <div class="layout-topbar-left">
            <a tabindex="0" class="menu-button" (click)="appMain.onMenuButtonClick($event)">
                <i class="pi pi-bars"></i>
            </a>
            <a tabindex="0" id="logo-link" class="layout-topbar-logo" (click)="appMain.onMenuButtonClick($event)">
                <img [src]="'assets/layout/images/logo-' + (app.topbarTheme === 'dark' ? 'bion-new' : 'bion-new') + '.png'"
                    alt="freya-layout" />
            </a>
        </div>

        <app-menu></app-menu>

        <!-- <span style="margin-left: 38px"><p-breadcrumb [style]="{'border': '0', 'font-weight':'bold'}" [model]="breadcrumbModel" [home]="breadcrumbHome"></p-breadcrumb></span> -->
        <div class="layout-topbar-right">
            <ul class="layout-topbar-actions">



                <!-- <li pTooltip="{{ 'SearchMarketplace' | translate}}" tooltipPosition="bottom"  #searchTemplate class="topbar-item search-item" [ngClass]="{'active-topmenuitem': appMain.search}">
                    <a tabindex="0" (click)="appMain.onTopbarItemClick($event,searchTemplate)">
                        <i class="topbar-icon pi pi-images"></i>
                    </a>

                    <div class="search-input-wrapper">
                        <span class="p-input-icon-left">
                            <i class="pi pi-images"></i>
                            <input type="text" pInputText placeholder="Search marketplace..."
                                (click)="appMain.searchClick = true;" />
                        </span>
                    </div>

                    <ul class="fadeInDown">
                        <div class="search-input-wrapper p-fluid" style="width: 100%">
                            <span class="p-input-icon-left">
                                <i class="pi pi-images"></i>
                                <input type="text" pInputText placeholder="Search marketplace..."
                                    (click)="appMain.searchClick = true;" />
                            </span>
                        </div>
                    </ul>
                </li> -->
                <!-- <li pTooltip="{{ 'Marketplace' | translate}}" tooltipPosition="bottom" #searchWidgetsItem
                    class="topbar-item search-item">
                    <a tabindex="0" [routerLink]="['/datamarket']">
                        <i class="topbar-icon pi pi-shopping-cart"></i>
                    </a>

                    <div class="search-input-wrapper">
                        <span class="p-input-icon-left">
                            <i class="pi pi-shopping-cart
                        "></i>
                        </span>
                    </div>

                    <ul class="fadeInDown">
                        <div class="search-input-wrapper p-fluid" style="width: 100%">
                            <span class="p-input-icon-left">
                                <i class="pi pi-settings"></i>
                            </span>
                        </div>
                    </ul>
                </li> -->
                <!-- <li pTooltip="{{ 'Guide' | translate}}" tooltipPosition="bottom"  #searchWidgetsItem class="topbar-item search-item"
                    >
                    <a tabindex="0" [routerLink]="" (click)="initGuide()">
                        <i class="topbar-icon pi pi-question"></i>
                    </a>

                    <div class="search-input-wrapper">
                        <span class="p-input-icon-left">
                            <i class="pi pi-cog
                            "></i>
                        </span>
                    </div>

                    <ul class="fadeInDown">
                        <div class="search-input-wrapper p-fluid" style="width: 100%">
                            <span class="p-input-icon-left">
                                <i class="pi pi-settings"></i>
                            </span>
                        </div>
                    </ul>
                </li> -->
                <!-- <li pTooltip="{{ 'Tasks' | translate}}" tooltipPosition="bottom" #searchWidgetsItem
                    class="topbar-item search-item">
                    <a tabindex="0" [routerLink]="[]" (click)="opTasks.toggle($event)">
                        <i class="topbar-icon pi pi-cog"></i>
                    </a>

                    <div class="search-input-wrapper">
                        <span class="p-input-icon-left">
                            <i [class]="savingInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-cog'"></i>
                        </span>
                    </div>

                    <ul class="fadeInDown">
                        <div class="search-input-wrapper p-fluid" style="width: 100%">
                            <span class="p-input-icon-left">
                                <i [class]="savingInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-settings'"></i>
                            </span>
                        </div>
                    </ul>
                </li> -->
                <!-- <p-divider layout="vertical"></p-divider> -->


                <li #profile class="topbar-item user-profile"
                    [ngClass]="{'active-topmenuitem fadeInDown': appMain.activeTopbarItem === profile}">

                    <a [routerLink]="[]" (click)="op.toggle($event)" class="profileIcon">
                        <img *ngIf="image !== undefined" [src]='image' alt="freya-layout"
                            style="width: 40px;height:40px" />
                        <img *ngIf="image === undefined" src="assets/layout/images/avatar-placeholder.png"
                            style="width: 40px;height:40px" alt="freya-layout" />
                        <i class="pi pi-arrow"></i>
                    </a>

                </li>

            </ul>
        </div>
    </div>
</div>
<p-overlayPanel #op >
    <ng-template pTemplate style="background-color: #F9F5FF;">
        <div class="p-grid" style="width: 35rem">

            <!-- <div class="p-col-12"
                style="text-align: left;color: #7F56D9;"
                *ngIf="appMain.currentUserFull.UserInfo">
                <p>
                    {{'Signed in as' | translate}}

                    <span style=" font-weight: bold;">{{appMain.currentUserFull.UserInfo.EMail}}</span>


                    <p-dropdown style="float:right" inputId="languageInput" [options]="translate.getLangs()"
                        [(ngModel)]="selectedLanguage" placeholder="{{'SelectLanguage' | translate}}"
                        (onChange)="switchLang(selectedLanguage)">
                        <ng-template pTemplate="selectedItem">
                            <div class="country-item country-item-value" *ngIf="selectedLanguage">
                                <img src="assets/demo/flags/flag_placeholder.png" [width]="5"
                                    [class]="'flag flag-' + selectedLanguage.toLowerCase()" />
                            </div>
                        </ng-template>
                        <ng-template let-country pTemplate="item">
                            <div class="country-item">
                                <img src="assets/demo/flags/flag_placeholder.png"
                                    [class]="'flag flag-' + country.toLowerCase()" />
                                <span style="margin-left: 10px">{{country}}</span>
                            </div>
                        </ng-template>
                    </p-dropdown>

                </p>

            </div> -->
            <!-- <div class="p-col-12"
                style="text-align: left;color: #7F56D9;font-weight: bold;">
                Menu
            </div> -->

            <div class="p-col-12 p-grid" style="display: flex;align-items: center;">
                <div class="p-col-6">
                    <span>{{'Signed in as' | translate}}</span>
                </div>
                <div class="p-col">
                    <span *ngIf="appMain.currentUserFull.UserInfo" style="font-weight: bold;">{{appMain.currentUserFull.UserInfo.EMail}}</span>
                </div>
            </div>


            <div class="p-col-12 p-grid" style="display: flex;align-items: center;">
                <div class="p-col-6">
                    <span>{{'Language' | translate}}</span>
                </div>
                <div class="p-col">
                    <p-dropdown style="float:right" inputId="languageInput" [options]="translate.getLangs()"
                    [(ngModel)]="selectedLanguage" placeholder="{{'SelectLanguage' | translate}}"
                    (onChange)="switchLang(selectedLanguage)">
                    <ng-template pTemplate="selectedItem">
                        <div class="country-item country-item-value" *ngIf="selectedLanguage">
                            <img src="assets/demo/flags/flag_placeholder.png" [width]="5"
                                [class]="'flag flag-' + selectedLanguage.toLowerCase()" />
                        </div>
                    </ng-template>
                    <ng-template let-country pTemplate="item">
                        <div class="country-item">
                            <img src="assets/demo/flags/flag_placeholder.png"
                                [class]="'flag flag-' + country.toLowerCase()" />
                            <span style="margin-left: 10px">{{country}}</span>
                        </div>
                    </ng-template>
                </p-dropdown>
                </div>
            </div>



            <div class="p-col-12 p-grid" style="text-align: left;">
                <span class="p-col-6">
                    <button pButton class="p-button-rounded p-button p-button-text" [routerLink]="'/profile'"><i
                            class="pi pi-user" style="margin-right: 5px;"></i>{{'MyProfile' | translate}}</button>
                    <button *ngIf="appMain.currentUserFull.UserInfo.IsAdmin === true" [routerLink]="['/personal/users']"
                        pButton class="p-button-rounded p-button p-button-text"><i class="pi pi-users"
                            style="margin-right: 5px;"></i>{{ 'UserManagement' | translate}}</button><br>
                    <!-- <button *ngIf="appMain.currentUserFull.UserInfo.IsAdmin === false" [routerLink]="['/tutorial']"
                        pButton class="p-button-rounded p-button p-button-text"><i class="pi pi-book"
                            style="margin-right: 5px;"></i>{{ 'Documentation' | translate}}</button><br> -->
                    <button *ngIf="appMain.currentUserFull.UserInfo.IsAdmin === true" (click)="openCommunityPage()"
                        pButton class="p-button-rounded p-button p-button-text"><i class="pi pi-globe"
                            style="margin-right: 5px;"></i>{{ 'Community' | translate}}</button><br>

                </span>
                <span class="p-col-6">
                    <button (click)="reportBug()" pButton class="p-button-rounded p-button p-button-text">{{
                        'ReportABug' | translate}}</button><br>
                    <button (click)="scheduleMeeting()" pButton class="p-button-rounded p-button p-button-text">{{
                        'ScheduleAppointmentWithUs' | translate}}</button>

                </span>
            </div>

            <!-- <div class="p-col-12"> <span style="color:rgb(161, 161, 161); font-weight: bold">{{'License' |
                    translate}}</span> </div>

            <div class="content_block p-col-12" *ngIf="currentLimits.length > 0">
                <span class="p-grid">
                    <div class="p-col-4">
                        {{'LicenseName' | translate}}
                    </div>
                    <div class="p-col-8" style="text-align: right;">
                        <p-badge value="{{appMain.currentUserFull.Licenses[0].Product[0].Name | translate}}"
                            severity="warning"></p-badge>
                    </div>
                </span>
                <span class="p-grid" *ngFor="let limit of currentLimits">
                    <div class="p-col-4">
                        {{limit.name | translate}}
                    </div>
                    <div class="p-col-8">
                        <span style="float:left; margin-right:10px">{{limit.current}}</span> <span
                            style="float:right;margin-left:10px">{{limit.max}}</span>
                        <p-progressBar [value]="limit.percentage"></p-progressBar>

                    </div>
                    <div class="p-col-8" *ngIf="!currentLicense.Product[0].MetaData.Data">
                        {{'Unlimited' | translate}}
                    </div>
                </span>
                <span class="p-grid">
                    <div class="p-col-12" style="text-align: right;">
                        <a href="">{{'Need more? Upgrade here' | translate}}</a>
                    </div>
                </span>
            </div> -->


            <!-- <div class="p-col-12" style="margin: 0; padding: 0">            <p-divider></p-divider> </div> -->

            <!-- <div class="p-col-12" style="height:100px">
                <span style="margin-top: 20px;">{{'Storage used' | translate }}</span>
                <p-progressBar [value]="1"></p-progressBar>
                <span style="margin-top: 20px;">{{'Traffic used' | translate }}</span>
                <p-progressBar [value]="1"></p-progressBar>
            </div> -->

            <div class="p-col-12" style="text-align: right">

                <button pButton class="p-button-rounded p-button-danger p-button" (click)="appMain.logout()"><i
                        class="pi pi-sign-out" style="margin-right: 5px;"></i>{{'Logout' | translate}}</button>
            </div>


        </div>

    </ng-template>
</p-overlayPanel>

<!-- <p-overlayPanel #opTasks>
    <ng-template pTemplate>
        <div class="p-grid" style="width: 80rem">
            <div class="p-col-12"
                style="text-align: left;color: white;background: linear-gradient(79deg, #34c7c2 0%, #0494c5 51%)">
                <p>
                    {{'All latest operations' | translate}}<br>
                </p>
            </div>
            <div class="p-col-12">
                <app-operations-overview></app-operations-overview>
            </div>

        </div>

    </ng-template>
</p-overlayPanel> -->
