import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DestinationWriteMode } from 'src/app/models/api/com/bion/connect/DestinationWriteMode';
import { EntityInfo } from 'src/app/models/api/com/bion/connect/EntityInfo';
import { TargetMetaInfo } from 'src/app/models/api/com/bion/connect/TargetAccessLike';
import { DestinationView } from 'src/app/models/destination-view.model';
import { SubSink } from 'subsink';

export interface DestinationChangedArg {
  Name: string;
  Description?: string;
  TargetMode: string;
  EntityMode: string;
  RecordMode: string;
  stream: string;
}

@Component({
  selector: 'app-define-destination',
  templateUrl: './define-destination.component.html',
  styleUrls: ['./define-destination.component.scss']
})
export class DefineDestinationComponent implements OnInit {
  subs = new SubSink;
  destinationName: string;
  destinationDescription?: string;
  @Input() destinationView?:DestinationView<any, TargetMetaInfo, any>;

  // im BE & FE typisieren!!! TODO
  targetWriteModes:DestinationWriteMode.TargetModeInfo[] = [];
  selectedTargetWriteMode?:DestinationWriteMode.TargetModeInfo = undefined;
  targetEntityWriteModes?:DestinationWriteMode.EntityModeInfo[] = [];
  selectedTargetEntityWriteMode?: DestinationWriteMode.EntityModeInfo = undefined;
  recordWriteModes?: string[] = [];
  selectedRecordWriteMode?: string = undefined;
  streamInputDisabled: boolean = true;
  streamIsEditable: boolean = true;
  entities: string[] = [];
  selectedEntity?: string ="";

  //selectedEntity?: EntityInfo = undefined;

  @Output() destinationChanged = new EventEmitter<DestinationChangedArg>();

  constructor() { }

  ngOnInit(): void {
    // brauche adapter id und settings.
    const meta_access = this.destinationView.fetchMetaDataObs().subscribe(meta_data => {
      console.log(meta_data);
      const targetModes = meta_data.WriteModes.TargetModeInfos;
      console.log(targetModes);
      this.targetWriteModes = targetModes;
      this.entities = meta_data.Entities.map((entity) => { return entity.Name});
    })
  }

  onDestinationChanged(name:string, description:string, targetMode: DestinationWriteMode.TargetModeInfo, entityMode: DestinationWriteMode.EntityModeInfo, recordMode: string, stream: string) {
    const arg: DestinationChangedArg = {
      Name: name,
      Description: description,
      TargetMode: targetMode.Mode,
      EntityMode: entityMode.Mode,
      RecordMode: recordMode,
      stream: stream,
    }

    console.log("onDestinationChanged", arg);
    this.destinationChanged.emit(arg);
  }

  updateForms() {
    try {
        this.checkStreamMode(this.selectedTargetWriteMode?.Mode, this.selectedTargetEntityWriteMode?.Mode);
        this.onDestinationChanged(this.destinationName, this.destinationDescription,this.selectedTargetWriteMode, this.selectedTargetEntityWriteMode, this.selectedRecordWriteMode, this.selectedEntity);
    } catch {
        //TODO
    }
  }

  checkStreamMode(targetMode?: string, entityMode?: string) {
    this.streamInputDisabled = false;
    this.streamIsEditable = true;
    if(targetMode === undefined) {
        this.streamInputDisabled = true;
        // Dropdown/Combox auf disabled setzen
        return;
    }
    if(targetMode === "Keep") {
        if(entityMode === undefined) {
            // Dropdown/Combox auf disabled setzen
            this.streamInputDisabled = true;
            return
        }
        if(entityMode === "Keep") {
            this.streamIsEditable = false;
            //Tabelle wird behalten -> DropdownListe der verfügbaren Tabellen anzeigen und eines davon muss ausgewählt werden
        }
        if(entityMode === "Drop") {
            this.streamIsEditable = true;
            // Neue Tabelle wird erzeugt -> Combobox anzeigen und User muss einen neuen Tabellennamen vergeben (neue Tabelle wird erstellt) oder eine bestehende Tabelle auswählen( diese wird dann überschrieben )
        }
    }
    if(targetMode === "Drop") {
        if(entityMode === undefined) {
            // Dropdown/Combox auf disabled setzen
            this.streamInputDisabled = true;
            return
        }
        if(entityMode === "Drop") {
            this.streamIsEditable = true;
            // Neue Tabelle wird erzeugt -> Combobox anzeigen und User muss einen neuen Tabellennamen vergeben (neue Tabelle wird erstellt) oder eine bestehende Tabelle auswählen( diese wird dann überschrieben )
        }
    }
  }

  // page interface -->  to be implemented on mother component
  isValid(): boolean {
    const valid_ds_name =
      this.destinationName !== undefined && this.destinationName.length > 0;
    const valid_write_mode = this.selectedTargetWriteMode !== undefined;
    return valid_ds_name && valid_write_mode;
  }
}
