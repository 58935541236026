<!-- <div style="width:100%;height:100%;position: absolut;background: black; opacity: 0.7;">
    <div style="position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);color: rgb(224, 224, 224);">
        <div class="loader">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
    </div>

</div> -->

<div class="loader">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
</div>