import { Sources } from "../../airbyte-api-structures";
import { ApiModel } from "../com/bion/integrate/airbyte/ApiModel";

export namespace AirbyteModel {
    export class CreateSourceArg<C> {
        sourceDefinitionId: string;
        connectionConfiguration: C;
        name: string;
        constructor(sourceDefinitionId: string, connectionConfiguration: C, name: string) {
            if (sourceDefinitionId === undefined) throw new Error("Class 'CreateSourceArg': Required value 'sourceDefinitionId' is undefined");
            if (connectionConfiguration === undefined) throw new Error("Class 'CreateSourceArg': Required value 'connectionConfiguration' is undefined");
            if (name === undefined) throw new Error("Class 'CreateSourceArg': Required value 'name' is undefined");
            this.sourceDefinitionId = sourceDefinitionId;
            this.connectionConfiguration = connectionConfiguration;
            this.name = name;
        }
    }

    export class CreateSourceResult<R> {
        SourceInfo: SourceInfo<Sources.Source>;
        OriginalResult: R;
        constructor(SourceInfo: SourceInfo<Sources.Source>, OriginalResult: R) {
            if (SourceInfo === undefined) throw new Error("Class 'CreateSourceResult': Required value 'SourceInfo' is undefined");
            if (OriginalResult === undefined) throw new Error("Class 'CreateSourceResult': Required value 'OriginalResult' is undefined");
            this.SourceInfo = SourceInfo;
            this.OriginalResult = OriginalResult;
        }
    }

    export class SourceInfo<A> {
        id: number;
        isActive: boolean;
        airbyteSourceId: string;
        source?: A;
        constructor(id: number, isActive: boolean, airbyteSourceId: string, source?: A) {
            if (id === undefined) throw new Error("Class 'SourceInfo': Required value 'id' is undefined");
            if (isActive === undefined) throw new Error("Class 'SourceInfo': Required value 'isActive' is undefined");
            if (airbyteSourceId === undefined) throw new Error("Class 'SourceInfo': Required value 'airbyteSourceId' is undefined");
            this.id = id;
            this.isActive = isActive;
            this.airbyteSourceId = airbyteSourceId;
            this.source = source;
        }
    }

    export class ExtractDataFromSourceArg {
        ConnectionInfo: number;
        constructor(ConnectionInfo: number) {
            if (ConnectionInfo === undefined) throw new Error("Class 'ExtractDataFromSourceArg': Required value 'ConnectionInfo' is undefined");
            this.ConnectionInfo = ConnectionInfo;
        }
    }

    export class ExtractMetaFromSourceArg {
        id: number;
        constructor(id: number) {
            if (id === undefined) throw new Error("Class 'ExtractMetaFromSourceArg': Required value 'id' is undefined");
            this.id = id;
        }
    }

    export class QueryStreamArg {
        streamName: string;
        connectionInfo: number;
        constructor(streamName: string, connectionInfo: number) {
            if (streamName === undefined) throw new Error("Class 'QueryStreamArg': Required value 'streamName' is undefined");
            if (connectionInfo === undefined) throw new Error("Class 'QueryStreamArg': Required value 'connectionInfo' is undefined");
            this.streamName = streamName;
            this.connectionInfo = connectionInfo;
        }
    }

    export class SourceDefinition {
        SourceInfoId: number;
        Name: string;
        SelectedStreams: Array<string>;
        constructor(SourceInfoId: number, Name: string, SelectedStreams: Array<string>) {
            if (SourceInfoId === undefined) throw new Error("Class 'SourceDefinition': Required value 'SourceInfoId' is undefined");
            if (Name === undefined) throw new Error("Class 'SourceDefinition': Required value 'Name' is undefined");
            if (SelectedStreams === undefined) throw new Error("Class 'SourceDefinition': Required value 'SelectedStreams' is undefined");
            this.SourceInfoId = SourceInfoId;
            this.Name = Name;
            this.SelectedStreams = SelectedStreams;
        }
    }

    export class CreateDataSourceArg {
        SourceDefinition: SourceDefinition;
        constructor(SourceDefinition: SourceDefinition) {
            if (SourceDefinition === undefined) throw new Error("Class 'CreateDataSourceArg': Required value 'SourceDefinition' is undefined");
            this.SourceDefinition = SourceDefinition;
        }
    }

    export class CreateConnectionArg {
        SourceInfo: number;
        Name: string;
        Schema?: any;
        constructor(SourceInfo: number, Name: string, Schema?: any) {
            if (SourceInfo === undefined) throw new Error("Class 'CreateConnectionArg': Required value 'SourceInfo' is undefined");
            if (Name === undefined) throw new Error("Class 'CreateConnectionArg': Required value 'Name' is undefined");
            this.SourceInfo = SourceInfo;
            this.Name = Name;
            this.Schema = Schema;
        }
    }

    export class CreateConnectionResult<C, S, R> {
        ConnectionInfo: ConnectionInfo<C>;
        SourceInfo: SourceInfo<S>;
        OriginalResult: R;
        constructor(ConnectionInfo: ConnectionInfo<C>, SourceInfo: SourceInfo<S>, OriginalResult: R) {
            if (ConnectionInfo === undefined) throw new Error("Class 'CreateConnectionResult': Required value 'ConnectionInfo' is undefined");
            if (SourceInfo === undefined) throw new Error("Class 'CreateConnectionResult': Required value 'SourceInfo' is undefined");
            if (OriginalResult === undefined) throw new Error("Class 'CreateConnectionResult': Required value 'OriginalResult' is undefined");
            this.ConnectionInfo = ConnectionInfo;
            this.SourceInfo = SourceInfo;
            this.OriginalResult = OriginalResult;
        }
    }

    export class ConnectionInfo<A> {
        id: number;
        airbyteConnectionId: string;
        connection?: A;
        sourceInfo: number;
        constructor(id: number, airbyteConnectionId: string, sourceInfo: number, connection?: A) {
            if (id === undefined) throw new Error("Class 'ConnectionInfo': Required value 'id' is undefined");
            if (airbyteConnectionId === undefined) throw new Error("Class 'ConnectionInfo': Required value 'airbyteConnectionId' is undefined");
            if (sourceInfo === undefined) throw new Error("Class 'ConnectionInfo': Required value 'sourceInfo' is undefined");
            this.id = id;
            this.airbyteConnectionId = airbyteConnectionId;
            this.connection = connection;
            this.sourceInfo = sourceInfo;
        }
    }

    export class CheckConnectionArg<C> {
        connectionConfiguration: C;
        sourceDefinitionId: string;
        sourceId?: string;
        constructor(connectionConfiguration: C, sourceDefinitionId: string, sourceId?: string) {
            if (connectionConfiguration === undefined) throw new Error("Class 'CheckConnectionArg': Required value 'connectionConfiguration' is undefined");
            if (sourceDefinitionId === undefined) throw new Error("Class 'CheckConnectionArg': Required value 'sourceDefinitionId' is undefined");
            this.connectionConfiguration = connectionConfiguration;
            this.sourceDefinitionId = sourceDefinitionId;
            this.sourceId = sourceId;
        }
    }
}
