import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { concatMap, map, mergeMap } from 'rxjs/operators';
import { AppMainComponent } from 'src/app/app.main.component';
import { RightHolder } from 'src/app/models/api/models/authorization/RightHolder';
import { UserInfo } from 'src/app/models/api/models/session/UserInfo';
import { WorkflowAccessible } from 'src/app/models/api/models/workflow/WorkflowAccessible';
import { WorkflowPermission } from 'src/app/models/api/models/workflow/WorkflowPermission';
import { WorkflowRepositoryEntry } from 'src/app/models/api/models/workflow/WorkflowRepositoryEntry';
import { UserDetailsRow } from 'src/app/models/user.model';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { ExperimentalApi } from 'src/app/services/experimental-api.service';
import { ObjectSearchService } from 'src/app/services/object-search.service';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { UserService } from 'src/app/services/user.service';
import { UtilFunctionsService } from 'src/app/services/util-functions.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { PermissionOps } from 'src/app/views/authorization/permission-operations';
import { DataModel, JobInfo, NewPermission, PermissionData } from 'src/app/views/authorization/role-permissions/role-permissions.component';
import { GenericLatestActivitiesComponent } from 'src/app/views/objectManagement/generic-latest-activities/generic-latest-activities.component';
import { WorkflowViewActivity } from 'src/app/views/objectManagement/generic-latest-activities/provider-workflow';
import { UserPermissionsListComponent } from 'src/app/views/users/components/user-permissions-list/user-permissions-list.component';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-workflow-view',
  templateUrl: './workflow-view.component.html',
  styleUrls: ['./workflow-view.component.scss'],
  providers: [MessageService]
})
export class WorkflowViewComponent implements OnInit, OnDestroy {
  subs = new SubSink;
  selectedWorkflow?: WorkflowRepositoryEntry = undefined;
  selectedWorkflow_copy?: WorkflowRepositoryEntry = undefined;
  editModeOn: boolean = false;
  loading: boolean = false;
  confirmDialog: boolean = false;
  userDetails: UserDetailsRow[] = [];
  @ViewChild('wfLatestActivities') wfLatestActivities!: GenericLatestActivitiesComponent<WorkflowRepositoryEntry, any>;
  //@ViewChild('permView') permView!: UserPermissionsListComponent<WorkflowPermission, WorkflowAccessible, WorkflowRepositoryEntry, any>;

  workflowActivityTypeClass = new WorkflowViewActivity([], this.workflowsService, this.userDetails, this.utilService, this.router);
  //workflowActivityTypeClass = new WorkflowActivity(this.bionApi, this.workflowsService, this.userDetails,this.utilService);




  constructor(
    private bionApi: ApiBackendService,
    public appMain: AppMainComponent,
    private workflowsService: WorkflowsService,
    private systemLogService: SystemMessageLogService,
    private userService: UserService,
    private objectSearchService: ObjectSearchService,
    private utilService: UtilFunctionsService,
    public translate: TranslateService,
    public experimentalApi: ExperimentalApi,
    public router: Router,
    public messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.initView().subscribe(() => {
      this.loading = false;
    }, (err) => this.systemLogService.handleError(err));
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
  /**
   * Der Datasource Schlüssel aus der Route.
   * @returns Data Source Key
   */
  getUrlContext(): number {
    const arr = this.router.url.split('/');
    const id = parseInt(arr.last());
    const origin = arr.getRight(1);
    console.log("Workflow ID: " + id);
    //console.log("Workflow Origin: " + origin);
    return id;
  }
  initView(): Observable<boolean> {

    this.loading = true;
    // top, search, object, activity, bottom

    const current_id = this.getUrlContext();

    const userDetailsObs = this.userService.getUserDetailsRow();
    const wfObs = this.experimentalApi.getWorkflowView();

    const permissionObs = this.getPermissionData(current_id);

    const obs = forkJoin(wfObs, userDetailsObs, permissionObs);

    const finalObs = obs.pipe(concatMap(rich_data => {

      console.log("Init View Workflow", rich_data);
      const workflows = rich_data[0].map(d => d.Workflow);
      this.selectedWorkflow = workflows.find(w => w.id === current_id);
      this.selectedWorkflow_copy = { ...this.selectedWorkflow };
      //this.permView.selectedObject = this.selectedWorkflow;
      //this.workflows = workflows;
      this.userDetails = rich_data[1];
      this.updatePermissionModel(current_id, rich_data[2]);

      this.workflowActivityTypeClass = new WorkflowViewActivity(rich_data[0], this.workflowsService, this.userDetails, this.utilService, this.router);
      const activity_obs = this.wfLatestActivities.setObject(this.workflowActivityTypeClass);

      return forkJoin(Array(activity_obs)).pipe(map(a => a.reduce((a, b) => a && b)));
    }));

    return finalObs;

  }

  onChangeEditMode() {
    if (this.editModeOn) {
      if (this.selectedWorkflow.name === this.selectedWorkflow_copy.name && this.selectedWorkflow.description == this.selectedWorkflow_copy.description && this.selectedWorkflow.runInfo.simulation.ReadOnly == this.selectedWorkflow_copy.runInfo.simulation.ReadOnly) {
        // Do nothing, just disable input
        this.editModeOn = false;
      } else {
        // show popup to ask

        this.confirmDialog = true;
      }
    } else {
      // activate input field to change data
      this.editModeOn = true;
    }
  }
  onChangeWorkflow() {
    this.loading = true;

    this.subs.sink = this.workflowsService.updateWorkflowObject(this.selectedWorkflow_copy).subscribe(() => {
      this.subs.sink = this.initView().subscribe(() => {
        this.confirmDialog = false;
        this.workflowsService.workflowsChangedEmitter.emit("workflowChanged");
        this.messageService.add({
          severity: "success",
          summary: this.translate.instant("Message.UpdateWorkflowSuccess.Title"),
          detail: this.translate.instant("Message.UpdateWorkflowSuccess.Text1") + this.selectedWorkflow_copy.id + this.translate.instant("Message.UpdateWorkflowSuccess.Text2"),
        });
      }, (err) => {
        this.systemLogService.handleError(err)
      });

    }, (err) => this.systemLogService.handleError(err), () => {
      this.editModeOn = false;
      this.loading = false;

    });
  }
  onResetDialog() {
    this.selectedWorkflow_copy = this.selectedWorkflow;
    this.editModeOn = false;
    this.confirmDialog = false;
  }


  // == Permission Component

  /**
 * Holt alle Informationen, die für den Permission Dialog benötigt werden.
 * @param source_key Data Source Key
 */
  getPermissionData(source_key: number) {
    const role_obs = this.userService.getRightHolders();
    const perm_obs = this.bionApi.getWorkflowAuthPermissions(undefined, source_key);
    const user_obs = this.userService.getUserCommon();
    const rights_obs = this.bionApi.getWorkflowRights();

    return role_obs
      .pipe(mergeMap(roles => perm_obs
        .pipe(mergeMap(permissions => user_obs
          .pipe(mergeMap(users => rights_obs.pipe(map(rights => {
            const result: [RightHolder[], WorkflowPermission[], UserInfo[], string[]] = [roles, permissions, users, rights];
            return result;
          }))
          ))
        ))
      ));
  }

  /**
   * Schreibt die neuen Informationen in die Permission Komponente.
   * @param workflowKey Objektschlüssel
   * @param data Permission und Metadaten
   */
  updatePermissionModel(workflowKey: number, data: [RightHolder[], WorkflowPermission[], UserInfo[], string[]]) {

    const permission_data: PermissionData<WorkflowAccessible, WorkflowPermission> = {
      right_holders: data[0],
      source_permissions: data[1],
      users: data[2],
      rights: data[3]
    }

    this.permissions_model = {
      Accessible: new WorkflowAccessible(workflowKey),
      PermissionData: permission_data,
      NewPermission: new NewWorkflowPermission()
    }
  }

  permissions_model?: DataModel<WorkflowAccessible, WorkflowPermission>;
  lastJobInfo?: JobInfo<WorkflowAccessible> = undefined;

  onPermissionSelectionChanged(jobInfo: JobInfo<WorkflowAccessible>) {
    this.lastJobInfo = jobInfo;
  }

  /**
   * Wird ausgeführt, sobald auf den Permission Update button gegklickt wurde.
   */
  clickUpdatePermissionsComp() {

    if (this.lastJobInfo === undefined) {
      // just say OK!
    } else {

      const cf: (item: WorkflowPermission) => Observable<WorkflowPermission> = this.bionApi.createWorkflowPermission.bind(this.bionApi);
      const uf: (item: WorkflowPermission) => Observable<number> = this.bionApi.updateWorkflowPermission.bind(this.bionApi);
      // const df: (p: WorkflowPermission) => Observable<number> = PermissionOps
      //   .mkDeleteOp<
      //     WorkflowAccessible,
      //     WorkflowPermission,
      //     number
      //   >(this.bionApi.deleteWorkflowPermission, p => p.Accessible.ID)
      //   .bind(this.bionApi)

      // Test: Bind the function context BEFORE contramap
      const df_bound = this.bionApi.deleteWorkflowPermission.bind(this.bionApi);
      const df: (p: WorkflowPermission) => Observable<number> = PermissionOps
        .mkDeleteOp<WorkflowAccessible, WorkflowPermission, number>(df_bound, p => p.ID);

      const ob = PermissionOps.processPermissions(this.lastJobInfo.Create, this.lastJobInfo.Update, this.lastJobInfo.Delete, cf, uf, df);

      // update view
      const source_key = this.permissions_model.Accessible.ID;

      const final_ob = ob
        .pipe(concatMap(results => this.getPermissionData(source_key)
          .pipe(map(data => {
            this.updatePermissionModel(source_key, data);
            return results;
          }))))

      final_ob.subscribe(results => {
        // TODO: error handling and ui notification

        const failed = results.filter(r => r.Successful == false);
        console.log("Failed Operations", failed);

        if (failed.nonEmpty()) {

          const fail_texts = failed.map(f => f.Tag + " " + f.Argument.ID + " '" + f.Argument.Role + "'");
          const fail_reduced = fail_texts.reduce((a, b) => a + ", " + b);
          const msg = "Could not process the following Permissions: " + fail_reduced;
          this.handleError(new Error(msg));
        } else {

          console.log("Permission processed via component", results);
          this.messageService.add({
            severity: "success",
            summary: this.translate.instant("Message.UpdatePermissionSuccess.Title"),
            detail: this.translate.instant("Message.UpdatePermissionSuccess.Text1") + source_key + this.translate.instant("Message.UpdatePermissionSuccess.Text2"),
          });
        }

      })

    }



  }

  handleError(err: Error) {
    this.systemLogService.handleError(err);
    console.error("Error Loading Data from PSA", err);
  }

  // == Permission Comopnent END

}

class NewWorkflowPermission implements NewPermission<WorkflowAccessible, WorkflowPermission> {
  create(role: number, accessible: WorkflowAccessible): WorkflowPermission {
    return new WorkflowPermission(-1, role, false, accessible, []);
  }
}
