import { Observable, of } from 'rxjs';
import { Arrays } from 'src/app/helper/arrays';
import { RichDataSourceCake } from 'src/app/models/api/controllers/staging/RichDataSourceCake';
import * as dss from 'src/app/models/datasource.model';
import { UserDetailsRow } from 'src/app/models/user.model';
import { DatasourcesService } from 'src/app/services/datasources.service';
import { UtilFunctionsService } from 'src/app/services/util-functions.service';
import { ActivityEntry, hasImage, LatestActivityProvider } from './latest-activity-provider';
import { ActivityUtils } from './util-functions';

import * as model from "src/app/models/api/models/staging/RichDataSourceCake";
import { Router } from '@angular/router';
import { IntegratedSourceModel } from 'src/app/models/api/models/IntegratedSourceModel';



export class DataSourceNewActivity implements LatestActivityProvider<dss.DataSource, RichDataSourceCake.DataSourceView<any>[]> {

    constructor(private datasourcesService: DatasourcesService, private data:RichDataSourceCake.DataSourceView<any>[], private users: UserDetailsRow[], private utilService:UtilFunctionsService, private router: Router) {}
    getContextUrl(): IntegratedSourceModel.DataSourceKey<number> {
        const arr = this.router.url.split('/');
        const id = parseInt(arr.last());
        const origin = arr.getRight(1);
        console.log("Destination ID: " + id);
        console.log("Destination Origin: " + origin);
        return new IntegratedSourceModel.DataSourceKey(id, origin);
      }

    getData(): Observable<RichDataSourceCake.DataSourceView<any>[]> {
        return of(this.data);
    }

    buildGuI(data: RichDataSourceCake.DataSourceView<any>[], select?:dss.DataSource): ActivityEntry[] {
        const key = this.getContextUrl();


        const target_ds = key ? data.filter(d => d.DataSource.id == key.Id) : data;

        const data_packages: Array<dss.DataPackageProtocolEntry> = target_ds.flatMap(e => e.DataPackages.flatMap(dpe => dpe.Protocols));
        const dsProtocols: dss.DataSourceProtocolEntry[] = target_ds.flatMap(( (view) => {
            const dsProtocols = view.Protocols;
            const flatDsProtocols = dsProtocols.map(p => p.Entry);

            return flatDsProtocols
        }))

        console.log(data_packages, dsProtocols);

        const aggregated_protocols: Array<dss.DataPackageProtocolEntry | dss.DataSourceProtocolEntry> = [...data_packages, ...dsProtocols];

        const sorted_protocols = aggregated_protocols.sort(
            (objA, objB) => new Date(objB.end).getTime() - new Date(objA.end).getTime()
        );

        const latest_protocols = sorted_protocols;

        // function to get user avatar
        const latest_protocols_with_img:ActivityEntry[] = latest_protocols.map((protocol) => {
            return ActivityUtils.extractImage(this.users,protocol, this.utilService)
        });

        return latest_protocols_with_img;
    }

    subscribeToEmitter(): Observable<dss.DataSource> {
        return this.datasourcesService.selectedDatasourceEmitter;
    }
}
