import { DataProviderIdentifier } from "./DataProviderIdentifier";
import { DataSegment } from "./DataSegment";
//import { ExportFormatConfig } from "./ExportFormatConfig";

export class ExportDataConfig<A extends DataProviderIdentifier,C> {
    DataProvider: A;
    DataSegment: DataSegment;
    ExportFormatConfig: C;
    constructor(DataProvider: A,DataSegment: DataSegment,ExportFormatConfig: C) {
        if(DataProvider === undefined) throw new Error( "Class 'ExportDataConfig': Required value 'DataProvider' is undefined" );
        if(DataSegment === undefined) throw new Error( "Class 'ExportDataConfig': Required value 'DataSegment' is undefined" );
        if(ExportFormatConfig === undefined) throw new Error( "Class 'ExportDataConfig': Required value 'ExportFormatConfig' is undefined" );
        this.DataProvider = DataProvider;
        this.DataSegment = DataSegment;
        this.ExportFormatConfig = ExportFormatConfig;
    }
}