// export class DataStoreAccessible {
//     ID: number;
//     constructor(ID: number) {
//         if(ID === undefined) throw new Error( "Class 'DataStoreAccessible': Required value 'ID' is undefined" );
//         this.ID = ID;
//     }
// }

import { Accessible } from "../authorization/Accessible";

// import { Accessible } from "src/app/models/authorization.model";

export class DataStoreAccessible implements Accessible{
	ID: number;
	constructor(ID: number) {
		if(ID === undefined) throw new Error( "Class 'DataStoreAccessible': Required value 'ID' is undefined" );
		this.ID = ID;
	}
	AccessibleType: string = "";
	Value: string = "";
}
