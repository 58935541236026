import { Component, OnInit } from "@angular/core";
import { NodeMetaData } from "src/app/models/api/com/bion/etl/NodeMetaData";
import { FieldInfo, WorkflowNodeSettings } from "src/app/models/api/com/bion/etl/Workflow";
import { GuiDropDown } from "src/app/models/helperClass.model";
import {
	FindAndReplaceSettings,
	FindAndReplaceInfo,
} from "src/app/models/nodeSettings.model";
// import { FieldInfo, WorkflowNodeSettings } from "src/app/models/workflow.model";
import { DesignerService } from "src/app/services/designer.service";
import { WorkflowsService } from "src/app/services/workflows.service";

import { NodeConfigComponentBase } from "../node-config-component-base";

export class FindAndReplaceView {
	ColumnName: string;
	FindValue: string;
	ReplaceValue: string;
	ReplaceColumn: boolean;
	CaseSensitive: boolean;
	MatchWholeWord: boolean;
	hasError?: boolean;

	constructor(ColumnName: string, FindValue: string, 	ReplaceValue: string,ReplaceColumn: boolean, CaseSensitive: boolean, MatchWholeWord: boolean,hasError?: boolean) {
		this.ColumnName = ColumnName;
		this.FindValue = FindValue;
		this.ReplaceValue = ReplaceValue;
		this.ReplaceColumn = ReplaceColumn;
		this.CaseSensitive = CaseSensitive;
		this.MatchWholeWord = MatchWholeWord;
		this.hasError = hasError
	}
}

@Component({
	selector: "app-find-and-replace-node",
	templateUrl: "./find-and-replace-node.component.html",
	styleUrls: ["./find-and-replace-node.component.css"],
})
export class FindAndReplaceNodeComponent
	extends NodeConfigComponentBase<FindAndReplaceSettings>
	implements OnInit
{
	settingsToView(settings: WorkflowNodeSettings): void {
		// -- Get Configuration
		let currentConfig = <FindAndReplaceSettings>settings;
		// -- Get Input Meta Data
		const meta_infos = this.getCurrentWorkflowNode().Properties.MetaInfo.get(
			this.InputPort
		);

		let meta_info:NodeMetaData;
		let input_field_names: string[] = [];

		// -- check for MetaInfos from Input Port & store dsField list
		if (meta_infos != undefined && meta_infos.length > 0) {

			meta_info = meta_infos[0];
			const input_field_infos = meta_info.FieldsInfo;

			// -- filter for string dsfield type entries only
			// const input_field_infos_string = input_field_infos.filter((dsfield) => {
			// 	return dsfield.DataType.Name === "String";
			// });

			// -- create string array for column dropdown

			input_field_names = input_field_infos.map(fieldInfo => fieldInfo.Name);

			this.columnList = input_field_names;
		}
		else {
			console.log("Warning - Mete Information is empty");
			return;
		}




		// -- if no configuration exists, set default
		if (currentConfig === undefined) {
			// -- create empty list
			this.findReplaceList = [];
			// -- select default TypeCheck Option (first entry)
			this.selectedTypeCheckOption = this.typeCheckOptions[0];
		} else {
			// // -- otherweise load to F&E list from existing config
			// // -- Check if metaInfo and F&E Info
			// currentConfig.FindAndReplaceInfos.map((info) => {
			// 	if (input_field_names.includes(info.ColumnName)) {
			// 		// -- column still exists, do nothing
			// 	} else {
			// 		// column does not exist anymore, meaning column name currently not included -> include
			// 		input_field_names.push(info.ColumnName);
			// 	}
			// });

			// this.columnList = input_field_names;

            // if(meta_info !== undefined) {
            //     this.findReplaceList = FindAndReplaceNodeComponent.infoToView(
            //         currentConfig,
            //         meta_info.FieldsInfo
            //     );
            // }


			const validated = FindAndReplaceNodeComponent.validateEntries(currentConfig, meta_info);
			this.columnList = validated[0];
			const new_views = validated[1];

			if(new_views !== undefined)
				this.findReplaceList = new_views;
		}
	}


	/**
	 * Validiert die Einträge und erstellt die DropDown Options (Field Options) und die Views.
	 * Es wird geprüft, ob die Infos noch valide sind.
	 * Wenn sie nicht valide sind, wird das Flag von der Funktion infoToView gesetzt.
	 * @param config Aktuelle Konfiguration
	 * @param input_field_names Input Felder aus dem Port
	 * @param meta_info Input Port (gewollte redundanz)
	 * @returns
	 */
	static validateEntries(config:FindAndReplaceSettings,
		meta_info:NodeMetaData) : [Array<string>,FindAndReplaceView[]] {

		const input_field_names = meta_info.FieldsInfo.map(fieldInfo => fieldInfo.Name);
		let out_input_field_names = [...input_field_names];

		config.FindAndReplaceInfos.map((info) => {
			if (out_input_field_names.includes(info.ColumnName)) {
				// -- column still exists, do nothing
			} else {
				// column does not exist anymore, meaning column name currently not included -> include
				out_input_field_names.push(info.ColumnName);
			}
		});

		//this.columnList = input_field_names;  wird vom caller gemacht

		// let findReplaceList:FindAndReplaceView[] = undefined
		// if(meta_info !== undefined) {
		// 	findReplaceList = FindAndReplaceNodeComponent.infoToView(config,meta_info.FieldsInfo);
		// }

		const findReplaceList = FindAndReplaceNodeComponent.infoToView(config,meta_info.FieldsInfo);

		return [out_input_field_names,findReplaceList];
	}


	viewToSettings(): FindAndReplaceSettings {
		const newSettings = <FindAndReplaceSettings>{
			...this.getCurrentWorkflowNode().Properties.Configuration,
		};
		let newInfoList: FindAndReplaceInfo[] = [];

		this.findReplaceList.map((entry) => {
			let newInfo = new FindAndReplaceInfo();
			newInfo.ColumnName = entry.ColumnName;
			newInfo.FindValue = entry.FindValue;
			newInfo.ReplaceColumn = entry.ReplaceColumn;
			newInfo.ReplaceValue = entry.ReplaceValue;
			newInfo.CaseSensitive = entry.CaseSensitive;
			newInfo.MatchWholeWord = entry.MatchWholeWord;

			newInfoList.push(newInfo);
		});

		newSettings.FindAndReplaceInfos = newInfoList;
		//newSettings.TypeCheck = this.selectedTypeCheckOption.value;

		// this.messageService.standardToastMessage({  severity : 'success',
		//                                                 summary : 'Filter successsfully added',
		//                                                 detail : 'New Filter was successfully added to the node.'});

		return newSettings;
	}
	onSettingsChanged(settings: FindAndReplaceSettings) {
		throw new Error("Method not implemented.");
	}

	typeCheckOptions: GuiDropDown[] = [];
	selectedTypeCheckOption?: GuiDropDown;

	columnList: string[] = [];
	//dropDownColumn: GuiDropDown[];
	findReplaceList: FindAndReplaceView[] = [];

	readonly InputPort = "Input";

	static infoToView(
		settings: FindAndReplaceSettings,
		meta: FieldInfo[]
	): FindAndReplaceView[] {

		let newViewList: FindAndReplaceView[] = [];

		if (settings.FindAndReplaceInfos.length > 0) {
			settings.FindAndReplaceInfos.map((info) => {
				//let newView = new FindAndReplaceView(info.ColumnName,info.FindValue,info.ReplaceValue,info.ReplaceColumn,info.CaseSensitive,info.MatchWholeWord);

				// newView.ColumnName = info.ColumnName;
				// newView.FindValue = info.FindValue;
				// newView.ReplaceValue = info.ReplaceValue;
				// newView.ReplaceColumn = info.ReplaceColumn;
				// newView.CaseSensitive = info.CaseSensitive;
				// newView.MatchWholeWord = info.MatchWholeWord;

               // let fieldInfoFound: FieldInfo;

                // if(meta !== undefined) {
                //     fieldInfoFound = meta.find((field) => {
                //         return field.Name === info.ColumnName;
                //     });
                // }

				const fieldInfoFound = meta.find((field) => {
					return field.Name === info.ColumnName;
				});

				const hasError = !fieldInfoFound;
				let newView = new FindAndReplaceView(info.ColumnName,info.FindValue,info.ReplaceValue,info.ReplaceColumn,info.CaseSensitive,info.MatchWholeWord,hasError);

				newViewList.push(newView);
			});
		}

		return newViewList;
	}

	onAddEntry() {
		let newFindReplaceEntry = new FindAndReplaceView("","","",true,false,false,false);
		// newFindReplaceEntry.ColumnName = "";
		// newFindReplaceEntry.FindValue = "";
		// newFindReplaceEntry.ReplaceValue = "";
		// newFindReplaceEntry.ReplaceColumn = true;
		// newFindReplaceEntry.CaseSensitive = false;
		// newFindReplaceEntry.MatchWholeWord = false;

		// newFindReplaceEntry.hasError = false;

		this.findReplaceList.push(newFindReplaceEntry);
		this.onUpdateSettings(false);
		//this.viewToInfo(this.findReplaceList);
	}
	onDeleteEntry(index: number) {
		this.findReplaceList = [
			...this.findReplaceList.slice(0, index),
			...this.findReplaceList.slice(index + 1),
		];
		this.onUpdateSettings(false);
		// this.messageService.standardToastMessage({  severity : 'warning',
		//                             summary : 'Filter deleted',
		//                             detail : 'The selected filter was deleted successfully!'});
	}

	constructor(
		protected workflowService: WorkflowsService,
		protected designerService: DesignerService
	) {
		super(workflowService, designerService);
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

    onFocusLost(event: FocusEvent) {
        // console.log("onFocusLost", event);
        // const srcAndTargetIdentical: boolean = event.srcElement === event.target;

        // const relatedTargetSet:boolean = event.relatedTarget !== null;
        // const target = event.target;
        // const sourceCapabilitiesSet: boolean = event['sourceCapabilities'] !== null;
        // const isInput = event.srcElement['localName'] === "input";

        // const div_left: boolean = (!relatedTargetSet && sourceCapabilitiesSet && !isInput);

        // console.log("srcAndTargetIdentical",srcAndTargetIdentical);
        // console.log("relatedTargetSet",relatedTargetSet);
        // console.log("sourceCapabilitiesSet",sourceCapabilitiesSet);
        // console.log("isInput",isInput);
        // console.log("Send from Div: ", div_left);

        const div_left: boolean = (event.relatedTarget === null);

        if (div_left) {
            //this.onCheckDirtyFlag();
            this.onUpdateSettings(true);
        }
    }
}
