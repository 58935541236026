<div>
    <p-table [value]="filterList" dataKey="ColumnName" editMode="row" [reorderableColumns]="false" sortMode="single"
        [globalFilterFields]="['Name','FilterValues','FilterTypes']">
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                <span class="p-input-icon-left">
                    <!-- <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                            placeholder="{{'LogHistory.SearchForLog' | translate}}" /> -->

                    <p-dropdown id="matchModeName" appendTo="body"  [options]="matchModeOptions" [style]="{'width':'100%'}"
                        (onChange)="onUpdateSettings(false)" [(ngModel)]="selectedMatchMode" optionLabel="name">
                    </p-dropdown>
                </span>

                <span class="p-input-icon-right">

                    <button style="float: right" pButton label="{{'Add' | translate}}" icon="fa fa-plus"
                        (click)="onAddFilter()"></button>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <!-- <th style="width:3rem"></th> -->
                <th tooltipPosition="top" pTooltip="{{'Config.Filter.Tooltip1' | translate}}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Name' | translate}}

                    </div>
                </th>

                <th tooltipPosition="top" pTooltip="{{'Config.Filter.Tooltip2' | translate}}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Filter Criteria' | translate}}

                    </div>
                </th>
                <th tooltipPosition="top" pTooltip="{{'Config.Filter.Tooltip3' | translate}}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Filter Value' | translate}}

                    </div>
                </th>
                <!-- <th pReorderableColumn tooltipPosition="top" pTooltip="Column that should be filtered on">Column Name</th>
                    <th pReorderableColumn tooltipPosition="top" pTooltip="Filter method to apply to selected column">Filter Critera</th>
                    <th pReorderableColumn tooltipPosition="top" pTooltip="Match Expression">Filter Value</th> -->
                <th style="width:3rem"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-column let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="column" [pReorderableRow]="ri"
                [ngClass]="{'row-accessories': column.hasError === true}">
                <!-- <td>
                        <span class="pi pi-bars" pReorderableRowHandle (onChange)="onChangeSettings(this.filterList)"></span>
                    </td> -->
                <td>
                    <p-dropdown appendTo="body" *ngIf="column.hasError === false" [options]="columnList"
                        [(ngModel)]="column.ColumnName" [autoDisplayFirst]="false" (onChange)="onUpdateSettings(false)"
                        autoWidth="false" [style]="{'width':'100%'}"></p-dropdown>
                    <p-dropdown *ngIf="column.hasError === true" appendTo="body" [options]="columnList"
                        [(ngModel)]="column.ColumnName" class="ng-invalid ng-dirty" [autoDisplayFirst]="false"
                        (onChange)="onUpdateSettings(false)" autoWidth="false" [style]="{'width':'100%'}"></p-dropdown>
                </td>
                <td>
                    <p-dropdown appendTo="body" [options]="filterTypeOptions" [(ngModel)]="column.FilterTypes"
                        optionLabel="name" dataKey="value" (onChange)="onUpdateSettings(false)" autoWidth="false"
                        [style]="{'width':'100%'}"></p-dropdown>
                </td>
                <td>
                    <input pInputText [(ngModel)]="column.FilterValues" (blur)="onUpdateSettings(false)"
                        [style]="{'width':'100%'}" [ngModelOptions]="{standalone: true}">
                </td>
                <td>
                    <div class="ui-table-buttongroup">
                        <button pButton pRipple type="button" class="p-button-rounded p-button-text ui-button-danger"
                            icon="pi pi-trash" pTooltip="{{'Delete' | translate}}" (click)="onDeleteEntry(ri)"
                            tooltipPosition="bottom"></button>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="4">{{'NoEntryFound' | translate}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>