import { Component, Input, OnInit, } from "@angular/core";
import {
	WorkflowNodeInfo,
} from "src/app/models/designer.models";
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
	selector: "app-node-info",
	templateUrl: "./node-info.component.html",
	styleUrls: ["./node-info.component.scss"],
})
export class NodeInfoComponent implements OnInit {
	@Input() selectedNode?: WorkflowNodeInfo;
	constructor(private sanitizer: DomSanitizer) {}

	ngOnInit(): void {
	}
  
	getSafeUrl(url: string): SafeResourceUrl {
	  return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}
}
