import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DatasourcesService } from 'src/app/services/datasources.service';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { SubSink } from 'subsink';
import { LoadToPsaArgs } from '../create-datasource-dialog-new/create-datasource-dialog-new.component';
import { CreateDataSourceNewLib } from '../create-datasource-dialog-new/createDataSourceNewLib';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-load-data-to-psadialog',
  templateUrl: './load-data-to-psadialog.component.html',
  styleUrls: ['./load-data-to-psadialog.component.scss'],
  providers: [MessageService],
})
export class LoadDataToPSADialogComponent implements OnInit {
  displayLoadDataToPsaDatasource: boolean = false;
  subs = new SubSink;
  args: LoadToPsaArgs;
  savingInProgress: boolean;
  buttonDisabled: boolean = false;

  constructor(private datasourceService:DatasourcesService,		
    private messageService: MessageService,
		private errorService: SystemMessageLogService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.subs.sink = this.datasourceService.loadToPSAEmitter.subscribe((args: LoadToPsaArgs) => {
      this.displayLoadDataToPsaDatasource = args.openDialog;
      this.args = args;
    })
  }

  loadDataToPsa(args: LoadToPsaArgs) {
    this.savingInProgress = true;
    this.buttonDisabled = true;
    this.subs.sink = CreateDataSourceNewLib.loadPSAStreamAsync(
      args.dataSource,
      args.connectorView,
      this.datasourceService,
      args.simulateFlag,
      args.writeMode,
      args.base64).subscribe(() => {

        this.displayLoadDataToPsaDatasource =false;
        this.messageService.add({
          severity: "info",
					summary: this.translate.instant("Message.LoadDataStartedSuccess.Title"),
					detail: this.translate.instant("Message.LoadDataStartedSuccess.Text"),
        });
      }, (error: Error) => {
        this.errorService.handleError(error);
      },() => {
        this.savingInProgress = false;
        this.buttonDisabled = false;
      });
  }

}
