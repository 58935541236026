<div>
    <p-table #dt [value]="regExList" dataKey="ColumnName">
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                <!-- <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="{{'LogHistory.SearchForLog' | translate}}" />
                </span> -->
                <span class="p-input-icon-right">
                    <button style="float: right" pButton label="{{'Add' | translate}}" icon="fa fa-plus"
                        (click)="onAddEntry()"></button>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem"></th>
                <th tooltipPosition="top" pTooltip="{{'Config.Regex.Tooltip1' | translate}}" pSortableColumn="name">
                    {{'Column' |translate }} <p-sortIcon field="name"></p-sortIcon>
                </th>
                <th tooltipPosition="top" pTooltip="{{'Config.Regex.Tooltip2' | translate}}">{{'Expression' |translate
                    }}</th>
                <th style="width: 3rem"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-column let-expanded="expanded" let-ri="rowIndex">
            <tr>
                <td>
                    <button type="button" pButton pRipple [pRowToggler]="column"
                        class="p-button-text p-button-rounded p-button-plain"
                        [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                </td>
                <td>
                    <p-dropdown *ngIf="column.hasError === false" appendTo="body" [options]="columnList"
                        (onChange)="onUpdateSettings(false)" [autoDisplayFirst]="false" [autoDisplayFirst]="false"
                        [(ngModel)]="column.ColumnName" autoWidth="false" [style]="{'width':'100%'}">
                    </p-dropdown>
                    <p-dropdown *ngIf="column.hasError === true" appendTo="body" [options]="columnList"
                        (onChange)="onUpdateSettings(false)" [autoDisplayFirst]="false" [autoDisplayFirst]="false"
                        [(ngModel)]="column.ColumnName" class="ng-invalid ng-dirty" autoWidth="false"
                        [style]="{'width':'100%'}">
                    </p-dropdown>
                </td>
                <td>
                    <input pInputText [(ngModel)]="column.FormatExpression" [style]="{'width':'100%'}"
                        (blur)="onUpdateSettings(false)" [ngModelOptions]="{standalone: true}">
                </td>
                <td>
                    <div class="ui-table-buttongroup">
                        <button pButton pRipple type="button" class="p-button-rounded p-button-text ui-button-danger"
                            icon="pi pi-trash" pTooltip="Delete entry" (click)="onDeleteEntry(ri)"
                            tooltipPosition="bottom"></button>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-column>
            <tr>
                <td colspan="4">
                    <div class="p-p-3">

                        <div class="p-grid">
                            <div class="p-col-9">
                                <div class="p-fluid">
                                    <span class="p-float-label">
                                        <p-dropdown id="outputMethod" appendTo="body" [options]="methodOptions"
                                            (onChange)="onUpdateSettings(false)" [(ngModel)]="column.OutputMethod"
                                            autoWidth="false" [style]="{'width':'100%'}"></p-dropdown>
                                        <label for="outputMethod">{{'Output Method' |translate }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="p-col-3">
                                <div class="p-field-checkbox">
                                    <p-checkbox name="group1" [binary]="true" [(ngModel)]="column.IsCaseSensitive"
                                        (onChange)="onUpdateSettings(false)" inputId="ny"></p-checkbox>
                                    <label for="ny">{{'Case Sensitive' |translate }}</label>
                                </div>
                            </div>
                            <!-- <div class="p-col-12">
                                    <div class="p-fluid">
                                        <span class="p-float-label">
                                            <input pInputText  (blur)="onUpdateSettings(false)"  [(ngModel)]="column.OutputMethod"
                                            [ngModelOptions]="{standalone: true}">
                                                <label for="matchModeName">Output Method</label>
                                        </span>
                                    </div>
                                </div> -->
                            <!-- <div class="p-col-9" *ngIf="column.OutputMethod === 'Tokenize'">
                                    <div class="p-fluid">
                                        <span class="p-float-label">
                                            <p-dropdown id="splitMethod" appendTo="body" [options]="splitMethods" [autoDisplayFirst]="false" [(ngModel)]="column.SplitMethod"
                                            (onChange)="onUpdateSettings(false)" autoWidth="false"
                                            [style]="{'width':'100%'}"></p-dropdown>
                                                <label for="splitMethod">Split Method</label>
                                        </span>
                                    </div>
                                </div> -->
                            <div class="p-col-9">
                                <div class="p-fluid">
                                    <span class="p-float-label">
                                        <input pInputText id="NewColumnName" [(ngModel)]="column.NewColumnName"
                                            (blur)="onUpdateSettings(false)" [ngModelOptions]="{standalone: true}">
                                        <label for="NewColumnName">{{'New Column Name' |translate }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="p-col-3">
                                <div class="p-field-checkbox">
                                    <p-checkbox name="group1" [binary]="true" [(ngModel)]="column.DropOld" inputId="ny"
                                        (onChange)="onUpdateSettings(false)">
                                    </p-checkbox>
                                    <label for="ny">{{'Drop Old' |translate }}</label>
                                </div>
                            </div>
                            <!-- <div class="p-col-12" *ngIf="column.OutputMethod === 'Parse'">
                                    <div class="p-fluid">
                                        <span class="p-float-label">
                                            <input pInputText id="NewColumnType" (blur)="onUpdateSettings(false)"
                                                [autoDisplayFirst]="false" [(ngModel)]="column.NewColumnType"
                                                [ngModelOptions]="{standalone: true}">
                                            <label for="NewColumnType">New Column Type</label>
                                        </span>
                                    </div>
                                </div> -->
                            <div class="p-col-12" *ngIf="column.OutputMethod === 'Replace'">
                                <div class="p-fluid">
                                    <span class="p-float-label">
                                        <input pInputText id="Replacement" [(ngModel)]="column.ReplacementText"
                                            (blur)="onUpdateSettings(false)" [ngModelOptions]="{standalone: true}">
                                        <label for="Replacement">{{'Replacement Text' |translate }}</label>
                                    </span>
                                </div>
                            </div>
                            <!-- <div class="p-col-12" *ngIf="column.OutputMethod === 'Match'">
                                    <div class="p-fluid">
                                        <span class="p-float-label">
                                            <input pInputText id="ColumnName" (blur)="onUpdateSettings(false)"
                                                [(ngModel)]="column.ColumnName" [ngModelOptions]="{standalone: true}">
                                            <label for="ColumnName">Column Name Match Status</label>
                                        </span>
                                    </div>

                                </div> -->
                        </div>
                        <!-- <p-table [value]="column.orders" dataKey="id">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="id">Id <p-sortIcon field="price"></p-sortIcon></th>
                                        <th pSortableColumn="customer">Customer <p-sortIcon field="customer"></p-sortIcon></th>
                                        <th pSortableColumn="date">Date <p-sortIcon field="date"></p-sortIcon></th>
                                        <th pSortableColumn="amount">Amount <p-sortIcon field="amount"></p-sortIcon></th>
                                        <th pSortableColumn="status">Status <p-sortIcon field="status"></p-sortIcon></th>
                                        <th style="width: 4rem"></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-order>
                                    <tr>
                                        <td>{{order.id}}</td>
                                        <td>{{order.customer}}</td>
                                        <td>{{order.id}}</td>
                                        <td>{{order.amount | currency:'USD'}}</td>
                                        <td><span [class]="'order-badge order-' + order.status.toLowerCase()">{{order.status}}</span></td>
                                        <td><p-button type="button" icon="pi pi-search"></p-button></td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="6">There are no order for this product yet.</td>
                                    </tr>
                                </ng-template>
                            </p-table> -->
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="4">{{'NoEntryFound' | translate}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>