import { Component, OnInit } from "@angular/core";
import { FieldInfo, WorkflowNodeSettings } from "src/app/models/api/com/bion/etl/Workflow";
import { GuiDropDown } from "src/app/models/helperClass.model";
import {
	ConstantValueInfo,
	ConstantValueSettings,
} from "src/app/models/nodeSettings.model";
// import { FieldInfo, WorkflowNodeSettings } from "src/app/models/workflow.model";
import { DesignerService } from "src/app/services/designer.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { NodeConfigComponentBase } from "../node-config-component-base";

export class ConstantValueInfoView {
	NewColumnName: string;
	NewColumnType: GuiDropDown;
	ConstantValue: any;

    constructor(NewColumnName: string, NewColumnType: GuiDropDown,ConstantValue: any){
        this.NewColumnName = NewColumnName;
        this.NewColumnType = NewColumnType;
        this.ConstantValue = ConstantValue;
    }
}

@Component({
	selector: "app-constant-value-node",
	templateUrl: "./constant-value-node.component.html",
	styleUrls: ["./constant-value-node.component.css"],
})
export class ConstantValueNodeComponent
	extends NodeConfigComponentBase<ConstantValueSettings>
	implements OnInit
{
	settingsToView(settings: WorkflowNodeSettings): void {
		// -- Get Configuration
		let currentConfig = <ConstantValueSettings>settings;
		// -- Get Input Meta Data
		const meta_infos = this.getCurrentWorkflowNode().Properties.MetaInfo.get(
			this.InputPort
		);

		// create SelectInfoViews from DsFields for table
		if (meta_infos != undefined && meta_infos.length > 0) {
			const input_field_infos = meta_infos[0].FieldsInfo;
			this.columnList = input_field_infos;
		}

		if (currentConfig.ConstantValueInfos.length > 0) {
			// -- otherweise load existing config into view
			this.constantValueList = this.infoToView(this.columnList, currentConfig);
		} else {
			this.constantValueList = [];
		}
	}
	viewToSettings(): ConstantValueSettings {
		const newSettings = <ConstantValueSettings>{
			...this.getCurrentWorkflowNode().Properties.Configuration,
		};

		const constantValueListView = this.constantValueList;
		const finalConstantValueConfig: ConstantValueInfo[] = [];

		constantValueListView.map((entry) => {
			let newInfo = new ConstantValueInfo();
			newInfo.NewColumnName = entry.NewColumnName;
			newInfo.NewColumnType = entry.NewColumnType.value;
			newInfo.ConstantValue = entry.ConstantValue;

			finalConstantValueConfig.push(newInfo);
		});

		newSettings.ConstantValueInfos = finalConstantValueConfig;

		return newSettings;
	}

	onSettingsChanged(settings: ConstantValueSettings): void {
		throw new Error("Method not implemented.");
	}

	readonly InputPort = "Input";
	constantValueList: ConstantValueInfoView[] = [];
	columnList: FieldInfo[] = [];
	dataTypeOptions: GuiDropDown[];

	onDeleteEntry(ri: number) {
		this.constantValueList = [
			...this.constantValueList.slice(0, ri),
			...this.constantValueList.slice(ri + 1),
		];

		this.onUpdateSettings(false);
		// this.messageService.standardToastMessage({  severity : 'warn',
		//                                                 summary : 'Row deleted',
		//                                                 detail : 'The selected row was deleted successfully!'});
	}
	onAddColumn() {
		let newConstantValue = new ConstantValueInfoView("", this.dataTypeOptions[0],"");
		// newConstantValue.ConstantValue = "";
		// newConstantValue.NewColumnName = "";
		// newConstantValue.NewColumnType = this.dataTypeOptions[0];

		this.constantValueList.push(newConstantValue);

		this.onUpdateSettings(false);
	}
	infoToView(list: FieldInfo[], config: ConstantValueSettings) {
		let newFilterInfosView: ConstantValueInfoView[] = [];

		config.ConstantValueInfos.map((entry) => {

			// -- set newColumnName from existing config
			//newInfoView.NewColumnName = entry.NewColumnName;

            let colType = new GuiDropDown();

			// -- create selected SortMethod from config
			for (let i = 0; i < this.dataTypeOptions.length; i++) {
				if (entry.NewColumnType == this.dataTypeOptions[i].value) {
					colType = this.dataTypeOptions[i];
				}
			}
			//newInfoView.ConstantValue = entry.ConstantValue;

            let newInfoView = new ConstantValueInfoView(entry.NewColumnName,colType,entry.ConstantValue);

			newFilterInfosView.push(newInfoView);
		});

		return newFilterInfosView;
	}

	constructor(
		protected workflowService: WorkflowsService,
		protected designerService: DesignerService
	) {
		super(workflowService, designerService);
		this.dataTypeOptions = [
			{ name: "Double", value: "Double" },
			{ name: "String", value: "String" },
			{ name: "Integer", value: "Int64" },
		];
	}

	ngOnInit(): void {
		super.ngOnInit();
	}
    onFocusLost(event: FocusEvent) {
        const div_left: boolean = (event.relatedTarget === null);

        console.log("Send from Div: ", div_left);

        if (div_left) {
            //this.onCheckDirtyFlag();
            this.onUpdateSettings(true);
        }
    }
}
