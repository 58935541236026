<p>view-ab-source works!</p>
<p-progressBar [mode]="progressMode" [style]="{'height': '6px'}"></p-progressBar>

<div id="source_header">
    <h1>{{sourceInfo?.source?.name}}</h1>
    <div>{{sourceInfo?.source?.sourceName}}</div>
</div>

<p-tabView>
    <p-tabPanel header="Overview">
        <div>
            <div>
                <h2>Connections</h2>
                <a routerLink="/testAirbyteCreateConnection/{{sourceInfo?.id}}">Create Connection</a>
            </div>
            <div>{{this.sourceInfo?.source?.name}}</div>
        </div>

    </p-tabPanel>
    <p-tabPanel header="Settings">
        <p>
            Settings comming soon
        </p>
    </p-tabPanel>
</p-tabView>