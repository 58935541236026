import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { Subject } from 'rxjs';
import { ConsoleLogger, LogLevel } from 'src/app/views/designer/components/workflow-graph/logger';
import { OAuth2Info } from '../create-datasource-int-page/oauth2-flow-service';
import { AuthConfig } from 'angular-oauth2-oidc';
import { AuthInfo, Oauth2FlowComponent } from 'src/app/views/general/oauth2-flow/oauth2-flow.component';
//import 'bootstrap/dist/css/bootstrap.min.css'; // Import der Bootstrap-Styles
//import 'node_modules/bootstrap/dist/css/bootstrap.min.css'; // Import der Bootstrap-Styles

/**
 * Airbyte Connector Specifications.
 */
interface AirbyteConnectorSpecification {
  sourceDefinitionId: string;
  documentationUrl: string;
  connectionSpecification: any;
}

/**
 * Diese Komponente ist für die dynamische Airbyte Config. Sie akzeptiert die Konnektor Specs und liefert das Config-Objekt.
 */
@Component({
  selector: 'app-create-config-int-ab',
  templateUrl: './create-config-int-ab.component.html',
  //styleUrls: ['./create-config-int-ab.component.scss', '../../../../../../node_modules/bootstrap/dist/css/bootstrap.min.css']
  styleUrls: ['./create-config-int-ab.component.scss']
})
export class CreateConfigIntAbComponent implements OnInit {

  constructor(private formlyJsonschema: FormlyJsonschema) { }

  ngOnInit(): void {
  }

  @Input()
  get connectorSpecs(): AirbyteConnectorSpecification { return this.model; }
  set connectorSpecs(m: AirbyteConnectorSpecification) {
    this.logger.info("Set Connector Specs", m);
    this.loadAirbyteForm(m.connectionSpecification);
  }

  @Input()
  get oauth2Info(): OAuth2Info<any> { return this._oauth2Info }
  set oauth2Info(i: OAuth2Info<any>) {
    this._oauth2Info = i;
    if (this._oauth2Info !== undefined) {
      //this.loadOAuth2Info(this._oauth2Info);
    }
  }

  @Output()
  public onConfigSet: EventEmitter<any> = new EventEmitter<any>();

  private logger = new ConsoleLogger("CreateDatasourceIntComponent", LogLevel.Info);
  private destroy$: Subject<any> = new Subject<any>();
  form: FormGroup;
  model: any;
  options: FormlyFormOptions;
  fields: FormlyFieldConfig[];

  loadAirbyteForm(connectionSpecification: any) {
    console.log("Load Airbyte Form", connectionSpecification);
    this.patchSchemaFormly(connectionSpecification);
    console.log("Schema after patch", connectionSpecification);

    this.form = new FormGroup({});
    this.options = {};
    this.fields = [this.formlyJsonschema.toFieldConfig(connectionSpecification)];
    this.model = {};
  }

  /**
   * Konfiguriert das Schema für das korrekte Formly-Rendering.
   * Dazu wird jedem Feld ein Attribut 'templateOptions' hinzugefügt, wo genau gesteuert wird,
   * wie der Eintrag zu rendern ist.
   */
  patchSchemaFormly(schema: any) {
    console.log("Patching Schema Formly");
    this.patchSchemaObject(schema);
  }

  patchSchemaObject(schema: any) {
    const props = schema["properties"];

    if (props === undefined) {
      return;
    }

    // Wird vom alias übernommen.
    // if(schema.type == "object") {
    //   console.log("Try custom object");
    //   schema.type = ObjectTypeComponent;
    // }

    for (let key of Object.keys(props)) {
      const value = props[key];

      if (value.type == "object") {
        this.patchSchemaObject(value);
      } else if (value.type == "array") {

      } else {
        // atomic
        this.patchSchemaAtomic(value);
      }

    }
  }

  patchSchemaField(schema: any) {

  }
  patchSchemaAtomic(schema: any) {

    const value = schema;

    // Datum patchen:
    // https://formly.dev/docs/ui/primeng/
    if (value.type == "string" && value.format == "date-time") {
      value.type = "datepicker";

      const date_props = {
        label: 'Datepicker',
        placeholder: 'Placeholder',
        description: 'Description',
        dateFormat: 'yy/mm/dd',
        hourFormat: '24',
        numberOfMonths: 1,
        selectionMode: 'single',
        required: true,
        readonlyInput: false,
        showTime: false,
        showButtonBar: true,
        showIcon: false,
        showOtherMonths: true,
        selectOtherMonths: false,
        monthNavigator: false,
        yearNavigator: false,
        yearRange: '2020:2030',
        inline: false,
      };

      value.props = date_props;

    }
  }

  submit() {
    const config = this.model;
    console.log("Final Argument", config);

    this.onCreate(config);
  }

  onCreate(data: any) {
    this.logger.debug("Config is valid", data);
    this.onConfigSet.emit(data);
  }

  // == OAUth2 Section
  _oauth2Info?: OAuth2Info<any> = undefined;

  /**
   * Wir benutzen das View Child, damit wir den Flow von hier aus starten können.
   */
  @ViewChild('oauth2Flow') oauth2Flow!: Oauth2FlowComponent;

  oauth_config?: AuthConfig = undefined;
  readonly redireactUri: string = window.location.origin + '/assets/views/general/oauth2Flow/silent-refresh.html';

  onConfigureOAuth() {
    // experimentell, sollte später auch so gehen

    if (this._oauth2Info) {
      const unsafe_model = this.model;

      const errors = this._oauth2Info.Read.validate(unsafe_model);

      if (errors.isEmpty()) {
        const auth_config = this._oauth2Info.Read.read(unsafe_model);
        console.log("Patch redirect URI and Silent Refresh Redirect", this.redireactUri);
        auth_config.redirectUri = this.redireactUri;
        auth_config.silentRefreshRedirectUri = this.redireactUri;
        console.log("OAuth Config", auth_config);

        // NOW WORKING! => configure-single-sign-on is async, therefore we produce a race condition here
        // this.oauth_config = auth_config;  // triggers configuration in oauth-flow-component
        // // Fire Dialog
        // console.log("Starting Dialog...")
        // this.oauth2Flow.loginFlowInPopup(undefined, this._oauth2Info.QueryParams).then(result => {
        //   console.log("Flow Successful", result)
        // }, err => {
        //   console.warn("Flow Failed", err)
        // });

        // TESTING -> Eliminates the Race Condition -> WORKS!
        this.oauth2Flow.configureSingleSignOn(auth_config)
          .then(fullFilled => this.oauth2Flow.loginFlowInPopup(undefined, this._oauth2Info.QueryParams))
          .then(result => {
            console.log("Flow Successful", result)
          }, err => {
            console.warn("Flow Failed", err)
          });



      } else {
        const msg = "Before fetching the refresh token you need to adjust the following config errors: " + this.makeOAuthError(errors);
        this.handleError(new Error(msg))
      }
    }

  }

  onAuthInfo(info: AuthInfo) {
    console.log("Auth Info received", info);
    if (info.RefreshToken) {
      this._oauth2Info.Write.patchForm(this.form, info);
    } else {
      this.handleError(new Error("Auth Information does not contain a refresh token"));
    }
  }

  // ==

  makeOAuthError(errors: string[]): string {
    return errors.reduce((a, b) => a + ", " + b);
  }

  handleError(err: Error) {
    // TODO: introduce message line
    alert(err.message);
  }
}
