<div style="margin-top: 7px">
    <span class="p-input-icon-left" style="width:100%">
        <i class="pi pi-search"></i>
        <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" autoWidth="false"
            [style]="{'width':'100%'}" placeholder="{{'Nodes.SearchForNode' | translate}}" />
    </span>

</div>
<!-- 
<p-badge value="Test">

</p-badge> -->

<p-chip  *ngFor="let plugIn of plugInInfos" [label]="plugIn.Name" icon="pi pi-cloud" styleClass="mr-2"></p-chip>


<p-scrollPanel [style]="{height: 'calc(77.2vh)'}">
    <p-table #dt [value]="plugInInfos" [(selection)]="selectedScheduleNodeInfo" selectionMode="none" sortField="representative.name"
        sortMode="single"
        [globalFilterFields]="['Name','NodeDescription.ShortText','status']" [scrollable]="false"
        [showPageLinks]="false" styleClass="p-datatable-customers p-datatable-sm">
        <ng-template pTemplate="header">
            <tr>
                <!-- <th style="width: 4rem">
                </th> -->
                <th pSortableColumn="Name" style="width: 100%">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Name' | translate}}
                        <p-sortIcon field="Name"></p-sortIcon>
                    </div>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-action let-rowIndex="rowIndex">
            <tr [pSelectableRow]="action" (onDragStart)="dragStart($event,action)" pDraggable="plugInInfos"
                (onDragEnd)="dragEnd($event)">
                <td>
                    <i *ngIf="action.Name === 'Extract'" class="pi pi-cloud"></i>
                    <i *ngIf="action.Name === 'Run'" class="pi pi-play"></i>
                    <i *ngIf="action.Name === 'Split'" class="pi pi-share-alt"></i>
                    <i *ngIf="action.Name === 'Join'" class="pi pi-share-alt" style=" transform: rotateY(180deg);
                    "></i>
                    {{action.Name}}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="1">{{'NoEntryFound' | translate}}</td>
            </tr>
        </ng-template>
    </p-table>

    <p-scrollTop target="parent" styleClass="custom-scrolltop" [threshold]="100" icon="pi pi-arrow-up">
    </p-scrollTop>
</p-scrollPanel>

