import { PlayJsonInheritance } from "src/app/models/play.model";
import { RightHolder } from "./RightHolder";

export interface UserRoleLike extends RightHolder, PlayJsonInheritance {
    UserID: number;
}

export class UserRole implements UserRoleLike {
    ID: number;
    UserID: number;
    Description?: string;
    IsActive: boolean;
    _type: string;
    constructor(ID: number, UserID: number, IsActive: boolean, Description?: string) {
        if (ID === undefined) throw new Error("Class 'UserRole': Required value 'ID' is undefined");
        if (UserID === undefined) throw new Error("Class 'UserRole': Required value 'UserID' is undefined");
        if (IsActive === undefined) throw new Error("Class 'UserRole': Required value 'IsActive' is undefined");
        this.ID = ID;
        this.UserID = UserID;
        this.Description = Description;
        this.IsActive = IsActive;
    }
}