import { DataStorePermission } from "src/app/models/api/models/staging/DataStorePermission";
import { WorkflowRepositoryEntry } from "src/app/models/api/models/workflow/WorkflowRepositoryEntry";
import { DataStore, DataStoreField, DataStoreTypeInfo } from "src/app/models/datastore.model";
import { RunWorkflowAction, ScheduleActionPlan, ScheduleBaseAction } from "src/app/models/schedule.model";
import { DropDownEntry, HeaderRow, RecordProvider, RecordView, RecordViewSorter } from "./record-provider";
import * as dss from 'src/app/models/datasource.model';
import { forkJoin, Observable, of } from "rxjs";
import { MenuItem } from "primeng/api";
import { PsaInputPlugIn } from "src/app/models/api/models/etl/PsaInputPlugIn";
import { DataStoreOutPlugInSettings } from "src/app/models/nodeSettings.model";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { CubesService } from "src/app/services/cubes.service";
import { ObjectSearchService } from "src/app/services/object-search.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { UserService } from "src/app/services/user.service";
import { SubSink } from "subsink";
import { DropDownModel } from "../general-search/drop-down-model";
import { FilterProvider } from "../general-search/filter-provider";
import { RichDataStoreCake } from "src/app/models/api/models/staging/RichDataStoreCake";
import { UserDetailsRow } from "src/app/models/user.model";
import { UtilFunctionsService } from "src/app/services/util-functions.service";
import { DestinationActionEvent, DestinationDialogActionType } from "src/app/models/dialog-actions.model";

export class DestinationRecordView implements RecordView<DataStore, dss.ObjectProtocolRow> {
  object: DataStore;
  lastUpdated?: dss.ObjectProtocolRow;
  info: any;
  fields: DataStoreField[] = [];
  type: DataStoreTypeInfo[] = [];
  workflows: WorkflowRepositoryEntry[] = [];
  schedulePlans: ScheduleActionPlan[] = [];
  datasources: dss.DataSource[] = [];
  permissions: DataStorePermission[] = [];
  loading: boolean = false;
  image?: any;
  shared?: any[];


  constructor(object: DataStore) {
    this.object = object
  }
}

class FilterUtil {
  static filterObjects(params: [Map<string, DropDownModel>, Map<string, FilterProvider<any>>], objList: DestinationRecordView[]) {

    let intermediateDataSets = objList;

    for (let providerEntry of params[1].keys()) {

      const provider = params[1].get(providerEntry);
      if (provider === undefined) continue;

      // TODO: get all filters from drop downs
      //console.log("Has Entry");
      //console.log(params[0].has(providerEntry));

      console.log("Get Entry");
      const xxx = params[0].get(providerEntry);
      console.log(xxx);

      const dropDownSelection: DropDownEntry<{}>[] | undefined = params[0].get(providerEntry)?.selection;

      console.log("selection");
      console.log(dropDownSelection);

      if (dropDownSelection === undefined) continue;
      const dropDownValues = dropDownSelection.map(s => s.value);

      if (dropDownValues.length == 0) continue;

      console.log(intermediateDataSets);

      intermediateDataSets = intermediateDataSets.filter(ds => {
        const foundValues = dropDownValues.filter(va => {
          return provider.filter(va, ds)
        });
        //console.log(foundValues);
        return foundValues.length > 0
      });
    }
    return intermediateDataSets;
  }
}

export class DestinationRecord implements RecordProvider<DataStore, DestinationRecordView, [dss.ObjectProtocolRow[], DataStoreField[], DataStoreTypeInfo[], ScheduleActionPlan[], ScheduleBaseAction[], WorkflowRepositoryEntry[], dss.DataSource[], DataStorePermission[]], dss.ObjectProtocolRow>{
  constructor(private bionApi: ApiBackendService, private userService: UserService, private dataStoreService: CubesService, private objectFilterService: ObjectSearchService, private errorService: SystemMessageLogService) { }
  sortEntries(entries: DestinationRecordView[]): DestinationRecordView[] {
    let sortedProtocols = entries.sort(
      (objA, objB) => RecordViewSorter.sortLastUpdate(objA, objB)
    );

    return sortedProtocols;
  }


  getData(): Observable<[dss.ObjectProtocolRow[], DataStoreField[], DataStoreTypeInfo[], ScheduleActionPlan[], ScheduleBaseAction[], WorkflowRepositoryEntry[], dss.DataSource[], DataStorePermission[]]> {
    let objectProtocolObs = this.bionApi.getObjectProtocolRow();

    let dsFieldsObs = this.bionApi.getDataStoreField();
    let dsTypesObs = this.bionApi.getDataStoreTypes();

    let schedulesObs = this.bionApi.getScheduleActionPlan();
    let scheduleActionsObs = this.bionApi.getScheduleActions();
    let workflowsObs = this.bionApi.getWorkflowObjectList();
    let datasourcesObs = this.bionApi.getDataSources();
    let permissionsObs = this.bionApi.getDataStorePermission();

    let finalRecordsObs = forkJoin(objectProtocolObs, dsFieldsObs, dsTypesObs, schedulesObs, scheduleActionsObs, workflowsObs, datasourcesObs, permissionsObs);

    return finalRecordsObs;
  }

  asHeader(): HeaderRow[] {
    let cols: HeaderRow[] = [
      { field: 'object.name', header: 'Destination', width: "40%" },
      { field: 'lastUpdated.start', header: 'LastUpdated', width: "40%" },
      // { field: 'fields', header: 'Fields', width: "10%" },
      // { field: 'info', header: 'Size', width:"10%" },
    ];

    return cols
  }
  asRecord(arg: DataStore, data: [dss.ObjectProtocolRow[], DataStoreField[], DataStoreTypeInfo[], ScheduleActionPlan[], ScheduleBaseAction[], WorkflowRepositoryEntry[], dss.DataSource[], DataStorePermission[]]): DestinationRecordView {
    //console.log(arg,data);
    let recordView: DestinationRecordView = new DestinationRecordView(arg);

    recordView.object = arg;
    let objProtocol = data[0].filter((log) => { return log.objectType === "DataStore" && parseInt(log.objectId) === arg.id });
    //let connector = data[1].find((connector) => { return connector.DataSource === arg.id});
    let dsFields = data[1].filter((field) => { return field.dataStore == arg.id });
    //let dsPermissions = data[4].filter((perm) => { return perm.Accessible.ID == arg.id});
    //let adapterInfo = data[2].find((adapter) => { return adapter.Name === connector.Connector});

    if (objProtocol && objProtocol.length === 0) {
      console.log("DS has no protocol:", arg);
      return recordView
      //throw new Error("No protocol found for this datastore");
    }
    let latestProtocol = objProtocol.reduce((a, b) => {
      return new Date(a.start) > new Date(b.start) ? a : b;
    });



    // Assign to data record
    recordView.lastUpdated = latestProtocol;
    recordView.permissions = data[7].filter((perm) => { return perm.Accessible.ID === arg.id });
    recordView.type = data[2];
    recordView.fields = dsFields;
    recordView.info = "TODO";

    recordView.loading = false;


    //Find related workflows
    let workflows = data[5];

    let filteredWorkflows: WorkflowRepositoryEntry[] = [];

    for (let workflow of workflows) {
      let wfData = workflow.workflowData;
      let wfNodes = wfData.Nodes;
      //console.log(wfNodes);
      let filteredNodes = wfNodes.filter((node) => { return node.Name == "DataStoreOutput" });

      for (let node of filteredNodes) {
        //console.log("node",node);
        let config = <DataStoreOutPlugInSettings>node.Properties.Configuration;
        let ds = config.SelectedDataStore;
        //console.log("DataStoreConfig",ds);
        if (ds === undefined) {
          break
        }
        if (config.SelectedDataStore?.id === arg.id) {
          filteredWorkflows.push(workflow);
        }
      }
    }
    recordView.workflows = filteredWorkflows;

    // Find related schedule Actions TODO!!!!!!
    let dsSchedules = data[3];
    let dsScheduleActions = data[4];

    let runWfActions = <RunWorkflowAction[]>dsScheduleActions.filter((schedule) => {
      return schedule._type = "models.scheduler.RunWorkflowAction"
    })
    //console.log("filtered dsActions",dsActions);

    let filteredSchedules: ScheduleActionPlan[] = [];

    for (let action of runWfActions) {
      let wfId = action.workflow;
      let foundWf = filteredWorkflows.find((wf) => { return wf.id === wfId });

      if (foundWf) {
        let wfNodes = foundWf.workflowData.Nodes;
        let wfInputNodes = wfNodes.filter((node) => {
          return node.Name === "DataStoreOutput"
        })

        wfInputNodes.forEach(() => {
          let dsFound = dsSchedules.find((sc) => { return sc.id === action.actionPlan })
          if (dsFound) {
            filteredSchedules.push(dsFound);
          }
        })
      }
    }
    recordView.schedulePlans = filteredSchedules;


    //Find related DataSources
    let datasources = data[6];
    // Filter RunActions
    let runAction = <RunWorkflowAction[]>dsScheduleActions.filter((schedule) => {
      return schedule._type = "models.scheduler.RunWorkflowAction"
    })

    let foundDatasourceArray: dss.DataSource[] = [];
    for (let action of runAction) {
      let wfId = action.workflow;
      let foundWf = filteredWorkflows.find((wf) => { return wf.id === wfId });

      if (foundWf) {
        let wfNodes = foundWf.workflowData.Nodes;
        let wfInputNodes = wfNodes.filter((node) => {
          return node.Name === "PsaInput"
        })

        wfInputNodes.forEach((node) => {
          let nodeConfig = <PsaInputPlugIn.Settings>node.Properties.Configuration;
          let dsFound = datasources.filter((ds) => { return ds.id === nodeConfig.SelectedDataSource?.ID })

          if (dsFound && dsFound.length > 0) {
            foundDatasourceArray = [...foundDatasourceArray, ...dsFound];
          }
        })
      }
    }

    recordView.datasources = foundDatasourceArray;

    // Set Loading Status - TODO!!!!!!!
    // Check if datasource is in List of ExtractJobs


    return recordView

  }
  asActions(): MenuItem[] {
    let dialogActions: MenuItem[] = [
      {
        label: 'Change', tooltip: "Edit destination", styleClass: "p-button-rounded p-button-text p-mr-2 p-mb-2", icon: "pi pi-cog", command: () => {
          this.dataStoreService.destinationDialogActionSendEmitter.emit(new DestinationActionEvent(true, "Edit destination", DestinationDialogActionType.editDestination, "Save", this.selectedDestination));
          

        }
      },
      {
        label: 'Delete', tooltip: "Delete destination", styleClass: "p-button-rounded p-button-danger p-button-text p-mr-2 p-mb-2", icon: "pi pi-trash", command: () => {
          this.dataStoreService.destinationDialogActionSendEmitter.emit(new DestinationActionEvent(true, "Delete destination", DestinationDialogActionType.deleteDestination, "Delete", this.selectedDestination));

        }
      },
    ];

    return dialogActions
  }
  subscribeToEmitter(objList: DataStore[]) {
    this.subs.sink = this.dataStoreService.selectedDataStoreEmitter.subscribe((res: DataStore) => {
      this.selectedDestination = res;
      //console.log(this.selectedDestination);
    },
      (err: Error) => {
        this.errorService.handleError(err);
      });
    this.subs.sink = this.objectFilterService.objectSearchParamsEmitter.subscribe((params) => {
      //console.log(params);

      this.subs.sink = this.getData().subscribe((finalRecordResult) => {
        if (objList) {
          //console.log(finalRecordResult);
          let objListViews: DestinationRecordView[] = objList.map((obj) => {
            return this.asRecord(obj, finalRecordResult);
          });
          let filteredObjects = this.filterObjects(params, objListViews);

          this.objectFilterService.emitChangedFilteredObjectList(filteredObjects);

          console.log("Filtered Objects", filteredObjects);
        }
      },
        (err: Error) => {
          this.errorService.handleError(err);
        });
    });

  }
  emitObjectAction(action: string, object: any) {
    throw new Error('Method not implemented.');
  }

  filterObjects(params: [Map<string, DropDownModel>, Map<string, FilterProvider<any>>], objList: DestinationRecordView[]) {
    return FilterUtil.filterObjects(params, objList);
  }
  subs = new SubSink;
  selectedDestination?: DataStore;

}



export class DestinationViewRecord implements RecordProvider<DataStore, DestinationRecordView, RichDataStoreCake.DataStoreView[], dss.ObjectProtocolRow>{
  constructor(private bionApi: ApiBackendService, private userService: UserService, private dataStoreService: CubesService, private objectFilterService: ObjectSearchService, private errorService: SystemMessageLogService, private data: RichDataStoreCake.DataStoreView[], private users: UserDetailsRow[], private utilService: UtilFunctionsService) { }
  sortEntries(entries: DestinationRecordView[]): DestinationRecordView[] {
    let sortedProtocols = entries.sort(
      (objA, objB) => RecordViewSorter.sortLastUpdate(objA, objB)
    );

    return sortedProtocols;
  }


  getData(): Observable<RichDataStoreCake.DataStoreView[]> {
    return of(this.data);
  }

  asHeader(): HeaderRow[] {
    let cols: HeaderRow[] = [
      { field: 'object.name', header: 'Destination', width: "55%" },
      { field: 'object.writeMode', header: 'WriteMode', width: "10%" },
      // { field: 'fields', header: 'Fields', width: "10%" },
      { field: 'permissions', header: 'SharedWith', width: "10%" },
      { field: 'lastUpdated.start', header: 'LastUpdated', width: "15%" },
      // { field: 'lastUpdated.user', header: 'von', width: "10%" },
    ];

    return cols
  }

  asRecord(arg: DataStore, data: RichDataStoreCake.DataStoreView[]): DestinationRecordView {


    const v = data.find(d => d.DataStore.id == arg.id);
    if (v) {
      const view = new DestinationRecordView(arg);
      view.object = v.DataStore;
      //view.connector = v.ConnectorSettings;
      //view.fields = v.Fields.map(f => DestinationViewRecord.asField(f));
      view.fields = v.Fields;
      view.permissions = v.Permissions.map(p => p.Entry);
      view.workflows = v.Workflows;
      //view.adapter = v.ConnectorInfo;
      view.datasources = [];
      view.schedulePlans = [];

      
      const permSorted = view.permissions.sort((a, b) => {
        if (a.IsOwner === b.IsOwner) {
          return 0;
        }
        return a.IsOwner ? -1 : 1;
      })
      const perm_disp_limit = 3;
      const permSorted_cut = permSorted.slice(0,2);
      let others_count: number = 0;
      
        if(permSorted.length > perm_disp_limit) {
          others_count = 0 ;
    
        } else {
          others_count = permSorted.length - permSorted_cut.length;
        }

    view.shared = permSorted_cut.map((p) => {
        
        const userdetailinfos = this.users.find ( (detail) => {return p.Role == detail.id});

        if(userdetailinfos && userdetailinfos.avatar) {
          const image = this.utilService.int8ArrayToBase64Image(userdetailinfos.avatar)
          return image;
        } else {
            return undefined;
        }
    });
    view['OtherCount'] = others_count>0 ? "+"+others_count.toString(): undefined;


      const objProtocol = v.Protocols.map(p => p.Entry);

      const latestProtocol = objProtocol.reduce((a, b) => {
        return new Date(a.start) > new Date(b.start) ? a : b;
      });

      view.lastUpdated = latestProtocol;

      // function to get user avatar
      const userFound = this.users.find((user) => { return user.id === latestProtocol?.userId });

      if (userFound && userFound.avatar) {
        view.image = this.utilService.int8ArrayToBase64Image(userFound.avatar);
      } else {
        view.image = undefined;
      }

      view.info = "TODO";

      return view;
    } else {
      throw new Error("The Data Source " + arg + "does not exist!");
    }
  }
  asActions(): MenuItem[] {
    let dialogActions: MenuItem[] = [
      {
        label: 'Change', tooltip: "Edit destination", styleClass: "p-button-rounded p-button-text p-mr-2 p-mb-2", icon: "pi pi-info", command: () => {
          //this.dataStoreService.displayEditDataStoreDialog.emit(true);
          this.dataStoreService.destinationDialogActionSendEmitter.emit(new DestinationActionEvent(true, "Edit destination", DestinationDialogActionType.editDestination, "Save", this.selectedDestination));


        }
      },
      {
        label: 'Delete', tooltip: "Delete destination", styleClass: "p-button-rounded p-button-danger p-button-text p-mr-2 p-mb-2", icon: "pi pi-trash", command: () => {
          //this.dataStoreService.displayDeleteDataStoreDialog.emit(true);
          this.dataStoreService.destinationDialogActionSendEmitter.emit(new DestinationActionEvent(true, "Delete destination", DestinationDialogActionType.deleteDestination, "Delete", this.selectedDestination));


        }
      },
    ];

    return dialogActions
  }
  subscribeToEmitter(objects: DataStore[]) {
    this.subs.sink = this.dataStoreService.selectedDataStoreEmitter.subscribe((res: DataStore) => {
      this.selectedDestination = res;
    },
      (err: Error) => {
        this.errorService.handleError(err);
      });
    this.subs.sink = this.objectFilterService.objectSearchParamsEmitter.subscribe((params) => {

      this.subs.sink = this.getData().subscribe(finalRecordResult => {
        if (objects) {
          //console.log(finalRecordResult);
          const objListViews: DestinationRecordView[] = objects.map(obj => this.asRecord(obj, finalRecordResult));
          const filteredObjects = this.filterObjects(params, objListViews);

          this.objectFilterService.emitChangedFilteredObjectList(filteredObjects);

          console.log("Filtered Objects", filteredObjects);
        }
      },
        (err: Error) => {
          this.errorService.handleError(err);
        });
    });

  }
  emitObjectAction(action: string, object: any) {
    if (action === "CreateNewObject") {
      console.log("CreateNewObject")
      //this.dataStoreService.displayNewDataStoreDialog.emit(true);
      this.dataStoreService.destinationDialogActionSendEmitter.emit(new DestinationActionEvent(true, "Create destination", DestinationDialogActionType.createDestination, "Create", undefined));

      return
    }
  }

  filterObjects(params: [Map<string, DropDownModel>, Map<string, FilterProvider<any>>], objList: DestinationRecordView[]) {
    return FilterUtil.filterObjects(params, objList);
  }

  subs = new SubSink;
  selectedDestination?: DataStore;
}
