import { TargetMetaAccess } from "../com/bion/connect/TargetAccessLike";

export namespace DestinationController {
    export class GetMetaDataArg<A> {
		Id: number;
		Access: TargetMetaAccess<A>;
		constructor(Id: number,Access: TargetMetaAccess<A>) {
			if(Id === undefined) throw new Error( "Class 'GetMetaDataArg': Required value 'Id' is undefined" );
			if(Access === undefined) throw new Error( "Class 'GetMetaDataArg': Required value 'Access' is undefined" );
			this.Id = Id;
			this.Access = Access;
		}
	}
}