import { Component, OnInit } from '@angular/core';
import { Id } from 'src/app/helper/id';
import { FieldInfo, WorkflowNodeSettings } from 'src/app/models/api/com/bion/etl/Workflow';
import { GuiDropDown } from 'src/app/models/helperClass.model';
import { RandomSamplingSettings, RateParamsInfo, SampleAtConstantRateSettings, SampleByConditionSettings, SampleRandomColumnsSettings, SamplingFilterInfo, SamplingSettings, WeightedSamplingSettings, WeightedValueInfo } from 'src/app/models/nodeSettings.model';
// import { FieldInfo, WorkflowNodeSettings } from 'src/app/models/workflow.model';
import { DesignerService } from 'src/app/services/designer.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { NodeConfigComponentBase } from '../node-config-component-base';

// export class SamplingSettingsView {
//     Method: number;
//     RandomSamplingSettings: RandomSamplingSettings;
//     WeightedSamplingSettings: WeightedSamplingSettings;
//     ConstantRateSettings: SampleAtConstantRateSettings;
//     SampleByConditionSettings: SampleByConditionSettings;
//     SampleRandomColumnsSettings: SampleRandomColumnsSettings;
//     hasError?: boolean
// }

export class SamplingSettingsView {
    settings: SamplingSettings;
    hasError?: boolean
    constructor(settings: SamplingSettings,hasError?: boolean) {
        this.settings = settings;
        this.hasError = hasError;
    }
}

export class MethodOptionDropDown {
    name: string;
    value: number;

    constructor(name:string,value:number) {
        this.name = name;
        this.value = value;
    }
}

@Component({
    selector: 'app-sampling-node',
    templateUrl: './sampling-node.component.html',
    styleUrls: ['./sampling-node.component.scss']
})
export class SamplingNodeComponent extends NodeConfigComponentBase<SamplingSettings> implements OnInit {

    columnName?: string;
    columnNames: string[] = [];
    columnList: string[] = [];
    SampleReplacementBoolean: boolean = false;
    UseAbsSample: boolean = false;
    sampleSizeInput: number = 0;  // TODO: check
    samplingMethods: MethodOptionDropDown[] = SamplingNodeComponent.getSamplingMethods();
    selectedSamplingMethod: MethodOptionDropDown = this.samplingMethods[0];
    samplingSettings?: SamplingSettingsView;
    WeightedValues: WeightedValueInfo[] = [];
    SamplingFilterInfos: SamplingFilterInfo[] = [];
    StartRow?: number;
    EndRow?: number;
    StepRow: number = 1;

    filterTypeOptions: GuiDropDown[] = SamplingNodeComponent.getFilterTypeOptions();
    matchModeOptions: GuiDropDown[] = SamplingNodeComponent.getMatchModeOptions();
    selectedMatchMode: GuiDropDown = this.matchModeOptions[0];

    readonly InputPort = "Input";

    static getSamplingMethods() : MethodOptionDropDown[] {
        // -- Initiliaize Modus options
        const samplingMethods: MethodOptionDropDown[] = [
            { name: "Random", value: 1 },
            { name: "Weighted samples", value: 2 },
            { name: "Sample at constant rate samples", value: 3 },
            // {name: "Sample items by condition", value:4},
            { name: "Sample random columns", value: 5 },
        ];

        return samplingMethods;
    }

    // static getDefaultSamplingSettingsView(methods:MethodOptionDropDown[]) {
    //     const samplingSettings = new SamplingSettingsView();
    //     samplingSettings.settings.Method = methods[0].value;
    //     samplingSettings.hasError = false;

    //     return samplingSettings;
    // }

    // static getDefaultSettings() : SamplingSettings {
    //     new SamplingSettings(0, new RandomSamplingSettings())
    // }

    settingsToView(settings: WorkflowNodeSettings): void {
        // -- Get Configuration
        let currentConfig = <SamplingSettings>settings;
        // -- Get Input Meta Data
        const meta_infos =
            this._currentWorkflowNodeAdapter.IWorkflowNode.Properties.MetaInfo.get(
                this.InputPort
            );
        let columnListArray: string[] = [];

        // // -- Initiliaize Modus options
        // let samplingMethods: MethodOptionDropDown[] = [
        //     { name: "Random", value: 1 },
        //     { name: "Weighted samples", value: 2 },
        //     { name: "Sample at constant rate samples", value: 3 },
        //     // {name: "Sample items by condition", value:4},
        //     { name: "Sample random columns", value: 5 },
        // ];

        // this.samplingMethods = samplingMethods;

        // // -- create default object
        // let samplingSettings = new SamplingSettingsView();
        // samplingSettings.Method = this.samplingMethods[0].value;
        // samplingSettings.hasError = false;
        // this.samplingSettings = samplingSettings;
        this.UseAbsSample = false;
        this.SampleReplacementBoolean = false;

        // -- check for MetaInfos from Input Port & store dsField list
        if (meta_infos !== undefined && meta_infos.length > 0) {
            const input_field_infos = meta_infos[0].FieldsInfo;

            // -- create string array for column dropdown
            columnListArray = input_field_infos.map((fieldInfo) => {
                return fieldInfo.Name;
            });
            this.columnList = columnListArray;
        }
        // -- if no configuration exists, exit
        if (currentConfig === undefined) {
            // -- if undefined, exit
            return;
        } else {
            // -- otherwise load from existing config
            if (!currentConfig) return;
            this.columnList = columnListArray;


            if (meta_infos !== undefined) {
                let field_infos = meta_infos[0].FieldsInfo;
                this.samplingSettings = this.infoToView(currentConfig, field_infos);
            }
        }

    }
    viewToSettings(): SamplingSettings {
        const newSettings = <SamplingSettings>{
            ...this.getCurrentWorkflowNode().Properties.Configuration,
        };

        newSettings.Method = this.selectedSamplingMethod.value;


        if (newSettings.Method === 1) {
            newSettings.RandomSamplingSettings.ColumnName = this.columnName ? this.columnName : "";
            newSettings.RandomSamplingSettings.SampleWithReplacement = this.SampleReplacementBoolean;
            newSettings.RandomSamplingSettings.UseAbsSample = this.UseAbsSample;

            if (!this.UseAbsSample) {
                newSettings.RandomSamplingSettings.SamplePercentage = this.sampleSizeInput;
            }
            if (this.UseAbsSample) {
                newSettings.RandomSamplingSettings.SampleSize = this.sampleSizeInput;
            }
        }

        if (newSettings.Method === 2) {
            newSettings.WeightedSamplingSettings.ColumnName = this.columnName ? this.columnName : "";
            newSettings.WeightedSamplingSettings.SampleWithReplacement = this.SampleReplacementBoolean;
            newSettings.WeightedSamplingSettings.UseAbsSample = this.UseAbsSample;

            if (!this.UseAbsSample) {
                newSettings.WeightedSamplingSettings.SamplePercentage = this.sampleSizeInput;
                //newSettings.WeightedSamplingSettings.SampleSize = 100;
            }
            if (this.UseAbsSample) {
                //newSettings.WeightedSamplingSettings.SamplePercentage = 1;
                newSettings.WeightedSamplingSettings.SampleSize = this.sampleSizeInput;
            }
            newSettings.WeightedSamplingSettings.WeightedValues = this.WeightedValues;
        }

        if (newSettings.Method === 3) {
            newSettings.ConstantRateSettings = <SampleAtConstantRateSettings>newSettings.ConstantRateSettings;
            newSettings.ConstantRateSettings.ColumnName = this.columnName ? this.columnName : "";

            let params = new RateParamsInfo(this.StepRow, undefined, undefined); // TODO!

            if (this.StartRow) {
                params.StartValue = this.StartRow;
            }
            if (this.EndRow) {
                params.EndValue = this.EndRow;
            }
            newSettings.ConstantRateSettings.ConstantRateParams = params;

        }

        if (newSettings.Method === 4) {
            newSettings.SampleByConditionSettings = <SampleByConditionSettings>newSettings.SampleByConditionSettings;
            newSettings.SampleByConditionSettings.SampleWithReplacement = this.SampleReplacementBoolean;
            newSettings.SampleByConditionSettings.UseAbsSample = this.UseAbsSample;

            if (!this.UseAbsSample) {
                newSettings.SampleByConditionSettings.SamplePercentage = this.sampleSizeInput;
            }
            if (this.UseAbsSample) {
                newSettings.SampleByConditionSettings.SampleSize = this.sampleSizeInput;
            }


            newSettings.SampleByConditionSettings.MatchMode = this.selectedMatchMode.value;
            newSettings.SampleByConditionSettings.FilterConditions = this.SamplingFilterInfos;

        }

        if (newSettings.Method === 5) {
            newSettings.SampleRandomColumnsSettings.ColumnNames = this.columnNames;
            newSettings.SampleRandomColumnsSettings.SamplePercentage;
            newSettings.SampleRandomColumnsSettings.UseAbsSample = this.UseAbsSample;

            if (!this.UseAbsSample) {
                newSettings.SampleRandomColumnsSettings.SamplePercentage = this.sampleSizeInput;
                newSettings.SampleRandomColumnsSettings.SampleSize = 100;
            }
            if (this.UseAbsSample) {
                newSettings.SampleRandomColumnsSettings.SamplePercentage = 1;
                newSettings.SampleRandomColumnsSettings.SampleSize = this.sampleSizeInput;
            }

        }



        return newSettings;
    }
    onSettingsChanged(settings: SamplingSettings): void {
        throw new Error('Method not implemented.');
    }

    constructor(
        protected workflowService: WorkflowsService,
        protected designerService: DesignerService
    ) {
        super(workflowService, designerService);

    }

    static getMatchModeOptions() {
        const options =  [
            { name: "Match all (AND)", value: "and" },
            { name: "Match any (OR)", value: "or" },
        ];

        return options;
    }

    static getFilterTypeOptions() {
        return [
            { name: "equals", value: "equals" },
            { name: "does not equal", value: "doesNotEqual" },
            { name: "greater than", value: "greaterThan" },
            { name: "greater or equal", value: "greaterOrEqual" },
            { name: "less than", value: "lessThan" },
            { name: "less or equal", value: "lessOrEqual" },
            { name: "contains", value: "contains" },
            { name: "does not contain", value: "doesNotContain" },
        ];
    }

    ngOnInit(): void {

    }

    infoToView(config: SamplingSettings, metaFieldInfos: FieldInfo[]): SamplingSettingsView {

        const view = new SamplingSettingsView(config, false);

        //let view = new SamplingSettingsView();
        view.settings.Method = config.Method;
        
        const found_method = this.samplingMethods.find((method) => { return method.value === config.Method });
        this.selectedSamplingMethod = found_method ? found_method : this.selectedSamplingMethod;
        this.selectedMatchMode = this.matchModeOptions[0];
        console.log("Start infoToView")

        // Method - Random Sampling
        if (config.Method === 1) {
            view.settings.RandomSamplingSettings = <RandomSamplingSettings>config.RandomSamplingSettings;
            this.columnName = config.RandomSamplingSettings.ColumnName;

            const colFound = metaFieldInfos.find(fi => fi.Name === config.RandomSamplingSettings.ColumnName);

            if (!colFound) {
                view.hasError = true;
                this.columnList.push(config.RandomSamplingSettings.ColumnName);

            } else {
                view.hasError = false;
            }

            this.SampleReplacementBoolean = config.RandomSamplingSettings.SampleWithReplacement;
            this.UseAbsSample = config.RandomSamplingSettings.UseAbsSample;

            if (config.RandomSamplingSettings.UseAbsSample) {
                this.sampleSizeInput = config.RandomSamplingSettings.SampleSize;
            } else {
                this.sampleSizeInput = config.RandomSamplingSettings.SamplePercentage;
            }

        }

        // Method - Weighted Sampling
        if (config.Method === 2) {
            view.settings.WeightedSamplingSettings = <WeightedSamplingSettings>config.WeightedSamplingSettings;
            this.columnName = config.WeightedSamplingSettings.ColumnName;
            const colFound = metaFieldInfos.find(fi => fi.Name === config.RandomSamplingSettings.ColumnName);

            if (!colFound) {
                view.hasError = true;
                this.columnList.push(config.RandomSamplingSettings.ColumnName);

            } else {
                view.hasError = false;
            }
            this.SampleReplacementBoolean = config.WeightedSamplingSettings.SampleWithReplacement;
            this.WeightedValues = config.WeightedSamplingSettings.WeightedValues;
            this.UseAbsSample = config.WeightedSamplingSettings.UseAbsSample;

            if (config.WeightedSamplingSettings.UseAbsSample) {
                this.sampleSizeInput = config.WeightedSamplingSettings.SampleSize;
            } else {
                this.sampleSizeInput = config.WeightedSamplingSettings.SamplePercentage;
            }
        }

        // Method - Constant Rate Sampling
        if (config.Method === 3) {
            view.settings.ConstantRateSettings = <SampleAtConstantRateSettings>config.ConstantRateSettings;
            this.columnName = config.ConstantRateSettings.ColumnName;
            const colFound = metaFieldInfos.find(fi => fi.Name === config.RandomSamplingSettings.ColumnName);

            if (!colFound) {
                view.hasError = true;
                this.columnList.push(config.RandomSamplingSettings.ColumnName);

            } else {
                view.hasError = false;
            }
            this.StepRow = config.ConstantRateSettings.ConstantRateParams.StepValue;

            if (config.ConstantRateSettings.ConstantRateParams.StartValue) this.StartRow = config.ConstantRateSettings.ConstantRateParams.StartValue;
            if (config.ConstantRateSettings.ConstantRateParams.EndValue) this.EndRow = config.ConstantRateSettings.ConstantRateParams.EndValue;

        }

        // Method - Sample by condition
        if (config.Method === 4) {
            view.settings.SampleByConditionSettings = <SampleByConditionSettings>config.SampleByConditionSettings;
            //this.columnName = config.SampleByConditionSettings.ColumnName;

            this.SampleReplacementBoolean = config.SampleByConditionSettings.SampleWithReplacement;
            this.UseAbsSample = config.SampleByConditionSettings.UseAbsSample;

            if (config.SampleByConditionSettings.FilterConditions) {
                this.SamplingFilterInfos = config.SampleByConditionSettings.FilterConditions;
            }

            if (config.SampleByConditionSettings.MatchMode) {
                const match_mode_opt = this.matchModeOptions.find((option) => { return option.value === config.SampleByConditionSettings.MatchMode });
                const match_mode = Id.assertSet(match_mode_opt, new Error("Match mode assertion failed!"));
                this.selectedMatchMode = match_mode;
            } else {
                this.selectedMatchMode = this.matchModeOptions[0];
            }
        }

        if (config.Method === 5) {
            view.settings.SampleRandomColumnsSettings = <SampleRandomColumnsSettings>config.SampleRandomColumnsSettings;
            this.columnNames = config.SampleRandomColumnsSettings.ColumnNames;

            const colFound = metaFieldInfos.find(fi => fi.Name === config.RandomSamplingSettings.ColumnName);

            if (!colFound) {
                view.hasError = true;

            } else {
                view.hasError = false;
            }
        }

        return view
    }
    addRow() {
        let row = new WeightedValueInfo("", 0);
        this.WeightedValues.push(row);
        this.onUpdateSettings(false);
    }
    onAddFilter() {
        // -- create new filterInfo view
        let newFilter = new SamplingFilterInfo(this.columnList[0], this.filterTypeOptions[0].value, "");

        // -- push new filterInfoView to FilterArray
        this.SamplingFilterInfos.push(newFilter);

        this.onUpdateSettings(false);
    }
    onDeleteEntry(index:number) {
        this.WeightedValues = [
            ...this.WeightedValues.slice(0, index),
            ...this.WeightedValues.slice(index + 1),
        ];
        this.onUpdateSettings(false);
    }

}
