import { WorkflowRepositoryEntry } from "src/app/models/api/models/workflow/WorkflowRepositoryEntry";
import { DataStore } from "src/app/models/datastore.model";
import { ScheduleActionPlan, ScheduleBaseAction, ActionPlanPermission, ExtractDataSourceAction, RunWorkflowAction } from "src/app/models/schedule.model";
import { DropDownEntry, HeaderRow, RecordProvider, RecordView, RecordViewSorter } from "./record-provider";
import * as dss from 'src/app/models/datasource.model';
import { MenuItem } from "primeng/api";
import { Observable, forkJoin, of } from "rxjs";
import { DataStoreOutPlugInSettings } from "src/app/models/nodeSettings.model";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { ObjectSearchService } from "src/app/services/object-search.service";
import { SchedulesService } from "src/app/services/schedules.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { SubSink } from "subsink";
import { DropDownModel } from "../general-search/drop-down-model";
import { FilterProvider } from "../general-search/filter-provider";
import { RichSchedulerCake } from "src/app/models/api/models/scheduler/RichSchedulerCake";
import { UserDetailsRow } from "src/app/models/user.model";
import { UtilFunctionsService } from "src/app/services/util-functions.service";
import { ScheduleActionEvent, ScheduleDialogActionType } from "src/app/models/dialog-actions.model";

export class SchedulePlanRecordView implements RecordView<ScheduleActionPlan, dss.ObjectProtocolRow> {
  object: ScheduleActionPlan;
  lastUpdated?: dss.ObjectProtocolRow;
  info: any;
  status: any;
  events: ScheduleBaseAction[] = [];
  workflows: WorkflowRepositoryEntry[] = [];
  datasources: dss.DataSource[] = [];
  datastores: DataStore[] = [];
  permissions: ActionPlanPermission[] = [];
  loading: boolean = false;
  image?: any;
  shared?: any[];

  constructor(object: ScheduleActionPlan) {
    this.object = object
  }
}

class FilterUtil {
  static filterObjects(params: [Map<string, DropDownModel>, Map<string, FilterProvider<any>>], objList: SchedulePlanRecordView[]) {

    let intermediateDataSets = objList;

    for (let providerEntry of params[1].keys()) {

      const provider = params[1].get(providerEntry);
      if (provider === undefined) continue;

      // TODO: get all filters from drop downs

      console.log("Get Entry");
      const xxx = params[0].get(providerEntry);
      console.log(xxx);

      const dropDownSelection: DropDownEntry<{}>[] | undefined = params[0].get(providerEntry)?.selection;

      console.log("selection");
      console.log(dropDownSelection);

      if (dropDownSelection === undefined) continue;
      const dropDownValues = dropDownSelection.map(s => s.value);

      if (dropDownValues.length == 0) continue;

      console.log(intermediateDataSets);

      intermediateDataSets = intermediateDataSets.filter(ds => {
        const foundValues = dropDownValues.filter(va => {
          return provider.filter(va, ds)
        });
        //console.log(foundValues);
        return foundValues.length > 0
      });
    }
    return intermediateDataSets;
  }
}

// OLD!!!!!!!!
export class ScheduleRecord implements RecordProvider<ScheduleActionPlan, SchedulePlanRecordView, [dss.ObjectProtocolRow[], ScheduleBaseAction[], WorkflowRepositoryEntry[], dss.DataSource[], DataStore[], ActionPlanPermission[]], dss.ObjectProtocolRow>{
  constructor(private bionApi: ApiBackendService, private objectFilterService: ObjectSearchService, private schedulesService: SchedulesService, private errorService: SystemMessageLogService) { }

  sortEntries(entries: SchedulePlanRecordView[]): SchedulePlanRecordView[] {
    let sortedProtocols = entries.sort(
      (objA, objB) => RecordViewSorter.sortLastUpdate(objA,objB)
    );

    return sortedProtocols;
  }

  getData(): Observable<[dss.ObjectProtocolRow[], ScheduleBaseAction[], WorkflowRepositoryEntry[], dss.DataSource[], DataStore[], ActionPlanPermission[]]> {
    let objectProtocolObs = this.bionApi.getObjectProtocolRow();
    let scheduleActionsObs = this.bionApi.getScheduleActions();
    let workflowsObs = this.bionApi.getWorkflowObjectList();
    let datasourcesObs = this.bionApi.getDataSources();
    let dataStoresObs = this.bionApi.getDataStoreObjectList();
    let permissionsObs = this.bionApi.getActionPlanPermission();

    let finalRecordsObs = forkJoin(objectProtocolObs, scheduleActionsObs, workflowsObs, datasourcesObs, dataStoresObs, permissionsObs);

    return finalRecordsObs;
  }

  asHeader(): HeaderRow[] {
    let cols: HeaderRow[] = [
      { field: 'object.name', header: 'Schedule', width: "50%" },
      { field: 'object.status', header: 'Status', width: "10%" },
      // { field: 'events', header: 'Events', width: "10%" },
      { field: 'permissions', header: 'SharedWith', width: "10%" },
      { field: 'lastUpdated.start', header: 'LastUpdated', width: "10%" },
      //{ field: 'lastUpdated.user', header: 'by', width: "10%" },
    ];

    return cols
  }
  asRecord(arg: ScheduleActionPlan, data: [dss.ObjectProtocolRow[], ScheduleBaseAction[], WorkflowRepositoryEntry[], dss.DataSource[], DataStore[], ActionPlanPermission[]]) {
    let protocol = data[0];
    let actions = data[1];
    let workflows = data[2];
    let datasources = data[3];
    let datastores = data[4];
    //find related permission
    let permissions = data[5].filter((perm) => { return perm.Accessible.ID == arg.id });


    let recordView: SchedulePlanRecordView = new SchedulePlanRecordView(arg);
    recordView.object = arg;
    recordView.permissions = permissions;
    let objProtocol = protocol.filter((log) => { return log.objectType === "ActionPlan" && parseInt(log.objectId) === arg.id });

    if (objProtocol && objProtocol.length === 0) {
      console.log("WF has no protocol:", arg);
      return recordView
      //throw new Error("No protocol found for this WF");
    }
    let latestProtocol = objProtocol.reduce((a, b) => {
      return new Date(a.start) > new Date(b.start) ? a : b;
    });
    recordView.lastUpdated = latestProtocol;

    recordView.events = actions.filter((action) => { return action.actionPlan === arg.id });
    recordView.loading = false;

    // get different actions
    let extractActions = <ExtractDataSourceAction[]>actions.filter((action) => { return action._type === "models.scheduler.ExtractDataSourceAction" });
    let runActions = <RunWorkflowAction[]>actions.filter((action) => { return action._type === "models.scheduler.RunWorkflowAction" });

    let filteredDatasources: dss.DataSource[] = [];
    let filteredWorkflows: WorkflowRepositoryEntry[] = [];

    for (let i of extractActions) {
      if (i.actionPlan === arg.id) {
        // If actionplan id matches, then get the respective dataSource
        let datasourceFound = datasources.find((ds) => { return ds.id === i.dataSource });
        if (datasourceFound) {
          filteredDatasources.push(datasourceFound);

        }

      }
    }
    for (let i of runActions) {
      if (i.actionPlan === arg.id) {
        // If actionplan id matches, then get the respective dataSource
        let workflowFound = workflows.find((wf) => { return wf.id === i.workflow });

        if (workflowFound) filteredWorkflows.push(workflowFound);


      }
    }

    recordView.datasources = filteredDatasources;
    recordView.workflows = filteredWorkflows;

    //Find related datastores within workflows
    let filteredDestinations: DataStore[] = [];
    for (let filteredWf of filteredWorkflows) {
      const wfNodes = filteredWf.workflowData.Nodes

      const filteredNodes = wfNodes.filter((node) => { return node.Name == "DataStoreOutput" });


      for (let node of filteredNodes) {
        //console.log("node",node);
        const config = <DataStoreOutPlugInSettings>node.Properties.Configuration;
        const ds = config.SelectedDataStore;
        console.log("DataStoreConfig", ds);
        if (ds === undefined) {
          break
        }
        const datastore = datastores.find((datastore) => { return datastore.id === ds?.id })
        if (datastore) filteredDestinations.push(datastore);

      }
    }
    recordView.datastores = filteredDestinations;

    return recordView


  }
  asActions(): MenuItem[] {
    let dialogActions: MenuItem[] = [
      {
        label: 'Change', tooltip: "Schedule.ChangeSchedule", styleClass: "p-button-rounded p-button-text p-mr-2 p-mb-2", icon: "pi pi-cog", command: () => {
          this.schedulesService.scheduleDialogActionSendEmitter.emit(new ScheduleActionEvent(true, "Edit schedule", ScheduleDialogActionType.editSchedule, "Save", this.selectedSchedule));
        }
      },
      {
        label: 'Delete', tooltip: "Schedules.DeleteSchedule", styleClass: "p-button-rounded p-button-danger p-button-text p-mr-2 p-mb-2", icon: "pi pi-trash", command: () => {
          this.schedulesService.scheduleDialogActionSendEmitter.emit(new ScheduleActionEvent(true, "Delete schedule", ScheduleDialogActionType.deleteSchedule, "Delete", this.selectedSchedule));

        }
      },
    ];

    return dialogActions
  }
  emitObjectAction(action: string, object: SchedulePlanRecordView) {
    this.schedulesService.selectedMenuSchedulePlanEmitter.emit(object.object);
  }
  subscribeToEmitter(objList: ScheduleActionPlan[]) {
    this.subs.sink = this.schedulesService.selectedSchedulePlanEmitter.subscribe((res: ScheduleActionPlan) => {
      this.selectedSchedule = res;
    },
      (err: Error) => {
        this.errorService.handleError(err);
      });
    this.subs.sink = this.objectFilterService.objectSearchParamsEmitter.subscribe((params) => {

      this.subs.sink = this.getData().subscribe((finalRecordResult) => {
        if (objList) {
          //console.log(finalRecordResult);
          let objListViews: SchedulePlanRecordView[] = objList.map((obj) => {
            return this.asRecord(obj, finalRecordResult);
          });
          let filteredObjects = this.filterObjects(params, objListViews);

          //this.changedFilterEmitter.emit(filteredObjects);

          this.objectFilterService.emitChangedFilteredObjectList(filteredObjects);

          console.log("Filtered Objects", filteredObjects);
        }
      },
        (err: Error) => {
          this.errorService.handleError(err);
        });
    });
  }
  filterObjects(params: [Map<string, DropDownModel>, Map<string, FilterProvider<any>>], objList: SchedulePlanRecordView[]) {
    return FilterUtil.filterObjects(params, objList);
  }

  subs = new SubSink;
  selectedSchedule?: ScheduleActionPlan;
}

export class ActionPlanViewRecord implements RecordProvider<ScheduleActionPlan, SchedulePlanRecordView, RichSchedulerCake.ActionPlanView[], dss.ObjectProtocolRow> {

  constructor(private data: RichSchedulerCake.ActionPlanView[], private userDetails: UserDetailsRow[],private schedulesService: SchedulesService,
    private objectFilterService: ObjectSearchService, private errorService: SystemMessageLogService, private utilService: UtilFunctionsService) {

  }

  getData(): Observable<RichSchedulerCake.ActionPlanView[]> {
    return of(this.data);
  }
  asRecord(arg: ScheduleActionPlan, data: RichSchedulerCake.ActionPlanView[]): SchedulePlanRecordView {
    const plan = data.find(d => d.Plan.id === arg.id);

    if (plan === undefined) throw new Error("Schedule Action Plan with id " + arg.id + " does not exist");

    const view = new SchedulePlanRecordView(arg);

    view.datasources = plan.DataSources;
    view.object = plan.Plan;
    view.permissions = plan.Permissions.map(p => p.Entry);
    view.datastores = plan.DataStores;
    view.info = "TODO";
    view.status = "TODO";
    view.events = plan.Plan.Actions.filter(act => act.actionPlan === arg.id);


    const permSorted = view.permissions.sort((a, b) => {
      if (a.IsOwner === b.IsOwner) {
        return 0;
      }
      return a.IsOwner ? -1 : 1;
    })

  view.shared = permSorted.map((p) => {

      const userdetailinfos = this.userDetails.find ( (detail) => {return p.Role == detail.id});

      if(userdetailinfos && userdetailinfos.avatar) {
        const image = this.utilService.int8ArrayToBase64Image(userdetailinfos.avatar)
          return image;
      } else {
          return undefined;
      }
  })


    const protocol = plan.Protocols.map(p => p.Entry);
    if (protocol.length > 0) {
      const latest_protocol = protocol.reduce((a, b) => new Date(a.start) > new Date(b.start) ? a : b);
      view.lastUpdated = latest_protocol;
    }

    // function to get user avatar
    const userFound = this.userDetails.find((user) => { return user.id === view.lastUpdated?.userId});

    if(userFound && userFound.avatar) {
        view.image = this.utilService.int8ArrayToBase64Image(userFound.avatar);
    } else {
        view.image = undefined;
    }

    return view;
  }
  asHeader(): HeaderRow[] {
    let cols: HeaderRow[] = [
      { field: 'object.name', header: 'Schedule', width: "55%" },
      { field: 'object.status', header: 'Status', width: "10%" },
      // { field: 'events', header: 'Events', width: "10%" },
      { field: 'permissions', header: 'SharedWith', width: "10%" },
      { field: 'lastUpdated.start', header: 'LastUpdated', width: "15%" },
      //{ field: 'lastUpdated.user', header: 'by', width: "10%" },
    ];

    return cols
  }
  asActions(): MenuItem[] {
    let dialogActions: MenuItem[] = [
      {
        label: 'Change', tooltip: "Schedule.ChangeSchedule", styleClass: "p-button-rounded p-button-text p-mr-2 p-mb-2", icon: "pi pi-info", command: () => {
          this.schedulesService.scheduleDialogActionSendEmitter.emit(new ScheduleActionEvent(true, "Edit schedule", ScheduleDialogActionType.editSchedule, "Save", this.selectedSchedule));

          //this.schedulesService.displayEditSchedule.emit(true);

        }
      },
      {
        label: 'Delete', tooltip: "Schedules.DeleteSchedule", styleClass: "p-button-rounded p-button-danger p-button-text p-mr-2 p-mb-2", icon: "pi pi-trash", command: () => {
          //this.schedulesService.displayDeleteSchedule.emit(true);
          this.schedulesService.scheduleDialogActionSendEmitter.emit(new ScheduleActionEvent(true, "Delete schedule", ScheduleDialogActionType.deleteSchedule, "Delete", this.selectedSchedule));


        }
      },
    ];

    return dialogActions
  }
  emitObjectAction(action: string, object: SchedulePlanRecordView) {
    if(action === "CreateNewObject") {
      console.log("CreateNewObject")
      this.schedulesService.scheduleDialogActionSendEmitter.emit(new ScheduleActionEvent(true, "Create schedule", ScheduleDialogActionType.createSchedule, "Create", undefined));

      //this.schedulesService.displayCreateSchedule.emit(true);
      return
  }
    this.schedulesService.selectedMenuSchedulePlanEmitter.emit(object.object);
  }
  subscribeToEmitter(objList: ScheduleActionPlan[]) {
    this.subs.sink = this.schedulesService.selectedSchedulePlanEmitter.subscribe((res: ScheduleActionPlan) => {
      this.selectedSchedule = res;
    },
      (err: Error) => {
        this.errorService.handleError(err);
      });
    this.subs.sink = this.objectFilterService.objectSearchParamsEmitter.subscribe((params) => {

      this.subs.sink = this.getData().subscribe((finalRecordResult) => {
        if (objList) {
          //console.log(finalRecordResult);
          let objListViews: SchedulePlanRecordView[] = objList.map((obj) => {
            return this.asRecord(obj, finalRecordResult);
          });
          let filteredObjects = this.filterObjects(params, objListViews);

          //this.changedFilterEmitter.emit(filteredObjects);

          this.objectFilterService.emitChangedFilteredObjectList(filteredObjects);

          console.log("Filtered Objects", filteredObjects);
        }
      },
        (err: Error) => {
          this.errorService.handleError(err);
        });
    });
  }
  filterObjects(params: [Map<string, DropDownModel>, Map<string, FilterProvider<any>>], objList: SchedulePlanRecordView[]) {
    return FilterUtil.filterObjects(params, objList);
  }
  sortEntries(entries: SchedulePlanRecordView[]): SchedulePlanRecordView[] {
    return entries.sort((objA, objB) => RecordViewSorter.sortLastUpdate(objA,objB));
  }

  subs = new SubSink;
  selectedSchedule?: ScheduleActionPlan;

}
