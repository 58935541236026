import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PermissionEnrichment, PermissionRich } from '../permission-enrichment';
import { UserInfo } from 'src/app/models/api/models/session/UserInfo';
import { RightHolder } from 'src/app/models/api/models/authorization/RightHolder';
import { RoleEnrichment } from '../role-enrichment';
import { PermissionBase } from 'src/app/models/api/models/authorization/PermissionBase';

/**
 * Erstellt eine neue Permission als Platzhalter für Rollen, die noch keine haben.
 * 
 * Typklasse.
 * 
 * Die neue Permission hat die ID -1.
 * Der Owner wird normalerweise auf 'false' gesetzt, weil die Owner Permission vom Backend angelegt wird.
 * Die Rechte sollten leer sein.
 * 
 */
export interface NewPermission<A, P extends PermissionBase<A>> {
  /**
   * Neue, noch nicht existierende Permission mit der ID -1.
   * @param role Rolle
   * @param accessible Accessible ID 
   */
  create(role: number, accessible: A): P
}

/**
 * Die Mutter muss diese Daten bereit stellen.
 * @param P Permission
 */
export interface PermissionData<A, P extends PermissionBase<A>> {
  source_permissions: P[];
  right_holders: RightHolder[];
  users: UserInfo[];
  rights: string[];
}

/**
 * Das Accessible (z.B. eine Data Source) und Meta-Daten wie Permissions, Benutzer und Rollen.
 */
export interface DataModel<A, P extends PermissionBase<A>> {
  Accessible: A;
  PermissionData: PermissionData<A, P>;
  NewPermission: NewPermission<A, P>;
}

/**
 * Operationen für den Permission-Batch-Job.
 */
export interface JobInfo<A> {
  Create: PermissionRich<A>[];
  Update: PermissionRich<A>[];
  Delete: PermissionRich<A>[];
}

/**
 * Vergabe von Berechtigungen auf genau ein Objekt.
 * Diese Komponente ruft keine API-Funktionen auf. Die Mutter stellt die Metadaten bereit und führt nach den Anpassungen die API-Operationen durch.
 */
@Component({
  selector: 'app-role-permissions',
  templateUrl: './role-permissions.component.html',
  styleUrls: ['./role-permissions.component.scss']
})
export class RolePermissionsComponent<A, P extends PermissionBase<A>> implements OnInit {

  constructor() { }

  pl_source_permissions: PermissionRich<A>[] = [];
  pl_target_permissions: PermissionRich<A>[] = [];
  pl_dragged_permission?: PermissionRich<A> = undefined;

  private _model?: DataModel<A, P>;

  @Input()
  public get model() {
    return this._model;
  }
  public set model(value: DataModel<A, P>) {
    this._model = value;
    if (this._model !== undefined) {
      this.updatePermissionDataView(this._model);
    }

  }

  @Output()
  selectionChanged = new EventEmitter<JobInfo<A>>();

  // todo: getter setter

  ngOnInit(): void {
    if (this._model !== undefined) {
      this.updatePermissionDataView(this._model);
    }
  }


  /**
 * Holt Permission Informationen und lädt den Permission View neu.
 * @param source_key
 */
  updatePermissionDataView(model: DataModel<A, P>) {
    this.loadPermissionView(model);
  }

  /**
 * Läd die Rollen und Permissions in die Liste. Rollen ohne Permissions kommen in den Topf 'source', Rollen mit Permission in den Topf 'target'.
 * Rollen die noch keine Permission haben bekommen eine Vorlage mit id = -1.
 * Es darf nur eine Permission pro Rolle für die Source existieren, da wir sonst mehrdeutige Rechte haben.
 * @param permissions
 * @param roles
 */
  loadPermissionView(model: DataModel<A, P>) {

    // permissions: P[], roles: RightHolder[], users: UserInfo[]
    const permissions = model.PermissionData.source_permissions;
    const roles = model.PermissionData.right_holders;
    const users = model.PermissionData.users;
    const accessible = model.Accessible;

    console.log("No rights preselected with new permissions yet");
    console.log("Right Holders", roles);

    //const source_key = Id.assertSet(this.source_key, new Error("Cannot load permissions: data source key is not set!"));
    const role_views = RoleEnrichment.prepareRightHolders(roles, users);

    this.pl_source_permissions = [];
    this.pl_target_permissions = [];

    for (let role of role_views) {
      const role_permissions = permissions.filter(p => p.Role == role.ID);

      if (role_permissions.isEmpty()) {
        //const new_accessible = new IntegratedSourceModel.IntSourceAccessible(source_key);
        //const new_permission = new IntegratedSourceModel.IntSourcePermission(-1, role.ID, false, new_accessible, []);
        const new_permission = this._model.NewPermission.create(role.ID, accessible)
        const rich = PermissionEnrichment.toRichPermissionRole(new_permission, role);
        this.pl_source_permissions.push(rich)
      } else {
        const role_perm = role_permissions.head();
        const rich = PermissionEnrichment.toRichPermissionRole(role_perm, role);
        rich.Role
        this.pl_target_permissions.push(rich);
      }

      if (role_permissions.length > 2) {
        console.warn("The role " + role.ID + " has multiple (" + role_permissions.length + ") permissions.");
      }
    }
  }

  /**
 * Prüft ob die Permission neu ist oder geändert wurde.
 * @param product Permission
 * @returns Status Text
 */
  checkState(product: PermissionRich<A>): string {
    // states = {new, changed , ""}

    if (product.ID == -1) {
      return "New";
    } else {
      return ""
    }
  }

  permOnDragStart(product: PermissionRich<A>) {
    this.pl_dragged_permission = product;
  }

  permOnDragEnd(event: DragEvent) {
    this.pl_dragged_permission = undefined;
  }


  //   /**
  //  * Permission Update Click Handler
  //  * @param event Event
  //  */
  //   valueCheckClicked(event: any) {
  //     console.log("Available", this.pl_source_permissions);
  //     console.log("Selected", this.pl_target_permissions);

  //     // Löschen:   isAvailable & hasId
  //     // Updaten:   isSelected  & hasId
  //     // Erstellen: isSelected  & NOT hasId

  //     const to_delete = this.pl_source_permissions.filter(p => p.ID != -1);
  //     const to_update = this.pl_target_permissions.filter(p => p.ID != -1);
  //     const to_create = this.pl_target_permissions.filter(p => p.ID == -1);

  //     const delete_obs = to_delete.map(p => {
  //       const arg = new IntegratedSourceModel.DeletePermissionArg(p.ID, p.Accessible.ID.Origin);
  //       return this.recoverObs(this.service_api.deleteIntSourcePermission(arg), p, "Delete");
  //     })
  //     const update_obs = to_update.map(p => this.recoverObs(this.service_api.updateIntSourcePermission(p), p, "Update"));
  //     const create_obs = to_create.map(p => this.recoverObs(this.service_api.createIntSourcePermission(p), p, "Create"));

  //     const all_obs: Observable<SafeObservableResult<SourcePermissionRich, any>>[] = [];

  //     delete_obs.forEach(e => all_obs.push(e));
  //     update_obs.forEach(e => all_obs.push(e));
  //     create_obs.forEach(e => all_obs.push(e));

  //     const forked = forkJoin(all_obs);

  //     const fork_and_reload = forked
  //       .pipe(concatMap(safeResults => this.updatePermissionDataView(this.source_key)
  //         .pipe(map(_ => safeResults))))

  //     this.setLoading(true);
  //     fork_and_reload.subscribe(results => {
  //       console.log("Permissions Performed!", results)

  //       const failed = results.filter(r => r.Successful == false);
  //       console.log("Failed Operations", failed);

  //       if (failed.nonEmpty()) {
  //         const fail_texts = failed.map(f => f.Tag + " " + f.Argument.Type + " '" + f.Argument.Role + "'");
  //         const fail_reduced = fail_texts.reduce((a, b) => a + ", " + b);
  //         const msg = "Could not process the following Permissions: " + fail_reduced;
  //         this.handleError(new Error(msg))
  //       } else {
  //         this.messageService.add({
  //           severity: "success",
  //           summary: this.translate.instant("Message.UpdateDataSourceSuccess.Title"),
  //           detail: this.translate.instant("Message.UpdateDataSourceSuccess.Text1") +
  //             this.translate.instant("Message.UpdateDataSourceSuccess.Text2"),
  //         });
  //       }
  //     },
  //       err => this.handleError(err),
  //       () => this.setLoading(false)
  //     )
  //   }

  /**
   * Feuert das SelectionChanged Event ab.
   */
  onSelectionChanged(): void {
    this.fireSelectedionChanged();
  }

  onRightChanged(): void {
    this.fireSelectedionChanged();
  }

  fireSelectedionChanged() {
    const to_delete = this.pl_source_permissions.filter(p => p.ID != -1);
    const to_update = this.pl_target_permissions.filter(p => p.ID != -1);
    const to_create = this.pl_target_permissions.filter(p => p.ID == -1);

    const info: JobInfo<A> = { Create: to_create, Update: to_update, Delete: to_delete }

    this.selectionChanged.emit(info);
  }

  /**
 * Verschiebt den ausgewählten Eintrag zurück in die "linke" Tabelle
 * @param perm Ausgewählte Permission
 */
  dropPermissionClick(perm: PermissionRich<A>) {

    console.log(perm);

    const tar_perms = this.pl_target_permissions;
    const tar_index = tar_perms.findIndex(t => this.comparePermission(t, perm));

    if (tar_index !== -1) {

      const tar_perms_new = tar_perms.filter((perm, i) => i !== tar_index);
      this.pl_target_permissions = tar_perms_new;
      this.pl_source_permissions.push(perm);

      this.onSelectionChanged();

    } else {
      // Nothing to do, but functional syntax :-)
    }

  }

  permOnDrop() {
    if (this.pl_dragged_permission) {
      let draggedProductIndex = this.findIndex(this.pl_dragged_permission);
      this.pl_target_permissions = [...(this.pl_target_permissions as PermissionRich<A>[]), this.pl_dragged_permission];
      this.pl_source_permissions = this.pl_source_permissions?.filter((val, i) => i != draggedProductIndex);
      this.pl_dragged_permission = undefined;
      this.onSelectionChanged();
    }
  }

  findIndex(product: PermissionRich<A>) {
    let index = -1;
    for (let i = 0; i < this.pl_source_permissions.length; i++) {

      const match = this.comparePermission(product, this.pl_source_permissions[i]);

      if (match) {
        index = i;
        break;
      }
    }
    return index;
  }

  /**
 *   Die ID geht nicht, weil wir auch Permissions anlegen die noch nicht in der DB sind => id = -1
  *  Rolle und Accessible reichen, hier reicht sogar die Rolle, weil das Accessible schon feststeht
 * @param a
 * @param b
 * @returns
 */
  comparePermission(a: PermissionRich<A>, b: PermissionRich<A>) {
    const match = a.Role === b.Role;
    return match
  }

}
