import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { forkJoin, Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { ActionPlanProtocolEntry } from 'src/app/models/api/models/scheduler/ActionPlanProtocolEntry';
import { WorkflowRepositoryEntry } from 'src/app/models/api/models/workflow/WorkflowRepositoryEntry';
import { DataSource } from 'src/app/models/datasource.model';
import { DataStore } from 'src/app/models/datastore.model';
import { ActionPlanPermission, ExtractDataSourceAction, RunWorkflowAction, ScheduleActionFullRow, ScheduleActionPlan, ScheduleBaseAction, ScheduleExtractActionFull, ScheduleWorkflowActionFull } from 'src/app/models/schedule.model';
// import { WorkflowRepositoryEntry } from 'src/app/models/workflow.model';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { CubesService } from 'src/app/services/cubes.service';
import { ObjectSearchService } from 'src/app/services/object-search.service';
import { SchedulesService } from 'src/app/services/schedules.service';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { UserService } from 'src/app/services/user.service';
import { UtilFunctionsService } from 'src/app/services/util-functions.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { SubSink } from 'subsink';
import { WorkflowGraphComponent } from '../../designer/components/workflow-graph/workflow-graph.component';
import { GeneralObjectViewComponent } from '../../objectManagement/general-object-view/general-object-view.component';
import { ScheduleRecord, SchedulePlanRecordView, ActionPlanViewRecord } from '../../objectManagement/general-object-view/provider-scheduler';
import { GeneralSearchComponent, ScheduleSearch } from '../../objectManagement/general-search/general-search.component';
import { GenericBottomToolbarComponent } from '../../objectManagement/generic-bottom-toolbar/generic-bottom-toolbar.component';
import { ScheduleBottomToolbar, SchedulerToolbarData, SchedulerToolbarResult } from '../../objectManagement/generic-bottom-toolbar/provider-scheduler';
import { GenericLatestActivitiesComponent } from '../../objectManagement/generic-latest-activities/generic-latest-activities.component';
import { ScheduleActivity, ScheduleViewActivity as ActionPlanViewActivity } from '../../objectManagement/generic-latest-activities/provider-scheduler';
import { GenericTopToolbarComponent } from '../../objectManagement/generic-top-toolbar/generic-top-toolbar.component';
import * as dss from '../../../models/datasource.model'
import { ScheduleToolbar } from '../../objectManagement/generic-top-toolbar/provider-schedule';
import { CronFrequency } from 'src/app/models/api/models/scheduler/CronFrequency';
import { ExperimentalApi } from 'src/app/services/experimental-api.service';
import { GenericObjectTasksComponent } from '../../objectManagement/generic-object-tasks/generic-object-tasks.component';
import { UserDetailsRow } from 'src/app/models/user.model';
import { AppMainComponent } from 'src/app/app.main.component';

@Component({
  selector: 'app-schedules-view',
  templateUrl: './schedules-view.component.html',
  styleUrls: ['./schedules-view.component.scss'],
  providers: [MessageService],
  animations: [trigger("fade", [
    state("void", style({ opacity: 0 })),
    transition(":enter", [animate(500)]),
    transition(":leave", [animate(500)]),
  ]),]
})
export class SchedulesViewRelayoutComponent implements OnInit {
  // Gui Elements
  navMenuItem: MenuItem[] = [];
  private subs = new SubSink();
  loading: boolean = false;
  displaySearchPanel: boolean = true;
  displayWidgets: boolean = true;
  // WF specific TypeClass
  scheduleToolbarTypeClass: ScheduleToolbar = new ScheduleToolbar(this.schedulesService, this.userService);
  scheduleRecordTypeClass: ScheduleRecord = new ScheduleRecord(this.bionApi, this.objectSearchService, this.schedulesService, this.systemLogService);
  scheduleSearchTypeClass: ScheduleSearch = new ScheduleSearch(this.bionApi, this.userService, this.utilService, this.dataStoreService, this.objectSearchService);
  scheduleActivityTypeClass = new ScheduleActivity(this.bionApi, this.schedulesService, this.userService);
  scheduleBottomToolbarTypeClass = new ScheduleBottomToolbar(this.bionApi, this.schedulesService);
//calendarTypeClass = new SchedulePlanSchedulePart(this.bionApi, this.schedulesService);

  // WF specific classes
  schedules: ScheduleActionPlan[] = [];
  selectedSchedule?: ScheduleActionPlan;
  baseActions: ScheduleBaseAction[] = [];
  frequencies: CronFrequency[] = [];

  userDetails: UserDetailsRow[] = [];

  actionPlanViewRecordTypeClass: ActionPlanViewRecord = new ActionPlanViewRecord([],[], this.schedulesService, this.objectSearchService, this.systemLogService, this.utilService);
  //actionPlanViewActivityTypeClass = new ActionPlanViewActivity([], this.schedulesService, this.userDetails, this.utilService);
  //actionPlanViewBottomToolbarTypeClass = new ScheduleBottomToolbar(this.bionApi, this.schedulesService);

  //@ViewChild("scheduleObjectToolbar") scheduleObjectToolbar!: GenericTopToolbarComponent<ScheduleActionPlan>;
  @ViewChild("scheduleObjectView") scheduleObjectView!: GeneralObjectViewComponent<ScheduleActionPlan, SchedulePlanRecordView, any, any>;
  //@ViewChild("scheduleObjectSearch") scheduleObjectSearch!: GeneralSearchComponent<[ScheduleActionPlan[], ScheduleBaseAction[], CronFrequency[], DataSource[], WorkflowRepositoryEntry[], DataStore[]], any>;
  //@ViewChild('scheduleLatestActivities') scheduleLatestActivities!: GenericLatestActivitiesComponent<ScheduleActionPlan, any>;
  //@ViewChild('scheduleBottomToolbar') scheduleBottomToolbar!: GenericBottomToolbarComponent<ScheduleActionPlan, never, SchedulerToolbarResult, SchedulerToolbarData>;
  //@ViewChild('calendarObjectWidget') calendarObjectWidget: GenericCalendarWidgetComponent<ScheduleActionPlan>;

  //@ViewChild('scheduleObjectTasks') scheduleObjectTasks!: GenericObjectTasksComponent;

  //@ViewChild("workflowGraph") graph!: WorkflowGraphComponent;

  constructor(
    private bionApi: ApiBackendService,
    public appMain: AppMainComponent,
    private dataStoreService: CubesService,
    private schedulesService: SchedulesService,
    private systemLogService: SystemMessageLogService,
    private userService: UserService,
    private objectSearchService: ObjectSearchService,
    private utilService: UtilFunctionsService,
    private workflowsService: WorkflowsService,
    public translate: TranslateService,
    public experimentalApi: ExperimentalApi,
    public router: Router
  ) { }

  ngOnInit(): void {

    this.subs.sink = this.schedulesService.selectedSchedulePlanEmitter.subscribe(
      (wf: ScheduleActionPlan) => {
        this.selectedSchedule = wf;
      },
      (err: Error) => {
        this.systemLogService.handleError(err);
      }
    );

    //const new_approach = true;

    //if (new_approach) {

      this.subs.sink = this.initViewNew().subscribe(() => {
        this.loading = false;
      }, (err: Error) => {
        this.systemLogService.handleError(err);
      });

      this.subs.sink = this.schedulesService.schedulesChangedEmitter.subscribe(() => {
        this.subs.sink = this.initViewNew(this.selectedSchedule).subscribe(() => {
          this.loading = false;
        }, err => {
          this.systemLogService.handleError(err);
        });
      },
        (err: Error) => {
          this.systemLogService.handleError(err);
        });

    // } else {

    //   this.subs.sink = this.initView().subscribe(() => {
    //     this.loading = false;
    //   }, (err: Error) => {
    //     this.systemLogService.handleError(err);
    //   });

    //   this.subs.sink = this.schedulesService.schedulesChangedEmitter.subscribe(() => {
    //     this.subs.sink = this.initView(this.selectedSchedule).subscribe(() => {
    //       this.loading = false;
    //     }, err => {
    //       this.systemLogService.handleError(err);
    //     });
    //   },
    //     (err: Error) => {
    //       this.systemLogService.handleError(err);
    //     });

    // }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  initViewNew(ap?: ScheduleActionPlan): Observable<boolean> {
    this.loading = true;
    // top, search, object, activity, bottom

    const userDetailsObs = this.userService.getUserDetailsRow();
    const apViewObs = this.experimentalApi.getActionPlanView();

    const obs = forkJoin(apViewObs,userDetailsObs);

    const finalObs = obs.pipe(concatMap(rich_data => {
        const plans = rich_data[0].map(d => d.Plan);
        this.schedules = rich_data[0].map(d => d.Plan);
        this.frequencies = rich_data[0].flatMap(d => d.Plan.Frequencies);
        this.baseActions = rich_data[0].flatMap(d => d.Plan.Actions.map(a => this.toBaseAction(a)));
        this.userDetails = rich_data[1];

        //const top_obs = this.scheduleObjectToolbar.setToolbar();
        //const search_obs = this.scheduleObjectSearch.setSearchModel(this.scheduleSearchTypeClass, this.schedules);

        this.actionPlanViewRecordTypeClass = new ActionPlanViewRecord(rich_data[0], this.userDetails,this.schedulesService, this.objectSearchService, this.systemLogService,this.utilService);
        const object_obs = this.scheduleObjectView.setObjects(plans, this.actionPlanViewRecordTypeClass);

        //this.actionPlanViewActivityTypeClass = new ActionPlanViewActivity(rich_data[0], this.schedulesService, this.userDetails, this.utilService);
        //const activity_obs = this.scheduleLatestActivities.setObject(this.actionPlanViewActivityTypeClass);

        // replace with new one!
        //console.log("ATTENTION: top, search and bottom toolbar need still adjustements!");
        //const bottom_obs = this.scheduleObjectTasks.setObject();

        //return forkJoin(Array(top_obs, search_obs, object_obs, activity_obs,bottom_obs)).pipe(map(a => a.reduce((a,b) => a && b)));
        return forkJoin(Array( object_obs)).pipe(map(a => a.reduce((a,b) => a && b)));

    }))

    return finalObs

    // const obs = this.experimentalApi.getActionPlanView().pipe(concatMap(rich_data => {


    //   // scheduleToolbarTypeClass: ScheduleToolbar = new ScheduleToolbar(this.schedulesService, this.userService);
    //   // scheduleRecordTypeClass: ScheduleRecord = new ScheduleRecord(this.bionApi, this.objectSearchService, this.schedulesService, this.systemLogService);
    //   // scheduleSearchTypeClass: ScheduleSearch = new ScheduleSearch(this.bionApi, this.userService, this.utilService, this.dataStoreService, this.objectSearchService);
    //   // scheduleActivityTypeClass = new ScheduleActivity(this.bionApi, this.schedulesService, this.userService);
    //   // scheduleBottomToolbarTypeClass = new ScheduleBottomToolbar(this.bionApi, this.schedulesService);

    //   // @ViewChild("scheduleObjectToolbar") scheduleObjectToolbar!: GenericTopToolbarComponent<ScheduleActionPlan>;
    //   // @ViewChild("scheduleObjectView") scheduleObjectView!: GeneralObjectViewComponent<ScheduleActionPlan, SchedulePlanRecordView, any>;
    //   // @ViewChild("scheduleObjectSearch") scheduleObjectSearch!: GeneralSearchComponent<[ScheduleActionPlan[], ScheduleBaseAction[], CronFrequency[], DataSource[], WorkflowRepositoryEntry[], DataStore[]], any>;
    //   // @ViewChild('scheduleLatestActivities') scheduleLatestActivities!: GenericLatestActivitiesComponent<ScheduleActionPlan, ActionPlanProtocolEntry[]>;
    //   // @ViewChild('scheduleBottomToolbar') scheduleBottomToolbar!: GenericBottomToolbarComponent<ScheduleActionPlan, never, SchedulerToolbarResult, SchedulerToolbarData>;


    //   // actionPlanViewRecordTypeClass: ActionPlanViewRecord = new ActionPlanViewRecord([], this.schedulesService, this.objectSearchService, this.systemLogService);
    //   // actionPlanViewActivityTypeClass = new ActionPlanViewActivity([], this.schedulesService);


    //   const plans = rich_data.map(d => d.Plan);
    //   this.schedules = rich_data.map(d => d.Plan);
    //   this.frequencies = rich_data.flatMap(d => d.Plan.Frequencies);
    //   this.baseActions = rich_data.flatMap(d => d.Plan.Actions.map(a => this.toBaseAction(a)));

    //   const top_obs = this.scheduleObjectToolbar.setToolbar();
    //   const search_obs = this.scheduleObjectSearch.setSearchModel(this.scheduleSearchTypeClass, this.schedules);

    //   this.actionPlanViewRecordTypeClass = new ActionPlanViewRecord(rich_data, this.schedulesService, this.objectSearchService, this.systemLogService);
    //   const object_obs = this.scheduleObjectView.setObjects(plans, this.actionPlanViewRecordTypeClass);

    //   this.actionPlanViewActivityTypeClass = new ActionPlanViewActivity(rich_data, this.schedulesService);
    //   const activity_obs = this.scheduleLatestActivities.setObject(this.actionPlanViewActivityTypeClass);

    //   // replace with new one!
    //   console.log("ATTENTION: top, search and bottom toolbar need still adjustements!");
    //   const bottom_obs = this.scheduleObjectTasks.setObject();

    //   return forkJoin(Array(top_obs, search_obs, object_obs, activity_obs,bottom_obs)).pipe(map(a => a.reduce((a,b) => a && b)));
    // }));

    // return obs;
  }

  /**
   * Converts old to new Scheduler Model Classes.
   *
   * Note: This is necessary. Even if the are structural identical (!) they differ in their '_type' attribute.
   * The Backend is messed up here and needs corrections.
   * @param row
   * @returns
   */
  toBaseAction(row:ScheduleActionFullRow) : ScheduleBaseAction {
    const ea_row = <ScheduleExtractActionFull>row;
    if(ea_row.dataSource) {
      return new ExtractDataSourceAction(ea_row.id, ea_row.actionPlan, ea_row.index, ea_row.dataSource, ea_row.user, ea_row.description);
    }

    const wf_row = <ScheduleWorkflowActionFull>row;
    if(wf_row.workflow) {
      return new RunWorkflowAction(wf_row.id, wf_row.actionPlan, wf_row.index, wf_row.workflow, wf_row.user, wf_row.description);
    }

    throw Error("The action row with type'" + row._type + "' is not supported!");
  }

//   initView(ap?: ScheduleActionPlan): Observable<[boolean, boolean]> {
//     this.loading = true;
//     let dsObs = this.schedulesService.getScheduleActionPlan();
//     let frequenciesObs = this.schedulesService.getScheduleFrequencies();
//     let actionsObs = this.schedulesService.getScheduleActions();

//     let schedResultObs = forkJoin(dsObs, frequenciesObs, actionsObs).pipe(concatMap((dsResults) => {
//       this.schedules = dsResults[0];
//       this.frequencies = dsResults[1];
//       this.baseActions = dsResults[2];

//       //let tbObs = this.scheduleObjectToolbar.setToolbar();
//       let objObs = this.scheduleObjectView.setObjects(this.schedules, this.scheduleRecordTypeClass);
//       //console.log(this.scheduleObjectView.records);
//       //let searchObs = this.scheduleObjectSearch.setSearchModel(this.scheduleSearchTypeClass, this.schedules);
//       //let calObs = this.calendarObjectWidget.setObjects(this.calendarTypeClass, this.schedules);
//       let actObs = this.scheduleLatestActivities.setObject(this.scheduleActivityTypeClass);
//       //let bbObs = this.scheduleBottomToolbar.setObject(this.scheduleBottomToolbarTypeClass);

//       let initCompObs = forkJoin(objObs, actObs);

//       return initCompObs;

//     }));

//     return schedResultObs

//   }

//   toggleWidgetMenu() {
//     this.displayWidgets = !this.displayWidgets;
//   }
//   toggleSearchMenu() {
//     this.displaySearchPanel = !this.displaySearchPanel;
//   }

}
