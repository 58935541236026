<div class="p-grid">
    <!-- <div class="p-col-3">
        <p *ngFor="let header of columnHeaders" style="font-size:x-small; font-weight:x-small; text-align:left">
            {{header}}
        </p>
    </div>[
    <div class="p-col-9">]
        <p *ngFor="let col of columnData" style="text-align: left; font-size:x-small; font-weight:x-small">{{col}}</p>
    </div> -->
    <div class="p-col-6" pTooltip="Percentage of unique entries">
        <p-progressBar mode="determinate" [value]="uniquePercentageValue" [style]="{'height': '2px'}"></p-progressBar>
        {{uniquePercentageValue}}% {{'unique' | translate}}
    </div>
    <div class="p-col-6" pTooltip="Percentage of valid entries">
        <p-progressBar mode="determinate" [value]="validPercentageValue" [style]="{'height': '2px'}"></p-progressBar>
        {{validPercentageValue}}% {{'valid' | translate}}
    </div>
</div>


<!-- <p-progressBar [value]="uniquePercentageValue" [style]="{'height': '5px'}"></p-progressBar> -->
