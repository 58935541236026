import { Component, ComponentRef, Input, OnInit, ViewChild } from '@angular/core';
import { ConfigItem } from '../create-ab-source/config-item';
import { ConfigDirective } from '../create-ab-source/config.directive';
import { ConfigComponent } from '../create-ab-source/config.component';


/**
 * Hier werden die dynamischen Config views gehalten.
 */
@Component({
  selector: 'app-create-ab-source-config',
  templateUrl: './create-ab-source-config.component.html',
  styleUrls: ['./create-ab-source-config.component.scss']
})
export class CreateAbSourceConfigComponent implements OnInit {

  @Input() configs: ConfigItem[] = [];    // Config Views
  //@Input() selectedConfig?: ConfigItem;  // Das ausgewählte Config Item wird durch die Mutter-ausgewählte Source Definition ID (Connector ID) gesetzt

  lastError: string = "";
  currentSourceDef?: string;   // TODO: As Input? From parent combo box.
  curentItem?: ConfigItem;
  currentComponent?: ComponentRef<ConfigComponent>;

  @ViewChild(ConfigDirective, { static: true }) configHost!: ConfigDirective;

  // KIND!!!!

  constructor() { }

  ngOnInit(): void {
    // load initial component
    console.log("Loading components");
    this.currentSourceDef = this.configs[0].id;
    this.loadComponent();
  }

  public setSelectedSourceDef(id: string) {
    this.currentSourceDef = id;
    this.loadComponent();
  }

  public setConnectorSpec(spec:any) {
    console.log("Set Connector Spec x on y", spec, this.currentComponent.instance);
    this.currentComponent.instance.connectorSpecs = spec;
  }

  loadComponent() {
    // select current view based on the selected source definition / connector

    this.lastError = "";
    if (this.currentSourceDef) {

      // Load component based on the source definition id, etc...
      // See: https://angular.io/guide/dynamic-component-loader

      const item = this.configs.find(c => c.id == this.currentSourceDef);

      if (item) {

        this.curentItem = item;

        // laden
        const viewContainerRef = this.configHost.viewContainerRef;
        viewContainerRef.clear();

        const componentRef = viewContainerRef.createComponent<ConfigComponent>(item.component);
        // componentRef.instance.data = item.data;  // Every component creates the default item by itself.
        this.currentComponent = componentRef;

      } else {

        // -- vielleicht können wir auch hier den view auch einfach leer machen
        this.lastError = "Für den Connector " + this.currentSourceDef + " existiert kein View."
      }

    }
  }
}