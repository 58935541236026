import { Component, Input, OnInit } from '@angular/core';
import * as dss from '../../../models/datasource.model'
import { forkJoin, Observable } from 'rxjs';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { DatasourcesService } from 'src/app/services/datasources.service';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { UserService } from 'src/app/services/user.service';
import { UtilFunctionsService } from 'src/app/services/util-functions.service';
import { SubSink } from 'subsink';
import { ObjectSearchService } from 'src/app/services/object-search.service';
import { UserDetailsRow } from 'src/app/models/user.model';
// import { WorkflowAccessible, WorkflowPermission, WorkflowRepositoryEntry } from 'src/app/models/workflow.model';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { ActionPlanAccessible, ActionPlanPermission, ScheduleActionPlan, ScheduleBaseAction } from 'src/app/models/schedule.model';
import { WorkflowNodeGuiInfo, WorkflowNodeInfo } from 'src/app/models/designer.models';
import { WorkflowProtocolEntry } from 'src/app/models/api/models/workflow/WorkflowProtocolEntry';
import { NodePlugInInfos } from 'src/app/models/nodePlugIn.model';
import { DataStore, DataStoreField } from 'src/app/models/datastore.model';
import { CubesService } from 'src/app/services/cubes.service';
import { DataStoreAccessible } from 'src/app/models/api/models/staging/DataStoreAccessible';
import { DataStorePermission } from 'src/app/models/api/models/staging/DataStorePermission';
import { WorkflowAccessible } from 'src/app/models/api/models/workflow/WorkflowAccessible';
import { WorkflowPermission } from 'src/app/models/api/models/workflow/WorkflowPermission';
import { WorkflowRepositoryEntry } from 'src/app/models/api/models/workflow/WorkflowRepositoryEntry';
import { map } from 'rxjs/operators';
import { DropDownModel } from './drop-down-model';
import { DropDownEntry, FilterProvider } from './filter-provider';
import { DataSourceRecordView } from '../general-object-view/provider-data-source';
import { DestinationRecordView } from '../general-object-view/provider-data-store';
import { SchedulePlanRecordView } from '../general-object-view/provider-scheduler';
import { WorkflowRecordView } from '../general-object-view/provider-workflow';
import { CronFrequency } from 'src/app/models/api/models/scheduler/CronFrequency';
import { TranslateService } from '@ngx-translate/core';
import { PowerBiEntry } from 'src/app/models/api/models/powerbi-report/PowerBiEntry';
import { PowerbiService } from '../../reports/powerbi.service';



export interface SearchProvider<T, P> {
  getData(): Observable<any>;
  setSearchModel(data: T, views: P): any;
  onFilterChanged(options: [DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[]], selected: [DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[]]): Map<string, DropDownModel>;
  emitSearchParams(params: Map<string, DropDownModel>, filter: Map<string, FilterProvider<{}>>): void;
}

export interface SearchParams {
  id: number;
  name: string;
  description: string;
}

export class DataSourceSearchParams implements SearchParams {
  id: number = 0;
  name: string = "";
  description: string = "";
  connectors: string[] = [];
  fields: string[] = [];
}



//------------------------------------------------------


// interface DropDownEntry<T> {
//   name:String;
//   value:T;
// }

// export interface FilterProvider<T> {
//   /**
//    * Attribute Path in the object, every element in the array represents one level
//    */
//   getPropertyPath():string[];
//     /**
//    * makes the allowed values unique
//    */
//   entrySet(values:T[]) : Set<T>;
//     /**
//    * convert and encapsulate value in dropdown option
//    */
//   dropDownOption(value:T) : DropDownEntry<T>;
//   filter(value:T, dataSet:any) : boolean;
// }

class ObjectFilterProvider<T> implements FilterProvider<T> {
  propertyPath: string[];
  equalityAttributes: string[];
  dropDownNameAttribute: string;
  dropDownIdAttribute: string;

  constructor(propertyPath: string[], equalityAttributes: string[], dropDownNameAttribute: string,dropDownIdAttribute: string) {
    this.propertyPath = propertyPath;
    this.equalityAttributes = equalityAttributes;
    this.dropDownNameAttribute = dropDownNameAttribute;
    this.dropDownIdAttribute = dropDownIdAttribute
  }

  getPropertyPath(): string[] {
    return this.propertyPath;
  }
  entrySet(values: T[]): Set<T> {

    const newValues = values.map(value => {
      // drop all attributes which are not in scope
      Object.getOwnPropertyNames(value);
      const allAttributes = Object.getOwnPropertyNames(value);
      for (let att of allAttributes) {
        if (!this.equalityAttributes.includes(att)) {
          delete value[att];
        }
      }
      return value;
    });

    const uniqueValues = this.getUniqueElements(newValues);

    return new Set(uniqueValues);
  }
  dropDownOption(value: T): DropDownEntry<T> {

    const dropDownAttribute = value[this.dropDownNameAttribute];

    return ({ name: dropDownAttribute.toString(), value: value });
  }
  filter(value: T, dataSet: any): boolean {
    //console.log("ObjectFilterProvider.filter(...)")
    //console.log(dataSet);
    //console.log(this.getPropertyPath());
    const dataSetValue = this.resolvePath(dataSet, this.getPropertyPath());

    if (dataSetValue === undefined) return false;

    // console.log(dataSetValue);
    // var equals = value == dataSetValue;
    //console.log("Filter object: " + value);
    //console.log("Data object: " + dataSetValue);
    const equals = this.compareObject(value, dataSetValue);
    //console.log("Filter equals Data object: " + equals );
    //console.log("equals: " + equals);
    return equals;
  }

  compareObject(value: any, dataSetValue: any): boolean {
    // compare each attribute of interest
    for (let att of this.equalityAttributes) {
      if (value[att] != dataSetValue[att]) return false;
    }

    return true;
  }

  resolvePath(obj: any, path: string[]): any {
    let now = obj;
    for (let step of path) {
      now = now[step];

      if (now === undefined) {
        console.log("The object does not have the attribute '" + step + "' - DROPPING!");
        return now;
      }
    }

    return now;
  }

  getUniqueElements(values: T[]): T[] {

    const result = new Array<T>();

    //console.log("get unique elements");

    for (let value of values) {
      // check if value ia alreay in result
      // if so, push else ignore, because it is already there
      let isInResult: boolean = false;
      for (let resValue of result) {

        if (this.compareObject(value, resValue)) {
          isInResult = true;
          break;
        }
      }

      if (!isInResult) result.push(value);
    }

    return result;
  }

}

/**
 * Compares two value.
 */
interface Comparactor<T> {
  /**
   * Returns true, if both values are equal.
   * @param value1 First value
   * @param value2 Second value
   */
  equals(value1: T, value2: T): boolean;
}

/**
 * A Comparator with Drop Down Label support.
 */
interface FilterComparator<T> extends Comparactor<T> {
  equals(value1: T, value2: T): boolean;
  dropDownLabel(value: T): string;
}

class AtomicComparator<T> implements FilterComparator<T> {
  equals(value1: T, value2: T): boolean {
    return value1 == value2;
  }
  dropDownLabel(value: T): string {
    return value.toString();
  }

}

/**
 * Compares two objects
 * Two objects are equal if all of their equality attributes matches
 */
class ObjectComparator<T> implements FilterComparator<T> {

  equalityAttributes: string[];
  labelFunction: (value: T) => string;

  /**
   *
   * @param equalityAttributes all attributes of an object to compare
   * @param labelFunction
   */
  constructor(equalityAttributes: string[], labelFunction: (value: T) => string) {
    this.equalityAttributes = equalityAttributes;
    this.labelFunction = labelFunction;
  }

  equals(value1: T, value2: T): boolean {
    for (let att of this.equalityAttributes) {

      if (value1[att] != value2[att]) return false;

    }

    return true;
  }
  dropDownLabel(value: T): string {
    return this.labelFunction(value);
  }

}


class ArrayFilterProvider<T> implements FilterProvider<T> {
  propertyPath: string[];
  comparator: FilterComparator<T>;

  constructor(propertyPath: string[], comparator: FilterComparator<T> = new AtomicComparator<T>()) {
    this.propertyPath = propertyPath;
    this.comparator = comparator;
  }

  getPropertyPath(): string[] {
    return this.propertyPath;
  }
  entrySet(values: T[]): Set<T> {
    // const result = values.reduce((accumulator, value) => accumulator.concat(value), []);
    const result = this.getUniqueElements(values);
    return new Set(result);
  }
  dropDownOption(value: T): DropDownEntry<T> {
    //console.log(value);
    return ({ name: this.comparator.dropDownLabel(value), value: value });
  }
  filter(value: T, dataSet: any): boolean {
    //console.log(dataSet);
    //console.log(this.getPropertyPath());
    const dataSetValue: [] = this.resolvePath(dataSet, this.getPropertyPath());

    if (dataSetValue === undefined) return false;

    //console.log(dataSetValue);
    // var equals = (dataSetValue.filter(x => x == value).length > 0);

    const equals = (dataSetValue.filter(x => this.comparator.equals(x, value)).length > 0);

    //console.log("equals: " + equals);
    return equals;
  }

  resolvePath(obj: any, path: string[]): any {
    let now = obj;
    for (let step of path) {
      now = now[step];

      if (now === undefined) {
        console.log("The object does not have the attribute '" + step + "' - DROPPING!");
        return now;
      }

    }

    return now;
  }

  getUniqueElements(values: T[]): T[] {

    const result = new Array<T>();

    //console.log("get unique elements");

    for (let value of values) {
      // check if value ia alreay in result
      // if so, push else ignore, because it is already there
      let isInResult: boolean = false;
      for (let resValue of result) {

        // console.log("compare objects in get unique elements");
        // console.log("Object a" + value);
        // console.log("Object b" + resValue);

        if (this.comparator.equals(value, resValue)) {
          isInResult = true;
          break;
        }
      }

      if (!isInResult) result.push(value);
    }

    return result;
  }
}

class AtomicFilterProvider<T> implements FilterProvider<T> {

  propertyPath: string[];
  comparator: FilterComparator<T>;

  constructor(propertyPath: string[], comparator: FilterComparator<T> = new AtomicComparator<T>()) {
    this.propertyPath = propertyPath;
    this.comparator = comparator;
  }

  getPropertyPath(): string[] {
    return this.propertyPath;
  }

  /**
   *
   * @param values allowed possible values to select
   * @returns unique values
   */
  entrySet(values: T[]): Set<T> {
    return new Set(values);
  }
  /**
   *  Builds a dropdown entry from given value
   * @param value atomic value to compare
   * @returns value capsulated in dropdown entry
   */
  dropDownOption(value: T): DropDownEntry<T> {
    return ({ name: this.comparator.dropDownLabel(value), value: value });
  }
  filter(value: T, dataSet: any): boolean {

    const dataSetValue = this.resolvePath(dataSet, this.getPropertyPath());

    if (dataSetValue === undefined) return false;

    const equals = this.comparator.equals(value, dataSetValue);

    //console.log("equals: " + equals);
    return equals;
  }

  resolvePath(obj: any, path: string[]): any {
    let now = obj;
    for (let step of path) {
      now = now[step];

      if (now === undefined) {
        console.log("The object does not have the attribute '" + step + "' - DROPPING!");
        return now;
      }

    }

    return now;
  }

  getUniqueElements(values: T[]): T[] {

    const result = new Array<T>();

    console.log("get unique elements");

    for (let value of values) {
      // check if value ia alreay in result
      // if so, push else ignore, because it is already there
      let isInResult: boolean = false;
      for (let resValue of result) {

        // console.log("compare objects in get unique elements");
        // console.log("Object a" + value);
        // console.log("Object b" + resValue);

        if (this.comparator.equals(value, resValue)) {
          isInResult = true;
          break;
        }
      }

      if (!isInResult) result.push(value);
    }

    return result;
  }
}

export class DataSourceSearch implements SearchProvider<[dss.DataSource[], dss.DataSourceField[], dss.DataPackageProtocolEntry[], dss.DataSourceConnector<any>[], UserDetailsRow[], ScheduleActionPlan[], WorkflowRepositoryEntry[]], any>{
  constructor(private bionApi: ApiBackendService, private systemLogService: SystemMessageLogService, private userService: UserService, private utilService: UtilFunctionsService, private datasourceService: DatasourcesService, private objectSearchService: ObjectSearchService) { }
  onFilterChanged(dropDownModel: [DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[]], selection: [DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[]]): Map<string, DropDownModel> {

    // Build or update current SearchModel and return the updated searchModel
    let dropDownModels = new Map<string, DropDownModel>();
    dropDownModels.set(this.FILTER_NAME, { model: dropDownModel[0], selection: selection[0] });
    dropDownModels.set(this.FILTER_FIELD, { model: dropDownModel[1], selection: selection[1] });
    dropDownModels.set(this.FILTER_CONNECTOR, { model: dropDownModel[2], selection: selection[2] });
    dropDownModels.set(this.FILTER_ID, { model: dropDownModel[3], selection: selection[3] });
    dropDownModels.set(this.FILTER_MYDS, { model: dropDownModel[4], selection: selection[4] });
    dropDownModels.set(this.FILTER_SCHEDULE, { model: dropDownModel[5], selection: selection[5] });
    dropDownModels.set(this.FILTER_WORKFLOW, { model: dropDownModel[6], selection: selection[6] });

    return dropDownModels

  }
  getData(): Observable<[dss.DataSource[], dss.DataSourceField[], dss.DataPackageProtocolEntry[], dss.DataSourceConnector<any>[], UserDetailsRow[], ScheduleActionPlan[], WorkflowRepositoryEntry[]]> {
    let datasourcesObs = this.bionApi.getDataSources();
    let dsFieldsObs = this.bionApi.getDataSourceFields();
    let dsDataPackagesObs = this.bionApi.getDataPackageProtocols();
    let connectorInfosObs = this.bionApi.getConnectorsInfo();
    let usersDetailsObs = this.userService.getUserDetailsRow();
    let schedulesObs = this.bionApi.getScheduleActionPlan();
    let wfObs = this.bionApi.getWorkflowObjectList();

    let finalSearchObs = forkJoin(datasourcesObs, dsFieldsObs, dsDataPackagesObs, connectorInfosObs, usersDetailsObs, schedulesObs, wfObs);

    return finalSearchObs;
  }
  setSearchModel(data: [dss.DataSource[], dss.DataSourceField[], dss.DataPackageProtocolEntry[], dss.DataSourceConnector<any>[], UserDetailsRow[], ScheduleActionPlan[], WorkflowRepositoryEntry[]], views: DataSourceRecordView[]) {
    //Fill Filter Options with Values

    //console.log("views",views);
    let dropDownModels = new Map<string, DropDownModel>();
    let filterProviders = new Map<string, FilterProvider<any>>();


    // -- new name (object filter)

    const dsObject = data[0].map(t => {
      return t
    });
    const dsObjectNames = dsObject.map(ds => {
      return ds.name
    });
    const dsNameComparator = new ObjectComparator<dss.DataSource>(["id", "name"], v => {
      return v.name + " (" + v.id + ")";
    });
    const dsNameFilterProvider = new AtomicFilterProvider<string>(["object", "name"]);
    let namesProvider = Array.from(dsNameFilterProvider.entrySet(dsObjectNames)).map(x => { return dsNameFilterProvider.dropDownOption(x) });

    filterProviders.set(this.FILTER_NAME, dsNameFilterProvider);
    dropDownModels.set(this.FILTER_NAME, { model: dsObject, selection: [] });

    // -- new id (object filter)
    const dsObjectIds = dsObject.map(ds => {
      return ds.id
    });
    const dsIdComparator = new ObjectComparator<dss.DataSource>(["id", "name"], v => {
      return v.name + " (" + v.id + ")";
    });
    const dsIdFilterProvider = new AtomicFilterProvider<number>(["object", "id"]);
    let idsProvider = Array.from(dsIdFilterProvider.entrySet(dsObjectIds)).map(x => { return dsIdFilterProvider.dropDownOption(x) });

    filterProviders.set(this.FILTER_ID, dsIdFilterProvider);
    dropDownModels.set(this.FILTER_ID, { model: dsObject, selection: [] });

    // -- new field (object filter)
    const dsFieldsObj = data[1].map(t => {
      return t
    });
    const dsFieldsObjectNames = dsFieldsObj.map(ds => {
      return ds.name
    });
    const dsFieldsComparator = new ObjectComparator<dss.DataSourceField>(["name"], v => {
      return v.name;
    });
    const dsFieldFilterProvider = new ArrayFilterProvider<dss.DataSourceField>(["fields"], dsFieldsComparator);
    let fieldsProvider = Array.from(dsFieldFilterProvider.entrySet(dsFieldsObj)).map(x => { return dsFieldFilterProvider.dropDownOption(x) });

    filterProviders.set(this.FILTER_FIELD, dsFieldFilterProvider);
    dropDownModels.set(this.FILTER_FIELD, { model: dsFieldsObj, selection: [] });

    // -- new connector (object filter)
    const connectorsObj = data[3].map(t => {
      return t
    });
    const connectorObjectNames = connectorsObj.map(ds => {
      return ds.Connector
    });
    const ConnectorComparator = new ObjectComparator<dss.DataSourceConnector<any>>(["name"], v => {
      return v.Connector;
    });
    const connectorFilterProvider = new AtomicFilterProvider<string>(["connector", "Connector"]);
    let connectorsProvider = Array.from(connectorFilterProvider.entrySet(connectorObjectNames)).map(x => { return connectorFilterProvider.dropDownOption(x) });

    filterProviders.set(this.FILTER_CONNECTOR, connectorFilterProvider);
    dropDownModels.set(this.FILTER_CONNECTOR, { model: connectorsObj, selection: [] });


    // -- new connector (object filter)
    const scheduleObs = data[5].map(t => {
      return t
    });
    const scheduleObjectNames = scheduleObs.map(ds => {
      return ds.name
    });
    const scheduleComparator = new ObjectComparator<ScheduleActionPlan>(["name"], v => {
      return v.name;
    });
    const scheduleFilterProvider = new ArrayFilterProvider<ScheduleActionPlan>(["schedulePlans"], scheduleComparator);
    let schedulesProvider = Array.from(scheduleFilterProvider.entrySet(scheduleObs)).map(x => { return scheduleFilterProvider.dropDownOption(x) });

    filterProviders.set(this.FILTER_SCHEDULE, scheduleFilterProvider);
    dropDownModels.set(this.FILTER_SCHEDULE, { model: scheduleObs, selection: [] });


    // -- new my datasource (object filter)
    const permissionComparator = new ObjectComparator<dss.DataSourcePermission>(["IsOwner"], v => {
      return ""
    });
    const permissionFilterProvider = new ArrayFilterProvider<dss.DataSourcePermission>(["permissions"], permissionComparator);

    let permissionSelectOptions: DropDownEntry<dss.DataSourcePermission>[] = [];
    permissionSelectOptions.push({ name: "My Datasources", value: new dss.DataSourcePermission(-1, -1, true, new dss.DataSourceAccessible(-1), []) });
    permissionSelectOptions.push({ name: "Shared with me", value: new dss.DataSourcePermission(-1, -1, false, new dss.DataSourceAccessible(-1), []) });
    //let permissionsProvider = Array.from(permissionFilterProvider.entrySet(connectorObjectNames)).map(x => { return permissionFilterProvider.dropDownOption(x)});

    filterProviders.set(this.FILTER_MYDS, permissionFilterProvider);
    dropDownModels.set(this.FILTER_MYDS, { model: permissionSelectOptions, selection: [] });

    // -- new connector (object filter)
    const workflowsObs = data[6].map(t => {
      return t
    });
    const workflowObjectNames = workflowsObs.map(ds => {
      return ds.name
    });
    const workflowComparator = new ObjectComparator<WorkflowRepositoryEntry>(["name"], v => {
      return v.name;
    });
    const workflowFilterProvider = new ArrayFilterProvider<WorkflowRepositoryEntry>(["workflows"], workflowComparator);
    let workflowsProvider = Array.from(workflowFilterProvider.entrySet(workflowsObs)).map(x => { return workflowFilterProvider.dropDownOption(x) });

    filterProviders.set(this.FILTER_WORKFLOW, workflowFilterProvider);
    dropDownModels.set(this.FILTER_WORKFLOW, { model: workflowsObs, selection: [] });

    // Aggregate all search options in tuple to return back
    const datamodel: [DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[]] = [namesProvider, fieldsProvider, connectorsProvider, idsProvider, permissionSelectOptions, schedulesProvider, workflowsProvider];


    //console.log(filterProviders);
    //console.log(dropDownModels);
    return [filterProviders, dropDownModels, datamodel]

  }
  emitSearchParams(params: any, filters: Map<string, FilterProvider<{}>>) {
    this.objectSearchService.emitSearchParams(params, filters);
  }

  readonly FILTER_NAME: string = "Name";
  readonly FILTER_FIELD: string = "Field";
  readonly FILTER_CONNECTOR: string = "Connector";
  readonly FILTER_ID: string = "id";
  readonly FILTER_MYDS: string = "IsOwner";
  readonly FILTER_SCHEDULE: string = "Schedule";
  readonly FILTER_WORKFLOW: string = "Workflow";


}





export class WorkflowSearch implements SearchProvider<[WorkflowRepositoryEntry[], WorkflowNodeInfo[], WorkflowProtocolEntry[], UserDetailsRow[], ScheduleActionPlan[], dss.DataSource[], DataStore[]], WorkflowRecordView[]>{
  constructor(private bionApi: ApiBackendService, private systemLogService: SystemMessageLogService, private userService: UserService, private utilService: UtilFunctionsService, private workflowsService: WorkflowsService, private objectSearchService: ObjectSearchService) { }

  getData(): Observable<[WorkflowRepositoryEntry[], WorkflowNodeInfo[], WorkflowProtocolEntry[], UserDetailsRow[], ScheduleActionPlan[], dss.DataSource[], DataStore[]]> {
    let workflowsObs = this.bionApi.getWorkflowObjectList();
    let nodesObs = this.workflowsService.getNodePlugIns();
    let wfProtocolObs = this.bionApi.getWorkflowProtocolEntry();
    let usersDetailsObs = this.userService.getUserDetailsRow();
    let schedulesObs = this.bionApi.getScheduleActionPlan();
    let datasourcesObs = this.bionApi.getDataSources();
    let dataStoreObs = this.bionApi.getDataStoreObjectList();


    let finalSearchObs = forkJoin(workflowsObs, nodesObs, wfProtocolObs, usersDetailsObs, schedulesObs, datasourcesObs, dataStoreObs);

    return finalSearchObs;
  }
  setSearchModel(data: [WorkflowRepositoryEntry[], WorkflowNodeInfo[], WorkflowProtocolEntry[], UserDetailsRow[], ScheduleActionPlan[], dss.DataSource[], DataStore[]], views: WorkflowRecordView[]) {
    //Fill Filter Options with Values

    console.log("views", views);
    let dropDownModels = new Map<string, DropDownModel>();
    let filterProviders = new Map<string, FilterProvider<any>>();

    // -- new name (object filter)

    const wfObject = data[0].map(t => {
      return t
    });
    const wfObjectNames = wfObject.map(ds => {
      return ds.name
    });
    const wfNameComparator = new ObjectComparator<dss.DataSource>(["id", "name"], v => {
      return v.name + " (" + v.id + ")";
    });
    const wfNameFilterProvider = new AtomicFilterProvider<string>(["object", "name"]);
    let namesProvider = Array.from(wfNameFilterProvider.entrySet(wfObjectNames)).map(x => { return wfNameFilterProvider.dropDownOption(x) });

    filterProviders.set(this.FILTER_NAME, wfNameFilterProvider);
    dropDownModels.set(this.FILTER_NAME, { model: wfObject, selection: [] });

    // -- new id (object filter)
    const dsObjectIds = wfObject.map(ds => {
      return ds.id
    });
    const dsIdComparator = new ObjectComparator<dss.DataSource>(["id", "name"], v => {
      return v.name + " (" + v.id + ")";
    });
    const dsIdFilterProvider = new AtomicFilterProvider<number>(["object", "id"]);
    let idsProvider = Array.from(dsIdFilterProvider.entrySet(dsObjectIds)).map(x => { return dsIdFilterProvider.dropDownOption(x) });

    filterProviders.set(this.FILTER_ID, dsIdFilterProvider);
    dropDownModels.set(this.FILTER_ID, { model: wfObject, selection: [] });

    // -- new field (object filter)
    const wfNodesObj = data[1].map(t => {
      return t
    });


    const rawGuiInfo = NodePlugInInfos.getNodeGuiInfo();
    const workflowNodeGuiInfo = NodePlugInInfos.getWorkflowNodeGuiInfo(
      wfNodesObj,
      rawGuiInfo
    );

    //const flattenedArray: WorkflowNodeGuiInfo[] = [].concat(...workflowNodeGuiInfo);
    console.log("WorkflowNodeGUIInfos", workflowNodeGuiInfo);
    const dsFieldsObjectNames = workflowNodeGuiInfo.map(ds => {
      return ds.NameLabel
    });
    const dsFieldsComparator = new ObjectComparator<WorkflowNodeGuiInfo>(["NameLabel"], v => {
      return v.NameLabel;
    });
    const dsFieldFilterProvider = new ArrayFilterProvider<WorkflowNodeGuiInfo>(["nodes"], dsFieldsComparator);
    let fieldsProvider = Array.from(dsFieldFilterProvider.entrySet(workflowNodeGuiInfo)).map(x => { return dsFieldFilterProvider.dropDownOption(x) });

    filterProviders.set(this.FILTER_NODE, dsFieldFilterProvider);
    dropDownModels.set(this.FILTER_NODE, { model: workflowNodeGuiInfo, selection: [] });

    // -- new connector (object filter)
    const scheduleObs = data[4].map(t => {
      return t
    });
    const scheduleObjectNames = scheduleObs.map(ds => {
      return ds.name
    });
    const scheduleComparator = new ObjectComparator<ScheduleActionPlan>(["name"], v => {
      return v.name;
    });
    const scheduleFilterProvider = new ArrayFilterProvider<ScheduleActionPlan>(["schedulePlans"], scheduleComparator);
    let schedulesProvider = Array.from(scheduleFilterProvider.entrySet(scheduleObs)).map(x => { return scheduleFilterProvider.dropDownOption(x) });

    filterProviders.set(this.FILTER_SCHEDULE, scheduleFilterProvider);
    dropDownModels.set(this.FILTER_SCHEDULE, { model: scheduleObs, selection: [] });


    // -- new permission (object filter)
    const permissionComparator = new ObjectComparator<WorkflowPermission>(["IsOwner"], v => {
      return ""
    });
    const permissionFilterProvider = new ArrayFilterProvider<WorkflowPermission>(["permissions"], permissionComparator);

    let permissionSelectOptions: DropDownEntry<WorkflowPermission>[] = [];
    permissionSelectOptions.push({ name: "My Worfklows", value: new WorkflowPermission(-1, -1, true, new WorkflowAccessible(-1), []) });
    permissionSelectOptions.push({ name: "Shared with me", value: new WorkflowPermission(-1, -1, false, new WorkflowAccessible(-1), []) });
    //let permissionsProvider = Array.from(permissionFilterProvider.entrySet(connectorObjectNames)).map(x => { return permissionFilterProvider.dropDownOption(x)});

    filterProviders.set(this.FILTER_MYDS, permissionFilterProvider);
    dropDownModels.set(this.FILTER_MYDS, { model: permissionSelectOptions, selection: [] });

    // -- new connector (object filter)
    const datasourceObs = data[5].map(t => {
      return t
    });
    const datasourceObjectNames = scheduleObs.map(ds => {
      return ds.name
    });
    const datasourceComparator = new ObjectComparator<dss.DataSource>(["name"], v => {
      return v.name;
    });
    const datasourceFilterProvider = new ArrayFilterProvider<dss.DataSource>(["datasources"], datasourceComparator);
    let datasourcesProvider = Array.from(datasourceFilterProvider.entrySet(datasourceObs)).map(x => { return datasourceFilterProvider.dropDownOption(x) });

    filterProviders.set(this.FILTER_DATASOURCE, datasourceFilterProvider);
    dropDownModels.set(this.FILTER_DATASOURCE, { model: datasourceObs, selection: [] });

    // -- new connector (object filter)
    const destinationObb = data[6].map(t => {
      return t
    });
    const destinationObjectNames = scheduleObs.map(ds => {
      return ds.name
    });
    const destinationComparator = new ObjectComparator<DataStore>(["name"], v => {
      return v.name;
    });
    const destinationFilterProvider = new ArrayFilterProvider<DataStore>(["datastores"], destinationComparator);
    let destinationsProvider = Array.from(destinationFilterProvider.entrySet(destinationObb)).map(x => { return destinationFilterProvider.dropDownOption(x) });

    filterProviders.set(this.FILTER_DESTINATION, destinationFilterProvider);
    dropDownModels.set(this.FILTER_DESTINATION, { model: destinationObb, selection: [] });

    // Aggregate all search options in tuple to return back
    const datamodel: [DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[]] = [namesProvider, fieldsProvider, idsProvider, permissionSelectOptions, schedulesProvider, datasourcesProvider, destinationsProvider];


    console.log(filterProviders);
    console.log(dropDownModels);
    return [filterProviders, dropDownModels, datamodel]
  }
  onFilterChanged(dropDownModel: [DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[]], selection: [DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[]]): Map<string, DropDownModel> {

    // Build or update current SearchModel and return the updated searchModel
    let dropDownModels = new Map<string, DropDownModel>();
    dropDownModels.set(this.FILTER_NAME, { model: dropDownModel[0], selection: selection[0] });
    dropDownModels.set(this.FILTER_NODE, { model: dropDownModel[1], selection: selection[1] });
    dropDownModels.set(this.FILTER_DATASOURCE, { model: dropDownModel[2], selection: selection[2] });
    dropDownModels.set(this.FILTER_ID, { model: dropDownModel[3], selection: selection[3] });
    dropDownModels.set(this.FILTER_MYDS, { model: dropDownModel[4], selection: selection[4] });
    dropDownModels.set(this.FILTER_SCHEDULE, { model: dropDownModel[5], selection: selection[5] });
    dropDownModels.set(this.FILTER_DESTINATION, { model: dropDownModel[6], selection: selection[6] });

    return dropDownModels

  }
  emitSearchParams(params: Map<string, DropDownModel>, filters: Map<string, FilterProvider<{}>>) {
    this.objectSearchService.emitSearchParams(params, filters);
  }

  readonly FILTER_NAME: string = "Name";
  readonly FILTER_NODE: string = "Field";
  readonly FILTER_DATASOURCE: string = "Datasource";
  readonly FILTER_ID: string = "id";
  readonly FILTER_MYDS: string = "IsOwner";
  readonly FILTER_SCHEDULE: string = "Schedule";
  readonly FILTER_DESTINATION: string = "Destination";

}

export class DestinationSearch implements SearchProvider<[DataStore[], DataStoreField[], UserDetailsRow[], ScheduleActionPlan[], WorkflowRepositoryEntry[], dss.DataSource[]], DestinationRecordView[]> {
  constructor(private bionApi: ApiBackendService, private userService: UserService, private utilService: UtilFunctionsService, private dataStoresService: CubesService, private objectSearchService: ObjectSearchService) { }
  onFilterChanged(dropDownModel: [DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[]], selection: [DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[]]): Map<string, DropDownModel> {

    // Build or update current SearchModel and return the updated searchModel
    let dropDownModels = new Map<string, DropDownModel>();
    dropDownModels.set(this.FILTER_NAME, { model: dropDownModel[0], selection: selection[1] });
    dropDownModels.set(this.FILTER_FIELD, { model: dropDownModel[1], selection: selection[2] });
    //dropDownModels.set(this.FILTER_CONNECTOR, {model: dropDownModel[2], selection:selection[2]});
    dropDownModels.set(this.FILTER_ID, { model: dropDownModel[3], selection: selection[0] });
    dropDownModels.set(this.FILTER_MYDS, { model: dropDownModel[4], selection: selection[3] });
    dropDownModels.set(this.FILTER_DATASOURCE, { model: dropDownModel[2], selection: selection[4] });
    dropDownModels.set(this.FILTER_SCHEDULE, { model: dropDownModel[5], selection: selection[6] });
    dropDownModels.set(this.FILTER_WORKFLOW, { model: dropDownModel[6], selection: selection[5] });

    return dropDownModels

  }
  getData(): Observable<[DataStore[], DataStoreField[], UserDetailsRow[], ScheduleActionPlan[], WorkflowRepositoryEntry[], dss.DataSource[]]> {
    let dataStoresObs = this.bionApi.getDataStoreObjectList();
    let dsFieldsObs = this.bionApi.getDataStoreField();
    //let dsDataPackagesObs = this.bionApi.getDataPackageProtocols();
    //let connectorInfosObs = this.bionApi.getConnectorsInfo();
    let usersDetailsObs = this.userService.getUserDetailsRow();
    let schedulesObs = this.bionApi.getScheduleActionPlan();
    let wfObs = this.bionApi.getWorkflowObjectList();
    let dsObs = this.bionApi.getDataSources();

    let finalSearchObs = forkJoin(dataStoresObs, dsFieldsObs, usersDetailsObs, schedulesObs, wfObs, dsObs);

    return finalSearchObs;
  }
  setSearchModel(data: [DataStore[], DataStoreField[], UserDetailsRow[], ScheduleActionPlan[], WorkflowRepositoryEntry[], dss.DataSource[]], views: DestinationRecordView[]) {
    //Fill Filter Options with Values

    console.log("views", views);
    let dropDownModels = new Map<string, DropDownModel>();
    let filterProviders = new Map<string, FilterProvider<any>>();


    // -- new name (object filter)

    const dsObject = data[0].map(t => {
      return t
    });
    const dsObjectNames = dsObject.map(ds => {
      return ds.name
    });
    const dsNameComparator = new ObjectComparator<DataStore>(["id", "name"], v => {
      return v.name + " (" + v.id + ")";
    });
    const dsNameFilterProvider = new AtomicFilterProvider<string>(["object", "name"]);
    let namesProvider = Array.from(dsNameFilterProvider.entrySet(dsObjectNames)).map(x => { return dsNameFilterProvider.dropDownOption(x) });

    filterProviders.set(this.FILTER_NAME, dsNameFilterProvider);
    dropDownModels.set(this.FILTER_NAME, { model: dsObject, selection: [] });

    // -- new id (object filter)
    const dsObjectIds = dsObject.map(ds => {
      return ds.id
    });
    const dsIdComparator = new ObjectComparator<DataStore>(["id", "name"], v => {
      return v.name + " (" + v.id + ")";
    });
    const dsIdFilterProvider = new AtomicFilterProvider<number>(["object", "id"]);
    let idsProvider = Array.from(dsIdFilterProvider.entrySet(dsObjectIds)).map(x => { return dsIdFilterProvider.dropDownOption(x) });

    filterProviders.set(this.FILTER_ID, dsIdFilterProvider);
    dropDownModels.set(this.FILTER_ID, { model: dsObject, selection: [] });

    // -- new field (object filter)
    const dsFieldsObj = data[1].map(t => {
      return t
    });
    const dsFieldsObjectNames = dsFieldsObj.map(ds => {
      return ds.name
    });
    const dsFieldsComparator = new ObjectComparator<DataStoreField>(["name"], v => {
      return v.name;
    });
    const dsFieldFilterProvider = new ArrayFilterProvider<DataStoreField>(["fields"], dsFieldsComparator);
    let fieldsProvider = Array.from(dsFieldFilterProvider.entrySet(dsFieldsObj)).map(x => { return dsFieldFilterProvider.dropDownOption(x) });

    filterProviders.set(this.FILTER_FIELD, dsFieldFilterProvider);
    dropDownModels.set(this.FILTER_FIELD, { model: dsFieldsObj, selection: [] });



    // -- new schedules (object filter)
    const scheduleObs = data[3].map(t => {
      return t
    });
    const scheduleObjectNames = scheduleObs.map(ds => {
      return ds.name
    });
    const scheduleComparator = new ObjectComparator<ScheduleActionPlan>(["name"], v => {
      return v.name;
    });
    const scheduleFilterProvider = new ArrayFilterProvider<ScheduleActionPlan>(["schedulePlans"], scheduleComparator);
    let schedulesProvider = Array.from(scheduleFilterProvider.entrySet(scheduleObs)).map(x => { return scheduleFilterProvider.dropDownOption(x) });

    filterProviders.set(this.FILTER_SCHEDULE, scheduleFilterProvider);
    dropDownModels.set(this.FILTER_SCHEDULE, { model: scheduleObs, selection: [] });


    // -- new permission (object filter)
    const permissionComparator = new ObjectComparator<DataStorePermission>(["IsOwner"], v => {
      return ""
    });
    const permissionFilterProvider = new ArrayFilterProvider<DataStorePermission>(["permissions"], permissionComparator);

    let permissionSelectOptions: DropDownEntry<DataStorePermission>[] = [];
    permissionSelectOptions.push({ name: "My Destinations", value: new DataStorePermission(-1, -1, true, new DataStoreAccessible(-1), []) });
    permissionSelectOptions.push({ name: "Shared with me", value: new DataStorePermission(-1, -1, false, new dss.DataSourceAccessible(-1), []) });
    //let permissionsProvider = Array.from(permissionFilterProvider.entrySet(connectorObjectNames)).map(x => { return permissionFilterProvider.dropDownOption(x)});

    filterProviders.set(this.FILTER_MYDS, permissionFilterProvider);
    dropDownModels.set(this.FILTER_MYDS, { model: permissionSelectOptions, selection: [] });

    // -- new wf (object filter)
    const workflowsObs = data[4].map(t => {
      return t
    });
    const workflowObjectNames = workflowsObs.map(ds => {
      return ds.name
    });
    const workflowComparator = new ObjectComparator<WorkflowRepositoryEntry>(["name"], v => {
      return v.name;
    });
    const workflowFilterProvider = new ArrayFilterProvider<WorkflowRepositoryEntry>(["workflows"], workflowComparator);
    let workflowsProvider = Array.from(workflowFilterProvider.entrySet(workflowsObs)).map(x => { return workflowFilterProvider.dropDownOption(x) });

    filterProviders.set(this.FILTER_WORKFLOW, workflowFilterProvider);
    dropDownModels.set(this.FILTER_WORKFLOW, { model: workflowsObs, selection: [] });

    // -- new connector (object filter)
    const datasourcesObb = data[5].map(t => {
      return t
    });
    const datasourceObjectNames = datasourcesObb.map(ds => {
      return ds.name
    });
    const datasourceComparator = new ObjectComparator<dss.DataSource>(["name"], v => {
      return v.name;
    });
    const datasourceFilterProvider = new ArrayFilterProvider<dss.DataSource>(["datasources"], datasourceComparator);
    let datasourcesProvider = Array.from(datasourceFilterProvider.entrySet(datasourcesObb)).map(x => { return datasourceFilterProvider.dropDownOption(x) });

    filterProviders.set(this.FILTER_DATASOURCE, datasourceFilterProvider);
    dropDownModels.set(this.FILTER_DATASOURCE, { model: datasourcesObb, selection: [] });


    // Aggregate all search options in tuple to return back
    const datamodel: [DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[]] = [namesProvider, fieldsProvider, idsProvider, permissionSelectOptions, schedulesProvider, workflowsProvider, datasourcesProvider];

    console.log(filterProviders);
    console.log(dropDownModels);
    return [filterProviders, dropDownModels, datamodel]

  }
  emitSearchParams(params: any, filters: Map<string, FilterProvider<{}>>) {
    this.objectSearchService.emitSearchParams(params, filters);
  }

  readonly FILTER_NAME: string = "Name";
  readonly FILTER_FIELD: string = "Field";
  readonly FILTER_ID: string = "id";
  readonly FILTER_MYDS: string = "IsOwner";
  readonly FILTER_SCHEDULE: string = "Schedule";
  readonly FILTER_WORKFLOW: string = "Workflow";
  readonly FILTER_DATASOURCE: string = "Datasource";

}




export class ScheduleSearch implements SearchProvider<[ScheduleActionPlan[], ScheduleBaseAction[], CronFrequency[], dss.DataSource[], WorkflowRepositoryEntry[], DataStore[]], SchedulePlanRecordView[]> {
  constructor(private bionApi: ApiBackendService, private userService: UserService, private utilService: UtilFunctionsService, private dataStoresService: CubesService, private objectSearchService: ObjectSearchService) { }
  onFilterChanged(dropDownModel: [DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[]], selection: [DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[]]): Map<string, DropDownModel> {

    // Build or update current SearchModel and return the updated searchModel
    let dropDownModels = new Map<string, DropDownModel>();
    dropDownModels.set(this.FILTER_ID, { model: dropDownModel[0], selection: selection[0] });
    dropDownModels.set(this.FILTER_NAME, { model: dropDownModel[1], selection: selection[1] });
    dropDownModels.set(this.FILTER_ACTION, { model: dropDownModel[2], selection: selection[2] });
    dropDownModels.set(this.FILTER_STATUS, { model: dropDownModel[3], selection: selection[3] });
    dropDownModels.set(this.FILTER_MYDS, { model: dropDownModel[4], selection: selection[4] });
    dropDownModels.set(this.FILTER_DATASOURCE, { model: dropDownModel[5], selection: selection[5] });
    dropDownModels.set(this.FILTER_DESTINATION, { model: dropDownModel[6], selection: selection[6] });
    dropDownModels.set(this.FILTER_WORKFLOW, { model: dropDownModel[7], selection: selection[7] });

    return dropDownModels

  }
  getData(): Observable<[ScheduleActionPlan[], ScheduleBaseAction[], CronFrequency[], dss.DataSource[], WorkflowRepositoryEntry[], DataStore[]]> {
    let schedules = this.bionApi.getScheduleActionPlan();
    let actionsObs = this.bionApi.getScheduleActions();
    let frequenciesObs = this.bionApi.getScheduleFrequencies();

    let dataStoresObs = this.bionApi.getDataStoreObjectList();
    let wfObs = this.bionApi.getWorkflowObjectList();
    let dsObs = this.bionApi.getDataSources();

    let finalSearchObs = forkJoin(schedules, actionsObs, frequenciesObs, dsObs, wfObs, dataStoresObs);

    return finalSearchObs;
  }
  setSearchModel(data: [ScheduleActionPlan[], ScheduleBaseAction[], CronFrequency[], dss.DataSource[], WorkflowRepositoryEntry[], DataStore[]], views: SchedulePlanRecordView[]) {
    //Fill Filter Options with Values

    console.log("views", views);
    let dropDownModels = new Map<string, DropDownModel>();
    let filterProviders = new Map<string, FilterProvider<any>>();


    // -- new name (object filter)

    const dsObject = data[0].map(t => {
      return t
    });
    const dsObjectNames = dsObject.map(ds => {
      return ds.name
    });
    const dsNameComparator = new ObjectComparator<ScheduleActionPlan>(["id", "name"], v => {
      return v.name + " (" + v.id + ")";
    });
    const dsNameFilterProvider = new AtomicFilterProvider<string>(["object", "name"]);
    let namesProvider = Array.from(dsNameFilterProvider.entrySet(dsObjectNames)).map(x => { return dsNameFilterProvider.dropDownOption(x) });

    filterProviders.set(this.FILTER_NAME, dsNameFilterProvider);
    dropDownModels.set(this.FILTER_NAME, { model: dsObject, selection: [] });

    // -- new id (object filter)
    const dsObjectIds = dsObject.map(ds => {
      return ds.id
    });
    const dsIdComparator = new ObjectComparator<ScheduleActionPlan>(["id", "name"], v => {
      return v.name + " (" + v.id + ")";
    });
    const dsIdFilterProvider = new AtomicFilterProvider<number>(["object", "id"]);
    let idsProvider = Array.from(dsIdFilterProvider.entrySet(dsObjectIds)).map(x => { return dsIdFilterProvider.dropDownOption(x) });

    filterProviders.set(this.FILTER_ID, dsIdFilterProvider);
    dropDownModels.set(this.FILTER_ID, { model: dsObject, selection: [] });

    // -- new action (object filter)
    const actionsObj = data[1].map(t => {
      return t
    });
    const actionObjectNames = actionsObj.map(ds => {
      return ds._type
    });
    const actionComparator = new ObjectComparator<ScheduleBaseAction>(["_type"], v => {
      return v._type;
    });
    const actionFilterProvider = new ArrayFilterProvider<ScheduleBaseAction>(["actions"], actionComparator);
    let actionsProvider = Array.from(actionFilterProvider.entrySet(actionsObj)).map(x => { return actionFilterProvider.dropDownOption(x) });

    filterProviders.set(this.FILTER_ACTION, actionFilterProvider);
    dropDownModels.set(this.FILTER_ACTION, { model: actionsObj, selection: [] });

    // // -- new cron frequencies (object filter)
    // var frequencyObj = data[2].map(t=>{ return t});
    // var frequencyObjectNames = actionsObj.map( ds => {return ds._type});
    // var frequencyComparator = new ObjectComparator<CronFrequency>(["_type"], v => { return v._type; });
    // var frequencyFilterProvider = new ArrayFilterProvider<CronFrequency>(["frequencies"],frequencyComparator);
    // let frequenciesProvider = Array.from(frequencyFilterProvider.entrySet(frequencyObj)).map(x => { return frequencyFilterProvider.dropDownOption(x)});

    // filterProviders.set(this.FILTER_FREQUENCY, frequencyFilterProvider);
    // dropDownModels.set(this.FILTER_FREQUENCY, {model:frequencyObj, selection:[]});


    // -- new status (object filter)
    const dsObjectStatus = dsObject.map(ds => {
      return ds.isActive
    });
    //var dsStatusComparator = new ObjectComparator<ScheduleActionPlan>(["isActive"], v => { return v.isActive ; });
    const dsStatusFilterProvider = new AtomicFilterProvider<boolean>(["object", "isActive"]);
    let statusProvider = Array.from(dsStatusFilterProvider.entrySet(dsObjectStatus)).map(x => { return dsStatusFilterProvider.dropDownOption(x) });

    filterProviders.set(this.FILTER_STATUS, dsStatusFilterProvider);
    dropDownModels.set(this.FILTER_STATUS, { model: dsObject, selection: [] });


    // -- new permission (object filter)
    const permissionComparator = new ObjectComparator<ActionPlanPermission>(["IsOwner"], v => {
      return ""
    });
    const permissionFilterProvider = new ArrayFilterProvider<ActionPlanPermission>(["permissions"], permissionComparator);

    let permissionSelectOptions: DropDownEntry<ActionPlanPermission>[] = [];
    permissionSelectOptions.push({ name: "My Schedules", value: new ActionPlanPermission(-1, -1, true, new ActionPlanAccessible(-1), []) });
    permissionSelectOptions.push({ name: "Shared with me", value: new ActionPlanPermission(-1, -1, false, new ActionPlanAccessible(-1), []) });

    filterProviders.set(this.FILTER_MYDS, permissionFilterProvider);
    dropDownModels.set(this.FILTER_MYDS, { model: permissionSelectOptions, selection: [] });

    // -- new wf (object filter)
    const workflowsObs = data[4].map(t => {
      return t
    });
    const workflowObjectNames = workflowsObs.map(ds => {
      return ds.name
    });
    const workflowComparator = new ObjectComparator<WorkflowRepositoryEntry>(["name"], v => {
      return v.name;
    });
    const workflowFilterProvider = new ArrayFilterProvider<WorkflowRepositoryEntry>(["workflows"], workflowComparator);
    let workflowsProvider = Array.from(workflowFilterProvider.entrySet(workflowsObs)).map(x => { return workflowFilterProvider.dropDownOption(x) });

    filterProviders.set(this.FILTER_WORKFLOW, workflowFilterProvider);
    dropDownModels.set(this.FILTER_WORKFLOW, { model: workflowsObs, selection: [] });

    // -- new connector (object filter)
    const datasourcesObb = data[3].map(t => {
      return t
    });
    const datasourceObjectNames = datasourcesObb.map(ds => {
      return ds.name
    });
    const datasourceComparator = new ObjectComparator<dss.DataSource>(["name"], v => {
      return v.name;
    });
    const datasourceFilterProvider = new ArrayFilterProvider<dss.DataSource>(["datasources"], datasourceComparator);
    let datasourcesProvider = Array.from(datasourceFilterProvider.entrySet(datasourcesObb)).map(x => { return datasourceFilterProvider.dropDownOption(x) });

    filterProviders.set(this.FILTER_DATASOURCE, datasourceFilterProvider);
    dropDownModels.set(this.FILTER_DATASOURCE, { model: datasourcesObb, selection: [] });


    // -- new destnations (object filter)
    const destinationObs = data[5].map(t => {
      return t
    });
    const destinationObjectNames = destinationObs.map(ds => {
      return ds.name
    });
    const destinationComparator = new ObjectComparator<DataStore>(["name"], v => {
      return v.name;
    });
    const destinationFilterProvider = new ArrayFilterProvider<DataStore>(["datastores"], destinationComparator);
    let destinationsProvider = Array.from(destinationFilterProvider.entrySet(destinationObs)).map(x => { return destinationFilterProvider.dropDownOption(x) });

    filterProviders.set(this.FILTER_DESTINATION, destinationFilterProvider);
    dropDownModels.set(this.FILTER_DESTINATION, { model: destinationObs, selection: [] });


    // Aggregate all search options in tuple to return back
    const datamodel: [DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[]] = [idsProvider, namesProvider, actionsProvider, statusProvider, permissionSelectOptions, datasourcesProvider, destinationsProvider, workflowsProvider];


    console.log(filterProviders);
    console.log(dropDownModels);
    return [filterProviders, dropDownModels, datamodel]

  }
  emitSearchParams(params: any, filters: Map<string, FilterProvider<{}>>) {
    this.objectSearchService.emitSearchParams(params, filters);
  }

  readonly FILTER_NAME: string = "Name";
  readonly FILTER_ACTION: string = "Action";
  readonly FILTER_FREQUENCY: string = "Frequency";
  readonly FILTER_ID: string = "id";
  readonly FILTER_MYDS: string = "IsOwner";
  readonly FILTER_DESTINATION: string = "Destination";
  readonly FILTER_WORKFLOW: string = "Workflow";
  readonly FILTER_DATASOURCE: string = "Datasource";
  readonly FILTER_STATUS: string = "ActiveStatus";
}

export class PowerBiSearch implements SearchProvider<PowerBiEntry[],any> {
  constructor(private bionApi: ApiBackendService, private errorService: SystemMessageLogService, private userService: UserService, private utilService: UtilFunctionsService, private biService: PowerbiService, private objectSearchService: ObjectSearchService) { }

  getData(): Observable<any> {
    throw new Error('Method not implemented.');
  }
  setSearchModel(data: PowerBiEntry[], views: any) {
    throw new Error('Method not implemented.');
  }
  onFilterChanged(options: [DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[]], selected: [DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[]]): Map<string, DropDownModel> {
    throw new Error('Method not implemented.');
  }
  emitSearchParams(params: Map<string, DropDownModel>, filter: Map<string, FilterProvider<{}>>): void {
    throw new Error('Method not implemented.');
  }

}


@Component({
  selector: 'app-general-search',
  templateUrl: './general-search.component.html',
  styleUrls: ['./general-search.component.scss']
})


export class GeneralSearchComponent<T, P> implements OnInit {
  subs = new SubSink;
  @Input() typeClass!: SearchProvider<T, P>;
  @Input() currentView!: string;


  filterProviders: Map<string, FilterProvider<{}>> = new Map<string, FilterProvider<{}>>();
  // dropDownModels : Map<string, {model:any[],selection:any[]}> = new Map<string,{model:[],selection:[],getSelection():[]}>();
  dropDownModels: Map<string, DropDownModel> = new Map<string, { model: [], selection: [], getSelection(): [] }>();
  //dropDownGuIModels: any[] = [];
  dropDownGuIModels: [DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[],] = [[], [], [], [], [], [], [], []];
  selectedDropDownModels: [DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[], DropDownEntry<any>[],] = [[], [], [], [], [], [], [], []];

  filteredNames: any;
  filterLabel: string = "";
  FilterSetBoolean: boolean = false;

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
        this.subs.sink = this.translate.onLangChange.subscribe(() => {
            this.onApplyFilter()
        })
  }

  setSearchModel(typeClass: SearchProvider<T, P>, views: any): Observable<boolean> {

    this.typeClass = typeClass;

    // this.subs.sink = this.typeClass.getData().subscribe((SearchDataResults) => {
    //     //this.dataModel = SearchDataResults;


    //     //console.log(SearchDataResults);
    //     let initialSearchModel = this.typeClass.setSearchModel(SearchDataResults, views);

    //     this.filterProviders = initialSearchModel[0];
    //     this.dropDownModels = initialSearchModel[1];
    //     this.dropDownGuIModels = initialSearchModel[2];

    //     this.selectedDropDownModels = [[],[],[],[],[],[],[],[]];
    //     //this.selectedDropDownModels[0] = new Array<DropDownEntry<string>>(1);
    //     //this.selectedDropDownModels[0].push({name: "", value:""});

    //     console.log(this.dropDownGuIModels,this.filterProviders);


    // },(err) => {
    //   console.log(err);
    // });

    return this.typeClass.getData().pipe(map((SearchDataResults) => {
      let initialSearchModel = this.typeClass.setSearchModel(SearchDataResults, views);

      this.filterProviders = initialSearchModel[0];
      this.dropDownModels = initialSearchModel[1];
      this.dropDownGuIModels = initialSearchModel[2];

      this.selectedDropDownModels = [[], [], [], [], [], [], [], []];
      this.filterLabel = this.translate.instant("NoFiltersApplied");
      this.FilterSetBoolean = false;

      return !!this.selectedDropDownModels;
    }))

    //return of(true)
  }

  // search(event) {
  //   let filtered: any[] = [];
  //   let query = event.query;
  //   console.log(this.dropDownGuIModels);
  //   for (let i = 0; i < this.dropDownGuIModels[0].length; i++) {
  //     let country = this.dropDownGuIModels[0][i];
  //     if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
  //       filtered.push(country);
  //     }
  //   }

  //   this.filteredNames = filtered;

  //   console.log(filtered)
  // }

  autoApplyFilter:boolean = false;

  onApplyFilter() {
    // Button wurde gedrückt
    const updatedDropdownModel = this.typeClass.onFilterChanged(this.dropDownGuIModels, this.selectedDropDownModels);
    console.log(updatedDropdownModel);
    const selectedEntriesCount = this.selectedDropDownModels.flatMap(entry => entry).length;
    console.log("This.FilterProvider", selectedEntriesCount);
    if(selectedEntriesCount > 0) {
        this.filterLabel = selectedEntriesCount + this.translate.instant("FilterApplied");
        this.FilterSetBoolean = true;
    } else {
        this.filterLabel = this.translate.instant("NoFiltersApplied");
        this.FilterSetBoolean = false;
    }
    this.typeClass.emitSearchParams(updatedDropdownModel, this.filterProviders);
  }

  onClearFilters() {
    this.selectedDropDownModels = [[], [], [], [], [], [], [], []];

    if(this.autoApplyFilter) {
        this.onApplyFilter();
      }

  }

  emitFilterChanged() {
    // Filter hat sich geändert
    if(this.autoApplyFilter) {
      this.onApplyFilter();
    }
  }
}
