import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { Id } from "src/app/helper/id";
import { ViewInfoRow } from "src/app/models/api/domain/NewDataSourceModelBase";
import { RightHolder } from "src/app/models/api/models/authorization/RightHolder";
import { WorkflowRepositoryEntry } from "src/app/models/api/models/workflow/WorkflowRepositoryEntry";

import { GroupRoleView, RightHolderView, UserRoleView } from "src/app/models/auth-service-decorator.model";
// import { RightHolder, ViewInfoRow } from "src/app/models/authorization.model";
import { DataSource } from "src/app/models/datasource.model";
import { ScheduleActionPlan } from "src/app/models/schedule.model";
// import { WorkflowRepositoryEntry } from "src/app/models/workflow.model";
import { AuthorizationService } from "src/app/services/authorization.service";
import { DatasourcesService } from "src/app/services/datasources.service";
import { SchedulesService } from "src/app/services/schedules.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { UserService } from "src/app/services/user.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { SubSink } from "subsink";
import {
	PermissionManager,
	PermissionView,
} from "../../components/user-permissions-list/user-permissions-list.component";

@Component({
	selector: "app-change-permission-dialog",
	templateUrl: "./change-permission-dialog.component.html",
	styleUrls: ["./change-permission-dialog.component.scss"],
})
export class ChangePermissionDialogComponent implements OnInit, OnDestroy {
	subs = new SubSink();
	savingInProgress: boolean = false;
	displayDialog: boolean = false;
	headerText: string = "";
	@Input() view!: string;

	selectedPermissionView?: PermissionView<any, any, any>;
	permissionManager?: PermissionManager<any, any, any,any>;

	rightHolderViews: RightHolderView<RightHolder>[] = [];
	selectedRightHolderView?: RightHolderView<RightHolder>;


	accessibles: WorkflowRepositoryEntry[] | DataSource[] | ViewInfoRow[] | ScheduleActionPlan[] = [];
	selectedAccessible?: WorkflowRepositoryEntry | DataSource | ViewInfoRow | ScheduleActionPlan;
	permissionLevels: string[] = [];
	roleTypes: string[] = [];
	rights: string[] = [];
	viewInfoRows: ViewInfoRow[] = [];

	constructor(
		private userService: UserService,
		private authService: AuthorizationService,
		private workflowService: WorkflowsService,
		private schedulesService: SchedulesService,
		private datasourceService: DatasourcesService,
		private systemLogService: SystemMessageLogService
	) { }
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngOnInit(): void {
		this.subs.sink = this.userService.selectedMenuPermissionEmitter.subscribe(
			(res: [PermissionView<any, any, any>, PermissionManager<any, any, any,any>]) => {
				this.selectedPermissionView = res[0];
				this.permissionManager = res[1];
			},
			(err:Error) => {
				this.systemLogService.handleError(err);
			}
		);
		this.subs.sink = this.userService.displayEditPermission.subscribe((res) => {
			this.displayDialog = true;
			this.headerText = "Permission.ChangePermission";
			this.initPermissionView();
		},
			(err) => {
				this.systemLogService.handleError(err);
			});
	}

	initPermissionView() {
		if (!this.selectedPermissionView) {
			console.log("selectedPermission not set, exit");
			return;
		}
		const selected_permission_view = this.selectedPermissionView;
		if (!this.permissionManager) {
			console.log("permissionManager not set, exit");
			return;
		}
		console.log(
			"selectedPermission given, load view...",
			this.selectedPermissionView,
			this.permissionManager
		);

		const user_role_view_obs = this.authService.getUserRoleViews();
		const group_role_view_obs = this.authService.getGroupRoleViews();
		const datasourcesObs = this.datasourceService.getDatasources();
		const workflowsObs = this.workflowService.getWorkflowObjectList();
		const schedulesObs = this.schedulesService.getScheduleActionPlan();
		const permissionLevelsObs = this.userService.getPermissionLevels();
		const viewInfoRowsObs = this.authService.getViewInfoRow();

		const all_obs: Observable<[UserRoleView[], GroupRoleView[], DataSource[], WorkflowRepositoryEntry[], ScheduleActionPlan[], string[], ViewInfoRow[]]> = forkJoin(
			user_role_view_obs,
			group_role_view_obs,
			datasourcesObs,
			workflowsObs,
			schedulesObs,
			permissionLevelsObs,
			viewInfoRowsObs
		);

		this.subs.sink = all_obs.subscribe((role_views) => {
			const userRoleViews = role_views[0];
			const groupRoleViews = role_views[1];
			const datasources = role_views[2];
			const workflows = role_views[3];
			const schedules = role_views[4];

			const viewInfoRows = role_views[6];
			
			

			const rh_1: RightHolderView<RightHolder>[] = userRoleViews;
			const rh_2: RightHolderView<RightHolder>[] = groupRoleViews;

			this.rightHolderViews = rh_1.concat(rh_2);

			this.selectedRightHolderView = this.rightHolderViews
				.find((holder) => holder.label === selected_permission_view.roleName);

			console.log("Start Test");

			this.subs.sink = this.authService
				.getWorkflowRights()
				.subscribe((res: string[]) => {
					this.rights = res;
				},
					(err) => {
						this.systemLogService.handleError(err);
					});

			if (this.view === "DataSourceView") {
				this.accessibles = datasources;
				this.selectedAccessible = datasources.find((accessible) => {
					return accessible.id === parseInt(selected_permission_view.accessibleName);
				});
			}
			if (this.view === "WorkflowView") {
				this.accessibles = workflows;
				this.selectedAccessible = workflows.find((accessible) => {
					return accessible.id === parseInt(selected_permission_view.accessibleName);
				});
			}
			if (this.view === "SchedulerView") {
				this.accessibles = schedules;
				this.selectedAccessible = schedules.find((accessible) => {
					return accessible.id === parseInt(selected_permission_view.accessibleName);
				});
			}
			if (this.view === "Views") {
				this.accessibles = viewInfoRows;
				this.selectedAccessible = viewInfoRows.find((accessible) => {
					return accessible.name === selected_permission_view.accessibleName;
				});
			}


			this.displayDialog = true;
		});
	}

	onChangePermission(event:PointerEvent) {
		try {
			this.changePermission();
		} catch(error) {
			this.systemLogService.handleError(<Error>error);
		}
	}

	changePermission() {
		this.savingInProgress = true;
		//Create permission argument

		console.log("StartChangePermission");

		const acc_name = Id.assertSet(this.selectedAccessible, new Error("No accessible object selected"));
		const right_holder = Id.assertSet(this.selectedRightHolderView, new Error("No User or Group selected"));
		const perm_view = Id.assertSet(this.selectedPermissionView, Error("No permission view selected"));

		perm_view.accessibleName = acc_name.name;
		perm_view.roleName = right_holder.label;

		// this.selectedPermissionView.accessibleName = this.selectedAccessible.name;
		// this.selectedPermissionView.roleName = this.selectedRightHolderView.label;

		let updated = { ...perm_view.permission };


		if (this.view === "WorkflowView") {
			this.subs.sink = this.authService
				.updateWorkflowPermission(updated)
				.subscribe(
					(res) => {
						this.authService.permissionsChangedEmitter.emit();
						this.displayDialog = false;
					},
					(err: Error) => {
						this.systemLogService.handleError(err);
					}, () => {
						this.savingInProgress = false;
					}
				);
		}
		if (this.view === "DataSourceView") {
			this.subs.sink = this.authService.updateDataSourcePermission(updated).subscribe(
				(res) => {
					this.authService.permissionsChangedEmitter.emit();
					this.displayDialog = false;
				},
				(err: Error) => {
					this.systemLogService.handleError(err);
				}, () => {
					this.savingInProgress = false;
				}
			)
		}
		if (this.view === "SchedulerView") {
			this.subs.sink = this.authService.updateActionPlanPermission(updated).subscribe(
				(res) => {
					this.authService.permissionsChangedEmitter.emit();
					this.displayDialog = false;
				},
				(err: Error) => {
					this.systemLogService.handleError(err);
				}, () => {
					this.savingInProgress = false;
				}
			)
		}
		if (this.view === "Views") {
			this.subs.sink = this.authService.updateViewPermission(updated).subscribe(
				(res) => {
					this.authService.permissionsChangedEmitter.emit();
					this.displayDialog = false;
				},
				(err: Error) => {
					this.systemLogService.handleError(err);
				}, () => {
					this.savingInProgress = false;
				}
			)
		}

	}

	clearDialog() {
		this.selectedPermissionView = undefined;
		this.permissionManager = undefined;
	}
}
