export namespace ConstantValuePlugIn {
	export class ConstantValueInfo {
		ColumnName: string;
		ColumnType?: string;
		ColumnLength: number;
		NewValue: string;
		constructor(ColumnName: string, ColumnLength: number, NewValue: string, ColumnType?: string) {
			if (ColumnName === undefined) throw new Error("Class 'ConstantValueInfo': Required value 'ColumnName' is undefined");
			if (ColumnLength === undefined) throw new Error("Class 'ConstantValueInfo': Required value 'ColumnLength' is undefined");
			if (NewValue === undefined) throw new Error("Class 'ConstantValueInfo': Required value 'NewValue' is undefined");
			this.ColumnName = ColumnName;
			this.ColumnType = ColumnType;
			this.ColumnLength = ColumnLength;
			this.NewValue = NewValue;
		}
	}
	export class Settings {
		Infos: Array<ConstantValueInfo>;
		constructor(Infos: Array<ConstantValueInfo>) {
			if (Infos === undefined) throw new Error("Class 'Settings': Required value 'Infos' is undefined");
			this.Infos = Infos;
		}
	}
} 