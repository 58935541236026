<!-- <p-dialog header="New Output" [resizable]="false" [draggable]="false" [modal]="true" [(visible)]="createNewOutputDialog"
    [style]="{width: '1250px'}" position="center" [maximizable]="true">
    Entered DataStore does not exist yet, do you want to create a new DataStore?
    <button style="float: right" pButton label="Create" icon="fa fa-plus" (click)="createNewDataStore()"></button>
</p-dialog> -->

<!-- <p-dialog header="New" [(visible)]="createNewOutputDialog" modal="modal" showEffect="fade"
    [style]="{width: '400px'}">
    <div style="line-height: 1.5">
        Entered DataStore does not exist yet, do you want to create a new DataStore?
    </div>
    <p-footer>
        <button type="button" pButton icon="pi pi-check" (click)="createNewDataStore()" label="Yes"
            class="p-button-text"></button>
        <button type="button" pButton icon="pi pi-times" (click)="createNewOutputDialog=false" label="No"
            class="p-button-text"></button>
    </p-footer>
</p-dialog> -->

<!-- <app-create-datastore-dialog></app-create-datastore-dialog> -->

<!-- <div class="p-grid">
        <div class="p-col-6">
            <p-dropdown [options]="dataStoresList" [(ngModel)]="selectedDataStore" optionLabel="name" filterBy="name"
                (onChange)="onUpdateSettings(false)"></p-dropdown>
        </div>
        <div class="p-col-6">
            <button pButton (click)="displayCreateDataStoreDialog()" icon="fa fa-plus" >New</button>
        </div>
</div> -->

<!-- <div class="p-grid">
    <div class="p-col-12 headerSection">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown [options]="dataStoresList" appendTo="body"  id="datastoreName" [autoDisplayFirst]="false"
                    [(ngModel)]="selectedDataStore" optionLabel="name" filterBy="name"
                    (onChange)="onUpdateSettings(false)"></p-dropdown>
                <label for="datastoreName">{{'Choose Destination' |translate }}</label>
            </span>
        </div>
    </div>
    <div class="p-col-12 headerSection">
        <button pButton class="p-button" label="{{'Create' | translate }}" style="float: right"
            class="p-button p-mr-2 p-mb-2" pTooltip="{{'CreateNewDatastore' | translate }}"
            (click)="displayCreateDataStoreDialog()" icon="fa fa-plus"></button>
    </div>
</div> -->


<div class="p-grid" style="margin:0 0">
    <div class="p-col-6" style="font-weight:bold; padding-left: 0;">
        {{'Choose DataStore' |translate }}<app-info-tooltip></app-info-tooltip>
    </div>

    <div class="p-fluid p-col">
        <span class="p-float-label">
            <p-dropdown id="datastoreName" appendTo="body" #dsSelection [options]="dataStoresList"
                [(ngModel)]="selectedDataStore" [autoDisplayFirst]="false"
                (onChange)="onUpdateSettings(true)" [filter]="true" filterBy="name" optionLabel="name"></p-dropdown>
            <label for="datastoreName">{{'Selected Datastore' |translate }}</label>
            <button pButton pRipple type="button" icon="pi pi-plus" label="{{'Create' | translate }}"
            class="p-button p-mr-2 p-mb-2" pTooltip="{{'CreateNewDatastore' | translate }}" tooltipPosition="bottom"
            (click)="displayCreateDataStoreDialog()"></button>
        </span>
    </div>
</div>

