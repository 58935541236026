import { RightHolderRich } from "./role-enrichment";
import { PermissionBase } from "src/app/models/api/models/authorization/PermissionBase";

/**
 * Permission mit zusätzlichen Attributen für die UI.
 */
export interface PermissionRich<A> extends PermissionBase<A> {
  Label: string;
  Type: string;
  Icon?: string;
  RichRole?: RightHolderRich;
}

/**
 * Unterstützt die PermissionRich-Erzeugung.
 */
export class PermissionEnrichment {

  /**
   * Fügt einer beliebigen Permission die Rich-Attribute hinzu
   * @param perm Permission
   * @param label Label
   * @param type Typ, meistens 'User' oder 'Group' bzw. sprachabhängige Bezeichner für diese.
   * @param icon Symbol
   * @param role Rolle
   * @returns Angereicherte Permission
   */
  static toRichPermission<A>(perm: PermissionBase<A>, label: string, type: string, icon?: string, role?: RightHolderRich): PermissionRich<A> {
    const part = { Label: label, Type: type, Icon: icon, RichRole: role };
    const rich = Object.assign(perm, part);
    return rich;
  }

  /**
   * Fügt einer beliebigen Permission Rich-Attribute auf Basis des angereicherten Rechteinhabers hinzu.
   * @param perm Permission
   * @param role Rolle / Richteinhaber
   * @returns Angereicherte Permission
   */
  static toRichPermissionRole<A>(perm: PermissionBase<A>, role: RightHolderRich): PermissionRich<A> {
    return this.toRichPermission(perm, role.Label, role.Type, role.Icon, role);
  }
}