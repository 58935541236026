<div>
    <p-table #dt [value]="trimInfoList" dataKey="ColumnName" editMode="row" sortMode="single">
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                <!-- <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="{{'LogHistory.SearchForLog' | translate}}" />
                </span> -->
                <span class="p-input-icon-right">
                    <button style="float: right" pButton label="{{'Add' | translate}}" icon="fa fa-plus"
                        (click)="onAddFilter()"></button>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width:3rem"></th>
                <th tooltipPosition="top" pTooltip="{{'Config.Sort.Tooltip1' | translate}}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Column' | translate}}
                    </div>
                </th>

                <th tooltipPosition="top" pTooltip="{{'Config.Sort.Tooltip2' | translate}}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Remove Characters' | translate}}
                    </div>
                </th>
                <th style="width:3rem"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-column let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="column" [pReorderableRow]="ri"
                [ngClass]="{'row-accessories': column.hasError === true}">
                <td>
                    <span class="pi pi-bars" pReorderableRowHandle tooltipPosition="left"
                        (onChange)="onUpdateSettings(false)" pTooltip="Drag & Drop to rearrange order">
                    </span>
                </td>
                <td>
                    <p-dropdown appendTo="body" *ngIf="column.hasError === false" [options]="columnList"
                        (onChange)="onUpdateSettings(false)" [(ngModel)]="column.ColumnName" [autoDisplayFirst]="false"
                        autoWidth="false" [style]="{'width':'100%'}"></p-dropdown>
                    <p-dropdown *ngIf="column.hasError === true" appendTo="body" [options]="columnList"
                        (onChange)="onUpdateSettings(false)" [autoDisplayFirst]="false" [(ngModel)]="column.ColumnName"
                        class="ng-invalid ng-dirty" autoWidth="false" [style]="{'width':'100%'}"></p-dropdown>
                </td>
                <td>
                    <input pInputText [(ngModel)]="column.RemoveCharacters" [style]="{'width':'100%'}"
                        (blur)="onUpdateSettings(false)" [ngModelOptions]="{standalone: true}">

                </td>
                <td>
                    <div class="ui-table-buttongroup">
                        <button pButton pRipple type="button" class="p-button-rounded p-button-text ui-button-danger"
                            icon="pi pi-trash" pTooltip="{{'Delete' | translate}}" (click)="onDeleteEntry(ri)"
                            tooltipPosition="bottom"></button>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="4">{{'NoEntryFound' | translate}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>