import { trigger, state, style, transition, animate } from "@angular/animations";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";

import { forkJoin, Observable, of, throwError } from "rxjs";
import { AppMainComponent } from "src/app/app.main.component";
import { NodeMetaData } from "src/app/models/api/com/bion/etl/NodeMetaData";
import { PsaInputPlugIn } from "src/app/models/api/models/etl/PsaInputPlugIn";
import { Model } from "src/app/models/api/models/license/model/Model";
import { CronFrequency, BasicFrequency } from "src/app/models/api/models/scheduler/CronFrequency";
import { DataStoreProtocolEntry } from "src/app/models/api/models/staging/DataStoreProtocolEntry";
import { WorkflowRepositoryEntry } from "src/app/models/api/models/workflow/WorkflowRepositoryEntry";
import { AdapterTypeInfo } from "src/app/models/connector.model";
import { DataStore, DataStoreField } from "src/app/models/datastore.model";
import { WorkflowNodeGuiInfo } from "src/app/models/designer.models";
import { NodePlugInInfos } from "src/app/models/nodePlugIn.model";
import {
	ExtractDataSourceAction,
	RunWorkflowAction,
	ScheduleActionPlan,
	ScheduleBaseAction,
	TimeStampInfo,
} from "src/app/models/schedule.model";
import { UserDetailsRow } from "src/app/models/user.model";
import { LoadingAppComponent } from "src/app/pages/loading-app/loading-app.component";
// import { UserInfo } from "src/app/models/user.model";
// import {
// 	WorkflowRepositoryEntry,
// } from "src/app/models/workflow.model";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { CubesService } from "src/app/services/cubes.service";
import { DatasourcesService } from "src/app/services/datasources.service";
import { ExperimentalApi } from "src/app/services/experimental-api.service";
import {
	SchedulesService,
} from "src/app/services/schedules.service";
import { TrackingService } from "src/app/services/tracking.service";
import { Limit } from "src/app/services/user-subscription.service";
import { UserService } from "src/app/services/user.service";
import { UtilFunctionsService } from "src/app/services/util-functions.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { SubSink } from "subsink";
import { GenericLatestActivitiesComponent } from "../objectManagement/generic-latest-activities/generic-latest-activities.component";
import { DashboardActivity } from "../objectManagement/generic-latest-activities/provider-dashboard";
import { DataSourceActivity } from "../objectManagement/generic-latest-activities/provider-data-source";
import { DataSourceNewActivity } from "../objectManagement/generic-latest-activities/provider-data-source-new";
import { DestinationActivity } from "../objectManagement/generic-latest-activities/provider-data-store";
import * as dss from "./../../../app/models/datasource.model";
import { DataSource } from "src/app/models/api/models/staging/DataSource";
import { Router } from "@angular/router";

export class CategoryTag {

	constructor(name: string, value: string, counter: number) {
		this.name = name;
		this.counter = counter;
		this.value = value;
	}

	name: string;
	value: string;
	counter: number;
}
@Component({
	selector: "app-dashboard",
	templateUrl: "./dashboard.component.html",
	styleUrls: ["./dashboard.component.scss"],
	animations: [trigger("fade", [
		state("void", style({ opacity: 0 })),
		transition(":enter", [animate(500)]),
		transition(":leave", [animate(500)]),
	]),],
	providers: [LoadingAppComponent]
})
export class DashboardComponent implements OnInit, OnDestroy {
	subs = new SubSink();
	loading: boolean = false;
	userDetails: UserDetailsRow[] = [];


	destinationActivityTypeClass = new DestinationActivity(this.bionApi, this.cubeService, this.userDetails, this.utilService, this.router);
	datasourceActivityTypeClass = new DataSourceNewActivity(this.datasourcesService, [], this.userDetails, this.utilService, this.router);

	dashboardActivityTypeClass = new DashboardActivity([], this.userDetails, this.utilService);
	@ViewChild('dashboardLatestActivities') dashboardLatestActivities!: GenericLatestActivitiesComponent<dss.ObjectProtocolRow, dss.ObjectProtocolRow[]>;

	@ViewChild('destinationLatestActivities') destinationLatestActivities!: GenericLatestActivitiesComponent<DataStore, DataStoreProtocolEntry[]>;
	@ViewChild('datasourceLatestActivities') datasourceLatestActivities!: GenericLatestActivitiesComponent<dss.DataSource, [dss.DataPackageProtocolEntry[], dss.DataSourceProtocolEntry[], dss.DataSource[]]>;

	datasources: dss.DataSource[] = [];
	fieldInfos: dss.DataSourceField[] = [];
	psaInfos: dss.PersistentStagingArea[] = [];
	adapterFiltered: dss.DataSourceAdapter[] = [];
	DatasourceCategoryTags: CategoryTag[] = [];

	plugInInfos: WorkflowNodeGuiInfo[] = [];
	workflows: WorkflowRepositoryEntry[] = [];
	workflowCategoryTags: CategoryTag[] = [];

	schedules: ScheduleActionPlan[] = [];
	baseActions: ScheduleBaseAction[] = [];
	frequencies: CronFrequency[] = [];
	timeStampInfos: TimeStampInfo[] = [];
	scheduleCategoryTags: CategoryTag[] = [];

	datastores: DataStore[] = [];
	dataStoreFields: DataStoreField[] = [];
	cubeCategoryTags: CategoryTag[] = [];

	currentLimits: Limit[] = [];

	lineStylesData: any;
	basicOptions: any;

	MessageHidden: boolean = false;

	ds_metric: number = 0;
	ds_metric_details: number = 0;

	wf_metric: number = 0;
	wf_metric_details: number = 0;

	dest_metric: number = 0;
	dest_metric_details: number = 0;

	final_metric: number = 0;
	final_metric_details: number = 0;

	onClickUpdateButton() {
		window.open(

			"https://buy.stripe.com/14kcNO9uq6sR3Dy288", "_blank");
	}
	onClickGiveFeedbackButton() {
		window.open(
			"https://form.jotform.com/221176350864355", "_blank");
	}
	openCommunityPage() {
		window.open(
			"https://bion-analytics.com/gruppen/", "_blank");
	}
	clearMessage() {
		this.MessageHidden = true;
	}
	constructor(
		private bionApi: ApiBackendService,
		private datasourcesService: DatasourcesService,
		private scheduleService: SchedulesService,
		private workflowService: WorkflowsService,
		private cubeService: CubesService,
		private userService: UserService,
		public appMain: AppMainComponent,
		private utilService: UtilFunctionsService,
		private expApi: ExperimentalApi,
		public loadApp: LoadingAppComponent,
		private trackingService: TrackingService,
        private router: Router
	) { }
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngOnInit() {
		console.log("INIT DASHBOARD");

		if (this.appMain.currentUserFull) {
			// let workflowObjectsObs = this.workflowService.getWorkflowObjectList();

			// workflowObjectsObs.subscribe((res)=> {console.log("WFObject Result:",res)},(err)=> {console.log("WFObject ERROR:",err)});

			this.subs.sink = this.getData().subscribe((data) => {
				let DatasourceCategoryObs = this.initDataSources(data);
				let DestinationCategoryObs = this.initDataStores(data);
				let WorkflowCategoryObs = this.initWorkflows(data);
				let ScheduleCategoryObs = this.initSchedules(data);
				//let dshistoryObs = this.datasourceLatestActivities.setObject(this.datasourceActivityTypeClass);
				let usersObs = this.userService.getUserDetailsRow();
				//let dsViewObs = this.expApi.getDataSourceView();

				//let objectProtocolsObs = this.bionApi.getObjectProtocolRow();
				let objectProtocolsObs = this.trackingService.getProtocolEntry();


				this.subs.sink = forkJoin(DatasourceCategoryObs, DestinationCategoryObs, WorkflowCategoryObs, ScheduleCategoryObs, objectProtocolsObs, usersObs).subscribe((final) => {
					try {
						this.DatasourceCategoryTags = final[0];
						this.cubeCategoryTags = final[1];
						this.workflowCategoryTags = final[2];
						this.scheduleCategoryTags = final[3];
						this.userDetails = final[5];
						this.initLimitStatus(this.appMain.currentUserFull.Licenses);
						this.initAutomationOverview_DS();
						this.initAutomationOverview_WF();
						this.initAutomationOverview_DEST();
						this.initAutomationOverview_FINAL();

						const objProtocols = final[4];
						const dashboardActivityTypeClass = new DashboardActivity(objProtocols, this.userDetails, this.utilService);
						this.dashboardLatestActivities.setObject(dashboardActivityTypeClass).subscribe(() => {

						});

					} catch (err) {
						console.log(err)
					}
					finally {
						this.loading = false;

					}
				});

			}, (err) => { console.log(err) });
		}

	}
	selectedView: string = "Home";

	selectView(objString: string) {
		if (objString === this.selectedView) {
			this.selectedView = "Home"
		} else {
			this.selectedView = objString;
		}
	}

	getData(): Observable<any> {
		this.loading = true;
		let datasourceObs = this.bionApi.getDataSources();
		let dsFieldsObs = this.bionApi.getDataSourceFields();
		let adapterTypeInfoObs = this.bionApi.getAdapterTypeInfo();
		let connectorInfoObs = this.bionApi.getConnectorsInfo();

		let dataStoresObs = this.cubeService.getDataStoreObjectList();
		let dataStoreFieldsObs = this.cubeService.getDataStoreField();

		let nodePlugInsObs = this.workflowService.getNodePlugIns();
		let workflowObjectsObs = this.workflowService.getWorkflowObjectList();

		let schedulePlanObs = this.scheduleService.getScheduleActionPlan();
		let scheduleFreqObs = this.scheduleService.getScheduleFrequencies();
		let scheduleActionObs = this.scheduleService.getScheduleActions();
		const defaultEnd = new Date(2100, 12, 31);
		let scheduleTimeStampsObs = this.scheduleService.getFrequencyTimeStamps(defaultEnd);

		let finalObs = forkJoin(adapterTypeInfoObs, connectorInfoObs, datasourceObs, dsFieldsObs, dataStoresObs, dataStoreFieldsObs, nodePlugInsObs, workflowObjectsObs, schedulePlanObs, scheduleFreqObs, scheduleActionObs, scheduleTimeStampsObs);

		//let finalObs = forkJoin(datasourceObs);

		return finalObs
	}

	initDataSources(data: any[]): Observable<CategoryTag[]> {


		let adapters: AdapterTypeInfo<any, any>[] = data[0];
		let connectorInfos = data[1];
		this.datasources = data[2];
		this.fieldInfos = data[3];

		let adapterFiltered: AdapterTypeInfo<any, any>[] = [];
		adapters.map((adapter: AdapterTypeInfo<any, any>) => {
			connectorInfos.map((connector: dss.DataSourceConnector<any>) => {
				if (connector.Connector === adapter.Name)
					adapterFiltered.push(adapter);
			});
		});

		const categories = adapters.map(info => info.Category);

		const unique = categories.filter((v, i, a) => a.indexOf(v) === i);

		let categoryCounts: CategoryTag[] = [];
		for (let category of unique) {
			// let newEntry = new CategoryTag();
			// newEntry.value = category;
			// // Iterate through list to get counts of each datatype
			// let counter = 0;
			// for (let i of adapterFiltered) {
			// 	if (i.Category === category) {
			// 		counter++;
			// 	}
			// }
			// newEntry.counter = counter;

			// //Push new entry to final Array
			// categoryCounts.push(newEntry);

			// new variant
			let counter = 0;
			for (let i of adapterFiltered) {
				if (i.Category === category) {
					counter++;
				}
			}
			const new_entry = new CategoryTag("", category, counter);
			categoryCounts.push(new_entry);
		}
		return of(categoryCounts);

	}

	initWorkflows(data: any[]): Observable<CategoryTag[]> {
		const rawGuiInfo = NodePlugInInfos.getNodeGuiInfo();
		const workflowNodeGuiInfo = NodePlugInInfos.getWorkflowNodeGuiInfo(
			data[6],
			rawGuiInfo
		);
		this.plugInInfos = workflowNodeGuiInfo;
		this.workflows = data[7];

		return of(this.createWorkflowTags(data[7], workflowNodeGuiInfo));
	}
	createWorkflowTags(
		wfs: WorkflowRepositoryEntry[],
		plugInInfos: WorkflowNodeGuiInfo[]
	): CategoryTag[] {
		let workflowNodesArray: WorkflowNodeGuiInfo[] = [];

		wfs.map((val: WorkflowRepositoryEntry) => {
			let nodes = val.workflowData.Nodes;
			nodes.map((node) => {
				plugInInfos.map((plugIn: WorkflowNodeGuiInfo) => {
					if (plugIn.Engine.Name === node.Engine.Name)
						workflowNodesArray.push(plugIn);
				});
			});
		});

		let inputNodes = workflowNodesArray.filter((value: WorkflowNodeGuiInfo) => {
			return value.Category === "In/Out";
		});

		let transformationNodes = workflowNodesArray.filter(
			(value: WorkflowNodeGuiInfo) => {
				return value.Category !== "In/Out";
			}
		);

		let newCategoryArray: CategoryTag[] = [
			{ name: "In/Out", value: "In/Out", counter: inputNodes.length },
			{
				name: "Transformation",
				value: "Transformation",
				counter: transformationNodes.length,
			},
		];
		return newCategoryArray;
	}

	initSchedules(data: any[]): Observable<CategoryTag[]> {
		this.schedules = data[8];
		this.frequencies = data[9];
		this.baseActions = data[10];
		this.timeStampInfos = data[11];
		return of(this.createScheduleCategoryTags(data[11]));
	}
	createScheduleCategoryTags(timestampInfos: TimeStampInfo[]): CategoryTag[] {
		let MonthFrequencyCounter: number = 0;
		let WeekFrequencyCounter: number = 0;
		let ExtractActionCounter: number = 0;
		let RunActionCounter: number = 0;


		for (let info of timestampInfos) {
			let frequencyList = info.ActionPlan ? info.ActionPlan.Frequencies : [];
			let actionList = info.ActionPlan ? info.ActionPlan.Actions : [];

			for (let freq of frequencyList) {
				let basicFreq = <BasicFrequency>freq;

				if (
					basicFreq.RhythmSettings.Rhythm._type ===
					"models.scheduler.WeekRhythm"
				) {
					WeekFrequencyCounter = WeekFrequencyCounter + 1;
				}
				if (
					basicFreq.RhythmSettings.Rhythm._type ===
					"models.scheduler.MonthRhythm"
				) {
					MonthFrequencyCounter = MonthFrequencyCounter + 1;
				}
			}

			for (let ac of actionList) {
				let action = <ScheduleBaseAction>ac;
				if (action._type === "models.ScheduleExtractActionFullRow") {
					ExtractActionCounter = ExtractActionCounter + 1;
				}
				if (action._type === "models.ScheduleWorkflowActionFullRow") {
					RunActionCounter = RunActionCounter + 1;
				}
			}
		}

		let newCategoryArray: CategoryTag[] = [
			{
				name: "Extract",
				value: "models.ScheduleExtractActionFullRow",
				counter:
					ExtractActionCounter * (WeekFrequencyCounter + MonthFrequencyCounter),
			},
			{
				name: "Run",
				value: "models.ScheduleWorkflowActionFullRow",
				counter:
					RunActionCounter * (WeekFrequencyCounter + MonthFrequencyCounter),
			},
		];
		return newCategoryArray;
	}

	initDataStores(data: any[]): Observable<CategoryTag[]> {
		this.datastores = data[4];
		this.dataStoreFields = data[5];
		return of(this.createCubesTags(data[5]));
	}
	createCubesTags(fields: DataStoreField[]): CategoryTag[] {
		let newCategoryArray: CategoryTag[] = [
			{
				name: "Field",
				value: "Field",
				counter: fields.length,
			},
		];
		return newCategoryArray;
		//this.loading = false;
	}


	initLimitStatus(licenses: Model.Subscription[]) {
		const dsObs = this.bionApi.getDataSources();
		const wfObs = this.bionApi.getWorkflowObjectList();
		const destObs = this.bionApi.getDataStoreObjectList();
		const schedObs = this.bionApi.getScheduleActionPlan();

		forkJoin(dsObs, wfObs, destObs, schedObs).subscribe((result) => {

			// Data
			const ds = result[0];
			const wf = result[1];
			const dest = result[2];
			const sched = result[3];

			const dsCount = ds.length;
			const wfCount = wf.length;
			const destCount = dest.length;
			const schedCount = sched.length;

			if (!licenses || licenses.length === 0) {
				throwError(new Error("No licenses found"));
			}
			if (!licenses[0].Product || licenses[0].Product.length === 0) {
				throwError(new Error("No licenses found"));
			}

			const licenseParams = licenses[0].Product[0].MetaData.Data;

			const dsMax = parseInt(licenseParams['Datasources']);
			const wfMax = parseInt(licenseParams['Workflows']);
			const destMax = parseInt(licenseParams['Cubes']);
			const schedMax = parseInt(licenseParams['Schedules']);

			// Metrics
			const dsPerc = dsCount / dsMax * 100;
			const wfPerc = wfCount / wfMax * 100;
			const destPerc = destCount / destMax * 100;
			const schedPerc = schedCount / schedMax * 100;

			const validFrom = new Date(licenses[0].ValidFrom);
			const validTo = new Date(licenses[0].ValidTo);
			const today = new Date(Date.now());

			const diff = Math.abs(validTo.getTime() - today.getTime());
			var diffDays = Math.ceil(diff / (1000 * 3600 * 24));

			//this.diffDays = diffDays;

			// Final Objects
			const dsLimit: Limit = { name: "Datasources", max: dsMax, current: dsCount, percentage: dsPerc };
			const wfLimit: Limit = { name: "Workflows", max: wfMax, current: wfCount, percentage: wfPerc };
			const destLimit: Limit = { name: "Cubes", max: destMax, current: destCount, percentage: destPerc };
			const schedLimit: Limit = { name: "Schedules", max: schedMax, current: schedCount, percentage: schedPerc };

			let limitArray: Limit[] = new Array();
			limitArray.push(dsLimit, wfLimit, destLimit, schedLimit);


			this.currentLimits = limitArray;
			console.log(this.currentLimits);
			return limitArray
		})
	}

	initAutomationOverview_DS() {
		const all_workflows = this.workflows;
		const all_datasources = this.datasources;

		const all_workflow_nodes = this.workflows.flatMap((wf => {
			return wf.workflowData.Nodes
		}));

		const all_input_nodes = all_workflow_nodes.filter((node) => { return node.Name === "PsaInput" || node.Name === "DataStoreInput" });
		const all_transformation_nodes = all_workflow_nodes.filter((node) => { return !(node.Name === "PsaInput" || node.Name === "DataStoreInput") });
		const all_output_nodes = all_workflow_nodes.filter((node) => { return node.Name === "DataStoreOutput" || node.Name === "PsaOutput" });


		const node_properties = all_input_nodes.map((node) => { return node.Properties });
		const node_configs = node_properties.map((prop) => { return prop.Configuration });
		const node_metaInfos = node_properties.map((p) => { return p.MetaInfo });

		let fieldsArray = new Array();
		node_metaInfos.map((m) => {
			m.forEach((value: NodeMetaData[], key: string) => {
				const fields = value[0].FieldsInfo;
				fieldsArray.push(fields);
			});

		});

		const flattenedArray = [].concat(...fieldsArray);

		this.ds_metric_details = flattenedArray.length;
		const node_ds = <DataSource[]>node_configs.map((config) => { return config['SelectedDataSource'] }).filter((ds) => { return ds !== undefined});

		const unique_ds = node_ds.filter((value: DataSource, index, self) =>
			index === self.findIndex((t) => (
				t.Name === value.Name && t.ID === value.ID
			))
		)

		const ds_metric: number = unique_ds.length / all_datasources.length * 100;
		// const ds_metric_detail = node_configs.map((config) => { return })

		if(ds_metric > 0) {
			this.ds_metric = Math.round(ds_metric);
		} else {
			this.ds_metric = 0;
		}

		//this.ds_metric = Math.round(ds_metric);

	}

	initAutomationOverview_WF() {
		const schedulesObs = this.scheduleService.getScheduleActions();
		const all_workflows = this.workflows;
		const all_wf_Ids = all_workflows.map((wf) => wf.id);
		const all_nodes = all_workflows.flatMap((wf) => wf.workflowData.Nodes);

		schedulesObs.subscribe((actions) => {
			const wfActions = <RunWorkflowAction[]>actions.filter((action => { return action._type === "models.scheduler.RunWorkflowAction" }));
			const extActions = <ExtractDataSourceAction[]>actions.filter((action => { return action._type === "models.scheduler.ExtractDataSourceAction" }));

			const unique_wf = wfActions.filter((value, index, self) =>
				index === self.findIndex((t) => (
					t.workflow === value.workflow
				))
			)

			const unique_my_wfs = unique_wf.filter((wf) => { return all_wf_Ids.includes(wf.workflow) });

			const wf_metric: number = unique_my_wfs.length / all_workflows.length * 100;

			if(wf_metric > 0) {
				this.wf_metric = Math.round(wf_metric);
			} else {
				this.wf_metric = 0;
			}

			this.wf_metric_details = all_nodes.length;
		})
	}


	initAutomationOverview_DEST() {
		const all_workflows = this.workflows;
		const all_datastores = this.datastores;

		const all_workflow_nodes = all_workflows.flatMap((wf => {
			return wf.workflowData.Nodes
		}));

		const all_input_nodes = all_workflow_nodes.filter((node) => { return node.Name === "PsaInput" || node.Name === "DataStoreInput" });
		const all_transformation_nodes = all_workflow_nodes.filter((node) => { return !(node.Name === "PsaInput" || node.Name === "DataStoreInput") });
		const all_output_nodes = all_workflow_nodes.filter((node) => { return node.Name === "DataStoreOutput" || node.Name === "PsaOutput" });


		const node_properties = all_output_nodes.map((node) => { return node.Properties });
		const node_configs = node_properties.map((prop) => { return prop.Configuration });
		const node_metaInfos = node_properties.map((p) => { return p.MetaInfo });

		let fieldsArray = new Array();
		node_metaInfos.map((m) => {
			m.forEach((value: NodeMetaData[], key: string) => {
				const fields = value.length > 0 ? value[0].FieldsInfo : value;
				fieldsArray.push(fields);
			});

		});

		const flattenedArray = [].concat(...fieldsArray);

		this.dest_metric_details = flattenedArray.length;
		const node_ds = <DataStore[]>node_configs.map((config) => { return config['SelectedDataStore'] }).filter((ds) => { return ds !== undefined});

		const unique_ds = node_ds.filter((value, index, self) =>
			index === self.findIndex((t) => (
				t.name === value.name && t.id === value.id
			))
		)

		const ds_metric: number = unique_ds.length / all_datastores.length * 100;
		// const ds_metric_detail = node_configs.map((config) => { return })
		if(ds_metric > 0) {
			this.dest_metric = Math.round(ds_metric);
		} else {
			this.dest_metric = 0;
		}
		//this.dest_metric = Math.round(ds_metric);

	}

	initAutomationOverview_FINAL() {
		const all_workflows = this.workflows;

		const all_workflow_nodes = all_workflows.flatMap((wf => {
			return wf.workflowData.Nodes
		}));
		const steps = all_workflow_nodes.length;
		const minPerStep = 20;
		const minPerStepNow = 1;

		const before = steps * minPerStep;
		const now = steps * minPerStepNow;
		const perc = now / before * 100;

		const final_metric= 100 - perc;

		if(final_metric > 0) {
			this.final_metric = Math.round(final_metric);
		} else {
			this.final_metric = 0;
		}

		const final_metric_details = Math.round((before-now) / 60);
		if(final_metric_details > 0) {
			this.final_metric_details = Math.round(final_metric_details);
		} else {
			this.final_metric_details = 0;
		}
	}

	// myScriptElement: HTMLScriptElement;

	// startGuide() {
	// 	this.myScriptElement = document.createElement("script");
	// 	this.myScriptElement.src = "../../../assets/js/tourMain.js";
	// 	document.body.appendChild(this.myScriptElement);
	// }
}
