<div class="p-grid">
    <div class="p-col-6" *ngIf="dsActionType !== 2">

        <div style="width:100%">
            <p-dataView #dv [value]="adapterTypeInfoList" [paginator]="true" [rows]="12" filterBy="Name"
                [sortField]="sortField" [sortOrder]="sortOrder" layout="grid">
                <ng-template pTemplate="header">
                    <div class="flex flex-column md:flex-row md:justify-content-between">
                        <!-- <p-dropdown [options]="sortOptions" [(ngModel)]="sortKey" placeholder="Sort By Price" (onChange)="onSortChange($event)" styleClass="mb-2 md:mb-0"></p-dropdown> -->
                        <span class="p-input-icon-left mb-2 md:mb-0">
                            <i class="pi pi-search"></i>
                            <input type="search" pInputText placeholder="{{'Search connector' | translate}}"
                                (input)="dv.filter($event.target.value)">
                        </span>
                        <!-- <span style="float:right">
                            <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
                        </span> -->
                    </div>
                </ng-template>
                <ng-template let-product pTemplate="listItem">
                    <div class="col-12" (click)="onSelectedConnectorNew(product)">
                        <div class="p-grid p-shadow-2 product-list-item" style="margin: 15px;">
                            <div class="p-col-4 card-header">
                                <div class="card-title">
                                    <div pTooltip="{{product.Provider}}">
                                        <img src="assets/layout/images/connectors/{{product.Name}}.png"
                                            style="width:40px; height: 40px; margin-right: 5px;"
                                            [alt]=" product.Provider" />
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-8">
                                <div class="product-name">
                                    {{product.Name}}
                                </div>
                                <div>
                                    <p-badge value="{{product.Category}}">{{product.Category}}</p-badge>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template let-product pTemplate="gridItem">
                    <div class="p-col-12 p-md-6 p-lg-4" (click)="onSelectedConnectorNew(product)">
                        <div class="p-grid connectorItem" [ngClass]="{'highlight': selectedAdapterInfo === product}"
                            style="margin: 10px;">
                            <div class="p-col-12">
                                <div class="card-title p-grid" pTooltip="{{product.Name}}">
                                    <div class="p-col-4">
                                        <img src="assets/layout/images/connectors/{{product.Name}}.png"
                                            style="width:50px; height: 50px; margin-bottom: 5px; margin-top: 5px"
                                            [alt]=" product.Provider" />
                                    </div>
                                    <div class="p-col-8">
                                        <p-badge value="{{product.Category}}">{{product.Category}}
                                        </p-badge><br>
                                        {{product.Name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </ng-template>
            </p-dataView>
        </div>

        <!-- <p-scrollPanel [style]="{height: '550px'}">
                <div class="p-grid">
                    <div class="p-col-12">
                        <div class="p-grid p-col-12" style="margin-bottom: 5px;">
                            <span class="p-tag">{{'Files' | translate}}</span>
                        </div>
                        <div style="margin-left:10px" class="p-grid">
                            <div #connectorFilterList pTooltip="{{item.Name}}" class="connectorItem p-col-6 p-md-6 p-lg-3" style="text-align: center"
                                [ngClass]="{'highlight': selectedAdapterInfo === item}"
                                *ngFor="let item of FileAdapterList; let i = index;"
                                (click)="onSelectedConnectorNew(item)">
                                <img src="assets/layout/images/connectors/{{item.Name}}.png"
                                    style="width:60px; height: 60px; margin-top: 5px; margin-bottom: 5px" />
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12">
                        <div class="p-grid p-col-12" style="margin-bottom: 5px;">
                            <span class="p-tag">{{'Databases' | translate}}</span>
                        </div>
                        <div style="margin-left:10px" class="p-grid">
                            <div #connectorFilterList pTooltip="{{item.Name}}"  class="connectorItem p-col-6 p-md-6 p-lg-3" style="text-align: center;"
                                [ngClass]="{'highlight': selectedAdapterInfo === item}"
                                *ngFor="let item of DatabaseAdapterList; let i = index;"
                                (click)="onSelectedConnectorNew(item)">
                                <img src="assets/layout/images/connectors/{{item.Name}}.png"
                                    style="width:60px; height: 60px; margin-top: 5px; margin-bottom: 5px" />
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12">
                        <div class="p-grid p-col-12" style="margin-bottom: 5px;">
                            <span class="p-tag">{{'APIs' | translate}}</span>
                        </div>
                        <div style="margin-left:10px" class="p-grid">
                            <div #connectorFilterList pTooltip="{{item.Name}}"  class="connectorItem p-col-6 p-md-6 p-lg-3" style="text-align: center;"
                                [ngClass]="{'highlight': selectedAdapterInfo === item}"
                                *ngFor="let item of APIAdapterList; let i = index;"
                                (click)="onSelectedConnectorNew(item)">
                                <img src="assets/layout/images/connectors/{{item.Name}}.png"
                                    style="width:60px; height: 60px; margin-top: 5px; margin-bottom: 5px" />
                            </div>
                        </div>
                    </div>
                </div>

                <p-scrollTop target="parent" styleClass="custom-scrolltop" [threshold]="100" icon="pi pi-arrow-up">
                </p-scrollTop>
            </p-scrollPanel> -->
    </div>
    <div class="p-col-6">
        <div *ngIf="!selectedAdapterInfo" style="margin-top: 3rem;">
            <div style="text-align: center">
                <div style="height: 500px; top: 50%">
                    <app-no-data-available [view]="'connector'" [url]="'/connector'"></app-no-data-available>
                </div>
            </div>
        </div>
        <!-- <div *ngIf="!currentConnectorView" style="margin-top: 3rem;">
            <div style="text-align: center">
                <div style="height: 500px">
                    <img class="dsimg" [src]="'assets/layout/images/pages/asset-error.svg'" />
                    <p style="color: rgb(170, 170, 170);">Connector coming soon</p>                </div>
            </div>
        </div> -->
        <div *ngIf="selectedAdapterInfo" class="card p-shadow-2" style="margin: 10px 20px 0px 0px"
            [ngSwitch]="selectedAdapterInfo.Name">
            <h6 style="color: rgb(170, 170, 170)"> <span>{{selectedAdapterInfo.Name}}</span></h6>

            <p-scrollPanel [style]="{height: '470px'}">
                <div *ngSwitchDefault style="position: absolute;
                top: 40%;
                left: 50%;
                transform: translate(-50%, -50%);text-align: center;color: rgb(224, 224, 224);">
                    <img class="dsimg" [src]="'assets/layout/images/pages/asset-access.svg'" />
                    <p style="color: rgb(170, 170, 170);">{{'ConnectorOnlyAvailableUponRequest' | translate}}</p>
                    <a href="mailto:­info@bion-analytics.com">­info@bion-analytics.com</a>
                </div>
                <span style="margin-top: 25px;">
                    <app-excel-connector *ngSwitchCase="EXCEL_ADAPTER" [(currentConnectorView)]="currentConnectorView">
                    </app-excel-connector>
                    <app-excel-url-connector *ngSwitchCase="EXCEL_URL_ADAPTER"
                        [(currentConnectorView)]="currentConnectorView">
                    </app-excel-url-connector>
                    <app-csv-connector *ngSwitchCase="CSV_ADAPTER" [(currentConnectorView)]="currentConnectorView">
                    </app-csv-connector>
                    <app-csv-url-connector *ngSwitchCase="CSV_URL_ADAPTER"
                        [(currentConnectorView)]="currentConnectorView">
                    </app-csv-url-connector>
                    <app-json-connector *ngSwitchCase="JSON_ADAPTER" [(currentConnectorView)]="currentConnectorView">
                    </app-json-connector>
                    <app-jdbc-connector *ngSwitchCase="JDBC_ADAPTER" [(currentConnectorView)]="currentConnectorView">
                    </app-jdbc-connector>
                    <app-postgre-connector *ngSwitchCase="POSTGRE_ADAPTER"
                        [(currentConnectorView)]="currentConnectorView">
                    </app-postgre-connector>
                    <app-h2-embedded-connector *ngSwitchCase="H2_EMBEDDED_ADAPTER"
                        [(currentConnectorView)]="currentConnectorView">
                    </app-h2-embedded-connector>
                    <app-hsql-embedded-connector *ngSwitchCase="HSQL_EMBEDDED_ADAPTER"
                        [(currentConnectorView)]="currentConnectorView">
                    </app-hsql-embedded-connector>
                    <app-h2-server-connector *ngSwitchCase="H2_SERVER_ADAPTER"
                        [(currentConnectorView)]="currentConnectorView">
                    </app-h2-server-connector>
                    <app-hsql-server-connector *ngSwitchCase="HSQL_SERVER_ADAPTER"
                        [(currentConnectorView)]="currentConnectorView">
                    </app-hsql-server-connector>
                    <app-db2-connector *ngSwitchCase="DB2_ADAPTER" [(currentConnectorView)]="currentConnectorView">
                    </app-db2-connector>
                    <app-maria-db-connector *ngSwitchCase="MARIA_DB_ADAPTER"
                        [(currentConnectorView)]="currentConnectorView">
                    </app-maria-db-connector>
                    <app-redshift-connector *ngSwitchCase="REDSHIFT_ADAPTER"
                        [(currentConnectorView)]="currentConnectorView">
                    </app-redshift-connector>
                    <app-twitter-connector *ngSwitchCase="TWITTER_ADAPTER"
                        [(currentConnectorView)]="currentConnectorView">
                    </app-twitter-connector>
                    <app-datamarket-connector *ngSwitchCase="DATAMARKET_ADAPTER"
                        [(currentConnectorView)]="currentConnectorView">
                    </app-datamarket-connector>
                    <app-rest-api-connector *ngSwitchCase="REST_API_ADAPTER"
                        [(currentConnectorView)]="currentConnectorView">
                    </app-rest-api-connector>
                    <app-spss-connector *ngSwitchCase="SPSS_FILE_ADAPTER"
                    [(currentConnectorView)]="currentConnectorView">
                    </app-spss-connector>
                    <app-postgres-sql-server-singer-connector *ngSwitchCase="MELTANOO_POSTGRE_ADAPTER"
                    [(currentConnectorView)]="currentConnectorView">
                    </app-postgres-sql-server-singer-connector>
                    <app-sap-connector *ngSwitchCase="SAP_ADAPTER"
                    [(currentConnectorView)]="currentConnectorView">
                    </app-sap-connector>
                </span>

                <p-scrollTop target="parent" styleClass="custom-scrolltop" [threshold]="100" icon="pi pi-arrow-up">
                </p-scrollTop>
            </p-scrollPanel>
        </div>
    </div>
</div>
