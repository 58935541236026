import { Component, OnInit } from "@angular/core";
import { NodeConfigComponentBase } from "../node-config-component-base";
// import { FieldInfo, WorkflowNodeSettings } from "src/app/models/workflow.model";
import { JoinSettings, JoinInfo } from "src/app/models/nodeSettings.model";
import { DesignerService } from "src/app/services/designer.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { GuiDropDown } from "src/app/models/helperClass.model";
import { FieldInfo, WorkflowNodeSettings } from "src/app/models/api/com/bion/etl/Workflow";
import { Id } from "src/app/helper/id";

export class ConfigError {
	hasLeftError: boolean;
	hasRightError: boolean;
	constructor(hasLeftError: boolean, hasRightError: boolean) {
		this.hasLeftError = hasLeftError;
		this.hasRightError = hasRightError
	}
}

export class JoinInfoView {
	LeftColumn: string;
	RightColumn: string;
	hasError?: ConfigError;

	constructor(LeftColumn: string, RightColumn: string, hasError?: ConfigError) {
		this.LeftColumn = LeftColumn;
		this.RightColumn = RightColumn;
		this.hasError = hasError;
	}
}

@Component({
	selector: "app-join-node",
	templateUrl: "./join-node.component.html",
	styleUrls: ["./join-node.component.css"],
})
export class JoinNodeComponent
	extends NodeConfigComponentBase<JoinSettings>
	implements OnInit
{
	settingsToView(settings: WorkflowNodeSettings): void {
		// -- Get node config
		let currentConfig = <JoinSettings>settings;
		// -- Get Input Meta Data
		const left_meta_infos =
			this._currentWorkflowNodeAdapter.IWorkflowNode.Properties.MetaInfo.get(
				this.leftInputPort
			);
		const right_meta_infos =
			this._currentWorkflowNodeAdapter.IWorkflowNode.Properties.MetaInfo.get(
				this.rightInputPort
			);

		let leftColumnListArray: string[] = [];
		let rightColumnListArray: string[] = [];

		// create JoinInfoViews from DsFields for table
		if (left_meta_infos !== undefined && left_meta_infos.length > 0) {
			const left_input_field_infos = left_meta_infos[0].FieldsInfo;

			//this.LeftInputOptions = left_input_field_infos;

			// -- create string array for column dropdown
			leftColumnListArray = left_input_field_infos.map((fieldInfo) => {
				return fieldInfo.Name;
			});
			this.LeftInputOptions = leftColumnListArray;
		}
		if (right_meta_infos !== undefined && right_meta_infos.length > 0) {
			const right_input_field_infos = right_meta_infos[0].FieldsInfo;

			//this.RightInputOptions = right_input_field_infos;

			// -- create string array for column dropdown
			rightColumnListArray = right_input_field_infos.map((fieldInfo) => {
				return fieldInfo.Name;
			});
			this.RightInputOptions = rightColumnListArray;
		}

		if (currentConfig == undefined) {
			// -- if no config found, set default
			let newJoinList: JoinInfoView[] = [];
			this.joinList = newJoinList;
			this.selectedJoinType = this.JoinTypeOptions[0];
		} else {
			// -- if config given, load view from config
			if (currentConfig.JoinInfos.length > 0) {
				currentConfig.JoinInfos.map((info) => {
					if (leftColumnListArray.includes(info.LeftColumn)) {
						// -- column still exists, do nothing
					} else {
						// column does not exist anymore, meaning column name currently not included -> include
						leftColumnListArray.push(info.LeftColumn);
					}
				});

				currentConfig.JoinInfos.map((info) => {
					if (rightColumnListArray.includes(info.RightColumn)) {
						// -- column still exists, do nothing
					} else {
						// column does not exist anymore, meaning column name currently not included -> include
						rightColumnListArray.push(info.RightColumn);
					}
				});

				// const left_meta_data_array = left_meta_infos ? left_meta_infos[0] : 

				const left_fields = left_meta_infos ? left_meta_infos[0].FieldsInfo : [];
				const right_fields = right_meta_infos ? right_meta_infos[0].FieldsInfo : [];

				this.joinList = this.joinInfoToJoinView(
					currentConfig.JoinInfos,
					left_fields,
					right_fields
				);
			} else {
				this.joinList = [];
			}

			this.JoinTypeOptions.map((entry) => {
				// -- create selected SortMethod from config
				if (entry.value == currentConfig.JoinType) {
					this.selectedJoinType = entry;
				}
			});
		}
	}
	viewToSettings(): JoinSettings {
		const newSettings = <JoinSettings>{
			...this.getCurrentWorkflowNode().Properties.Configuration,
		};
		const filterInfoViews = this.joinList;
		const filterInfos = this.joinViewToJoinInfo(filterInfoViews);

		newSettings.JoinInfos = filterInfos;
		
		const select_join_type = Id.assertSet(this.selectedJoinType, new Error("No Selected JoinType found, should not happen"));
		newSettings.JoinType = select_join_type.value;

		// this.messageService.standardToastMessage({  severity : 'success',
		//                                                 summary : 'Filter successsfully added',
		//                                                 detail : 'New Filter was successfully added to the node.'});

		return newSettings;
	}
	onSettingsChanged(settings: JoinSettings) {
		throw new Error("Method not implemented.");
	}

	readonly leftInputPort = "Left";
	readonly rightInputPort = "Right";

	// Node options
	LeftInputOptions: string[] = [];
	RightInputOptions: string[] = [];
	JoinTypeOptions: GuiDropDown[];
	selectedJoinType?: GuiDropDown;

	// JoinList
	joinList: JoinInfoView[] = [];

	constructor(
		protected workflowService: WorkflowsService,
		protected designerEventService: DesignerService
	) {
		super(workflowService, designerEventService);
		this.JoinTypeOptions = [
			{ name: "Inner Join", value: "inner" },
			{ name: "Left Join", value: "left" },
			{ name: "Right Join", value: "right" },
			{ name: "Outer Join", value: "outer" },
		];
	}

	onAddJoinRow() {
		//let newJoin = new JoinInfoView();
		// if (this.LeftInputOptions) {
		// 	newJoin.LeftColumn = this.LeftInputOptions[0];
		// }
		// if (this.RightInputOptions) {
		// 	newJoin.RightColumn = this.RightInputOptions[0];
		// }

		const left_col = this.LeftInputOptions ? this.LeftInputOptions[0] : "";
		const right_col = this.RightInputOptions ? this.RightInputOptions[0] : "";
		const errorObject = new ConfigError(false, false);

		// errorObject.hasLeftError = false;
		// errorObject.hasRightError = false;

		//newJoin.hasError = errorObject;

		let newJoin = new JoinInfoView(left_col,right_col,errorObject);


		this.joinList.push(newJoin);
		this.onUpdateSettings(false);
	}
	onDeleteEntry(index: number) {

		this.joinList = [
			...this.joinList.slice(0, index),
			...this.joinList.slice(index + 1),
		];

		this.onUpdateSettings(false);
		// this.messageService.standardToastMessage({  severity : 'warning',
		// summary : 'Filter deleted',
		// detail : 'The selected filter was deleted successfully!'});
	}
	joinInfoToJoinView(
		joininfos: JoinInfo[],
		leftMeta?: FieldInfo[],
		rightMeta?: FieldInfo[]
	): JoinInfoView[] {
		let newJoinInfoView: JoinInfoView[] = [];

		joininfos.map((entry: JoinInfo) => {
			// -- create joininfo for each given entry
			//let newSettings = new JoinInfoView();

			//let configError = new ConfigError();
			//newSettings.hasError = configError;

			// -- Get respective fieldInfo class from config (left port meta)
			//newSettings.LeftColumn = entry.LeftColumn;

			let fieldInfoFoundLeft = leftMeta?.find((field) => {
				return field.Name === entry.LeftColumn;
			});

			const has_left_error = !fieldInfoFoundLeft;

			// -- Get respective fieldInfo class from config (right port meta)
			//newSettings.RightColumn = entry.RightColumn;
			let fieldInfoFoundRight = rightMeta?.find((field) => {
				return field.Name === entry.RightColumn;
			});

			const has_right_error = !fieldInfoFoundRight;

			let configError = new ConfigError(has_left_error,has_right_error);

			let newSettings = new JoinInfoView(entry.LeftColumn,entry.RightColumn,configError);


			newJoinInfoView.push(newSettings);
		});


		return newJoinInfoView;
	}
	joinViewToJoinInfo(joinList: JoinInfoView[]): JoinInfo[] {
		// -- starting with joinList from GUI

		// -- create new empty array
		let newJoinInfos: JoinInfo[] = [];

		// -- Iterate through joinList and create new JoinInfo Rows for each given entry
		joinList.map((entry: JoinInfoView) => {
			// -- create joininfo for each given entry
			let newSettings = new JoinInfo();

			newSettings.LeftColumn = entry.LeftColumn? entry.LeftColumn : "";
			newSettings.RightColumn = entry.RightColumn? entry.RightColumn : "";

			newJoinInfos.push(newSettings);
		});
		return newJoinInfos;
	}

	ngOnInit(): void {
		super.ngOnInit();
	}
}
