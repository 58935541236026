<button #actualTarget pButton class="p-button-round p-button-text" (click)="op.toggle($event, actualTarget)"
    [icon]="currentRunning ? 'pi pi-spin pi-spinner' : 'pi pi-cog'" label="{{taskLabel | translate}}"></button>



<p-overlayPanel #op>
    <ng-template pTemplate>
            <div style="width: 70vh" class="p-grid">
                <div class="p-col-12" style="text-align: left;color: white;background: linear-gradient(79deg, #34c7c2 0%, #0494c5 51%); margin: 0">
                    <p>
                        {{'CurrentRunning' | translate}} {{currentView | translate}}<br>
                        <!-- <span style=" font-weight: bold;">{{appMain.currentUser.userInfo.EMail}}</span>
                        <br> -->
                    </p>
                </div>
            <div class="p-col-12" style="margin: 0">
                <p-table #dt *ngIf="currentView !== 'Schedules'" [value]="tasks" dataKey="Job"
                    sortField="representative.name" sortMode="single"
                    [globalFilterFields]="['ID','Start','End','State','User', 'OriginInfo']" [scrollable]="false"
                    styleClass="p-datatable-customers p-datatable-sm">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 4rem"></th>
                            <th pSortableColumn="OriginInfo.Label">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    {{'Origin' | translate}}
                                    <p-sortIcon field="OriginInfo.Label"></p-sortIcon>
                                </div>
                            </th>
                            <th pSortableColumn="Completed">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    {{'State' | translate}}
                                    <p-sortIcon field="Completed"></p-sortIcon>
                                </div>
                            </th>
                            <th>
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    {{'Progress' | translate}}
                                    <p-sortIcon field="OriginInfo.Progress"></p-sortIcon>
                                </div>
                            </th>
                            <th style="width: 4rem"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-task let-expanded="expanded" let-rowIndex="rowIndex">
                        <tr [pSelectableRow]="task">
                            <td>
                                <button type="button" pButton pRipple [pRowToggler]="task"
                                    class="p-button-text p-button-rounded p-button-plain"
                                    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                            </td>
                            <td><span class="p-column-title">{{'Origin' | translate}}</span>
                                {{task.OriginInfo.Label}}
                            </td>
                            <td><span class="p-column-title">{{'State' | translate}}</span>
                                <span *ngIf="task.Completed === false"><i class="pi pi-spin pi-spinner"
                                        style="margin-right: 10px"></i>{{'Running' | translate}}</span>
                                <span *ngIf="task.Successful === false && task.Completed === true"><i class="pi pi-times"
                                        style="margin-right: 10px"></i>{{'Failed' | translate}}</span>
                                <span *ngIf="task.Successful === true  && task.Completed === true"><i
                                        class="pi pi-check-circle" style="margin-right: 10px"></i>{{'Success' |
                                    translate}}</span>
                            </td>
                            <td>
                                <span *ngIf="task.Progress">
                                    <p-progressBar *ngIf="task.Progress.Max" [value]="task.Progress.Percentage"
                                        mode="determinate" [style]="{'height': '12px'}"></p-progressBar>
                                    <p-progressBar *ngIf="!task.Progress.Max" [value]="task.Progress.Value"
                                        mode="indeterminate" [style]="{'height': '12px'}"></p-progressBar>
                                </span>
                            </td>
                            <td>
                                <div class="ui-table-buttongroup">
                                    <button pButton pRipple type="button" *ngIf="task.Completed === false"
                                        class="p-button-rounded p-button-text ui-button-danger" icon="pi pi-trash"
                                        pTooltip="Delete process" (click)="onCancelProgress(task)"
                                        tooltipPosition="bottom"></button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="rowexpansion" let-task>
                        <tr>
                            <td colspan="5">
                                <div class="p-3">
                                    <div class="p-grid">
                                        <div class="p-col">
                                            <h6> <i class="pi pi-user" style="margin-right: 10px"
                                                    *ngIf="task.OriginInfo.Initiator === 'User'"></i>
                                                <i class="pi pi-calendar" style="margin-right: 10px"
                                                    *ngIf="task.OriginInfo.Initiator === 'Schedule'"></i>{{'Initiator'
                                                | translate}}
                                            </h6>
                                            <p>
                                                {{task.OriginInfo.User.Username}}
                                            </p>
                                        </div>
                                        <div class="p-col">
                                            <h6>{{'Start' | translate}}</h6>
                                            <p>
                                                {{task.Start | date: 'medium'}}
                                            </p>
                                        </div>
                                        <div class="p-col">
                                            <h6>{{'End' | translate}}</h6>
                                            <p *ngIf="task.End">
                                                {{task.End | date: 'medium'}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="5">{{'NoTaskFound' | translate}}</td>
                        </tr>
                    </ng-template>
                </p-table>


                <p-table *ngIf="currentView === 'Schedules'" #dt [value]="scheduleTasks" dataKey="Job"
                    sortField="representative.name" sortMode="single"
                    [globalFilterFields]="['Job','Start','End','State','User', 'OriginInfo']" [scrollable]="false"
                    styleClass="p-datatable-customers p-datatable-sm">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 4rem"></th>
                            <th pSortableColumn="Job">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    {{'Job-ID' | translate}}
                                    <p-sortIcon field="Job"></p-sortIcon>
                                </div>
                            </th>
                            <th pSortableColumn="End">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    {{'Start' | translate}}
                                    <p-sortIcon field="OriginInfo.Label"></p-sortIcon>
                                </div>
                            </th>
                            <th pSortableColumn="Completed">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    {{'End' | translate}}
                                    <p-sortIcon field="End"></p-sortIcon>
                                </div>
                            </th>
                            <th>
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    {{'Status' | translate}}
                                    <p-sortIcon field="State"></p-sortIcon>
                                </div>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-task let-expanded="expanded" let-rowIndex="rowIndex">
                        <tr [pSelectableRow]="task">
                            <td>
                                <button type="button" pButton pRipple [pRowToggler]="task"
                                    class="p-button-text p-button-rounded p-button-plain"
                                    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                            </td>
                            <td><span class="p-column-title">{{'Job-ID' | translate}}</span>
                                {{task.Job}}
                            </td>
                            <td><span class="p-column-title">{{'Start' | translate}}</span>
                                {{task.Start | date: 'medium'}}
                            </td>
                            <td><span class="p-column-title">{{'End' | translate}}</span>
                                {{task.End | date: 'medium'}}
                            </td>
                            <td> <span class="p-column-title">{{'Status' | translate}}</span>
                                <span>
                                    <span *ngIf="task.State !== 'Ended'"><i class="pi pi-spin pi-spinner"
                                            style="margin-right: 10px"></i>{{'Pending' | translate}}</span>
                                    <!-- <span *ngIf="task.Successful === false && task.Completed === true"><i class="pi pi-times"
                                    style="margin-right: 10px"></i>{{'Failed' | translate}}</span> -->
                                    <span *ngIf="task.State === 'Ended'"><i class="pi pi-check-circle"
                                            style="margin-right: 10px"></i>{{'Success' | translate}}</span>
                                </span>
                            </td>
                            <!-- <td>
                            <div class="ui-table-buttongroup">
                                <button pButton pRipple type="button" *ngIf="task.Completed === false" class="p-button-rounded p-button-text ui-button-danger"
                                    icon="pi pi-trash" pTooltip="Delete process" (click)="onCancelProgress(task)"
                                    tooltipPosition="bottom"></button>
                            </div>
                        </td> -->
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="rowexpansion" let-task>
                        <tr>
                            <td colspan="5">
                                <div class="p-3">
                                    <div class="p-grid">

                                        <p-table [value]="task.ActionStates" dataKey="Action.ActionPlan">
                                            <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="Action">Action <p-sortIcon field="Action.ActionPlan"></p-sortIcon>
                            </th>
                            <th pSortableColumn="Start">Start <p-sortIcon field="Start"></p-sortIcon>
                            </th>
                            <th pSortableColumn="End">End <p-sortIcon field="End"></p-sortIcon>
                            </th>
                            <th pSortableColumn="Cancelled">Cancelled <p-sortIcon field="Cancelled"></p-sortIcon>
                            </th>
                            <th pSortableColumn="Result">Result <p-sortIcon field="Result"></p-sortIcon>
                            </th>
                            <th style="width: 4rem"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-state>
                        <tr>
                            <td>{{state.ActionInfo.Label}} (ID: {{state.Action.ActionPlan}})</td>
                            <td>{{state.Start | date: 'dd/MM/yyyy'}}</td>
                            <td>{{state.End | date: 'dd/MM/yyyy'}}</td>
                            <td>{{state.Cancelled}}</td>
                            <td><span class="p-column-title">{{'Result' | translate}}</span>
                                <span><i class="pi pi-check" style="color: #689f38"
                                        *ngIf="state.Result === true"></i></span>
                                <span><i class="pi pi-times" style="color: #d32f2f"
                                        *ngIf="state.Result === false"></i></span>
                                <span><i class="pi pi-trash" style="color: #d32f2f"
                                        *ngIf="state.Cancelled === true"></i></span>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="6">{{'NoEntryFound' | translate}}</td>
                        </tr>
                    </ng-template>
                </p-table>

            </div>
            </div>
            </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="5">{{'NoTaskFound' | translate}}</td>
            </tr>
        </ng-template>
        </p-table>
            </div>




    </div>
    </ng-template>
</p-overlayPanel>
