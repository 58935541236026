export class Clock {
    Hour: number;
    Minute: number;
    Second: number;
    constructor(Hour: number,Minute: number,Second: number) {
        if(Hour === undefined) throw new Error( "Class 'Clock': Required value 'Hour' is undefined" );
        if(Minute === undefined) throw new Error( "Class 'Clock': Required value 'Minute' is undefined" );
        if(Second === undefined) throw new Error( "Class 'Clock': Required value 'Second' is undefined" );
        this.Hour = Hour;
        this.Minute = Minute;
        this.Second = Second;
    }
}