import { ConnectorView } from "src/app/models/connectorView.model";
import * as dss from "src/app/models/datasource.model";
import { WriteMode } from "src/app/models/helperClass.model";
import { DatasourcesService } from "src/app/services/datasources.service";
import { concatMap, map, mergeMap } from "rxjs/operators";
import { forkJoin, Observable, of } from "rxjs";
import { DynamicItemInfo } from "src/app/models/com-bion-json";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { ExtractDataToPsaStream } from "src/app/models/api/models/api/ExtractDataToPsaStream";
import { CreatePsaResult } from "src/app/models/api/models/staging/CreatePsaResult";
import { CreatePsaArg } from "src/app/models/api/controllers/api/CreatePsaArg";

export class CreateDataSourceNewLib {

    // ----- LIBRARY FUNCTIONS ----- //

    /**
     * Get Base64 string if given, otherwise return undefined
     * @param view
     * @returns
     */
    static tryGetFileData(view: ConnectorView<any, any, any>): string | undefined {
        try {
            return view.getBase64FileData();
        } catch {
            return undefined;
        }
    }


    /**
     *
     * @param res
     */
     static data_to_preview(res: dss.DataTypePreview,initialPreviewColumns: dss.FieldInfo[]) {
		let indexedColumnData = res.Columns.map((v, i, a) => {
            const ici = {...v, ColumnIndex: i};
            return ici;
		});

		let dataPreviewColumns = indexedColumnData;

		if (initialPreviewColumns === undefined) {
			initialPreviewColumns = { ...indexedColumnData };
		}

		let cloned = indexedColumnData.map((x) => Object.assign({}, x));
		const dataPreviewColumnClone = cloned;

		const dataPreviewColumnsMapping = indexedColumnData; // Copy of DS Data Preview for Column Mapping
		let dataPreviewRowsOriginal = res.Rows;

		let data = [];
		for (let i = 0; i < dataPreviewRowsOriginal.length; i++) {
			let row = {};
			for (let j = 0; j < dataPreviewColumns.length; j++) {
				row[dataPreviewColumns[j]["Name"]] = dataPreviewRowsOriginal[i][j];
			}
			data.push(row);
		}

        return [dataPreviewColumns,dataPreviewColumnClone,dataPreviewColumnsMapping, initialPreviewColumns, data]
	}

    /**
     *
     * @param res
     */
     static data_to_preview_load(res: dss.DataTypePreview) {

		let data = [];
		for (let i = 0; i < res.Rows.length; i++) {
			let row = {};
			for (let j = 0; j < res.Columns.length; j++) {
				row[res.Columns[j]["Name"]] = res.Rows[i][j];
			}
			data.push(row);
		}

        return data
	}

    /**
     *
     * @returns
     */
    static createDataSourcesAsync(selectedWriteMode:WriteMode,selectedDsFields:dss.FieldInfo[],dsName: string, dsDescription: string,
        datasourceService: DatasourcesService,currentConnectorView: ConnectorView<any,any,any>, bionApi: ApiBackendService,
        datasourcePrefix: string,suffixConnect: string,autoTableName: boolean) {
		const update_modes = new Set(["Update", "Delta"]);
		if (update_modes.has(selectedWriteMode.name)) {
			if (selectedDsFields.filter((f) => f.IsKey).length == 0)
				throw new Error("Update & Delta Modes require key fields");
		}

		const newDataSource = new dss.DataSource(
			-1,
			dsName,
			1,
			true,
			true,
			dsDescription
		);

		let finalDsObs = datasourceService
			.createDataSource(newDataSource).pipe(concatMap((dsResult) => {
				const currentSelectedDatasource: Observable<dss.DataSource> = of({ ...dsResult });

				let connectorInfoObs = this.createConnectorInfo(dsResult,currentConnectorView,bionApi);
				let dsFieldsObs = this.createDataSourceFields(dsResult,selectedDsFields,datasourceService);
				let psaObs = dsFieldsObs.pipe(concatMap((dsfieldsResult) => {
					return this.createPSA(dsResult,datasourcePrefix,suffixConnect,dsName,autoTableName, selectedWriteMode,datasourceService);
				}));

				let resultObs = forkJoin({connectorInfoObs, psaObs,currentSelectedDatasource});
				return resultObs

			}));
		return finalDsObs;
	}

    /**
     *
     * @param ds
     * @returns
     */
    static createConnectorInfo(ds: dss.DataSource, currentConnectorView: ConnectorView<any,any,any>, bionApi: ApiBackendService): Observable<number> {
		let create_ds_arg = new dss.CreateDataSourceConnectorArg();

		create_ds_arg.DataSource = ds.id;

		// data source -> settings builder -> connector info
		const builder_future = currentConnectorView.getSettingsBuilder().pipe(
			map((builder) => {
				let connectorinfo = new DynamicItemInfo(
					currentConnectorView.getConnectorID(),
					builder.getConnectorSettings()
				);

				create_ds_arg.Info = connectorinfo;

				return create_ds_arg;
			})
		);

		return builder_future.pipe(
			mergeMap((newConnectorInfo) => {
				return bionApi.createConnectorInfo(newConnectorInfo).pipe(
					map((result: number) => {
						return result;
					})
				);
			})
		);
	}

    /**
     *
     * @param ds
     * @returns
     */
    static createDataSourceFields(
		ds: dss.DataSource,
        selectedDsFields: dss.FieldInfo[],
        datasourcesService: DatasourcesService
	): Observable<dss.DataSourceField[]> {
		let newArg = new dss.CreateDataSourceFieldsArg();

		let finalSelectedFields = selectedDsFields.map(
			(entry: dss.FieldInfo) => {
				let newFieldInfo = new dss.FieldInfo();
				newFieldInfo.Name = entry.Name;
				newFieldInfo.IsKey = entry.IsKey;
				newFieldInfo.DataType = entry.DataType;

				return newFieldInfo;
			}
		);
		newArg.DataSource = ds.id;
		newArg.Fields = finalSelectedFields;

		return datasourcesService.createDataSourceFields(newArg);
	}

    /**
     *
     * @param ds
     * @returns
     */
     static createPSA(ds: dss.DataSource,datasourcePrefix: string,suffixConnect: string, currentDataSourceName: string,autoTableName: boolean, selectedWriteMode: WriteMode,datasourcesService: DatasourcesService): Observable<CreatePsaResult> {
		// let newPsaArg = new CreatePsaArg();
		// newPsaArg.DataSource = ds.id;
		// newPsaArg.PsaTable = datasourcePrefix + suffixConnect + currentDataSourceName;
		// newPsaArg.PsaTableAutoName = autoTableName;
		// newPsaArg.PsaWriteMode = selectedWriteMode.name;

		const psa_table:string|undefined = autoTableName ? undefined : (datasourcePrefix + suffixConnect + currentDataSourceName);

		const arg = new CreatePsaArg(ds.id, selectedWriteMode.name,psa_table);

		return datasourcesService
			.api_createPersistentStagingArea(arg)
			.pipe(
				map((result: CreatePsaResult) => {
					return result;
				})
			);
	}

    	/**
	 * Get PSA Information for load process
	 * @param ds Data Source
	 * @param dataPreviewColumns
	 */
	static getPSA(
		ds: dss.DataSource,
		dataPreviewColumns: dss.FieldInfo[],
        writeModes: WriteMode[],
        bionApi: ApiBackendService
	): Observable<[dss.PersistentStagingArea, dss.FieldInfo[],WriteMode]> {
		const psa_future = bionApi.getPsaOfDataSource(ds.id);
		const fields_future = bionApi
			.getDataSourceFields(undefined, ds.id)
			.pipe(
				map((result: dss.DataSourceField[]) => {
					return this.filter_valid_ds_fields(result, dataPreviewColumns);
				})
			);

		const all_futures = forkJoin([psa_future, fields_future]);

		return all_futures.pipe(
			map((result) => {
				const psa = result[0][0];
				let selectedWriteModeOption = writeModes.find(
					(o) => o.name === psa.writeMode
				);

				return [...result,selectedWriteModeOption];
			})
		);
	}
    	/**
	 * Check if the selected fields are valid data source fields
	 * @param dataSourceFields
	 * @param selectedFields
	 * @returns Valid fields
	 */
	static filter_valid_ds_fields(
		dataSourceFields: dss.DataSourceField[],
		selectedFields: dss.FieldInfo[]
	) {
		let ds_field_set = new Set(dataSourceFields.map((r) => r.name));
		let valid_fields = selectedFields.filter((entry: dss.FieldInfo) => {
			return ds_field_set.has(entry.Name);
		});
		return valid_fields;
	}

    /**
     *
     */
    static loadPSAStreamAsync(ds: dss.DataSource,currentConnectorView: ConnectorView<any,any,any>, datasourcesService: DatasourcesService, isSimulate: boolean, writeMode?: WriteMode, base64?: string) {

		if (ds === undefined) throw new Error("The Datasource is undefined");

		let settingsBuilderObs = currentConnectorView.getSettingsBuilder();
		let extractObs = settingsBuilderObs.pipe(concatMap((builder)=> {
		let ConnectorSettings = new DynamicItemInfo(
				builder.getConnectorId(),
				builder.getConnectorSettings()
			);
			let arg = new ExtractDataToPsaStream.Argument(ds.id,isSimulate,base64,writeMode?.name,ConnectorSettings);
			let extractToPsaObs = datasourcesService.ExtractDataToPsaStream_async(arg);

			return extractToPsaObs;

		}));
		return extractObs;
	}



}
