export class SapConfig {
    AsHost: string;
    SystemNumber: string;
    Client: string;
    User: string;
    Password: string;
    Language: string;
    constructor(AsHost: string,SystemNumber: string,Client: string,User: string,Password: string,Language: string) {
        if(AsHost === undefined) throw new Error( "Class 'SapConfig': Required value 'AsHost' is undefined" );
        if(SystemNumber === undefined) throw new Error( "Class 'SapConfig': Required value 'SystemNumber' is undefined" );
        if(Client === undefined) throw new Error( "Class 'SapConfig': Required value 'Client' is undefined" );
        if(User === undefined) throw new Error( "Class 'SapConfig': Required value 'User' is undefined" );
        if(Password === undefined) throw new Error( "Class 'SapConfig': Required value 'Password' is undefined" );
        if(Language === undefined) throw new Error( "Class 'SapConfig': Required value 'Language' is undefined" );
        this.AsHost = AsHost;
        this.SystemNumber = SystemNumber;
        this.Client = Client;
        this.User = User;
        this.Password = Password;
        this.Language = Language;
    }
}