import { Component, OnDestroy, OnInit } from "@angular/core";
import { MessageService } from "primeng/api";
import { forkJoin, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Arrays } from "src/app/helper/arrays";
import { Id } from "src/app/helper/id";
import { ViewInfoRow } from "src/app/models/api/domain/NewDataSourceModelBase";
import { Accessible } from "src/app/models/api/models/authorization/Accessible";
import { GroupRole } from "src/app/models/api/models/authorization/GroupRoleLike";
import { PermissionBase } from "src/app/models/api/models/authorization/PermissionBase";
import { RightHolder } from "src/app/models/api/models/authorization/RightHolder";
import { UserRole } from "src/app/models/api/models/authorization/UserRoleLIke";
import { ViewPermission } from "src/app/models/api/models/authorization/ViewPermission";
import { UserInfo } from "src/app/models/api/models/session/UserInfo";
import { GroupRoleView, UserRoleView } from "src/app/models/auth-service-decorator.model";
import {
	Permission
} from "src/app/models/authorization.model";

import { GeneralTypeInfo } from "src/app/models/types.model";
import { AuthorizationService } from "src/app/services/authorization.service";
import { UserService } from "src/app/services/user.service";
import { SubSink } from "subsink";
import { PermissionManager, PermissionView, ViewPermissionManager } from "./components/user-permissions-list/user-permissions-list.component";


@Component({
	selector: "app-users-view",
	templateUrl: "./users-view.component.html",
	styleUrls: ["./users-view.component.scss"],
	providers: [MessageService]
})
export class UsersViewComponent<P extends PermissionBase<A>, A extends Accessible, E, V> implements OnInit, OnDestroy {
	subs = new SubSink();
	users: UserInfo[] = [];

	rightHolders: RightHolder[] = [];
	rightHolderTypes: GeneralTypeInfo<string>[] = [];
	rightHolderView: UserRoleView[] = [];
	permissions: Permission[] = [];

	userRoles: UserRole[] = [];
	userRolesView: UserRoleView[] = [];
	groupRoles: GroupRoleView[] = [];
	selectedUser?: UserInfo;

	permissionManager?: PermissionManager<P, A, E, V>;

	viewInfoRows: ViewInfoRow[] = [];
	viewPermissions: ViewPermission[] = [];

	genericPermissionView: PermissionView<P, A, E>[] = [];


	constructor(
		private userService: UserService,
		private authService: AuthorizationService
	) { }
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	getUsers() {
		this.subs.sink = this.userService.getUserCommon().subscribe((res: UserInfo[]) => {
			this.users = res;
		});
	}

	buildUserRoleView() {
		const userRolesObs = this.authService.getUserRoles();
		const usersObs = this.userService.getUserCommon();

		this.subs.sink = forkJoin(userRolesObs, usersObs).subscribe(
			(pairResult) => {
				const userRoles = pairResult[0];
				const users = pairResult[1];
				this.users = users;

				let user_role_views = userRoles.map((entry: UserRole) => {
					let userFound = users.find((user: UserInfo) => entry.UserID === user.ID);
					if (userFound) {
						let userRoleView = new UserRoleView(entry, userFound);
						return userRoleView;
					} else {
						return undefined;
					}

				});

				const clean_views = Arrays.dropUndef(user_role_views);

				this.userRolesView = clean_views;
			}
		);
	}

	buildGroupRole() {
		this.subs.sink = this.authService.getGroupRoleViews().subscribe(groupRoleViews => {
			this.groupRoles = groupRoleViews;
		})
	}

	buildViewPermission(): Observable<PermissionView<P, A, E>[]> {

		const userRolesObs = this.authService.getUserRoles();
		const groupRolesObs = this.authService.getGroupRoles();
		const viewInfoObs = this.authService.getViewInfoRow();
		const rightsObs = this.authService.getWorkflowRights();
		const viewPermissionsObs = this.authService.getViewPermission()
		const usersObs = this.userService.getUserCommon();

		const perm_obs = forkJoin(userRolesObs, groupRolesObs, viewInfoObs, viewPermissionsObs, rightsObs, usersObs).pipe(map(res => {

			let viewPermissions = <any>res[3];
			return this.buildGenericPermissionView(viewPermissions, res[0], res[5], res[1]);
		}));

		return perm_obs;
	}

	/**
	 * User Role Type Key
	 */
	static readonly UserRoleType = "UserRole";
	/**
	 * Group Role Type Key
	 */
	static readonly GroupRoleType = "GroupRole";

	buildGenericPermissionView(
		permissions: P[],
		userroles: UserRole[],
		users: UserInfo[],
		grouproles: GroupRole[]
	): PermissionView<P, A, E>[] {

		let permissionViews = permissions.map((permission: P) => {

			const userroleFound = userroles.find(userrole => permission.Role === userrole.ID);
			const grouproleFound = grouproles.find(grouprole => permission.Role === grouprole.ID);

			let roleType: string | undefined = undefined;
			let roleName: string | undefined = undefined;

			if (userroleFound) {
				roleType = UsersViewComponent.UserRoleType;
				const user_info_opt = users.find(user => user.ID === userroleFound?.UserID);
				if (user_info_opt?.Username) {
					roleName = user_info_opt?.Username;
				} else {
					throw new Error("Could not find a user role for the user id " + userroleFound.UserID);
				}
			}

			if (grouproleFound) {
				roleType = UsersViewComponent.GroupRoleType;
				roleName = grouproleFound.Name;
			}

			console.log("WARNING: Adjust CreateView Function to handle objects");

			const role_type = Id.assertSet(roleType, new Error("The role type was not set!"));
			const role_name = Id.assertSet(roleName, new Error("The role name was not set!"));

			if (this.permissionManager) {
				const permissionView = this.permissionManager.createView(
					permission,
					role_type,
					role_name,
					undefined
				);
				return permissionView;
			} else {
				throw new Error("The Permission Manager is not set!");
			}
		});

		return permissionViews;
	}

	ngOnInit(): void {
		// Get all users
		this.getUsers();

		// UserRoles
		// --- init user roles
		this.buildUserRoleView();
		// --- subscribe to user role events
		this.subs.sink = this.authService.userRolesChangedEmitter.subscribe(
			(res: UserRole) => {
				this.buildUserRoleView();
			}
		);
		// GroupRoles
		// --- init group roles
		this.buildGroupRole();
		// --- subscribe to group role events
		this.subs.sink = this.authService.groupRolesChangedEmitter.subscribe(
			(res: GroupRole) => {
				this.buildGroupRole();
			}
		);

		const permissionManager = new ViewPermissionManager(this.authService);
		const permissionManagerAny = <any>permissionManager;
		this.permissionManager = <PermissionManager<P, A, E, V>>permissionManagerAny;

		this.buildViewPermission().subscribe(views => {
			// TODO, handle Error
		})

		this.subs.sink = this.authService.permissionsChangedEmitter.subscribe(() => {
			// TODO, handle Error
			this.buildViewPermission().subscribe(views => { });
		})

		// Subscribe to USER EVENTS
		this.subs.sink = this.userService.selectedMenuUserEmitter.subscribe(
			(res: UserInfo) => {
				this.selectedUser = res;
			}
		);
		this.subs.sink = this.userService.newUserEmitter.subscribe(
			(res: UserInfo) => {
				this.users.push(res);
			}
		);
		this.subs.sink = this.userService.updatedUserEmitter.subscribe(
			(res: UserInfo) => {

				let targetIndex: number = -1;
				let counter = 0;

				for (let user of this.users) {
					if (user.ID === res.ID) {
						targetIndex = counter;
						break;
					}
					counter = counter + 1;
				}

				if (targetIndex === -1) throw new Error("UserID not found");

				//Copy result into target index
				this.users[targetIndex] = res;
			}
		);
		this.subs.sink = this.userService.deletedUserEmitter.subscribe(
			(res: UserInfo) => {
				this.users = [...this.users.filter((item) => item.ID !== res.ID)];
			}
		);
	}
}
