import { Component, Input, OnInit } from "@angular/core";
// import {
// 	PlayWorkflowPermission,
// 	WorkflowPermission,
// } from "src/app/models/workflow.model";
import { AuthorizationService } from "src/app/services/authorization.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { UserService } from "src/app/services/user.service";
import { SubSink } from "subsink";
import {
	PermissionManager,
	PermissionView,
} from "../../components/user-permissions-list/user-permissions-list.component";

@Component({
	selector: "app-delete-permission-dialog",
	templateUrl: "./delete-permission-dialog.component.html",
	styleUrls: ["./delete-permission-dialog.component.scss"],
})
export class DeletePermissionDialogComponent<E> implements OnInit {
	subs = new SubSink();
	@Input() view!: string;
	savingInProgress: boolean = false;
	displayDeletePermission: boolean = false;
	selectedPermissionView?: PermissionView<any, any, any>;
	permissionManager?: PermissionManager<any, any, E, any>;
	selectedObject?: E;

	constructor(
		private userService: UserService,
		private authService: AuthorizationService,
		private errorService: SystemMessageLogService
	) { }

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngOnInit(): void {
		this.subs.sink = this.userService.selectedMenuPermissionEmitter.subscribe(
			(view_and_manager: [PermissionView<any, any, any>, PermissionManager<any, any, E, any>]) => {
				this.selectedPermissionView = view_and_manager[0];
				this.permissionManager = view_and_manager[1];
				this.subs.sink = this.permissionManager.selectedObjectEmitter.subscribe(
					(res) => {
						this.selectedObject = res;
					}
				);
			},
			(err: Error) => {
				this.errorService.handleError(err);
			}
		);
		this.subs.sink = this.userService.displayDeletePermission.subscribe(
			(active: boolean) => {
				this.displayDeletePermission = active;
			},
			(err: Error) => {
				this.errorService.handleError(err);
			}
		);
	}

	deletePermission() {

		if (this.selectedPermissionView) {

			this.savingInProgress = true;

			if (this.view === "DataSourceView") {
				this.subs.sink = this.authService
					.deleteDataSourcePermission(this.selectedPermissionView.permission.ID)
					.subscribe(
						(res) => {

							this.authService.permissionsChangedEmitter.emit();
							this.displayDeletePermission = false;
						},
						(err: Error) => {
							this.errorService.handleError(err);
						}, () => {
							this.savingInProgress = false;
						}
					);
			}
			if (this.view === "WorkflowView") {
				this.subs.sink = this.authService
					.deleteWorkflowPermission(this.selectedPermissionView.permission.ID)
					.subscribe(
						(res) => {
							this.authService.permissionsChangedEmitter.emit();
							this.displayDeletePermission = false;
						},
						(err: Error) => {
							this.errorService.handleError(err);
						}, () => {
							this.savingInProgress = false;
						}
					);
			}
			if (this.view === "SchedulerView") {
				this.subs.sink = this.authService
					.deleteActionPlanPermission(this.selectedPermissionView.permission.ID)
					.subscribe(
						(res) => {
							this.authService.permissionsChangedEmitter.emit();
							this.displayDeletePermission = false;
						},
						(err: Error) => {
							this.errorService.handleError(err);
						}, () => {
							this.savingInProgress = false;
						}
					);
			}
			if (this.view === "Views") {
				this.subs.sink = this.authService
					.deleteViewPermission(this.selectedPermissionView.permission.ID)
					.subscribe(
						(res) => {
							this.authService.permissionsChangedEmitter.emit();
							this.displayDeletePermission = false;
						},
						(err: Error) => {
							this.errorService.handleError(err);
						}, () => {
							this.savingInProgress = false;
						}
					);
			}
		}
	}
	clearDialog() {
		this.selectedPermissionView = undefined;
		this.permissionManager = undefined;
		this.selectedObject = undefined;
	}
}