import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  providers: [MessageService]
})
export class ForgotPasswordComponent implements OnInit,OnDestroy {
  subs = new SubSink;
  form = new UntypedFormGroup({
  email: new UntypedFormControl("", [Validators.required]),
  });
  isSuccessful: boolean = false;
  resultMessage: string;

  constructor(private bionApi: ApiBackendService, private errorService: SystemMessageLogService, private messageService: MessageService, private translate: TranslateService) { 
    translate.addLangs(["en", "de"]);
    let browserLang = translate.getBrowserLang();

    if(browserLang === "de") {
        translate.setDefaultLang(browserLang);
    } else {
        translate.setDefaultLang("en")
    }
  }
    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

  ngOnInit(): void {
  }

  requestResetPassword() {
      // Send request to BE API

    const email = this.form.value.email;
    const hostUrl = window.location.host;
    const staticUrl = "/#/resetPassword";
    const protocol = window.location.protocol;

    const finalUrl = protocol + "//" + hostUrl + staticUrl;

    console.log(email, finalUrl);

     this.subs.sink = this.bionApi.resetPassword(email,finalUrl,"name").subscribe(() => {
      this.errorService.clearHttpError();
        this.messageService.add({
            severity: "success",
            summary: "Request successful!",
            detail:
                "Your request was successful. Please check your email for the reset link!",
        });
        this.isSuccessful = true;
        this.resultMessage = "An E-Mail was successfully sent to your email address to reset your password. You can close this window.";
      },err =>  {
        this.errorService.handleError(err);
        this.resultMessage = "E-Mail could not be sent. Please verify your entry and try again.";

      })
  }

}
