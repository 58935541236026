import { HttpErrorResponse } from "@angular/common/http";
import { Observable, from, throwError } from "rxjs";
import { mergeMap, map } from "rxjs/operators";
import { AsyncFileReader } from "src/app/helper/fileReader";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { ExcelBlobAccess } from "../api/com/bion/connect/excel/ExcelBlobAccess";
import { ExcelMetaInfo } from "../api/com/bion/connect/excel/ExcelMetaInfo";
import { DynamicItemInfo } from "../com-bion-json";
import { ExcelMetaAccess, ExtractMetaFromConnectorArg, SheetInfo } from "../connector.model";
import { ConnectorSettingsBuilder, ConnectorSettingsBuilderBase, ConnectorViewBase } from "../connectorView.model";
import { PlayErrorResponse } from "../play.error.model";


export class ExcelConnectorView extends ConnectorViewBase<ExcelBlobAccess, ExcelMetaInfo, ExcelMetaAccess> {

    constructor(api: ApiBackendService, connectorID: string) {
        super(api, connectorID);
        this.metaInfo = new ExcelMetaInfo(new Array<SheetInfo>());
        this.metaAccess = new ExcelMetaAccess();
    }

    getBase64FileData(): string {
        return this.base64FileData;
    }
    getDataSourceNameSuggestion(): string {
        return this.connectorSettings.Table;
    }
    // uploadFile(file: File) {
    //     this.uploadedFile = file;
    // }

    fileIsUpdated: boolean = false;
    flattenedFileList: [];
    base64FileData: string;

    prüfeSettingsIntegrität(metaData:ExcelMetaInfo, settings:ExcelBlobAccess) : Observable<[ExcelMetaInfo,ExcelBlobAccess]> {
        
        const sheet_found = metaData.Sheets.find(sheet => settings.Table === sheet.Name);
        
        if(!sheet_found) {
            // TODO: schöner format string.
            return throwError(new Error("The settings sheet $s no longer exists in the current sheets $sheetList. Please select on of these sheets."));
        }

        return super.prüfeSettingsIntegrität(metaData,settings);
    }

    fetchMetaDataObs(): Observable<ExcelMetaInfo> {

        const reader = new FileReader();
        reader.readAsDataURL(this.uploadedFile);


        const file_load_promise = new Promise<string>((resolve, reject) => {
            reader.onload = () => {
                // Collect Base64 File Info
                const base64Raw = reader.result as string;
                const base64FileData = base64Raw.split(",")[1];

                // update base 64 file data
                this.base64FileData = base64FileData;
                resolve(base64FileData);
            }
        });

        const file_obs = from(file_load_promise);

        const meta_obs = file_obs.pipe(mergeMap(file_res => {

            const excel_meta_access = new ExcelMetaAccess();
            const dynamic_meta_access_info = new DynamicItemInfo(this.connectorID, excel_meta_access);

            const extract_meta_arg = new ExtractMetaFromConnectorArg();
            extract_meta_arg.ConnectorMetaInfo = dynamic_meta_access_info;
            extract_meta_arg.Base64FileData = file_res;

            return this.api.api_extractMetaFromConnector(extract_meta_arg).pipe(map((extractRes) => {
                return <ExcelMetaInfo>extractRes.MetaData;
            }));
        }));

        return meta_obs;
    }

    handleError(error: HttpErrorResponse) {
        const play_error: PlayErrorResponse = error.error;
        console.log(play_error);
    }

    getSettingsBuilder(): Observable<ConnectorSettingsBuilder<ExcelBlobAccess>> {

        const asyncReader = new AsyncFileReader();
        const result = asyncReader.readAsync(this.uploadedFile);

        const finalRes = result.pipe(
            map((fileData: string) => {
                //<let innerRes = new ExcelSettingsBuilder(this.api, this.connectorID, this.connectorSettings.Table, fileData);
                //return innerRes;
                return new ExcelSettingsBuilder(this.connectorID, this.connectorSettings, fileData);
            })
        );
        return finalRes;
    }

    isFileBased(): boolean {
        return true;
    }
}

export class ExcelSettingsBuilder extends ConnectorSettingsBuilderBase<ExcelBlobAccess> {

}

// export class ExcelSettingsBuilder implements ConnectorSettingsBuilder<ExcelBlobAccess> {

//     constructor(api: ApiBackendService, connectorID: string, sheetName: string, base64FileData: string) {
//         this.api = api;
//         this.connectorID = connectorID;
//         this.sheetName = sheetName;
//         this.base64FileData = base64FileData;
//     }

//     getConnectorId(): string {
//         return this.connectorID;
//     }
//     getConnectorSettings() {
//         return new ExcelBlobAccess(this.sheetName, true, true, 100, new ReadSettings(true, 1));
//     }

//     base64FileData: string;
//     sheetName: string;
//     connectorID: string;
//     api: ApiBackendService;
// }