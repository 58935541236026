import { PlayJsonInheritance } from "src/app/models/play.model";
import { RightHolder } from "./RightHolder";

export interface GroupRoleLike extends RightHolder, PlayJsonInheritance {
    Name: string;
}

export class GroupRole implements GroupRoleLike {
    ID: number;
    Name: string;
    Description?: string;
    IsActive: boolean;
    _type: string;
    constructor(ID: number, Name: string, IsActive: boolean, Description?: string) {
        if (ID === undefined) throw new Error("Class 'GroupRole': Required value 'ID' is undefined");
        if (Name === undefined) throw new Error("Class 'GroupRole': Required value 'Name' is undefined");
        if (IsActive === undefined) throw new Error("Class 'GroupRole': Required value 'IsActive' is undefined");
        this.ID = ID;
        this.Name = Name;
        this.Description = Description;
        this.IsActive = IsActive;
    }
}