import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { concatMap, flatMap, map } from 'rxjs/operators';
import { Model } from '../models/api/models/license/model/Model';
import { ApiBackendService } from './api-backend.service';

export class SubscriptionView {
  licences: Model.Subscription[];
  limits: Limit[];
  remainingDays: number;
}
export class Limit {
  name: string;
  max: number;
  current: number;
  percentage: number;
}


@Injectable({
  providedIn: 'root'
})
export class UserSubscriptionService {
  _licenses = new EventEmitter<Model.Subscription[]>();

  constructor(private bionApi: ApiBackendService) { }

  initLimitStatus(licenses: Model.Subscription[]): Observable<SubscriptionView> {
    const dsObs = this.bionApi.getDataSources();
    const wfObs = this.bionApi.getWorkflowObjectList();
    const destObs = this.bionApi.getDataStoreObjectList();
    const schedObs = this.bionApi.getScheduleActionPlan();

    const final = forkJoin(dsObs,wfObs,destObs,schedObs).pipe(concatMap((result) => {

      // Data
      const ds = result[0];
      const wf = result[1];
      const dest = result[2];
      const sched = result[3];

      const dsCount = ds.length;
      const wfCount = wf.length;
      const destCount = dest.length;
      const schedCount = sched.length;

      const latestLicence = licenses[0];
      const licenseParams = latestLicence.Product[0].MetaData.Data;
      console.log(licenseParams);

      const dsMax = parseInt(licenseParams['Datasources']);
      const wfMax = parseInt(licenseParams['Workflows']);
      const destMax = parseInt(licenseParams['Cubes']);
      const schedMax = parseInt(licenseParams['Schedules']);

      // Metrics
      const dsPerc = dsCount / dsMax * 100;
      const wfPerc = wfCount / wfMax* 100;
      const destPerc = destCount / destMax* 100;
      const schedPerc = schedCount / schedMax* 100;

      const validFrom = new Date(licenses[0].ValidFrom);
      const validTo = new Date(licenses[0].ValidTo);
      const today = new Date(Date.now());

      const diff = Math.abs(validTo.getTime() - today.getTime());
      var diffDays = Math.ceil(diff / (1000 * 3600 * 24)) > 0 ? Math.ceil(diff / (1000 * 3600 * 24)) : 0; 



      //this.diffDays = diffDays;

      // Final Objects
      const dsLimit: Limit = {name: "Datasources", max: dsMax, current: dsCount, percentage: dsPerc};
      const wfLimit: Limit = {name: "Workflows", max: wfMax, current: wfCount, percentage: wfPerc};
      const destLimit: Limit = {name: "Cubes", max: destMax, current: destCount, percentage: destPerc};
      const schedLimit: Limit = {name: "Schedules", max: schedMax, current: schedCount, percentage: schedPerc};

      let limitArray: Limit[] = new Array();
      limitArray.push(dsLimit,wfLimit,destLimit,schedLimit);

      const finalObi: SubscriptionView = {licences: licenses,limits: limitArray,remainingDays: diffDays}

      // this.currentLimits = limitArray;
      // console.log(this.currentLimits);
      return of(finalObi)
    })

  );
  
  return final;
};


}
