<div class="exception-body error">
    <div class="exception-topbar">
        <a id="logolink" [routerLink]="['/']" class="layout-topbar-logo">
            <img [src]="'assets/layout/images/logo-' + (app.topbarTheme === 'dark' ? 'bion-white' : 'bion') + '.svg'" alt="bion-layout"/>
        </a>
    </div>
    <div class="exception-wrapper">
        <div class="exception-content">
            <img src="assets/layout/images/pages/asset-error.svg" alt="bion-layout" />
            <span>Error</span>
        </div>
        <div class="exception-footer">
            <h4>bion</h4>
            <h6>Copyright Ⓒ PrimeTek Informatics</h6>
        </div>
    </div>
</div>
