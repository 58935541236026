import { Component, OnDestroy, OnInit } from "@angular/core";
import { MessageService } from "primeng/api";
import { forkJoin, of } from "rxjs";
import { WriteMode } from "src/app/models/helperClass.model";
import { DatasourcesService } from "src/app/services/datasources.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { SubSink } from "subsink";
import * as dss from "../../../../models/datasource.model";
import { TranslateService } from "@ngx-translate/core";



@Component({
	selector: "app-change-write-mode-dialog",
	templateUrl: "./change-write-mode-dialog.component.html",
	styleUrls: ["./change-write-mode-dialog.component.scss"],
    providers: [MessageService],
})
export class ChangeWriteModeDialogComponent implements OnInit, OnDestroy {
	private subs = new SubSink();
	savingInProgress: boolean = false;
	displayChangeWriteMode: boolean;
	buttonDisabled: boolean = false;

	currentSelectedDatasource?: dss.DataSource;
	psaInfos?: dss.PersistentStagingArea;
	writeModeOptions: WriteMode[];
	selectedWriteModeOption: WriteMode;

	constructor(
		private datasourceService: DatasourcesService,
		private systemLogService: SystemMessageLogService,
        public messageService: MessageService,
		private translate: TranslateService
	) {}

	ngOnInit(): void {
		// this.subs.sink = this.datasourceService.selectedMenuDatasourceEmitter.subscribe(
		// 	(ds: dss.DataSource) => {
		// 		this.currentSelectedDatasource = ds;
		// 	}, (err: Error) => {
		// 		this.systemLogService.handleError(err)
		// 	}
		// );
		this.subs.sink = this.datasourceService.displayChangeWriteMode.subscribe(
			(res: [dss.DataSource,boolean]) => {
				this.currentSelectedDatasource = res[0]
				this.displayChangeWriteMode = res[1];

				if (!res) return;

				const writeModes = this.datasourceService.getWriteModes();
				const writeModesObs = of(writeModes);
				let psaObs = this.datasourceService.getPsaOfDataSource(this.currentSelectedDatasource.id);
				let finalObs = forkJoin(psaObs,writeModesObs);

				this.subs.sink = finalObs.subscribe((res) => {
					this.psaInfos = res[0];
					this.writeModeOptions = res[1];
					this.selectedWriteModeOption = res[1].find((i) => {
							return i.name === res[0].writeMode;
					});
				})


			}, (err: Error) => {
				this.systemLogService.handleError(err)
			}
		);
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	// Functions
	selectWriteModeOption(evt) {
		this.selectedWriteModeOption = evt.value;
	}

	updatePsaWriteMode() {
		this.savingInProgress = true;
		this.buttonDisabled = true;
		let psaUpdated = { ...this.psaInfos };
		psaUpdated.writeMode = this.selectedWriteModeOption.name;
		console.log(psaUpdated);


		this.subs.sink = this.datasourceService.updatePsa(psaUpdated).subscribe(
			() => {
                this.datasourceService.datasourceChangedEmitter.emit(this.currentSelectedDatasource);
                this.messageService.add({
					severity: "success",
					summary: this.translate.instant("Message.UpdateWriteModeSuccess.Title"),
					detail: this.translate.instant("Message.UpdateWriteModeSuccess.Text") + psaUpdated.writeMode,
				});
				this.displayChangeWriteMode = false;
			},
			(err: Error) => {
				this.systemLogService.handleError(err);
			}, () => {
				this.savingInProgress = false;
				this.buttonDisabled = false;
			}
		);
	}
}
