<div style="width:100%;height:100%;position: relative;border-radius: 50%;   
            height:100px;
            -moz-border-radius:50%;
            -webkit-border-radius:50%;">
    <div style="position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);text-align: center;color: rgb(224, 224, 224);">
        <!-- <i class="pi pi-info-circle" style="font-size: 32px;"></i> -->
        <p>{{'dataNotAvailable' | translate}}</p>
        <!-- <button pButton routerlink="url">Create</button> -->
    </div>

</div>
