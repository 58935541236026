import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { ScheduleActionEvent, ScheduleDialogActionType } from 'src/app/models/dialog-actions.model';
import { DummySchedulerService, SchedulerNodeInfo } from 'src/app/services/dummy-backend.service';
import { SchedulesService } from 'src/app/services/schedules.service';

@Component({
  selector: 'app-schedule-planner-nav-toolbar',
  templateUrl: './schedule-planner-nav-toolbar.component.html',
  styleUrls: ['./schedule-planner-nav-toolbar.component.scss']
})
export class SchedulePlannerNavToolbarComponent implements OnInit {
  tieredItems: MenuItem[] = [];
  plugInInfos:SchedulerNodeInfo[] = [];
  savingInProgress: boolean = false;
  constructor(private schedulesService: SchedulesService, private dummyService: DummySchedulerService) { }

  ngOnInit(): void {
    this.tieredItems = [
      {
          label: "File",
          icon: "pi pi-fw pi-file",
          items: [
              {
                label: "New",
                icon: "pi pi-fw pi-plus",
                command: () => {
                    //this.schedulesService.displayCreateSchedule.emit(true);
                    this.schedulesService.scheduleDialogActionSendEmitter.emit(new ScheduleActionEvent(true, "Create schedule", ScheduleDialogActionType.createSchedule, "Create", undefined));

                },
              },
              {
                  label: "Open",
                  icon: "pi pi-fw pi-folder",
                  command: () => {
                      //this.schedulesService.displayCreateSchedule.emit(true);
                      this.schedulesService.scheduleDialogActionSendEmitter.emit(new ScheduleActionEvent(true, "Create schedule", ScheduleDialogActionType.createSchedule, "Create", undefined));

                  },
              },
              {
                  label: 'Save as',
                  icon: 'pi pi-fw pi-copy',
                  command: () => {
                      //this.schedulesService.displayCreateSchedule.emit(true);
                      this.schedulesService.scheduleDialogActionSendEmitter.emit(new ScheduleActionEvent(true, "create schedule", ScheduleDialogActionType.createSchedule, "Create", undefined));

                  },
              },
          ]
      },

  ];
  this.loadPlugInInfo().subscribe((infos) => {
    this.plugInInfos = infos;
  });
  }

  loadPlugInInfo(): Observable<SchedulerNodeInfo[]> {
    return this.dummyService.getScheduleNodes();
  }
  displaySaveWorkflow() {}
  onRunWorkflow() {}
  onRunWorkflowPartially() {}

}
