import { HttpErrorResponse } from "@angular/common/http";
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core";
import { Table } from "primeng/table";
import { ExcelBlobAccess } from "src/app/models/api/com/bion/connect/excel/ExcelBlobAccess";
import { ExcelMetaInfo } from "src/app/models/api/com/bion/connect/excel/ExcelMetaInfo";
import { ExcelMetaAccess } from "src/app/models/connector.model";
import { ConnectorView } from "src/app/models/connectorView.model";
import {
	DataSourceAdapter
} from "src/app/models/datasource.model";
import { PlayErrorResponse } from "src/app/models/play.error.model";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";

export interface ExcelSheet {
	Index: number;
	Name: string;
	IsHidden: boolean;
}

@Component({
	selector: "app-excel-connector",
	templateUrl: "./excel-connector.component.html",
	styleUrls: ["./excel-connector.component.scss"],
})
export class ExcelConnectorComponent implements OnInit {
	selectedConnector: DataSourceAdapter;
	loading: boolean = false;
	excelTableList: ExcelSheet[] = [];
	@ViewChild("fileListTable") fileListTable: Table;
	@Input() currentConnectorView: ConnectorView<
		ExcelBlobAccess,
		ExcelMetaInfo,
		ExcelMetaAccess
	>;
	@Input() isChangeSettingsOnlyFlag: boolean = false;
	@Output() onSettingsChangedEmitter = new EventEmitter<boolean>();
	fileListCols;
	currentFile?: File;
	//selectedSourceTable;

	// onExcelSheetSelected($event) {
	// 	console.log($event);
	// 	// let selectedSourceTable = $event.data.Name;
	// 	let selectedSourceTable: ExcelSheet = $event.value;

	// 	this.currentConnectorView.connectorSettings.Table =
	// 		selectedSourceTable.Name;
	// 	console.log(this.currentConnectorView);
	// }
	onSettingsChanged() {
		this.onSettingsChangedEmitter.emit(true);
	}
	uploadFilesAsync(event) {
		const input = event.files;
		const firstFile = input[0];
		console.log(firstFile);
		this.currentFile = firstFile;
		this.currentConnectorView.uploadFile(firstFile);
		this.currentConnectorView.fetchMetaDataObs().subscribe(
			(res) => {
				let excelTableList: ExcelSheet[] = [...res.Sheets];

				if (excelTableList.length === 0) {
					console.log("No Sheets found!");
					return;
				}
				this.excelTableList = [...excelTableList];
				console.log("Excel Sheets:", this.excelTableList);

				this.cd.detectChanges();

			},
			(error: Error) => {
				this.errorService.handleError(error);
			}, () => {
				this.loading = false
			}
		);
	}
	onRemoveFile(event) {
		console.log(event);
		this.excelTableList = [];
	}

	// handleError(error: HttpErrorResponse) {
	// 	const play_error: PlayErrorResponse = error.error;
	// 	console.log(play_error);
	// }

	constructor(private cd: ChangeDetectorRef, private errorService: SystemMessageLogService) {}

	ngOnInit(): void {
		this.currentConnectorView.connectorSettings.ReadSettings.StartRow
		this.fileListCols = [
			// { field: 'Index', header: 'Index', width:'20%' },
			{ field: "Name", header: "Sheet Name", width: "70%" },
			{ field: "IsHidden", header: "Hidden Sheet", width: "30%" },
		];
	}
}
