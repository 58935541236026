import { Component, OnInit } from '@angular/core';
import { WorkflowNodeSettings } from 'src/app/models/api/com/bion/etl/Workflow';
import { DesignerService } from 'src/app/services/designer.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { NodeConfigComponentBase } from '../node-config-component-base';


export class AutoSelectSettings {
  DropNullColumns: boolean;
  DropNullRows: boolean;

  constructor(DropNullColumns: boolean,DropNullRows: boolean) {
    if(DropNullColumns === undefined) throw new Error("DropNullColumns is undefined");
    if(DropNullRows === undefined) throw new Error("DropNullRows is undefined");
    this.DropNullColumns = DropNullColumns;
    this.DropNullRows = DropNullRows;
  }
}

@Component({
  selector: 'app-spark-auto-select-node',
  templateUrl: './spark-auto-select-node.component.html',
  styleUrls: ['./spark-auto-select-node.component.scss']
})
export class SparkAutoSelectNodeComponent extends NodeConfigComponentBase<AutoSelectSettings> implements OnInit {

  viewToSettings(): AutoSelectSettings {
    let settingsCopy = <AutoSelectSettings>{
			...this.getCurrentWorkflowNode().Properties.Configuration,
		};

		let newSettings = {...settingsCopy};
    console.log(newSettings);
    
    if(newSettings) {
      newSettings.DropNullColumns = this.removeCols;
      newSettings.DropNullRows = this.removeRows;
    } else {
      newSettings = new AutoSelectSettings(true,true);
    }

    return newSettings;

  }
  onSettingsChanged(settings: AutoSelectSettings): void {
    throw new Error('Method not implemented.');
  }
  settingsToView(settings: WorkflowNodeSettings): void {
        // -- Get Configuration
    let currentConfig = <AutoSelectSettings>settings;

    if(currentConfig) {
      this.removeCols = currentConfig.DropNullColumns;
      this.removeRows = currentConfig.DropNullRows;

    } else {
      currentConfig = new AutoSelectSettings(true,true);
    }
  }

  removeCols: boolean;
  removeRows: boolean;

	constructor(
		protected workflowService: WorkflowsService,
		protected designerEventService: DesignerService
	) {
		super(workflowService, designerEventService);
	}


  ngOnInit(): void {
  }
  onCheckRemoveColsFlag(event) {
    this.removeCols = event.checked;
    this.onUpdateSettings(false);
  }
  onCheckRemoveRowsFlag(event) {
    this.removeRows = event.checked;
    this.onUpdateSettings(false);
  }
}
