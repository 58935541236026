import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import  destinationJson from 'src/assets/data/dummy-destination-info.json';
import  destinationMetaJson from 'src/assets/data/dummy-destination-meta-info.json';
import  createDestinationResponseJson from 'src/assets/data/dummy-create-destination-response.json';

export interface DataProvider {
  id: number;
}
export interface Destination<C> extends DataProvider {
  id: number;
  target?: DestinationTarget;
  settings: C;
  name: string;
  description?: string;
  writeMode: string;
  entity?: string;
}
export class DestinationTarget {
  _type: string;
  parameters: any;
}
/**
 * Infos, wie man sich verbinden kann, Config, Argument Beispiel, etc...
 */
export interface ConnectionInfo{
  InitialConfig:any;
}

export interface DestinationInfo {
  id: number;
  name: string;
  Version:number;
  Category:string;
  Info:ConnectionInfo;
}

export interface DestinationMetaInfo {
  id: number;
  name: string;
}

export interface DestinationField {
  id:number;
  destination:number;
}

export interface DestinationConnection {
  id:number;
  settings:any;
}

/**
 * Ich definiere die Form der REST-API für die Destinations.
 * Ich weiß NICHTS über die individuellen Destination-Typen.
 */
interface RestServiceShape {
  getDestinationInfos(): Observable<DestinationInfo[]>;
  extractMetaFromDestination(settings): Observable<any>;
  pushDataToDestination(destination): Observable<any>;

  /**
   * Create ist bischen komplizierter, deswegen ist sie hier oben als Sonderfall
   * @param arg
   */
  createDestination(arg): Observable<Destination<any>>;

  // REST - CRUD Operations

  getDestination(id?:number) : Observable<Array<Destination<any>>>;
  updateDestination(arg:Destination<any>) : Observable<number>;
  deleteDestination(id:number) : Observable<number>;

  getDestinationField(id?:number, destination?:number);
  createDestinationField(arg:DestinationField): Observable<DestinationField>;

  // -- Connection Settings
  createConnectionSettings(id:number, arg:any);
  getConnectionSettings(id:number) : any;

  // Preview
  // Nicht notwendig, da wir nur wegschreiben!
}




@Injectable({
  providedIn: 'root'
})
export class DummyDestinationService implements RestServiceShape {

  destinations:Array<Destination<any>> = new Array<Destination<any>>();

  constructor() { }
  getDestination(id?: number): Observable<Destination<any>[]> {

    let result:Array<Destination<any>>;

    if(id) {
      result = this.destinations.filter(d => d.id);
    } else {
      result = this.destinations;
    }

    return of(result);
  }
  updateDestination(arg: Destination<any>): Observable<number> {
    throw new Error('Method not implemented.');
  }
  deleteDestination(id: number): Observable<number> {
    throw new Error('Method not implemented.');
  }
  getDestinationField(id?: number, destination?: number) {
    throw new Error('Method not implemented.');
  }
  createDestinationField(arg: DestinationField): Observable<DestinationField> {
    throw new Error('Method not implemented.');
  }

  destinationSettings:Array<DestinationConnection> = new Array<DestinationConnection>();

  createConnectionSettings(id: number, arg: any) {

    const others = this.destinationSettings.filter(cs => cs.id != id);

    const value:DestinationConnection = {id: id, settings:arg};

    others.push(value);
    this.destinationSettings = others;
  }
  getConnectionSettings(id: number) : Observable<DestinationConnection[]> {
    let result:Array<DestinationConnection>;

    if(id) {
      result = this.destinationSettings.filter(d => d.id);
    } else {
      result = this.destinationSettings;
    }

    return of(result);
  }
  /**
   * Holt die Liste aller verfügbaren Destination typen
   */
  getDestinationInfos(): Observable<DestinationInfo[]> {
    let array: DestinationInfo[] = destinationJson.destinations;

    return of(array)
  }
  /**
   * Holt die Tabelleninformation und weitere Details aus Target Settings
   */

  extractMetaFromDestination(settings): Observable<any> {
    let array: DestinationMetaInfo[] = destinationMetaJson.meta;

    return of(array)
  }
    /**
   * Erzeugt einen neuen Destination Eintrag im BE
   */
  createDestination(arg): Observable<Destination<any>> {

    let dest = <Destination<any>>createDestinationResponseJson;

    return of(dest)

  }
    /**
   * Befüllt eine bestehende Destination mit Daten
   */
  pushDataToDestination(destination): Observable<any> {
    throw new Error("Not Implemented")
  }



  // Service



}



// Destination Meta Informationen - gelten für alle Destination Typen

export class EntityFieldInfo {
  Name:string;
  DataType:string;
  IsKey:boolean;
}

export class EntityInfo {
  Name:string;
  Fields:Array<EntityFieldInfo>;
}

// JDBC Destination Prototyp

export class JdbcDestinationConfig {
  Url:string;
  Username:string;
  Passwort:string;
}

export class JdbcMetaInfo {
  Entites : Array<EntityInfo>;
}

export class JdbcDestination {

}
