<div>
    <p-table [value]="formulaList" dataKey="ColumnName" editMode="row" [reorderableColumns]="false" sortMode="single"
        [globalFilterFields]="['Name','FilterValues','FilterTypes']">
        <!-- <ng-template pTemplate="caption">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                <span class="p-input-icon-right">
                    <button style="float: right" pButton label="{{'Add' | translate}}" icon="fa fa-plus"
                        (click)="onAddRow()"></button>
                </span>
            </div>
        </ng-template> -->
        <ng-template pTemplate="header">
            <tr>
                <th tooltipPosition="top" pTooltip="{{'Config.Formula.Tooltip3' | translate}}">
                    <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                        <span class="p-input-icon-right">
                            <button style="float: right" pButton label="{{'Add' | translate}}" icon="fa fa-plus"
                                (click)="onAddRow()"></button>
                        </span>
                    </div>
                    <!-- <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Formula' | translate}}

                    </div> -->
                </th>
                <th style="width:3rem"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-column let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="column" [pReorderableRow]="ri"
                [ngClass]="{'row-accessories': column.hasError === true}">
                <td>
                    <div class="p-grid">
                        <div class="p-col-12 headerSection">
                            <div class="p-fluid">
                                <span class="p-float-label">
                                    <textarea id="formulaInputText" [rows]="5" [cols]="30" [style]="{'width':'100%'}"
                                        (blur)="onUpdateSettings(false)" [(ngModel)]="column.FormulaString"
                                        pInputTextarea autoResize="autoResize"></textarea>
                                    <label for="formulaInputText">{{'Expression' |translate }}</label>
                                </span>
                            </div>
                        </div>
                        <div class="p-col-9 headerSection">
                            <div class="p-fluid">
                                <span class="p-float-label">
                                    <p-dropdown id="dataTypeInputText" appendTo="body" [options]="dataTypes"
                                        (onChange)="onUpdateSettings(false)" [(ngModel)]="column.NewColumnType"
                                        optionLabel="Name" autoWidth="false" [style]="{'width':'100%'}"></p-dropdown>
                                    <label for="dataTypeInputText">{{'Datatype' |translate }}</label>
                                </span>
                            </div>
                        </div>
                        <div class="p-col headerSection">
                            <div class="p-fluid">
                                <span class="p-float-label">
                                    <p-inputNumber id="dataTypeLengthInputText" (blur)="onUpdateSettings(false)"
                                        [(ngModel)]="column.NewColumnType.Length" [disabled]="true"
                                        [style]="{'width':'100%'}"></p-inputNumber>
                                    <label for="dataTypeLengthInputText">{{'Length' |translate }}</label>
                                </span>
                            </div>
                        </div>
                        <div class="p-col-12 headerSection">
                            <div class="p-fluid">
                                <span class="p-float-label">
                                    <p-dropdown id="newColumnNameInputText" appendTo="body" [options]="columnList"
                                        (blur)="onUpdateSettings(false)" [(ngModel)]="column.NewColumnName"
                                        [autoDisplayFirst]="false" [editable]="true" autoWidth="false"
                                        [style]="{'width':'100%'}"></p-dropdown>
                                    <label for="newColumnNameInputText">{{'Choose Target Column' |translate }}</label>
                                </span>
                            </div>
                        </div>
                    </div>



                    <!-- <textarea [rows]="5" [cols]="30" [style]="{'width':'100%'}" (onChange)="onUpdateSettings(false)" [(ngModel)]="column.FormulaString" pInputTextarea autoResize="autoResize"></textarea>
                    <p-dropdown appendTo="body" [options]="dataTypes" [(ngModel)]="column.NewColumnType"
                        optionLabel="Name" (onChange)="onUpdateSettings(false)" autoWidth="false"
                        [style]="{'width':'100%'}"></p-dropdown>

                    <p-inputNumber [(ngModel)]="column.NewColumnType.Length" [disabled]="true" [style]="{'width':'100%'}"></p-inputNumber>
                    <p-dropdown appendTo="body" [options]="columnList"
                        [(ngModel)]="column.NewColumnName" [autoDisplayFirst]="false" [editable]="true" (onChange)="onUpdateSettings(false)"
                        autoWidth="false" [style]="{'width':'100%'}"></p-dropdown> -->
                    <!-- <p-dropdown *ngIf="column.hasError === true" [editable]="true" appendTo="body" [options]="columnList"
                        [(ngModel)]="column.NewColumnName" class="ng-invalid ng-dirty" [autoDisplayFirst]="false"
                        (onChange)="onUpdateSettings(false)" autoWidth="false" [style]="{'width':'100%'}"></p-dropdown> -->
                </td>
                <td>
                    <div class="ui-table-buttongroup">
                        <button pButton pRipple type="button" class="p-button-rounded p-button-text ui-button-danger"
                            icon="pi pi-trash" pTooltip="{{'Delete' | translate}}" (click)="onDeleteEntry(ri)"
                            tooltipPosition="bottom"></button>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="2">{{'NoEntryFound' | translate}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>