import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MenuItem } from "primeng/api";
import { AppMainComponent } from "src/app/app.main.component";
import { DesignerEvent, DesignerService, DesignProgresSpinnerEvent, WorkflowOperationType } from "src/app/services/designer.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { SubSink } from "subsink";
import { NodeConfigComponent } from "../node-config/node-config.component";
import { WorkflowGraphEventType, IWorkflowGraphEventData } from "../workflow-graph/workflow-graph-events";
import { WorkflowActionEvent, WorkflowDialogActionType } from "src/app/models/dialog-actions.model";

@Component({
    selector: "app-designer-nav-toolbar",
    templateUrl: "./designer-nav-toolbar.component.html",
    styleUrls: ["./designer-nav-toolbar.component.scss"],
    providers: [NodeConfigComponent]
})
export class DesignerNavToolbarComponent implements OnInit {

    //wfName: string = "";
    saveButtonItems: MenuItem[] = [];
    subs = new SubSink;
    savingInProgress: boolean = false;
    updateSettingsStatus: boolean = false;
    currentLang: string;
    disableRunPartiallyButton: boolean = true;
    disableRunFullButton: boolean = false;

    constructor(
        private designerService: DesignerService,
        private workflowService: WorkflowsService,
        private appMain: AppMainComponent,
        private translate: TranslateService,
        private configComp: NodeConfigComponent,
        private router: Router
    ) {
        this.currentLang = this.appMain.translate.currentLang;

    }

    ngOnInit(): void {
        if (this.currentLang === "en") {
            this.saveButtonItems = [
                {
                    label: 'Save as', icon: 'pi pi-fw pi-copy', command: () => {
                        this.displaySaveAsWorkflow()
                    }
                },
            ];
        } else {
            this.saveButtonItems = [
                {
                    label: 'Speichern als', icon: 'pi pi-fw pi-copy', command: () => {
                        this.displaySaveAsWorkflow()
                    }
                },
            ];
        }
        this.subs.sink =
            this.designerService.designerProgressSpinnerEmitter.subscribe(
                (res: DesignProgresSpinnerEvent) => {

                    try {
                        if (res.workflowOperation == WorkflowOperationType.UpdateSettings) {
                            this.updateSettingsStatus = res.inProgress;
                            this.disableRunFullButton = res.inProgress;
                            this.disableRunPartiallyButton = res.inProgress;
                        } else {
                            this.savingInProgress = res.inProgress;

                        }
                    } catch (e) {
                        // nothing
                    } finally {
                        // TODO: check what we need to clean up here
                    }
                }
            );

        this.subs.sink = this.designerService.workflowGraphEmitter.subscribe(
            (
                event: DesignerEvent<WorkflowGraphEventType, IWorkflowGraphEventData>
            ) => {

                if (event.Type === WorkflowGraphEventType.GraphClicked) {
                    this.disableRunPartiallyButton = true;

                }
                if (event.Type === WorkflowGraphEventType.NodeCellClicked) {
                    this.disableRunPartiallyButton = false;
                }
            })
    }

    // Workflow Dialog Action Events

    displayOpenWorkflow() {
        this.workflowService.displayOpenWorkflow.emit(true);
    }
    onSaveWorkflow() {
        this.workflowService.workflowDialogActionSendEmitter.emit(new WorkflowActionEvent(true, "Save workflow", WorkflowDialogActionType.saveWorkflow, "Save", undefined, undefined));
    }
    displaySaveAsWorkflow() {
        this.workflowService.workflowDialogActionSendEmitter.emit(new WorkflowActionEvent(true, "Save workflow as", WorkflowDialogActionType.saveAsWorkflow, "Save", undefined, undefined));
    }

    onCreateNewWorkflow() {
        this.workflowService.workflowDialogActionSendEmitter.emit(new WorkflowActionEvent(true, "Create workflow", WorkflowDialogActionType.createWorkflow, "Create", undefined, undefined));
    }


    // Designer Graph Action Events
    onDelete() {
        this.designerService.toolBarCommandEmitter.emit(ToolbarCommand.delete);
    }
    onCut() {
        this.designerService.toolBarCommandEmitter.emit(ToolbarCommand.cut);
    }
    onCopy() {
        this.designerService.toolBarCommandEmitter.emit(ToolbarCommand.copy);
    }
    onPaste() {
        this.designerService.toolBarCommandEmitter.emit(ToolbarCommand.paste);
    }
    onUndo() {
        this.designerService.toolBarCommandEmitter.emit(ToolbarCommand.unDo);
    }
    onRedo() {
        this.designerService.toolBarCommandEmitter.emit(ToolbarCommand.reDo);
    }
    onZoomIn() {
        this.designerService.toolBarCommandEmitter.emit(ToolbarCommand.zoomIn);
    }
    onZoomOut() {
        this.designerService.toolBarCommandEmitter.emit(ToolbarCommand.zoomOut);
    }
    onFit() {
        this.designerService.toolBarCommandEmitter.emit(ToolbarCommand.fit);
    }
    onActualSize() {
        this.designerService.toolBarCommandEmitter.emit(ToolbarCommand.actualSize);
    }
    onRunWorkflowPartially() {
        this.designerService.designerProgressSpinnerEmitter.emit(new DesignProgresSpinnerEvent(true, WorkflowOperationType.RunWorkflowPartially));

        this.designerService.toolBarCommandEmitter.emit(ToolbarCommand.runWorkflowPartially);
    }
    onRunWorkflow() {

        // if (this.settingsAreDirty) {
        //     this.forceViewToSettings();
        // }

        console.log("Force View to Settings emitting...");
        this.designerService.forceViewToSettingsEmitter.emit();  // this must be sync!
        console.log("Force View to Settings emitted");

        this.designerService.designerProgressSpinnerEmitter.emit(new DesignProgresSpinnerEvent(true, WorkflowOperationType.RunWorkflow));
        this.designerService.toolBarCommandEmitter.emit(ToolbarCommand.runWorkflow);
    }
    saveWorkflow() {
        this.designerService.toolBarCommandEmitter.emit(
            ToolbarCommand.saveWorkflow
        );
    }
    openWorkflow() {
        this.designerService.toolBarCommandEmitter.emit(
            ToolbarCommand.openWorkflow
        );
    }

    settingsAreDirty(): boolean {
        throw new Error("Not Implemented");
    }

    forceViewToSettings(): void {
        throw new Error("Not Implemented");
    }
}

export enum ToolbarCommand {
    runWorkflowPartially,
    runWorkflow,
    openWorkflow,
    saveWorkflow,
    delete,
    cut,
    copy,
    paste,
    unDo,
    reDo,
    zoomIn,
    zoomOut,
    fit,
    actualSize,
}