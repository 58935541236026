import { UserInfo } from "./session/UserInfo";


export namespace PowerBIModel {
	export class CreateReportsArg {
		Reports: Array<PowerBIModel.CreateReportsArg.Report>;
		constructor(Reports: Array<PowerBIModel.CreateReportsArg.Report>) {
			if(Reports === undefined) throw new Error( "Class 'CreateReportsArg': Required value 'Reports' is undefined" );
			this.Reports = Reports;
		}
	}

    export class ReportInfo {
		Id: number;
		Title: string;
		Url: string;
		constructor(Id: number,Title: string,Url: string) {
			if(Id === undefined) throw new Error( "Class 'ReportInfo': Required value 'Id' is undefined" );
			if(Title === undefined) throw new Error( "Class 'ReportInfo': Required value 'Title' is undefined" );
			if(Url === undefined) throw new Error( "Class 'ReportInfo': Required value 'Url' is undefined" );
			this.Id = Id;
			this.Title = Title;
			this.Url = Url;
		}
	}
}

export namespace PowerBIModel.CreateReportsArg {
    export class Permission {
        Role: number;
        constructor(Role: number) {
            if(Role === undefined) throw new Error( "Class 'Permission': Required value 'Role' is undefined" );
            this.Role = Role;
        }
    }
    export class Report {
        Title: string;
        Url: string;
        Permissions: Array<Permission>;
        constructor(Title: string,Url: string,Permissions: Array<Permission>) {
            if(Title === undefined) throw new Error( "Class 'Report': Required value 'Title' is undefined" );
            if(Url === undefined) throw new Error( "Class 'Report': Required value 'Url' is undefined" );
            if(Permissions === undefined) throw new Error( "Class 'Report': Required value 'Permissions' is undefined" );
            this.Title = Title;
            this.Url = Url;
            this.Permissions = Permissions;
        }
    }
    export class ReportView {
        Title: string;
        Url: string;
        Permissions: Array<UserInfo>;
        constructor(Title: string,Url: string,Permissions: Array<UserInfo>) {
            if(Title === undefined) throw new Error( "Class 'Report': Required value 'Title' is undefined" );
            if(Url === undefined) throw new Error( "Class 'Report': Required value 'Url' is undefined" );
            if(Permissions === undefined) throw new Error( "Class 'Report': Required value 'Permissions' is undefined" );
            this.Title = Title;
            this.Url = Url;
            this.Permissions = Permissions;
        }
    }
}