<div class="p-grid">
    <div class="p-col-12 p-md-12 p-xl-12">
        <div class="card-header">
            <div class="card-title">
                <h6>{{'Datatypes' | translate}}</h6>
            </div>
        </div>
        <div class="progress" *ngFor="let type of dataTypesCountFilteredList">
            <span style="min-width: 100px;">{{type.name}}</span>
            <p-progressBar [value]="type.progress" [showValue]="true"></p-progressBar>
            <span>{{type.count}}</span>
        </div>
        <div style="height: 150px" *ngIf="dataTypesCountFilteredList.length === 0">
            <app-no-data-available [view]="'chart'" [url]="'/chart'"></app-no-data-available>
        </div>
    </div>
</div>
