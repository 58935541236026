import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { BaseActionActionEvent, BaseActionDialogActionType } from 'src/app/models/dialog-actions.model';
import { ScheduleBaseAction } from 'src/app/models/schedule.model';
import { SchedulesService } from 'src/app/services/schedules.service';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-delete-action-dialog',
  templateUrl: './delete-action-dialog.component.html',
  styleUrls: ['./delete-action-dialog.component.scss'],
  providers: [MessageService]
})
export class DeleteActionDialogComponent implements OnInit, OnDestroy {
  subs = new SubSink();
  savingInProgress: boolean = false;
  displayDeleteAction: boolean = false;
  selectedAction?: ScheduleBaseAction;

  constructor(private schedulesService: SchedulesService, private translate: TranslateService, private systemLogService: SystemMessageLogService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.subs.sink = this.schedulesService.baseActionDialogActionSendEmitter.subscribe((event: BaseActionActionEvent) => {
      if(event.actionType === BaseActionDialogActionType.deleteAction) {
        this.displayDeleteAction = event.display;
        this.selectedAction = event.action;
      }
    }, (err:Error) => {
      this.systemLogService.handleError(err);
    });

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  deleteAction(event?: ScheduleBaseAction) {

    if (event === undefined) return;

    this.savingInProgress = true;

    this.schedulesService.deleteScheduleActions(event.id).subscribe(count => {

      this.schedulesService.actionsChangedEmitter.emit("Action Deleted");

      this.messageService.add({
        severity: "success",
        summary: this.translate.instant("Message.DeleteActionSuccess.Title"),
        detail: this.translate.instant("Message.DeleteActionSuccess.Text1") + event.id +
        this.translate.instant("Message.DeleteActionSuccess.Text2"),
      });
      this.displayDeleteAction = false;
    }, (error: Error) => {
      this.systemLogService.handleError(error);
    }, () => {
      this.savingInProgress = false;
    })
  }

}
