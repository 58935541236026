import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Message } from 'primeng/api';
import { concatMap, map, mergeMap } from 'rxjs/operators';
import { IntegratedSourceModel } from 'src/app/models/api/models/IntegratedSourceModel';
import { IntegratedSourceService } from 'src/app/services/integrated-source.service';

enum ChangeMode {
  Read,
  Update
}

/**
 * Diese Komponente rendert eine integrierte Connection.
 * 
 * Features:
 * - Stream anschauen
 * - Extraktionsstatus sehen
 * 
 * Aktionen: 
 * - Streams ändern
 */
@Component({
  selector: 'app-view-connection-int',
  templateUrl: './view-connection-int.component.html',
  styleUrls: ['./view-connection-int.component.scss']
})
export class ViewConnectionIntComponent implements OnInit {

  constructor(private service_api: IntegratedSourceService, private router: Router) { }

  write_mode_infos: IntegratedSourceModel.GenCatalog.WriteModeInfo[] = [];
  new_generic_catalog?: IntegratedSourceModel.GenCatalog;
  get_catalog_result?: IntegratedSourceModel.GetStreamsResult = undefined;
  source_key?: IntegratedSourceModel.DataSourceKey<number>;

  // -- UI
  isLoading: boolean = false;
  progressMode: string = "determinate";
  messages: Message[] = [];

  stream_change_mode = ChangeMode.Read;

  ngOnInit(): void {

    this.source_key = this.getUrlContext();
    const source_key = this.source_key;

    const get_cat_arg = new IntegratedSourceModel.GetStreamsArg(source_key);
    const cat_obs = this.service_api.getStreams(get_cat_arg);

    const write_mode_obs = this.service_api.getWriteModeInfos();

    const cat_and_write_modes = cat_obs.pipe(mergeMap(cat => write_mode_obs.pipe(map(wm => [cat, wm]))));

    this.setLoading(true);
    cat_and_write_modes.subscribe(cat_and_write => {

      const pair: [IntegratedSourceModel.GetStreamsResult, IntegratedSourceModel.GenCatalog.WriteModeInfo[]] =
        <[IntegratedSourceModel.GetStreamsResult, IntegratedSourceModel.GenCatalog.WriteModeInfo[]]>cat_and_write;

      const cat = pair[0];
      this.write_mode_infos = pair[1];

      this.get_catalog_result = cat;
      this.new_generic_catalog = this.get_catalog_result.Catalog;
    },
      err => this.handle_error(err),
      () => this.setLoading(false)
    )
  }

  /**
   * Läd den Katalog und aktualisiert die member Variablen als Seiteneffekt.
   */
  refreshCatalogObs() {
    const get_cat_arg = new IntegratedSourceModel.GetStreamsArg(this.source_key);

    const obs = this.service_api.getStreams(get_cat_arg).pipe(map(cat => {
      this.get_catalog_result = cat;
      this.new_generic_catalog = this.get_catalog_result.Catalog;
    }));

    return obs;
  }

  /**
   * Läd den Katalog erneut ohne die Schreibmodi
   */
  refreshCatalog() {
    const get_cat_arg = new IntegratedSourceModel.GetStreamsArg(this.source_key);

    this.setLoading(true);
    this.service_api.getStreams(get_cat_arg).subscribe(cat => {
      this.get_catalog_result = cat;
      this.new_generic_catalog = this.get_catalog_result.Catalog;
    },
      err => this.handle_error(err),
      () => this.setLoading(false))
  }

  /**
 * Der Datasource Schlüssel aus der Route.
 * @returns Data Source Key
 */
  getUrlContext(): IntegratedSourceModel.DataSourceKey<number> {
    const arr = this.router.url.split('/');
    const id = parseInt(arr.last());
    const origin = arr.getRight(1);
    console.log("Data Source ID: " + id);
    console.log("Data Source Origin: " + origin);
    return new IntegratedSourceModel.DataSourceKey(id, origin);
  }

  setLoading(loading: boolean): void {
    this.isLoading = loading;

    // https://www.primefaces.org/primeng-v14-lts/progressbar

    if (this.isLoading) {
      this.progressMode = "indeterminate";
    } else {
      this.progressMode = "determinate";
    }
  }

  handle_error(e: Error) {
    this.messages.push({ severity: 'error', summary: 'Error', detail: e.message });
    console.error(e);
    this.setLoading(false);
  }

  // UI Callbacks

  onStreamsChange() {
    console.log("On Streams Changed");

    if (this.stream_change_mode == ChangeMode.Update) return;

    this.stream_change_mode = ChangeMode.Update;
  }

  onStreamsCancel() {
    console.log("On Streams Cancel");

    if (this.stream_change_mode == ChangeMode.Read) return;

    this.setLoading(true);
    this.refreshCatalogObs().subscribe(
      () => {
        this.stream_change_mode = ChangeMode.Read;
      },
      err => this.handle_error(err),
      () => this.setLoading(false))
  }

  /**
   * Aktualisiert die Schreibmodi der Streams und lädt die Daten anschließend neu.
   * @returns 
   */
  onStreamsSave() {
    console.log("On Streams Save");

    if (this.stream_change_mode == ChangeMode.Read) return;

    const skip_reset = true;
    console.log("Skip reset is set to " + skip_reset);
    if (skip_reset) {
      console.log("We're keeping all old data");
    } else {
      console.log("We're dropping all old data.");
    }

    const api_arg = new IntegratedSourceModel.UpdateStreamsArg(this.source_key, this.new_generic_catalog, skip_reset);

    const update_obs = this.service_api.updateStreams(api_arg);
    const update_and_reload = update_obs.pipe(concatMap(() => this.refreshCatalogObs()));

    this.setLoading(true);
    update_and_reload.subscribe(
      () => this.stream_change_mode = ChangeMode.Read,
      err => this.handle_error(err),
      () => this.setLoading(false)
    )
  }
}
