import { Component, OnDestroy, OnInit } from "@angular/core";
import { NodeConfigComponentBase } from "../node-config-component-base";

import { ApiBackendService } from "src/app/services/api-backend.service";
import { DesignerService } from "src/app/services/designer.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import {
	CalculateInfo,
	CalculateSettings,
} from "src/app/models/nodeSettings.model";
// import { FieldInfo, WorkflowNodeSettings } from "src/app/models/workflow.model";
import { SubSink } from "subsink";
import { FieldInfo, WorkflowNodeSettings } from "src/app/models/api/com/bion/etl/Workflow";
import { Id } from "src/app/helper/id";

export class ConfigError {
	hasLeftError: boolean;
	hasRightError: boolean;

	constructor(hasLeftError: boolean, hasRightError: boolean ){
		this.hasLeftError = hasLeftError;
		this.hasRightError = hasRightError
	}
}

export class CalculateInfoView {

    FirstColName: string;
    Operator: DropDownView;
    SecondColName: string;
    NewColumnName: string;
    NewDataType: DropDownView;
    hasError?: ConfigError;


	constructor(FirstColName: string , Operator: DropDownView, SecondColName: string , NewColumnName: string , NewDataType: DropDownView ,hasError?: ConfigError) {
		this.FirstColName = FirstColName;
		this.Operator = Operator;
		this.SecondColName = SecondColName;
		this.NewColumnName = NewColumnName;
		this.NewDataType = NewDataType;
		this.hasError = hasError;
	}
}

// export class ColumnView {
// 	name: string;
// 	hasError?: boolean;
// }

export class DropDownView {
    name: string;
    value: number;

	constructor(name: string, value: number) {
		this.name = name;
		this.value = value
	}
}
// export class TableColumn {
// 	header: string;
// 	field: string;
// }

// export class Formula {
// 	FunctionName: string;
// 	FunctionValue: string;
// 	Expression: string;
// 	Category: string;
// 	Description: string;
// }

@Component({
	selector: "app-calculate-node",
	templateUrl: "./calculate-node.component.html",
	styleUrls: ["./calculate-node.component.css"],
})
export class CalculateNodeComponent
	extends NodeConfigComponentBase<CalculateSettings>
	implements OnInit, OnDestroy
{
	// ---------------------------------------------------------------------------------------------------------- Variables
	// Local general variables
	subs = new SubSink;
	InputPort: string = "Input";
	loading: boolean = false;

	// List of all avaiable ds fields from Input port
	allDsFields: string[] = [];

	// DropDown Selection for DataType
	dataTypesList: DropDownView[]= [];
    operators: DropDownView[] = [];
	selectedDataType?: DropDownView;

    calculateInfoList: CalculateInfoView[] = [];

	// ---------------------------------------------------------------------------------------------------------- ToView/From View Functions

	settingsToView(settings: WorkflowNodeSettings): void {
		// -- Get Input Config Data
		let currentConfig = <CalculateSettings>{ ...settings };
		console.log("Current Config : ", currentConfig);

		// -- Get current Meta Data
		const meta_infos = this.getCurrentWorkflowNode().Properties.MetaInfo.get(
			this.InputPort
		);

        this.operators = [{name:"+", value: 1},{name:"-", value: 2},{name:"/", value: 3},{name:"*", value: 4},{name:"DeltaPct", value: 5},{name:"Pow", value: 6}];
		let dataTypesList = [{name:"Double", value: 1},{name:"Integer", value: 2},{name:"String", value: 3}];
		this.dataTypesList = dataTypesList;
        let columnListArray: string[] = [];

			// create SelectInfoViews from DsFields for table
			if (meta_infos != undefined && meta_infos.length > 0) {
				const input_field_infos = meta_infos[0].FieldsInfo;

				columnListArray = input_field_infos.map((fieldInfo) => {
					return fieldInfo.Name;
				});
				this.allDsFields = columnListArray;
			}
			// --If currentConfig not available (-> new node)
			if (currentConfig === undefined) {
				// -- do nothing
			} else {
				// -- otherwise load existing config into view
				if (!currentConfig.CalculateInfos) return;

				currentConfig.CalculateInfos.map((info) => {
					if (columnListArray.includes(info.NewColumnName)) {
						// -- column still exists, do nothing
					} else {
						// column does not exist anymore, meaning column name currently not included -> include
						columnListArray.push(info.NewColumnName);
					}
					if (columnListArray.includes(info.FirstColName)) {
						// -- column still exists, do nothing
					} else {
						// column does not exist anymore, meaning column name currently not included -> include
						columnListArray.push(info.FirstColName);
					}
					if (columnListArray.includes(info.SecondColName)) {
						// -- column still exists, do nothing
					} else {
						// column does not exist anymore, meaning column name currently not included -> include
						columnListArray.push(info.SecondColName);
					}
				});
				this.allDsFields = columnListArray;

                if(meta_infos) {
                    if(meta_infos.length > 0) {
                        const meta_data = meta_infos[0];
                        this.infoToInfoView(
                            currentConfig.CalculateInfos,
                            meta_data.FieldsInfo,
                            this.dataTypesList
                        );
                    }
                }

			}
	}
	infoToInfoView(info: CalculateInfo[], metaFieldInfos?: FieldInfo[], dtypes?: DropDownView[]) {
        let infoViewArray = new Array<CalculateInfoView>();


		const metaFields = metaFieldInfos?.map(field => field.Name);

        info.map((info) => {

			//const _FirstColName = Optionals.getOrElse(this.allDsFields.find((col) => { return info.FirstColName === col}),);
            const FirstColName = this.allDsFields.find((col) => { return info.FirstColName === col});
            const Operator = this.operators.find((op) => { return op.value === info.Operator});
            const SecondColName = this.allDsFields.find((col) => { return info.SecondColName === col});
            const NewDataType = dtypes.find((dt) => { return dt.value === info.NewDataType});
            const NewColumnName = info.NewColumnName;

			//const _firstColExists = Optionals.getOrElse(metaFields, false, m => m.includes(FirstColName));
			const firstColExists = FirstColName ? this.allDsFields?.includes(FirstColName) : false;
			const secondColExists = SecondColName ? this.allDsFields?.includes(SecondColName) : false;

			const dtype = Id.assertSet(NewDataType, new Error("The datatype cannot be found. THIS SHOULD NEVER HAPPEN!"));
			const op = Id.assertSet(Operator, new Error("The operator is not set. THIS SHOULD NEVER HAPPEN!"));

			let view = new CalculateInfoView(info.FirstColName,op,info.SecondColName,NewColumnName,dtype,new ConfigError(!firstColExists,!secondColExists));

			//view.hasError= new ConfigError(!firstColError,!secondColError);

            infoViewArray.push(view);

        })
        console.log(infoViewArray);
        this.calculateInfoList = infoViewArray;
	}

    viewToSettings(): CalculateSettings {
		let settingsCopy = <CalculateSettings>{
			...this.getCurrentWorkflowNode().Properties.Configuration,
		};

		let newSettings = new CalculateSettings;

		newSettings = {...settingsCopy};

        let infoArray:CalculateInfo[] = [];

        this.calculateInfoList.map((view) => {
            let info = new CalculateInfo();
            info.FirstColName = view.FirstColName;
            info.Operator = view.Operator.value;
            info.SecondColName = view.SecondColName;
            info.NewDataType = view.NewDataType.value;
            info.NewColumnName = view.NewColumnName

            infoArray.push(info);
        })

		newSettings.CalculateInfos = <CalculateInfo[]>[...infoArray];

        console.log(newSettings);

		return newSettings;
	}

	// ---------------------------------------------------------------------------------------------------------- Event Handler
	onSettingsChanged(settings: any) {
		throw new Error("Method not implemented.");
	}

	constructor(
		protected workflowService: WorkflowsService,
		protected designerEventService: DesignerService,
		protected bionApi: ApiBackendService
	) {
		super(workflowService, designerEventService);
	}

	onAddEntry() {
		let newCalculateViewEntry = new CalculateInfoView("",this.operators[0],"","",this.dataTypesList[0],new ConfigError(false,false));

		// newCalculateViewEntry.FirstColName = "";
		// newCalculateViewEntry.Operator = this.operators[0];
		// newCalculateViewEntry.SecondColName = "";
		// newCalculateViewEntry.NewColumnName = "";
		// newCalculateViewEntry.NewDataType = this.dataTypesList[0];

		//newCalculateViewEntry.hasError = false;
		
		//newCalculateViewEntry.hasError= new ConfigError(false,false);

		this.calculateInfoList.push(newCalculateViewEntry);
		this.onUpdateSettings(false);
		//this.viewToInfo(this.findReplaceList);
	}
	onDeleteEntry(index:number) {
		this.calculateInfoList = [
			...this.calculateInfoList.slice(0, index),
			...this.calculateInfoList.slice(index + 1),
		];
		this.onUpdateSettings(false);
	}

	// ---------------------------------------------------------------------------------------------------------- Hooks
	ngOnInit(): void {

		super.ngOnInit();
	}
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}
}
