<div class="p-grid" *ngIf="selectedDataSet">
    <!-- <div class="p-col-12 dataSetHeader">
        <div class="p-grid dataSetHeaderContainer">
            <div class="p-col-12">
                <span style="width: 100%">
                    <h5>{{selectedDataSet.Name}}
                    </h5>

                </span>
            </div>
        </div>
    </div> -->

    <div class="p-col-8">
        <div class="p-col-12 dataSetHeaderContainer">
            <span style="width: 100%">
                <h5>{{selectedDataSet.Name}}
                </h5>
                <!-- <p class="sub-title"><img style="width: 25px" [src]="'assets/layout/images/datamarket/' + selectedDataSet.Provider + '.png'"
                        [alt]="selectedDataSet.Provider" /> {{selectedDataSet.Provider}}</p> -->
            </span>
        </div>
        <div class="p-col-12 dataSetSection">
            <div class="card-header">
                <div class="card-title">
                    <h6>{{'Description' | translate}}</h6>
                </div>
            </div>
            <p *ngFor="let desc of selectedDataSet.Description.LongDesc">{{desc}}</p>
            <p *ngIf="selectedDataSet.serviceMetaData.Records">DataSet records:
                <b>{{selectedDataSet.serviceMetaData.Records}} records</b>
            </p>
            <p *ngIf="selectedDataSet.serviceMetaData.Size">DataSet Size: <b>{{selectedDataSet.serviceMetaData.Size}}
                    MB</b></p>
            <p>Source Link: <a href="{{selectedDataSet.URL}}">{{selectedDataSet.URL}}</a></p>
            <div class="p-grid p-fluid product-info-section" *ngIf="selectedDataSet.IncludedNodes">
                <div class="product-info-section-item-node" *ngFor="let node of selectedDataSet.IncludedNodes">
                    <div class="product-info-section-node-name"><i class="fa fa-plus-circle"
                            style="margin-right: 10px;"></i><span>{{node.Name}} included</span></div>
                </div>
            </div>
        </div>

        <div class="p-col-12  dataSetSection">
            <div class="card-header">
                <div class="card-title">
                    <h6>{{'Data' | translate}}</h6>
                </div>
            </div>

            <p-table [value]="selectedDataSet.TableInfo">
                <ng-template pTemplate="header">
                    <tr>
                        <th width="70%">{{'Attribute' | translate}}</th>
                        <th width="30%">{{'Datatype' | translate}}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-col>
                    <tr>
                        <td>{{col.Name}}</td>
                        <!-- <td>{{col.DataType.Name}}</td> -->
                        <td><span class="p-column-title">{{'Datatype' | translate}}</span>
                            <span [class]="'customer-badge status-' + col.DataType.Name">{{col.DataType.Name}}</span>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

        <div class="p-col-12 dataSetSection" *ngIf="selectedDataSet.serviceMetaData.Countries.length > 0">
            <div class="card-header">
                <div class="card-title">
                    <h6>{{'Demography' | translate}}</h6>
                </div>
            </div>
            <app-world-map [countryList]="selectedDataSet.serviceMetaData.Countries"></app-world-map>
            <p-accordion>
                <p-accordionTab header="Country Overview ({{selectedDataSet.serviceMetaData.Countries.length}})">
                    <div class="p-grid p-fluid product-info-section">
                        <div class="product-info-section-items"
                            *ngFor="let cntry of selectedDataSet.serviceMetaData.Countries">
                            <div class="product-info-section-name"><span style="margin-right: 10px;"
                                    class="flag-icon flag-icon-{{cntry.id.toLowerCase()}} flag-icon-squared"></span><span>{{cntry.name}}
                                    ({{cntry.id}})</span></div>
                        </div>
                    </div>
                </p-accordionTab>
            </p-accordion>
        </div>

        <div class="p-col-12 dataSetSection" *ngIf="selectedDataSet.serviceMetaData.Currencies.length > 0">
            <div class="card-header">
                <div class="card-title">
                    <h6>{{'Currency' | translate}}</h6>
                </div>
            </div>
            <div class="p-grid p-fluid product-info-section">
                <div class="product-info-section-items" *ngFor="let curr of currencyicons">
                    <div class="product-info-section-name">
                        <span style="margin-right: 10px;">{{curr.symbol}}</span><span>{{curr.name}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="p-col-12 dataSetSection" *ngIf="selectedDataSet.serviceMetaData.Years.length > 0">
            <div class="card-header">
                <div class="card-title">
                    <h6>{{'History' | translate}}</h6>
                </div>
            </div>
            <div class="card-content">
                <div class="product-info-section-items" style="margin-right: 5px; margin-bottom: 5px; float: left;"
                    *ngFor="let year of selectedDataSet.serviceMetaData.Years">
                    <p-tag value="{{year}}"></p-tag>
                </div>
            </div>
        </div>

    </div>

    <div class="p-col-4" style="background-color: #fcfcfc;">
        <div class="p-col-12 dataSetSection">
            <div class="card-header">
                <div class="card-title">
                    <h6>{{'Category' | translate}}</h6>
                </div>
            </div>
            <p-chip>{{selectedDataSet.Category}}</p-chip>
        </div>
        <div class="p-col-12 dataSetSection">
            <div class="card-header">
                <div class="card-title">
                    <h6>{{'Provider' | translate}}</h6>
                </div>
            </div>
            <p class="sub-title"><img style="width: 30px"
                    [src]="'assets/layout/images/datamarket/' + selectedDataSet.Provider + '.png'"
                    [alt]="selectedDataSet.Provider" /> {{selectedDataSet.Provider}}</p>
        </div>
        <div class="p-col-12 dataSetSection">
            <div class="card-header">
                <div class="card-title">
                    <h6>{{'Pricing' | translate}}</h6>
                </div>
            </div>
            <p-table [value]="selectedDataSet.PricingModel">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 70%">{{'License' | translate}}</th>
                        <th style="width: 70%">{{'Frequency' | translate}}</th>
                        <th style="width: 30%">{{'Price' | translate}}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product>
                    <tr>
                        <td>{{product.License}}</td>
                        <td>{{product.Frequency}}</td>
                        <td>{{product.Price}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

        <div class="p-col-12 dataSetSection">
            <div class="card-header">
                <div class="card-title">
                    <h6>{{'DeliveryOptions' | translate}}</h6>
                </div>
            </div>
            <div class="p-grid p-fluid product-info-section">
                <div class="card p-col-12" *ngFor="let option of selectedDataSet.DeliveryOptions">
                    <div class="card-header">
                        <div class="card-title">
                            <!-- <h6>{{option.Name}}</h6> -->
                            <p class="subtitle">{{option.Name}}</p>
                        </div>
                        <!-- <p class="subtitle">8 May</p> -->
                    </div>

                    <div class="card-content">{{option.Description}}</div>
                </div>
            </div>
        </div>

        <div class="p-col-12 dataSetSection">
            <div class="card-header">
                <div class="card-title">
                    <h6>{{'RelatedTags' | translate}}</h6>
                </div>
            </div>
            <div class="product-info-section-items" style="margin-right: 5px;margin-bottom: 5px; float: left;"
                *ngFor="let tag of selectedDataSet.Tags">
                <p-tag value="{{tag}}"></p-tag>
            </div>
        </div>
    </div>
</div>
