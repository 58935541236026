// New DS TypeKlasse und der Typ hier ist diesmal DatasourceView direkt

import { MenuItem } from "primeng/api";
import { Observable, of } from "rxjs";
import { RichDataSourceCake } from "src/app/models/api/controllers/staging/RichDataSourceCake";
import { DatasourcesService } from "src/app/services/datasources.service";
import { SubSink } from "subsink";
import { DropDownModel } from "../general-search/drop-down-model";
import { FilterProvider } from "../general-search/filter-provider";
import { DropDownEntry, HeaderRow, RecordProvider, RecordViewSorter } from "./record-provider";
import * as dss from 'src/app/models/datasource.model';
import { DataSourceRecordView } from "./provider-data-source";
import { DataSourceFieldRow, DataSourceRow } from "src/app/models/api/domain/NewDataSourceModelBase";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { ObjectSearchService } from "src/app/services/object-search.service";
import { UserService } from "src/app/services/user.service";
import { UtilFunctionsService } from "src/app/services/util-functions.service";
import { UserDetailsRow } from "src/app/models/user.model";
import { Id } from "src/app/helper/id";

import * as model from "src/app/models/api/models/staging/RichDataSourceCake";

export class DataSourceViewRecord implements RecordProvider<dss.DataSource, DataSourceRecordView, RichDataSourceCake.DataSourceView<any>[], dss.ObjectProtocolRow> {

    subs = new SubSink;

    //datasources: dss.DataSource[];



    constructor(private view: RichDataSourceCake.DataSourceView<any>[],private allUserDetails: UserDetailsRow[],private datasourceService: DatasourcesService, private errorService: SystemMessageLogService, private objectFilterService: ObjectSearchService, private userService: UserService, private utilService: UtilFunctionsService) {
    }

    /**
 * Hilfsfunktionen, bis das Model grade gezogen ist.
 * @param ds Korrekt DS Model
 * @returns Old DS Model
 */
    static asDs(ds: DataSourceRow): dss.DataSource {
        return new dss.DataSource(ds.id, ds.name, ds.sourceSystem, ds.age, ds.usePsa, ds.description);
    }

    /**
 * Hilfsfunktionen, bis das Model grade gezogen ist.
 * @param ds Korrekt Field Model
 * @returns Old Field Model
 */
    static asField(f: DataSourceFieldRow): dss.DataSourceField {
        const ff = new dss.DataSourceField();

        ff.dataSource = f.dataSource;
        ff.dataType = f.dataType;
        ff.format = f.format;
        ff.id = f.id;
        ff.isFixedSize = f.isFixedSize;
        ff.isKey = f.isKey;
        ff.length = f.length;
        ff.name = f.name;
        ff.position = f.position;
        ff.precision = f.precision

        return ff;
    }

    asRecord(arg: dss.DataSource, data: RichDataSourceCake.DataSourceView<any>[]): DataSourceRecordView {

        const v = data.find(d => d.DataSource.id == arg.id);
        if (v) {
            const view = new DataSourceRecordView(arg);
            view.object = v.DataSource;
            view.connector = v.ConnectorSettings;
            view.fields = v.Fields.map(f => DataSourceViewRecord.asField(f));
            view.permissions = v.Permissions.map(p => p.Entry);
            view.workflows = [];
            view.adapter = v.ConnectorInfo;

            const permSorted = view.permissions.sort((a, b) => {
                if (a.IsOwner === b.IsOwner) {
                  return 0;
                }
                return a.IsOwner ? -1 : 1;
              })

            view.shared = permSorted.map((p) => {
                
                const userdetailinfos = this.allUserDetails.find ( (detail) => {return p.Role == detail.id});

                if(userdetailinfos && userdetailinfos.avatar) {
                    const image = this.utilService.int8ArrayToBase64Image(userdetailinfos.avatar)
                    return image;
                  } else {
                      return undefined;
                  }


            });
            console.log(view.shared);


            const dsProtocols = v.Protocols.map(p => p.Entry);
            const dpProtocols = v.DataPackages.flatMap((d: model.RichDataSourceCake.DataPackageEntry) => d.Protocols);  

            const allProtocols: dss.ObjectProtocolRow[] = [].concat(dsProtocols,dpProtocols);

            console.log("allProtocols",allProtocols);

            let latestProtocol = allProtocols.reduce((a, b) => {
                return new Date(a.start) > new Date(b.start) ? a : b;
            });

            view.lastUpdated = latestProtocol;

            // function to get user avatar
            const userFound = this.allUserDetails.find((user) => { return user.id === latestProtocol?.userId});
            console.log("userFound",userFound);
            if(userFound && userFound.avatar) {
                view.image = this.utilService.int8ArrayToBase64Image(userFound.avatar);
            } else {
                view.image = undefined;
            }

            // TODO: Currently not used, if needed add code here
            view.info = "TODO";

            return view;
        } else {
            throw new Error("The Data Source " + arg + "does not exist!");
        }
    }
    filterObjects(params: [Map<string, DropDownModel>, Map<string, FilterProvider<any>>], objList: DataSourceRecordView[]): DataSourceRecordView[] {

        console.log(params);

        let intermediateDataSets = objList;

        for (let providerEntry of params[1].keys()) {

            console.log("Apply filter: " + providerEntry);

            const provider = Id.assertSet(params[1].get(providerEntry), new Error("No provider for this entry found" + providerEntry));


            // TODO: get all filters from drop downs
            console.log(params[0].has(providerEntry));

            console.log("Get Entry");
            const xxx = params[0].get(providerEntry);
            console.log(xxx);

            const dropDownSelection: DropDownEntry<{}>[] | undefined = params[0].get(providerEntry)?.selection;

            if (dropDownSelection === undefined) continue;
            const dropDownValues = dropDownSelection.map(s => s.value);

            if (dropDownValues.length == 0) continue;

            console.log(intermediateDataSets);

            intermediateDataSets = intermediateDataSets.filter(ds => {
                const foundValues = dropDownValues.filter(va => provider.filter(va, ds));
                return foundValues.length > 0
            });
        }
        return intermediateDataSets;
    }

    getData(): Observable<RichDataSourceCake.DataSourceView<any>[]> {
        // let dsViewObs = this.expApi.getDataSourceView();

        // return dsViewObs
        return of(this.view);
    }

    asHeader(): HeaderRow[] {
        let cols: HeaderRow[] = [
            { field: 'object.name', header: 'Datasource', width: "65%" },
            { field: 'permissions', header: 'SharedWith', width: "10%" },
            { field: 'lastUpdated.start', header: 'LastUpdated', width: "15%" },
            { field: 'lastUpdated.user', header: 'by', width: "10%" },

        ];
        return cols
    }

    asActions(): MenuItem[] {

        let dialogActions: MenuItem[] = [

            {
                label: 'Change datasource', tooltip: "ChangeDatasourceInformation", styleClass: "p-button-rounded p-button-text p-mr-2 p-mb-2", icon: "pi pi-info", command: () => {
                    this.datasourceService.displayEditDatasourceDialog([this.selectedDataSource,true]);
                }
            },
            {
                label: 'Delete', tooltip: "DeleteDatasourcePermanently", styleClass: "p-button-rounded p-button-danger p-button-text p-mr-2 p-mb-2", icon: "pi pi-trash", command: () => {
                    this.datasourceService.displayDeleteDatasourceDialog( [this.selectedDataSource,true]);
                }
            },
        ];

        return dialogActions
    }

    selectedDataSource?: dss.DataSource;

    subscribeToEmitter(objList:dss.DataSource[]) {
        this.subs.sink = this.datasourceService.selectedDatasourceEmitter.subscribe((res: dss.DataSource) => {
            this.selectedDataSource = res;
            console.log(this.selectedDataSource);
        },
            (err: Error) => {
                this.errorService.handleError(err);
            });
        this.subs.sink = this.objectFilterService.objectSearchParamsEmitter.subscribe((params) => {

            this.subs.sink = this.getData().subscribe((finalRecordResult) => {
                if (objList) {
                    let objListViews: DataSourceRecordView[] = objList.map(obj => this.asRecord(obj, finalRecordResult));
                    let filteredObjects = this.filterObjects(params, objListViews);
                    this.objectFilterService.emitChangedFilteredObjectList(filteredObjects);

                    console.log("Filtered Objects", filteredObjects);
                }
            },
                (err: Error) => {
                    this.errorService.handleError(err);
                });
        });
        this.subs.sink = this.datasourceService.changedObjectLoadingEmitter.subscribe((loading: boolean) => {
            // Update loading status icon for respective ds
        },
            (err: Error) => {
                this.errorService.handleError(err);
            });
    }

    emitObjectAction(action: string, object: any) {
        if(action === "CreateNewObject") {
            console.log("CreateNewObject")
            this.datasourceService.displayCreateDatasource.emit(true);
            return
        }
        this.datasourceService.selectedMenuDatasourceEmitter.emit(object.object);

        if (action === "PullAction") {
            this.datasourceService.displayPullDatasourceDialog([object.object, true]);
        }
        if (action === "UploadFile") {
            this.datasourceService.displayPushNewDataDialog([object.object, true]);
        }
        if (action === "ChangeSettings") {
            this.datasourceService.displayChangeConnectorSettingsDialog([object.object, true]);
        }
        if (action == "EditWriteMode") {
            this.datasourceService.displayChangeWriteModeDialog([object.object,true]);
        }
    }

    sortEntries(entries: DataSourceRecordView[]): DataSourceRecordView[] {
        let sortedProtocols = entries.sort(
            (objA, objB) => RecordViewSorter.sortLastUpdate(objA,objB)
        );

        return sortedProtocols;
        //return entries;
    }
}
