import { Component, OnInit } from '@angular/core';
import { SelectPlugIn } from 'src/app/models/api/com/bion/etl/plugins/SelectPlugIn';
import { DataTypeInfo } from 'src/app/models/datasource.model';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { DesignerService } from 'src/app/services/designer.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { NodeConfigComponentBase } from '../node-config-component-base';
import { FieldInfo } from "src/app/models/api/com/bion/etl/Workflow";
import { Arrays } from 'src/app/helper/arrays';

export class SelectInfoView {
	Name: string;
	Select: boolean;
	DataType?: DataTypeInfo;
	NewName?: string;
	NewDataType?: DataTypeInfo;
	NewLength?: number;
	hasError?: boolean = false;

	constructor(Name: string, Select: boolean, NewName?: string, DataType?: DataTypeInfo, NewDataType?: DataTypeInfo, NewLength?: number, hasError?: boolean) {
		if (Name === undefined) throw new Error("Class 'SelectInfo': Required value 'Name' is undefined");
		if (Select === undefined) throw new Error("Class 'SelectInfo': Required value 'Select' is undefined");
		this.Name = Name;
		this.Select = Select;
		this.NewName = NewName;
		this.DataType = DataType;
		this.NewDataType = NewDataType;
		this.NewLength = NewLength;
		this.hasError = hasError;
	}

	toInfo(): SelectPlugIn.SelectInfo {
		return SelectInfoView.toInfo(this);
	}

	static fromInfo(info: SelectPlugIn.SelectInfo, meta: FieldInfo[], dataTypes: DataTypeInfo[]): SelectInfoView {

		//let select_info_view = new SelectInfoView();
		//select_info_view.Name = info.Name;
		const dataType = dataTypes.find(res => res.Name === info.NewTypeName);
		const newDataType = dataTypes.find(res => res.Name === info.NewTypeName);
		//select_info_view.NewLength = info.NewTypeLength;
		//select_info_view.NewName = info.NewName;

		const field_info_found = meta.find(field => field.Name === info.Name);
		const has_error = !field_info_found;

		// let has_error = false;
		// if (field_info_found) {
		// 	has_error = false;
		// } else {
		// 	has_error = true;
		// }

		const select_info_view = new SelectInfoView(info.Name, info.Select, info.NewName, dataType, newDataType, info.NewTypeLength, has_error);

		return select_info_view;
	}
	static toInfo(v: SelectInfoView): SelectPlugIn.SelectInfo {

		let s_name: string;
		let new_type: string | undefined;

		//Fill out SelectEntry
		s_name = v.Name;
		const t_name = v.NewName;
		const new_selected = v.Select;
		const new_len = v.NewLength;

		if (v.NewDataType?.Name !== v.DataType?.Name) {
			new_type = v.NewDataType?.Name;
		} else {
			new_type = v.DataType?.Name;
		}

		const newSelectEntry = new SelectPlugIn.SelectInfo(s_name, new_selected, t_name, new_type, new_len);

		return newSelectEntry;
	}
}

/**
 * View für den Spark Select.
 */
@Component({
	selector: 'app-spark-select-node',
	templateUrl: './spark-select-node.component.html',
	styleUrls: ['./spark-select-node.component.scss']
})
export class SparkSelectNodeComponent
	extends NodeConfigComponentBase<SelectPlugIn.Settings>
	implements OnInit {

	constructor(
		protected workflowService: WorkflowsService,
		protected designerService: DesignerService,
		protected bionApi: ApiBackendService
	) {
		super(workflowService, designerService);
	}

	dataTypesList: DataTypeInfo[] = [];
	readonly InputPort = "Input";
	allDsFields: SelectInfoView[] = [];
	selectedDsFields: SelectInfoView[] = []; // scoped and changed data

	ngOnInit(): void {
		super.ngOnInit();
	}


	settingsToView(settings: SelectPlugIn.Settings): void {

		if (Arrays.isNullOrEmpty(this.dataTypesList)) {
			// -- if no datatypes available, fetch datatypes from api
			this.subs.sink = this.bionApi.getDataTypes().subscribe((result) => {
				this.dataTypesList = result;
				this.loadSettingsToView(settings);
			});
		} else {
			// -- if datatypes available, load into view
			this.loadSettingsToView(settings);
		}
	}
	viewToSettings(): SelectPlugIn.Settings {
		const newSettings = <SelectPlugIn.Settings>{
			...this.getCurrentWorkflowNode().Properties.Configuration,
		};

		// -- Get select information
		const all_views = this.allDsFields;
		// const all_select_infos = all_views.map((v, i, a) => {
		// 	return this.selectInfoViewToSelectInfo(v);
		// });

		const selected_fields_names = this.selectedDsFields.map((field) => field.Name);

		const all_views_updated = all_views.map((view) => {
			view.Select = selected_fields_names.includes(view.Name);
			return view
		})



		const all_select_infos = all_views_updated.map(v => v.toInfo());
		newSettings.SelectInfos = all_select_infos;

		// -- Get selected fields
		//const selected_views = this.selectedDsFields;
		//const selected_fields = selected_views.map(fi => fi.Name);

		//newSettings.SelectedFields = selected_fields;

		return newSettings;
	}
	onSettingsChanged(settings: SelectPlugIn.Settings): void {
		throw new Error('Method not implemented.');
	}


	// -------- new LoadSettingsToView
	loadSettingsToView(settings: SelectPlugIn.Settings) {
		this.loading = true;

		const meta_infos = this.getCurrentWorkflowNode().Properties.MetaInfo.get(this.InputPort);

		const meta_info_opt = Arrays.headOption(meta_infos);

		if (meta_info_opt === undefined) {
			console.log("SELECT: settingsToView - meta infos could not be found!!!");
			return;
		}

		// //check Meta Infos, if not given => quit
		// if (Arrays.isNullOrEmpty(meta_infos)) {
		// 	console.log("SELECT: settingsToView - meta infos could not be found!!!");
		// 	return;
		// }

		const meta_info = Arrays.head(meta_infos); // infos of first and only table!
		//const meta_info = meta_infos[0]; // infos of first and only table!

		// Lade SelectInfos in den view, basierend auf ausegwählten Feldern und vorhandenen META INFOS.


		// Erwartet Meta Infos <=> Field Infos
		// Für jedes Feld existiert ein Field Info
		// ! Du Rückrichtichtung gilt nicht
		// Meta Infos IST TEILMENGE VON Field Infos

		// => Lade Field Info in View!

		// SelectInfo: BE Klasse
		// FieldInfo: BE Meta Info
		// SelectInfoView


		// Fall 1: Das Feld existiert!
		// Feld Info normal einfügen

		// Fall 2. Das Feld existiert NICHT!
		// Feld Info MIT MISSING INFO einfügen

		// Fall 3: Es gibt neue Felder, für die noch kein Field Info existiert!
		// => Delegieren ans Backend: Der Select Knoten baut für jedes fehlende Feld ein Field Info!

		const select_infos = settings.SelectInfos;
		//const field_info_views = this.fieldInfoToSelectInfoViews(select_infos, meta_info, this.dataTypesList);   // komplette Liste
		const field_info_views = select_infos.map(si => SelectInfoView.fromInfo(si, meta_info.FieldsInfo, this.dataTypesList));

		const selected_fields = settings.SelectInfos.filter(si => si.Select).map(fi => fi.Name);
		const selected_views = field_info_views.filter(f => selected_fields.includes(f.Name));  // Test mit Name & NewName mismatch

		this.allDsFields = field_info_views;
		this.selectedDsFields = selected_views;


		// Reihenfolge
		// - Wird bestimmt durch Field Infos

		// S = { A  , B , C , D }

		// Fälle: Es gibt mehr Field Infos als Felder: Reihenfolge?

		// Input Fields = A, B, D, F
		// Missing Fields = c, e
		// S = { A, B, c, D, e, F}  // Field Infos

		// Out Fields: A, B, D, F

		// Will die Reihenfolge: D, B, F, A
		// S = { e, c, D, B, F, A}
		// Out Fields: D, B, F, A
		// Out Field Infos: e, c, D, B, F, A
	}
}
