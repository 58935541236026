import { Component, OnInit } from '@angular/core';
import { ConfigComponent } from '../../../create-ab-source/config.component';

export interface Configuration {
  client_secret: string;
  account_id: string;
  start_date: string;
  slice_range?: number;
  lookback_window_days?: number;
}

@Component({
  selector: 'app-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.scss']
})
export class StripeComponent implements OnInit, ConfigComponent {

  constructor() {
    this.data = {
      "client_secret": "",
      "account_id": "",
      "start_date": ""
    }
  }
  data: Configuration;
  connectorSpecs: any;

  ngOnInit(): void {
  }

}
