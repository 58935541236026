<p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>

<p-dialog header="{{headerText | translate}}" [baseZIndex]="1000" [(visible)]="displayCreateDataSource" modal="modal" showEffect="fade"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '70vw'}" (onHide)="onCloseDialog()">

    <p-steps [model]="stepsItems" [activeIndex]="activeIndex" (activeIndexChange)="onIndexChanged($event)">
    </p-steps>

    <app-system-message-log></app-system-message-log>

    <div class="p-col-12" style="margin-top: 3rem;">
        <div>
            <div class="p-grid" *ngIf="stepsItems[activeIndex].label === 'Choose Connection'">
                <app-choose-connector #chooseConnector [connectorList]="connectorList" [dsActionType]="dsActionType" [selectedDataSet]="selectedDataSet" (selectedAdapterInfo)="setSelectedAdapterInfo($event)"
                    (currentConnectorViewEmitter)="setCurrentConnectorView($event)">
                </app-choose-connector>
            </div>
            <div class="p-grid" *ngIf="stepsItems[activeIndex].label === 'Define Datasource'">
                <app-define-datasource #page_def_datasource [(dataSourceName)]="currentDataSourceName"
                    [(dataSourceDescription)]="currentDataSourceDescription"
                    (datasourceNameEmitter)="setDataSourceName($event)"
                    (LoadDataFlagEmitter)="setDataFlag($event)"
                    (datasourceDescriptionEmitter)="setDataSourceDescription($event)"
                    (selectedWriteMode)="setWriteMode($event)">
                </app-define-datasource>
            </div>
            <div class="p-grid"  *ngIf="stepsItems[activeIndex].label === 'Select Columns'">
                <app-create-new-datasource #page_ds_dialog [dataPreviewColumns]="dataPreviewColumns" [dataPreviewRows]="dataPreviewRows"
                    (DataSourceFieldsEmitter)="onUpdateDataSourceFields($event)"
                    [previewRows]="previewRows" [previewColumns]="previewColumns"
                    >
                </app-create-new-datasource>
            </div>

            <div  *ngIf="stepsItems[activeIndex].label === 'Load Data'">
                <app-populate-psa #page_pop_psa (closeDialogEmitter)="onCloseDialog()" (loadPsaEmitter)="onPsaLoadClicked()" [isNewDataSource]="isNewDataSource" [psaInfos]="psaInfos">
                </app-populate-psa>
            </div>
        </div>
    </div>

    <!-- <div style="margin: 0 5px">
        <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '2px'}"></p-progressBar>
    </div> -->

    <p-footer>
        <!-- <button type="button" pButton icon="pi pi-times" (click)="displayLoadDataSource=false" label="Cancel"
            class="p-button-text"></button> -->
        <button *ngIf="this.activeIndex !== 0 && this.activeIndex !== 3" pButton pRipple type="button" icon="pi pi-arrow-left" label="{{'Back' | translate}}"
            (click)="resetCurrentForm(this.activeIndex)" class="p-button-text p-button-rounded p-mr-2 p-mb-2"></button>
        <button pButton pRipple type="button" icon="pi pi-check" *ngIf="this.activeIndex !== 3" (click)="validateCurrentForm(this.activeIndex)"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2" label="{{'Next' | translate}}"
            [icon]="savingInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="buttonHidden"></button>
    </p-footer>
</p-dialog>
