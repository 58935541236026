import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthConfig, LoginOptions, OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { AuthFlowUtils } from './oauth-flow-utils';

/**
 * Auth-Informationen wie Tokens und Nutzerdaten.
 */
export interface AuthInfo {
  AccessToken?: string;
  RefreshToken?: string;
}

/**
 * Führt den impliziten OAuth2 Flow für einen beliebigen Open ID Provider aus.
 * Die Komponente unterstützt den Refresh-Token Abgriff für die Bion Konnektoren.
 * 
 * Über das Input-Attribut 'authConfig' können alle notwendigen Parameter übergeben werden. Hierzu gehören Auth-URl, Client-ID, Scope etc.
 * 
 * Das Output-Attribut 'authInfo' sollte alle notwendigen Infos, vor allem aber das Refresh-Token zurückgeben.
 * 
 * Eine Beispielkomponente gibt es hier:
 * https://github.com/manfredsteyer/angular-oauth2-oidc/blob/master/projects/sample/src/app/home/home.component.ts
 */
@Component({
  selector: 'app-oauth2-flow',
  templateUrl: './oauth2-flow.component.html',
  styleUrls: ['./oauth2-flow.component.scss']
})
export class Oauth2FlowComponent implements OnInit {

  constructor(private oauthService: OAuthService, private httpService: HttpClient) {
    console.warn("Service Injection should be done by Controller component.");
  }

  readonly err_config_unset = "OAuth2 Config not set yet."

  @Input()
  get authConfig(): AuthConfig { return this._authConfig; }
  set authConfig(authConfig: AuthConfig) {
    this._authConfig = authConfig;

    if (this.authConfig === undefined)
      console.warn(this.err_config_unset);
    else
      this.configureSingleSignOn(this._authConfig);
  }
  private _authConfig: AuthConfig;

  /**
   * Gibt an, ob die Buttons zum antriggern des OAuth-Flow-Fensters bzw. Popups angezeigt werden sollen. Dies ist praktisch zum Testen.
   * 
   * Produktive Komponenten können diese Komponente als ViewChild einbinden und anschließed die Funktionen direkt aufrufen, und zwar in der Reihenfolge:
   * 
   * 1. Auth-Configuration setzen via: authConfig (set)
   * 2. Flow starten, z.B. via: loginFlowInPopup
   */
  @Input()
  showDebugButtons: boolean = true;

  @Input()
  testQueryParams: any = {};

  /**
   * Wird ausgelöst, sobald der Login-Prozess erfolgreich war.
   */
  @Output() authInfo = new EventEmitter<AuthInfo>();

  ngOnInit(): void {
    if (this._authConfig === undefined) {
      console.warn(this.err_config_unset)
    } else {
      this.configureSingleSignOn(this._authConfig);
    }
  }

  /**
   * Konfiguriert den Single-Sign-On Prozess.
   * @param config Auth Konfiguration
   */
  configureSingleSignOn(config: AuthConfig) {
    console.log("Configure Single Sign On", config);

    console.log("Set silent redirect");
    config.silentRefreshRedirectUri = window.location.origin + '/assets/views/general/oauth2Flow/silent-refresh.html';
    console.log(config.silentRefreshRedirectUri);

    console.log("Final Config", config);
    this.oauthService.configure(config);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();

    // Check if we need this, looks like this is for default login funcionality.
    // Could be helpful if the user is already logged in to its Auth-Provider because then we save the login dialog.
    return this.oauthService.loadDiscoveryDocumentAndTryLogin()
    // .then(result => {
    //   return result
    // }, failed => {
    //   console.log(failed);
    // })
  }

  /**
   * Login mit einem Popup Fenster, sodass die Elternseite erhalten bleibt.
   * Nach erfolgreichem Login wird das AuthInfo emitted.
   */
  login() {
    console.log("Login URL", this.oauthService.loginUrl);
    this.oauthService.showDebugInformation = true;
    //this.oauthService.state = "my_state";
    this.oauthService.state = undefined;
    const login_options = new LoginOptions();
    //login_options.disableNonceCheck = true;
    //this.oauthService.tryLoginImplicitFlow(login_options);
    //this.oauthService.customQueryParams = { nonce: undefined };
    this.oauthService.initImplicitFlow();
    // this.oauthService.initLoginFlowInPopup().then(() => {
    //   console.log("Login Complete");
    //   const token = this.oauthService.getRefreshToken();
    //   const auth_info = { RefreshToken: token };
    //   this.authInfo.emit(auth_info);
    // });
  }

  /**
   * Login mit angular-oauth2-oidc im Popup Fenster
   * @param state State
   */
  loginFlowInPopup(state?: string, customQueryParams?: any) {
    console.log("Login URL", this.oauthService.loginUrl);
    this.oauthService.showDebugInformation = true;
    this.oauthService.state = state;

    const silentRefresh = false;
    const experimental = false;

    console.log("Adding access type = 'offline' for google auth")

    // const google_query_params = {
    //   'access_type': 'offline',               // refresh token
    //   'include_granted_scopes': 'true',
    //   'prompt': 'consent'                     // Enforce user prompt and refresh-token otherwise RT is only returned the first time
    // };

    console.log("Query Params unset", this.oauthService.customQueryParams);
    console.log("Query Params given");

    if (customQueryParams) {
      this.oauthService.customQueryParams = customQueryParams;
    } else {
      this.oauthService.customQueryParams = this.testQueryParams;
    }

    console.log("Query Params", this.oauthService.customQueryParams);

    return this.oauthService.initLoginFlowInPopup().then(() => {
      console.log("Login Complete");

      const refreh_token = this.oauthService.getRefreshToken();
      console.log("Refresh-Token", refreh_token);

      const access_token = this.oauthService.getAccessToken();
      console.log("Access-Token", access_token);

      const auth_info: AuthInfo = { AccessToken: access_token, RefreshToken: refreh_token };
      this.authInfo.emit(auth_info);

      return auth_info;
    });
  }

  /**
   * Testvariante von loginFlowInPopup
   * @param state 
   */
  private testLoginFlowInPopup(state?: string) {
    console.log("Login URL", this.oauthService.loginUrl);
    this.oauthService.showDebugInformation = true;
    this.oauthService.state = state;

    const silentRefresh = false;
    const experimental = false;

    console.log("Adding access type = 'offline' for google auth")
    this.oauthService.customQueryParams = {
      'access_type': 'offline',               // refresh token
      'include_granted_scopes': 'true',
      'prompt': 'consent'                     // Enforce user prompt and refresh-token otherwise RT is only returned the first time
    };

    if (silentRefresh) {
      this.oauthService
        .silentRefresh()
        .then(info => console.debug('refresh ok', info))
        .catch(err => console.error('refresh error', err));
    } else if (experimental) {
      this.oauthService.initLoginFlowInPopup()
        .then(() => this.oauthService.loadUserProfile())
        .then(() => {
          const claims = this.oauthService.loadUserProfile();
          console.log(claims);
        })
        .catch(err => console.error('login sequence failed with: ', err));

    } else {
      this.oauthService.initLoginFlowInPopup().then(() => {
        console.log("Login Complete");

        const refreh_token = this.oauthService.getRefreshToken();
        console.log("Refresh-Token", refreh_token);

        const access_token = this.oauthService.getAccessToken();
        console.log("Access-Token", access_token);

        const auth_info: AuthInfo = { AccessToken: access_token, RefreshToken: refreh_token };
        this.authInfo.emit(auth_info);
      });
    }
  }

  loginCodeFlow(state?: string) {
    console.log("Login Code Flow");

    console.log("Login URL", this.oauthService.loginUrl);
    this.oauthService.showDebugInformation = true;
    this.oauthService.state = state;

    this.oauthService.initCodeFlow();
  }

  /**
   * Login mit angular-oauth2-oidc im selben Fenster
   * @param state 
   */
  loginImplicitFlow(state?: string) {
    console.log("Login URL", this.oauthService.loginUrl);
    this.oauthService.showDebugInformation = true;
    this.oauthService.state = state;
    this.oauthService.state = "my_state";

    // const url_params = { scope: "https://www.googleapis.com/auth/drive" }
    // this.oauthService.customQueryParams = url_params;

    this.oauthService.initImplicitFlow();
  }

  /**
   * Login mit eigenem Popup. Das Ergebnis wird an das Elternfenster geschickt und zwar:
   * 
   * component -> google -> parent-window-sender -> parent
   * 
   * Die Elternkomponente muss dabei auf Fenster Nachrichten hören.
   * 
   */
  loginByPopUpMyself() {
    const c = this._authConfig;

    const params = [
      { param: "response_type", value: c.responseType },
      { param: "client_id", value: c.clientId },
      { param: "state", value: "my_state" },
      // { param: "redirect_uri", value: c.redirectUri },
      { param: "redirect_uri", value: "http://localhost:4200/#/test/TestOauth2FlowComponentCallback" },
      { param: "scope", value: c.scope }
    ]

    // const options = ApiServiceUtils.makeOptions(
    //   { param: "response_type", value: c.responseType },
    //   { param: "client_id", value: c.clientId },
    //   { param: "state", value: "my_state" },
    //   { param: "redirect_uri", value: c.redirectUri },
    //   { param: "scope", value: c.scope }
    // );

    const e_params = AuthFlowUtils.encodeUrlParams(params);
    const param_str = AuthFlowUtils.makeUrlParams(e_params);
    const google_auth_url = "https://accounts.google.com/o/oauth2/v2/auth";

    const url = google_auth_url + "?" + param_str;
    console.log("URL", url);
    window.open(url, 'newwindow');
  }

  /**
   * Testroutine für Popup, sollte später nicht mehr verwendet werden, weil die gleiche Funktionalität in parent-window-sender ist.
   */
  loginByMyself() {

    const c = this._authConfig;

    const params = [
      { param: "response_type", value: c.responseType },
      { param: "client_id", value: c.clientId },
      { param: "state", value: "my_state" },
      { param: "redirect_uri", value: c.redirectUri },
      { param: "scope", value: c.scope }
    ]

    // const options = ApiServiceUtils.makeOptions(
    //   { param: "response_type", value: c.responseType },
    //   { param: "client_id", value: c.clientId },
    //   { param: "state", value: "my_state" },
    //   { param: "redirect_uri", value: c.redirectUri },
    //   { param: "scope", value: c.scope }
    // );

    const e_params = AuthFlowUtils.encodeUrlParams(params);
    const param_str = AuthFlowUtils.makeUrlParams(e_params);
    const google_auth_url = "https://accounts.google.com/o/oauth2/v2/auth";

    const url = google_auth_url + "?" + param_str;
    console.log("URL", url);
    window.open(url, 'newwindow');
  }

  logout() {
    // hopefully we won't need this.
    this.oauthService.logOut();
  }
}
