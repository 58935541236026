<p>Connection</p>
<p-tabView>
    <p-tabPanel header="Status">
        <div>
            <div>
                <h2>Extract History</h2>
            </div>
            <div>
                Comming soon
            </div>
        </div>
    </p-tabPanel>
    <p-tabPanel header="Streams">

        <div>
            <p-button *ngIf="stream_change_mode==0" (onClick)="onStreamsChange()">Change</p-button>
            <p-button *ngIf="stream_change_mode==1" (onClick)="onStreamsCancel()">Cancel</p-button>
            <p-button *ngIf="stream_change_mode==1" (onClick)="onStreamsSave()">Save</p-button>
        </div>

        <div>
            <app-define-streams-int #defineStreams [writeModeInfos]="write_mode_infos"
                [genCatalog]="new_generic_catalog" [disabled]="stream_change_mode==0"></app-define-streams-int>
        </div>

    </p-tabPanel>
</p-tabView>