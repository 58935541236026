import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { UserInfoController } from 'src/app/models/api/controllers/UserInfoController';
import { BackendAuthService } from 'src/app/services/backend-auth.service';

export interface AppStatus {
  status: string;
  loading: boolean;
  userLoggedIn: boolean;
}

@Component({
  selector: 'app-loading-app',
  templateUrl: './loading-app.component.html',
  styleUrls: ['./loading-app.component.scss'],
  animations: [trigger("fade", [
    state("void", style({ opacity: 0 })),
    transition(":enter", [animate(500)]),
    transition(":leave", [animate(500)]),
  ]),]
})
export class LoadingAppComponent implements OnInit {
  @Input() appStatus!:  AppStatus;
  ///currentUserFull: UserInfoController.FullUserInfo;

  constructor() { }

  ngOnInit(): void {

  }

    // Backend Keycloak Authentication


}
