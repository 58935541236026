

<div class="p-grid" *ngIf="selectedNode">
    <div class="p-col-12 p-grid">
         <!-- <div class="p-col-12">
           <div class="p-col-12 dataSetSection">
                <div class="p-col-12" style="text-align: center;">
                    <img [src]="'assets/layout/images/nodes/' + selectedNode.NameLabel + '.png'"
                        [alt]="selectedNode.NameLabel" />
                    <p>{{selectedNode.NameLabel}}</p>
                    <p-chip>{{selectedNode.Category}}</p-chip>
                </div>
            </div> -->

            <!-- <div class="p-col-12 dataSetSection">
                <div class="card-header">
                    <div class="card-title">
                        <h6>{{'Category' | translate}}</h6>
                    </div>
                </div>
                <p-chip>{{selectedNode.Category}}</p-chip>
            </div> 
            <p-divider></p-divider>
            <div class="p-col-12 dataSetSection">
                <div class="card-header">
                    <div class="card-title">
                        <h6>{{'Ports' | translate}}</h6>
                    </div>
                </div>
                <p *ngIf="selectedNode.Ports">{{selectedNode.Ports.length}} Input(s) | {{selectedNode.Ports.length}}
                    Output(s)</p>
            </div>

        </div> -->
        <div class="p-col-12 dataSetSection">
            <div class="card-header">
                <div class="card-title">
                    <h6>{{'Description' | translate}}</h6>
                </div>
            </div>
            <div class="product-info-section-items" *ngIf="selectedNode.NodeDescription" style="margin-right: 5px; float: left;	text-align: justify;
                text-align-last: left;">
                <p *ngFor="let txt of selectedNode.NodeDescription.LongText">{{txt | translate}}</p>
            </div>
        </div>

        <div class="p-col-12 dataSetSection">
            <div class="card-header">
                <div class="card-title">
                    <h6>{{'Settings' | translate}}</h6>
                </div>
            </div>
            <div class="product-info-section-items" style="margin-right: 5px; float: left;	text-align: justify;
            text-align-last: left;">
                <p *ngFor="let req of selectedNode.Settings">{{req | translate}}</p>

            </div>
        </div>

        <div class="p-col-12 dataSetSection" *ngIf="selectedNode.ImageUrls ">
            <div class="card-header">
                <div class="card-title">
                    <h6>{{'Quick Guide' | translate}}</h6>
                </div>
            </div>
            <div class="product-info-section-items p-grid" style="margin-right: 5px; float: left;	text-align: justify;
            text-align-last: left;">
                <div class="p-col-4" *ngFor="let img of selectedNode.ImageUrls; let i = index">
                    <span style="top:0px;left:-10" >    
                        <p-avatar [label]="i + 1" styleClass="mr-2"></p-avatar>
                    </span>
                    <div class="card">
                        <p-image  [src]="img.itemImageSrc" alt="Image" width="150px" [preview]="true"></p-image>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="p-col-12 dataSetSection" *ngIf="selectedNode.VideoUrls">
            <div class="card-header">
                <div class="card-title">
                    <h6>{{'Useful Videos' | translate}}</h6>
                </div>
            </div>
            <div class="p-col-4" style="margin-right: 5px; margin-bottom: 5px; float: left;"
                *ngFor="let vid of selectedNode.VideoUrls">
                <div class="card"  style="text-align: center;">
                    <iframe  [src]='getSafeUrl(vid)' frameborder="0"
                        allowfullscreen></iframe>
                </div>
            </div>
        </div> -->

        <div class="p-col-12 dataSetSection">
            <div class="card-header">
                <div class="card-title">
                    <h6>{{'UseCases' | translate}}</h6>
                </div>
            </div>
            <div class="product-info-section-items" style="margin-right: 5px; margin-bottom: 5px; float: left;"
                *ngFor="let usecase of selectedNode.UseCases">
                <p-tag value="{{usecase}}"></p-tag>
            </div>
        </div>
    </div>

</div>
