import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { IntegratedDestinationController, IntegratedDestinationModel } from '../views/destinations/components/choose-dest-connector-page/choose-dest-connector-page.component';
import { ApiServiceUtils } from './api-util';

@Injectable({
  providedIn: 'root'
})
export class IntegratedDestinationService {
  constructor(private http: HttpClient) {
  }

  /**
   * 
   * @returns Alle Bion und Airbyte Konnektoren. 
   */
  getConnectors(): Observable<IntegratedDestinationController.ConnectorInfo[]> {
      const options = ApiServiceUtils.makeOptions();
      return this.http.get<IntegratedDestinationController.ConnectorInfo[]>("/exp/api/Staging/Destinations/Integrate/api/getConnectors", options);
  }
      /**
     * Konnektor Details und Konfigurationsinformationen.
     * @param arg Konnektor Schlüssel
     * @returns 
     */
      getConnectorSpecs(arg: IntegratedDestinationModel.ConnectorKey<string>): Observable<IntegratedDestinationModel.ConnectorSpec<string,any>> {
        return this.http.post<IntegratedDestinationModel.ConnectorSpec<string,any>>("/exp/api/Staging/Destinations/Integrate/api/getConnectorSpecs", arg);
    }

    /**
     * Erstellt eine neue Datasource
     * @param arg Konnektorschlüssel, Konfiguration und Name.
     * @returns Datasource Id und Original API Ergebins
     */
    createDestination<C,R>(arg: IntegratedDestinationModel.CreateDestinationArg<C>): Observable<IntegratedDestinationModel.CreateDestinationResult<R>> {
        return this.http.post<IntegratedDestinationModel.CreateDestinationResult<R>>("/exp/api/Staging/Destinations/Integrate/api/createDataSource", arg);
    }
}
