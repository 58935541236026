import { Observable, of } from "rxjs";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { ConnectorSettingsBuilder, ConnectorViewBase } from "../connectorView.model";

export class TwitterAccess {
    path: string;
    bearerToken: string

    constructor(path: string, bearerToken) {
        this.path = path;
        this.bearerToken = bearerToken;
    }
}

export class TwitterConnectorView extends ConnectorViewBase<TwitterAccess,undefined,undefined> {
    fetchMetaDataObs(): Observable<undefined> {
        throw new Error("Method not implemented.");
    }
    getSettingsBuilder(): Observable<ConnectorSettingsBuilder<TwitterAccess>> {
        // let asyncReader = new AsyncFileReader();
        // let result = asyncReader.readAsync(this.uploadedFile);

        // let finalRes = result.pipe(
        //     map((fileData: string) => {
        //         let innerRes = new TwitterSettingsBuilder(this.api, this.connectorID, this.connectorSettings.path, this.connectorSettings.bearerToken);
        //         return innerRes;
        //     })
        // );


        let innerRes = new TwitterSettingsBuilder(this.api, this.connectorID, this.connectorSettings.path, this.connectorSettings.bearerToken);
        let finalRes = of(innerRes);
        return finalRes;
    }
    uploadFile(file: File): void {
        throw new Error("Method not implemented.");
    }
    getDataSourceNameSuggestion(): string {
        throw new Error("Method not implemented.");
    }
    getBase64FileData(): string {
        throw new Error("Method not implemented.");
    }
    isFileBased(): boolean {
        return false;
    }

}

export class TwitterSettingsBuilder implements ConnectorSettingsBuilder<TwitterAccess> {

    constructor(api: ApiBackendService, connectorID: string, path: string, bearerToken: string) {
        this.api = api;
        this.connectorID = connectorID;
        this.path = path;
        this.bearerToken = bearerToken;

    }

    getConnectorId(): string {
        return this.connectorID;
    }
    getConnectorSettings() {
        return new TwitterAccess(this.path, this.bearerToken);
    }
    connectorID: string;
    api: ApiBackendService;
    path: string;
    bearerToken: string;
}
