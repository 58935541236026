import { Workflow, WorkflowNode } from "src/app/models/api/com/bion/etl/Workflow";
import { WorkflowNodeInfo } from "src/app/models/designer.models";
import { GoEdgeEntry, GoNode, GoNodePaletteEntry, GoPaletteEntry, GoPortEntry } from "./goJs.model";


export interface GoJsWorkflowData {
    nodeData: GoNode[];
    linkData: GoEdgeEntry[]
}
export interface IGoJsCodec {
    /**
     * Turns a Bion Workflow into a GoJS Workflow
     * @param workflow Bion Workflow Object from BE
     */
    encode(workflow: Workflow): GoJsWorkflowData
    /**
     * Turns a GoJs Workflow into a Bion Workflow
     * @param goWfData GoJs Object with Nodes and Edges
     */
    decode(goWfData: GoJsWorkflowData): Workflow
}


export class GoJsCodec implements IGoJsCodec {
    constructor(private plugIns: WorkflowNodeInfo[]) { }

    encode(workflow: Workflow): GoJsWorkflowData {
        const goNodes = this.multiSampleNodeDataWf(workflow, this.plugIns);
        const goEdges = this.multiSampleLinkDataEdges(workflow);

        return { nodeData: goNodes, linkData: goEdges }

    }
    decode(goWfData: GoJsWorkflowData): Workflow {
        throw new Error("Method not implemented.");
    }

    multiSampleNodeDataWf(wfGraph: Workflow, plugIns: WorkflowNodeInfo[]): GoNode[] {
        //const wf_unkn = <unknown>workflow_graph;
        //const wf_cast = <wf.Workflow>wf_unkn;
        //const wf_map = NodeConfigUtil.workflow_object_to_map(wfGraph);


        // TODO: aktuelle Workflow Class enthält Map
        return this.encodeWorkflow(wfGraph, plugIns);
    }
    /**
     * Encode Workflow to GoJS
     * @param workflow Workflow Object
     */
    encodeWorkflow(workflow: Workflow, plugIns: WorkflowNodeInfo[]): GoNode[] {
        // workflow -> gojs

        const go_nodes = workflow.Nodes.map(n => this.encodeNode(n, plugIns));

        return go_nodes;
    }
    encodeNode(node: WorkflowNode, plugIns: WorkflowNodeInfo[]): GoNode {

        const log_str = node.GuiSettings.X.toString() + " " + node.GuiSettings.Y.toString();[]
        let go_node: GoNode = { id: parseInt(node.ID), type: node.Engine.ClassName, name: node.Name, loc: log_str, nodeProperties: node.Properties , value: undefined};

        const node_info = plugIns.find(plugIn => plugIn.Engine.Name === node.Engine.Name);
        if (node_info) {
            go_node.leftArray = GoJsCodec.createPorts(node_info, true);
            go_node.rightArray = GoJsCodec.createPorts(node_info, false);
        } else {
            console.log("Node info not found for", node);
        }
        go_node.value = node_info;

        console.log(go_node);


        return go_node;
    }
    /**
    * 
    * @param node Workflow Node Object without port infos
    */
    static createPorts(plugIn: WorkflowNodeInfo, isInput: boolean): GoPortEntry[] {
        //const plugIns = this.plugIns;
        //const nodePlugIn = plugIns.find(plugIn => plugIn.Engine.Name === node.Engine.Name);

        const prefix = isInput ? "left" : "right";

        const ports = plugIn.Ports;
        const left_ports = ports.filter(port => port.IsInput == isInput);
        const left_port_array = left_ports.map((port, idx, all) => {
            const p_id = prefix + idx.toString();
            return { portColor: "#6cafdb", portId: p_id }
        });
        return left_port_array
    }


    multiSampleLinkDataEdges(wfGraph: Workflow): GoEdgeEntry[] {
        const go_edges = this.createEdges(wfGraph);
        return go_edges;
    }
    createEdges(workflow: Workflow): GoEdgeEntry[] {
        const edges = workflow.Edges;
        const go_edges: GoEdgeEntry[] = edges.map(edge => {
            return { from: parseInt(edge.SourceNode), to: parseInt(edge.TargetNode), fromPort: edge.SourcePort, toPort: edge.TargetPort, progress: "not started" }
        });

        return go_edges
    }

}

export class CodecHelper {
    static cast<Z>(obj: any): Z {
        const wf_unkn = <unknown>obj;
        const wf_cast = <Z>wf_unkn;
        return wf_cast
    }
}

export class GoPaletteCodec {
    /**
 * Creates GoPalette with all nodes
 * @param plugIns PlugIn Object List
 * @returns GoNode List
 */
    static createGoPalette(plugIns: WorkflowNodeInfo[]): GoPaletteEntry[] {
        const goPalette = plugIns.map(plugIn => {
            const entry: GoPaletteEntry = { key: plugIn.Name, text: plugIn.Engine.ClassName, color: 'grey', value: plugIn }
            return entry
        })

        return goPalette
    }

    static createGoNode(plugIns: WorkflowNodeInfo[]): GoNode[] {
        const goNode = plugIns.map(plugIn => {
            const leftArray = GoJsCodec.createPorts(plugIn, true);
            const rightArray = GoJsCodec.createPorts(plugIn, false);
            const entry: GoNode = { id: -1, loc: "", name: plugIn.Name, type: plugIn.Engine.ClassName, leftArray: leftArray, rightArray: rightArray, nodeProperties: undefined, value: plugIn}
            return entry
        })

        return goNode
    }

    static createGoNodePalette(plugIns: WorkflowNodeInfo[]): GoNodePaletteEntry[] {
        const goPalette = plugIns.map(plugIn => {
            const entry: GoNodePaletteEntry = {
                key: plugIn.Name, text: plugIn.Engine.ClassName, color: 'grey', value: plugIn, name: plugIn.Name,
                leftArray: GoJsCodec.createPorts(plugIn, true), rightArray: GoJsCodec.createPorts(plugIn, false)
            }
            return entry
        })

        return goPalette
    }
}