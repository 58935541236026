<p-dialog header="{{headerText}}" [(visible)]="displayInviteUser" modal="modal" showEffect="fade"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '400px'}">
    <div class="p-grid p-ai-center p-jc-center">
        <div class="p-col-12">
            <div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12">
                        <label for="username">Username</label>
                        <input type="text" id="username" pInputText [(ngModel)]="form.username">
                    </div>
                    <div class="p-field p-col-12">
                        <label for="email">E-Mail</label>
                        <input type="text" id="email" pInputText [(ngModel)]="form.email">
                    </div>
                    <div>
                        <input id="error-info" type="text" pInputText [disabled]="error_disabled" [(ngModel)]="error_text" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <p-footer>
        <button type="button" pButton icon="pi pi-check" (click)="sendEmail()" label="Yes"
            class="p-button-text"></button>
        <button type="button" pButton icon="pi pi-times" label="No" class="p-button-text"></button>
    </p-footer>
</p-dialog>
