<p-overlayPanel #op1 [showCloseIcon]="false">
    <ng-template pTemplate>
        <div style="float: left">
            <button pButton pRipple type="button" icon="pi pi-cog" class="p-button-rounded p-button-text p-mr-2 p-mb-2"
                pTooltip="{{'ChangeUser' | translate}}" (click)="displayEditUserDialog()"></button>
            <button pButton pRipple type="button" icon="pi pi-pencil" pTooltip="{{'ChangePassword' | translate}}"
                class="p-button-rounded p-button-warning p-button-text p-mr-2 p-mb-2"
                (click)="displayChangePasswordDialog()"></button>
            <button pButton pRipple type="button" icon="pi pi-trash" pTooltip="{{'DeleteUser' | translate}}"
                class="p-button-rounded p-button-danger p-button-text p-mr-2 p-mb-2"
                (click)="displayDeleteUserDialog()"></button>
        </div>
    </ng-template>
</p-overlayPanel>


<div class="card" style="height: calc(83vh);">
    <div class="card-header">
        <div class="card-title">
            <h5>{{'Users' | translate}}
            </h5>
        </div>
        <p class="subtitle">
            <button pButton pRipple type="button" icon="pi pi-user-plus" label="{{'Create' | translate}}"
                pTooltip="{{'CreateNewUser' | translate}}" (click)="displayNewUserDialog()"
                class="p-button-rounded p-button p-button-text p-mr-2 p-mb-2"></button>
            <!-- <button pButton pRipple type="button" icon="pi pi-send" label="Invite" pTooltip="Invite user"
                (click)="displayInviteUserDialog()"
                class="p-button-rounded p-button p-button-text p-mr-2 p-mb-2"></button> -->
        </p>
    </div>
    <p-table #dtc [value]="users" [(selection)]="selectedUser" dataKey="ID" selectionMode="single"
        styleClass="p-datatable-customers p-datatable-sm" [rows]="10" paginator=true [filterDelay]="0"
        [globalFilterFields]="['ID','Username','FirstName','LastName','EMail','IsAdmin']">
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dtc.filterGlobal($event.target.value, 'contains')"
                        placeholder="{{'SearchForUser' | translate}}" />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="Username">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Username' | translate}}
                        <p-columnFilter type="text" field="Username" display="menu" class="p-ml-auto">
                        </p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="FirstName">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Name' | translate}}
                        <p-columnFilter type="text" field="FirstName" display="menu" class="p-ml-auto">
                        </p-columnFilter>
                    </div>
                </th>
                <!-- <th pSortableColumn="LastName">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        LastName
                        <p-columnFilter type="text" field="LastName" display="menu" class="p-ml-auto">
                        </p-columnFilter>
                    </div>
                </th> -->
                <th pSortableColumn="EMail">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'EMail' | translate}}
                        <p-columnFilter type="text" field="EMail" display="menu" class="p-ml-auto">
                        </p-columnFilter>
                    </div>
                </th>
                <!-- <th pSortableColumn="IsAdmin">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Group(s)
                        <p-columnFilter type="text" field="IsAdmin" display="menu" class="p-ml-auto">
                        </p-columnFilter>
                    </div>
                </th> -->
                <th style="width: 4rem"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user>
            <tr [pSelectableRow]="user">
                <td>
                    <span class="p-column-title">{{'Username' | translate}}</span>
                    {{user.Username}} <p-tag *ngIf="user.IsAdmin === true" value="Admin"></p-tag>
                </td>
                <td>
                    <span class="p-column-title">{{'Name' | translate}}</span>
                    {{user.FirstName}} {{user.LastName}}
                    <!-- <td>
                    <span class="p-column-title">LastName</span>
                    {{user.LastName}}
                </td> -->
                <td>
                    <span class="p-column-title">{{'EMail' | translate}}</span>
                    {{user.EMail}}
                </td>
                <!-- <td>
                    <span class="p-column-title">Group(s)</span>
                    <span [class]="'customer-badge status-' + user.IsAdmin">{{user.IsAdmin}}</span>
                </td> -->
                <td>
                    <div class="ui-table-buttongroup-right">
                        <button pButton pRipple type="button" icon="pi pi-ellipsis-v"
                            class="p-button-rounded p-button-text p-mr-2 p-mb-2" pTooltip="{{'ChangeUser' | translate}}"
                            (click)="onMenuClicked(user)" (click)="op1.toggle($event)"></button>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="4">{{'NoEntryFound' | translate}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>
