import { JdbcAccess, CommonSettings } from "../connector.model";
import { ConnectorSettingsBuilder } from "../connectorView.model";
import { JdbcConnectorViewBase } from "./jdbc-connector-base.model";


export class JdbcConnectorView extends JdbcConnectorViewBase<CommonSettings> {

    // constructor(api: ApiBackendService, connectorID:string) {
    //     super(api, connectorID);
    // }

    // constructor(api: ApiBackendService, connectorID: string, url: string, dbtable: string, username: string, password: string) {
    //     this.api = api;
    //     this.metaInfo = new JdbcMetaInfo(new Array<string>());
    //     this.connectorID = connectorID;
    //     this.connectorSettings = new JdbcAccess(url, dbtable, username, password);
    //     this.url = url;
    //     this.dbtable = dbtable;
    //     this.username = username;
    //     this.password = password;
    // }
    // setInitialSettings(settings: JdbcAccess): void {
    //     throw new Error("Method not implemented.");
    // }
    // setInitialMetaSettings(settings: JdbcMetaInfo): void {
    //     throw new Error("Method not implemented.");
    // }
    // fetchMetaDataObs(): Observable<JdbcMetaInfo> {
    //     let dynamicItemInfo = new DynamicItemInfo;
    //     dynamicItemInfo.Type = this.connectorID;

    //     const con_settings = new CommonSettings(this.url, this.username, this.password);
    //     const meta_access = new JdbcMetaAccess(con_settings, this.dbtable);

    //     dynamicItemInfo.Data = meta_access;

    //     let extractMetaArg = new ExtractMetaFromConnectorArg;
    //     extractMetaArg.ConnectorMetaInfo = dynamicItemInfo;

    //     return this.api.ExtractMetaFromConnector(extractMetaArg).pipe(map((extractRes) => {
    //         return <JdbcMetaInfo>extractRes.MetaData;
    //     }));
    // }
    // getBase64FileData(): string {
    //     return '';
    // }
    // getConnectorID(): string {
    //     return this.connectorID;
    // }
    // getDataSourceNameSuggestion(): string {
    //     return this.dbtable.toString();
    // }
    // uploadFile(file: File) {
    //     throw new Error('JDBC does not support file upload');
    // }
    // getSettingsBuilder(): Observable<ConnectorSettingsBuilder<JdbcAccess>> {
    //     // throw new Error('JDBC does not support file upload');
    //     // let observer = undefined;
    //     let jdbcSettings = new JdbcSettingsBuilder(this.connectorID, this.url, this.dbtable, this.username, this.password);
    //     // observer.next(jdbcSettings);
    //     // observer.complete();
    //     // return new Observable<ConnectorSettingsBuilder>(observer);
    //     return of(jdbcSettings);
    // }
    // api: ApiBackendService;
    // connectorID: string;
    // url: string = "";
    // dbtable?: string = undefined;
    // username: string = "";
    // password: string = "";
    // connectorSettings: JdbcAccess;
    // metaInfo: JdbcMetaInfo;
    // uploadedFile: File;
}
export class JdbcSettingsBuilder implements ConnectorSettingsBuilder<JdbcAccess> {

    constructor(connectorId: string, url: string, dbtable: string, username: string, password: string) {

        this.connectorId = connectorId;
        this.url = url;
        this.dbtable = dbtable;
        this.username = username;
        this.password = password;
    }
    connectorId: string;
    url: string;
    dbtable: string;
    username: string;
    password: string;

    getConnectorId(): string {
        return this.connectorId;
    }
    getConnectorSettings() {
        const settings = new JdbcAccess(this.url, this.dbtable, this.username, this.password);
        return settings;
    }
}