import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { AppMainComponent } from 'src/app/app.main.component';
import { SubscriptionInfo } from 'src/app/models/api/controllers/license/SubscriptionInfo';
import { Model } from 'src/app/models/api/models/license/model/Model';
// import { SubscriptionInfo } from 'src/app/models/license.model';
import { UserInfoView } from 'src/app/models/user.model';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { Limit, SubscriptionView, UserSubscriptionService } from 'src/app/services/user-subscription.service';



@Component({
  selector: 'app-app-subscriptions',
	styleUrls: ["./app-subscriptions.component.scss"],
  templateUrl: './app-subscriptions.component.html',

})
export class AppSubscriptionsComponent implements OnInit {
  @Input() currentUser: UserInfoView;
  @Input() isHidden: boolean;
  @Input() currentLicenses: Model.Subscription[] = [];
  //currentLicenses: Model.Subscription[] = [];
  currentLimits: Limit[] = [];
  diffDays: number;

  constructor(private bionApi: ApiBackendService, private userSubService: UserSubscriptionService, private appMain: AppMainComponent, private translate: TranslateService) { }

  ngOnInit(): void {
    console.log("current Subs",this.currentLicenses);
    this.initLimitStatus(this.appMain.currentUserFull.Licenses);

    // this.userSubService._licenses.subscribe((lics) => {
    //   // this.currentLicenses = lics;
    //   this.userSubService.initLimitStatus(lics).subscribe((result) => {
    //     this.currentLicenses = result[0];
    //     this.currentLimits = result[1];
    //     this.diffDays = result[2];
    //     console.log("sub Service",result);
    //   });
    // });

  }
  // ngAfterViewInit() {
  //   console.log("current Subs",this.currentLicenses);
  //   this.userSubService.initLimitStatus(this.appMain.currentUserFull.Licenses).subscribe((result) => {
  //     this.currentLicenses = result[0]
  //     this.currentLimits = result[1];
  //     this.diffDays = result[2];
  //     console.log("sub Service",result);
  //   });
  // }

  initLimitStatus(licenses: Model.Subscription[]) {
    const dsObs = this.bionApi.getDataSources();
    const wfObs = this.bionApi.getWorkflowObjectList();
    const destObs = this.bionApi.getDataStoreObjectList();
    const schedObs = this.bionApi.getScheduleActionPlan();

    forkJoin(dsObs,wfObs,destObs,schedObs).subscribe((result) => {

      // Data
      const ds = result[0];
      const wf = result[1];
      const dest = result[2];
      const sched = result[3];

      const dsCount = ds.length;
      const wfCount = wf.length;
      const destCount = dest.length;
      const schedCount = sched.length;

      if(!licenses || licenses.length === 0) {
        throwError(new Error("No licenses found"));
      }
      if(!licenses[0].Product || licenses[0].Product.length === 0) {
        throwError(new Error("No licenses found"));
      }

      const licenseParams = licenses[0].Product[0].MetaData.Data;
      console.log(licenseParams);

      const dsMax = parseInt(licenseParams['Datasources']);
      const wfMax = parseInt(licenseParams['Workflows']);
      const destMax = parseInt(licenseParams['Cubes']);
      const schedMax = parseInt(licenseParams['Schedules']);

      // Metrics
      const dsPerc = dsCount / dsMax * 100;
      const wfPerc = wfCount / wfMax* 100;
      const destPerc = destCount / destMax* 100;
      const schedPerc = schedCount / schedMax* 100;

      const validFrom = new Date(licenses[0].ValidFrom);
      const validTo = new Date(licenses[0].ValidTo);
      const today = new Date(Date.now());

      const diff = Math.abs(validTo.getTime() - today.getTime());
      var diffDays = Math.ceil(diff / (1000 * 3600 * 24)); 

      this.diffDays = diffDays;

      // Final Objects
      const dsLimit: Limit = {name: "Datasources", max: dsMax, current: dsCount, percentage: dsPerc};
      const wfLimit: Limit = {name: "Workflows", max: wfMax, current: wfCount, percentage: wfPerc};
      const destLimit: Limit = {name: "Cubes", max: destMax, current: destCount, percentage: destPerc};
      const schedLimit: Limit = {name: "Schedules", max: schedMax, current: schedCount, percentage: schedPerc};

      let limitArray: Limit[] = new Array();
      limitArray.push(dsLimit,wfLimit,destLimit,schedLimit);

      
      this.currentLimits = limitArray;
      console.log(this.currentLimits);
      return limitArray
    })
  }

  requestSubscriptionUpgrade(sub: string) {
		// var email = this.appMain.currentUser.userInfo.EMail;
		// var subject = "I want to report a bug";
		// var emailBody = 'Hi ';
		// document.location = "mailto:"+email+"?subject="+subject+"&body="+emailBody;
		const subject = this.translate.instant("I want to upgrade my current subscription to ") + sub;
    const user = this.appMain.currentUserFull.UserInfo.Username;
		const bodyText = this.translate.instant("Hi Bion team, i want to upgrade my current subscription to ") + sub;
    const bodyText2 =  this.translate.instant(". For following user: ") + user;
		const mailText = "mailto:support@bion-analytics.com?subject="+subject+"&body="+bodyText+bodyText2; // add the links to body
		window.location.href = mailText;
	}

}
