<div class="p-grid" style="width:100%; margin-top:10px">
    <div class="p-col-12 p-md-9">
        <div class="p-fluid">
            <span class="p-float-label">
                <input type="text" id="Pathinputtext" pInputText
                    [(ngModel)]="currentConnectorView.connectorSettings.Uri">
                <label for="Pathinputtext">{{'Path' | translate}}</label>
            </span>
        </div>
    </div>
    <div class="p-col-12 p-md-3">
        <div class="p-fluid">
            <span class="p-float-label">
                <button pButton type="button" id="connect" label="{{'Connect' | translate}}" pInputText
                    (click)="uploadFilesAsync($event)"></button>
            </span>
        </div>
    </div>
    <!-- <div class="p-col-12">
        <p-divider layout="horizontal" align="center">
            <span class="p-tag">{{'Choose table' | translate}}</span>
        </p-divider>
    </div> -->

    <div class="p-col-12 p-md-6">
        <div class="p-fluid">
            <span class="p-float-label">
                <input type="text" id="lineBreakText" pInputText
                    [(ngModel)]="currentConnectorView.connectorSettings.LineBreak"
                    value="{{currentConnectorView.connectorSettings.LineBreak}}">
                <label for="lineBreakText">{{'Linebreak' | translate}}</label>
            </span>
        </div>
    </div>

    <div class="p-col-12 p-md-6">
        <div class="p-fluid">
            <span class="p-float-label">
                <input type="text" id="delimiterText" pInputText
                    [(ngModel)]="currentConnectorView.connectorSettings.Separator"
                    value="{{currentConnectorView.connectorSettings.Separator}}">
                <label for="delimiterText">{{'Delimiter' | translate}}</label>
            </span>
        </div>
    </div>

</div>
