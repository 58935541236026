<p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>

<p-dialog header="{{'Delete action' | translate}}" [(visible)]="displayDeleteAction" modal="modal" showEffect="fade"
    [style]="{width: '600px'}">
    <div *ngIf="selectedAction" style="line-height: 1.5">
        {{'DeleteAction?' | translate}} {{selectedAction.id}} ?
    </div>
    <app-system-message-log></app-system-message-log>

    <p-footer>
        <!-- <button type="button" pButton icon="pi pi-times" (click)="displayDeleteAction=false" label="{{'No' | translate}}"
        class="p-button-text"></button>
        <button type="button" pButton icon="pi pi-check" (click)="deleteAction(selectedAction)" label="{{'Yes' | translate}}"
            class="p-button-text"></button> -->

        <button pButton pRipple type="button" icon="pi pi-times" (click)="displayDeleteAction=false"
            class="p-button-rounded p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'No' | translate}}"></button>
        <button pButton pRipple type="button" icon="pi pi-check" (click)="deleteAction(selectedAction)"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'Yes' | translate}}" [icon]="savingInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'"></button>
    </p-footer>
</p-dialog>
