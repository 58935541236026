export class WriteMode {
    name: string;
    value: string;
    description: string;
}

export class GuiDropDown {
    name: string;
    value: string
}


export class NavigationItem {
    name: string;
    content: ContentItem[];
}
 export class ContentItem {
    title: string;
    textarea: TextContent[];
    img?: string;
    link?: string;
 }

 export class TextContent {
    contentHeader?: string;
    contentText: string[];
 }
