import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ConfigResponse } from './powerbi-component/powerbi.component';
import { PowerBiEntry } from '../../models/api/models/powerbi-report/PowerBiEntry';
import { DomSanitizer } from '@angular/platform-browser';
import { PowerBiActionEvent } from 'src/app/models/api/models/powerbi-report/PowerBiActionEvent';


@Injectable({
  providedIn: 'root'
})
export class PowerbiService {
  constructor(private httpClient: HttpClient, private sanitizer: DomSanitizer) {}

  // PowerBiActionEmitter = new EventEmitter<PowerBiActionEvent>();

  // /**
  //  * @returns embed configuration
  //  */
  // getEmbedConfig(endpoint: string): Observable<ConfigResponse> {
	// 	let headers = new HttpHeaders();

	// 	//headers.append("Content-Type", "application/json");
	// 	headers.append("Access-Control-Allow-Headers", "Content-Type");
	// 	headers.append("Accept", "application/json");
  //   headers.append('Access-Control-Allow-Origin','*');

	// 	let options = { headers: headers };
  //   return this.httpClient.get<ConfigResponse>(endpoint,options);
  // }


  // getReports(url?: string): Observable<PowerBiEntry[]> {
  //   const reports = [
  //     {id: 1, title: "Airup_Abweichungsanalyse_Dashboard", url: this.sanitizer.bypassSecurityTrustResourceUrl("https://app.powerbi.com/reportEmbed?reportId=b1f45f2b-a6c6-4bb2-b034-68ff48e9d974&autoAuth=true&embeddedDemo=false&filterPaneEnabled=false&customLayout=displayOption:FitToWidth")},
  //     {id: 2, title: "Dashboard_prototype", url: this.sanitizer.bypassSecurityTrustResourceUrl("https://app.powerbi.com/reportEmbed?reportId=00b38086-b270-4a20-beec-21e6f79e4313&autoAuth=true&ctid=be5e3f90-196b-47fc-a72f-8c6d453e6df7&filterPaneEnabled=false&customLayout=displayOption:FitToWidth")}
  //   ]


  //   return of(reports);
  // }
  // getReportPermissions() {

  // }

  // emitPowerBiAction(evt: PowerBiActionEvent) {
  //   this.PowerBiActionEmitter.emit(evt);
  // }



}
