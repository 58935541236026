<p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>
<p-dialog header="{{header | translate}}" [(visible)]="displayDialog" modal="modal"
    showEffect="fade" [style]="{width: '800px'}">

    <!-- <p-fileUpload mode="basic" name="demo[]"  accept=".json" maxFileSize="1000000" (onUpload)="onBasicUploadAuto($event)" [auto]="true" chooseLabel="Browse"></p-fileUpload> -->

    <p-fileUpload auto="true" multiple="single" showCancelButton="false" customUpload="true" maxFileSize="200000000"
    (onRemove)="onRemoveFile($event)" chooseLabel="{{'Browse' | translate}}" uploadLabel="Preview"
    (uploadHandler)="onBasicUploadAuto($event)" accept=".json">
    <ng-template pTemplate="content">
        <ul>
            <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
        </ul>
    </ng-template>
        </p-fileUpload>
        <app-system-message-log></app-system-message-log>

    <p-footer>
        <button pButton pRipple type="button" icon="pi pi-times" (click)="displayDialog=false"
            class="p-button-rounded p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'Close' | translate}}"></button>
        <button pButton pRipple type="button" icon="pi pi-check" (click)="importWorkflows()"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'Import' | translate}}" [icon]="savingInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-download'"></button>
    </p-footer>
</p-dialog>
