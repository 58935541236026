<div style="z-index: 1000000;">
    <p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
    </p-toast>
</div>
<div class="spinner-container" *ngIf="loading" @fade>
    <app-loading-screen></app-loading-screen>
</div>
<div class="p-grid">
    <span class="p-col-12 p-md-12 p-lg-12">
        <span>
            <app-generic-top-toolbar #dsObjectToolbar [typeClass]="dataSourceToolbarTypeClass"
                [currentView]="'Datasources'" @fade>
            </app-generic-top-toolbar>
            <span style="display: flex; align-items: center; float: right">
                <!-- <app-general-search #dsObjectSearch [typeClass]="dataSourceSearchTypeClass" [currentView]="'DataSources'">
                </app-general-search>
                <span style="color: white;margin:0px 10px">|</span> 
                <span style="display: flex; align-items: center;">
                    <app-generic-object-tasks #dsObjectTasks [currentView]="'DataSources'"></app-generic-object-tasks>
                </span>-->
            </span>
        </span>
    </span>
</div>
<div class="p-grid content-container" id="container" @fade>
    <div class="p-col" style="padding: 0 20px;margin-top: 20px;">
        <div class="card">
            <app-general-object-view [objectList]="datasources" [userDetails]="userDetails" #dsObjectView
                [typeClass]="intDataSourceRecordTypeClass" [currentView]="'Datasources'"></app-general-object-view>
        </div>
    </div>
</div>