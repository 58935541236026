
<app-create-powerbi-report></app-create-powerbi-report>
<app-delete-powerbi-report></app-delete-powerbi-report>
<app-edit-powerbi-report></app-edit-powerbi-report>
<div style="z-index: 1000000;">
    <p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
    </p-toast>
</div>

<div class="spinner-container" *ngIf="loading" @fade>
    <app-loading-screen></app-loading-screen>
</div>

<div class="p-grid">
    <div style="height: 2px; width:100%">
        <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '2px'}"></p-progressBar>
    </div>

    <span class="p-col" style="display: flex; align-items: center;">
        <!-- <app-general-search #pbiObjectSearch [typeClass]="powerBiSearchTypeClass" [currentView]="'PowerBiReports'">
        </app-general-search> -->

        <!-- <span style="color: white;margin:0px 10px">|</span>
        <span style="display: flex; align-items: center;">
            <app-generic-object-tasks #wfObjectTasks [currentView]="'Workflows'"></app-generic-object-tasks>

        </span> -->
    </span>
    <span class="p-col-12 p-md-6 p-lg-3">
        <span style="float:right">

            <app-generic-top-toolbar #pbiObjectToolbar [typeClass]="powerBiToolbarTypeClass" [currentView]="'PowerBiReports'"
                @fade></app-generic-top-toolbar>

        </span>
    </span>
</div>

<div class="p-grid" id="container">
    <div class="p-col" style="padding: 0 20px;margin-top: 20px;">
        <div class="card">
            <app-general-object-view [objectList]="reports_new" [userDetails]="userDetails" #pbiObjectView
                [typeClass]="powerBiViewRecordTypeClass" [currentView]="'PowerBiReports'"></app-general-object-view>
        </div>
    </div>
</div>

<div class="layout-rightpanel" (click)="appMain.rightPanelClick = true">
    <div class="rightpanel-wrapper">
        <p-tabView [scrollable]="false">
            <p-tabPanel header="{{'Overview' | translate }}">
                <ng-template pTemplate="header">
                    <img class="static" style="width: 30px" pTooltip="{{'Activities' | translate }}"
                        tooltipPosition="top" src="assets/layout/images/animatedIcons/auffuhren.png"
                        alt="freya-layout" />
                    <img class="active" style="width: 30px" pTooltip="{{'Activities' | translate }}"
                        tooltipPosition="top" src="assets/layout/images/animatedIcons/auffuhren.gif"
                        alt="freya-layout" />
                </ng-template>
                <div style="margin:20px 0">
                    <h5>
                        {{'Preview' | translate }}<app-info-tooltip></app-info-tooltip>
                    </h5>
                    <span class="card OpenReportButton" (click)="onOpenReport()">{{'Open in new tab' | translate}}</span>

                </div>

                <div *ngIf="selectedReport" style="text-align: center;">
                    <iframe [title]="selectedReport.title" class="report-container" [src]="selectedReport.url" frameborder="0" allowFullScreen="false"></iframe>
                </div>

            </p-tabPanel>
            <p-tabPanel header="{{'Permissions' | translate }}"
                *ngIf="appMain.currentUserFull.UserInfo.IsAdmin === true">
                <ng-template pTemplate="header">
                    <img class="static" style="width: 30px" pTooltip="{{'Permissions' | translate }}"
                        tooltipPosition="top" src="assets/layout/images/animatedIcons/nutzer.png" alt="freya-layout" />
                    <img class="active" style="width: 30px" pTooltip="{{'Permissions' | translate }}"
                        tooltipPosition="top" src="assets/layout/images/animatedIcons/nutzer.gif" alt="freya-layout" />
                </ng-template>
                <app-user-permissions-list view="PowerBiReports"></app-user-permissions-list>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
