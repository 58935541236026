import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Id } from 'src/app/helper/id';
import { FieldInfo, WorkflowNodeSettings } from 'src/app/models/api/com/bion/etl/Workflow';
import { DataTypeInfo } from 'src/app/models/datasource.model';
import { FormulaInfo, FormulaSettings } from 'src/app/models/nodeSettings.model';
// import { FieldInfo, WorkflowNodeSettings } from 'src/app/models/workflow.model';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { DesignerService } from 'src/app/services/designer.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { SubSink } from 'subsink';
import { NodeConfigComponentBase } from '../node-config-component-base';

export class FormulaInfoView {
	NewColumnName: string;
	FormulaString: string;
	NewColumnType: DataTypeInfo;
	hasError?: boolean;

  constructor(NewColumnName: string, FormulaString:  string, NewColumnType: DataTypeInfo, hasError?: boolean) {
    this.NewColumnName = NewColumnName;
    this.FormulaString = FormulaString;
    this.NewColumnType = NewColumnType;
    this.hasError = hasError;
  }
}

@Component({
  selector: 'app-formula-node',
  templateUrl: './formula-node.component.html',
  styleUrls: ['./formula-node.component.scss']
})
export class FormulaNodeComponent 	extends NodeConfigComponentBase<FormulaSettings> implements OnInit, OnDestroy {
	settingsToView(settings: WorkflowNodeSettings): void {
    console.log("SettingsToView");

		// -- Get Configuration
		let currentConfig = <FormulaSettings>settings;
		// -- Get Input Meta Data
		const meta_infos =
			this._currentWorkflowNodeAdapter.IWorkflowNode.Properties.MetaInfo.get(
				this.InputPort
			);

		let columnListArray: string[] = [];

		// create SelectInfoViews from DsFields for table
		if (meta_infos != undefined && meta_infos.length > 0) {
			const input_field_infos = meta_infos[0].FieldsInfo;
			//this.columnList = input_field_infos;

			// -- create string array for dropdown
			columnListArray = input_field_infos.map((fieldInfo) => {
				return fieldInfo.Name;
			});
			this.columnList = columnListArray;
		}

      this.subs.sink = this.getDataTypes().subscribe((dTypes) => {
        const filteredDTypes = dTypes.filter((types) => { return types.Name !== "Number" && types.Name !== "Decimal" && types.Name !== "Bytes"});
        this.dataTypes = filteredDTypes;
        		// --If currentConfig not available (-> new node)
        if (currentConfig === undefined) {
          this.formulaList = [];
        } else {
          // -- otherweise load existing config into view
          if (!currentConfig.FormulaInfos) return;

          currentConfig.FormulaInfos.map((info) => {
            if (columnListArray.includes(info.NewColumnName)) {
              // -- column still exists, do nothing
            } else {
              // column does not exist anymore, meaning column name currently not included -> include
              columnListArray.push(info.NewColumnName);
            }
          });
          this.columnList = columnListArray;
          if(meta_infos !== undefined) {
            this.formulaList = this.infoToView(
              meta_infos[0].FieldsInfo,
              currentConfig
            );
          }
        }

      });




	}
  viewToSettings(): FormulaSettings {
		const newSettings = <FormulaSettings>{
			...this.getCurrentWorkflowNode().Properties.Configuration,
		};
		const filterInfoViews = this.formulaList;
		const filterInfos = this.viewToInfo(filterInfoViews);

		newSettings.FormulaInfos = filterInfos;

		return newSettings;
  }
  onSettingsChanged(settings: FormulaSettings): void {
    throw new Error('Method not implemented.');
  }

	constructor(
		protected workflowService: WorkflowsService,
		protected designerService: DesignerService,
    protected bionApi: ApiBackendService,
	) {
		super(workflowService, designerService);

  }

	ngOnInit(): void {
		super.ngOnInit();
	}
  subs = new SubSink;
	readonly InputPort = "Input";
  columnList: any[] = [];
  formulaList: any[] = [];
  dataTypes: DataTypeInfo[] = [];

  infoToView(list: FieldInfo[], config: FormulaSettings): FormulaInfoView[] {

    let views = config.FormulaInfos.map((info) => {
      //let view = new FormulaInfoView();
      //view.NewColumnName = info.NewColumnName;
      //view.NewColumnType = this.dataTypes.find((dType) => { return dType.Name === info.NewColumnType});
      //view.FormulaString = info.FormulaString;

      const NewColumnType = this.dataTypes.find((dType) => { return dType.Name === info.NewColumnType});
      const new_col_type = Id.assertSet(NewColumnType, new Error("No Column data type found, should not happen!"));

      const hasError = !list.find((field) => {
          return field.Name === info.NewColumnName
      });



      let view = new FormulaInfoView(info.NewColumnName,info.FormulaString,new_col_type,hasError);

      return view
    });

    return views
  }
  viewToInfo(views: FormulaInfoView[]): FormulaInfo[] {
    let infos = views.map((view) => {
      let info = new FormulaInfo(view.NewColumnName,view.FormulaString,view.NewColumnType.Name);
      return info
    })

    return infos
  }

  getDataTypes(): Observable<DataTypeInfo[]> {
    return this.bionApi.getDataTypes();
  }
	onAddRow() {
		// -- create new filterInfo view
		let newFilter = new FormulaInfoView("","",this.dataTypes[0],false);

		// newFilter.NewColumnName = "";
		// newFilter.NewColumnType = this.dataTypes[0];
		// newFilter.FormulaString = "";
		// newFilter.hasError = false;
		// -- push new filterInfoView to FilterArray
		this.formulaList.push(newFilter);

		this.onUpdateSettings(false);
	}
	onDeleteEntry(index: number) {
		this.formulaList = [
			...this.formulaList.slice(0, index),
			...this.formulaList.slice(index + 1),
		];
		this.onUpdateSettings(false);
	}

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }
  onFocusLost(event: FocusEvent) {
    const div_left: boolean = (event.relatedTarget === null);

    console.log("Send from Div: ", div_left);

    if (div_left) {
        //this.onCheckDirtyFlag();
        this.onUpdateSettings(true);
    }
}

}

