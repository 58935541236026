import { Observable } from "rxjs";
import { WorkflowNode } from "src/app/models/api/com/bion/etl/Workflow";
import { WorkflowRepositoryEntry } from "src/app/models/api/models/workflow/WorkflowRepositoryEntry";
import { DataSource } from "src/app/models/datasource.model";
import { DataStore } from "src/app/models/datastore.model";
import { ScheduleActionPlan, ScheduleBaseAction } from "src/app/models/schedule.model";
import { DataTablePreview } from "src/app/models/staging.model";

/**
 * Preview Data Information.
 * @tparam DH The Data Holder Type, e.g. PSA in case of Data Source.
 */
export interface PreviewData<DH> {
    DataTablePreview: DataTablePreview;
    DataHolder?: DH;
}

/**
 * Typklasse für etwas, dass auf der Bottom Toolbar angezeigt werden kann.
 * @param T Objekttyp
 * @param DH Objekt, dass die Daten hält (Data Holder). Das läuft manchmal auseinander, z.b. Data Source & PSA
 * @param BTR Build Toolbar Result Typ
 * @param BTA Build Toolbar Argument Typ
 */
export interface BottomToolbarProvider<T, DH, BTR extends ToolbarResult, BTA> {
    /**
     * Liefert die Daten um die Toolbar zu bauen.
     */
    getData(): Observable<BTA>;
    subscribeToEmitter(): Observable<T>;
    buildToolbar(data: BTA, select?: T): BTR;
    /**
     * Liefert die Vorschaudaten im Fall von PSA und Data Store. Andernfalls einen NoDataProviderError.
     * @param obj Das Objekt, dass die Daten hält.
     */
    getPsaData(obj: T): Observable<PreviewData<DH>>;
}

/**
 * Exception which states the Provider does not provide any record data at all, e.g. Workflow.
 */
export class NoDataProviderError extends Error {
    constructor(message: string) {
        super(message);
    }
}

/**
 * Alle Eigenschaften, die ein Toolbar Result haben kann.
 * Nicht alle Results haben alle Eigenschaften. Diese liefern normalerweise dann eine leere Liste.
 */
export interface ToolbarResult {
    ScheduleActionPlans: ScheduleActionPlan[];
    Workflows: WorkflowRepositoryEntry[];

    PsaInNodes: WorkflowNode[];
    DataStoreOutNodes: WorkflowNode[];

    WorkflowNodes: WorkflowNode[];
    ScheduleActions: ScheduleBaseAction[];

    DataSources: DataSource[];
    DataStores: DataStore[];

    Matches: boolean[];
}

export class ToolbarHelper {
    static empty(): ToolbarResult {
        const result: ToolbarResult = {
            ScheduleActionPlans: [],
            Workflows: [], PsaInNodes: [],
            DataStoreOutNodes: [],
            WorkflowNodes: [],
            ScheduleActions: [],
            DataSources: [],
            DataStores: [], Matches: []
        };

        return result;
    }
}