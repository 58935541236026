import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { SapAccess } from "../api/com/bion/connect/sap/SapAccess";
import { SapConfig } from "../api/com/bion/connect/sap/SapConfig";
import { SapMetaAccess } from "../api/com/bion/connect/sap/SapMetaAccess";
import { SapMetaInfo } from "../api/com/bion/connect/sap/SapMetaInfo";
import { DynamicItemInfo } from "../com-bion-json";
import { ExtractMetaFromConnectorArg } from "../connector.model";
import { ConnectorSettingsBuilder, ConnectorViewBase, PureConnectorSettingsBuilder } from "../connectorView.model";


export class SapConnectorView extends ConnectorViewBase<SapAccess, SapMetaInfo, SapMetaAccess>{

    constructor(api: ApiBackendService, connectorID: string) {
		super(api, connectorID);
		this.metaInfo = new SapMetaInfo(false);
        this.metaAccess = new SapMetaAccess(new SapConfig("","","","","",""));
	}

    //url:string;

    fetchMetaDataObs(): Observable<SapMetaInfo> {


        const con_settings = {...this.connectorSettings.Configuration };

        const meta_access = new SapMetaAccess(con_settings, this.connectorSettings.Table);  // Set the table only, if you need the field info


        const dynamic_meta_access_info = new DynamicItemInfo(this.connectorID, meta_access);
        const arg = new ExtractMetaFromConnectorArg();
        arg.ConnectorMetaInfo = dynamic_meta_access_info;
        return this.api.api_extractMetaFromConnector(arg).pipe(map((extractRes) => {
            const meta_data = <SapMetaInfo>extractRes.MetaData;

            return meta_data;
        }));
    }
    getSettingsBuilder(): Observable<ConnectorSettingsBuilder<SapAccess>> {
        const connector_settings = {...this.connectorSettings};
        const builder = new SapSettingsBuilder(this.connectorID, connector_settings);
        return of(builder);
    }
    uploadFile(file: File): void {
        throw new Error("Method not implemented.");
    }
    getDataSourceNameSuggestion(): string {
        return this.connectorID;
    }
    isFileBased(): boolean {
        return false;
    }
    getBase64FileData(): string {
        return "";
    }
    setInitialSettings(settings: SapAccess): void {
        super.setInitialSettings(settings);
        console.log("Initial Settings");

    }
    setInitialMetaSettings(settings: SapMetaAccess): void{
        super.setInitialMetaSettings(settings);
        console.log("Initial Meta Settings");
    }

    getPreviewBuilder(limit:number,offset:number): Observable<ConnectorSettingsBuilder<SapAccess>> {
        const connector_settings = {...this.connectorSettings};
        connector_settings.Limit = limit;
        connector_settings.Offset = offset;
        const builder = new PureConnectorSettingsBuilder<SapAccess>(this.connectorID, connector_settings);
        return of(builder);
        // const connector_settings = {...this.connectorSettings};
        // connector_settings.
        // const builder = new SapSettingsBuilder(this.connectorID, connector_settings);
        // return of(builder);
    }

}

export class SapSettingsBuilder implements ConnectorSettingsBuilder<SapAccess> {

    constructor(connectorID: string, access:SapAccess) {
        this.connectorID = connectorID;
        this.access = access;
    }

    connectorID:string;
    access:SapAccess;

    getConnectorId(): string {
        return this.connectorID;
    }
    getConnectorSettings() {
        return {...this.access};
    }
}

// export class SapPreviewBuilder implements ConnectorSettingsBuilder<SapAccess> {

//     constructor(connectorID: string, access:SapAccess, limit:number,offset:number) {
//         this.connectorID = connectorID;
//         this.access = access;
//         this.limit = limit;
//         this.offset = offset;
//     }

//     connectorID:string;
//     access:SapAccess;
//     readonly limit:number;
//     readonly offset:number;

//     getConnectorId(): string {
//         return this.connectorID;
//     }
//     getConnectorSettings() {
//         return {...this.access};
//     }
// }
