<div class="card">
	<div class="card-header">
		<div class="card-title">
			<h5>My Invoices
				<!-- <p-badge [value]="invoices"></p-badge> -->
			</h5>
			<!-- <p class="subtitle">{{columnCount}} columns | {{rowCount}}
				rows</p> -->
		</div>
		<p *ngIf="invoices" class="subtitle">
			{{invoices.length}} invoices
		</p>
	</div>
	<div class="p-grid">
		<div class="p-col-12">
				<p-table #dtc [value]="invoices" [(selection)]="selectedInvoice" dataKey="id" selectionMode="single"
						styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
						[scrollable]="true" scrollHeight="140px" [filterDelay]="0" [globalFilterFields]="['name','country.name','representative.name','status']">
					<ng-template pTemplate="caption">
						<div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
							<span class="p-input-icon-left">
						<i class="pi pi-search"></i>
						<input pInputText type="text" (input)="dtc.filterGlobal($event.target.value, 'contains')"
							placeholder="Global Search"/>
					</span>
						</div>
					</ng-template>
					<ng-template pTemplate="header">
						<tr>
							<th pSortableColumn="name">Invoice
								<p-sortIcon field="name"></p-sortIcon>
							</th>
							<th pSortableColumn="representative.name">Subscription
								<p-sortIcon field="representative.name"></p-sortIcon>
							</th>
							<th pSortableColumn="date">Date
								<p-sortIcon field="date"></p-sortIcon>
							</th>
							<th pSortableColumn="status">Status
								<p-sortIcon field="status"></p-sortIcon>
							</th>

						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-customer>
						<tr [pSelectableRow]="customer">
							<td>
								<span class="p-column-title">Invoice ID</span>
								{{customer.name}}
							</td>
							<td>
								<span class="p-column-title">Subscription</span>
								<img [alt]="customer.representative.name"
									src="assets/demo/images/avatar/{{customer.representative.image}}" width="32"
									style="vertical-align: middle"/>
								<span class="image-text" style="margin-left: .5em;vertical-align: middle">{{customer.representative.name}}</span>
							</td>
							<td>
								<span class="p-column-title">Date</span>
								{{customer.date}}
							</td>
							<td>
								<span class="p-column-title">Status</span>
								<span [class]="'customer-badge status-' + customer.status">{{customer.status}}</span>
							</td>
						</tr>
					</ng-template>
					<ng-template pTemplate="emptymessage">
						<tr>
							<td colspan="8">No invoices found.</td>
						</tr>
					</ng-template>
				</p-table>
			
		</div>
	</div>

</div>