<p-overlayPanel #op1 [showCloseIcon]="false">
    <ng-template pTemplate>
        <div style="float: left">
            <button pButton pRipple type="button" icon="pi pi-info-circle" class="p-button-rounded p-button-text p-mr-2 p-mb-2"
                pTooltip="More info" (click)="displayNodeInfo=true"></button>
            <!-- <button pButton pRipple type="button" icon="pi pi-download"
                class="p-button-rounded p-button-text p-button-warning p-mr-2 p-mb-2" pTooltip="Export data"
                ></button> -->
        </div>

    </ng-template>
</p-overlayPanel>

<p-dialog header="Details" [(visible)]="displayNodeInfo" modal="modal" showEffect="fade"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '65vw'}" (onHide)="resetCurrentForm()">
    <app-node-info [selectedNode]="selectedNode"></app-node-info>
</p-dialog>

<div style="z-index: 100000;float: left;" class="p-col-12">
    <!-- <div class="p-d-flex p-flex-column p-flex-md-row">
        <span class="p-input-icon-left p-mb-2 p-mb-md-0">
            <i class="pi pi-search"></i>
            <input type="search" pInputText placeholder="Search node" (input)="nodesList.filter($event.target.value)">
        </span>
        <p-selectButton [options]="nodeCategorySelectionOptions" [(ngModel)]="nodeCategorySelection" [multiple]="true"
            optionLabel="name" optionValue="value" (onChange)="filterNodes($event)"></p-selectButton>
    </div> -->

    <p-carousel [showNavigators]="true" [showIndicators]="true" #nodesList [value]="plugInInfos" [numVisible]="16" [numScroll]="6" [circular]="false" [responsiveOptions]="responsiveOptions">
        <ng-template pTemplate="header">
            <p-selectButton [options]="nodeCategorySelectionOptions" [(ngModel)]="nodeCategorySelection" [multiple]="true"
            optionLabel="name" optionValue="value" (onChange)="filterNodes($event)"></p-selectButton>
        </ng-template>
        <ng-template let-node pTemplate="item">
            <div class="product-item">
                <div class="product-item-content">
                    <div class="p-mb-3" pDraggable="plugInInfos" (click)="openNodeMenu($event, node)" (onDragStart)="dragStart($event,node)"
                    (onDragEnd)="dragEnd($event)">
                        <img [src]="'assets/layout/images/nodes/' + node.NameLabel + '.png'" class="product-image"
                            [alt]="node.NameLabel" />
                        </div>
                    <div>
                        <p class="p-mb-1 product-name">{{node.NameLabel}}</p>
                        <!-- <h6 class="p-mt-0 p-mb-3">${{product.price}}</h6>
                        <span [class]="'product-badge status-'+product.inventoryStatus.toLowerCase()">{{product.inventoryStatus}}</span> -->
                        <!-- <div class="car-buttons p-mt-5">
                            <p-button type="button" styleClass="p-button p-button-rounded p-mr-2" icon="pi pi-search"></p-button>
                            <p-button type="button" styleClass="p-button-success p-button-rounded p-mr-2" icon="pi pi-star-fill"></p-button>
                            <p-button type="button" styleClass="p-button-help p-button-rounded" icon="pi pi-cog"></p-button>
                        </div> -->
                    </div>
                </div>
            </div>
        </ng-template>
    </p-carousel>

    <!-- <p-scrollPanel [style]="{height: '70px'}">
        <p-dataView #nodesList [value]="plugInInfos" [paginator]="false" [rows]="1" filterBy="NameLabel, Category"
             layout="grid">
            <ng-template let-node pTemplate="listItem">
                <div class="p-col-12">
                    <div class="product-list-item">
                        <img [src]="'assets/layout/images/nodes/' + node.NameLabel + '.png'"
                            [alt]="node.NameLabel" />
                        <div class="product-list-detail">
                            <p class="product-name">{{node.NameLabel}}</p>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template let-node pTemplate="gridItem" >
                <div class="p-col-3 p-md-2 p-lg-1">
                    <div #nodeitem class="product-grid-item" pDraggable="plugInInfos" (click)="openNodeMenu($event, node)" (onDragStart)="dragStart($event,node)"
                        (onDragEnd)="dragEnd($event)">
                        <div class="product-grid-item-content">
                            <img [src]="'assets/layout/images/nodes/' + node.NameLabel + '.png'"
                                [alt]="node.NameLabel" />
                            <div class="product-name">{{node.NameLabel}}</div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-dataView>

        <p-scrollTop target="parent" styleClass="custom-scrolltop" [threshold]="100" icon="pi pi-arrow-up">
        </p-scrollTop>
    </p-scrollPanel> -->

</div>
