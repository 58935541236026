export class RFC_DB_FLD {
    FIELDNAME: string;
    OFFSET: number;
    LENGTH: number;
    TYPE: string;
    FIELDTEXT: string;
    constructor(FIELDNAME: string,OFFSET: number,LENGTH: number,TYPE: string,FIELDTEXT: string) {
        if(FIELDNAME === undefined) throw new Error( "Class 'RFC_DB_FLD': Required value 'FIELDNAME' is undefined" );
        if(OFFSET === undefined) throw new Error( "Class 'RFC_DB_FLD': Required value 'OFFSET' is undefined" );
        if(LENGTH === undefined) throw new Error( "Class 'RFC_DB_FLD': Required value 'LENGTH' is undefined" );
        if(TYPE === undefined) throw new Error( "Class 'RFC_DB_FLD': Required value 'TYPE' is undefined" );
        if(FIELDTEXT === undefined) throw new Error( "Class 'RFC_DB_FLD': Required value 'FIELDTEXT' is undefined" );
        this.FIELDNAME = FIELDNAME;
        this.OFFSET = OFFSET;
        this.LENGTH = LENGTH;
        this.TYPE = TYPE;
        this.FIELDTEXT = FIELDTEXT;
    }
}

export class SapMetaInfo {
    Table?: string;
    Fields?: Array<RFC_DB_FLD>;
    IsOversize: boolean;
    constructor(IsOversize: boolean,Table?: string,Fields?: Array<RFC_DB_FLD>) {
        if(IsOversize === undefined) throw new Error( "Class 'SapMetaInfo': Required value 'IsOversize' is undefined" );
        this.Table = Table;
        this.Fields = Fields;
        this.IsOversize = IsOversize;
    }
}