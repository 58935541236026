import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import kpiResultjson from "src/assets/test/dashboard-kpi-result.json";

export interface DashboardKPIArg {
    Categories:string[];
    KPIs:string[];
    CurrentPeriod:TimeId;
    IntervalType:IntervalType;
    IntervalAbsolute?:AbsoluteInterval;
    IntervalRelativeOffset?:RelativeInterval;
}

export interface ValueInfo {
    value:number;
    valueUnit:string;
    valueType:string;
}

export interface DimensionInfo {
    //TBD
    time:TimeId;
}

export interface KpiEntry {
    value:ValueInfo;
    dimension:DimensionInfo;
    offset:number;
}

export interface KPI {
    img:string;
    title:string;
    category:string;
    entries:KpiEntry[];
}

export interface DashboardKpiResult {
    KPIs:KPI[];
}

export enum IntervalType {
    Absolute,
    Relative
}

export interface Interval {
    getPeriods();
}

export interface AbsoluteInterval {
    start:string;
    end:string;
}

export interface RelativeInterval {
    offset:number;
}

export enum TimeLevel {
    Jahr,
    Halbjahr,
    Quartal,
    Monat,
    Woche,
    Tag
}

/**
 * Identidifziert eindeutig ein Jahr, Monat, Quartal, ...
 */
export interface TimeId {
    Year : number;
    HalfYear? : number;
    Quarter? : number;
    Month? : number;
    Week? : number;
    Day? : number;
}

export enum Zeitscheibe {
    Woche,
    Monat,
    Quartal,
    Jahr
}

@Injectable({
    providedIn: "root",
})
export class DashboardDummyService {


    static buildDashboardArg(categories: string[], kpis: string[], zeitscheibe:Zeitscheibe): DashboardKPIArg {

        let currentPeriod: TimeId;
        const today = new Date(Date.now());
        const currentYear = today.getFullYear();

        // Szenario: Ich will ab heute alle KPIs des aktuellen und Vormonats (Monatsscheiben)
        switch(zeitscheibe) {
            case Zeitscheibe.Woche: {
                // Get the current week number
                const weekNumber = Math.ceil((today.getDate() + 6 - today.getDay()) / 7);                
                currentPeriod = this.makeWeek(weekNumber,currentYear);
                break
            }
            case Zeitscheibe.Monat: {
                // Get the current month number
                const monthNumber = today.getMonth() + 1;

                currentPeriod = this.makeMonth(monthNumber,currentYear);
                break
            }
            case Zeitscheibe.Quartal: {
                // Get the current quarter number
                const monthNumber = today.getMonth() + 1;
                const quarterNumber = Math.ceil((monthNumber / 3));
                currentPeriod = this.makeQuarter(quarterNumber,currentYear);
                break
            }
            case Zeitscheibe.Jahr: {
                currentPeriod = this.makeYear(currentYear);
                break
            }
            
        }
    
        const arg: DashboardKPIArg = {
            Categories: categories,
            KPIs: kpis,
            CurrentPeriod : currentPeriod,
            IntervalType : IntervalType.Relative,
            IntervalRelativeOffset : {
                offset: -1
            }
        }

        return arg

    }

    getKpisFromBE(arg: DashboardKPIArg): Observable<DashboardKpiResult> {
        // Here comes the real BE code

        return of(kpiResultjson)
    }

    static makeWeek(value:number,year:number) : TimeId {
        return { Year:year, Month:value }
    }    
    static makeMonth(value:number,year:number) : TimeId {
        return { Year:year, Month:value }
    }
    static makeQuarter(value:number, year:number) : TimeId {
        const tf:TimeId = { Year:value, Quarter:value };
        return tf;
    }
    static makeYear(value:number) : TimeId {
        const tf:TimeId = { Year:value };
        return tf;
    }

}