import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Message } from "primeng/api";
import { MessageService } from "primeng/api";
import { PersistentStagingArea } from "src/app/models/datasource.model";
import { WriteMode } from "src/app/models/helperClass.model";
import { SubSink } from "subsink";

export enum StatusEnum {
	successful,
	failed,
	notStartedYet,
}

export class GuIStatus {
	loading: boolean;
	source: string;
	text: string;
	status: number;
	message?: Message;

	constructor(
		loading: boolean,
		text: string,
		source: string,
		status: number,
		message?: Message
	) {
		this.loading = loading;
		this.text = text;
		this.source = source;
		this.status = status;
		this.message = message;
	}
}

@Component({
	selector: "app-populate-psa",
	templateUrl: "./populate-psa.component.html",
	styleUrls: ["./populate-psa.component.scss"],
})
export class PopulatePsaComponent implements OnInit {
	subs = new SubSink;
	@Input() psaInfos!: PersistentStagingArea[];
	@Input() isNewDataSource!: boolean;
	writeMode: WriteMode;
	status: GuIStatus[] = [];
	@Output() loadPsaEmitter = new EventEmitter<string>();
	@Output() closeDialogEmitter = new EventEmitter<string>();

	dataSourceStatus: GuIStatus;
	dataPoolStatus: GuIStatus;

	constructor(private messageService: MessageService) {}

	ngOnInit(): void {
		this.initStatus();
		this.subs.sink = this.messageService.messageObserver.subscribe((res: Message) => {
			this.initStatus(res);
		});
	}
	initStatus(message?: Message) {
		let guiStatusArray:any[] = [];

		if(this.isNewDataSource) {
			guiStatusArray = new Array(
				// new GuIStatus(
				// 	true,
				// 	"CurrentlyCreatingDatasource",
				// 	"Datasource",
				// 	StatusEnum.notStartedYet,
				// 	message
				// ),
				new GuIStatus(
					false,
					"LoadData?",
					"datapool",
					StatusEnum.notStartedYet,
					message
				)
			);
			this.dataSourceStatus = guiStatusArray[0];
			this.dataPoolStatus = guiStatusArray[1];
		}
		if(!this.isNewDataSource) {
			guiStatusArray = new Array(
				new GuIStatus(
					false,
					"LoadData?",
					"datapool",
					StatusEnum.notStartedYet,
					message
				)
			);
			this.dataPoolStatus = guiStatusArray[0];
		}

		this.setStatus(guiStatusArray,message);
	}
	setStatus(status: GuIStatus[],message?: Message) {
		let newStatusArray = status;
		// Check if message exists -> if yes, check for new status
		if (message !== undefined) {
			// Get status to be updated
			let stat2Update: GuIStatus = {
				...status.find((stat: GuIStatus) => {
					return message.summary.includes(stat.source);
				}),
			};

			console.log("stat2Update", stat2Update);

			if (!stat2Update.message) {
				return;
			}

			if (stat2Update.message.severity) {
				let severity = stat2Update.message.severity;

				if (severity === undefined) {
					stat2Update.loading = false;
					stat2Update.status = StatusEnum.notStartedYet;
				}
				if (severity === "success") {
					stat2Update.loading = false;
					stat2Update.status = StatusEnum.successful;
				}
				if (severity === "warn") {
					stat2Update.loading = false;
					stat2Update.status = StatusEnum.failed;
				}
			}

			// Update current UI message
			if (stat2Update.message.summary) {
				let summary = stat2Update.message.summary;

				if (summary.includes(stat2Update.source)) {
					stat2Update.text = stat2Update.message.summary;
				} else {
					//Do nothing
				}
			}

			// Write result back to Array
			newStatusArray = status.map((stat) => {
				if (stat.source === stat2Update.source) {
					stat = stat2Update;
				}
				return stat;
			});
		}

		this.status = newStatusArray;
	}
	emitLoadPsa() {
		this.loadPsaEmitter.emit("Load");
	}
	emitCloseDialog() {
		this.closeDialogEmitter.emit("Close");
	}
	isValid(): boolean {
		return this.psaInfos !== undefined && this.psaInfos.length > 0;
	}
}
