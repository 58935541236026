import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { DynamicItemInfo } from "../../com-bion-json";
import { ExtractMetaFromConnectorArg } from "../../connector.model";
import { ConnectorSettingsBuilder, ConnectorViewBase } from "../../connectorView.model";
import { Catalog, SingerAccess, SingerMetaAccess, SingerMetaInfo } from "./postgre-connector-view.model";

/**
 * Extend this class for your individual JDBC Connector View Model
 * @type T JDBC Connection Type
 */
export abstract class MeltanooConnectorViewBase<T>
    extends ConnectorViewBase<SingerAccess<T>, SingerMetaInfo, SingerMetaAccess<T>> {

    constructor(api: ApiBackendService, connectorID: string) {
        super(api, connectorID);
        this.metaInfo = new SingerMetaInfo(new Catalog([]));
    }

    fetchMetaDataObs(): Observable<SingerMetaInfo> {

        // == Build Meta Argument fron Connection Argument
        const con_settings = { ...this.connectorSettings.Configuration };


        // Note: In Meta Arg, the table parameter is optional IN CONTRAST to the connector settings where it is mandatory!
        //       If given, the backend tries to find the field information and raises an error if the table doesn't exist,
        //       therefore we check the tables existence.

        //const table = this.metaInfo.Tables.find(t => t == this.connectorSettings.Table);
        const meta_access = new SingerMetaAccess(con_settings);  // Set the table only, if you need the field info

        let extract_meta_arg = new ExtractMetaFromConnectorArg;
        let connector_meta_info = new DynamicItemInfo(this.connectorID, meta_access);
        extract_meta_arg.ConnectorMetaInfo = connector_meta_info;

        return this.api.api_extractMetaFromConnector(extract_meta_arg).pipe(map((extractRes) => {
            const typed_meta_data = <SingerMetaInfo>extractRes.MetaData;
            this.metaInfo = typed_meta_data;
            return typed_meta_data;
        }));
    }
    getBase64FileData(): string {
        return '';
    }
    getDataSourceNameSuggestion(): string {
        return this.connectorSettings.Stream.toString();
    }
    uploadFile(file: File) {
        throw new Error('JDBC does not support file upload');
    }
    getSettingsBuilder(): Observable<ConnectorSettingsBuilder<SingerAccess<T>>> {

        let jdbcSettings = new MeltanooSettingsBuilder(this.connectorID, this.connectorSettings.Configuration, this.connectorSettings.Stream);

        return of(jdbcSettings);
    }
    isFileBased(): boolean {
        return false;
    }
}

export class MeltanooSettingsBuilder<T> implements ConnectorSettingsBuilder<SingerAccess<T>> {

    constructor(connectorId: string, settings: T, stream: string) {

        this.connectorId = connectorId;
        this.settings = settings;
        this.stream = stream;
    }
    connectorId: string;
    settings: T;
    stream: string;


    getConnectorId(): string {
        return this.connectorId;
    }
    getConnectorSettings() {
        const settings = new SingerAccess(this.settings, this.stream);
        return settings;
    }
}