<p-overlayPanel #op1 [showCloseIcon]="false">
    <ng-template pTemplate>
        <div class="p-grid" style="float: left">
            <div class="p-col-3">
                {{'Object' | translate}}:
            </div>
            <div class="p-col-7">
                {{selectedInfo.obj.name}} (ID: {{selectedInfo.obj.id}})
            </div>
            <div class="p-col-3">
                {{'Description' | translate}}:
            </div>
            <div class="p-col-7">
                {{selectedInfo.obj.description}}
            </div>
            <!-- <div class="p-col-3">
                Length:
            </div>
            <div class="p-col-7">
                {{selectedInfo.fieldInfo.length}}
            </div> -->
        </div>
    </ng-template>
</p-overlayPanel>

<div style="margin:20px 0">
    <h5>
        {{'Fields' | translate }} <app-info-tooltip></app-info-tooltip>
    </h5>
</div>


<div class="card">
    <p-table #dtc [value]="objectTreeNodes" rowGroupMode="subheader" groupRowsBy="data" dataKey="label" styleClass="p-datatable-customers p-datatable-sm" [rows]="10"
    paginator=true [filterDelay]="0"
    [globalFilterFields]="['fieldInfo','fieldInfo.name','fieldInfo.isKey','obj.name','obj.id']">
    <ng-template pTemplate="header" let-field>
        <tr>
            <th style="width: 4rem"></th>
            <th style="width: 80%;">
                <div class="p-d-flex p-jc-between p-ai-center">
                    {{'Object' | translate}}
                    <p-columnFilter type="text" field="data.name" display="menu" class="p-ml-auto">
                    </p-columnFilter>
                </div>
            </th>
            <th style="width: 10%;" >
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="groupheader" let-object let-rowIndex="rowIndex" let-expanded="expanded">
        <tr>
            <td colspan="1">
                <button type="button" pButton pRipple [pRowToggler]="object" class="p-button-text p-button-rounded p-button-plain mr-2" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            </td>
            <td colspan="1">
                <span [class]="'customer-badge status-' + object.label">{{object.label}}</span>
            </td>
            <td colspan="1" style="text-align: right">
                <span>{{'Total' | translate}} {{object.children.length}}</span>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-object let-expanded="expanded" let-ri="rowIndex">
        <tr>
            <td colspan="3">
                <p-table #dt [value]="object.children" sortField="data" sortMode="single" dataKey="data.id">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width:80%">{{'Field' | translate}}</th>
                            <th style="width:20%"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-field  let-index="rowIndex">
                        <tr (click)="op1.toggle($event)" (click)="onMenuClicked(field)">
                            <!-- <td style="width: 100%;">
                                <span class="p-column-title">{{'Object' | translate}}</span>
                                <span>{{field.obj.name}} (ID: {{field.obj.id}})</span>
                            </td> -->
                            <td style="width: 80%;">
                                <span class="p-column-title">{{'Field' | translate}}</span>
                                <p-chip [label]="field.data.fieldInfo.name" icon="pi pi-table"></p-chip>
                                 <!-- <span  *ngIf="field.data.fieldInfo.isKey === true">
                                    <p-avatar pTooltip="{{'identifier' | translate}}" icon="pi pi-key" styleClass="mr-2"  [style]="{'background-color':'#2196F3', 'color': '#ffffff'}" shape="circle"></p-avatar>
                                 </span> -->
                            </td>
                
                            <td style="width: 20%;">
                                <span class="p-column-title"></span>
                                <span  *ngIf="field.data.fieldInfo.isKey === true">
                                    <p-avatar pTooltip="{{'identifier' | translate}}" icon="pi pi-key" styleClass="mr-2"  [style]="{'background-color':'#2196F3', 'color': '#ffffff'}" shape="circle"></p-avatar>
                                 </span>                            
                                </td>
                        </tr>
                    </ng-template>
        
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="2">{{'NoEntryFound' | translate}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </td>
        
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="3">{{'NoEntryFound' | translate}}</td>
        </tr>
    </ng-template>
</p-table>

</div>
