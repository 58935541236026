import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { of, throwError } from "rxjs";
import { concatMap } from "rxjs/operators";
import { ScheduleActionEvent, ScheduleDialogActionType } from "src/app/models/dialog-actions.model";
import { ScheduleActionPlan } from "src/app/models/schedule.model";
import { SchedulesService } from "src/app/services/schedules.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { SubSink } from "subsink";

@Component({
	selector: "app-edit-schedule-dialog",
	templateUrl: "./edit-schedule-dialog.component.html",
	styleUrls: ["./edit-schedule-dialog.component.scss"],
	providers: [MessageService]
})
export class EditScheduleDialogComponent implements OnInit, OnDestroy {
	subs = new SubSink();
	displayEditSchedule: boolean = false;
	savingInProgress: boolean = false;
	@Input() currentSelectedSchedule?: ScheduleActionPlan;

	scheduleName: string = "";
	scheduleDescription?: string;
	isActive: boolean = false;

	constructor(
		private schedulesService: SchedulesService,
		private systemLogService: SystemMessageLogService, private messageService: MessageService, private translate: TranslateService,
	) { }
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngOnInit(): void {
		this.subs.sink = this.schedulesService.scheduleDialogActionSendEmitter.subscribe((res: ScheduleActionEvent) => {
			if (res.actionType == ScheduleDialogActionType.editSchedule) {
				this.displayEditSchedule = res.display;
				this.currentSelectedSchedule = res.schedule;

				const schedule = res.schedule;
				if (schedule) {
					this.scheduleName = schedule.name;
					this.scheduleDescription = schedule.description;
					this.isActive = schedule.isActive;
				}
			}
		}, (error: Error) => {
			this.systemLogService.handleError(error);
		})
	}

	updateSchedule() {
		this.savingInProgress = true;

		const schedule_obs = this.currentSelectedSchedule ? of(this.currentSelectedSchedule) : throwError(new Error("No Scheduler selected"));

		const schedule_to_update = schedule_obs.pipe(concatMap(currentSelectedSchedule => {
			const new_schedule = { ...currentSelectedSchedule };
			new_schedule.name = this.scheduleName;
			new_schedule.description = this.scheduleDescription;
			new_schedule.isActive = this.isActive;

			return this.schedulesService.updateScheduleActionPlan(new_schedule);
		}));

		this.subs.sink = schedule_to_update.subscribe(
			(updated_plan) => {
				this.schedulesService.schedulesChangedEmitter.emit("Schedule updated");

				this.messageService.add({
					severity: "success",
					summary: this.translate.instant("Message.UpdateScheduleSuccess.Title"),
					detail: this.translate.instant("Message.UpdateScheduleSuccess.Text1") + updated_plan.id +
						this.translate.instant("Message.UpdateScheduleSuccess.Text2"),
				});
				this.resetForms();
				this.displayEditSchedule = false;
			},
			(err: Error) => {
				this.systemLogService.handleError(err);
			}, () => {
				this.savingInProgress = false;
			}
		);
	}

	resetForms() {
		this.scheduleName = "";
		this.scheduleDescription = undefined;
		this.isActive = true;
	}
}
