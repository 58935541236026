import { from } from "rxjs";
import { Observable } from "rxjs";


export class AsyncFileReader {

    constructor() { }

    readAsync(file:File) : Observable<string> {

        let reader = new FileReader();
        reader.readAsDataURL(file);

        let obs = new Observable<string>((observer) => {
            reader.onload = () => {
                    // Collect Base64 File Info
                let base64Raw = reader.result as string;
                let base64FileData = base64Raw.split(",")[1];

                observer.next(base64FileData)
                observer.complete();
            }
            reader.onerror= (error): void => {
                observer.error(error);
            }
        });
        return obs;
    }
  }

export class FileTools {
    static fileToBase64(file:File)  {
        let reader = new FileReader();
        reader.readAsDataURL(file);

        return reader.onload = () => {
            const base64Raw = reader.result as string;
            const base64Arr = base64Raw.split(",")[1];
            return base64Arr;
        }
    }

    static readFileAsync(file:File) {
        const reader = new FileReader();
        reader.readAsDataURL(file);


        const promise = new Promise<string>((resolve, reject) => {
            reader.onload = () => {
                // Collect Base64 File Info
                const base64Raw = reader.result as string;
                const base64FileData = base64Raw.split(",")[1];

                // update base 64 file data
                resolve(base64FileData);
            }
        });

        const obs = from(promise);

        return obs;
    }
}
