<p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>

<p-dialog header="{{header | translate}}" [(visible)]="displayDialog" modal="modal" showEffect="fade"
    [style]="{width: '600px'}">

    <app-system-message-log></app-system-message-log>


        <div class="p-fluid p-formgrid"  *ngIf="selectedReport">
            <div class="p-col-12 p-mb-12 p-lg-12 p-mb-lg-0">
                <div class="p-fluid">
                    <span class="p-float-label" style="margin-top: 40px;">
                        <input type="text" id="dsidinputtext" [disabled]="inputDisabled" pInputText
                            [(ngModel)]="selectedReport.Id">
                        <label for="dsidinputtext">{{'ID' | translate}}</label>
                    </span>
                </div>
            </div>
            <div class="p-col-12 p-mb-12 p-lg-12 p-mb-lg-0">
                <div class="p-fluid">
                    <span class="p-float-label" >
                        <input type="text" id="dsnameinputtext"  pInputText
                            [(ngModel)]="selectedReport.Title">
                        <label for="dsnameinputtext">{{'Title' | translate}}</label>
                    </span>
                </div>
            </div>
            <div class="p-col-12 p-mb-12 p-lg-12 p-mb-lg-0">
                <div class="p-fluid">
                    <span class="p-float-label" >
                        <!-- <textarea type="text" id="dsnameinputtext" pInputText
                            [(ngModel)]="selectedReport.Url"> -->
                            <textarea style="min-height:7rem;" id="dsdescriptiontext" [cols]="30" pInputTextarea
                            [(ngModel)]="selectedReport.Url" autoResize="autoResize"></textarea>
                        <label for="dsdescriptiontext">{{'Url' | translate}}</label>
                    </span>
                </div>
            </div>
        </div>



    <p-footer>
        <!-- <button type="button" pButton icon="pi pi-times" serverity="success" (click)="displayDeleteWorkflow=false" label="{{'No' | translate}}"
            class="p-button-text"></button>
            <button type="button" pButton icon="pi pi-check" (click)="deleteWorkflow(selectedWorkflow)" label="{{'Yes' | translate}}"
            class="p-button-text"></button> -->
        <button pButton pRipple type="button" icon="pi pi-times" (click)="displayDialog=false"
            class="p-button-rounded p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'No' | translate}}"></button>
        <button pButton pRipple type="button" icon="pi pi-check" (click)="updateReport(selectedReport)"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'Save' | translate}}" [icon]="savingInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'"></button>
    </p-footer>
</p-dialog>
