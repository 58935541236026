<!-- <p-dialog [(visible)]="displayConfig" [position]="'right'" [blockScroll]="false" [modal]="false" closeAriaLabel="'Close'" [closable]="false"   [closeOnEscape]="true" [dismissableMask]="true" [style]="{width: '40vw',padding:0}"
[draggable]="false" [resizable]="false">
<div class="p-grid chartContainer">
    <div class="p-col-4 chart">
        <app-nodes-status [workflowStatesLog]="workflowStatesLog" [toggleView]="true"></app-nodes-status>
    </div>
    <div class="p-col-4 chart">
        <app-node-stats [tableStatsRecords]="currentTableStatsRecords" [toggleView]="true" [tableCols]="currentTableStatsCols" [columnStatsRecords]="currentColumnStatsRecords" [columnCols]="currentColumnStatsCols"  ></app-node-stats>
    </div>
    <div class="p-col-4 chart">
        <app-workflow-artefact [artifacts]="artifacts" [toggleView]="true" ></app-workflow-artefact>
    </div>
</div>
</p-dialog> -->


<div class="spinner-container" *ngIf="savingInProgress">
    <app-loading-screen></app-loading-screen>
</div>

<!-- <div class="sticky-button">
    <button pButton icon="" label="{{'Close' | translate}}" (click)="appMain.rightPanelActive = false"></button>
</div> -->

<p-tabView>
    <p-tabPanel header="{{'Config' | translate}}">
        <!-- <ng-template pTemplate="header">
                <img class="static" style="width: 30px" pTooltip="{{'Config' | translate }}" tooltipPosition="top"
                    src="assets/layout/images/animatedIcons/reparaturwerkzeuge.png" alt="freya-layout" />
                <img class="active" style="width: 30px" pTooltip="{{'Config' | translate }}" tooltipPosition="top"
                    src="assets/layout/images/animatedIcons/reparaturwerkzeuge.gif" alt="freya-layout" />
            </ng-template> -->
        <!-- <div class="p-fluid"> -->
        <!-- <p-scrollPanel [style]="{height: 'calc(70vh)'}"> -->
        <div style="margin:20px 0; display: flex;align-items: center;">
            <h5 *ngIf="currentWorkflowNodeInfo && currentWorkflowNodeInfo.IWorkflowNode">
                {{currentWorkflowNodeInfo.IWorkflowNode.Name | translate }} (ID: {{currentWorkflowNodeInfo.Id }})
                <span style="margin-left: 10px">
                    <i class="pi pi-table showDataIcon" (click)="onClickShowData()"
                        pTooltip="{{'Show data' | translate}}"></i>
                </span>
            </h5>
        </div>

        <!-- <div class="p-col-12" style="margin: 0px 0; padding-left:0; display: flex;align-items: center;justify-content: space-between;">
                <div style=" display: flex;align-items: center" *ngIf="currentWorkflowNodeInfo && currentWorkflowNodeInfo.IWorkflowNode">
                    <span><h5 style="margin-bottom:0; margin-left: 10px">{{currentWorkflowNodeInfo.IWorkflowNode.Name | translate }} (ID: {{currentWorkflowNodeInfo.Id }})</h5></span>
                    <span style="margin-left: 10px">
                        <i class="pi pi-table showDataIcon" (click)="onClickShowData()" pTooltip="{{'Show data' | translate}}" ></i>
                    </span>
                </div>
            </div> -->

        <div [ngSwitch]="currentEngineName">
            <app-default *ngSwitchDefault></app-default>
            <app-input-datasource-node [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo"
                *ngSwitchCase="EXCEL_INPUT_NODE">
            </app-input-datasource-node>

            <app-datasource-int-node [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo"
                *ngSwitchCase="DATA_SOURCE_INT_INPUT">
            </app-datasource-int-node>

            <app-input-datastore-node [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo"
                *ngSwitchCase="DATASTORE_INPUT_NODE">
            </app-input-datastore-node>
            <app-select-node [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo" *ngSwitchCase="SELECT_NODE">
            </app-select-node>
            <app-spark-select-node [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo"
                *ngSwitchCase="SELECT_NODE_SPARK">
            </app-spark-select-node>
            <app-filter-node [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo" *ngSwitchCase="FILTER_NODE">
            </app-filter-node>
            <app-filter-node [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo" *ngSwitchCase="FILTER_NODE_SPARK">
            </app-filter-node>
            <app-union-node [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo" *ngSwitchCase="UNION_NODE">
            </app-union-node>
            <app-join-node [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo" *ngSwitchCase="JOIN_NODE">
            </app-join-node>
            <app-join-node [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo" *ngSwitchCase="JOIN_NODE_SPARK">
            </app-join-node>

            <app-spark-constant-value-node [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo"
                *ngSwitchCase="CONSTANT_VALUE_SPARK">
            </app-spark-constant-value-node>
            <app-spark-find-and-replace-node [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo"
                *ngSwitchCase="FIND_AND_REPLACE_SPARK">
            </app-spark-find-and-replace-node>
            <app-spark-split-column-node [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo"
                *ngSwitchCase="SPLIT_COLUMN_SPARK">
            </app-spark-split-column-node>

            <app-find-and-replace-node *ngSwitchCase="FINDANDREPLACE_NODE"
                [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo"></app-find-and-replace-node>
            <app-sorting-node *ngSwitchCase="SORTING_NODE" [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo">
            </app-sorting-node>
            <app-sorting-node *ngSwitchCase="SORTING_NODE_SPARK" [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo">
            </app-sorting-node>
            <app-transpose-node *ngSwitchCase="TRANSPOSE_NODE" [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo">
            </app-transpose-node>
            <app-transpose-node *ngSwitchCase="TRANSPOSE_NODE_SPARK"
                [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo">
            </app-transpose-node>
            <app-pivot-node *ngSwitchCase="PIVOT_NODE" [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo">
            </app-pivot-node>
            <app-output-node *ngSwitchCase="OUTPUT_PREVIEW_NODE" [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo">
            </app-output-node>
            <app-output-datastore-node *ngSwitchCase="OUTPUT_DATASTORE_NODE"
                [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo"></app-output-datastore-node>
            <app-calculate-node *ngSwitchCase="CALCULATE_NODE" [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo">
            </app-calculate-node>
            <app-fx-convert-node *ngSwitchCase="FXCONVERT_NODE" [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo">
            </app-fx-convert-node>
            <app-unit-convert-node *ngSwitchCase="UNITCONVERT_NODE"
                [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo">
            </app-unit-convert-node>
            <app-constant-value-node *ngSwitchCase="CONSTANTVALUE_NODE"
                [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo">
            </app-constant-value-node>
            <app-missing-value-node *ngSwitchCase="MISSINGVALUE_NODE"
                [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo">
            </app-missing-value-node>
            <app-table-splitter-node *ngSwitchCase="TABLESPLITTER_NODE"
                [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo">
            </app-table-splitter-node>
            <app-column-splitter-node *ngSwitchCase="COLUMNSPLITTER_NODE"
                [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo">
            </app-column-splitter-node>
            <app-column-aggregator-node *ngSwitchCase="COLUMNAGGREGATOR_NODE"
                [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo">
            </app-column-aggregator-node>
            <app-present-value-node *ngSwitchCase="PRESENTVALUE_NODE"
                [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo">
            </app-present-value-node>
            <app-summarize-node *ngSwitchCase="SUMMARIZE_NODE" [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo">
            </app-summarize-node>
            <app-unique-values-node *ngSwitchCase="UNIQUEVALUES_NODE"
                [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo">
            </app-unique-values-node>
            <app-dateparser-node *ngSwitchCase="DATEPARSER_NODE" [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo">
            </app-dateparser-node>
            <app-regex-node *ngSwitchCase="REGEX_NODE" [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo">
            </app-regex-node>
            <app-anonymize-node *ngSwitchCase="ANONYMIZE_NODE"
                [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo"></app-anonymize-node>
            <app-sampling-node *ngSwitchCase="SAMPLING_NODE" [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo">
            </app-sampling-node>
            <app-encrypt-node *ngSwitchCase="ENCRYPT_NODE" [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo">
            </app-encrypt-node>
            <app-compare-node *ngSwitchCase="COMPARE_NODE" [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo">
            </app-compare-node>
            <app-change-datatype-node *ngSwitchCase="CHANGEDATATYPE_NODE"
                [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo">
            </app-change-datatype-node>
            <app-file-downloader-node *ngSwitchCase="FILEDOWNLOADER_NODE"
                [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo">
            </app-file-downloader-node>

            <app-auto-select-node *ngSwitchCase="AUTO_SELECT_NODE"
                [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo">
            </app-auto-select-node>
            <app-spark-auto-select-node *ngSwitchCase="AUTO_SELECT_NODE_SPARK"
                [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo">
            </app-spark-auto-select-node>

            <app-formula-node *ngSwitchCase="FORMULA_NODE" [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo">
            </app-formula-node>
            <app-email-validation-node *ngSwitchCase="EMAIL_VALIDATION_NODE"
                [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo"></app-email-validation-node>
            <app-gender-check-node *ngSwitchCase="GENDERCHECK_NODE"
                [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo"></app-gender-check-node>
            <app-trim-node *ngSwitchCase="TRIM_NODE"
                [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo"></app-trim-node>
            <app-remove-duplicates-node *ngSwitchCase="REMOVE_DUPLICATES_NODE"
                [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo"></app-remove-duplicates-node>
            <app-convert-case-node *ngSwitchCase="CONVERT_CASE_NODE"
                [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo"></app-convert-case-node>
            <app-limit-rows-node *ngSwitchCase="LIMIT_ROWS_NODE" [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo">
            </app-limit-rows-node>

            <app-destination-node *ngSwitchCase="DESTINATION_NODE"
                [(currentWorkflowNodeInfo)]="currentWorkflowNodeInfo">
            </app-destination-node>
        </div>



        <!-- <p-scrollTop target="parent" styleClass="custom-scrolltop" [threshold]="100" icon="pi pi-arrow-up">
                </p-scrollTop>
            </p-scrollPanel> -->
        <!-- </div> -->
    </p-tabPanel>
    <!-- <p-tabPanel header="Suggestions">
                        Coming soon...
                    </p-tabPanel> -->
    <p-tabPanel header="{{'Info' | translate}}">
        <!-- <ng-template pTemplate="header">
                <img class="static" style="width: 30px" pTooltip="{{'Info' | translate }}" tooltipPosition="top"
                    src="assets/layout/images/animatedIcons/information.png" alt="freya-layout" />
                <img class="active" style="width: 30px" pTooltip="{{'Info' | translate }}" tooltipPosition="top"
                    src="assets/layout/images/animatedIcons/information.gif" alt="freya-layout" />
            </ng-template> -->
        <div class="p-fluid">
            <!-- <p-scrollPanel [style]="{height: 'calc(76vh)'}"> -->
            <app-node-infos [selectedNode]="selectedNode"></app-node-infos>
            <!-- <p-scrollTop target="parent" styleClass="custom-scrolltop" [threshold]="100" icon="pi pi-arrow-up">
                    </p-scrollTop>
                </p-scrollPanel> -->
        </div>
    </p-tabPanel>
</p-tabView>


<!-- <div style="display:fixed; right:30px">
    <button pButton icon="" label="{{'Close' | translate}}" (click)="appMain.rightPanelActive = false"></button>
</div> -->