import { SafeResourceUrl } from "@angular/platform-browser";

export class PowerBiEntry {
    id: number;
    title: string;
    url: SafeResourceUrl;


    constructor( id: number, title: string, url: SafeResourceUrl) {
        this.id = id;
        this.title = title;
        this.url = url;

    }

    // constructor(id: number, title)
}

export class PowerBiReportSettings {
    showFilter: string;

}
