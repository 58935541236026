import { Injectable, Output } from "@angular/core";
import { Observable, Subject } from "rxjs";

export enum MessageType {
    /**
     * Flow child window gestartet. Liefert eine dummy Zahl.
     */
    FlowInitialized,
    /**
     * Flow abgeschlossen, liefert eine ParamMap
     */
    FlowCompleted,
    /**
     * Startet den Fluss, als Argument sollte die aktuelle Config übergeben werden.
     */
    StartFlow
}

export interface FlowMessage<T> {
    Sender: string;
    MessageType: MessageType;
    Data: T;
}

@Injectable({
    providedIn: "root",
})
export class OAuthFlowEventService {

    private subject = new Subject<FlowMessage<any>>();

    time:number = Date.now();

    sendMessage(message: FlowMessage<any>) {
        this.subject.next(message);
    }

    getSubject(): Observable<FlowMessage<any>> {
        return this.subject.asObservable();
    }

}