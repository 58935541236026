import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TabViewEvents } from "src/app/helper/events";
import { FieldInfo } from "src/app/models/datasource.model";
import { WriteMode } from "src/app/models/helperClass.model";
import { DatasourcesService } from "src/app/services/datasources.service";

@Component({
	selector: "app-define-datasource",
	templateUrl: "./define-datasource.component.html",
	styleUrls: ["./define-datasource.component.scss"],
})
export class DefineDatasourceComponent implements OnInit {
	@Input() dataSourceName!: string;
	@Input() dataSourceDescription!: string;
	@Input() previewColumns!: FieldInfo[];
	selectedKeyCols: FieldInfo[] = [];
	previewColumnsClone: FieldInfo[] = [];
	index: number = 0;
	writeModeOptions: WriteMode[] = [];
	selectedWriteModeOption?: WriteMode;
	LoadDataFlag: boolean = true;

	//@Input() psaInfos: PersistentStagingArea[];
	//@Output() loadPsaEmitter = new EventEmitter<string>();


	@Output() selectedWriteMode = new EventEmitter<WriteMode>();
	@Output() datasourceNameEmitter = new EventEmitter<string>();
	@Output() datasourceDescriptionEmitter = new EventEmitter<string>();
	@Output() LoadDataFlagEmitter = new EventEmitter<boolean>();
	@Output() keyColumnsEmitter = new EventEmitter<FieldInfo[]>();
	constructor(private datasourceService: DatasourcesService) {}

	ngOnInit(): void {
		this.writeModeOptions = this.datasourceService.getWriteModes();
		if (this.selectedWriteModeOption === undefined) {
			this.selectedWriteModeOption = this.writeModeOptions[0];
			this.selectedWriteMode.emit(this.selectedWriteModeOption);
		}
		this.previewColumnsClone = [...this.previewColumns]
	}

	updateForms() {
		this.datasourceNameEmitter.emit(this.dataSourceName);
		this.datasourceDescriptionEmitter.emit(this.dataSourceDescription);
		this.LoadDataFlagEmitter.emit(this.LoadDataFlag);
	}

	selectWriteMode(event: TabViewEvents.OnChange<any>) {
		let index: number = event.index;

		//this.selectedWriteModeOption = <WriteMode>event.value;
		this.selectedWriteModeOption = this.writeModeOptions[index];
		this.selectedWriteMode.emit(this.selectedWriteModeOption);
	}

	onSelectKeyCols(event) {
		console.log(event);
		const keyCols:FieldInfo[] = event.value;
		const keyColsString = keyCols.map((col:FieldInfo) => {return col.Name});

		const updated = this.previewColumnsClone.map((col:FieldInfo) => {
			let newCol:FieldInfo = {...col};

			newCol.IsKey = keyColsString.includes(newCol.Name) ? true : false;		

			return newCol

		});
		this.keyColumnsEmitter.emit(updated);
		console.log(updated);

	}
	// selectWriteModeDropdown(event) {
	// 	this.selectedWriteModeOption = <WriteMode>event.value;

	// 	this.selectedWriteMode.emit(this.selectedWriteModeOption);
	// }

	// page interface
	isValid(): boolean {
		const valid_ds_name =
			this.dataSourceName !== undefined && this.dataSourceName.length > 0;
		const valid_write_mode = this.selectedWriteModeOption !== undefined;
		let valid_keySet:boolean = true;

		if(this.selectedWriteModeOption.name !== "Append" && this.selectedWriteModeOption.name !== "Replace") {
			if(this.selectedKeyCols.length > 0) {
				valid_keySet = true

			}  else {
				valid_keySet = false
			}
		}

		return valid_ds_name && valid_write_mode && valid_keySet;
	}
	// isValidPsa(): boolean {
	// 	return this.psaInfos !== undefined && this.psaInfos.length > 0;
	// }

}
