import { Component, Input, OnInit } from '@angular/core';

import { TableStats } from 'src/app/models/designer.models';

@Component({
  selector: 'app-column-stats',
  templateUrl: './column-stats.component.html',
  styleUrls: ['./column-stats.component.scss']
})
export class ColumnStatsComponent implements OnInit {
  @Input() col: any;
  @Input() tableStats?: TableStats;

  columnData: any[] = [];
  columnHeaders: string[] = [];

  uniquePercentageValue: number = 100;
  validPercentageValue: number = 100;

  constructor() { }

  ngOnInit(): void {
    this.createStats();
  }


  createStats() {

    let colName = this.col.header;

    if (this.tableStats !== undefined) {
      let columnStats = this.tableStats.ColumnStats;
      let tableStats = this.tableStats.TableStats;

      if (tableStats.Data === undefined) return;

      const tableStatsFiltered = tableStats.Data.filter((row) => row[0] === colName);

      this.uniquePercentageValue = parseInt((parseInt(tableStatsFiltered[0][3]) / parseInt(tableStatsFiltered[0][1]) * 100).toFixed(2));

      const validEntries = (parseInt(tableStatsFiltered[0][1]) - parseInt(tableStatsFiltered[0][2]));
      this.validPercentageValue = parseInt((validEntries / parseInt(tableStatsFiltered[0][1]) * 100).toFixed(2));

      const columnStatsFiltered = columnStats.Data.filter(row => row[0] === colName);

      this.columnData = columnStatsFiltered[0].slice(1).slice(0, -1);

      const columnFieldInfos = columnStats.MetaData.FieldsInfo;
      const columnHeaders = columnFieldInfos.map(entry => entry.Name)
      this.columnHeaders = columnHeaders.slice(1).slice(0, -1);
    }
  }
}