import { Accessible } from "./Accessible";

export class ViewAccessible implements Accessible {
    Name: string;
    constructor(Name: string) {
        if(Name === undefined) throw new Error( "Class 'ViewAccessible': Required value 'Name' is undefined" );
        this.Name = Name;
    }
    AccessibleType: string = "";
    Value: string = "";
}