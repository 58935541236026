import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MeltanooPostgreConnectorView, SingerMetaInfo } from 'src/app/models/connectors/meltanoo/postgre-connector-view.model';
import { PlayErrorResponse } from 'src/app/models/play.error.model';

@Component({
  selector: 'app-postgres-sql-server-singer-connector',
  templateUrl: './postgres-sql-server-singer-connector.component.html',
  styleUrls: ['./postgres-sql-server-singer-connector.component.scss']
})
export class PostgresSqlServerSingerConnectorComponent implements OnInit {
  @Input() currentConnectorView: MeltanooPostgreConnectorView;
  status_text: string = "";
  status: boolean = false;

  ngOnInit(): void {

  }

  onConnect(event: PointerEvent): void {
    this.currentConnectorView.fetchMetaDataObs().subscribe(
      (res: SingerMetaInfo) => {

        this.currentConnectorView.metaInfo = res;
        console.log(res);
        this.status_text = "OK!";
        this.status = true;

      },
      (error: any) => this.handleError(error));
  }

  handleError(error: HttpErrorResponse) {
    // TODO: delegate or handle
    console.log(error);
    const play_error: PlayErrorResponse = error.error;
    this.status_text = play_error.error.exception.description;
    this.status = false;
  }
}
