import {
	Component,
	Input,
	OnDestroy,
	OnInit,

} from "@angular/core";
import { Chart } from "chart.js";
import { Id } from "src/app/helper/id";
import { BasicFrequency, CronFrequency } from "src/app/models/api/models/scheduler/CronFrequency";
import {
	ScheduleActionPlan,
	ScheduleBaseAction,
} from "src/app/models/schedule.model";
import {
	DateTimeStampInfo,
	SchedulesService,
} from "src/app/services/schedules.service";
import { SubSink } from "subsink";

export class DataTypesChart {
	name: string;
	progress: number;
	count: number;

	constructor(name:string, progress:number, count:number) {
		this.name = name;
		this.progress = progress;
		this.count = count;
	}
}
export class MonthOption {
	name: string;
	value: string;

	constructor(name:string, value:string) {
		this.name = name;
		this.value = value;
	}
}

export class Appointment {
	date: string;
	actionPlan: ScheduleActionPlan;

	constructor(date:string,actionPlan:ScheduleActionPlan) {
		this.date = date;
		this.actionPlan = actionPlan;
	}
}

@Component({
	selector: "app-schedule-charts",
	templateUrl: "./schedule-charts.component.html",
	styleUrls: ["./schedule-charts.component.scss"],
})
export class ScheduleChartsComponent implements OnInit, OnDestroy {
	subs = new SubSink();
	@Input() schedules: ScheduleActionPlan[] = [];
	frequencies: BasicFrequency[] = [];
	actions: ScheduleBaseAction[] = [];
	appointments: DateTimeStampInfo[] = [];
	upcomingAppointments: Appointment[] = [];
	chartMonths: MonthOption[];
	schedulesFiltered: ScheduleActionPlan[] = [];

	actionsCountList: DataTypesChart[] = [];
	actionsCountFilteredList: DataTypesChart[] = [];

	frequenciesCountList: DataTypesChart[] = [];
	frequenciesCountFilteredList: DataTypesChart[] = [];

	appointmentsCountList: any[] = [];
	appointmentsCountFilteredList: any[] = [];

	selectedSchedule?: ScheduleActionPlan;

	lineData: any;
	lineOptions: any;

	constructor(private scheduleService: SchedulesService) {

		this.chartMonths = [
			{ name: "Jan", value: "0" },
			{ name: "Feb", value: "1" },
			{ name: "Mar", value: "2" },
			{ name: "Apr", value: "3" },
			{ name: "May", value: "4" },
			{ name: "Jun", value: "5" },
			{ name: "Jul", value: "6" },
			{ name: "Aug", value: "7" },
			{ name: "Sep", value: "8" },
			{ name: "Oct", value: "9" },
			{ name: "Nov", value: "10" },
			{ name: "Dec", value: "11" },
		];
	}
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	createChart(ds?: ScheduleActionPlan) {
		this.subs.sink = this.scheduleService
			.getScheduleActions()
			.subscribe((res: ScheduleBaseAction[]) => {
				let currentActions = res;

				if (!ds) {
					let currentActionsNew = currentActions;

					this.createActionChart(currentActionsNew, false);
					this.getAppointments();
				} else {
					let currentActionsNew = currentActions.filter((field) => {
						return field.actionPlan === ds.id;
					});
					this.createActionChart(currentActionsNew, false);
					this.getAppointments(undefined, ds.id);
				}
			});
		this.subs.sink = this.scheduleService
			.getScheduleFrequencies()
			.subscribe((res: CronFrequency[]) => {
				let currentFrequencies = <BasicFrequency[]>res;
				if (!ds) {
					let currentFrequenciesNew = currentFrequencies;
					this.createFrequencyChart(currentFrequenciesNew, false);
					this.getAppointments();
				} else {
					let currentFrequenciesNew = currentFrequencies.filter((field) => {
						return field.ActionPlan === ds.id;
					});
					this.createFrequencyChart(currentFrequenciesNew, false);
					this.getAppointments(undefined, ds.id);
				}
			});
	}
	createActionChart(actions: ScheduleBaseAction[], isReset: boolean) {
		// Create Action Chart
		let actionsList = actions.map((field) => {
			return field._type;
		});
		let actionsListSet = new Set(actionsList);
		let actionsArray = [];

		for (let type of actionsListSet) {
			// Iterate through list to get counts of each datatype
			let counter = 0;
			for (let i of actionsList) {
				if (i === type) {
					counter++;
				}
			}

			//Init new component class
			//let newEntry = new DataTypesChart();
			let typeSplit = type.split(".");
			const new_entry_name = typeSplit[2]
			.split("Action")[0]
			.split("DataSource")[0]
			.split("Workflow")[0];
			// newEntry.name = typeSplit[2]
			// 	.split("Action")[0]
			// 	.split("DataSource")[0]
			// 	.split("Workflow")[0];
			// newEntry.count = counter;
			const progress = (counter / actionsList.length) * 100;
			// newEntry.progress = parseInt(progress.toFixed(2));

			const newEntry = new DataTypesChart(new_entry_name, parseInt(progress.toFixed(2)), counter);

			//Push new entry to final Array
			actionsArray.push(newEntry);
		}
		if (isReset) {
			this.actionsCountList = actionsArray;
		}
		this.actionsCountFilteredList = actionsArray;
	}
	createFrequencyChart(frequencies: BasicFrequency[], isReset: boolean) {
		// Create frequency data
		let frequenciesList = frequencies.map((field) => {
			return field.RhythmSettings.Rhythm._type;
		});
		let frequenciesListSet = new Set(frequenciesList);
		let frequenciesArray = [];

		for (let type of frequenciesListSet) {
			// Iterate through list to get counts of each datatype
			let counter = 0;
			for (let i of frequenciesList) {
				if (i === type) {
					counter++;
				}
			}

			//Init new component class
			//let newEntry = new DataTypesChart();
			let typeSplit = type.split(".");

			// newEntry.name = typeSplit[2].split("Rhythm")[0];
			// newEntry.count = counter;
			let progress = (counter / frequenciesList.length) * 100;
			// newEntry.progress = parseInt(progress.toFixed(2));

			//Push new entry to final Array
			const newEntry = new DataTypesChart(typeSplit[2].split("Rhythm")[0], counter, parseInt(progress.toFixed(2)));
			frequenciesArray.push(newEntry);
		}
		if (isReset) {
			this.frequenciesCountList = frequenciesArray;
		}
		this.frequenciesCountFilteredList = frequenciesArray;
	}
	getAppointments(id?: number, actionPlan?: number, start?: Date) {
		const defaultstartDate = new Date(2000, 1, 1);
		const defaultEndDate = new Date(2100, 12, 31);

		this.subs.sink = this.scheduleService
			.getFrequencyTimeStamps(defaultEndDate, id, actionPlan, defaultstartDate)
			.subscribe((res: DateTimeStampInfo[]) => {
				this.appointments = res;
				this.createAppointmentsChart(res, false);
				this.createUpcomingAppointments(res);
			});
	}
	createAppointmentsChart(appointments: DateTimeStampInfo[], isReset: boolean) {
		const startDate = new Date(Date.now());
		startDate.setMonth(startDate.getMonth() - 3);

		const all_dates = appointments
			.map((r) => r.DateObjects)
			.reduce((a1, a2) => a1.concat(a2), new Array<Date>());

		// Filter all dates after startDate
		const filteredRes = all_dates.filter((date: Date) => {
			return date >= startDate;
		});

		// Get Months of filtered DatesArray -> 12 months starting with the current month
		const filteredResMonthArray:Array<number> = [];
		filteredRes.map((date: Date) => {
			let dateMonth = date.getMonth();
			let dateYear = date.getFullYear();
			filteredResMonthArray.push(dateMonth);
		});

		// Create set with unique months
		let filteredResListSet = new Set(filteredResMonthArray);

		let filteredResArray = [];
		for (let type of filteredResListSet) {
			// Iterate through list to get counts of each datatype
			let counter = 0;
			for (let i of filteredResMonthArray) {
				if (i === type) {
					counter++;
				}
			}

			//Init new component class
			// let newEntry = new DataTypesChart();
			// newEntry.name = type.toString();
			// newEntry.count = counter;
			// newEntry.progress = (counter / filteredRes.length) * 100;

			//Push new entry to final Array
			const newEntry = new DataTypesChart(type.toString(), (counter / filteredRes.length) * 100, counter);
			filteredResArray.push(newEntry);
		}
		let finalRes: DataTypesChart[] = filteredResArray;

		//Prepare ChartLegendString Array
		let chartLegendArray: string[] = [];
		const startMonthNumber = startDate.getMonth();
		let monthinterval: number = 11;

		let j = 0;

		for (let i = 0; i <= monthinterval; i++) {
			let currentMonthIndex = startMonthNumber + i;
			let finalMonth = undefined;

			if (currentMonthIndex > 11) {
				finalMonth = j;
				j = j + 1;
			} else {
				finalMonth = startMonthNumber + i;
			}
			chartLegendArray.push(finalMonth.toString());
		}

		// Iterate through unsorted Month Class and and resort based on starting month
		let chartDataArray = new Array();
		this.chartMonths.map((month: MonthOption) => {
			let entryValue = undefined;
			finalRes.map((item: DataTypesChart) => {
				if (month.value.toString() === item.name) {
					entryValue = item.count;
				}
			});
			chartDataArray.push(entryValue);
		});

		let chartLegendOrdered = this.chartMonths.sort((a, b) => {
			return (
				chartLegendArray.indexOf(a.value) - chartLegendArray.indexOf(b.value)
			);
		});

		let chartLegendStringOrdered = chartLegendOrdered.map((i) => {
			return i.name;
		});

		chartDataArray = chartDataArray.map((str: string) => {
			if (str === undefined) {
				return 0;
			} else {
				return str;
			}
		});
		if (isReset) {
			this.appointmentsCountList = chartDataArray;
		}
		this.appointmentsCountFilteredList = chartDataArray;

		this.lineData = {
			labels: chartLegendStringOrdered,

			datasets: [
				{
					label: "Appointments",
					data: chartDataArray,
					borderColor: [
						getComputedStyle(document.body).getPropertyValue(
							"--primary-color"
						) || "#2c84d8",
					],
					borderWidth: 4,
					fill: true,
					backgroundColor: [
						getComputedStyle(document.body).getPropertyValue(
							"--primary-lighter-color"
						) || "#2c84d8",
					],
					tension: 0.4,
				},
				// {
				// 	label: "Appointments",
				// 	data: chartDataArray.slice(3,chartDataArray.length),
				// 	borderColor: [
				// 		getComputedStyle(document.body).getPropertyValue(
				// 			"--secondary-color"
				// 		) || "#2c84d8",
				// 	],
				// 	borderWidth: 4,
				// 	fill: true,
				// 	backgroundColor: [
				// 		getComputedStyle(document.body).getPropertyValue(
				// 			"--secondary-lighter-color"
				// 		) || "#2c84d8",
				// 	],
				// 	tension: 0.4,
				// },
				{
					type: 'bar',
					label: 'Now',
					data: [0, 0, 0, chartDataArray[3], 0, 0, 0],
					fill: false,
					borderColor: 'rgb(54, 162, 235)',
					
					background: "linear-gradient(79deg, #34c7c2 0%, #0494c5 51%)"
				  }
			],
		};
	}
	createUpcomingAppointments(plan_time_infos: DateTimeStampInfo[]) {
		let array: Appointment[] = [];
		for (let plan_time of plan_time_infos) {
			let datesArray = plan_time.DateObjects;
			let orderedDates = datesArray.sort((x, y) => +new Date(x) - +new Date(y));
			let firstFiveDates = orderedDates.slice(0, 7);

			for (let y of firstFiveDates) {
				// let newAp = new Appointment();
				// newAp.date = y.toUTCString();
				// newAp.actionPlan = i.ActionPlan;

				const actionPlan = Id.assertSet(plan_time.ActionPlan, new Error("No actionplan for this timestampInfo"))

				const newAp = new Appointment(y.toUTCString(), actionPlan);

				array.push(newAp);
			}
		}
		let orderedArray = array
			.sort((x, y) => +new Date(x.date) - +new Date(y.date))
			.slice(0, 8);
		this.upcomingAppointments = orderedArray;
	}

	ngOnInit(): void {
		this.lineOptions = {
			plugins: {
				legend: {
					display: false,
				},
			},
			maintainAspectRatio: false,
			hover: {
				mode: "index",
			},
			scales: {
				x: {
					display: true,
					grid: {
						color: "transparent",
					},
					ticks: {
						color: "#BFC2C6",
					},
				},
				y: {
					display: true,
					grid: {
						color: "rgba(191, 194, 198, .45)",
						//borderDash: [5, 10],
						display: false,
					},
					ticks: {
						color: "#BFC2C6",
						min: 0,
						stepSize: 5,
					},
				},
			},
		};

		this.subs.sink = this.scheduleService.selectedSchedulePlanEmitter.subscribe(
			(ds: ScheduleActionPlan) => {
				if (ds === this.selectedSchedule) {
					this.selectedSchedule = undefined;
					this.createChart();
				} else {
					this.selectedSchedule = ds;
					this.createChart(ds);
				}
			}
		);
		this.createChart();
	}


}
