import { Component, OnDestroy, OnInit } from "@angular/core";
import { CheckBoxEvents } from "src/app/helper/events";
import { Id } from "src/app/helper/id";
import { PsaInputPlugIn } from "src/app/models/api/models/etl/PsaInputPlugIn";
import { FieldInfo } from "src/app/models/datasource.model";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { DatasourcesService } from "src/app/services/datasources.service";
import { DesignerService } from "src/app/services/designer.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { NodeConfigComponentBase } from "../node-config-component-base";
import * as stg from "src/app/models/api/models/staging/DataSource";

@Component({
	selector: "app-input-datasource-node",
	templateUrl: "./input-datasource-node.component.html",
	styleUrls: ["./input-datasource-node.component.css"],
})
export class InputNodeComponent
	extends NodeConfigComponentBase<PsaInputPlugIn.Settings>
	implements OnInit, OnDestroy {

	settingsToView(settings: PsaInputPlugIn.Settings): void {
		// -- Get Configuration
		// -- Ensure that updateSettings is triggered at the beginning to get current datasources

		this.currentConfig = settings;
		if (this.currentConfig === undefined)
			throw new Error("Config is undefined");
	}
	viewToSettings(): PsaInputPlugIn.Settings {
		const config = Id.assertSet(this.currentConfig, new Error("No Config found, this should not happen"));
		console.log("View to Settings", config);

		return config;
	}
	onSettingsChanged(settings: PsaInputPlugIn.Settings): void {
		throw new Error("Method not implemented.");
	}

	UseOnlyLatestDelta: boolean = false;
	columnList: FieldInfo[] = [];
	currentConfig?: PsaInputPlugIn.Settings = undefined;

	readonly InputPort = "Input";

	constructor(
		protected workflowService: WorkflowsService,
		protected designerService: DesignerService,
		protected datasourceService: DatasourcesService,
		protected bionApi: ApiBackendService
	) {
		super(workflowService, designerService);
	}
	ngOnInit(): void {
		super.ngOnInit();

		this.subs.sink = this.datasourceService.datasourceChangedEmitter.subscribe(ds => {
			console.log("Update node because new ds was created", this.currentConfig);
			const stg_ds = new stg.DataSource(ds.id, ds.name, false, ds.usePsa, ds.description);
			this.currentConfig.DataSources.push(stg_ds);
			console.log("AFTER - Update node because new ds was created", this.currentConfig);
		});
	}
	ngOnDestroy(): void {
		this.subs.unsubscribe();
		this.currentConfig = undefined;
	}
	onChangeUseOnlyLatestDelta(event: CheckBoxEvents.OnChange<any>) {
		if (this.currentConfig) {
			this.onUpdateSettings(true);
		}

	}
	onCheckLastPackageOnly(event: CheckBoxEvents.OnChange<any>) {
		if (this.currentConfig) {
			this.onUpdateSettings(true);
		}
	}
	onCheckIncludeMetaFields(event: CheckBoxEvents.OnChange<any>) {
		if (this.currentConfig) {
			this.onUpdateSettings(true);
		}
	}
	displayCreateDatasourceDialog(evt: boolean) {
		this.datasourceService.displayCreateDatasourceDialog(evt);
	}
}