<p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>
<p-dialog header="{{header | translate}}" [(visible)]="displayDialog" modal="modal"
    showEffect="fade" [style]="{width: '800px'}">

<p-multiSelect appendTo="body" [options]="workflows" [(ngModel)]="selectedWorkflows" optionLabel="name"></p-multiSelect>
<app-system-message-log></app-system-message-log>

    <p-footer>
        <button pButton pRipple type="button" icon="pi pi-times" (click)="displayDialog=false"
            class="p-button-rounded p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'Close' | translate}}"></button>
        <button pButton pRipple type="button" icon="pi pi-check" (click)="exportWorkflows()"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'ToExport' | translate}}" [icon]="savingInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-download'"></button>
    </p-footer>
</p-dialog>
