import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Id } from 'src/app/helper/id';
import { DatasourcesService } from 'src/app/services/datasources.service';
import { SubSink } from 'subsink';
import { BottomToolbarProvider, ToolbarHelper, ToolbarResult } from './bottom-toolbar-provider';

/**
 * Displays Object relations & data.
 * @param T Object Type
 * @param DH Data Holder Type in case of Data Source & Data Store
 * @param BTA Get Data Result & Argument for building the toolbar
 * @param BTR Build Toolbar Result
 */
@Component({
  selector: 'app-generic-bottom-toolbar',
  templateUrl: './generic-bottom-toolbar.component.html',
  styleUrls: ['./generic-bottom-toolbar.component.scss']
})
export class GenericBottomToolbarComponent<T, DH, BTR extends ToolbarResult, BTA> implements OnInit, OnDestroy {
  @Input() currentView!: string;
  @Input() typeClass!: BottomToolbarProvider<T, DH, BTR, BTA>;
  activities: any[] = [];
  objectRelations: ToolbarResult = ToolbarHelper.empty();
  selectedObj?: T;
  displayPsa: boolean = false;
  psaInfos?: DH;
  subs = new SubSink;
  data?: BTA;
  constructor(private router: Router, private dataSourceService: DatasourcesService) { }

  ngOnInit(): void {

  }
  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }

  /**
   * Initializes the view and replaces setObject.
   * @param typeClass Relation Abstraction
   * @param data Relation Data provided by the mother
   */
  initView(typeClass: BottomToolbarProvider<T, DH, BTR, BTA>, data:BTA) {
    this.data = data;
    this.subs.sink = this.typeClass.subscribeToEmitter().subscribe(obj => {
      this.objectRelations = this.typeClass.buildToolbar(data, obj);
    });
  }

  setObject(typeClass: BottomToolbarProvider<T, DH, BTR, BTA>): Observable<boolean> {

    this.typeClass = typeClass;

    return this.typeClass.getData().pipe(map((res) => {
      if (this.selectedObj) {
        this.objectRelations = this.typeClass.buildToolbar(res, this.selectedObj);
      } else {
        this.objectRelations = this.typeClass.buildToolbar(res);
      }
      this.data = res;

      this.subs.sink = this.typeClass.subscribeToEmitter().subscribe((obj) => {
        this.objectRelations = this.typeClass.buildToolbar(res, obj);

        if (obj === undefined) {
          this.selectedObj = undefined;
          this.psaInfos = undefined;
        } else {
          this.selectedObj = obj;
          // const holder_obs = this.typeClass.getPsaData(obj).pipe(map(data_holder => {
          //   this.psaInfos = data_holder.DataHolder;
          // }))
        }
      });

      return true
    }));

  }

  routeToUrl(url: string) {
    this.router.navigateByUrl(url);
  }

  displayPsaDialog() {
    this.dataSourceService.displayPsaDialog.emit(true);
  }
  displayRelatedObjects(objects: any[]) {
    console.log(objects)
  }
}
