import { IntegratedSourceModel } from "../IntegratedSourceModel";

export namespace DataSourceIntInput {
    export class StreamDetail {
        Info: IntegratedSourceModel.DataSource<number>;
        Name: string;
        PSAs: Array<IntegratedSourceModel.PsaInfo>;
        constructor(Info: IntegratedSourceModel.DataSource<number>,Name: string,PSAs: Array<IntegratedSourceModel.PsaInfo>) {
            if(Info === undefined) throw new Error( "Class 'StreamDetail': Required value 'Info' is undefined" );
            if(Name === undefined) throw new Error( "Class 'StreamDetail': Required value 'Name' is undefined" );
            if(PSAs === undefined) throw new Error( "Class 'StreamDetail': Required value 'PSAs' is undefined" );
            this.Info = Info;
            this.Name = Name;
            this.PSAs = PSAs;
        }
    }
    export class DataSourceDetail {
        Info: IntegratedSourceModel.DataSource<number>;
        Streams: Array<StreamDetail>;
        constructor(Info: IntegratedSourceModel.DataSource<number>,Streams: Array<StreamDetail>) {
            if(Info === undefined) throw new Error( "Class 'DataSourceDetail': Required value 'Info' is undefined" );
            if(Streams === undefined) throw new Error( "Class 'DataSourceDetail': Required value 'Streams' is undefined" );
            this.Info = Info;
            this.Streams = Streams;
        }
    }
    export class StreamTarget {
		Stream: string;
		PSA?: IntegratedSourceModel.PsaInfo;
		constructor(Stream: string,PSA?: IntegratedSourceModel.PsaInfo) {
			if(Stream === undefined) throw new Error( "Class 'StreamTarget': Required value 'Stream' is undefined" );
			this.Stream = Stream;
			this.PSA = PSA;
		}
	}
    export class DataSourceTarget {
		SourceKey: IntegratedSourceModel.DataSourceKey<number>;
		Stream?: StreamTarget;
		constructor(SourceKey: IntegratedSourceModel.DataSourceKey<number>,Stream?: StreamTarget) {
			if(SourceKey === undefined) throw new Error( "Class 'DataSourceTarget': Required value 'SourceKey' is undefined" );
			this.SourceKey = SourceKey;
			this.Stream = Stream;
		}
	}
    export class Settings {
		Sources: Array<IntegratedSourceModel.DataSource<number>>;
		SourceDetail?: DataSourceDetail;
		SelectedStream?: DataSourceTarget;
		constructor(Sources: Array<IntegratedSourceModel.DataSource<number>>,SourceDetail?: DataSourceDetail,SelectedStream?: DataSourceTarget) {
			if(Sources === undefined) throw new Error( "Class 'Settings': Required value 'Sources' is undefined" );
			this.Sources = Sources;
			this.SourceDetail = SourceDetail;
			this.SelectedStream = SelectedStream;
		}
	}
}