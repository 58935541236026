<div class="p-grid">
    <div class="p-col-12 headerSection">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-multiSelect id="KeyColsNames" appendTo="body"  [options]="keyFields" [(ngModel)]="selectedKeyFields"
                    (onChange)="onChangeKeyColumns($event)"></p-multiSelect>
                <label for="KeyColsNames">Key Columns</label>
            </span>
        </div>
    </div>
</div>
<div class="p-grid">
    <div class="p-col-12" *ngIf="allValueFields">
        <p-table #dt [value]="allValueFields" [(selection)]="selectedValueFields" editMode="row" [lazy]="true"
            [loading]="loading">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem">
                        <p-tableHeaderCheckbox (click)="onUpdateSettings(false)"></p-tableHeaderCheckbox>
                    </th>
                    <th tooltipPosition="top" pTooltip="{{'Config.Compare.Tooltip1' | translate}}">{{'Column'
                        |translate}}</th>
                    <!-- <th pTooltip="The old name will be overwritten by the entered name">New Name</th>
                    <th
                        pTooltip="Change the datatype of a column. A datatype change may result in data losses. Make sure to choose the right one">
                        Datatype</th>
                    <th pTooltip="Length defines the maximum length a string datatype should be.">Length</th> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-column let-editing="editing" let-ri="rowIndex">
                <tr [pEditableRow]="column" [ngClass]="{'row-accessories': column === undefined}">
                    <td>
                        <p-tableCheckbox [value]="column" (click)="onUpdateSettings(false)"></p-tableCheckbox>
                    </td>
                    <td>
                        <p-cellEditor>
                            <ng-template pTemplate="output">
                                {{column}}
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <!-- <td>
                        <input pInputText type="text" placeholder="Enter new name" [(ngModel)]="column.NewName"
                            (blur)="onUpdateSettings(true)" [ngModelOptions]="{standalone: true}">
                    </td>
                    <td>
                        <p-dropdown appendTo="body" [options]="dataTypesList" [(ngModel)]="column.NewDataType"
                            optionLabel="Name" dataKey="Name" autoWidth="false" [style]="{'width':'100%'}"
                            (onChange)="onUpdateSettings(false)"></p-dropdown>
                    </td>
                    <td>
                        <input pInputText type="number" [(ngModel)]="column.NewLength" (blur)="onUpdateSettings(true)"
                            [ngModelOptions]="{standalone: true}">
                    </td> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="2">{{'NoEntryFound' | translate}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>