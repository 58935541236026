
export interface ILogger {
    debug(message?: any, ...optionalParams: any[]): void;
    info(message?: any, ...optionalParams: any[]): void;
    warn(message?: any, ...optionalParams: any[]): void;
    error(message?: any, ...optionalParams: any[]): void;
}

export enum LogLevel {
    Debug,
    Info,
    Warn,
    Error
}

export class ConsoleLogger implements ILogger {

    readonly context: string;
    readonly level: LogLevel;

    constructor(context: string, level: LogLevel) {
        this.context = context;
        this.level = level;
    }
    debug(message?: any, ...optionalParams: any[]): void {
        this.log(LogLevel.Debug, message, optionalParams);
    }
    info(message?: any, ...optionalParams: any[]): void {
        this.log(LogLevel.Info, message, optionalParams);
    }
    warn(message?: any, ...optionalParams: any[]): void {
        this.log(LogLevel.Warn, message, optionalParams);
    }
    error(message?: any, ...optionalParams: any[]): void {
        this.log(LogLevel.Error, message, optionalParams);
    }

    protected log(level: LogLevel, message?: any, ...optionalParams: any[]): void {
        if (level >= this.level) {
            console.log(message, optionalParams);
        }
    }
}