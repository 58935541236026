import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExcelConnector } from 'src/app/models/api/com/bion/connect/excel/ExcelConnector';

@Component({
  selector: 'app-excel-adapter',
  templateUrl: './excel-adapter.component.html',
  styleUrls: ['./excel-adapter.component.scss']
})
export class ExcelAdapterComponent implements OnInit {

  constructor() { }

  @Input()
  config:ExcelConnector.Config;

  @Output()
  public configChanged: EventEmitter<ExcelConnector.Config> = new EventEmitter<ExcelConnector.Config>();

  ngOnInit(): void {
  }

  /**
   * Generelles Callback
   */
  onSettingsChanged() {
    this.config.DetectColumnTypes
    this.configChanged.emit(this.config);
  }
}
