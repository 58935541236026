<button pButton pRipple (click)="onAddGraph()" type="button" icon="pi pi-plus-circle" pTooltip="{{'New Workflow' | translate}}"
tooltipPosition="top" class="p-button-rounded p-button-text p-mr-2 p-mb-2"></button>
<app-toolbar-view #toolbarView [commands]="toolbarCommands"></app-toolbar-view>
  


<p-tabView id="closableTabView" class="TabViewContainer">
    <p-tabPanel header="Home" [closable]="false" >
        <div class="homeContainer">
            Home Tab - Hier kommen nützliche Informationen, Tutorials wie man einen Workflow bedient.

        </div>
    </p-tabPanel>
    <p-tabPanel  (onChange)="onTabClicked(graph)" *ngFor="let graph of graphViewComponents" [header]="graph.workflow.id" [closable]="true">
        <div class="homeContainer">
            <app-graph-view [plugIns]="plugIns"></app-graph-view>
            <!-- <app-settings-view  (settingsChanged)="onNodeSettingsChanged($event)">
            </app-settings-view> -->
            <app-data-view></app-data-view>

        </div>

    </p-tabPanel>

</p-tabView>

<!-- <app-workflow-tab-view #graphTabView></app-workflow-tab-view> -->
<!-- <app-plugin-view #plugInView [plugIns]="plugIns"></app-plugin-view> -->
<!-- <app-graph-view [plugIns]="plugIns"></app-graph-view>
<app-settings-view></app-settings-view>
<app-data-view></app-data-view> -->