<div class="p-grid" style="width:100%">
    <div class="p-col-12" *ngIf="!isChangeSettingsOnlyFlag">
        <p-fileUpload auto="true" multiple="single" showCancelButton="false"  customUpload="true" maxFileSize="500000000"
            (onRemove)="onRemoveFile($event)" chooseLabel="{{'Browse' | translate}}" uploadLabel="Preview"
            (uploadHandler)="uploadFilesAsync($event)" accept=".xlsm,.xlx,.xlsx,application/msexcel">
            <ng-template pTemplate="content">
                <ul>
                    <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
                </ul>
            </ng-template>
        </p-fileUpload>
        <!-- <p-fileUpload #fubauto mode="basic" showCancelButton="true" customUpload="true" accept=".xlsm,.xlx,.xlsx,application/msexcel"
            maxFileSize="500000000" (uploadHandler)="uploadFilesAsync($event)" [auto]="true" chooseLabel="{{'Browse' | translate}}">
        </p-fileUpload> -->
    </div>

    <!-- <div class="p-col-12" *ngIf="!isChangeSettingsOnlyFlag">
        <p-divider layout="horizontal" align="center">
            <span>{{'Choose table' | translate}}</span>
        </p-divider>
    </div> -->
    <div class="p-col-12 ">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown id="excelsheetselection" [options]="excelTableList" [autoDisplayFirst]="false"
                    optionLabel="Name" optionValue="Name" [(ngModel)]="currentConnectorView.connectorSettings.Table" (onChange)="onSettingsChanged()" [style]="{'width':'100%'}">
                </p-dropdown>
                <label for="excelsheetselection">{{'Excel Sheet' | translate}}</label>
            </span>
        </div>
    </div>
    <div class="p-col-12 ">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-inputNumber id="excelSheetStartRow" [(ngModel)]="currentConnectorView.connectorSettings.ReadSettings.StartRow" [min]="1" [max]="100" (onBlur)="onSettingsChanged()" ></p-inputNumber>
                <label for="excelSheetStartRow">{{'Start Row' | translate}}</label>
            </span>
        </div>
    </div>
    <div class="p-col-12 ">
        <div class="p-fluid">
            <div class="field-checkbox">
                <p-checkbox id="hasHeaderCheckbox" [(ngModel)]="currentConnectorView.connectorSettings.ReadSettings.HasHeader" (onChange)="onSettingsChanged()"  [binary]="true"></p-checkbox>
                <label for="hasHeaderCheckbox">{{'Start row has column header' | translate}}</label>
            </div>
        </div>
    </div>


</div>
