
/**
 * Diese Monade abstrahiert über den NULL Pointer. Es können somit keine null oder undefined Fehler mehr auftreten.
 * ACHTUNG: Der Typ null wird noch nicht behandelt.
 * @author: Rouven Thum
 */
export abstract class Option<A> {
    abstract get(): A;
    getOrElse<B>(alt: B): A | B {
        if (this.isEmpty()) {
            return alt;
        } else {
            return this.get();
        }
    }
    map<B>(f: (a: A) => B): Option<B> {
        if (this.isEmpty()) {
            return None.instance;
        } else {
            const v = new Some<B>(f(this.get()));
            return v;
        }
    }
    flatMap<B>(f: (a: A) => Option<B>): Option<B> {
        return this.isEmpty() ? None.instance : f(this.get());
    }
    isEmpty(): boolean {
        return this instanceof None;
    }
    isDefined(): boolean {
        return !this.isEmpty();
    }
    static pure<A>(v: A): Option<A> {
        if (v === undefined || v === null || v == null) {
            return None.instance;
        } else {
            return new Some(v);
        }
    }
}

export class Some<A> extends Option<A> {

    readonly value: A;
    constructor(value: A) {
        super();
        this.value = value;
    }
    get(): A {
        return this.value;
    }
}

export class None extends Option<never> {
    get(): never {
        throw new Error("The value is not available");
    }
    static instance = new None();
}

export class Optionals {

    static pure<A>(v: A): Option<A> {
        if (v === undefined || v === null || v == null) {
            return None.instance;
        } else {
            return new Some(v);
        }
    }

    /**
     * Wenn value gesetzt ist, nimm ihn, ansonsten nimm die Alternative.
     * @param value Wert
     * @param alternative Alternative 
     * @returns Den Wert falls gesetzt, sonst die Alternative
     */
    static getOrElse<A>(value: A | undefined, alternative: A): A {

        if (value !== undefined) {
            return value;
        } else {
            return alternative;
        }
    }
}