import { HttpEventType } from "@angular/common/http";
import {
	Component,
	Input,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewChild,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MessageService, SortEvent } from "primeng/api";
import { Table } from "primeng/table";
import { forkJoin, Observable, of, throwError } from "rxjs";
import { concatMap, map } from "rxjs/operators";
import { CheckBoxEvents, TableEvents } from "src/app/helper/events";
import { Id } from "src/app/helper/id";
import { ExportFormatInfo } from "src/app/models/api/models/ExportModel";
import { DataProviderIdentifier, DataStoreIdentifier } from "src/app/models/api/models/staging/DataProviderIdentifier";
import { DataProviderInfo } from "src/app/models/api/models/staging/DataProviderInfo";
import { DataSegment } from "src/app/models/api/models/staging/DataSegment";
import { ExportDataConfig } from "src/app/models/api/models/staging/ExportDataConfig";
import * as dss from "src/app/models/datasource.model";
import { DataStore } from "src/app/models/datastore.model";
import { DestinationActionEvent, DestinationDialogActionType } from "src/app/models/dialog-actions.model";
import { EndpointInfo } from "src/app/models/odata.model";
import { DataTablePreview, ExportResult } from "src/app/models/staging.model";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { CubesService } from "src/app/services/cubes.service";
import { DatasourcesService } from "src/app/services/datasources.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { SubSink } from "subsink";

@Component({
	selector: "app-psa-table",
	templateUrl: "./psa-table.component.html",
	styleUrls: ["./psa-table.component.scss"],
    providers: [MessageService]
})
export class PsaTableComponent implements OnInit, OnDestroy {
	displayPsa: boolean = true;
	subs = new SubSink();
	//selectedDatasource?: dss.DataSource;
	selectedDataStore?: DataStore;
	psaInfos: dss.PersistentStagingArea[] = [];
	@ViewChild("dt") table!: Table;

	totalRecords: number = 0;
	rowsPerPage: number = 100;
	loading: boolean = false;

	cols: dss.FieldInfo[] = [];
	psaData: any[] = [];
	psaDataFull: any[] = [];

	writeMode: string = "";
	showMetaFields: boolean = false;
	showLatestPackage: boolean = false;

	rowCount: number = 0;
	columnCount: number = 0;
	currentRowCount: number = 0;

	dataPackages: any[];
	selectedDataPackage: any;

    exportFormats: ExportFormatInfo<any>[] = [];

	constructor(
		private bionApi: ApiBackendService,
		private datasourceService: DatasourcesService,
		private cubeService: CubesService,
		private errorService: SystemMessageLogService,
        private translate: TranslateService,
        private messageService: MessageService
	) { }
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngOnInit() {
        // -- export formats
        this.subs.sink = this.datasourceService.getFormats().subscribe(formats => {
        this.exportFormats = formats;
            // if(this.selectedDataStore) {
            //     this.createApiUrl(this.selectedDataStore);
            // } else {
            //     this.createApiUrl();
            // }
        },
            err => this.errorService.handleError(err)
        );
        this.subs.sink = this.datasourceService
        .getDataProviders()
        .subscribe((res: DataProviderInfo<DataProviderIdentifier>[]) => {
            //this.dataProviders = res;
            //this.psaProvider = <DataProviderInfo<PsaIdentifier>>res.find(p => p.ID === 1);
            this.dataStoreProvider = <DataProviderInfo<DataStoreIdentifier>>res.find(p => p.ID === 2);
            //this.dataSourceProvider = <DataProviderInfo<DataSourceIdentifier>>res.find(p => p.ID === 3);
        });
	}


	selectDataPackage(event) {}

	// queryDataSource(ds: dss.DataSource): Observable<[DataTablePreview, number]> {
	// 	const query_obs = this.bionApi.psaApiQuery(ds.id, this.rowsPerPage, 0,false,this.showLatestPackage, this.showMetaFields);
	// 	const count_obs = this.bionApi.psaApiCount(ds.id);
	// 	this.subs.sink = this.bionApi.getPsaOfDataSource(ds.id).subscribe(psa => {
	// 		this.psaInfos = [psa];
	// 		this.writeMode = this.psaInfos[0].writeMode
	// 	}
	// 	);

	// 	const psa_info_obs = forkJoin(query_obs, count_obs);

	// 	return psa_info_obs;
	// }

	init_data(info_result: [dss.DataTypePreview, number]) {
		const res = info_result[0];
		const row_count = info_result[1];

		this.rowCount = row_count;
		this.columnCount = res.Columns.length;

		this.table.columns = res.Columns;
		this.psaDataFull = this.createPsaDataModel(res);
		this.table.value = this.psaDataFull;
		this.totalRecords = row_count;

		//TODO: Wenn showLatest
		this.currentRowCount = res.Rows.length
	}

    initDataStore(ds:DataStore) {
        //this.selectedDataStore = ds;
        this.subs.sink = this.queryDataStore(ds).subscribe(data_and_count => {
                            this.init_data(data_and_count);
                            this.selectedDataStore = ds;
                            this.createApiUrl(ds);
            				this.writeMode = ds.writeMode;
                            this.loading = false;
                        },(err) => {
            				this.psaDataFull = [];
            				this.cols = [];
            				console.log("No PSA Found");
            				this.loading = false;
                            this.errorService.handleError(err);
                        },() => { this.loading = false})
    }
	queryDataStore(ds: DataStore) {
		const query_obs = this.cubeService.datastoreApiQuery(ds.id, this.rowsPerPage, 0, false, this.showMetaFields, this.showLatestPackage);
		const count_obs = this.cubeService.datastoreApiCount(ds.id);
		const psa_info_obs = forkJoin(query_obs, count_obs);

		return psa_info_obs;
	}

	createPsaDataModel(res: dss.DataTypePreview) {
		let data = [];
		for (let i = 0; i < res.Rows.length; i++) {
			let row = [{}];
			for (let j = 0; j < res.Columns.length; j++) {
				row[res.Columns[j]["Name"]] = res.Rows[i][j];
			}
			data.push(row);
		}

		return data;
	}

	onTablePageChanged(event: TableEvents.OnPageChanged<any>) {

		console.log("HALT!");
		console.log(event);

		const first: number = event.first;  			// offset
		const page: number = event.page;				// current page 0-index
		const pageCount: number = event.pageCount;	// all pages
		const rows: number = event.rows;				// rows per page

		// first & rows

		let query_obs: Observable<DataTablePreview> = undefined;
		// if (this.selectedDatasource) {
		// 	query_obs = this.bionApi.psaApiQuery(this.selectedDatasource.id, this.rowsPerPage, first,false,this.showLatestPackage,this.showMetaFields); // TODO: neue Flag showMetaFields noch implementieren
		// }
		if (this.selectedDataStore) {
			query_obs = this.cubeService.datastoreApiQuery(this.selectedDataStore.id, this.rowsPerPage, first, false, this.showMetaFields, this.showLatestPackage);
		}

		if (query_obs === undefined) return;

		this.loading = true;
		query_obs.subscribe(res => {
			this.columnCount = res.Columns.length;
			this.table.columns = res.Columns;
			this.psaDataFull = this.createPsaDataModel(res);
			//this.psaData = this.psaDataFull;
			this.table.value = this.psaDataFull;
            this.cols = res.Columns;

		},
			(err: Error) => {
				this.errorService.handleError(err);
			}, () => {
				this.loading = false
			});
	}
	customSort(event: SortEvent) {
		if(!event.data) {
			return
		}
		event.data.sort((data1, data2) => {
			let value1 = data1[event.field];
			let value2 = data2[event.field];
			let result = null;

			if (value1 == null && value2 != null)
				result = -1;
			else if (value1 != null && value2 == null)
				result = 1;
			else if (value1 == null && value2 == null)
				result = 0;
			else if (typeof value1 === 'string' && typeof value2 === 'string')
				result = value1.localeCompare(value2);
			else
				result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

			return (event.order * result);
		});
	}

	onCloseDialog() {
		this.psaData = [];
		this.psaDataFull = [];
		this.cols = []
	}
	displayDeleteDataPackageDialog() {
		if(this.selectedDataStore) {
			this.cubeService.destinationDialogActionSendEmitter.emit(new DestinationActionEvent(true, "Delete data package", DestinationDialogActionType.deleteDataPackage, "Delete", this.selectedDataStore));
		}
		//this.displayPsa = false;

	}

	onChangeShowMetaFields(event: CheckBoxEvents.OnChange<any> ) {
		const metaFieldsFlag = event.checked;
		this.showMetaFields = metaFieldsFlag;

		if(this.selectedDataStore) {
			this.subs.sink = this.queryDataStore(this.selectedDataStore).subscribe(data_and_count => {
				this.init_data(data_and_count);
				//this.loading = false;
			},(err) => {
				this.errorService.handleError(err)
			},() => { this.loading = false})
		}
	}
	onChangeShowLatestPackage(event: CheckBoxEvents.OnChange<any> ) {
		const latestPackageFlag = event.checked;
		this.showLatestPackage = latestPackageFlag;


		if(this.selectedDataStore) {
			this.subs.sink = this.queryDataStore(this.selectedDataStore).subscribe(data_and_count => {
				this.init_data(data_and_count);
				//this.loading = false;
			},(err) => {
				this.errorService.handleError(err)
			},() => { this.loading = false})
		}

	}

    createApiUrl(ds?: DataStore) {


            this.selectedDataStore = <DataStore>ds;
            const dest_cast= <DataStore>ds;

			this.subs.sink = this.bionApi.getDataStoreEndpointInfo().subscribe((endpoints: EndpointInfo[]) => {

				//this.baseUrl = this.getDataStoreEndpoint();
				if (dest_cast) {
					const selected = endpoints.find(endpoint => dest_cast.id === endpoint.ID);

					if (selected === undefined) {
						throw new Error("There is not endpoint for the selected Data Store with id " + dest_cast.id);
					}

					this.finalUrl = this.getDataStoreEndpoint(selected.EntityName);
				}
				else {
					this.finalUrl = this.getDataStoreEndpoint();
				}


			},
				(err: Error) => {
					this.errorService.handleError(err);
				});

	}
    getDataStoreEndpoint(entity?: string): string {
		return this.getEndpoint(this.baseUrl, entity);
	}

	getEndpoint(oDataUrl: string, entity?: string) {
		const url = window.location.origin + this.apiUrl + oDataUrl;

		const result: string = entity ? url + entity : url;

		return result;
	}

	textToClipboard(url: string) {
		const dummy = document.createElement("textarea");
		document.body.appendChild(dummy);
		dummy.value = url;
		dummy.select();
		document.execCommand("copy");
		document.body.removeChild(dummy);

		this.messageService.add({
			severity: "success",
			summary: this.translate.instant("Message.CopyURLSuccess.Title"),
			detail: this.translate.instant("Message.CopyURLSuccess.Text"),
			//summary: "Url copied!",
			//detail: "Url is copied to clipboard",
		});
	}

	extractDataStoreIdentifier(provider?:DataProviderInfo<DataStoreIdentifier>, selected?:DataStore) : DataStoreIdentifier {
		const identifier_example = Id.assertSet(provider?.IdentifierExample, new Error("Data Store Provider is not available"));
		const identifier = <DataStoreIdentifier>{ ...identifier_example };
		identifier.ID = Id.assertSet(selected?.id, new Error("Please select one single Data Store."));
		return identifier;
	}

    dataStoreProvider?: DataProviderInfo<DataStoreIdentifier>;

	prepareExportConfig(format_config: ExportFormatInfo<any>, ext_url:string): ExportDataConfig<DataProviderIdentifier, any> {

		console.log("downloadFile", format_config);

		//const initial_config = this.exportFormatInfos[0].InitialConfig;
		const initial_config = format_config.InitialConfig;
		const data_segment = new DataSegment();
		let identifier_opt : DataStoreIdentifier | undefined = undefined;

		console.warn("Data Store identifier ist set to ''. Check if this is OK or if it must be 'destination'")

		identifier_opt = this.extractDataStoreIdentifier(this.dataStoreProvider, this.selectedDataStore);

		const identifier = Id.assertSet(identifier_opt, new Error("The identifier is unknown! Please check the extension URL."));

		const config = new ExportDataConfig(identifier, data_segment, initial_config);

		return config;
	}

	/**
	 * Prepares the file for downloading
	 * @param ext_url URL with object type hint: datasource, datastore, ...
	 * @param export_info Export information
	 * @returns
	 */
	prepareDownloadFile(ext_url:string, export_info?: ExportFormatInfo<any>): Observable<[ExportFormatInfo<any>, ExportDataConfig<DataProviderIdentifier, any>]> {
		try {
			const target_export_info = Id.assertSet(export_info, new Error("No format selected"));
			const config = this.prepareExportConfig(target_export_info, ext_url);
			return of([target_export_info, config]);
		} catch (e) {
			const err = <Error>e;
			return throwError(err);
		}
	}
        apiUrl: string = "/api";
        extensionUrl?: string = "dataStore/";
        baseUrl?: string = "/DataWarehouse/DataStore/OData/";
        finalUrl?: string ="";

      onDownloadFile(export_info?: ExportFormatInfo<any>) {

		//console.log("On Download File", export_info);
		console.log("Extension URL", this.extensionUrl);

		this.loading = true;

		const prepObs = this.prepareDownloadFile(this.extensionUrl, export_info);
		// const finalObs = prepObs.pipe(concatMap(prep => this.datasourceService.exportDataProvider(prep[1]).pipe(map(ex_res => {
		// 	const inter_res: [[ExportFormatInfo<any>, ExportDataConfig<DataProviderIdentifier, any>], ExportResult] = [prep, ex_res];
		// 	return inter_res;
		// }))));

		// NEU
		// const use_chunked_stream = false;
		// const export_via_rest = prepObs.pipe(concatMap(config =>this.datasourceService.exportDataProvider(config[1]).pipe(map(res => {
		// 	const final_res:[[ExportFormatInfo<any>, ExportDataConfig<DataProviderIdentifier, any>],number[]] = [config,res.Bytes];
		// 	return final_res;
		// }))));
		// const export_via_stream = prepObs.pipe(concatMap(config =>this.datasourceService.exportDataProviderStream(config[1]).pipe(map(num_res => {
		// 	const final_res:[[ExportFormatInfo<any>, ExportDataConfig<DataProviderIdentifier, any>],number[]] = [config,num_res];
		// 	return final_res;
		// }))));

		//const exportDataProviderObs : Observable<[[ExportFormatInfo<any>, ExportDataConfig<DataProviderIdentifier, any>],number[]]> = use_chunked_stream ? export_via_stream : export_via_rest;


		// NEW STREAMING EXPORT

		// const export_via_chunked_stream = prepObs.pipe(concatMap(config =>this.datasourceService.exportDataProvChunkedStreamBody(config[1]).pipe(map(blob => {
		// 	return blob
		// }))));

		const export_via_chunked_stream_events = prepObs.pipe(concatMap(config =>this.datasourceService.exportDataProvChunkedStream(config[1]).pipe(map(blob => {
			return blob
		}))));

		export_via_chunked_stream_events.subscribe((httpEvent) => {

			if (httpEvent.type === HttpEventType.DownloadProgress) {
				console.log("download progress", httpEvent);
			}
			if (httpEvent.type === HttpEventType.Response) {
				console.log("donwload completed", httpEvent);
				const data = window.URL.createObjectURL(httpEvent.body);

				const link = document.createElement("a");
				link.href = data;
				link.download = export_info.Name + "." + export_info.Extension;

				// this is necessary as link.click() does not work on the latest firefox
				link.dispatchEvent(
					new MouseEvent("click", {
						bubbles: true,
						cancelable: true,
						view: window,
					})
				);

			}
		}, (err: Error) => {
			this.errorService.handleError(err);

		}, () => {
			this.loading = false;
		})

		return

	}



}
