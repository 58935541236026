import mx from './mx-graph-loader'; // <- import values from factory()
import {
    mxCell,
    mxCellRenderer,
    mxEvent,
    mxGraph,
    mxGraphModel,
    mxStylesheet,
} from 'mxgraph';
import {
    WorkflowPortBase,
    PortInfo,
    Table,
    TableStats,
    WorkflowPlugInInfo,
    DataTable,
} from 'src/app/models/designer.models';
// import {
// 	WorkflowNode,
// 	GuiSettings,
// 	NodeProperties,
// 	EngineInfo,
// 	IWorkflowNode,
// } from "src/app/models/workflow.model";

import cloneDeep from 'lodash.clonedeep';
import { IWorkflowNode } from 'src/app/models/workflow.model';
import { EngineInfo } from 'src/app/models/api/com/bion/etl/EngineInfo';
import {
    GuiSettings,
    NodeProperties,
    WorkflowNode,
} from 'src/app/models/api/com/bion/etl/Workflow';
import { Arrays } from 'src/app/helper/arrays';
import { CutInfo } from 'src/app/models/api/com/bion/etl/CutInfo';
import { Id } from 'src/app/helper/id';
import { Optionals } from 'src/app/helper/optional';

/**
 * Basic class for special edges, e.g. if the target is a multi node
 */
export class mxWorkflowEdgeValue {
    Name: string;
}

/**
 * Workflow Node Port Data
 */
export class mxWorkflowNodePortData {
    Table: Table;
    TableStats?: TableStats;
    CutInfo?: CutInfo;

    constructor(table: Table, tableStats?: TableStats, cutInfo?: CutInfo) {
        this.Table = table;
        this.TableStats = tableStats;
        this.CutInfo = cutInfo;
    }
}

/**
 * Port Cell Value with additional fields for data.
 *
 */
export class mxWorkflowPortValue extends PortInfo {
    /**
     *
     * @param name Port Name
     * @param isInput Is Input Port
     * @param isMulti Is Multi Port
     * @param type Port Data Type
     * @param data Port Data. If none is given, the data list is empty.
     */
    constructor(
        name: string,
        isInput: boolean,
        isMulti: boolean,
        type: string = PortInfo.DefaultPort,
        data?: mxWorkflowNodePortData[]
    ) {
        super(name, isInput, isMulti, type);
        this.Data = data ? data : new Array<mxWorkflowNodePortData>();
    }

    Data: mxWorkflowNodePortData[];
}

export class mxNodeState {
    // TODO: add percentage of node
    progress: string;
    isSuccessful?: boolean;
    constructor(progress: string, isSuccessful?: boolean) {
        this.progress = progress;
        this.isSuccessful = isSuccessful;
    }
}

/**
 * Workflow Node cell value for mxGraph
 */
export class mxWorkflowNodeValue implements IWorkflowNode {
    constructor(
        guiSettings: GuiSettings,
        properties: NodeProperties,
        engine: EngineInfo,
        portInfos: mxWorkflowPortValue[],
        name?: string,
        data?: Map<string, mxWorkflowNodePortData[]>,
        nodeState?: mxNodeState
    ) {
        this.GuiSettings = guiSettings;
        this.Properties = properties;
        this.Engine = engine;
        this.PortInfos = portInfos;
        this.Name = name;
        this.NodeState = nodeState;
        // if(nodeState === undefined) 
        // 	this.NodeState = new mxNodeState("");
        // else 
        // 	this.NodeState = nodeState;

        this.Data = data ? data : new Map<string, mxWorkflowNodePortData[]>();
    }

    GuiSettings: GuiSettings;
    Properties: NodeProperties;
    Engine: EngineInfo;
    PortInfos: mxWorkflowPortValue[];
    Name?: string;
    Data?: Map<string, mxWorkflowNodePortData[]>;
    NodeState?: mxNodeState;
}

/**
 * Slightly adjusted graph model to support deep clones of node values
 */
export class mxWorkflowGraphModel extends mx.mxGraphModel {
    constructor(root: mxCell) {
        super(root);
    }

    cellCloned(cell: mxCell): mxCell {
        return this.workflowCellCloned(cell);
    }

    workflowCellCloned(cell: mxCell): mxCell {
        // if this cell is a node cell, perform a deep clone on the value
        const is_node_cell = mxWorkflowExtension.isWorkflowNode(
            cell.getValue()
        );

        // TODO: optimize: Need to clone data?

        if (is_node_cell) {
            const clone = cell.clone();
            const clone_value = cloneDeep(cell.getValue());
            clone.setValue(clone_value);
            return clone;
        }

        const is_port_cell = mxWorkflowExtension.isPortInfo(cell.getValue());
        if (is_port_cell) {
            const clone = cell.clone();
            const clone_value = cloneDeep(cell.getValue());
            clone.setValue(clone_value);
            return clone;
        }

        return cell.clone();
    }
}

/**
 * Workflow Graph based on mxGraph with additional methods for node and port handling and error checking.
 */
export class mxWorkflowGraph extends mx.mxGraph {
    constructor(
        container: HTMLElement,
        model?: mxGraphModel,
        renderHint?: string,
        stylesheet?: mxStylesheet
    ) {
        super(
            container,
            model ? model : new mxWorkflowGraphModel(null),
            renderHint,
            stylesheet
        );

        (this as any).setHtmlLabels(true);  // activate HTML
    }

    // Override the function that creates the DOM node for the cell
    createHtmlElement(cell: any, graph: mxGraph) {
        const div = document.createElement('div');
        div.style.position = 'absolute';
        div.style.whiteSpace = 'nowrap';
        div.style.overflow = 'hidden';

        // Create the input element for the textbox
        const input = document.createElement('input');
        input.setAttribute('type', 'text');
        input.style.width = '100%';

        // Add an event listener to update the cell's value when the input is changed
        mxEvent.addListener(input, 'change', (evt: any) => {
            const newValue = input.value;
            graph.getModel().setValue(cell, newValue);
        });

        // Set the initial value of the input to the cell's value
        input.value = cell.getValue();

        // Add the input element to the div
        div.appendChild(input);

        return div;
    }

    // Override the function that updates the DOM node for the cell
    redrawHtmlShape(node: any, graph: mxGraph) {
        const cell = node.cell;

        // Get the input element for the textbox
        const input = node.getElementsByTagName('input')[0];

        // Set the position of the input element
        input.style.top = `${node.offsetHeight + 5}px`;
        input.style.left = '0';

        // Set the width of the input element to match the cell's width
        input.style.width = `${cell.geometry.width}px`;

        // Set the value of the input element to the cell's value
        input.value = cell.getValue();

        // Set the visibility of the input element based on whether the cell is currently being edited
        const isEditing = graph.isEditing(cell);
        input.style.display = isEditing ? 'inline-block' : 'none';
    }

    workflowCellCloned(cell: mxCell): mxCell {
        // if this cell is a node cell, perform a deep clone on the value
        console.log('workflowCellCloned');

        const is_node_cell = mxWorkflowExtension.isWorkflowNode(
            cell.getValue()
        );

        if (is_node_cell) {
            console.log('Performing deep clone');
            return this.clone_Cell(cell);
        }

        return cell.clone();
    }

    /**
     * Deep Clone a cell
     * @param cell Cell
     * @returns Deep cloned cell
     */
    clone_Cell(cell: mxCell): mxCell {
        // lodash clone - use it if the other approach failse
        // cloneDeep(cell);

        const clone: mxCell = <mxCell>mx.mxUtils.clone(this, cell.mxTransient);
        const cloned_value = mx.mxUtils.clone(cell.getValue(), []);
        clone.setValue(cloned_value);
        return clone;
    }

    isCellFoldable(cell: mxCell, collapse: boolean): boolean {
        return false;
    }

    private name: string = '';
    getName() {
        return this.name;
    }
    setName(name: string) {
        this.name = name;
    }

    private context: string = 'Default';
    getContext() {
        return this.context;
    }
    setContext(context: string) {
        this.context = context;
    }

    /**
     * Returns the label of the cell
     * @param cell Cell
     * @returns Cell Label
     */
    getLabel(cell: mxCell): string | Node {

        if ((this as any).isHtmlLabels()) {

            var style = (this as any).getCurrentCellStyle(cell);
            //console.log(style);          // DEBUG ONLY

            return super.getLabel(cell);
        }

        // check node label
        if (mxWorkflowExtension.cellIsNode(cell)) {
            const node = <WorkflowNode>cell.getValue();
            return node.Name;
        }

        // check port label
        if (mxWorkflowExtension.cellIsPort(cell)) {
            return '';
        }

        // check edges with target multi port
        if (cell.isEdge() && mxWorkflowExtension.cellIsWorkflowEdge(cell)) {
            const edge = <mxWorkflowEdgeValue>cell.getValue();
            return edge.Name;
        }

        // node unkown, return default mxGraph function
        return super.getLabel(cell);
    }

    /**
     * Check if the edge already exists
     * @param source Source Port which should be an output port
     * @param target Target Port which should be an input port
     * @return
     */
    edgeExists(source: any, target: any, newEdge: any): boolean {
        return mxWorkflowExtension.edgeExists(source, target, newEdge, this);
    }

    /**
     * This hook method checks to correctness of the workflow edges: no circles, correct source & target ports
     * @param edge Edge
     * @param source Source Port
     * @param target Target Port
     * @return
     */
    validateEdge(edge: mxCell, source: mxCell, target: mxCell): string | null {
        return mxWorkflowExtension.validateEdge(edge, source, target, this);
    }

    lookupWorkflowNode(id: string, parent: any | undefined): mxCell[] {
        return mxWorkflowExtension.lookupWorkflowNode(id, parent, this);
    }

    getWorkflowCells(parent: any | undefined): mxCell[] {
        return mxWorkflowExtension.getWorkflowCells(parent, this);
    }

    getWorkflowNodes(parent: any | undefined): WorkflowNode[] {
        return mxWorkflowExtension.getWorkflowNodes(parent, this);
    }

    // == HTML label section ==

    // cached: boolean = false;
    // //useHtmlLabels:boolean = true;

    // convertValueToString = function (cell: mxCell) {

    // 	console.log("convertValueToString");

    //     if (this.cached && cell.div != null) {
    //         // Uses cached label
    //         return cell.div;
    //     }
    //     //else if (mx.mxUtils.isNode(cell.value) && cell.value.nodeName.toLowerCase() == 'userobject')
    //     else if (mx.mxUtils.isNode(cell.value, 'userobject') || true )  // enter this block always : for testing :-)
    // 	{
    // 		// get data from cell
    // 		const workflow_value = <mxWorkflowNodeValue>cell.getValue();
    // 		const is_node_cell:boolean = workflow_value.NodeState !== undefined;  //TODO: use utility functions

    // 		console.log("Is node cell: " + is_node_cell);

    // 		if(!is_node_cell) return "";

    // 		console.log("Build node state!");

    //         // TODO: validate!
    //         // Returns a DOM for the label
    //         var div = document.createElement('div');
    //         div.innerHTML = cell.getAttribute('label', 'DOOM!'); // Default Label is empty (RTH)
    //         mx.mxUtils.br(div, 1); // One line break (PH)

    // 		let progress_info = "Not started";
    // 		let is_successful:boolean|undefined = undefined;

    // 		if(is_node_cell) {
    // 			progress_info = workflow_value.NodeState.progress;
    // 			is_successful = workflow_value.NodeState.isSuccessful;
    // 		}

    // 		var statusDiv = document.createElement('div');
    // 		statusDiv.innerHTML = progress_info;

    // 		// // Deactivated because example not needed
    //         // var checkbox = document.createElement('input');
    //         // checkbox.setAttribute('type', 'checkbox');

    //         // if (cell.getAttribute('checked', false) == 'true') {
    //         //     // RTH: default is false
    //         //     checkbox.setAttribute('checked', 'checked');
    //         //     checkbox.defaultChecked = true;
    //         // }

    //         // // Writes back to cell if checkbox is clicked
    //         // mx.mxEvent.addListener(
    //         //     checkbox,
    //         //     mx.mxClient.IS_QUIRKS ? 'click' : 'change',
    //         //     function (evt) {
    //         //         var elt = cell.value.cloneNode(true);
    //         //         elt.setAttribute(
    //         //             'checked',
    //         //             checkbox.checked ? 'true' : 'false'
    //         //         );

    //         //         this.model.setValue(cell, elt);
    //         //     }
    //         // );

    //         //div.appendChild(checkbox);
    // 		div.appendChild(statusDiv);

    //         if (this.cached) {
    //             // Caches label
    //             cell.div = div;
    //         }

    //         return div;
    //     }

    //     return '';
    // };


    cached: boolean = false;
    readonly defaultHtmlLabel: string = "";
    readonly htmlLabelStatusSize = "64px";

    convertValueToString = function (cell: mxCell) {

        console.log("Convert Value to String");     // DEBUG ONLY

        if (this.cached && cell.div != null) {
            // Uses cached label
            return cell.div;
        } else if (mx.mxUtils.isNode(cell.value, 'userobject') || true) {
            const workflow_value = <mxWorkflowNodeValue>cell.getValue();
            //const is_node_cell: boolean = workflow_value.NodeState !== undefined;
            console.log(workflow_value);              // DEBUG ONLY

            const is_workflow_value_unset = Id.isNullOrUndefined(workflow_value);

            if (is_workflow_value_unset) {

                // Der Wert für den Knoten darf nur leer sein, wenn es eine unbeschriftete Kante ist, ansonsten gibt es eine Warnung!

                if (!cell.isEdge()) {
                    console.log("Warning Workflow Cell Value not set");
                }

                return "";
            }

            const is_node_cell = mxWorkflowExtension.isWorkflowNode(cell.getValue());

            if (!is_node_cell) return "";  // Ports bekommen (noch) kein Label


            // Baue das Knoten-HTML-Label

            const label_div = document.createElement('div');
            // div.innerHTML = (cell as any).getAttribute('label', 'DOOM!'); // Default Label is empty (RTH)
            console.log(cell);

            // Das Label des Knoten soll der Nutzer hinterher ändern können.
            // Am Anfang wird das Label auf den Knoten Namen gesetzt, select, filter, etc.
            // Dafür gibt es das Attribut Annotation
            const node_name = workflow_value.Name;
            const node_anno = workflow_value.Properties.Annotation;
            const node_label = node_anno?.Text ? node_anno.Text : node_name;

            // label_div.innerHTML = (cell as any).getAttribute('label', this.defaultHtmlLabel); // Disabled due to new HTML Label logic below
            label_div.innerHTML = node_label;
            
            // Alternative Variante, damit das Label nicht zu lang wird, allerdings werden dann Sachen abgeschnitten
            //label_div.style.width = "64px";
            //label_div.style.overflow = "hidden";

            label_div.style.color = "#0494c5";
            label_div.style.position = "relative";
            label_div.style.top = "-30px";
            mx.mxUtils.br(label_div, 1); // One line break (PH)

            let progress_info = "Not started";
            let is_successful: boolean | undefined = undefined;

            if (is_node_cell) {
                progress_info = Optionals.getOrElse(workflow_value.NodeState?.progress, "");
                is_successful = workflow_value.NodeState?.isSuccessful;
            }

            console.log("WARN - Status Label Style not final. See code comments");
            // TODO: Styles für den Status sind noch nicht perfekt
            //       Wenn das Knoten-Label zu groß ist, wird der Status Text nicht korrekt ausgerichtet
            //       Siehe Ordner .../Requirements Engineering/mxGraph HTML Labels

            const status_div = document.createElement('div');
            status_div.innerHTML = progress_info;
            status_div.style.display = "flex";
            //status_div.style.width = "64px";
            //status_div.style.textAlign = "center";
            status_div.style.justifyContent = "center";
            //status_div.style.alignItems = "center";
            status_div.style.position = "relative";
            status_div.style.bottom = "-35px";
            status_div.style.padding = "px";

            // Set color based on the progress_info value
            switch (progress_info.toLowerCase()) {
                case "not started":
                    status_div.style.backgroundColor = "gray";
                    break;
                case "initial":
                    status_div.style.backgroundColor = "blue";
                    break;
                case "succeeded":
                    status_div.style.backgroundColor = "green";
                    break;
                case "failed":
                    status_div.style.backgroundColor = "red";
                    break;
                case "processing":
                    status_div.style.backgroundColor = "yellow";
                    break;
                default:
                    status_div.style.backgroundColor = "gray";
                    break;
            }

            status_div.style.borderRadius = "4px"; // Adjust the value as per your preference
            //statusDiv.style.boxShadow = "2px 2px 4px rgba(0, 0, 0, 0.3)"; // Adjust the values as per your preference

            const container_div = document.createElement('div');
            //   container_div.style.width = "100%";
            //   containerDiv.style.position = "relative";
            //   containerDiv.style.top = "-30px";
            container_div.appendChild(label_div);
            container_div.appendChild(status_div);

            if (this.cached) {
                // Caches label
                (cell as any).div = container_div;
                // cell.div.appendChild(div);
                // cell.div.appendChild(statusDiv);
            }

            return container_div;
        }

        return '';
    };
}





/**
 * Provides methods which extend an mxGraph to a mxWorkflowGraph.
 * This class is a singleton.
 */
export class mxWorkflowExtension {
    /**
     * Create one port for one direction (in or out) of a workflow node.
     *
     * Note: The port IDs are prefixed with "p" to avoid clashes with existing node IDs.
     * @param v1 Workflow Node
     * @param graph Graph
     * @param i Port Index
     * @param step Relative Step Size ( 0 - 1.0)
     * @param isIn True if input port
     * @param value Port Value
     * @param radius Port Radius
     * @returns The port cell
     */
    static createPort(
        v1: mxCell,
        graph: mxGraph,
        i: number,
        step: number,
        isIn: boolean,
        value: WorkflowPortBase,
        radius: number = 9
    ): mxCell {
        // TODO: Derive size and alternative bounds from graph settings and workflow backend information

        const geo = graph.getModel().getGeometry(v1);
        // const relPosX = if(isIn) 0 else 1
        const relPosX = isIn ? -0.04 : 1.09;
        //const relPosX = isIn ? 0.08 : 0.99;
        geo.alternateBounds = new mx.mxRectangle(20, 20, 100, 50);

        const node_geo = new mx.mxGeometry(
            relPosX,
            (i + 1.15) * step,
            radius * 1.3,
            radius * 1.3
        );
        node_geo.offset = new mx.mxPoint(-radius, -radius);
        node_geo.relative = true;

        const port_node_value = new mxWorkflowPortValue(
            value.Name,
            value.IsInput,
            value.IsMulti,
            value.Type
        );

        // Receive port data from parent workflow node.
        const workflow_node_value = <mxWorkflowNodeValue>v1.getValue();
        const port_data = workflow_node_value.Data?.get(value.Name);
        port_node_value.Data = port_data ? port_data : [];

        const port1 = new mx.mxCell(
            port_node_value,
            node_geo,
            'rounded=1;whiteSpace=wrap;html=1;labelBackgroundColor=none;gradientColor=none;fontColor=#000000;strokeColor=none;arcSize=10;fillColor=#d1d1d1'
        );
        port1.setVertex(true);
        port1.setConnectable(true);

        const port_id = this.getNextPortId();
        port1.setId(port_id);

        return port1;
    }

    // This is necesarry to seperate the node ids from the port ids.
    // Otherwise the port ids could overwrite the node ids and then, the edges cannot connect to the nodes anymore

    static next_port_id = 0;
    /**
     * Gets the next unique port ID.
     * @returns A new unique port id
     */
    static getNextPortId(): string {
        this.next_port_id = this.next_port_id + 1;
        return 'p' + this.next_port_id.toString();
    }

    /**
     * Creates the ports on a frontend node based on the backend node information
     * @param v1 Frontend Graph Node
     * @param node Backend Node Information
     * @param graph Graph
     * @param isIn True if the port is an input port
     */
    static createPorts(
        v1: mxCell,
        node: WorkflowPlugInInfo,
        graph: mxGraph,
        isIn: boolean
    ): mxCell[] {
        //console.log("Create Ports");
        const inPorts = node.Ports.filter((c) => c.IsInput === isIn);
        // TODO: check conversion
        const step = 1.0 / (inPorts.length + 1);
        const result: mxCell[] = inPorts.map(function (
            value: WorkflowPortBase,
            index: number,
            array: WorkflowPortBase[]
        ) {
            return mxWorkflowExtension.createPort(
                v1,
                graph,
                index,
                step,
                isIn,
                value
            );
        });

        return result;
    }

    static insertWokflowNode(
        graph: mxGraph,
        parent: mxCell,
        workflowNode: WorkflowNode,
        ports: WorkflowPortBase[],
        style: string
    ) {
        return this.insertIWorkflowNode(
            graph,
            parent,
            workflowNode,
            ports,
            style,
            workflowNode.GuiSettings,
            workflowNode.ID
        );
    }

    /**
     * Creates a mxGraph Workflow node.
     *
     * Note: When the ID is null or empty, a new ID is generated!
     * @param graph Graph
     * @param parent Parent
     * @param guiSettings Gui Settings
     * @param properties Properties
     * @param ports Ports
     * @param engine Engine Info
     * @param style Style Info
     * @param name Node Label
     * @param id mxGraph node id
     * @returns
     */
    static insertWorkflowNodeFull(
        graph: mxGraph,
        parent: mxCell,
        guiSettings: GuiSettings,
        properties: NodeProperties,
        ports: WorkflowPortBase[],
        engine: EngineInfo,
        style: string,
        name?: string,
        id?: string
    ): mxCell {
        const port_values = ports.map((port) => {
            return new mxWorkflowPortValue(
                port.Name,
                port.IsInput,
                port.IsMulti,
                port.Type
            );
        });

        const node_value = new mxWorkflowNodeValue(
            guiSettings,
            properties,
            engine,
            port_values,
            name
        );

        // Add available meta information as initial data if available
        if (properties.MetaInfo) {
            const node_data = new Map<string, mxWorkflowNodePortData[]>();
            for (let entry of properties.MetaInfo) {
                const port_tables = entry[1].map((mi) => {
                    return new DataTable(mi, []);
                });
                const port_data = port_tables.map(
                    (port_table) => new mxWorkflowNodePortData(port_table)
                );
                node_data.set(entry[0], port_data);
            }

            node_value.Data = node_data;
        }

        const gui = guiSettings;
        if (gui === undefined)
            throw new Error('The Gui Settings are undefined!');

        let target_id: string = undefined;
        if (id === undefined || id.length == 0) target_id = undefined;
        else target_id = id;

        // console.log(gui.X, "GuiX");
        // console.log(gui.Y, "GuiY");

        const v1 = graph.insertVertex(
            parent,
            target_id,
            node_value,
            gui.X,
            gui.Y,
            gui.Width,
            gui.Height,
            style
        );

        // Prevent direct connection -> connect via ports
        v1.setConnectable(false);

        // If HTML Labels is enabled, we render the graph with a label, so set this flag to true!
        if (graph.isHtmlLabels()) {
            console.log("Patch HTML style flag");
            const use_label_style = "noLabel=0";
            const use_no_label_style = "noLabel=1";
            const style_with_label = v1.getStyle().replace(use_no_label_style, use_label_style);
            v1.setStyle(style_with_label);
        }


        // Presets the collapsed size
        v1.geometry.alternateBounds = new mx.mxRectangle(0, 0, 40, 40);

        return v1;
    }

    static insertIWorkflowNode(
        graph: mxGraph,
        parent: mxCell,
        workflowNode: IWorkflowNode,
        ports: WorkflowPortBase[],
        style: string,
        guiSettings: GuiSettings,
        id?: string
    ): mxCell {
        const engine = workflowNode.Engine;
        const name = workflowNode.Name;
        const properties = workflowNode.Properties;

        return this.insertWorkflowNodeFull(
            graph,
            parent,
            guiSettings,
            properties,
            ports,
            engine,
            style,
            name,
            id
        );
    }

    static getWorkflowNodes(
        parent: any | undefined,
        graph: mxGraph
    ): WorkflowNode[] {
        const workflowCells = mxWorkflowExtension.getWorkflowCells(
            parent,
            graph
        );
        const nodes = workflowCells.map((c) => <WorkflowNode>c.getValue());

        return nodes;
    }

    static getWorkflowCells(parent: any | undefined, graph: mxGraph): mxCell[] {
        // const targetParent = parent.getOrElse(graph.getDefaultParent);
        const targetParent =
            parent === undefined ? graph.getDefaultParent() : parent;
        const cells = graph
            .getChildVertices(targetParent)
            .map((c) => <mxCell>c);
        const workflowCells = cells.filter((c) =>
            mxWorkflowExtension.isWorkflowNode(c.getValue())
        );

        return workflowCells;
    }

    static lookupWorkflowNode(
        id: string,
        parent: any | undefined,
        graph: mxGraph
    ): mxCell[] {
        const targetParent =
            parent === undefined ? graph.getDefaultParent() : parent;

        const cells = graph
            .getChildVertices(targetParent)
            .map((c) => <mxCell>c);
        const nodes = cells.filter((c) =>
            mxWorkflowExtension.isWorkflowNode(c.getValue())
        );

        return nodes.filter((n) => n.getId() == id);
    }

    /**
     * Checks if the given port can be connected.
     *
     * Warning: mxGraph calls this function in two places: connecting an edge and cloneCells. Watch out in the latter case, because the objects differ,
     * e.g. the port has no id yet but the port terminals are marked as connected even if mxGraph has not inserted the edges for the cloned cells.
     * @param port Port Cell
     * @return
     */
    static isFreePort(port: mxCell): boolean {
        try {
            const portCell = <mxCell>port;
            const portInfo = <mxWorkflowPortValue>portCell.getValue();

            if (!portInfo.IsInput) return true; // Output ports can always get another output edge

            if (portInfo.IsMulti) return true; // Multi input ports can always get another edge

            const id = portCell.getId();

            if (this.isNullOrUndefined(id)) {
                // If the id is missing this cell is still beeing built, e.g. in cloneCells operation. Therefore we ignore it.
                return true;
            } else {
                return portCell.getEdgeCount() == 0; // Single input ports must not have connected edges
            }
        } catch (e) {
            // case e:Exception => e.printStackTrace()
            console.log(e);
        }

        return false;
    }

    /**
     * This hook method checks to correctness of the workflow edges: no circles, correct source & target ports
     * @param edge Edge
     * @param source Source Port
     * @param target Target Port
     * @return
     */
    static validateEdge(
        edge: mxCell,
        source: mxCell,
        target: mxCell,
        graph: mxGraph
    ): string | null {
        console.log('validateEdge');

        if (!mxWorkflowExtension.isOutToInPort(source, target))
            return 'Connections must go from output to input ports';

        if (mxWorkflowExtension.isCircle(source, target))
            return 'The connection ends in a circle';

        // Check if we can replace this with graph.isMultigraph attribute - Yes, we can :-)
        // if (mxWorkflowExtension.edgeExists(source, target, edge, graph))
        // 	return "The edge exists already";

        // Wir hatten den Teil auskommentiert, weil beim Kopieren, die Kante nicht mitkopiert werden. Problem: Jetzt können Single Input ports mehrere Kanten haben (WAS NICHT ERLAUB SEIN SOLLTE!)
        // (Ref mxGraph -> mxGraph.getEdgeValidationError() Funktion und wird bei cloneCells() aufgerufen)
        // Der Bug ist behoben, die Funktion isFreePort(...) wurde entsprechend angepasst.
        if (!mxWorkflowExtension.isFreePort(target))
            return 'The port is not free';

        // TODO: verlagere diesen check in getEdgeValidationError
        // Prüfe graph.isMultigraph
        //       graph.multiplicities

        // console.log("validateEdge - edge", edge);
        // console.log("validateEdge - source", source);
        // console.log("validateEdge - target", target);

        // console.log("validateEdge - END");

        return null;
    }

    /**
     * Checks if the workflow contains circles
     * @param source Source Port which should be an output port
     * @param target Target Port which should be an input port
     * @return Returns true if the edge produces a circle
     */
    static isCircle(source: any, target: any): boolean {
        // start at the target node. If the target will end in the source node, we have a circle
        const sourceNode = (<mxCell>source).getParent();
        const targetNode = (<mxCell>target).getParent();

        // check successors
        const visitedNodes: Set<mxCell> = new Set();
        const recStack: Set<mxCell> = new Set();
        return mxWorkflowExtension.findCircle(
            sourceNode,
            visitedNodes,
            recStack
        );
    }

    /**
     * Depth first search to find the first circle
     * @param lookUpNode Look up node
     * @param startNode Start node
     * @param visitedNodes Visited nodes
     */
    static findCircle(
        lookUpNode: mxCell,
        visitedNodes: Set<mxCell>,
        recStack: Set<mxCell>
    ): boolean {
        console.log('findCircle');

        if (recStack.has(lookUpNode)) return true;

        if (visitedNodes.has(lookUpNode)) return false;

        if (this.isNullOrUndefined(lookUpNode)) {
            console.log('LookUpNode is null or undefined', lookUpNode);
        }
        visitedNodes.add(lookUpNode);
        recStack.add(lookUpNode);

        const successors = mxWorkflowExtension.getSuccessorNodes(lookUpNode);
        console.log(successors);
        for (let successor of successors) {
            if (
                mxWorkflowExtension.findCircle(
                    successor,
                    visitedNodes,
                    recStack
                )
            )
                return true;
        }

        recStack.delete(lookUpNode);

        return false;
    }

    /**
     * Create a number range with n elements.
     * @param n Number of elements
     * @returns Range with indices
     */
    static makeRange(n: number): number[] {
        return [...Array(n).keys()];
    }

    /**
     * Get the succeeding nodes which are connected via ports
     * @param node Node
     * @return The succeeding nodes
     */
    static getSuccessorNodes(node: mxCell): mxCell[] {
        const ports = mxWorkflowExtension.getPorts(node);

        const outPorts = ports.filter(
            (c) => !(<mxWorkflowPortValue>c.getValue()).IsInput
        );

        const outEdgesLists = outPorts.map((op) =>
            mxWorkflowExtension
                .makeRange(op.getEdgeCount())
                .map((i) => op.getEdgeAt(i))
        );

        const outEdges = this.flatten(outEdgesLists);
        const targetPortsRaw = outEdges.map(
            (out_edge) => <mxCell>out_edge.getTerminal(false)
        );
        const targetPorts = targetPortsRaw.filter((p) => p !== null);

        console.log('getSuccessorNodes [Ports]: ', targetPorts);

        const targetNodes = targetPorts.map((tp) => tp.getParent());

        for (let targetNode of targetNodes) {
            if (targetNode === null || targetNode === undefined) {
                console.log('WARNING: TargetNode is Null or Undefined');
            }
        }

        const targetNodeSafe = Arrays.dropUnsets(targetNodes);

        return targetNodeSafe;
    }

    static flatten<T>(listlist: T[][]) {
        let result = new Array<T>();

        for (let arr of listlist) {
            for (let i of arr) {
                result.push(i);
            }
        }

        return result;
    }

    /**
     * Get all ports of the given cell
     * @param cell Workflow node
     * @return
     */
    static getPorts(cell: mxCell): mxCell[] {
        const range = mxWorkflowExtension.makeRange(cell.getChildCount());
        const children = range.map((i) => cell.getChildAt(i));

        const result = children.filter((c) =>
            mxWorkflowExtension.cellIsPort(c)
        );

        return result;
    }

    static getChildren(cell: mxCell): mxCell[] {
        const range = mxWorkflowExtension.makeRange(cell.getChildCount());
        const children = range.map((i) => cell.getChildAt(i));
        return children;
    }

    /**
     * Check if the data flow goes from output to input
     * @param source Source Port which should be an output port
     * @param target Target Port which should be an input port
     * @return
     */
    static isOutToInPort(source: mxCell, target: mxCell): boolean {
        try {
            const sourcePort = <mxWorkflowPortValue>source.getValue();
            const targetPort = <mxWorkflowPortValue>target.getValue();

            return !sourcePort.IsInput && targetPort.IsInput;
        } catch (e) {
            console.log(e);
        }

        return false;
    }

    static getConnectedEdges(cell: mxCell, graph: mxGraph): Set<mxCell> {
        const edges = graph.getAllEdges(Array(cell));

        const mxEdges = edges.map((e) => <mxCell>e);

        // The framework delivers all edges, including the new to create
        // So we need to remove the new to create from the existing
        const connectedEdges = mxEdges.filter(
            (e) => e.getTerminal(true) != null && e.getTerminal(false) != null
        );
        // const existingEdges = connectedEdges.toSet - newEdgeCell
        const connectedEdgeSet = new Set(connectedEdges);

        return connectedEdgeSet;
    }

    /**
     * Check if the edge already exists
     * @param source Source Port which should be an output port
     * @param target Target Port which should be an input port
     * @return
     */
    static edgeExists(
        source: mxCell,
        target: mxCell,
        newEdge: mxCell,
        graph: mxGraph
    ): boolean {
        const edges = graph.getAllEdges(Array(source));

        const newEdgeCell = <mxCell>newEdge;
        const mxEdges = edges.map((e) => <mxCell>e);

        // The framework delivers all edges, including the new to create
        // So we need to remove the new to create from the existing
        const connectedEdges = mxEdges.filter(
            (e) => e.getTerminal(true) != null && e.getTerminal(false) != null
        );
        // const existingEdges = connectedEdges.toSet - newEdgeCell
        const connectedEdgeSet = new Set(connectedEdges);
        connectedEdgeSet.delete(newEdgeCell);
        const existingEdgesOnly = connectedEdgeSet;
        const existingEdges = Array.from<mxCell>(existingEdgesOnly);

        const result = existingEdges.filter(
            (ee) =>
                ee.getTerminal(true) === source &&
                ee.getTerminal(false) === target
        );

        return result.length > 0;
    }

    /**
     * Check if the cell contains a worklflow node port value
     * @param cell Graph Cell
     * @returns True if the cell is a workflow node port
     */
    static cellIsPort(cell: mxCell): boolean {
        const value = cell.getValue();

        if (value == null) return false;

        return mxWorkflowExtension.isPortInfo(value);
    }

    static isNullOrUndefined(val: any) {
        return val === undefined || val === null;
    }
    static isPortInfo(value: any): value is mxWorkflowPortValue {
        if (this.isNullOrUndefined(value)) {
            return false;
        }

        return (value as mxWorkflowPortValue).IsMulti !== undefined;
    }

    static cellIsWorkflowEdge(cell: mxCell): boolean {
        const value = cell.getValue();

        if (value == null) return false;

        return mxWorkflowExtension.isEdgeInfo(value);
    }

    static isEdgeInfo(value: any): value is mxWorkflowEdgeValue {
        return (value as mxWorkflowEdgeValue).Name !== undefined;
    }

    /**
     * Check if the cell contains a workflow node value
     * @param cell Graph Cell
     * @returns True if the cell is a workflow node
     */
    static cellIsNode(cell: mxCell): boolean {
        const value = cell.getValue();

        if (value == null) return false;

        return mxWorkflowExtension.isWorkflowNode(value);
    }

    static isWorkflowNode(value: any): value is mxWorkflowNodeValue {
        if (value === undefined || value === null) {
            return false;
        }
        return (value as mxWorkflowNodeValue).Engine !== undefined;
    }
}

/**
 * Builds the mxWorkflow graph and adjusts the methods.
 * This implementation prevents compiler errors then die mxGraph library is not loaded at compile time.
 */
export class mxWorkflowGraphFactory {
    createWorkflow(
        container: HTMLElement,
        model?: mxGraphModel,
        renderHint?: string,
        stylesheet?: mxStylesheet
    ): mxGraph {
        const graph = new mxGraph(container, model, renderHint, stylesheet);

        throw new Error('Not implemented yet');
    }
}

// Typescript Mixin
// https://www.typescriptlang.org/docs/handbook/mixins.html

// type Constructor = new (...args: any[]) => {};
// function Scale<TBase extends Constructor>(Base: TBase) {
//   return class Scaling extends Base {
//     // Mixins may not declare private/protected properties
//     // however, you can use ES2020 private fields
//     _scale = 1;

//     setScale(scale: number) {
//       this._scale = scale;
//     }

//     get scale(): number {
//       return this._scale;
//     }
//   };
// }
