import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { forkJoin, Observable } from "rxjs";
import { concatMap, map, mergeMap } from "rxjs/operators";
import { CheckBoxEvents } from "src/app/helper/events";
import { Id } from "src/app/helper/id";
import { RoleMembershipRow, UserLicStripeRow, UserRow } from "src/app/models/api/domain/NewDataSourceModelBase";
import { GroupRole } from "src/app/models/api/models/authorization/GroupRoleLike";
import { UserInfo } from "src/app/models/api/models/session/UserInfo";
import { UserAzureRow } from "src/app/models/azure.model";
import { RegisterUserInfo } from "src/app/models/email.model";
import { createStripeSubArg, createStripeSubResponse, createStripeUserArg, Price, Product, StripeDataList, StripeUser } from "src/app/pages/app-register/app-register.component";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { AuthService } from "src/app/services/auth.service";
import { AuthorizationService } from "src/app/services/authorization.service";
import { EmailService } from "src/app/services/email.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { UserService } from "src/app/services/user.service";
import { SubSink } from "subsink";

/**
 * Shape für die User UI Form.
 */
export interface IUserForm {
	username?: string,
	firstName?: string,
	lastName?: string,
	email?: string,
	isAdmin: boolean,
	password?: string,
	isAzure: boolean,
	azureEmail?: string,
	selectedGroupRoles: RoleMembershipRow[] | GroupRole[]
}

@Component({
	selector: "app-new-user-dialog",
	templateUrl: "./new-user-dialog.component.html",
	styleUrls: ["./new-user-dialog.component.scss"],
})
export class NewUserDialogComponent implements OnInit {
	subs = new SubSink();
	savingInProgress: boolean = false;
	headerText: string = "";
	displayNewUser: boolean = false;
	selectedUser?: UserInfo;

	groupRoles: GroupRole[] = [];
	//selectedGroupRoles: GroupRole[];

	isNew: boolean = true;

	form: IUserForm = {
		username: undefined,
		firstName: undefined,
		lastName: undefined,
		email: undefined,
		isAdmin: false,
		password: undefined,
		isAzure: false,
		azureEmail: undefined,
		selectedGroupRoles: [],
	};

	constructor(
		private userService: UserService,
		private systemLogService: SystemMessageLogService,
		private authService: AuthorizationService,
		private userAuthService: AuthService,
		private errorService: SystemMessageLogService,
		private messageService: MessageService,
		private mailService: EmailService,
		private httpClient: HttpClient,
		private bionApi: ApiBackendService,
		private translate: TranslateService
	) { }

	ngOnInit(): void {
		this.subs.sink = this.authService.getGroupRoles().subscribe((res) => {
			this.groupRoles = res;
			console.log(this.groupRoles);
		},
		(err: Error) => {
			this.errorService.handleError(err);
		});
		this.subs.sink = this.getPricesProducts().subscribe((licenses) => {
			console.log("licenses",licenses);
			this.stripeLicensePrice = licenses.find((license) => { return license[1].name === "Free Test"});
		},
		(err: Error) => {
			this.errorService.handleError(err);
		});
		this.subs.sink = this.userService.selectedMenuUserEmitter.subscribe(
			(res: UserInfo) => {
				this.selectedUser = res;
			},
			(err: Error) => {
				this.errorService.handleError(err);
			}
		);
		this.subs.sink = this.userService.displayNewUser.subscribe(
			(res: boolean) => {
				this.displayNewUser = res;
				this.headerText = "Create user";
				this.isNew = true;
			},
			(err: Error) => {
				this.errorService.handleError(err);
			}
		);

		this.subs.sink = this.userService.displayEditUser.subscribe(
			(display: boolean) => {

				if (this.selectedUser === undefined) {
					console.log("No user selected!");
					return;
				}
				const selected_user: UserInfo = this.selectedUser;

				this.displayNewUser = display;
				this.headerText = "Edit user";
				this.isNew = false;
				this.form.username = selected_user.Username;
				this.form.firstName = selected_user.FirstName;
				this.form.lastName = selected_user.LastName;
				this.form.email = this.selectedUser.EMail;
				this.form.isAdmin = false;

				let azureRowObs = this.userAuthService.getUserAzureRow(this.selectedUser.ID);
				let membershipsObs = this.authService.getRoleMembership(this.selectedUser.ID);

				this.subs.sink = forkJoin(azureRowObs, membershipsObs)
					.subscribe((res) => {
						console.log(res);

						let azureRow = res[0];
						let memberships = res[1];

						if (azureRow.length > 0) {
							this.form.isAzure = true;
							this.form.azureEmail = azureRow[0].accountName;
						}
						if (memberships.length > 0) {
							this.form.selectedGroupRoles = memberships;
						}
					},
						(err: Error) => {
							this.systemLogService.handleError(err);
						});
			},
			(err: Error) => {
				this.errorService.handleError(err);
			}
		);
	}

	displayEditUser() {

	}

	stripeLicensePrice? : [Price,Product] = undefined;

	/**
	 * Joins the Price with the Products Relation. The Products contain more detailed inforamtion of the license.
	 * @returns Price-Product Pairs
	 */
	getPricesProducts() : Observable<[Price,Product][]> {

		const price_op = this.getStripePrices();
		const product_op = this.getStripeProducts();

		return price_op.pipe(mergeMap(prices => {
			return product_op.pipe(map(prodcuts => {
				const result = new Array<[Price,Product]>();
				for(let price of prices) {
					const product_lookup = prodcuts.find(p => p.id === price.product);
					if(product_lookup) {
						result.push([price, product_lookup]);
					}
				}
				return result;
			}))
		}))
	}

		/**
	 * Retrieves the Stripe Prices.
	 * @returns Prices.
	 */
		 getStripePrices() : Observable<Price[]> {
			const api_sub_endpoint: string = "https://api.stripe.com/v1/prices";

			const options = this.makeStripeOptions();

			return this.httpClient.get<StripeDataList<Price>>(api_sub_endpoint, options).pipe(map(list => {
				const prices:Price[] = list.data;
				return prices;
			}));
		}

		/**
		 * Retrieve the Stripe Products
		 * @returns Products
		 */
		getStripeProducts() : Observable<Product[]> {
			const api_sub_endpoint: string = "https://api.stripe.com/v1/products";

			const options = this.makeStripeOptions();

			return this.httpClient.get<StripeDataList<Product>>(api_sub_endpoint, options).pipe(map(list => {
				const products:Product[] = list.data;
				return products;
			}));
		}

		makeStripeOptions() {
			let headers = new HttpHeaders();
			let bearerToken = "bearer " + this.bearerToken;
			headers = headers.append("Authorization", bearerToken);

			let options = {headers: headers};
			return options;
		}

	setAccount(event: CheckBoxEvents.OnChange<any>) {
		this.form.isAzure = event.checked;
	}

	createUser() {
		this.savingInProgress = true;

		// let newUser = new User();
		// newUser.id = -1;
		// newUser.userName = this.form.username;
		// newUser.firstName = this.form.firstName;
		// newUser.lastName = this.form.lastName;
		// newUser.eMail = this.form.email;
		// newUser.isAdmin = false;
		// newUser.passWord = this.form.password;

		if (this.form.username === undefined) throw new Error("The username is not set");
		if (this.form.password === undefined) throw new Error("The password is not set");
		if (this.form.email === undefined) throw new Error("The email is not set");

		if (this.form.isAzure && this.form.azureEmail === undefined) throw new Error("Azure E-Mail is not set!");

		const newUser = new UserRow(-1,
			this.form.username,
			this.form.password,
			this.form.email, false,
			this.form.firstName,
			this.form.lastName);

		this.userService.createUser(newUser).subscribe(
			(res: UserInfo) => {

				// == OLD BLOCK
				let roleMembershipObs = new Observable();
				let azureRowObs = new Observable();

				let memberShipArray: RoleMembershipRow[] = [];

				if (this.form.selectedGroupRoles.length > 0) {
					this.form.selectedGroupRoles.map(groupRole => {
						let singleMembership = new RoleMembershipRow(res.ID, groupRole.ID);
						memberShipArray.push(singleMembership);
					})

					roleMembershipObs = this.authService.createRoleMembershipUtil(memberShipArray);
				}

				// == REVIEWED BLOCK

				// if(this.form.selectedGroupRoles.length > 0) {
				// 	const memberShipArray = this.form.selectedGroupRoles.map((groupRole:GroupRole) => {
				// 		return new RoleMembershipRow(res.ID,groupRole.ID);
				// 	});
				// }



				if (this.form.isAzure) {

					if (this.form.azureEmail === undefined) throw new Error("Azure E-Mail is not set!");

					let azureRow = new UserAzureRow(res.ID, this.form.azureEmail);
					azureRowObs = this.userAuthService.createUserAzureRow(azureRow);
				}

				forkJoin(roleMembershipObs, azureRowObs).subscribe(
					(res) => {

					},
					(err) => {
						this.errorService.handleError(err);
					}
				);
				this.resetForms();
				this.userService.emitNewUser(res);

				this.displayNewUser = false;

				this.messageService.add({
					severity: "success",
					summary: this.translate.instant("Message.CreateUserSuccess.Title"),
					detail: this.translate.instant("Message.CreateUserSuccess.Text1") + res.Username + this.translate.instant("Message.CreateUserSuccess.Text2"),
				});

			},
			(err: Error) => {
				this.errorService.handleError(err);
			}, () => {
				this.savingInProgress = false;
			}
		);
	}
	createUserNew() {
		this.savingInProgress = true;

		if (this.form.username === undefined) throw new Error("The username is not set");
		if (this.form.password === undefined) throw new Error("The password is not set");
		if (this.form.email === undefined) throw new Error("The email is not set");

		if (this.form.isAzure && this.form.azureEmail === undefined) throw new Error("Azure E-Mail is not set!");

		const newUser = new UserRow(-1,
			this.form.username,
			this.form.password,
			this.form.email, false,
			this.form.firstName,
			this.form.lastName);

		this.registerUserViaStripe(newUser).subscribe((result: number) => {
			//this.isSuccessful = true;
			//this.isSignUpFailed = false;

			const hostUrl = window.location.host;
			const protocol = window.location.protocol;
			const finalUrl = protocol + "//" + hostUrl;

			const user_info = new RegisterUserInfo(newUser.userName, newUser.eMail, finalUrl);

			//this.subs.sink = this.mailService.registerUserEmail(newUser).subscribe(
			this.subs.sink = this.mailService.notifyRegisterUser(user_info).subscribe(
				(res) => {
					console.log(res);
					// this.isSuccessful = true;
					// this.isSignUpFailed = false;
					console.log("Registration Email was successfully send!");

					this.resetForms();
					this.savingInProgress = false;
					//this.userService.emitNewUser(newUser);

					this.displayNewUser = false;

					this.messageService.add({
						severity: "success",
						summary: this.translate.instant("Message.CreateUserSuccess.Title"),
						detail: this.translate.instant("Message.CreateUserSuccess.Text1") + user_info.EMail + this.translate.instant("Message.CreateUserSuccess.Text2"),
					});


				},
				(error: HttpErrorResponse) => {
					console.log(error);
					const warn_text = "Registration was successful, but the Email could not be send!";
					console.log(warn_text);
					alert(warn_text + ": " + error.message);
					this.savingInProgress = false;
				}
			);

		},
		(err: Error) => {
			this.errorService.handleError(err);
		}, () => {
			this.savingInProgress = false;
		});

	}

	searchStripeUsers(name: string, eMail: string): Observable<StripeDataList<StripeUser>> {
		let api_sub_endpoint: string = "https://api.stripe.com/v1/customers/search";
		let headers = new HttpHeaders();

		let bearerToken = "bearer " + this.bearerToken;
		headers = headers.append("Authorization", bearerToken);
		headers = headers.append("Content-Type", "application/x-www-form-urlencoded");


		let params = new HttpParams();
		const query = `name:'${name}' OR email:'${eMail}'`;
		params = params.append("query", query);

		let options = { headers: headers, params: params };

		let paramsString = params.toString();
		console.log(paramsString);

		// return this.httpClient.post<StripeUser[]>(api_sub_endpoint,paramsString,options);
		return this.httpClient.get<StripeDataList<StripeUser>>(api_sub_endpoint, options);
	}

	registerStripeUser(createStripeUserArg: createStripeUserArg): Observable<StripeUser> {
		let api_sub_endpoint: string = "https://api.stripe.com/v1/customers";
		let headers = new HttpHeaders();

		let bearerToken = "bearer " + this.bearerToken;
		headers = headers.append("Authorization", bearerToken);
		headers = headers.append("Content-Type", "application/x-www-form-urlencoded");
		let options = { headers: headers };

		let params = new HttpParams();
		params = params.append("name", createStripeUserArg.name);
		params = params.append("email", createStripeUserArg.email);

		let paramsString = params.toString();


		return this.httpClient.post<StripeUser>(api_sub_endpoint, paramsString, options);

	}

	linkStripeUserToBion(bionId: number, stripeId: string): Observable<number> {

		let userStripeRow: UserLicStripeRow = new UserLicStripeRow(bionId, stripeId);

		return this.bionApi.upsertUserLicStripeRow(userStripeRow);
	}

	registerUserViaStripe(user: UserRow) {

		const precheck_obs = this.searchStripeUsers(user.userName, user.eMail).pipe(map(result => {
			const users = result.data;
			console.log("Search Stripe Users: " + users);
			if (users.length > 0) {
				// const names = users.map(u => u.id + ", " + u.name + ", " + u.email).reduce( (a,b) => a + ", " + b );
				const names = users.map(u => u.name + " <" + u.email + ">").reduce((a, b) => a + ", " + b);
				const msg = `The Username or E-mail is already in use: ${names}`;
				// throw throwError(new Error(msg));
				throw new Error(msg);
			}
		}));

		const bionUserObs = precheck_obs.pipe(concatMap(() => this.userService.createUser(user)));


		let stripeUserArg: createStripeUserArg = { name: user.userName, email: user.eMail };

		const stripeUserObs = precheck_obs.pipe(concatMap(() => this.registerStripeUser(stripeUserArg)));

		let stripeSubsObs = stripeUserObs.pipe(concatMap((stripeUserResult) => {
			console.log("stripeUserResult", stripeUserResult);

			const lic_price = Id.assertSet(this.stripeLicensePrice, new Error("The license price is not set!"));
			const createStripeSubArg: createStripeSubArg = { customer: stripeUserResult.id, items: [{ price: lic_price[0].id}] };
			console.log("createStripeSubArg", createStripeSubArg);

			return this.createStripeSubscription(createStripeSubArg);
		}))

		return forkJoin(bionUserObs, stripeSubsObs).pipe(mergeMap((subscriptionResponse) => {
			console.log("subscriptionResponse", subscriptionResponse);
			let bionUser = subscriptionResponse[0];
			let stripeSubs = subscriptionResponse[1];

			return this.linkStripeUserToBion(bionUser.ID, stripeSubs.customer);

		}))
	}
	bearerToken: string = "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";
	createStripeSubscription(stripeSubArg: createStripeSubArg): Observable<createStripeSubResponse> {

		const api_sub_endpoint: string = "https://api.stripe.com/v1/subscriptions";
		let headers = new HttpHeaders();

		const bearerToken = "bearer " + this.bearerToken;
		headers = headers.append("Authorization", bearerToken);
		headers = headers.append("Content-Type", "application/x-www-form-urlencoded");
		const options = { headers: headers };

		let params = new HttpParams();
		params = params.append("customer", stripeSubArg.customer);
		const lic_price = Id.assertSet(this.stripeLicensePrice, new Error("The license price is not set!"));
		params = params.append("items[0][price]", lic_price[0].id);
		const paramsString = params.toString();

		return this.httpClient.post<createStripeSubResponse>(api_sub_endpoint, paramsString, options);
	}

	updateUser() {

		if (this.selectedUser === undefined) throw new Error("No User selected");

		const userinfo = { ...this.selectedUser };

		if (this.form.username === undefined) throw new Error("The username is not set");
		if (this.form.email === undefined) throw new Error("The email is not set!");


		userinfo.Username = this.form.username;
		userinfo.FirstName = this.form.firstName;
		userinfo.LastName = this.form.lastName;
		userinfo.EMail = this.form.email;
		userinfo.IsAdmin = false;

		this.userService.updateUser(userinfo).subscribe(
			(res: number) => {
				if (res === 0) {
					console.log("Update User failed, User not found or no users given");
				} else {
					if (this.form.isAzure && this.form.azureEmail !== undefined) {
						let azureRow = new UserAzureRow(userinfo.ID, this.form.azureEmail);

						this.userAuthService.updateUserAzureRow(azureRow).subscribe(
							(res: number) => {
								console.log(res);
							},
							(err) => {
								this.systemLogService.handleError(err);
							}
						);
					}
					this.resetForms();
					this.savingInProgress = false;
					this.userService.updatedUserEmitter.emit(userinfo);
					this.displayNewUser = false;

					this.messageService.add({
						severity: "success",
						summary: this.translate.instant("Message.UpdateUserSuccess.Title"),
						detail: this.translate.instant("Message.UpdateUserSuccess.Text1") + userinfo.Username + this.translate.instant("Message.CreateUserSuccess.Text2"),
					});
				}
			},
			(err) => {
				this.savingInProgress = false;
				this.systemLogService.handleError(err);
			}
		);
	}

	validateForms() {
		if (this.isNew) {
			this.createUserNew();
		} else {
			this.updateUser();
		}
	}
	resetForms() {
		this.form.username = undefined;
		this.form.email = undefined;
		this.form.firstName = undefined;
		this.form.lastName = undefined;
		this.form.password = undefined;
		this.form.azureEmail = undefined;
		this.form.isAzure = false;
		this.form.isAdmin = false;
		this.form.selectedGroupRoles = [];
	}
}
