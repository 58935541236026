import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-generic-team-widget',
  templateUrl: './generic-team-widget.component.html',
  styleUrls: ['./generic-team-widget.component.scss']
})
export class GenericTeamWidgetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
