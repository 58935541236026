import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthConfig } from 'angular-oauth2-oidc';
import { Subscription } from 'rxjs';
import { FlowMessage, MessageType, OAuthFlowEventService } from 'src/app/services/oauth-flow-event-service';
import { ParentWindowSenderComponent } from '../parent-window-sender/parent-window-sender.component';


/**
 * Testseite für die app-oauth2-flow Komponente.
 * 
 * Hier prüfen wird, ob die Konfigurationseinstellungen ein Fenster erscheinen lassen 
 * und wir die Refresher-Token abgreifen können.
 * 
 * Diese Komponente sollte im Produktivbetrieb nicht verwendet werden.
 */
@Component({
  selector: 'app-test-oauth2-flow',
  templateUrl: './test-oauth2-flow.component.html',
  styleUrls: ['./test-oauth2-flow.component.scss']
})
export class TestOauth2FlowComponent implements OnInit, OnDestroy {

  constructor(private messageService: OAuthFlowEventService) { }

  ngOnDestroy(): void {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  //readonly componentUrl = "http://localhost:4200/#/test/TestOauth2FlowComponent";  // fetchable via router?
  
  // /**
  //  * Die Version mit Raute wird von Google abgelehnt.
  //  */
  // readonly componentUrl = "http://localhost:4200/test/TestOauth2FlowComponent";  // fetchable via router?

  /**
   * Redirect für Popup, wird von Google immer akzeptiert.
   */
  readonly componentUrl = "http://localhost:4200";

  readonly silentRefreshUrl = window.location.origin + '/assets/views/general/oauth2Flow/silent-refresh.html';

  authConfig: AuthConfig = this.getAuthConfig(this.componentUrl);

  googleAuthConfig: AuthConfig = this.getGoogleAuthConfig(this.silentRefreshUrl);

  subscription?: Subscription = undefined;

  readonly googleQueryParams = {
    'access_type': 'offline',               // refresh token
    'include_granted_scopes': 'true',
    'prompt': 'consent'                     // Enforce user prompt and refresh-token otherwise RT is only returned the first time
  };

  ngOnInit(): void {
    // Lade und übergebe die Testeinstellungen für Hubspot.
    // ACHTUNG: Wir checken keine echten Login-Daten ein. Der Flow passiert über das Popup-Fenster

    console.log("Message Service Time", this.messageService.time);

    this.subscription = this.messageService.getSubject().subscribe(m => {

      if (this.use_message_service) {
        this.handleServiceMessage(m);
      }
    })
  }

  static SenderName: string = "TestOauth2FlowComponent";

  sendFlowMessage<T>(value: T, tpe: MessageType) {
    const m: FlowMessage<T> = { Sender: TestOauth2FlowComponent.SenderName, MessageType: tpe, Data: value };
    this.messageService.sendMessage(m);
  }

  handleServiceMessage(m: FlowMessage<any>) {
    if (m.MessageType == MessageType.FlowInitialized && m.Sender == ParentWindowSenderComponent.SenderName) {
      const config = this.getGoogleAuthConfig(this.componentUrl);
      console.log("Child loaded -> send OAuth data", config);

      this.sendFlowMessage(config, MessageType.StartFlow);
    } else {
      console.log("Message unexpected", m);
    }
  }
  handleWindowMessage(evt: any) {
    console.log(evt.data)

    if (evt.data == 5) {
      const config = this.getGoogleAuthConfig(this.componentUrl);
      console.log("Child loaded -> send OAuth data", config);


      //const origin = this.my_origin;
      const origin = location.origin;
      console.log("Validate 'origin' parameter in 'postMessage'", origin);
      this.windowRef.postMessage(config, origin);
    }
  }

  /**
   * Die Auth-Config zum Testen.
   * @returns Auth-Config
   */
  getAuthConfig(redirectUri: string): AuthConfig {

    const auth_config: AuthConfig = {
      clientId: "5ba2694c-6e76-4bbc-bd37-0407c36ccda5",
      loginUrl: "https://app.hubspot.com/oauth/authorize",
      scope: "contacts  social",
      redirectUri: redirectUri,
      requireHttps: false,
    }

    return auth_config;
  }

  getGoogleAuthConfig(redirectUri: string): AuthConfig {

    // == Scopes für Google (Sheets)
    // https://developers.google.com/identity/protocols/oauth2/scopes
    // https://developers.google.com/identity/protocols/oauth2/scopes#sheets
    // https://developers.google.com/sheets/api/scopes

    // offline_access         // für Refresh-Token

    // Refresh Token Resources
    // https://www.googleapis.com/auth/cloud-platform.read-only

    // Sheets Read-Only
    // https://www.googleapis.com/auth/spreadsheets.readonly



    const auth_config: AuthConfig = {
      clientId: "499702120556-djkihbu3igks5b5o3q4kj0ckaua453t6.apps.googleusercontent.com",
      dummyClientSecret: "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",  // Google mal wieder, nicht standard Konform
      loginUrl: "https://accounts.google.com/o/oauth2/v2/auth",
      // scope: "openid profile",
      scope: "https://www.googleapis.com/auth/drive",  // Minimal ... works?
      //scope : "openid profile email https://www.googleapis.com/auth/drive",  // works
      //scope : "offline_access",
      // responseType: "token",
      responseType: "code",
      //redirectUri: "http://localhost:4200",
      redirectUri: redirectUri,
      requireHttps: false,
      oidc: false,
      issuer : "https://accounts.google.com",     // Auto Endpoint Info
      strictDiscoveryDocumentValidation : false   // for google, see: https://manfredsteyer.github.io/angular-oauth2-oidc/docs/additional-documentation/using-an-id-provider-that-fails-discovery-document-validation.html
    }

    // Google does not allow nonce in this request.
    auth_config["disableNonce"] = true;
    auth_config["disableNonceCheck"] = true;

    return auth_config;
  }

  googleManualClicked() {
    this.openAuthWindow();
  }

  use_message_service: boolean = true;

  windowRef: Window = undefined;
  my_origin = "oauth2-flow-parant-window";
  openAuthWindow() {
    //const url = "test/TestOauth2FlowComponentCallback?bionInit=true";
    const url = "http://localhost:4200/#/test/TestOauth2FlowComponentCallback?bionInit=true"

    //this.windowRef = window.open(url, 'newwindow');
    const child_style = "toolbar=no,location=no,directories=no,status=no,menubar=no,titlebar=no,fullscreen=no,scrollbars=1,resizable=no,width=430,height=220,left=500,top=100";
    this.windowRef = window.open(url, 'child', child_style);
    this.windowRef.addEventListener("message", this.receivemessage.bind(this), false);
  }

  receivemessage(evt: any) {
    if (this.use_message_service == false)
      this.handleWindowMessage(evt);
    else
      console.log("Ignoring Window Messages, using service instead");
  }
}
