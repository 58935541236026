<app-create-workflow-dialog [sender]="'WorkflowView'" [selectedWorkflow]="selectedWorkflow">
</app-create-workflow-dialog>
<app-delete-workflow-dialog></app-delete-workflow-dialog>
<app-export-workflows-dialog></app-export-workflows-dialog>
<app-import-workflows-dialog></app-import-workflows-dialog>

<app-add-permission-dialog sender="Workflows" view="WorkflowView"
    (click)="appMain.rightPanelClick = true"></app-add-permission-dialog>
<app-delete-permission-dialog [view]="'WorkflowView'"
    (click)="appMain.rightPanelClick = true"></app-delete-permission-dialog>
<app-change-permission-dialog [view]="'WorkflowView'"
    (click)="appMain.rightPanelClick = true"></app-change-permission-dialog>

<div style="z-index: 1000000;">
    <p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
    </p-toast>
</div>

<!-- <p-overlayPanel #op1 [showCloseIcon]="false">
    <ng-template pTemplate>
        <div style="float: left; width: 300px">
            <button pButton pRipple type="button" icon="pi pi-cog" class="p-button-rounded p-button-text p-mr-2 p-mb-2"
                pTooltip="{{'ChangeDatasourceInformation' | translate }}"
                (click)="displayEditWorkflowDialog(true)"></button>
            <button pButton pRipple type="button" icon="pi pi-trash"
                pTooltip="{{'DeleteWorkflowPermanently' | translate }}" (click)="displayDeleteWorkflowDialog(true)"
                class="p-button-rounded p-button-danger p-button-text p-mr-2 p-mb-2"></button>
        </div>

    </ng-template>
</p-overlayPanel> -->
<div class="spinner-container" *ngIf="loading" @fade>
    <app-loading-screen></app-loading-screen>
</div>

<div style="display:flex;align-items: center;margin-bottom: 20px">
    <img [alt]="'HeaderIcon'" src="assets/layout/images/HeaderIcon_WF.svg" width="50" style="vertical-align: middle" />
    <h2 style="margin: 0px 10px;color:#6941C6;font-weight: bold;">{{'Workflows' | translate }}</h2>
</div>
<!-- <div class="p-grid">
    <div style="height: 2px; width:100%">
        <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '2px'}"></p-progressBar>
    </div>
    <span class="p-col-12 p-md-12 p-lg-12">
        <span>
            <app-generic-top-toolbar #wfObjectToolbar [typeClass]="workflowToolbarTypeClass" [currentView]="'Workflows'"
                @fade></app-generic-top-toolbar>
            <span style="display: flex; align-items: center; float: right">
                <app-general-search #wfObjectSearch [typeClass]="workflowSearchTypeClass" [currentView]="'Workflows'">
                </app-general-search>
                <span style="color: white;margin:0px 10px">|</span>
                <span style="display: flex; align-items: center;">
                    <app-generic-object-tasks #wfObjectTasks [currentView]="'Workflows'"></app-generic-object-tasks>
                </span>
            </span>
        </span>
    </span>

    <span class="p-col" style="display: flex; align-items: center;">
        <app-general-search #wfObjectSearch [typeClass]="workflowSearchTypeClass" [currentView]="'Workflows'">
        </app-general-search>

        <span style="color: white;margin:0px 10px">|</span>
        <span style="display: flex; align-items: center;">
            <app-generic-object-tasks #wfObjectTasks [currentView]="'Workflows'"></app-generic-object-tasks>

        </span>
    </span>
    <span class="p-col-12 p-md-6 p-lg-3">
        <span style="float:right">

            <app-generic-top-toolbar #wfObjectToolbar [typeClass]="workflowToolbarTypeClass" [currentView]="'Workflows'"
                @fade></app-generic-top-toolbar>

        </span>
    </span>
</div> -->
<div style="height:4px">
    <p-progressBar *ngIf="loading" [mode]="'indeterminate'" [style]="{'height': '4px'}"></p-progressBar>
</div>

<div class="card">
    <app-general-object-view [objectList]="workflows" [userDetails]="userDetails" #wfObjectView
        [typeClass]="workflowRecordTypeClass" [currentView]="'Workflows'"></app-general-object-view>
</div>


<!-- <div [hidden]="true">
    <div>
        <p-tabView [scrollable]="false">
            <p-tabPanel header="{{'Activities' | translate }}">
                <ng-template pTemplate="header">
                    <img class="static" style="width: 30px" pTooltip="{{'Activities' | translate }}"
                        tooltipPosition="top" src="assets/layout/images/animatedIcons/auffuhren.png"
                        alt="freya-layout" />
                    <img class="active" style="width: 30px" pTooltip="{{'Activities' | translate }}"
                        tooltipPosition="top" src="assets/layout/images/animatedIcons/auffuhren.gif"
                        alt="freya-layout" />
                </ng-template>
                <app-generic-latest-activities #wfLatestActivities [typeClass]="workflowActivityTypeClass"
                    [currentView]="'Workflows'"></app-generic-latest-activities>
            </p-tabPanel>
            <p-tabPanel header="{{'Permissions' | translate }}"
                *ngIf="appMain.currentUserFull?.UserInfo?.IsAdmin === true">
                <ng-template pTemplate="header">
                    <img class="static" style="width: 30px" pTooltip="{{'Permissions' | translate }}"
                        tooltipPosition="top" src="assets/layout/images/animatedIcons/nutzer.png" alt="freya-layout" />
                    <img class="active" style="width: 30px" pTooltip="{{'Permissions' | translate }}"
                        tooltipPosition="top" src="assets/layout/images/animatedIcons/nutzer.gif" alt="freya-layout" />
                </ng-template>
                <app-user-permissions-list view="WorkflowView"></app-user-permissions-list>
            </p-tabPanel>
            <p-tabPanel header="{{'Preview' | translate }}">
                <ng-template pTemplate="header">
                    <img class="static" style="width: 30px" pTooltip="{{'Preview' | translate }}" tooltipPosition="top"
                        src="assets/layout/images/animatedIcons/bild.png" alt="freya-layout" />
                    <img class="active" style="width: 30px" pTooltip="{{'Preview' | translate }}" tooltipPosition="top"
                        src="assets/layout/images/animatedIcons/bild.gif" alt="freya-layout" />
                </ng-template>
                <div style="width: relative;	overflow: hidden">
                    <app-workflow-graph #workflowGraph style="flex-basis: calc(600px);height: 500px">
                    </app-workflow-graph>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div> -->