import {
	Component,
	OnInit,
	NgZone,
	Input,

} from "@angular/core";
/* Imports */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

// Themes end
am4core.useTheme(am4themes_animated);
@Component({
	selector: "app-world-map",
	templateUrl: "./world-map.component.html",
	styleUrls: ["./world-map.component.scss"],
})
export class WorldMapComponent implements OnInit {
	chart: am4maps.MapChart;
	@Input() countryList;
	finalCountryObj;
	constructor(private zone: NgZone) {}

	ngAfterViewInit() {
		this.zone.runOutsideAngular(() => {
			// Create map instance
			let chart = am4core.create("chartdiv", am4maps.MapChart);
			chart.maxZoomLevel = 1;
			chart.seriesContainer.draggable = false;
			chart.seriesContainer.resizable = false;

			// Set map definition
			chart.geodata = am4geodata_worldLow;

			// Set projection
			chart.projection = new am4maps.projections.Miller();

			// Series for World map
			let worldSeries = chart.series.push(new am4maps.MapPolygonSeries());
			worldSeries.exclude = ["AQ"];
			worldSeries.useGeodata = true;

			let polygonTemplate = worldSeries.mapPolygons.template;
			polygonTemplate.tooltipText = "{name}";
			polygonTemplate.fill = chart.colors.getIndex(0);
			polygonTemplate.nonScalingStroke = true;

			// worldSeries.data = [{
			//     "id": "US",
			//     "name": "United States",
			//     "fill": am4core.color("#01d382")
			//   }, {
			//     "id": "FR",
			//     "name": "France",
			//     "fill": am4core.color("#01d382")
			//   }];
			worldSeries.data = this.finalCountryObj;
			polygonTemplate.tooltipText = "{name} ({id}): {value}";
			// polygonTemplate.tooltipX = am4core.percent(50);
			// polygonTemplate.tooltipY = am4core.percent(50);
			polygonTemplate.propertyFields.fill = "fill";

			// Hover state
			let hs = polygonTemplate.states.create("hover");
			hs.properties.fill = am4core.color("#0494c5");

			this.chart = chart;
		});
	}

	ngOnInit(): void {
		//console.log(this.countryList);

		let countryObj = this.countryList.map((x) => {
			return { id: x.id, value: x.value, fill: am4core.color("#7EB870") };
		});
		this.finalCountryObj = countryObj;
		console.log(this.finalCountryObj);
	}

	ngOnDestroy(): void {
		this.chart.dispose();
	}
}
