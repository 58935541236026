import { Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { Id } from "src/app/helper/id";
import { DataStore, DataStoreTypeInfo } from "src/app/models/datastore.model";
import { DestinationActionEvent, DestinationDialogActionType } from "src/app/models/dialog-actions.model";
import { WriteMode } from "src/app/models/helperClass.model";
import { CubesService } from "src/app/services/cubes.service";
import { DatasourcesService } from "src/app/services/datasources.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { SubSink } from "subsink";

@Component({
	selector: "app-create-datastore-dialog",
	templateUrl: "./create-datastore-dialog.component.html",
	styleUrls: ["./create-datastore-dialog.component.scss"],
	providers: [MessageService],
})
export class CreateDatastoreDialogComponent implements OnInit, OnDestroy {
	subs = new SubSink();
	savingInProgress: boolean = false;
	headerText: string = "";
	isNew: boolean = false;
	displayNewDataStore: boolean = false;
	dataStoreName?: string;
	dataStoreDescription?: string;
	index: number = 0;

	selectedDataStore?: DataStore;
	writeModes: WriteMode[] = [];
	selectedWriteMode?: WriteMode;

	dataStoreTypeInfo: DataStoreTypeInfo[] = [];

	constructor(
		private cubeService: CubesService,
		private systemLogService: SystemMessageLogService,
		private datasourceService: DatasourcesService,
		private messageService: MessageService,
		private translate: TranslateService
	) {}
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngOnInit(): void {
		this.writeModes = this.datasourceService.getWriteModes().filter((mode) => {return mode.name === "Append" || mode.name === "Replace"});
		this.subs.sink = this.cubeService.selectedMenuDataStoreEmitter.subscribe(
			(res: DataStore) => {
				this.selectedDataStore = res;
			}
		);
		this.subs.sink = this.cubeService.destinationDialogActionSendEmitter.subscribe((res:DestinationActionEvent) => {
			if(res.actionType === DestinationDialogActionType.createDestination) {
				this.displayNewDataStore = res.display;
				this.headerText = "Create destination";
				this.isNew = true;
				this.selectedWriteMode = this.writeModes[0];
				this.index = 0;
			}
			if(res.actionType == DestinationDialogActionType.editDestination) {
				this.displayNewDataStore = res.display;
				this.headerText = "Edit destination";
				this.isNew = false;
				this.selectedDataStore = res.dest;
				this.dataStoreName = res.dest?.name;
				this.dataStoreDescription = res.dest?.description;
				this.selectedWriteMode = this.writeModes.find(
					(i) => i.name === res.dest?.writeMode
				);
				if(this.selectedWriteMode.name === "Append") {
					this.index = 0

				};
				if(this.selectedWriteMode.name === "Replace") {
					this.index = 1;
				}
			}
		});
		// this.subs.sink = this.cubeService.displayNewDataStoreDialog.subscribe(
		// 	(res: boolean) => {
		// 		this.displayNewDataStore = res;
		// 		this.headerText = "Create destination";
		// 		this.isNew = true;
		// 		this.selectedWriteMode = this.writeModes[0];
		// 		this.index = 0;
		// 	}
		// );
		// this.subs.sink = this.cubeService.displayEditDataStoreDialog.subscribe(
		// 	(res: boolean) => {
		// 		this.displayNewDataStore = res;
		// 		this.headerText = "Edit destination";
		// 		this.isNew = false;
		// 		this.dataStoreName = this.selectedDataStore?.name;
		// 		this.dataStoreDescription = this.selectedDataStore?.description;
		// 		this.selectedWriteMode = this.writeModes.find(
		// 			(i) => i.name === this.selectedDataStore?.writeMode
		// 		);
		// 		if(this.selectedWriteMode.name === "Append") {
		// 			this.index = 0

		// 		};
		// 		if(this.selectedWriteMode.name === "Replace") {
		// 			this.index = 1;
		// 		}
		// 	}
		// );
		this.subs.sink = this.cubeService
			.getDataStoreTypes()
			.subscribe((result) => {
				this.dataStoreTypeInfo = result;
			});
	}

	selectWriteMode(event) {
		let index: number = event.index;
		this.selectedWriteMode = this.writeModes[index];
		console.log("Selected Update Mode: ", this.selectedWriteMode);
	}
	createDataStore() {

		const selected_write_mode = Id.assertSet(this.selectedWriteMode, new Error("No write mode selected"));

		this.savingInProgress = true;
		const store_name = Id.assertSet(this.dataStoreName, new Error("No Name set"));
		const store_type_opt = this.dataStoreTypeInfo.find(d =>d.Type.startsWith("D"))?.Type;
		const store_type = Id.assertSet(store_type_opt, new Error("Store Type not set"));
		const write_mode = selected_write_mode.name;
		const description = this.dataStoreDescription;
		const table_name = this.dataStoreName + "_" + Date.now();

		const dataStoreObj = new DataStore(
			-1,
			store_name,
			store_type,
			table_name,
			write_mode,
			description
		);

		this.subs.sink = this.cubeService
			.createDataStoreObject(dataStoreObj)
			.subscribe(
				(res: DataStore) => {
					this.cubeService.dataStoresChangedEmitter.emit(res);
					this.savingInProgress = false;
					this.messageService.add({
						severity: "success",
						summary: this.translate.instant("Message.CreateDataStoreSuccess.Title"),
						detail: this.translate.instant("Message.CreateDataStoreSuccess.Text1") + res.id +
						this.translate.instant("Message.CreateDataStoreSuccess.Text2"),
					});
					this.displayNewDataStore = false;
				},
				(err) => {
					this.systemLogService.handleError(err);
				}
			);
	}

	updateDataStore() {
		this.savingInProgress = true;

		const selected_store = Id.assertSet(this.selectedDataStore, new Error("No Data Store selected"));
		const write_mode = Id.assertSet(this.selectedWriteMode, new Error("No write mode selected"));

		const wf = { ...selected_store};
		wf.name = Id.assertSet(this.dataStoreName, new Error("No Name set"));
		wf.writeMode = write_mode.name;
		wf.description = this.dataStoreDescription;

		this.subs.sink = this.cubeService.updateDataStoreObject(wf).subscribe(
			(res: DataStore) => {
				this.cubeService.dataStoresChangedEmitter.emit(res);
				//this.cubeService.updatedDataStoreEmitter.emit(wf);
				this.savingInProgress = false;

				this.messageService.add({
					severity: "success",
					summary: this.translate.instant("Message.UpdateDataStoreSuccess.Title"),
					detail: this.translate.instant("Message.UpdateDataStoreSuccess.Text1") + wf.id +
					this.translate.instant("Message.UpdateDataStoreSuccess.Text2"),
				});

				this.displayNewDataStore = false;
			},
			(err) => {
				this.systemLogService.handleError(err);
			}
		);
	}

	onValidateForms() {
		try {
			this.validateForms();
		} catch(e) {
			console.log(e);
			const err:Error = <Error>e;
			alert(err.message);
		}
	}

	validateForms() {
		if (this.isNew) {
			this.createDataStore();
		} else {
			this.updateDataStore();
		}
	}
	resetForms() {
		this.dataStoreName = undefined;
		this.dataStoreDescription = undefined;
		this.index = 0;
	}
}
