import { Component, OnInit } from '@angular/core';
import { IntegratedDestinationModel } from '../choose-dest-connector-page/choose-dest-connector-page.component';
import { Id } from 'src/app/helper/id';
import { MenuItem, Message } from 'primeng/api';
import { ConsoleLogger, LogLevel } from 'src/app/views/designer/components/workflow-graph/logger';
import { IntegratedDestinationService } from 'src/app/services/integrated-destination.service';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { concatMap } from 'rxjs/operators';
import { DestinationService } from 'src/app/services/destinationService';
import { CubesService } from 'src/app/services/cubes.service';
import { DestinationRow } from 'src/app/models/api/domain/NewDataSourceModelBase';
import { DataStore, DataStoreTypeInfo } from 'src/app/models/datastore.model';
import { WriteMode } from 'src/app/models/helperClass.model';
import { SubSink } from 'subsink';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-destination-page',
  templateUrl: './create-destination-page.component.html',
  styleUrls: ['./create-destination-page.component.scss']
})
export class CreateDestinationPageComponent implements OnInit {

  //items: MenuItem[] = [{ label: '1. Konnektor auswählen', disabled: false, routerLink: '/SourceIntegrationCreateDatasource'}, { label: '2. Datenquelle einrichten',disabled: false,routerLink: '/SourceIntegrationCreateDatasource'  }, { label: '3. Tabellen auswählen',disabled: true, routerLink: '/SourceIntegrationCreateDatasource'  }];
  items: MenuItem[] = [{ label: this.translate.instant('1. Konnektor auswählen'), disabled: false}, { label: this.translate.instant('2. Destination einrichten'),disabled: false }];

  subs = new SubSink;

  private logger = new ConsoleLogger("CreateDatasourceIntPageComponent", LogLevel.Info);

  isLoading = false;
  selected_connector_key?: IntegratedDestinationModel.ConnectorKey<string> = undefined;
  destination_name: string = "";
  config_to_display: string = "";  // Airbyte or Bion
  selected_connector_spec?: IntegratedDestinationModel.ConnectorSpec<string, any>; // Ausgewählte Konnektor Informationen (Konfig, Typ, ...)
  bion_file?:File;

  messages: Message[] = [];
  progressMode: string = "determinate";

  dataStoreTypeInfo: DataStoreTypeInfo[] = [];

  readonly BionConfig = "Bion";
  readonly AirbyteConfig = "Airbyte";

  constructor(private service_api: IntegratedDestinationService, private destService: CubesService,private router: Router, private route: ActivatedRoute, private translate: TranslateService) { }

  ngOnInit(): void {
    this.setBreadcrumb();
    this.subs.sink = this.destService
    .getDataStoreTypes()
    .subscribe((result) => {
      this.dataStoreTypeInfo = result;
    });
    // The old approach has problem with whitespaces in the connector name!
    // this.selected_connector_key = this.getUrlContext();
    // const conn_ob_old = this.service_api.getConnectorSpecs(this.selected_connector_key);

    const conn_ob = this.route.url.pipe(concatMap(segments => {
      const conn_key = this.extractUrlContext(segments);
      this.selected_connector_key = conn_key;
      return this.service_api.getConnectorSpecs(this.selected_connector_key)
    }))

    this.setLoading(true);
    const sub = conn_ob.subscribe(
      spec_res => this.handle_spec_result(spec_res),
      err => this.handle_error(err),
      () => this.setLoading(false)
    );

    // TODO: handle sub
    this.logger.warn("TODO: handle Subscription variable");
  }

  setBreadcrumb() {

    const currentRoute = this.router.url;
    console.log("currentRoute",currentRoute);
    //this.items = <MenuItem[]>[{ label: '1. Konnektor auswählen', disabled: false, routerLink: '/SourceIntegrationChooseConnector'}, { label: '2. Destination einrichten',disabled: false,routerLink: currentRoute  }];
    this.items = <MenuItem[]>[{ label: this.translate.instant('1. Konnektor auswählen'), disabled: false, routerLink: '/chooseDestConnectorPage'}, { label: this.translate.instant('2. Destination einrichten'),disabled: false,routerLink: currentRoute }];

  }



  /**
   * Holt den Konnektor aus der URL
   * @param segments
   * @returns
   */
  extractUrlContext(segments: UrlSegment[]): IntegratedDestinationModel.ConnectorKey<string> {

    const origin_path = segments[1];
    const id_path = segments[2];

    return new IntegratedDestinationModel.ConnectorKey(id_path.path, origin_path.path)
  }

  /**
   * Liefert den Konnektorschlüssel aus der Route
   */
  getUrlContext(): IntegratedDestinationModel.ConnectorKey<string> {

    this.route.url.subscribe(console.log); // UrlSegment[]

    const arr = this.router.url.split('/');
    const id = arr[arr.length - 1];
    const origin = arr[arr.length - 2];
    return new IntegratedDestinationModel.ConnectorKey(id, origin);
  }

  handle_error(e: Error) {
    this.messages.push({ severity: 'error', summary: 'Error', detail: e.message });
    this.logger.error("Error Handler", e);
    this.setLoading(false);
  }

  setLoading(loading: boolean): void {
    this.isLoading = loading;

    // https://www.primefaces.org/primeng-v14-lts/progressbar

    if (this.isLoading) {
      this.progressMode = "indeterminate";
    } else {
      this.progressMode = "determinate";
    }
  }

  /**
 * Läd die Konnektor Konfiguration.
 * @param result Konnektor Spezifikationen
 */
  handle_spec_result(result: IntegratedDestinationModel.ConnectorSpec<string, any>) {
    this.selected_connector_spec = result;

    this.config_to_display = result.ConnectorId.Origin;
    console.log("config_to_display",this.config_to_display);
    this.setLoading(false);
  }

  /**
 * Überprüft den Datasource Namen. Leere Namen sind nicht erlaubt.
 * @param name Datasource Name
 * @throws Name is empty
 */
  validate_ds_name(name: string) {
    if (name.length == 0) throw new Error("The destination name must not be empty");
  }

  on_airbyte_config_set(config: any) {
    this.on_create_destination_gen(config);
  }

  on_bion_config_set(config:any) {
    this.on_create_destination_gen(config);
  }
  on_bion_file_set(file:File) {
    console.log("On Bion FIle");
    this.bion_file = file;
  }

  on_create_destination_gen(config: [{description: string, writeMode:string}]) {
    this.logger.warn("TODO: push connector key & config to parameters")

    this.messages = [];
    this.setLoading(true);
    const con_key = Id.assertSet(this.selected_connector_key, new Error("Connector Key not set!"));

    try {
      const name: string = this.destination_name;
      this.validate_ds_name(name);

      //NEW CLASS (TODO)
      //const arg = new IntegratedDestinationModel.CreateDestinationArg(con_key, config, name);

      // this.service_api.createDestination(arg).subscribe(
      //   ds_result => this.handle_create_ds_result(ds_result),
      //   err => this.handle_error(err),
      //   () => this.setLoading(false)
      // );
      const store_type_opt = this.dataStoreTypeInfo.find(d =>d.Type.startsWith("D"))?.Type;
      const store_type = Id.assertSet(store_type_opt, new Error("Store Type not set"));
      const table_name = this.destination_name + "_" + Date.now();
      //OLD CLASS
      const arg = new DataStore(-1,name,store_type,table_name,config[0].writeMode,config[0].description);
      console.log("DataStoreARg",arg);

      this.destService.createDataStoreObject(arg).subscribe(
        ds_result => this.handle_create_dest_result(ds_result),
        err => this.handle_error(err),
        () => this.setLoading(false)
      );
    }
    catch (e) {
      this.handle_error(e);
    } finally {
      this.setLoading(false);
    }

  }

  // on_create_destination() {

  //   this.logger.warn("TODO: push connector key & config to parameters")

  //   try {

  //     // TODO: Check user input
  //     const con_key = Id.assertSet(this.selected_connector_key, new Error("Connector Key not set!"));
  //     let config: any = undefined;
  //     switch (con_key.Origin) {
  //       case this.BionConfig: config = this.get_bion_config();
  //         break;
  //       case this.AirbyteConfig: config = this.get_airbyte_config();
  //         break;
  //       default:
  //         throw new Error("Origin of " + con_key.Origin + " is unknown");
  //     }

  //     const name: string = this.datasource_name;
  //     this.validate_ds_name(name);

  //     const arg = new IntegratedDestinationModel.CreateDestinationArg(con_key, config, name);

  //     console.log("Create Data Source Argument", arg);

  //     this.service_api.createDestination(arg).subscribe(
  //       ds_result => this.handle_create_ds_result(ds_result),
  //       err => this.handle_error(err),
  //       () => this.setLoading(false)
  //     );
  //   }
  //   catch (e) {
  //     this.handle_error(e);
  //   } finally {
  //     this.setLoading(false);
  //   }
  // }

  handle_create_ds_result(result: IntegratedDestinationModel.CreateDestinationResult<any>) {
    this.logger.info("Destination successfully created!", result);
    this.setLoading(false);
    //this.datasource_key = result.SourceKey;
    //this.gotoDefineStreams(result);

    // TODO: Go to Select Streams Page
    //this.gotoDefineStreams(result.SourceKey, this.bion_file);
  }
  handle_create_dest_result(result: DataStore) {
    this.logger.info("Destination successfully created!", result);
    this.setLoading(false);
    //this.datasource_key = result.SourceKey;
    //this.gotoDefineStreams(result);

    // TODO: Go to Select Streams Page
    //this.gotoDefineStreams(result.SourceKey, this.bion_file);
    this.gotoDestinationView();
  }

  /**
 * Holt die Konfiguration aus dem speziellen Bion Dialog
 */
  get_bion_config(): any {
    throw new Error("Not Implemented");
  }
  /**
   * Holt die Konfiguration aus dem Formly Dialog
   */
  get_airbyte_config(): any {
    throw new Error("Not Implemented");
  }


  gotoDestinationView() {
    this.router.navigate(['/', 'destinations']);
  }

  /**
   * Verbindungseinstellungen testen.
   */
  testSourceClick() {
    console.warn("Test Source Settings not implemented yet");
  }
}
