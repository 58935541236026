

export namespace SplitColumnPlugIn {
    export class SplitColumnInfo {
		SelectedColumn: string;
		SplitMode: string;
		DropOldColumn: boolean;
		SplitPattern: string;
		NewColumnCount: number;
		constructor(SelectedColumn: string,SplitMode: string,DropOldColumn: boolean,SplitPattern: string,NewColumnCount: number) {
			if(SelectedColumn === undefined) throw new Error( "Class 'SplitColumnInfo': Required value 'SelectedColumn' is undefined" );
			if(SplitMode === undefined) throw new Error( "Class 'SplitColumnInfo': Required value 'SplitMode' is undefined" );
			if(DropOldColumn === undefined) throw new Error( "Class 'SplitColumnInfo': Required value 'DropOldColumn' is undefined" );
			if(SplitPattern === undefined) throw new Error( "Class 'SplitColumnInfo': Required value 'SplitPattern' is undefined" );
			if(NewColumnCount === undefined) throw new Error( "Class 'SplitColumnInfo': Required value 'NewColumnCount' is undefined" );
			this.SelectedColumn = SelectedColumn;
			this.SplitMode = SplitMode;
			this.DropOldColumn = DropOldColumn;
			this.SplitPattern = SplitPattern;
			this.NewColumnCount = NewColumnCount;
		}
	}
	export class Settings {
		Infos: Array<SplitColumnInfo>;
		constructor(Infos: Array<SplitColumnInfo>) {
			if(Infos === undefined) throw new Error( "Class 'Settings': Required value 'Infos' is undefined" );
			this.Infos = Infos;
		}
	}
}