import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { Observable, forkJoin } from 'rxjs';
import { AppMainComponent } from 'src/app/app.main.component';
import { PowerBiEntry } from 'src/app/models/api/models/powerbi-report/PowerBiEntry';
import { UserDetailsRow } from 'src/app/models/user.model';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { ObjectSearchService } from 'src/app/services/object-search.service';
import { UserService } from 'src/app/services/user.service';
import { UtilFunctionsService } from 'src/app/services/util-functions.service';
import { SubSink } from 'subsink';
import { PowerbiService } from '../powerbi.service';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { GeneralObjectViewComponent } from '../../objectManagement/general-object-view/general-object-view.component';
import { GeneralSearchComponent, PowerBiSearch } from '../../objectManagement/general-search/general-search.component';
import { GenericLatestActivitiesComponent } from '../../objectManagement/generic-latest-activities/generic-latest-activities.component';
import { PowerBiRecordView, PowerBiViewRecord } from '../../objectManagement/general-object-view/provider-report';
import { DataSource } from 'src/app/models/datasource.model';
import { DataStore } from 'src/app/models/datastore.model';
import { PowerBiActionEvent, PowerBiDialogActionType } from 'src/app/models/api/models/powerbi-report/PowerBiActionEvent';
import { concatMap, map } from 'rxjs/operators';
import { RichtPowerBiCake } from 'src/app/models/api/models/powerbi-report/RichPowerBiCake';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { PowerBiReportService } from 'src/app/services/power-bi-report.service';
import { PowerBIModel } from 'src/app/models/api/models/PowerBIModel';
import { DomSanitizer } from '@angular/platform-browser';
import { GenericTopToolbarComponent } from '../../objectManagement/generic-top-toolbar/generic-top-toolbar.component';
import { ReportToolbar } from '../../objectManagement/generic-top-toolbar/provider-report';

@Component({
  selector: 'app-powerbi-view',
  templateUrl: './powerbi-view.component.html',
  styleUrls: ['./powerbi-view.component.scss'],
  providers: [MessageService],
  animations: [trigger("fade", [
    state("void", style({ opacity: 0 })),
    transition(":enter", [animate(500)]),
    transition(":leave", [animate(500)]),
  ]),]
})
export class PowerbiViewComponent implements OnInit {
  navMenuItem: MenuItem[] = [];
  private subs = new SubSink();
  loading: boolean = false;
  displaySearchPanel: boolean = true;
  displayWidgets: boolean = true;
  // WF specific classes
  userDetails: UserDetailsRow[] = [];
  reports:PowerBiEntry[] = [];
  selectedReport?: PowerBiEntry;
  // New Type Classes
  powerBiViewRecordTypeClass: PowerBiViewRecord = new PowerBiViewRecord([],[], this.biReportService,  this.systemLogService, this.objectSearchService, this.utilService);
  powerBiSearchTypeClass: PowerBiSearch = new PowerBiSearch(this.bionApi, this.systemLogService, this.userService, this.utilService, this.biService, this.objectSearchService);
  powerBiToolbarTypeClass: ReportToolbar = new ReportToolbar(this.biReportService,this.appMain);

  //workflowViewActivityTypeClass = new PowerBiViewActivity([], this.biService,this.userDetails, this.utilService);
  //workflowViewBottomToolbarTypeClass = new WorkflowBottomToolbar(this.bionApi, this.biService);


  reports_new: PowerBIModel.ReportInfo[] = [];


  @ViewChild("pbiObjectToolbar") wfObjectToolbar!: GenericTopToolbarComponent<PowerBiRecordView>;
  @ViewChild("pbiObjectView") pbiObjectView!: GeneralObjectViewComponent<PowerBiEntry, PowerBiRecordView, any,any>;
  @ViewChild("pbiObjectSearch") pbiObjectSearch!: GeneralSearchComponent<[PowerBiEntry[], PowerBiEntry[], UserDetailsRow[], DataSource[], DataStore[]], any>;
  @ViewChild('pbiLatestActivities') pbiLatestActivities!: GenericLatestActivitiesComponent<PowerBiEntry, any>;
  constructor(
    private bionApi: ApiBackendService,
    public appMain: AppMainComponent,
    private biService: PowerbiService,
    private biReportService: PowerBiReportService,
    private systemLogService: SystemMessageLogService,
    private userService: UserService,
    private objectSearchService: ObjectSearchService,
    private utilService: UtilFunctionsService,
    public translate: TranslateService,
    //public experimentalApi: ExperimentalApi,
    public router: Router,
    private sanitizer: DomSanitizer,
    public messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.biReportService.PowerBiActionEmitter.subscribe(
      (event: PowerBiActionEvent) => {
        if(event.actionType === PowerBiDialogActionType.ReportSelected) {
          this.selectedReport = event.report;

          if(this.selectedReport === undefined) {
            this.appMain.onRightPanelClick(false);
          } else {
              this.appMain.onRightPanelClick(true);
          }
        }
        if(event.actionType === PowerBiDialogActionType.ReportsChanged) {
          this.subs.sink = this.initViewNew().subscribe(() => {
            console.log("Load Successully)")
            this.loading = false;
          })
        }

      }
    );

    this.subs.sink = this.initViewNew().subscribe((bool) => {
      console.log("Load Successully)")
        this.loading = false;
      }, err => {
        this.systemLogService.handleError(err);
    });

  }


  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }


  initViewNew(rp?: PowerBiEntry): Observable<boolean> {
    this.loading = true;

    const userDetailsObs = this.userService.getUserDetailsRow();
    const reportObs = this.biReportService.getReports();

    const obs = forkJoin(reportObs, userDetailsObs);

    // this.subs.sink = obs.subscribe(() => {

    // },(err: Error) => {
    //   this.systemLogService.handleError(err);
    // })

    return obs.pipe(concatMap(rich_data => {
      const data = rich_data[0];

      let entries = data.map((report) => {
        const entry = new PowerBiEntry(report.Id,report.Title, this.sanitizer.bypassSecurityTrustResourceUrl(report.Url));
        return entry

      })

      let views = entries.map((entry) => {
        return new RichtPowerBiCake.PowerBiView(entry,[],[],[],[],[]);
      });

      this.powerBiViewRecordTypeClass = new PowerBiViewRecord(views, this.userDetails, this.biReportService, this.systemLogService, this.objectSearchService, this.utilService);
      const top_obs = this.wfObjectToolbar.setToolbar();

      const object_obs = this.pbiObjectView.setObjects(entries, this.powerBiViewRecordTypeClass);
      return forkJoin(Array( object_obs, top_obs)).pipe(map(a => a.reduce((a, b) => a && b)));
      // this.reports_new = rich_data[0];
      // return true;
    }))
  }


  onOpenReport() {
    const event= new PowerBiActionEvent(true,"Open report",PowerBiDialogActionType.openReport,"Open",this.selectedReport)

      window.open('/#/reportsFull/' + this.selectedReport.id, '_blank');
      this.biReportService.emitPowerBiAction(event);
  }


}
