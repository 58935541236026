export namespace TrimPlugIn {
    export class TrimInfo {
        ColumnName: string;
        RemoveChars: string;
        constructor(ColumnName: string,RemoveChars: string) {
            if(ColumnName === undefined) throw new Error( "Class 'TrimInfo': Required value 'ColumnName' is undefined" );
            if(RemoveChars === undefined) throw new Error( "Class 'TrimInfo': Required value 'RemoveChars' is undefined" );
            this.ColumnName = ColumnName;
            this.RemoveChars = RemoveChars;
        }
    }
    export class Settings {
        TrimInfos: Array<TrimInfo>;
        constructor(TrimInfos: Array<TrimInfo>) {
            if(TrimInfos === undefined) throw new Error( "Class 'Settings': Required value 'TrimInfos' is undefined" );
            this.TrimInfos = TrimInfos;
        }
    }
}