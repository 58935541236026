import { Observable } from "rxjs";
import { PowerBIModel } from "../models/api/models/PowerBIModel";
import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable, Output } from "@angular/core";
import { ReportPermission } from "../models/api/models/integrate/ReportPermission";
import { ApiServiceUtils } from "./api-util";
import { PowerBiActionEvent, PowerBiDialogActionType } from "../models/api/models/powerbi-report/PowerBiActionEvent";
import { PowerBiEntry } from "../models/api/models/powerbi-report/PowerBiEntry";

/**
 * Manages Power BI Reports & Permissions
 */
@Injectable({
    providedIn: "root",
})
export class PowerBiReportService {

    @Output() selectedReportEmitter = new EventEmitter<PowerBIModel.ReportInfo>();
    @Output() PowerBiActionEmitter = new EventEmitter<PowerBiActionEvent>();
    
    @Output() reportChangedEmitter = new EventEmitter<string>();

    constructor(private http: HttpClient) { }

    /**
     * Registriert neue Berichte und legt die Leserechte an. EXPERIMENTEL
     * Dies ist eine API Funktion.
     * @param arg Argument
     * @returns Alle angelegten Berichtseinträge.
     */
    public createReports_Exp(arg: PowerBIModel.CreateReportsArg): Observable<[PowerBIModel.ReportInfo, ReportPermission[]]> {
        console.log("Warning: API ist experimental & unprotected");
        return this.http.post<[PowerBIModel.ReportInfo, ReportPermission[]]>("/exp/Reporting/PowerBI/api/createReports", arg);
    }

    // CRUD

    /**
     * Liefert alle Berichte. EXPERIMENTEL
     * @param id Berichtsfilter
     * @returns Berichte
     */
    public getReports_Exp(id?: number) {
        console.log("Warning: API ist experimental & unprotected");
        const opt = ApiServiceUtils.makeOptions({ param: "id", value: id });
        return this.http.get<PowerBIModel.ReportInfo[]>("/exp/Reporting/PowerBI/Reports", opt);
    }



    /**
     * Registriert neue Berichte und legt die Leserechte an.
     * Dies ist eine API Funktion.
     * @param arg Argument
     * @returns Alle angelegten Berichtseinträge.
     */
    public createReports(arg: PowerBIModel.CreateReportsArg): Observable<[PowerBIModel.ReportInfo, ReportPermission[]]> {
        console.log("Warning: API ist experimental & unprotected");
        return this.http.post<[PowerBIModel.ReportInfo, ReportPermission[]]>("/api/Reporting/PowerBI/api/createReports", arg);
    }

    // CRUD

    /**
     * Liefert alle Berichte.
     * @param id Berichtsfilter
     * @returns Berichte
     */
    public getReports(id?: number) {
        console.log("Warning: API ist experimental & unprotected");
        const opt = ApiServiceUtils.makeOptions({ param: "id", value: id });
        return this.http.get<PowerBIModel.ReportInfo[]>("/api/Reporting/PowerBI/Reports", opt);
    }

    public createReportInfo(item: PowerBIModel.ReportInfo): Observable<PowerBIModel.ReportInfo> {
		return this.http.post<PowerBIModel.ReportInfo>("/api/Reporting/PowerBI/Reports", item);
	}
	public updateReportInfo(item: PowerBIModel.ReportInfo): Observable<number> {
		return this.http.put<number>("/api/Reporting/PowerBI/Reports", item);
	}
	public deleteReportInfo(id:number): Observable<number> {
		const options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.delete<number>("/api/Reporting/PowerBI/Reports", options);
	}


    // Permissions - CRUD
    public getReportPermission(id?:number, accessible?:number): Observable<ReportPermission[]> {
		const options = ApiServiceUtils.makeOptions({ param: "id", value: id }, { param: "accessible", value: accessible });
		return this.http.get<ReportPermission[]>("/api/Reporting/PowerBI/Reports/Auth/Permissions", options);
	}
	public createReportPermission(item: ReportPermission): Observable<ReportPermission> {
		return this.http.post<ReportPermission>("/api/Reporting/PowerBI/Reports/Auth/Permissions", item);
	}
	public updateReportPermission(item: ReportPermission): Observable<number> {
		return this.http.put<number>("/api/Reporting/PowerBI/Reports/Auth/Permissions", item);
	}
	public deleteReportPermission(id:number): Observable<number> {
		const options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.delete<number>("/api/Reporting/PowerBI/Reports/Auth/Permissions", options);
	}


    // GUI Event Handler

    emitPowerBiAction(evt: PowerBiActionEvent) {
        this.PowerBiActionEmitter.emit(evt);

        if(evt.actionType === PowerBiDialogActionType.ReportSelected) {
            const reportInfo = new PowerBIModel.ReportInfo(evt.report.id,evt.report.title,evt.report.url.toString())
            this.selectedReportEmitter.emit(reportInfo);

        }

      }
    //   emitSelectedReport(evt: PowerBIModel.ReportInfo) {
    //     this.selectedReportEmitter.emit(evt);
    //   }

}
