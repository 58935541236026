<div class="p-grid">
    <div class="p-col-6 headerSection">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown [autoDisplayFirst]="false" appendTo="body"  id="encriptionMethodOptions" [options]="samplingMethods"
                    [(ngModel)]="selectedSamplingMethod" optionLabel="name" (onChange)="onUpdateSettings(false)">
                </p-dropdown>

                <label for="encriptionMethodOptions">{{'Sampling Method' |translate }}</label>
            </span>
        </div>
    </div>
    <div class="p-col-6 headerSection" *ngIf="selectedSamplingMethod.value !== 5">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown id="columnNameText" appendTo="body"  *ngIf="samplingSettings.hasError === false" filterBy="ColumnName" [autoDisplayFirst]="false" [options]="columnList"
                    [autoDisplayFirst]="false" [(ngModel)]="columnName" (onChange)="onUpdateSettings(false)">
                </p-dropdown>
                <p-dropdown id="columnNameText" appendTo="body" *ngIf="samplingSettings.hasError === true" filterBy="ColumnName" [autoDisplayFirst]="false" class="ng-invalid ng-dirty"  [options]="columnList"
                [autoDisplayFirst]="false" [(ngModel)]="columnName" (onChange)="onUpdateSettings(false)">
            </p-dropdown>
                <label for="columnNameText">{{'Column' |translate }}</label>
            </span>
        </div>
    </div>
    <div class="p-col-6 headerSection" *ngIf="selectedSamplingMethod.value === 5">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-multiSelect id="columnsDropDownList" appendTo="body" *ngIf="samplingSettings.hasError === false"  [filter]="true" [options]="columnList" [(ngModel)]="columnNames"
                    [autoDisplayFirst]="false" (onChange)="onUpdateSettings(false)">
                </p-multiSelect>
                <p-multiSelect id="columnsDropDownList" appendTo="body"  *ngIf="samplingSettings.hasError === true" [filter]="true" class="ng-invalid ng-dirty"  [options]="columnList" [(ngModel)]="columnNames"
                    [autoDisplayFirst]="false" (onChange)="onUpdateSettings(false)">
                </p-multiSelect>
                
                <label for="columnsDropDownList">{{'Columns' |translate }}</label>
            </span>
        </div>
    </div>


    <div class="p-col-12" *ngIf="selectedSamplingMethod.value !== 3">
        <div class="p-fluid">
            <div class="p-inputgroup">
                <span class="p-float-label">
                    <input type="number" id="UseAbsSampleInput" [(ngModel)]="sampleSizeInput"
                        (blur)="onUpdateSettings(false)" pInputText>

                    <label for="UseAbsSampleInput">{{'Sampling Size' |translate }}</label>
                </span>
                <span *ngIf="selectedSamplingMethod.value !== 3" class="p-inputgroup-addon">
                    <p-inputSwitch [(ngModel)]="UseAbsSample" (click)="onUpdateSettings(false)"></p-inputSwitch>
                </span>
                <span class="p-inputgroup-addon" *ngIf="UseAbsSample === false">PCT</span>
                <span class="p-inputgroup-addon" *ngIf="UseAbsSample === true">ABS</span>
            </div>
        </div>
    </div>
    <div class="p-col-12" *ngIf="selectedSamplingMethod.value !== 3 && selectedSamplingMethod.value !== 5">
        <p-inputSwitch id="SampleReplacementBoolean" [(ngModel)]="SampleReplacementBoolean"></p-inputSwitch>
        <label for="SampleReplacementBoolean">{{'Sample with replacement' |translate }}</label>
    </div>

    <div class="p-col-12" [ngSwitch]="selectedSamplingMethod.value">
        <!-- <div *ngSwitchCase="1">
            Test
        </div> -->
        <div *ngSwitchCase="2">
            <p-table *ngIf="samplingSettings.settings" [value]="WeightedValues" dataKey="ColumnName" editMode="row"
                [reorderableColumns]="false">
                <ng-template pTemplate="header">
                    <tr>
                        <th pReorderableColumn tooltipPosition="top" pTooltip="Column that should be filtered on">Record
                            Entry</th>
                        <th pReorderableColumn tooltipPosition="top"
                            pTooltip="Filter method to apply to selected column">{{'Weight' |translate }} (in %)</th>
                        <th style="width:3rem">
                            <button pButton pRipple type="button" icon="pi pi-plus" label="{{'Add' | translate}}"
                                tooltipPosition="bottom" class="p-button-rounded p-button-text p-mr-2 p-mb-2"
                                pTooltip="{{'Add' | translate}}" (click)="addRow()"></button>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-column let-editing="editing" let-ri="rowIndex">
                    <tr [pEditableRow]="column" [pReorderableRow]="ri"
                        [ngClass]="{'row-accessories': column.hasError === true}">
                        <td>
                            <!-- <p-dropdown appendTo="body" *ngIf="column.hasError === false" [options]="columnList"
                                [(ngModel)]="column.ColumnName" (onChange)="onUpdateSettings(false)" autoWidth="false"
                                [style]="{'width':'100%'}"></p-dropdown>
                            <p-dropdown *ngIf="column.hasError === true" appendTo="body" [options]="columnList"
                                [(ngModel)]="column.ColumnName" class="ng-invalid ng-dirty"
                                (onChange)="onUpdateSettings(false)" autoWidth="false" [style]="{'width':'100%'}">
                            </p-dropdown> -->
                            <input pInputText type="text" [(ngModel)]="column.ColumnName" [autoDisplayFirst]="false"
                                (blur)="onUpdateSettings(false)" [ngModelOptions]="{standalone: true}">
                        </td>
                        <td>
                            <input pInputText type="number" [(ngModel)]="column.Weight" (blur)="onUpdateSettings(false)"
                                [ngModelOptions]="{standalone: true}">
                        </td>
                        <td>
                            <div class="ui-table-buttongroup">
                                <button pButton pRipple type="button"
                                    class="p-button-rounded p-button-text ui-button-danger" icon="pi pi-trash"
                                    pTooltip="Delete entry" (click)="onDeleteEntry(ri)"
                                    tooltipPosition="bottom"></button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="3">{{'NoEntryFound' |translate }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div *ngSwitchCase="3">
            <div class="p-grid">
                <div class="p-col-4">
                    <div class="p-fluid">
                        <span class="p-float-label">
                            <input id="StartRowInput" [(ngModel)]="StartRow" (blur)="onUpdateSettings(false)" pInputText
                                type="number">
                            <label for="StartRowInput">{{'Start Row' |translate }}</label>
                        </span>

                    </div>
                </div>
                <div class="p-col-4">
                    <div class="p-fluid">
                        <span class="p-float-label">
                            <input id="EndRowInput" [(ngModel)]="EndRow" (blur)="onUpdateSettings(false)" pInputText
                                type="number">
                            <label for="EndRowInput">{{'End Row' |translate }}</label>
                        </span>
                    </div>
                </div>
                <div class="p-col-4">
                    <div class="p-fluid">
                        <span class="p-float-label">
                            <input id="StepRowInput" [(ngModel)]="StepRow" (blur)="onUpdateSettings(false)" pInputText
                                type="number">
                            <label for="StepRowInput">{{'Each n rows' |translate }}</label>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div *ngSwitchCase="4">
            <div class="p-grid">
                <div class="p-col headerSection">
                    <div class="p-fluid">
                        <span class="p-float-label">
                            <p-dropdown id="matchModeName" appendTo="body" [options]="matchModeOptions" [(ngModel)]="selectedMatchMode"
                                (onChange)="onUpdateSettings(false)" optionLabel="name"></p-dropdown>
                            <label for="matchModeName">{{'Match Mode' |translate }}</label>
                        </span>
                    </div>
                </div>
                <div class="p-col-3 headerSection">
                    <button pButton label="{{'Add' |translate }}" icon="fa fa-plus" (click)="onAddFilter()"></button>
                </div>
            </div>


            <p-table [value]="SamplingFilterInfos" dataKey="ColumnName" editMode="row" [reorderableColumns]="false">
                <ng-template pTemplate="header">
                    <tr>
                        <!-- <th style="width:3rem"></th> -->
                        <th tooltipPosition="top" pTooltip="Column that should be filtered on">{{'Column' |translate }}
                        </th>
                        <th tooltipPosition="top" pTooltip="Filter method to apply to selected column">{{'Filter
                            Criteria' |translate }}
                        </th>
                        <th tooltipPosition="top" pTooltip="Match Expression">{{'Filter Value' |translate }}</th>
                        <th style="width:3rem"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-column let-editing="editing" let-ri="rowIndex">
                    <tr [pEditableRow]="column" [pReorderableRow]="ri"
                        [ngClass]="{'row-accessories': column.hasError === true}">
                        <!-- <td>
                            <span class="pi pi-bars" pReorderableRowHandle (onChange)="onChangeSettings(this.filterList)"></span>
                        </td> -->
                        <td>
                            <p-dropdown appendTo="body" [options]="columnList" *ngIf="column.hasError === false" [(ngModel)]="column.ColumnName"
                                [autoDisplayFirst]="false" (onChange)="onUpdateSettings(false)" autoWidth="false"
                                [style]="{'width':'100%'}">
                            </p-dropdown>
                            <p-dropdown *ngIf="column.hasError === true" appendTo="body" [options]="columnList"
                                [(ngModel)]="column.ColumnName" class="ng-invalid ng-dirty"
                                (onChange)="onUpdateSettings(false)" autoWidth="false" [style]="{'width':'100%'}">
                            </p-dropdown>
                        </td>
                        <td>
                            <p-dropdown appendTo="body" [options]="filterTypeOptions" optionValue="value"
                                [(ngModel)]="column.FilterType" optionLabel="name" dataKey="value"
                                (onChange)="onUpdateSettings(false)" autoWidth="false" [style]="{'width':'100%'}">
                            </p-dropdown>
                        </td>
                        <td>
                            <input pInputText [(ngModel)]="column.FilterValue" (blur)="onUpdateSettings(false)"
                                [ngModelOptions]="{standalone: true}">
                        </td>
                        <td>
                            <div class="ui-table-buttongroup">
                                <button pButton pRipple type="button"
                                    class="p-button-rounded p-button-text ui-button-danger" icon="pi pi-trash"
                                    pTooltip="Delete entry" (click)="onDeleteEntry(ri)"
                                    tooltipPosition="bottom"></button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="4">{{'NoEntryFound' |translate }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>


    </div>


</div>
