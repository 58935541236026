<p>PostgreSQL Configuration</p>

<div group="db" class="card">

    <div class="field">
        <label htmlFor="host">Host</label>
        <input pInputText id="host" aria-describedby="host-help" [(ngModel)]="data.host" />
        <small id="host-help">Hostname of the database.</small>
    </div>
    <div class="field">
        <label htmlFor="port">Port</label>
        <input pInputText id="port" aria-describedby="port-help" [(ngModel)]="data.port" />
        <small id="port-help">Port of the database.</small>
    </div>
    <div class="field">
        <label htmlFor="db">DB</label>
        <input pInputText id="db" aria-describedby="db-help" [(ngModel)]="data.database" />
        <small id="db-help">DB helptext.</small>
    </div>

    <p-panel header="Optional fields" [toggleable]="true">
        <div>
            <label htmlFor="schemas">Schemas</label>
            <p-table id="schemas" [value]="data.schemas" [tableStyle]="{ 'min-width': '50rem' }">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Schema</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-schema>
                    <tr>
                        <td>{{ schema }}</td>
                    </tr>
                </ng-template>
            </p-table>
            <small id="schemas-help">The list of schemas (case sensitive) to sync from. Defaults to public.</small>
        </div>

    </p-panel>

</div>

<div group="auth" class="card">
    <div class="flex flex-column gap-2">
        <label htmlFor="username">Username</label>
        <input pInputText id="username" aria-describedby="username-help" [(ngModel)]="data.username" />
        <small id="username-help">Hostname of the database.</small>
    </div>
    <div class="flex flex-column gap-2">
        <label htmlFor="password">Password</label>
        <input pInputText id="password" aria-describedby="password-help" [(ngModel)]="data.password" />
        <small id="password-help">Port of the database.</small>
    </div>
</div>

<div group="security" class="card">
    <h3>Security</h3>
</div>

<div group="advanced" class="card">
    <h3>Advanced</h3>
</div>