import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";

import lodash from "lodash";
import { Id } from "src/app/helper/id";
import { MetaInfo } from "src/app/models/api/com/bion/etl/NodeMetaData";
import { FieldInfo } from "src/app/models/api/com/bion/etl/Workflow";
import { DataTypeInfo } from "src/app/models/datasource.model";
import { SelectInfo, SelectSettings } from "src/app/models/nodeSettings.model";

import { ApiBackendService } from "src/app/services/api-backend.service";
import { DesignerService } from "src/app/services/designer.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { NodeConfigComponentBase } from "../node-config-component-base";

export class SelectInfoView {
	Name: string;
	DataType?: DataTypeInfo;
	NewName?: string;
	NewDataType?: DataTypeInfo;
	NewLength?: number;
	hasError?: boolean = false;

	constructor(Name: string, DataType?: DataTypeInfo, NewName?: string, NewDataType?: DataTypeInfo, NewLength?: number, hasError?: boolean) {
		this.Name = Name;
		this.DataType = DataType;
		this.NewName = NewName;
		this.NewDataType = NewDataType;
		this.NewLength = NewLength;
		this.hasError = hasError;
	}
}

@Component({
	selector: "app-select-node",
	templateUrl: "./select-node.component.html",
	styleUrls: ["./select-node.component.css"],
})
export class SelectNodeComponent
	extends NodeConfigComponentBase<SelectSettings>
	implements OnInit, OnDestroy {
	onSettingsChanged(settings: SelectSettings) {
		throw new Error("Method not implemented.");
	}

	settingsToView(settings: SelectSettings): void {

		//console.log("Settings to View");
		//console.log("settings", settings);
		//console.log("current workflow node", this._currentWorkflowNodeAdapter.IWorkflowNode.Properties.Configuration);

		//TODO: synchronize methods
		if (this.dataTypesList === undefined) {
			// -- if no datatypes available, fetch datatypes from api
			this.subs.sink = this.bionApi.getDataTypes().subscribe((result) => {
				this.dataTypesList = result;
				this.loadSettingsToView(settings);
			});
		} else {
			// -- if datatypes available, load into view
			this.loadSettingsToView(settings);
		}
	}

	viewToSettings(): SelectSettings {
		const newSettings = <SelectSettings>{
			...this.getCurrentWorkflowNode().Properties.Configuration,
		};

		// -- Get select information
		const all_views = this.allDsFields;
		const all_select_infos = all_views.map((v, i, a) => {
			return this.selectInfoViewToSelectInfo(v);
		});
		newSettings.SelectInfos = all_select_infos;

		// -- Get selected fields
		const selected_views = this.selectedDsFields;
		const selected_fields = selected_views.map(fi => fi.Name);

		newSettings.SelectedFields = selected_fields;

		return newSettings;
	}


	loading: boolean = false;
	@ViewChild('containerDiv') containerDiv!: ElementRef;
	allDsFields: SelectInfoView[] = [];
	selectedDsFields: SelectInfoView[] = []; // scoped and changed data
	dataTypesList: DataTypeInfo[] = [];

	readonly InputPort = "Input";

	constructor(
		protected workflowService: WorkflowsService,

		protected designerService: DesignerService,
		protected bionApi: ApiBackendService
	) {
		super(workflowService, designerService);
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	fieldInfo_to_SelectInfo(info: FieldInfo): SelectInfo {
		return new SelectInfo(
			info.Name,
			info.Name,
			info.DataType.Name,
			info.DataType.Length
		);
	}


	// -------- new LoadSettingsToView
	loadSettingsToView(settings: SelectSettings) {
		this.loading = true;

		const meta_infos = this.getCurrentWorkflowNode().Properties.MetaInfo.get(
			this.InputPort
		);

		//check Meta Infos, if not given => quit
		if (meta_infos === undefined || meta_infos.length == 0) {
			console.log("SELECT: settingsToView - meta infos could not be found!!!");
			return;
		}

		const meta_info = meta_infos[0]; // infos of first and only table!

		// Lade SelectInfos in den view, basierend auf ausegwählten Feldern und vorhandenen META INFOS.


		// Erwartet Meta Infos <=> Field Infos
		// Für jedes Feld existiert ein Field Info
		// ! Du Rückrichtichtung gilt nicht
		// Meta Infos IST TEILMENGE VON Field Infos

		// => Lade Field Info in View!

		// SelectInfo: BE Klasse
		// FieldInfo: BE Meta Info
		// SelectInfoView


		// Fall 1: Das Feld existiert!
		// Feld Info normal einfügen

		// Fall 2. Das Feld existiert NICHT!
		// Feld Info MIT MISSING INFO einfügen

		// Fall 3: Es gibt neue Felder, für die noch kein Field Info existiert!
		// => Delegieren ans Backend: Der Select Knoten baut für jedes fehlende Feld ein Field Info!

		const select_infos = settings.SelectInfos;
		
		//const field_info_views = this.fieldInfoToSelectInfoViews(select_infos, meta_info, this.dataTypesList);   // komplette Liste
		const field_info_views = this.fieldInfoToSelectInfoViews(select_infos, meta_info, this.dataTypesList)
			.filter((view) => { return view.hasError === false});   // errorFelder werden aussortiert

		const selected_views = field_info_views.filter(f => settings.SelectedFields.includes(f.Name));  // Test mit Name & NewName mismatch

		this.allDsFields = field_info_views;
		this.selectedDsFields = selected_views;


		// Reihenfolge
		// - Wird bestimmt durch Field Infos

		// S = { A  , B , C , D }

		// Fälle: Es gibt mehr Field Infos als Felder: Reihenfolge?

		// Input Fields = A, B, D, F
		// Missing Fields = c, e
		// S = { A, B, c, D, e, F}  // Field Infos

		// Out Fields: A, B, D, F

		// Will die Reihenfolge: D, B, F, A
		// S = { e, c, D, B, F, A}
		// Out Fields: D, B, F, A
		// Out Field Infos: e, c, D, B, F, A

	}

	/**
	 * Konvertiert die BE Strukturen in FE freundliche Strukturen und markiert Einträge, für die kein Feld existiert!
	 * @param selectInfos Select Infos
	 * @param metaInfo  Meta Infos
	 */
	fieldInfoToSelectInfoViews(selectInfos: SelectInfo[], metaInfo: MetaInfo, dtInfo: DataTypeInfo[]): SelectInfoView[] {


		const field_names = metaInfo.FieldsInfo.map(fi => fi.Name);

		const select_info_views = selectInfos.map(si => {


			const field_info = metaInfo.FieldsInfo.find(fi => fi.Name == si.SourceFieldName);

			//const view_info = new SelectInfoView();

			const Name = si.SourceFieldName;

			let dType;
			
			if (field_info === undefined) {
				dType = undefined;
			} else {
				dType = dtInfo.find(dti => dti.Name == field_info.DataType.Name);
			}



			const NewDataType = dtInfo.find(dti => dti.Name == si.NewType);
			const NewName = si.TargetFieldName;
			const NewLength = si.NewTypeLength;

			// has error check
			const hasError = !field_names.includes(si.SourceFieldName);  // error if field is not present!

			const view_info = new SelectInfoView(Name,dType,NewName,NewDataType,NewLength,hasError);

			return view_info;
		})


		return select_info_views;
	}

	fieldInfo_to_SelectInfoView(fieldInfo: FieldInfo): SelectInfoView {
		//let select_info_view = new SelectInfoView();

		const Name = fieldInfo.Name;

		const DataType = this.dataTypesList.find((entry) => {
			return fieldInfo.DataType.Name == entry.Name;
		});

		const NewDataType = DataType;
		const NewLength = fieldInfo.DataType.Length;

		const select_view = new SelectInfoView (Name,DataType,undefined,NewDataType,NewLength)

		return select_view;
	}

	selectInfo_to_SelectInfoView(
		info: SelectInfo,
		meta?: FieldInfo[]
	): SelectInfoView {
		//let select_info_view = new SelectInfoView();
		const Name = info.SourceFieldName;
		//console.log("BREAKPOINT");
		const DataType = this.dataTypesList.find((res) => {
			return res.Name === info.NewType;
		});
		const NewDataType = this.dataTypesList.find((res) => {
			return res.Name === info.NewType;
		});
		const NewLength = info.NewTypeLength;
		const NewName = info.TargetFieldName;

		let fieldInfoFound = meta?.find((field) => {
			return field.Name === info.SourceFieldName;
		});

		const hasError = !fieldInfoFound;

		const select_view = new SelectInfoView(Name,DataType,NewName,NewDataType,NewLength,hasError)

		return select_view;
	}

	/**
	 * SelectInfoView => SelectInfo
	 * @param v Select Info View
	 * @returns Select Info
	 */
	selectInfoViewToSelectInfo(v: SelectInfoView): SelectInfo {
		// Create Map Class

		let s_name: string;
		let t_name: string;
		let new_type: string | undefined;
		let new_len: number | undefined;

		//Fill out SelectEntry
		s_name = v.Name;
		if (v.NewName) {
			t_name = v.NewName;
		} else {
			t_name = v.Name;
		}

		new_len = v.NewLength ? v.NewLength : 0;


		if (v.NewDataType?.Name !== v.DataType?.Name) {
			new_type = v.NewDataType?.Name;
		} else {
			new_type = v.DataType?.Name;
		}

		//const new_type_checked = Id.assertSet(new_type, new Error("No datatype set, should not happen"));


		let newSelectEntry = new SelectInfo(s_name, t_name, "", 0); // Neuer Datentyp und Länge werden nicht mehr unterstüzt, da es von ChangeDataType Knoten berücksichtigt wird

		return newSelectEntry;
	}


	// 	/**
	//  * https://stackoverflow.com/questions/46391662/focusout-and-blur-not-working-in-angular
	//  * https://stackoverflow.com/questions/15369572/jquery-focusout-for-entire-div-and-children // zu checken ob das Element ChildElement ist
	//  * @param event
	//  */
	// 	onFocusLost(event: FocusEvent) {
	// 		console.log("event", event);


	// 		//console.log("currentConfigView: ", this.currentWorkflowNodeInfo);
	// 		this.onUpdateSettings(false);
	// 	}



	/**
	 * The component has lost the focus.
	 * https://stackoverflow.com/questions/46391662/focusout-and-blur-not-working-in-angular
	 * We check the attribut event.relatedTarget, because it is ALWAYS null if you click OUTSIDE the div.
	 * If you click a child within the Div it is set.
	 * @param event
	 */

	onFocusLost(event: FocusEvent) {
		const div_left: boolean = (event.relatedTarget === null);

		console.log("Send from Div: ", div_left);

		if (div_left) {
			//this.onCheckDirtyFlag();
			this.onUpdateSettings(true);
		}
	}

	// @Output() isDirtyFlag = new EventEmitter<boolean>();
	// onCheckDirtyFlag() {
	// 	const isDirtyFlag = this.isDirty();
	// 	//this.isDirtyFlag.emit(isDirtyFlag);

	// 	//this.designerService.isDirtyFlagEmitter.emit(isDirtyFlag);
	// 	console.log("Sent isDirtyFlag",isDirtyFlag)
	// }

}
