import { PlayJsonInheritance } from "src/app/models/play.model";

/**
 * The rythm is day oriented
 */
export interface BasicDayRhythm extends PlayJsonInheritance {
	_type: string;
	Days: number[];
	UseLastDay: boolean;
}

export class WeekRhythm implements BasicDayRhythm {
    Days: Array<number>;
    UseLastDay: boolean;
    LastDayIndex: number;
    constructor(Days: Array<number>,UseLastDay: boolean,LastDayIndex: number) {
        if(Days === undefined) throw new Error( "Class 'WeekRhythm': Required value 'Days' is undefined" );
        if(UseLastDay === undefined) throw new Error( "Class 'WeekRhythm': Required value 'UseLastDay' is undefined" );
        if(LastDayIndex === undefined) throw new Error( "Class 'WeekRhythm': Required value 'LastDayIndex' is undefined" );
        this.Days = Days;
        this.UseLastDay = UseLastDay;
        this.LastDayIndex = LastDayIndex;
    }
    _type: string = "models.scheduler.WeekRhythm";
}

export class MonthRhythm {
    Days: Array<number>;
    UseLastDay: boolean;
    LastDayOffset: number;
    constructor(Days: Array<number>,UseLastDay: boolean,LastDayOffset: number) {
        if(Days === undefined) throw new Error( "Class 'MonthRhythm': Required value 'Days' is undefined" );
        if(UseLastDay === undefined) throw new Error( "Class 'MonthRhythm': Required value 'UseLastDay' is undefined" );
        if(LastDayOffset === undefined) throw new Error( "Class 'MonthRhythm': Required value 'LastDayOffset' is undefined" );
        this.Days = Days;
        this.UseLastDay = UseLastDay;
        this.LastDayOffset = LastDayOffset;
    }
    _type: string = "models.scheduler.MonthRhythm";
}