import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { UserInfo } from "src/app/models/api/models/session/UserInfo";
// import { UserInfo } from "src/app/models/user.model";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { UserService } from "src/app/services/user.service";
import { SubSink } from "subsink";

@Component({
	selector: "app-delete-user-dialog",
	templateUrl: "./delete-user-dialog.component.html",
	styleUrls: ["./delete-user-dialog.component.scss"],
})
export class DeleteUserDialogComponent implements OnInit {
	subs = new SubSink();
	savingInProgress: boolean = false;
	displayDeleteUser: boolean = false;
	selectedUser?: UserInfo;

	constructor(
		private userService: UserService,
		private errorService: SystemMessageLogService,
		private messageService: MessageService,
		private translate: TranslateService
	) {}

	ngOnInit(): void {
		this.subs.sink = this.userService.displayDeleteUser.subscribe(
			(res: boolean) => {
				this.displayDeleteUser = res;
			},
			(err: Error) => {
				this.errorService.handleError(err);
			}
		);
		this.subs.sink = this.userService.selectedMenuUserEmitter.subscribe(
			(user: UserInfo) => {
				this.selectedUser = user;
			},
			(err: Error) => {
				this.errorService.handleError(err);
			}
		);
	}
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	deleteUser(event?: UserInfo) {

		if(event === undefined) return;

		this.savingInProgress = true;
		this.userService.deleteUser(event.ID).subscribe(
			(res) => {
				this.userService.deletedUserEmitter.emit(event);

				this.displayDeleteUser = false;
				
				this.messageService.add({
					severity: "success",
					summary: this.translate.instant("Message.DeleteUserSuccess.Title"),
					detail: this.translate.instant("Message.DeleteUserSuccess.Text1") + event.Username + this.translate.instant("Message.DeleteUserSuccess.Text2"),
					// summary: "User deleted!",
					// detail:
					// 	"user was successfully deleted: " + event.Username,
				});
			},
			(err: Error) => {
				this.errorService.handleError(err);
			}, () => {
				this.savingInProgress = false;
			}
		);
	}
}
