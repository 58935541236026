import { Component, Input, OnInit } from '@angular/core';
import { TargetMetaInfo } from 'src/app/models/api/com/bion/connect/TargetAccessLike';
import { PostgreDestinationView } from 'src/app/models/destinations/postgre-destination-view.model';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-postgre-sql-settings',
  templateUrl: './postgre-sql-settings.component.html',
  styleUrls: ['./postgre-sql-settings.component.scss']
})
export class PostgreSqlSettingsComponent implements OnInit {

  @Input() viewModel: PostgreDestinationView;
  subs= new SubSink;
  status_text: string = "";
  status: boolean = false;

  constructor() { }

  ngOnInit(): void {
    // this.viewModel.connectorSettings.Configuration
    // this.viewModel.metaInfo.Entities
    // this.viewModel.connectorSettings.Entity
  }

  onConnect(event: PointerEvent): void {
    //this.status_text = "On Connect is no longer destination specific. Use this method generic for every destination to check the config"
    this.subs.sink = this.viewModel.fetchMetaDataObs().subscribe(
      (res: TargetMetaInfo) => {

        //this.currentConnectorView.metaInfo = res;
        console.log(res);
        this.status_text = "OK!";
        this.status = true;

      },
      (error: Error) => {
        this.status_text = "Failed! " + error.message;
        this.status = false;
        //this.handleError(error)
      });
  }
}