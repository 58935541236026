import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DataPackage } from 'src/app/models/datasource.model';
import { DataStore } from 'src/app/models/datastore.model';
import { CubesService } from 'src/app/services/cubes.service';
import { DestinationService } from 'src/app/services/destinationService';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { SubSink } from 'subsink';
import {DataStore as DataStoreNs} from 'src/app/models/api/models/staging/DataStore.ns';
import { DestinationActionEvent, DestinationDialogActionType } from 'src/app/models/dialog-actions.model';
import { TranslateService } from '@ngx-translate/core';
import { DropDownEvents } from 'src/app/helper/events';

@Component({
  selector: 'app-delete-datastore-package-dialog',
  templateUrl: './delete-datastore-package-dialog.component.html',
  styleUrls: ['./delete-datastore-package-dialog.component.scss'],
  providers: [MessageService]
})
export class DeleteDatastorePackageDialogComponent implements OnInit,OnDestroy {
	subs = new SubSink();
	displayDeleteDataPackage: boolean = false;
	savingInProgress: boolean = false;

	currentSelectedDataStore?: DataStore;
	buttonDisabled: boolean = false;
	dataPackages: DataStoreNs.DataPackage[] = [];    // typisieren
	selectedDataPackage?: DataStoreNs.DataPackage;// typisieren
  
  constructor(private dataStoreService: CubesService, private translate: TranslateService, private systemLogService: SystemMessageLogService, private messageService: MessageService) { }

  ngOnInit(): void {

		this.subs.sink = this.dataStoreService.destinationDialogActionSendEmitter.subscribe(
			(res: DestinationActionEvent) => {
				if(res.actionType === DestinationDialogActionType.deleteDataPackage) {
					this.displayDeleteDataPackage = res.display;
					this.currentSelectedDataStore = res.dest;

					this.getDataPackages(res.dest);
					if (!res) return;
				}

			}, (err: Error) => {
				this.systemLogService.handleError(err)
			}
		);
        this.subs.sink = this.dataStoreService.dataStoresChangedEmitter.subscribe(
			() => {
                if(this.currentSelectedDataStore) this.getDataPackages(this.currentSelectedDataStore)
			}
		);
  }
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}
  getDataPackages(ds?: DataStore) {
		if(ds) {
			this.subs.sink = this.dataStoreService
			.getDataPackage(undefined, ds.id)
			.subscribe((res: DataStoreNs.DataPackage[]) => {
				this.dataPackages = res;
				this.selectedDataPackage = res[0];
			},(err: Error) => {
				this.systemLogService.handleError(err);
			});
		}

    }

	selectDataPackage(evt:DropDownEvents.OnChange<any,DataStoreNs.DataPackage>) {
		this.selectedDataPackage = evt.value;
	}

	deleteDataPackage() {

		if(this.selectedDataPackage === undefined) {
			console.log("No data package selected");
			return;
		}

		this.savingInProgress = true;
		let dataPackage = this.selectedDataPackage;

		this.dataStoreService.deleteDataPackage(dataPackage.id).subscribe(
			(result) => {
				this.dataStoreService.dataStoresChangedEmitter.emit(this.currentSelectedDataStore);

				this.savingInProgress = false;
				this.buttonDisabled = false;
				this.messageService.add({
					severity: "success",
					summary: this.translate.instant("Message.DeleteDatapackageSuccess.Title"),
					detail: this.translate.instant("Message.DeleteDatapackageSuccess.Text1") + dataPackage.id +
					this.translate.instant("Message.DeleteDatapackageSuccess.Text2"),
				});
				this.displayDeleteDataPackage = false;
			},
			(err: Error) => {
				this.savingInProgress = false;
				this.buttonDisabled = false;
				this.systemLogService.handleError(err);
			}
		);
	}

}
