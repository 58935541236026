<app-data-set-details-dialog [selectedDataSet]="selectedDataSet" [dataPreviewColumns]="dataPreviewColumns">
</app-data-set-details-dialog>
<app-create-datasource-dialog-new #createDataSourceDialog></app-create-datasource-dialog-new>


<div class="p-col-12 p-md-12 p-xl-12">
    <div class="card operations">
        <div class="card-header">
            <div class="card-title">
                <h4>{{'Datasets' | translate}}</h4>
                <p class="subtitle">
                    {{'DataMarket.LookingForData' | translate}}
                </p>
            </div>
        </div>
        <div class="p-grid">
            <div class="device-status p-col-12 p-md-12 p-lg-12">

                <p-dataView #dv [value]="datasets" [paginator]="true" [rows]="8"
                    filterBy="Name,Category,Provider,Tags,PricingModel.license,BuyState,serviceMetaData.Countries.id,serviceMetaData.Years"
                    layout="grid">
                    <p-header class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
                        <!-- <div class="search-input-wrapper p-fluid">
                                    <span class="p-input-icon-left">
                                        <span class="p-float-label">
                                            <p-autoComplete inputId="autocomplete" field="name" [dropdown]="true"
                                                dropdownIcon="pi pi-search" (input)="dv.filter($event.target.value)">
                                                <ng-template let-brand pTemplate="item">
                                                    <img src="assets/layout/images/{{selectedDataSetsAdvanced}}.png"
                                                        style="width:40px;display:inline-block;margin:5px 0 2px 5px" />
                                                    <div style="font-size:18px;float:right;margin:10px 10px 0 0">
                                                        {{selectedDataSetsAdvanced}}
                                                    </div>
                                                </ng-template>
                                            </p-autoComplete>
                                            <label for="autocomplete"> <i class="pi pi-table"></i> Search for data sets
                                            </label>
                                        </span>
                                    </span>
                                </div> -->

                        <!-- <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dv.filter($event.target.value)"
                                    placeholder="{{'SearchForDataSet' | translate }}" />
                            </span> -->
                        <span class="p-float-label">
                            <input pInputText type="text" id="inputtext" (input)="dv.filter($event.target.value)" />
                            <label for="inputtext">
                                <i class="pi pi-search"></i>{{'DataMarket.SearchForExternalData' | translate }}
                            </label>
                        </span>
                        <span class="p-float-label">
                            <p-multiSelect [options]="dataSetCategories" [style]="{'width' : 'calc(30rem)'}"
                                id="categoryFilter" optionLabel="name" (onChange)="onFilterChange($event)"
                                [(ngModel)]="selectedDataSetCategories" display="chip">
                            </p-multiSelect>
                            <label for="categoryFilter">{{'Category' | translate }}</label>
                        </span>
                        <span class="p-float-label">
                            <p-multiSelect [options]="dataSetProviders" id="providerFilter"
                                [style]="{'width' : 'calc(30rem)'}" (onChange)="onFilterChange($event)"
                                optionLabel="name" [(ngModel)]="selectedProviders" display="chip">
                            </p-multiSelect>
                            <label for="providerFilter">{{'Provider' | translate }}</label>
                        </span>


                        <!-- <p-dataViewLayoutOptions></p-dataViewLayoutOptions> -->
                    </p-header>

                    <ng-template let-product pTemplate="listItem">
                        <div class="p-col-12">
                            <div class="p-grid p-fluid" style="position: relative">
                                <div class="product-by"><i
                                        class="pi pi-tag product-category-icon"></i>{{product.Category}}
                                </div>
                                <div class="p-col-2" style="text-align: center;">
                                    <img width="200px"
                                        [src]="'assets/layout/images/datamarket/' + product.Provider + '.png'"
                                        [alt]="product.Provider" />
                                    <div class="product-by">by {{product.Provider}}</div>
                                </div>
                                <div class="product-list-detail p-col-8">
                                    <div class="product-name">{{product.Name}}</div>
                                    <!-- <div class="product-version">Current Version: {{product.Version}} | ServiceID:
                                                {{product.ServiceID}} | DataSetID: {{product.DataSetID}}</div>
                                            <div class="product-description">{{product.Description.ShortDesc}}</div> -->

                                </div>
                                <div class="product-list-detail p-col-2">
                                    <div class="p-grid p-fluid" style="bottom:0">
                                        <div class="product-info-section-items" *ngIf="product.serviceMetaData.Records">
                                            <div class="product-info-section-name"><span>RECORDS</span></div>
                                            <div class="product-info-section-value">{{product.serviceMetaData.Records}}
                                            </div>
                                        </div>
                                        <div class="product-info-section-items"
                                            *ngIf="product.serviceMetaData.Countries">
                                            <div class="product-info-section-name"><span>COUNTRY</span></div>
                                            <div class="product-info-section-value">
                                                {{product.serviceMetaData.Countries.length}}</div>
                                        </div>
                                        <div class="product-info-section-items"
                                            *ngIf="product.serviceMetaData.Currencies">
                                            <div class="product-info-section-name"><span>CURRENCY</span></div>
                                            <div class="product-info-section-value">
                                                {{product.serviceMetaData.Currencies.length}}</div>
                                        </div>
                                        <div class="product-info-section-items" *ngIf="product.serviceMetaData.Years">
                                            <div class="product-info-section-name"><span>HISTORY</span></div>
                                            <div class="product-info-section-value">
                                                {{product.serviceMetaData.Years.length}}
                                            </div>
                                        </div>
                                        <!-- <div class="product-info-section-item-node" *ngIf="product.IncludedNodes">
                                                <div class="product-info-section-name"><i class="fa fa-plus"></i><span>
                                                        {{product.IncludedNodes.length}} Node(s)</span></div>
                                            </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template let-product pTemplate="gridItem">
                        <div class="p-col-12 p-md-6 p-lg-3">

                            <div class="p-grid card p-shadow-2" style="margin: 15px;">
                                <div class="p-col-12 card-header">
                                    <div class="card-title">
                                        <div pTooltip="{{product.Provider}}">
                                            <img [src]="'assets/layout/images/datamarket/' + product.Provider + '.png'"
                                                [alt]=" product.Provider" />
                                        </div>
                                    </div>
                                    <p class="subtitle"><button style="float: right" pButton pRipple type="button"
                                            icon="pi pi-plus-circle"
                                            class="p-button-rounded p-button-text p-mr-2 p-mb-2"
                                            pTooltip="{{'Add' | translate}}"
                                            (click)="onDisplayNewDataSourceDialog(product)"></button>
                                        <button style="float: right" pButton pRipple type="button"
                                            icon="pi pi-info-circle"
                                            class="p-button-rounded p-button-text p-mr-2 p-mb-2"
                                            pTooltip="{{'DataMarket.ShowDetails' | translate}}"
                                            (click)="displayDataSetDetailsDialog(product)"></button>
                                    </p>
                                </div>

                                <div class="p-col-12 card-content">
                                    <div>
                                        <span class="product-version">{{product.Category}}</span>
                                    </div>
                                    <div>
                                        <div pTooltip="{{product.Description.ShortDesc}}" tooltipPosition="top"
                                            class="p-grid p-fluid">
                                            <div class="p-col-12" style="height: 100px">
                                                <div class="product-name">
                                                    {{product.Name}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-grid p-fluid " style="bottom: 0;">
                                        <div class="p-col-12">
                                            <span style="margin-right: 3px;margin-bottom: 3px">
                                                <p-tag *ngIf="product.serviceMetaData.Records.length > 0">
                                                    {{product.serviceMetaData.Records}}
                                                    {{'Records' | translate}}
                                                </p-tag>
                                            </span>
                                            <span style="margin-right: 3px;margin-bottom: 3px">
                                                <p-tag *ngIf="product.serviceMetaData.Countries.length > 0">
                                                    {{product.serviceMetaData.Countries.length}}
                                                    {{'Countries' | translate}}
                                                </p-tag>
                                            </span>
                                            <span style="margin-right: 3px;margin-bottom: 3px">
                                                <p-tag *ngIf="product.serviceMetaData.Currencies.length > 0">
                                                    {{product.serviceMetaData.Currencies.length}} {{'Currencies' |
                                                    translate}}
                                                </p-tag>
                                            </span>
                                            <span style="margin-right: 3px;margin-bottom: 3px">
                                                <p-tag *ngIf="product.serviceMetaData.Years.length > 0">
                                                    {{product.serviceMetaData.Years.length}}
                                                    {{'Years' | translate}}
                                                </p-tag>
                                            </span>
                                            <!-- <span style="margin-right: 3px;margin-bottom: 3px">
                                                    <p-tag *ngIf="product.IncludedNodes">{{product.IncludedNodes.length}} {{'NODES' |
                                                        translate}}</p-tag>
                                                </span> -->
                                        </div>
                                        <!-- <div class="product-info-section-item-node" *ngIf="product.IncludedNodes">
                                                    <div class="product-info-section-name"><i class="fa fa-plus"></i><span>
                                                            {{product.IncludedNodes.length}} Node(s)</span></div>
                                                </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </p-dataView>




            </div>
        </div>
    </div>
</div>


<div class="p-col-12 p-md-12 p-xl-12">
    <div class="card operations">
        <div class="card-header">
            <div class="card-title">
                <h4>{{'Workflow Templates' | translate}}</h4>
                <p class="subtitle">

                </p>
            </div>
        </div>
        <div class="p-grid">
            <div class="device-status p-col-12 p-md-12 p-lg-12">
                Coming soon!
            </div>
        </div>
    </div>
</div>
