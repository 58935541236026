import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
//import { DashboardDemoComponent } from "./demo/view/dashboarddemo.component";
//import { FormLayoutDemoComponent } from "./demo/view/formlayoutdemo.component";
// import { InvalidStateDemoComponent } from "./demo/view/invalidstatedemo.component";
// import { FloatLabelDemoComponent } from "./demo/view/floatlabeldemo.component";
// import { PanelsDemoComponent } from "./demo/view/panelsdemo.component";
// import { OverlaysDemoComponent } from "./demo/view/overlaysdemo.component";
// import { MediaDemoComponent } from "./demo/view/mediademo.component";
// import { MenusDemoComponent } from "./demo/view/menusdemo.component";
// import { MessagesDemoComponent } from "./demo/view/messagesdemo.component";
// import { MiscDemoComponent } from "./demo/view/miscdemo.component";
// import { EmptyDemoComponent } from "./demo/view/emptydemo.component";
// import { ChartsDemoComponent } from "./demo/view/chartsdemo.component";
// import { FileDemoComponent } from "./demo/view/filedemo.component";
// import { DocumentationComponent } from "./demo/view/documentation.component";
import { AppMainComponent } from "./app.main.component";
import { AppNotfoundComponent } from "./pages/app.notfound.component";
import { AppErrorComponent } from "./pages/app.error.component";
import { AppAccessdeniedComponent } from "./pages/app.accessdenied.component";
import { AppLoginComponent } from "./pages/app.login.component";
// import { InputDemoComponent } from "./demo/view/inputdemo.component";
// import { ButtonDemoComponent } from "./demo/view/buttondemo.component";
// import { TableDemoComponent } from "./demo/view/tabledemo.component";
// import { ListDemoComponent } from "./demo/view/listdemo.component";
// import { TreeDemoComponent } from "./demo/view/treedemo.component";
// import { DisplayComponent } from "./utilities/display.component";
// import { ElevationComponent } from "./utilities/elevation.component";
// import { FlexboxComponent } from "./utilities/flexbox.component";
// import { GridComponent } from "./utilities/grid.component";
// import { IconsComponent } from "./utilities/icons.component";
// import { WidgetsComponent } from "./utilities/widgets.component";
// import { SpacingComponent } from "./utilities/spacing.component";
// import { TypographyComponent } from "./utilities/typography.component";
// import { TextComponent } from "./utilities/text.component";
// import { AppCrudComponent } from "./pages/app.crud.component";
// import { AppCalendarComponent } from "./pages/app.calendar.component";
// import { AppTimelineDemoComponent } from "./pages/app.timelinedemo.component";
// import { AppInvoiceComponent } from "./pages/app.invoice.component";
// import { AppHelpComponent } from "./pages/app.help.component";
import { AppRegisterComponent } from "./pages/app-register/app-register.component";

import { UserProfileComponent } from "./views/users/components/user-profile/user-profile.component";
import { UserSettingsComponent } from "./views/users/components/user-settings/user-settings.component";
import { UsersViewComponent } from "./views/users/users-view.component";
import { UsersListComponent } from "./views/users/components/users-list/users-list.component";

import { DesignerViewComponent } from "./views/designer/designer-view.component";

import { DataMarketViewComponent } from "./views/datamarket/data-market-view.component";
import { AppTutorialComponent } from "./pages/app-tutorial/app-tutorial.component";
import { DashboardComponent } from "./views/dashboard/dashboard.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
// import { MsalGuard } from "@azure/msal-angular";
import { DatasourcesViewRelayoutComponent } from "./views/datasources/view/datasources-view.component";
import { WorkflowsViewRelayoutComponent } from './views/workflows/view/workflows-view.component';
import { DestinationsViewRelayoutComponent } from "./views/destinations/view/destinations-view.component";
import { SchedulesViewRelayoutComponent } from "./views/schedules/view/schedules-view.component";
import { SchedulePlannerComponent } from "./views/schedule-planner/schedule-planner.component";
import { WebSocketTesterComponent } from "./views/experimental/web-socket-tester/web-socket-tester.component";
import { SqlDesignerViewComponent } from "./views/sql-designer/designer-view/designer-view.component";
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from "@angular/common";
import { CreateAbSourceComponent } from "./views/datasources/components/create-ab-source/create-ab-source.component";
import { PowerbiComponent } from "./views/reports/powerbi-component/powerbi.component";
import { PowerbiViewComponent } from "./views/reports/powerbi-view/powerbi-view.component";
import { CreateAbSourceConfigComponent } from "./views/datasources/components/create-ab-source-config/create-ab-source-config.component";
import { CreateAbConnectionComponent } from "./views/datasources/components/create-ab-connection/create-ab-connection.component";
import { ViewAbSourceComponent } from "./views/datasources/components/view-ab-source/view-ab-source.component";
import { ViewAbConnectionComponent } from "./views/datasources/components/view-ab-connection/view-ab-connection.component";
import { ViewAbConnectionsComponent } from "./views/datasources/components/view-ab-connections/view-ab-connections.component";
import { ViewAbSourcesComponent } from "./views/datasources/components/view-ab-sources/view-ab-sources.component";
import { ViewAbMenuComponent } from "./views/datasources/components/view-ab-menu/view-ab-menu.component";
import { CreateDatasourceIntComponent } from "./views/datasources/components/create-datasource-int/create-datasource-int.component";
import { CreateDatasourceIntPageComponent } from "./views/datasources/components/create-datasource-int-page/create-datasource-int-page.component";
import { ChooseConnectorIntPageComponent } from "./views/datasources/components/choose-connector-int-page/choose-connector-int-page.component";
import { DefineStreamsIntPageComponent } from "./views/datasources/components/define-streams-int-page/define-streams-int-page.component";
import { ViewSourcesIntComponent } from "./views/datasources/components/view-sources-int/view-sources-int.component";
import { GetStreamsIntPageComponent } from "./views/datasources/components/get-streams-int-page/get-streams-int-page.component";
import { UpdateStreamsIntPageComponent } from "./views/datasources/components/update-streams-int-page/update-streams-int-page.component";
import { ViewSourceIntComponent } from "./views/datasources/components/view-source-int/view-source-int.component";
import { ViewConnectionIntComponent } from "./views/datasources/components/view-connection-int/view-connection-int.component";
import { DashboardV2Component } from "./views/dashboard-v2/dashboard-v2.component";
import { DestinationViewComponent } from "./views/destinations/view/destination-view/destination-view.component";
import { ChooseDestConnectorPageComponent } from "./views/destinations/components/choose-dest-connector-page/choose-dest-connector-page.component";
import { CreateDestinationPageComponent } from "./views/destinations/components/create-destination-page/create-destination-page.component";
import { WorkflowViewComponent } from "./views/workflows/view/workflow-view/workflow-view.component";
import { TestOauth2FlowComponent } from "./views/experimental/test-oauth2-flow/test-oauth2-flow.component";
import { ParentWindowSenderComponent } from "./views/experimental/parent-window-sender/parent-window-sender.component";

const routes: Routes = [
	{
		path: "",
		component: AppMainComponent,
		children: [
			//{ path: "", component: DashboardComponent },
			{ path: "", component: DashboardV2Component },

			{ path: "personal/users", component: UsersViewComponent },
			{ path: "profile", component: UserProfileComponent },
			{ path: "user/settings", component: UserSettingsComponent },
			{ path: "tutorial", component: AppTutorialComponent },
			{
				path: "workflows",
				component: WorkflowsViewRelayoutComponent,
			},
			{ path: "workflow/:id", component: WorkflowViewComponent },
			{
				path: "planner",
				component: SchedulePlannerComponent,
			},
			{ path: "planner/:id", component: SchedulePlannerComponent },
			{
				path: "designer", component: DesignerViewComponent, children: [
				]
			},
			{ path: "designer/:id", component: DesignerViewComponent },

			{ path: "schedules", component: SchedulesViewRelayoutComponent, },
			{
				path: "destinations", component: DestinationsViewRelayoutComponent, children: [
				]
			},
			{ path: "destination/DataStore/:id", component: DestinationViewComponent }, //DataStore später analog wie datasources variable halten
			{ path: "chooseDestConnectorPage", component: ChooseDestConnectorPageComponent },
			{ path: "createDestinationPage/:origin/:id", component: CreateDestinationPageComponent }, //DataStore später analog wie datasources variable halten
			{
				path: "datamarket",
				component: DataMarketViewComponent,
			},
			{
				path: "sqlDesigner",
				component: SqlDesignerViewComponent,
			},
			{ path: "webSocketTester", component: WebSocketTesterComponent },
			{ path: "testAirbyteDialogs", component: CreateAbSourceComponent },
			{ path: "testAirbyteDialogsConfig", component: CreateAbSourceConfigComponent },
			{ path: "testAirbyteCreateConnection/:id", component: CreateAbConnectionComponent },
			{ path: "testAirbyteViewSource/:id", component: ViewAbSourceComponent },
			{ path: "testAirbyteViewSource", component: ViewAbSourcesComponent },
			{ path: "testAirbyteViewConnection/:id", component: ViewAbConnectionComponent },
			{ path: "testAirbyteViewConnection", component: ViewAbConnectionsComponent },
			{ path: "testAirbyteViewMenu", component: ViewAbMenuComponent },
			{ path: "reports", component: PowerbiViewComponent },
			{ path: "reportsFull/:id", component: PowerbiComponent },
			{ path: "createDataSourceIntBeta", component: CreateDatasourceIntComponent },

			{
				path: "SourceIntegrationSources", component: ViewSourcesIntComponent,
				children: [

				]
			},

			{ path: "SourceIntegrationSource/:origin/:id", component: ViewSourceIntComponent },
			{ path: "SourceIntegrationGetStreams/:origin/:id", component: GetStreamsIntPageComponent },
			{ path: "SourceIntegrationConnection/:origin/:id", component: ViewConnectionIntComponent },
			{ path: "SourceIntegrationUpdateStreams/:origin/:id", component: UpdateStreamsIntPageComponent },
			{ path: "SourceIntegrationChooseConnector", component: ChooseConnectorIntPageComponent },
			{ path: "SourceIntegrationCreateDatasource/:origin/:id", component: CreateDatasourceIntPageComponent },
			{ path: "SourceIntegrationDefineStreams/:origin/:id", component: DefineStreamsIntPageComponent },
			// Testing
			{ path: "test/TestOauth2FlowComponent", component: TestOauth2FlowComponent },
			{ path: "test/TestOauth2FlowComponentCallback", component: ParentWindowSenderComponent },

		],
	},
	{ path: "error", component: AppErrorComponent },
	{ path: "access", component: AppAccessdeniedComponent },
	{ path: "notfound", component: AppNotfoundComponent },
	{ path: "login", component: AppLoginComponent },
	{ path: "register", component: AppRegisterComponent },
	{ path: "forgotPassword", component: ForgotPasswordComponent },
	{ path: "resetPassword", component: ResetPasswordComponent },
	{ path: "**", redirectTo: "" },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			//initialNavigation: !isIframe ? 'enabled' : 'disabled', // Don't perform initial navigation in iframes,
			scrollPositionRestoration: "enabled",
		}),

	],
	exports: [RouterModule],
	providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }]
})
export class AppRoutingModule { }
