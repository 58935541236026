import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { interval, Observable, Subscription } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { SelectEvent } from 'src/app/helper/events';
import { TaskJobModel } from 'src/app/models/api/models/staging/TaskJobModel';
import { WorkflowRepositoryEntry } from 'src/app/models/api/models/workflow/WorkflowRepositoryEntry';
import * as dss from 'src/app/models/datasource.model';
import { DataStore } from 'src/app/models/datastore.model';
import { WorkflowResult } from 'src/app/models/designer.models';
import { ScheduleActionPlan } from 'src/app/models/schedule.model';
import { UserDetailsRow } from 'src/app/models/user.model';
import { CubesService } from 'src/app/services/cubes.service';
import { DatasourcesService } from 'src/app/services/datasources.service';
import { DesignerService, DesignProgresSpinnerEvent, WorkflowOperationType } from 'src/app/services/designer.service';
import { ObjectSearchService } from 'src/app/services/object-search.service';
import { SchedulesService } from 'src/app/services/schedules.service';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { UserService } from 'src/app/services/user.service';
import { UtilFunctionsService } from 'src/app/services/util-functions.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { SubSink } from 'subsink';
import { WorkflowExecutedData } from '../../designer/components/workflow-graph/workflow-graph-events';
import { DataSourceRecordView } from './provider-data-source';
import { DestinationRecordView } from './provider-data-store';
import { SchedulePlanRecordView } from './provider-scheduler';
import { WorkflowRecordView } from './provider-workflow';
import { HeaderRow, RecordProvider } from './record-provider';
import { DestinationActionEvent, DestinationDialogActionType, ScheduleActionEvent, ScheduleDialogActionType, WorkflowActionEvent, WorkflowDialogActionType } from 'src/app/models/dialog-actions.model';
import { TranslateService } from '@ngx-translate/core';
import { PowerBiRecordView } from './provider-report';
import { PowerbiService } from '../../reports/powerbi.service';
import { PowerBiActionEvent, PowerBiDialogActionType } from 'src/app/models/api/models/powerbi-report/PowerBiActionEvent';
import { AppMainComponent } from 'src/app/app.main.component';
import { PowerBiReportService } from 'src/app/services/power-bi-report.service';
import { ClassicResultExtractor } from '../../designer/components/workflow-graph/workflow-result-extractor';


@Component({
  selector: 'app-general-object-view',
  templateUrl: './general-object-view.component.html',
  styleUrls: ['./general-object-view.component.scss'],
  providers: [MessageService],
  styles: [`
  :host ::ng-deep .p-datatable-gridlines p-progressBar {
      width: 100%;
  }

  @media screen and (max-width: 960px) {
      :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
          display: flex;
      }
  }
`]
})

export class GeneralObjectViewComponent<T,V,D,P extends dss.ObjectProtocolRow> implements OnInit, OnDestroy {
  subs = new SubSink;
  @Input() objectList!: T[];
  @Input() userDetails!: UserDetailsRow[];
  @Input() typeClass!: RecordProvider<T,V,D,P>;
  @Input() currentView!: string;
  @ViewChild('dt') dt!: Table;

  //GUI Elements
  actions: MenuItem[] = [];
  cols: HeaderRow[] = [];
  public records: V[] = [];
  //loading: boolean = false;

  constructor(private datasourceService: DatasourcesService, private translate:TranslateService, private messageService: MessageService, private objectFilterService: ObjectSearchService, private router: Router, private designerService: DesignerService, private workflowsService: WorkflowsService, private dataStoreService: CubesService, private schedulesService: SchedulesService, private errorService: SystemMessageLogService, private utilService: UtilFunctionsService, private userService: UserService, private biService: PowerBiReportService, private appMain: AppMainComponent) { }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }


  ngOnInit(): void {
    this.subs.sink = this.objectFilterService.emitChangedFilteredObjectListEmitter.subscribe((res) => {
      //console.log(res);
      this.dt.value = [...res];
    });
  }

  setObjects(objList: T[], typeClass: RecordProvider<T,V,D,P>): Observable<boolean> {
    this.objectList = objList;
    this.typeClass = typeClass;
    this.typeClass.subscribeToEmitter(this.objectList);
    this.actions = this.typeClass.asActions();
    this.cols = this.typeClass.asHeader();

    return this.typeClass.getData().pipe(map((finalRecordResult) => {
      if (this.objectList) {
        let records = this.objectList.map((obj) => {
          return this.typeClass.asRecord(obj, finalRecordResult);
        });
        this.records = this.typeClass.sortEntries(records);
        return true
      } else {
        return false
      }
    }))

    //return of(true)
  }

  // GUI Handling - DATASOURCES --> current event are separate managed per view
  selectedObject?: T;

  onSelectObject(evt: SelectEvent<any,DataSourceRecordView>) {
    let selectedObject = <dss.DataSource>evt.data.object;
    console.log("Emit Selected Object", selectedObject);
    this.datasourceService.emitSelectedDatasource(selectedObject);
  }
  onUnselectObject(evt: SelectEvent<any,DataSourceRecordView>) {
    //let unselectedDatasource = <dss.DataSource>evt.data;
    this.selectedObject = undefined;
    this.datasourceService.emitSelectedDatasource(undefined);
  }
  onMenuClicked(dsView: DataSourceRecordView) {
    console.log(dsView);
    this.datasourceService.selectedMenuDatasourceEmitter.emit(dsView.object);
  }
  onSelectWf(evt: SelectEvent<any,WorkflowRecordView>) {
    let selectedObject = <WorkflowRepositoryEntry>evt.data.object;
    console.log("Emit Selected Object", selectedObject);
    this.workflowsService.selectedWorkflowEmitter.emit(selectedObject);
  }
  onUnselectWf(evt: SelectEvent<any,WorkflowRecordView>) {
    //let unselectedDatasource = <dss.DataSource>evt.data;
    this.selectedObject = undefined;
    this.workflowsService.selectedWorkflowEmitter.emit(undefined);
  }
  onWfMenuClicked(wfView: WorkflowRecordView) {
    console.log(wfView);
    this.workflowsService.selectedMenuWorkflowEmitter.emit(wfView.object);
  }
  onCreateWorkflow() {
    this.workflowsService.workflowDialogActionSendEmitter.emit(new WorkflowActionEvent(true,"Create workflow",WorkflowDialogActionType.createWorkflow,"Create"))

  }
  onOpenWorkflow(wf: WorkflowRecordView) {
    this.router.navigateByUrl('/designer/' + wf.object.id);
    // emit selected id
    this.workflowsService.selectedWorkflowEmitter.emit(wf.object);
  }
  onDeleteWorkflow(wf:WorkflowRecordView) {
    this.workflowsService.workflowDialogActionSendEmitter.emit(new WorkflowActionEvent(true,"Delete workflow",WorkflowDialogActionType.deleteWorkflow,"Delete",wf.object,wf.object?.workflowData))
  }
  onEditWorkflow(wf:WorkflowRecordView) {
    //this.workflowsService.selectedWorkflowEmitter.emit(wf.object);
    //this.workflowsService.workflowDialogActionSendEmitter.emit(new WorkflowActionEvent(true,"Edit workflow",WorkflowDialogActionType.editWorkflow,"Edit",wf.object, wf.object?.workflowData))
    console.log("onOpenWf",wf);
    //routerLink="/destination/DataStore/{{destination.object.id}}"
    this.workflowsService.selectedWorkflowEmitter.emit(wf.object);
    console.log(this.router.url);
    const url = "/workflow/" + wf.object.id.toString();
    console.log(url);

    this.router.navigateByUrl(url);
  }
  onExportWorkflow() {
    this.workflowsService.workflowDialogActionSendEmitter.emit(new WorkflowActionEvent(true,"Export workflows",WorkflowDialogActionType.exportWorkflows,"Export"));

  }
  onImportWorkflow() {
    this.workflowsService.workflowDialogActionSendEmitter.emit(new WorkflowActionEvent(true,"Import workflows",WorkflowDialogActionType.importWorkflows,"Import"));

  }

//   {
//     icon: "pi pi-fw pi-download",
//     styleClass: "p-button p-button-text p-mr-2 p-mb-2",
//     tooltip: "Export workflows as JSON file",
//     command: () => {
//         this.workflowsService.workflowDialogActionSendEmitter.emit(new WorkflowActionEvent(true,"Export workflows",WorkflowDialogActionType.exportWorkflows,"Export"));
//         //this.workflowsService.displayCreateWorkflow.emit(true);
//     }
// },
// {
//     icon: "pi pi-fw pi-upload",
//     styleClass: "p-button p-button-text p-mr-2 p-mb-2",
//     tooltip: "Import workflows from JSON file",
//     command: () => {
//         this.workflowsService.workflowDialogActionSendEmitter.emit(new WorkflowActionEvent(true,"Import workflows",WorkflowDialogActionType.importWorkflows,"Import"));
//         //this.workflowsService.displayCreateWorkflow.emit(true);
//     }
// },

  /**
 * Runs the async execution and starts polling to wait for the result.
 */
  onRunWorkflowAsync(wf: WorkflowRecordView) {

    const workflow = wf.object.workflowData;

    console.log("onRunWorkflowAsync");
    //const workflow = this.getWorkflow();

    this.subs.sink = this.designerService
      .executeWorkflow_async(workflow)
      .subscribe((res: TaskJobModel.JobRequestResult) => {
        this.asyncWorkflowJob = res.Job;
        this.asyncWorkflowPollSub = this.asyncWorkflowPollInterval
          .pipe(startWith(0),
            switchMap(() => this.designerService.executeWorkflow_status(res.Job)))
          .subscribe(
            res => {
              console.log("onRunWorkflowAsync - Checking Workflow for completeness");
              if (res.Completed) {
                // clean up and fetch result.
                this.workflowAsyncStopPolling();
                // The error check is unnecessary here, because this function always returns successful.
                this.subs.sink = this.designerService.executeWorkflow_result(res.Job).subscribe(workflowResult => {
                  this.processWorkflowResult(workflowResult);
                })
              }
            }, (err: Error) => {
              this.workflowAsyncStopPolling();
              this.errorService.handleError(err);
            })
      });
  }
  asyncWorkflowPollSub?: Subscription = undefined;
  asyncWorkflowPollInterval = interval(500);
  asyncWorkflowJob?: string = undefined;

  workflowAsyncStopPolling() {
    console.log("workflowAsyncStopPolling");

    this.asyncWorkflowPollSub?.unsubscribe();
    this.asyncWorkflowPollSub = undefined;

    this.asyncWorkflowJob = undefined;

    console.log("workflowAsyncStopPolling - DONE");
  }

  processWorkflowResult(res: WorkflowResult) {

    try {
      console.log(res);

      const data = new WorkflowExecutedData(res, new ClassicResultExtractor());

      this.messageService.add({
        severity: "success",
        summary: this.translate.instant("Message.RunWorkflowSuccess.Title"),
        detail: this.translate.instant("Message.RunWorkflowSuccess.Text"),
        //summary: "Run workflow was successful!",
        //detail: "Workflow was successfully run",
      });
    } finally {
      console.log("processWorkflowResult: STOP SPINNER");
      this.designerService.designerProgressSpinnerEmitter.emit(new DesignProgresSpinnerEvent(false, WorkflowOperationType.RunWorkflow));
    }
  }
  onSelectDestination(evt: SelectEvent<any,DestinationRecordView>) {
    let selectedObject = <DestinationRecordView>evt.data;
    console.log("Emit Selected Object", selectedObject);
    this.dataStoreService.selectedDataStoreEmitter.emit(selectedObject.object);
  }
  onUnselectDestination(evt: SelectEvent<any,DestinationRecordView>) {
    //let obj = <DataStore>evt.data;
    this.selectedObject = undefined;
    this.dataStoreService.selectedDataStoreEmitter.emit(undefined);
  }
  onDestinationMenuClicked(wfView: DestinationRecordView) {
    console.log(wfView);
    this.dataStoreService.selectedMenuDataStoreEmitter.emit(wfView.object);
  }
  onDeleteDestination(dest: DestinationRecordView) {
    this.dataStoreService.destinationDialogActionSendEmitter.emit(new DestinationActionEvent(true, "Delete destination", DestinationDialogActionType.deleteDestination, "Delete", dest.object));
  }
  onOpenScheduleWorkflow(wf: WorkflowRecordView) {
    this.router.navigateByUrl('/designer/' + wf.object.id);
    // emit selected id
    this.workflowsService.selectedWorkflowEmitter.emit(wf.object);
  }
  /**
   * Callback, navigates to Create Data Source Wizard.
   */
  onCreateDestination() {
    this.router.navigate(['/', 'chooseDestConnectorPage']);
  }
  onOpenDestinationDetails(dest: DestinationRecordView) {
    console.log("onOpenDest",dest);
    //routerLink="/destination/DataStore/{{destination.object.id}}"
  this.dataStoreService.selectedDataStoreEmitter.emit(dest.object);
  console.log(this.router.url);
  const url = "/destination/DataStore/" + dest.object.id.toString();
  console.log(url);

  this.router.navigateByUrl(url);
  //routerLink="/destination/DataStore/{{destination.object.id}}"
}
  onRunScheduleWorkflow(sc: SchedulePlanRecordView) {
    //this.schedulesService.selectedMenuSchedulePlanEmitter.emit(sc.object);
    //this.schedulesService.displayRunSchedule.emit(true);
    this.schedulesService.scheduleDialogActionSendEmitter.emit(new ScheduleActionEvent(true, "Run schedule", ScheduleDialogActionType.runSchedule, "Run", sc.object));


  }

  onSelectAP(evt: SelectEvent<any,SchedulePlanRecordView>) {
    let selectedObject = evt.data.object;
    console.log("Emit Selected Object", selectedObject);
    this.schedulesService.selectedSchedulePlanEmitter.emit(selectedObject);
  }
  onUnselectAP(evt: SelectEvent<any,SchedulePlanRecordView>) {
    //let obj = <ScheduleActionPlan>evt.data;
    this.selectedObject = undefined;
    this.schedulesService.selectedSchedulePlanEmitter.emit(undefined);
  }
  onAPMenuClicked(wfView: SchedulePlanRecordView) {
    console.log(wfView);
    this.schedulesService.selectedMenuSchedulePlanEmitter.emit(wfView.object);
  }

  // onSelectionChanged(event) {
  //   console.log(event);
  // }
  onSelectReport(event:SelectEvent<any,PowerBiRecordView>) {
    console.log(event);

    const biAction = new PowerBiActionEvent(true,"Selected report",PowerBiDialogActionType.ReportSelected,"Open",event.data.object);

    console.log(biAction)
    this.biService.emitPowerBiAction(biAction)

  }
  onUnselectReport(evt: SelectEvent<any,PowerBiRecordView>) {
    //let obj = <ScheduleActionPlan>evt.data;
    this.selectedObject = undefined;

    const biAction = new PowerBiActionEvent(true,"Selected report",PowerBiDialogActionType.ReportSelected,"Open",undefined);
    this.biService.emitPowerBiAction(biAction)
  }
  onReportMenuClicked(wfView: PowerBiRecordView) {
    const biAction = new PowerBiActionEvent(true,"Selected report",PowerBiDialogActionType.ReportMenuSelected,"Open",wfView.object);
    this.biService.emitPowerBiAction(biAction)
  }
}
