import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { AppMainComponent } from "src/app/app.main.component";
import { Id } from "src/app/helper/id";
import { Option, Optionals } from "src/app/helper/optional";
import { Workflow } from "src/app/models/api/com/bion/etl/Workflow";
import { Simulation } from "src/app/models/api/models/workflow/RunWorkflowSettings.ns";
import { WorkflowRepositoryEntry } from "src/app/models/api/models/workflow/WorkflowRepositoryEntry";
import { WorkflowRunInfo } from "src/app/models/api/models/workflow/WorkflowRunInfo";
import { WorkflowActionEvent, WorkflowActionEventStatus, WorkflowDialogActionType } from "src/app/models/dialog-actions.model";
import { LoadingAppComponent } from "src/app/pages/loading-app/loading-app.component";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { SubSink } from "subsink";

export class NewWorkflowArg {
	name: string;
	description?: string;

	constructor(name: string, description?: string) {
		this.name = name;
		this.description = description;
	}
}


@Component({
	selector: "app-create-workflow-dialog",
	templateUrl: "./create-workflow-dialog.component.html",
	styleUrls: ["./create-workflow-dialog.component.scss"],
	providers: [MessageService]
})
export class CreateWorkflowDialogComponent implements OnInit, OnDestroy {
	subs = new SubSink();
	headerText: string = "";
	//isNew: boolean = false;
	dialogType?: WorkflowDialogActionType;
	inputDisabled: boolean = false;
	displayWorkflowDialog: boolean = false;
	workflowName: string = "";
	workflowDescription?: string;
	isSimulationFlag: boolean = false;
	maxRows?: number;
	savingInProgress: boolean = false;
	buttonLabel: string = "";

	workflowDialogEvent?: WorkflowActionEvent;

	@Input() selectedWorkflow?: WorkflowRepositoryEntry;
	@Input() sender?: string;

	constructor(
		private appMain: AppMainComponent,
		private workflowService: WorkflowsService,
		private router: Router,
		private systemLogService: SystemMessageLogService,
		private messageService: MessageService,
		private loadApp: LoadingAppComponent
	) { }
	ngOnDestroy(): void {
		this.subs.unsubscribe();
		this.resetForms();
	}

	ngOnInit(): void {
		this.subs.sink = this.workflowService.selectedWorkflowEmitter.subscribe(
			(res: WorkflowRepositoryEntry) => {
				this.selectedWorkflow = res;
			}, (err: Error) => {
				this.systemLogService.handleError(err)
			}
		);
		// NEW LOGIC
		this.subs.sink = this.workflowService.workflowDialogActionSendEmitter.subscribe((wfActionEvent: WorkflowActionEvent) => {
			if(wfActionEvent.actionType !== WorkflowDialogActionType.exportWorkflows && wfActionEvent.actionType !== WorkflowDialogActionType.importWorkflows) {
				try {
					this.initDialog(wfActionEvent);
				} catch (e) {
					this.systemLogService.handleError(<Error>e);
				}
			}

		}, (err: Error) => {
			this.systemLogService.handleError(err)
		});
		this.subs.sink = this.workflowService.workflowDialogActionStatusEmitter.subscribe((eventStatus) => {
			this.handleEventStatus(eventStatus);
		})
	}
	createWorkflowSuccess: boolean = false;
	/**
	 * Workflow Dialog ist für das Anlegen, Speichern und Bearbeiten von WorkflowRepositoryEntries zuständig. Hierbei wird unterschieden, ob die Operationen über den Workflow-View
	 * oder über den Designer-View erfolgt. Je nach View und Operation werden leicht unterschiedliche Ansätze angewendet. Die Views werden durch @Input "sender" von der jeweiligen View-Mutter an die Tochter übergeben.
	 * FÜr die unterschiedlichen Operationen wird die Enumeration WorkflowDialogActionType verwendet, die von dieser Dialog-Komponente subscribed wird.
	 */


	/**
	 * Initializierung des Dialogs anhand des workflowActionTypes (Wie soll sich die GUI zeichnen?)
	 * @param wfActionEvent
	 * @returns
	 */
	initDialog(wfActionEvent: WorkflowActionEvent) {
		this.workflowDialogEvent = wfActionEvent;

		if (wfActionEvent.actionType === WorkflowDialogActionType.deleteWorkflow) return;

		this.displayWorkflowDialog = wfActionEvent.display;
		this.headerText = wfActionEvent.header;
		this.buttonLabel = wfActionEvent.actionLabel,
			this.dialogType = wfActionEvent.actionType;
		//this.selectedWorkflow = wfActionEvent.wf;

		const raw_flag = this.selectedWorkflow?.runInfo?.simulation?.ReadOnly;
		const is_simulation = Optionals.getOrElse(raw_flag, false);


		if (wfActionEvent.actionType === WorkflowDialogActionType.editWorkflow) {
			const selected_workflow = Id.assertSet(this.selectedWorkflow, new Error("No workflow selected"));
			this.workflowName = selected_workflow.name;
			this.workflowDescription = selected_workflow.description;
			this.isSimulationFlag = is_simulation;
		}
		if (wfActionEvent.actionType === WorkflowDialogActionType.createAndSaveWorkflow) {
			const selected_workflow = Id.assertSet(wfActionEvent.wf, new Error("No workflow selected"));
			this.workflowName = selected_workflow.name;
			this.workflowDescription = selected_workflow.description;
			this.isSimulationFlag = is_simulation;

		}
		if (wfActionEvent.actionType === WorkflowDialogActionType.saveWorkflow) {

			if (this.selectedWorkflow) {
				this.inputDisabled = true;
				this.workflowName = this.selectedWorkflow.name;
				this.workflowDescription = this.selectedWorkflow.description;
				this.isSimulationFlag = is_simulation;

			}
		}
		if (wfActionEvent.actionType === WorkflowDialogActionType.saveAsWorkflow) {
			const selected_workflow = Id.assertSet(this.selectedWorkflow, new Error("No workflow selected"));
			this.workflowName = selected_workflow.name + "_copy";
			this.workflowDescription = selected_workflow.description;
			this.isSimulationFlag = is_simulation;

		}
	}
	/**
	 * Event Status Listener um den aktuellen Status vom Designer zu empfangen
	 * @param eventStatus
	 */
	handleEventStatus(eventStatus: WorkflowActionEventStatus) {
		this.displayWorkflowDialog = eventStatus.display;
		this.createWorkflowSuccess = eventStatus.wasSuccessful;
		this.savingInProgress = eventStatus.savingInProgress;

		if (eventStatus.actionType === WorkflowDialogActionType.saveAsWorkflow) {
			this.selectedWorkflow = eventStatus?.wf;
			this.openWorkflow()
		}
	}

	onValidateForms() {
		try {
			this.validateForms();
		} catch (e) {
			const err = <Error>e;
			console.log(err);
			alert(err.message);
		}
	}

	/**
	 * Zentrale Steuerung der Operationen für den gesamten Dialog
	 */
	validateForms() {

		this.savingInProgress = true;

		if (this.dialogType === WorkflowDialogActionType.createWorkflow) {
			this.createWorkflow(this.workflowDialogEventSafe());
		}
		if (this.dialogType === WorkflowDialogActionType.editWorkflow) {
			this.saveWorkflow(this.workflowDialogEventSafe());
		}
		if (this.dialogType === WorkflowDialogActionType.saveWorkflow) {
			if (this.selectedWorkflow) {
				this.saveWorkflow(this.workflowDialogEventSafe());
			} else {
				this.createAndSaveWorkflow(this.workflowDialogEventSafe());
			}
		}
		if (this.dialogType === WorkflowDialogActionType.saveAsWorkflow) {
			this.saveAsWorkflow(this.workflowDialogEventSafe());
		}
		if (this.dialogType === WorkflowDialogActionType.createAndSaveWorkflow) {
			this.createAndSaveWorkflow(this.workflowDialogEventSafe());
		}
		if (this.dialogType === undefined) {
			throw new Error("The Dialog Type is undefined!");
		}
	}

	workflowDialogEventSafe(): WorkflowActionEvent {
		return Id.assertSet(this.workflowDialogEvent, new Error("Workflow Dialog event is not set"));
	}

	/**
	 * Handling, wenn der Dialog gesschlossen wird. Der Dialog wird geleert bzw. Settings werden zurückgesetzt auf Default-Werte.
	 */
	resetForms() {
		this.inputDisabled = false;
		this.savingInProgress = false;
		this.workflowName = "";
		this.workflowDescription = "";
		this.createWorkflowSuccess = false;
		this.dialogType = undefined;
		this.isSimulationFlag = false;
	}

	/**
	 * Öffnet einen bereits existierenden Workflow
	 */
	openWorkflow() {
		if (this.sender === "DesignerView") {
			this.router.navigateByUrl("/designer/" + this.selectedWorkflow?.id);
			this.workflowService.selectedWorkflowToOpenEmitter.emit(this.selectedWorkflow);

		} else {
			this.router.navigateByUrl("/designer/" + this.selectedWorkflow?.id);
		}
		this.displayWorkflowDialog = false;

	}

	/**
	 * Erstellt einen neuen und leeren Workflow
	 */
	createWorkflow(wfEvent: WorkflowActionEvent) {

		console.log("Init createAndSaveWorkflow");
		let event = { ...wfEvent };

		const workflowArg = new NewWorkflowArg(this.workflowName, this.workflowDescription);
		const now = new Date().toISOString();
		const workflowData = new Workflow("", [], [], "");
		const simulation = new WorkflowRunInfo(new Simulation(this.isSimulationFlag, this.isSimulationFlag ? this.maxRows : undefined));

		const user = Id.assertSet(this.appMain.currentUserFull?.UserInfo.Username, new Error("A user must be set in app main in order to create a workflow"));

		const workflowObj = new WorkflowRepositoryEntry(-1, //id
			workflowArg.name, //name
			user, // created by
			now,  // last change
			"",
			workflowData,
			workflowArg.description,
			simulation);

		event.wf = workflowObj;


		console.log("", workflowObj);

		this.workflowService.workflowDialogActionReceivedEmitter.emit(event);

	}

	/**
	 * Aktualisiert/Speichert den bereits geöffneten Workflow
	 */
	saveWorkflow(wfEvent: WorkflowActionEvent) {

		console.log("Init saveWorkflow");
		let event = { ...wfEvent };

		const workflowArg = new NewWorkflowArg(this.workflowName, this.workflowDescription);
		const now = new Date().toISOString();

		const workflow = { ...Id.assertSet(this.selectedWorkflow, new Error("No workflow selected")) };
		const workflowData = workflow.workflowData;
		const simulation = new WorkflowRunInfo(new Simulation(this.isSimulationFlag, this.isSimulationFlag ? this.maxRows : undefined));

		event.wf = workflow;
		event.wf.name = workflowArg.name;
		event.wf.description = workflowArg.description;
		event.wfData = workflowData;
		event.wf.runInfo = simulation;

		event.wf.lastChange = now;

		this.workflowService.workflowDialogActionReceivedEmitter.emit(event);

	}

	/**
	 * Erstellt einen neuen Workflow und speichert den aktuellen Stand ab. Für den Fall, dass im Designer noch kein WF existiert, aber der Benutzer initial einen erstellt.
	 */
	createAndSaveWorkflow(wfEvent: WorkflowActionEvent) {
		console.log("Init createAndSaveWorkflow");
		let event = { ...wfEvent };

		const workflowArg = new NewWorkflowArg(this.workflowName, this.workflowDescription);
		const now = new Date().toISOString();
		const workflowData = new Workflow("", [], [], "");
		const simulation = new WorkflowRunInfo(new Simulation(this.isSimulationFlag, this.isSimulationFlag ? this.maxRows : undefined));


		const user = Id.assertSet(this.appMain.currentUserFull?.UserInfo.Username, new Error("A user must be set in app main in order to create a workflow"));

		const workflowObj = new WorkflowRepositoryEntry(-1, //id
			workflowArg.name, //name
			user, // created by
			now,  // last change
			"",
			workflowData,
			workflowArg.description,
			simulation);

		event.wf = workflowObj;
		event.actionType = WorkflowDialogActionType.createAndSaveWorkflow;


		console.log("", workflowObj);

		this.workflowService.workflowDialogActionReceivedEmitter.emit(event);

	}

	/**
	 * Speichert den bereits geöffneten Workflow als neuen Workflow ab
	 */
	saveAsWorkflow(wfEvent: WorkflowActionEvent) {
		console.log("Init saveAsWorkflow");
		let event = { ...wfEvent };

		const workflowArg = new NewWorkflowArg(this.workflowName, this.workflowDescription);
		const now = new Date().toISOString();
		const workflowData = new Workflow("", [], [], "");
		const simulation = new WorkflowRunInfo(new Simulation(this.isSimulationFlag, this.isSimulationFlag ? this.maxRows : undefined));

		const user = Id.assertSet(this.appMain.currentUserFull?.UserInfo.Username, new Error("A user must be set in app main in order to create a workflow"));

		const workflowObj = new WorkflowRepositoryEntry(-1, //id
			workflowArg.name, //name
			user, // created by
			now,  // last change
			"",
			workflowData,
			workflowArg.description,
			simulation);

		event.wf = workflowObj;
		console.log("", workflowObj);

		this.workflowService.workflowDialogActionReceivedEmitter.emit(event);

	}



}
