import { Component, OnInit } from '@angular/core';
import { Connection } from 'src/app/models/airbyte-api-structures';
import { AirbyteModel } from 'src/app/models/api/models/AirbyteModel';
import { AirbyteStagingService } from 'src/app/services/airbyte-staging.service';


@Component({
  selector: 'app-view-ab-connections',
  templateUrl: './view-ab-connections.component.html',
  styleUrls: ['./view-ab-connections.component.scss']
})
export class ViewAbConnectionsComponent implements OnInit {

  constructor(private airbyteService: AirbyteStagingService) { }

  isLoading: boolean = false;
  progressMode: string = "determinate";
  infos: AirbyteModel.ConnectionInfo<Connection>[] = [];

  ngOnInit(): void {

    this.setLoading(true);
    this.airbyteService.connectionInfos(undefined, true).subscribe(
      infos => { this.infos = infos; },
      err => this.handleError(err),
      () => this.setLoading(false))
  }

  setLoading(loading: boolean): void {
    this.isLoading = loading;

    // https://www.primefaces.org/primeng-v14-lts/progressbar

    if (this.isLoading) {
      this.progressMode = "indeterminate";
    } else {
      this.progressMode = "determinate";
    }
  }

  handleError(err: Error, message?: any) {
    this.setLoading(false);
    if (message !== undefined)
      console.log(message);

    console.log(err);
  }

  onSync(conn:AirbyteModel.ConnectionInfo<Connection>) {
    console.log("Starting Sync", conn);

    const arg = new AirbyteModel.ExtractDataFromSourceArg(conn.id);
    this.airbyteService.extractData(arg).subscribe(result => {
      console.log("Job requested", result);
    }, err => this.handleError(err));
  }
}