<div class="card p-shadow-2">
    <div class="card-header">
        <div class="card-title">
            <h6>{{'Appointments' | translate}}
            </h6>
        </div>
        <!-- <p class="subtitle" *ngIf="events">{{events.length}} {{'Appointments' | translate}}</p> -->
    </div>
    <!-- <full-calendar [style]="{'min-height': '38rem'}" [options]="options"></full-calendar> -->
</div>

<!-- <p-dialog [(visible)]="eventDialog" [style]="{width: '450px'}" header="Event Details" [modal]="true" [closable]="true">
    <ng-template pTemplate="content">
        <div class="p-fluid">
            <div class="p-field">
                <label for="title">Title</label>
                <input id="title" type="text" pInputText *ngIf="clickedEvent" [(ngModel)]="changedEvent.title"
                    [required]="true">
            </div>
            <div class="p-field">
                <label for="start">From</label>
                <p-calendar id="start" *ngIf="clickedEvent" [(ngModel)]="changedEvent.start" [showTime]="true"
                    appendTo="body"></p-calendar>
            </div>
            <div class="p-field">
                <label for="end">To</label>
                <p-calendar id="end" *ngIf="clickedEvent" [(ngModel)]="changedEvent.end" [showTime]="true"
                    appendTo="body"></p-calendar>
            </div>

        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton label="{{'Save' | translate}}" icon="pi pi-check" class="p-button-text"
            (click)="save()"></button>
        <button pButton label="{{'Reset' | translate}}" icon="pi pi-refresh" class="p-button-text"
            (click)="reset()"></button>
    </ng-template>
</p-dialog> -->
