import { Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { AsyncFileReader, FileTools } from "src/app/helper/fileReader";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { DynamicItemInfo } from "../com-bion-json";
import { ExtractMetaFromConnectorArg, SpssPushAccess, SpssPushMetaInfo, SpssPushMetaAccess } from "../connector.model";
import { ConnectorSettingsBuilder, ConnectorSettingsBuilderBase, ConnectorViewBase } from "../connectorView.model";


export class SpssConnectorView extends ConnectorViewBase<SpssPushAccess, SpssPushMetaInfo, SpssPushMetaAccess> {
    constructor(api: ApiBackendService, connectorID: string) {
        super(api, connectorID);
    }
    fetchMetaDataObs(): Observable<SpssPushMetaInfo> {

        if (this.uploadedFile === undefined) throw new Error("No File to extract Meta Data from given");

        const file_obs = FileTools.readFileAsync(this.uploadedFile);

        const meta_obs = file_obs.pipe(mergeMap(file_res => {

            const meta_access = new SpssPushMetaAccess();

            const extract_meta_arg = new ExtractMetaFromConnectorArg;
            const dynamic_meta_item_info = new DynamicItemInfo(this.connectorID, meta_access);
            extract_meta_arg.ConnectorMetaInfo = dynamic_meta_item_info;
            extract_meta_arg.Base64FileData = file_res;
            this.base64FileData = file_res;

            return this.api.api_extractMetaFromConnector(extract_meta_arg).pipe(map((extractRes) => {
                return <SpssPushMetaInfo>extractRes.MetaData;
            }));
        }));

        return meta_obs;
    }

    getBase64FileData(): string {
        return this.base64FileData;
    }
    getDataSourceNameSuggestion(): string {
        return this.uploadedFile.name;
    }
    // uploadFile(file: File) {
    //     this.uploadedFile = file;
    // }

    getSettingsBuilder(): Observable<ConnectorSettingsBuilder<SpssPushAccess>> {

        const file_reader = new AsyncFileReader();
        const file_obs = file_reader.readAsync(this.uploadedFile);

        const settings_builder_obs = file_obs.pipe(
            map((fileData: string) => {
                let innerRes = new SpssSettingsBuilder(this.connectorID, this.connectorSettings, fileData);
                return innerRes;
            })
        );
        return settings_builder_obs;
    }

    isFileBased(): boolean {
        return true;
    }

    fileIsUpdated: boolean = false;
    flattenedFileList: [];
    base64FileData: string;
}

export class SpssSettingsBuilder extends ConnectorSettingsBuilderBase<SpssPushAccess> {
    
}