<div *ngIf="allDsFields">
    <p-table #dt (onRowReorder)="onUpdateSettings(false)" [value]="allDsFields" [(selection)]="selectedDsFields"
        dataKey="Name" editMode="row" sortMode="single"
        [globalFilterFields]="['Name','NewName','DataType', 'Length','Precision']" [scrollable]="false"
        styleClass="p-datatable-customers p-datatable-sm">
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="{{'LogHistory.SearchForLog' | translate}}" />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem">
                    <p-tableHeaderCheckbox (click)="onUpdateSettings(true)"></p-tableHeaderCheckbox>
                </th>
                <th pSortableColumn="Name" tooltipPosition="top" pTooltip="{{'Config.Select.Tooltip1' | translate}}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Name' | translate}}
                        <p-sortIcon field="Name"></p-sortIcon>
                    </div>
                </th>

                <th pSortableColumn="NewName" tooltipPosition="top" pTooltip="{{'Config.Select.Tooltip2' | translate}}"
                    style="width: 12rem">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Rename' | translate}}
                        <p-sortIcon field="NewName"></p-sortIcon>
                    </div>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-column let-expanded="expanded" let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="column" [ngClass]="{'row-accessories': column.hasError === true}" [ngClass]="{'row-accessories-rename': column.NewName}"
                [pReorderableRow]="ri">
                <td>
                    <p-tableCheckbox [value]="column" (click)="onUpdateSettings(true)"></p-tableCheckbox>
                </td>
                <td pReorderableRowHandle>
                    <p-cellEditor>
                        <ng-template pTemplate="output">
                            {{column.Name}}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <input pInputText type="text" placeholder="Enter new name" [(ngModel)]="column.NewName"
                        (blur)="onUpdateSettings(true)" [ngModelOptions]="{standalone: true}"
                        [style]="{'width':'100%'}">
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="3">{{'NoEntryFound' | translate}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>