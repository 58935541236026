<div style="z-index: 1000000;">
    <p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
    </p-toast>
</div>

<div class="spinner-container" *ngIf="loading">
    <app-loading-screen></app-loading-screen>
</div>

<div class="animated fadeInDown"
    style="z-index: 100;position:absolute; top:60px;left:0; width: calc(100% - 63px);margin-left: 63px;"
    id="DesignerNavbar">
    <div style="height: 2px">
        <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '2px'}"></p-progressBar>
    </div>
    <app-schedule-planner-nav-toolbar></app-schedule-planner-nav-toolbar>
</div>


<div class="p-grid" id="container" @fade
    style="position: absolute; left: 63px;padding: 0; margin: 0 0px;height: calc(100% - 175px)">
    <!-- <div class="p-col-3 p-md-2 p-lg-2" style="padding: 0;margin: 0 0px;">
        <app-schedule-actions-list></app-schedule-actions-list>
    </div> -->
    <div class="p-col-12 p-md-12 p-lg-12" style="padding: 0;margin: 0 0px;">
        <app-schedule-graph-workflow [isEditor]=true #workflowGraph style="flex-basis: auto;overflow: hidden;">
        </app-schedule-graph-workflow>
    </div>
    <div class="box" id="infoi"  @fade>
        
        <div class="card">

        </div>
    </div>
</div>


<app-schedule-planner-bottom-toolbar style="z-index: 5000; position:absolute; bottom:0;left:0; width: calc(100% - 63px);margin-left: 63px;">
</app-schedule-planner-bottom-toolbar>