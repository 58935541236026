import { Component, OnInit } from "@angular/core";
import { FieldInfo, WorkflowNodeSettings } from "src/app/models/api/com/bion/etl/Workflow";
import {
	UniqueValuesSettings,
	UniqueValuesInfo,
} from "src/app/models/nodeSettings.model";
// import { FieldInfo, WorkflowNodeSettings } from "src/app/models/workflow.model";
import { DesignerService } from "src/app/services/designer.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { NodeConfigComponentBase } from "../node-config-component-base";

export class UniqueValuesInfoView {
	ColumnName: string;
	hasError?: boolean;

	constructor(ColumnName: string, hasError?: boolean) {
		this.ColumnName = ColumnName;
		this.hasError = hasError
	}
}

@Component({
	selector: "app-unique-values-node",
	templateUrl: "./unique-values-node.component.html",
	styleUrls: ["./unique-values-node.component.css"],
})
export class UniqueValuesNodeComponent
	extends NodeConfigComponentBase<UniqueValuesSettings>
	implements OnInit
{
	settingsToView(settings: WorkflowNodeSettings): void {
		// -- Get Configuration
		let currentConfig = <UniqueValuesSettings>settings;
		// -- Get Input Meta Data
		const meta_infos = this.getCurrentWorkflowNode().Properties.MetaInfo.get(
			this.InputPort
		);

		let columnListArray: string[] = [];

		// create UniqueValuesInfos from DsFields for table
		if (meta_infos != undefined && meta_infos.length > 0) {
			const input_field_infos = meta_infos[0].FieldsInfo;
			columnListArray = input_field_infos.map((fieldInfo) => {
				return fieldInfo.Name;
			});
			this.columnList = columnListArray;
		}

		// --If currentConfig not available (-> new node)
		if (currentConfig == undefined) {
			this.uniqueValuesList = [];
		} else {
			// -- otherweise load existing config into view
			currentConfig.UniqueValuesInfos.map((info) => {
				if (columnListArray.includes(info.ColumnName)) {
					// -- column still exists, do nothing
				} else {
					// column does not exist anymore, meaning column name currently not included -> include
					columnListArray.push(info.ColumnName);
				}
			});
			this.columnList = columnListArray;
            if(meta_infos !== undefined) {
				this.uniqueValuesList = this.infoToView(
					currentConfig.UniqueValuesInfos,
					meta_infos[0].FieldsInfo
				);
			}

		}
	}
	viewToSettings(): UniqueValuesSettings {
		const newSettings = <UniqueValuesSettings>{
			...this.getCurrentWorkflowNode().Properties.Configuration,
		};
		const uniqueValuesInfoViews = this.uniqueValuesList;
		const uniqueValuesInfos = this.viewToInfo(uniqueValuesInfoViews);

		newSettings.UniqueValuesInfos = uniqueValuesInfos;

		return newSettings;
	}
	onSettingsChanged(settings: UniqueValuesSettings): void {
		throw new Error("Method not implemented.");
	}

	uniqueValuesList: UniqueValuesInfoView[] = [];
	columnList: string[] = [];

	readonly InputPort = "Input";

	onAddEntry() {
		let newFilter = new UniqueValuesInfoView("", false);
		// newFilter.ColumnName = "";
		// newFilter.hasError = false;
		this.uniqueValuesList.push(newFilter);
		this.viewToInfo(this.uniqueValuesList);
		this.onUpdateSettings(false);
	}
	onDeleteEntry(index: number) {
		this.uniqueValuesList = [
			...this.uniqueValuesList.slice(0, index),
			...this.uniqueValuesList.slice(index + 1),
		];
		this.onUpdateSettings(false);
		// this.messageService.standardToastMessage({  severity : 'warn',
		//                                                 summary : 'Entry deleted',
		//                                                 detail : 'The selected entry was deleted successfully!'});
	}

	infoToView(
		infoList: UniqueValuesInfo[],
		meta?: FieldInfo[]
	): UniqueValuesInfoView[] {
		let newUniqueValuesInfosView: UniqueValuesInfoView[] = [];

		infoList.map((entry: UniqueValuesInfo) => {
			//let newInfoView = new UniqueValuesInfoView();

			//newInfoView.ColumnName = entry.ColumnName;

			let fieldInfoFound = meta?.find((field) => {
				return field.Name === entry.ColumnName;
			});

			const hasError = !fieldInfoFound;

			let newInfoView = new UniqueValuesInfoView(entry.ColumnName,hasError);

			newUniqueValuesInfosView.push(newInfoView);
		});
		// -- return final view to GUI
		return newUniqueValuesInfosView;
	}
	viewToInfo(uniqueValuesList: UniqueValuesInfoView[]): UniqueValuesInfo[] {
		// -- create new empty array
		let newUniqueValuesInfos: UniqueValuesInfo[] = [];

		uniqueValuesList.map((entry) => {
			let newSettings = new UniqueValuesInfo();
			newSettings.ColumnName = entry.ColumnName;
			newUniqueValuesInfos.push(newSettings);
		});
		return newUniqueValuesInfos;
	}

	constructor(
		protected workflowService: WorkflowsService,
		protected designerEventService: DesignerService
	) {
		super(workflowService, designerEventService);
	}

	ngOnInit(): void {
		super.ngOnInit();
	}
    onFocusLost(event: FocusEvent) {
        const div_left: boolean = (event.relatedTarget === null);

        console.log("Send from Div: ", div_left);

        if (div_left) {
            //this.onCheckDirtyFlag();
            this.onUpdateSettings(true);
        }
    }
}
