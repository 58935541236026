import mx from './mx-graph-loader';                       // <- import values from factory()
import { mxPopupMenuHandler, mxCell, mxMouseEvent, mxGraph } from 'mxgraph';
import { mxSchedulerGraph } from './mxSchedulerGraph';


// Call mxGraph functions by casting object to any: (graph as any).function(arg1, arg2, ...)
// http://tutorialspots.com/typescript-how-to-fix-error-property-does-not-exist-on-type-6233.html

export class mxSchedulerEditor extends mx.mxEditor {

    constructor(config?: any) {
        super(config);
    }

    createGraph(): mxGraph {
        // var graph = new mxWorkflowGraph(null,null,this.graphRenderHint);
        // var graph = new mxWorkflowGraph(null,null,this.["graphRenderHint"]);
        const graph = new mxSchedulerGraph(null, null, (this as any).graphRenderHint);
        return this.initGraph(graph);
    };

    /**
     * Loads a graph into the editor. Use this function when displaying existing workflows.
     * @param graph Graph
     * @returns
     */
    loadGraph(graph: mxGraph): mxGraph {
        (this as any).graph = graph;
        return this.initGraph(graph);
    }

    /**
     * Initialize the editor with the given graph.
     * @param graph Graph
     * @returns The given graph.
     */
    private initGraph(graph: mxGraph): mxGraph {

        // Enables rubberband, tooltips, panning
        graph.setTooltips(true);
        graph.setPanning(true);

        // Overrides the dblclick method on the graph to
        // invoke the dblClickAction for a cell and reset
        // the selection tool in the toolbar
        // this.installDblClickHandler(graph);
        // this.["installDblClickHandler"](graph);
        (this as any).installDblClickHandler(graph);

        // Installs the command history
        // this.installUndoHandler(graph);
        // this.["installUndoHandler"](graph);
        (this as any).installUndoHandler(graph);

        // Installs the handlers for the root event
        // this.installDrillHandler(graph);
        // this.["installDrillHandler"](graph);
        (this as any).installDrillHandler(graph);

        // Installs the handler for validation
        // this.installChangeHandler(graph);
        // this.["installChangeHandler"](graph);
        (this as any).installChangeHandler(graph);

        // Installs the handler for calling the
        // insert function and consume the
        // event if an insert function is defined
        // this.installInsertHandler(graph);
        // this.["installInsertHandler"](graph);
        (this as any).installInsertHandler(graph);

        // Redirects the function for creating the
        // popupmenu items
        const popUpHandlerFunction = (menu: mxPopupMenuHandler, cell: mxCell, evt: mxMouseEvent): any => {
            // return this.createPopupMenu(menu, cell, evt);
            // return this.["createPopupMenu"](menu, cell, evt);
            return (this as any).createPopupMenu(menu, cell, evt);
        };
        popUpHandlerFunction.bind(this);
        graph.popupMenuHandler.factoryMethod = popUpHandlerFunction;

        // graph.popupMenuHandler.factoryMethod =
        //   mx.mxUtils.bind(this, function(menu:mxPopupMenuHandler, cell:mxCell, evt:mxMouseEvent)
        //   {
        //     return this.createPopupMenu(menu, cell, evt);
        //   });


        // Redirects the function for creating
        // new connections in the diagram
        // const connectionHandlerFunction = (source: mxCell, target: mxCell, style?: string) : mxCell => {
        //   const correctCreateEdge = <(source: mxCell, target: mxCell, style?: string) => mxCell>this.createEdge;
        //   console.log("EDITOR : Edge Connection Handler!");
        //   return correctCreateEdge(source,target);
        // };
        // connectionHandlerFunction.bind(this);
        // graph.connectionHandler.factoryMethod = connectionHandlerFunction;

        // const fun = (source: mxCell, target: mxCell, style?: string) : mxCell => {return this.createEdge(source, target);};
        // const fun = (source: mxCell, target: mxCell, style?: string) : mxCell => {return this.["createEdge"](source, target);};
        const fun = (source: mxCell, target: mxCell, style?: string): mxCell => { return (this as any).createEdge(source, target); };
        fun.bind(this);
        graph.connectionHandler.factoryMethod = fun;



        // graph.connectionHandler.factoryMethod =
        //   mx.mxUtils.bind(this, function(source, target)
        //   {
        //     return this.createEdge(source, target);
        //   });

        // Maintains swimlanes and installs autolayout
        // this.createSwimlaneManager(graph);
        // this.["createSwimlaneManager"](graph);
        (this as any).createSwimlaneManager(graph);
        // this.createLayoutManager(graph);
        // this.["createLayoutManager"](graph);
        (this as any).createLayoutManager(graph);

        return graph;
    }
}
