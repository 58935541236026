<p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>

<p-dialog header="{{'Edit schedule' | translate}}" [(visible)]="displayEditSchedule" modal="modal" showEffect="fade"
    [style]="{width: '600px'}" (onHide)="resetForms()">
    <!-- <div style="line-height: 1.5">
        Change your datasource information here
    </div> -->


    <div class="p-grid p-ai-center p-jc-center">
        <div class="p-fluid p-formgrid">
            <div class="p-col-12 p-mb-12 p-lg-12 p-mb-lg-0">
                <div class="p-fluid">
                    <span class="p-float-label" style="margin-top: 40px;">
                        <input type="text" id="dsnameinputtext" pInputText [(ngModel)]="scheduleName">
                        <label for="dsnameinputtext">{{'Name' | translate}}</label>
                    </span>
                </div>
            </div>
            <div class="p-col-12 p-mb-2 p-lg-12 p-mb-lg-0">
                <div class="p-fluid">
                    <span class="p-float-label">
                        <textarea id="dsdescriptiontext" [cols]="30" pInputTextarea style="min-height:7rem;"
                            [(ngModel)]="scheduleDescription" autoResize="autoResize"></textarea>
                        <label for="dsdescriptiontext">{{'DescriptionOptional' | translate}}</label>
                    </span>
                </div>
            </div>
            <div class="p-col-12 p-mb-2 p-lg-12 p-mb-lg-0">
                <p-checkbox [binary]="true" [(ngModel)]="isActive" label="{{'active' | translate}}"></p-checkbox>
            </div>
        </div>
    </div>
    <app-system-message-log></app-system-message-log>

    <p-footer>
        <!-- <button type="button" pButton icon="pi pi-times" (click)="displayEditSchedule=false" label="{{'No' | translate}}"
        class="p-button-text"></button>
        <button type="button" pButton icon="pi pi-check" class="p-button-success" (click)="updateSchedule()" label="{{'Yes' | translate}}"
            class="p-button-text"></button> -->
        <button pButton pRipple type="button" icon="pi pi-times" (click)="displayEditSchedule=false"
            class="p-button-rounded p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'No' | translate}}"></button>
        <button pButton pRipple type="button" icon="pi pi-check" (click)="updateSchedule()"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'Yes' | translate}}" [icon]="savingInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'"></button>
    </p-footer>
</p-dialog>
