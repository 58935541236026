import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { forkJoin } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { concatMap, map, mergeMap } from "rxjs/operators";
import { UserRow, UserLicStripeRow } from "src/app/models/api/domain/NewDataSourceModelBase";
import { RegisterUserInfo } from "src/app/models/email.model";

import { GuiErrorCode, GuiErrorException } from "src/app/models/gui.error.model";
import { UserInfoView } from "src/app/models/user.model";
// import { UserLicStripeRow } from "src/app/models/license.model";
// import { User, UserInfoView } from "src/app/models/user.model";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { AuthService } from "src/app/services/auth.service";
import { EmailService } from "src/app/services/email.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { UserService } from "src/app/services/user.service";
import { SubSink } from "subsink";


export interface StripeUser {
	id: string;
	name?:string;
	email?:string;
}

export interface createStripeUserArg {
	name: string;
	email: string;
}

export interface StripeItem {
	price: string;
}
export interface createStripeSubArg {
	customer: string;
	items: StripeItem[];
}
export interface createStripeSubResponse {
	id: string;
	customer: string;
}

export interface Price {
	id: string;
	active:boolean;
	currency:string;
	meta_data:any[];
	nickname:string;
	product:string;
}

export interface Product {
	id:string;
	active:boolean;
	default_price:string;
	description:string;
	name:string;
}

export interface StripeDataList<T> {
	object:string;
	data:T[];
}

@Component({
	selector: "app-app-register",
	templateUrl: "./app-register.component.html",
})
export class AppRegisterComponent implements OnInit, OnDestroy {
	subs = new SubSink();
	user: UserInfoView;
    siteKey: string = "6Ld1mP4fAAAAACfc7sBwtDHF8HqzOL_4x-gSaYbt";
	isAzure: boolean;
	form = new UntypedFormGroup({
		username: new UntypedFormControl("", [
			Validators.required,
			Validators.minLength(4),
		]),
		email: new UntypedFormControl("", [Validators.required, Validators.email]),
		password: new UntypedFormControl("", [
			Validators.required,
			Validators.minLength(0),
		]),
		repeatPassword: new UntypedFormControl("", [
			Validators.required,
			Validators.minLength(0),
		]),
		stripeLicensePrice: new UntypedFormControl([{},{}], Validators.required),
		agreeToTerms: new UntypedFormControl(false, Validators.required),
		emailAzure: new UntypedFormControl("", [Validators.email]),
		isAzureAccount: new UntypedFormControl(false),
	});

	isSuccessful = false;
	isSignUpFailed = false;
	errorMessage = "";

	isLocalHost:boolean = false;


	constructor(
		private userService: UserService,
		public formBuilder: UntypedFormBuilder,
		public authService: AuthService,
		public errorService: SystemMessageLogService,
		private bionApi: ApiBackendService,
		private route: Router,
		private httpClient: HttpClient,
		private mailService: EmailService
	) {}
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngOnInit(): void {
		this.subs.sink = this.getPricesProducts().subscribe((licenses) => {
			console.log("licenses",licenses);
			this.stripeLicensePrices = licenses;
			//let defaultLicenseProduct = licenses.find((license) => { return license[1].name === "Free Test"});
			//this.currentLicenseProduct = defaultLicenseProduct;
			this.form.get("stripeLicensePrice").setValue(licenses.find((license) => { return license[1].name === "Free Test"}));
			//this.form.value.stripeLicensePrice = defaultLicenseProduct;
		});

		const url_info = this.getUrlInfo();
		this.isLocalHost = url_info[1] === "localhost";


		if(this.isLocalHost) {
			console.log("Is Local Host -> Disable Captcha Check!");
			this.isHumanFlag = true;
		}
	}

	openTermsOfCondition() {
		window.open(
            "https://bion-analytics.com/termsconditions/", "_blank");
	}
	openTermsOfDataProtection() {
		window.open(
            "https://bion-analytics.com/data-privacy-statement/", "_blank");
	}

	currentLicenseProduct: [Price, Product];
	onSelectSubscription(evt) {
		console.log(evt);
		let license = <[Price, Product]>evt.value;
		this.form.value.stripeLicensePrice = license;
		//this.currentLicenseProduct = license;
	}
	setAccount(event) {
		console.log(event);
		this.isAzure = event.checked;
	}
	onSubmit(): void {
		// // Validate form
		if (!this.form.valid) {
			this.errorService.handleError(new GuiErrorException(GuiErrorCode.notAllFieldsEntered));

			//this.errorService.sendErrorCode(GuiErrorCode.notAllFieldsEntered);
			this.isSuccessful = false;
			this.isSignUpFailed = true;
			return;
		}
		// //Validate PW length
		// if (this.form.value.password.length < 8) {
		// 	this.errorService.sendErrorCode(GuiErrorCode.invalidPassword);
		// 	this.isSuccessful = false;
		// 	this.isSignUpFailed = true;
		// 	return;
		// }
		// Validate repeatPW
		if (this.form.value.password !== this.form.value.repeatPassword) {
			this.errorService.handleError(new GuiErrorException(GuiErrorCode.notMatchPassword));

			//this.errorService.sendErrorCode(GuiErrorCode.notMatchPassword);
			this.isSuccessful = false;
			this.isSignUpFailed = true;
			return;
		}
		if(this.form.value.agreeToTerms === false) {
			this.errorService.handleError(new GuiErrorException(GuiErrorCode.notAgreedToTerms));
			//this.errorService.sendErrorCode(GuiErrorCode.notAgreedToTerms);
			this.isSuccessful = false;
			this.isSignUpFailed = true;
			return;
		}
		//Validate PW length
		if (!this.isHumanFlag) {
			this.errorService.handleError(new GuiErrorException(GuiErrorCode.invalidCaptcha));
			//this.errorService.sendErrorCode(GuiErrorCode.invalidCaptcha);
			this.isSuccessful = false;
			this.isSignUpFailed = true;
			return;
		}


		// Create new user class
		// const newUser = new User();
		// newUser.id = -1;
		// newUser.userName = this.form.value.username;
		// newUser.passWord = this.form.value.password;
		// newUser.eMail = this.form.value.email;
		// newUser.isAdmin = false;
		// newUser.firstName = "";
		// newUser.lastName = "";

		const newUser = new UserRow(-1,
			this.form.value.username,
			this.form.value.password,
			this.form.value.email,
			false,
			"",
			"");

		console.log(newUser);

		this.registerUserViaStripe(newUser).subscribe((result: number) => {
				this.isSuccessful = true;
				this.isSignUpFailed = false;

				//const email = this.form.value.email;
				const hostUrl = window.location.host;
// 				const staticUrl = "/#/resetPassword";
				const protocol = window.location.protocol;

				const finalUrl = protocol + "//" + hostUrl;

				const user_info = new RegisterUserInfo(newUser.userName, newUser.eMail, finalUrl);

            //this.subs.sink = this.mailService.registerUserEmail(newUser).subscribe(
			this.subs.sink = this.mailService.notifyRegisterUser(user_info).subscribe(
					(res) => {
						console.log(res);
						// this.isSuccessful = true;
						// this.isSignUpFailed = false;
						console.log("Registration Email was successfully send!");
						this.route.navigateByUrl("#");
					},
					(error: HttpErrorResponse) => {
						console.log(error);
						const warn_text = "Registration was successful, but the Email could not be send!";
						console.log(warn_text);
						alert(warn_text + ": " + error.message);
						this.route.navigateByUrl("#");
						//this.errorMessage = error.error.message;
						// this.errorService.sendHttpError(error);
						// this.isSignUpFailed = true;
					}
				);


// 				this.route.navigateByUrl("#");

		},(err) => {
			this.errorService.handleError(err);
		});


		// // API Call to register new user
		// this.subs.sink = this.userService.createUser(newUser).subscribe(
		// 	(res: UserInfo) => {
		// 		console.log("New User created: ", res);
		// 		this.isSuccessful = true;
		// 		this.isSignUpFailed = false;

		// 		// If successful & azure account checked
		// 		if (this.isAzure && this.form.value.emailAzure) {
		// 			console.log("Azure Account flag");
		// 			let newAzureRow = new UserAzureRow(
		// 				res.ID,
		// 				this.form.value.emailAzure
		// 			);

		// 			this.subs.sink = this.authService
		// 				.createUserAzureRow(newAzureRow)
		// 				.subscribe(
		// 					(res) => {
		// 						console.log(res);
		// 					},
		// 					(err) => {
		// 						this.errorService.sendHttpError(err);
		// 						this.isSignUpFailed = true;
		// 					}
		// 				);
		// 		}

		// 		this.subs.sink = this.mailService.registerUserEmail(res).subscribe(
		// 			(res) => {
		// 				console.log(res);
		// 				this.isSuccessful = true;
		// 				this.isSignUpFailed = false;
		// 				console.log("Registration Email was successfully send!");
		// 			},
		// 			(error: HttpErrorResponse) => {
		// 				console.log(error);
		// 				//this.errorMessage = error.error.message;
		// 				this.errorService.sendHttpError(error);
		// 				this.isSignUpFailed = true;
		// 			}
		// 		);
		// 		this.route.navigateByUrl("#");
		// 	},
		// 	(error: HttpErrorResponse) => {
		// 		//this.errorMessage = error.error.message;
		// 		this.errorService.sendHttpError(error);
		// 		this.isSignUpFailed = true;
		// 	}
		// );


		// STRI
	}

	// -------------- STRIPE API

	// https://stripe.com/docs/api/customers/create
	// 	curl https://api.stripe.com/v1/customers \
	//   -u sk_test_4eC39HqLyjWDarjtT1zdp7dc: \
	//   -d description="My First Test Customer (created for API docs)"


	bearerToken: string = "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";
	//stripeLicensePrice: string = "price_1KvjHmBky8tnug6DqnTZfYAg";

	stripeLicensePrices : [Price,Product][] = [];

	/**
	 * Joins the Price with the Products Relation. The Products contain more detailed inforamtion of the license.
	 * @returns Price-Product Pairs
	 */
	getPricesProducts() : Observable<[Price,Product][]> {

		const price_op = this.getStripePrices();
		const product_op = this.getStripeProducts();

		return price_op.pipe(mergeMap(prices => {
			return product_op.pipe(map(prodcuts => {
				const result = new Array<[Price,Product]>();
				for(let price of prices) {
					result.push([price, prodcuts.find(p => p.id === price.product)]);
				}
				return result;
			}))
		}))
	}

	/**
	 * Retrieves the Stripe Prices.
	 * @returns Prices.
	 */
	getStripePrices() : Observable<Price[]> {
		const api_sub_endpoint: string = "https://api.stripe.com/v1/prices";

		const options = this.makeStripeOptions();

		return this.httpClient.get<StripeDataList<Price>>(api_sub_endpoint, options).pipe(map(list => {
			const prices:Price[] = list.data;
			return prices;
		}));
	}

	/**
	 * Retrieve the Stripe Products
	 * @returns Products
	 */
	getStripeProducts() : Observable<Product[]> {
		const api_sub_endpoint: string = "https://api.stripe.com/v1/products";

		const options = this.makeStripeOptions();

		return this.httpClient.get<StripeDataList<Product>>(api_sub_endpoint, options).pipe(map(list => {
			const products:Product[] = list.data;
			return products;
		}));
	}

	makeStripeOptions() {
		let headers = new HttpHeaders();
		let bearerToken = "bearer " + this.bearerToken;
		headers = headers.append("Authorization", bearerToken);

		let options = {headers: headers};
		return options;
	}

	searchStripeUsers(name:string, eMail:string) : Observable<StripeDataList<StripeUser>> {
		let api_sub_endpoint: string = "https://api.stripe.com/v1/customers/search";
		let headers = new HttpHeaders();

		let bearerToken = "bearer " + this.bearerToken;
		headers = headers.append("Authorization", bearerToken);
		headers = headers.append("Content-Type", "application/x-www-form-urlencoded");


		let params = new HttpParams();
		const query = `name:'${name}' OR email:'${eMail}'`;
		params = params.append("query",query);

		let options = {headers: headers, params: params};

		let paramsString = params.toString();
		console.log(paramsString);

		// return this.httpClient.post<StripeUser[]>(api_sub_endpoint,paramsString,options);
		return this.httpClient.get<StripeDataList<StripeUser>>(api_sub_endpoint,options);
	}

	registerStripeUser(createStripeUserArg: createStripeUserArg): Observable<StripeUser> {
		let api_sub_endpoint: string = "https://api.stripe.com/v1/customers";
		let headers = new HttpHeaders();

		let bearerToken = "bearer " + this.bearerToken;
		headers = headers.append("Authorization", bearerToken);
		headers = headers.append("Content-Type", "application/x-www-form-urlencoded");
		let options = {headers: headers};

		let params = new HttpParams();
		params = params.append("name",createStripeUserArg.name);
		params = params.append("email",createStripeUserArg.email);

		let paramsString = params.toString();


		return this.httpClient.post<StripeUser>(api_sub_endpoint,paramsString,options);

	}

	linkStripeUserToBion(bionId: number, stripeId: string): Observable<number> {

		let userStripeRow: UserLicStripeRow = new UserLicStripeRow(bionId,stripeId);

		return this.bionApi.upsertUserLicStripeRow(userStripeRow);
	}

	registerUserViaStripe(user: UserRow) {

		const precheck_obs = this.searchStripeUsers(user.userName, user.eMail).pipe(map(result => {
			const users = result.data;
			console.log("Search Stripe Users: " + users);
			if(users.length > 0) {
				// const names = users.map(u => u.id + ", " + u.name + ", " + u.email).reduce( (a,b) => a + ", " + b );
				const names = users.map(u => u.name + " <" + u.email + ">").reduce( (a,b) => a + ", " + b );
				const msg = `The Username or E-mail is already in use: ${names}`;
				// throw throwError(new Error(msg));
				throw new Error(msg);
			}
		}));

		const bionUserObs = precheck_obs.pipe(concatMap(() => this.userService.createUser(user)));
		//let bionUserObs = this.userService.createUser(user);

		let stripeUserArg: createStripeUserArg = {name: user.userName,email: user.eMail};
		//let stripeUserObs = this.registerStripeUser(stripeUserArg);
		const stripeUserObs = precheck_obs.pipe(concatMap(() => this.registerStripeUser(stripeUserArg)));

		let stripeSubsObs = stripeUserObs.pipe(concatMap((stripeUserResult) => {
			console.log("stripeUserResult",stripeUserResult);

			let createStripeSubArg: createStripeSubArg = {customer: stripeUserResult.id, items: [{price: this.form.value.stripeLicensePrice[0].id}]};
			console.log("createStripeSubArg",createStripeSubArg);

			return this.createStripeSubscription(createStripeSubArg);
		}))

		return forkJoin(bionUserObs,stripeSubsObs).pipe(mergeMap((subscriptionResponse) => {
			console.log("subscriptionResponse",subscriptionResponse);
			let bionUser = subscriptionResponse[0];
			let stripeSubs = subscriptionResponse[1];

			return this.linkStripeUserToBion(bionUser.ID,stripeSubs.customer);

		}))
	}


	// https://stripe.com/docs/api/subscriptions/create
	// curl https://api.stripe.com/v1/subscriptions \

	// require 'stripe'Stripe.api_key = 'sk_test_4eC39HqLyjWDarjtT1zdp7dc'Stripe::Subscription.create({
	// 	customer: 'cus_4QE4bx4C5BVSrC',
	// 	items: [{price: 'price_1Kvd6I2eZvKYlo2CYsB3TPOW'},],})


	// 	val params = new util.ArrayList[NameValuePair]()
	// params.add(new BasicNameValuePair("customer", "cus_LcGYJ3bUykTnFz"))
	// //params.add(new BasicNameValuePair("items", items.toString))
	// params.add(new BasicNameValuePair("items[0][price]", "price_1KvjHmBky8tnug6DqnTZfYAg"))

	createStripeSubscription(stripeSubArg:createStripeSubArg): Observable<createStripeSubResponse> {

		let api_sub_endpoint: string = "https://api.stripe.com/v1/subscriptions";
		let headers = new HttpHeaders();

		let bearerToken = "bearer " + this.bearerToken;
		headers = headers.append("Authorization", bearerToken);
		headers = headers.append("Content-Type", "application/x-www-form-urlencoded");
		let options = {headers: headers};

		let params = new HttpParams();
		params = params.append("customer",stripeSubArg.customer);
		params = params.append("items[0][price]",this.form.value.stripeLicensePrice[0].id);
		let paramsString = params.toString();

		return this.httpClient.post<createStripeSubResponse>(api_sub_endpoint,paramsString,options);
	}


	registerUserAzureMail() {

	}

    isHumanFlag:boolean = false;
    responseCaptcha() {
        this.isHumanFlag = true;
    }

	getUrlInfo() : string[] {
		const arr = new Array<string>();

		arr.push(window.location.href);
		arr.push(window.location.hostname);
		arr.push(window.location.host);
		arr.push(window.location.origin);
		arr.push(window.location.pathname);

		return arr;
	}
	getLoginUrlInfo() : string[] {
		const arr = new Array<string>();

		arr.push(window.location.href);
		arr.push(window.location.hostname);
		arr.push(window.location.host);
		arr.push(window.location.origin);
		arr.push(window.location.pathname);

		return arr;
	}

}
