import { ActionPlan } from "src/app/models/schedule.model";
import { DataSourceRow, DataStoreRow } from "../../domain/NewDataSourceModelBase";
import { RightHolder } from "../authorization/RightHolder";
import { UserInfo } from "../session/UserInfo";

import { WorkflowProtocolEntry } from "./PowerBiProtocolEntry";
import { PowerBiPermission } from "./PowerBiPermission";
import { PowerBiEntry } from "src/app/models/api/models/powerbi-report/PowerBiEntry";


export namespace RichtPowerBiCake {

    export class PermissionEntry {
		Entry: PowerBiPermission;
		RightHolder?: RightHolder;
		constructor(Entry: PowerBiPermission,RightHolder?: RightHolder) {
			if(Entry === undefined) throw new Error( "Class 'PermissionEntry': Required value 'Entry' is undefined" );
			this.Entry = Entry;
			this.RightHolder = RightHolder;
		}
	}

	export class ProtocolEntry {
		Entry: WorkflowProtocolEntry;
		User?: UserInfo;
		constructor(Entry: WorkflowProtocolEntry,User?: UserInfo) {
			if(Entry === undefined) throw new Error( "Class 'ProtocolEntry': Required value 'Entry' is undefined" );
			this.Entry = Entry;
			this.User = User;
		}
	}

    export class PowerBiView {
		Entry: PowerBiEntry;
		Protocols: Array<ProtocolEntry>;
		Permissions: Array<PermissionEntry>;
		SchedulePlan: Array<ActionPlan>;
		DataSources: Array<DataSourceRow>;
		DataStores: Array<DataStoreRow>;
		constructor(Entry: PowerBiEntry,Protocols: Array<ProtocolEntry>,Permissions: Array<PermissionEntry>,SchedulePlan: Array<ActionPlan>,DataSources: Array<DataSourceRow>,DataStores: Array<DataStoreRow>) {
			if(Entry === undefined) throw new Error( "Class 'PowerBiView': Required value 'Workflow' is undefined" );
			if(Protocols === undefined) throw new Error( "Class 'PowerBiView': Required value 'Protocols' is undefined" );
			if(Permissions === undefined) throw new Error( "Class 'PowerBiView': Required value 'Permissions' is undefined" );
			if(SchedulePlan === undefined) throw new Error( "Class 'PowerBiView': Required value 'SchedulePlan' is undefined" );
			if(DataSources === undefined) throw new Error( "Class 'PowerBiView': Required value 'DataSources' is undefined" );
			if(DataStores === undefined) throw new Error( "Class 'PowerBiView': Required value 'DataStores' is undefined" );
			this.Entry = Entry;
			this.Protocols = Protocols;
			this.Permissions = Permissions;
			this.SchedulePlan = SchedulePlan;
			this.DataSources = DataSources;
			this.DataStores = DataStores;
		}
	}
}
