<div class="p-grid" style="margin:0 0">
    <div class="p-col-6" style="font-weight:bold; padding-left: 0;">
        {{'Choose DataStore' |translate }}<app-info-tooltip></app-info-tooltip>
    </div>

    <div class="p-fluid p-col">
        <span class="p-float-label">
            <p-dropdown id="datastoreName" appendTo="body" #dsSelection [options]="datastores"
                [(ngModel)]="selectedDataStore" [autoDisplayFirst]="false"
                (onChange)="onUpdateSettings(true)" [filter]="true" filterBy="name" optionLabel="name"></p-dropdown>
            <label for="datastoreName">{{'Selected Datastore' |translate }}</label>
            <!-- <button pButton pRipple type="button" icon="pi pi-plus" label="{{'Create' | translate }}"
            class="p-button p-mr-2 p-mb-2" pTooltip="{{'CreateNewDataStore' | translate }}" tooltipPosition="bottom"
            (click)="displayCreateDatastoreDialog(true)"></button> -->
        </span>
    </div>
</div>

<div class="p-grid" style="margin:0 0">
    <div class="p-col-6" style="padding-left: 0;">
        {{'Do you only want to show the latest data package?' |translate }}<app-info-tooltip></app-info-tooltip>
    </div>
    <div class="p-fluid p-col-6" style="text-align: right">
        <p-checkbox name="group1" [binary]="true" (onChange)="onUpdateSettings(true)"
        [(ngModel)]="latestPackageOnly" inputId="ny2"></p-checkbox>
    </div>
</div>
<div class="p-grid" style="margin:0 0">
    <div class="p-col-6" style="padding-left: 0;">
        {{'Do you want to include meta data?' |translate }}<app-info-tooltip></app-info-tooltip>
    </div>
    <div class="p-fluid p-col-6" style="text-align: right">
        <p-checkbox name="group1" [binary]="true" (onChange)="onUpdateSettings(true)"
        [(ngModel)]="includeMetaFields" inputId="ny3"></p-checkbox>
    </div>
</div>





<!-- <div class="p-grid">
    <div class="p-col-12 headerSection">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown id="dataStoreName" appendTo="body"  #dsSelection [options]="datastores" [(ngModel)]="selectedDataStore"
                    [autoDisplayFirst]="false" (onChange)="onUpdateSettings(true)" [filter]="true" filterBy="name"
                    optionLabel="name"></p-dropdown>
                <label for="dataStoreName">{{'Choose Destination' |translate }}</label>
            </span>
        </div>
    </div>
    <div class="p-col-6 headerSection">
        <div class="p-field-checkbox">
            <p-checkbox name="group1" [binary]="true" (onChange)="onUpdateSettings(true)" inputId="ny" [(ngModel)]="latestPackageOnly"></p-checkbox>
            <label for="ny">{{'Latest Data Only' |translate }}</label>
        </div>
    </div>
    <div class="p-col-6 headerSection">
        <div class="p-field-checkbox">
            <p-checkbox name="group1" [binary]="true" (onChange)="onUpdateSettings(true)" inputId="ny2" [(ngModel)]="includeMetaFields"></p-checkbox>
            <label for="ny2">{{'Include Meta Fields' |translate }}</label>
        </div>
    </div>
</div> -->
