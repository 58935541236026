import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { AdapterTypeInfo } from "src/app/models/connector.model";
import { DataStore, DataStoreField } from "src/app/models/datastore.model";
import { CubesService } from "src/app/services/cubes.service";
import { SubSink } from "subsink";

export class DataTypesChart {
	name: string = "";
	progress: number = 0;
	count: number = 0;
}

@Component({
	selector: "app-cube-charts",
	templateUrl: "./cube-charts.component.html",
	styleUrls: ["./cube-charts.component.scss"],
})
export class CubeChartsComponent implements OnInit {
	subs = new SubSink();
	@Input() fieldInfos: DataStoreField[] = [];

	fieldInfosFiltered: DataStoreField[] = [];

	dataTypesCountList: DataTypesChart[] = [];
	dataTypesCountFilteredList: DataTypesChart[] = [];

	selectedDatastore?: DataStore;
	adapterTypeInfos: AdapterTypeInfo<any, any>[] = [];
	adapterFilteredCount: number[] = [];

	pieData: any;
	pieOptions: any;

	constructor(private cubeService: CubesService) {}
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.fieldInfos.currentValue) {
			// Change detection if changes occured
			let fieldInfos: DataStoreField[] = changes.fieldInfos.currentValue;

			let dataTypesList = fieldInfos.map((field) => {
				return field.dataType;
			});
			let dataTypesListSet = new Set(dataTypesList);
			let dataTypesArray = [];

			for (let type of dataTypesListSet) {
				// Iterate through list to get counts of each datatype
				let counter = 0;
				for (let i of dataTypesList) {
					if (i === type) {
						counter++;
					}
				}

				//Init new component class
				let newEntry = new DataTypesChart();
				newEntry.name = type;
				newEntry.count = counter;
				let progress = (counter / dataTypesList.length) * 100;
				newEntry.progress = parseInt(progress.toFixed(2));

				//Push new entry to final Array
				dataTypesArray.push(newEntry);
			}
			this.dataTypesCountList = dataTypesArray.sort(
				(a, b) => b.count - a.count
			);
			this.dataTypesCountFilteredList = dataTypesArray.sort(
				(a, b) => b.count - a.count
			);
		}
	}

	createChart(ds?: DataStore) {
		let fieldInfos: DataStoreField[] = [];
		if (!ds) {
			fieldInfos = this.fieldInfos;
			let dataTypesList = fieldInfos.map((field) => {
				return field.dataType;
			});
			//this.fieldInfosFiltered = fieldInfos;
		} else {
			fieldInfos = this.fieldInfos.filter((field) => {
				return field.dataStore === ds.id;
			});
			let dataTypesList = fieldInfos.map((field) => {
				return field.dataType;
			});
		}
		// Create DataTypes Chart
		let dataTypesList = fieldInfos.map((field) => {
			return field.dataType;
		});
		let dataTypesListSet = new Set(dataTypesList);
		let dataTypesArray = [];

		for (let type of dataTypesListSet) {
			// Iterate through list to get counts of each datatype
			let counter = 0;
			for (let i of dataTypesList) {
				if (i === type) {
					counter++;
				}
			}

			//Init new component class
			let newEntry = new DataTypesChart();
			newEntry.name = type;
			newEntry.count = counter;
			let progress = (counter / dataTypesList.length) * 100;
			newEntry.progress = parseInt(progress.toFixed(2));

			//Push new entry to final Array
			dataTypesArray.push(newEntry);
		}

		this.dataTypesCountFilteredList = dataTypesArray.sort(
			(a, b) => b.count - a.count
		);

		//this.createPieChart(ds);
	}
	// createPieChart(ds?: dss.DataSource) {
	// 	// Get all ConnectorAdapter

	// 	this.subs.sink = this.datasourceService
	// 		.getConnectors()
	// 		.subscribe((adapterInfo: dss.DataSourceAdapter[]) => {
	// 			//console.log("adapterTypeInfos", adapterInfo);

	// 			this.subs.sink = this.datasourceService
	// 				.getConnectorsInfo()
	// 				.subscribe((res: dss.DataSourceConnector[]) => {
	// 					let result = res;
	// 					// if ds is given, prefilter entries
	// 					if (ds) {
	// 						result = result.filter((val) => {
	// 							return val.DataSource === ds.id;
	// 						});
	// 					}
	// 					// Create new Array with respective adapter
	// 					let adapterFiltered: dss.DataSourceAdapter[] = new Array();
	// 					result.map((adapter: dss.DataSourceConnector) => {
	// 						adapterInfo.map((connector: dss.DataSourceAdapter) => {
	// 							if (connector.Name === adapter.Connector)
	// 								adapterFiltered.push(connector);
	// 						});
	// 					});

	// 					//console.log("adapterFiltered", adapterFiltered);

	// 					// Create PieChart Label
	// 					let pieLabels: string[] = new Array();
	// 					adapterFiltered.map((i: dss.DataSourceAdapter) => {
	// 						pieLabels.push(i.Name);
	// 					});

	// 					let pieLabelSet = new Set(pieLabels);
	// 					let pieLabelSetArray = new Array();

	// 					for(let item of pieLabelSet.values()){
	// 						pieLabelSetArray.push(item);
	// 					}

	// 					// Create chart objects with counts
	// 					let adapterFilteredSet = new Set(adapterFiltered);
	// 					let adapterFilteredCount: number[] = new Array();

	// 					for (var type of adapterFilteredSet) {
	// 						// Iterate through list to get counts of each datatype
	// 						let counter = 0;
	// 						for (let i of adapterFiltered) {
	// 							if (i === type) {
	// 								counter++;
	// 							}
	// 						}

	// 						//Push new entry to final Array
	// 						adapterFilteredCount.push(counter);
	// 					}
	// 					this.adapterFilteredCount = adapterFilteredCount;

	// 					// Initialize pieChart data
	// 					this.pieData = {
	// 						labels: pieLabelSetArray,
	// 						datasets: [
	// 							{
	// 								data: adapterFilteredCount,
	// 								backgroundColor: [
	// 									"rgb(118, 189, 220)",
	// 									"rgb(118, 189, 220)",
	// 									"rgb(205, 234, 250)",
	// 									"rgb(205, 234, 250)",
	// 									"rgb(205, 234, 250)",
	// 									"rgb(242, 249, 253)",
	// 								],
	// 							},
	// 						],
	// 					};
	// 				});
	// 		});
	// }

	ngOnInit(): void {
		// this.pieOptions = {
		// 	plugins: {
		// 		legend: {
		// 			display: false,
		// 			labels: {
		// 				fontColor: "#A0A7B5",
		// 			},
		// 		},
		// 	},
		// };
		this.subs.sink = this.cubeService.selectedDataStoreEmitter.subscribe(
			(ds: DataStore) => {
				if (ds === this.selectedDatastore) {
					this.selectedDatastore = undefined;
					this.createChart();
					return;
				}
				this.selectedDatastore = ds;

				this.createChart(ds);
			}
		);
	}
}
