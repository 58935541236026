<div class="p-grid container" *ngIf="status.length > 0" style="width: 100%;">
    <div class="p-col section" *ngFor="let stat of status">
        <div class="card item p-shadow-2" style="margin: 10px 40px 0px 20px">

            <div class="itemContainer">
                <div class="itemSection">
                    <i *ngIf="stat.loading === true" class="pi pi-spin pi-spinner" style="font-size: 32px;"></i>
                    <i *ngIf="stat.status === 0 && stat.loading === false" class="pi pi-check-circle"
                        style="font-size: 32px;color:rgb(118, 158, 118)"></i>
                    <i *ngIf="stat.status === 1 && stat.loading === false" class="pi pi-times-circle"
                        style="font-size: 32px;color:rgb(255, 170, 170)"></i>
                    <i *ngIf="stat.status === 2 && stat.loading === false" class="pi pi-question-circle"
                        style="font-size: 32px;"></i>

                    <h5 style="color: rgb(170, 170, 170)">{{stat.text | translate}}</h5>
                    <!-- <div *ngIf="stat.source === 'datapool' && stat.loading === false">

                    <button pButton pRipple type="button"  icon="pi pi-times" label="{{'Close' | translate}}"
                        (click)="emitCloseDialog()" class="p-button-text p-button-rounded p-mr-2 p-mb-2"></button>
                    <button pButton pRipple type="button" (click)="emitLoadPsa(this.activeIndex)"
                        class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2" label="{{'Load' | translate}}"
                        icon="pi pi-check" [disabled]="buttonHidden"></button>
                    </div> -->
                </div>

            </div>
        </div>
    </div>
</div>


<!-- <div class="p-grid container" *ngIf="status.length > 0" style="width: 100%;">
    <div class="p-col section" [(ngModel)]="dataSourceStatus">
        <div class="card item p-shadow-2" style="margin: 10px 40px 0px 20px">
            <div class="itemContainer">
                <div class="itemSection">
                    <i *ngIf="dataSourceStatus.loading === true" class="pi pi-spin pi-spinner" style="font-size: 32px;"></i>
                    <i *ngIf="dataSourceStatus.status === 0 && dataSourceStatus.loading === false" class="pi pi-check-circle" style="font-size: 32px;color:rgb(118, 158, 118)"></i>
                    <i *ngIf="dataSourceStatus.status === 1 && dataSourceStatus.loading === false" class="pi pi-times-circle" style="font-size: 32px;color:rgb(255, 170, 170)"></i>
                    <i *ngIf="dataSourceStatus.status === 2 && dataSourceStatus.loading === false" class="pi pi-question-circle" style="font-size: 32px;"></i>

                    <h5 style="color: rgb(170, 170, 170)">{{stat.text | translate}}</h5>
                    <div *ngIf="dataSourceStatus.source === 'datapool' && dataSourceStatus.loading === false">

                    <button pButton pRipple type="button"  icon="pi pi-times" label="{{'Close' | translate}}"
                        (click)="emitCloseDialog()" class="p-button-text p-button-rounded p-mr-2 p-mb-2"></button>
                    <button pButton pRipple type="button" (click)="emitLoadPsa(this.activeIndex)"
                        class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2" label="{{'Load' | translate}}"
                        icon="pi pi-check" [disabled]="buttonHidden"></button>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="p-col section" *ngIf="dataSourceStatus.status === 'successful'" [(ngModel)]="dataPoolStatus">
        <div class="card item p-shadow-2" style="margin: 10px 40px 0px 20px">

            <div class="itemContainer">
                <div class="itemSection">
                    <i *ngIf="dataPoolStatus.loading === true" class="pi pi-spin pi-spinner" style="font-size: 32px;"></i>
                    <i *ngIf="dataPoolStatus.status === 0 && dataPoolStatus.loading === false" class="pi pi-check-circle" style="font-size: 32px;color:rgb(118, 158, 118)"></i>
                    <i *ngIf="dataPoolStatus.status === 1 && dataPoolStatus.loading === false" class="pi pi-times-circle" style="font-size: 32px;color:rgb(255, 170, 170)"></i>
                    <i *ngIf="dataPoolStatus.status === 2 && dataPoolStatus.loading === false" class="pi pi-question-circle" style="font-size: 32px;"></i>

                    <h5 style="color: rgb(170, 170, 170)">{{dataPoolStatus.text | translate}}</h5>
                    <div *ngIf="stat.source === 'datapool' && stat.loading === false">

                    <button pButton pRipple type="button"  icon="pi pi-times" label="{{'Close' | translate}}"
                        (click)="emitCloseDialog()" class="p-button-text p-button-rounded p-mr-2 p-mb-2"></button>
                    <button pButton pRipple type="button" (click)="emitLoadPsa(this.activeIndex)"
                        class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2" label="{{'Load' | translate}}"
                        icon="pi pi-check" [disabled]="buttonHidden"></button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div> -->
