import { MenuItem } from "primeng/api/menuitem";
import { ToolbarProvider } from "./toolbar-providers";
import { PowerBiReportService } from "src/app/services/power-bi-report.service";
import { PowerBiEntry } from "src/app/models/api/models/powerbi-report/PowerBiEntry";
import { PowerBiActionEvent, PowerBiDialogActionType } from "src/app/models/api/models/powerbi-report/PowerBiActionEvent";
import { AppMainComponent } from "src/app/app.main.component";
import { PowerBIModel } from "src/app/models/api/models/PowerBIModel";

export class ReportToolbar implements ToolbarProvider<PowerBIModel.ReportInfo> {
    constructor(private reportService: PowerBiReportService, private appMain: AppMainComponent) { }

    subscribeToEmitters() {
        let selectedObs = this.reportService.selectedReportEmitter;

        return selectedObs
    }

    initObjectMenu(): MenuItem[] {

        if(this.appMain.currentUserFull.UserInfo.IsAdmin == true ) {
            return [

                {
                    label: "New",
                    icon: "pi pi-fw pi-plus",
                    styleClass: "p-button p-button-success p-mr-2 p-mb-2",
                    command: () => {
                        //this.dataStoresService.displayNewDataStoreDialog.emit(true);
                        this.reportService.PowerBiActionEmitter.emit(new PowerBiActionEvent(true, "Create report", PowerBiDialogActionType.createReport, "Create", undefined));
                    }
                },
            ]
        } else {
            return []
        }

    }
    initActionMenu(): MenuItem[] {
        return [
        ]
    }
}
