import { Component, OnInit } from '@angular/core';
import { WorkflowNodeSettings } from 'src/app/models/api/com/bion/etl/Workflow';
import { DestinationPlugIn } from 'src/app/models/api/models/etl/DestinationPlugIn';
import { Destination } from 'src/app/models/api/models/staging/Destination';
import { DesignerService } from 'src/app/services/designer.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { NodeConfigComponentBase } from '../node-config-component-base';

@Component({
  selector: 'app-destination-node',
  templateUrl: './destination-node.component.html',
  styleUrls: ['./destination-node.component.scss']
})
export class DestinationNodeComponent extends NodeConfigComponentBase<DestinationPlugIn.Settings> implements OnInit {
  settingsToView(settings: DestinationPlugIn.Settings): void {
    this.destinations = settings.Destinations;
    this.selectedDestination = settings.SelectedDestination;
  }
  viewToSettings(): DestinationPlugIn.Settings {

    const newSettings = <DestinationPlugIn.Settings>{
			...this.getCurrentWorkflowNode().Properties.Configuration,
		};

    newSettings.Destinations = this.destinations;
    newSettings.SelectedDestination = this.selectedDestination;

    return newSettings;
  }
  onSettingsChanged(settings: DestinationPlugIn.Settings): void {
    throw new Error('Method not implemented.');
  }

  //current_settings?:DestinationPlugIn.Settings = undefined;
  destinations: Array<Destination<any>> = [];
	selectedDestination?: Destination<any> = undefined;

  constructor(protected workflowService: WorkflowsService,protected designerService: DesignerService,) {
    super(workflowService, designerService);
  }

  onDestinationChanged(event:PointerEvent) {
    this.onUpdateSettings(true);
  }

  ngOnInit(): void {
    //this.current_settings.SelectedDestination.Name
  }

}
