import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { WorkflowNodeGuiInfo } from 'src/app/models/designer.models';

@Component({
  selector: 'app-node-infos',
  templateUrl: './node-info.component.html',
  styleUrls: ['./node-info.component.scss']
})
export class NodeInfosComponent implements OnInit {

  @Input() selectedNode?: WorkflowNodeGuiInfo;

    constructor(private sanitizer: DomSanitizer, private translate: TranslateService) {}

	ngOnInit(): void {
    
	}
  
	getSafeUrl(url: string): SafeResourceUrl {
	  return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}

}
