<p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>

<p-dialog header="{{headerText | translate}}" [baseZIndex]="1000" [(visible)]="displayPushNewDataDialog" modal="modal" showEffect="fade"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '70vw'}" (onHide)="resetDialog()">
    <app-system-message-log></app-system-message-log>

    <div class="spinner-container" *ngIf="loading" @fade>
        <app-loading-screen></app-loading-screen>
    </div>


    <!-- <div class="p-grid">
        <div class="p-col-12 p-grid">
            <div class="p-col-8" *ngIf="selectedAdapterInfo"
            [ngSwitch]="selectedAdapterInfo.Name">
            <h6 style="color: rgb(170, 170, 170)"> <span>{{selectedAdapterInfo.Name}}</span></h6>
                <span style="margin-top: 25px;">
                    <app-excel-connector *ngSwitchCase="EXCEL_ADAPTER" [(currentConnectorView)]="currentConnectorView" (onSettingsChangedEmitter)="onSettingsChanged($event)">
                    </app-excel-connector>
                    <app-csv-connector *ngSwitchCase="CSV_ADAPTER" [(currentConnectorView)]="currentConnectorView" (onSettingsChangedEmitter)="onSettingsChanged($event)">
                    </app-csv-connector>
                    <app-json-connector *ngSwitchCase="JSON_ADAPTER" [(currentConnectorView)]="currentConnectorView">
                    </app-json-connector>
                    <app-spss-connector *ngSwitchCase="SPSS_FILE_ADAPTER" [(currentConnectorView)]="currentConnectorView">
                    </app-spss-connector>
                </span>
            </div>

            <div class="p-col">
                <p-table [value]="previewErrorCheckResult" responsiveLayout="scroll">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Field</th>
                            <th>Check</th>
                            <th>Error Description</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-field>
                        <tr>
                            <td>{{field[0].name}}</td>
                            <td>{{field[1]}}</td>
                            <td>{{field[2]}}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="3">{{'NoEntryFound' | translate}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
        <div class="p-col-12">
     
                <p-table #dt [columns]="previewColumns" [value]="previewRows" styleClass="p-datatable-sm" scrollDirection="both"
                    [scrollable]="true" scrollHeight="250px" autoLayout="true" [lazy]="true" [rows]="20">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th pResizableColumn [style]="{'width':'150px', 'font-size':'12px'}"
                                *ngFor="let col of columns">
                                {{col.Name}}
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr>
                            <td [style]="{'width':'150px', 'font-size':'12px'}"
                                *ngFor="let col of columns">
                                {{rowData[col.Name]}}
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="1">{{'NoEntryFound' | translate}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            

        </div>

    </div> -->

    <div *ngIf="selectedAdapterInfo"
        [ngSwitch]="selectedAdapterInfo.Name">
        <h6 style="color: rgb(170, 170, 170)"> <span>{{selectedAdapterInfo.Name}}</span></h6>
            <span style="margin-top: 25px;">
                <app-excel-connector *ngSwitchCase="EXCEL_ADAPTER" [(currentConnectorView)]="currentConnectorView" (onSettingsChangedEmitter)="onSettingsChanged($event)">
                </app-excel-connector>
                <app-csv-connector *ngSwitchCase="CSV_ADAPTER" [(currentConnectorView)]="currentConnectorView" (onSettingsChangedEmitter)="onSettingsChanged($event)">
                </app-csv-connector>
                <app-json-connector *ngSwitchCase="JSON_ADAPTER" [(currentConnectorView)]="currentConnectorView">
                </app-json-connector>
                <app-spss-connector *ngSwitchCase="SPSS_FILE_ADAPTER" [(currentConnectorView)]="currentConnectorView">
                </app-spss-connector>
            </span>
    </div>

    <p-tabView>
        <p-tabPanel header="Preview">
            <p-table #dt [columns]="previewColumns" [value]="previewRows" styleClass="p-datatable-sm" scrollDirection="both"
            [scrollable]="true" scrollHeight="200px" autoLayout="true" [lazy]="true" [rows]="20">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th pResizableColumn [style]="{'width':'150px', 'font-size':'12px'}"
                        *ngFor="let col of columns">
                        {{col.Name}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                    <td [style]="{'width':'150px', 'font-size':'12px'}"
                        *ngFor="let col of columns">
                        {{rowData[col.Name]}}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="1">{{'NoEntryFound' | translate}}</td>
                </tr>
            </ng-template>
        </p-table>
        </p-tabPanel>
        <p-tabPanel header="Errors">
            <p-table [value]="previewErrorCheckResult" [scrollable]="true" scrollHeight="200px" responsiveLayout="scroll">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Field</th>
                        <th>Check</th>
                        <th>Error Description</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-field>
                    <tr>
                        <td>{{field[0].name}}</td>
                        <td>{{field[1]}}</td>
                        <td>{{field[2]}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="3">{{'NoEntryFound' | translate}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </p-tabPanel>

    </p-tabView>

    <!-- <div id="PreviewErrorCheck">
        <p-accordion>
            <p-accordionTab header="Error Check">
                <p-table [value]="previewErrorCheckResult" responsiveLayout="scroll">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Field</th>
                            <th>Check</th>
                            <th>Error Description</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-field>
                        <tr>
                            <td>{{field[0].name}}</td>
                            <td>{{field[1]}}</td>
                            <td>{{field[2]}}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="3">{{'NoEntryFound' | translate}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-accordionTab>
        </p-accordion>
    </div>

    <div id="Preview">
        <p-table #dt [columns]="previewColumns" [value]="previewRows" styleClass="p-datatable-sm" scrollDirection="both"
            [scrollable]="true" scrollHeight="250px" autoLayout="true" [lazy]="true" [rows]="20">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th pResizableColumn [style]="{'width':'150px', 'font-size':'12px'}"
                        *ngFor="let col of columns">
                        {{col.Name}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                    <td [style]="{'width':'150px', 'font-size':'12px'}"
                        *ngFor="let col of columns">
                        {{rowData[col.Name]}}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="1">{{'NoEntryFound' | translate}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div> -->
    <p-footer>
        <button pButton pRipple type="button" icon="pi pi-upload" (click)="onLoadToPSA()"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2" label="{{'Load' | translate}}"
            [icon]="loading ? 'pi pi-spin pi-spinner' : 'pi pi-upload'" [disabled]="buttonDisabled"></button>
    </p-footer>
</p-dialog>