import { Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { WorkflowRepositoryEntry } from "src/app/models/api/models/workflow/WorkflowRepositoryEntry";
import { WorkflowActionEvent, WorkflowDialogActionType } from "src/app/models/dialog-actions.model";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { SubSink } from "subsink";

@Component({
	selector: "app-delete-workflow-dialog",
	templateUrl: "./delete-workflow-dialog.component.html",
	styleUrls: ["./delete-workflow-dialog.component.scss"],
	providers: [MessageService]
})
export class DeleteWorkflowDialogComponent implements OnInit,OnDestroy {
	subs = new SubSink();
	savingInProgress: boolean = false;
	displayDeleteWorkflow: boolean = false;
	selectedWorkflow?: WorkflowRepositoryEntry;

	constructor(
		private workflowService: WorkflowsService,
		private systemLogService: SystemMessageLogService,
		private messageService: MessageService,
		private translate: TranslateService
	) {}

	ngOnInit(): void {
		this.subs.sink = this.workflowService.workflowDialogActionSendEmitter.subscribe(
			(res: WorkflowActionEvent) => {
				if(res.actionType === WorkflowDialogActionType.deleteWorkflow) {
					this.selectedWorkflow = res.wf;
					this.displayDeleteWorkflow = res.display;
				}
			}, (err: Error) => {
				this.systemLogService.handleError(err)
			}
		);
	}
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	deleteWorkflow(event?: WorkflowRepositoryEntry) {

		if(event === undefined) return;

		this.savingInProgress = true;
		this.workflowService.deleteWorkflowObject(event.id.toString()).then(
			(res) => {
				this.savingInProgress = false;
                this.workflowService.workflowsChangedEmitter.emit("Delete workflow");

				this.messageService.add({
					severity: "success",
					summary: this.translate.instant("Message.DeleteWorkflowSuccess.Title"),
					detail: this.translate.instant("Message.DeleteWorkflowSuccess.Text1") + event.id + this.translate.instant("Message.DeleteWorkflowSuccess.Text2"),
				});

				this.displayDeleteWorkflow = false;
			},
			(err: Error) => {
				this.savingInProgress = false;
				this.systemLogService.handleError(err);
			}
		);
	}
}
