export namespace EmailValidationPlugIn {
    export class EmailValidationInfo {
        ColumnName: string;
        constructor(ColumnName: string) {
            if(ColumnName === undefined) throw new Error( "Class 'EmailValidationInfo': Required value 'ColumnName' is undefined" );
            this.ColumnName = ColumnName;
        }
    }
    export class Settings {
        EmailValidationInfos: Array<EmailValidationInfo>;
        ApiKey: string;
        SendingActive: boolean;
        constructor(EmailValidationInfos: Array<EmailValidationInfo>,ApiKey: string,SendingActive: boolean) {
            if(EmailValidationInfos === undefined) throw new Error( "Class 'Settings': Required value 'EmailValidationInfos' is undefined" );
            if(ApiKey === undefined) throw new Error( "Class 'Settings': Required value 'ApiKey' is undefined" );
            if(SendingActive === undefined) throw new Error( "Class 'Settings': Required value 'SendingActive' is undefined" );
            this.EmailValidationInfos = EmailValidationInfos;
            this.ApiKey = ApiKey;
            this.SendingActive = SendingActive;
        }
    }
}