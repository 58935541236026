import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CsvPushSettings } from 'src/app/models/api/com/bion/csv/CsvPushSettings';
import { CsvUtil, ParserCharacter } from 'src/app/models/csv-util';

@Component({
  selector: 'app-csv-adapter',
  templateUrl: './csv-adapter.component.html',
  styleUrls: ['./csv-adapter.component.scss']
})
export class CsvAdapterComponent implements OnInit {

  constructor() { }

  parseCharaters:Array<ParserCharacter> = CsvUtil.getParserCharaters();

  @Input()
  config:CsvPushSettings;

  @Output()
  public configChanged: EventEmitter<CsvPushSettings> = new EventEmitter<CsvPushSettings>();

  ngOnInit(): void {
  }

    /**
   * Generelles Callback
   */
    onSettingsChanged() {
      console.log("On Settings Changed", this.config);
      this.configChanged.emit(this.config);
    }

}
