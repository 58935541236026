
<div style="width: 100%;margin:10px auto">
    <div *ngIf="schedules">
        <p-chart type="line" id="lineChart" [data]="lineData" [options]="lineOptions" [responsive]="true"
            [style]="{'width': '100%'}" height="290px"></p-chart>
    </div>
</div>



<!-- <div class=" p-grid">
    <div class="p-col-9 p-xl-12"> -->

        <!-- <div class="device-status">
            <div class="p-col-9 p-xl-12">
                <div class="card-header">
                    <div class="card-title">
                        <h6>{{'Actions' | translate}}</h6>
                    </div>
                </div>
                <div class="progress" *ngFor="let type of actionsCountFilteredList">
                    <span>{{type.name}}</span>
                    <p-progressBar [value]="type.progress" [showValue]="true"></p-progressBar>
                    <span>{{type.count}}</span>
                </div>
                <div style="height: 100px" *ngIf="actionsCountFilteredList.length === 0">
                    <app-no-data-available [view]="'action'" [url]="'/action'"></app-no-data-available>
                </div>
            </div>
            <div class="p-col-12">
                <div class="card-header">
                    <div class="card-title">
                        <h6>{{'Frequencies' | translate}}</h6>
                    </div>
                </div>
                <div class="progress" *ngFor="let type of frequenciesCountFilteredList">
                    <span>{{type.name}}</span>
                    <p-progressBar [value]="type.progress" [showValue]="true"></p-progressBar>
                    <span>{{type.count}}</span>
                </div>
                <div style="height: 100px" *ngIf="frequenciesCountFilteredList.length === 0">
                    <app-no-data-available [view]="'frequency'" [url]="'/frequency'"></app-no-data-available>
                </div>
            </div>
        </div> -->
    <!-- </div> -->
    <!--
            <div class="p-col-12 p-xl-6">
                <div class="card p-shadow-2">
                    <div class="card-header">
                        <div class="card-title">
                            <h6>{{'UpcomingAppointments' | translate}}</h6>
                        </div>
                    </div>
                    <p-timeline [value]="upcomingAppointments">
                        <ng-template pTemplate="content" let-event>
                            <small class="p-text-secondary">{{event.date | date: 'dd/MM/yyyy' }}</small>
                        </ng-template>
                        <ng-template pTemplate="opposite" let-event>
                            {{event.actionPlan.name}}
                        </ng-template>
                    </p-timeline>
                    <div style="height: 250px" *ngIf="upcomingAppointments.length === 0">
                        <app-no-data-available  [view]="'appointment'"  [url]="'/appointment'"></app-no-data-available>
                    </div>
                </div>
            </div> -->



<!-- </div> -->
