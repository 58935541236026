import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { IntegratedSourceController } from 'src/app/models/api/controllers/IntegratedSourceController';
import { IntegratedSourceModel } from 'src/app/models/api/models/IntegratedSourceModel';
import { IntegratedSourceService } from 'src/app/services/integrated-source.service';
import { ConsoleLogger, LogLevel } from 'src/app/views/designer/components/workflow-graph/logger';

@Component({
  selector: 'app-choose-connector-int-page',
  templateUrl: './choose-connector-int-page.component.html',
  styleUrls: ['./choose-connector-int-page.component.scss']
})
export class ChooseConnectorIntPageComponent implements OnInit {

  items: MenuItem[] = [{ label: this.translate.instant('1. Konnektor auswählen'), disabled: false}, { label: this.translate.instant('2. Datenquelle einrichten'),disabled: true }, { label: this.translate.instant('3. Tabellen auswählen'),disabled: true}];



  connector_infos: IntegratedSourceController.ConnectorInfo[] = [];
  connector_info_selected?:IntegratedSourceController.ConnectorInfo;
  is_loading: boolean = false;
  goto_ds_button_disabled:boolean = true;
  private logger = new ConsoleLogger(typeof(this), LogLevel.Info);

  constructor(private service_api: IntegratedSourceService, private router: Router, private translate: TranslateService) { }

  ngOnInit(): void {
    // load connectors
    this.setLoading(true);
    this.service_api.getConnectors().subscribe(
      connectors => this.handle_connectors(connectors),
      err => this.handle_error(err),
      () => this.setLoading(false))
  }

  setLoading(isLoading: boolean) {
    this.is_loading = isLoading;
  }

  handle_error(e: Error) {
    this.logger.error("Error Handler", e);
    this.setLoading(false);
  }

  /**
 * Alle Konnektoren wurden geholt
 * @param conns Konnektoren
 */
  handle_connectors(conns: IntegratedSourceController.ConnectorInfo[]) {
    this.connector_infos = conns;
  }

  on_connector_selected(info: IntegratedSourceController.ConnectorInfo) {
    this.logger.info("On Connector Selected", info);
    this.connector_info_selected = info;
    this.goto_ds_button_disabled = false;
    //const values:IntegratedSourceModel.ConnectorKey<string>[] = event;
    // const key: IntegratedSourceModel.ConnectorKey<string> = event.value;
    const key: IntegratedSourceModel.ConnectorKey<string> = info.Key;
    // check
    // choose
    // go
    //this.gotoCreateSource(key);
  }

  on_create_source_click(event:any) {
    this.logger.debug("On create source click", event);
    if(this.connector_info_selected) {
      this.logger.debug("call gotoCreateSource()", this.connector_info_selected);
      this.gotoCreateSource(this.connector_info_selected.Key);
    } else {
      alert("You must select a connector");
    }
  }

  gotoCreateSource(key: IntegratedSourceModel.ConnectorKey<string>) {
    const id = key.Id;
    const origin = key.Origin;
    // SourceIntegrationCreateDatasource/:origin/:id
    this.router.navigate(['/', 'SourceIntegrationCreateDatasource', origin, id]);
  }
}
