import { Component, OnInit, ViewChild } from "@angular/core";
import { MenuItem } from "primeng/api";
import { WorkflowEngineControllers } from "src/app/models/api/controllers/WorkflowEngineController";
import { NewWorkflowResult } from "src/app/models/api/models/workflow/NewWorkflowResult";
import { ExceptionInfo } from "src/app/models/designer.models";
import { IWorkflowGraphEventData, WorkflowExecutedData, WorkflowGraphEventType } from "../workflow-graph/workflow-graph-events";
import { DesignProgresSpinnerEvent, WorkflowOperationType, DesignerEvent, DesignerService } from "src/app/services/designer.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { SubSink } from "subsink";

@Component({
	selector: "app-nodes-errors",
	templateUrl: "./nodes-errors.component.html",
	styleUrls: ["./nodes-errors.component.scss"],
})
export class NodesErrorsComponent implements OnInit {
	subs = new SubSink;
	loading: boolean = false;
	@ViewChild('op2') op2;

	nodeLogResult?: NewWorkflowResult.NodeRunInfo<WorkflowEngineControllers.NodeRunResult<any>>[];
    nodeLogErrors?:NewWorkflowResult.NodeRunInfo<WorkflowEngineControllers.NodeRunResult<any>>[];
	wfLogResult?: NewWorkflowResult.LogEntry[];
	errorLogResult?: [string, ExceptionInfo][];
	selectedNodeErrors:[string, ExceptionInfo][];
    updateSettingsStatus: boolean = false;
	runWorkflowStatus: boolean = false;

	nodeLogCols: any[] = [
		{ field: "NodeID", header: "NodeID", width: "10%" },
		{ field: "Node", header: "Node", width: "20%" },
		{ field: "IsSuccess", header: "IsSuccess", width: "60%" },
	];
	wfLogCols: any[] = [
		{ field: "DateTime", header: "Date", width: "20%" },
		{ field: "Level", header: "Level", width: "10%" },
		{ field: "Message", header: "Message", width: "70%" },

	];
	erLogCols: any[] = [
		// { field: "NodeID", header: "NodeID", width: "20%" },
		{ field: "Message", header: "Message", width: "100%" },

	];
	items: MenuItem[] | undefined = [
		{ label: 'Workflow', icon: 'pi pi-fw pi-home' },
		{ label: 'Node', icon: 'pi pi-fw pi-calendar' }
	];
    activeItem: MenuItem = this.items[0];

	constructor(private designerService: DesignerService, private logService: SystemMessageLogService) {}

	ngOnInit(): void {

		this.subs.sink =
		this.designerService.designerProgressSpinnerEmitter.subscribe(
			(res: DesignProgresSpinnerEvent) => {

				try {
					if (res.workflowOperation == WorkflowOperationType.UpdateSettings) {
						this.updateSettingsStatus = res.inProgress;
					} else {
						this.runWorkflowStatus = res.inProgress;
					}

				} catch (e) {
					// nothing
				} finally {
					// TODO: check what we need to clean up here
				}

			}
		);
		this.designerService.workflowGraphEmitter.subscribe(
			(res: DesignerEvent<WorkflowGraphEventType, IWorkflowGraphEventData>) => {
				this.loading = true;

				if (res.Type === WorkflowGraphEventType.WorkflowExecuted) {
					console.log("WorkflowExecuted", res);
					const data = <WorkflowExecutedData<any>>res.Data;

					// New Result Logic
					// Write new result object info components
					const t_res = <NewWorkflowResult.RunResult<WorkflowEngineControllers.NodeRunResult<any>>>data.Result;
					const is_new_result_type:boolean = (t_res.Output !== undefined);

					if(is_new_result_type) {

						const extractor = data.Extractor;
						const errorLogResult = extractor.errors(data.Result);
						const nodeLogResults = t_res.Output.WorkflowResult.NodeResults;
						const wfLogResult = t_res.Output.WorkflowResult.Log;

						this.errorLogResult = errorLogResult;
						this.nodeLogResult = nodeLogResults.map(n => n[1]);

                        const nodeLogErrors = this.nodeLogResult.filter((res) => { return res.ActionResult.IsSuccess === false});
                        this.nodeLogErrors = nodeLogErrors;
						this.wfLogResult = wfLogResult;
						console.log(this.nodeLogResult);
						console.log(this.wfLogResult)

					} else {
						throw new Error("The workflow result type is unexpected. Pls ensure that you use the new functions");
					}

				}
			}
		);
	}


	selectNodeErrors(evt: any, id: string) {
		this.selectedNodeErrors = this.errorLogResult.filter( e => e[0] == id);
	}
}
