<div id="connector_settings" class="card">

    <div class="card">
        <label htmlFor="port">Authentication</label>
        <!-- <input pInputText id="port" aria-describedby="port-help" [(ngModel)]="data.port" /> -->
        <p-dropdown [options]="credential_options" [(ngModel)]="credential_options_selected"
            optionLabel="name"></p-dropdown>
        <small id="port-help">Authentication</small>

        <div [ngSwitch]="credential_options_selected.value">

            <div *ngSwitchCase="'OAuth Credentials'" class="card">

                <div class="field">
                    <label htmlFor="credentials.credentials_title">Auth Type</label>
                    <input pInputText id="host" aria-describedby="credentials.credentials_title-help"
                        [ngModel]="credential_options_selected.value" [readonly]="true" type="hidden" />
                    <small id="credentials.credentials_title-help">Auth Type</small>
                </div>

                <div class="field">
                    <label htmlFor="credentials.client_id">Client ID</label>
                    <input pInputText id="host" aria-describedby="credentials.client_id-help"
                        [(ngModel)]="data.credentials.client_id" />
                    <small id="credentials.client_id-help">Enter your Google application's Client ID.</small>
                </div>

                <div class="field">
                    <label htmlFor="credentials.client_secret">Client Secret</label>
                    <input pInputText id="host" aria-describedby="credentials.client_secret-help"
                        [(ngModel)]="data.credentials.client_secret" />
                    <small id="credentials.client_secret-help">Enter your Google application's Client Secret.</small>
                </div>

                <div class="field">
                    <label htmlFor="credentials.refresh_token">Refresh Token</label>
                    <input pInputText id="host" aria-describedby="credentials.refresh_token-help"
                        [(ngModel)]="data.credentials.refresh_token" />
                    <small id="credentials.refresh_token-help">Enter your Google application's refresh token</small>
                </div>


            </div>

            <div *ngSwitchCase="'Private App Credentials'" class="card">

                <div class="field">
                    <label htmlFor="credentials.credentials_title">Auth Type</label>
                    <input pInputText id="host" aria-describedby="credentials.credentials_title-help"
                        [(ngModel)]="data.credentials.credentials_title" [readonly]="true" type="hidden" />
                    <small id="credentials.credentials_title-help">Auth Type</small>
                </div>

                <div class="field">
                    <label htmlFor="credentials.access_token">Access token</label>
                    <input pInputText id="host" aria-describedby="credentials.access_token-help"
                        [(ngModel)]="data.credentials.access_token" />
                    <small id="credentials.access_token-help">HubSpot Access token. See the <a
                            href="https://developers.hubspot.com/docs/api/private-apps">Hubspot docs</a> if you need
                        help finding this token.</small>
                </div>

            </div>

        </div>

    </div>

    <div class="field">
        <label htmlFor="start_date">Start date</label>
        <!-- <input pInputText id="host" aria-describedby="start_date-help" [(ngModel)]="data.start_date" /> -->
        <!-- Geht -->
        <!-- <p-calendar [(ngModel)]="data.start_date" [showTime]="true" [showSeconds]="true" [showIcon]="true"></p-calendar> -->
        <!-- Geht nicht -->
        <!-- <p-calendar [ngModel]="getDate(data.start_date)" (ngModelChange)="setDate($event,data,'start_date')" [showTime]="true" [showSeconds]="true" [showIcon]="true"></p-calendar> -->
        <!-- <p-calendar [ngModel]="getDate(data.start_date)" [showTime]="true" [showSeconds]="true" [showIcon]="true"></p-calendar> -->
        <!-- <p-calendar [ngModel]="formattedDate" [showTime]="true" [showSeconds]="true" [showIcon]="true"></p-calendar> -->
        <!-- <p-calendar [ngModel]="getDate(data.start_date)" (ngModelChange)="isUpdating ? null : setDate($event,data,'start_date')" [showTime]="true" [showSeconds]="true" [showIcon]="true"></p-calendar> -->
        <!-- Workaround -->
        <p-calendar [ngModel]="_date" (ngModelChange)="onDateChanged($event)" [showTime]="true" [showSeconds]="true"
            [showIcon]="true"></p-calendar>

        <small id="start_date-help">UTC date and time in the format 2017-01-25T00:00:00Z. Any data before this date will
            not be replicated.</small>
    </div>

</div>