<div class="p-col-12 headerSection" style="margin-top: 20px">
    <div class="p-fluid dropDownInput">
        <span class="p-float-label">
            <!-- <p-dropdown id="startRowInput" [autoDisplayFirst]="false" [options]="columnListFiltered"
                (onChange)="onUpdateSettings(false)" [(ngModel)]="startRow" optionLabel="Name">
            </p-dropdown> -->
            <p-inputNumber inputId="startRowInput" [showButtons]="true" [min]="0" [(ngModel)]="startRow" (onBlur)="onUpdateSettings(false)">
            </p-inputNumber>
            <label for="startRowInput">{{'Start row' | translate }}</label>
        </span>
    </div>
    <div class="p-fluid dropDownInput">
        <span class="p-float-label">
            <!-- <p-dropdown id="EndRowsInput" [autoDisplayFirst]="false" [options]="columnListFiltered"
                (onChange)="onUpdateSettings(false)" [(ngModel)]="endRow" optionLabel="Name">
            </p-dropdown> -->
            <p-inputNumber inputId="MaxRowsInput" [showButtons]="true" [min]="0" [(ngModel)]="maxRows" (onBlur)="onUpdateSettings(false)">
            </p-inputNumber>
            <label for="MaxRowsInput">{{'End row' | translate }}</label>
        </span>
    </div>
</div>
