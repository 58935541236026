import { Component, OnInit } from '@angular/core';
import { Invoice } from 'src/app/models/invoice.model';

@Component({
  selector: 'app-invoices-list',
  templateUrl: './invoices-list.component.html',
})
export class InvoicesListComponent implements OnInit {

  invoices: Invoice[] = [];
  selectedInvoice?: Invoice;

  constructor() { }

  ngOnInit(): void {
  }

}
