<div class="p-grid" style="width:100%; margin-top:10px">
    <div class="p-col-12 ">
        <div class="p-fluid">
            <span class="p-float-label">
                <input type="text" id="UrlInputtext" pInputText>
                <label for="inputtext">{{'Path' | translate}}</label>
                <!-- <small id="inputtext">Text</small> -->
            </span>
        </div>
    </div>
    <div class="p-col-12 ">
        <div class="p-fluid">
            <span class="p-float-label">
                <input pInputText type="text" id="BearerTokenText">
                <label for="BearerTokenText">{{'Bearer Token' | translate}}</label>
            </span>
        </div>
    </div>

    <div class="p-col-12 p-md-6">
        <div class="p-fluid">
            <span class="p-float-label">
                <button pButton type="button" id="connect" label="{{'Connect' | translate}}" pInputText
                    (click)="onConnect($event)"></button>
            </span>
        </div>
    </div>

    <!-- <div class="p-col-12 p-md-6">
        <div class="p-fluid">
            <span class="p-float-label">
                <input type="text" id="Statusinputtext" [disabled]=true pInputText [(ngModel)]="status_text">
                <label for="Statusinputtext">Status</label>
            </span>
        </div>
    </div>
    <div class="p-col-12 ">
        <p-divider layout="horizontal" align="center">
            <span class="p-tag">Choose DB table</span>
        </p-divider>
    </div>

    <div class="p-col-12 ">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown [options]="currentConnectorView.metaInfo.Tables" [(ngModel)]="currentConnectorView.connectorSettings.Table" editable="true"></p-dropdown>
                <label for="inputtext">DB Table</label>
            </span>
        </div>
    </div> -->

</div>
