import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { DynamicItemInfo } from "../com-bion-json";
import { DataMarketAccess, DataMarketMetaAccess, DataMarketMetaInfo, ExtractMetaFromConnectorArg } from "../connector.model";
import { ConnectorSettingsBuilder, ConnectorViewBase } from "../connectorView.model";
import { DataSet } from "../datamarket.model";


export class dataMarketConnectorView extends ConnectorViewBase<DataMarketAccess, DataMarketMetaInfo, DataMarketMetaAccess> {
    constructor(api: ApiBackendService, connectorID: string) {
        super(api, connectorID);
        this.metaInfo = new DataMarketMetaInfo(new Array<DataSet>());
    }
    fetchMetaDataObs(): Observable<DataMarketMetaInfo> {

        const data_market_meta_access = new DataMarketMetaAccess();
        const dynamic_meta_access_info = new DynamicItemInfo(this.connectorID, data_market_meta_access);

        const extract_meta_arg = new ExtractMetaFromConnectorArg();
        extract_meta_arg.ConnectorMetaInfo = dynamic_meta_access_info;

        return this.api.api_extractMetaFromConnector(extract_meta_arg).pipe(map((extractRes) => {
            console.log("Extract Meta from Connector DONE");
            return <DataMarketMetaInfo>extractRes.MetaData;
        }));
    }
    getBase64FileData(): string {
        // throw new Error('Data Market Connector does not support file upload');
        return '';
    }
    getDataSourceNameSuggestion(): string {
        return this.selectedDataSet.DataSetID.toString();
    }
    uploadFile(file: File) {
        throw new Error('Data Market Connector does not support file upload');
    }

    getSettingsBuilder(): Observable<ConnectorSettingsBuilder<DataMarketAccess>> {

        let dataMarketSettings = new dataMarketSettingsBuilder(this.connectorID, this.selectedDataSet.ServiceID, this.selectedDataSet.DataSetID, this.IsPreview, this.PreviewRows);

        return of(dataMarketSettings);
    }

    setInitialSettings(settings: DataMarketAccess): void {
        this.connectorSettings = settings;
        

        // select correct data set if possible
        console.log("Find data set to preset");
        const con_settings = this.connectorSettings;
        console.log("Connector Settings: ", con_settings);
        console.log("Meta Infos", this.metaInfo);

        const valid_set = this.metaInfo.Services
            .find(s => s.ServiceID === con_settings.ServiceID && s.DataSetID === con_settings.Table);

        console.log("Found Set: " + valid_set);

        if(valid_set) {
            this.selectedDataSet = valid_set;
        }
    }
    // setInitialMetaSettings(settings: MA): void {
    //     this.metaAccess = settings;
    // }

    initialize() : Observable<any> {

        // Initialize view model with data sets and return afterwards!
        return this.fetchMetaDataObs().pipe(map(res => {
            this.metaInfo = res;
            console.log("Initialized", res);

            if (!this.selectedDataSet && this.metaInfo.Services.length > 0)
				this.selectedDataSet = this.metaInfo.Services[0];

            return 0;
        }));
    }

    isFileBased(): boolean {
        return false;
    }

    query: string = "";
    IsPreview: boolean = true;
    PreviewRows: number = 100;
    selectedDataSet?: DataSet;
}
export class dataMarketSettingsBuilder implements ConnectorSettingsBuilder<DataMarketAccess> {

    constructor(connectorId: string, serviceID: number, tableID: number, IsPreview: boolean, PreviewRows: number) {

        this.connectorId = connectorId;
        this.serviceID = serviceID;
        this.tableID = tableID;
        this.IsPreview = IsPreview;
        this.PreviewRows = PreviewRows;
    }
    connectorId: string;
    serviceID: number;
    tableID: number;
    IsPreview: boolean;
    PreviewRows: number;

    getConnectorId(): string {
        return this.connectorId;
    }
    getConnectorSettings() {
        return new DataMarketAccess(this.serviceID, this.tableID, "", this.IsPreview, this.PreviewRows);
    }
}