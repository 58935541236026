import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { PowerBIModel } from 'src/app/models/api/models/PowerBIModel';
import { PowerBiActionEvent, PowerBiDialogActionType } from 'src/app/models/api/models/powerbi-report/PowerBiActionEvent';
import { UserInfo } from 'src/app/models/api/models/session/UserInfo';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { PowerBiReportService } from 'src/app/services/power-bi-report.service';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-create-powerbi-report',
  templateUrl: './create-powerbi-report.component.html',
  styleUrls: ['./create-powerbi-report.component.scss']
})
export class CreatePowerbiReportComponent implements OnInit {
  displayDialog: boolean = false;
  subs = new SubSink;
  headerText: string = "";
  savingInProgress: boolean = false;
  inputDisabled: boolean = false;
  actionEvent?: PowerBiActionEvent;
  @ViewChild('wt') table!: Table;

  embeddedUrl: string = "";
  title: string = "";

  reports: PowerBIModel.CreateReportsArg.ReportView[] = [];
  userInfos: UserInfo[] = [];

  constructor(private biService: PowerBiReportService, private errorService: SystemMessageLogService, private translate: TranslateService, private messageService: MessageService, private api: ApiBackendService) { }

  ngOnInit(): void {
    this.subs.sink = this.api.getUserCommon().subscribe((userInfos) => {
      this.userInfos = userInfos;
    })
    this.subs.sink = this.biService.PowerBiActionEmitter.subscribe((action: PowerBiActionEvent) => {
      if(action.actionType === PowerBiDialogActionType.createReport) {
        this.actionEvent = action;
        this.displayDialog = action.display;
        this.headerText = action.header;
        this.inputDisabled = false;

      }
    })
  }
  addEntry() {
    const entry = new PowerBIModel.CreateReportsArg.ReportView("","",[]);
    console.log(entry);

    this.table.value.push(entry);

  }
  deleteEntry(ri: number) {
		this.reports = [
			...this.reports.slice(0, ri),
			...this.reports.slice(ri + 1),
		];
  }
  createReport() {
    this.savingInProgress = true;

    console.log(this.table.value);
    const reportViews = this.table.value;
    const reports = reportViews.map((view:PowerBIModel.CreateReportsArg.ReportView) => {
      const permissions = view.Permissions.map((userInfo) => {
        const perm = new PowerBIModel.CreateReportsArg.Permission(userInfo.ID);
        return perm;
      });
      const config = "&embeddedDemo=false&filterPaneEnabled=false&customLayout=displayOption:FitToWidth";

      const entry = new PowerBIModel.CreateReportsArg.Report(view.Title,view.Url + config, permissions);
      return entry
    })

    console.log(reports);

    const arg = new PowerBIModel.CreateReportsArg(reports);
    this.biService.createReports(arg).subscribe((res) => {
      console.log("Report created successfully", res);
      this.biService.PowerBiActionEmitter.emit(new PowerBiActionEvent(false,"Report Changed",PowerBiDialogActionType.ReportsChanged,"Changed"));
      
      this.messageService.add({
        severity: "success",
        summary: this.translate.instant("Message.CreateReportSuccess.Title"),
        detail: res.length + this.translate.instant("Message.CreateReportSuccess.Text"),
      });

      this.savingInProgress = false;
      this.displayDialog = false;

    }, (err) => {
      this.errorService.handleError(err);
      this.savingInProgress = false;
    })
  }
  resetForms() {
    this.reports =  [];
  }
  
}
