<p-overlayPanel #op1 [showCloseIcon]="false">
    <ng-template pTemplate>
        <div style="float: left">
            <button pButton pRipple type="button" icon="pi pi-cog" class="p-button-rounded p-button-text p-mr-2 p-mb-2"
                pTooltip="{{'Freq.EditFrequency' | translate}}" (click)="displayEditFrequencyDialog()"></button>
            <button pButton pRipple type="button" icon="pi pi-trash" pTooltip="{{'Freq.DeleteFrequency' | translate}}"
                (click)="displayDeleteFrequencyDialog()"
                class="p-button-rounded p-button-danger p-button-text p-mr-2 p-mb-2"></button>
        </div>
    </ng-template>
</p-overlayPanel>

<p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>

<div style="margin:20px 0">
    <h5>
        {{'Frequencies' | translate }} <app-info-tooltip></app-info-tooltip>
        <span style="float: right">
            <button pButton pRipple type="button" icon="pi pi-plus" label="{{'Create' | translate}}"
            class="p-button-rounded p-button-text p-mr-2 p-mb-2" pTooltip="{{'Freq.CreateNewFrequency' | translate}}"
            (click)="displayNewFrequencyDialog()"></button>
        </span>
    </h5>
</div>

<div class="card">
    <p-table #dt [value]="frequencies" sortField="data" sortMode="single" dataKey="data.id">
        <ng-template pTemplate="header">
            <tr>
                <th style="width:50%">{{'Freq.Occurance' | translate}}</th>
                <th style="width:30%">{{'Duration' | translate}}</th>
                <th style="width:20%">{{'Time' | translate}}</th>
                <th  style="width: 4rem">
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-freqNode  let-index="rowIndex">
            <tr>
            <td><span class="p-column-title">{{'Freq.Occurance' | translate}}</span>
                <span *ngIf="freqNode.RhythmSettings.Rhythm._type === WeekRhytm && freqNode.RhythmSettings.Rhythm.UseLastDay === false">
                    {{'every' | translate}} <span style="margin-right: 3px;" *ngFor="let day of freqNode.RhythmSettings.Rhythm.Days">
                        <span [ngSwitch]="day">
                            <p-tag *ngSwitchCase="1" severity="success">{{'Freq.Sunday' | translate}}
                            </p-tag>
                            <p-tag *ngSwitchCase="2" severity="success" >{{'Freq.Monday' | translate}}
                            </p-tag>
                            <p-tag *ngSwitchCase="3" severity="success" >{{'Freq.Tuesday' | translate}}
                            </p-tag>
                            <p-tag *ngSwitchCase="4" severity="success" >{{'Freq.Wednesday' | translate}}
                            </p-tag>
                            <p-tag *ngSwitchCase="5" severity="success" >{{'Freq.Thursday' | translate}}
                            </p-tag>
                            <p-tag *ngSwitchCase="6" severity="success" >{{'Freq.Friday' | translate}}
                            </p-tag>
                            <p-tag *ngSwitchCase="7" severity="success" >{{'Freq.Saturday' | translate}}
                            </p-tag>
                        </span>

                    </span>
                </span>
                <span *ngIf="freqNode.RhythmSettings.Rhythm._type === WeekRhytm && freqNode.RhythmSettings.Rhythm.UseLastDay === true">
                    <span style="margin-right: 3px;" >
                        {{'every' | translate}} 
                        <span [ngSwitch]="freqNode.RhythmSettings.Rhythm.LastDayIndex">
                            <p-tag *ngSwitchCase="1" severity="success">{{'Freq.Sunday' | translate}}
                            </p-tag>
                            <p-tag *ngSwitchCase="2" severity="success" >{{'Freq.Monday' | translate}}
                            </p-tag>
                            <p-tag *ngSwitchCase="3" severity="success" >{{'Freq.Tuesday' | translate}}
                            </p-tag>
                            <p-tag *ngSwitchCase="4" severity="success" >{{'Freq.Wednesday' | translate}}
                            </p-tag>
                            <p-tag *ngSwitchCase="5" severity="success" >{{'Freq.Thursday' | translate}}
                            </p-tag>
                            <p-tag *ngSwitchCase="6" severity="success" >{{'Freq.Friday' | translate}}
                            </p-tag>
                            <p-tag *ngSwitchCase="7" severity="success" >{{'Freq.Saturday' | translate}}
                            </p-tag>
                        </span>
                    </span>
                </span>
                <span *ngIf="freqNode.RhythmSettings.Rhythm._type === MonthRhytm && freqNode.RhythmSettings.Rhythm.UseLastDay === false">
                    {{'every' | translate}} <span style="margin-right: 3px;" *ngFor="let day of freqNode.RhythmSettings.Rhythm.Days">
                        <p-tag severity="warning">{{day}}.
                        </p-tag>
                    </span>{{'Freq.ofMonth' | translate}}
                </span>
                <span *ngIf="freqNode.RhythmSettings.Rhythm._type === MonthRhytm && freqNode.RhythmSettings.Rhythm.UseLastDay === true">
                    <span style="margin-right: 3px;">
                        <p-tag severity="warning">{{freqNode.RhythmSettings.Rhythm.LastDayOffset}}
                        </p-tag>
                    </span>{{'Freq.beforeLastDayofMonth' | translate}}
                </span>
            </td>
            <td><span class="p-column-title">{{'Freq.Duration' | translate}}</span>
                {{freqNode.ValidFrom | date: "dd/MM/yy"}} - {{freqNode.ValidTo | date: "dd/MM/yy"}}
            </td>
            <td><span class="p-column-title">{{'Freq.Time' | translate}}</span>
                <i class="pi pi-clock" style="margin-right:5px"> </i>{{freqNode.Clock.Hour}}:{{freqNode.Clock.Minute}}:{{freqNode.Clock.Second}}
            </td>
            <td>
                <div class="ui-table-buttongroup-right">
                    <button pButton pRipple type="button" icon="pi pi-ellipsis-v"
                        class="p-button-rounded p-button-text p-mr-2 p-mb-2" pTooltip="{{'Freq.ChangeFrequency' | translate}}"
                        (click)="op1.toggle($event)" (click)="onMenuClicked(freqNode)"></button>
                </div>
            </td>
        </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="3">{{'NoEntryFound' | translate}}</td>
            </tr>
        </ng-template>
    </p-table>

</div>
