<div>
    <div>
        <p-checkbox [(ngModel)]="config.DetectColumnTypes" [binary]="true"></p-checkbox>
        <label for="ny">{{'DetectColumnTypes' | translate}}</label>
    </div>
    <div style="margin-top: 10px">
        <p-checkbox [(ngModel)]="config.UseDetectLimit" [binary]="true"></p-checkbox>
        <label for="ny">{{'UseDetectLimit' | translate}}</label>
    </div>

    <div class="p-col-12 p-md-6">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown id="SeparatorCharOpt" [options]="parseCharaters" [(ngModel)]="config.Separator"
                    [editable]="true" optionLabel="name" optionValue="char"
                    (onChange)="onSettingsChanged()"></p-dropdown>
                <label for="SeparatorCharOpt">{{'Delimiter' | translate}}</label>
            </span>
        </div>
    </div>

    <div class="p-col-12 p-md-6">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown id="escapeCharOpt" [options]="parseCharaters" [(ngModel)]="config.LineBreak"
                    [editable]="true" optionLabel="name" optionValue="char"
                    (onChange)="onSettingsChanged()"></p-dropdown>
                <label for="escapeCharOpt">{{'EscapeChar' | translate}}</label>
            </span>
        </div>
    </div>

    <div class="p-col-12 p-md-6">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown id="quoteCharOpt" [options]="parseCharaters" [(ngModel)]="config.QuoteCharacter"
                    [editable]="true" optionLabel="name" optionValue="char"
                    (onChange)="onSettingsChanged()"></p-dropdown>
                <label for="quoteCharOpt">{{'QuoteChar' | translate}}</label>
            </span>
        </div>
    </div>

    <div class="p-col-12 p-md-6">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown id="escapeCharOpt" [options]="parseCharaters" [(ngModel)]="config.EscapeCharacter"
                    [editable]="true" optionLabel="name" optionValue="char"
                    (onChange)="onSettingsChanged()"></p-dropdown>
                <label for="escapeCharOpt">{{'EscapeChar' | translate}}</label>
            </span>
        </div>
    </div>

</div>