import { Component, OnInit } from '@angular/core';
import { NodeMetaData } from 'src/app/models/api/com/bion/etl/NodeMetaData';
import { FindReplacePlugIn } from 'src/app/models/api/com/bion/etl/plugins/FindReplacePlugIn';
import { FieldInfo, WorkflowNodeSettings } from 'src/app/models/api/com/bion/etl/Workflow';
import { GuiDropDown } from 'src/app/models/helperClass.model';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { DesignerService } from 'src/app/services/designer.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { NodeConfigComponentBase } from '../node-config-component-base';
import { ViewBuilder } from './../nodeUtil';

// export class FindAndReplaceView {
//   ColumnName: string;
//   FindValue: string;
//   ReplaceValue: string;
//   ReplaceColumn: boolean;
//   CaseSensitive: boolean;
//   MatchWholeWord: boolean;
//   hasError?: boolean;
// }

@Component({
  selector: 'app-spark-find-and-replace-node',
  templateUrl: './spark-find-and-replace-node.component.html',
  styleUrls: ['./spark-find-and-replace-node.component.scss']
})
export class SparkFindAndReplaceNodeComponent extends NodeConfigComponentBase<FindReplacePlugIn.Settings>
  implements OnInit {


  typeCheckOptions: GuiDropDown[] = [];
  selectedTypeCheckOption: GuiDropDown;

  columnList: string[] = [];
  //dropDownColumn: GuiDropDown[];
  findReplaceList: FindReplaceInfoView[] = [];
  readonly InputPort = "Input";

  settingsToView(settings: WorkflowNodeSettings): void {



    // -- Get Configuration
    let currentConfig = <FindReplacePlugIn.Settings>settings;
    // -- Get Input Meta Data
    const meta_infos = this.getCurrentWorkflowNode().Properties.MetaInfo.get(
      this.InputPort
    );

    let meta_info: NodeMetaData = undefined;
    let input_field_names: string[] = [];

    // -- check for MetaInfos from Input Port & store dsField list
    if (meta_infos != undefined && meta_infos.length > 0) {

      meta_info = meta_infos[0];
      const input_field_infos = meta_info.FieldsInfo;

      // -- create string array for column dropdown

      input_field_names = input_field_infos.map(fieldInfo => fieldInfo.Name);

      this.columnList = input_field_names;
    }
    else {
      console.log("Warning - Mete Information is empty");
      return;
    }




    // -- if no configuration exists, set default
    if (currentConfig === undefined) {
      this.findReplaceList = [];
      this.selectedTypeCheckOption = this.typeCheckOptions[0];
    } else {

      const validated = SparkFindAndReplaceNodeComponent.validateEntries(currentConfig, meta_info);
      this.columnList = validated[0];
      const new_views = validated[1];

      if (new_views !== undefined)
        this.findReplaceList = new_views;
    }

  }
  viewToSettings(): FindReplacePlugIn.Settings {
		const newSettings = <FindReplacePlugIn.Settings>{
			...this.getCurrentWorkflowNode().Properties.Configuration,
		};
		let newInfoList = [];

		this.findReplaceList.map((entry) => {
      let newInfo = new FindReplacePlugIn.FindReplaceInfo(entry.SelectedColumn,entry.FindValue,entry.ReplaceValue,entry.NewName,entry.ReplaceColumn,entry.IgnoreCase,entry.MatchWholeWord);

			newInfoList.push(newInfo);
		});

		newSettings.Infos = newInfoList;

		return newSettings;
  }
  onSettingsChanged(settings: FindReplacePlugIn.Settings): void {
    throw new Error('Method not implemented.');
  }

  constructor(protected workflowService: WorkflowsService,
    protected designerService: DesignerService,
    protected bionApi: ApiBackendService) {
    super(workflowService, designerService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }



  /**
 * Validiert die Einträge und erstellt die DropDown Options (Field Options) und die Views.
 * Es wird geprüft, ob die Infos noch valide sind.
 * Wenn sie nicht valide sind, wird das Flag von der Funktion infoToView gesetzt.
 * @param config Aktuelle Konfiguration
 * @param input_field_names Input Felder aus dem Port
 * @param meta_info Input Port (gewollte redundanz)
 * @returns
 */
  static validateEntries(config: FindReplacePlugIn.Settings,
    meta_info: NodeMetaData): [Array<string>, FindReplaceInfoView[]] {

    const input_field_names = meta_info.FieldsInfo.map(fieldInfo => fieldInfo.Name);
    let out_input_field_names = [...input_field_names];

    config.Infos.map((info) => {
      if (out_input_field_names.includes(info.SelectedColumn)) {
        // -- column still exists, do nothing
      } else {
        // column does not exist anymore, meaning column name currently not included -> include
        out_input_field_names.push(info.SelectedColumn);
      }
    });

    //this.columnList = input_field_names;  wird vom caller gemacht

    // let findReplaceList:FindAndReplaceView[] = undefined
    // if(meta_info !== undefined) {
    // 	findReplaceList = FindAndReplaceNodeComponent.infoToView(config,meta_info.FieldsInfo);
    // }

    const findReplaceList = SparkFindAndReplaceNodeComponent.infoToView(config, meta_info.FieldsInfo);

    return [out_input_field_names, findReplaceList];
  }



  static infoToView(
    settings: FindReplacePlugIn.Settings,
    meta: FieldInfo[]
  ): FindReplaceInfoView[] {

    let newViewList: FindReplaceInfoView[] = [];

    if (settings.Infos.length > 0) {
      settings.Infos.map((info) => {



        // newView.ColumnName = info.ColumnName;
        // newView.FindValue = info.FindValue;
        // newView.ReplaceValue = info.ReplaceValue;
        // newView.ReplaceColumn = info.ReplaceColumn;
        // newView.CaseSensitive = info.CaseSensitive;
        // newView.MatchWholeWord = info.MatchWholeWord;

        let fieldInfoFound: FieldInfo = undefined;

        if (meta !== undefined) {
          fieldInfoFound = meta.find((field) => {
            return field.Name === info.SelectedColumn;
          });
        }

        let has_error = false;
        has_error = !fieldInfoFound;

        let newView = new FindReplaceInfoView(info.SelectedColumn, info.FindValue, info.ReplaceValue,
          info.NewName, info.ReplaceColumn, info.IgnoreCase, info.MatchWholeWord, has_error);

        newViewList.push(newView);
      });
    }

    return newViewList;
  }

  onAddEntry() {
    // let newFindReplaceEntry = new FindAndReplaceView();
    // newFindReplaceEntry.ColumnName = "";
    // newFindReplaceEntry.FindValue = "";
    // newFindReplaceEntry.ReplaceValue = "";
    // newFindReplaceEntry.ReplaceColumn = true;
    // newFindReplaceEntry.CaseSensitive = false;
    // newFindReplaceEntry.MatchWholeWord = false;

    // newFindReplaceEntry.hasError = false;

    const newFindReplaceEntry = new FindReplaceInfoView("", "", "", "", true, false, false, false);

    this.findReplaceList.push(newFindReplaceEntry);
    this.onUpdateSettings(false);
    //this.viewToInfo(this.findReplaceList);
  }
  onDeleteEntry(index) {
    this.findReplaceList = [
      ...this.findReplaceList.slice(0, index),
      ...this.findReplaceList.slice(index + 1),
    ];
    this.onUpdateSettings(false);
    // this.messageService.standardToastMessage({  severity : 'warning',
    //                             summary : 'Filter deleted',
    //                             detail : 'The selected filter was deleted successfully!'});
  }





}

class FindReplaceInfoView {
  SelectedColumn: string;
  FindValue: string;
  ReplaceValue: string;
  NewName: string;
  ReplaceColumn: boolean;
  IgnoreCase: boolean;
  MatchWholeWord: boolean
  HasError: boolean;
  constructor(SelectedColumn: string, FindValue: string, ReplaceValue: string, NewName: string, ReplaceColumn: boolean, IgnoreCase: boolean, MatchWholeWord: boolean, HasError: boolean) {
    if (SelectedColumn === undefined) throw new Error("Class 'FindReplaceInfoView': Required value 'SelectedColumn' is undefined");
    if (FindValue === undefined) throw new Error("Class 'FindReplaceInfoView': Required value 'FindValue' is undefined");
    if (ReplaceValue === undefined) throw new Error("Class 'FindReplaceInfoView': Required value 'ReplaceValue' is undefined");
    if (NewName === undefined) throw new Error("Class 'FindReplaceInfoView': Required value 'NewName' is undefined");
    if (ReplaceColumn === undefined) throw new Error("Class 'FindReplaceInfoView': Required value 'DropOld' is undefined");
    if (IgnoreCase === undefined) throw new Error("Class 'FindReplaceInfoView': Required value 'IgnoreCase' is undefined");
    if (HasError === undefined) throw new Error("Class 'FindReplaceInfoView': Required value 'HasError' is undefined");
    if (MatchWholeWord === undefined) throw new Error("Class 'FindReplaceInfoView': Required value 'MatchWholeWord' is undefined");
    this.SelectedColumn = SelectedColumn;
    this.FindValue = FindValue;
    this.ReplaceValue = ReplaceValue;
    this.NewName = NewName;
    this.ReplaceColumn = ReplaceColumn;
    this.IgnoreCase = IgnoreCase;
    this.MatchWholeWord = MatchWholeWord;
    this.HasError = HasError;
  }
}

//   ColumnName: string;
//   FindValue: string;
//   ReplaceValue: string;
//   ReplaceColumn: boolean;
//   CaseSensitive: boolean;
//   MatchWholeWord: boolean;  // TODO
//   hasError?: boolean;

class SparkFindViewBuilder implements ViewBuilder<FindReplacePlugIn.Settings, FindReplacePlugIn.FindReplaceInfo, FindReplaceInfoView> {
  colName(info: FindReplacePlugIn.FindReplaceInfo): string {
    return info.SelectedColumn;
  }
  buildViews(config: FindReplacePlugIn.Settings, meta: FieldInfo[]) {
    throw new Error('Method not implemented.');
  }
  fromConfig(c: FindReplacePlugIn.Settings): FindReplacePlugIn.FindReplaceInfo[] {
    return c.Infos;
  }
  build(arg: FindReplacePlugIn.FindReplaceInfo): FindReplaceInfoView {
    return new FindReplaceInfoView(arg.SelectedColumn, arg.FindValue, arg.ReplaceValue, arg.NewName, arg.ReplaceColumn, arg.IgnoreCase, arg.MatchWholeWord, false);
  }
}
