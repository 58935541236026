<button #actualTarget pButton class="p-button-round p-button-text"
    [icon]="FilterSetBoolean ? 'pi pi-filter-fill' : 'pi pi-filter'" (click)="op.toggle($event, actualTarget)"
    label="{{filterLabel | translate}}"></button>

<p-overlayPanel #op>
    <ng-template pTemplate>

        <div class="p-grid" style="width: 70vh">
            <div class="p-col-12" 
            style="position: relative; text-align: left;color: white;background: linear-gradient(79deg, #34c7c2 0%, #0494c5 51%); margin: 0">
            <button id="ButtonApplyFilter" [disabled]="autoApplyFilter" label="{{'ApplyFilter' | translate}}"
            (click)="onApplyFilter()" pButton></button>
            <span style="float: right">
                <button id="ButtonClearFilter" class="p-button-text p-button-danger" icon="pi pi-trash"
                    pTooltip="{{'ClearAllFilters' | translate}}" toolTipPosition="top" (click)="onClearFilters()"
                    pButton></button>
            </span>
            <span style="margin-left: 10px;  margin: 0;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);" class="field-checkbox">
                <p-checkbox [(ngModel)]="autoApplyFilter" [binary]="true" inputId="binary"></p-checkbox>
                <label for="binary" style="color: #58aed3;font-size: 12px;margin-left: 3px">
                    {{'Auto-Filter' | translate}}
                </label>
            </span>
        </div>
            <!-- <div class="p-col-12" style="position: relative">
                <button id="ButtonApplyFilter" [disabled]="autoApplyFilter" label="{{'ApplyFilter' | translate}}"
                    (click)="onApplyFilter()" pButton></button>
                <span style="float: right">
                    <button id="ButtonClearFilter" class="p-button-text p-button-danger" icon="pi pi-trash"
                        pTooltip="{{'ClearAllFilters' | translate}}" toolTipPosition="top" (click)="onClearFilters()"
                        pButton></button>
                </span>
                <span style="margin-left: 10px;  margin: 0;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);" class="field-checkbox">
                    <p-checkbox [(ngModel)]="autoApplyFilter" [binary]="true" inputId="binary"></p-checkbox>
                    <label for="binary" style="color: #58aed3;font-size: 12px;margin-left: 3px">
                        {{'Auto-Filter' | translate}}
                    </label>
                </span>
            </div>
            <div style="width:100%">
                <p-divider></p-divider>
            </div> -->

            <!-- individual views -->
            <div *ngIf="currentView === 'DataSources'" class="p-col-12 p-grid">
                <div class="p-col-6">
                    <div class="p-col-12">
                        <span style="color:#58aed3; font-weight: bold;"><i class="pi pi-search"
                                style="margin-right:5px"> </i>{{'AttributeSearch' | translate}}</span>
                    </div>
                    <div class="p-col-12">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-multiSelect id="IdInputtext" [options]="dropDownGuIModels[3]" optionLabel="name"
                                    [(ngModel)]="selectedDropDownModels[3]" (onChange)="emitFilterChanged()"
                                    display="chip">
                                </p-multiSelect>
                                <label for="IdInputtext">{{'ID' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-multiSelect id="NameInputtext" [options]="dropDownGuIModels[0]" optionLabel="name"
                                    [(ngModel)]="selectedDropDownModels[0]" (onChange)="emitFilterChanged()"
                                    display="chip">
                                </p-multiSelect>
                                <label for="NameInputtext">{{'Name' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-multiSelect id="SourceInputtext" [options]="dropDownGuIModels[2]" optionLabel="name"
                                    [(ngModel)]="selectedDropDownModels[2]" (onChange)="emitFilterChanged()"
                                    display="chip">
                                </p-multiSelect>
                                <label for="SourceInputtext">{{'Connector' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-multiSelect id="fieldsInputtext"  [options]="dropDownGuIModels[1]" [lazy]="true" optionLabel="name"
                                    [(ngModel)]="selectedDropDownModels[1]" (onChange)="emitFilterChanged()"
                                    display="chip">
                                </p-multiSelect>
                                <label for="fieldsInputtext">{{'Fields' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 ">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-multiSelect id="OwnerInputtext" [options]="dropDownGuIModels[4]" optionLabel="name"
                                    [(ngModel)]="selectedDropDownModels[4]" (onChange)="emitFilterChanged()"
                                    display="chip">
                                    <ng-template let-option pTemplate="item">
                                        <div class="country-item">
                                            <div>{{option.name | translate}}</div>
                                        </div>
                                    </ng-template>
                                </p-multiSelect>
                                <label for="OwnerInputtext">{{'owner' | translate}}</label>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="p-col-6">
                    <div class="p-col-12">
                        <span style="color:#58aed3; font-weight: bold;"><i class="pi pi-search"
                                style="margin-right:5px"> </i>{{'RelationsSearch' | translate}}</span>
                    </div>
                    <div class="p-col-12 ">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-multiSelect id="relatedWfInput" optionLabel="name" [options]="dropDownGuIModels[6]"
                                    [(ngModel)]="selectedDropDownModels[6]" (onChange)="emitFilterChanged()"
                                    display="chip">
                                </p-multiSelect>
                                <label for="relatedWfInput">{{'Related workflow' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 ">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-multiSelect id="relatedScInput" optionLabel="name" [options]="dropDownGuIModels[5]"
                                    [(ngModel)]="selectedDropDownModels[5]" (onChange)="emitFilterChanged()"
                                    display="chip">
                                </p-multiSelect>
                                <label for="relatedScInput">{{'Related schedule' | translate}}</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="currentView === 'Workflows'" class="p-col-12 p-grid">
                <div class="p-col-6">
                    <div class="p-col-12">
                        <span style="color:#58aed3; font-weight: bold;"><i class="pi pi-search"
                                style="margin-right:5px"> </i>{{'AttributeSearch' | translate}}</span>
                    </div>
                    <div class="p-col-12 " style="margin-top: 10px">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-multiSelect id="IdInputtext" [options]="dropDownGuIModels[2]" optionLabel="name"
                                    [(ngModel)]="selectedDropDownModels[3]" (onChange)="emitFilterChanged()"
                                    display="chip">
                                </p-multiSelect>
                                <label for="IdInputtext">{{'ID' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 ">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-multiSelect id="NameInputtext" [options]="dropDownGuIModels[0]" optionLabel="name"
                                    [(ngModel)]="selectedDropDownModels[0]" (onChange)="emitFilterChanged()"
                                    display="chip">
                                </p-multiSelect>
                                <label for="NameInputtext">{{'Name' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 ">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-multiSelect id="SourceInputtext" [options]="dropDownGuIModels[1]" optionLabel="name"
                                    [(ngModel)]="selectedDropDownModels[1]" (onChange)="emitFilterChanged()"
                                    display="chip">
                                </p-multiSelect>
                                <label for="SourceInputtext">{{'Nodes' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 ">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-multiSelect id="OwnerInputtext" [options]="dropDownGuIModels[3]" optionLabel="name"
                                    [(ngModel)]="selectedDropDownModels[4]" (onChange)="emitFilterChanged()"
                                    display="chip">
                                    <ng-template let-option pTemplate="item">
                                        <div class="country-item">
                                            <div>{{option.name | translate}}</div>
                                        </div>
                                    </ng-template>
                                </p-multiSelect>
                                <label for="OwnerInputtext">{{'owner' | translate}}</label>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="p-col-6">
                    <div class="p-col-12">
                        <span style="color:#58aed3; font-weight: bold;"><i class="pi pi-search"
                                style="margin-right:5px"> </i>{{'RelationsSearch' | translate}}</span>
                    </div>
                    <div class="p-col-12 ">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-multiSelect id="relatedDsInput" optionLabel="name"
                                    [options]="dropDownGuIModels[5]" [(ngModel)]="selectedDropDownModels[2]"
                                    (onChange)="emitFilterChanged()" display="chip">
                                </p-multiSelect>
                                <label for="relatedDsInput">{{'Related datasource' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 ">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-multiSelect id="relatedDestInput" optionLabel="name"
                                    [options]="dropDownGuIModels[6]" [(ngModel)]="selectedDropDownModels[6]"
                                    (onChange)="emitFilterChanged()" display="chip">
                                </p-multiSelect>
                                <label for="relatedDestInput">{{'Related destination' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 ">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-multiSelect id="relatedScheduleInput" optionLabel="name"
                                    [options]="dropDownGuIModels[4]" [(ngModel)]="selectedDropDownModels[5]"
                                    (onChange)="emitFilterChanged()" display="chip">
                                </p-multiSelect>
                                <label for="relatedScheduleInput">{{'Related schedule' | translate}}</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="currentView === 'Destinations'" class="p-col-12 p-grid">
                <div class="p-col-6">
                    <div class="p-col-12">
                        <span style="color:#58aed3; font-weight: bold;"><i class="pi pi-search"
                                style="margin-right:5px"> </i>{{'AttributeSearch' | translate}}</span>
                    </div>
                    <div class="p-col-12 ">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-multiSelect id="NameInputtext" [options]="dropDownGuIModels[0]" optionLabel="name"
                                    [(ngModel)]="selectedDropDownModels[1]" (onChange)="emitFilterChanged()"
                                    display="chip">
                                </p-multiSelect>
                                <label for="NameInputtext">{{'Name' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 ">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-multiSelect [options]="dropDownGuIModels[1]" id="fieldsinputtext" optionLabel="name"
                                    [(ngModel)]="selectedDropDownModels[2]" (onChange)="emitFilterChanged()"
                                    display="chip">
                                </p-multiSelect>
                                <label for="fieldsinputtext">{{'Fields' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 ">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-multiSelect id="UrlInputtext" [options]="dropDownGuIModels[3]" optionLabel="name"
                                    [(ngModel)]="selectedDropDownModels[3]" (onChange)="emitFilterChanged()"
                                    display="chip">
                                    <ng-template let-option pTemplate="item">
                                        <div class="country-item">
                                            <div>{{option.name | translate}}</div>
                                        </div>
                                    </ng-template>
                                </p-multiSelect>
                                <label for="UrlInputtext">{{'owner' | translate}}</label>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="p-col-6">
                    <div class="p-col-12">
                        <span style="color:#58aed3; font-weight: bold;"><i class="pi pi-search"
                                style="margin-right:5px"> </i>{{'RelationsSearch' | translate}}</span>
                    </div>
                    <div class="p-col-12 ">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-multiSelect id="relateddsinputtext" optionLabel="name"
                                    [options]="dropDownGuIModels[6]" [(ngModel)]="selectedDropDownModels[4]"
                                    (onChange)="emitFilterChanged()" display="chip">
                                </p-multiSelect>
                                <label for="relateddsinputtext">{{'Related datasource' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 ">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-multiSelect id="relatedWfinputtext" optionLabel="name"
                                    [options]="dropDownGuIModels[5]" [(ngModel)]="selectedDropDownModels[5]"
                                    (onChange)="emitFilterChanged()" display="chip">
                                </p-multiSelect>
                                <label for="relatedWfinputtext">{{'Related workflow' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 ">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-multiSelect id="relatedScheduleinputtext" optionLabel="name"
                                    [options]="dropDownGuIModels[4]" [(ngModel)]="selectedDropDownModels[6]"
                                    (onChange)="emitFilterChanged()" display="chip">
                                </p-multiSelect>
                                <label for="relatedScheduleinputtext">{{'Related schedule' | translate}}</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="currentView === 'Schedules'" class="p-col-12 p-grid">
                <div class="p-col-6">
                    <div class="p-col-12">
                        <span style="color:#58aed3; font-weight: bold;"><i class="pi pi-search"
                                style="margin-right:5px"> </i>{{'AttributeSearch' | translate}}</span>
                    </div>
                    <div class="p-col-12 " style="margin-top: 10px">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-multiSelect id="IdInputtext" [options]="dropDownGuIModels[0]" optionLabel="name"
                                    [(ngModel)]="selectedDropDownModels[0]" (onChange)="emitFilterChanged()"
                                    display="chip">
                                </p-multiSelect>
                                <label for="IdInputtext">{{'ID' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 ">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-multiSelect id="NameInputtext" [options]="dropDownGuIModels[1]" optionLabel="name"
                                    [(ngModel)]="selectedDropDownModels[1]" (onChange)="emitFilterChanged()"
                                    display="chip">
                                </p-multiSelect>
                                <label for="NameInputtext">{{'Name' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 ">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-multiSelect [options]="dropDownGuIModels[2]" id="fieldsinputtext" optionLabel="name"
                                    [(ngModel)]="selectedDropDownModels[2]" (onChange)="emitFilterChanged()"
                                    display="chip">
                                </p-multiSelect>
                                <label for="fieldsinputtext">{{'Type' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 ">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-multiSelect [options]="dropDownGuIModels[3]" id="Statusinputtext" optionLabel="name"
                                    [(ngModel)]="selectedDropDownModels[3]" (onChange)="emitFilterChanged()"
                                    display="chip">
                                </p-multiSelect>
                                <label for="Statusinputtext">{{'Status' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 ">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-multiSelect id="UrlInputtext" [options]="dropDownGuIModels[4]" optionLabel="name"
                                    [(ngModel)]="selectedDropDownModels[4]" (onChange)="emitFilterChanged()"
                                    display="chip">
                                    <ng-template let-option pTemplate="item">
                                        <div class="country-item">
                                            <div>{{option.name | translate}}</div>
                                        </div>
                                    </ng-template>
                                </p-multiSelect>
                                <label for="UrlInputtext">{{'owner' | translate}}</label>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="p-col-6">
                    <div class="p-col-12">
                        <span style="color:#58aed3; font-weight: bold;"><i class="pi pi-search"
                                style="margin-right:5px"> </i>{{'RelationsSearch' | translate}}</span>
                    </div>
                    <div class="p-col-12 ">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-multiSelect id="relateddsinputtext" optionLabel="name"
                                    [options]="dropDownGuIModels[5]" [(ngModel)]="selectedDropDownModels[5]"
                                    (onChange)="emitFilterChanged()" display="chip">
                                </p-multiSelect>
                                <label for="relateddsinputtext">{{'Related datasource' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 ">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-multiSelect id="relatedWfinputtext" optionLabel="name"
                                    [options]="dropDownGuIModels[7]" [(ngModel)]="selectedDropDownModels[7]"
                                    (onChange)="emitFilterChanged()" display="chip">
                                </p-multiSelect>
                                <label for="relatedWfinputtext">{{'Related workflow' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 ">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-multiSelect id="relatedScheduleinputtext" optionLabel="name"
                                    [options]="dropDownGuIModels[6]" [(ngModel)]="selectedDropDownModels[6]"
                                    (onChange)="emitFilterChanged()" display="chip">
                                </p-multiSelect>
                                <label for="relatedScheduleinputtext">{{'Related destination' | translate}}</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>
