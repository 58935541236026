import { Component, OnInit } from "@angular/core";
import { Id } from "src/app/helper/id";
import { FieldInfo, WorkflowNodeSettings } from "src/app/models/api/com/bion/etl/Workflow";
import { GuiDropDown } from "src/app/models/helperClass.model";
import { FilterSettings, FilterInfo } from "src/app/models/nodeSettings.model";
// import { FieldInfo, WorkflowNodeSettings } from "src/app/models/workflow.model";
import { DesignerService } from "src/app/services/designer.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { NodeConfigComponentBase } from "../node-config-component-base";

export class FilterInfoView {
	ColumnName: string;
	FilterTypes: GuiDropDown;
	FilterValues: string;
	hasError?: boolean;

	constructor(ColumnName: string, FilterTypes: GuiDropDown, FilterValues: string, hasError?: boolean) {
		this.ColumnName = ColumnName;
		this.FilterTypes = FilterTypes;
		this.FilterValues = FilterValues;
		this.hasError = hasError;
	}
}

@Component({
	selector: "app-filter-node",
	templateUrl: "./filter-node.component.html",
	styleUrls: ["./filter-node.component.css"],
})
export class FilterNodeComponent
	extends NodeConfigComponentBase<FilterSettings>
	implements OnInit {
	settingsToView(settings: WorkflowNodeSettings): void {
		// -- Get Configuration
		let currentConfig = <FilterSettings>settings;
		// -- Get Input Meta Data
		const meta_infos =
			this._currentWorkflowNodeAdapter.IWorkflowNode.Properties.MetaInfo.get(
				this.InputPort
			);

		let col_names: string[] = [];

		// create SelectInfoViews from DsFields for table
		if (meta_infos != undefined && meta_infos.length > 0) {
			const input_field_infos = meta_infos[0].FieldsInfo;

			// -- create string array for dropdown
			col_names = input_field_infos.map(fieldInfo => fieldInfo.Name);
			this.columnList = col_names;
		}

		// --If currentConfig not available (-> new node)
		if (currentConfig === undefined) {
			this.filterList = [];
			this.selectedMatchMode = this.matchModeOptions[0];
		} else {
			// -- otherweise load existing config into view
			if (!currentConfig.FilterInfos) return;

			currentConfig.FilterInfos.map((info) => {
				if (col_names.includes(info.ColumnName)) {
					// -- column still exists, do nothing
				} else {
					// column does not exist anymore, meaning column name currently not included -> include
					col_names.push(info.ColumnName);
				}
			});
			this.columnList = col_names;
			if (meta_infos !== undefined) {
				this.filterList = this.filterInfoToFilterInfoView(
					meta_infos[0].FieldsInfo,
					currentConfig
				);
			}
			const selectedMatchMode = this.matchModeOptions.find(i => currentConfig.MatchMode === i.value);
			const selectedMatchmodeAssert = Id.assertSet(selectedMatchMode, new Error("No MatchMode found, that should not happen"));
			this.selectedMatchMode = selectedMatchmodeAssert;

		}
	}
	viewToSettings(): FilterSettings {
		const newSettings = <FilterSettings>{
			...this.getCurrentWorkflowNode().Properties.Configuration,
		};
		const filterInfoViews = this.filterList;
		const filterInfos = this.filterInfoViewToFilterInfo(filterInfoViews);

		newSettings.FilterInfos = filterInfos;

		const select_match_mode = Id.assertSet(this.selectedMatchMode, new Error("No MatchMode Selected, this should not happen!"));
		newSettings.MatchMode = select_match_mode.value;

		return newSettings;
	}

	onSettingsChanged(settings: FilterSettings) {
		throw new Error("Method not implemented.");
	}

	dropDownColumn: any[] = [];
	columnList: any[] = [];
	filterList: FilterInfoView[] = [];
	filterTypeOptions: any[];
	matchModeOptions: GuiDropDown[] = [];
	selectedMatchMode?: GuiDropDown;

	readonly InputPort = "Input";

	constructor(
		protected workflowService: WorkflowsService,
		protected designerService: DesignerService
	) {
		super(workflowService, designerService);

		this.filterTypeOptions = [
			{ name: "equals", value: "equals" },
			{ name: "does not equal", value: "doesNotEqual" },
			{ name: "greater than", value: "greaterThan" },
			{ name: "greater or equal", value: "greaterOrEqual" },
			{ name: "less than", value: "lessThan" },
			{ name: "less or equal", value: "lessOrEqual" },
			{ name: "contains", value: "contains" },
			{ name: "does not contain", value: "doesNotContain" },
		];
		this.matchModeOptions = [
			{ name: "Match all (AND)", value: "and" },
			{ name: "Match any (OR)", value: "or" },
		];
	}

	onAddFilter() {
		// -- create new filterInfo view
		let newFilter = new FilterInfoView("", this.filterTypeOptions[0], "", false);

		// newFilter.ColumnName = "";
		// newFilter.FilterTypes = this.filterTypeOptions[0];
		// newFilter.FilterValues = "";
		// newFilter.hasError = false;
		// -- push new filterInfoView to FilterArray
		this.filterList.push(newFilter);

		// -- transform from filterInfoView to filterInfos
		this.filterInfoViewToFilterInfo(this.filterList);
		this.onUpdateSettings(false);
	}
	onDeleteEntry(index: number) {
		this.filterList = [
			...this.filterList.slice(0, index),
			...this.filterList.slice(index + 1),
		];
		this.onUpdateSettings(false);
	}

	filterInfoToFilterInfoView(list: FieldInfo[], config: FilterSettings) {
		let newFilterInfosView: FilterInfoView[] = [];

		const configFilterInfo = new Array<FilterInfo>();
		for (let fi of config.FilterInfos) {
			configFilterInfo.push(fi);
		}

		configFilterInfo.map((entry) => {
			//let newInfoView = new FilterInfoView(entry.ColumnName,);

			// -- create view class from config
			//newInfoView.ColumnName = entry.ColumnName;

			const FilterTypes = {
				name: entry.FilterTypes,
				value: entry.FilterTypes,
			};

			const FilterValues = entry.FilterValues.map(entry => entry).toString();

			const fieldInfoFound = list.find((field) => field.Name === entry.ColumnName);

			const hasError = !fieldInfoFound;

			let newInfoView = new FilterInfoView(entry.ColumnName, FilterTypes, FilterValues, hasError);

			newFilterInfosView.push(newInfoView);
		});

		return newFilterInfosView;
	}
	filterInfoViewToFilterInfo(filterList: FilterInfoView[]) {
		// -- starting with filterList from GUI

		// -- create new empty array
		let newFilterInfos: FilterInfo[] = [];

		// -- Iterate through filterList and create new FilterInfo Rows for each given entry
		filterList.map((entry) => {
			let newSettings = new FilterInfo();
			newSettings.ColumnName = entry.ColumnName;
			newSettings.FilterTypes = entry.FilterTypes.value;
			newSettings.FilterValues = [entry.FilterValues];
			newFilterInfos.push(newSettings);
		});
		// // -- create respective FilterConfig Class

		return newFilterInfos;
	}

	ngOnInit(): void {
		super.ngOnInit();
	}
	onFocusLost(event: FocusEvent) {
		const div_left: boolean = (event.relatedTarget === null);

		console.log("Send from Div: ", div_left);

		if (div_left) {
			//this.onCheckDirtyFlag();
			this.onUpdateSettings(true);
		}
	}

	ngOnDestroy(): void { }
}
