<app-create-datasource-dialog-new></app-create-datasource-dialog-new>
<app-create-datastore-dialog></app-create-datastore-dialog>
<app-create-schedule-dialog></app-create-schedule-dialog>
<app-create-workflow-dialog [sender]="'WorkflowView'" [selectedWorkflow]="selectedWorkflow">
</app-create-workflow-dialog>

<div style="z-index: 1000000;">
    <p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
    </p-toast>
</div>

<div style="display:flex;align-items: center;margin-bottom: 20px">
    <img [alt]="'HeaderIcon'" src="assets/layout/images/HeaderIcon_Home.svg" width="50"
        style="vertical-align: middle" />
    <h2 *ngIf="appMain.currentUserFull.UserInfo.FirstName" style="margin: 0px 10px;color:#6941C6;font-weight: bold;">
        {{'Welcome ' | translate}}{{appMain.currentUserFull.UserInfo.FirstName}}! {{'What do you want to do?' |
        translate}}</h2>
    <h2 *ngIf="!appMain.currentUserFull.UserInfo.FirstName" style="margin: 0px 10px;color:#6941C6;font-weight: bold;">
        {{'Welcome ' | translate}}{{appMain.currentUserFull.UserInfo.EMail}}! {{'What do you want to do?' | translate}}
    </h2>

</div>

<!-- <div class="headerSection p-jc-between">
    <span class="headerSection">


        <h3 *ngIf="appMain.currentUserFull.UserInfo.FirstName" style="display:flex; align-items: center;">        <p-avatar image="assets/layout/images/logo-bion-new.png" styleClass="mr-2" size="xlarge" shape="circle" style="margin-right: 10px"></p-avatar><span class="sprechblase animated-text" >{{'Welcome ' | translate}}{{appMain.currentUserFull.UserInfo.FirstName}}! {{'What do you want to do?' | translate}}</span>

        </h3>
        <h3 *ngIf="!appMain.currentUserFull.UserInfo.FirstName" style="display:flex; align-items: center;">        <p-avatar image="assets/layout/images/logo-bion-new.png" styleClass="mr-2" size="xlarge" shape="circle" style="margin-right: 10px"></p-avatar> <span class="sprechblase animated-text">{{'Welcome ' | translate}}{{appMain.currentUserFull.UserInfo.EMail}}! {{'What do you want to do?' | translate}}</span>

        </h3>
    </span>
</div> -->

<div class="p-grid actionItemContainer">
    <div class="p-col-4 p-md-4 actionItem" style="border-right: 2px solid #f2f4f6"
        (click)="onActionClicked('newWorkflow')">
        <span class="p-col-2 itemImg">
            <img src="assets/layout/images/New_WF.svg" alt="Static Image" width="50" class="staticImage" />
            <img src="assets/layout/images/New_WF.svg" alt="Animated GIF" width="50" class="gifImage" />
        </span>
        <p class="itemText p-col">
            {{'Create workflow' | translate}}
        </p>
    </div>
    <div class="p-col p-grid" style="padding-bottom: 0;">
        <div class="p-col-6 actionItem" (click)="onActionClicked('newDatasource')"
            style="border-right: 2px solid #f2f4f6">
            <span class="p-col-2 itemImg">
                <img src="assets/layout/images/New_DS.svg" alt="Static Image" class="staticImage" />
                <img src="assets/layout/images/New_DS.svg" alt="Animated GIF" class="gifImage" />
            </span>
            <p class="itemText p-col">
                {{'Create datasource' | translate}}
            </p>
        </div>
        <!-- <div class="p-col-6 actionItem" (click)="onActionClicked('newWorkflow')">
            <span class="p-col-2 itemImg">
                <img src="assets/layout/images/animatedIcons/sozial.png" alt="Static Image" class="staticImage" />
                <img src="assets/layout/images/animatedIcons/sozial.png" alt="Animated GIF" class="gifImage" />
            </span>
            <p class="itemText p-col">
                {{'Create workflow' | translate}}
            </p>
        </div> -->
        <div class="p-col-6 actionItem" (click)="onActionClicked('newDestination')">
            <span class="p-col-2 itemImg">
                <img src="assets/layout/images/New_DEST.svg" alt="Static Image" class="staticImage" />
                <img src="assets/layout/images/New_DEST.svg" alt="Animated GIF" class="gifImage" />
            </span>
            <p class="itemText p-col">
                {{'Create destination' | translate}}
            </p>
        </div>
        <!-- <div class="p-col-6 actionItem" (click)="onActionClicked('newSchedule')">
            <span class="p-col-2 itemImg">
                <img src="assets/layout/images/animatedIcons/kalender.png" alt="Static Image" class="staticImage" />
                <img src="assets/layout/images/animatedIcons/kalender.png" alt="Animated GIF" class="gifImage" />
            </span>
            <p class="itemText p-col">
                {{'Create schedule' | translate}}
            </p>
        </div> -->
    </div>

</div>

<!-- <div class="usageSection p-jc-between">
    <span class="usageSection">
        <h5 style="margin-bottom:0; margin-right: 15px">{{'Performance Indicators' | translate}}</h5>

        <p-selectButton [options]="allZeitscheiben" [(ngModel)]="currentZeitscheibe" optionLabel="icon" (onChange)="onChangePeriod($event)">
            <ng-template let-item pTemplate>
                 <span [pTooltip]="item.tooltip" tooltipPosition="top">{{item.abb}}</span>
            </ng-template>
        </p-selectButton>
    </span>

    <span pTooltip="Choose one of the available reports" tooltipPosition="top">
        <p-dropdown [options]="reports" [(ngModel)]="currentCategory" (onChange)="onChangeCategory($event)"></p-dropdown>
    </span>

</div>

<div class="p-grid usageContainer">
    <div class="p-col-4" *ngFor="let kpi of kpiViews">
        <div class="kpiItem p-grid" [ngClass]="{'bordered-left': kpi.hasLeftNeighbor, 'bordered-right': kpi.hasRightNeighbor}">
            <div class="itemHeader p-col-12">
                <p>
                    <img [src]="kpi.img" alt="Static Image" class="kpiImage" />
                    {{kpi.title | translate}}
                </p>
            </div>
            <div class="itemContent p-col-12">
                <h3 style="margin-bottom:0">{{kpi.current.value.value }} {{kpi.current.value.valueUnit }} {{kpi.current.value.valueType | translate}}</h3>
                <p class="itemHeader">{{kpi.previous.value.value }} {{kpi.previous.value.valueUnit }} {{kpi.dimension | translate}}  <span style="margin: 0px 5px">|</span> <span [style.color]="kpi.percentage < 0 ? 'rgb(219, 146, 146)' : 'rgb(146, 219, 146)'"> {{kpi.percentage}}%</span> </p>
            </div>
        </div>
    </div>
</div> -->
<div class="p-grid" style="margin-top:25px">
    <div class="p-col-12 p-md-8">
        <div class="miscSection">
            <h5>{{'Current processes' | translate}}</h5>
        </div>
        <div class="card">
            <app-operations-overview></app-operations-overview>
        </div>
    </div>

    <div class="p-col">
        <div class="miscSection">
            <h5>{{'Helpful links' | translate}}</h5>
        </div>
        <div class="p-col-12 helpUrl"
            style="padding-top:0;padding-bottom:0;padding-left:0;display:flex;align-items: center">
            <i class="pi pi-arrow-right" style="margin-right:10px;color:#6941C6"></i>
            <span (click)="onActionClicked('EmailSupport')" class="margin-left:15px">{{'Send E-Mail to
                Support' |
                translate}}</span>
        </div>
        <div class="p-col-12 helpUrl"
            style="padding-top:0;padding-bottom:0;padding-left:0;display:flex;align-items: center">
            <i class="pi pi-arrow-right" style="margin-right:10px;color:#6941C6"></i>
            <span (click)="onActionClicked('GiveFeedback')" class="margin-left:15px">{{'Give Product
                Feedback' |
                translate}}</span>
        </div>
        <div class="p-col-12 helpUrl"
            style="padding-top:0;padding-bottom:0;padding-left:0;display:flex;align-items: center">
            <i class="pi pi-arrow-right" style="margin-right:10px;color:#6941C6"></i>
            <span class="margin-left:15px">{{'Go to Help Center' | translate}} {{'(soon)' | translate}}</span>
        </div>
        <div class="p-col-12 helpUrl"
            style="padding-top:0;padding-bottom:0;padding-left:0;display:flex;align-items: center">
            <i class="pi pi-arrow-right" style="margin-right:10px;color:#6941C6"></i>
            <span class="margin-left:15px">{{'Start tutorial' | translate}} {{'(soon)' | translate}}</span>
        </div>
    </div>
</div>



<!--
<div class="p-grid miscContainer">

    <div class="p-col-6 miscItem" (click)="onActionClicked('EmailSupport')" [attr.data-text]="hovered3 ? ('Contact our Support' | translate) : ('Customer Support' | translate)" (mouseenter)="onMouseEnter3()" (mouseleave)="onMouseLeave3()">
        <div class="backgroundImage2"></div>
        <p class="miscItemText p-col" >
            {{ hovered3 ? ('Contact our Support' | translate) : ('Customer Support' | translate) }}
        </p>
    </div>
    <div class="p-col-6 miscItem" (click)="onActionClicked('GiveFeedback')" [attr.data-text]="hovered2 ? ('Every Feedback is appreciated' | translate) : ('Feedback' | translate)" (mouseenter)="onMouseEnter2()" (mouseleave)="onMouseLeave2()">
        <div class="backgroundImage"></div>
        <p class="miscItemText p-col">
            {{ hovered2 ? ('Every Feedback is appreciated' | translate) : ('Feedback' | translate) }}
        </p>
    </div>
</div> -->