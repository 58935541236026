import { Component, OnInit, ViewChild } from '@angular/core';
import { AirbyteStagingService, SourceDefinition } from 'src/app/services/airbyte-staging.service';
import dummySourceDefinitions from './source-definitions.json';
import { Observable, Subject, of } from 'rxjs';
import { ConfigService } from './config.service';
import { ConfigItem } from './config-item';
import { CreateAbSourceConfigComponent } from '../create-ab-source-config/create-ab-source-config.component';
import { AirbyteModel } from 'src/app/models/api/models/AirbyteModel';
import jsonSchemaDemo from "./json-schema-demo.json"
import { concatMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiModel } from 'src/app/models/api/com/bion/integrate/airbyte/ApiModel';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';

// import { State } from '@dashjoin/json-schema-form';

// https://transform.tools/json-schema-to-typescript
// http://schemaform.io/

export interface SchemaAttribute {
  type: string;
  title: string;
  properties: any;  // Object with Schema Attribute Entries
  groups?: any[];
  group?: string;
  items?: any[];    // Array Attribute
  required: string[];
  default?: any[];
  order?: number;
  minItems?: number;
  description?: string;
  uniqueItems?: boolean;
}

/**
 * Source Configuration Schema
 */
export interface SourceDefinitionSpec {
  sourceDefinitionId: string;
  documentationUrl: string;
  connectionSpecification: SchemaAttribute;
  jobInfo: any;
}

/**
 * Erstellt eine neue Airbyte Source. Sie ist zugleich die Host-Komponente für die Config-Views, die von
 * CreateAbSourceConfigComponent verwaltet werden.
 *
 * Diese Komponente ruft Airbyte-API-Funktionen auf, um die Connector-Liste zu holen.
 *
 * ACHTUNG: Das hier entspricht NICHT (!!) der Ad Banner Component. Die hier liefert nur die ConfigItems, die von
 * CreateAbSourceConfigComponent benutzt werden.
 */
@Component({
  selector: 'app-create-ab-source',
  templateUrl: './create-ab-source.component.html',
  styleUrls: ['./create-ab-source.component.scss'],
  providers: [ConfigService]
})
export class CreateAbSourceComponent implements OnInit {

  //connectorViews:Map<string,string>;            // Config View Mappings

  @ViewChild("sourceConfig") sourceConfigView: CreateAbSourceConfigComponent;  // ACHTUNG: Wenn man dieses ViewChild strict einbindet funktioniert es nicht mehr!

  // Source Paramter
  sourceName: string = "";
  sourceDefinitions: SourceDefinition[] = [];
  sourceDefinitionSelected?: SourceDefinition;
  configs: ConfigItem[] = [];
  //selectedConfig?:ConfigItem = undefined;

  // TODO, prüfe ob das Kind (also wir) das überhaupt entscheiden.
  isReady: boolean = false;    // Info für die Mutter, dass wir weiter gehen können.


  // https://angular.io/guide/dynamic-component-loader

  /**
   * 
   * @param router Angular Routing
   * @param configService Liefert spezielle Komponenten für die dynamischen Config Views (unser erster Ansatz)
   * @param airbyteService Airbyte Backend Service
   * @param fb Form Builder: veraltet, expeterimentell, kann bald entfernt werden.
   * @param formlyJsonschema Formly Bibliothek - Primäres dynsmiches Form-Rendering
   */
  constructor(private router: Router, private configService: ConfigService, private airbyteService: AirbyteStagingService, private fb: FormBuilder,
    private formlyJsonschema: FormlyJsonschema) {
    //this.connectorViews = this.getConnetorViews();
  }

  ngOnInit(): void {

    // this.sourceDefinitions = this.getSourceDefinitionsDummy();
    // this.sourceDefinitionSelected = this.sourceDefinitions[0];

    this.configs = this.configService.getViewItems();  // just for the child.

    this.sourceDefinitions = this.getSourceDefinitionsDummy();  // For our dropdown

    // -- This component contains:
    // 1. Connector Selector
    // 2. Set Name
    // 3. Config

    // -- Initialize components


    // -- Dynamic Schema by myself -> DISABLED DUE TO json-schema-form TESTING
    // const root_item = jsonSchemaDemo;
    // console.log("Building Dynamic Form");
    // const root_form = this.dynDispatch(root_item);
    // console.log(root_form);
  }

  // onNext(event:any) {
  //   // TODO
  // }

  /**
   * Erzeugt die Airbyte Source
   */
  onCreate() {


    // call api

    // Mock: Create Postgres Hardcoded

    //const source_def = "decd338e-5647-4c0b-adf4-da0e75f5a750";          // connector id
    //const config: any = postgresTestConfig;

    const comp_ref = this.sourceConfigView.currentComponent;
    const comp_data = comp_ref.instance.data;
    const source_def = this.sourceConfigView.curentItem?.id;

    if (source_def === undefined) {
      alert("No connector selected!");
      return;
    }

    if (comp_ref === undefined) {
      alert("No Config Loaded!");
      return;
    }

    if (comp_data === undefined) {
      alert("No Config Data available");
      return;
    }

    const config: any = comp_data;
    const name = this.sourceName;

    // 1. Check Config
    const check_arg = new AirbyteModel.CheckConnectionArg(config, source_def);
    console.log("Check Connection Argument", check_arg);
    const check_conn = this.airbyteService.checkConnection(check_arg);

    // 2. Create Source
    const create_source_arg = new AirbyteModel.CreateSourceArg(source_def, config, name);
    console.log("Create Source Argument", create_source_arg);
    const create_source = this.airbyteService.createSource(create_source_arg);


    const complete_op = check_conn.pipe(concatMap(() => create_source));

    this.setLoading(true);
    complete_op.subscribe(
      create_result => this.handleSuccess(create_result),
      err => this.handleError(<Error>err),
      () => this.setLoading(false)
    );
  }

  /**
   * Erstell die Airbyte Source:
   * ACHTUNG. Diese Funktion hat Seiteneffekte: später ersetzen durch synchronisierbare Funktion.
   * @param config 
   * @param source_def 
   * @param name 
   */
  createAirbyteSource(config: any, source_def: string, name: string) {
    // 1. Check Config
    const check_arg = new AirbyteModel.CheckConnectionArg(config, source_def);
    console.log("Check Connection Argument", check_arg);
    const check_conn = this.airbyteService.checkConnection(check_arg);

    // 2. Create Source
    const create_source_arg = new AirbyteModel.CreateSourceArg(source_def, config, name);
    console.log("Create Source Argument", create_source_arg);
    const create_source = this.airbyteService.createSource(create_source_arg);


    const complete_op = check_conn.pipe(concatMap(() => create_source));

    this.setLoading(true);
    complete_op.subscribe(
      create_result => this.handleSuccess(create_result),
      err => this.handleError(<Error>err),
      () => this.setLoading(false)
    );
  }

  handleSuccess(create_result: AirbyteModel.CreateSourceResult<unknown>) {

    console.log("Create Source Successful", create_result);

    //const id = create_result[1].id.toString();
    const id = create_result.SourceInfo.id;
    console.log("Display Source Info with id " + id);

    // Und wieder mal eine bessere Anleitung, weil Angular einfach etwas degeneriert ist.
    // Hey Angular, wenn ihr euch in euren Tutorials verliert, klärt doch erstmal am Anfang die Basisfunktionen.... cool?
    // https://www.digitalocean.com/community/tutorials/angular-navigation-routerlink-navigate-navigatebyurl

    this.router.navigate(['/', 'testAirbyteViewSource', id]);
  }

  handleError(err: Error) {
    console.log("Create Source FAILED!", err);
    this.setLoading(false);
  }

  isLoading: boolean = false;
  progressMode: string = "determinate";

  setLoading(loading: boolean): void {
    this.isLoading = loading;

    // https://www.primefaces.org/primeng-v14-lts/progressbar

    if (this.isLoading) {
      this.progressMode = "indeterminate";
    } else {
      this.progressMode = "determinate";
    }
  }

  onSourceDefChanged(event) {
    const new_source_def = this.sourceDefinitionSelected;
    console.log("Source Definition Changed", new_source_def);

    if (new_source_def === undefined) {
      console.log("No Source Definition selected -> quit!");
      return;
    }

    if (new_source_def) {
      this.sourceConfigView.setSelectedSourceDef(new_source_def.sourceDefinitionId);
    }

    // Trigger dynamic form generation

    if (new_source_def) {

      console.log("Load Schema");
      this.setLoading(true);

      const arg = new ApiModel.SourceDefinitionSpecs.GetReq(new_source_def.sourceDefinitionId, "");  // param 2 patched by BE -> Ignore!
      this.airbyteService.getSourceDefinitionSpecs(arg).subscribe(definition => {

        this.sourceConfigView.setConnectorSpec(definition);

        console.log(definition);
        const _schema = definition.connectionSpecification;
        console.log("Original Schema", _schema);

        // Formly
        this.loadAirbyteForm(_schema);

        // the dashjoin lib produces an error when the schema has a property "order"
        // dashjoin-json-schema-form.mjs, line: 789
        const _safe_schema = this.removeOrderFromSchema(_schema);
        //const _safe_schema = _schema;
        this.schema = _safe_schema;
        console.log("Safe Schema", this.schema);
        this.value = {};
      },
        err => this.handleError(err),
        () => this.setLoading(false));
    }

    if (new_source_def) {
      //this.createDynamicConfig()
    }

  }

  loadConnectorSchema() {

  }

  removeOrderFromSchema(schema: any): any {

    if (Array.isArray(schema)) {
      return schema;
    }

    const t = typeof (schema);

    if (t != "object") {
      return schema;
    }

    const keys = Object.keys(schema);
    for (let k of keys) {
      if (k == "order")
        schema["order"] = undefined;
      else {
        schema[k] = this.removeOrderFromSchema(schema[k]);
      }
    }

    return schema;
  }

  onShow(event: any) {
    console.log("Form Value", this.value);
  }

  /**
   * Creates a new Airbyte Source - DUMMY
   * @param connectorId Source Connector ID
   * @param config Config
   * @returns true or an exception
   */
  createSourceDummy<T>(connectorId: string, config: T): Observable<boolean> {
    console.log("API CALL DUMMY");
    console.log("Connector", connectorId);
    console.log("Config", config);

    return of(true);
  }

  onHandleError(e: Error) {
    // handle error here... maybe enhance this :-)
    console.log(e);
  }

  onSubmit() {

  }

  // /**
  //  * Liefert die Config Views für die Airbyte Sources.
  //  * Die ID wird von Airbyte festgelegt, eine übersicht aller IDs gibt es im Service mit der Funktion 'getSourceDefinitions'.
  //  * Alternativ kann man die eigene Airbyte Instanz nutzen oder deren API direkt anfunken.
  //  * @returns L
  //  */
  // getConnetorViews() : Map<string,string> {

  //   const m = new Map<string,string>();

  //   // create the view mapping here:
  //   m.set('aaaa-bbbb-cccc-dddd-eeee', 'app-ab-conn-google-sheets');

  //   return m;
  // }

  /**
   * Source definitions from a JSON file, later replaced by API call.
   * @returns
   */
  getSourceDefinitionsDummy(): SourceDefinition[] {
    const data: SourceDefinition[] = <SourceDefinition[]>dummySourceDefinitions.sourceDefinitions;
    return data;
  }

  // json-schema-form 1.0.3 - ZU NEU!!!, wir nutzen erstmal 0.9.3

  // // TODO: rename them to avoid collisions.
  //   /**
  //  * This JSON schema defines the form
  //  *
  //  * Examples: https://dashjoin.github.io/
  //  * Documentation: https://github.com/dashjoin/json-schema-form
  //  */
  //   state: State = {
  //     schema: {
  //       type: 'array',
  //       items: {
  //         type: 'object',
  //         required: ['name'],
  //         properties: {
  //           name: { type: 'string', description: 'Please enter your name' },
  //           bday: { type: 'string', widget: 'date', dateFormat: 'yyyy-MM-dd' },
  //           hobbies: {
  //             type: 'array',
  //             title: 'Hobbies',
  //             layout: 'chips',
  //             items: { type: 'string' },
  //             style: { width: '400px' },
  //           },
  //         },
  //       },
  //     },
  //     value: [
  //       {
  //         name: 'Joe',
  //         hobbies: ['swimming', 'reading'],
  //       },
  //     ],
  //     name: 'myform',
  //     control: new FormArray([]),
  //   };

  // /**
  //  * print form contents
  //  */
  // value: string;


  // json-schema-form 0.9.3

  // -- Demo Schema

  // schema: Schema = {
  //   type: 'array',
  //   items: {
  //     type: 'object',
  //     properties: {
  //       name: { type: 'string' },
  //       bday: { type: 'string', widget: 'date' }
  //     }
  //   }
  // };

  // value: any = [{
  //   name: 'Joe',
  //   bday: '2018-09-09T22:00:00.000Z'
  // }];

  // -- Airbyte Schema

  schema = jsonSchemaDemo;
  value: any = {};


  error: any;

  // https://www.youtube.com/watch?v=Xk9dxbbBFjo


  profileForm: FormGroup = new FormGroup([]);  // empty


  createDynamicConfig(specs: SourceDefinitionSpec) {

    console.log("Create Dynamic Form");

    // -- Es gibt auch libs die das machen, aber dann komme ich in die Versionen Hölle

    // clear old

    // fill

    const root_Item = specs.connectionSpecification;

    // for(const property of root_Item.properties) {
    //   this.fb.control('')
    // }

    const root_form = this.dynDispatch(root_Item);
    this.profileForm = root_form;
  }

  dynDispatch(a: SchemaAttribute) {
    switch (a.type) {
      case "object":
        return this.dynCreateObject(a);
      case "integer":
        return this.dynCreateInteger(a);
      case "string":
        return this.dynCreateString(a);
      case "boolean":
        return this.dynCreateBool(a);
      case "array":
        return this.dynCreateArray(a);
      default:
        throw new Error("The type " + a.type + " is unexpected");
    }
  }

  dynCreateString(a: SchemaAttribute) {
    //const v = Validators.required;
    //const req = a.required;
    //return this.fb.control(a.default ? a.default : "", { validators: Validators.required });
    return this.fb.control(a.default ? a.default : "", { validators: Validators.required });
  }

  dynCreateInteger(a: SchemaAttribute) {
    return this.fb.control(a.default ? a.default : 0);
  }

  dynCreateBool(a: SchemaAttribute) {
    return this.fb.control(a.default ? a.default : false);
  }

  dynCreateObject(a: SchemaAttribute) {

    //const items = a.items.map(i => this.dynDispatch(i));
    const keys = Object.keys(a.properties);
    const controls = keys.map(k => {
      const p: SchemaAttribute = a.properties[k];
      return this.dynDispatch(p);
    })

    const fg = this.fb.group(controls);

    return fg;
  }

  dynCreateArray(a: SchemaAttribute) {

    // TODO: check case of type
    // items.type = "string"   --> OK  (atomic)
    // items.type = "object"   --> Nesting

    console.log("Array is still dummy");
    //this.fb.array()

    return this.fb.control("Dummy Array");
  }


  hide_formly: boolean = false;
  hide_json_forms: boolean = true;
  hide_dynamic_config: boolean = false;


  // == Formly Section
  private destroy$: Subject<any> = new Subject<any>();
  form: FormGroup;
  model: any;
  options: FormlyFormOptions;
  fields: FormlyFieldConfig[];

  // no longer needed due to api call
  examplesAirbyte = [
    'Google Sheets',
    'Postgres',
    'Oracle DB',
  ];

  // TODO: adjust
  // loadExampleAirbyte(type: string) {
  //   this.http
  //     .get<any>(`assets/specs/${type}.json`)
  //     .pipe(
  //       tap(({ connectionSpecification }) => {
  //         this.type = type;
  //         this.form = new FormGroup({});
  //         this.options = {};
  //         this.fields = [this.formlyJsonschema.toFieldConfig(connectionSpecification)];
  //         this.model = {};
  //       }),
  //       takeUntil(this.destroy$),
  //     )
  //     .subscribe();
  // }

  loadAirbyteForm(connectionSpecification: any) {
    console.log("Load Airbyte Form", connectionSpecification);

    this.form = new FormGroup({});
    this.options = {};
    this.fields = [this.formlyJsonschema.toFieldConfig(connectionSpecification)];
    this.model = {};
  }

  real_formly_submit: boolean = true;

  submit() {
    console.log("Submit Formly Form");

    if (this.real_formly_submit) {

      const source_def = this.sourceConfigView.curentItem?.id;
      const name = this.sourceName;
      if (source_def === undefined) {
        alert("No connector selected!");
        return;
      }

      //const config = JSON.stringify(this.model);
      const config = this.model;
      console.log("Final Argument", config);

      this.createAirbyteSource(config, source_def, name);

    } else {
      alert(JSON.stringify(this.model));

    }


  }


  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  // == Formly Section End

}
