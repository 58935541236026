import { Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { ScheduleActionEvent, ScheduleDialogActionType } from "src/app/models/dialog-actions.model";
import { ScheduleActionPlan } from "src/app/models/schedule.model";
import { SchedulesService } from "src/app/services/schedules.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { SubSink } from "subsink";

@Component({
	selector: "app-create-schedule-dialog",
	templateUrl: "./create-schedule-dialog.component.html",
	styleUrls: ["./create-schedule-dialog.component.scss"],
	providers: [MessageService]
})
export class CreateScheduleDialogComponent implements OnInit, OnDestroy {
	subs = new SubSink();
	savingInProgress: boolean = false;
	displayNewSchedule: boolean = false;
	scheduleName: string = "";
	scheduleDescription?: string;
	isActive: boolean = true;

	constructor(
		private scheduleService: SchedulesService,
		private systemLogService: SystemMessageLogService,
		private messageService: MessageService,
		private translate: TranslateService
	) { }

	ngOnInit(): void {
		this.subs.sink = this.scheduleService.scheduleDialogActionSendEmitter.subscribe((res: ScheduleActionEvent) => {
			if(res.actionType === ScheduleDialogActionType.createSchedule) {
				this.displayNewSchedule = res.display;
			}
		},
			(err: Error) => {
				this.systemLogService.handleError(err);
			});
	}
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	createSchedule() {
		this.savingInProgress = true;

		let newSchedule = new ScheduleActionPlan();
		newSchedule.id = -1;
		newSchedule.name = this.scheduleName;
		newSchedule.description = this.scheduleDescription;
		newSchedule.isActive = this.isActive;


		this.subs.sink = this.scheduleService.createScheduleActionPlan(newSchedule).subscribe(
			(res: ScheduleActionPlan) => {
				this.scheduleService.schedulesChangedEmitter.emit("New Schedule created");
				this.resetForms();

				this.messageService.add({
					severity: "success",
					summary: this.translate.instant("Message.CreateScheduleSuccess.Title"),
					detail: this.translate.instant("Message.CreateScheduleSuccess.Text1") + res.id +
					this.translate.instant("Message.CreateScheduleSuccess.Text2"),
				});

				this.displayNewSchedule = false;
			},
			(error: Error) => {
				this.systemLogService.handleError(error);
			}, () => {
				this.savingInProgress = false;
			}
		);
	}
	resetForms() {
		this.scheduleName = "";
		this.scheduleDescription = undefined;
		this.isActive = true;
	}
}
