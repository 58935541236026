import { Component, OnInit } from '@angular/core';
import { ChartData, ChartOptions } from 'chart.js';
import { forkJoin } from 'rxjs';
import { Id } from 'src/app/helper/id';
import { BasicFrequency } from 'src/app/models/api/models/scheduler/CronFrequency';
import { DateTimeStampInfo, SchedulesService } from 'src/app/services/schedules.service';

@Component({
  selector: 'app-schedule-line-chart',
  templateUrl: './schedule-line-chart.component.html',
  styleUrls: ['./schedule-line-chart.component.scss']
})
export class ScheduleLineChartComponent implements OnInit {
  lineOptions: ChartOptions;
  lineData: ChartData;
  constructor(private schedulesService: SchedulesService) { 
    this.lineOptions = {}
    this.lineData = {datasets:[]};
  }

  ngOnInit(): void {
    this.lineOptions = {
      plugins: {
          legend: {
                display: false,
                labels: {
                  color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                  display: false,
                  color: '#ebedef'
                }
            },
            y: {
              display: false,
                ticks: {
                  display: false,
                  color: '#495057'
                },
                grid: {
                  display: false,
                  color: '#ebedef'
                }
            }
        }
  };
  this.initGui();

  }

  initGui() {

    let actionsObs = this.schedulesService.getScheduleActions();
    let frequenciesObs = this.schedulesService.getScheduleFrequencies();

		const defaultstartDate = new Date(Date.now());
		const defaultEndDate = new Date(2100, 12, 31);

    let timestampInfosObs = this.schedulesService.getFrequencyTimeStamps(defaultEndDate,undefined,undefined,defaultstartDate);

    let finalObs = forkJoin(actionsObs,frequenciesObs,timestampInfosObs).subscribe((result) => {
      console.log("RESULT",result);

      //let actions = result[0];
      //let frequencies= <BasicFrequency[]>result[1];
      let timestamps: DateTimeStampInfo[] = result[2];
      let extractCounter = 0;
      let workflowCounter = 0;
      let weeklyCounter = 0;
      let monthlyCounter = 0;

      for(let stamp of timestamps) {
        const actionplan = Id.assertSet(stamp.ActionPlan, new Error("No Actionplan for this timestampInfo"))
        let actions = actionplan.Actions;

        for(let action of actions) {
          if(action._type === 'models.ScheduleExtractActionFullRow') {
            extractCounter = extractCounter + 1;
          }
          if(action._type === 'models.ScheduleWorkflowActionFullRow') {
            workflowCounter = workflowCounter + 1;
          }
        }
        const frequencies = <BasicFrequency[]>actionplan.Frequencies;
        for(let freq of frequencies) {

          if(freq.RhythmSettings.Rhythm._type === 'models.scheduler.WeekRhythm') {
            weeklyCounter = weeklyCounter + 1;
          }
          if(freq.RhythmSettings.Rhythm._type === 'models.scheduler.MonthRhythm') {
            monthlyCounter = monthlyCounter + 1;
          }
        }
      }

      this.lineData = {
        labels: ['Weekly', 'Monthly'],
        datasets: [
            // {
            //     label: 'Extract',
            //     data: [400,65],
            //     fill: false,
            //     tension: .4,
            //     borderColor: '#42A5F5',
            //     backgroundColor: 'rgba(125,17,38,0.2)'
            // },
            {
                label: 'Rythmn',
                data: [weeklyCounter,monthlyCounter],
                fill: false,
                //borderDash: [5, 5],
                tension: .4,
                borderColor: '#66BB6A',
                //backgroundColor: 'rgba(25,167,38,0.2)'
                backgroundColor: 'rgba(125,17,38,0.2)'
            },
            // {
            //     label: 'Pipe',
            //     data: [2,722],
            //     fill: false,
            //     borderColor: '#FFA726',
            //     tension: .4,
            //     backgroundColor: 'rgba(255,167,38,0.2)'
            // }
        ]
      };

    });



  }

}
