import { EventEmitter, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { NodeDataChangedData } from '../designer/components/workflow-graph/workflow-graph-events';
import { GoNode } from './graph-view/goJs.model';

import { NodeSelectedData, NodeSettingsChangedData, ToolbarCommand, WorkflowGraphEvent } from './sql-workflow-sketch';

@Injectable({
  providedIn: 'root'
})
export class SqlDummyApiService {



  constructor() { }

  // getSqlPlugIns() : Observable<WorkflowNodeInfo[]> {

  //   const dummy: WorkflowNodeInfo[] = [
  //     {
  //       Name:"Input",
  //       NameLabel: "Input",
  //       Category: "Input",
  //       Settings: [],
  //     },
  //     {
  //       Name:"Select",
  //       NameLabel: "Select",
  //       Category: "Transformation",
  //       Settings: [],
  //     }] ;

  //   return of(dummy); 
  // }



  toolbarCommandFired:EventEmitter<ToolbarCommand> = new EventEmitter<ToolbarCommand>();
  nodeSelectedEmitter:EventEmitter<WorkflowGraphEvent<any,NodeSelectedData>> = new EventEmitter<WorkflowGraphEvent<any,NodeSelectedData>>();
  nodeSettingsChanged: EventEmitter<GoNode> = new EventEmitter<GoNode>();  // Ich habe den WF Node geändert und schicke ihn nun!
  nodeSettingsChanged2:EventEmitter<WorkflowGraphEvent<any,NodeSettingsChangedData>> = new EventEmitter<WorkflowGraphEvent<any,NodeSettingsChangedData>>();

  
}