import { Component, OnInit } from "@angular/core";
import { FieldInfo, WorkflowNodeSettings } from "src/app/models/api/com/bion/etl/Workflow";
import { GuiDropDown } from "src/app/models/helperClass.model";
import { RegexInfo, RegexSettings } from "src/app/models/nodeSettings.model";
// import { FieldInfo, WorkflowNodeSettings } from "src/app/models/workflow.model";
import { DesignerService } from "src/app/services/designer.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { NodeConfigComponentBase } from "../node-config-component-base";

export class RegExInfoView extends RegexInfo {
	hasError?: boolean;
}

@Component({
	selector: "app-regex-node",
	templateUrl: "./regex-node.component.html",
	styleUrls: ["./regex-node.component.scss"],
})
export class RegexNodeComponent
	extends NodeConfigComponentBase<RegexSettings>
	implements OnInit
{
	settingsToView(settings: WorkflowNodeSettings): void {
		// -- Get Configuration
		let currentConfig = <RegexSettings>settings;
		// -- Get Input Meta Data
		const meta_infos =
			this._currentWorkflowNodeAdapter.IWorkflowNode.Properties.MetaInfo.get(
				this.InputPort
			);
		let columnListArray: string[] = [];

		// -- check for MetaInfos from Input Port & store dsField list
		if (meta_infos !== undefined && meta_infos.length > 0) {
			const input_field_infos = meta_infos[0].FieldsInfo;

			// -- filter for string dsfield type entries only
			const input_field_infos_string = input_field_infos.filter((dsfield) => {
				return dsfield.DataType.Name === "String";
			});

			// -- create string array for column dropdown
			columnListArray = input_field_infos.map((fieldInfo) => {
				return fieldInfo.Name;
			});
			this.columnList = columnListArray;
		}
		// -- if no configuration exists, set default
		if (currentConfig === undefined) {
			// -- create empty list
			this.regExList = [];
		} else {
			// -- otherwise load to F&E list from existing config
			// -- Check if metaInfo and F&E Info
			if (!currentConfig.RegExInfos) return;

			currentConfig.RegExInfos.map((info) => {
				if (columnListArray.includes(info.ColumnName)) {
					// -- column still exists, do nothing
				} else {
					// column does not exist anymore, meaning column name currently not included -> include
					columnListArray.push(info.ColumnName);
				}
			});
			this.columnList = columnListArray;
            if(meta_infos !== undefined) {
			this.regExList = this.infoToView(currentConfig, meta_infos[0].FieldsInfo);
            }
		}
	}
	viewToSettings(): RegexSettings {
		const newSettings = <RegexSettings>{
			...this.getCurrentWorkflowNode().Properties.Configuration,
		};
		let newInfoList: RegexInfo[] = [];

		this.regExList.map((entry) => {
			let newInfo = new RegexInfo();
			newInfo.ColumnName = entry.ColumnName;
			newInfo.FormatExpression = entry.FormatExpression;
			newInfo.DropOld = entry.DropOld;
			newInfo.IsCaseSensitive = entry.IsCaseSensitive;
			newInfo.OutputMethod = entry.OutputMethod;
			//newInfo.SplitMethod = entry.SplitMethod;
            newInfo.NewColumnName = entry.NewColumnName;
			//newInfo.NewColumnType = entry.NewColumnType;
			//newInfo.OutputColumnName = entry.OutputColumnName;
			newInfo.ReplacementText = entry.ReplacementText;

			newInfoList.push(newInfo);
		});

		newSettings.RegExInfos = newInfoList;
		//newSettings.TypeCheck = this.selectedTypeCheckOption.value;

		// this.messageService.standardToastMessage({  severity : 'success',
		//                                                 summary : 'Filter successsfully added',
		//                                                 detail : 'New Filter was successfully added to the node.'});

		return newSettings;
	}
	onSettingsChanged(settings: RegexSettings): void {
		throw new Error("Method not implemented.");
	}

	columnList: string[] = [];
	regExList: RegExInfoView[] = [];
	methodOptions: string[] = [];
	expressionList: GuiDropDown[] = [];
	splitMethods: string[] = [];

	readonly InputPort = "Input";

	constructor(
		protected workflowService: WorkflowsService,
		protected designerService: DesignerService
	) {
		super(workflowService, designerService);
	}

	ngOnInit(): void {
		this.methodOptions = ["Replace", "Parse", "Match", "Contains"];
		this.splitMethods = ["Split to column", "Split to row"];
		this.expressionList = [
			{ name: "[abc]", value: "A single character of: a,b,or c" },
			{ name: "[^abc]", value: "Any character except: a,b or c" },
		];
	}

	onAddEntry() {
		let newEntry = new RegExInfoView();
		newEntry.ColumnName = "";
		newEntry.IsCaseSensitive = true;
		newEntry.FormatExpression = "";
		newEntry.DropOld = false;
		newEntry.OutputMethod = this.methodOptions[0];
		newEntry.hasError = false;
		this.regExList.push(newEntry);
        this.onUpdateSettings(false);
	}
	onDeleteEntry(index: number) {
		this.regExList = [
			...this.regExList.slice(0, index),
			...this.regExList.slice(index + 1),
		];
		this.onUpdateSettings(false);
	}

	infoToView(config: RegexSettings, meta?: FieldInfo[]): RegExInfoView[] {
		let view: RegExInfoView[] = [];
		config.RegExInfos.map((info: RegexInfo) => {
			let newInfoView = new RegExInfoView();

			// -- create selected SortMethod from config
			newInfoView.ColumnName = info.ColumnName;
			newInfoView.FormatExpression = info.FormatExpression;
			newInfoView.IsCaseSensitive = info.IsCaseSensitive;
			newInfoView.OutputMethod = info.OutputMethod;
			newInfoView.DropOld = info.DropOld;
            newInfoView.NewColumnName = info.NewColumnName;
			//newInfoView.NewColumnType = info.NewColumnType;
			//newInfoView.OutputColumnName = info.OutputColumnName;
			newInfoView.ReplacementText = info.ReplacementText;
			//newInfoView.SplitMethod = info.SplitMethod;

			let fieldInfoFound = meta?.find((field) => {
				return field.Name === info.ColumnName;
			});

			newInfoView.hasError = !fieldInfoFound;

			view.push(newInfoView);
		});

		return view;
	}
    onFocusLost(event: FocusEvent) {
        const div_left: boolean = (event.relatedTarget === null);

        console.log("Send from Div: ", div_left);

        if (div_left) {
            //this.onCheckDirtyFlag();
            this.onUpdateSettings(true);
        }
    }
}
