import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DatasourcesService } from 'src/app/services/datasources.service';
import { SubSink } from 'subsink';
import * as dss from './../../../../models/datasource.model';
export class DataTypesChart {
	name: string;
	progress: number;
	count: number;
    constructor(name: string, progress: number, count: number) {
        this.name = name;
        this.progress = progress;
        this.count = count
    }
}
@Component({
  selector: 'app-datatype-chart',
  templateUrl: './datatype-chart.component.html',
  styleUrls: ['./datatype-chart.component.scss']
})
export class DatatypeChartComponent implements OnInit, OnDestroy {
    subs = new SubSink();
	@Input() fieldInfos: dss.DataSourceField[] = [];
	dataTypesCountList: DataTypesChart[] = [];
	dataTypesCountFilteredList: DataTypesChart[] = [];
    selectedDatasource?: dss.DataSource;
  constructor(private datasourceService: DatasourcesService) { }

  ngOnInit(): void {
      this.createChart();
      this.subs.sink = this.datasourceService.selectedDatasourceEmitter.subscribe(
        (ds: dss.DataSource) => {
            if (ds === this.selectedDatasource) {
                this.selectedDatasource = undefined;
                this.createChart();
                return;
            }
            this.selectedDatasource = ds;

            this.createChart(ds);
        }
    );
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
}
//   ngOnChanges(changes: SimpleChanges) {
//     if (changes.fieldInfos.currentValue) {

//         this.createChart();
//         // Change detection if changes occured
//         let fieldInfos: dss.DataSourceField[] = changes.fieldInfos.currentValue;

//         let dataTypesList = fieldInfos.map((field) => {
//             return field.dataType;
//         });
//         //console.log(dataTypesList);
//         let dataTypesListSet = new Set(dataTypesList);
//         let dataTypesArray = new Array();

//         for (var type of dataTypesListSet) {
//             // Iterate through list to get counts of each datatype
//             let counter = 0;
//             for (let i of dataTypesList) {
//                 if (i === type) {
//                     counter++;
//                 }
//             }

//             //Init new component class
//             let newEntry = new DataTypesChart();
//             newEntry.name = type;
//             newEntry.count = counter;
//             let progress = (counter / dataTypesList.length) * 100;
//             newEntry.progress = parseInt(progress.toFixed(2));

//             //Push new entry to final Array
//             dataTypesArray.push(newEntry);
//         }
//         this.dataTypesCountList = dataTypesArray.sort(
//             (a, b) => b.count - a.count
//         );
//         this.dataTypesCountFilteredList = dataTypesArray.sort(
//             (a, b) => b.count - a.count
//         );

//     }
// }

  createChart(ds?: dss.DataSource) {
    let fieldInfos: dss.DataSourceField[] = [];

    if (!ds) {
        fieldInfos = this.fieldInfos;
        let dataTypesList = fieldInfos.map((field) => {
            return field.dataType;
        });
        //this.fieldInfosFiltered = fieldInfos;
    } else {
        fieldInfos = this.fieldInfos.filter((field) => {
            return field.dataSource === ds.id;
        });
        let dataTypesList = fieldInfos.map((field) => {
            return field.dataType;
        });
    }

    // Create DataTypes Chart
    let dataTypesList = fieldInfos.map((field) => {
        return field.dataType;
    });
    let dataTypesListSet = new Set(dataTypesList);
    let dataTypesArray = [];

    for (let type of dataTypesListSet) {
        // Iterate through list to get counts of each datatype
        let counter = 0;
        for (let i of dataTypesList) {
            if (i === type) {
                counter++;
            }
        }

        //Init new component class

        //newEntry.name = type;
        //newEntry.count = counter;
        let progress = (counter / dataTypesList.length) * 100;
        const progress_final = parseInt(progress.toFixed(2));

        let newEntry = new DataTypesChart(type,progress_final,counter);
        //Push new entry to final Array
        dataTypesArray.push(newEntry);
    }

    this.dataTypesCountFilteredList = dataTypesArray.sort(
        (a, b) => b.count - a.count
    );

}


}
