import { EventEmitter } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { DestinationService } from "src/app/services/destinationService";
import { TargetAccessLike, TargetMetaAccess, TargetMetaAccessLike, TargetMetaInfo } from "../api/com/bion/connect/TargetAccessLike";
import { DestinationController } from "../api/controllers/DestinationController";
import { ConnectorSettingsBuilder } from "../connectorView.model";
import { DestinationView } from "../destination-view.model";

/**
 * Destination View Base Implementations.
 */
 export abstract class DestinationViewBase<T, MA> implements DestinationView<T, TargetMetaInfo, MA> {

    readonly api: DestinationService;
    readonly connectorID: string;
    readonly connector_key:number;
    connectorSettings: T;
    metaInfo: TargetMetaInfo;
    metaAccess: MA;
    uploadedFile?: File;

    //@Output() _newFileEventEmitter: EventEmitter<File> = new EventEmitter<File>();
    _newFileEventEmitter: EventEmitter<File> = new EventEmitter<File>();

    constructor(api: DestinationService, connectorID: string, connector_key:number) {
        this.api = api;
        this.connectorID = connectorID;
        this.connector_key = connector_key;
    }
    newFileEventEmitter(): EventEmitter<File> {
        return this._newFileEventEmitter;
    }

    fetchMetaDataObs(): Observable<TargetMetaInfo> {
        // == Build Meta Argument fron Connection Argument
        const con_settings = { ...this.connectorSettings };

        //const target_meta_access = this.connectorSettings;
        const target_meta_access = new TargetMetaAccess(con_settings);
        const extract_meta_arg = new DestinationController.GetMetaDataArg(this.connector_key, target_meta_access);

        return this.api.getMetaData(extract_meta_arg).pipe(map(meta_info => {
            this.metaInfo = meta_info;
            return this.metaInfo;
        }));
    }

    abstract getSettingsBuilder(): Observable<ConnectorSettingsBuilder<T>>;

    uploadFile(file: File): void {
        if(this.isFileBased()) {
            this.uploadedFile = file;
            this._newFileEventEmitter.emit(file);
        }
    }

    abstract getDataSourceNameSuggestion(): string;
    abstract isFileBased(): boolean;

    /**
     * Get the Preview Builder for this Connector. If this method isn't overwridden, the settings builder is returned.
     * @param limit Preview Limit
     * @param offset Preview Offset
     */
    getPreviewBuilder(limit:number,offset:number): Observable<ConnectorSettingsBuilder<T>> {
        return this.getSettingsBuilder();
    }

    getConnectorID(): string {
        return this.connectorID;
    }

    abstract getBase64FileData(): string;

    setInitialSettings(settings: T): void {
        this.connectorSettings = settings;
    }
    setInitialMetaSettings(settings: MA): void {
        this.metaAccess = settings;
    }

    initialize() : Observable<any> {
        return of(0);
    }
}