import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MenuItem } from "primeng/api";
import { Customer } from "src/app/demo/domain/customer";
import { WorkflowNodeGuiInfo } from "src/app/models/designer.models";
import { DesignerService } from "src/app/services/designer.service";
import { WorkflowsService } from "src/app/services/workflows.service";
interface Category {
    Name: string;
    plugins: WorkflowNodeGuiInfo[];
  }


@Component({
	selector: "app-nodes-list",
	templateUrl: "./nodes-list.component.html",
	styleUrls: ["./nodes-list.component.scss"],
})
export class NodesListComponent implements OnInit {
	displayNodeInfo: boolean = false;
	@Input() plugInInfos: WorkflowNodeGuiInfo[] = [];
	selectedNode?: WorkflowNodeGuiInfo;

	rowGroupMetadata: any;
	items: MenuItem[] = [];
    layout: string = 'grid';

	//@ViewChild("dt") table!: Table;
	@ViewChild("dt") table!: ElementRef;
	@ViewChild("plugInItem") plugInItem!: ElementRef;

	constructor(private designerService: DesignerService, private workflowService: WorkflowsService, private translate: TranslateService) { }

	ngOnInit() {
        this.buildGui(this.plugInInfos);
		this.items = [
			{
				label: this.translate.instant('Show node info'),
				icon: 'pi pi-fw pi-info-circle',
				disabled: false,
				command: () => {this.displayNodeInfo = true}
			}
		];
	}
    uniqueCategories: Category[] = [];
    buildGui(plugIns:WorkflowNodeGuiInfo[]) {


        const categoriesMap: Map<string, Category> = new Map();

        plugIns.forEach((obj) => {
          const categoryName = obj.Category;

          if (!categoriesMap.has(categoryName)) {
            // Falls die Kategorie noch nicht existiert, erstelle sie
            categoriesMap.set(categoryName, { Name: categoryName, plugins: [obj] });
          } else {
            // Falls die Kategorie existiert, füge das Objekt zur Liste hinzu
            const category = categoriesMap.get(categoryName);
            if (category) {
              category.plugins.push(obj);
            }
          }
        });

        // Wandele die Map in ein Array um (optional)
        const uniqueCategories: Category[] = Array.from(categoriesMap.values());


        console.log("uniqueCategories",uniqueCategories);
        this.uniqueCategories = uniqueCategories;

    }

	setSelectedNode(node: WorkflowNodeGuiInfo) {
		this.selectedNode = node;
		this.displayNodeInfo = true;
	}
	resetCurrentForm() {
		this.selectedNode = undefined;
	}

	onSort() {
		this.updateRowGroupMetaData();
	}
	updateRowGroupMetaData() {
		this.rowGroupMetadata = {};
	}

	findIndex(product: Customer) {
		let index = -1;
		for (let i = 0; i < this.plugInInfos.length; i++) {
			if (product.name === this.plugInInfos[i].Name) {
				index = i;
				break;
			}
		}
		return index;
	}

	// RIGHT CLICK
	onRightClick(event: MouseEvent, plugIn: WorkflowNodeGuiInfo) {
		// Handle the right-click event here
		event.preventDefault(); // Prevent the default browser context menu
		// Add your custom logic or actions
		this.selectedNode = plugIn;
	  }

	// DRAG & DROP
	draggedNodeGuiInfo?: WorkflowNodeGuiInfo = undefined;

	dragStart(event: DragEvent, product: WorkflowNodeGuiInfo) {


		this.draggedNodeGuiInfo = product;
		console.log(product);
		this.designerService.draggedNodeEmitter.emit(product);
	}


	dragEnd(event: DragEvent) {
		//this.draggedProduct = null;

		this.draggedNodeGuiInfo = undefined;
	}

	onRequestNode() {
		const subject = this.translate.instant("I want to request a new node");
		const bodyText = this.translate.instant("Hi Bion support, i want to request a new node. Here are my requirements:");
		const mailText = "mailto:support@bion-analytics.com?subject="+subject+"&body="+bodyText; // add the links to body
		window.location.href = mailText;
	}
}
