<p-dialog header="{{'Delete' | translate}}" [(visible)]="displayDeletePermission" (onHide)="clearDialog()" modal="modal"
    showEffect="fade" [style]="{width: '600px'}">


    <div style="line-height: 1.5">
        {{'DeletePermissionText' | translate}}
    </div>
    <div *ngIf="selectedPermissionView">
        {{'DeletePermission?' | translate}} <b>{{selectedPermissionView.roleName}} (ID:
            {{selectedPermissionView.permission.ID}})</b>?
    </div>
    <app-system-message-log></app-system-message-log>
    <p-footer>
        <!-- <button type="button" pButton icon="pi pi-check" (click)="deletePermission()"
            label="Yes" class="p-button-text"></button>
        <button type="button" pButton icon="pi pi-times" (click)="displayDeletePermission=false" label="No"
            class="p-button-text"></button> -->
        <button pButton pRipple type="button" icon="pi pi-times" (click)="displayDeletePermission=false"
            class="p-button-rounded p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'No' | translate}}"></button>
        <button pButton pRipple type="button" icon="pi pi-check" (click)="deletePermission()"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'Yes' | translate}}" [icon]="savingInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'"></button>
    </p-footer>
</p-dialog>
