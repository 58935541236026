import { Accessible } from "../authorization/Accessible";

export class WorkflowAccessible implements Accessible {
	ID: number;
	constructor(ID: number) {
		if (ID === undefined)
			throw new Error(
				"Class 'WorkflowAccessible': Required value 'ID' is undefined"
			);
		this.ID = ID;
	}
	AccessibleType: string = "";
	Value: string = "";
}