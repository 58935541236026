import { SapConfig } from "./SapConfig";

export class SapMetaAccess {
    Configuration: SapConfig;
    Table?: string;
    constructor(Configuration: SapConfig, Table?: string) {
        if (Configuration === undefined) throw new Error("Class 'SapMetaAccess': Required value 'Configuration' is undefined");
        this.Configuration = Configuration;
        this.Table = Table;
    }
}