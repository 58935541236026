<div *ngIf="currentConnectorView.selectedDataSet" class="p-fluid p-grid">
    <div class="p-col-12">
        <p-dropdown appendTo="body" [options]="currentConnectorView.metaInfo.Services"
            [(ngModel)]="currentConnectorView.selectedDataSet" optionLabel="Name"></p-dropdown>
    </div>
    <div class="p-col-12">
        <div class="product-grid-item">
            <div class="product-name">
                <b>{{currentConnectorView.selectedDataSet.Name}}</b>
                <div class="product-version">{{'Current Version' | translate}}
                    {{currentConnectorView.selectedDataSet.Version}} |
                    {{'ServiceID' | translate}}
                    {{currentConnectorView.selectedDataSet.ServiceID}} | {{'DataSetID' | translate}}
                    {{currentConnectorView.selectedDataSet.DataSetID}}</div>
            </div>
            <p *ngIf="currentConnectorView.selectedDataSet.Description.ShortDesc">
                {{currentConnectorView.selectedDataSet.Description.ShortDesc}}</p>
            <p *ngIf="currentConnectorView.selectedDataSet.serviceMetaData.Records">{{'Records' | translate}}
                <b>{{currentConnectorView.selectedDataSet.serviceMetaData.Records}} {{'rows' | translate}}</b>
            </p>
            <p *ngIf="currentConnectorView.selectedDataSet.serviceMetaData.Size">{{'Size' | translate}}
                <b>{{currentConnectorView.selectedDataSet.serviceMetaData.Size}}
                    MB</b>
            </p>
            <div class="product-info-section-large"
                *ngFor="let node of currentConnectorView.selectedDataSet.IncludedNodes">
                <div class="product-info-section-node-name"><i class="fa fa-plus-circle"
                        style="margin-right: 10px;"></i><span>{{node.Name}}</span></div>
            </div>
            <p></p>
            <p-accordion [multiple]="true">
                <p-accordionTab *ngIf="currentConnectorView.selectedDataSet.serviceMetaData.Countries.length > 0"
                    header="Country Overview ({{currentConnectorView.selectedDataSet.serviceMetaData.Countries.length}})">
                    <div class="p-col-12">
                        <span style="margin-right: 15px;margin-bottom: 15px;"
                            *ngFor="let item of currentConnectorView.selectedDataSet.serviceMetaData.Countries"><span
                                style="margin-right: 4px;"
                                class="flag-icon flag-icon-{{item.id.toLowerCase()}} flag-icon-squared"></span>{{item.name}}</span>
                    </div>
                </p-accordionTab>
                <p-accordionTab *ngIf="currentConnectorView.selectedDataSet.serviceMetaData.Currencies.length > 0"
                    header="Currency Overview ({{currentConnectorView.selectedDataSet.serviceMetaData.Currencies.length}})">
                    <div class="p-col-12">
                        <span
                            *ngFor="let item of currentConnectorView.selectedDataSet.serviceMetaData.Currencies">{{item}},
                        </span>
                    </div>
                </p-accordionTab>
                <p-accordionTab *ngIf="currentConnectorView.selectedDataSet.serviceMetaData.Years.length > 0"
                    header="Year History Overview ({{currentConnectorView.selectedDataSet.serviceMetaData.Years.length}})">
                    <div class="p-col-12">
                        <span *ngFor="let item of currentConnectorView.selectedDataSet.serviceMetaData.Years">{{item}},
                        </span>
                    </div>
                </p-accordionTab>
            </p-accordion>
        </div>
    </div>
</div>
