<div>
    <p-orderList controlsPosition="right" [value]="leftTableList" [listStyle]="{'max-height':'10rem'}"
        header="{{'Left Table' | translate}}" filterBy="columnName"
        filterPlaceholder="{{'Filter by name' |translate }}">
        <ng-template let-product pTemplate="item">
            <div class="product-item" pDraggable="leftTableList" (onDragStart)="dragStart(product)"
                (onDragEnd)="dragEnd()">
                <div class="product-list-detail">
                    <p class="mb-2">{{product.columnName}}</p>
                </div>
            </div>
        </ng-template>
    </p-orderList>
</div>
<div>
    <p-orderList controlsPosition="right" [value]="rightTableList" [listStyle]="{'max-height':'10rem'}"
        header="{{'Right Table' | translate}}" filterBy="columnName"
        filterPlaceholder="{{'Filter by name' |translate }}" pDroppable="leftTableList" (onDrop)="drop()">
        <ng-template let-product pTemplate="item">
            <div class="product-item"  [ngStyle]="{'background-color':product.isMissing === false ? undefined : '#FFF0F0'}"  >
                <div class="product-list-detail">
                    <p class="mb-2">{{product.columnName}} <span style="float:right"><button pButton icon="pi pi-times"
                                class="p-button-rounded p-button-text p-button-danger"
                                (click)="deleteEntry(product)"></button></span></p>
                </div>
            </div>
        </ng-template>
    </p-orderList>
</div>
