<p>test-oauth2-flow works!</p>

<!-- <p>HubSpot Test</p>

<app-oauth2-flow [authConfig]="authConfig">
    
</app-oauth2-flow> -->

<p>Google Test</p>

<app-oauth2-flow [authConfig]="googleAuthConfig" [testQueryParams]="googleQueryParams">

</app-oauth2-flow>

<p>Google Manual Test</p>

<p-button label="Login Google Manual" (click)="googleManualClicked()"></p-button>