import { PermissionBase } from "./PermissionBase";
import { ViewAccessible } from "./ViewAccessible";

export class ViewPermission implements PermissionBase<ViewAccessible> {
    ID: number;
    Role: number;
    IsOwner: boolean;
    Accessible: ViewAccessible;
    Rights: string[];
    constructor(ID: number, Role: number, IsOwner: boolean, Accessible: ViewAccessible, Rights: string[]) {
        if(ID === undefined) throw new Error( "Class 'ViewPermission': Required value 'ID' is undefined" );
        if(Role === undefined) throw new Error( "Class 'ViewPermission': Required value 'Role' is undefined" );
        if(IsOwner === undefined) throw new Error( "Class 'ViewPermission': Required value 'IsOwner' is undefined" );
        if(Accessible === undefined) throw new Error( "Class 'ViewPermission': Required value 'Accessible' is undefined" );
        if(Rights === undefined) throw new Error( "Class 'ViewPermission': Required value 'Rights' is undefined" );
        this.ID = ID;
        this.Role = Role;
        this.IsOwner = IsOwner;
        this.Accessible = Accessible;
        this.Rights = Rights;
    }
}