import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import lodash from 'lodash';
import { NodeProperties } from 'src/app/models/api/com/bion/etl/Workflow';
import { PsaInputPlugIn } from 'src/app/models/api/models/etl/PsaInputPlugIn';
import { GoNode } from '../../../graph-view/goJs.model';

@Component({
  selector: 'app-datasource-input',
  templateUrl: './datasource-input.component.html',
  styleUrls: ['./datasource-input.component.scss']
})
export class DatasourceInputComponent implements OnInit {
  @Input() currentNode?: GoNode;
  //selectedDS?: DataSource;
  currentNodeProperties?: NodeProperties;
  currentConfig?: PsaInputPlugIn.Settings = <PsaInputPlugIn.Settings>this.currentNode?.nodeProperties?.Configuration;
  constructor() { }

  ngOnInit(): void {
    console.log("Init currentNode",this.currentNode);

    if(this.currentNode) {
      this.currentNodeProperties = lodash.cloneDeep(this.currentNode.nodeProperties);
      this.currentConfig = <PsaInputPlugIn.Settings>lodash.cloneDeep(this.currentNodeProperties?.Configuration);

    } else {
      console.log("No current Node given");
    }
  }

  onSettingsChanged(evt) {
    console.log("evt", evt);
    //console.log("selectedDS", this.selectedDS);

    if(this.currentNode === undefined) return;
    
    const node = lodash.cloneDeep(this.currentNode);
    console.log("DS Input, before node changed", node);

    //let settings = {...this.currentNodeProperties};
    //const config = <PsaInputPlugIn.Settings>settings.Configuration;
    const config = this.currentConfig;

    console.log("config", config);
    const desc = config?.SelectedDataSource ? config.SelectedDataSource.Name : "";
    node.description = desc;
    //node.nodeProperties = settings;
    node.nodeProperties.Configuration = {...config};

    console.log("node desc changed:", node.description);
    console.log("node settings changed:", node.nodeProperties);
    console.log("DS Input, after node changed, ready to emit: ", node);

    //this.currentNodeProperties = node.nodeProperties;

    this.settingsChanged.emit(node);

  }
  @Output() settingsChanged = new EventEmitter<GoNode>()
}
