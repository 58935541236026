import { Component, Input, OnInit } from '@angular/core';
import { WorkflowNodeInfo } from 'src/app/models/designer.models';
import { PlugInView } from '../sql-workflow-sketch';

@Component({
  selector: 'app-plugin-view',
  templateUrl: './plugin-view.component.html',
  styleUrls: ['./plugin-view.component.scss']
})
export class PluginViewComponent implements OnInit, PlugInView {
  
  constructor() { }
  //@Input() plugIns: PlugInInfo[];
  @Input() plugIns: WorkflowNodeInfo[] = [];

  // setPlugIns(values: PlugInInfo[]): void {
  //   this.plugIns = values;
  // }
  getPlugIns(): WorkflowNodeInfo[] {
    return this.plugIns;
  }

  ngOnInit(): void {
  }

}
