<p-menubar [model]="tieredItems" appendTo=body>

    <ng-template pTemplate="end">


        <span class="p-input-icon-right">

            <div>
                <span style="float:center">
                    <button pButton *ngFor="let plugIn of plugInInfos" [label]="plugIn.Name" icon="pi pi-cloud" class="p-button-rounded p-button-text p-button-warn p-mr-2 p-mb-2"></button>
    
                </span>
                <button pButton pRipple type="button" [icon]="savingInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-play'"
                    pTooltip="{{'RunNode' | translate}}" tooltipPosition="top"
                    class="p-button-rounded p-button-success p-mr-2 p-mb-2"
                    [disabled]="savingInProgress || disableButton" (click)="onRunWorkflowPartially()"></button>
                <button pButton pRipple type="button"
                    [icon]="savingInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-step-forward'"
                    pTooltip="{{'Run' | translate}}" tooltipPosition="top"
                    class="p-button-rounded p-button-success p-mr-2 p-mb-2" [disabled]="savingInProgress"
                    (click)="onRunWorkflow()"></button>
                <button pButton pRipple type="button" icon="pi pi-save" pTooltip="{{'Save' | translate}}"
                    label="{{'Save' | translate}}" tooltipPosition="top" class="p-button  p-mr-2 p-mb-2"
                    [disabled]="savingInProgress" (click)="displaySaveWorkflow()"></button>
            </div>
        </span>
    </ng-template>
</p-menubar>
