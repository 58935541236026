import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { ActivityEntry, LatestActivityProvider } from './latest-activity-provider';

@Component({
  selector: 'app-generic-latest-activities',
  templateUrl: './generic-latest-activities.component.html',
  styleUrls: ['./generic-latest-activities.component.scss']
})
export class GenericLatestActivitiesComponent<T, D> implements OnInit, OnDestroy {
  @Input() currentView!: string;
  @Input() typeClass!: LatestActivityProvider<T, D>;
  activities: ActivityEntry[] = [];
  selectedActivity?: ActivityEntry;
  subs = new SubSink;

  constructor() { }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
  }

  initView(typeClass: LatestActivityProvider<T, D>, data: D): Observable<boolean> {

    this.typeClass = typeClass;

    this.activities = this.typeClass.buildGuI(data);
    // this.subs.sink = this.typeClass.subscribeToEmitter().subscribe((obj: T) => {
    //   this.onObjectClicked(data, obj);
    // });

    return of(true);
  }

  setObject(typeClass: LatestActivityProvider<T, D>): Observable<boolean> {

    this.typeClass = typeClass;

    return this.typeClass.getData().pipe(map((res) => {


      this.activities = this.typeClass.buildGuI(res);

    //   this.subs.sink = this.typeClass.subscribeToEmitter().subscribe((obj: T) => {
    //     this.onObjectClicked(res, obj)
    //   });


      return true
    }))

  }

  onObjectClicked(res: any, obj: T) {
    this.activities = this.typeClass.buildGuI(res, obj);
  }

  onMenuClicked(obj: any) {
    this.selectedActivity = obj;
  }
}
