<div style="z-index: 1000000;">
    <p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
    </p-toast>
</div>
<app-add-permission-dialog sender="Data Source" [view]="'DataSourceView'"></app-add-permission-dialog>
<app-delete-permission-dialog [view]="'DataSourceView'"></app-delete-permission-dialog>
<app-change-permission-dialog [view]="'DataSourceView'"></app-change-permission-dialog>
<div style="display:flex;align-items: center;margin-bottom: 20px">
    <img [alt]="'HeaderIcon'" src="assets/layout/images/HeaderIcon_DS.svg" width="50" style="vertical-align: middle" />
    <h2 style="margin: 0px 10px;color:#6941C6;font-weight: bold;"><a
            routerLink="/SourceIntegrationSources">{{'Datasource' | translate }}</a> <i class="pi pi-chevron-right"
            style="font-size: 1.5rem;color:#E9D7FE"></i> {{data_source?.Name}}</h2> <p-chip *ngIf="connectorInfo"
        label="{{connectorInfo.Name}}"></p-chip>
</div>

<div style="height:4px">
    <p-progressBar *ngIf="isLoading === true" [mode]="progressMode" [style]="{'height': '4px'}"></p-progressBar>
</div>

<app-system-message-log></app-system-message-log>

<!-- <div class="flex justify-content-center">
    <p-breadcrumb class="max-w-full" [model]="items" [home]="home"></p-breadcrumb>
</div> -->
<p-tabView>
    <!-- <p-tabPanel header="{{'History' | translate }}"> -->
    <!-- <app-view-source-int-history></app-view-source-int-history> -->
    <!-- <app-generic-latest-activities #dsLatestActivities [typeClass]="dataSourceActivityNewTypeClass"
            [currentView]="'Datasources'"></app-generic-latest-activities>
    </p-tabPanel> -->

    <p-tabPanel header="{{'Streams' | translate }}">

        <!-- https://primeng.org/splitter -->

        <!-- <p-splitter [panelSizes]="[20,20, 60]" styleClass="mb-5">
            <ng-template pTemplate>
                <div class="col flex align-items-center justify-content-center">

                    <p-table #dt1 [value]="catalog?.streams" (onRowSelect)="onStreamSelected($event)" selectionMode="single"
                        [(selection)]="selected_stream" dataKey="stream.name"
                        [tableStyle]="{'width':'100%', 'height':'100%'}"
                        [globalFilterFields]="['stream.name']" [rows]="25"
                        paginator=true
                        >
                        <ng-template pTemplate="header">
                            <tr>
                                <th>1. Select Stream</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="caption">
                            <div class="flex">
                                <span class="p-input-icon-left ml-auto">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Search Stream" />
                                </span>
                            </div>
                        </ng-template>

                        <ng-template pTemplate="body" let-stream_info>
                            <tr [pSelectableRow]="stream_info">
                                <td>{{stream_info.stream.name}}</td>
                            </tr>
                        </ng-template>
                    </p-table>

                </div>
            </ng-template>
            <ng-template pTemplate>
                <div class="col flex align-items-center justify-content-center">

                    <p-table #dt2 [value]="selected_stream_psa_infos" (onRowSelect)="onPsaSelected($event)"
                        selectionMode="single" [(selection)]="selected_psa" dataKey="Table"
                        [tableStyle]="{'width':'100%', 'height':'100%'}"[globalFilterFields]="['Table']" [rows]="25"
                        paginator=true
                        >
                        <ng-template pTemplate="header">
                            <tr>
                                <th>2. Select Table</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="caption">
                            <div class="flex">
                                <span class="p-input-icon-left ml-auto">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" (input)="dt2.filterGlobal($event.target.value, 'contains')" placeholder="Search Table" />
                                </span>
                            </div>
                        </ng-template>

                        <ng-template pTemplate="body" let-psa_info>
                            <tr [pSelectableRow]="psa_info">
                                <td>{{psa_info.Table}}</td>
                            </tr>
                        </ng-template>
                    </p-table>

                </div>
            </ng-template>
            <ng-template pTemplate>
                <div class="col flex align-items-center justify-content-center">

                    <p-table [columns]="psa_table_cols" [value]="psa_table_data" scrollDirection="both"
                    [resizableColumns]="true" columnResizeMode="expand" [scrollable]="true" scrollHeight="750px"
                    autoLayout="true"
                        >
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th pResizableColumn *ngFor="let col of columns">
                                    {{ col.name }}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr>
                                <td *ngFor="let col of columns">
                                    {{ rowData[col.ordinalPosition -1] }}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>

                </div>
            </ng-template>
        </p-splitter> -->

        <div class="p-grid" style="margin-top:15px;display: flex; align-items: center;">
            <span class="p-col" style="display: flex; align-items: center;">
                <h5 style="margin-bottom:0">
                    {{'Stream selection' | translate }}
                </h5>
                <span style="margin-left:5px"><app-info-tooltip></app-info-tooltip></span>
            </span>
            <span class="p-col-2" style="float:right;text-align:right">
                <button type="button" pButton [icon]="switchEditMode ? 'pi pi-arrow-right':'pi pi-pencil'"
                    [label]="switchEditMode ? 'Zurück':'Bearbeiten'" styleClass="p-button"
                    (click)="toggleStreamEdit()"></button>
            </span>
        </div>
        <!-- <div>
            <p-button (onClick)="editStreamsClicked($event)">Show Details</p-button>
        </div> -->


        <div class="card" *ngIf="!switchEditMode" style="background-color: white;">
            <div class="p-grid">

                <div class="p-col-5">

                    <p-table #dt1 [value]="catalog?.streams" (onRowSelect)="onStreamSelected($event)"
                        selectionMode="single" [(selection)]="selected_stream" dataKey="stream.name"
                        [tableStyle]="{'width':'100%', 'height':'100%'}" [paginator]="true" [rows]="5"
                        [globalFilterFields]="['stream.name']">
                        <!-- <ng-template pTemplate="caption">
                            <tr>
                                <th>1. Select Stream</th>
                            </tr>
                        </ng-template> -->
                        <ng-template pTemplate="caption">
                            <div class="flex">
                                <span>{{'1. Select Stream' | translate}}</span>

                                <span class="p-input-icon-left ml-auto" style="margin-left:10px">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text"
                                        (input)="dt1.filterGlobal($event.target.value, 'contains')"
                                        placeholder="{{'Search stream' | translate }}" />
                                </span>
                            </div>
                        </ng-template>

                        <ng-template pTemplate="body" let-stream_info>
                            <tr [pSelectableRow]="stream_info" [style]="{'font-size':'12px'}">
                                <td>{{stream_info.stream.name}}</td>
                            </tr>
                        </ng-template>
                    </p-table>

                </div>


                <div class="p-col" *ngIf="selected_stream" style="text-align: center; margin: auto;">
                    <img [alt]="'HeaderIcon'" src="assets/layout/images/arrow-right-circle.svg" width="75"
                        style="vertical-align: middle" />
                </div>


                <div class="p-col-5" *ngIf="selected_stream">

                    <p-table #dt2 [value]="selected_stream_psa_infos" [paginator]="true" [rows]="5"
                        (onRowSelect)="onPsaSelected($event)" selectionMode="single" [(selection)]="selected_psa"
                        dataKey="Table" [tableStyle]="{'width':'100%', 'height':'100%'}"
                        [globalFilterFields]="['Table']" pageLinkSize="3">
                        <!-- <ng-template pTemplate="header">
                            <tr>
                                <th>2. Select Table</th>
                            </tr>
                        </ng-template> -->
                        <ng-template pTemplate="caption">
                            <div class="flex">
                                <span>{{'2. Select Table' | translate}}</span>
                                <span class="p-input-icon-left ml-auto" style="margin-left:10px">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text"
                                        (input)="dt2.filterGlobal($event.target.value, 'contains')"
                                        placeholder="{{'Search table' | translate }}" />
                                </span>
                            </div>
                        </ng-template>

                        <ng-template pTemplate="body" let-psa_info>
                            <tr [pSelectableRow]="psa_info">
                                <td [style]="{'font-size':'12px'}">{{psa_info.Table}}</td>
                            </tr>
                        </ng-template>
                    </p-table>

                </div>
            </div>
        </div>

        <div class="card" *ngIf="switchEditMode">
            <app-update-streams-int-page></app-update-streams-int-page>
        </div>

        <div class="p-grid" *ngIf="selected_psa" style="margin-top:15px;display: flex; align-items: center;">
            <span class="p-col" style="display: flex; align-items: center;">
                <h5 style="margin-bottom:0">
                    {{'Table Preview' | translate }}
                </h5>
                <span style="margin-left:5px"><app-info-tooltip></app-info-tooltip></span>
            </span>
            <span class="p-col-2" style="text-align:right;display: flex;">
                <!-- <div *ngFor="let format of exportFormats">
                    <button type="button" pButton icon="pi pi-download" styleClass="p-button" label="{{format.Name}}"
                        (click)="onDownloadPsaClick(format)">
                    </button>
                </div> -->
            </span>
        </div>
        <div class="card" *ngIf="selected_psa">
            <div class="p-grid">
                <div class="p-col-12">
                    <p-table [columns]="psa_table_cols" [value]="psa_table_data" scrollDirection="both"
                        [resizableColumns]="true" columnResizeMode="expand" [scrollable]="true" scrollHeight="700px"
                        autoLayout="true" [loading]="isLoading" [paginator]="true" [rows]="50">
                        <ng-template pTemplate="caption">
                            <div class="p-grid" style="margin-top:10px;display: flex; align-items: center;">
                                <span class="p-col">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label">
                                            <input [disabled]=true type="text" id="finalUrlInputgroup" pInputText
                                                [(ngModel)]="finalUrl">
                                            <button type="button" icon="pi pi-copy" pButton pRipple
                                                label="{{'Copy' | translate}}" tooltipPosition="top"
                                                pTooltip="{{'CopyUrlToClipboard' | translate}}"
                                                (click)="textToClipboard(finalUrl)"></button>
                                            <label for="finalUrlInputgroup">{{'Full Url' | translate }}</label>
                                        </span>
                                    </div>
                                </span>
                                <span class="p-col-3" style="float: right">
                                    <div style="float: right;display: flex">
                                        <div *ngFor="let format of exportFormats" style="margin-left:5px">
                                            <button type="button" pButton icon="pi pi-file-export" styleClass="p-button"
                                                tooltipPosition="top" pTooltip="{{'Download as file' | translate }}"
                                                label="{{format.Name | translate }}"
                                                (click)="onDownloadPsaClick(format)">
                                            </button>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th pResizableColumn *ngFor="let col of columns"
                                    [style]="{'width':'10%', 'font-size':'12px'}">
                                    {{ col.name }}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr>
                                <td *ngFor="let col of columns" [style]="{'width':'10%', 'font-size':'12px'}">
                                    {{ rowData[col.ordinalPosition -1] }}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>

    </p-tabPanel>
    <!-- <p-tabPanel header="{{'Permissions' | translate }}">
        <app-user-permissions-list view="DatasourceView"></app-user-permissions-list>
    </p-tabPanel> -->
    <p-tabPanel header="{{'Permissions' | translate }}">

        <div class="p-grid" style="margin-top:15px;display: flex; align-items: center;">
            <span class="p-col" style="display: flex; align-items: center;">
                <h5 style="margin-bottom:0">
                    {{'Permissions' | translate }}
                </h5>
                <span style="margin-left:5px"><app-info-tooltip></app-info-tooltip></span>
            </span>
            <span class="p-col-5" style="float:right;text-align:right">
                <!-- <button type="button" pButton icon="pi pi-user-plus" label="{{'Save changes' | translate}}"
                    (click)="valueCheckClicked($event)">
                </button> -->

                <button type="button" pButton icon="pi pi-user-plus" label="{{'Save changes' | translate}}"
                    (click)="clickUpdatePermissionsComp()">
                </button>

            </span>
        </div>



        <app-role-permissions [model]="permissions_model" (selectionChanged)="onPermissionSelectionChanged($event)">

        </app-role-permissions>

        <!-- <div class="card">
            <div class="p-grid grid-nogutter">
                <div class="p-col-md-12 p-col-3 drag-column">
                    <div *ngFor="let product of pl_source_permissions">
                        <div class="product-item" pDraggable="permissions" (onDragStart)="permOnDragStart(product)"
                            (onDragEnd)="permOnDragEnd()">
                            <div class="image-container">
                            </div>
                            <div class="product-list-detail">
                                <h6 class="mb-2">{{product.Label}}
                                    <span style="float:right; color:grey">
                                        <p-chip *ngIf="product.Type === 'Group'" icon="pi pi-users"
                                            label="{{product.Type | translate}}"></p-chip>
                                        <p-chip *ngIf="product.Type === 'User' && product.image === undefined"
                                            icon="pi pi-user" label="{{product.Type | translate}}">
                                        </p-chip>
                                        <p-chip *ngIf="product.Type === 'User' && product.image !== undefined"
                                            [image]="product.image" label="{{product.Type | translate}}"></p-chip>
                                    </span>
                                </h6>
                                <p>{{product.RichRole?.EMail}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-col-md-12 p-col-9 drop-column" pDroppable="permissions" (onDrop)="permOnDrop()">
                    <p-table [value]="pl_target_permissions">
                        <ng-template pTemplate="header">
                            <tr>
                                <th></th>
                                <th>{{'Name' | translate }}</th>
                                <th>{{'Category' | translate }}</th>
                                <th>{{'IsOwner' | translate }}</th>
                                <th>{{'Rights' | translate }}</th>
                                <th></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-product>
                            <tr>
                                <td style="width: 4rem;"><p-badge *ngIf="checkState(product) === 'New'"
                                        value="{{'New' | translate }}"></p-badge></td>
                                <td>{{product.Label}}</td>
                                <td>
                                    <i *ngIf="product.Type === 'Group'" pTooltip="{{product.Type | translate}}"
                                        class="pi pi-users">
                                    </i>
                                    <i *ngIf="product.Type === 'User' && product.image === undefined"
                                        pTooltip="{{product.Type | translate}}" class="pi pi-user">
                                    </i>
                                    <i *ngIf="product.Type === 'User' && product.image  !== undefined"
                                        [image]="product.image"></i>
                                    {{product.Type | translate}}
                                </td>
                                <td>
                                    <i *ngIf="product.IsOwner === 'true'" pTooltip="{{product.IsOwner | translate}}"
                                        class="pi pi-check"></i>
                                    <i *ngIf="product.IsOwner === false" pTooltip="{{product.IsOwner | translate}}"
                                        class="pi pi-delete"></i>
                                </td>
                                <td>
                                    <span *ngFor="let category of rights" class="p-field-checkbox">
                                        <p-checkbox name="{{'perm_group_' + product.Role}}" [value]="category"
                                            [(ngModel)]="product.Rights" [inputId]="category">
                                        </p-checkbox>
                                        <label [for]="category">{{category | translate}}</label>
                                    </span>
                                </td>
                                <td style="width: 4rem;">
                                    <div class="ui-table-buttongroup-right">
                                        <button pButton pRipple type="button" icon="pi pi-trash"
                                            class="p-button-rounded p-button-text p-mr-2 p-mb-2"
                                            pTooltip="{{'Delete' | translate}}"
                                            (click)="dropPermissionClick(product)"></button>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div> -->

    </p-tabPanel>
    <!-- <p-tabPanel header="{{'Endpoints' | translate }}">
        <app-pipeline #objectPipeline [extensionUrl]="'dataSource/'"></app-pipeline>
    </p-tabPanel> -->

    <p-tabPanel header="{{'Settings' | translate }}">

        <div class="p-grid" style="margin-top:15px;display: flex; align-items: center;">
            <span class="p-col" style="display: flex; align-items: center;">
                <h5 style="margin-bottom:0">
                    {{'Datasource info' | translate }}
                </h5>
                <span style="margin-left:5px"><app-info-tooltip></app-info-tooltip></span>
            </span>
            <span class="p-col-2" style="float:right;text-align:right">
                <button type="button" pButton [icon]="editModeOn ? 'pi pi-save':'pi pi-pencil'"
                    [label]="editModeOn ? 'Speichern':'Bearbeiten'" styleClass="p-button"
                    (click)="onChangeEditMode()"></button>
            </span>
        </div>
        <div class="card">
            <div class="p-grid" style="margin:20px 0;" *ngIf="data_source_copy">
                <div class="p-col-12 p-fluid">
                    <span class="p-float-label">
                        <input pInputText id="UrlInputtext" [(ngModel)]="data_source_copy.Name"
                            [disabled]="!editModeOn" />
                        <label htmlFor="UrlInputtext">{{'DatasourceName' | translate}}</label>
                        <!-- <small id="inputtext">{{'Enter the non-empty datasource name.' | translate}}</small> -->
                    </span>
                </div>
                <!-- <div class="p-col-12 p-fluid">
                    <span class="p-float-label">
                        <p-dropdown [options]="writeModes" optionLabel="Name" optionValue="Name"
                            [disabled]="!editModeOn" id="selectedWritemodeInput" (onChange)="onInfoChanged()"
                            [(ngModel)]="data_source.writeMode"></p-dropdown>
                        <label for="selectedWritemodeInput">{{'WriteMode' | translate}}</label>
                    </span>
                </div> -->
                <div class="p-col-12 p-fluid">
                    <span class="p-float-label">
                        <textarea id="float-input" rows="5" cols="30" [(ngModel)]="data_source_copy.Description"
                            [disabled]="!editModeOn" pInputTextarea></textarea>
                        <label for="float-input">{{'Description' | translate}}</label>
                    </span>
                </div>
            </div>
        </div>

    </p-tabPanel>
</p-tabView>