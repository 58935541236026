import { mxCell } from "mxgraph";
import {
	mxWorkflowEdgeValue,
	mxWorkflowExtension,
	mxWorkflowGraph,
	mxWorkflowNodeValue,
	mxWorkflowPortValue,
} from "./mxWorkflowGraph";
import {
	WorkflowNodeInfo,
	WorkflowPlugInInfo,
} from "src/app/models/designer.models";
import { NodeStyleInfo } from "./node-styles";
import { Workflow, WorkflowNode, GuiSettings, WorkflowEdge } from "src/app/models/api/com/bion/etl/Workflow";


// Call mxGraph functions by casting object to any: (graph as any).function(arg1, arg2, ...)
// http://tutorialspots.com/typescript-how-to-fix-error-property-does-not-exist-on-type-6233.html

export class WorkflowGraphCodecBaseSettings {
	HoldOnError: boolean;
	constructor(holdOnError: boolean) {
		this.HoldOnError = holdOnError;
	}
}

// export class WorkflowGraphConversionSettings extends WorkflowGraphCodecBaseSettings {
//     NodeStyles: Map<string, NodeStyleInfo>;
//     NodeInfo: WorkflowNodeInfo[];
// }

export abstract class mxWorkflowCodecBase {
	BaseSettings: WorkflowGraphCodecBaseSettings;

	constructor(baseSettings: WorkflowGraphCodecBaseSettings) {
		this.BaseSettings = baseSettings;
	}

	logCallback?: (s: any) => void;

	protected log(message: any, is_error: boolean = false) {
		//console.log(message);
		if (this.BaseSettings.HoldOnError && is_error) throw new Error(message);
		else {
			if (this.logCallback !== undefined) this.logCallback(message);
		}
	}
}

export class mxWorkflowGraphEncoder extends mxWorkflowCodecBase {
	/**
	 * Encode a mxGraph workflow to a Workflow Engine workflow
	 * @param graph mxGraph Workflow
	 * @returns Workflow Engine Workflow
	 */
	encode(graph: mxWorkflowGraph): Workflow {



		const parent = (graph as any).getDefaultParent();
		const nodeCells = (graph as any).getWorkflowCells(parent);

		// Nodes
		const nodes = nodeCells.map((c) => this.cellToNode(c));

		// Edges
		const allPortCells = nodeCells.map((c) => mxWorkflowExtension.getPorts(c));
		const portCells = [].concat(...allPortCells);
		this.log(portCells);

		// Edges - Drop Duplicates
		const allPortEdges = portCells.map((pc) => graph["getEdges"](pc));
		const allPortEdgesFlat = [].concat(...allPortEdges);
		const portEdgeSet = new Set<mxCell>(allPortEdgesFlat);
		const portEdges = Array.from(portEdgeSet.values());

		const workflowEdges = portEdges.map((e) => this.edgeCellToWorkflowEdge(e));

		const workflow = new Workflow(graph.getName(), nodes, workflowEdges, graph.getContext());

		this.log(workflow);

		return workflow;


		// const workflow = new Workflow();

		// // const parent = graph.getDefaultParent();
		// const parent = (graph as any).getDefaultParent();
		// // const nodeCells = graph.getWorkflowCells(parent);
		// const nodeCells = (graph as any).getWorkflowCells(parent);

		// // Head
		// workflow.Name = graph.getName();
		// workflow.Context = graph.getContext();

		// // Nodes
		// const nodes = nodeCells.map((c) => this.cellToNode(c));
		// workflow.Nodes = nodes;

		// // Edges
		// const allPortCells = nodeCells.map((c) => mxWorkflowExtension.getPorts(c));
		// const portCells = [].concat(...allPortCells);
		// //this.log("port cells");
		// this.log(portCells);

		// // Edges - Drop Duplicates
		// // const allPortEdges = portCells.map(pc => graph.getEdges(pc));
		// const allPortEdges = portCells.map((pc) => graph["getEdges"](pc));
		// const allPortEdgesFlat = [].concat(...allPortEdges);
		// const portEdgeSet = new Set<mxCell>(allPortEdgesFlat);
		// const portEdges = Array.from(portEdgeSet.values());

		// const workflowEdges = portEdges.map((e) => this.edgeCellToWorkflowEdge(e));

		// workflow.Edges = workflowEdges;

		// this.log(workflow);

		// return workflow;
	}

	/**
	 * Converts an mxGraph cell with a node value to a Workflow Node.
	 * @param cell mxGraph Node cell
	 * @returns Workflow Node
	 */
	cellToNode(cell: mxCell): WorkflowNode {

		const cell_value = <mxWorkflowNodeValue>cell.getValue();

		const geo = cell.getGeometry();

		const gui_settings = new GuiSettings(
			geo.getPoint().x,
			geo.getPoint().y,
			geo.width,
			geo.height
		);

		return new WorkflowNode(cell.getId(),
			gui_settings,
			cell_value.Properties,
			cell_value.Engine,
			cell_value.Name);

		// OLD CODE

		// const node = new WorkflowNode();

		// node.Engine = cell_value.Engine;

		// const geo = cell.getGeometry();
		// const gui_settings = new GuiSettings(
		// 	geo.getPoint().x,
		// 	geo.getPoint().y,
		// 	geo.width,
		// 	geo.height
		// );
		// node.GuiSettings = gui_settings;

		// node.ID = cell.getId();
		// node.Name = cell_value.Name;
		// node.Properties = cell_value.Properties;

		// return node;

		// VERY OLD CODE

		// // get value
		// const node = <WorkflowNode>cell.getValue();
		// console.log(node);

		// // update node gui settings
		// const geo = cell.getGeometry();
		// const guiInfo = new GuiSettings();
		// guiInfo.X = geo.getPoint().x;
		// guiInfo.Y = geo.getPoint().y;
		// guiInfo.Width = geo.width;
		// guiInfo.Height = geo.height;
		// node.GuiSettings = guiInfo;
		// node.ID = cell.getId();

		// return node;
	}

	/**
	 * Converts an mxGraph edge to a Workflow Edge
	 * @param cell mxGraph edge cell
	 * @returns Workflow Edge
	 */
	edgeCellToWorkflowEdge(cell: mxCell): WorkflowEdge {
		const inPort = cell.getTerminal(true);
		const outPort = cell.getTerminal(false);
		this.log(inPort);
		this.log(outPort);
		this.log("In Cell is Port: " + mxWorkflowExtension.cellIsPort(inPort));
		this.log("Out Cell is Port" + mxWorkflowExtension.cellIsPort(outPort));

		const inNode = inPort.getParent();
		const outNode = outPort.getParent();

		this.log(inNode);
		this.log(outNode);

		// const inCellId = (<WorkflowNode>inNode.getValue()).ID;
		// const outCellId = (<WorkflowNode>outNode.getValue()).ID;
		const inCellId = inNode.getId();
		const outCellId = outNode.getId();
		const inPortName = (<mxWorkflowPortValue>inPort.getValue()).Name;
		const outPortName = (<mxWorkflowPortValue>outPort.getValue()).Name;

		// check label
		let edge_label: string = undefined;
		if (mxWorkflowExtension.cellIsWorkflowEdge(cell)) {
			const wf_edge = <mxWorkflowEdgeValue>cell.getValue();
			edge_label = wf_edge.Name;
		}

		// const workflowEdge = new WorkflowEdge();
		// workflowEdge.SourceNode = inCellId;
		// workflowEdge.SourcePort = inPortName;
		// workflowEdge.TargetPort = outPortName;
		// workflowEdge.TargetNode = outCellId;
		// workflowEdge.Name = edge_label;

		const workflowEdge = new WorkflowEdge(inCellId, inPortName, outCellId, outPortName, edge_label);

		return workflowEdge;
	}
}

export class WorkflowGraphDecodeSettings {
	constructor(
		nodeStyles: Map<string, NodeStyleInfo>,
		nodeInfo: WorkflowNodeInfo[]
	) {
		this.NodeStyles = nodeStyles;
		this.NodeInfo = nodeInfo;
	}

	NodeStyles: Map<string, NodeStyleInfo>;
	NodeInfo: WorkflowNodeInfo[];
}

export class mxWorkflowGraphDecoder extends mxWorkflowCodecBase {
	/**
	 * Converts a workflow graph to a mxWorkflowGraph
	 * @param workflow A Workflow
	 * @param container The mxGraph HTML container
	 * @returns The mxWorkflowGraph
	 */


	decode(
		workflow: Workflow,
		container: HTMLElement,
		settings: WorkflowGraphDecodeSettings,
		renderHint?: any
	): mxWorkflowGraph {
		const graph = new mxWorkflowGraph(container,undefined,renderHint);

		// Workflow
		graph.setName(workflow.Name);
		graph.setContext(workflow.Context);

		// -- update model
		// const model = graph.getModel();
		const model = graph["getModel"]();
		model.beginUpdate();
		try {
			// add nodes
			const keyedNodeInfo = new Map<string, WorkflowNodeInfo>();
			settings.NodeInfo.map((ni) => keyedNodeInfo.set(ni.Engine.Name, ni));
			// const parent = graph.getDefaultParent();
			const parent = (graph as any).getDefaultParent();
			// const parent = graph.["getDefaultParent"]();
			const mxNodes = workflow.Nodes.map((n) => {
				if (!keyedNodeInfo.has(n.Engine.Name))
					this.log("No Style available for " + n);

				const nodeInfo = keyedNodeInfo.get(n.Engine.Name);
				return this.insertWorkflowNode(graph, n, parent, nodeInfo, settings);
			});

			console.log(graph);

			console.log("Create Edges");

			// add edges
			const mxEdges = workflow.Edges.map((e) =>
				this.workflowEdgeToCell(e, graph, parent)
			);
		} finally {
			model.endUpdate();
		}

		return graph;
	}

	decodeToGraph(
		workflow: Workflow,
		settings: WorkflowGraphDecodeSettings,
		graph: mxWorkflowGraph
	): mxWorkflowGraph {

		// Workflow
		graph.setName(workflow.Name);
		graph.setContext(workflow.Context);

		// -- update model
		// const model = graph.getModel();
		const model = graph["getModel"]();
		model.beginUpdate();
		try {
			// add nodes
			const keyedNodeInfo = new Map<string, WorkflowNodeInfo>();
			settings.NodeInfo.map((ni) => keyedNodeInfo.set(ni.Engine.Name, ni));
			// const parent = graph.getDefaultParent();
			const parent = (graph as any).getDefaultParent();
			// const parent = graph.["getDefaultParent"]();
			const mxNodes = workflow.Nodes.map((n) => {
				if (!keyedNodeInfo.has(n.Engine.Name))
					this.log("No Style available for " + n);

				const nodeInfo = keyedNodeInfo.get(n.Engine.Name);
				return this.insertWorkflowNode(graph, n, parent, nodeInfo, settings);
			});

			console.log(graph);

			console.log("Create Edges");

			// add edges
			const mxEdges = workflow.Edges.map((e) =>
				this.workflowEdgeToCell(e, graph, parent)
			);
		} finally {
			model.endUpdate();
		}

		return graph;
	}

	/**
	 * Converts a Workflow Node to an mxCell
	 * @param graph Graph
	 * @param workflowNode Workflow Node
	 * @param parent Parent Cell
	 * @param nodeInfo Workflow Node Info for Style and Port settings
	 * @returns The Workflow Node mxCell
	 */
	insertWorkflowNode(
		graph: mxWorkflowGraph,
		workflowNode: WorkflowNode,
		parent: mxCell,
		nodeInfo: WorkflowPlugInInfo,
		settings: WorkflowGraphDecodeSettings
	): mxCell {
        let label = "";
        if (workflowNode.Properties.Annotation !== undefined)
			label = workflowNode.Properties.Annotation.Text;

		// Determine Node Style
		const defaultNodeStyle =
		"rounded=1;whiteSpace=wrap;html=1;shadow=1;labelPosition=center;verticalLabelPosition=bottom;align=center;verticalAlign=top;fontStyle=1;imageAlign=center;imageWidth=30;imageHeight=30;fillColor=#99CCFF;strokeColor=#88B6E3;";
		//const nodeStyleKey = this.translate.instance(nodeInfo.Name);
		const nodeStyleKey = nodeInfo.Name;

		const nodeStyles = settings.NodeStyles;

        let finalNodeStyle = defaultNodeStyle;
        if (nodeStyles.has(nodeStyleKey)) {
			this.log("Custom Node style found for node with key: " + nodeStyleKey);
			finalNodeStyle = nodeStyles.get(nodeStyleKey).style;
		} else {
			this.log(
				"Node style not found for " + nodeStyleKey + ". Using default Style"
			);
		}
		this.log(finalNodeStyle);

		// // Insert Vertex: ID is null (auto generated by mxGraph). TODO: add 2nd ID for workflow edges
		// const gui = workflowNode.GuiSettings;
		// const id = workflowNode.ID;
		// // const v1 = graph.insertVertex(parent, id, label, gui.X, gui.Y, gui.Width, gui.Height, finalNodeStyle);
		// // const v1 = graph.["insertVertex"](parent, id, label, gui.X, gui.Y, gui.Width, gui.Height, finalNodeStyle);
		// const v1 = (graph as any).insertVertex(parent, id, label, gui.X, gui.Y, gui.Width, gui.Height, finalNodeStyle);
		// v1.setConnectable(false);

		// // Presets the collapsed size
		// v1.geometry.alternateBounds = new mx.mxRectangle(0, 0, 120, 40);

		const v1 = mxWorkflowExtension.insertWokflowNode(
			graph,
			parent,
			workflowNode,
			nodeInfo.Ports,
			finalNodeStyle
		);

		this.log("Settings new generated id to workflow node object: " + v1.getId());
		workflowNode.ID = v1.getId();


		    // // Create an icon for the mxCell
			// const icon = new mxImage("icon.png", 30, 30);
			// const iconStyle = "image;imageAspect=0;image=" + icon.src + ";imageWidth=30;imageHeight=30";
			// v1.insertVertex(
			// 	v1,
			// 	"icon",
			// 	"",
			// 	0,
			// 	0,
			// 	30,
			// 	30,
			// 	iconStyle
			// );



		// Create & add the ports at various relative locations
		const inPorts = mxWorkflowExtension.createPorts(v1, nodeInfo, graph, true);
		const outPorts = mxWorkflowExtension.createPorts(
			v1,
			nodeInfo,
			graph,
			false
		);
		const ports = inPorts.concat(outPorts);
		this.log(ports);

		ports.forEach((p) => (graph as any).addCell(p, v1));

		// Add the workflow node value
		// v1.value = workflowNode;

	// 		// Create an editable textbox for the mxCell
	// const editableStyle = "resizable=0;editable=1;autosize=1;html=1;labelBackgroundColor=#ffffff;overflow=hidden;align=center;verticalAlign=middle;whiteSpace=wrap;";
	// //const geo = new mxGeometry(0, 30, 120, 40);
	// const editable = new mxCell("Default Text", undefined, editableStyle);
	// editable.vertex = true;
	// (graph as any).addCell(editable, v1);

	 	return v1;
	}

	/**
	 * Connects the port cells based on the workflow edge. The ports must exist at this point.
	 * @param workflowEdge Workflow Edge
	 * @param graph graph containing the nodes and their ports as mxCells.
	 */
	workflowEdgeToCell(
		workflowEdge: WorkflowEdge,
		graph: mxWorkflowGraph,
		parent: mxCell
	): mxCell {
		// Find Node Cells
		const sourceNodes = graph.lookupWorkflowNode(
			workflowEdge.SourceNode,
			parent
		);
		if (sourceNodes.length === 0)
			this.log("No In Node Cell found for Node ID " + workflowEdge.SourceNode);

		const targetNodes = graph.lookupWorkflowNode(
			workflowEdge.TargetNode,
			parent
		);
		if (targetNodes.length === 0)
			this.log("No In Node Cell found for Node ID " + workflowEdge.TargetNode);

		// Find Port Cells
		const sourceNode = sourceNodes[0];
		const sourceNodePort = this.findTargetPortCell(
			sourceNode,
			workflowEdge.SourcePort
		);

		const targetNode = targetNodes[0];
		const targetNodePort = this.findTargetPortCell(
			targetNode,
			workflowEdge.TargetPort
		);

		// Create the Edge
		// const edge = graph.insertEdge(parent, null, null, sourceNodePort, targetNodePort);
		// const edge = graph.["insertEdge"](parent, null, null, sourceNodePort, targetNodePort);

		let edge_value: mxWorkflowEdgeValue = undefined;
		if (workflowEdge.Name === undefined) {
			edge_value = null;
		} else {
			edge_value = new mxWorkflowEdgeValue();
			edge_value.Name = workflowEdge.Name;
		}

		const edge = (graph as any).insertEdge(
			parent,
			null,
			edge_value,
			sourceNodePort,
			targetNodePort
		);

		return edge;
	}

	/**
	 * Lookup the specific port cell in the given node cell.
	 * @param nodeCell Node Cell to lookup
	 * @param portName Port Name
	 * @returns The Port Cell
	 */
	findTargetPortCell(nodeCell: mxCell, portName: string): mxCell {
		const outNodePorts = mxWorkflowExtension.getPorts(nodeCell);
		const outPortInfos = outNodePorts.filter(
			(c) => (<mxWorkflowPortValue>c.getValue()).Name == portName
		);
		if (outPortInfos.length == 0) {
			throw new Error("No Port Found for Port with ID " + portName);
		}

		if (outPortInfos.length > 1) {
			throw new Error("Multiple ports found for port ID " + portName);
		}

		return outPortInfos[0];
	}
}
