import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AuthConfig } from "angular-oauth2-oidc";
import { IntegratedSourceModel } from "src/app/models/api/models/IntegratedSourceModel";
import { AuthInfo } from "src/app/views/general/oauth2-flow/oauth2-flow.component";

/**
 * Konnektor Konfigurationsinformationen und Typklassen für OAUth2-Token-Abgriff.
 */
export namespace ConnConfig {
    export namespace Google {
        export interface ClientAuth {
            auth_type: string;
            client_id: string;
            client_secret: string;
            refresh_token: string;
        }

        export interface ServiceAuth {
            auth_type: string;
        }

        export interface Config {
            spreadsheet_id: string;
            credentials: ClientAuth | ServiceAuth;
            row_batch_size?: number;
            names_conversion?: boolean;
        }

        export class GoogleRead implements Read<ConnConfig.Google.Config> {
            read(config: Config): AuthConfig {

                const c = this.assertConfig(config);
                const at: Google.ClientAuth = <Google.ClientAuth>c.credentials;

                // den 'scope' parameter können wir auch auf die Read-Variante setzen, da wir nichts ändern müssen.
                console.warn("Achtung, Redirect-Uri wird hier nicht gesetzt, das muss die Mutter machen");

                const auth_config: AuthConfig = {
                    // clientId: "499702120556-djkihbu3igks5b5o3q4kj0ckaua453t6.apps.googleusercontent.com",
                    // dummyClientSecret: "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",  // Google mal wieder, nicht standard Konform
                    clientId: at.client_id,
                    dummyClientSecret: at.client_secret,
                    loginUrl: "https://accounts.google.com/o/oauth2/v2/auth",
                    // scope: "openid profile",
                    scope: "https://www.googleapis.com/auth/drive",  // Minimal ... works?
                    //scope : "openid profile email https://www.googleapis.com/auth/drive",  // works
                    //scope : "offline_access",
                    // responseType: "token",
                    responseType: "code",
                    //redirectUri: "http://localhost:4200",
                    //redirectUri: redirectUri,  // Die Redirect-Uri wird vom aufrufenden Code gesetzt
                    requireHttps: false,
                    oidc: false,
                    issuer: "https://accounts.google.com",     // Auto Endpoint Info
                    strictDiscoveryDocumentValidation: false   // for google, see: https://manfredsteyer.github.io/angular-oauth2-oidc/docs/additional-documentation/using-an-id-provider-that-fails-discovery-document-validation.html
                }

                return auth_config;
            }

            validate(config: Config): string[] {
                const errors: string[] = [];

                if (config.credentials === undefined) {
                    const msg = "No credentials given. Please select OAuth and set 'Authenticate via Google (OAuth)' to 'Client'";
                    errors.push(msg);
                } else {
                    if (config.credentials.auth_type != 'Client') {
                        const msg = "The attribut 'credentials.auth_type' must be 'Client' but is '" + config.credentials.auth_type + "'."
                        errors.push(msg);
                    } else {
                        const at: Google.ClientAuth = <Google.ClientAuth>config.credentials;
                        if (at.client_id === undefined) {
                            errors.push("'Client ID' must be set");
                        }
                        if (at.client_secret === undefined) {
                            errors.push("'Client Secret' must be set");
                        }
                    }
                }

                return errors;
            }

            assertConfig(config: Config): Config {
                const errors = this.validate(config);
                if (errors.isEmpty()) {
                    return config;
                } else {
                    const error_text = errors.reduce((a, b) => a + ", " + b);
                    const msg = "The OAuth Configuration contains errors: " + error_text;
                    throw new Error(msg);
                }
            }
        }

        export class GoogleWrite implements Write<ConnConfig.Google.Config> {

            patchForm(form: FormGroup<any>, info: AuthInfo): FormGroup<any> {

                form.get(['credentials', 'refresh_token']).patchValue(info.RefreshToken);

                return form;
            }
            write(config: Config, info: AuthInfo): Config {

                const at: Google.ClientAuth = <Google.ClientAuth>config.credentials;

                at.refresh_token = info.RefreshToken;

                return config;
            }

        }
    }
}

/**
 * Unterstützt die Refresh-Token Abholung für OAuth Konnektoren
 * 
 * Typklasse
 * @param C Konnektor Konfiguration
 */
export interface OAuth2Info<C> {
    Read: Read<C>;
    Write: Write<C>;
    /**
     * Zusätzliche Parameter, z.B. für Google.
     */
    QueryParams?: object;
}

/**
 * Liest die Auth-Config aus der Konnektor-Konfig
 */
export interface Read<T> {
    /**
     * Liest die AuthConfig-Parameter für die angular-oauth2-oidc Library aus der Konnektor-Konfiguration
     * @param config Konnektor Konfiguration
     */
    read(config: T): AuthConfig
    /**
     * Überprüft, ob die Konfiguration alle Attribute zum Token-Abriff enthält und liefert die Fehler, falls nicht. 
     * @param config Konnektor Konfiguration
     */
    validate(config: T): string[];
}

/**
 * Schreibt die Auth bzw. Token Informationen zurück in die Konnektor-Konfig.
 * Die Funktion 'patchForm' kann die Konfig direkt im Formular patchen, das ist nützlich, da wir Formly verwenden.
 */
export interface Write<T> {
    /**
     * Aktualisiert die Konfiguration auf Basis der Token-Informationen
     * @param config 
     * @param info 
     */
    write(config: T, info: AuthInfo): T
    /**
     * Aktualisiert das Formular auf Basis der Token-Informationen
     * @param form Formular
     * @param info Token-Informationen
     */
    patchForm(form: FormGroup<any>, info: AuthInfo): FormGroup<any>
}


/**
 * Bietet Informationen und Typklassen zur Interaktion der Konnektor Konfiguration und der OAuth2-Bibliothek
 */
@Injectable({
    providedIn: "root",
})
export class OAuth2FlowService {
    /**
     * Liefert die OAuth2 Informationen.
     * @returns OAuth2 Informationen
     */
    getAuthInfo(): Array<[IntegratedSourceModel.ConnectorKey<string>, OAuth2Info<any>]> {

        const infos: Array<[IntegratedSourceModel.ConnectorKey<string>, OAuth2Info<any>]> = [];

        const google_source_def = "71607ba1-c0ac-4799-8049-7f4b90dd50f7";
        const google_source_key = new IntegratedSourceModel.ConnectorKey<string>(google_source_def, "Airbyte");
        const google_drive_read = new ConnConfig.Google.GoogleRead();
        const google_drive_write = new ConnConfig.Google.GoogleWrite();
        const google_drive_query_params = {
            'access_type': 'offline',               // refresh token
            'include_granted_scopes': 'true',
            'prompt': 'consent'                     // Enforce user prompt and refresh-token otherwise RT is only returned the first time
        }

        const google_drive_info: OAuth2Info<ConnConfig.Google.Config> = {
            Read: google_drive_read,
            Write: google_drive_write,
            QueryParams: google_drive_query_params
        }

        infos.push([google_source_key, google_drive_info]);


        return infos;
    }
}