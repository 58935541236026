import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { JsonConnectorView } from 'src/app/models/connectors/json-connector-view.model';
import { DataSourceAdapter } from 'src/app/models/datasource.model';
import { ApiBackendService } from 'src/app/services/api-backend.service';

@Component({
  selector: 'app-json-connector',
  templateUrl: './json-connector.component.html',
  styleUrls: ['./json-connector.component.scss']
})
export class JsonConnectorComponent implements OnInit {
  selectedConnector: DataSourceAdapter;

  loading: boolean;
  @Input() currentConnectorView: JsonConnectorView;
  @Input() isChangeSettingsOnlyFlag: boolean = false;

  // readModeOptions: Array<string>;
  selectedReadMode: string;
  // readMode: string;
  // NestedObjectsMode: string;
  // NestedObjectsModeOptions: string[];
  selectedNestedObjectsMode: string;
  // UseQuery: boolean;
  // //selectedUseQuery: boolean;
  // Query: string;

  jsonPreviewCols: any;
  jsonPreviewData: any;

  jsonHierarchy: TreeNode[];
  selectedJsonHierarchy: TreeNode;


  constructor(private bionApi: ApiBackendService, private cd: ChangeDetectorRef, private http: HttpClient) { }

  ngOnInit(): void {
    this.currentConnectorView.metaAccess.JsonPreview
  }


  uploadFilesAsync(event) {

    let input = event.files;
    let firstFile = input[0];
    this.currentConnectorView.uploadFile(firstFile);
    this.currentConnectorView.fetchMetaDataObs().subscribe(
      (res) => {

        this.currentConnectorView.metaInfo = res;

        console.log('JSON META:', res);

        if (res.Json) {

          // TODO: check result and convert to array if needed.
          // Object -> Convert
          // Array -> Leave

          const tree_data = <TreeNode[]>[res.Json];
          this.jsonHierarchy = tree_data;

          const new_tree_data = this.toTreeNodes(res.Json);
          console.log(new_tree_data);
          this.jsonHierarchy = new_tree_data;
        }


        this.loading = false;

      },
      (error) => {
        // this.messageService.standardToastMessage({  severity : 'warning',
        //                                             summary : 'Could not fetch meta data',
        //                                             detail : error});
        this.loading = false;
      }
    )
  }
  onRemoveFile(event) {
    console.log(event);
  }

  toTreeNodes(value: any): TreeNode[] {

    if (value instanceof Object) return this.toTreeNodeObj(<Object>value);

    if (value instanceof Array) return this.toTreeNodeArray(<Array<any>>value);

    throw new Error("Only objects and arrays are supported");
  }

  toTreeNodeObj(value: Object) {

    let nodes = new Array<TreeNode>();

    for (let attr in value) {

      const attr_value = value[attr];

      let children = undefined;
      try {
        children = this.toTreeNodes(attr_value);
      } catch (e) {
        console.log("No more sub tree -> stopping!");
      }


      const node = {
        label: attr,
        data: attr_value,
        expandedIcon: "pi pi-folder-open",
        collapsedIcon: "pi pi-folder",
        children: children
      }

      nodes.push(node);

    }

    return nodes;
  }

  toTreeNodeArray(value: Array<any>): TreeNode[] {

    let nodes = new Array<TreeNode>();

      let i = 0;
      const len = value.length;
      for (; i < len; i++) {

      const v = value[i];
      const children = this.toTreeNodes(v);

      const new_label = i.toString();

      const node = {
        label: new_label,
        data: v,
        expandedIcon: "pi pi-folder-open",
        collapsedIcon: "pi pi-folder",
        children: children
      }

      nodes.push(node);
    }

    return nodes;
  }

  setJsonView() {
    // this.currentConnectorView.connectorSettings.UseQuery = this.UseQuery;
    this.currentConnectorView.connectorSettings.ReadMode = (this.selectedReadMode === undefined) ? undefined : this.selectedReadMode;
    //this.currentConnectorView.connectorSettings.Query = this.Query;
    this.currentConnectorView.connectorSettings.NestedObjectsMode = (this.selectedNestedObjectsMode === undefined) ? undefined : this.selectedNestedObjectsMode;

    console.log(this.currentConnectorView);
  }

  onTreeTest(event: PointerEvent) {
    console.log(event);
    this.getFiles().subscribe(data => {
      const tree_data = <TreeNode[]>data.data;
      this.jsonHierarchy = tree_data;
    })
  }

  onNodeSelect(event: PointerEvent) {
    console.log(event);

    const path = this.makeJsonPathFromEvent(event);
    console.log(path);

    const reverse_path = path.reverse();      // Query needs TOP DOWN path

    const query = this.makeQueryFromPath(reverse_path);
    console.log(query);

    this.currentConnectorView.connectorSettings.Query = query;
  }

  /**
   * Builds a query string from the given node path.
   * @param nodes Nodes in path. Must be given in TOP DOWN order.
   * @returns The query string
   */
  makeQueryFromPath(nodes:Array<TreeNode>) : string {

    const node_part = nodes.map(n => "." + n.label).reduce((a,b) => a + b);

    const query = "$" + node_part + "[*]";

    return query;
  }

  /**
   * Creates the BOTTOM-UP Path from a clicked node
   * @param event Event
   * @returns The path from the clicked node to the root
   */
  makeJsonPathFromEvent(event: PointerEvent) : TreeNode[] {

    const start_node = <TreeNode> event["node"];
    console.log(start_node);

    return this.makeJsonPathFromNode(start_node);
  }

    /**
   * Creates the BOTTOM-UP Path from a node
   * @param event Event
   * @returns The path from the node to the root
   */
  makeJsonPathFromNode(node:TreeNode) : TreeNode[] {

    if(node == undefined)
      return new Array<TreeNode>();
    else {
      const arr = new Array<TreeNode>(node);
      return arr.concat(this.makeJsonPathFromNode(node.parent));
    }
  }

  getFiles() {
    return this.http.get<any>('./assets/data/testdata.json');
  }
}
