import { Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { forkJoin } from "rxjs/internal/observable/forkJoin";
import { AppMainComponent } from "src/app/app.main.component";
import { FileUploadEvents } from "src/app/helper/events";
import { FileTools } from "src/app/helper/fileReader";
import { Id } from "src/app/helper/id";
import { Model } from "src/app/models/api/models/license/model/Model";
import { UserDetailsRow, UserInfoView } from "src/app/models/user.model";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { UserService } from "src/app/services/user.service";
import { UtilFunctionsService } from "src/app/services/util-functions.service";
import { SubSink } from "subsink";



@Component({
    selector: "app-user-profile",
    templateUrl: "./user-profile.component.html",
    styleUrls: ["./user-profile.component.scss"],
    providers: [MessageService]
})
export class UserProfileComponent implements OnInit, OnDestroy {
    subs = new SubSink;
    currentUser?: UserInfoView;
    currentLicenses: Model.Subscription[] = [];

    inputDisabled: boolean = true;
    buttonText: string = "Edit";

    currentUserDetails: UserDetailsRow[] = [];
    image: any = undefined;

    constructor(
        private userService: UserService,
        private appMain: AppMainComponent,
        private systemLogService: SystemMessageLogService,
        private messageService: MessageService,
        private utilService: UtilFunctionsService,
        private translate: TranslateService

    ) { }
    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    ngOnInit(): void {
        this.initUserProfile();
    }

    initUserProfile() {
        let currentUserObs = this.userService.getUserInfo();
        let allUserDetailsObs = this.userService.getUserDetailsRow();

        this.subs.sink = forkJoin(currentUserObs, allUserDetailsObs).subscribe((res) => {
            const userInfo = res[0];
            const details = res[1];
            const myUserDetails = details.filter((detail) => { return detail.id === userInfo.ID});

            let licenses = this.appMain.currentUserFull?.Licenses;

            console.log(userInfo);

            let currentUserView = new UserInfoView();
            currentUserView.userInfo = userInfo;

            this.currentUser = currentUserView;
            this.currentLicenses = licenses ? licenses : [];
            console.log(this.currentLicenses);
            this.currentUserDetails = myUserDetails;

            if (myUserDetails[0] && myUserDetails[0].avatar) {
                this.image = this.utilService.int8ArrayToBase64Image(myUserDetails[0].avatar);
            }
        },
            (err: Error) => {
                this.systemLogService.handleError(err);
            })
    }

    onClickEdit() {
        if (this.inputDisabled) {
            this.inputDisabled = false;
            this.buttonText = "Save";
        } else {
            this.saveUserInformation();
        }
    }

    displayChangePasswordDialog() {
        this.userService.displayChangeMyPassword.emit(true);
    }

    onUploadAvatar(event: FileUploadEvents.OnUpload<any>) {
        try {
            this.uploadAvatar(event)
        } catch(e) {
            this.systemLogService.handleError(<Error>e);
        }
    }

    uploadAvatar(event: FileUploadEvents.OnUpload<any>) {

        const current_user = Id.assertSet(this.currentUser, new Error("Please select a user account"))
        //console.log(event);
        let input = event.files;
        let file = input[0];

        console.log(file);

        const file_obs = FileTools.readFileAsync(file);
        file_obs.subscribe((res) => {
            console.log(res);

            let newUserDetailsRow = undefined;

            if (this.currentUserDetails.length === 0) {
                newUserDetailsRow = new UserDetailsRow(current_user.userInfo.ID);
            } else {
                newUserDetailsRow = { ...this.currentUserDetails[0] }
            }

            console.log("currentUserDetails", this.currentUserDetails);

            let byteArray = this.base64ToArrayBuffer(res);
            let avatarBytes = Array.from(byteArray);

            newUserDetailsRow.avatar = avatarBytes;

            console.log("userDetailsRow", newUserDetailsRow);

            this.subs.sink = this.userService.createUserDetailsRow(newUserDetailsRow).subscribe(result => {
                console.log(result);

                this.image = this.utilService.int8ArrayToBase64Image(avatarBytes);

            }, (err) => {
                this.systemLogService.handleError(err);
            })
        });


    }

    base64ToArrayBuffer(base64:string) {
        const binary_string = window.atob(base64);
        const len = binary_string.length;
        const bytes = new Int8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes;
    }

    saveUserInformation() {

        if (this.currentUser) {

            const user = { ...this.currentUser };
            const userInfo = { ...user.userInfo };

            this.subs.sink = this.userService.updateUser(userInfo).subscribe((res) => {

                this.messageService.add({
                    severity: "success",
                    summary: this.translate.instant("Message.UpdateProfileSuccess.Title"),
					detail: this.translate.instant("Message.UpdateProfileSuccess.Text"),
                    //summary: "Profile updated!",
                    // detail:
                    //     "Your profile update will take effect with your next login",
                });
                this.inputDisabled = true;
                this.buttonText = "Edit";

            },
                (err: Error) => {
                    this.systemLogService.handleError(err);
                });
        } else {
            this.systemLogService.handleError(new Error("No User selected"));
        }
    }
}
