import { Component, OnInit } from '@angular/core';
import { FieldInfo, WorkflowNodeSettings } from 'src/app/models/api/com/bion/etl/Workflow';
import { EncryptionInfo, EncryptionSettings } from 'src/app/models/nodeSettings.model';
// import { FieldInfo, WorkflowNodeSettings } from 'src/app/models/workflow.model';
import { DesignerService } from 'src/app/services/designer.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { NodeConfigComponentBase } from '../node-config-component-base';

export class EncryptInfoView {
    ColumnName: string;
    Mode: string;
    DropInputCol: boolean;
    Key: string;
    GenerateKey: boolean;
    EncryptStatusCol?: string;
    hasError?: boolean;

    constructor(ColumnName: string, Mode: string, DropInputCol: boolean, Key: string, GenerateKey: boolean, EncryptStatusCol?: string, hasError?: boolean) {
        this.ColumnName = ColumnName;
        this.Mode = Mode;
        this.DropInputCol = DropInputCol;
        this.Key = Key;
        this.GenerateKey = GenerateKey;
        this.EncryptStatusCol = EncryptStatusCol;
        this.hasError = hasError;
    }
}

@Component({
  selector: 'app-encrypt-node',
  templateUrl: './encrypt-node.component.html',
  styleUrls: ['./encrypt-node.component.scss']
})
export class EncryptNodeComponent extends NodeConfigComponentBase<EncryptionSettings>  implements OnInit {
    settingsToView(settings: WorkflowNodeSettings): void {
        // -- Get Configuration
        let currentConfig = <EncryptionSettings>settings;
        // -- Get Input Meta Data
        const meta_infos =
            this._currentWorkflowNodeAdapter.IWorkflowNode.Properties.MetaInfo.get(
                this.InputPort
            );
        let columnListArray: string[] = [];

        // -- Initiliaize Modus options
        // this.encriptionMethods = [
        //     "Encrypt","Decrypt",
        // ];
        // -- create default object
        //let encryptInfo = new EncryptInfoView("",this.encriptionMethods[0],false,"",false,"",false);
        // encryptInfo.GenerateKey = false;
        // encryptInfo.DropInputCol = false;
        // encryptInfo.ColumnName = "";
        // encryptInfo.EncryptStatusCol = "";
        // encryptInfo.Mode = this.encriptionMethods[0];
        // encryptInfo.Key = "";
        // encryptInfo.hasError = false;

        //this.encryptInfo = encryptInfo;
        // -- check for MetaInfos from Input Port & store dsField list
        if (meta_infos !== undefined && meta_infos.length > 0) {
            const input_field_infos = meta_infos[0].FieldsInfo;

            // -- create string array for column dropdown
            columnListArray = input_field_infos.map((fieldInfo) => {
                return fieldInfo.Name;
            });
            this.columnList = columnListArray;
        }
        // -- if no configuration exists, exit
        if (currentConfig === undefined) {
            // -- if undefined, exit
            return;
        } else {
            // -- otherwise load from existing config
            if (!currentConfig.EncryptionInfos || currentConfig.EncryptionInfos.length === 0) return;

            // -- if given, check infos for columns
            currentConfig.EncryptionInfos.map((info) => {
                if (columnListArray.includes(info.ColumnName)) {
                    // -- column still exists, do nothing
                } else {
                    // column does not exist anymore, meaning column name currently not included -> include
                    columnListArray.push(info.ColumnName);
                }
            });
            this.columnList = columnListArray;

            if(meta_infos !== undefined) {
                let field_infos = meta_infos[0].FieldsInfo;

                if(field_infos === undefined) return ;

                this.encryptInfo = this.infoToView(currentConfig, field_infos);
            } else {
                this.encryptInfo = this.infoToView(currentConfig);
            }



    }}
    viewToSettings(): EncryptionSettings {
        const newSettings = <EncryptionSettings>{
            ...this.getCurrentWorkflowNode().Properties.Configuration,
        };
        let newInfo = new EncryptionInfo();
        newInfo.ColumnName = this.encryptInfo.ColumnName;
        newInfo.Mode = this.encryptInfo.Mode;
        newInfo.EncryptStatusCol = this.encryptInfo.EncryptStatusCol;
        newInfo.GenerateKey = this.encryptInfo.GenerateKey;
        newInfo.Key = this.encryptInfo.Key;
        newInfo.DropInputCol = this.encryptInfo.DropInputCol;

        newSettings.EncryptionInfos = [...[newInfo]];

        return newSettings;
    }
    onSettingsChanged(settings: EncryptionSettings): void {
        throw new Error('Method not implemented.');
    }

    readonly InputPort = "Input";
    columnList: string[] = [];
    encriptionMethods: string[] = ["Encrypt","Decrypt",];
    encryptInfo: EncryptInfoView = new EncryptInfoView("",this.encriptionMethods[0],false,"",false,"",false);


    constructor(
        protected workflowService: WorkflowsService,
        protected designerService: DesignerService
    ) {
        super(workflowService, designerService);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }
    generateKey() {
        this.encryptInfo.GenerateKey = true;
        console.log(this.encryptInfo);

        this.onUpdateSettings(false);
    }
    clipboardContent: string = "";
    copyToClipboard(key: string) {
        const dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = key;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        this.clipboardContent = dummy.value;
    }
    pasteToClipboard() {

    }
    infoToView(config: EncryptionSettings, meta?: FieldInfo[]): EncryptInfoView {
        //let view: EncryptInfoView = new EncryptInfoView();

        // -- create selected EncryptInfo from config
        const ColumnName = config.EncryptionInfos[0].ColumnName;
        const Mode = config.EncryptionInfos[0].Mode;
        const DropInputCol = config.EncryptionInfos[0].DropInputCol;
        const EncryptStatusCol = config.EncryptionInfos[0].EncryptStatusCol;
        const Key = config.EncryptionInfos[0].Key;
        const GenerateKey = false;

        let hasErrorFlag;

        if(meta) {
            let fieldInfoFound = meta.find((field) => {
                return field.Name === config.EncryptionInfos[0].ColumnName;
            });

            hasErrorFlag = !fieldInfoFound;
        } else {
            hasErrorFlag = false;
        }

        let view: EncryptInfoView = new EncryptInfoView(ColumnName,Mode,DropInputCol,Key, GenerateKey,EncryptStatusCol,hasErrorFlag);

        return view;
    }
    onFocusLost(event: FocusEvent) {
        const div_left: boolean = (event.relatedTarget === null);

        console.log("Send from Div: ", div_left);

        if (div_left) {
            //this.onCheckDirtyFlag();
            this.onUpdateSettings(true);
        }
    }

}
