import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AppMainComponent } from './app.main.component';
// import { JobStatusInfo } from './models/staging/LoadDataModel';
import { UserService } from './services/user.service';
import { UtilFunctionsService } from './services/util-functions.service';

@Component({
    selector: 'app-menu',
    styleUrls: ['app.menu.component.scss'],
    //styles: ['.highlight { background-image: linear-gradient(to right, #00b4a3 , #0494c5); border-radius: 15px; opacity: 0.65}'],
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
    model: any[];
    model2: any[];
    selectedItem: any;
    image: any;
    selectedLanguage: string;
    constructor(public appMain: AppMainComponent, private translate: TranslateService, private router: Router, public utilService: UtilFunctionsService, private userService: UserService,) { }

    ngOnInit() {

        this.model2 = [
            {
                label: this.translate.instant('User management'), icon: 'pi pi-users', count: 2, routerLink: ['/personal/users'],
            },
            {
                label: this.translate.instant('Logout'), icon: 'pi pi-sign-out', count: 2, command: () => { this.appMain.logout() },
            },];
        this.model = [
            // {
            //     label: 'Planner', icon: 'pi pi-sitemap', count: 2, routerLink: ['/planner'],
            // },
            {
                label: this.translate.instant('Home'), icon: 'pi pi-home', count: 2, routerLink: [''],
            },
            {
                label: this.translate.instant('Designer'), icon: 'pi pi-images', count: 2, routerLink: ['/designer'],
            },
            {
                label: this.translate.instant('Datasources'), icon: 'pi pi-database', count: 2, routerLink: ['/SourceIntegrationSources'],
            },
            {
                label: this.translate.instant('Workflows'), icon: 'pi pi-share-alt', count: 2, routerLink: ['/workflows'],
            },
            {
                label: this.translate.instant('Destinations'), icon: 'pi pi-th-large', count: 2, routerLink: ['/destinations'],
            },
            // {
            //     label: this.translate.instant('Scheduler'), icon: 'pi pi-calendar', count: 2, routerLink: ['/schedules'],
            // },
            // {
            //     label: this.translate.instant('Reports'), icon: 'pi pi-chart-line', count: 2, routerLink: ['/reports'],
            // },
            // {
            //     label: this.translate.instant('Airbyte'), icon: 'pi pi-chart-line', count: 2, routerLink: ['/testAirbyteViewMenu'],
            // }, 
            // == Datasources Int war ein experimenteller Eintrag für Tests. Er hat keine Funktion mehr
            // {
            //     label: this.translate.instant('Datasources Int'), icon: 'pi pi-chart-line', count: 2, routerLink: ['/createDataSourceIntBeta'],
            // },
            // == Data Source Int Pages is now useless, the Data Source Dialog will handle this step.
            // {
            //     label: this.translate.instant('Datasources Int Pages'), icon: 'pi pi-chart-line', count: 2, routerLink: ['/SourceIntegrationChooseConnector'],
            // },
            // {
            //     label: this.translate.instant('Datasources Int All'), icon: 'pi pi-chart-line', count: 2, routerLink: ['/SourceIntegrationSources'],
            // },

            // {
            //     label: 'Marketplace', icon: 'pi pi-shopping-cart', count: 2, routerLink: ['/datamarket'],
            // },
            // {
            //     label: 'Object Management', icon: 'pi pi-cloud',
            //     items: [
            //         {
            //             label: 'Datasources', icon: 'pi pi-table', count: 2, routerLink: ['/datasources'],
            //         },
            //         {
            //             label: 'Workflows', icon: 'pi pi-share-alt', count: 2, routerLink: ['/workflows'],
            //         },
            //         {
            //             label: 'Destinations', icon: 'pi pi-microsoft', count: 2, routerLink: ['/destinations'],
            //         },
            //         {
            //             label: 'Schedules', icon: 'pi pi-calendar', count: 2, routerLink: ['/schedules'],
            //         },
            //     ]
            // },
        ];


    }

    onSelectMenuItem(item) {
        this.selectedItem = item;
    }
    openCommunityPage() {
        window.open(
            "https://bion-analytics.com/gruppen/", "_blank");
    }


}
