<div class="p-grid" style="width:100%;">
    <div class="p-col-12" *ngIf="!isChangeSettingsOnlyFlag">
        <p-fileUpload auto="true" multiple="single" showCancelButton="false" customUpload="true" maxFileSize="200000000"
            (onRemove)="onRemoveFile($event)" chooseLabel="{{'Browse' | translate}}" uploadLabel="Preview"
            (uploadHandler)="uploadFilesAsync($event)" accept=".sav">
            <ng-template pTemplate="content">
                <ul>
                    <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
                </ul>
            </ng-template>
        </p-fileUpload>
    </div>

    <div class="p-col-12" *ngIf="!isChangeSettingsOnlyFlag">
        <p-divider layout="horizontal" align="center">
            <span class="p-tag">{{'Settings' |translate}}</span>
        </p-divider>
    </div>

    <div class="p-col-12">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown [options]="modes"
                    [(ngModel)]="currentConnectorView.connectorSettings.Mode" editable="false"></p-dropdown>
                <!-- <input type="text" id="inputtext" pInputText [(ngModel)]="currentConnectorView.dbtable"> -->
                <label for="inputtext">{{'Mode' | translate}}</label>
            </span>
        </div>
    </div>

    <div class="p-col-12 p-md-12" *ngIf="currentConnectorView.connectorSettings.Mode ==='Data'">
        <p-checkbox [(ngModel)]="currentConnectorView.connectorSettings.UseLabelHeaders" [binary]="true"></p-checkbox>
        <label for="UseLabelHeadersInput">{{'Use Label as headers' | translate}}</label>
    </div>

    <div class="p-col-12 p-md-12" *ngIf="currentConnectorView.connectorSettings.Mode ==='Data'">
        <p-checkbox [(ngModel)]="currentConnectorView.connectorSettings.ResolveLabels" [binary]="true"></p-checkbox>
        <label for="ResolveLabelsInput">{{'Resolve Labels' | translate}}</label>
    </div>


</div>
