import { PowerBiEntry } from "./PowerBiEntry";

export class PowerBiActionEvent {
	display: boolean;
	header: string;
	actionType: PowerBiDialogActionType;
	actionLabel: string;
	report?: PowerBiEntry;

	constructor(display: boolean, header: string, actionType: PowerBiDialogActionType,actionLabel: string, report?:PowerBiEntry) {
		this.display = display;
		this.header = header;
		this.actionType = actionType;
		this.actionLabel = actionLabel;
		this.report = report;
	}
}
export enum PowerBiDialogActionType {
    ReportSelected,
    ReportMenuSelected,
    openReport,
	createReport,
	ReportsChanged,
	deleteReport,
	editReport
}
