import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
	DataTable,
	ExecuteUpdateSettingsArg,
	NodeGuiInfo,
	UpdateSettingsPortResult,
	UpdateSettingsResult,
	UpdateWorkflowSettingsResult,
	WorkflowNodeGuiInfo,
	WorkflowNodeInfo,
	WorkflowPlugInInfo,
	WorkflowPortBase,
	WorkflowResult,
} from "src/app/models/designer.models";
import { DesignerEvent, DesignerService, DesignProgresSpinnerEvent, WorkflowOperationType } from "src/app/services/designer.service";
import { ToolbarCommand } from "../designer-nav-toolbar/designer-nav-toolbar.component";
import mx from "./mx-graph-loader"; // <- import values from factory()
import {
	mxGraph,
	mxCell,
	mxEditor,
	mxEventSource,
	mxEventObject,
	mxOutline,
	mxUtils,
	mxEvent,
} from "mxgraph"; // <- import types only
import {
	mxNodeState,
	mxWorkflowEdgeValue,
	mxWorkflowExtension,
	mxWorkflowGraph,
	mxWorkflowNodePortData,
	mxWorkflowNodeValue,
	mxWorkflowPortValue,
} from "./mxWorkflowGraph";
import { mxWorkflowEditor } from "./mx-workflow-editor";
import {
	mxWorkflowGraphDecoder,
	mxWorkflowGraphEncoder,
	WorkflowGraphCodecBaseSettings,
	WorkflowGraphDecodeSettings,
} from "./mx-graph-encoder";
import { WorkflowStyleInfo } from "./node-styles";
import { NodePlugInInfos } from "src/app/models/nodePlugIn.model";
import {
	mxGraphEventData,
	WorkflowGraphEvent,
	WorkflowGraphEventType,
	NodeCellClickedData,
	PortCellClickedData,
	CellClickedData,
	CellsAddedData,
	IWorkflowGraphEventData,
	UpdateSettingsExecutedData,
	NodeDataChangedData,
	PortDataChangedData,
	WorkflowExecutedData,
	GraphViewLoadedData,

} from "./workflow-graph-events";
import { NodeConfigSettingsChanged } from "../node-settings/node-config-component-base";
import { MenuItem, Message, MessageService } from "primeng/api";
import { OverlayPanel } from "primeng/overlaypanel";
import cloneDeep from "lodash.clonedeep";
import { map, startWith, switchMap } from "rxjs/operators";
import { interval, Observable, Subscription } from "rxjs";
import { SubSink } from "subsink";
import { HttpErrorResponse } from "@angular/common/http";
import { PlayErrorResponse } from "src/app/models/play.error.model";
import { MetaInfo } from "src/app/models/api/com/bion/etl/NodeMetaData";
import { Workflow, WorkflowNode, WorkflowNodeSettings, NodeProperties, GuiSettings } from "src/app/models/api/com/bion/etl/Workflow";
import { RunWorkflowArg } from "src/app/models/api/models/workflow/RunWorkflowArg";
import { RunWorkflowSettings } from "src/app/models/api/models/workflow/RunWorkflowSettings";
import { TaskJobModel } from "src/app/models/api/models/staging/TaskJobModel";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { Id } from "src/app/helper/id";
import { TranslateService } from "@ngx-translate/core";
import { Simulation } from "src/app/models/api/models/workflow/RunWorkflowSettings.ns";
import { WorkflowRepositoryEntry } from "src/app/models/api/models/workflow/WorkflowRepositoryEntry";
import { NewWorkflowResult } from "src/app/models/api/models/workflow/NewWorkflowResult";
import { WorkflowEngineControllers } from "src/app/models/api/controllers/WorkflowEngineController";
import { ClassicResultExtractor, NewResultExtractor } from "./workflow-result-extractor";
import { Optionals } from "src/app/helper/optional";
import { ConsoleLogger, ILogger, LogLevel } from "./logger";
import { SettingsResult } from "src/app/models/api/com/bion/etl/SettingsResult";

/**
 * Workflow UI Control
 */
export interface WorkflowUI {
	unDo(): void;
	reDo(): void;
	cut(): void;
	copy(): void;
	paste(): void;
	delete(): void;
	getWorkflow(): Workflow;
	setWorkflow(wf: Workflow): Observable<mxGraph>;
	addNode(
		node: WorkflowNode,
		pluginInfo: WorkflowPlugInInfo,
		x: number,
		y: number
	): void;
	selectNode(id: number): void;
	deleteNode(id: number): void;
	editNodeDescription(text: string): void;
}

@Component({
	selector: "app-workflow-graph",
	templateUrl: "./workflow-graph.component.html",
	styleUrls: ["./workflow-graph.component.scss"],
	providers: [MessageService],
})
export class WorkflowGraphComponent implements OnInit, OnDestroy, WorkflowUI {
	constructor(
		private designerService: DesignerService,
		private messageService: MessageService,
		private errorService: SystemMessageLogService,
		private translate: TranslateService
	) { }
	editNodeDescription(text: string): void {
		throw new Error("Method not implemented.");
	}
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}
	@ViewChild("op") op: OverlayPanel;
	subs = new SubSink;
	@Input() selectedWorkflow?: WorkflowRepositoryEntry;

	private logger:ILogger = new ConsoleLogger("WorkflowGraphComponent", LogLevel.Debug);

	/**
	 * Loads the Plug In Information for the Workflow Codec, the Node GUI and the Toolbar.
	 *
	 * You must call this method BEFORE you load or create a new workflow!
	 */
	loadPlugInInfo(): Observable<[WorkflowNodeInfo[], NodeGuiInfo[], WorkflowNodeGuiInfo[]]> {
		return this.designerService.getPlugIns().pipe(map((plugIns) => {
			this.plugInInfos = plugIns;
			this.nodeGuiInfo = NodePlugInInfos.getNodeGuiInfo();
			this.workflowNodeGuiInfo = NodePlugInInfos.getWorkflowNodeGuiInfo(
				plugIns,
				this.nodeGuiInfo
			);

			const result: [WorkflowNodeInfo[], NodeGuiInfo[], WorkflowNodeGuiInfo[]]
				= [this.plugInInfos, this.nodeGuiInfo, this.workflowNodeGuiInfo];

			return result;
		}));
	}
	items: MenuItem[];
	mousePositionX: number;
	mousePositionY: number;

	ngAfterViewInit(): void {
		//this.adjustContainerHeight();
	}

	ngOnInit(): void {
		this.subs.sink = this.designerService.workflowGraphEmitter.subscribe(
			(
				event: DesignerEvent<WorkflowGraphEventType, IWorkflowGraphEventData>
			) => {

				if (event.Type === WorkflowGraphEventType.GraphClicked) {
					this.items = [
						{
							label: this.translate.instant('Run'),
							icon: 'pi pi-fw pi-play',
							disabled: false,
							command: () => { this.onRunWorkflowAsync() }
						},
						{
							label: this.translate.instant('Paste'),
							icon: 'pi pi-fw pi-copy',
							disabled: false,
							command: () => { this.paste() }
						},
						// {
						//     label: 'Actual Size',
						//     icon: 'pi pi-fw pi-window-minimize',
						//     disabled: false,
						//     command: () => {this.actualSize()}
						// },
						// {
						//     label: 'Undo',
						//     icon: 'pi pi-fw pi-arrow-left',
						//     disabled: false,
						//     command: () => {this.unDo()}
						// },
						// {
						//     label: 'Redo',
						//     icon: 'pi pi-fw pi-arrow-right',
						//     disabled: false,
						//     command: () => {this.reDo()}
						// }
					];

				}
				if (event.Type === WorkflowGraphEventType.NodeCellClicked) {
					this.items = [
						{
							label: this.translate.instant('RunNode'),
							icon: 'pi pi-fw pi-play',
							disabled: false,
							command: () => { this.onRunPartialWorkflowAsync() }
						},
						{
							label: this.translate.instant('Show data'),
							icon: 'pi pi-fw pi-table',
							disabled: false,
							command: () => { this.designerService.displayDataPreview.emit([true, undefined]) }
						},
						{
							label: this.translate.instant('Copy'),
							icon: 'pi pi-fw pi-copy',
							disabled: false,
							command: () => { this.copy() }
						},
						{
							label: this.translate.instant('Cut'),
							icon: 'pi pi-fw pi-copy',
							disabled: false,
							command: () => { this.cut() }

						},
						{
							label: this.translate.instant('Delete'),
							icon: 'pi pi-fw pi-trash',
							disabled: false,
							command: () => { this.delete() }
						}
					];
				}
				if (event.Type === WorkflowGraphEventType.CellClicked) {
					this.items = [
						{
							label: this.translate.instant('Copy'),
							icon: 'pi pi-fw pi-copy',
							disabled: false,
							command: () => { this.copy() }
						},
						{
							label: this.translate.instant('Cut'),
							icon: 'pi pi-fw pi-copy',
							disabled: false,
							command: () => { this.cut() }

						},
						{
							label: this.translate.instant('Delete'),
							icon: 'pi pi-fw pi-trash',
							disabled: false,
							command: () => { this.delete() }
						}
					];
				}
				if (event.Type === WorkflowGraphEventType.NodeCellDoubleClicked) {

				}
			})



		this.subs.sink = this.newWorkflow().subscribe(workflow => {
			// anything to do here?
		});
		this.subs.sink = this.designerService.draggedNodeEmitter.subscribe(
			(res: WorkflowNodeGuiInfo) => {
				this.lastDraggedNodeGuiInfo = res;
			}
		);

		this.subs.sink = this.designerService.toolBarCommandEmitter.subscribe(
			(res: ToolbarCommand) => {
				if (res === ToolbarCommand.delete) {
					this.delete();
				}
				if (res === ToolbarCommand.copy) {
					this.copy();
				}
				if (res === ToolbarCommand.cut) {
					this.cut();
				}
				if (res === ToolbarCommand.paste) {
					this.paste();
				}
				if (res === ToolbarCommand.unDo) {
					this.unDo();
				}
				if (res === ToolbarCommand.reDo) {
					this.reDo();
				}
				if (res === ToolbarCommand.zoomIn) {
					this.zoomIn(event);
				}
				if (res === ToolbarCommand.zoomOut) {
					this.zoomOut(event);
				}
				if (res === ToolbarCommand.actualSize) {
					this.actualSize();
				}
				if (res === ToolbarCommand.fit) {
					this.fit();
				}
				if (res === ToolbarCommand.runWorkflow) {
					//this.onRunWorkflow();
					try {
						this.onRunWorkflowAsync();
					} catch (err) {
						this.errorService.handleError(<Error>err);
						this.designerService.designerProgressSpinnerEmitter.emit(new DesignProgresSpinnerEvent(false, WorkflowOperationType.RunWorkflow));
						//this.designerService.designerProgressSpinnerEmitter.emit(false);

					} finally {
						//this.designerService.designerProgressSpinnerEmitter.emit(false);
					}
				}
				if (res === ToolbarCommand.runWorkflowPartially) {
					//this.onRunWorkflowPartially();
					try {
						this.onRunPartialWorkflowAsync();
					} catch (err) {
						this.designerService.designerProgressSpinnerEmitter.emit(new DesignProgresSpinnerEvent(false, WorkflowOperationType.RunWorkflowPartially));
						//this.designerService.designerProgressSpinnerEmitter.emit(false);
						this.errorService.handleError(<Error>err)
					} finally {
						//this.designerService.designerProgressSpinnerEmitter.emit(false);
					}
					//this.onRunPartialWorkflowAsync();
				}
			}
		);
		this.subs.sink = this.designerService.NodeConfigSettingsChangedEmitter.subscribe(
			(res: NodeConfigSettingsChanged<any>) => {
				console.log("Received Command: ", res);
				try {
					this.updateNodeSettings(res.NodeID);
				} catch (err) {
					console.log("NodeConfigSettingsChangedEmitter Error", err);
				}
			}
		);
	}

	/**
	 * Sets the final node state via the workflow result object
	 * @param res 
	 */
	updateNodeStatesFromResult(res: NewWorkflowResult.RunResult<WorkflowEngineControllers.NodeRunResult<any>>) {

		// -- Find & Prepare Node Cells
		const node_cell_map = new Map<string, mxCell>();
		const parent = this.editor.graph.getDefaultParent();
		const cells = mxWorkflowExtension.getChildren(parent);

		// -- Create Map for faster access
		for (let c of cells) {
			node_cell_map.set(c.getId(), c);
		}

		res.Output.WorkflowResult.NodeResults.forEach(ns => {
			console.log(ns[0]);
			const nsr = ns[1];
			console.log(nsr.ActionResult.IsSuccess);

			const node_id = ns[0];
			console.log("WARNING - Final Node State Strings are not clean -> clean this up in the future -> add state info to workflow result, not just the Successful flag!!");
			const state_str = nsr.ActionResult.IsSuccess ? "Succeeded" : "Failed";

			const node_cell = node_cell_map.get(node_id);

			if (node_cell) {
				const node_cell_value = <mxWorkflowNodeValue>node_cell.getValue();

				const new_state = new mxNodeState(Optionals.getOrElse(state_str, ""), nsr.ActionResult.IsSuccess);

				node_cell_value.NodeState = new_state;
				console.log("Update Node States - New State Set");			// DEBUG ONLY

				// node_cell_value.NodeState.progress = node_state.RunState.State;
				// node_cell_value.NodeState.isSuccessful = node_state.IsSuccess;
			}

		})

	}

	/**
	 * Sets the final node state via the workflow state object
	 * @param runState 
	 */
	updateNodeStates(runState: WorkflowEngineControllers.RunState<string>) {
		console.log("Update Node States");			// DEBUG ONLY

		// -- Find & Prepare Node Cells
		const node_cell_map = new Map<string, mxCell>();
		const parent = this.editor.graph.getDefaultParent();
		const cells = mxWorkflowExtension.getChildren(parent);

		// -- Create Map for faster access
		for (let c of cells) {
			node_cell_map.set(c.getId(), c);
		}

		for (let entry of runState.NodeStatesInfos) {
			const node_id = entry[0];
			const node_cell = node_cell_map.get(node_id);

			const node_state = entry[1];

			if (node_cell) {
				const node_cell_value = <mxWorkflowNodeValue>node_cell.getValue();

				const new_state = new mxNodeState(Optionals.getOrElse(node_state?.RunState.State, ""), node_state.IsSuccess);

				node_cell_value.NodeState = new_state;
				console.log("Update Node States - New State Set");			// DEBUG ONLY

				// node_cell_value.NodeState.progress = node_state.RunState.State;
				// node_cell_value.NodeState.isSuccessful = node_state.IsSuccess;
			}
		}
	}

	/**
	 * Updates the nodes data based on the given Execute or Update Settings result.
	 * @param res Run Result
	 * @param startNodeID Start Node ID
	 * @param nodesToIgnore Nodes to ignore
	 */
	protected updateNodes(
		res: Map<string, UpdateSettingsResult<WorkflowNodeSettings>>,
		startNodeID?: string,
		nodesToIgnore?: string[]
	) {
		// -- Find & Prepare Node Cells
		const node_cell_map = new Map<string, mxCell>();
		const parent = this.editor.graph.getDefaultParent();
		const cells = mxWorkflowExtension.getChildren(parent);

		const nodesSettings = res;

		// -- Create Map for faster access
		for (let c of cells) {
			node_cell_map.set(c.getId(), c);
		}

		const nodesToIgnoreSet = new Set<string>();
		if (nodesToIgnore !== undefined) {
			nodesToIgnore.forEach((n) => nodesToIgnoreSet.add(n));
		}

		// -- Update settings for each node
		for (let node_id of nodesSettings.keys()) {
			// -- Write node result

			// -- Retrieve Node Value
			const node_cell = node_cell_map.get(node_id);
			const node_cell_value = <mxWorkflowNodeValue>node_cell.getValue();

			const run_result = nodesSettings.get(node_id);
			node_cell_value.Properties.Configuration = run_result.Configuration;

			// update node data
			// const node_cell = node_cell_map.get(node_key);
			// const node_cell_value = <mxWorkflowNodeValue>node_cell.getValue();
			const ports_data = run_result.PortResults;
			const ports_data_map: Map<string, mxWorkflowNodePortData[]> = new Map();

			// Port Info Map for update settings start node data keeping
			let portInfoMap = new Map<string, WorkflowPortBase>();
			node_cell_value.PortInfos.map((entry: WorkflowPortBase) => {
				portInfoMap.set(entry.Name, entry);
			});

			ports_data.forEach((value: UpdateSettingsPortResult, key: string) => {
				// Check if currentNode is start node, dont update Input ports
				if (node_id === startNodeID && portInfoMap.get(key).IsInput) {
					if (node_cell_value.Data.has(key)) {
						const old_data = node_cell_value.Data.get(key);
						ports_data_map.set(key, old_data);
					}
					return;
				}

				// Check if current Node is on the ignore list
				if (nodesToIgnoreSet.has(node_id)) {
					if (node_cell_value.Data.has(key)) {
						const old_data = node_cell_value.Data.get(key);
						ports_data_map.set(key, old_data);
					}
					return;
				}

				const newValue: mxWorkflowNodePortData[] = value.Tables.map((entry) => {
					const newPortDataObj = new mxWorkflowNodePortData(
						entry.Data,
						entry.Stats,
						entry.CutInfo
					);
					return newPortDataObj;
				});

				ports_data_map.set(key, newValue);
			});

			node_cell_value.Data = ports_data_map;

			const port_cell_map = this.buildPortCellMap(node_cell);

			for (let port_key of ports_data.keys()) {
				// // Check if currentNode is start node, dont update Input ports
				// if(node_id === startNodeID && portInfoMap.get(port_key).IsInput) {
				// 	continue;
				// }

				// update each node port
				const port_data = ports_data.get(port_key);

				// update node meta data
				//const meta_infos = port_data.map(m => m.Table.MetaData);
				const tables = port_data.Tables;
				const meta_infos = tables.map((entry) => {
					return entry.Data.MetaData;
				});

				node_cell_value.Properties.MetaInfo.set(port_key, meta_infos);

				// update port cell
				const target_port_cell = port_cell_map.get(port_key);
				const port_value = <mxWorkflowPortValue>target_port_cell.getValue();
				const port_tables = port_data.Tables;
				const port_tables_data = port_tables.map((entry) => {
					const port_value_data = new mxWorkflowNodePortData(
						entry.Data,
						entry.Stats,
						entry.CutInfo
					);
					return port_value_data;
				});

				port_value.Data = port_tables_data;
				//this.onPortDataChanged(node_cell, target_port_cell, port_data);

				const portDataChanged = new PortDataChangedData(
					port_data,
					node_id,
					port_key
				);

				this.onFireEvent(
					WorkflowGraphEventType.PortDataChanged,
					portDataChanged
				);
			}

			const nodeDataChanged = new NodeDataChangedData(
				run_result,
				node_id,
				node_cell_value
			);
			this.onFireEvent(WorkflowGraphEventType.NodeDataChanged, nodeDataChanged);
		}
	}

	/**
	 * Finds the port cells of the given node cell
	 * @param nodeCell Node cell which contains the ports
	 * @returns Port Cells indexed by their name
	 */
	buildPortCellMap(nodeCell: mxCell): Map<string, mxCell> {
		const port_cells = mxWorkflowExtension.getPorts(nodeCell);
		const port_cell_map = new Map<string, mxCell>();
		for (let p of port_cells) {
			const port_cell_value = <mxWorkflowPortValue>p.getValue();
			port_cell_map.set(port_cell_value.Name, p);
		}

		return port_cell_map;
	}

	// MEMBERS

	@ViewChild("graphContainer") graphContainer!: ElementRef;
	@ViewChild("outlineContainer") outlineContainer!: ElementRef;
	@Input() isEditor!: boolean;

	protected editor?: mxEditor;
	protected graph?: mxGraph;
	protected config = new WorkflowConfiguration(80, 80, true);
	protected plugInInfos: WorkflowNodeInfo[] = [];
	protected nodeGuiInfo: NodeGuiInfo[] = [];
	protected workflowNodeGuiInfo: WorkflowNodeGuiInfo[] = [];
	protected lastDraggedNodeGuiInfo?: WorkflowNodeGuiInfo = undefined;
	protected outlineWindow?: mxOutline;

	// == UI CALLBACKS

	dropEmpty(event: DragEvent) {
		console.log("DragDrop deactivated due to native mxGraph DragDrop Testing");
	}
	drop(event: DragEvent) {
		if (this.lastDraggedNodeGuiInfo === undefined) return;

		const plugInInfo: WorkflowNodeInfo = this.lastDraggedNodeGuiInfo;
		const graphContainer = this.graph.container;

		const bounds = graphContainer.getBoundingClientRect();
		const containerX = event.clientX - bounds.left;
		const containerY = event.clientY - bounds.top;

		// Use containerX and containerY for further calculations
		const offsetX = 0; // Adjust the offset value as needed
		const offsetY = 0;

		const settings = this.designerService
			.getNodeSettings(plugInInfo.Engine)
			.subscribe((res: SettingsResult<WorkflowNodeSettings, MetaInfo>) => {
				const properties = new NodeProperties(
					res.Configuration,
					res.MetaInfo
				);

				const editorZoomScale = this.editor.graph.view.scale;
				const zoomScale = this.graph.view.scale;

				const originalX = containerX;
				const originalY = containerY;

				let adjustedX = originalX;
				let adjustedY = originalY;

				// if (zoomScale !== 1) {
				//   adjustedX = (originalX - offsetX) * zoomScale;
				//   adjustedY = (originalY - offsetY) * zoomScale;
				// } else {
				//   adjustedX = originalX - offsetX;
				//   adjustedY = originalY - offsetY;
				// }

				adjustedX = (originalX - offsetX) * zoomScale;
				adjustedY = (originalY - offsetY) * zoomScale;

				// const x_zoom = adjustedX;
				// const y_zoom = adjustedY;

				const x_zoom = originalX;
				const y_zoom = originalY;

				// console.log("x", containerX);
				// console.log("y", containerY);
				// console.log("zoomScale", zoomScale);
				// console.log("editorZoomScale", editorZoomScale);
				// console.log("originalX", originalX);
				// console.log("originalY", originalY);
				// console.log("x_zoom", x_zoom);
				// console.log("y_zoom", y_zoom);

				const gui_settings = new GuiSettings(
					x_zoom,
					y_zoom,
					this.config.vertexHeight,
					this.config.vertexWidth
				);

				const label_name = plugInInfo.Name.split(".").reverse()[0];

				// ID is undefined because it isset by _addNode method.
				const workflowNode = new WorkflowNode(
					"",
					gui_settings,
					properties,
					plugInInfo.Engine,
					label_name
				);

				this._addNode(workflowNode, x_zoom, y_zoom, plugInInfo);
			});
	}

	// == INTERFACE

	unDo(): void {
		this.editor?.execute("undo");
	}
	reDo(): void {
		this.editor?.execute("redo");
	}
	cut(): void {
		this.editor?.execute("cut");
	}
	copy(): void {
		this.editor?.execute("copy");
	}
	paste(): void {
		this.editor?.execute("paste");
	}
	delete(): void {
		this.editor?.execute("delete");
		this.designerService.displayConfigEmitter.emit(false);
	}
	zoomIn(event): void {
		this.editor?.execute("zoomIn", event);
	}
	zoomOut(event): void {
		this.editor?.execute("zoomOut", event);
	}
	actualSize(): void {
		this.editor?.execute("actualSize");
	}
	fit(): void {
		this.editor?.execute("fit");
	}
	getWorkflow(): Workflow {
		const mxWorkflowGraph = <mxWorkflowGraph>this.graph;
		const workflow = this.convertMxGraphToWorkflow(mxWorkflowGraph);
		return workflow;
	}
	getWorkflowRepositoryEntry(): WorkflowRepositoryEntry {
		return this.selectedWorkflow
	}
	setWorkflow(wf: Workflow): Observable<mxGraph> {
		return this.openWorkflow(wf);
	}
	addNode(
		node: WorkflowNode,
		pluginInfo: WorkflowPlugInInfo,
		x: number,
		y: number
	): void {
		this._addNode(node, x, y, pluginInfo);
	}
	selectNode(id: number): void {
		throw new Error("Method not implemented.");
	}
	deleteNode(id: number): void {
		throw new Error("Method not implemented.");
	}

	protected _addNode(
		node: WorkflowNode,
		x: number,
		y: number,
		info: WorkflowPlugInInfo
	) {
		const settings = new WorkflowGraphCodecBaseSettings(true);
		const decoder = new mxWorkflowGraphDecoder(settings);

		const graph = Id.assertSet(this.graph, new Error("No Graph set!"));

		const parent = graph.getDefaultParent();
		const decode_settings = new WorkflowGraphDecodeSettings(
			WorkflowStyleInfo.NodeStyles(),
			this.plugInInfos
		);
		decoder.insertWorkflowNode(
			<mxWorkflowGraph>this.graph,
			node,
			parent,
			info,
			decode_settings
		);
		graph.refresh();
	}

	// == CORE

	/**
	 * Create a new mxWorkflowGraph in the given HTML Element
	 * @param element Graph Container
	 * @returns The new graph
	 */
	protected newWorkflowGraph(element: HTMLElement): mxWorkflowGraph {
		const targetGraph = new mxWorkflowGraph(element);
		return targetGraph;
	}

	/**
	 * Create a new workflow editor
	 * @param element Graph Container
	 * @param graph Graph, if none is given a new is created
	 * @returns The new editor
	 */
	protected newWorkflowGraphEditor(
		element: HTMLElement,
		graph?: mxWorkflowGraph
	): mxWorkflowEditor {
		const editor = new mxWorkflowEditor();

		if (graph === undefined) {
			editor.createGraph();
		} else {
			editor.loadGraph(graph);
		}

		// editor.setGraphContainer(element);
		// editor.["setGraphContainer"](element);
		(editor as any).setGraphContainer(element);

		// // -- Configure Editor
		// var group = new mx.mxCell('Group', new mx.mxGeometry(), 'group');
		// group.setVertex(true);
		// group.setConnectable(false);
		// this.editor.defaultGroup = group;
		// this.editor.groupBorderSize = 20;

		const config = mx.mxUtils
			.load("assets/mxgraph/keyhandler-commons.xml")
			.getDocumentElement();
		(editor as any).configure(config);

		return editor;
	}

	onClearWorkflow() {
		this.newWorkflow().subscribe()
	}

	/**
	 * Creates a new fresh workflow & drops an existing old one.
	 */
	newWorkflow(): Observable<mxGraph> {
		this.purgeGraph();

		return this.loadPlugInInfo().pipe(map(info => {
			// -- Create new workflow

			// #1 
			if (this.config.useEditorMode) {
				this.editor = this.newWorkflowGraphEditor(
					this.graphContainer.nativeElement
				);

				this.graph = this.editor.graph;
			} else {
				this.graph = this.newWorkflowGraph(this.graphContainer.nativeElement);
			}


			// #2
			if (this.outlineContainer) {
				this.graph = this.loadGraphIntoView(
					this.graph,
					this.outlineContainer.nativeElement
				);
			} else {
				this.graph = this.loadGraphIntoView(this.graph, undefined);
			}

			this.graph.refresh();

			this.fireEvent(new WorkflowGraphEvent(WorkflowGraphEventType.GraphViewLoaded, new GraphViewLoadedData(this, <mxWorkflowGraph>this.graph)));

			return this.graph;
		}));
	}

	/**
	 * Open the given workflow in the Component
	 * @param workflow Workflow
	 */
	protected openWorkflow(workflow: Workflow): Observable<mxGraph> {
		this.purgeGraph();

		return this.loadPlugInInfo().pipe(map(info => {

			// convert and load

			// #1
			if (this.config.useEditorMode) {
				// this.editor = this.newWorkflowGraphEditor(
				// 	this.graphContainer.nativeElement,
				// 	<mxWorkflowGraph>decoded_graph
				// );
				this.editor = this.newWorkflowGraphEditor(
					this.graphContainer.nativeElement
				);
				this.graph = WorkflowGraphComponent.convertWorkflowToMxGraphGiven(
					<mxWorkflowGraph>this.editor.graph,
					workflow,
					this.plugInInfos
				)

				//this.graph = this.editor.graph;
			} else {
				const decoded_graph = WorkflowGraphComponent.convertWorkflowToMxGraph(
					workflow,
					this.graphContainer.nativeElement,
					this.plugInInfos,
				);
				this.graph = decoded_graph;
			}

			// #2
			if (this.outlineContainer) {
				this.graph = this.loadGraphIntoView(
					this.graph,
					this.outlineContainer.nativeElement
				);
			} else {
				this.graph = this.loadGraphIntoView(this.graph, undefined);
			}

			this.graph.refresh();

			this.fireEvent(new WorkflowGraphEvent(WorkflowGraphEventType.GraphViewLoaded, new GraphViewLoadedData(this, <mxWorkflowGraph>this.graph)));

			return this.graph;
		}));
	}


	/**
	 * Load the graph into the view, setting styles and connect event handlers
	 * @param graph Graph
	 * @param outline mxOutline Container
	 * @param readOnly Graph read-only flag
	 * @returns The updated graph
	 */
	protected loadGraphIntoView(
		graph: mxGraph,
		outline?: HTMLElement,
		readOnly: boolean = false
	): mxGraph {

		// -- Initialize
		mx.mxEdgeHandler.prototype.snapToTerminals = true;

		// -- Collect References for callbacks
		const targetGraph = graph;
		const parentView = this;

		// -- Set graph handling
		if (readOnly) {
			targetGraph.setConnectable(false);
			// TODO: add additional parameters
		} else {
			targetGraph.setAllowDanglingEdges(false);
			targetGraph.setDisconnectOnMove(false);
			targetGraph.connectionHandler.targetConnectImage = true; // Centers the port icon on the target port
			targetGraph.setConnectable(true);
			// targetGraph.setHtmlLabels(true); // ehemals hier gesetzt
		}

		// -- Register Events
		const graphClickedFunction = this.onGraphClicked;
		const boundGraphClickedFunction = graphClickedFunction.bind(parentView);
		targetGraph.addListener(mx.mxEvent.CLICK, boundGraphClickedFunction);

		const cellConnectedFunction = this.onCellConnected;
		const boundCellConnectedFunction = cellConnectedFunction.bind(parentView);
		targetGraph.addListener(
			mx.mxEvent.CELL_CONNECTED,
			boundCellConnectedFunction
		);

		const cellAddedFunction = this.onCellAdded;
		const boundOnCellAddedFunction = cellAddedFunction.bind(parentView);
		targetGraph.addListener(mx.mxEvent.CELLS_ADDED, boundOnCellAddedFunction);
		const mouseMoveAddedFunction =
			targetGraph.addListener(mx.mxEvent.MOVE, boundOnCellAddedFunction)

		// -- Insert image bundles for node images
		targetGraph.htmlLabels = true;
		const image_bundle = WorkflowStyleInfo.getDefaultImageBundle(
			false,
			this.imageBundleFallback
		);
		targetGraph.addImageBundle(image_bundle);

		// -- Set default styles
		this.configureStylesheet(targetGraph);

		// -- Disable Tooltips, maybe reactivate later
		targetGraph.tooltipHandler.setEnabled(false);

		// Enables HTML labels
		targetGraph.setHtmlLabels(true);


		// Check the view we are currently in (designer or preview only)
		if (!this.isEditor) {
			this.fit();
			graph.setEnabled(false);
		}

		targetGraph.refresh();

		// init the minimap
		if (outline) {
			this.outlineWindow = new mx.mxOutline(targetGraph, outline);
		}

		// prevent multiple edges betweene the same cells -> No Multigraph!
		graph.setMultigraph(false);

		return graph;
	}

	/**
	 * Destroy old graph and drop references including editor.
	 */
	protected purgeGraph() {
		if (this.outlineWindow !== undefined) {
			this.outlineWindow.destroy();
			this.outlineWindow = undefined;
		}
		if (this.graph !== undefined) {
			this.graph.destroy();
			this.graph = undefined;
		}
		if (this.editor !== undefined) {
			this.editor = undefined;
		}
	}

	/**
	 * The name sais it all.
	 * @param graph Workflow Graph
	 * @returns Workflow Object
	 */
	protected convertMxGraphToWorkflow(graph: mxWorkflowGraph): Workflow {
		const settings = new WorkflowGraphCodecBaseSettings(true);

		const converter = new mxWorkflowGraphEncoder(settings);

		const workflow = converter.encode(graph);

		return workflow;
	}

	/**
	 * The name sais it all.
	 * @param workflow Workflow Object
	 * @param container HTML container in which the method creates the Workflow Graph
	 * @returns The created Workflow Graph
	 */
	static convertWorkflowToMxGraph(
		workflow: Workflow,
		container: HTMLElement,
		plugInInfos: WorkflowNodeInfo[],
		renderHint?: any
	): mxWorkflowGraph {
		const settings = new WorkflowGraphCodecBaseSettings(true);

		const converter = new mxWorkflowGraphDecoder(settings);

		const decode_settings = new WorkflowGraphDecodeSettings(
			WorkflowStyleInfo.NodeStyles(),
			plugInInfos
		);
		const graph = converter.decode(workflow, container, decode_settings, renderHint);

		return graph;
	}

	/**
	 * The name sais it all.
	 * @param workflow Workflow Object
	 * @param container HTML container in which the method creates the Workflow Graph
	 * @returns The created Workflow Graph
	 */
	static convertWorkflowToMxGraphGiven(
		graph: mxWorkflowGraph,
		workflow: Workflow,
		plugInInfos: WorkflowNodeInfo[],
	): mxWorkflowGraph {
		const settings = new WorkflowGraphCodecBaseSettings(true);

		const converter = new mxWorkflowGraphDecoder(settings);

		const decode_settings = new WorkflowGraphDecodeSettings(
			WorkflowStyleInfo.NodeStyles(),
			plugInInfos
		);
		graph = converter.decodeToGraph(workflow, decode_settings, graph);

		return graph;
	}


	// RECEIVED EVENT FUNCTIONS

	// FIRE EVENT FUNCTIONS
	onCellConnected(sender: mxEventSource, evt: mxEventObject) {
		const data = new mxGraphEventData(sender, evt);
		const event = new WorkflowGraphEvent(
			WorkflowGraphEventType.CellConnected,
			data
		);
		this.fireEvent(event);

		if (evt.properties["source"]) return; // source of edge updated, too early to fetch target -> QUIT!

		const edge = <mxCell>evt.properties["edge"];
		const sourcePortCell = edge.source;
		const targetPortCell = edge.target;

		const sourceNodeCell = sourcePortCell.getParent();
		const targetNodeCell = targetPortCell.getParent();

		const targetPort = <mxWorkflowPortValue>targetPortCell.getValue(); // legacy only - delete later

		// Multi Port
		if (targetPort.IsMulti) {
			const incom_edge_set = mxWorkflowExtension.getConnectedEdges(
				targetPortCell,
				this.graph
			);
			incom_edge_set.delete(targetPortCell);

			let given_names = new Array<string>();
			for (const incom_cell of incom_edge_set.values()) {
				if (incom_cell.value !== undefined) {
					const call_value_obj = <mxWorkflowEdgeValue>incom_cell.getValue();
					given_names.push(call_value_obj.Name);
				}
			}

			const new_edge_name = this.generateEdgeName(given_names);
			const new_edge_value = new mxWorkflowEdgeValue();
			new_edge_value.Name = new_edge_name;
			edge.setValue(new_edge_value);
		}

		// -- flood data from source to taget port
		// collect all in edges, combine the data and copy it to the target port
		const incom_edge_set = mxWorkflowExtension.getConnectedEdges(
			targetPortCell,
			this.graph
		);
		// merge edge result
		const merged_data = this.mergeEdgeData(incom_edge_set);
		// copy result to target node
		targetPort.Data = merged_data;
		const targetCell = <mxWorkflowNodeValue>targetNodeCell.getValue();
		if (targetCell.Data == undefined) {
			targetCell.Data = new Map();
		}
		targetCell.Data.set(targetPort.Name, merged_data);

		// also write the new edge labels to the meta data
		// TODO: check if the '_type' attrib is given corretly
		const old_cell_meta_info = targetCell.Properties.MetaInfo.get(
			targetPort.Name
		);
		const new_cell_meta_info = { ...old_cell_meta_info };
		const merged_meta_info = merged_data.map((md) => {
			return md.Table.MetaData;
		});
		targetCell.Properties.MetaInfo.set(targetPort.Name, merged_meta_info);

		// TODO: Fire node data changed event here?

		// -- Update Settings
		const targetNodeId = targetNodeCell.id;
		this.updateNodeSettings(targetNodeId);

		// select target cell explicitly
		//this.graph.selectionModel.setCell(sourceNodeCell);
	}

	/**
	 * Merges the source port data of the given edges into the target port data.
	 * These edges target port is usually a Multi Port.
	 * @param edges
	 * @returns Merged Edge data
	 */
	mergeEdgeData(edges: Set<mxCell>): Array<mxWorkflowNodePortData> {
		const target_port_data = new Array<mxWorkflowNodePortData>();

		// get in edge label

		// get edge label
		for (let e of edges) {
			const edge_value = <mxWorkflowEdgeValue>e.getValue();
			const source_port = <mxWorkflowPortValue>e.source.getValue();
			const new_port_data = source_port.Data.map((d) => {
				// better clone the old value then creating a new from scratch,
				// so the type information is saved.
				const new_meta_data = cloneDeep(d.Table.MetaData);
				new_meta_data.EdgeLabel = edge_value.Name;

				// const meta_data = new MetaInfo(
				// 	d.Table.MetaData.FieldsInfo,
				// 	edge_value.Name
				// );

				const table = new DataTable(new_meta_data, d.Table.Data);
				return new mxWorkflowNodePortData(table, d.TableStats, d.CutInfo);
			});

			new_port_data.forEach((pd) => target_port_data.push(pd));
		}

		// merge

		return target_port_data;
	}

	generateEdgeName(existing_names: Array<string>): string {
		let counter = 1;
		const prefix = "#";
		let name = prefix + counter;

		while (existing_names.find((n) => n == name) !== undefined) {
			counter = counter + 1;
			name = prefix + counter;
		}

		return name;
	}

	onGraphClicked(sender: mxEventSource, evt: mxEventObject) {

		// console.log("OnGraphClicked", sender, evt);  // DEBUg ONLY

		const cell = <mxCell>evt.properties["cell"];
		if (cell === undefined) {
			const data = new mxGraphEventData(sender, evt);
			// this.op.hide();
			this.onFireEvent(WorkflowGraphEventType.GraphClicked, data);
			return;
		}

		if (mxWorkflowExtension.cellIsNode(cell)) {
			const data = new NodeCellClickedData(
				sender,
				evt,
				cell,
				<mxWorkflowNodeValue>cell.getValue()
			);
			// this.op.show(data);
			this.onFireEvent(WorkflowGraphEventType.NodeCellClicked, data);

			return;
		}

		if (mxWorkflowExtension.cellIsPort(cell)) {
			const data = new PortCellClickedData(
				cell,
				<mxWorkflowPortValue>cell.getValue(),
				sender,
				evt
			);
			const event = new WorkflowGraphEvent(
				WorkflowGraphEventType.PortCellClicked,
				data
			);
			this.fireEvent(event);
			return;
		}

		const data = new CellClickedData(sender, evt, cell);
		const event = new WorkflowGraphEvent(
			WorkflowGraphEventType.CellClicked,
			data
		);
		this.fireEvent(event);
	}

	onCellAdded(sender: mxEventSource, evt: mxEventObject) {
		const cells = <mxCell[]>evt.properties["cells"];
		const data = new CellsAddedData(sender, evt, cells);
		this.onFireEvent(WorkflowGraphEventType.CellsAdded, data);
	}

	onRunWorkflow() {
		const workflow = this.getWorkflow();

		this.designerService
			.executeWorkflow(workflow)
			.subscribe((res: WorkflowResult) => {
				console.log(res);
				this.updateNodes(res.OutNodeData);

				const data = new WorkflowExecutedData(res, new ClassicResultExtractor());
				this.onFireEvent(WorkflowGraphEventType.WorkflowExecuted, data);

				this.designerService.designerProgressSpinnerEmitter.emit(new DesignProgresSpinnerEvent(false, WorkflowOperationType.RunWorkflow));
				this.messageService.add({
					severity: "success",
					summary: this.translate.instant("Message.RunWorkflowSuccess.Title"),
					detail: this.translate.instant("Message.RunWorkflowSuccess.Text"),
					//summary: "Run workflow was successful!",
					//detail: "Workflow was successfully run",
				});
			});
	}

	/**
	 * Runs the async execution and starts polling to wait for the result.
	 */
	onRunWorkflowAsync() {

		// Check if workflow is in progress, if yes -> throw Exception
		if (this.workflowInProgress) {
			throw new Error("Cannot start onRunWorkflowAsync, because Workflow is still in progress");
		};

		console.log("onRunWorkflowAsync");
		const workflow = this.getWorkflow();
		console.log("Workflow to run", workflow);

		this.workflowInProgress = true;

		//const selectedCellId = selectedCell.getId();
		const wfEntry = this.getWorkflowRepositoryEntry();

		let sim = new Simulation(true);

		if (wfEntry) {
			sim = wfEntry.runInfo ? wfEntry.runInfo.simulation : new Simulation(true);
		} else {
			// Do nothing, get default
		}

		// const simulation = wfEntry.runInfo ? wfEntry.runInfo.simulation : new Simulation(true);

		const settings = new RunWorkflowSettings(sim, undefined);
		const arg = new RunWorkflowArg(workflow, settings);


		this.subs.sink = this.designerService
			//.executeWorkflowNew_async(arg)  // Disabled to new object test
			.exp_executeWorkflow_obj_async(arg)
			//this.subs.sink = this.designerService
			//	.executeWorkflow_async(workflow)
			.subscribe((res: TaskJobModel.JobRequestResult) => {
				this.asyncWorkflowJob = res.Job;
				this.asyncWorkflowPollSub = this.asyncWorkflowPollInterval
					.pipe(startWith(0),
						//switchMap(() => this.designerService.executeWorkflow_status(res.Job)))
						switchMap(() => this.designerService.exp_executeWorkflow_obj_status(res.Job)))  // Disabled to new object test
					.subscribe(
						res => {
							console.log("onRunWorkflowAsync - Checking Workflow for completeness");
							console.log("State Object", res);
							if (res.Completed) {
								// clean up and fetch result.
								this.workflowAsyncStopPolling();
								// The error check is unnecessary here, because this function always returns successful.


								console.log("WARNING - New Experimental Workflow Result Object ACTIVE");
								this.subs.sink = this.designerService.exp_executeWorkflow_obj_result(res.Job).subscribe(workflowResult => {
									this.processNewWorkflowResult(workflowResult);
								}, (err: Error) => {
									this.errorService.handleError(err);
								}, () => {
									this.workflowInProgress = false;
								})

								// -- classic logic, disabled during new workflow object test
								// this.subs.sink = this.designerService.executeWorkflow_result(res.Job).subscribe(workflowResult => {
								// 	this.processWorkflowResult(workflowResult);
								// }, (err: Error) => {
								// 	this.errorService.handleError(err);
								// }, () => {
								// 	this.workflowInProgress = false;
								// })
							} else {
								// Workflow is still running -> patch the status cells / HTML labels
								this.updateWorkflowRunStatus(res);
							}
						}, (err: Error) => {
							this.workflowAsyncStopPolling();
							this.errorService.handleError(err);
						},
						() => {
							this.workflowInProgress = false;
						})
			}, (err: Error) => {
				this.errorService.handleError(err);
			},
				() => {
					this.workflowInProgress = false;
				});
	}

	asyncWorkflowPollSub?: Subscription = undefined;
	asyncWorkflowPollInterval = interval(500);
	asyncWorkflowJob?: string = undefined;

	handleMouseWheel(event) {
		//console.log(event);

		if (event.wheelDelta > 0) {
			this.zoomIn(event)
		}
		if (event.wheelDelta < 0) {
			this.zoomOut(event)
		}
	}

	workflowAsyncStopPolling() {
		console.log("workflowAsyncStopPolling");

		this.asyncWorkflowPollSub.unsubscribe();
		this.asyncWorkflowPollSub = undefined;

		this.asyncWorkflowJob = undefined;

		console.log("workflowAsyncStopPolling - DONE");
	}

	updateWorkflowRunStatus(result: TaskJobModel.JobStatusInfo<WorkflowEngineControllers.RunState<string>>) {

		const progress = result.Progress;

		if (progress) {
			const run_state = progress.Original;

			this.updateNodeStates(run_state);

			// force graph redraw
			this.editor?.graph?.refresh();
		}
	}

	/**
	 * Verarbeitet das neue Workflow Ergebnis Objekt
	 * @param res Workflow Ergebnis
	 */
	processNewWorkflowResult(res: NewWorkflowResult.RunResult<WorkflowEngineControllers.NodeRunResult<any>>) {

		try {

			res.Output.WorkflowResult.NodeResults.forEach(ns => {
				console.log(ns[0]);
				const nsr = ns[1];
				console.log(nsr.ActionResult.IsSuccess);
			});

			// Update Node States
			this.updateNodeStatesFromResult(res);

			// Update Nodes - OK
			const tc = new NewResultExtractor();
			const node_data = tc.nodeDataMap(res);
			this.updateNodes(node_data);

			// redraw graph
			console.log("Process workflow result - Refresh Graph");
			this.editor?.graph.refresh();

			// Fire Event
			const data = new WorkflowExecutedData(res, tc);
			console.log("WorkflowExecuted Result", res);
			this.onFireEvent(WorkflowGraphEventType.WorkflowExecuted, data);


			const errors = tc.errors(res);
			const run_successful = errors.length == 0;
			this.notifyRunResult(run_successful)
		} catch (err) {
			this.errorService.handleError(<Error>err);
		}
		finally {
			console.log("processWorkflowResult: STOP SPINNER");
			this.designerService.designerProgressSpinnerEmitter.emit(new DesignProgresSpinnerEvent(false, WorkflowOperationType.RunWorkflow));
		}
	}


	/**
	 * Verarbeitet das klassische Workflow Ergebnis Objekt.
	 * @param res Workflow Ergebnis
	 */
	processWorkflowResult(res: WorkflowResult) {

		try {

			const tc = new ClassicResultExtractor();

			console.log(res);
			this.updateNodes(res.OutNodeData);

			const data = new WorkflowExecutedData(res, tc);
			this.onFireEvent(WorkflowGraphEventType.WorkflowExecuted, data);

			const run_successful = data.Result.Errors.length == 0;


			// notification section
			this.notifyRunResult(run_successful)
		} finally {
			console.log("processWorkflowResult: STOP SPINNER");
			this.designerService.designerProgressSpinnerEmitter.emit(new DesignProgresSpinnerEvent(false, WorkflowOperationType.RunWorkflow));
		}
	}

	notifyRunResult(run_successful: boolean) {
		const msg: Message = run_successful ? {
			severity: "success",
			summary: this.translate.instant("Run workflow was successful!"),
			detail: this.translate.instant("Workflow was successfully run"),
		} : {
			severity: "warn",
			summary: this.translate.instant("Run workflow with errors!"),
			detail: this.translate.instant("Workflow run but some nodes caused errors"),
		};

		this.messageService.add(msg);
	}


	// Run Workflow Partially Async

	asyncPartialWorkflowPollSub?: Subscription = undefined;
	asyncPartialWorkflowPollInterval = interval(500);
	asyncPartialWorkflowJob?: string = undefined;


	/**
	 * Runs the async execution and starts polling to wait for the result.
	 */
	onRunPartialWorkflowAsync() {


		// Check if workflow is in progress, if yes -> throw Exception
		if (this.workflowInProgress) {
			throw new Error("Cannot start onRunPartialWorkflowAsync, because Workflow is still in progress");
		};

		const workflow = this.getWorkflow();
		const selectedCell = this.graph.getSelectionCell();

		if (selectedCell === undefined) {
			this.designerService.designerProgressSpinnerEmitter.emit(new DesignProgresSpinnerEvent(false, WorkflowOperationType.RunWorkflowPartially));
			//this.designerService.designerProgressSpinnerEmitter.emit(false);
			console.log("No Cell selected!");
			return;
		}

		const selectedCellId = selectedCell.getId();

		const wfEntry = this.getWorkflowRepositoryEntry();

		if (!wfEntry) {
			console.log("No corresponding wfEntry found. Is it a new workflow?")
		}

		let sim = new Simulation(true);

		if (wfEntry) {
			sim = wfEntry.runInfo ? wfEntry.runInfo.simulation : new Simulation(true);
		} else {
			// Do nothing, get default
		}
		//const simulation = wfEntry.runInfo ? wfEntry.runInfo.simulation : new Simulation(true);

		//const simulation = new Simulation(false, 0);
		const settings = new RunWorkflowSettings(sim, selectedCellId);
		const arg = new RunWorkflowArg(workflow, settings);

		this.workflowInProgress = true;

		this.subs.sink = this.designerService
			.executePartialWorkflow_async(arg)
			.subscribe((res: TaskJobModel.JobRequestResult) => {
				this.asyncPartialWorkflowJob = res.Job;
				this.asyncPartialWorkflowPollSub = this.asyncPartialWorkflowPollInterval
					.pipe(startWith(0),
						switchMap(() => this.designerService.executePartialWorkflow_status(res.Job)))
					.subscribe(
						res => {
							console.log("onRunPartialWorkflowAsync - Checking Workflow for completeness");
							if (res.Completed) {
								// clean up and fetch result.
								this.partialWorkflowAsyncStopPolling();

								// The error check is unnecessary here, because this function always returns successful.
								this.subs.sink = this.designerService.executePartialWorkflow_result(res.Job).subscribe(workflowResult => {
									this.processWorkflowResult(workflowResult);
								}, (err: Error) => {
									this.errorService.handleError(err);
								},
									() => {
										this.workflowInProgress = false;
									})
							}
						}, (err: Error) => {
							this.partialWorkflowAsyncStopPolling();
							this.errorService.handleError(err);
						},
						() => {
							this.workflowInProgress = false;
						})
			}, (err: Error) => {
				this.errorService.handleError(err)
			},
				() => {
					this.workflowInProgress = false;
				});
	}

	partialWorkflowAsyncStopPolling() {
		console.log("partialWorkflowAsyncStopPolling");

		this.asyncPartialWorkflowPollSub.unsubscribe();
		this.asyncPartialWorkflowPollSub = undefined;

		this.asyncPartialWorkflowJob = undefined;

		console.log("partialWorkflowAsyncStopPolling - DONE");
	}



	// Run Workflow Partially Sync

	onRunWorkflowPartially() {
		const workflow = this.getWorkflow();
		const selectedCell = this.graph.getSelectionCell();

		if (selectedCell === undefined) {
			this.designerService.designerProgressSpinnerEmitter.emit(new DesignProgresSpinnerEvent(false, WorkflowOperationType.RunWorkflowPartially));
			//this.designerService.designerProgressSpinnerEmitter.emit(false);
			console.log("No Cell selected!");
			return;
		}

		const selectedCellId = selectedCell.getId();
		const simulation = new Simulation(false, 0);
		const settings = new RunWorkflowSettings(simulation, selectedCellId);
		const arg = new RunWorkflowArg(workflow, settings);

		this.designerService
			.executePartialWorkflow(arg)
			.subscribe((res: WorkflowResult) => {
				console.log(res);
				this.updateNodes(res.OutNodeData);

				const data = new WorkflowExecutedData(res, new ClassicResultExtractor());
				this.onFireEvent(WorkflowGraphEventType.WorkflowExecuted, data);

				// this.selectOutPortOfCurrentCell();

				//this.designerService.designerProgressSpinnerEmitter.emit(false);
				this.designerService.designerProgressSpinnerEmitter.emit(new DesignProgresSpinnerEvent(false, WorkflowOperationType.RunWorkflowPartially));
				this.messageService.add({
					severity: "success",
					summary: this.translate.instant("Message.RunPartiallyWorkflowSuccess.Title"),
					detail: this.translate.instant("Message.RunPartiallyWorkflowSuccess.Text"),
				});
			},
				(error: HttpErrorResponse) => {
					console.log(error);
					const play_error: PlayErrorResponse = error.error;

					//this.designerService.designerProgressSpinnerEmitter.emit(false);
					this.designerService.designerProgressSpinnerEmitter.emit(new DesignProgresSpinnerEvent(false, WorkflowOperationType.RunWorkflowPartially));
					this.messageService.add({
						severity: "warn",
						summary: this.translate.instant("Message.RunPartiallyWorkflowFail.Title"),
						detail: this.translate.instant(play_error.error.exception.description),
					});
				});
	}

	workflowInProgress: boolean = false;
	/**
	 * Run Update Settings from the given start node.
	 * @param id Start Node
	 */
	updateNodeSettings(id: string) {

		// Check if workflow is in progress, if yes -> throw Exception
		if (this.workflowInProgress) {
			throw new Error("Cannot start updateNodeSettings, because Workflow is still in progress");
		};

		const arg = new ExecuteUpdateSettingsArg();
		const cells = mxWorkflowExtension.getChildren(this.graph.getDefaultParent());
		const cell = cells.find(cell => cell.id === id);

		if (cell === undefined)
			throw new Error("Update Node Settings not possible because cell with id " + id + " does not exist");

		arg.StartNodeID = id;
		arg.Workflow = this.getWorkflow();

		console.log("ExecuteUpdateSettingsArg", arg);

		this.workflowInProgress = true;
		this.designerService.designerProgressSpinnerEmitter.emit(new DesignProgresSpinnerEvent(true, WorkflowOperationType.UpdateSettings));

		this.subs.sink = this.designerService
			.executeUpdateSettings(arg)
			.subscribe((res: UpdateWorkflowSettingsResult) => {
				console.log(res);

				// Ignore incoming nodes of start node for update
				const nodesToIgnore = this.getIncomingNodes(
					arg.Workflow,
					arg.StartNodeID
				);

				// Update workflow
				this.updateNodes(res.OutNodeData, arg.StartNodeID, nodesToIgnore);

				// Emit UpdateNodeSettings Event
				const data = new UpdateSettingsExecutedData(res);
				this.onFireEvent(WorkflowGraphEventType.UpdateSettingsExecuted, data);
			}, (err: Error) => {
				this.errorService.handleError(err);
			},
				() => {
					this.workflowInProgress = false;
					this.designerService.designerProgressSpinnerEmitter.emit(new DesignProgresSpinnerEvent(false, WorkflowOperationType.UpdateSettings));
				});
	}

	/**
	 * Get the incoming Nodes of the given start node
	 * @param workflow Workflow
	 * @param nodeID Start node
	 * @returns Incoming Node IDs
	 */
	getIncomingNodes(workflow: Workflow, nodeID: string) {
		return workflow.Edges.filter((e) => e.TargetNode == nodeID).map(
			(ee) => ee.SourceNode
		);
	}

	configureStylesheet(graph: mxGraph) {
		const defaultVertexStyle = new Object();
		defaultVertexStyle[mx.mxConstants.STYLE_SHAPE] = mx.mxConstants.SHAPE_LABEL;

		mx.mxConstants.VERTEX_SELECTION_COLOR = "#000000";
		mx.mxConstants.VERTEX_SELECTION_DASHED = false;
		mx.mxConstants.VERTEX_SELECTION_STROKEWIDTH = 2;

		graph.getStylesheet().putDefaultVertexStyle(defaultVertexStyle);

		let edgeStyle = graph.getStylesheet().getDefaultEdgeStyle();
		edgeStyle[mx.mxConstants.STYLE_LABEL_BACKGROUNDCOLOR] = "#FFFFFF";
		edgeStyle[mx.mxConstants.STYLE_STROKEWIDTH] = "2";
		edgeStyle[mx.mxConstants.STYLE_ROUNDED] = true;
		edgeStyle[mx.mxConstants.STYLE_EDGE] = mx.mxEdgeStyle.EntityRelation;

		console.log("defaultVertexStyle", defaultVertexStyle)
	}

	/**
	 * Callback for mxGraph Style preparation.
	 */
	imageBundleFallback(): any { }

	protected onFireEvent(
		type: WorkflowGraphEventType,
		data: IWorkflowGraphEventData
	) {
		const event = new WorkflowGraphEvent(type, data);
		this.fireEvent(event);
	}

	/**
	 * Emit an event to the Designer Service
	 * @param event Event
	 */
	protected fireEvent<D>(event: WorkflowGraphEvent<D>) {
		this.designerService.workflowGraphEmitter.emit(event);
	}

	/**
	 * Die Div-Container aus dem PlugIn-Leiste wird draggable gemacht
	 * Wir holen aus dem Div-Container, das erste Kind (img span), damit der Cursor Offset funktioniert. Problem ist, dass die MutterDiv über p-col-4 gesteuert wird. 
	 * P-Col ist aber eine flexible Größe, abhängig von der Mutter, was dazu führt, dass der Offsett inkorrekt ist.
	 * @param elements 
	 * @param dragElt 
	 */
	assignDragDrop(elements: HTMLCollection, dragElt?: any) {

		if (this.graph) {
			const graph = this.graph;

			for (let i = 0; i < elements.length; i++) {

				const el = elements.item(i);

				if (el === undefined) continue;

				const el_span = el.children.item(0);

				const plugIn = this.plugInInfos[i];

				const callback_func = this.buildDragDropCallback(plugIn, this)
				var ds = mx.mxUtils.makeDraggable(<HTMLElement>el_span, graph, callback_func, el_span, -1000, 10000, true, true);

			}


		}

	}
	buildDragDropCallback(plugIn: WorkflowNodeInfo, self: WorkflowGraphComponent) {
		// const myFunc: (graphF: mxGraph,funct: Function,dragElement?: Node,x?: number,y?:number) => any = function(graphF,funct,dragElement?, x?, y?) {
		const myFunc: (graph, evt, cell, x, y) => any = function (graph, evt, cell, x: number, y: number) {

			// console.log("Drop Callback");
			// console.log(graph, "graph");
			// console.log(evt, "evt");
			// console.log(cell, "cell");
			// console.log(x, "x");
			// console.log(y, "y");

			const plugInInfo: WorkflowNodeInfo = plugIn;
			const offsetX = 0; // Adjust the offset value as needed
			const offsetY = 0;

			const adjustedX = (x - offsetX);
			const adjustedY = (y - offsetY);

			const settings = self.designerService
				.getNodeSettings(plugInInfo.Engine)
				.subscribe((res: SettingsResult<WorkflowNodeSettings, MetaInfo>) => {
					const properties = new NodeProperties(
						res.Configuration,
						res.MetaInfo
					);

					// console.log("x: ", x);
					// console.log("y: ", y);
					// console.log("adjustedX: ", adjustedX);
					// console.log("adjustedY: ", adjustedY);

					const gui_settings = new GuiSettings(
						adjustedX ? adjustedX : 0,
						adjustedY ? adjustedY : 0,
						self.config.vertexHeight,
						self.config.vertexWidth
					);

					const label_name = plugInInfo.Name.split(".").reverse()[0];

					// ID is undefined because it isset by _addNode method.
					const workflowNode = new WorkflowNode(
						"",
						gui_settings,
						properties,
						plugInInfo.Engine,
						label_name
					);

					self._addNode(workflowNode, adjustedX, adjustedY, plugInInfo);
				});
		}
		return myFunc
	}

	dragDropCallback(graph, evt, cell, x, y) {
		console.log("NEW DROP ROUTINE");

		if (this.lastDraggedNodeGuiInfo === undefined) return;

		const plugInInfo: WorkflowNodeInfo = this.lastDraggedNodeGuiInfo;
		const graphContainer = this.graph.container;

		// const bounds = graphContainer.getBoundingClientRect();
		// const containerX = event.clientX - bounds.left;
		// const containerY = event.clientY - bounds.top;

		// Use containerX and containerY for further calculations
		const offsetX = 0; // Adjust the offset value as needed
		const offsetY = 0;

		const adjustedX = (x - offsetX);
		const adjustedY = (y - offsetY);

		// console.log("x: ", x);
		// console.log("y: ", y);
		// console.log("adjustedX: ", adjustedX);
		// console.log("adjustedY: ", adjustedY);

		const settings = this.designerService
			.getNodeSettings(plugInInfo.Engine)
			.subscribe((res: SettingsResult<WorkflowNodeSettings, MetaInfo>) => {
				const properties = new NodeProperties(
					res.Configuration,
					res.MetaInfo
				);

				const gui_settings = new GuiSettings(
					adjustedX,
					adjustedY,
					this.config.vertexHeight,
					this.config.vertexWidth
				);

				const label_name = plugInInfo.Name.split(".").reverse()[0];

				// ID is undefined because it is set by _addNode method.
				const workflowNode = new WorkflowNode(
					"",
					gui_settings,
					properties,
					plugInInfo.Engine,
					label_name
				);

				this._addNode(workflowNode, adjustedX, adjustedY, plugInInfo);
			});


	}

	// private adjustContainerHeight(): void {
	// 	const containerEl = this.graphContainer.nativeElement;
	// 	const containerHeight = containerEl.scrollHeight;
	// 	containerEl.style.height = `${containerHeight}px`;
	//   }
}

/**
 * Workflow Layout and Behaviour information.
 */
export class WorkflowConfiguration {
	vertexWidth: number;
	vertexHeight: number;
	useEditorMode: boolean;

	constructor(
		vertexWidth: number,
		vertexHeight: number,
		useEditorMode: boolean
	) {
		this.vertexWidth = vertexWidth;
		this.vertexHeight = vertexHeight;
		this.useEditorMode = useEditorMode;
	}
}
