<div #StreamsToSync>

    <!-- <p-table [value]="streams" [(selection)]="selectedStreams" dataKey="code" -->

    <!-- <p-table [value]="catalog?.streams" [(selection)]="selected_streams" dataKey="stream.name"
        [tableStyle]="{'min-width': '50rem'}">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 4rem"> <p-tableHeaderCheckbox></p-tableHeaderCheckbox> </th>
                <th>Source Namespace</th>
                <th>Source Stream name</th>
                <th>Sync Mode</th>
            </tr>
        </ng-template> <ng-template pTemplate="body" let-product>
            <tr>
                <td> <p-tableCheckbox [value]="product"></p-tableCheckbox> </td>
                <td>{{product.stream.namespace}}</td>
                <td>{{product.stream.name}}</td>
                <td>{{product.stream.supportedSyncModes[0]}}</td>
            </tr>
        </ng-template>
    </p-table> -->

    <p-table [value]="genCatalog?.streams" [(selection)]="gen_selected_stream" dataKey="stream.name"
        [tableStyle]="{'min-width': '50rem'}" (selectionChange)="streamSelectionChanged($event)">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 4rem"> <p-tableHeaderCheckbox></p-tableHeaderCheckbox> </th>
                <!-- <th>Source Namespace</th> -->
                <th>{{'Source Stream name' | translate}}</th>
                <th>{{'Sync Mode' | translate}}</th>
            </tr>
        </ng-template> <ng-template pTemplate="body" let-product>
            <tr>
                <td> <p-tableCheckbox [value]="product" [disabled]="disabled"></p-tableCheckbox> </td>
                <!-- <td>{{product.stream.namespace}}</td> -->
                <td>{{product.stream.name}}</td>
                <td>
                    <p-dropdown [options]="writeModeInfos" appendTo="body" [(ngModel)]="product.config.writeMode"
                        optionLabel="Label" optionValue="GenWriteMode" [disabled]="disabled"></p-dropdown>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>