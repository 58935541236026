import { HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, from, of, throwError } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { Connection, StreamInfo } from 'src/app/models/airbyte-api-structures';
import { AirbyteModel } from 'src/app/models/api/models/AirbyteModel';
import { AirbyteStagingService } from 'src/app/services/airbyte-staging.service';

export interface DataRow {
  null: boolean;
  type: string;
  value: any;
}

interface TableColumn {
  key: any;
  label: string;
}

@Component({
  selector: 'app-view-ab-connection',
  templateUrl: './view-ab-connection.component.html',
  styleUrls: ['./view-ab-connection.component.scss']
})
export class ViewAbConnectionComponent implements OnInit {

  constructor(private route: ActivatedRoute, private airbyteService: AirbyteStagingService) { }

  id?: string;
  isLoading: boolean = false;
  progressMode: string = "determinate";
  connectionInfo?: AirbyteModel.ConnectionInfo<Connection>;

  //realStreams: StreamInfo[] = [];
  //realSelectedStreams: StreamInfo[] = [];

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    console.log("Connection Info ID: " + this.id);

    if (this.id)
      this.connInfoToView(this.id, true);
    else
      this.handleError(new Error("No Bion Source Id provided in route."));
  }

  /**
   * Loads the source connected to the given source info id.
   * @param id The source info id.
   */
  connInfoToView(id: string, fetchSource?: boolean) {

    const _id = parseInt(id);
    const conn_info_obs = this.getConnInfo(_id, fetchSource);

    this.setLoading(true);

    conn_info_obs.subscribe(
      source_info => this.handleResult(source_info),
      err => this.handleError(err),
      () => { this.setLoading(false) }
    )
  }

  /**
   * Get the single connection info for this id.
   * 
   * CAUTION: This is not the Airbyte Source Id, it's the Bion Source Info id.
   * @param id Connection info id
   * @returns 
   */
  getConnInfo(id: number, fetchSource?: boolean): Observable<AirbyteModel.ConnectionInfo<Connection>> {
    return this.airbyteService.connectionInfos(id, fetchSource).pipe(concatMap(infos => {

      if (infos.length == 0) {
        return throwError(new Error("There is no source info with id " + id));
      }
      if (infos.length > 1) {
        return throwError(new Error("There are multiple source infos with id " + id))
      }

      const info = infos[0];
      return of(info);

    }));
  }

  handleResult(info: AirbyteModel.ConnectionInfo<Connection>) {
    console.log("Connection Info Received", info);
    this.connectionInfo = info;
  }

  handleError(err: Error) {
    this.setLoading(false);
    console.log("Get Connection Info failed", err);
  }

  setLoading(loading: boolean): void {
    this.isLoading = loading;
    console.log("Set Loading", loading);

    // https://www.primefaces.org/primeng-v14-lts/progressbar

    if (this.isLoading) {
      this.progressMode = "indeterminate";
    } else {
      this.progressMode = "determinate";
    }
  }

  onSync(event: any) {
    console.log("On Sync", event);

    console.log("[WARN]: Sync not implemented yet!");
  }

  tableData = [];
  tableCols: TableColumn[] = [];

  onQueryRaw(event: any, item: StreamInfo) {
    console.log("On Query Raw", event, item);

    if (this.connectionInfo) {

      const name = item.stream.name;
      const id = this.connectionInfo.id;

      this.setLoading(true);
      const arg = new AirbyteModel.QueryStreamArg(name, id);

      const obs = this.airbyteService.queryStreamRaw2(arg).pipe(concatMap(blob => {

        const p = new Promise((resolve, reject) => {

          const reader = new FileReader();
          reader.onload = () => {
            const buffer = reader.result as ArrayBuffer;
            const jsonString = new TextDecoder().decode(buffer);
            console.log(jsonString);
            const json_strings = jsonString.split("\n");
            console.log(json_strings);

            const json_strings_safe = json_strings.slice(0, json_strings.length - 1);
            const json_data_arr = json_strings_safe.map(s => JSON.parse(s));

            console.log(json_data_arr);

            resolve(json_data_arr);

            //const jsonData = JSON.parse(jsonString);
            //console.log(jsonData);
            //resolve(jsonData);
          };
          reader.onerror = reject;
          reader.readAsArrayBuffer(blob);

        });

        const o = from(p);

        return o;

      }));

      this.setLoading(true);

      obs.subscribe((json_data: any) => {

        console.log(json_data);
        console.log(typeof (json_data));

        const data_arr = <Array<any>>(json_data);

        //let data_arr = []
        // json_data.then(v => {
        //   data_arr = <Array<any>>v;
        // });

        const result_array = [];

        for (let i = 0; i < data_arr.length; i++) {
          const row = data_arr[i];
          const ab_id = row[0];
          const ab_value = row[1];
          const ab_timestamp = row[2];

          const ab_val_typed: DataRow = ab_value;  // Das ist ein Fehler der JSON Serialisierung und wird in Zukunft behoben
          const js_row_str = ab_val_typed.value;
          const js_parse = JSON.parse(js_row_str);
          result_array.push(js_parse);
        }

        this.tableData = result_array;

        if (result_array.length > 0) {
          const obj1 = result_array[0];
          const tab_cols = new Array<TableColumn>();
          for (let k of Object.keys(obj1)) {
            tab_cols.push({ key: k, label: k })
          }
          this.tableCols = tab_cols;
          //this.tableCols = Object.keys(obj1);
        }

        console.log(this.tableCols);
        console.log(this.tableData);
      },
        err => this.handleError(err),
        () => this.setLoading(false)
      );


      // == DISABLED

      // this.airbyteService.queryStreamRaw(arg).subscribe(httpEvent => {

      //   console.log(httpEvent);

      //   if (httpEvent.type === HttpEventType.DownloadProgress) {
      //     console.log("download progress", httpEvent);
      //   }
      //   if (httpEvent.type === HttpEventType.Response) {
      //     console.log("donwload completed", httpEvent);

      //     // httpEvent.body.stream().pipeThrough()
      //     const text_prom = httpEvent.body.text()
      //     const body_stream = httpEvent.body.stream();
      //     const s1s2 = body_stream.tee();

      //     const s1 = s1s2[0];
      //     s1.getReader();

      //   }

      // },
      //   err => this.handleError(err),
      //   () => this.setLoading(false)
      // );

    } else {
      this.handleError(new Error("No connection info available"));
    }
  }

  onQuery(event: any, item: StreamInfo) {

    console.log("On Query", event, item);

    if (this.connectionInfo) {

      const name = item.stream.name;
      const id = this.connectionInfo.id;

      const arg = new AirbyteModel.QueryStreamArg(name, id);

      const obs = this.airbyteService.queryStream(arg).pipe(concatMap(blob => {

        const p = new Promise((resolve, reject) => {

          const reader = new FileReader();
          reader.onload = () => {
            const buffer = reader.result as ArrayBuffer;
            const jsonString = new TextDecoder().decode(buffer);
            console.log(jsonString);
            const json_strings = jsonString.split("\n");
            console.log(json_strings);

            const json_strings_safe = json_strings.slice(0, json_strings.length - 1);
            const json_data_arr = json_strings_safe.map(s => JSON.parse(s));

            console.log(json_data_arr);

            resolve(json_data_arr);
          };
          reader.onerror = reject;
          reader.readAsArrayBuffer(blob);

        });

        const o = from(p);

        return o;

      }));

      obs.subscribe((json_data: any) => {

        console.log(json_data);
        console.log(typeof (json_data));

        const data_arr = <Array<any>>(json_data);

        console.log("Warning: No Table Column Info fetched yet. Provide via API-Route or deliver dictionary-results");
        this.tableData = data_arr;
        const tab_cols: TableColumn[] = [];
        if (this.tableData.length > 0) {
          for (let i = 0; i < this.tableData[0].length; i++) {
            const col_label = "Column " + i;
            tab_cols.push({ key: i, label: col_label });
          }
          this.tableCols = tab_cols;
        }

        console.log(this.tableCols);
        console.log(this.tableData);
      },
        err => this.handleError(err),
        () => this.setLoading(false)
      );

    } else {
      this.handleError(new Error("No connection info available"));
    }
  }
}