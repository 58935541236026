
/**
 * Spark Select Plug-In
 */
export namespace SelectPlugIn {
    export class SelectInfo {
		Name: string;
		Select: boolean;
		NewName?: string;
		NewTypeName?: string;
		NewTypeLength?: number;
		constructor(Name: string,Select: boolean,NewName?: string,NewTypeName?: string,NewTypeLength?: number) {
			if(Name === undefined) throw new Error( "Class 'SelectInfo': Required value 'Name' is undefined" );
			if(Select === undefined) throw new Error( "Class 'SelectInfo': Required value 'Select' is undefined" );
			this.Name = Name;
			this.Select = Select;
			this.NewName = NewName;
			this.NewTypeName = NewTypeName;
			this.NewTypeLength = NewTypeLength;
		}
	}
	export class Settings {
		SelectInfos: Array<SelectInfo>;
		constructor(SelectInfos: Array<SelectInfo>) {
			if(SelectInfos === undefined) throw new Error( "Class 'Settings': Required value 'SelectInfos' is undefined" );
			this.SelectInfos = SelectInfos;
		}
	}
}