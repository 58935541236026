import { WorkflowNodeSettings } from "src/app/models/api/com/bion/etl/Workflow";
import { WorkflowEngineControllers } from "src/app/models/api/controllers/WorkflowEngineController";
import { NewWorkflowResult } from "src/app/models/api/models/workflow/NewWorkflowResult";
import { ExceptionInfo, ExecutePlugInResult, ExecutePortResult, WorkflowLogEntry, WorkflowResult } from "src/app/models/designer.models";

/**
 * Workflow Result Abstraction
 * @param R Workflow Result
 */
export interface WorkflowResultExtractor<R> {
    /**
     * Liefert die Knotendaten indexiert nach Knoten ID.
     * @param result Workflow result
     */
    nodeDataMap(result: R): Map<string, ExecutePlugInResult<WorkflowNodeSettings>>
    /**
     * Liefert den finalen Knotenstatus als Tuple-Array mit [ID,Status].
     * @param result Workflow result
     */
    nodeStates(result: R): Array<[string, string]>;
    /**
     * Liefert alle Knoten, die wegen eines Fehlers fehlgeschlugen als Tuple-Array mit [ID,Exception].
     * @param result 
     */
    errors(result: R): Array<[string, ExceptionInfo]>;
    /**
     * Liefert das Protokoll aller Knoten.
     * @param result 
     */
    log(result: R): WorkflowLogEntry[];
}

export class ClassicResultExtractor implements WorkflowResultExtractor<WorkflowResult> {
    nodeStates(result: WorkflowResult): [string, string][] {
        return result.NodeStates;
    }
    errors(result: WorkflowResult): [string, ExceptionInfo][] {
        return result.Errors;
    }
    log(result: WorkflowResult): WorkflowLogEntry[] {
        return result.Log;
    }
    nodeDataMap(result: WorkflowResult): Map<string, ExecutePlugInResult<WorkflowNodeSettings>> {
        return result.OutNodeData;
    }
}

export class NewResultExtractor implements WorkflowResultExtractor<NewWorkflowResult.RunResult<WorkflowEngineControllers.NodeRunResult<any>>> {
    nodeStates(result: NewWorkflowResult.RunResult<WorkflowEngineControllers.NodeRunResult<any>>): [string, string][] {

        console.log("WARNING - States not implemented yet, using dummy value / message");

        const states: [string, string][] = result.Output.WorkflowResult.NodeResults.map(ns => {
            const node_id = ns[0];
            const node_result = ns[1];

            const tuple: [string, string] = [node_id, "Not Implemented yet!"]

            return tuple;
        })

        return states;
    }
    errors(result: NewWorkflowResult.RunResult<WorkflowEngineControllers.NodeRunResult<any>>): [string, ExceptionInfo][] {

        console.log("Attention - Exeption Info may miss on the new object when there was just no error!");

        let safe_errors:[string, ExceptionInfo][] = [];

        result.Output.WorkflowResult.NodeResults.forEach(ns => {

            const id = ns[0];
            const value = ns[1].ActionResult.ErrorInfo;

            const tuple: [string, ExceptionInfo] = [id, value]

            if(value) {
                safe_errors.push(tuple);
            } else {
                console.log("Node " + id + " didn't produce errors");
            }
        })

        return safe_errors;
    }
    log(result: NewWorkflowResult.RunResult<WorkflowEngineControllers.NodeRunResult<any>>): WorkflowLogEntry[] {

        console.log("Attentions - Logs not yet harmonized!");

        const logs = result.Output.WorkflowResult.NodeResults.flatMap(ns => ns[1].ActionResult.Data.Log);

        const workflowLogs = logs.map(l => {
            const w = new WorkflowLogEntry();
            w.DateTime = l.DateTime;
            w.Language = l.Language;
            w.Level = l.Level;
            w.Message = l.Message;
            w.NodeID = undefined;
            return w;
        });

        return workflowLogs;
    }
    nodeDataMap(result: NewWorkflowResult.RunResult<WorkflowEngineControllers.NodeRunResult<any>>): Map<string, ExecutePlugInResult<WorkflowNodeSettings>> {

        console.log("Warning - Node Data Map not yet harmonized!")

        let map = new Map<string, ExecutePlugInResult<WorkflowNodeSettings>>();

        result.Output.WorkflowResult.NodeResults.forEach(ns => {

            const id = ns[0];
            const value = ns[1];

            const e_res = new ExecutePlugInResult();

            const node_data = value.ActionResult.Data;

            if (node_data) {
                e_res.Artifacts = node_data.Artifacts;
                e_res.Configuration = node_data.Configuration;
                
                let port_map = new Map<string,ExecutePortResult>();

                node_data.PortResults.forEach( p => {
                    const ep = new ExecutePortResult();
                    ep.Tables = p[1].Tables;
                    port_map.set(p[0],ep);
                });

                e_res.PortResults = port_map;

                map.set(id, e_res);

            } else {
                console.log("Node " + id + " has no data!");
            }
        })

        return map;
    }
}