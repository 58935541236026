<p>{{'DsIntInputNode.Header' | translate}}</p>
<div class="p-grid" style="margin-top:25px">

    <div class="p-col-12">
        <div class="p-fluid">
            <span class="p-float-label">
                <!-- <input type="text" id="dsnameinputtext" [disabled]="inputDisabled" pInputText
                    [(ngModel)]="workflowName"> -->
                <p-dropdown #DataSourceDrop id="DataSourceDropInput" [options]="config.Sources" [disabled]=""
                    [(ngModel)]="selected_source" optionLabel="Name" (onChange)="combo_changed($event)"
                    [autoDisplayFirst]="false"></p-dropdown>
                <label for="DataSourceDropInput">{{'1. Select a Datasource' | translate}}</label>
            </span>
        </div>

    </div>
    <div class="p-col-12">
        <div class="p-fluid">
            <span class="p-float-label">
                <!-- <input type="text" id="dsnameinputtext" [disabled]="inputDisabled" pInputText
                    [(ngModel)]="workflowName"> -->
                <p-dropdown #StreamDrop id="StreamDropInput" [options]="config.SourceDetail?.Streams"
                    [(ngModel)]="selected_stream" dataKey="Name" optionLabel="Name"
                    (onChange)="stream_combo_changed($event)" [autoDisplayFirst]="false"></p-dropdown>
                <label for="StreamDropInput">{{'2. Select a Stream' | translate}}</label>
            </span>
        </div>

    </div>
    <div class="p-col-12">
        <div class="p-fluid">
            <span class="p-float-label">
                <!-- <input type="text" id="dsnameinputtext" [disabled]="inputDisabled" pInputText
                    [(ngModel)]="workflowName"> -->
                <p-dropdown #PsaDrop id="PsaDropInput" [options]="selected_stream?.PSAs" [(ngModel)]="selected_psa"
                    dataKey="Table" optionLabel="Table" (onChange)="psa_combo_changed($event)"
                    [autoDisplayFirst]="false"></p-dropdown>
                <label for="PsaDropInput">{{'3. Select a Table' | translate}}</label>
            </span>
        </div>
    </div>

    <div class="p-col-12">
        <h5>{{'CurrentSettings' | translate}}</h5>

        <div class="flex flex-column gap-2">
            <!-- <label htmlFor="psa_name">Name</label> -->
            <input pInputText id="psa_name" aria-describedby="psa_name" [ngModel]="selected_psa?.Name"
                [disabled]="true" />
            <small id="psa_name">{{'PSA Name' | translate }}</small>
        </div>
        <div class="flex flex-column gap-2">
            <!-- <label htmlFor="psa_stream">Stream</label> -->
            <input pInputText id="psa_stream" aria-describedby="psa_stream" [ngModel]="selected_psa?.Stream"
                [disabled]="true" />
            <small id="psa_stream">{{'Stream Name' | translate }}</small>
        </div>
        <div class="flex flex-column gap-2">
            <!-- <label htmlFor="psa_table">Tabelle</label> -->
            <input pInputText id="psa_table" aria-describedby="psa_table" [ngModel]="selected_psa?.Table"
                [disabled]="true" />
            <small id="psa_table">{{'TableNameInDB' | translate }}</small>
        </div>

        <!-- <div>
            <p-checkbox label="Is Raw" [ngModel]="selected_psa?.IsRaw" [disabled]="false"></p-checkbox>
            <p-checkbox label="Is Normalized" [ngModel]="selected_psa?.IsNormalized" [disabled]="false"></p-checkbox>
            <p-checkbox label="Is Sub-Table" [ngModel]="selected_psa?.IsSubTable" [disabled]="false"></p-checkbox>
        </div> -->
    </div>
</div>