import { Id } from "src/app/helper/id";
import { StreamConfig, StreamDetail, SyncCatalog } from "src/app/models/airbyte-api-structures";
import { MetaResponse } from "src/app/services/airbyte-staging.service";

/**
 * Abstrahiert zwischen Katalogen.
 */
export interface AsCatalog<A> {
    /**
     * Liefert den generischen Katalog
     * @param a Original Katalog
     */
    toGenCatalog(a: A): GenCatalog;
    /**
     * Aktualisiert den original Katalog mit Hilfe des generischen Katalogs.
     * @param g Generischer Katalog
     * @param a Original Katalog
     */
    patchCatalog(g: GenCatalog, a: A): A;

    /**
     * Wählt aus dem original Result das Katalog Objekt aus.
     * @param r 
     */
    selectCatalog(r: any): A

    /**
     * Konverti die ursprüngliche Meta Antwort und den gepatchten Katalog in das Stream-Schema für die Zielfunktion.
     * @param orignalResult 
     * @param patchedCatalog 
     */
    asStreamSchema(orignalResult: any, patchedCatalog: any): any;
}

export interface PatchStream<A> {
    patch(a: A, g: GenStreamDetail): A;
}

export interface AsGenWriteMode<A> {
    from(w: GenWriteMode): A;
    to(a: A): GenWriteMode;
}

export class Builder {
    static genCatalog(streams: GenStreamInfo[]): GenCatalog {
        return { streams: streams };
    }

    static syncModeInfo(SourceMode: SourceSyncMode,
        TargetMode: TargetSyncMode,
        label: string, isAirbyte: boolean,
        isBion: boolean, GenWriteMode: GenWriteMode): SyncModeInfo {

        return {
            SourceMode: SourceMode,
            TargetMode: TargetMode,
            label: label,
            isAirbyte: isAirbyte,
            isBion: isBion,
            GenWriteMode: GenWriteMode
        }
    }

    static genStreamInfo(stream: GenStreamDetail,
        config: GenStreamConfig,): GenStreamInfo {
        return {
            stream: stream,
            config: config
        }
    }

    static genStreamDetail(name: string, sourcePrimaryKeys: string[][],
        supportedSyncModes: SourceSyncMode[], namespace?: string): GenStreamDetail {

        return {
            name: name,
            namespace: namespace,
            sourcePrimaryKeys: sourcePrimaryKeys,
            supportedSyncModes: supportedSyncModes,
        }
    }

    static genStreamConfig(writeMode: GenWriteMode, cursorField: string[],
        primaryKey: string[][], selected: boolean, suggested: boolean): GenStreamConfig {

        return {
            writeMode: writeMode, cursorField: cursorField,
            primaryKey: primaryKey, selected: selected,
            suggested: suggested
        }

    }
}

/**
 * Singleton
 */
export class GenericInfo {

    readonly BionOrigin = "Bion";
    readonly AirbyteOrigin = "Airbyte";

    readonly smiAppend = Builder.syncModeInfo(SourceSyncMode.FullRefresh, TargetSyncMode.Append, "Full Refresh - Append", true, true, GenWriteMode.Append);
    readonly smiReplace = Builder.syncModeInfo(SourceSyncMode.FullRefresh, TargetSyncMode.Overwrite, "Full Refresh - Overwrite", true, true, GenWriteMode.Replace);

    readonly smiAbIncAppend = Builder.syncModeInfo(SourceSyncMode.Incremental, TargetSyncMode.Append, "Incremental - Append", true, false, GenWriteMode.AirbyteIncAppend);
    readonly smiAbIncDedup = Builder.syncModeInfo(SourceSyncMode.Incremental, TargetSyncMode.AppendDedup, "Incremental - Append Deduped History", true, false, GenWriteMode.AirbyteIncDedup);

    SyncModes: SyncModeInfo[] = [
        this.smiReplace,
        this.smiAppend,
        this.smiAbIncAppend,
        this.smiAbIncDedup
    ];

    private static _instance?: GenericInfo = undefined;

    static instance(): GenericInfo {
        if (this._instance === undefined) {
            this._instance = new GenericInfo();
        }

        return this._instance;
    }
}

/**
 * Das Stream Schema für Airbyte
 */
export interface AirbyteStreamSchema {
    catalog: SyncCatalog;
    catalogId: string;
}

export class AsAirbyteCatalog implements AsCatalog<SyncCatalog> {
    asStreamSchema(orignalResult: any, patchedCatalog: any) : AirbyteStreamSchema {
        const or: MetaResponse = orignalResult;
        const pc: SyncCatalog = patchedCatalog;

        return { catalog: pc, catalogId: or.catalogId };
    }
    selectCatalog(r: MetaResponse): SyncCatalog {
        return r.catalog;
    }

    readonly SourceSyncRefresh = "full_refresh";
    readonly SourceSyncIncrement = "incremental";

    readonly TargetSyncOverwrite = "overwrite";
    readonly TargetSyncAppend = "append";
    readonly TargetSyncDedup = "append_dedup";

    readonly SourceSyncMap: [string, SourceSyncMode][] = [
        [this.SourceSyncRefresh, SourceSyncMode.FullRefresh],
        [this.SourceSyncIncrement, SourceSyncMode.Incremental]
    ];

    readonly TargetSyncMap: [string, TargetSyncMode][] = [
        [this.TargetSyncOverwrite, TargetSyncMode.Overwrite],
        [this.TargetSyncAppend, TargetSyncMode.Append],
        [this.TargetSyncDedup, TargetSyncMode.AppendDedup]
    ];

    readonly SyncModeInfos: SyncModeInfo[] = [
        GenericInfo.instance().smiAppend,
        GenericInfo.instance().smiReplace,
        GenericInfo.instance().smiAbIncAppend,
        GenericInfo.instance().smiAbIncDedup
    ]

    toGenCatalog(a: SyncCatalog): GenCatalog {

        const streams = a.streams.map(s => {
            const d = this.mk_detail(s.stream);
            const c = this.mk_config(s.config);
            return Builder.genStreamInfo(d, c);
        })

        return Builder.genCatalog(streams);
    }
    patchCatalog(g: GenCatalog, a: SyncCatalog): SyncCatalog {

        a.streams.forEach(a_s => {

            g.streams.forEach(g_s => {
                if (a_s.stream.name == g_s.stream.name && a_s.stream.namespace == g_s.stream.namespace) {
                    a_s.config.selected = g_s.config.selected;
                    a_s.config.cursorField = g_s.config.cursorField;
                    const info = GenericInfo.instance().SyncModes.find(sm => sm.GenWriteMode == g_s.config.writeMode);
                    if (info) {
                        a_s.config.syncMode = Id.assertSet(this.SourceSyncMap
                            .find(ssm => ssm[1] == info.SourceMode), new Error("No Airbyte sync mode found for " + info.SourceMode))[0];
                        a_s.config.destinationSyncMode = Id.assertSet(this.TargetSyncMap
                            .find(tsm => tsm[1] == info.TargetMode), new Error("No Airbyte target sync mode found for " + info.TargetMode))[0];
                    } else {
                        throw new Error("No Symc Mode Info found for " + g_s.config);
                    }
                }
            })
        })

        return a;
    }

    /**
 * Simplifizierte Version ohne update & delta
 * @param s Sipmli
 */
    toGenWriteMode(s: string): GenWriteMode[] {

        // Aus zwei supported sync modes, werden vier mögliche Schreibmodus, des für jeden Source Modus zwei Ziel gibt -> 2*2
        //
        // SyncModes = {full_refresh,incremental}
        // DestSyncModes = {append,append_dedup,overwrite}
        //
        // Das ist KEIN Kreuzprodukt. Unterstütz wird hier nur eine Teilmenge (SSM)
        // SSM <= {full_refresh,incremental} x {append,append_dedup,overwrite}

        // Es sind nur die folgenden vier Kombinationen möglich:

        // (full_refresh,overwrite)
        // (full_refresh,append)
        // (incremental,append)
        // (incremental,append_dedup)

        // Siehe auch:
        // https://airbyte.com/blog/understanding-data-replication-modes

        if (s == this.SourceSyncRefresh) {
            return [GenWriteMode.Append, GenWriteMode.Replace];
        } else {
            return [];
        }
    }

    private mk_detail(s: StreamDetail): GenStreamDetail {

        console.log("WARNING - Write Mode Missing");
        console.log("Warning - pre selected write modes: Use them!");

        const gen_sync_modes_infos = s.supportedSyncModes.map(ssm => this.SourceSyncMap.find(m => m[0] == ssm));
        const source_sync_modes = gen_sync_modes_infos.map(s => s[1]);
        const sd = Builder.genStreamDetail(s.name, s.sourceDefinedPrimaryKey, source_sync_modes, s.namespace);

        return sd;
    }
    private mk_config(s: StreamConfig): GenStreamConfig {
        const wm = this.mk_writeMode(s);
        const sc = Builder.genStreamConfig(wm, s.cursorField, s.primaryKey, s.selected, s.suggested);

        return sc;
    }

    private mk_writeMode(s: StreamConfig): GenWriteMode {
        const source = this.SourceSyncMap.find(ssm => ssm[0] == s.syncMode)?.[1];
        const target = this.TargetSyncMap.find(tsm => tsm[0] == s.destinationSyncMode)?.[1];
        const info = GenericInfo.instance().SyncModes.find(sm => sm.SourceMode == source && sm.TargetMode == target);
        if (info) {
            return info.GenWriteMode;
        } else {
            throw new Error("No generic write mode found for stream config: " + s);
        }
    }
}

// TODO use structures from com.bion.connect.core
export interface BionCatalog {

}

export class AsBionCatalog implements AsCatalog<BionCatalog> {
    asStreamSchema(orignalResult: any, patchedCatalog: any) {
        throw new Error("Method not implemented.");
    }
    selectCatalog(r: any): BionCatalog {
        return r["Catalog"];
    }
    toGenCatalog(a: BionCatalog): GenCatalog {
        console.warn("Dummy -> remove soon!");
        return a["Catalog"];
    }
    patchCatalog(g: GenCatalog, a: BionCatalog): BionCatalog {
        throw new Error("Method not implemented.");
    }
}

export interface GenCatalog {
    streams: GenStreamInfo[];
}

export interface GenStreamInfo {
    stream: GenStreamDetail;
    config: GenStreamConfig;
}

export interface GenStreamConfig {
    writeMode: GenWriteMode;
    cursorField: string[];
    primaryKey: string[][];
    selected: boolean;
    suggested: boolean;
}

/**
 * Abstrahiert über Streams. Der Nutzer muss nur 'selected' und 'writeMode' festlegen.
 */
export interface GenStreamDetail {
    name: string;
    namespace?: string;
    sourcePrimaryKeys: string[][];
    supportedSyncModes: SourceSyncMode[];
}

/**
 * Aktuell noch eine Enumeration, vielleicht machen wir da einen Produkt-Typ drauß (sourceWriteMode,targetWriteMode)
 */
export enum GenWriteMode {
    Append,
    Replace,
    AirbyteIncAppend,
    AirbyteIncDedup,
    BionUpdate,
    BionDelta
    // Airbyte incremental|append, incremental|deduped, Bion update, Bion delta coming soon.
}

// coming soon
export interface GenWriteModeInfo {
    mode: GenWriteMode;
    requiresCursor: boolean;
    requiresKeys: boolean;
}

// Hilfsklassen für die Airbyte Schreibmethoden.
// Airbyte trennt hier zwischen Quell- und Zielschreibmethoden

// Stream Info: SourceSyncMode[]
// Stream Config: (SoureSyncMode,TargetSyncMode)

export enum SourceSyncMode {
    FullRefresh,
    Incremental
}

export enum TargetSyncMode {
    Append,
    AppendDedup,
    Overwrite
}

/**
 * Vereinigt die Schreib-/Sync-Modi von Airbyte und Bion.
 */
export interface SyncModeInfo {
    SourceMode: SourceSyncMode;
    TargetMode: TargetSyncMode;
    label: string;
    /**
     * Supported by Airbyte
     */
    isAirbyte: boolean;
    /**
     * Supported by Bion
     */
    isBion: boolean;
    GenWriteMode: GenWriteMode;
}