import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Message } from 'primeng/api';
import { Id } from 'src/app/helper/id';
import { IntegratedSourceModel } from 'src/app/models/api/models/IntegratedSourceModel';
import { IntegratedSourceService } from 'src/app/services/integrated-source.service';

@Component({
  selector: 'app-push-int-source-dialog',
  templateUrl: './push-int-source-dialog.component.html',
  styleUrls: ['./push-int-source-dialog.component.scss']
})
export class PushIntSourceDialogComponent implements OnInit {

  constructor(private apiService: IntegratedSourceService) { }

  isLoading: boolean = false;
  progressMode: string = "determinate";
  messages: Message[] = [];

  // Diese Komponente wird Teil der Data Source Übersicht
  // Sie hat ein öffentliches Flag um angezeigt zu werden
  // Der Dialog sollte beim Schließen immer zurück gesetzt werden

  // Verhalten:
  // Wenn alles ausgewählt ist, gibt diese Komponente Rückmeldung an die Mutter (Data Source Liste)
  // Die Mutter ruft die API Funktion auf.
  // Wenn der Aufruf funktioniert hat, schließt die Mutter diesen Dialog
  // Wenn nicht, lässt die Mutter den Dialog offen und gibt den Fehler raus


  @Input()
  sourceKey?: IntegratedSourceModel.DataSourceKey<number> = undefined;

  @Input()
  source?: IntegratedSourceModel.DataSource<number> = undefined;  // Nice to have but not required

  @Input()
  showPushFileDialog: boolean = false;
  @Output() showPushFileDialogChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit(): void {
  }

  dlgOnHide() {

    console.log("On Hide", this.showPushFileDialog);

    this.reset();

    this.showPushFileDialogChange.emit(this.showPushFileDialog);
  }

  /**
   * Clear all members and set to initial states.
   */
  reset() {
    // clear all data.
    this.sourceKey = undefined;
    this.isLoading = false;
    this.progressMode = "determinate";
    this.messages = [];
  }

  onFileSelected(event: any): void {

    console.log("On file selected");

    try {
      const file = event.target.files[0];
      if (event.files && event.files.length > 0) {
        this.selectedFileName = event.files[0].name;
      } else {
        this.selectedFileName = '';
      }
      this.uploadFile(file, this.sourceKey);
    } catch (err) {
      this.handle_error(err);
    }
  }

  uploadFile(file: File, source_key: IntegratedSourceModel.DataSourceKey<number>): void {

    console.log("Upload File");

    Id.assertSet(source_key, new Error("The soure key is not set!"));

    console.warn("TODO: move to mother component.");

    this.setLoading(true);

    this.apiService.syncFile(file, source_key)
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          const progress = Math.round(100 * event.loaded / event.total);
          // Hier kannst du den Fortschritt verarbeiten
          console.log("Progress", progress);
        } else if (event.type === HttpEventType.Response) {
          // Hier kannst du die Serverantwort verarbeiten
          console.log("Response", event);
        }
      }, err => this.handle_error(err),
        () => this.setLoading(false));
  }

  btnCloseClick() {
    // fire events.
    // mother decides.
  }

  handle_error(e: Error) {
    //alert(e.message);
    this.messages.push({ severity: 'error', summary: 'Error', detail: e.message });
    console.log("Error Handler", e);

    this.setLoading(false);
  }

  setLoading(loading: boolean): void {
    this.isLoading = loading;

    // https://www.primefaces.org/primeng-v14-lts/progressbar

    if (this.isLoading) {
      this.progressMode = "indeterminate";
    } else {
      this.progressMode = "determinate";
    }
  }


  selectedFileName: string;
}
