import { HttpUrlEncodingCodec } from "@angular/common/http";
import { AuthConfig } from "angular-oauth2-oidc";
import { ParamInfo } from "src/app/services/api-util";


export class AuthFlowUtils {
    static configToParams(config: AuthConfig, state?: string) {
        const c = config;

        const params = [
            { param: "response_type", value: c.responseType },
            { param: "client_id", value: c.clientId },
            { param: "state", value: state },
            { param: "redirect_uri", value: c.redirectUri },
            { param: "scope", value: c.scope }
        ]

        return params;
    }

    static encodeUrlParams(paramList: ParamInfo[]): ParamInfo[] {

        const codec = new HttpUrlEncodingCodec();

        const result = new Array<ParamInfo>();

        for (let p of paramList) {
            const ek = codec.encodeKey(p.param);
            const ev = codec.encodeValue(p.value);

            result.push({ param: ek, value: ev });
        }

        return result;
    }

    static makeUrlParams(encoded: ParamInfo[]): string {

        const pairs = new Array<string>();
        for (let p of encoded) {
            const pair = p.param + "=" + p.value;
            pairs.push(pair);
        }

        if (pairs.isEmpty())
            return "";
        else
            return pairs.reduce((a, b) => a + "&" + b)
    }
}