import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { of, throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { concatMap } from 'rxjs/operators';
import { AppMainComponent } from 'src/app/app.main.component';
import { WorkflowRepositoryEntry } from 'src/app/models/api/models/workflow/WorkflowRepositoryEntry';
import { DestinationActionEvent, DestinationDialogActionType, ScheduleActionEvent, ScheduleDialogActionType, WorkflowActionEvent, WorkflowActionEventStatus, WorkflowDialogActionType } from 'src/app/models/dialog-actions.model';
import { CubesService } from 'src/app/services/cubes.service';
import { DatasourcesService } from 'src/app/services/datasources.service';
import { SchedulesService } from 'src/app/services/schedules.service';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { SubSink } from 'subsink';
import { DashboardDummyService, DashboardKpiResult, KPI, KpiEntry, TimeId, Zeitscheibe } from './dashboard-kpi-dummy.service';
import { DropDownEvents } from 'src/app/helper/events';
import { Router } from '@angular/router';

export interface ZeitSchreibenView {
  abb: string;
  tooltip: string;
  value: Zeitscheibe
}

export interface KPIView {
  img: string;
  title: string;
  current: KpiEntry;
  previous: KpiEntry;
  percentage: number;
  dimension: string;
  hasLeftNeighbor: boolean;
  hasRightNeighbor: boolean;
}


@Component({
  selector: 'app-dashboard-v2',
  templateUrl: './dashboard-v2.component.html',
  styleUrls: ['./dashboard-v2.component.scss'],
  providers: [MessageService]
})
export class DashboardV2Component implements OnInit {
  subs = new SubSink;
  selectedTimePeriod: any;
  selectedReport: any
  selectedWorkflow?: WorkflowRepositoryEntry;

  hovered1: boolean = false;
  hovered2: boolean = false;
  hovered3: boolean = false;

  onMouseEnter1(): void {
    this.hovered1 = true;
  }

  onMouseLeave1(): void {
    this.hovered1 = false;
  }
  onMouseEnter2(): void {
    this.hovered2 = true;
  }

  onMouseLeave2(): void {
    this.hovered2 = false;
  }
  onMouseEnter3(): void {
    this.hovered3 = true;
  }

  onMouseLeave3(): void {
    this.hovered3 = false;
  }

  // Declare a variable to keep track of the menu state
isMenuOpen: boolean = false;

// Function to toggle the menu state
toggleMenu(): void {
  this.isMenuOpen = !this.isMenuOpen;
}


  constructor(public appMain:AppMainComponent,private router: Router, private datasourceService: DatasourcesService, private workflowService: WorkflowsService, private destService:CubesService, private schedService: SchedulesService, private translate: TranslateService, private errorService: SystemMessageLogService, private messageService: MessageService, private dashboardService: DashboardDummyService ) {

  }
  ngOnDestroy() {
    this.subs.unsubscribe()
  }

  ngOnInit(): void {
    this.subs.sink = this.workflowService.workflowDialogActionReceivedEmitter.subscribe((wfEvent:WorkflowActionEvent) => {
      this.createNewWorkflow(wfEvent);
    }, (err: Error) => {
        this.errorService.handleError(err);
    })

    const cat = [this.currentCategory];
    const zeit = this.currentZeitscheibe.value;
    const arg = DashboardDummyService.buildDashboardArg(cat, [], zeit);

    this.subs.sink = this.dashboardService.getKpisFromBE(arg).subscribe((kpiRes) => {
      this.kpiViews = kpiRes.KPIs.map( (kpi,i) => this.kpiToKpiView(kpi,i, kpiRes.KPIs));
      console.log("Meine Dashboard KPIS", this.kpiViews);
    },(err:Error) => {
      this.errorService.handleError(err)
    });

  }



  // startTour() {
  //   console.log("started")
  //   this.appMain.updateChatbotScript()
  // }

  onActionClicked(action: string) {
    if(action === "newAutomation") {

    }
    if(action === "newDatasource") {
      //this.datasourceService.displayCreateDatasource.emit(true);
      this.router.navigateByUrl('/SourceIntegrationChooseConnector')
    }
    if(action === "newWorkflow") {
      this.workflowService.workflowDialogActionSendEmitter.emit(new WorkflowActionEvent(true, "Create workflow", WorkflowDialogActionType.createWorkflow, "Create", undefined, undefined));

    }
    if(action === "newDestination") {
      this.router.navigateByUrl('/chooseDestConnectorPage')

      //this.destService.destinationDialogActionSendEmitter.emit(new DestinationActionEvent(true, "Create destination", DestinationDialogActionType.createDestination, "Create", undefined));

    }
    if(action === "newSchedule") {
      this.schedService.scheduleDialogActionSendEmitter.emit(new ScheduleActionEvent(true, "Create schedule", ScheduleDialogActionType.createSchedule, "Create", undefined));

    }
    if(action === "GoToGetStarted") {
      window.open(
              "https://bion-analytics.getgist.help/collection/10-ihre-ersten-schritte-mit-bion", "_blank");
  }
    if(action === "GoToHelpcenter") {
        window.open(
                "https://bion-analytics.getgist.help", "_blank");
    }
    if(action === "EmailSupport") {
        const subject = this.translate.instant("I have a question");
        const bodyText = this.translate.instant("Hi Bion support, i have question:");
        const mailText = "mailto:support@bion-analytics.com?subject="+subject+"&body="+bodyText; // add the links to body
        window.location.href = mailText;
    }
    if(action === "GiveFeedback") {
      const subject = this.translate.instant("I Want to give a feedback");
      const bodyText = this.translate.instant("Hi Bion Team, i want to give a feedback:");
      const mailText = "mailto:feedback@bion-analytics.com?subject="+subject+"&body="+bodyText; // add the links to body
      window.location.href = mailText;
  }
    console.log("Start Tour disabled.");
    // if(action == "Tour") {
    //   console.log("Tour started")
    //   this.appMain.startTour();
    // }
  }



    /**
	 * Erstellt einen neuen und leeren Workflow
	 */
	createNewWorkflow(wfEvent: WorkflowActionEvent) {

		const wf_obs = wfEvent.wf ? of(wfEvent.wf) : throwError(new Error("No workflow entry given"));

		const create_obs = wf_obs.pipe(concatMap(wf => this.workflowService.createWorkflowObject(wf)));

		this.subs.sink = create_obs
			.subscribe((res: WorkflowRepositoryEntry) => {
				this.messageService.add({
					severity: "success",
					summary: this.translate.instant("Message.CreateWorkflowSuccess.Title"),
					detail: this.translate.instant("Message.CreateWorkflowSuccess.Text1") + res.id + this.translate.instant("Message.CreateWorkflowSuccess.Text2"),
				});

				this.selectedWorkflow = { ...res };
				this.workflowService.workflowsChangedEmitter.emit("New workflow created");
				this.workflowService.workflowDialogActionStatusEmitter.emit(new WorkflowActionEventStatus(true, wfEvent.actionType, true, false, res));
				//this.createWorkflowSuccess = true;
			}, (error: Error) => {
				this.errorService.handleError(error);
			});
	}


  // DASHBOARD KPI-------------------------------------------------------
  allZeitscheiben: ZeitSchreibenView[] = [
    { tooltip: 'This week', abb: "W", value: Zeitscheibe.Woche },
    { tooltip: 'This month', abb: "M", value: Zeitscheibe.Monat },
    { tooltip: 'This quarter', abb: "Q", value: Zeitscheibe.Quartal },
    { tooltip: 'This year', abb: "Y", value: Zeitscheibe.Jahr  }
  ];
  reports: string[] = ['Automation','Usage'];
  kpis: KPI[];
  kpiViews: KPIView[];
  currentZeitscheibe: ZeitSchreibenView = this.allZeitscheiben[1];
  currentCategory:string = "Automation";


  // Event Handler
  /**
   * Gui Handler for changing the timeframe (W,M,Q,Y)
   * @param evt captured user event
   */
  onChangePeriod(evt: DropDownEvents.OnChange<any,ZeitSchreibenView>) {
      this.currentZeitscheibe = evt.value;
      console.log("onChangePeriod",evt);

      //Event bezieht sich auf das Attribut time period
      const cat = [this.currentCategory];
      const zeit = this.currentZeitscheibe.value;
      const arg = DashboardDummyService.buildDashboardArg(cat, [], zeit);
      this.subs.sink = this.dashboardService.getKpisFromBE(arg).subscribe((kpiRes) => {
        this.kpiViews = kpiRes.KPIs.map( (kpi,i) => this.kpiToKpiView(kpi,i, kpiRes.KPIs));
      },(err:Error) => {
        this.errorService.handleError(err)
      })
  }

  /**
   * Gui Handler for changing the category
   * @param evt captured user event
   */
  onChangeCategory(evt: DropDownEvents.OnChange<any,string>) {
      this.currentCategory = evt.value;
      console.log("onChangeCategory",evt);

      //Event bezieht sich auf das Attribut category
      const cat = [this.currentCategory];
      const zeit = this.currentZeitscheibe.value;
      const arg = DashboardDummyService.buildDashboardArg(cat, [], zeit);

      this.subs.sink = this.dashboardService.getKpisFromBE(arg).subscribe((kpiRes) => {
        this.kpis = kpiRes.KPIs;
        this.kpiViews = kpiRes.KPIs.map( (kpi,i) => this.kpiToKpiView(kpi,i, kpiRes.KPIs));
      },(err:Error) => {
        this.errorService.handleError(err)
      })
  }

  /**
   * Calculates the increase or decrease (compared to previous period) in percentage for !one! KPI
   * @param current
   * @param previous
   * @returns percentage value, e.g. 80 for 80%
   */
  calcPercentage(current: number, previous: number): number {
    const perc = (current / previous) - 1;
    const perc_100 = perc * 100;
    const perc_short = Math.round(perc_100)

    return perc_short
  }
  createDimensionString(dim_time: TimeId): string {
    let dim_string = "";

    if(dim_time.Year) {
      dim_string = "im Vorjahr"
    };
    if(dim_time.Day && dim_time.Year) {
      dim_string = "am Vortag"
    };
    if(dim_time.Week && dim_time.Year) {
      dim_string = "in der Vorwoche"
    };
    if(dim_time.Month && dim_time.Year) {
      dim_string = "im Vormonat"
    };
    if(dim_time.Quarter && dim_time.Year) {
      dim_string = "im Vorquartal"
    };
    if(dim_time.HalfYear && dim_time.Year) {
      dim_string = "im letzten Halbjahr"
    };


    return dim_string;
  }

  /**
   * transforms !one! KPI into a KPIView
   * @param kpi kpi to transform to view
   * @param index current index of the kpi (neede for style purposes only)
   * @param kpis list of all available kpis (neede for style purposes only)
   * @returns final KPIView
   */
  kpiToKpiView(kpi: KPI, index: number, kpis: KPI[]): KPIView {

    //Prepare view class
    const current = kpi.entries.find(e => e.offset === 0);
    const previous = kpi.entries.find(e => e.offset === -1);
    const perc = this.calcPercentage(current.value.value, previous.value.value);

    const dim_array = kpi.entries.map(e => e.dimension);
    const dim_time = dim_array[0].time;
    const final_dim_string = this.createDimensionString(dim_time);

    //Prepare view style
    const hasLeftNeighbor = (index > 0);  // Check if there is a left neighbor
    const hasRightNeighbor = (index < kpis.length - 1);  // Check if there is a right neighbor

    const view = { img: kpi.img, title: kpi.title, current: current, previous: previous, percentage: perc, dimension: final_dim_string, hasLeftNeighbor: hasLeftNeighbor, hasRightNeighbor: hasRightNeighbor }
    return view
  }




}
