

export class Id {
    /**
     * Stellt sicher, dass der Wert gesetzt ist, anderfalls wird ein Fehler geworfen.
     * @param value Der zu testende Wert
     * @param error Der Fehler, falls der Wert nicht gesetzt ist.
     * @returns Der gesetzte Wert
     */
    static assertSet<A, E extends Error>(value: A | undefined, error: E): A {
        if (value !== undefined) {
            return value;
        } else {
            throw error;
        }
    }

    /**
     * Ruft assertSet auf. Erspart das Erstellen einer Exception.
     * @param value Der zu testende Wert
     * @param message Der Fehler, falls der Wert nicht gesetzt ist.
     * @param addPrefix Flag für optionalen Prefix
     * @param messagePrefix Prefix für die Nachricht zum besseren Tracking.
     * @returns Der gesetzte Wert
     */
    static assertSetMsg<A>(value: A | undefined, message: string, addPrefix: boolean = false, messagePrefix: string = "Assertion Failed - Value undefined: "): A {
        const msg = addPrefix ? messagePrefix + message : message;
        return this.assertSet(value, new Error(msg));
    }

    /**
     * Stellt sicher, dass die Bedingung wahr ist.
     * @param condition Bedingung
     * @param error Fehler, wenn die Bedingung falsch ist.
     */
    static assert<E extends Error>(condition: boolean, error: E): void {
        if (!condition) throw error;
    }

    static isNullOrUndefined(val: any) {
        return val === undefined || val === null;
    }
}