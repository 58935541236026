<div class="p-col-12" *ngIf="toggleView === true">
    <div class="card">
        <div class="card-header">
            <div class="card-title">
                <h6>Table Statistics</h6>
            </div>
        </div>
        <p-table #tst [value]="tableStatsRecords" [columns]="tableCols" [rows]="10" paginator=false [scrollable]="false"
            styleClass="p-datatable-customers p-datatable-sm">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" [width]="col.width">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            {{col.header}}
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                    <td *ngFor="let col of columns"><span class="p-column-title">{{col.header}}</span>
                        {{rowData[col.field]}}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="4">No stats found.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<div class="p-col-12" *ngIf="toggleView === true">
    <div class="card device-status">
        <div class="card-header">
            <div class="card-title">
                <h6>Column Statistics</h6>
            </div>
        </div>
        <p-table #cst [value]="columnStatsRecords" [columns]="columnCols" sortMode="single"
            [globalFilterFields]="['dataSource','psa','user','loadSuccessful','startTime']" [rows]="10" paginator=false
            [scrollable]="false" styleClass="p-datatable-customers p-datatable-sm">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" [width]="col.width">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            {{col.header}}
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                    <td *ngFor="let col of columns"><span class="p-column-title">{{col.header}}</span>
                        {{rowData[col.field]}}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="7">No stats found.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>




<div class="" style="width: 100%;height:50px; text-align: center" *ngIf="toggleView === true">

    <!-- <button pButton style="margin-top: 3px" (click)="op.toggle($event)" class="p-button-rounded p-button-text p-button-sm">Statistics</button> -->
    <div class="p-d-flex p-justify-center">
        <p-chart type="bar" [data]="chartData" [options]="chartOptions" [style]="{'width': '150px'}" height="40px">
        </p-chart>
    </div>
</div>




<!--
    <p-table #tst [value]="tableStatsRecords" [columns]="tableCols" [rows]="3" paginator=false [scrollable]="false"
        styleClass="p-datatable-customers p-datatable-sm">
        <ng-template pTemplate="caption" >
            <h6>Table Statistics</h6>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" [width]="col.width">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{col.header}}
                    </div>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td *ngFor="let col of columns" [style]="{'font-size':'12px'}"><span class="p-column-title">{{col.header}}</span>
                    {{rowData[col.field]}}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="4">No stats found.</td>
            </tr>
        </ng-template>
    </p-table> -->
<p-chart type="bar" [data]="chartData" [options]="chartOptions" [style]="{'width': '150px'}" height="40px">
</p-chart>

<!-- <p-table #cst [value]="columnStatsRecords" [columns]="columnCols" sortMode="single"
        [globalFilterFields]="['dataSource','psa','user','loadSuccessful','startTime']" [rows]="5" paginator=false
        [scrollable]="false" styleClass="p-datatable-customers p-datatable-sm">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" [width]="col.width">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{col.header}}
                    </div>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td *ngFor="let col of columns"  [style]="{'font-size':'12px'}"><span class="p-column-title">{{col.header}}</span>
                    {{rowData[col.field]}}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="7">No stats found.</td>
            </tr>
        </ng-template>
    </p-table> -->

<p-carousel [showNavigators]="true" [showIndicators]="true" #nodesList [value]="columnStatsRecords" [numVisible]="6"
    [numScroll]="6" [circular]="false" [responsiveOptions]="responsiveOptions">
    <ng-template let-node pTemplate="item">
        <div class="product-item">
            <div class="product-item-content">
                <!-- <div class="p-mb-3">
                        <img [src]="'assets/layout/images/nodes/' + node.NameLabel + '.png'" class="product-image"
                            [alt]="node.NameLabel" />
                        </div> -->
                <h6 class="p-mb-1 product-name">{{node['Column Name']}}
                    <span style="margin-left: 5px;font-size:8px"
                        [class]="'customer-badge status-' + node['DataType']">{{node['DataType']}}</span>
                </h6>
                <p-divider></p-divider>

                <div style="margin-top: 10px;">
                    <!-- <p class="p-mb-1 product-name"><i class="pi pi-table" style="margin-right: 10px;"></i>COUNT: {{node['count']}}</p> -->
                    <p class="p-mb-1 product-name"><i class="pi pi-table" style="margin-right: 10px;"></i>MIN:
                        {{node['min']}}</p>
                    <p class="p-mb-1 product-name"><i class="pi pi-table" style="margin-right: 10px;"></i>MAX:
                        {{node['max']}}</p>
                    <p class="p-mb-1 product-name"
                        *ngIf="node['DataType'] !== 'String' && node['DataType'] !== 'Boolean'"><i class="pi pi-table"
                            style="margin-right: 10px;"></i>STD: {{node['std']}}</p>
                    <p class="p-mb-1 product-name"
                        *ngIf="node['DataType'] !== 'String' && node['DataType'] !== 'Boolean'"><i class="pi pi-table"
                            style="margin-right: 10px;"></i>MEAN: {{node['mean']}}</p>
                    <p class="p-mb-1 product-name"
                        *ngIf="node['DataType'] !== 'String' && node['DataType'] !== 'Boolean'"><i class="pi pi-table"
                            style="margin-right: 10px;"></i>MED: {{node['median']}}</p>
                    <!-- <h6 class="p-mt-0 p-mb-3">${{product.price}}</h6>
                        <span [class]="'product-badge status-'+product.inventoryStatus.toLowerCase()">{{product.inventoryStatus}}</span> -->
                    <!-- <div class="car-buttons p-mt-5">
                            <p-button type="button" styleClass="p-button p-button-rounded p-mr-2" icon="pi pi-search"></p-button>
                            <p-button type="button" styleClass="p-button-success p-button-rounded p-mr-2" icon="pi pi-star-fill"></p-button>
                            <p-button type="button" styleClass="p-button-help p-button-rounded" icon="pi pi-cog"></p-button>
                        </div> -->
                </div>
            </div>
        </div>
    </ng-template>
</p-carousel>
