export namespace SessionController {
    export class LogInArg {
        Username: string;
        Password: string;
        constructor(Username: string,Password: string) {
            if(Username === undefined) throw new Error( "Class 'LogInArg': Required value 'Username' is undefined" );
            if(Password === undefined) throw new Error( "Class 'LogInArg': Required value 'Password' is undefined" );
            this.Username = Username;
            this.Password = Password;
        }
    }
}