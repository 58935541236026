// import { PlayJsonInheritance } from "./play.model";

import { UserRow } from "./api/domain/NewDataSourceModelBase";
import { UserInfo } from "./api/models/session/UserInfo";

// import { User } from "./api/models/session/User";
// import { UserInfo } from "./api/models/session/UserInfo";

// export interface UserInfo extends PlayJsonInheritance {
// 	ID: number;
// 	Username: string;
// 	IsAdmin: boolean;
// 	EMail: string;
// 	FirstName?: string;
// 	LastName?: string;
// }

// export class BasicUserInfo implements UserInfo {
// 	ID: number;
// 	Username: string;
// 	IsAdmin: boolean;
// 	EMail: string;
// 	FirstName?: string;
// 	LastName?: string;
// 	_type: string = "models.BasicUserInfo";
// }

export class UserInfoView {
	userInfo: UserInfo;
	//subscription: string = "FREE";
	//client: string;
    avatar?: number[];
}

// export class User {
// 	id: number;
// 	userName: string;
// 	passWord: string;
// 	eMail: string;
// 	isAdmin: boolean;
// 	firstName?: string;
// 	lastName?: string;
// }

// export class UserRole {
// 	id: number;
// 	name: string;
// 	views: string[];
// 	action: string;
// }

// export class UserPermission {
// 	id: number;
// 	user: string;
// 	viewName: string;
// 	view: string;
// 	permission: string;
// 	owner: boolean;
// }

export class ChangeUserPassword {
	User: UserInfo;
	Password: string;
}


export class UserDetailsRow {
	id: number;
	avatar?: Array<number>;
	constructor(id: number, avatar?: Array<number>) {
		if(id === undefined) throw new Error( "Class 'UserDetails': Required value 'id' is undefined" );
		this.id = id;
		this.avatar = avatar;
	}
}

export class UserLogIn {
	Username: string;
	Password: string;
	constructor(Username: string, Password: string) {
		if(Username === undefined) throw new Error( "Class 'UserLogIn': Required value 'Username' is undefined" );
		if(Password === undefined) throw new Error( "Class 'UserLogIn': Required value 'Password' is undefined" );
		this.Username = Username;
		this.Password = Password;
	}
}

export class CreateUserAdminArg {
	User: UserRow;
	AdminCredentials: UserLogIn;
	constructor(User: UserRow, AdminCredentials: UserLogIn) {
		if(User === undefined) throw new Error( "Class 'CreateUserAdminArg': Required value 'User' is undefined" );
		if(AdminCredentials === undefined) throw new Error( "Class 'CreateUserAdminArg': Required value 'AdminCredentials' is undefined" );
		this.User = User;
		this.AdminCredentials = AdminCredentials;
	}
}

export class SetNewPassword {
	Token: string;
	Password: string;
	PasswordRepeat: string;
	constructor(Token: string,Password: string,PasswordRepeat: string) {
		if(Token === undefined) throw new Error( "Class 'SetNewPassword': Required value 'Token' is undefined" );
		if(Password === undefined) throw new Error( "Class 'SetNewPassword': Required value 'Password' is undefined" );
		if(PasswordRepeat === undefined) throw new Error( "Class 'SetNewPassword': Required value 'PasswordRepeat' is undefined" );
		this.Token = Token;
		this.Password = Password;
		this.PasswordRepeat = PasswordRepeat;
	}
}