import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { SelectEvent } from "src/app/helper/events";
import { WorkflowRepositoryEntry } from "src/app/models/api/models/workflow/WorkflowRepositoryEntry";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
// import { WorkflowRepositoryEntry } from "src/app/models/workflow.model";
import { WorkflowsService } from "src/app/services/workflows.service";
import { SubSink } from "subsink";

@Component({
	selector: "app-open-workflow-dialog",
	templateUrl: "./open-workflow-dialog.component.html",
	styleUrls: ["./open-workflow-dialog.component.scss"],
})
export class OpenWorkflowDialogComponent implements OnInit {
	displayOpenWorkflow: boolean = false;
	subs = new SubSink();
	workflows: WorkflowRepositoryEntry[] = [];
	selectedWorkflow?: WorkflowRepositoryEntry;
	savingInProgress: boolean = false;

	constructor(
		private workflowService: WorkflowsService,
		private errorService: SystemMessageLogService,
		private router: Router
	) { }

	ngOnInit(): void {
		this.subs.sink = this.workflowService.displayOpenWorkflow.subscribe(
			(res: boolean) => {
				this.savingInProgress = true;
				this.displayOpenWorkflow = res;
				this.subs.sink = this.workflowService
				.getWorkflowObjectList()
				.subscribe((res: WorkflowRepositoryEntry[]) => {
					this.workflows = res;
					this.savingInProgress = false;
				});
			}, (err: Error) => {
				this.errorService.handleError(err)
			}, () => { this.savingInProgress = false}
		);
		// this.workflowService.workflowsChangedEmitter.subscribe(() => {
		// 	this.subs.sink = this.workflowService
		// 	.getWorkflowObjectList()
		// 	.subscribe((res: WorkflowRepositoryEntry[]) => {
		// 		this.workflows = res;
		// 	});

		// })
	}

	onSelectWorkflow(evt: SelectEvent<any,WorkflowRepositoryEntry>) {
		let selectedWorkflow = <WorkflowRepositoryEntry>evt.data;
		this.selectedWorkflow = selectedWorkflow;
	}
	onUnselectWorkflow(evt: SelectEvent<any,WorkflowRepositoryEntry>) {
		let unselectedWorkflow = <WorkflowRepositoryEntry>evt.data;
		this.selectedWorkflow = undefined;
	}
	// onMenuClicked(wf: WorkflowRepositoryEntry) {
	// 	this.selectedWorkflow = wf;
	// 	this.workflowService.selectedMenuWorkflowEmitter.emit(wf);
	// }
	// -- WORKFLOW DETAILS FUNCTIONS
	openWorkflow() {
		console.log("Open Workflow from Dialog");
		// route to designer view
		this.displayOpenWorkflow = false;
		this.router.navigateByUrl("/designer/" + this.selectedWorkflow?.id);
		// emit selected id
		this.workflowService.selectedWorkflowToOpenEmitter.emit(this.selectedWorkflow);
	}
}
