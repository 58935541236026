<p-overlayPanel #op [showCloseIcon]="true">
    <ng-template pTemplate>
        <div style="width:600px">
            <div class="p-col-12" *ngIf="toggleView === true">
                <div class="rightpanel-section favorites-section">
                    <div class="section-header">
                        <h6>{{'Export Files' | translate}}</h6>
                    </div>
                    <div class="p-grid">
                        <div class="p-col-12 p-md-6 p-lg-4 item" style="text-align: center;"
                            *ngFor="let node of artifactsMap">
                            <h6>Node {{node[0]}}</h6>
                            <div *ngFor="let artifact of node[1]" (click)="downloadFileNew(artifact)">
                                <img class="artifactImg"
                                    src="assets/layout/images/fileExport/{{artifact.Extension}}.png"
                                    alt="freya-layout" />
                                <p style="font-size: 14px">{{artifact.Name}}.{{artifact.Extension}}</p>
                            </div>
                        </div>
                        <div class="p-col-12" *ngIf="!artifactsMap || artifactsMap.length === 0">
                            <p>{{'No export files available. Use the Export File node to create files' | translate}}
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>


<div style="margin-left: 5px">
    <p-button label="{{'Export' | translate}}" icon="pi pi-download" (click)="op.toggle($event)"></p-button>
</div>
