<div class="p-grid" *ngIf="currentConfig">
    <div class="p-col-6 headerSection">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown id="datasourceName" #dsSelection [options]="currentConfig.DataSources"
                    [(ngModel)]="currentConfig.SelectedDataSource" (onChange)="onSettingsChanged($event)" [autoDisplayFirst]="false"
                     [filter]="true" filterBy="Name" optionLabel="Name"></p-dropdown>
                <label for="datasourceName">{{'Choose Datasource' |translate }}</label>
            </span>
        </div>
    </div>

    <!-- <div class="p-col-6 headerSection">
        <button style="float: right" pButton pRipple type="button" icon="pi pi-plus" label="{{'Create' | translate }}"
            class="p-button p-mr-2 p-mb-2" pTooltip="{{'CreateNewDatasource' | translate }}"
            ></button>
    </div>
    <div class="p-col headerSection" *ngIf="currentNode.settings.Configuration.DataSourceInfo.Psa.WriteMode === 'Delta'">
        <div class="p-field-checkbox">
            <p-checkbox name="group1" [binary]="true" 
                [(ngModel)]="currentNode.settings.Configuration.UseOnlyLatestDelta" (onChange)="onSettingsChanged()" inputId="ny"></p-checkbox>
            <label for="ny">{{'Latest Data Only' |translate }}</label>
        </div>
    </div>
    <div class="p-col headerSection">
        <div class="p-field-checkbox">
            <p-checkbox name="group1" [binary]="true"
                [(ngModel)]="currentNode.settings.Configuration.QuerySettings.LastPackageOnly" (onChange)="onSettingsChanged()" inputId="ny2"></p-checkbox>
            <label for="ny">{{'Last Package Only' |translate }}</label>
        </div>
    </div>
    <div class="p-col headerSection">
        <div class="p-field-checkbox">
            <p-checkbox name="group1" [binary]="true" 
                [(ngModel)]="currentNode.settings.Configuration.QuerySettings.IncludeMetaFields" (onChange)="onSettingsChanged()" inputId="ny3"></p-checkbox>
            <label for="ny">{{'Include Meta Fields' |translate }}</label>
        </div>
    </div> -->
</div>
