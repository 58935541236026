<div class="p-grid">
    <div class="p-col-12 headerSection">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown id="columnNameText" [autoDisplayFirst]="false" [options]="columnList"
                    (onChange)="onUpdateSettings(false)" [style]="{'width':'100%'}"
                    [(ngModel)]="encryptInfo.ColumnName"></p-dropdown>
                <label for="columnNameText">{{'Column' |translate }}</label>
            </span>
        </div>
    </div>
    <div class="p-col-12 headerSection">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown [autoDisplayFirst]="false" appendTo="body"  id="encriptionMethodOptions" [options]="encriptionMethods"
                    (onChange)="onUpdateSettings(false)" [style]="{'width':'100%'}" [(ngModel)]="encryptInfo.Mode">
                </p-dropdown>
                <label for="encriptionMethodOptions">{{'Mode' |translate }}</label>
            </span>
        </div>
    </div>
    <div class="p-col-12" *ngIf="encryptInfo.Mode === 'Decrypt'">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown [autoDisplayFirst]="false" appendTo="body" id="EncryptStatusColText" [options]="columnList"
                    (onChange)="onUpdateSettings(false)" [style]="{'width':'100%'}"
                    [(ngModel)]="encryptInfo.EncryptStatusCol"></p-dropdown>
                <label for="EncryptStatusColText">{{'Encrypt Column' |translate }}</label>
            </span>
        </div>
    </div>
    <div class="p-col-12">
        <p-checkbox [(ngModel)]="encryptInfo.DropInputCol" [binary]="true" (onChange)="onUpdateSettings(false)">
        </p-checkbox> {{'Drop Old' |translate }}
    </div>

    <div class="p-col-12" style="width:100%;">
        <textarea pInputTextarea [(ngModel)]="encryptInfo.Key" inputId="textarea" (blur)="onUpdateSettings(false)"
            [style]="{'width':'100%', 'height':'100px'}" [disabled]="encryptInfo.Mode === 'Encrypt'"></textarea>
    </div>

    <!--
    <button pButton *ngIf="encryptInfo.Mode === 'Encrypt'" (click)="generateKey()">Generate
        Key</button>
    <button pButton *ngIf="encryptInfo.Mode === 'Encrypt'" (click)="copyToClipboard(encryptInfo.Key)">Copy to
        clipboard</button>
    <button pButton *ngIf="encryptInfo.Mode === 'Decrypt'" (click)="pasteToClipboard()">Paste to clipboard</button> -->
    <div class="p-col-12" style="float: right">
        <button pButton pRipple *ngIf="encryptInfo.Mode === 'Encrypt'" type="button" icon="pi pi-file-o"
            label="{{'GenerateKey' | translate}}" tooltipPosition="bottom"
            class="p-button-rounded p-button-text p-mr-2 p-mb-2" pTooltip="{{'GenerateKey' | translate}}"
            (click)="generateKey()"></button>
        <button pButton pRipple *ngIf="encryptInfo.Mode === 'Encrypt'" type="button" icon="pi pi-file-o"
            label="{{'CopyKey' | translate}}" tooltipPosition="bottom"
            class="p-button-rounded p-button-text p-mr-2 p-mb-2" pTooltip="{{'CopyKeyToClipboard' | translate}}"
            (click)="copyToClipboard(encryptInfo.Key)"></button>

        <!-- <button pButton pRipple *ngIf="encryptInfo.Mode === 'Decrypt'" type="button" icon="pi pi-file-o"
            label="{{'PasteKey' | translate}}" tooltipPosition="bottom"
            class="p-button-rounded p-button-text p-mr-2 p-mb-2" pTooltip="{{'PasteKeyToClipboard' | translate}}"
            (click)="pasteToClipboard()"></button> -->
    </div>


</div>