<div style="margin: 10px 0">
    <div>
        <p-checkbox [(ngModel)]="config.DetectColumnTypes" [binary]="true"></p-checkbox>
        <label for="ny">{{'DetectColumnTypes' | translate}}</label>
    </div>
    <div style="margin-top:10px">
        <p-checkbox [(ngModel)]="config.UseDetectLimit" [binary]="true"></p-checkbox>
        <label for="ny">{{'UseDetectLimit' | translate}}</label>
    </div>
    <div></div>
    <div></div>
</div>