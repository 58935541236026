import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { WorkflowNode } from 'src/app/models/api/com/bion/etl/Workflow';
import { GoNode } from '../graph-view/goJs.model';
import { SqlDummyApiService } from '../sql-dummy-api.service';
import { DataNavigation, DataNodeView } from '../sql-workflow-sketch';

@Component({
  selector: 'app-data-view',
  templateUrl: './data-view.component.html',
  styleUrls: ['./data-view.component.scss']
})
export class DataViewComponent implements OnInit, DataNodeView {

  constructor(private sql_api: SqlDummyApiService) { }
  currentNavigation?: DataNavigation;
  onChangeNavigation(newNavigation: DataNavigation): Observable<DataNavigation> {

    if(this.currentNavigation?.Workflow.Name == newNavigation.Workflow.Name) {
      if(this.currentNavigation.Node.id == newNavigation.Node.id) {
        if(this.currentNavigation.Port == newNavigation.Port) {
          if(this.currentNavigation.TableIndex == newNavigation.TableIndex) {
            // do nothing!
          } else {
            // new
          }
        } else {
          // new
        }
      } else {
        // new!
      }
    } else {
      // new!
    }

    throw new Error('Method not implemented.');
  }


  dataMode: string = "Data";
  onNodeSelected(node: GoNode): Observable<void> {
    throw new Error('Method not implemented.');
  }
  onPreviewData(): Observable<boolean> {
    throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
    this.sql_api.nodeSelectedEmitter.subscribe(event => {

      if(event.Data === undefined) {
        console.log("Warning, Event Data is undefined");
        return;
      }

      if(this.currentNavigation === undefined) {
        console.log("Current Navigation is undefined");
        return;
      }

      const selectedPort = event.Data.Port? event.Data.Port : this.currentNavigation.Port;
      const currentIndex = this.currentNavigation.TableIndex;

      const navi : DataNavigation = { Workflow:event.Data.Workflow, Node:event.Data.GoNode, Port:selectedPort, TableIndex:currentIndex };

      this.onChangeNavigation(navi);
    })
  }

}
