import { Component, OnInit } from "@angular/core";
import { FieldInfo, WorkflowNodeSettings } from "src/app/models/api/com/bion/etl/Workflow";
import { GuiDropDown } from "src/app/models/helperClass.model";
import {
	ColumnSplitterInfo,
	ColumnSplitterSettings,
} from "src/app/models/nodeSettings.model";
// import { FieldInfo, WorkflowNodeSettings } from "src/app/models/workflow.model";
import { DesignerService } from "src/app/services/designer.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { NodeConfigComponentBase } from "../node-config-component-base";

export class ColumnSplitterInfoView {
	ColumnName: string;
	SplitCondition: string;
	SplitMethod: GuiDropDown;
	DropColumn: boolean;
	MaxColumns: number;
	hasError?: boolean;

    constructor(ColumnName: string, SplitCondition: string, SplitMethod: GuiDropDown,DropColumn: boolean, MaxColumns: number, hasError?: boolean) {
        this.ColumnName = ColumnName;
        this.SplitCondition = SplitCondition;
        this.SplitMethod = SplitMethod;
        this.DropColumn = DropColumn;
        this.MaxColumns = MaxColumns;
        this.hasError = hasError;
    }
}

@Component({
	selector: "app-column-splitter-node",
	templateUrl: "./column-splitter-node.component.html",
	styleUrls: ["./column-splitter-node.component.css"],
})
export class ColumnSplitterNodeComponent
	extends NodeConfigComponentBase<ColumnSplitterSettings>
	implements OnInit
{
	settingsToView(settings: WorkflowNodeSettings): void {
		// -- Get Configuration
		let currentConfig = <ColumnSplitterSettings>settings;
		// -- Get Input Meta Data
		const meta_infos = this.getCurrentWorkflowNode().Properties.MetaInfo.get(
			this.InputPort
		);

		let columnListArray: string[] = [];

		// create SelectInfoViews from DsFields for table
		if (meta_infos != undefined && meta_infos.length > 0) {
			const input_field_infos = meta_infos[0].FieldsInfo;
			// -- create string array for dropdown
			columnListArray = input_field_infos.map((fieldInfo) => {
				return fieldInfo.Name;
			});
			this.columnList = columnListArray;
		}

		// --If currentConfig not available (-> new node)
		if (currentConfig == undefined) {
			this.columnSplitterList = [];
		} else {
			// -- otherweise load existing config into view
			console.log("COLUMN SPLITTER: Load View from Config...", currentConfig);

			currentConfig.ColumnSplitterInfos.map((info) => {
				if (columnListArray.includes(info.ColumnName)) {
					// -- column still exists, do nothing
				} else {
					// column does not exist anymore, meaning column name currently not included -> include
					columnListArray.push(info.ColumnName);
				}
			});
			this.columnList = columnListArray;
            if(meta_infos !== undefined) {
			this.columnSplitterList = this.infoToView(
				currentConfig.ColumnSplitterInfos,
				meta_infos[0].FieldsInfo
			);
            }
		}
	}
	viewToSettings(): ColumnSplitterSettings {
		const newSettings = <ColumnSplitterSettings>{
			...this.getCurrentWorkflowNode().Properties.Configuration,
		};
		const sortInfoViews = this.columnSplitterList;
		const columnSplitterInfos = this.ViewToInfo(sortInfoViews);

		newSettings.ColumnSplitterInfos = columnSplitterInfos;

		// this.messageService.standardToastMessage({  severity : 'success',
		//                                                 summary : 'Filter successsfully added',
		//                                                 detail : 'New Filter was successfully added to the node.'});

		return newSettings;
	}
	onSettingsChanged(settings: ColumnSplitterSettings): void {
		throw new Error("Method not implemented.");
	}

	readonly InputPort = "Input";
	columnList: string[] = [];
	splitMethodOptions: GuiDropDown[];
	columnSplitterList: ColumnSplitterInfoView[] = [];

	constructor(
		protected workflowService: WorkflowsService,
		protected designerEventService: DesignerService
	) {
		super(workflowService, designerEventService);
		this.splitMethodOptions = [
			{ name: "Split to column", value: "column" },
			{ name: "Split to row", value: "row" },
		];
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

	onAddRow() {
		let newFilter = new ColumnSplitterInfoView("","",this.splitMethodOptions[0],true,5,false);
		// newFilter.ColumnName = "";
		// newFilter.SplitMethod = this.splitMethodOptions[0];
		// newFilter.SplitCondition = "";
		// newFilter.DropColumn = true;
		// newFilter.MaxColumns = 5;
		// newFilter.hasError = false;

		this.columnSplitterList.push(newFilter);

		this.ViewToInfo(this.columnSplitterList);
		this.onUpdateSettings(false);
	}
	onDeleteRow(index: number) {
		this.columnSplitterList = [
			...this.columnSplitterList.slice(0, index),
			...this.columnSplitterList.slice(index + 1),
		];
		this.onUpdateSettings(false);
		// this.messageService.standardToastMessage({  severity : 'warning',
		//                                                 summary : 'Row deleted',
		//                                                 detail : 'The selected row was deleted successfully!'});
	}

	infoToView(
		list: ColumnSplitterInfo[],
		meta?: FieldInfo[]
	): ColumnSplitterInfoView[] {
		let newInfosView: ColumnSplitterInfoView[] = [];

		list.map((entry: ColumnSplitterInfo) => {

			// -- Get respective fieldInfo class from config
			//newInfoView.ColumnName = entry.ColumnName;

			let fieldInfoFound = meta?.find((field) => {
				return field.Name === entry.ColumnName;
			});

			const hasError = !fieldInfoFound;

			// -- get split condition
			//newInfoView.SplitCondition = entry.SplitCondition;

			// -- get DropColumn Booolean
			//newInfoView.DropColumn = entry.DropColumn;
			//newInfoView.MaxColumns = entry.MaxColumns;

            let splitMethod = new GuiDropDown();
			// -- create selected SplitMethod from config
			for (let i = 0; i < this.splitMethodOptions.length; i++) {
				if (entry.SplitMethod == this.splitMethodOptions[i].value) {
					splitMethod = this.splitMethodOptions[i];
				}
			}
            let newInfoView = new ColumnSplitterInfoView(entry.ColumnName,entry.SplitCondition,splitMethod,entry.DropColumn,entry.MaxColumns,hasError);

			newInfosView.push(newInfoView);
		});
		// -- return final view to GUI
		console.log("Final ColumnSplitterInfosView: ", newInfosView);

		return newInfosView;
	}

	ViewToInfo(list: ColumnSplitterInfoView[]): ColumnSplitterInfo[] {
		// -- create new empty array
		let newInfos: ColumnSplitterInfo[] = [];

		list.map((entry) => {
			let newSettings = new ColumnSplitterInfo();
			newSettings.ColumnName = entry.ColumnName;
			newSettings.SplitCondition = entry.SplitCondition;
			newSettings.SplitMethod = entry.SplitMethod.value;
			newSettings.DropColumn = entry.DropColumn;
			newSettings.MaxColumns = entry.MaxColumns;

			newInfos.push(newSettings);
		});
		console.log("Final ColumnSplitterInfos: ", newInfos);
		return newInfos;
	}
    onFocusLost(event: FocusEvent) {
        const div_left: boolean = (event.relatedTarget === null);

        console.log("Send from Div: ", div_left);

        if (div_left) {
            //this.onCheckDirtyFlag();
            this.onUpdateSettings(true);
        }
    }
}
