import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { DynamicItemInfo } from "../com-bion-json";
import { ConnectionSettings, ExtractMetaFromConnectorArg, JdbcAccessNew, JdbcMetaAccess, JdbcMetaInfo } from "../connector.model";
import { ConnectorSettingsBuilder, ConnectorViewBase } from "../connectorView.model";

/**
 * Extend this class for your individual JDBC Connector View Model
 * @type T JDBC Connection Type
 */
export abstract class JdbcConnectorViewBase<T extends ConnectionSettings>
    extends ConnectorViewBase<JdbcAccessNew<T>, JdbcMetaInfo, JdbcMetaAccess<T>> {

    constructor(api: ApiBackendService, connectorID: string) {
        super(api, connectorID);
        this.metaInfo = new JdbcMetaInfo([]);
    }

    fetchMetaDataObs(): Observable<JdbcMetaInfo> {

        // == Build Meta Argument fron Connection Argument
        const con_settings = { ...this.connectorSettings.ConnectionSettings };


        // Note: In Meta Arg, the table parameter is optional IN CONTRAST to the connector settings where it is mandatory!
        //       If given, the backend tries to find the field information and raises an error if the table doesn't exist,
        //       therefore we check the tables existence.

        const table = this.metaInfo.Tables.find(t => t == this.connectorSettings.Table);
        const meta_access = new JdbcMetaAccess(con_settings, table);  // Set the table only, if you need the field info

        let extract_meta_arg = new ExtractMetaFromConnectorArg;
        let connector_meta_info = new DynamicItemInfo(this.connectorID, meta_access);
        extract_meta_arg.ConnectorMetaInfo = connector_meta_info;

        return this.api.api_extractMetaFromConnector(extract_meta_arg).pipe(map((extractRes) => {
            const typed_meta_data = <JdbcMetaInfo>extractRes.MetaData;
            this.metaInfo = typed_meta_data;
            return typed_meta_data;
        }));
    }
    getBase64FileData(): string {
        return '';
    }
    getDataSourceNameSuggestion(): string {
        return this.connectorSettings.Table.toString();
    }
    uploadFile(file: File) {
        throw new Error('JDBC does not support file upload');
    }
    getSettingsBuilder(): Observable<ConnectorSettingsBuilder<JdbcAccessNew<T>>> {

        let jdbcSettings = new JdbcSettingsBuilder(this.connectorID, this.connectorSettings.ConnectionSettings, this.connectorSettings.Table);

        return of(jdbcSettings);
    }
    isFileBased(): boolean {
        return false;
    }
}

export class JdbcSettingsBuilder<T extends ConnectionSettings> implements ConnectorSettingsBuilder<JdbcAccessNew<T>> {

    constructor(connectorId: string, settings: T, table: string) {

        this.connectorId = connectorId;
        this.settings = settings;
        this.table = table;
    }
    connectorId: string;
    settings: T;
    table: string;


    getConnectorId(): string {
        return this.connectorId;
    }
    getConnectorSettings() {
        const settings = new JdbcAccessNew(this.settings, this.table);
        return settings;
    }
}