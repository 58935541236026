<div class="p-grid" style="margin-top:10px">
    <div class="p-col-12 p-md-12">
        <div class="p-fluid">
            <span class="p-float-label">
                <input type="text" id="Pathinputtext" pInputText
                    [(ngModel)]="viewModel.connectorSettings.Uri">
                <label for="Pathinputtext">{{'Path' | translate}}</label>
            </span>
        </div>
    </div>

    <div class="p-col-12 p-md-6">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown id="lineBreakOpt" [options]="parseCharaters"
                    [(ngModel)]="viewModel.connectorSettings.LineBreak" [editable]="true"
                    optionLabel="name" optionValue="char"></p-dropdown>
                <label for="lineBreakText">{{'Linebreak' | translate}}</label>
            </span>
        </div>
    </div>

    <div class="p-col-12 p-md-6">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown id="delimiterOpt" [options]="parseCharaters"
                    [(ngModel)]="viewModel.connectorSettings.Separator" [editable]="true"
                    optionLabel="name" optionValue="char"></p-dropdown>
                <label for="delimiterText">{{'Delimiter' | translate}}</label>
            </span>
        </div>
    </div>

    <div class="p-col-12 p-md-6">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown id="quoteCharOpt" [options]="parseCharaters"
                    [(ngModel)]="viewModel.connectorSettings.QuoteCharacter" [editable]="true"
                    optionLabel="name" optionValue="char"></p-dropdown>
                <label for="quoteCharOpt">{{'QuoteChar' | translate}}</label>
            </span>
        </div>
    </div>

    <div class="p-col-12 p-md-6">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown id="escapeCharOpt" [options]="parseCharaters"
                    [(ngModel)]="viewModel.connectorSettings.EscapeCharacter" [editable]="true"
                    optionLabel="name" optionValue="char"></p-dropdown>
                <label for="escapeCharOpt">{{'EscapeChar' | translate}}</label>
            </span>
        </div>
    </div>

</div>