import { Component, OnInit } from '@angular/core';
import { NodeConfigComponentBase } from '../node-config-component-base';
import { DataSourceIntInput } from 'src/app/models/api/models/etl/DataSourceIntInput';
import { WorkflowNodeSettings } from 'src/app/models/api/com/bion/etl/Workflow';
import { DesignerService } from 'src/app/services/designer.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { IntegratedSourceModel } from 'src/app/models/api/models/IntegratedSourceModel';
import { Id } from 'src/app/helper/id';
import { cloneDeep } from 'lodash';

/**
 * Konfiguration für den integrierten Source Knoten.
 * Der Nutzer muss Data Source, Stream und PSA auswählen.
 */
@Component({
  selector: 'app-datasource-int-node',
  templateUrl: './datasource-int-node.component.html',
  styleUrls: ['./datasource-int-node.component.scss']
})
export class DatasourceIntNodeComponent extends NodeConfigComponentBase<DataSourceIntInput.Settings> implements OnInit {

  settingsToView(settings: WorkflowNodeSettings): void {

    console.log("Settings to View", settings);

    this.config = <DataSourceIntInput.Settings>settings;

    this.selected_source = undefined;
    this.selected_stream = undefined;
    this.selected_psa = undefined;

    // setze das DS Target, wenn eine DS ausgewählt wurde
    const ds = this.setDsStarget(this.config);
    console.log("Set selected ds to", ds);
    const st = this.setStreamTarget(this.config);
    console.log("Set selected stream to", st);
    const psa = this.setPsaTarget(this.config);
    console.log("Set selected PSA to", psa);
  }

  /**
   * Vergleich Datenquellen nach Name und Origin;
   * @param ds1 
   * @param ds2 
   * @returns 
   */
  compare_ds(ds1:IntegratedSourceModel.DataSourceKey<number>, ds2:IntegratedSourceModel.DataSourceKey<number>) : boolean {
    return (ds1.Id == ds2.Id && ds1.Origin == ds2.Origin);
  }

  private setDsStarget(config:DataSourceIntInput.Settings) : IntegratedSourceModel.DataSource<number> | undefined {

    if(config.SourceDetail === undefined) {
      return undefined;
    } else {
      const detail = Id.assertSet(this.config.SourceDetail, new Error("Data Source not selected."));
      this.selected_source = config.Sources.find(s => this.compare_ds(detail.Info.Id, s.Id));
      return this.selected_source;
    }
  }

  /**
   * Config selected Stream
   * @param config 
   * @returns 
   */
  private setStreamTarget(config:DataSourceIntInput.Settings) : DataSourceIntInput.StreamDetail | undefined {

    if(config.SelectedStream?.Stream === undefined) {
      return undefined;
    } else {
      this.selected_stream = config.SourceDetail?.Streams.find(s => s.Name == config.SelectedStream?.Stream?.Stream);
      return this.selected_stream;
    }
  }

  private setPsaTarget(config:DataSourceIntInput.Settings) : IntegratedSourceModel.PsaInfo {
    this.selected_psa = config.SelectedStream?.Stream?.PSA;
    return this.selected_psa;
  }

  viewToSettings(): DataSourceIntInput.Settings {

    // TODO: validate
    console.warn("View to settings not implemented!");
    console.log("View to Settings", this.config);

    console.log("Selected Source", this.selected_source);
    console.log("Selected Stream", this.selected_stream);
    console.log("Selected PSA", this.selected_psa);

    const new_config = cloneDeep(this.config);

    // Source - always set
    const new_ds_target = new DataSourceIntInput.DataSourceTarget(this.selected_source.Id);

    new_config.SourceDetail.Info = this.selected_source;
    let new_stream: DataSourceIntInput.StreamDetail = undefined;
    let new_psa:IntegratedSourceModel.PsaInfo = undefined;

    if(this.selected_stream !== undefined) {
      // check if the new_ds is in details and stream also
      if(new_config.SourceDetail !== undefined && this.compare_ds(new_config.SourceDetail.Info.Id, this.selected_source.Id)) {
        new_stream = new_config.SourceDetail.Streams.find(s => s.Name == this.selected_stream.Name)

        // -- Check for valid PSA
        if(new_stream !== undefined  && this.selected_psa !== undefined) {
          new_psa = new_stream.PSAs.find(p => p.Table == this.selected_psa.Table)
        }
      }
    }

    const stream_target = new DataSourceIntInput.StreamTarget(new_stream?.Name, new_psa);
    const ds_target = new DataSourceIntInput.DataSourceTarget(this.selected_source.Id,stream_target);

    new_config.SelectedStream = ds_target;

    console.log("View to Settings result", new_config);

    return new_config;
  }

  onSettingsChanged(settings: DataSourceIntInput.Settings): void {
    throw new Error('Method not implemented.');
  }

  constructor(protected workflowService: WorkflowsService, protected designerService: DesignerService) {
    super(workflowService, designerService);
  }

  config: DataSourceIntInput.Settings = new DataSourceIntInput.Settings([]);
  selected_source?: IntegratedSourceModel.DataSource<number> = undefined;
  selected_stream?:DataSourceIntInput.StreamDetail = undefined;
  selected_psa?:IntegratedSourceModel.PsaInfo = undefined;

  ngOnInit(): void {
    console.warn("TODO: Die PSA-Attribute 'Is Raw', 'Is Normalized' und 'is Sub Table' werden zwar rausgegeben, in den Check-Boxes aber nicht angezeigt.");
  }

  combo_changed(event: any) {
    console.log("Data Source Dropdown value changed", event);
    this.onDsSelected(event.value);
  }

  stream_combo_changed(event:any) {
    console.log("Stream Drowdown changed", event);
    this.onStreamSelected(event.value);
  }

  psa_combo_changed(event:any) {
    console.log("PSA Drowdown changed", event);
    this.onPsaSelected(event.value);
  }

  /**
   * Data Source selection changed
   */
  onDsSelected(ds:IntegratedSourceModel.DataSource<number>) {
    const ds_target = new DataSourceIntInput.DataSourceTarget(ds.Id);
    this.config.SelectedStream = ds_target;
    console.log("On Data Source Selected", this.config);
    this.onUpdateSettings(true);
  }

  /**
   * Stream selection changed
   */
  onStreamSelected(stream:DataSourceIntInput.StreamDetail) {
    const stream_target = new DataSourceIntInput.StreamTarget(stream.Name);
    const ds_detail = Id.assertSet(this.config.SourceDetail, new Error("Data Source not selected"));
    const ds_target = new DataSourceIntInput.DataSourceTarget(ds_detail.Info.Id,stream_target);
    this.config.SelectedStream = ds_target;
    console.log("On Stream Selected", this.config);
    this.onUpdateSettings(true);
  }

  /**
   * PSA selection changed
   */
  onPsaSelected(psa:IntegratedSourceModel.PsaInfo) {

    const selected_stream = Id.assertSet(this.config.SelectedStream.Stream, new Error("No Stream selected"));

    const selected_psa = this.selected_stream?.PSAs.find(p => p.Table == psa.Table);

    const stream_target = new DataSourceIntInput.StreamTarget(selected_stream.Stream, selected_psa);
    const ds_detail = Id.assertSet(this.config.SourceDetail, new Error("Data Source not selected"));
    const ds_target = new DataSourceIntInput.DataSourceTarget(ds_detail.Info.Id,stream_target);
    this.config.SelectedStream = ds_target;
    console.log("On PSA Selected", this.config);
    this.onUpdateSettings(true);
  }

}
