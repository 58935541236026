<div>
    <p-table [columns]="unionListCols" [(selection)]="unionInputDataSelection" (onColReorder)="onReorderColumns($event)"
        [value]="unionInputData" dataKey="Field" editMode="row" [reorderableColumns]="true">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th style="width: 3rem">
                    <p-tableHeaderCheckbox (click)="onUpdateSettings(false)"></p-tableHeaderCheckbox>
                </th>
                <th *ngFor="let col of columns" pReorderableColumn>
                    {{col.header | translate}}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-ri="rowIndex">
            <tr [pReorderableRow]="ri">
                <td>
                    <p-tableCheckbox [value]="rowData" (click)="onUpdateSettings(false)"></p-tableCheckbox>
                </td>
                <td *ngFor="let col of columns">
                    <span
                        *ngIf="rowData[col.field] !== true && rowData[col.field] !== false">{{rowData[col.field]}}</span>
                    <span><i class="pi pi-check-circle" style="color: #689f38"
                            *ngIf="rowData[col.field] === true"></i></span>
                    <span><i class="pi pi-times-circle" style="color: #d32f2f"
                            *ngIf="rowData[col.field] === false"></i></span>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="2">{{'NoEntryFound' | translate}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>