declare global {
    interface Map<K,V> {
        getOrElse(key:K,alt:V) : V;
    }
}

Map.prototype.getOrElse = function<K,V>(key:K,alt:V) {
    return Maps.getOrElse(this,key,alt);
}

export class Maps {

    static getOrElse<K,V>(m:Map<K,V>, key:K,alt:V) : V {
        const opt = m.get(key);
        if(opt) {
            return opt;
        } else {
            return alt;
        }
    }

}