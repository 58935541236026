import { Component, OnInit } from "@angular/core";
import { mxCell } from "mxgraph";
import { SortEvent } from "primeng/api";
import { of } from "rxjs";
import { MetaInfo } from "src/app/models/api/com/bion/etl/NodeMetaData";
import { FieldInfo } from "src/app/models/api/com/bion/etl/Workflow";
import {
	DataArtifactLike,
	DataTable,
	ExceptionInfo,
	Table,
	TableStats,
	WorkflowLogEntryGui,
	WorkflowPortBase,
	WorkflowStatusLogEntry,
} from "src/app/models/designer.models";
import { GuiDropDown } from "src/app/models/helperClass.model";
// import { FieldInfo, MetaInfo } from "src/app/models/workflow.model";
import {
	DesignerEvent,
	DesignerService,
} from "src/app/services/designer.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { UtilFunctionsService } from "src/app/services/util-functions.service";
import { SubSink } from "subsink";
import {
	mxWorkflowNodePortData,
	mxWorkflowNodeValue,
	mxWorkflowPortValue,
} from "../workflow-graph/mxWorkflowGraph";
import {
	WorkflowGraphEventType,
	IWorkflowGraphEventData,
	WorkflowExecutedData,
	NodeCellClickedData,
	PortCellClickedData,
	NodeDataChangedData,

} from "../workflow-graph/workflow-graph-events";
import { CutInfo } from "src/app/models/api/com/bion/etl/CutInfo";

export interface Product {
	id?: string;
	code?: string;
	name?: string;
	description?: string;
	price?: number;
	quantity?: number;
	inventoryStatus?: string;
	category?: string;
	image?: string;
	rating?: number;
}

export class PortTableEntry {
	Name: string;
	Value: Table; // TODO: maybe replace later with 'Table[]' for Union Nodes.

	constructor(name: string, table: Table) {
		this.Name = name;
		this.Value = table;
	}
}
export class GenericEntry<T> {
	Name: string;
	Value: T;

	constructor(name: string, table: T) {

		if (name === undefined) throw new Error("Attribute 'name' of class GenericEntry is undefined");
		if (table === undefined) throw new Error("Attribute 'table' of class GenericEntry is undefined");
		this.Name = name;
		this.Value = table;
	}
}

export class NodeDataViewEvent {
	//Name: string;
	Node: mxWorkflowNodeValue;
}

export class DataViewPortChangeEvent extends NodeDataViewEvent {
	Port: string;
}

export enum PreviewEvents {
	PortSelected,
}

export class DataPreviewEventObject {
	Type: PreviewEvents;
	Data: NodeDataViewEvent;
}

/**
 * Easy to handle field info for the PrimeNG table.
 */
export interface FlatFieldInfo {
	Field:string;
	FixedSize:boolean;
	Format:string;
	IsKey:boolean;
	Length:number;
	Name:string;
	Precision:number;
}

@Component({
	selector: "app-node-data-preview",
	templateUrl: "./node-data-preview.component.html",
	styleUrls: ["./node-data-preview.component.scss"],
})
export class NodeDataPreviewComponent implements OnInit {
    displayDataPreview: boolean = false;
	loading: boolean = false;
	columnsLoading: boolean = false;
	subs = new SubSink;
	data: any[][] = [];
	dataFull: any[] = [];
	cols: FieldInfo[] = [];
	displayedRecords: number = 0;
	totalRecords: number = 0;
	rowsPerPage: number = 100;
	previewModes: GuiDropDown[];
	selectedPreviewMode: GuiDropDown = { name: "DATA", value: "Data" };

	//Column Limit
	columnPreviewSize: number = 30;
	columnsCollapsed: boolean = true;

	// --- Node Information
	_currentWorkflowNode?: mxWorkflowNodeValue;
	currentNodeID?: string;
	// --- PortsView
	nodePorts: PortTableEntry[] = [];
	multiPortOptions: GenericEntry<mxWorkflowNodePortData>[] = [];
	selectedMultiPortOption?: GenericEntry<mxWorkflowNodePortData> | undefined;
	selectedNodePort: PortTableEntry | undefined;

	tableStats?: TableStats;
	errorLogResult?: Array<[string, ExceptionInfo]>;
	workflowErrorLog: WorkflowLogEntryGui[] = [];
	workflowStatesLog: WorkflowStatusLogEntry[] = [];
	artifacts: DataArtifactLike[] = [];

	constructor(
		private designerService: DesignerService,
		private utilService: UtilFunctionsService,
		private logService: SystemMessageLogService
	) {
		this.previewModes = [
			{ name: "DATA", value: "Data" },
			{ name: "META", value: "Meta" },
		];
	}

	ngOnInit() {
        this.subs.sink = this.designerService.displayDataPreview.subscribe((res:[boolean, mxCell]) => {
            this.displayDataPreview = res[0];
        }, (err) => {

        }
        )

		this.subs.sink = this.designerService.workflowGraphEmitter.subscribe(
			(res: DesignerEvent<WorkflowGraphEventType, IWorkflowGraphEventData>) => {
				// this.loading = true;
				if (res.Type === WorkflowGraphEventType.NodeCellClicked) {
					try {
						const data = <NodeCellClickedData>res.Data;

						this.currentNodeID = data.Cell.id;
						this._currentWorkflowNode = data.Value;

						const portInfos = data.Value.PortInfos;

						if (portInfos === undefined) {
							console.log("PortInfos Undefined");
							return;
						}
						// TODO: PortInfos ist undefined bzw. wird nicht vom Event mitgegeben.

						this.initGui(portInfos);

						const currentPort = this.selectedNodePort.Name;
						const currentPreview = this.selectedPreviewMode.value;

						this.onActionClicked(currentPort, currentPreview);
					} catch (e) {
						this.logService.errorEventEmitter.emit(e);
					}
				}
				if (res.Type === WorkflowGraphEventType.NodeDataChanged) {

					try {
						const data = <NodeDataChangedData>res.Data;

						if (this.currentNodeID === undefined) return;

						if (data.NodeID !== this.currentNodeID) return;

						const currentPort = this.selectedNodePort?.Name;
						const currentPreview = this.selectedPreviewMode.value;

						this.nodeResultIntoGui(data.WorkflowNodeValue.Data, currentPort, currentPreview);
					} catch (e) {
						this.logService.errorEventEmitter.emit(e);
					}
				}
				if (res.Type === WorkflowGraphEventType.PortCellClicked) {
					try {
						const data = <PortCellClickedData>res.Data;
						console.log("onPortCellClicked: ", data);

						const nodeID = data.Cell.id;
						const nodeCell = data.ParentCellValue;
						const portValue = data.Value;

						this._currentWorkflowNode = data.ParentCellValue;

						if (!nodeCell.PortInfos) {
							return;
						}
						this.initGui(nodeCell.PortInfos); // TODO: validate, alternative: event.makeWorkflowNode(...)

						// find new port to select
						const port_to_select = this.nodePorts.find((p) => p.Name == portValue.Name);
						if (port_to_select !== undefined)
							this.selectedNodePort = port_to_select;

						const currentPort = this.selectedNodePort;
						const currentPreview = this.selectedPreviewMode.value;

						this.onActionClicked(currentPort.Name, currentPreview);
					} catch (e) {
						this.logService.errorEventEmitter.emit(e);
					}
				}
                if (res.Type === WorkflowGraphEventType.WorkflowExecuted) {
					const data = <WorkflowExecutedData<any>>res.Data;
					const result = data.Result;
					const extractor = data.Extractor;

					//let nodeStates = result.NodeStates;
					let nodeStates = extractor.nodeStates(result);
					const node_states_map = new Map<string, string>();
					nodeStates.forEach(state => node_states_map.set(state[0], state[1]));

					const logResult = extractor.log(result);
					const errorLogResult = extractor.errors(result);
					const nodeDataResultMap = extractor.nodeDataMap(result);
					this.errorLogResult = errorLogResult;

					// -- Create Log Data
					if (logResult.length > 0) {
						this.workflowErrorLog = this.createLogTable(logResult);
					}

					if (node_states_map.size > 0) {
						this.workflowStatesLog = this.createStatusLogTable(node_states_map);
					}

					// -- get selected cell
					// -- get out port
					if (this._currentWorkflowNode === undefined) return;

					const in_port = this._currentWorkflowNode.PortInfos.find(p => !p.IsInput);

					if (in_port === undefined) return;

					const node_in_port = this.nodePorts.find(p => p.Name == in_port.Name);

					// -- display!
					this.selectedNodePort = node_in_port;
					this.change_port(node_in_port.Name);


					// -- Create Table Stats Data
					if (nodeDataResultMap.size == 0) return;

					// -- Get necessary information (Selected Node, selected Port)
					let currentNodePort = this.selectedNodePort;
					let currentNode = this._currentWorkflowNode.Name;



					// -- Check for undefined node and port -> exit
					if (currentNode === undefined) return;
					if (currentNodePort === undefined) return;

					// -- Check if Results exists, if not exit
					if (!nodeDataResultMap.has(currentNode)) return;

					let currentNodeResult = nodeDataResultMap.get(currentNode);

					if (!currentNodeResult.PortResults.has(currentNodePort.Name)) return;

					let artifacts = currentNodeResult.Artifacts;
					this.artifacts = this.createArtefacts(artifacts);

					// let stats = currentNodePortResults.Tables[0].Stats; // ---> TODO: multi-port to be included

					// if (stats === undefined) return;

					// this.createTableStats(stats);
				}
			}
		);
	}

	createStatusLogTable(statusLog: Map<string, string>) {
		let newArray: WorkflowStatusLogEntry[] = [];

		statusLog.forEach((value: string, key: string) => {
			newArray.push(new WorkflowStatusLogEntry(key, value));
		});

		return newArray;
	}

	createLogTable(log): WorkflowLogEntryGui[] {
		// -- check for error entries & get respective Error Message
		const l = log.length;
		let logResultextend: WorkflowLogEntryGui[] = new Array(l);

		for (let i= 0; i < l;i++) {
			const entry = log[i];
			let newEntry = new WorkflowLogEntryGui();
			newEntry.NodeID = entry.NodeID;
			newEntry.Message = entry.Message;
			newEntry.DateTime = entry.DateTime;
			newEntry.Language = entry.Language;
			newEntry.Level = entry.Level;

			logResultextend[i] = newEntry;
		}

		return logResultextend;
	}

	currentTableStatsCols: any[];
	currentTableStatsRecords: any[];
	currentTableStatsCount: number;

	currentColumnStatsCols: any[];
	currentColumnStatsRecords: any[];

	createTableStats(result: TableStats) {

		this.tableStats = result;

		if (result === undefined) {
			this.resetTableStats();
			return;
		}

		let tableStats: DataTable = result.TableStats;
		let columnStats: DataTable = result.ColumnStats;


		let tableStatsRecords = tableStats.Data;
		let tableStatsFieldsInfo = tableStats.MetaData.FieldsInfo;

		let columnStatsRecords = columnStats.Data;
		let columnStatsFieldsInfo = columnStats.MetaData;

		//-- Prepare TableStats Record
		const tsl = tableStatsRecords.length;
		const tableData = new Array(tsl);
		for (let i = 0; i < tsl; i++) {
			let row = {};
			for (let j = 0; j < this.currentTableStatsCols.length; j++) {
				row[this.currentTableStatsCols[j]["field"]] = tableStatsRecords[i][j];
			}
			//tableData.push(row);
			tableData[i] = row;
		}

		this.currentTableStatsRecords = tableData;

		//-- Prepare ColumnStats Record
		const csl = columnStatsRecords.length;
		const ccsl = this.currentColumnStatsCols.length;
		const columnData = new Array(csl);
		for (let i = 0; i < csl; i++) {
			let row = {};
			for (let j = 0; j < ccsl; j++) {
				row[this.currentColumnStatsCols[j]["field"]] = columnStatsRecords[i][j];
			}
			//columnData.push(row);
			columnData[i] = row;
		}
		this.currentColumnStatsRecords = columnData;
	}
	resetTableStats() {
		this.currentTableStatsRecords = [];
		this.currentColumnStatsRecords = [];
	}

	createArtefacts(artifacts: DataArtifactLike[]) {
		console.log(artifacts);
		return artifacts
	}

	/**
	 * Draw the new port menu
	 */
	initGui(node_ports: WorkflowPortBase[]) {

		// -- reset the view
		this.resetView();

		// -- create menu object for gui
		const node_ports_ex = <mxWorkflowPortValue[]>node_ports;
		if (node_ports_ex === undefined) return;

		// -- Build Port Data View
		const l = node_ports_ex.length;
		const portsMapArray = new Array<PortTableEntry>();
		for (let i = 0; i < l; i++) {

			const node_port = node_ports_ex[i];
			const new_meta_info = new MetaInfo(new Array<FieldInfo>());
			const empty_table = new DataTable(new_meta_info, new Array<Array<any>>());
			const port_entry = new PortTableEntry(node_port.Name, empty_table);

			portsMapArray[i] = port_entry;
		}

		this.nodePorts = portsMapArray;

		if (this.nodePorts.length > 0) {
			this.selectedNodePort = this.nodePorts[0];
		}
	}

	resetView() {
		this.data = [];
		this.cols = [];
		//this.globalFilterArray = [];
		this.selectedNodePort = undefined;
		this.nodePorts = [];
		this.multiPortOptions = [];
		this.selectedMultiPortOption = undefined;
	}

	onActionClicked(
		currentPort: string,
		currentPreview: string,
		currentEdge?: string
	) {
		// IF there is no selected node => QUiT!
		if (this._currentWorkflowNode === undefined) {
			return;
		}

		try {
			let nodeValue = <mxWorkflowNodeValue>this._currentWorkflowNode;

			let nodeValueData = nodeValue.Data;

			this.nodeResultIntoGui(
				nodeValueData,
				currentPort,
				currentPreview,
				currentEdge
			);
		} finally {
			//this.designerService.emitLoadingEvent(false);
		}
	}

	/**
	 * Extract Port Options from a Port Result
	 * @param portData Node Port Data
	 * @returns
	 */
	extractPortOptions(portData: mxWorkflowNodePortData[]): GenericEntry<mxWorkflowNodePortData>[] {
		const multi_port_options = portData.map((result) => {

			const edge_label = result.Table.MetaData.EdgeLabel ? result.Table.MetaData.EdgeLabel : "";

			const newEntry = new GenericEntry<mxWorkflowNodePortData>(
				edge_label,
				result
			);
			return newEntry;
		});
		return multi_port_options;
	}

	nodeResultIntoGui(
		node_result: Map<string, mxWorkflowNodePortData[]>,
		currentPort: string,
		currentPreview: string,
		currentEdge?: string
	): void {
		if (node_result === undefined) {
			console.log("No PortInfos available yet, exit!");
			return;
		}

		// TODO: Check case: Union Input
		let current_port_results = node_result.get(currentPort);

		if (current_port_results === undefined) {
			this.displayedRecords = undefined;
			this.cols = [];
			this.data = [];
			return
		}

		const is_multi_port_result = current_port_results.length > 1;
		const port_options = this.extractPortOptions(current_port_results);

		if (is_multi_port_result) {

			this.multiPortOptions = port_options;

			if (this.selectedMultiPortOption === undefined) {
				this.selectedMultiPortOption = port_options[0];
			}

			if (currentEdge !== undefined) {

				const opt_result = port_options.find(o => o.Name == currentEdge);
				if (opt_result) {
					this.selectedMultiPortOption = opt_result;
				} else {
					console.log("No Port Options available for the selected edge " + currentEdge + ". Selecting first!");
					this.selectedMultiPortOption = port_options[0];
				}
			}
		} else {
			this.multiPortOptions = port_options;
			this.selectedMultiPortOption = port_options[0];
		}

		const selected_port_data = this.selectedMultiPortOption;

		if (selected_port_data === undefined) {
			console.log("WARNING: No Selected Port Data found");
			return;
		}

		this.loadRecordsIntoView(selected_port_data.Value.Table, currentPreview, selected_port_data.Value.CutInfo);

		if (selected_port_data.Value.TableStats !== undefined) {
			this.tableStats = selected_port_data.Value.TableStats;
		} else {
			//TODO: validate!
			this.tableStats = undefined;

		}
	}

	/**
	 * Converts a table to a prime ng table data and col format indexed by their column index
	 * @param table Table
	 * @returns Column and Data indexed by position index
	 */
	tableToPrimeNgIndex(table: Table): [any[], any[][]] {

		const l =  table.MetaData.FieldsInfo.length;

		const field_infos = table.MetaData.FieldsInfo;
		const cols = new Array(l);

		for (let i = 0; i < l; i++) {
			const head_entry = { field: i, header: field_infos[i].Name, dataType: field_infos[i].DataType.Name };
			cols[i] = head_entry;
		}

		const result: [Array<any>, Array<Array<any>>] = [cols, table.Data];

		return result;
	}


	customSort(event: SortEvent) {
		event.data.sort((data1, data2) => {
			let value1 = data1[event.field];
			let value2 = data2[event.field];
			let result = null;

			if (value1 == null && value2 != null)
				result = -1;
			else if (value1 != null && value2 == null)
				result = 1;
			else if (value1 == null && value2 == null)
				result = 0;
			else if (typeof value1 === 'string' && typeof value2 === 'string')
				result = value1.localeCompare(value2);
			else
				result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

			return (event.order * result);
		});
	}

	wfPortResult: Table;

	loadRecordsIntoView(table: Table, currentPreview: string, cutInfo?: CutInfo) {


		this.loading = true;
		//let recordData = table.Data;
		let recordMeta = table.MetaData.FieldsInfo;

		let newCols = [];
		let dataFull: any[][] = [];
		let data: any[][] = [];
		let displayedRecords: number | undefined = cutInfo?.DisplayedRecords;
		let totalRecords: number | undefined = cutInfo?.TotalRecords;


		this.wfPortResult = table;

		//-- Check current view
		if (currentPreview === "Data") {

			// == Performance Optimized Mode (experimental)
			const data_result = this.tableToPrimeNgIndex(table);

			newCols = data_result[0];
			dataFull = data_result[1];
			data = data_result[1].slice(0, 100);
			//displayedRecords = data_result[1].length;
		} else {

			console.log("Convert Meta Data Start", recordMeta);
			const flatMetaData = this.flatFieldInfo(recordMeta);
			console.log("Convert Meta Data End: ", flatMetaData);

			dataFull = flatMetaData;
			displayedRecords = flatMetaData.length;

			// -- create new columns and push
			let cols = [];
			cols.push(
				{ field: "Field", header: "Field" },
				{ field: "Name", header: "Datatype" },
				{ field: "Length", header: "Length" }
			);
			newCols = cols;
		}


		if(this.columnsCollapsed) {
			this.cols = newCols.slice(0,this.columnPreviewSize);			// write col data to UI

		} else {
			this.cols = newCols;
		}

		this.dataFull = dataFull;
		this.data = dataFull;
		this.displayedRecords = displayedRecords;
		this.totalRecords = totalRecords;

		if(!this.displayedRecords) {
			this.displayedRecords = totalRecords;
		}


		this.loading = false;

	}

	/**
	 * Converts the field meta data so it fits nicely into the meta info table.
	 * @param recordMeta Field Meta Information
	 * @returns Flattened field meta data
	 */
	protected flatFieldInfo(recordMeta: FieldInfo[]): any[] {
		let flatMetaData = [];
		recordMeta.forEach((element) => {
			let dsFieldName = { Field: element["Name"] };
			let flatRecordMeta = Object.assign(
				{},
				...(function _flatten(o) {
					return [].concat(
						...Object.keys(o).map((k) =>
							typeof o[k] === "object" ? _flatten(o[k]) : { [k]: o[k] }
						)
					);
				})(element)
			);
			let flatAgg = { ...dsFieldName, ...flatRecordMeta };
			flatMetaData.push(flatAgg);
		});

		return flatMetaData;
	}

	protected change_port(port: string) {

		let currentPort: string;
		let currentPreview: string;
		let currentEdge: string;

		currentPort = port;

		if (this.selectedMultiPortOption) {
			currentEdge = this.selectedMultiPortOption.Value.Table.MetaData.EdgeLabel;
		}

		currentPreview = this.selectedPreviewMode.value;

		this.onActionClicked(currentPort, currentPreview, currentEdge);

		let nodeValue = <mxWorkflowNodeValue>this._currentWorkflowNode;

		let portChangedEvent = new DataPreviewEventObject();
		portChangedEvent.Type = PreviewEvents.PortSelected;

		let portData = new DataViewPortChangeEvent();
		portData.Node = nodeValue;
		portData.Port = currentPort;
		portChangedEvent.Data = portData;

		this.designerService.previewEventsEmitter.emit(portChangedEvent);
		console.log("portChangedEvent", portChangedEvent);
	}

	onPortChanged(event) {

		let currentPort: string;
		currentPort = event.option.Name;

		this.change_port(currentPort);

	}
	onMultiPortChanged(event) {
		let currentPort: string;
		let currentPreview: string;
		let currentEdge: string;

		currentPort = this.selectedNodePort.Name;
		const eventOb: GenericEntry<mxWorkflowNodePortData> = <GenericEntry<mxWorkflowNodePortData>>event.option;
		currentEdge = eventOb.Name;

		currentPreview = this.selectedPreviewMode.value;

		this.onActionClicked(currentPort, currentPreview, currentEdge);

		let nodeValue = <mxWorkflowNodeValue>this._currentWorkflowNode;

		let portChangedEvent = new DataViewPortChangeEvent();

		portChangedEvent.Node = nodeValue;
		portChangedEvent.Port = currentPort;
	}

	onViewModeChanged(event) {
		let currentPort: string;
		let currentPreview: string;

		if (this.selectedNodePort === undefined) return;

		currentPort = this.selectedNodePort.Name;
		currentPreview = event.value.value;

		this.onActionClicked(currentPort, currentPreview);
	}

	onTablePageChanged(event) {
		console.log("_DEL: On Table Page Changed");
		let dataSliced = this.dataFull.slice(event.first, event.first + event.rows);
		this.data = dataSliced;
	}

	onColumnsCollapsed(event) {
		//this.columnsLoading = !this.columnsLoading;
		this.columnsLoading = true;

		let isCollapsed = event.checked;
		this.columnsCollapsed = isCollapsed;

		if(!this.selectedNodePort || !this.selectedPreviewMode) {
			this.columnsLoading = false;
			return
		}

		const currentPort = this.selectedNodePort.Name;
		const currentPreview = this.selectedPreviewMode.value;

		console.log("Before ActionObs");
		let actionObs = of(this.onActionClicked(currentPort,currentPreview));

		console.log("After ActionObs");

		this.subs.sink = actionObs.subscribe(() => {
			this.columnsLoading = false;
		},(err) => {
			this.columnsLoading = false
		})
	}
}