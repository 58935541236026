import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,

} from "@angular/core";
import { SelectItem } from "primeng/api";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { Id } from "src/app/helper/id";
import { Maps } from "src/app/helper/maps";
import {
	AdapterTypeInfo,
} from "src/app/models/connector.model";
import { CsvConnectorView } from "src/app/models/connectors/csv-connector-view.model";
import { CsvUrlConnectorView } from "src/app/models/connectors/csv-url-connector-view.model";
import { dataMarketConnectorView as DataMarketConnectorView } from "src/app/models/connectors/datamarket-connector-view.model";
import { ExcelConnectorView } from "src/app/models/connectors/excel-connector-view.model";
import { ExcelUrlConnectorView } from "src/app/models/connectors/excel-url-connector-view.model";
import { JdbcConnectorView } from "src/app/models/connectors/jdbc-connector-view.model";
import { JsonConnectorView } from "src/app/models/connectors/json-connector-view.model";
import { MeltanooPostgreConnectorView } from "src/app/models/connectors/meltanoo/postgre-connector-view.model";
import {
	DB2ConnectorView,
	H2EmbeddedConnectorView,
	H2ServerConnectorView,
	HsqlEmbeddedConnectorView,
	HsqlServerConnectorView,
	MariaDBConnectorView,
	PostgreConnectorView,
	RedshiftConnectorView,
} from "src/app/models/connectors/postgre-connector-view.model";
import { RestApiConnectorView } from "src/app/models/connectors/rest-api-connector-view.model";
import { SapConnectorView } from "src/app/models/connectors/sap-connector-view.model";
import { SpssConnectorView } from "src/app/models/connectors/spss-connector-view.model";
import { TwitterConnectorView } from "src/app/models/connectors/twitter-connector-view.model";
import { ConnectorView } from "src/app/models/connectorView.model";
import { DataSet } from "src/app/models/datamarket.model";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { DatasourcesService } from "src/app/services/datasources.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { SubSink } from "subsink";
import * as dss from "../../../../models/datasource.model";
import { dsActionType } from "../../dialogs/create-datasource-dialog/create-datasource-dialog.component";

@Component({
	selector: "app-choose-connector",
	templateUrl: "./choose-connector.component.html",
	styleUrls: ["./choose-connector.component.scss"],
})
export class ChooseConnectorComponent implements OnInit, OnDestroy {
	subs = new SubSink();

	// Classic Connector Info
	connectorList: dss.DataSourceAdapter[] = [];
	selectedConnector?: dss.DataSourceAdapter;

	FileConnectorList: dss.DataSourceAdapter[] = [];
	DatabaseConnectorList: dss.DataSourceAdapter[] = [];
	APIConnectorList: dss.DataSourceAdapter[] = [];

	// Modern Connector Info
	adapterTypeInfoList: AdapterTypeInfo<any, any>[] = [];
	selectedAdapterInfo?:AdapterTypeInfo<any, any>;

	FileAdapterList: AdapterTypeInfo<any, any>[] = [];
	DatabaseAdapterList: AdapterTypeInfo<any, any>[] = [];
	APIAdapterList: AdapterTypeInfo<any, any>[] = [];


	@Input() selectedDataSet?: DataSet;
	@Input() dsActionType?: dsActionType;

	//Data Connector selection

	connectorViewMap: Map<string, ConnectorView<any, any, any>> = new Map();

	selectedView:any;

	currentConnectorView?: ConnectorView<any, any, any>;
	excelView?: ExcelConnectorView;
	excelUrlView?: ExcelUrlConnectorView;
	csvView?: CsvConnectorView;
	jdbcView?: JdbcConnectorView;
	dataMarketView?: DataMarketConnectorView;
	jsonView?: JsonConnectorView;
	postgreView?: PostgreConnectorView;
	hsqlEmbeddedView?: HsqlEmbeddedConnectorView;
	h2EmbeddedView?: H2EmbeddedConnectorView;
	h2ServerView?: H2ServerConnectorView;
	hsqlServerView?: HsqlServerConnectorView;
	db2View?: DB2ConnectorView;
	mariaDBView?: MariaDBConnectorView;
	redshiftView?: RedshiftConnectorView;
	twitterView?: TwitterConnectorView;
	restApiView?: RestApiConnectorView;
	spssView?: SpssConnectorView;
	meltanooPostgresView?: MeltanooPostgreConnectorView;
	sapView?: SapConnectorView;


	@Output() currentConnectorViewEmitter = new EventEmitter<ConnectorView<any, any, any>>();

	readonly EXCEL_ADAPTER: string = "Excel Adapter";
	readonly EXCEL_URL_ADAPTER: string = "Excel URL Adapter";
	readonly CSV_ADAPTER: string = "CSV Adapter";
	readonly CSV_URL_ADAPTER: string = "CSV URL Adapter";
	readonly JDBC_ADAPTER: string = "JDBC Adapter";
	readonly DATAMARKET_ADAPTER: string = "BION Data Market Adapter";
	readonly JSON_ADAPTER: string = "JSON File Adapter";
	readonly POSTGRE_ADAPTER: string = "PostgreSQL Server";
	readonly H2_EMBEDDED_ADAPTER: string = "H2 Embedded";
	readonly HSQL_EMBEDDED_ADAPTER: string = "HSQL Embedded";
	readonly H2_SERVER_ADAPTER: string = "H2 Server";
	readonly HSQL_SERVER_ADAPTER: string = "HSQL Server";
	readonly DB2_ADAPTER: string = "DB2";
	readonly MARIA_DB_ADAPTER: string = "MariaDB";
	readonly REDSHIFT_ADAPTER: string = "AWS Redshift";
	readonly TWITTER_ADAPTER: string = "Twitter Connector";
	readonly REST_API_ADAPTER: string = "REST API Adapter";
	readonly SPSS_FILE_ADAPTER: string = "SPSS File Adapter";
	readonly SAP_ADAPTER: string = "SAP ERP Adapter";

	readonly MELTANOO_POSTGRE_ADAPTER: string = "PostgresSql Server Singer";

	constructor(
		private datasourceService: DatasourcesService,
		private bionApi: ApiBackendService,
		private errorService: SystemMessageLogService
	) { }
	ngOnDestroy(): void {
		this.subs.unsubscribe();
		this.selectedAdapterInfo = undefined

	}

	ngOnInit(): void {
		// Init AdapterTypeInfos
		this.subs.sink = this.datasourceService.getAdapterTypeInfo().subscribe((adapters: AdapterTypeInfo<any, any>[]) => {
			this.adapterTypeInfoList = adapters;
			//console.log("adapterTypeInfoList",this.adapterTypeInfoList);
			// TODO: Categorize

			const connectorsByCategory = new Map<string,Array<AdapterTypeInfo<any, any>>>();
			for(let a of adapters) {

				if(!connectorsByCategory.has(a.Category)) {
					connectorsByCategory.set(a.Category, new Array<AdapterTypeInfo<any, any>>());
				}
			
				connectorsByCategory.get(a.Category)?.push(a);
			
			}
			
			// this.FileAdapterList = connectorsByCategory.getOrElse("File", []);
			// this.DatabaseAdapterList = connectorsByCategory.getOrElse("Database",[]);
			// this.APIAdapterList = connectorsByCategory.getOrElse("API",[]);

			this.FileAdapterList = Maps.getOrElse(connectorsByCategory,"File", []);
			this.DatabaseAdapterList =  Maps.getOrElse(connectorsByCategory,"Database",[]);
			this.APIAdapterList =  Maps.getOrElse(connectorsByCategory,"API",[]);


		}, (err: Error) => {
			this.errorService.handleError(err);		
		});

	}

	// GUI Controls -------------------------------
    sortOptions: SelectItem[] = [];

    sortOrder: number = 1;

    sortField?: string;
	// onSortChange(event) {
    //     let value = event.value;

    //     if (value.indexOf('!') === 0) {
    //         this.sortOrder = -1;
    //         this.sortField = value.substring(1, value.length);
    //     }
    //     else {
    //         this.sortOrder = 1;
    //         this.sortField = value;
    //     }
    // }

	/**
	 * Selects the given connector and presets its settings
	 * @param name Connector Name
	 * @param settings Connector Settings preset
	 * @param metaSettings Connector Meta Settings preset
	 */
	selectConnector<A, B>(name: string, settings?: A, metaSettings?: B) {

		const adapter = this.adapterTypeInfoList.find(a => a.Name === name);

		if (adapter === undefined)
			throw new Error("Adapter with name '" + name + "' can't be selected because it does not exist");

		// let viewModel: ConnectorView<A, any, B> | undefined = undefined ;

		console.log(this.connectorViewMap);

		let init_obs : Observable<[AdapterTypeInfo<any,any>,ConnectorView<any,any,any>]>;

		if (this.connectorViewMap.has(name)) {
			const viewModel = Id.assertSet(this.connectorViewMap.get(name), new Error("No view for adapter: " + name));
			init_obs = of([adapter,viewModel]);
		} else {
			const viewModel = this.createAdapterViewModel(adapter.Name);

			init_obs = viewModel.initialize().pipe(map(init_res => {
				viewModel.setInitialSettings(adapter.Info.InitialExtractArg);
				viewModel.setInitialMetaSettings(adapter.Info.InitialMetaRequestArg);
				return [adapter,viewModel];
			}));
		}

		const final_result = init_obs.pipe(map(super_res => {

			const res_view_model = super_res[1];
			const res_adapter = super_res[0];

			if (settings !== undefined) res_view_model.setInitialSettings(settings);
			if (metaSettings !== undefined) res_view_model.setInitialMetaSettings(metaSettings);

			this.setCurrentConnectorView(res_view_model);

			this.selectedAdapterInfo = res_adapter;

			return super_res;
		}));

		// this.subs.sink = final_result.subscribe(xxx => {
		// })
		return final_result;
	}

	/**
	 * Sets the current connector view and emits an event.
	 * @param viewModel Connector View Model
	 */
	protected setCurrentConnectorView(viewModel: ConnectorView<any, any, any>) {
		this.currentConnectorView = viewModel;
		this.currentConnectorViewEmitter.emit(this.currentConnectorView);
	}

	/**
	 * Creates the adapter/connector view from the given adapter type information and preset with initial settings.
	 * @param adapter Adapter Information
	 * @returns Connector View
	 */
	protected buildAdapterViewModel(adapter: AdapterTypeInfo<any, any>): ConnectorView<any, any, any> {
		const viewModel = this.createAdapterViewModel(adapter.Name);

		viewModel.setInitialSettings(adapter.Info.InitialExtractArg);
		viewModel.setInitialMetaSettings(adapter.Info.InitialMetaRequestArg);

		return viewModel;
	}

	/**
	 * Get the view model for the given adapter or create one, if it does not exist already
	 * @param adapter Adapter Type Info
	 * @returns The View Model
	 */
	protected getOrBuildViewModel(adapter: AdapterTypeInfo<any, any>): Observable<[AdapterTypeInfo<any,any>, ConnectorView<any, any, any>]> {

		let init_obs : Observable<[AdapterTypeInfo<any,any>,ConnectorView<any,any,any>]>;

		//let viewModel:ConnectorView<any,any,any> = undefined;

		if(this.connectorViewMap.has(adapter.Name)) {
			const viewModel = Id.assertSet(this.connectorViewMap.get(adapter.Name), new Error("No view for adapter: " + adapter.Name));
			init_obs = of([adapter,viewModel]);
		} else {
			const viewModel = this.createAdapterViewModel(adapter.Name);
			init_obs = viewModel.initialize().pipe(map(init_res => {
				viewModel.setInitialSettings(adapter.Info.InitialExtractArg);
				viewModel.setInitialMetaSettings(adapter.Info.InitialMetaRequestArg);
				return [adapter,viewModel];
			}));
		}

		const final_obs = init_obs.pipe(map(av => {
			const a = av[0];
			const v = av[1];
			this.connectorViewMap.set(a.Name,v);
			return av;
		}));

		return final_obs;
	}

	/**
	 * UI Callback: Adapter/Connector Selected
	 * @param adapter Adapter/Connector
	 */
	onSelectedConnectorNew(adapter: AdapterTypeInfo<any, any>) {

		// if the view already exists THEN use it ELSE create a new and add it to the view map END

		const adapterViewModelObs = this.getOrBuildViewModel(adapter);

		adapterViewModelObs.subscribe(av => {

			const a = av[0];
			const viewModel = av[1];

			this.setCurrentConnectorView(viewModel);
			this.selectedAdapterInfo = a;
		});
	}
	createAdapterViewModel(name: string): ConnectorView<any, any, any> {

		if (name === this.EXCEL_ADAPTER) return new ExcelConnectorView(this.bionApi, name);
		if (name === this.EXCEL_URL_ADAPTER) return new ExcelUrlConnectorView(this.bionApi, name);
		if (name === this.CSV_ADAPTER) return new CsvConnectorView(this.bionApi, name);
		if (name === this.CSV_URL_ADAPTER) return new CsvUrlConnectorView(this.bionApi, name);
		if (name === this.JDBC_ADAPTER) return new JdbcConnectorView(this.bionApi, name);
		if (name === this.DATAMARKET_ADAPTER) return new DataMarketConnectorView(this.bionApi, name);
		if (name === this.JSON_ADAPTER) return new JsonConnectorView(this.bionApi, name);
		if (name === this.POSTGRE_ADAPTER) return new PostgreConnectorView(this.bionApi, name);
		if (name === this.H2_EMBEDDED_ADAPTER) return new H2EmbeddedConnectorView(this.bionApi, name);
		if (name === this.HSQL_EMBEDDED_ADAPTER) return new HsqlEmbeddedConnectorView(this.bionApi, name);
		if (name === this.H2_SERVER_ADAPTER) return new H2ServerConnectorView(this.bionApi, name);
		if (name === this.HSQL_SERVER_ADAPTER) return new HsqlServerConnectorView(this.bionApi, name);
		if (name === this.MARIA_DB_ADAPTER) return new MariaDBConnectorView(this.bionApi, name);
		if (name === this.REDSHIFT_ADAPTER) return new RedshiftConnectorView(this.bionApi, name);
		if (name === this.DB2_ADAPTER) return new DB2ConnectorView(this.bionApi, name);
		if (name === this.TWITTER_ADAPTER) return new TwitterConnectorView(this.bionApi, name);
		if (name === this.REST_API_ADAPTER) return new RestApiConnectorView(this.bionApi, name);
		if (name === this.SPSS_FILE_ADAPTER) return new SpssConnectorView(this.bionApi, name);
		if (name === this.MELTANOO_POSTGRE_ADAPTER) return new MeltanooPostgreConnectorView(this.bionApi, name);
		if (name === this.SAP_ADAPTER) return new SapConnectorView(this.bionApi, name);


		// If no connector found, set dummy
		return new ExcelConnectorView(this.bionApi, name);
		//throw new Error("No view found for this adapter:" + name);
	}


}
