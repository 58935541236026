import { MeltanooConnectorViewBase } from "./meltanoo-base.model";




export class PostgresConfig {
	host: string;
	port: number;
	user: string;
	password: string;
	dbname: string;
	constructor(host: string,port: number,user: string,password: string,dbname: string) {
		if(host === undefined) throw new Error( "Class 'PostgresConfig': Required value 'host' is undefined" );
		if(port === undefined) throw new Error( "Class 'PostgresConfig': Required value 'port' is undefined" );
		if(user === undefined) throw new Error( "Class 'PostgresConfig': Required value 'user' is undefined" );
		if(password === undefined) throw new Error( "Class 'PostgresConfig': Required value 'password' is undefined" );
		if(dbname === undefined) throw new Error( "Class 'PostgresConfig': Required value 'dbname' is undefined" );
		this.host = host;
		this.port = port;
		this.user = user;
		this.password = password;
		this.dbname = dbname;
	}
}
export class PostgreSqlAccess {
	Configuration: PostgresConfig;
	Stream: string;
	constructor(Configuration: PostgresConfig,Stream: string) {
		if(Configuration === undefined) throw new Error( "Class 'PostgreSqlAccess': Required value 'Configuration' is undefined" );
		if(Stream === undefined) throw new Error( "Class 'PostgreSqlAccess': Required value 'Stream' is undefined" );
		this.Configuration = Configuration;
		this.Stream = Stream;
	}
}


export class PostgreSqlMetaAccess {
	Configuration: PostgresConfig;
	constructor(Configuration: PostgresConfig) {
		if(Configuration === undefined) throw new Error( "Class 'PostgreSqlMetaAccess': Required value 'Configuration' is undefined" );
		this.Configuration = Configuration;
	}
}

export class SingerAccess<A> {
	Configuration: A;
	Stream: string;
	constructor(Configuration: A,Stream: string) {
		if(Configuration === undefined) throw new Error( "Class 'SingerAccess': Required value 'Configuration' is undefined" );
		if(Stream === undefined) throw new Error( "Class 'SingerAccess': Required value 'Stream' is undefined" );
		this.Configuration = Configuration;
		this.Stream = Stream;
	}
}


export class SingerMetaAccess<A> {
	Configuration: A;
	constructor(Configuration: A) {
		if(Configuration === undefined) throw new Error( "Class 'SingerMetaAccess': Required value 'Configuration' is undefined" );
		this.Configuration = Configuration;
	}
}

export class PropertyEntry {
	type?: Array<string>;
	format?: string;
	minimum?: number;
	maximum?: number;
	constructor(type?: Array<string>,format?: string,minimum?: number,maximum?: number) {
		this.type = type;
		this.format = format;
		this.minimum = minimum;
		this.maximum = maximum;
	}
}
export class SchemaDefinition {
	type: any;
	properties: [string,PropertyEntry];
	constructor(type: any,properties: [string,PropertyEntry]) {
		if(type === undefined) throw new Error( "Class 'SchemaDefinition': Required value 'type' is undefined" );
		if(properties === undefined) throw new Error( "Class 'SchemaDefinition': Required value 'properties' is undefined" );
		this.type = type;
		this.properties = properties;
	}
}
export class MetaDataDetail {
	selected?: boolean;
	constructor(selected?: boolean) {
		this.selected = selected;
	}
}
export class MetaData {
	breadcrumb: Array<string>;
	metadata: MetaDataDetail;
	constructor(breadcrumb: Array<string>,metadata: MetaDataDetail) {
		if(breadcrumb === undefined) throw new Error( "Class 'MetaData': Required value 'breadcrumb' is undefined" );
		if(metadata === undefined) throw new Error( "Class 'MetaData': Required value 'metadata' is undefined" );
		this.breadcrumb = breadcrumb;
		this.metadata = metadata;
	}
}
export class Stream {
	stream: string;
	tap_stream_id: string;
	schema: SchemaDefinition;
	table_name?: string;
	metadata: Array<MetaData>;
	constructor(stream: string,tap_stream_id: string,schema: SchemaDefinition,metadata: Array<MetaData>,table_name?: string) {
		if(stream === undefined) throw new Error( "Class 'Stream': Required value 'stream' is undefined" );
		if(tap_stream_id === undefined) throw new Error( "Class 'Stream': Required value 'tap_stream_id' is undefined" );
		if(schema === undefined) throw new Error( "Class 'Stream': Required value 'schema' is undefined" );
		if(metadata === undefined) throw new Error( "Class 'Stream': Required value 'metadata' is undefined" );
		this.stream = stream;
		this.tap_stream_id = tap_stream_id;
		this.schema = schema;
		this.table_name = table_name;
		this.metadata = metadata;
	}
}
export class Catalog {
	streams: Array<Stream>;
	constructor(streams: Array<Stream>) {
		if(streams === undefined) throw new Error( "Class 'Catalog': Required value 'streams' is undefined" );
		this.streams = streams;
	}
}
export class SingerMetaInfo {
	Catalog: Catalog;
	constructor(Catalog: Catalog) {
		if(Catalog === undefined) throw new Error( "Class 'SingerMetaInfo': Required value 'Catalog' is undefined" );
		this.Catalog = Catalog;
	}
}



export class MeltanooPostgreConnectorView extends MeltanooConnectorViewBase<PostgresConfig> {}

