import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { ExcelMetaInfo } from "../api/com/bion/connect/excel/ExcelMetaInfo";
import { ExcelPullMetaAccess } from "../api/com/bion/connect/excel/ExcelPullMetaAccess";
import { ExcelPullMetaInfo } from "../api/com/bion/connect/excel/ExcelPullMetaInfo";
import { ExcelUriAccess } from "../api/com/bion/connect/excel/ExcelUriAccess";
import { DynamicItemInfo } from "../com-bion-json";
import {
	ExtractMetaFromConnectorArg,
	SheetInfo,
} from "../connector.model";
import {
	ConnectorSettingsBuilder,
	ConnectorSettingsBuilderBase,
	ConnectorViewBase,
} from "../connectorView.model";

export class ExcelUrlConnectorView extends ConnectorViewBase<ExcelUriAccess, ExcelPullMetaInfo, ExcelPullMetaAccess>
{
	constructor(api: ApiBackendService, connectorID: string) {
		super(api, connectorID);
		this.metaInfo = new ExcelMetaInfo(new Array<SheetInfo>());
	}

	getBase64FileData(): string {
		return this.base64FileData;
	}

	getDataSourceNameSuggestion(): string {
		return this.connectorSettings.Table;
	}
	uploadFile(file: File) {
		this.uploadedFile = file;
	}

	fileIsUpdated: boolean = false;
	flattenedFileList: [];
	base64FileData: string;

	fetchMetaDataObs(): Observable<ExcelMetaInfo> {

		const excel_pull_meta_access = new ExcelPullMetaAccess(this.connectorSettings.Uri);

		const extract_meta_arg = new ExtractMetaFromConnectorArg();
		const connector_meta_info = new DynamicItemInfo(this.connectorID, excel_pull_meta_access);
		extract_meta_arg.ConnectorMetaInfo = connector_meta_info;

		return this.api.api_extractMetaFromConnector(extract_meta_arg).pipe(
			map((extractRes) => {
				return <ExcelPullMetaInfo>extractRes.MetaData;
			})
		);
	}

	getSettingsBuilder(): Observable<ConnectorSettingsBuilder<ExcelUriAccess>> {
		const builder = new ExcelUrlSettingsBuilder(this.connectorID, this.connectorSettings, undefined);
		return of(builder);
	}

	isFileBased(): boolean {
		return false;
	}
}

export class ExcelUrlSettingsBuilder extends ConnectorSettingsBuilderBase<ExcelUriAccess> { }

// export class ExcelUrlSettingsBuilder
// 	implements ConnectorSettingsBuilder<ExcelUriAccess>
// {
// 	constructor(
// 		api: ApiBackendService,
// 		connectorID: string,
// 		sheetName: string,
// 		uri: string,
// 	) {
// 		this.api = api;
// 		this.connectorID = connectorID;
// 		this.sheetName = sheetName;
// 		this.uri = uri;
// 	}

// 	getConnectorId(): string {
// 		return this.connectorID;
// 	}
// 	getConnectorSettings() {
// 		return new ExcelUriAccess(this.uri, this.sheetName, true, true, 100, new ReadSettings(true, 1));
// 	}

// 	base64FileData: string;
// 	sheetName: string;
// 	uri: string
// 	connectorID: string;
// 	api: ApiBackendService;
// }