

export class DataStore {
    id: number = -1;
    name: string;
    type: string;
    table: string;
    writeMode: string;
    description?: string;
    constructor(id: number, name: string, type: string, table: string, writeMode: string,description?: string) {
        if(id === undefined) throw new Error( "Class 'DataStore': Required value 'id' is undefined" );
        if(name === undefined) throw new Error( "Class 'DataStore': Required value 'name' is undefined" );
        if(type === undefined) throw new Error( "Class 'DataStore': Required value 'type' is undefined" );
        if(table === undefined) throw new Error( "Class 'DataStore': Required value 'table' is undefined" );
        if(writeMode === undefined) throw new Error( "Class 'DataStore': Required value 'writeMode' is undefined" );
        this.id = id;
        this.name = name;
        this.type = type;
        this.table = table;
        this.writeMode = writeMode;
        this.description = description;
    }
}

export class DataStoreField {
    id: number;
    name: string;
    dataStore: number;
    position: number;
    dataType: string;
    length: number;
    format?: string;
    isFixed: boolean;
    precision?: number;
    isKey: boolean;
    physicalName:string;
}

export class DataStoreTypeInfo {
    Type: string;
    SupportedWriteModes: Array<string>;
    constructor(Type: string, SupportedWriteModes: Array<string>) {
        if(Type === undefined) throw new Error( "Class 'DataStoreTypeInfo': Required value 'Type' is undefined" );
        if(SupportedWriteModes === undefined) throw new Error( "Class 'DataStoreTypeInfo': Required value 'SupportedWriteModes' is undefined" );
        this.Type = Type;
        this.SupportedWriteModes = SupportedWriteModes;
    }
}

// == Data Store Input Node

export class DataStoreInputSettings {
    DataStores: Array<DataStore>;
    SelectedDataStore?: DataStore;
    DataStoreFields?: Array<DataStoreField>;
    constructor(DataStores: Array<DataStore>, SelectedDataStore?: DataStore, DataStoreFields?: Array<DataStoreField>) {
        if(DataStores === undefined) throw new Error( "Class 'DataStoreInputSettings': Required value 'DataStores' is undefined" );
        this.DataStores = DataStores;
        this.SelectedDataStore = SelectedDataStore;
        this.DataStoreFields = DataStoreFields;
    }
}