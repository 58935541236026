<p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>


<p-dialog header="{{headerText | translate}}" [(visible)]="displayNewAction" modal="modal" showEffect="fade"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '45vw'}" (onHide)="resetCurrentForm()">

    <div class="chat" style="margin-top: 10px">
        <p-tabView [(activeIndex)]="index" (onChange)="selectActionType($event)">
            <p-tabPanel *ngFor="let type of actionTypeOptions; let i=index" [selected]="i == 0" [disabled]="isNew === false">
                <ng-template pTemplate="header">
                    <i class="{{type.icon}}"></i>
                    <!-- <img src="assets/layout/images/connectors/{{type.name}}.png" width="40" height="40" alt="freya-layout" /> -->
                    <span class="p-badge">{{type.name}}</span>
                </ng-template>
                <div class="p-fluid p-formgrid" style="margin-top:40px">
                    <div class="p-col-12 p-mb-2 p-lg-12 p-mb-lg-0" *ngIf="type" [ngSwitch]="type.name">
                        <!-- <div *ngSwitchDefault>
                            Default
                        </div> -->
                        <!-- <div style="margin-bottom: 30px">
                            <div class="p-fluid">
                                <span class="p-float-label">
                                    <p-dropdown [showClear]="false" [disabled]="!isNew" id="scheduleInput"
                                        [options]="schedules" [autoDisplayFirst]="false"
                                        (onChange)="onSelectSchedule($event)" [(ngModel)]="selectedSchedule"
                                        optionLabel="name">
                                    </p-dropdown>
                                    <label for="scheduleInput">{{'Schedule' | translate}}</label>
                                </span>
                            </div>
                        </div> -->
                        <div *ngSwitchCase="'Extract'">
                            <div class="p-fluid">
                                <span class="p-float-label">
                                    <p-dropdown [showClear]="false" appendTo="body" id="datasourceInput" [options]="datasources"
                                        [autoDisplayFirst]="false" (onChange)="onSelectExtractAction($event)"
                                        [(ngModel)]="selectedDatasource" optionLabel="name">
                                    </p-dropdown>
                                    <label for="datasourceInput">{{'SelectDataSource' | translate}}</label>
                                </span>
                            </div>
                        </div>
                        <div *ngSwitchCase="'Run'">
                            <div class="p-fluid">
                                <span class="p-float-label">
                                    <p-dropdown [showClear]="false" appendTo="body" [options]="workflows" id="wfInput"
                                        [autoDisplayFirst]="false" (onChange)="onSelectWorkflowAction($event)"
                                        [(ngModel)]="selectedWorkflow" optionLabel="name">
                                    </p-dropdown>
                                    <label for="wfInput">{{'SelectWorkflow' | translate}}</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-mb-2 p-lg-12 p-mb-lg-0">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <textarea style="min-height:7rem;" id="dsdescriptiontext" [cols]="30" pInputTextarea
                                    [(ngModel)]="actionDescription" autoResize="autoResize"></textarea>
                                <label for="dsdescriptiontext">{{'DescriptionOptional' | translate}}</label>
                            </span>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
    <app-system-message-log></app-system-message-log>

    <!-- <div class="p-grid">
        <div class="p-col-12 p-ai-center p-jc-center">
            <div class="p-grid">
                <div class="p-fluid p-formgrid">
                    <div class="p-col-12 p-mb-12 p-lg-12 p-mb-lg-0">
                        <div class="p-fluid">
                            <span class="p-float-label" style="margin-top: 20px;">
                                <p-dropdown [showClear]="false" [options]="actionTypeOptions"
                                    (onChange)="onSelectActionType($event)" [(ngModel)]="actionType" id="typeInput" optionLabel="name">
                                </p-dropdown>
                                <label for="typeInput">{{'ActionType' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-mb-2 p-lg-12 p-mb-lg-0">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <textarea style="min-height:7rem;" id="dsdescriptiontext" [cols]="30" pInputTextarea
                                    [(ngModel)]="actionDescription" autoResize="autoResize"></textarea>
                                <label for="dsdescriptiontext">{{'DescriptionOptional | translate}}</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="p-col-7 p-ai-center p-jc-center">
            <div class="p-grid p-fluid" style="margin-top: 5px;">
                <div class="p-col-12 p-mb-2 p-lg-12 p-mb-lg-0" *ngIf="actionType" [ngSwitch]="actionType.name">
                    <div *ngSwitchDefault>
                        Default
                    </div>
                    <div *ngSwitchCase="'Extract'">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-dropdown [showClear]="false" id="datasourceInput" [options]="datasources"  [autoDisplayFirst]="false"
                                    (onChange)="onSelectExtractAction($event)" [(ngModel)]="selectedDatasource"
                                    optionLabel="name">
                                </p-dropdown>
                                <label for="datasourceInput">{{'SelectDataSource' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div *ngSwitchCase="'Run'">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-dropdown [showClear]="false" [options]="workflows"  id="wfInput" [autoDisplayFirst]="false"
                                    (onChange)="onSelectWorkflowAction($event)" [(ngModel)]="workflowAction"
                                    optionLabel="name">
                                </p-dropdown>
                                <label for="wfInput">{{'SelectWorkflow' | translate}}</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <p-footer>
        <!-- <button type="button" pButton icon="pi pi-times" (click)="displayNewAction=false" label="{{'No' | translate}}"
        class="p-button-text"></button>
        <button type="button" pButton icon="pi pi-check" (click)="onValidateCurrentForm()" label="{{'Yes' | translate}}"
            class="p-button-text"></button> -->
        <button pButton pRipple type="button" icon="pi pi-times" (click)="displayNewAction=false"
            class="p-button-rounded p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'No' | translate}}"></button>
        <button pButton pRipple type="button" icon="pi pi-check" (click)="onValidateCurrentForm()"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'Yes' | translate}}" [icon]="savingInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'"></button>
    </p-footer>
</p-dialog>
