<p-progressBar [mode]="progressMode" [style]="{'height': '6px'}"></p-progressBar>
<div>
    <p-dropdown [options]="sourceDefinitions" [(ngModel)]="sourceDefinitionSelected" optionLabel="name"
        (onChange)="onSourceDefChanged($event)">
    </p-dropdown>
    <p></p>
    <span class="p-float-label">
        <input pInputText id="sourceName" [(ngModel)]="sourceName" />
        <label htmlFor="sourceName">Data Source Name</label>
    </span>


    <!-- UI Show-Hide-Modes -->

    <div class="field-checkbox">
        <p-checkbox [(ngModel)]="hide_formly" [binary]="true" inputId="binary"></p-checkbox>
        <label for="binary">Hide Formly</label>
    </div>
    <div class="field-checkbox">
        <p-checkbox [(ngModel)]="hide_dynamic_config" [binary]="true" inputId="binary"></p-checkbox>
        <label for="binary">Hide Dynamic Config</label>
    </div>    
    <div class="field-checkbox">
        <p-checkbox [(ngModel)]="hide_json_forms" [binary]="true" inputId="binary"></p-checkbox>
        <label for="binary">Hide JSON Forms</label>
    </div>

    <div class="field-checkbox">
        <p-checkbox [(ngModel)]="real_formly_submit" [binary]="true" inputId="binary"></p-checkbox>
        <label for="binary">Source mit Formly Submit anlegen.</label>
    </div>

    <!-- UI Show-Hide-Modes END-->


    <!-- Formly Version -->

    <p></p>
    <form [formGroup]="form" [hidden]="hide_formly" (ngSubmit)="submit()" *ngIf="fields">
        <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
        <button type="submit" class="btn btn-primary submit-button" [disabled]="!form.valid">Submit</button>
    </form>
    <!-- Formly Version End -->

    <p></p>
    <app-create-ab-source-config #sourceConfig [configs]="configs" [hidden]="hide_dynamic_config"></app-create-ab-source-config>
    <p></p>
    <button pButton (click)="onCreate()">Create</button>
    <div #dynamicConfig></div>

    <!-- <lib-json-schema-form [state]="state">

    </lib-json-schema-form> -->

    <!-- <lib-json-schema-form [(value)]="value" [schema]="schema" [label]="schema.title" (errorChange)="error=$event">
    </lib-json-schema-form> -->

</div>

<button pbutton (click)="onShow($event)">Show Value</button>

<form [formGroup]="profileForm" (ngSubmit)="onSubmit()"></form>