<p-overlayPanel #op [showCloseIcon]="true" [appendTo]="'body'">
    <ng-template pTemplate>
        <div style="width:650px">

            <div class="p-col-12">
                <div class="">
                    <!-- <div class="card-header">
                        <div class="card-title">
                            <h6>{{'Error Status' | translate}}</h6>
                        </div>
                    </div> -->


                    <p-tabView>
                        <p-tabPanel header="Workflow Log" [selected]="true">

                            <p-table #wfLogTable [value]="wfLogResult" [columns]="wfLogCols" [resizableColumns]="false"
                                [reorderableColumns]="false" [rowHover]="true" [filterDelay]="0" [lazy]="true"
                                [globalFilterFields]="wfLogCols">
                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th [ngStyle]="{'width': col.width}" *ngFor="let col of columns"
                                            field="col.field">
                                            {{col.header}}
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData>
                                    <tr>
                                        <td><span class="p-column-title">{{'Date' | translate}}</span>
                                            <span>{{rowData.DateTime | date: 'medium'}}</span>
                                        </td>
                                        <td><span class="p-column-title">{{'Level' | translate}}</span>
                                            <span
                                                [class]="'customer-badge status-' + rowData.Level">{{rowData.Level}}</span>
                                        </td>
                                        <td><span class="p-column-title">{{'Message' | translate}}</span>
                                            <span>{{rowData.Message}}</span>
                                        </td>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="3">{{'NoEntryFound' | translate}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>

                        </p-tabPanel>
                        <p-tabPanel header="Nodes Log">
                            <p-table #nodeLogTable [value]="nodeLogResult" [columns]="nodeLogCols">
                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th [ngStyle]="{'width': col.width}" *ngFor="let col of columns"
                                            field="col.field" style="width: 100px">
                                            {{col.header}}
                                        </th>
                                        <th style="width: 5rem"></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData>
                                    <tr>
                                        <td>{{rowData.NodeData.ID}} </td>
                                        <td><span class="p-column-title">{{'Node' | translate}}</span>
                                            <span>{{rowData.NodeData.Name}}</span>
                                        </td>
                                        <td><span class="p-column-title">{{'Status' | translate}}</span>
                                            <span
                                                [class]="'customer-badge status-' + rowData.ActionResult.IsSuccess">{{rowData.ActionResult.IsSuccess}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="!rowData.ActionResult.IsSuccess"><button pButton
                                                    class="p-button-text"
                                                    (click)="selectNodeErrors($event,rowData.NodeData.ID)"
                                                    (click)="op2.toggle($event)">View Error(s)</button></span>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </p-tabPanel>

                    </p-tabView>

                </div>
            </div>
        </div>

    </ng-template>


    <p-overlayPanel #op2 [showCloseIcon]="false" [appendTo]="'body'">
        <ng-template pTemplate>
            <div style="width:650px">
                <p-table #errorLogTable [value]="selectedNodeErrors" [columns]="erLogCols">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th [ngStyle]="{'width': col.width}" *ngFor="let col of columns" field="col.field"
                                style="width: 100px">
                                {{col.header}}
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                        <tr>
                            <!-- <td>{{rowData[0]}} </td> -->
                            <td><span class="p-column-title">{{'Message' | translate}}</span>
                                <span>{{rowData[1].Message}}</span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </ng-template>
    </p-overlayPanel>

</p-overlayPanel>





<div *ngIf="!nodeLogResult && updateSettingsStatus == false && runWorkflowStatus == false ">
    <p-button icon="pi pi-pause" styleClass="p-button-text" [disabled]="true"></p-button>
</div>
<div *ngIf="nodeLogErrors && updateSettingsStatus == false && runWorkflowStatus == false ">
    <p-button icon="pi pi-times" (click)="op.toggle($event)" styleClass="p-button-danger p-button-text"
        *ngIf="nodeLogErrors.length > 0"></p-button>
    <p-button icon="pi pi-check" (click)="op.toggle($event)" styleClass="p-button-success p-button-text"
        *ngIf="nodeLogErrors.length === 0"></p-button>
</div>

<span *ngIf="updateSettingsStatus">
    <p-button icon="pi pi-spin pi-spinner" pTooltip="{{'Updating...' | translate}}" styleClass="p-button-text"
        [disabled]="true"></p-button>
</span>
<span *ngIf="runWorkflowStatus">
    <p-button icon="pi pi-spin pi-spinner" pTooltip="{{'Workflow running...' | translate}}" styleClass="p-button-text"
        [disabled]="true"></p-button>
</span>