<p-dialog header="{{headerText | translate}}" [(visible)]="displayChangePassword" modal="modal" (onHide)="clearDialog()"
    showEffect="fade" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '600px'}">
    <app-system-message-log></app-system-message-log>
    <div class="p-grid p-ai-center p-jc-center" style="margin-top: 10px">
        <div class="p-col-12">
            <div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12">
                        <label for="initPW">{{'NewPassword' | translate}}</label>
                        <input type="password" id="initPW" pInputText [(ngModel)]="form.newPassword">
                    </div>
                    <div class="p-field p-col-12">
                        <label for="repeatPW">{{'RepeatPassword' | translate}}</label>
                        <input type="password" id="repeatPW" pInputText [(ngModel)]="form.repeatPassword">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <p-footer>
        <!-- <button type="button" pButton icon="pi pi-check" (click)="changeUserPassword()" label="Yes"
            class="p-button-text"></button>
        <button type="button" pButton icon="pi pi-times" (click)="displayChangePassword=false" label="No"
            class="p-button-text"></button> -->
        <button pButton pRipple type="button" icon="pi pi-times" (click)="displayChangePassword=false"
            class="p-button-rounded p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'No' | translate}}"></button>
        <button pButton pRipple type="button" icon="pi pi-check" (click)="onChangeUserPassword()"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'Yes' | translate}}" [icon]="savingInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'"></button>
    </p-footer>
</p-dialog>
