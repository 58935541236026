import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SchedulerGraph } from '../models/api/models/scheduler/SchedulerGraph';


export interface SchedulerPlugInInfo {
  Name:string;
  Ports:Array<SchedulerPortBase>;
}
export interface SchedulerPortBase {
  Name:string;
  IsInput:boolean;
  Type:string;
  IsMulti:boolean;
}


/**
* Plugin and Origin Information
*/
export interface SchedulerNodeBase extends SchedulerPlugInInfo{
  Repository:string;
  EngineInfo:SchedulerGraph.EngineInfo;
}

/**
* Describes a workflow node plugin
*/
export class SchedulerNodeInfo implements SchedulerNodeBase {
  Repository:string;
  EngineInfo:SchedulerGraph.EngineInfo;
  Name:string;
  IsFavourite:boolean;
  Ports:SchedulerPortBase[];
  SettingsType:string;
}



export interface ScheduleWorkflow {

}
// export interface ScheduleNode {

// }

@Injectable({
  providedIn: 'root'
})
export class DummySchedulerService {
	// BASE API URL
	readonly BackEndUrl = environment.API_URL;



  /*
    DUMMY CRUD API - SCHEDULE WORKFLOW SERVICE
  */
  getScheduleWorkflowObjects(): Observable<ScheduleWorkflow[]> {
    throw new Error("Not Implemented")
  }
  createScheduleWorkflowObject() {
    throw new Error("Not Implemented")

  }
  deleteScheduleWorkflowObject() {
    throw new Error("Not Implemented")
  }
  getScheduleNodes(): Observable<SchedulerNodeInfo[]> {
    let array: SchedulerNodeInfo[] = [];
    let newNode = new SchedulerNodeInfo();
    newNode.Name = "Extract";
    newNode.IsFavourite = false;
    newNode.Ports = [];
    newNode.Repository = "Action";

    array.push(newNode);


    let newNode2 = new SchedulerNodeInfo();
    newNode2.Name = "Run";
    newNode2.IsFavourite = false;
    newNode2.Ports = [];
    newNode2.Repository = "Action";
    array.push(newNode2);

    let newNode3 = new SchedulerNodeInfo();
    newNode3.Name = "Split";
    newNode3.IsFavourite = false;
    newNode3.Ports = [];
    newNode3.Repository = "Sequence";
    array.push(newNode3);


    let newNode4 = new SchedulerNodeInfo();
    newNode4.Name = "Join";
    newNode4.IsFavourite = false;
    newNode4.Ports = [];
    newNode4.Repository = "Sequence";
    array.push(newNode4);

    return of(array);



  }
  /*
    DUMMY SCHEDULE GRAPH API
  */



  constructor() { }
}
