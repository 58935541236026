import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthConfig } from 'angular-oauth2-oidc';
import { AuthFlowUtils } from '../../general/oauth2-flow/oauth-flow-utils';
import { cloneDeep } from 'lodash';
import { FlowMessage, MessageType, OAuthFlowEventService } from 'src/app/services/oauth-flow-event-service';
import { Subscription } from 'rxjs';

enum Origin {
  Window,
  Service
}

/**
 * Diese Komponente dient als Rückruf-Fenster für den impliziten OAuth Flow.
 * Sie Sendet die enthaltenen Daten der Antwort an das Eltern-Fenster.
 */
@Component({
  selector: 'app-parent-window-sender',
  templateUrl: './parent-window-sender.component.html',
  styleUrls: ['./parent-window-sender.component.scss']
})
export class ParentWindowSenderComponent implements OnInit, AfterViewInit, OnDestroy {

  // Route : TestOauth2FlowComponentCallback

  static SenderName: string = "ParentWindowSenderComponent";

  use_message_service: boolean = true;

  begin_of_flow: boolean = false;

  subscription?: Subscription = undefined;

  constructor(private route: ActivatedRoute, private messageService: OAuthFlowEventService) { }

  ngOnDestroy(): void {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.sendMessage(5, MessageType.FlowInitialized);
  }

  ngOnInit(): void {

    console.log("ParentWindowSenderComponent INIT")

    console.log("Message Service Time", this.messageService.time);

    console.log("Look for Bion Init flag");

    const params = this.route.snapshot.queryParamMap;
    console.log("Params received", params);

    parent.addEventListener("message", this.receivemessage.bind(this), false);
    this.subscription = this.messageService.getSubject().subscribe(m => {
      this.handleMessage(Origin.Service,m,undefined);
    })

    this.begin_of_flow = params.has("bionInit");

    if (this.begin_of_flow) {
      // wait for incoming message from parent to Open-ID Flow start
      console.log("Bion Init Found -> Waiting for messages");
      this.sendMessage(5, MessageType.FlowInitialized);
    }
    else {
      // callback from open-id -> send result and close!
      console.log("Bion Init not found -> send result to parent and close");
      parent.postMessage(params, location.origin);
      this.sendMessage(params, MessageType.FlowCompleted);
      window.close();
    }
  }

  sendMessage<T>(value: T, tpe: MessageType) {
    console.log("Send Message", value, tpe);
    if (this.use_message_service)
      this.sendFlowMessage(value, tpe);
    else
      this.sendWindowMessage(value);
  }

  sendWindowMessage<T>(value: T) {
    parent.postMessage(value, location.origin);
  }

  sendFlowMessage<T>(value: T, tpe: MessageType) {
    const m: FlowMessage<T> = { Sender: ParentWindowSenderComponent.SenderName, MessageType: tpe, Data: value };
    this.messageService.sendMessage(m);
  }

  handleMessage(origin: Origin, msg?: FlowMessage<any>, evt?: any) {

    console.log("Handle Message", origin, msg, evt);

    if (origin == Origin.Service && this.use_message_service) {
      this.handleWindowMessage(evt);
    } else {
      this.handleServiceMessage(msg);
    }
  }

  handleServiceMessage(m: FlowMessage<any>) {

    if (m.MessageType == MessageType.StartFlow) {
      this.startFlow(m.Data);
    }
  }

  handleWindowMessage(evt: any) {
    console.log("Event", evt);
    
    if(evt === undefined) {
      return;
    }

    // expecting: AuthConfig
    if (evt.data == 5) {

    } else if (evt.data?.redirect_uri !== undefined) {
      const config: AuthConfig = evt.data;
      this.startFlow(config);
    } else {
      console.warn("Windows Message unexpected", evt);
    }

  }

  receivemessage(evt: any) {
    this.handleMessage(Origin.Window, undefined, evt);
  }

  old_redirect_uri?: string = undefined;
  new_redirect_url = "http://localhost:4200/#/test/TestOauth2FlowComponentCallback";

  startFlow(config: AuthConfig) {
    config;

    const old_redirect_uri = config.redirectUri;
    console.log("Replacing Redirect URI with this one and delegate result to parent");
    console.log("Old Redirect", old_redirect_uri);
    console.log("New Redirect", old_redirect_uri);
    console.log("State", old_redirect_uri);

    const c = cloneDeep(config);
    c.redirectUri = this.new_redirect_url;

    console.log("New Auth Config", c);

    const params = AuthFlowUtils.configToParams(c, "my_state");
    const e_params = AuthFlowUtils.encodeUrlParams(params);
    const param_str = AuthFlowUtils.makeUrlParams(e_params);

    const login_url = c.loginUrl;

    const url = login_url + "?" + param_str;
    console.log("URL", url);
    //window.open(url, "self");
    window.location.href = url;
  }

  // Tutorial
  // https://ramya-bala221190.medium.com/communication-between-parent-and-child-windows-in-angular-51629f2ab757

  login(f) {
    parent.postMessage(f.value, location.origin);
    window.close();
  }

}
