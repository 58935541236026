import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ConnectorSettingsBuilder, ConnectorView } from 'src/app/models/connectorView.model';
import { FieldInfo } from 'src/app/models/datasource.model';
import { WriteMode } from 'src/app/models/helperClass.model';
import { DatasourcesService } from 'src/app/services/datasources.service';
import { SubSink } from 'subsink';
import { CreateDataSourceNewLib } from './createDataSourceNewLib';
import * as dss from "src/app/models/datasource.model";
import { DynamicItemInfo } from 'src/app/models/com-bion-json';
import { ChooseConnectorComponent } from '../../components/choose-connector/choose-connector.component';
import { DefineDatasourceComponent } from '../../components/define-datasource/define-datasource.component';
import { PopulatePsaComponent } from '../../components/populate-psa/populate-psa.component';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { AdapterTypeInfo } from 'src/app/models/connector.model';
import { GuiErrorException } from 'src/app/models/gui.error.model';
import { GuiErrorCode } from "src/app/models/gui.error.model";
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { TranslateService } from '@ngx-translate/core';


export interface LoadToPsaArgs{
  openDialog: boolean,
  dataSource: dss.DataSource,
  connectorView: ConnectorView<any,any,any>,
  dsService: DatasourcesService,
  simulateFlag: boolean,
  writeMode?: WriteMode,
  base64: string
}

@Component({
  selector: 'app-create-datasource-dialog-new',
  templateUrl: './create-datasource-dialog-new.component.html',
  styleUrls: ['./create-datasource-dialog-new.component.scss'],
  providers: [MessageService],
})
export class CreateDatasourceDialogNewComponent implements OnInit, OnDestroy {
  subs = new SubSink;
  displayCreateDataSource: boolean;
  stepsItems: MenuItem[];
  headerText: string;

  //ViewChilds
  @ViewChild("page_def_datasource")
  page_define_datasource: DefineDatasourceComponent;
  @ViewChild("page_pop_psa") page_pop_psa: PopulatePsaComponent;
  @ViewChild("chooseConnector") chooseConnector: ChooseConnectorComponent;

  //Step 1 - Select Connector
  currentConnectorView: ConnectorView<any, any, any>;
	selectedAdapterTypeInfo: AdapterTypeInfo<any,any>;

  //Step 2 - Define DS
  currentDataSourceName: string;
  currentDataSourceDescription: string;
  LoadDataFlag: boolean;

  //Step 3 - Select Columns
  selectedDataSourceFields: dss.FieldInfo[] = [];
  prefixDisabled: boolean; // Suffix DataSource Name
  autoTableName: boolean = true; // Attribute für auto Table Naming
  writeModes: WriteMode[]; // List of all UpdateOptions
  selectedWriteModeOption: WriteMode; // Variable for selectedUpdateOption
  public datasourcePrefix: string = "PSAT";
  public suffixConnect: string = "_";
  currentSelectedDatasource: dss.DataSource;

  constructor(private datasourceService: DatasourcesService, private translate: TranslateService, private bionApi: ApiBackendService,  private messageService: MessageService,private errorService:SystemMessageLogService) { }

  ngOnInit(): void {
    this.initializeComponent().subscribe((initRes) => {
    //this.initializeComponent().pipe(map((initRes) => {
      this.displayCreateDataSource = initRes[0];
      console.log("initRES",initRes);

      this.stepsItems = [
        { label: "Choose Connection" },
        { label: "Select Columns" },
        { label: "Define Datasource" },
        //{ label: "Load Data" },
      ];
      this.headerText = "CreateNewDatasource";

      this.goToSelectConnector()
    });
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
  // Init Component
  initializeComponent(): Observable<[boolean, WriteMode[]]> {
    const writeModes = this.datasourceService.getWriteModes();
    const displayComponentObs = this.datasourceService.displayCreateDatasource;

    const finalObs = displayComponentObs.pipe(map((displayRes) => {
      let finalResultObs: [boolean, WriteMode[]] = [displayRes, writeModes];

      return finalResultObs

    }))
    return finalObs

  }

  // Event Emitter
  emitLoadToPsaEvent() {
    let newArgs: LoadToPsaArgs = {
      openDialog: true,
      dataSource: this.currentSelectedDatasource,
      connectorView: this.currentConnectorView,
      dsService: this.datasourceService,
      simulateFlag: false,
      writeMode: this.selectedWriteModeOption,
      base64: CreateDataSourceNewLib.tryGetFileData(this.currentConnectorView)
    }

    this.datasourceService.loadToPSAEmitter.emit(newArgs)

  }

  // UI Callbacks
  savingInProgress: boolean;
  buttonDisabled: boolean;
  onNext() {
    this.savingInProgress = true;
    this.buttonDisabled = true;
    this.subs.sink = this.stepForward().subscribe((activeindex: number) => {
      this.errorService.clearHttpError();
      this.activeindex = activeindex;

      if(activeindex === 3) {

        this.displayCreateDataSource = false;
        if(this.LoadDataFlag) {
        //Emitter senden, dass PSA beladen werden soll -> neue Dialog Komponente
        this.emitLoadToPsaEvent();
        }
        if(!this.LoadDataFlag) {

          this.messageService.add({
            severity: "success",
            summary: this.translate.instant("Message.CreateNewDataSourceSuccess.Title"),
            detail: this.translate.instant("Message.CreateNewDataSourceSuccess.Text"),
          });
        }
      }
    },
      (error: Error) => {
        this.errorService.handleError(error);
        this.savingInProgress = false;
        this.buttonDisabled = false;
      }, () => {
        this.savingInProgress = false;
        this.buttonDisabled = false;
      })
  }
  onBack() {
    this.savingInProgress = true;
    this.buttonDisabled = true;
    this.subs.sink = this.stepBackward().subscribe((activeindex: number) => {
      this.activeindex = activeindex;

    },
    (error: Error) => {
      this.errorService.handleError(error);

    }, () => {
      this.savingInProgress = false;
      this.buttonDisabled = false;
    })
  }
  /**
   * Controls for step 1 - Select Connector
   * @param event
   */
  setCurrentConnectorView(event) {
    this.currentConnectorView = event;
  }
  onIndexChanged(event) {}
  onCloseDialog() {
    this.subs.sink = this.resetAllTabs().subscribe(() => {
      this.displayCreateDataSource = false;
      this.activeindex = 0;

    }, (err: Error) => {
      this.errorService.handleError(err);

    },() => {
      this.savingInProgress = false;
      this.buttonDisabled = false;
    })
  }

	// STEP 2 DEFINE DATASOURCE -------------------------------------------------------
	onUpdateDataSourceFields(event) {
		this.selectedDataSourceFields = event;

		// update data preview ->
		this.subs.sink = this.loadTablePreview(event).subscribe((result) => {
			console.log(result);
		});
	}
  onSetKeyColumns(event:FieldInfo[]) {
    this.selectedDataSourceFields = event;
  }
	// STEP 3 - SETUP DATASOURCE -------------------------------------------------------
  setDataSourceName(event) {
		this.currentDataSourceName = event;
	}
	setDataSourceDescription(event) {
		this.currentDataSourceDescription = event;
	}
	setDataFlag(event) {
		this.LoadDataFlag = event;
	}
	setWriteMode(event: WriteMode) {
		this.selectedWriteModeOption = event;
	}


  // DEA (Zustandsmaschine)
  activeindex = 0;
  stepForward(): Observable<number> {
    if (this.activeindex == 0) {
      return this.fromSelectConnectorToSelectFields();
    }
    if (this.activeindex == 1) {
      return this.fromSelectFieldsToDefineDataSource();
    }
    if (this.activeindex == 2) {
      return this.fromDefineDataSourceToCreateDataSource();
    }
    const obs = throwError("Step does not exist. CurrentIndex: " + this.activeindex);
    return obs
  }
  stepBackward(): Observable<number> {
    if (this.activeindex == 1) {
      return this.fromSelectFieldsToSelectConnector();
    }
    if (this.activeindex == 2) {
      return this.fromDefineDataSourceToSelectFields();
    }
    const obs = throwError("Step does not exist. CurrentIndex: " + this.activeindex);
    return obs
  }
  goToSelectConnector(): Observable<number> {
    this.activeindex = 0;
    let indexObs = of(0);

    return indexObs
  }
  fromSelectConnectorToSelectFields(): Observable<number> {
    if (this.currentConnectorView === undefined) return throwError(new GuiErrorException(GuiErrorCode.noConnectorSelected));
    if (this.currentConnectorView.connectorSettings === undefined) return throwError(new GuiErrorException(GuiErrorCode.noSettingsProvided));
    if (this.currentConnectorView.isFileBased() && this.currentConnectorView.getBase64FileData() === undefined) return throwError(new GuiErrorException(GuiErrorCode.noFileProvided));

    let indexObs = this.loadTablePreview().pipe(map(() => {
      return 1
    }))

    return indexObs
  }


  fromSelectFieldsToDefineDataSource(): Observable<number> {
    if (this.selectedDataSourceFields === undefined || this.selectedDataSourceFields.length === 0) return throwError(new GuiErrorException(GuiErrorCode.noColumnsSelected));

    let indexObs = of(2);
    return indexObs

  }

  fromDefineDataSourceToSelectFields(): Observable<number> {
    const indexObs = this.resetDefineDataSourceTab().pipe(map(() => {
      return 1
    }));
    return indexObs


  }
  fromSelectFieldsToSelectConnector(): Observable<number> {
    const indexObs = this.resetSelectFieldsTab().pipe(map(() => {
      return 0
    }));
    return indexObs

  }
  resetSelectConnectorTab(): Observable<number> {
    this.selectedAdapterTypeInfo = undefined;
    return of(-1)
  }
  resetSelectFieldsTab(): Observable<number> {
    this.selectedDataSourceFields = undefined;
    this.dataPreviewColumnClone = undefined;
    this.dataPreviewColumns = undefined;
    this.dataPreviewColumnsMapping = undefined;
    this.dataPreviewRows = undefined;
    return of(-1)
  }
  resetDefineDataSourceTab(): Observable<number> {
    this.currentDataSourceName = undefined;
    this.currentDataSourceDescription = undefined;
    this.selectedWriteModeOption = undefined;
    return of(-1)
  }
  resetAllTabs(): Observable<boolean> {
    const resetConnectorObs = this.resetSelectConnectorTab();
    const resetSelectFieldsObs = this.resetSelectFieldsTab();
    const resetDefineDSObs = this.resetDefineDataSourceTab();

    let finalResetObs = forkJoin(resetConnectorObs,resetSelectFieldsObs,resetDefineDSObs).pipe(mergeMap(() => {
      return of(true)
    }));

    return finalResetObs
  }
  /**
   * Final state where ds is created
   */
  fromDefineDataSourceToCreateDataSource(): Observable<number> {
    if (!this.page_define_datasource.isValid()) {
      return throwError(new GuiErrorException(GuiErrorCode.dsNameOrWriteModeNotSet))
    };

    let createDSObs = this.createDataSourcesAsync_New().pipe(map((psa_res) => {
      this.currentSelectedDatasource = psa_res['currentSelectedDatasource'];
      this.datasourceService.datasourceChangedEmitter.emit(psa_res['currentSelectedDatasource']);

    }));

    return createDSObs.pipe(map(() => {
      return 3
    }))

  }


  loadTablePreview(
    fieldSelection?: FieldInfo[]
  ): Observable<dss.DataTypePreview> {

    const previewAdapterFuture = this.currentConnectorView.getPreviewBuilder(100, 0);

    let file_data: string = undefined;
    try {
      file_data = this.currentConnectorView.getBase64FileData();
    } catch (e) {
      console.log(
        "Connector " +
        this.currentConnectorView.getConnectorID() +
        " does not provide file data"
      );
    }

    return previewAdapterFuture.pipe(
      mergeMap((previewAdapter) => {
        return this.updateTablePreview(
          previewAdapter,
          file_data,
          fieldSelection
        );
      })
    );
  }

  dataPreviewColumnsMapping;
  dataPreviewColumnClone = [];
  dataPreviewColumns: dss.FieldInfo[];
  initialPreviewColumns: dss.FieldInfo[];
  dataPreviewRows: any[];
  updateTablePreview(
    previewAdapter: ConnectorSettingsBuilder<any>,
    base64FileData: string,
    fieldSelection?: FieldInfo[]
  ): Observable<dss.DataTypePreview> {
    // Initialize extractDataArg Class

    const connector_info = new DynamicItemInfo(
      previewAdapter.getConnectorId(),
      previewAdapter.getConnectorSettings()
    );
    const load_settings = new dss.LoadSettings(true, 100);
    const arg = new dss.ExtractFromConnectorArg(
      connector_info,
      load_settings,
      base64FileData,
      fieldSelection
    );

    let preview = this.datasourceService.extractFromConnector(arg);

    return preview.pipe(
      map((res) => {
        this.data_to_preview(res);
        return res;
      })
    );
  }

  previewRows: any[] = [];
  previewColumns: FieldInfo[] = [];

  data_to_preview(res: dss.DataTypePreview) {
    let previewResult = CreateDataSourceNewLib.data_to_preview(res, this.initialPreviewColumns);
    this.dataPreviewColumns = previewResult[0];
    this.dataPreviewColumnClone = previewResult[1];
    this.dataPreviewColumnsMapping = previewResult[2];
    this.initialPreviewColumns = previewResult[3];
    this.previewRows = previewResult[4];
    this.previewColumns = res.Columns;
  }

  createDataSourcesAsync_New() {
    return CreateDataSourceNewLib.createDataSourcesAsync(this.selectedWriteModeOption, this.selectedDataSourceFields,
      this.currentDataSourceName, this.currentDataSourceDescription, this.datasourceService,
      this.currentConnectorView, this.bionApi, this.datasourcePrefix, this.suffixConnect, this.autoTableName)
  }


  // handleError(error: any) {
  //   // GUI Error handling
  //   if(error instanceof GuiErrorException) {
  //     let typedError = <GuiErrorException>error;
  //     this.errorService.sendErrorCode(typedError.code)
  //   }
  //       // Http Error handling

  //   if(error instanceof HttpErrorResponse) {
  //     let typedError = <HttpErrorResponse>error;
  //     this.errorService.handleError(typedError);
  //   }
  //   this.errorService.handleError(<Error>error);
  // }

}
