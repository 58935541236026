import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SqlDummyApiService } from '../sql-dummy-api.service';
import { DesignerStatusEvent, HeadToolbar, ToolbarCommand } from '../sql-workflow-sketch';

@Component({
  selector: 'app-toolbar-view',
  templateUrl: './toolbar-view.component.html',
  styleUrls: ['./toolbar-view.component.scss']
})
export class ToolbarViewComponent implements OnInit, HeadToolbar  {
  
  @Input() commands: ToolbarCommand[] = [];

  constructor(private sql_api: SqlDummyApiService) { }

  ngOnInit(): void {
  }

  //@Output() commandFired:EventEmitter<ToolbarCommand> = new EventEmitter<ToolbarCommand>();

  setCommands(commands:ToolbarCommand[]) : Observable<boolean> {
    //throw new Error("Not implemented");
    this.commands = commands;
    //this.assignCommands(commands);
    return of(true)
  }


  setStatusInfo(event: DesignerStatusEvent) : Observable<boolean>{
    throw new Error("Not implemented");
  }

  onCommandClick(event:ToolbarCommand) {
    // bla bla
    console.log("ToolbarCommand",event);
    this.sql_api.toolbarCommandFired.emit(event);
  }


}
