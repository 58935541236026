export class DataType {
    Name: string;
    Length: number;
    Format: string;
    FixedSize: boolean;
    Precision: number;
    constructor(Name: string,Length: number,Format: string,FixedSize: boolean,Precision: number) {
        if(Name === undefined) throw new Error( "Class 'DataType': Required value 'Name' is undefined" );
        if(Length === undefined) throw new Error( "Class 'DataType': Required value 'Length' is undefined" );
        if(Format === undefined) throw new Error( "Class 'DataType': Required value 'Format' is undefined" );
        if(FixedSize === undefined) throw new Error( "Class 'DataType': Required value 'FixedSize' is undefined" );
        if(Precision === undefined) throw new Error( "Class 'DataType': Required value 'Precision' is undefined" );
        this.Name = Name;
        this.Length = Length;
        this.Format = Format;
        this.FixedSize = FixedSize;
        this.Precision = Precision;
    }
}