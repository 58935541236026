import {
	Component,
	Input,
	OnInit,
	SimpleChanges,
} from "@angular/core";
import { WorkflowStatusLogEntry } from "src/app/models/designer.models";

@Component({
	selector: "app-nodes-status",
	templateUrl: "./nodes-status.component.html",
	styleUrls: ["./nodes-status.component.scss"],
})
export class NodesStatusComponent implements OnInit {
	// -- Node States Table
	nodeStatesCols: any[] = [];
	// -- Node States Chart
	chartData: any;
	chartOptions: any;
	@Input() toggleView: boolean = false;
	showImg: boolean = true;
	workflowFailed: boolean = false;

	@Input() workflowStatesLog!: WorkflowStatusLogEntry[];

	constructor() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.workflowStatesLog.currentValue) {
			this.initChart(changes.workflowStatesLog.currentValue);
		}
	}

	ngOnInit(): void {

		// Prepare column for table
		this.nodeStatesCols = [
			{ field: "NodeID", header: "Node", width: "50%" },
			{ field: "Message", header: "State", width: "50%" },
		];
		this.initChart();
	}

	initChart(states?: WorkflowStatusLogEntry[]) {
		let data = states;
		let failedNumber: number = 0;
		let succeedNumber: number = 0;
		let dataArray: number[] = [];

		if (data !== undefined) {
			data.map((value) => {
				if (value.Message === "Succeeded") {
					succeedNumber = succeedNumber + 1;
				}
				if (value.Message === "Failed") {
					failedNumber = failedNumber + 1;
				}
			});
		}

		this.workflowFailed = failedNumber > 0;

		dataArray = [failedNumber, succeedNumber];

		let chartData = {
			labels: ["Failed", "Succeeded"],
			datasets: [
				{
					data: dataArray,
					backgroundColor: ["#FF6384", "#36A2EB"],
					hoverBackgroundColor: ["#FF6384", "#36A2EB"],
				},
			],
		};
		this.chartData = chartData;
		this.chartOptions = {
			plugins: {
				legend: {
					labels: {
						fontColor: "#A0A7B5",
					},
					display: false,
				},
			},
		};
		this.showImg = false;
	}
}