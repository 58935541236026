<div>
    <p-table [value]="missingValueList" dataKey="ColumnName" editMode="row" [reorderableColumns]="false">
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                <!-- <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="{{'LogHistory.SearchForLog' | translate}}" />
                </span> -->

                <span class="p-input-icon-right">
                    <button style="float: right" pButton label="{{'Add' | translate}}" icon="fa fa-plus"
                        (click)="onAddRow()"></button>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width:3rem"></th>
                <th>{{'Column' |translate }}</th>
                <th tooltipPosition="top" pTooltip="{{'Config.MissingValue.Tooltip1' | translate}}">
                    {{'Column Type' |translate }}</th>
                <th tooltipPosition="top" pTooltip="{{'Config.MissingValue.Tooltip2' | translate}}">
                    {{'Operation' |translate }}</th>
                <th tooltipPosition="top" pTooltip="{{'Config.MissingValue.Tooltip3' | translate}}">
                    {{'Operation Value' |translate }}
                </th>
                <th style="width:3rem"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-column let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="column" [pReorderableRow]="ri"
                [ngClass]="{'row-accessories': column.hasError === true}">
                <td>
                    <span class="pi pi-sort-alt" pReorderableRowHandle (onChange)="onUpdateSettings(false)"></span>
                </td>
                <td>
                    <!-- <p-dropdown appendTo="body" [options]="columnList" [(ngModel)]="column.ColumnName"
                            optionLabel="Name" dataKey="Name" (onChange)="onUpdateSettings(false)" autoWidth="false"
                            [style]="{'width':'100%'}"></p-dropdown> -->
                    <p-dropdown appendTo="body" *ngIf="column.hasError === false" [options]="columnsList"
                        (onChange)="onUpdateSettings(false)" [autoDisplayFirst]="false" [(ngModel)]="column.ColumnName"
                        autoWidth="false" [style]="{'width':'100%'}"></p-dropdown>
                    <p-dropdown *ngIf="column.hasError === true" appendTo="body" [options]="columnsList"
                        (onChange)="onUpdateSettings(false)" [autoDisplayFirst]="false" [(ngModel)]="column.ColumnName"
                        class="ng-invalid ng-dirty" autoWidth="false" [style]="{'width':'100%'}"></p-dropdown>
                </td>
                <td>
                    <p-dropdown appendTo="body" [options]="dataTypeOptions" [(ngModel)]="column.ColumnDataType"
                        (onChange)="onUpdateSettings(false)" optionLabel="Name" dataKey="Name" autoWidth="false"
                        [style]="{'width':'100%'}"></p-dropdown>
                </td>
                <td>
                    <p-dropdown appendTo="body" [options]="operationOptions" [(ngModel)]="column.Operator"
                        (onChange)="onUpdateSettings(false)" optionLabel="name" dataKey="name" autoWidth="false"
                        [style]="{'width':'100%'}"></p-dropdown>
                </td>
                <td>
                    <input pInputText [(ngModel)]="column.OperatorValue" [ngModelOptions]="{standalone: true}"
                        (blur)="onUpdateSettings(false)" [style]="{'width':'100%'}">
                </td>
                <td>
                    <div class="ui-table-buttongroup">
                        <button pButton pRipple type="button" class="p-button-rounded p-button-text ui-button-danger"
                            icon="pi pi-trash" pTooltip="Delete entry" (click)="onDeleteEntry(ri)"
                            tooltipPosition="bottom"></button>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="6">{{'NoEntryFound' |translate }}</td>
            </tr>
        </ng-template>
    </p-table>
</div>