import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { DataSet } from "src/app/models/datamarket.model";
import { DataMarketService } from "src/app/services/data-market.service";
import { SubSink } from "subsink";

@Component({
	selector: "app-data-set-details-dialog",
	templateUrl: "./data-set-details-dialog.component.html",
	styleUrls: ["./data-set-details-dialog.component.scss"],
})
export class DataSetDetailsDialogComponent implements OnInit, OnDestroy {
	subs = new SubSink();
	headerText: string = "";
	@Input() selectedDataSet?: DataSet;
	@Input() dataPreviewColumns;
	displayDataSetDetails: boolean = false;

	constructor(private dataMarketService: DataMarketService) {}
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngOnInit(): void {
		this.subs.sink =
			this.dataMarketService.displayDataSetDetailsDialog.subscribe(
				(res: boolean) => {
					this.displayDataSetDetails = res;
				}
			);
	}
}
