<div class="p-grid">
    <div class="p-col-12 headerSection">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown appendTo="body" [options]="columnList" (onChange)="onUpdateSettings(false)"
                [(ngModel)]="firstNameColumn" autoWidth="false" [style]="{'width':'100%'}">
                </p-dropdown>
                 <label for="datasourceName">{{'First Name Column' |translate }}</label>
            </span>
        </div>
    </div>

    <div class="field p-col-12">
        <span class="p-float-label">
            <input  id="inputtext" (blur)="onUpdateSettings(false)" pInputText
            [(ngModel)]="apiKey" [style]="{'width':'100%'}"
            [ngModelOptions]="{standalone: true}">
            <label for="inputtext">{{'API Key' | translate}}</label>
        </span>
    </div>

    <div class="p-col-12">
        <div class="p-field-checkbox">
            <p-checkbox name="group1" [binary]="true" (onChange)="onUpdateSettings(false)"
            [(ngModel)]="sendingActiveFlag" inputId="ny3" label="{{'Active Service' |translate }}"></p-checkbox>
        </div>
    </div>
</div>



