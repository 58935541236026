import { Component, OnDestroy, OnInit } from "@angular/core";
import { MessageService } from "primeng/api";
import { DatasourcesService } from "src/app/services/datasources.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { SubSink } from "subsink";
import * as dss from "../../../../models/datasource.model";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-edit-datasource-dialog",
	templateUrl: "./edit-datasource-dialog.component.html",
	styleUrls: ["./edit-datasource-dialog.component.scss"],
	providers: [MessageService],
})
export class EditDatasourceDialogComponent implements OnInit, OnDestroy {
	subs = new SubSink();
	savingInProgress = false;
	buttonDisabled: boolean = false;
	displayEditDatasource: boolean = false;
	currentSelectedDatasource?: dss.DataSource;

	dataSourceName: string;
	dataSourceDescription: string;

	constructor(
		private datasourceService: DatasourcesService,
		private messageService: MessageService,
		private systemLogService: SystemMessageLogService,
		private translate: TranslateService
	) {}
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngOnInit(): void {
		this.subs.sink = this.datasourceService.displayEditDatasource.subscribe(
			(res: [dss.DataSource, boolean]) => {
				this.currentSelectedDatasource = res[0];
				this.dataSourceName = res[0].name;
				this.dataSourceDescription = res[0].description;
				this.displayEditDatasource = res[1];
			}, (err: Error) => {
				this.systemLogService.handleError(err)
			}
		);
	}

	updateDatesource() {
		this.savingInProgress = true;
		this.buttonDisabled = true;
		let clone = { ...this.currentSelectedDatasource };
		clone.name = this.dataSourceName;
		clone.description = this.dataSourceDescription;

		this.subs.sink = this.datasourceService.updateDataSource(clone).subscribe(
			(res) => {
				// this.datasourceService.emitUpdatedDatasource(clone);
                this.datasourceService.datasourceChangedEmitter.emit(res);
				this.messageService.add({
					severity: "success",
					summary: this.translate.instant("Message.UpdateDataSourceSuccess.Title"),
					detail: this.translate.instant("Message.UpdateDataSourceSuccess.Text1") + clone.id +
					this.translate.instant("Message.UpdateDataSourceSuccess.Text2"),
					// summary: "Datasource updated!",
					// detail:
					// 	"Datasource with id " + clone.id + " was successfully updated",
				});
				this.displayEditDatasource = false;
			},
			(err: Error) => {
				this.systemLogService.handleError(err);
			}, () => {
				this.savingInProgress = false;
				this.buttonDisabled = false;
			}
		);
	}
}
