import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { ChangeUserPasswordInfo, RegisterUserInfo } from "../models/email.model";

@Injectable({
	providedIn: "root",
})
export class EmailService {
	baseUrl = "http://localhost:4211";
	registerUserUrl = this.baseUrl + "/registerUser";
	inviteUserUrl = this.baseUrl + "/inviteUser";
	changeUserPasswordUrl = this.baseUrl + "/changeUserPassword";
	sendDataViaEmailUrl = this.baseUrl + "/sendDataViaMail";

	readonly BackEndUrl = environment.API_URL;

	constructor(private http: HttpClient) {}

	notifyRegisterUser(info:RegisterUserInfo) : Observable<string> {
		return this.http.post<string>(this.BackEndUrl + "/Mail/Service/notifyRegisterUser", info);
	}
	notifyChangedUserPassword(info:ChangeUserPasswordInfo) {
		return this.http.post<string>(this.BackEndUrl + "/Mail/Service/notifyChangeUserPassword", info);
	}

	registerUserEmail(data: any): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Access-Control-Allow-Origin", "*");
        console.log("Before",headers);
		headers = headers.set("Access-Control-Allow-Origin", "*");
        console.log("After",headers);
		console.log(data);

		return this.http.post(this.registerUserUrl, data, { headers: headers });
	}

	inviteUserEmail(data: any): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Access-Control-Allow-Origin", "*");
		headers = headers.set("Access-Control-Allow-Origin", "*");
		console.log(data);

		return this.http.post(this.inviteUserUrl, data, { headers: headers });
	}

	changedUserPasswordEmail(data: any): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Access-Control-Allow-Origin", "*");
		headers = headers.set("Access-Control-Allow-Origin", "*");
		console.log(data);

		return this.http.post(this.changeUserPasswordUrl, data, {
			headers: headers,
		});
	}

	sendDataViaEmail(data: any): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append("Access-Control-Allow-Origin", "*");
		headers = headers.set("Access-Control-Allow-Origin", "*");
		console.log(data);

		return this.http.post(this.sendDataViaEmailUrl, data, { headers: headers });
	}
}
