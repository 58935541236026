import { DynamicItemInfo } from "src/app/models/com-bion-json";
// import { UserInfo } from "src/app/models/user.model";


export namespace ExtractDataToPsaStream {
    export class Argument {
		DataSourceID: number;
		Base64FileData?: string;
		WriteMode?: string;
		Simulate: boolean;
		//UserInfo: UserInfo;
		ConnectorSettings?: DynamicItemInfo;
		Description?: string;
		constructor(DataSourceID: number,Simulate: boolean,Base64FileData?: string,WriteMode?: string,ConnectorSettings?: DynamicItemInfo,Description?: string) {
			if(DataSourceID === undefined) throw new Error( "Class 'Argument': Required value 'DataSourceID' is undefined" );
			if(Simulate === undefined) throw new Error( "Class 'Argument': Required value 'Simulate' is undefined" );
			//if(UserInfo === undefined) throw new Error( "Class 'Argument': Required value 'UserInfo' is undefined" );
			this.DataSourceID = DataSourceID;
			this.Base64FileData = Base64FileData;
			this.WriteMode = WriteMode;
			this.Simulate = Simulate;
			//this.UserInfo = UserInfo;
			this.ConnectorSettings = ConnectorSettings;
			this.Description = Description;
		}
	}

    export class Result {
		Records: number;
		DataPackage: number;
		constructor(Records: number,DataPackage: number) {
			if(Records === undefined) throw new Error( "Class 'Result': Required value 'Records' is undefined" );
			if(DataPackage === undefined) throw new Error( "Class 'Result': Required value 'DataPackage' is undefined" );
			this.Records = Records;
			this.DataPackage = DataPackage;
		}
	}
}
