import { Id } from "src/app/helper/id";
import { ObjectProtocolRow } from "src/app/models/datasource.model";
import { UserDetailsRow } from "src/app/models/user.model";
import { UtilFunctionsService } from "src/app/services/util-functions.service";
import { ActivityEntry, hasImage } from "./latest-activity-provider";       
        
export class ActivityUtils {
    static extractImage(users: UserDetailsRow[], protocol:ObjectProtocolRow, utilService: UtilFunctionsService) {
        const userFound = users.find((user) => { return user.id === protocol.userId});

        let has_image : hasImage = {image:undefined};
        if(userFound && userFound.avatar) {
            has_image.image = utilService.int8ArrayToBase64Image(userFound.avatar);
            const new_entry:ActivityEntry = Object.assign({}, protocol,has_image);
            return new_entry;
            //protocol.image = this.utilService.int8ArrayToBase64Image(userFound.avatar);
        } else {
            const new_entry:ActivityEntry = Object.assign({}, protocol,has_image);
            return new_entry;
            //protocol.image = undefined;
        }
    };
}