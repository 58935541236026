import { Injectable } from "@angular/core";
import { AirbyteModel } from "../models/api/models/AirbyteModel";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiServiceUtils } from "./api-util";
import { TaskJobModel } from "../models/api/models/staging/TaskJobModel";
import { Connection, Sources, SyncCatalog } from "../models/airbyte-api-structures";
import { ApiModel } from "../models/api/com/bion/integrate/airbyte/ApiModel";

export interface SourceDefinition {
    sourceDefinitionId: string;
    name: string;
    icon: string;
    sourceType: string;
}

export interface MetaResponse {
    catalog: SyncCatalog;
    catalogId:string;
}

/**
 * Erstellt Data Sources mit Hilfe von Airbyte
 */
@Injectable({
    providedIn: "root",
})
export class AirbyteStagingService {

    readonly BackEndUrl = environment.API_URL;

    constructor(private http: HttpClient) {

    }

    /**
     * Creates the Airbyte Source
     * @param arg Argument
     * @returns
     */
    createSource<C, R>(arg: AirbyteModel.CreateSourceArg<C>): Observable<AirbyteModel.CreateSourceResult<R>> {
        return this.http.post<AirbyteModel.CreateSourceResult<R>>("/exp/api/Staging/DataSources/Airbyte/api/createSource", arg);
    }

    sourceInfos(id?: number, sourceId?: number, fetchSource?: boolean): Observable<AirbyteModel.SourceInfo<Sources.Source>[]> {
        const options = ApiServiceUtils.makeOptions(
            { param: "id", value: id },
            { param: "sourceId", value: sourceId },
            { param: "fetchSource", value: fetchSource });

        return this.http.get<AirbyteModel.SourceInfo<Sources.Source>[]>("/exp/api/Staging/DataSources/Airbyte/SourceInfos", options);
    }

    connectionInfos(id?: number, fetchConnection?: boolean): Observable<AirbyteModel.ConnectionInfo<Connection>[]> {
        const options = ApiServiceUtils.makeOptions(
            { param: "id", value: id },
            { param: "fetchConnection", value: fetchConnection });

        return this.http.get<AirbyteModel.ConnectionInfo<Connection>[]>("/exp/api/Staging/DataSources/Airbyte/api/ConnectionInfo/list", options);
    }

    sourceDefinitions(): Observable<SourceDefinition[]> {
        const options = ApiServiceUtils.makeOptions();
        return this.http.get<SourceDefinition[]>("/exp/api/Staging/DataSources/Airbyte/SourceInfos", options);
    }

    checkConnection<C>(item: AirbyteModel.CheckConnectionArg<C>): Observable<any> {
        return this.http.post<any>("/exp/api/Staging/DataSources/Airbyte/api/checkConnection", item);
    }

    /**
     * Verbindet ein Airbyte Quellsystem mit der Bion System Destination
     * @param arg 
     * @returns 
     */
    createConnection(arg: AirbyteModel.CreateConnectionArg): Observable<AirbyteModel.CreateConnectionResult<Connection, Sources.Source, any>> {
        return this.http.post<AirbyteModel.CreateConnectionResult<Connection, Sources.Source, any>>("/exp/api/Staging/DataSources/Airbyte/api/createConnection", arg);
    }

    /**
     * Query Data of this Source Stream / Data Source.
     * Returns a chunked stream which representation can vary.
     */
    queryStream(arg: AirbyteModel.QueryStreamArg) {

        const url: string = "/exp/api/Staging/DataSources/Airbyte/api/queryStream";

        const options = this.stream_options();

        const result = this.http.post<Blob>(url, arg, options);
        return result;
    }

    /**
     * HTTP headers for requests expecting chunked responses.
     * @returns 
     */
    stream_options() {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        const options = { headers, responseType: 'blob' as 'json' }; // Wichtig: responseType auf 'blob' setzen

        return options;
    }

    /**
     * Provides to unnormlized table as nested JSON how it was originally loaded by Airbyte.
     * @param arg
     * @returns
     */
    queryStreamRaw(arg: AirbyteModel.QueryStreamArg) {

        const url: string = "/exp/api/Staging/DataSources/Airbyte/api/queryStreamRaw";

        const result = this.http.post(url, arg, {
            reportProgress: true,
            observe: 'events',
            responseType: 'blob'
        });

        return result;
    }

    queryStreamRaw2(arg: AirbyteModel.QueryStreamArg): Observable<Blob> {

        const url: string = "/exp/api/Staging/DataSources/Airbyte/api/queryStreamRaw";

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            // Weitere Header-Parameter hier hinzufügen, wenn benötigt
        });

        const options = { headers, responseType: 'blob' as 'json' }; // Wichtig: responseType auf 'blob' setzen

        const result = this.http.post<Blob>(url, arg, options);

        return result;
    }

    /**
     * Trigger data extraction task
     */
    extractData(arg: AirbyteModel.ExtractDataFromSourceArg): Observable<TaskJobModel.JobRequestResult> {
        return this.http.post<TaskJobModel.JobRequestResult>("/exp/api/Staging/DataSources/Airbyte/api/extractData", arg);
    }

    extractMeta(arg: AirbyteModel.ExtractMetaFromSourceArg): Observable<MetaResponse> {
        return this.http.post<MetaResponse>("/exp/api/Staging/DataSources/Airbyte/api/extractMeta", arg);
    }

    getSourceDefinitionSpecs(arg: ApiModel.SourceDefinitionSpecs.GetReq) {
        return this.http.post<any>("/exp/api/Airbyte/api/v1/source_definition_specifications/get", arg);
    }
}
