import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Message } from 'primeng/api';
import { map, mergeMap } from 'rxjs/operators';
import { Id } from 'src/app/helper/id';
import { IntegratedSourceModel } from 'src/app/models/api/models/IntegratedSourceModel';
import { IntegratedSourceService } from 'src/app/services/integrated-source.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-update-streams-int-page',
  templateUrl: './update-streams-int-page.component.html',
  styleUrls: ['./update-streams-int-page.component.scss']
})
export class UpdateStreamsIntPageComponent implements OnInit {

  constructor(private service_api: IntegratedSourceService, private router: Router, private route: ActivatedRoute) { }
    subs = new SubSink;
    // -- API
    write_mode_infos: IntegratedSourceModel.GenCatalog.WriteModeInfo[] = [];
    new_generic_catalog?: IntegratedSourceModel.GenCatalog;
    get_catalog_result?: IntegratedSourceModel.GetStreamsResult = undefined;
    source_key?: IntegratedSourceModel.DataSourceKey<number>;

    // -- UI
    isLoading: boolean = false;
    progressMode: string = "determinate";
    messages: Message[] = [];

  ngOnInit(): void {
    this.source_key = this.getUrlContext();
    const source_key = this.source_key;

    const get_cat_arg = new IntegratedSourceModel.GetStreamsArg(source_key);
    const cat_obs = this.service_api.getStreams(get_cat_arg);

    const write_mode_obs = this.service_api.getWriteModeInfos();

    const cat_and_write_modes = cat_obs.pipe(mergeMap(cat => write_mode_obs.pipe(map(wm => [cat, wm]))));

    this.setLoading(true);

    this.subs.sink = cat_and_write_modes.subscribe(cat_and_write => {

      const pair: [IntegratedSourceModel.GetStreamsResult, IntegratedSourceModel.GenCatalog.WriteModeInfo[]] =
        <[IntegratedSourceModel.GetStreamsResult, IntegratedSourceModel.GenCatalog.WriteModeInfo[]]>cat_and_write;

      const cat = pair[0];
      this.write_mode_infos = pair[1];

      this.get_catalog_result = cat;
      this.new_generic_catalog = this.get_catalog_result.Catalog;
    },
      err => this.handle_error(err),
      () => this.setLoading(false)
    )
  }

  update_streams_click(event:any) {
    console.log("Define Streams Click", event);

    this.setLoading(true);
    this.messages = [];

    const use_backend_catalog_mode = true;
    console.log("Use Backend Catalog Mode: " + use_backend_catalog_mode);

    try {
      this.on_streams_updated(this.new_generic_catalog);
    } catch (e) {
      this.handle_error(e);
    } finally {
      this.setLoading(false);
    }
  }

  on_streams_updated(catalog:IntegratedSourceModel.GenCatalog) {

    this.assertCatalog(catalog);

    const ds_key = Id.assertSet(this.source_key, new Error("Datasource key not set"));

    console.log("WARNING - Incomplete - We need to provide the generic catalog so the backend can patch it!");

    const stop_before_call = false;

    if (stop_before_call) {
      this.handle_error(new Error("Version not implemented yet."))
      return;
    }

    const skip_reset = true;
    console.log("Skip reset is set to " + skip_reset);
    if(skip_reset) {
      console.log("We're keeping all old data");
    } else {
      console.log("We're dropping all old data.");
    }

    this.setLoading(true);

    const api_arg = new IntegratedSourceModel.UpdateStreamsArg(ds_key, catalog, skip_reset);
    this.subs.sink = this.service_api.updateStreams(api_arg).subscribe(
      api_result => this.handle_update_streams_result(api_result, ds_key),
      err => this.handle_error(err),
      () => this.setLoading(false)
    )
  }

  handle_update_streams_result(result:IntegratedSourceModel.UpdateStreamsResult, key:IntegratedSourceModel.DataSourceKey<number>) {
    // post processing -> confirm message, route to read only page
    //this.router.navigate(['/', 'SourceIntegrationGetStreams', key.Origin, key.Id]); //route deactivated
  }

  setLoading(loading: boolean): void {
    this.isLoading = loading;

    // https://www.primefaces.org/primeng-v14-lts/progressbar

    if (this.isLoading) {
      this.progressMode = "indeterminate";
    } else {
      this.progressMode = "determinate";
    }
  }

  handle_error(e: Error) {
    this.messages.push({ severity: 'error', summary: 'Error', detail: e.message });
    console.error(e);
    this.setLoading(false);
  }

  /**
 * Der Datasource Schlüssel aus der Route.
 */
  getUrlContext(): IntegratedSourceModel.DataSourceKey<number> {
    const arr = this.router.url.split('/');
    const id = parseInt(arr.last());
    const origin = arr.getRight(1);
    console.log("Data Source ID: " + id);
    console.log("Data Source Origin: " + origin);
    return new IntegratedSourceModel.DataSourceKey(id, origin);
  }

    /**
   * Make sure the catalog has at least one selected stream.
   * @param cat
   */
    assertCatalog(cat: IntegratedSourceModel.GenCatalog) {

      const streams_selected = cat.streams.filter(s => s.config.selected);

      if (streams_selected.isEmpty()) {
        throw new Error("Select at least one stream");
      }
    }

}
