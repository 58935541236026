import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { PowerBIModel } from 'src/app/models/api/models/PowerBIModel';
import { PowerBiActionEvent, PowerBiDialogActionType } from 'src/app/models/api/models/powerbi-report/PowerBiActionEvent';
import { PowerBiReportService } from 'src/app/services/power-bi-report.service';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { SubSink } from 'subsink';

@Component({
	selector: 'app-edit-powerbi-report',
	templateUrl: './edit-powerbi-report.component.html',
	styleUrls: ['./edit-powerbi-report.component.scss']
})
export class EditPowerbiReportComponent implements OnInit {

	subs = new SubSink();
	savingInProgress: boolean = false;
	displayDialog: boolean = false;
	selectedReport?: PowerBIModel.ReportInfo;
	header: string = "";
	inputDisabled: boolean = true;

	constructor(
		private biService: PowerBiReportService,
		private systemLogService: SystemMessageLogService,
		private messageService: MessageService,
		private translate: TranslateService
	) { }

	ngOnInit(): void {
		this.subs.sink = this.biService.PowerBiActionEmitter.subscribe(
			(res: PowerBiActionEvent) => {
				if (res.actionType === PowerBiDialogActionType.editReport) {

					if (res.report !== undefined) {
						const reportInfo = new PowerBIModel.ReportInfo(res.report.id, res.report.title, res.report.url.toString());
						this.selectedReport = reportInfo;
						this.displayDialog = res.display;
						this.header = res.header;
					} else {
						this.systemLogService.handleError(new Error("Event 'Edit Report' needs report object but it is undefined"))
					}

				}
			}, (err: Error) => {
				this.systemLogService.handleError(err)
			}
		);
	}

	updateReport(event?: PowerBIModel.ReportInfo) {

		if (event === undefined) return;

		this.savingInProgress = true;

		const repInfo = new PowerBIModel.ReportInfo(event.Id, event.Title, event.Url);

		this.subs.sink = this.biService.updateReportInfo(repInfo).subscribe(
			(res) => {
				this.savingInProgress = false;

				this.biService.PowerBiActionEmitter.emit(new PowerBiActionEvent(false, "Report Changed", PowerBiDialogActionType.ReportsChanged, "Changed"));

				this.messageService.add({
					severity: "success",
					summary: this.translate.instant("Message.UpdateReportSuccess.Title"),
					detail: this.translate.instant("Message.UpdateReportSuccess.Text1") + event.Id + this.translate.instant("Message.UpdateReportSuccess.Text2"),
				});

				this.displayDialog = false;
			},
			(err: Error) => {
				this.savingInProgress = false;
				this.systemLogService.handleError(err);
			}
		);
	}

}
