import { Component, Input, OnInit } from "@angular/core";
import { dataMarketConnectorView } from "src/app/models/connectors/datamarket-connector-view.model";
import { PricingModel } from "src/app/models/datamarket.model";

@Component({
	selector: "app-datamarket-connector",
	templateUrl: "./datamarket-connector.component.html",
	styleUrls: ["./datamarket-connector.component.scss"],
})
export class DatamarketConnectorComponent implements OnInit {
	savingInProgress = false;
	@Input() currentConnectorView: dataMarketConnectorView;
	// @Input() selectedDataSet?: DataSet;
	selectedDeliveryOption: string; // Variable for selectedUpdateOption
	selectedPaymentOption: PricingModel;

	constructor() { }

	ngOnInit(): void {

		console.log(this.currentConnectorView.connectorSettings);
		console.log(this.currentConnectorView.metaAccess);
		console.log(this.currentConnectorView.metaInfo);

		// TODO: Check if we need to call this method always on start.

		// this.currentConnectorView.fetchMetaDataObs().subscribe(meta_info => {
		// 	console.log("Fetch Meta Info: ", meta_info);
		// 	this.currentConnectorView.metaInfo = meta_info;

		// 	// If there is none select the first available set
		// 	if (!this.currentConnectorView.selectedDataSet && meta_info.Services.length > 0)
		// 		this.currentConnectorView.selectedDataSet = meta_info.Services[0];
			
		// 	console.log("Fetch Meta Info END: ", meta_info);
		// });
	}

	// ngOnChange() {
	// 	this.selectedPaymentOption = this.currentConnectorView.selectedDataSet.PricingModel[0];
	// 	console.log(this.selectedPaymentOption);
	// }


}
