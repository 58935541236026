import { Workflow } from "../../com/bion/etl/Workflow";
import { RunWorkflowSettings } from "./RunWorkflowSettings";

export class RunWorkflowArg {
	Workflow: Workflow;
	Settings: RunWorkflowSettings;
	constructor(Workflow: Workflow, Settings: RunWorkflowSettings) {
		if(Workflow === undefined) throw new Error( "Class 'RunWorkflowArg': Required value 'Workflow' is undefined" );
		if(Settings === undefined) throw new Error( "Class 'RunWorkflowArg': Required value 'Settings' is undefined" );
		this.Workflow = Workflow;
		this.Settings = Settings;
	}
}