import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { SpssPushAccess } from 'src/app/models/connector.model';
import { SpssConnectorView } from 'src/app/models/connectors/spss-connector-view.model';
import { ConnectorSettingsBuilder } from 'src/app/models/connectorView.model';
import { DataSourceAdapter } from 'src/app/models/datasource.model';

@Component({
  selector: 'app-spss-connector',
  templateUrl: './spss-connector.component.html',
  styleUrls: ['./spss-connector.component.scss']
})
export class SpssConnectorComponent implements OnInit {
  selectedConnector: DataSourceAdapter;
  loading: boolean;
  modes: string [];

  @Input() currentConnectorView: SpssConnectorView;
  @Input() isChangeSettingsOnlyFlag: boolean = false;


  uploadFilesAsync(event) {
    let input = event.files;
    let firstFile = input[0];
    this.currentConnectorView.uploadFile(firstFile);


    this.currentConnectorView.fetchMetaDataObs().subscribe(
      (res) => {

        this.cd.detectChanges();

        this.loading = false;
      }
    )


    this.currentConnectorView.getSettingsBuilder().subscribe(
      (res:ConnectorSettingsBuilder<SpssPushAccess>) => {
        console.log(res);
        this.currentConnectorView.connectorSettings = res.getConnectorSettings();
      }
    );
  }
  onRemoveFile(event) {
    console.log(event);
  }

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.modes = ['Data', 'Meta', 'Variables'];
  }

}
