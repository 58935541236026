<p-toast position="top-center" baseZIndex="99999999999"
    [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>


<div class="p-grid" style="margin-top:15px;display: flex; align-items: center;">
    <span class="p-col" style="display: flex; align-items: center;">
        <h5 style="margin-bottom:0">
            {{'API interfaces' | translate }}
        </h5>
        <span style="margin-left:5px"><app-info-tooltip></app-info-tooltip></span>
    </span>
    <span class="p-col-5" style="float:right;text-align:right">
        <button type="button" class="p-button-text" pButton icon="pi pi-question"
            pTooltip="{{'Permission.CreateNewRole' | translate}}" label="{{'Request custom API' | translate}}"></button>
    </span>
</div>


<div class="card">
    <div class="card-header">
        <div class="card-title">
            <h5>{{'ODataEndpoint' | translate}}</h5>
        </div>
    </div>
    <p class="subtitle">
        {{'ODataDescription'|translate}}
    </p>
    <div class="card-content p-formgrid">
        <div class="p-inputgroup" style="margin-top:30px">
            <span class="p-float-label">
                <input [disabled]=true type="text" id="finalUrlInputgroup" pInputText [(ngModel)]="finalUrl">
                <button type="button" icon="pi pi-copy" pButton pRipple label="{{'Copy' | translate}}"
                    tooltipPosition="bottom" pTooltip="{{'CopyUrlToClipboard' | translate}}"
                    (click)="textToClipboard(finalUrl)"></button>
                <label for="finalUrlInputgroup">Full Url</label>
            </span>
        </div>
    </div>
    <p-divider></p-divider>
    <div class="card-footer">
        <p style="font-weight: bold">{{'SupportedByTools'|translate}}</p>
        <span>
            <p-avatarGroup styleClass="mb-3">
                <p-avatar *ngFor="let sup of oDataSupports" image="assets/layout/images/connectors/{{sup.label}}.png"
                    size="large" shape="circle"></p-avatar>
            </p-avatarGroup>
        </span>
    </div>
</div>


<div class="p-grid" style="margin-top:15px;display: flex; align-items: center;">
    <span class="p-col" style="display: flex; align-items: center;">
        <h5 style="margin-bottom:0">
            {{'File Download' | translate }}
        </h5>
        <span style="margin-left:5px"><app-info-tooltip></app-info-tooltip></span>
    </span>
    <span class="p-col-5" style="float:right;text-align:right">
        <button type="button" class="p-button-text" pButton icon="pi pi-question"
            pTooltip="{{'Permission.CreateNewRole' | translate}}"
            label="{{'Request custom filetype' | translate}}"></button>
    </span>
</div>

<div class="card">
    <p-table #dtc [value]="exportFormatInfos" selectionMode="single" styleClass="p-datatable-customers p-datatable-sm"
        [filterDelay]="0" [globalFilterFields]="['action','objectType','objectId','user','end']">
        <ng-template pTemplate="header" let-activity>
            <tr>
                <th pSortableColumn="end" style="width: 30px;">
                </th>
                <th pSortableColumn="end" style="width: 80%;">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Filetype' | translate}}
                        <p-sortIcon field="end"></p-sortIcon>
                    </div>
                </th>
                <th style="width: 4rem"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-format>
            <tr>
                <td style="width: 30px;">
                    <img src="assets/layout/images/connectors/{{format.Name}} Adapter.png" width="30" height="30"
                        alt="freya-layout" />
                </td>
                <td style="width: 80%;">
                    <span class="p-column-title">{{'Exporttype' | translate}}</span>
                    <div style="display:flex;align-items: center;">
                        {{format.Name}}
                    </div>
                </td>
                <td style="width: 4rem">
                    <div class="ui-table-buttongroup-right">
                        <button pButton pRipple [disabled]="isDownloading"
                            [icon]="isDownloading ? 'pi pi-spin pi-spinner' : 'pi pi-save'"
                            label="{{'Download' | translate}}" class=" p-button p-mr-2 p-mb-2"
                            pTooltip="{{'DownladAsFile' | translate}}" tooltipPosition="bottom"
                            (click)="onDownloadFile(format)"></button>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="4">{{'NoEntryFound' | translate}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>