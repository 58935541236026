<div>
    <div class="p-grid" style="display: flex; align-items: center;">
        <div class="p-col-1">
            <i class="pi pi-key" style="font-size: 24px"></i>
        </div>
        <div class="p-col-3">
            <span>Owner</span>             
        </div>
        <div class="p-col">
            <span  style="float:right">
                <p-avatarGroup styleClass="mb-3">
                    <p-avatar image="assets/demo/images/avatar/amyelsner.png" size="large" shape="circle"></p-avatar>
                    <p-avatar image="assets/demo/images/avatar/asiyajavayant.png" size="large" shape="circle"></p-avatar>
                    <p-avatar image="assets/demo/images/avatar/onyamalimba.png" size="large" shape="circle"></p-avatar>
                    <p-avatar image="assets/demo/images/avatar/ionibowcher.png" size="large" shape="circle"></p-avatar>
                    <p-avatar image="assets/demo/images/avatar/xuxuefeng.png" size="large"shape="circle"></p-avatar>
                    <p-avatar label="+2" shape="circle" size="large" [style]="{'background-color':'#9c27b0', 'color': '#ffffff'}"></p-avatar>
                </p-avatarGroup>
            </span>
        </div>
    </div>
    <div class="p-grid" style="display: flex; align-items: center;">
        <div class="p-col-1">
            <i class="pi pi-user" style="font-size: 24px"></i>
        </div>
        <div class="p-col-3">
            <span>Team Member</span>             
        </div>
        <div class="p-col">
            <span  style="float:right">
                    <p-avatarGroup styleClass="mb-3">
                        <p-avatar image="assets/demo/images/avatar/amyelsner.png" size="large" shape="circle"></p-avatar>
                        <p-avatar image="assets/demo/images/avatar/asiyajavayant.png" size="large" shape="circle"></p-avatar>
                        <p-avatar image="assets/demo/images/avatar/onyamalimba.png" size="large" shape="circle"></p-avatar>
                        <p-avatar image="assets/demo/images/avatar/xuxuefeng.png" size="large"shape="circle"></p-avatar>
                        <p-avatar label="+6" shape="circle" size="large" [style]="{'background-color':'#9c27b0', 'color': '#ffffff'}"></p-avatar>
                    </p-avatarGroup>
            </span>
        </div>
    </div>
</div>
<p-divider></p-divider>
<h5 style="position:relative; bottom:0;">Managed by 22 <p-chip label="members" icon="pi pi-users"></p-chip>                <span  style="float:right"><button pButton label="{{'Manage' | translate}}"></button> </span>
</h5>  