import { NodeProperties, WorkflowNodeSettings } from "src/app/models/api/com/bion/etl/Workflow";
import { WorkflowNodeInfo } from "src/app/models/designer.models";

export interface GoPortEntry {
    portColor:string;
    portId:string;
  }
  
  export interface GoNode {
    id:number;
    type:string;
    name:string;
    loc:string;
    leftArray?:GoPortEntry[];
    topArray?:GoPortEntry[];
    bottomArray?:GoPortEntry[];
    rightArray?:GoPortEntry[];
    nodeProperties?: NodeProperties;
    value: WorkflowNodeInfo;
    description?: string;
  }
  
  export interface GoPaletteEntry {
    key: string;
    text: string;
    color: string;
    value: WorkflowNodeInfo;
  }

  export interface GoNodePaletteEntry extends GoPaletteEntry {
    name?: string;
    leftArray?:GoPortEntry[];
    rightArray?:GoPortEntry[];
  }
  
  export interface GoEdgeEntry {
    from: number;
    to: number;
    fromPort: string;
    toPort: string;
    progress?: string
  }
  