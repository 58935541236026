import { Component, OnInit } from "@angular/core";
import { Arrays } from "src/app/helper/arrays";
import { TableEvents } from "src/app/helper/events";
import { Id } from "src/app/helper/id";
import { MetaInfo } from "src/app/models/api/com/bion/etl/NodeMetaData";
import { UnionSettings } from "src/app/models/nodeSettings.model";
// import { MetaInfo } from "src/app/models/workflow.model";
import { DesignerService } from "src/app/services/designer.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { NodeConfigComponentBase } from "../node-config-component-base";

// export class FlatFieldInfo {
// 	name: string;
// 	datatype: string;
// 	datalength: number;
// }

export class FieldNameView {
	header: string;
	field: string;

	constructor(header: string, field: string) {
		this.header = header;
		this.field = field
	}
}
export class UnionCellView {
	field: string;
	isInFieldUnion: boolean;
	edgeLabel: string;

	constructor(field: string, isInFieldUnion: boolean, edgeLabel: string) {
		this.field = field;
		this.isInFieldUnion = isInFieldUnion;
		this.edgeLabel = edgeLabel
	}
}

interface UnionRow {
	//Field:string;
	[index:string] : boolean | string;
}

@Component({
	selector: "app-union-node",
	templateUrl: "./union-node.component.html",
	styleUrls: ["./union-node.component.css"],
})
export class UnionNodeComponent
	extends NodeConfigComponentBase<UnionSettings>
	implements OnInit
{
	settingsToView(settings: UnionSettings): void {
		this.currentConfig = <UnionSettings>settings;

		// -- Get Input Meta Data
		// const meta_infos = this.getCurrentWorkflowNode().Properties.MetaInfo.get(
		// 	this.InputPort
		// );

		const meta_infos = this._currentWorkflowNodeAdapter.IWorkflowNode.Properties.MetaInfo.get(
			this.InputPort
		);

		// --create SelectInfoViews from DsFields for table
		if (meta_infos != undefined && meta_infos.length > 0) {
			// -- FUNCTIONING CODE WITH UNTRANSPOSED TABLE
			let fieldInfoStringList = meta_infos.map((fieldInfo) => {
				return fieldInfo.FieldsInfo.map((fieldInfo) => {
					return fieldInfo.Name;
				});
			});


			let fieldNameList: string[] = [];
			fieldInfoStringList.map((entry) => {
				fieldNameList = fieldNameList.concat(entry);
			});

			let fieldNameSet = new Set(fieldNameList);

			let fieldNameUniqueList = Array.from(fieldNameSet.keys());
			// const fieldNameListFiltered = fieldNameUniqueList.filter((val) => {return settings.UnionInfos.includes(val)})

			this.fieldNameUniqueList = fieldNameUniqueList;

			// this.unionListCols = fieldNameUniqueList.map((entry) => {
			// 	const newFieldNameView = new FieldNameView();
			// 	newFieldNameView.field = entry;
			// 	newFieldNameView.header = entry;

			// 	return newFieldNameView;
			// });

			let buildTableResult = this.buildTable(fieldNameUniqueList, meta_infos,settings);
			this.unionListCols = buildTableResult[0];
			this.unionInputData = buildTableResult[1];

			if (this.currentConfig.UnionInfos) {
				console.log(
					"Initial unionInfos found, write existing unioninfo to view",
					buildTableResult[1]
				);
				let selection: UnionRow[] = buildTableResult[1];
				console.log("selection",selection);

				let selectionViewArray: UnionRow[] = [];

				for (let i of settings.UnionInfos) {
					let selected = selection.find((entry: UnionRow) => {
						return i === entry.Field;
					});
					selectionViewArray.push(selected);
				}

				// let selectionViewArrayFlat: UnionRow[] = [];
				// selectionViewArray.map((i) => {
				// 	selectionViewArrayFlat.push(i[0]);
				// });
				//this.unionInputDataSelection = selectionViewArrayFlat;
				this.unionInputDataSelection = selectionViewArray;
			} else {
				console.log("No Initial unionInfos found, empty array");
				this.unionInputDataSelection = [];
			}
		}
	}
	viewToSettings(): UnionSettings {
		return this.onChangeSettings(this.unionInputDataSelection);
	}
	onSettingsChanged(settings: UnionSettings) {
		throw new Error("Method not implemented.");
	}

	readonly InputPort = "Input";

	currentConfig?: UnionSettings;
	unionInputData: UnionRow[] = [];
	unionInputDataSelection: UnionRow[] = [];
	unionListCols: FieldNameView[] = [];
	inputPortsList: FieldNameView[] = [];
	fieldNameUniqueList: string[] = [];
	edgeOrder: string[] = [];
	//unionCols: any[] = [];
	//selectedUnionList: FieldInfo[] = [];

	buildTable(fieldNameUniqueList: string[], metaInfos: MetaInfo[], settings?:UnionSettings) : [FieldNameView[],UnionRow[]]{

		let portNames: string[] = [];

		if(settings && settings.EdgeOrder) {
			if(settings.EdgeOrder.length > 0) {
				portNames = settings.EdgeOrder;
			} else {

				//portNames = Arrays.flatten(metaInfos.map(info => info.EdgeLabel));
				portNames = metaInfos.map(info => info.EdgeLabel).dropUndef();

				// portNames = metaInfos.map((info) => {
				// 	return info.EdgeLabel;
				// });
			}
		}

		this.edgeOrder = portNames;

		let field_col = new FieldNameView("Field","Field");
		// field_col.field = "Field";
		// field_col.header = "Field";

		let edgeCols = portNames.map((c) => {
			let edgeFieldCol = new FieldNameView(c,c);
			// edgeFieldCol.field = c;
			// edgeFieldCol.header = c;
			return edgeFieldCol;
		});

		let columns = [field_col].concat(edgeCols);
		let rows: any[] = [];

		for (let dsfield of fieldNameUniqueList) {
			let firstColValue = dsfield;
			//let newBoolArray = new Array<boolean>();

			//let row:UnionRow = { Field:  firstColValue};
			let row:UnionRow = {};
			row["Field"] = firstColValue;
			for (let metaInfo of metaInfos) {
				let findResult = metaInfo.FieldsInfo.find((fieldInfo) => {
					return fieldInfo.Name == firstColValue;
				});
				const edge_label = Id.assertSet(metaInfo.EdgeLabel, new Error("The edge label is not set!"));
				row[edge_label] = findResult !== undefined;
			}
			rows.push(row);
		}

		const result:[FieldNameView[],UnionRow[]] = [columns, rows];

		return result;
	}

	onChangeSettings(list: UnionRow[]) {
		const newUnionConfig = <UnionSettings>{
			...this.getCurrentWorkflowNode().Properties.Configuration,
		};
		newUnionConfig.Axis = 0; // default set to 0 for first row
		newUnionConfig.UnionType = "outer"; // default

		if(!this.unionInputDataSelection || this.unionInputDataSelection.length === 0) {
			newUnionConfig.UnionInfos = [];

		} else {
			let finalUnionInfos = <string[]>this.unionInputDataSelection.map((entry) => {
				return entry["Field"];
			});
		
			finalUnionInfos = finalUnionInfos.dropUndef();
	
			// -- write list back to final class
			newUnionConfig.UnionInfos = finalUnionInfos;
		}

		newUnionConfig.EdgeOrder = this.edgeOrder;

		return newUnionConfig;
	}

	onReorderColumns(event: TableEvents.OnReOrderColumns<any, FieldNameView>) {
		let order = event.columns;
		let orderFiltered: FieldNameView[] = order.filter((view: FieldNameView) => {
			return view.field !== "Field";
		});
		this.edgeOrder = orderFiltered.map((res) => {
			return res.field;
		});
		this.onUpdateSettings(false);
	}

	constructor(
		protected workflowService: WorkflowsService,
		protected designerEventService: DesignerService
	) {
		super(workflowService, designerEventService);
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

	ngOnDestroy(): void {}
    onFocusLost(event: FocusEvent) {
        const div_left: boolean = (event.relatedTarget === null);

        console.log("Send from Div: ", div_left);

        if (div_left) {
            //this.onCheckDirtyFlag();
            this.onUpdateSettings(true);
        }
    }
}
