import { Component, OnInit } from '@angular/core';
import { FieldInfo, WorkflowNodeSettings } from 'src/app/models/api/com/bion/etl/Workflow';
import { PresentValueInfo, PresentValueSettings } from 'src/app/models/nodeSettings.model';
// import { FieldInfo, WorkflowNodeSettings } from 'src/app/models/workflow.model';
import { DesignerService } from 'src/app/services/designer.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { NodeConfigComponentBase } from '../node-config-component-base';



export class PresentValueInfoView {
    TargetColumn: FieldInfo;
    DateColumn: FieldInfo;
    RateColumn?: FieldInfo;
    Rate?: number;
    PaymentFrequency: DropDown; // ggf number?
    PaymentMode: DropDown;
    StartDate: Date;      //ggf number?
    EndDate: Date;        //ggf number?
}
export class DropDown {
    name: string;
    value: number;
}

@Component({
  selector: 'app-present-value-node',
  templateUrl: './present-value-node.component.html',
  styleUrls: ['./present-value-node.component.css']
})
export class PresentValueNodeComponent extends NodeConfigComponentBase<PresentValueSettings> implements OnInit {
    settingsToView(settings: WorkflowNodeSettings): void {


        // -- Get node config
        //let currentConfig = <JoinSettings>{...this.getCurrentWorkflowNode().Properties.Configuration};
        let currentConfig = <PresentValueSettings>{...settings};
        // -- Get Input Meta Data
        const meta_infos = this.getCurrentWorkflowNode().Properties.MetaInfo.get(this.InputPort);
        console.log("FILTER: Checking Meta Infos of Port: ", meta_infos);

        // create SelectInfoViews from DsFields for table
        if (meta_infos != undefined && meta_infos.length > 0) {
            console.log("FILTER: MetaInfos available: ",meta_infos);
            const input_field_infos = meta_infos[0].FieldsInfo;
            this.dsfieldList = input_field_infos;
        }

        if(currentConfig.PresentValueInfos.length>0) {
            console.log("PV Infos exists in Config, prepare load", currentConfig.PresentValueInfos);
            this.currentConfig = this.infoToView(currentConfig.PresentValueInfos)[0];
        } else {
            console.log("No PV Infos found, initialize empty config");

            this.currentConfig = this.initConfig();
        }
    }
    viewToSettings(): PresentValueSettings {
        let newSettings = <PresentValueSettings>{...this.getCurrentWorkflowNode().Properties.Configuration};

        let newConfig = new PresentValueInfo();
        newConfig.TargetColumn = this.currentConfig.TargetColumn.Name;
        newConfig.DateColumn = this.currentConfig.DateColumn.Name;
        newConfig.RateColumn = this.currentConfig.RateColumn.Name;
        newConfig.Rate = this.currentConfig.Rate;
        newConfig.PaymentFrequency = this.currentConfig.PaymentFrequency.name;
        newConfig.PaymentMode = this.currentConfig.PaymentMode.name;
        newConfig.StartDate = this.currentConfig.StartDate;
        newConfig.EndDate = this.currentConfig.EndDate;

        newSettings.PresentValueInfos = [newConfig];

        console.log("PV: Final Settings", newSettings);

        return newSettings;

    }
    onSettingsChanged(settings: PresentValueSettings): void {
        throw new Error('Method not implemented.');
    }


    readonly InputPort="Input";
    currentConfig: PresentValueInfoView;
    dsfieldList: FieldInfo[] = [];
    paymentFrequencyOptions: DropDown[];
    paymentModeOptions: DropDown[];
    isConstantRate: boolean;


    constructor(
        protected workflowService: WorkflowsService,
        protected designerService: DesignerService,
    ) {
        super(workflowService,designerService);
        this.paymentFrequencyOptions = [
			{ name: "Monthly", value: 1 },
			{ name: "Quarterly", value: 3 },
			{ name: "Bi annually", value: 6 },
			{ name: "annually", value: 12 },
		];
        this.paymentModeOptions = [
            { name: "Beginning of Period", value: 0 },
			{ name: "End of Period", value: 1 },
        ];
    }

    initConfig() {
        let emptyView = new PresentValueInfoView();
        emptyView.TargetColumn = this.dsfieldList[0];
        emptyView.DateColumn = this.dsfieldList[0];
        emptyView.RateColumn = undefined;
        emptyView.Rate = null;
        emptyView.PaymentFrequency = this.paymentFrequencyOptions[0];
        emptyView.PaymentMode = this.paymentModeOptions[0];
        emptyView.StartDate = undefined;
        emptyView.EndDate = undefined;

        console.log("Empty Info class:", emptyView);

        return emptyView;
    }

    infoToView(infos: PresentValueInfo[]): PresentValueInfoView[] {

        let viewArray: PresentValueInfoView[] = [];

        infos.map(info => {
            let newView = new PresentValueInfoView();
            newView.TargetColumn = this.dsfieldList.find((dsfield: FieldInfo) => {
                return info.TargetColumn === dsfield.Name;
            });
            newView.DateColumn = this.dsfieldList.find((dsfield: FieldInfo) => {
                return info.DateColumn === dsfield.Name;
            });
            newView.RateColumn = this.dsfieldList.find((dsfield: FieldInfo) => {
                return info.RateColumn === dsfield.Name;
            });
            newView.Rate = info.Rate;
            newView.PaymentFrequency = this.paymentFrequencyOptions.find(option => {
                return info.PaymentFrequency === option.name;
            });
            newView.PaymentMode = this.paymentModeOptions.find(option => {
                return info.PaymentMode === option.name;
            });
            newView.StartDate = info.StartDate;
            newView.EndDate = info.EndDate;

            viewArray.push(newView);
        });

        console.log("Final PV View: ", viewArray);

        return viewArray;
    }


  ngOnInit(): void {
    super.ngOnInit();

  }

}
