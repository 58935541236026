import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ScheduleActionPlan } from 'src/app/models/schedule.model';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { SchedulesService } from 'src/app/services/schedules.service';
import { UserService } from 'src/app/services/user.service';
import { SubSink } from 'subsink';


@Component({
  selector: 'app-schedule-planner',
  templateUrl: './schedule-planner.component.html',
  styleUrls: ['./schedule-planner.component.scss'],
  providers: [MessageService],
  animations: [
		trigger("fade", [
			state("void", style({ opacity: 0 })),
			transition(":enter", [animate(500)]),
			transition(":leave", [animate(500)]),
		]),
        trigger('slideLeft', [
            transition(':enter', [style({ width: 0 }), animate(500)]),
            transition(':leave', [animate(500, style({ width: 0 }))]),
          ]),
          trigger('panelInOut', [
            transition('void => *', [
                style({transform: 'translateY(-100%)'}),
                animate(800),
				transition(":enter", [animate(500)]),
				transition(":leave", [animate(500)]),
            ]),
            transition('* => void', [
                animate(800, style({transform: 'translateY(-100%)'}))
            ])
        ])
	],
})
export class SchedulePlannerComponent implements OnInit {
	subs = new SubSink();
	loading: boolean = false;
  displayConfig: boolean = true;

  constructor(private schedulesService: SchedulesService, private userService: UserService, private bionApi: ApiBackendService) { }

  ngOnInit(): void {
    this.initView();
  }

  initView(select?: ScheduleActionPlan) {
    this.loading = true;

    try {
      // this.schedulePlannerToolbar.setToolbar();
      if(!select) {
        console.log("No SchedulePlan selected, initialize new graph");
      }
      if (select) {
        console.log("SchedulePlan selected, load graph: ", select);
      }
    } finally {
      this.loading = false;

    }

  }

}
