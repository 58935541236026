import { Accessible } from "../authorization/Accessible";

export class ReportAccessible implements Accessible{
    ID: number;
    constructor(ID: number) {
        if(ID === undefined) throw new Error( "Class 'ReportAccessible': Required value 'ID' is undefined" );
        this.ID = ID;
    }
    AccessibleType: string = "";
    Value: string = "";
}