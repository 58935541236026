<div style="z-index: 1000000;">
    <p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
    </p-toast>
</div>
<app-add-permission-dialog sender="Workflows" view="WorkflowView"
    (click)="appMain.rightPanelClick = true"></app-add-permission-dialog>
<app-delete-permission-dialog [view]="'WorkflowView'"
    (click)="appMain.rightPanelClick = true"></app-delete-permission-dialog>
<app-change-permission-dialog [view]="'WorkflowView'"
    (click)="appMain.rightPanelClick = true"></app-change-permission-dialog>


<div style="display:flex;align-items: center;margin-bottom: 20px">
    <img [alt]="'HeaderIcon'" src="assets/layout/images/HeaderIcon_WF.svg" width="50" style="vertical-align: middle" />
    <h2 style="margin: 0px 10px;color:#6941C6;font-weight: bold;"><a routerLink="/workflows">{{'Workflow' |
            translate }}</a> <i class="pi pi-chevron-right" style="font-size: 1.5rem;color:#E9D7FE"></i>
        {{selectedWorkflow?.name}}</h2>
    <!-- <p-chip label="test"></p-chip> -->
</div>
<p-tabView [scrollable]="false">
    <p-tabPanel header="{{'History' | translate }}">

        <app-generic-latest-activities #wfLatestActivities [typeClass]="workflowActivityTypeClass"
            [currentView]="'Workflows'"></app-generic-latest-activities>
    </p-tabPanel>
    <p-tabPanel header="{{'Permissions' | translate }}" *ngIf="appMain.currentUserFull.UserInfo.IsAdmin === true">
        <!-- <app-user-permissions-list #permView view="WorkflowView"></app-user-permissions-list> -->
        <div class="p-grid" style="margin-top:15px;display: flex; align-items: center;">
            <span class="p-col" style="display: flex; align-items: center;">
                <h5 style="margin-bottom:0">
                    {{'Permissions' | translate }}
                </h5>
                <span style="margin-left:5px"><app-info-tooltip></app-info-tooltip></span>
            </span>
            <span class="p-col-5" style="float:right;text-align:right">

                <button type="button" pButton icon="pi pi-user-plus" label="{{'Save changes' | translate}}"
                    (click)="clickUpdatePermissionsComp()">
                </button>

            </span>
        </div>

        <app-role-permissions [model]="permissions_model" (selectionChanged)="onPermissionSelectionChanged($event)">
        </app-role-permissions>

    </p-tabPanel>
    <!-- <p-tabPanel header="{{'Fields' | translate }}">

        <app-generic-field-infos #destinationFieldInfos [typeClass]="destinationFieldInfoTypeClass"
            [currentView]="'Destinations'"></app-generic-field-infos>
    </p-tabPanel> -->
    <!-- <p-tabPanel header="{{'API' | translate }}">

        <app-pipeline [extensionUrl]="'dataStore/'"></app-pipeline>
        <div class="card selected">
            <div class="p-col-12">
                <img style="width: 50px" src="assets/layout/images/misc/die-info.png" alt="freya-layout" />
            </div>
            <div class="p-col-12">
                <h5>{{'ApiNotSupportedContactUs.Text' |  translate}}</h5>
                <a class="Link" (click)="onSendRequest('Api')">{{'ApiNotSupportedContactUs.LinkText' |  translate}}</a>
            </div>
        </div>
    </p-tabPanel> -->

    <p-tabPanel header="{{'Settings' | translate }}">

        <div class="p-grid" style="margin-top:15px;display: flex; align-items: center;">
            <span class="p-col" style="display: flex; align-items: center;">
                <h5 style="margin-bottom:0">
                    {{'Workflow information' | translate }}
                </h5>
                <span style="margin-left:5px"><app-info-tooltip></app-info-tooltip></span>
            </span>
            <span class="p-col-5" style="float:right;text-align:right">
                <button type="button" pButton [icon]="editModeOn ? 'pi pi-save':'pi pi-pencil'"
                    [label]="editModeOn ? 'Speichern':'Ändern'" styleClass="p-button"
                    (click)="onChangeEditMode()"></button>
            </span>
        </div>
        <div class="card">
            <div class="p-grid" style="margin:20px 0;" *ngIf="selectedWorkflow_copy">
                <div class="p-col-12 p-fluid">
                    <span class="p-float-label">
                        <input pInputText id="UrlInputtext" [(ngModel)]="selectedWorkflow_copy.name"
                            [disabled]="!editModeOn" />
                        <label htmlFor="UrlInputtext">{{'Workflow name' | translate}}</label>
                        <!-- <small id="inputtext">{{'Enter the non-empty datasource name.' | translate}}</small> -->
                    </span>
                </div>
                <div class="p-col-12 p-fluid">
                    <!-- <span class="p-float-label">
                        <p-checkbox pInputText id="ReadOnlyInput"
                            [(ngModel)]="selectedWorkflow_copy.runInfo.simulation.ReadOnly" [binary]="true"
                            [disabled]="!editModeOn"></p-checkbox>
                        <label for="ReadOnlyInput">{{'MaxRows' | translate}}</label>
                    </span> -->

                    <span class="flex align-items-center gap-1">
                        <p-checkbox [(ngModel)]="selectedWorkflow_copy.runInfo.simulation.ReadOnly" [binary]="true"
                            [disabled]="!editModeOn" inputId="ny"></p-checkbox>
                        <label for="ny">{{'inaktiver Workflow' | translate}}</label>
                    </span>
                </div>
                <div class="p-col-12 p-fluid" *ngIf="selectedWorkflow_copy.runInfo.simulation.ReadOnly === true">
                    <span class="p-float-label">
                        <input pInputText type="number" id="UrlInputtext"
                            [(ngModel)]="selectedWorkflow_copy.runInfo.simulation.MaxRows" [disabled]="!editModeOn" />
                        <label for="selectedWritemodeInput">{{'MaxRows' | translate}}</label>
                    </span>
                </div>
                <div class="p-col-12 p-fluid">
                    <span class="p-float-label">
                        <textarea id="float-input" rows="5" cols="30" [(ngModel)]="selectedWorkflow_copy.description"
                            [disabled]="!editModeOn" pInputTextarea></textarea>
                        <label for="float-input">{{'Description' | translate}}</label>
                    </span>

                </div>

            </div>

        </div>

    </p-tabPanel>
</p-tabView>

<p-dialog header="{{'save changes' | translate}}" [(visible)]="confirmDialog" modal="modal" (onHide)="onResetDialog()"
    showEffect="fade" [style]="{width: '600px'}">
    <div style="line-height: 1.5" *ngIf="selectedWorkflow">
        {{'Do you really want to save changes?' | translate}}
    </div>
    <app-system-message-log></app-system-message-log>
    <p-footer>
        <button pButton pRipple type="button" icon="pi pi-times" (click)="onResetDialog()"
            class="p-button-rounded p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'No' | translate}}"></button>
        <button pButton pRipple type="button" icon="pi pi-check" (click)="onChangeWorkflow()"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'Yes' | translate}}" [icon]="loading ? 'pi pi-spin pi-spinner' : 'pi pi-check'"></button>
    </p-footer>
</p-dialog>