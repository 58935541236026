<app-create-datastore-dialog></app-create-datastore-dialog>
<app-delete-datastore-dialog></app-delete-datastore-dialog>
<app-export-datastore-dialog></app-export-datastore-dialog>
<!-- <app-delete-datastore-package-dialog></app-delete-datastore-package-dialog> -->
<app-add-permission-dialog sender="Destination" [view]="'DestinationView'"></app-add-permission-dialog>
<app-change-permission-dialog [view]="'DataSourceView'"></app-change-permission-dialog>
<app-delete-permission-dialog [view]="'DataSourceView'"></app-delete-permission-dialog>
<!-- <app-psa-table></app-psa-table> -->

<!-- <app-create-destination-dialog #destinationCreateObject [typeClass]="destinationCreateTypeClass"></app-create-destination-dialog> -->
<app-create-destination-dialog #destinationCreateObject></app-create-destination-dialog>

<div style="z-index: 1000000;">
    <p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
    </p-toast>
</div>


<p-overlayPanel #op1 [showCloseIcon]="false">
    <ng-template pTemplate>
        <div style="float: left">
            <button pButton pRipple type="button" icon="pi pi-cog" class="p-button-rounded p-button-text p-mr-2 p-mb-2"
                pTooltip="{{'ChangeDestinationInformation' | translate }}"
                (click)="displayEditWorkflowDialog(true)"></button>
            <button pButton pRipple type="button" icon="pi pi-trash"
                pTooltip="{{'DeleteDatasourcePermanently' | translate }}" (click)="displayDeleteWorkflowDialog(true)"
                class="p-button-rounded p-button-danger p-button-text p-mr-2 p-mb-2"></button>
        </div>

    </ng-template>
</p-overlayPanel>

<div class="spinner-container" *ngIf="loading" @fade>
    <app-loading-screen></app-loading-screen>
</div>

<div style="display:flex;align-items: center;margin-bottom: 20px">
    <img [alt]="'HeaderIcon'" src="assets/layout/images/HeaderIcon_DEST.svg" width="50"
        style="vertical-align: middle" />
    <h2 style="margin: 0px 10px;color:#6941C6;font-weight: bold;">{{'Destinations' | translate }}</h2>
</div>
<!-- <div class="p-grid">
    <span class="p-col-12 p-md-12 p-lg-12">
        <span>
            <app-generic-top-toolbar #destinationObjectToolbar [typeClass]="destinationToolbarTypeClass"
                [currentView]="'Destinations'" @fade></app-generic-top-toolbar>
            <span style="display: flex; align-items: center; float: right">
                <app-general-search #destinationObjectSearch [typeClass]="destinationSearchTypeClass"
                [currentView]="'Destinations'">
            </app-general-search>

                <span style="color: white;margin:0px 10px">|</span>
                <span style="display: flex; align-items: center;">
                    <app-generic-object-tasks #destinationObjectTasks [currentView]="'Destinations'"></app-generic-object-tasks>
                </span>
            </span>
        </span>
    </span>
</div> -->


<!-- <div class="p-col-2 p-md-2 p-lg-2" style="padding: 0;margin-top: 20px; margin-left: 20px; height: 100%" *ngIf="displaySearchPanel" @fade>
       <app-general-search #destinationObjectSearch [typeClass]="destinationSearchTypeClass" [currentView]="'Destinations'"></app-general-search>
    </div> -->
<div style="height:4px">
    <p-progressBar *ngIf="loading" [mode]="'indeterminate'" [style]="{'height': '4px'}"></p-progressBar>
</div>
<div class="card">
    <app-general-object-view [objectList]="destinations" [userDetails]="userDetails" #destinationObjectView
        [typeClass]="destinationRecordTypeClass" [currentView]="'Destinations'"></app-general-object-view>
</div>


<!-- <div [hidden]="true">
        <div class="rightpanel-wrapper">
            <p-tabView [scrollable]="false">
                <p-tabPanel header="{{'Activities' | translate }}">
                    <ng-template pTemplate="header">
                        <img class="static" style="width: 30px" pTooltip="{{'Activities' | translate }}"
                            tooltipPosition="top" src="assets/layout/images/animatedIcons/auffuhren.png"
                            alt="freya-layout" />
                        <img class="active" style="width: 30px" pTooltip="{{'Activities' | translate }}"
                            tooltipPosition="top" src="assets/layout/images/animatedIcons/auffuhren.gif"
                            alt="freya-layout" />
                    </ng-template>
                    <app-generic-latest-activities #destinationLatestActivities
                        [typeClass]="destinationActivityTypeClass" [currentView]="'Destinations'">
                    </app-generic-latest-activities>
                </p-tabPanel>
                <p-tabPanel header="{{'Tables' | translate }}">
                    <ng-template pTemplate="header">
                        <img class="static" style="width: 30px" pTooltip="{{'Data' | translate }}" tooltipPosition="top"
                            src="assets/layout/images/animatedIcons/buch.png" alt="freya-layout" />
                        <img class="active" style="width: 30px" pTooltip="{{'Data' | translate }}" tooltipPosition="top"
                            src="assets/layout/images/animatedIcons/buch.gif" alt="freya-layout" />
                    </ng-template>
                    <app-psa-table></app-psa-table>
                </p-tabPanel>
                <p-tabPanel header="{{'Permissions' | translate }}"
                    *ngIf="appMain.currentUserFull.UserInfo.IsAdmin === true">
                    <ng-template pTemplate="header">
                        <img class="static" style="width: 30px" pTooltip="{{'Permissions' | translate }}"
                            tooltipPosition="top" src="assets/layout/images/animatedIcons/nutzer.png"
                            alt="freya-layout" />
                        <img class="active" style="width: 30px" pTooltip="{{'Permissions' | translate }}"
                            tooltipPosition="top" src="assets/layout/images/animatedIcons/nutzer.gif"
                            alt="freya-layout" />
                    </ng-template>

                    <app-user-permissions-list view="DestinationView"></app-user-permissions-list>
                </p-tabPanel>
                <p-tabPanel header="{{'Fields' | translate }}">
                    <ng-template pTemplate="header">
                        <img class="static" style="width: 30px" pTooltip="{{'Fields' | translate }}"
                            tooltipPosition="top" src="assets/layout/images/animatedIcons/liste.png"
                            alt="freya-layout" />
                        <img class="active" style="width: 30px" pTooltip="{{'Fields' | translate }}"
                            tooltipPosition="top" src="assets/layout/images/animatedIcons/liste.gif"
                            alt="freya-layout" />
                    </ng-template>
                    <app-generic-field-infos #destinationFieldInfos [typeClass]="destinationFieldInfoTypeClass"
                        [currentView]="'Destinations'"></app-generic-field-infos>
                </p-tabPanel>
                <p-tabPanel header="{{'API' | translate }}">
                    <ng-template pTemplate="header">
                        <img class="static" style="width: 30px" pTooltip="{{'API' | translate }}" tooltipPosition="top"
                            src="assets/layout/images/animatedIcons/mischen.png" alt="freya-layout" />
                        <img class="active" style="width: 30px" pTooltip="{{'API' | translate }}" tooltipPosition="top"
                            src="assets/layout/images/animatedIcons/mischen.gif" alt="freya-layout" />
                    </ng-template>
                    <app-pipeline [extensionUrl]="'dataStore/'"></app-pipeline>
                    <div class="card selected">
                        <div class="p-col-12">
                            <img style="width: 50px" src="assets/layout/images/misc/die-info.png" alt="freya-layout" />
                        </div>
                        <div class="p-col-12">
                            <h5>{{'ApiNotSupportedContactUs.Text' |  translate}}</h5>
                            <a class="Link" (click)="onSendRequest('Api')">{{'ApiNotSupportedContactUs.LinkText' |  translate}}</a>
                        </div>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="{{'Download' | translate }}">
                    <ng-template pTemplate="header">
                        <img class="static" style="width: 30px" pTooltip="{{'Download' | translate }}"
                            tooltipPosition="top" src="assets/layout/images/animatedIcons/download.png"
                            alt="freya-layout" />
                        <img class="active" style="width: 30px" pTooltip="{{'Download' | translate }}"
                            tooltipPosition="top" src="assets/layout/images/animatedIcons/download.gif"
                            alt="freya-layout" />
                    </ng-template>
                    <app-pipeline></app-pipeline>
                    <div class="card selected">
                        <div class="p-col-12">
                            <img style="width: 50px" src="assets/layout/images/misc/plaudern.png" alt="freya-layout" />
                        </div>
                        <div class="p-col-12">
                            <h5>{{'FiletypeNotSupportedContactUs.Text' |  translate}}</h5>
                            <a class="Link" (click)="onSendRequest('Filetype')">{{'FiletypeNotSupportedContactUs.LinkText' |  translate}}</a>
                        </div>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div> -->