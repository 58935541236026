import { Workflow } from "./api/com/bion/etl/Workflow";
import { BasicFrequency } from "./api/models/scheduler/CronFrequency";
import { WorkflowRepositoryEntry } from "./api/models/workflow/WorkflowRepositoryEntry";
import { DataStore } from "./datastore.model";
import { ScheduleActionPlan, ScheduleBaseAction } from "./schedule.model";

export enum WorkflowDialogActionType {
	openWorkflow,
	createWorkflow,
	editWorkflow,
	saveWorkflow,
	saveAsWorkflow,
	createAndSaveWorkflow,
	deleteWorkflow,
	runWorkflow,
	exportWorkflows,
	importWorkflows
}
export class WorkflowActionEvent {
	display: boolean;
	header: string;
	actionType: WorkflowDialogActionType;
	actionLabel: string;
	wf?: WorkflowRepositoryEntry;
	wfData?: Workflow

	constructor(display: boolean, header: string, actionType: WorkflowDialogActionType,actionLabel: string, wf?:WorkflowRepositoryEntry, wfData?: Workflow) {
		this.display = display;
		this.header = header;
		this.actionType = actionType;
		this.actionLabel = actionLabel;
		this.wf = wf;
		this.wfData = wfData;
	}
}

export class WorkflowActionEventStatus {
	display: boolean;
	actionType: WorkflowDialogActionType;
	wasSuccessful: boolean;
	savingInProgress: boolean;
	wf?: WorkflowRepositoryEntry;

	constructor(display: boolean, actionType: WorkflowDialogActionType, wasSuccessful: boolean, savingInProgress: boolean,wf?:WorkflowRepositoryEntry) {
		this.display = display;
		this.actionType = actionType;
		this.wasSuccessful = wasSuccessful;
		this.savingInProgress = savingInProgress; 
		this.wf = wf;
	}
}



export enum DataSourceDialogActionType {
    createDataSource,
	editDataSource,
	deleteDataSource,
	changeSettings,
	deleteDataPackage
}

export enum DestinationDialogActionType {
	createDestination,
	editDestination,
	deleteDestination,
	changeSettings,
	deleteDataPackage
}

export class DestinationActionEvent {
	display: boolean;
	header: string;
	actionType: DestinationDialogActionType;
	actionLabel: string;
	dest?: DataStore;

	constructor(display: boolean, header: string, actionType: DestinationDialogActionType,actionLabel: string, dest?:DataStore) {
		this.display = display;
		this.header = header;
		this.actionType = actionType;
		this.actionLabel = actionLabel;
		this.dest = dest;

	}
}
export class DestinationActionEventStatus {
	display: boolean;
	actionType: DestinationDialogActionType;
	wasSuccessful: boolean;
	savingInProgress: boolean;
	dest?: DataStore;

	constructor(display: boolean, actionType: DestinationDialogActionType, wasSuccessful: boolean, savingInProgress: boolean,dest?:DataStore) {
		this.display = display;
		this.actionType = actionType;
		this.wasSuccessful = wasSuccessful;
		this.savingInProgress = savingInProgress; 
		this.dest = dest;
	}
}

export enum ScheduleDialogActionType {
	createSchedule,
	editSchedule,
	deleteSchedule,
	runSchedule,
}

export class ScheduleActionEvent {
	display: boolean;
	header: string;
	actionType: ScheduleDialogActionType;
	actionLabel: string;
	schedule?: ScheduleActionPlan;

	constructor(display: boolean, header: string, actionType: ScheduleDialogActionType,actionLabel: string, schedule?:ScheduleActionPlan) {
		this.display = display;
		this.header = header;
		this.actionType = actionType;
		this.actionLabel = actionLabel;
		this.schedule = schedule;

	}
}
export class ScheduleActionEventStatus {
	display: boolean;
	actionType: ScheduleDialogActionType;
	wasSuccessful: boolean;
	savingInProgress: boolean;
	schedule?: ScheduleActionPlan;

	constructor(display: boolean, actionType: ScheduleDialogActionType, wasSuccessful: boolean, savingInProgress: boolean,schedule?:ScheduleActionPlan) {
		this.display = display;
		this.actionType = actionType;
		this.wasSuccessful = wasSuccessful;
		this.savingInProgress = savingInProgress; 
		this.schedule = schedule;
	}
}


export enum BaseActionDialogActionType {
	createAction,
	editAction,
	deleteAction,
}

export class BaseActionActionEvent {
	display: boolean;
	header: string;
	actionType: BaseActionDialogActionType;
	actionLabel: string;
	action?: ScheduleBaseAction;

	constructor(display: boolean, header: string, actionType: BaseActionDialogActionType,actionLabel: string, action?:ScheduleBaseAction) {
		this.display = display;
		this.header = header;
		this.actionType = actionType;
		this.actionLabel = actionLabel;
		this.action = action;

	}
}
export class BaseActionActionEventStatus {
	display: boolean;
	actionType: BaseActionDialogActionType;
	wasSuccessful: boolean;
	savingInProgress: boolean;
	action?: ScheduleBaseAction;

	constructor(display: boolean, actionType: BaseActionDialogActionType, wasSuccessful: boolean, savingInProgress: boolean,action?:ScheduleBaseAction) {
		this.display = display;
		this.actionType = actionType;
		this.wasSuccessful = wasSuccessful;
		this.savingInProgress = savingInProgress; 
		this.action = action;
	}
}

export enum BaseFrequencyDialogActionType {
	createFrequency,
	editFrequency,
	deleteFrequency,
}

export class BaseFrequencyActionEvent {
	display: boolean;
	header: string;
	actionType: BaseFrequencyDialogActionType;
	actionLabel: string;
	frequency?: BasicFrequency;

	constructor(display: boolean, header: string, actionType: BaseFrequencyDialogActionType,actionLabel: string, frequency?:BasicFrequency) {
		this.display = display;
		this.header = header;
		this.actionType = actionType;
		this.actionLabel = actionLabel;
		this.frequency = frequency;

	}
}
export class BaseFrequencyActionEventStatus {
	display: boolean;
	actionType: BaseFrequencyDialogActionType;
	wasSuccessful: boolean;
	savingInProgress: boolean;
	frequency?: BasicFrequency;

	constructor(display: boolean, actionType: BaseFrequencyDialogActionType, wasSuccessful: boolean, savingInProgress: boolean,frequency?:BasicFrequency) {
		this.display = display;
		this.actionType = actionType;
		this.wasSuccessful = wasSuccessful;
		this.savingInProgress = savingInProgress; 
		this.frequency = frequency;
	}
}