<!-- <div class="p-grid">

        <div class="p-col headerSection">
        </div>
        <div class="p-col-3 headerSection">
            <button style="float: right" pButton label="Add Converter" icon="fa fa-plus"
            (click)="onAddConverter()"></button>
        </div> -->

<div>
    <p-table #dt [value]="convertList" dataKey="ColumnName" editMode="row" [reorderableColumns]="false">
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                <!-- <span class="p-input-icon-left">

                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="{{'LogHistory.SearchForLog' | translate}}" />
                </span> -->

                <span class="p-input-icon-right">

                    <button style="float: right" pButton label="{{'Add' | translate}}" icon="fa fa-plus"
                        (click)="onAddConverter()"></button>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <!-- <th pReorderableColumn>Column Name</th> -->
                <!-- <th pReorderableColumn tooltipPosition="top"
                    pTooltip="Select a column as your base unit. The colum should be numberic.">
                    Base Unit</th>
                <th pReorderableColumn tooltipPosition="top" pTooltip="Choose your target units.">Target Units</th>
                <th tooltipPosition="top" pTooltip="{{'Column that should be filtered on' | translate}}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Name' | translate}}

                    </div>
                </th> -->
                <th tooltipPosition="top" pTooltip="{{'Config.UnitConvert.Tooltip1' | translate}}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Name' | translate}}
                    </div>
                </th>
                <th tooltipPosition="top" pTooltip="{{'Config.UnitConvert.Tooltip2' | translate}}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Base Unit' | translate}}
                    </div>
                </th>
                <th tooltipPosition="top" pTooltip="{{'Config.UnitConvert.Tooltip3' | translate}}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Target Units' | translate}}
                    </div>
                </th>
                <th style="width:3rem"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-column let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="column" [pReorderableRow]="ri"
                [ngClass]="{'row-accessories': column.hasError === true}">
                <td>
                    <p-dropdown appendTo="body" *ngIf="column.hasError === false" [options]="columnList"
                        (onChange)="onUpdateSettings(false)" [autoDisplayFirst]="false" [(ngModel)]="column.ColumnName"
                        autoWidth="false" [style]="{'width':'100%'}"></p-dropdown>
                    <p-dropdown *ngIf="column.hasError === true" appendTo="body" [options]="columnList"
                        (onChange)="onUpdateSettings(false)" [autoDisplayFirst]="false" [(ngModel)]="column.ColumnName"
                        class="ng-invalid ng-dirty" autoWidth="false" [style]="{'width':'100%'}"></p-dropdown>
                </td>
                <td>
                    <p-dropdown appendTo="body" [options]="baseUnits" [(ngModel)]="column.baseUnit"
                        (onChange)="onUpdateSettings(false)" optionLabel="UnitName" dataKey="UnitValue"
                        autoWidth="false" [style]="{'width':'100%'}">
                    </p-dropdown>
                </td>
                <td>
                    <p-multiSelect appendTo="body" [options]="column.targetUnitOptions" display="chip"
                        (onChange)="onUpdateSettings(false)" [(ngModel)]="column.targetUnits" optionLabel="UnitName"
                        dataKey="UnitValue" autoWidth="false" [style]="{'width':'100%'}">
                    </p-multiSelect>
                </td>
                <td>
                    <div class="ui-table-buttongroup">
                        <button pButton pRipple type="button" class="p-button-rounded p-button-text ui-button-danger"
                            icon="pi pi-trash" pTooltip="Delete entry" (click)="onDeleteEntry(ri)"
                            tooltipPosition="bottom"></button>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="4">{{'NoEntryFound' | translate}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>
<!-- </div> -->