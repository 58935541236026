import mx from "./mx-graph-loader"; // <- import values from factory()
import { mxCell, mxGraph, mxGraphModel, mxStylesheet } from "mxgraph";
import { PortInfo } from "src/app/models/api/models/scheduler/workflow/PortInfo";
import { SchedulerGraph } from "src/app/models/api/models/scheduler/SchedulerGraph"
import { SchedulerPlugInInfo, SchedulerPortBase } from "src/app/services/dummy-backend.service";

/**
 * A reduced version of SchedulerGraph.Node.
 *
 * This is necessary, because mxGraph manages the ID and GuiSettings for us.
 */
export class ISchedulerNode {
	constructor(Properties:SchedulerGraph.Properties, EngineInfo:SchedulerGraph.EngineInfo) {
		this.Properties = Properties;
		this.EngineInfo = EngineInfo;
	}
	Properties: SchedulerGraph.Properties;
	EngineInfo: SchedulerGraph.EngineInfo;
}

export class mxSchedulerNodeValue {
	constructor(Node:ISchedulerNode, RunInfo?:string, RunResult?:any) {
		this.Node = Node;
		this.RunInfo = RunInfo;
		this.RunResult = RunResult;
	}
	Node:ISchedulerNode;
	RunInfo?:string;
	RunResult?:any;
}

export class mxSchedulerPortValue {
	constructor(PortInfo:PortInfo) {
		this.PortInfo = PortInfo;
	}
	readonly PortInfo: PortInfo;
}
export class mxSchedulerEdgeValue {

}
export class mxSchedulerGraphModel extends mx.mxGraphModel {
	constructor(root: mxCell) {
		super(root);
	}

}

export class mxSchedulerGraph extends mx.mxGraph {
	constructor(
		container: HTMLElement,
		model?: mxGraphModel,
		renderHint?: string,
		stylesheet?: mxStylesheet
	) {
		super(
			container,
			model ? model : new mxSchedulerGraphModel(null),
			renderHint,
			stylesheet
		);
	}

	lookupWorkflowNode(id: string, parent: any | undefined): mxCell[] {
		return mxSchedulerGraphEx.lookupWorkflowNode(id, parent, this);
	}
}

/**
 * Static extension methods for mxSchedulerGraph
 */
export class mxSchedulerGraphEx {


	/**
 * Create a number range with n elements.
 * @param n Number of elements
 * @returns Range with indices
 */
	static makeRange(n: number): number[] {
		return [...Array(n).keys()];
	}


	/**
	 * Get all ports of the given cell
	 * @param cell Workflow node
	 * @return
	 */
	static getPorts(cell: mxCell): mxCell[] {
		const range = mxSchedulerGraphEx.makeRange(cell.getChildCount());
		const children = range.map((i) => cell.getChildAt(i));

		const result = children.filter((c) => mxSchedulerGraphEx.cellIsPort(c));

		return result;
	}


	/**
 * Check if the cell contains a scheduler worklflow node port value
 * @param cell Graph Cell
 * @returns True if the cell is a scheduler workflow node port
 */
	static cellIsPort(cell: mxCell): boolean {
		const value = cell.getValue();

		if (value == null) return false;

		return mxSchedulerGraphEx.isPortInfo(value);
	}

	static isPortInfo(value: any): value is mxSchedulerPortValue {
		return (value as mxSchedulerPortValue).PortInfo !== undefined;
	}

	static lookupWorkflowNode(
		id: string,
		parent: any | undefined,
		graph: mxGraph
	): mxCell[] {
		const targetParent =
			parent === undefined ? graph.getDefaultParent() : parent;

		const cells = graph.getChildVertices(targetParent).map((c) => <mxCell>c);
		const nodes = cells.filter((c) =>
		mxSchedulerGraphEx.isWorkflowNode(c.getValue())
		);

		return nodes.filter(n => n.getId() == id);
	}

	static isWorkflowNode(value: any): value is mxSchedulerNodeValue {
		return (value as mxSchedulerNodeValue).Node !== undefined;
	}

	static insertWokflowNode(
		graph: mxGraph,
		parent: mxCell,
		workflowNode: SchedulerGraph.Node,
		ports: SchedulerPortBase[],
		style: string
	) {
		return this.insertIWorkflowNode(
			graph,
			parent,
			workflowNode,
			ports,
			style,
			workflowNode.GuiSettings,
			workflowNode.ID
		);
	}

	static insertIWorkflowNode(
		graph: mxGraph,
		parent: mxCell,
		workflowNode: ISchedulerNode,
		ports: SchedulerPortBase[],
		style: string,
		guiSettings: SchedulerGraph.GuiSettings,
		id?: string
	): mxCell {
		// const engine = workflowNode.Engine;
		// const name = workflowNode.Name;
		const engine = workflowNode.EngineInfo;
		const properties = workflowNode.Properties;

		return this.insertWorkflowNodeFull(
			graph,
			parent,
			guiSettings,
			properties,
			ports,
			engine,
			style,
			undefined,
			id
		);
	}

	static insertWorkflowNodeFull(
		graph: mxGraph,
		parent: mxCell,
		guiSettings: SchedulerGraph.GuiSettings,
		properties: SchedulerGraph.Properties,
		ports: SchedulerPortBase[],
		engine: SchedulerGraph.EngineInfo,
		style: string,
		name?: string,
		id?: string
	): mxCell {

		// TODO: add port info to node
		console.log("TODO: add port info to node");
		const port_values = ports.map((port) => {
			return new mxSchedulerPortValue(port);
		});

		// const node_value = new mxSchedulerNodeValue(
		// 	guiSettings,
		// 	properties,
		// 	engine,
		// 	port_values,
		// 	name
		// );

		const i_node = new ISchedulerNode(properties, engine);
		const node_value = new mxSchedulerNodeValue(i_node);

		// Add available meta information as initial data if available
		// if(properties.MetaInfo) {

		// 	const node_data = new Map<string,mxWorkflowNodePortData[]>();
		// 	for(let entry of properties.MetaInfo) {
		// 		const port_tables = entry[1].map(mi => {
		// 			return new DataTable(mi, []);
		// 		});
		// 		const port_data = port_tables.map(port_table => new mxWorkflowNodePortData(port_table));
		// 		node_data.set(entry[0], port_data);
		// 	}

		// 	node_value.Data = node_data;
		// }

		const gui = guiSettings;
		if (gui === undefined) throw new Error("The Gui Settings are undefined!");

		const v1 = graph.insertVertex(
			parent,
			id,
			node_value,
			gui.X,
			gui.Y,
			gui.Width,
			gui.Height,
			style
		);

		// Prevent direct connection -> connect via ports
		v1.setConnectable(false);

		// Presets the collapsed size
		v1.geometry.alternateBounds = new mx.mxRectangle(0, 0, 40, 40);

		return v1;
	}

		/**
	 * Creates the ports on a frontend node based on the backend node information
	 * @param v1 Frontend Graph Node
	 * @param node Backend Node Information
	 * @param graph Graph
	 * @param isIn True if the port is an input port
	 */
		 static createPorts(
			v1: mxCell,
			node: SchedulerPlugInInfo,
			graph: mxGraph,
			isIn: boolean
		): mxCell[] {
			//console.log("Create Ports");
			const inPorts = node.Ports.filter((c) => c.IsInput === isIn);
			// TODO: check conversion
			const step = 1.0 / (inPorts.length + 1);
			const result: mxCell[] = inPorts.map(function (
				value: SchedulerPortBase,
				index: number,
				array: SchedulerPortBase[]
			) {
				return mxSchedulerGraphEx.createPort(
					v1,
					graph,
					index,
					step,
					isIn,
					value
				);
			});

			return result;
		}

			/**
	 * Create one port for one direction (in or out) of a workflow node
	 * @param v1 Workflow Node
	 * @param graph Graph
	 * @param i Port Index
	 * @param step Relative Step Size ( 0 - 1.0)
	 * @param isIn True if input port
	 * @param value Port Value
	 * @param radius Port Radius
	 * @returns The port cell
	 */
	static createPort(
		v1: mxCell,
		graph: mxGraph,
		i: number,
		step: number,
		isIn: boolean,
		value: SchedulerPortBase,
		radius: number = 9
	): mxCell {
		// TODO: Derive size and alternative bounds from graph settings and workflow backend information

		const geo = graph.getModel().getGeometry(v1);
		// const relPosX = if(isIn) 0 else 1
		const relPosX = isIn ? -0.04 : 1.09;
		//const relPosX = isIn ? 0.08 : 0.99;
		geo.alternateBounds = new mx.mxRectangle(20, 20, 100, 50);

		const node_geo = new mx.mxGeometry(
			relPosX,
			(i + 1.15) * step,
			radius * 1.3,
			radius * 1.3
		);
		node_geo.offset = new mx.mxPoint(-radius, -radius);
		node_geo.relative = true;

		const port_node_value = new mxSchedulerPortValue(value);

		// Receive port data from parent workflow node.
		const workflow_node_value = <mxSchedulerNodeValue> v1.getValue();
		// const port_data = workflow_node_value.Data?.get(value.Name);
		// port_node_value.Data = port_data ? port_data : [];

		const port1 = new mx.mxCell(
			port_node_value,
			node_geo,
			"rounded=1;whiteSpace=wrap;html=1;labelBackgroundColor=none;gradientColor=none;fontColor=#000000;strokeColor=none;arcSize=10;fillColor=#d1d1d1"
		);
		port1.setVertex(true);
		port1.setConnectable(true);

		const port_id = this.getNextPortId();
		port1.setId(port_id);

		return port1;
	}

	static next_port_id = 0;
	/**
	 * Gets the next unique port ID.
	 * @returns A new unique port id
	 */
	static getNextPortId(): string {
		this.next_port_id = this.next_port_id + 1;
		return "p" + this.next_port_id.toString();
	}
}
