<div class="p-grid">
    <div class="p-col-6">
        Value Column
    </div>
    <div class="p-col-6">
        <p-dropdown [options]="dsfieldList" [(ngModel)]="currentConfig.TargetColumn" optionLabel="Name"
        optionValue="Name" dataKey="Name" (onChange)="onUpdateSettings(false)"></p-dropdown>
    </div>
    <div class="p-col-6">
        Date Column
    </div>
    <div class="p-col-6" >
        <p-dropdown [options]="dsfieldList" [(ngModel)]="currentConfig.DateColumn" optionLabel="Name"
        optionValue="Name" dataKey="Name" (onChange)="onUpdateSettings(false)"></p-dropdown>
    </div>
    <div class="p-col-6">
        Constant Rate?
    </div>
    <div class="p-col-6" >
        <p-checkbox [(ngModel)]="isConstantRate" [binary]="true"></p-checkbox>
    </div>
    <div class="p-col-6" *ngIf="!isConstantRate">
        Rate Colummn
    </div>
    <div class="p-col-6" *ngIf="!isConstantRate">
        <p-dropdown [options]="dsfieldList" [(ngModel)]="currentConfig.RateColumn" optionLabel="Name"
        optionValue="Name" dataKey="Name" (onChange)="onUpdateSettings(false)"></p-dropdown>
    </div>
    <div class="p-col-6" *ngIf="isConstantRate">
        Rate
    </div>
    <div class="p-col-6" *ngIf="isConstantRate">
        <input pInputText [(ngModel)]="currentConfig.Rate" (blur)="onUpdateSettings(false)">
    </div>
    <div class="p-col-6">
        Payment Frequency
    </div>
    <div class="p-col-6">
        <p-dropdown [options]="paymentFrequencyOptions" [(ngModel)]="currentConfig.PaymentFrequency" optionLabel="name"
        optionValue="value" dataKey="name" (onChange)="onUpdateSettings(false)"></p-dropdown>
    </div>
    <div class="p-col-6">
        Payment Mode
    </div>
    <div class="p-col-6">
        <p-dropdown [options]="paymentModeOptions" [(ngModel)]="currentConfig.PaymentMode" optionLabel="name"
        optionValue="value" dataKey="name" (onChange)="onUpdateSettings(false)"></p-dropdown>
    </div>
    <div class="p-col-6">
        Start Date
    </div>
    <div class="p-col-6">
        <p-calendar [(ngModel)]="currentConfig.StartDate" class="lean-input-full-width" appendTo="body"
            [showIcon]="true" (onInput)="onUpdateSettings(false)" (onSelect)="onUpdateSettings(false)">
        </p-calendar>
    </div>
    <div class="p-col-6">
        End Date
    </div>
    <div class="p-col-6" >
        <p-calendar [(ngModel)]="currentConfig.EndDate" class="lean-input-full-width" appendTo="body"
            [showIcon]="true" (onInput)="onUpdateSettings(false)" (onSelect)="onUpdateSettings(false)">
        </p-calendar>
    </div>


</div>
