import {
	Component,
	Input,
	OnInit,
} from "@angular/core";
import { SelectEvent } from "src/app/helper/events";
import { ViewInfoRow } from "src/app/models/api/domain/NewDataSourceModelBase";
import { Accessible } from "src/app/models/api/models/authorization/Accessible";
import { PermissionBase } from "src/app/models/api/models/authorization/PermissionBase";
import { ViewPermission } from "src/app/models/api/models/authorization/ViewPermission";
import { GroupRoleView, UserRoleView } from "src/app/models/auth-service-decorator.model";
import { AuthorizationService } from "src/app/services/authorization.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { UserService } from "src/app/services/user.service";
import { SubSink } from "subsink";
import { PermissionView, ViewPermissionManager } from "../user-permissions-list/user-permissions-list.component";


// event.originalEvent: Browser event
// event.data: Selected row data or an array if a range is selected with shift key
// event.type: Type of selection, valid values are "row", "radiobutton" and "checkbox"
// event.index: Index of the row


@Component({
	selector: "app-user-roles-list",
	templateUrl: "./user-roles-list.component.html",
	styleUrls: ["./user-roles-list.component.scss"],
})
export class UserRolesListComponent<
P extends PermissionBase<A>,
A extends Accessible,
E> implements OnInit {
	subs = new SubSink();
	displayNewRole: boolean = false;
	index: number = 0;

	@Input() userRolesView: UserRoleView[] = [];
	@Input() groupRoles: GroupRoleView[] = [];
    @Input() genericPermissionView: PermissionView<P, A, E>[] = [];

	selectedUserRole?: UserRoleView;
	selectedGroupRole?: GroupRoleView;
	selectedViewInfoRow?: ViewInfoRow;
	selectedViewRole?: ViewPermission;
    viewInfos: ViewInfoRow[] = [];
    rights: string[] = [];
    roleTypes: string[] = [];
	permissionManager?: ViewPermissionManager;

	constructor(private userService: UserService, private authService: AuthorizationService, private errorService: SystemMessageLogService) {}


	ngOnInit(): void {
		let permissionManager = new ViewPermissionManager(
			this.authService,
		);
		this.permissionManager = permissionManager;

        this.roleTypes = ["User Role", "Group Role"];
        this.subs.sink = this.authService.getViewInfoRow().subscribe((res) => {
            this.viewInfos = res;
        },
		(err: Error) => {
			this.errorService.handleError(err);
		});
        this.subs.sink = this.authService.getWorkflowRights().subscribe((res) => {
            this.rights = res;
        },
		(err: Error) => {
			this.errorService.handleError(err);
		});
	}

	onSelectRole(evt:SelectEvent<PointerEvent,UserRoleView>) {
		let selectedRole = evt.data;
		this.userService.selectedUserRoleEmitter.emit(selectedRole);
	}
	onUnselectRole(evt:SelectEvent<PointerEvent,UserRoleView>) {
		let unselectedRole = evt.data;
		this.userService.selectedUserRoleEmitter.emit(unselectedRole);
	}
	onMenuClicked(u: UserRoleView) {
		this.selectedUserRole = u;
		this.userService.selectedMenuUserRoleEmitter.emit(u);
	}

	onSelectGroupRole(evt:SelectEvent<PointerEvent,GroupRoleView>) {
		let selectedRole = evt.data;
		this.userService.selectedGroupRoleEmitter.emit(selectedRole);
	}
	onUnselectGroupRole(evt:SelectEvent<PointerEvent,GroupRoleView>) {
		let unselectedRole = evt.data;
		this.userService.selectedGroupRoleEmitter.emit(unselectedRole);
	}
	onMenuGroupClicked(u: GroupRoleView) {
		this.selectedGroupRole = u;
		this.userService.selectedMenuGroupRoleEmitter.emit(u);
	}

	onSelectViewRole(evt:SelectEvent<PointerEvent,ViewPermission>) {
		let selectedView = evt.data;
		this.userService.selectedViewRoleEmitter.emit(selectedView);
	}
	onUnselectViewRole(evt:SelectEvent<PointerEvent,ViewPermission>) {
		let unselectedRole = evt.data;
		this.userService.selectedViewRoleEmitter.emit(unselectedRole);
	}
	onMenuViewRoleClicked(u: PermissionView<P, A,E>) {
		this.userService.selectedMenuPermissionEmitter.emit([
			u,
			this.permissionManager,
		]);
	}

	displayNewRoleDialog() {
		this.userService.displayNewRole.emit(true);
	}
	displayDeleteRoleDialog() {
		this.userService.displayDeleteUserRole.emit(true);
	}
	displayEditRoleDialog() {
		this.userService.displayEditRole.emit(true);
	}
	displayEditPermissionDialog() {
		this.userService.displayEditPermission.emit(true);
	}
    displayNewPermissionDialog() {
		this.userService.displayAddPermission.emit(true);
	}
    displayDeletePermissionDialog() {
		this.userService.displayDeletePermission.emit(true);
	}
}
