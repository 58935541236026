export class UserAzureRow {
    userId: number;
    accountName: string;
    constructor(userId: number, accountName: string) {
        if(userId === undefined) throw new Error( "Class 'UserAzureRow': Required value 'userId' is undefined" );
        if(accountName === undefined) throw new Error( "Class 'UserAzureRow': Required value 'accountName' is undefined" );
        this.userId = userId;
        this.accountName = accountName;
    }
}

export class AuthCodeRequest {
    Code: string;
    constructor(Code: string) {
        if(Code === undefined) throw new Error( "Class 'AuthCodeRequest': Required value 'Code' is undefined" );
        this.Code = Code;
    }
}

export class UserLogIn {
    Username: string;
    Password: string;
    constructor(Username: string, Password: string) {
        if(Username === undefined) throw new Error( "Class 'UserLogIn': Required value 'Username' is undefined" );
        if(Password === undefined) throw new Error( "Class 'UserLogIn': Required value 'Password' is undefined" );
        this.Username = Username;
        this.Password = Password;
    }
}