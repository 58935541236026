import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Workflow, WorkflowNode, WorkflowNodeSettings } from 'src/app/models/api/com/bion/etl/Workflow';
import { SubSink } from 'subsink';
import { GoNode } from '../graph-view/goJs.model';

import { SqlDummyApiService } from '../sql-dummy-api.service';
import { NodeSelectedData, NodeSettingsChangedData, SettingsView, WorkflowGraphEvent, WorkflowGraphEventFactory } from '../sql-workflow-sketch';

@Component({
  selector: 'app-settings-view',
  templateUrl: './settings-view.component.html',
  styleUrls: ['./settings-view.component.scss']
})
export class SettingsViewComponent implements OnInit, SettingsView {

  constructor(private sql_api: SqlDummyApiService) { }

  //currentNode?: GoNode;
  @Input() nodeData!: GoNode;
  subs = new SubSink;
   
  readonly EXCEL_INPUT_NODE = "Bion.DataWarehouse.PsaInput";

  ngOnInit(): void {
    // this.subs.sink = this.sql_api.nodeSelectedEmitter.subscribe(event => {
    //    this.currentNode = this.onNodeSelected(event.Data.GoNode);
    // });
  }

  // Triggers
  onNodeSelected(node: GoNode): GoNode {
    console.log(node);
    return node
  }
  onSettingsChanged<T>(node: GoNode): Observable<void> {
    
    console.log("Mother Recevied Settings: ",node);

    //this.currentNode = node;

    if(node.nodeProperties === undefined) {
      console.log("No Properties are undefined.");
      return throwError(new Error("No properties are undeined"));
    }

    const wfGraphEvent = WorkflowGraphEventFactory.buildNodeSettingsChanged("settingsView", node,node.nodeProperties);

    // per Service
    //this.sql_api.nodeSettingsChanged2.emit(wfGraphEvent);

    // per @Output() --> siehe Beispiel
    this.settingsChanged.emit(wfGraphEvent);

    return of()
  }

  // Events
  @Output() settingsChanged: EventEmitter<WorkflowGraphEvent<string, NodeSettingsChangedData>> = new EventEmitter<WorkflowGraphEvent<string, NodeSettingsChangedData>>();  // Ich habe den WF Node geändert und schicke ihn nun!


}
