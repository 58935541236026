import { TreeNode } from "primeng/api";
import { Observable } from "rxjs";

/**
 * Displays a field with its corresponding parent object, e.g. Data Source Field & Data Source.
 * @param F Field Type
 * @param O Parent Object Type
 */
export class FieldInfoView<F, O>{

    constructor(fieldInfo: F, obj?: O) {
        this.fieldInfo = fieldInfo;
        this.obj = obj;
    }

    fieldInfo: F;
    obj?: O;
}

/**
 * Item or Items are convertible to a FieldInfoView
 * @param A Source Object or List
 * @param F Field Type
 * @param T Object Type
 */
export interface FieldViewable<T,F,A> {
    buildGuI(arg:A, obj?: T) : FieldInfoView<F,T>[];
}


/**
 * The Field Information Provider for this type.
 * @param T Object Type
 * @param F Field Type
 * @param D Data
 */
export interface FieldInfoProvider<T, F, D> {
    getData(): Observable<D>;
    objectsToTreeNodes(obj: any, fields: FieldInfoView<F,T>[]): TreeNode;
    fieldToTreeNode(field: FieldInfoView<F,T>): TreeNode;
    subscribeToEvents(): Observable<T>;
    buildGuI(res: D, obj?: T): FieldInfoView<F, T>[];
}