export namespace IntegratedDestinationModel {
    export class ConnectorKey<A> {
        Id: A;
        Origin: string;
        constructor(Id: A,Origin: string) {
            if(Id === undefined) throw new Error( "Class 'ConnectorKey': Required value 'Id' is undefined" );
            if(Origin === undefined) throw new Error( "Class 'ConnectorKey': Required value 'Origin' is undefined" );
            this.Id = Id;
            this.Origin = Origin;
        }
    }
    export class DestinationKey<A> {
        Id: A;
        Origin: string;
        constructor(Id: A, Origin: string) {
            if (Id === undefined) throw new Error("Class 'DestinationKey': Required value 'Id' is undefined");
            if (Origin === undefined) throw new Error("Class 'DestinationKey': Required value 'Origin' is undefined");
            this.Id = Id;
            this.Origin = Origin;
        }
    }

}
