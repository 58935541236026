
<div style="display:flex;align-items: center;margin-bottom: 15px">
    <img [alt]="'HeaderIcon'" src="assets/layout/images/HeaderIcon_New.svg" width="50" style="vertical-align: middle" />
    <h2 style="margin: 0px 10px;color:#6941C6;font-weight: bold;">{{'Create new destination' | translate }}</h2>
</div>
<div style="height:4px">
    <p-progressBar *ngIf="isLoading" [mode]="progressMode" [style]="{'height': '4px'}"></p-progressBar>
</div>

<div class="flex justify-content-center" style="margin-bottom:1px">
    <p-breadcrumb class="max-w-full" [model]="items" ></p-breadcrumb>
</div>

<!-- <div class="flex flex-column gap-2" style="margin: 10px 0">
    <label htmlFor="datasource_name">Name</label>
    <input pInputText id="datasource_name" aria-describedby="datasource_name-help" [(ngModel)]="datasource_name" />
    <small id="datasource_name-help"></small>
</div> -->

<div style="background-color: white;padding: 1rem">
    <p-messages [(value)]="messages" [enableService]="false"></p-messages>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-fluid">
                    <label style="font-weight: bold;margin-bottom: 5px" for="inputtext">{{'1. Enter a destination name' | translate}}</label>
                    <input type="text" id="UrlInputtext" pInputText
                        [(ngModel)]="destination_name">
                    <small id="inputtext">{{'Enter the non-empty destination name.' | translate}}</small>
            </div>
        </div>

        <div class="p-col-12">
            <label style="font-weight: bold;margin-bottom: 5px">{{'2. Setup your settings' | translate}}</label>

            <!-- <div *ngIf="config_to_display == 'Airbyte'">
                <app-create-config-int-ab #airbyteConfig [connectorSpecs]="selected_connector_spec?.OriginalSpecs" (onConfigSet)="on_airbyte_config_set($event)"></app-create-config-int-ab>
            </div> -->
            
            <div *ngIf="selected_connector_key.Origin == 'Bion'">
                <app-create-destination-config-bion #bionConfig [connectorSpecs]="selected_connector_spec?.OriginalSpecs" (onConfigSet)="on_bion_config_set($event)"></app-create-destination-config-bion>
            </div>
            
            <!-- <div>
                <p-button label="Testen" (onClick)="testSourceClick()" hidden="true"></p-button>
            </div> -->
        </div>
    </div>
    

    
</div>
