<div class="p-grid">
    <div class="p-col-6">
        <p-toolbar>
            <h6 style="color: rgb(170, 170, 170);margin-top: 0px;margin-bottom:0"> Define your destination</h6>
            <span class="p-input-icon-left mb-2 md:mb-0">
                <button pButton icon="pi pi-info"> </button>
            </span>
        </p-toolbar>
        <div class="p-grid" style="margin-top: 10px">
            <div class="p-col-12 p-md-12">
                <div class="p-fluid">
                    <span class="p-float-label">
                        <input type="text" id="Hostinputtext" pInputText [(ngModel)]="destinationName"
                            (blur)="updateForms()">
                        <label for="Hostinputtext">{{'Destination name' | translate}}</label>
                    </span>
                </div>
            </div>
            <div class="p-col-12 p-md-12">
                <div class="p-fluid">
                    <span class="p-float-label">
                        <textarea style="min-height:20rem;" id="dsdescriptiontext" [cols]="30" pInputTextarea
                            [(ngModel)]="destinationDescription" autoResize="autoResize"
                            (blur)="updateForms()"></textarea>
                        <label for="dsdescriptiontext">{{'DescriptionOptional' | translate}}</label>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="p-col-6">
        <p-toolbar>
            <h6 style="color: rgb(170, 170, 170);margin-top: 0px;margin-bottom:0">Specify your write modus</h6>
            <span class="p-input-icon-left mb-2 md:mb-0">
                <button pButton icon="pi pi-info"> </button>
            </span>
        </p-toolbar>
        <div class="p-grid" style="margin-top:10px">
            <div class="p-col-12 p-md-12">
                <div class="p-fluid">
                    <span class="p-float-label">
                        <p-dropdown id="targetMode" [options]="targetWriteModes" [(ngModel)]="selectedTargetWriteMode"
                            (onChange)="updateForms()" [autoDisplayFirst]="false" optionLabel="Mode" [showClear]="true">
                        </p-dropdown>
                        <label for="targetMode">{{'Target Modus' | translate}} </label>
                    </span>
                </div>
            </div>
            <div class="p-col-12 p-md-12">
                <div class="p-fluid">
                    <span class="p-float-label">
                        <p-dropdown id="tableMode" [options]="selectedTargetWriteMode?.EntityModes"
                            [(ngModel)]="selectedTargetEntityWriteMode" (onChange)="updateForms()"
                            [autoDisplayFirst]="false" optionLabel="Mode" [showClear]="true"></p-dropdown>
                        <label for="tableMode">{{'Table Modus' | translate}} </label>
                    </span>
                </div>
            </div>
            <div class="p-col-12 p-md-12">
                <div class="p-fluid">
                    <span class="p-float-label">
                        <p-dropdown id="recordMode" [options]="selectedTargetEntityWriteMode?.RecordModes"
                            [(ngModel)]="selectedRecordWriteMode" [autoDisplayFirst]="false" (onChange)="updateForms()"
                            [showClear]="true"></p-dropdown>
                        <label for="recordMode">{{'Record Modus' | translate}} </label>
                    </span>
                </div>
            </div>
            <div class="p-col-12 p-md-12">
                <p-divider></p-divider>
            </div>
            <div class="p-col-12 p-md-12">
                <div class="p-fluid">
                    <span class="p-float-label">
                        <!-- <input type="text" id="streamInput" pInputText [(ngModel)]="selectedStream"
                            (blur)="updateForms()"> -->
                        <p-dropdown id="recordMode" [disabled]="streamInputDisabled" [options]="entities"
                            [(ngModel)]="selectedEntity" [autoDisplayFirst]="false" (onChange)="updateForms()"
                            [editable]="streamIsEditable" [showClear]="true"></p-dropdown>

                        <label for="streamInput">{{'Stream' | translate}}</label>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- <div id="TargetWriteModeCombo">
    <h5>Write Mode</h5>
    <b>Datei Schreibmodus</b>
    <p-dropdown [options]="targetWriteModes" [(ngModel)]="selectedTargetWriteMode" placeholder="Select" optionLabel="Label" [showClear]="true"></p-dropdown>
    <b>Tabellen Schreibmodus</b>
    <p-dropdown [options]="targetEntityWriteModes" [(ngModel)]="selectedTargetEntityWriteMode" placeholder="Select" optionLabel="Label" [showClear]="true"></p-dropdown>
</div>

<p></p>

<div id="TargetWriteModeCheck">

    <h5>Write Mode</h5>

    <div class="field-checkbox">
        <p-checkbox [(ngModel)]="targetOverwrite" [binary]="true" inputId="binary"></p-checkbox>
        <label for="binary">Datei überschreiben</label>
    </div>

    <div class="field-checkbox">
        <p-checkbox [(ngModel)]="targetEntityOverwrite" [disabled]="targetOverwrite" [binary]="true" inputId="binary"></p-checkbox>
        <label for="binary">Tabelle überschreiben</label>
    </div>

    <div *ngFor="let category of categories" class="field-checkbox">
        <p-radioButton [inputId]="category.key" name="category" [value]="category" [disabled]="targetEntityOverwrite" [(ngModel)]="selectedCategory" [disabled]="category.key === 'R'"></p-radioButton>
        <label [for]="category.key">{{category.name}}</label>
    </div>

</div>

<p></p>

<div id="TargetWriteMode">
    <h5>Write Mode</h5>
    <p-treeTable [value]="files1" [columns]="cols" selectionMode="single" [(selection)]="selectedNode1" dataKey="name">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns">
                    {{col.header}}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
            <tr [ttRow]="rowNode" [ttRow]="rowNode" [ttSelectableRow]="rowNode">
                <td *ngFor="let col of columns; let i = index">
                    <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
                    {{rowData[col.field]}}
                </td>
            </tr>
        </ng-template>
    </p-treeTable>
</div>

<p></p>

<div id="SelectTargetEntity">

</div>

<div class="p-col-4">
    <p-toolbar>
        <h6 style="color: rgb(170, 170, 170);margin-top: 0px;margin-bottom:0"> Select a write mode</h6>

        <span class="p-input-icon-left mb-2 md:mb-0">
            <button pButton icon="pi pi-info"> </button>
        </span>
    </p-toolbar>
    <div class="chat-section chat card"
        style="background: linear-gradient(79deg, rgba(9,160,199,0.3) 0%, rgba(43,95,147,0.3) 51%); margin-top:20px ">
        <div class="p-grid" style="margin: 10 10">
            <div class="p-col-12 ">
                <h6 style="color: rgb(170, 170, 170);">{{'Mode options' | translate}}</h6>
            </div>
            <p-tabView [(activeIndex)]="index" (onChange)="setWriteMode($event)">
                <p-tabPanel *ngFor="let mode of writeModes; let i=index" [selected]="i == 0">
                    <ng-template pTemplate="header" style="text-align: center;">
                        <img src="assets/layout/images/{{mode.name}}.svg" width="50" height="50" alt="freya-layout" />
                        <span class="p-badge">{{mode.name}}</span>
                    </ng-template>
                    <div class="p-col-12 p-mb-12 p-lg-12 p-mb-lg-0" *ngIf="selectedWriteMode">
                        <div [ngSwitch]="selectedWriteMode.name">
                            <div *ngSwitchCase="'Append'">
                                <div class="p-grid">
                                    <div class="p-col-12" style="margin: 5px 0">
                                        <i class="fa fa-info-circle"></i>
                                        {{'AppendModeText' | translate}}
                                    </div>
                                    <div class="p-col-12" style="text-align: center;">
                                        <img class="writeModeImg" [src]="'assets/layout/images/Append.svg'" width="150"
                                            height="150" />
                                    </div>
                                </div>
                            </div>
                            <div *ngSwitchCase="'Replace'">
                                <div class=" p-grid">
                                    <div class="p-col-12" style="margin: 5px 0">
                                        <i class="fa fa-info-circle"></i>
                                        {{'ReplaceModeText' | translate}}
                                    </div>
                                    <div class="p-col-12" style="text-align: center;">
                                        <img class="writeModeImg" [src]="'assets/layout/images/Replace.svg'" width="150"
                                            height="150" />
                                    </div>
                                </div>
                            </div>
                            <div *ngSwitchCase="'Update'">
                                <div class=" p-grid">
                                    <div class="p-col-12" style="margin: 5px 0">
                                        <i class="fa fa-info-circle"></i>
                                        {{'DeltaModeText' | translate}}
                                    </div>
                                    <div class="p-col-12" style="text-align: center;">
                                        <img class="writeModeImg" [src]="'assets/layout/images/Update.svg'" width="150"
                                            height="150" />
                                    </div>
                                </div>
                            </div>
                            <div *ngSwitchCase="'Delta'">
                                <div class=" p-grid">
                                    <div class="p-col-12" style="margin: 5px 0">
                                        <i class="fa fa-info-circle"></i>
                                        {{'DeltaModeText' | translate}}
                                    </div>
                                    <div class="p-col-12" style="text-align: center;">
                                        <img class="writeModeImg" [src]="'assets/layout/images/Delta.svg'" width="150"
                                            height="150" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </div>

    </div>
</div> -->
