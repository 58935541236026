import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, TreeNode } from 'primeng/api';
import { Table } from 'primeng/table';
import { forkJoin } from 'rxjs';
import { BaseActionActionEvent, BaseActionDialogActionType } from 'src/app/models/dialog-actions.model';
import { ScheduleActionPlan, ScheduleBaseAction } from 'src/app/models/schedule.model';

import { SchedulesService } from 'src/app/services/schedules.service';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { SubSink } from 'subsink';

@Component({
    selector: 'app-schedule-action',
    templateUrl: './schedule-action.component.html',
    styleUrls: ['./schedule-action.component.scss'],
    providers: [MessageService]
})
export class ScheduleActionComponent implements OnInit, OnDestroy {
    subs = new SubSink();
    buttonHidden: boolean = false;
    selectedSchedule?: ScheduleActionPlan;

    selectedAction?: ScheduleBaseAction;
    //@Input() baseActions: ScheduleBaseAction[];
    baseActionsFiltered: ScheduleBaseAction[] = [];
    //selectedRow?: ScheduleBaseAction;
    baseActionsTree: TreeNode[] = [];

    @ViewChild('dt') dt!: Table;
    readonly ExtractType = "models.scheduler.ExtractDataSourceAction";
    readonly WorkflowType = "models.scheduler.RunWorkflowAction";

    constructor(private scheduleService: SchedulesService, private errorService: SystemMessageLogService, private translate: TranslateService, private messageService: MessageService) { }

    ngOnInit(): void {
        this.initView();
        this.subs.sink = this.scheduleService.selectedSchedulePlanEmitter.subscribe((res: ScheduleActionPlan) => {

            if (res === this.selectedSchedule) {
                //this.buttonHidden = true;
                this.selectedSchedule = undefined;
            } else {
                //this.buttonHidden = false;
                this.selectedSchedule = res;
            }
            //console.log(res, this.selectedSchedule);
            this.initView(this.selectedSchedule);
        },
            (err: Error) => {
                this.errorService.handleError(err);
            });
        this.subs.sink = this.scheduleService.actionsChangedEmitter.subscribe(() => {
            this.initView(this.selectedSchedule);
        },
            (err: Error) => {
                this.errorService.handleError(err);
            });
        this.subs.sink = this.scheduleService.schedulesChangedEmitter.subscribe(() => {
            this.initView(this.selectedSchedule);
        },
            (err: Error) => {
                this.errorService.handleError(err);
            })
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    calculateCustomerTotal(name: number) {
        let total = 0;

        if (this.baseActionsFiltered) {
            for (let customer of this.baseActionsFiltered) {
                if (customer.actionPlan === name) {
                    total++;
                }
            }
        }

        return total;
    }

    initView(schedule?: ScheduleActionPlan) {
        const schedulePlansObs = this.scheduleService.getScheduleActionPlan();
        const actionsObs = this.scheduleService.getScheduleActions();

        const finalDataObs = forkJoin(schedulePlansObs, actionsObs).subscribe(final_data => {
            //this.subs.sink = this.scheduleService.getScheduleActions().subscribe((actions) => {
            let baseActionsFinalArray: ScheduleBaseAction[] = new Array();
            if (schedule !== undefined) {
                baseActionsFinalArray = final_data[1].filter(
                    (action: ScheduleBaseAction) => {
                        return action.actionPlan === schedule.id;
                    }
                );
            } else {
                baseActionsFinalArray = final_data[1];
            }
            this.baseActionsFiltered = baseActionsFinalArray;


            const plans = schedule ? final_data[0].filter((plan) => plan.name === schedule.name) : final_data[0];
            const plansTreeNodes = plans
                .map(plan => this.baseActionsToTreeNodes(plan, baseActionsFinalArray))
                .filter(planNode => planNode.children?.length !== 0);

            console.log("plansTreeNodes", plansTreeNodes);
            this.baseActionsTree = plansTreeNodes;


        },
            (err: Error) => {
                this.errorService.handleError(err);
            });
    }


    baseActionsToTreeNodes(plan: ScheduleActionPlan, actions: ScheduleBaseAction[]): TreeNode {
        let treeNode: TreeNode = { label: plan.name, data: plan };

        const relatedActions = actions.filter((action) => action.actionPlan === plan.id);
        const relatedActionsTreeNodes = relatedActions.map((action) => this.actionToTreeNode(action)).sort(
            (objA, objB) => objA.data.index - objB.data.index
        );


        treeNode.children = relatedActionsTreeNodes;

        return treeNode

    }
    /**
     * macht aus einer BaseAction ein TreeNode
     */
    actionToTreeNode(action: ScheduleBaseAction): TreeNode {
        let treeNode: TreeNode = { label: action.description, data: action };

        return treeNode
    }

    // setSelectedAction(tree: TreeNode) {
    //     //console.log("selectedAction",action);
    //     this.selectedAction = tree.data;
    // }
    // dragEnd(event) {
    //     console.log("dragEnd Event",event)
    // }
    // drop(event) {
    //     console.log("drop",event);
    //     this.selectedAction = null;

    // }
    // onRowOrderChanged(node: TreeNode, event) {
    //     // get new order of reordered actions
    //     const allActionNodes: TreeNode[] = this.dt._value; 
    //     // get list of all actions and filter only valid actions within the same actionplan
    //     allActionNodes.forEach((action: TreeNode, i: number) => action.data.index = i);
    //     this.onChangeOrderinBE(allActionNodes);
    // }

    onRowOrderChanged(actions: ScheduleBaseAction[], event: any) {
        // get new order of reordered actions
        //const allActionNodes: TreeNode[] = this.dt._value; 
        // get list of all actions and filter only valid actions within the same actionplan
        //allActionNodes.forEach((action: TreeNode, i: number) => action.data.index = i);
        this.onChangeOrderinBE(actions);
    }

    onChangeOrderinBE(actions: ScheduleBaseAction[]) {
        const newActions: ScheduleBaseAction[] = actions;

        //liste der geänderten Actions an BE schicken
        this.subs.sink = this.scheduleService.updateActionBatch(newActions).subscribe((api_res: number[]) => {
            console.log("api_res", api_res);
            this.scheduleService.actionsChangedEmitter.emit("Action Order changed!");
            this.messageService.add({
                severity: "success",
                summary: this.translate.instant("Message.ChangeActionOrderSuccess.Title"),
                detail: this.translate.instant("Message.ChangeActionOrderSuccess.Text"),
            })
        }, (err: Error) => {
            this.errorService.handleError(err);
        }, () => { });

    }

    displayNewActionDialog() {
        //this.scheduleService.displayCreateAction.emit(true);
        this.scheduleService.baseActionDialogActionSendEmitter.emit(new BaseActionActionEvent(true, "Create action", BaseActionDialogActionType.createAction, "Create", this.selectedAction));

    }
    displayDeleteActionDialog() {
        //this.scheduleService.displayDeleteAction.emit(true);
        this.scheduleService.baseActionDialogActionSendEmitter.emit(new BaseActionActionEvent(true, "Delete action", BaseActionDialogActionType.deleteAction, "Delete", this.selectedAction));

    }
    displayEditActionDialog() {
        //this.scheduleService.displayEditAction.emit(true);
        this.scheduleService.baseActionDialogActionSendEmitter.emit(new BaseActionActionEvent(true, "Edit action", BaseActionDialogActionType.editAction, "Save", this.selectedAction));

    }

    // onSelectAction(evt) {
    //     let action = <ScheduleBaseAction>evt.data;
    //     this.scheduleService.selectedActionEmitter.emit(action);
    // }
    // onUnselectAction(evt) {
    //     let action = <ScheduleBaseAction>evt.data;
    //     this.scheduleService.selectedActionEmitter.emit(action);
    // }

    onMenuClicked(node: ScheduleBaseAction) {
        //let onMenuClickedSchedule = node.data;
        this.selectedAction = node;
        //console.log("node",node);
        //this.scheduleService.selectedMenuActionEmitter.emit(node);
    }

}
