import { Component, OnInit } from "@angular/core";
import {
	TransposeInfo,
	TransposeSettings,
} from "src/app/models/nodeSettings.model";
import { DesignerService } from "src/app/services/designer.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { NodeConfigComponentBase } from "../node-config-component-base";
import { FieldInfo, WorkflowNodeSettings } from "src/app/models/api/com/bion/etl/Workflow";
import { Arrays } from "src/app/helper/arrays";
import { Id } from "src/app/helper/id";
import { DropDownEvents } from "src/app/helper/events";


// Col        Is Key         Errors
// id            X             []
// value         -             []
// 

export class InfoView {
	constructor(columnName:string, isMissing:boolean, errorInfo:string[]) {
		this.columnName = columnName;
		this.isMissing = isMissing;
		this.errorInfo = errorInfo;
	}

	readonly columnName: string;
	isMissing: boolean;
	errorInfo: string[];
}

export class TransposeInfoView {
	availableKeyColums: InfoView[];
	availableDataColumns: InfoView[];
	keyColsHasError: boolean;
	dataColsHasError: boolean;
	selectedKeyColumns: InfoView[];
	selectedDataColumns: InfoView[];

	static empty(): TransposeInfoView {
		return new TransposeInfoView([], [], false, false, [], []);
	}


	constructor(availableKeyColumns: InfoView[],
		availableDataColumns: InfoView[],
		keyColsHasError: boolean,
		dataColsHasError: boolean,
		selectedKeyColumns: InfoView[],
		selectedDataColumns: InfoView[],
	) {
		this.availableDataColumns = availableDataColumns;
		this.availableKeyColums = availableKeyColumns;
		this.keyColsHasError = keyColsHasError;
		this.dataColsHasError = dataColsHasError;
		this.selectedDataColumns = selectedDataColumns;
		this.selectedKeyColumns = selectedKeyColumns
	}
}

@Component({
	selector: "app-transpose-node",
	templateUrl: "./transpose-node.component.html",
	styleUrls: ["./transpose-node.component.css"],
})
export class TransposeNodeComponent
	extends NodeConfigComponentBase<TransposeSettings>
	implements OnInit {


	_settingsToView(settings: WorkflowNodeSettings): void {


		const currentSettings = <TransposeSettings>settings;
		const meta_infos = this.getCurrentWorkflowNode().Properties.MetaInfo.get(this.InputPort);

		try {

			const meta_array = Id.assertSet(meta_infos, new Error("The meta info is not available"));
			const meta = Arrays.head(meta_array);
			this.newSettingsToView(currentSettings, meta);
		} catch (e) {
			console.log(e);
		}

		throw new Error("Not Implemented yet!");
	}

	onSettingsDefault(): void {
		// Keine Settings

	}

	onKeysOrValuesChanged(event: DropDownEvents.OnChange<any,InfoView[]>) {
		// set operations
		console.log("onKeysOrValuesChanged",event);
		const includedKeyInfos: InfoView[] = event.value;
		const newKeys: string[] = includedKeyInfos.map(key => key.columnName);

		const filteredValues = this.transposeInfoView.availableKeyColums.filter(val => newKeys.includes(val.columnName) === false);
		this.transposeInfoView.availableDataColumns = [...filteredValues];

        this.onUpdateSettings(false)
	}



	static withTransposeInfos(transposeInfo: TransposeInfo, infoViewsList: InfoView[], fieldInfoList: FieldInfo[]): TransposeInfoView {


		const transposeInfoView: TransposeInfoView = new TransposeInfoView([], [], false, false, [], []);

		// Check if selection exists, if not extend missing columns to full list & set Error
		const currentDataColumns = transposeInfo.DataColumns;
		const currentKeyColumns = transposeInfo.KeyColumns;

		// -- Prepare key List
		let includedKeyColumns = currentKeyColumns.map(inCol => {
			const missing = infoViewsList.find(element => element.columnName === inCol) === undefined;
			const errors:string[] = missing ? ["The key column does not exist anymore"] : [];
			return new InfoView(inCol, missing, errors);
		});
		transposeInfoView.selectedKeyColumns = includedKeyColumns;


		// Alle aktuellen Felder PLUS Key Fields
		// --> Vereinigung

		const includedKeysMissing = includedKeyColumns.find(keyCol => keyCol.isMissing) !== undefined;
		transposeInfoView.keyColsHasError = includedKeysMissing;

		// -- Prepare available Key Cols
		const field_views = fieldInfoList.map(info => new InfoView(info.Name, false, []));

		const included_key_set = new Map(includedKeyColumns.map(v => [v.columnName,v]));
		const field_set = new Map(field_views.map(v => [v.columnName,v]));
		const available_key_set = new Map([...included_key_set, ...field_set]);

		transposeInfoView.availableKeyColums = Array.from(available_key_set.values());

		// -- Prepare available Data Cols
		const available_value_fields = infoViewsList.filter(col => !currentKeyColumns.includes(col.columnName));
		const available_value_field_set = new Map(available_value_fields.map( v => [v.columnName,v]));
		console.log("filteredKeyCols", available_value_fields);


		let includedDataColumns = currentDataColumns.map(inCol => {
			const missing = infoViewsList.find(element => element.columnName === inCol) === undefined;
			return new InfoView(inCol, missing, []);
		});
		transposeInfoView.selectedDataColumns = includedDataColumns;

		const included_value_set = new Map(includedDataColumns.map(v => [v.columnName,v]));
		const available_value_set = new Map([...included_value_set, ...available_value_field_set]);

		transposeInfoView.availableDataColumns = Array.from(available_value_set.values());

		const includedValuesMissing = includedDataColumns.find(valCol => valCol.isMissing) !== undefined;
		transposeInfoView.dataColsHasError = includedValuesMissing;


		return transposeInfoView;
	}

	/**
	 * Es sind noch keine anderen Tranpose Infos vorahnden!
	 * @param fieldInfoList Field Info List
	 * @returns New Tranpose Info View!
	 */
	static withoutTranposeInfos(fieldInfoList: FieldInfo[]): TransposeInfoView {
		// Check if selection does not exists, set default
		const initialColumns = fieldInfoList.map(entry => this.infoView(entry));
		return new TransposeInfoView(initialColumns, initialColumns, false, false, [], [])
	}

	static infoView(entry: FieldInfo): InfoView {
		return new InfoView(entry.Name, false, []);
	}


	settingsToView(settings: WorkflowNodeSettings): void {
		let currentConfig = <TransposeSettings>settings;

		// -- Get Input Meta Data
		const meta_infos = this.getCurrentWorkflowNode().Properties.MetaInfo.get(
			this.InputPort
		);

		// --create SelectInfoViews from DsFields for table
		if (meta_infos != undefined && meta_infos.length > 0) {
			// --Create new Array
			let fieldInfoList: FieldInfo[] = meta_infos[0].FieldsInfo;
			// -- Create new InfoView Array
			let infoViewsList: InfoView[] = [];

			// -- Append existing metaInfos to full list
			fieldInfoList.map((info) => {
				const view = TransposeNodeComponent.infoView(info)
				infoViewsList.push(view);
			});

			let currentConfigInfos = currentConfig.TransposeInfos;

			if (currentConfigInfos[0] === undefined) {
				// // Check if selection does not exists, set default
				this.transposeInfoView = TransposeNodeComponent.withoutTranposeInfos(fieldInfoList);
			} else {
				this.transposeInfoView = TransposeNodeComponent.withTransposeInfos(currentConfigInfos[0], infoViewsList, fieldInfoList);
			}
		}
	}
	viewToSettings(): TransposeSettings {
		let newSettings = <TransposeSettings>{
			...this.getCurrentWorkflowNode().Properties.Configuration,
		};

		let newConfig = new TransposeInfo();
		newConfig.KeyColumns = this.transposeInfoView.selectedKeyColumns.map((col) => {
			return col.columnName;
		});
		newConfig.DataColumns = this.transposeInfoView.selectedDataColumns.map((col) => {
			return col.columnName;
		});

		newSettings.TransposeInfos = [newConfig];

		return newSettings;
	}
	onSettingsChanged(settings: TransposeSettings) {
		throw new Error("Method not implemented.");
	}

	readonly InputPort = "Input";

	transposeInfoView: TransposeInfoView = TransposeInfoView.empty();

	//availableKeyColums: InfoView[] = [];
	//availableDataColumns: InfoView[] = [];
	//selectedKeyColumns: InfoView[] = [];
	//selectedDataColumns: InfoView[] = [];


	constructor(
		protected workflowService: WorkflowsService,
		protected designerEventService: DesignerService
	) {
		super(workflowService, designerEventService);
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

	ngOnDestroy(): void { }
	onFocusLost(event: FocusEvent) {
		const div_left: boolean = (event.relatedTarget === null);

		console.log("Send from Div: ", div_left);

		if (div_left) {
			this.onUpdateSettings(true);
		}
	}

}
