import { PlayJsonInheritance } from "src/app/models/play.model";
import { DestinationWriteMode } from "./DestinationWriteMode";
import { EntityInfo } from "./EntityInfo";

export interface TargetAccessLike<C> extends PlayJsonInheritance {
    Configuration: C;
    Entity: EntityInfo;
}

export class TargetAccess<C> implements TargetAccessLike<C>{
    Configuration: C;
    Entity: EntityInfo;
    constructor(Configuration: C,Entity: EntityInfo) {
        if(Configuration === undefined) throw new Error( "Class 'TargetAccess': Required value 'Configuration' is undefined" );
        if(Entity === undefined) throw new Error( "Class 'TargetAccess': Required value 'Entity' is undefined" );
        this.Configuration = Configuration;
        this.Entity = Entity;
    }
    _type:string;
}

export interface TargetMetaAccessLike<C> {
    Configuration: C;
}

export class TargetMetaAccess<C> implements TargetMetaAccessLike<C>{
    Configuration: C;
    constructor(Configuration: C) {
        if(Configuration === undefined) throw new Error( "Class 'TargetMetaAccess': Required value 'Configuration' is undefined" );
        this.Configuration = Configuration;
    }
}

export class TargetMetaInfo {
    Entities: Array<EntityInfo>;
    WriteModes: DestinationWriteMode.WriteModeInfo;
    constructor(Entities: Array<EntityInfo>,WriteModes: DestinationWriteMode.WriteModeInfo) {
        if(Entities === undefined) throw new Error( "Class 'TargetMetaInfo': Required value 'Entities' is undefined" );
        if(WriteModes === undefined) throw new Error( "Class 'TargetMetaInfo': Required value 'WriteModes' is undefined" );
        this.Entities = Entities;
        this.WriteModes = WriteModes;
    }
}