<p-dialog header="{{'Upload File' | translate}}" [baseZIndex]="1000" [(visible)]="showPushFileDialog" modal="modal"
    showEffect="fade" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '70vw'}"
    (onHide)="dlgOnHide()">
    <div style="height: 4px">
        <p-progressBar *ngIf="isLoading" [mode]="progressMode" [style]="{'height': '4px'}"></p-progressBar>
    </div>
    <p-messages [(value)]="messages" [enableService]="false"></p-messages>

    <p>Wählen Sie eine neue Datei für die {{source?.Name}}!</p>
    <div id="fileView">
        <label for="file-upload" class="custom-file-upload">
            {{'Select file' | translate}}
        </label>
        <input id="file-upload" type="file" (change)="onFileSelected($event)"
            accept=".xlsm,.xlx,.xlsx,application/msexcel,.csv" />
        <span *ngIf="selectedFileName"> {{ selectedFileName }}</span>
    </div>

    <!-- <input type="file" (change)="onFileSelected($event)" /> -->

    <p-footer>
        <p-button raised="true" (onClick)="btnCloseClick()"
            [label]="selectedFileName ? 'Datei hochladen' : 'Bitte Datei hochladen'"
            [disabled]="!selectedFileName"></p-button>
    </p-footer>




</p-dialog>