<p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>
<p-overlayPanel #op [showCloseIcon]="true" [appendTo]="'body'" style="width: 300px;">
    <ng-template pTemplate>
        <app-node-config style="width:500px"></app-node-config>
    </ng-template>
</p-overlayPanel>

<div style="padding-bottom:0;margin-bottom:0; max-height:calc(30rem)">
    <!-- <div #graphContainer pDroppable="plugInInfos" (onDrop)="dropEmpty($event)" (mousewheel)="handleMouseWheel($event)"
    class="graphContainer"> -->
    <div #graphContainer (mousewheel)="handleMouseWheel($event)" class="graphContainer">
    </div>
    <!-- <div *ngIf="isEditor" #outlineContainer id="outlineContainer"
    style="position:absolute;overflow:hidden;top:190px;right:525px;width:250px;height:150px;background:transparent;border-style:solid;border-width: 1px; border-color:rgb(191, 191, 191);">
</div> -->
    <!-- <div #outlineContainer id="outlineContainer"
        style="position:absolute; top:65px; right: 5px;overflow:hidden;width:245px;height:150px;background:white;border-style:solid;border-width: 1px; border-color:rgb(191, 191, 191);z-index:102">
    </div>
    <div style="position:absolute; top:170px; right: 5px;overflow:hidden;z-index:103">
        <button pButton pRipple type="button" icon="pi pi-plus" pTooltip="{{'ZoomIn' | translate}}"
            tooltipPosition="top" (click)="zoomIn($event)" class="p-button-rounded p-button-text p-mr-2 p-mb-2"></button>
        <button pButton pRipple type="button" icon="pi pi-minus" pTooltip="{{'ZoomOut' | translate}}"
            tooltipPosition="top" (click)="zoomOut($event)" class="p-button-rounded p-button-text p-mr-2 p-mb-2"></button>
        <button pButton pRipple type="button" icon="pi pi-window-maximize" pTooltip="{{'Fit' | translate}}"
            tooltipPosition="top" (click)="fit()" class="p-button-rounded p-button-text p-mr-2 p-mb-2"></button>
        <button pButton pRipple type="button" icon="pi pi-window-minimize" pTooltip="{{'ActualSize' | translate}}"
            tooltipPosition="top" (click)="actualSize()" class="p-button-rounded p-button-text p-mr-2 p-mb-2"></button>
    </div> -->

    <p-contextMenu appendTo="body" [target]="graphContainer" [model]="items"></p-contextMenu>
</div>