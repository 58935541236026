import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { DropDownEvents, TabViewEvents } from "src/app/helper/events";
import { Id } from "src/app/helper/id";
import { WeekRhythm, MonthRhythm } from "src/app/models/api/models/scheduler/BasicDayRythm";
import { Clock } from "src/app/models/api/models/scheduler/Clock";
import { BasicFrequency } from "src/app/models/api/models/scheduler/CronFrequency";
import { RhythmSettings } from "src/app/models/api/models/scheduler/RhythmSettings";
import { BaseFrequencyActionEvent, BaseFrequencyDialogActionType } from "src/app/models/dialog-actions.model";
import { GuiErrorCode, GuiErrorException } from "src/app/models/gui.error.model";
import {
	ScheduleActionPlan
} from "src/app/models/schedule.model";
import { TimeZoneInfo } from "src/app/models/system.model";
import { SchedulesService } from "src/app/services/schedules.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { SubSink } from "subsink";

export interface Option {
	name: string;
	value: number;
}

@Component({
	selector: "app-create-frequency-dialog",
	templateUrl: "./create-frequency-dialog.component.html",
	styleUrls: ["./create-frequency-dialog.component.scss"],
	providers: [MessageService],
})
export class CreateFrequencyDialogComponent implements OnInit, OnDestroy {
	subs = new SubSink();
	@Input() schedules: ScheduleActionPlan[] = [];

	headerText: string = "";
	savingInProgress: boolean = false;
	displayNewFrequency: boolean = false;
	isNew: boolean = true;
	activeIndex: number = 0;
	selectedSchedule?: ScheduleActionPlan;
	selectedFrequency?: BasicFrequency;

	weekOptions: Option[] = [];
	selectedWeekOptions: Option[] = [];
	monthOptions: Option[] = [];
	selectedMonthOptions: Date[] = [];

	selectedRythmnOption?: string;

	isLastDayFlag: boolean = false;
	LastDayOffset: number = 0;

	defaultDate = new Date("January 31 2022 12:30");

	frequencyDescription: string = "";
	startDate?: Date;
	endDate?: Date;
	time?: Date;
	clockTime?: string;

	readonly WeekRhytm: string = "models.scheduler.WeekRhythm";
	readonly MonthRhytm: string = "models.scheduler.MonthRhythm";

	selectedLastDayIndex: number = 1;

	time_zones: TimeZoneInfo[] = [];
	system_time_zone?: TimeZoneInfo = undefined;

	constructor(
		private scheduleService: SchedulesService,
		private systemLogService: SystemMessageLogService,
		private messageService: MessageService,
		private translate: TranslateService
	) { }
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngOnInit(): void {
		this.weekOptions = [
			{ name: "Mon", value: 2 },
			{ name: "Tue", value: 3 },
			{ name: "Wed", value: 4 },
			{ name: "Thu", value: 5 },
			{ name: "Fri", value: 6 },
			{ name: "Sat", value: 7 },
			{ name: "Sun", value: 1 },
		];
		this.monthOptions = [
			{ name: "1", value: 1 },
			{ name: "2", value: 2 },
			{ name: "3", value: 3 },
			{ name: "4", value: 4 },
			{ name: "5", value: 5 },
			{ name: "6", value: 6 },
			{ name: "7", value: 7 },
			{ name: "8", value: 8 },
			{ name: "9", value: 9 },
			{ name: "10", value: 10 },
			{ name: "11", value: 11 },
			{ name: "12", value: 12 },
			{ name: "13", value: 13 },
			{ name: "14", value: 14 },
			{ name: "15", value: 15 },
			{ name: "16", value: 16 },
			{ name: "17", value: 17 },
			{ name: "18", value: 18 },
			{ name: "19", value: 19 },
			{ name: "20", value: 20 },
			{ name: "21", value: 21 },
			{ name: "22", value: 22 },
			{ name: "23", value: 23 },
			{ name: "24", value: 24 },
			{ name: "25", value: 25 },
			{ name: "26", value: 26 },
			{ name: "27", value: 27 },
			{ name: "28", value: 28 },
			{ name: "29", value: 29 },
			{ name: "30", value: 30 },
			{ name: "31", value: 31 },
		];

		// Time Zones
		this.subs.sink = this.scheduleService
			.getTimeZones()
			.subscribe((time_zones) => {
				this.time_zones = time_zones;
				this.subs.sink = this.scheduleService
					.getDefaultTimeZone()
					.subscribe((default_zone) => {
						this.system_time_zone = default_zone;
					},
						(err: Error) => {
							this.systemLogService.handleError(err);
						});
			});

		this.subs.sink = this.scheduleService.selectedSchedulePlanEmitter.subscribe(
			(sc: ScheduleActionPlan) => {
				if (sc === this.selectedSchedule) {
					this.selectedSchedule = undefined;
				} else {
					this.selectedSchedule = sc;
				}
			},
			(err: Error) => {
				this.systemLogService.handleError(err);
			}
		);

		this.subs.sink = this.scheduleService.baseFrequencyDialogActionSendEmitter.subscribe((evt: BaseFrequencyActionEvent) => {
			if(evt.actionType === BaseFrequencyDialogActionType.createFrequency) {
				this.selectedFrequency = evt.frequency;
				this.headerText = evt.header;
				this.displayNewFrequency = evt.display;
				this.isNew = true;
				this.system_time_zone = this.time_zones.find((timezone) => {
					return timezone.ID === "Europe/Berlin";
				});
				this.startDate = new Date(Date.now());

				this.selectedRythmnOption = this.WeekRhytm;
				this.isLastDayFlag = false;
			}
			if(evt.actionType === BaseFrequencyDialogActionType.editFrequency) {
				
				if (evt.frequency === undefined) return;

				this.headerText = evt.header;
				this.displayNewFrequency = evt.display;
				this.selectedFrequency = evt.frequency;

				this.isNew = false;

				this.selectedSchedule = evt.frequency ? this.schedules.find(sc => sc.id === evt.frequency?.ActionPlan) : undefined;

				if (evt.frequency.ValidFrom)
					this.startDate = new Date(evt.frequency.ValidFrom);
				if (evt.frequency.ValidTo)
					this.endDate = new Date(evt.frequency.ValidTo);
				if (evt.frequency.Description)
					this.frequencyDescription = evt.frequency.Description;
				if (evt.frequency.ZoneId) {
					this.system_time_zone = this.time_zones.find(timezone => timezone.ID === evt.frequency?.ZoneId);
				}
				if (evt.frequency.RhythmSettings.Rhythm.UseLastDay) {
					this.isLastDayFlag = evt.frequency.RhythmSettings.Rhythm.UseLastDay;
				}

				if (evt.frequency.Clock) {
					let clock = evt.frequency.Clock;
					let hh = clock.Hour.toString();
					let mm = clock.Minute.toString();
					let ss = clock.Second.toString();
					let clockArray = [hh, mm, ss];

					let clockArrayFinal = clockArray.map((i) => {
						if (i.length === 1) {
							return "0" + i;
						} else {
							return i;
						}
					});

					this.clockTime = clockArrayFinal.join(":");
				}

				if (
					evt.frequency.RhythmSettings.Rhythm._type === this.WeekRhytm
				) {
					this.selectedRythmnOption = this.WeekRhytm;
					this.activeIndex = 0;
					let rhytm = <WeekRhythm>evt.frequency.RhythmSettings.Rhythm;
					rhytm.LastDayIndex = this.selectedLastDayIndex;

					let rhytmObjArray = [];
					for (let i of this.weekOptions) {
						for (let j of rhytm.Days) {
							if (i.value === j) rhytmObjArray.push(i);
						}
					}
					this.selectedWeekOptions = rhytmObjArray;

					this.isLastDayFlag = evt.frequency.RhythmSettings.Rhythm.UseLastDay;
				} else {
					this.selectedRythmnOption = this.MonthRhytm;
					this.activeIndex = 1;
					let rhytm = <MonthRhythm>evt.frequency.RhythmSettings.Rhythm;

					let monthRhytmObjArray = [];

					for (let i of rhytm.Days) {
						let newDate = new Date(this.defaultDate.setDate(i));
						monthRhytmObjArray.push(newDate);
					}

					this.selectedMonthOptions = monthRhytmObjArray;
					this.isLastDayFlag = evt.frequency.RhythmSettings.Rhythm.UseLastDay;
					this.LastDayOffset = rhytm.LastDayOffset;
				}
			}
		}, (err:Error) => {
			this.systemLogService.handleError(err);
		});

	}

	// onSelectSchedule(evt) {

	// }
	setIsLastDayFlag() {
	}

	resetCurrentForm() {
		this.frequencyDescription = "";
		this.startDate = undefined;
		this.endDate = undefined;
		this.selectedRythmnOption = undefined;
		this.selectedMonthOptions = [];
		this.selectedWeekOptions = [];
	}

	validateCurrentForm() {
		this.savingInProgress = true;
		if (this.isNew) {
			this.createBasicFrequency();
		}
		if (!this.isNew) {
			this.updateFrequencyNew();
		}
	}

	onSelectWeekOptions(event: DropDownEvents.OnChange<any,Option[]>) {
		this.selectedWeekOptions = event.value;
	}

	onTabChanged(event: TabViewEvents.OnChange<any>) {
		if (event.index === 0) {
			this.selectedRythmnOption = this.WeekRhytm;
		} else {
			this.selectedRythmnOption = this.MonthRhytm;
		}
	}

	createBasicFrequency() {

		try {

			const selected_schedule = Id.assertSet(this.selectedSchedule, new Error("No Schedule selected"));

			const valid_from = this.startDate
				? this.startDate.toISOString()
				: undefined;
			const valid_to = this.endDate
				? this.endDate.toISOString()
				: undefined;

			// create Frequency Clock
			const clock_time = Id.assertSet(this.clockTime, new Error("Clock Time is not set"));
			const system_time_zone = Id.assertSet(this.system_time_zone, new Error("System Time Zone is not set"));

			let splittedClockTime = clock_time.split(":");

			const hour = parseInt(splittedClockTime[0]);
			const minute = parseInt(splittedClockTime[1]);
			const second = parseInt(splittedClockTime[2]);

			const newFrequencyClock = new Clock(hour, minute, second);

			// create Generic RythmSettings Class
			let newFrequencyRythm: RhythmSettings | undefined;

			// create specific rythmn class depending on selection on GUI (weekly vs monthly)
			if (this.selectedRythmnOption === this.WeekRhytm) {


				let days: number[] = [];
				let use_last_day = false;
				let last_day_index = 1;

				if (this.isLastDayFlag) {
					use_last_day = this.isLastDayFlag; // TODO
					last_day_index = this.selectedLastDayIndex;

				} else {
					if (this.selectedWeekOptions === undefined) {
						throw new Error("Please select a week Option");
					}
					days = this.selectedWeekOptions.map((entry) => entry.value);
				}

				newFrequencyRythm = new RhythmSettings(new WeekRhythm(days, use_last_day, last_day_index));
			}
			if (this.selectedRythmnOption === this.MonthRhytm) {

				let days: number[] = [];
				let use_last_day = false;
				let last_day_offset = 0;

				if (this.isLastDayFlag) {
					use_last_day = this.isLastDayFlag; // TODO
					last_day_offset = this.LastDayOffset;
				} else {

					if (this.selectedMonthOptions === undefined) {
						throw new Error("Please select a month Option");
					}
					days = this.selectedMonthOptions.map((entry) => entry.getDate());
				}

				newFrequencyRythm = new RhythmSettings(new MonthRhythm(days,use_last_day,last_day_offset));
			}

			if(newFrequencyRythm === undefined) throw new Error("The Rythm " + this.selectedRythmnOption + " is not supported!");

			const new_frequency = new BasicFrequency(
				-1,
				selected_schedule.id,
				newFrequencyClock,
				newFrequencyRythm,
				system_time_zone.ID,
				this.frequencyDescription,
				valid_from,
				valid_to
			);

			console.log("New Basic Frequency", new_frequency);

			if(!new_frequency.ActionPlan && !new_frequency.Clock && !new_frequency.ID && !new_frequency.RhythmSettings) {
				this.systemLogService.handleError( new GuiErrorException(GuiErrorCode.entryIncomplete))
				return;
			}

			// Emit new Action to Mother Component
			this.subs.sink = this.scheduleService
				.createScheduleFrequency(new_frequency)
				.subscribe(
					(res) => {
						this.scheduleService.frequenciesChangedEmitter.emit("New Frequency");

						this.messageService.add({
							severity: "success",
							summary: this.translate.instant("Message.CreateFrequencySuccess.Title"),
							detail: this.translate.instant("Message.CreateFrequencySuccess.Text1") + res.ID +
							this.translate.instant("Message.CreateFrequencySuccess.Text2"),
						});

						//this.scheduleService.newFrequencyEmitter.emit(new_frequency);
						this.displayNewFrequency = false;
					},
					(err: Error) => {
						this.systemLogService.handleError(err);
						this.savingInProgress = false;
					}, () => {
						this.savingInProgress = false;
					}
				);
		}
		catch (e) {
			const error: Error = <Error>e;
			this.systemLogService.handleError(error);
			this.savingInProgress = false;
		}
	}

	updateExistingRhythm(copy: BasicFrequency) {
		if (copy.RhythmSettings.Rhythm._type === this.WeekRhytm) {
			let newFrequency = <BasicFrequency>copy;
			let rythm = <WeekRhythm>copy.RhythmSettings.Rhythm;

			rythm.Days = [];
			rythm.UseLastDay = false;
			rythm.LastDayIndex = 1;

			if (this.isLastDayFlag) {
				rythm.UseLastDay = this.isLastDayFlag; // TODO
				rythm.LastDayIndex = this.selectedLastDayIndex;

			} else {
				if (this.selectedWeekOptions === undefined) {
					throw new Error("Please select a week Option");
				}
				rythm.Days = this.selectedWeekOptions.map((entry) => {
					return entry.value;
				});
			}

			rythm.UseLastDay = this.isLastDayFlag;
			rythm.LastDayIndex = this.selectedLastDayIndex;

			copy = newFrequency;
		}
		if (copy.RhythmSettings.Rhythm._type === this.MonthRhytm) {
			let newFrequency = <BasicFrequency>copy;
			let rythm = <MonthRhythm>copy.RhythmSettings.Rhythm;

			let days: number[] = this.selectedMonthOptions.map((entry) => {
				let dateDay = entry.getDate();
				return dateDay;
			});

			newFrequency.RhythmSettings.Rhythm.Days = days;
			newFrequency.RhythmSettings.Rhythm.UseLastDay = this.isLastDayFlag;

			rythm.LastDayOffset = this.LastDayOffset;

			copy = newFrequency;
		}

		return copy;
	}

	createNewRythm(copy: BasicFrequency) {
		if (this.selectedRythmnOption === this.WeekRhytm) {
			const days = this.selectedWeekOptions.map(entry => entry.value);
			const newRhythm = new WeekRhythm(days, this.isLastDayFlag, this.selectedLastDayIndex);

			const newFrequencyRythm = new RhythmSettings(newRhythm);
			copy.RhythmSettings = newFrequencyRythm;
		}
		if (this.selectedRythmnOption === this.MonthRhytm) {


			const days = this.selectedMonthOptions.map(entry => entry.getDate());
			const use_last_day = this.isLastDayFlag;
			const last_day_offset = this.LastDayOffset;

			const newRhythm = new MonthRhythm(days, use_last_day, last_day_offset);
			const newFrequencyRythm = new RhythmSettings(newRhythm);

			copy.RhythmSettings = newFrequencyRythm;
		}

		return copy;
	}

	setupBasicFrequency(copy: BasicFrequency) {
		copy.ValidTo = this.endDate ? this.endDate.toISOString()
			: undefined;

		copy.ValidFrom = this.startDate?.toISOString();
		copy.Description = this.frequencyDescription;
		const action_plan = Id.assertSet(this.selectedFrequency?.ActionPlan, new Error("The action plan is not set"));
		copy.ActionPlan = action_plan;

		console.log(this.clockTime);

		const clock_time = Id.assertSet(this.clockTime, new Error("Clock time is not set"));
		const splittedClockTime = clock_time.split(":");

		copy.Clock.Hour = parseInt(splittedClockTime[0]);
		copy.Clock.Minute = parseInt(splittedClockTime[1]);
		copy.Clock.Second = parseInt(splittedClockTime[2]);

		return copy;
	}

	updateFrequencyNew() {

		const selected_frequency = Id.assertSet(this.selectedFrequency, new Error("Selected frequency is not set"));
		let copy: BasicFrequency = { ...selected_frequency };

		try {

			if (copy.RhythmSettings.Rhythm._type === this.selectedRythmnOption) {
				// "RHYTMTYPE UNCHANGED" -> just update existing rythmn;
				copy = this.updateExistingRhythm(copy);
			} else {
				// "RHYTMTYPE CHANGED -> create new rythm";
				copy = this.createNewRythm(copy);
			}

			copy = this.setupBasicFrequency(copy);

			this.subs.sink = this.scheduleService
				.updateScheduleFrequencies(copy)
				.subscribe(
					(res) => {
						this.scheduleService.frequenciesChangedEmitter.emit("Updated Frequency");

						this.messageService.add({
							severity: "success",
							summary: this.translate.instant("Message.UpdateFrequencySuccess.Title"),
							detail: this.translate.instant("Message.UpdateFrequencySuccess.Text1") + res.ID +
							this.translate.instant("Message.UpdateFrequencySuccess.Text2"),
						});

						this.displayNewFrequency = false;
					},
					(err: Error) => {
						this.systemLogService.handleError(err);
					}, () => {
						this.savingInProgress = false;
					}
				);

		} catch (e) {
			const error = <Error>e;
			this.systemLogService.handleError(error);
		} finally {
			this.savingInProgress = false;
		}
	}
}
