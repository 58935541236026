import {
	Component,
	Input,
	OnDestroy,
	OnInit,
	SimpleChanges,
} from "@angular/core";
//import { CalendarOptions, EventApi } from "@fullcalendar/angular";
import { BasicFrequency } from "src/app/models/api/models/scheduler/CronFrequency";
import {
	ScheduleActionPlan,
	TimeStampInfo,
} from "src/app/models/schedule.model";
import {
	DateTimeStampInfo,
	SchedulesService,
} from "src/app/services/schedules.service";
import { SubSink } from "subsink";

// CODE URL REFERENCE
// https://fullcalendar.io/docs/angular

export class TimeStampInfoView {

	dateObjects: Date[];
	actionPlan: ScheduleActionPlan;
	frequency: BasicFrequency;

	constructor(dateObjects: Date[], actionPlan: ScheduleActionPlan, frequency: BasicFrequency) {
		this.dateObjects = dateObjects;
		this.actionPlan = actionPlan;
		this.frequency = frequency;
	}
}

@Component({
	selector: "app-schedule-calendar",
	templateUrl: "./schedule-calendar.component.html",
	styleUrls: ["./schedule-calendar.component.scss"],
})
export class ScheduleCalendarComponent implements OnInit {
	// subs = new SubSink();
	// @Input() schedules: ScheduleActionPlan[] = [];
	// @Input() appointments: TimeStampInfo[] = [];
	// selectedSchedule?: ScheduleActionPlan;
	// endDate: Date = new Date(2100, 12, 31);
	// startDate?: Date;

	// events: any[] = [];

	// // event.start OR events.date ?

	// options: CalendarOptions = {
	// 	initialView: "dayGridMonth",
	// 	editable: false,
	// 	// dateClick: this.onDateClick.bind(this), // bind is important!
	// 	events: [
	// 		{ title: "event 1", start: "2019-04-01" },
	// 		{ title: "event 2", start: "2019-04-02" },
	// 	],
	// };

	// header: any;

	// eventDialog: boolean = false;

	// changedEvent: any;

	// clickedEvent?: EventApi = undefined;
	ngOnInit() {}

	 constructor(private scheduleService: SchedulesService) { }
	// ngOnDestroy(): void {
	// 	this.subs.unsubscribe();
	// }

	// ngOnChange(changes: SimpleChanges) {
	// 	if (changes.options.currentValue) {
	// 		this.options = changes.options.currentValue;
	// 	}
	// }

	// ngOnInit() {

	// 	this.options = {
	// 		initialDate: Date.now(),
	// 		headerToolbar: {
	// 			left: "prev,next today",
	// 			center: "title",
	// 			right: "dayGridMonth,timeGridWeek,timeGridDay",
	// 		},
	// 		editable: false,
	// 		selectable: false,
	// 		selectMirror: true,
	// 		dayMaxEvents: true,
	// 		eventClick: (e) => {
	// 			this.eventDialog = true;

	// 			this.clickedEvent = e.event;

	// 			this.changedEvent.title = this.clickedEvent.title;
	// 			this.changedEvent.start = this.clickedEvent.start;
	// 			this.changedEvent.end = this.clickedEvent.end;
	// 		},
	// 		events: [
	// 			{ title: "event 1", start: "2019-04-01" },
	// 			{ title: "event 2", start: "2019-04-02" },
	// 		],
	// 	};

	// 	this.getAppointments();

	// 	this.subs.sink = this.scheduleService.selectedSchedulePlanEmitter.subscribe(
	// 		(plan: ScheduleActionPlan) => {
	// 			if (plan === this.selectedSchedule) {
	// 				this.selectedSchedule = undefined;
	// 				this.getAppointments();

	// 				return;
	// 			}

	// 			this.selectedSchedule = plan;
	// 			this.getAppointments(plan);
	// 		}
	// 	);

	// 	//this.changedEvent = { title: "", start: null, end: "", allDay: null };
	// }

	// getAppointments(schedule?: ScheduleActionPlan) {
	// 	if (!schedule) {
	// 		this.subs.sink = this.scheduleService
	// 			.getFrequencyTimeStamps(this.endDate)
	// 			.subscribe((timestamps: DateTimeStampInfo[]) => {
	// 				let new_events = this.timeStampInfoToEvent(timestamps);

	// 				const new_options = { ...this.options };
	// 				new_options.events = new_events;
	// 				this.events = new_events;
	// 				this.options = { ...this.options, ...{ events: new_events } };
	// 			});
	// 	} else {
	// 		this.subs.sink = this.scheduleService
	// 			.getFrequencyTimeStamps(
	// 				this.endDate,
	// 				undefined,
	// 				this.selectedSchedule?.id,
	// 				this.startDate
	// 			)
	// 			.subscribe((timestamps: DateTimeStampInfo[]) => {
	// 				let new_events = this.timeStampInfoToEvent(timestamps);

	// 				const new_options = { ...this.options };
	// 				new_options.events = new_events;
	// 				this.events = new_events;
	// 				this.options = { ...this.options, ...{ events: new_events } };
	// 			});
	// 	}
	// }

	// save() {
	// 	if (this.clickedEvent) {
	// 		this.eventDialog = false;

	// 		this.clickedEvent.setProp("title", this.changedEvent.title);
	// 		this.clickedEvent.setStart(this.changedEvent.start);
	// 		this.clickedEvent.setEnd(this.changedEvent.end);
	// 		this.clickedEvent.setAllDay(this.changedEvent.allDay);

	// 		this.changedEvent = { title: "", start: null, end: "", allDay: null };
	// 	} else {
	// 		console.log("Warning! Clicked Event is undefined!");
	// 	}
	// }

	// reset() {
	// 	if (this.clickedEvent) {
	// 		this.changedEvent.title = this.clickedEvent.title;
	// 		this.changedEvent.start = this.clickedEvent.start;
	// 		this.changedEvent.end = this.clickedEvent.end;
	// 	}
	// }

	// // onDateClick(arg) {
	// // 	console.log("On Date Click", arg);
	// // }

	// timeStampInfoToEvent(timestamps: DateTimeStampInfo[]) {
	// 	let new_events = [];

	// 	for (let timestamp of timestamps) {
	// 		for (let date of timestamp.DateObjects) {
	// 			let newTitel: string;
	// 			newTitel = timestamp.ActionPlan?.name ? timestamp.ActionPlan?.name : "";
	// 			const new_date = date.toISOString();
	// 			new_events.push({ title: newTitel, start: new_date });
	// 		}
	// 	}
	// 	return new_events;
	// }
}
