<!-- <p-dropdown [options]="ConnectorInfos" [(ngModel)]="selected_connector_info" optionLabel="Name" (onChange)="onConnChanged($event)"></p-dropdown> -->

<p-dataView #dv [value]="ConnectorInfos" [paginator]="true" [rows]="24" filterBy="Name" [sortField]="sortField"
    [sortOrder]="sortOrder" layout="grid">
    <ng-template pTemplate="header">
        <div class="flex flex-column md:flex-row" style="display: flex;align-items: center">
            <!-- <p-dropdown [options]="sortOptions" [(ngModel)]="sortKey" placeholder="Sort By Price" (onChange)="onSortChange($event)" styleClass="mb-2 md:mb-0"></p-dropdown> -->
            <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input type="search" [style]="{'width':'100%'}" pInputText
                    placeholder="{{'Search connector' | translate}}" (input)="dv.filter($event.target.value)">
            </span>
            <span style="margin-left:10px">
                <!-- <p-dataViewLayoutOptions></p-dataViewLayoutOptions> -->
                {{ConnectorInfos.length}} {{'available connectors' | translate}}
            </span>
        </div>
    </ng-template>
    <ng-template let-product pTemplate="listItem">
        <div class="col-12" (click)="onConnChanged(product)">
            <div class="p-grid p-shadow-2 product-list-item" style="margin: 15px;">
                <div class="p-col-4 card-header">
                    <div class="card-title">
                        <!-- <div pTooltip="{{product.Provider}}">
                        <img src="assets/layout/images/connectors/{{product.Name}}.png"
                            style="width:40px; height: 40px; margin-right: 5px;"
                            [alt]=" product.Provider" />
                    </div> -->
                        <div [innerHTML]="getSafeHtml(product.Icon)" style="max-width: 100%;"></div>

                    </div>
                </div>
                <div class="p-col-8">
                    <div class="product-name">
                        {{product.Name}}
                    </div>

                    <!-- <div>
                    <p-badge value="{{product.Category}}">{{product.Category}}</p-badge>
                </div> -->
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template let-product pTemplate="gridItem">
        <div class="p-col-12 p-md-6 p-lg-3" (click)="onConnChanged(product)">
            <div class="p-grid connectorItem" [ngClass]="{'highlight': selected_connector_info === product && !product.DisabledInFE }"
                style="margin: 10px;">
                <div class="p-col-12">
                    <div class="card-title p-grid" style="display: flex;align-items: center;margin:0;"
                        pTooltip="{{product.Name}}">
                        <div class="p-col-4" *ngIf="!product.DisabledInFE">
                            <img [style.max-width.%]="'100'" [style.max-height.%]="'100'" *ngIf="!product.Icon"
                                src="assets/layout/images/connectors/{{product.Name}}.png"
                                style="margin-bottom: 5px; margin-top: 5px" [alt]=" product.Provider" />
                            <div *ngIf="product.Icon" [style.max-width.%]="'100'" [style.max-height.%]="'100'"
                                [innerHTML]="getSafeHtml(product.Icon)"></div>
                        </div>
                        <div class="p-col-4" *ngIf="product.DisabledInFE" style="opacity: 0.25;">
                            <img [style.max-width.%]="'100'" [style.max-height.%]="'100'" *ngIf="!product.Icon"
                                src="assets/layout/images/connectors/{{product.Name}}.png"
                                style="margin-bottom: 5px; margin-top: 5px" [alt]=" product.Provider" />
                            <div *ngIf="product.Icon" [style.max-width.%]="'100'" [style.max-height.%]="'100'"
                                [innerHTML]="getSafeHtml(product.Icon)"></div>
                        </div>
                        <div class="p-col-8 d-flex align-items-center">
                            <div class="product-name">
                                {{product.Name}}
                                <p-badge *ngIf="!product.DisabledInFE" severity="success" value="{{'active' | translate}}">
                                </p-badge>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </ng-template>
</p-dataView>