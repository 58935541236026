import { Component, Input, OnInit } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Id } from 'src/app/helper/id';
import { Workflow } from 'src/app/models/api/com/bion/etl/Workflow';
import { PsaInputPlugIn } from 'src/app/models/api/models/etl/PsaInputPlugIn';
import { WorkflowRepositoryEntry } from 'src/app/models/api/models/workflow/WorkflowRepositoryEntry';
import { DataStore } from 'src/app/models/datastore.model';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { CubesService } from 'src/app/services/cubes.service';
import { DatasourcesService } from 'src/app/services/datasources.service';
import { DateTimeStampInfo, SchedulesService } from 'src/app/services/schedules.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { SubSink } from 'subsink';

import { DataSource } from "../../../models/datasource.model";
import { ActionPlan, ExtractDataSourceAction, RunWorkflowAction, ScheduleActionPlan, ScheduleBaseAction, ScheduleExtractActionFull, ScheduleWorkflowActionFull } from "../../../models/schedule.model";
// import { DataSourceInputConfiguration, Workflow, WorkflowRepositoryEntry } from "../../../models/workflow.model";



// Scope:
// Welche Workflows sind in welchen Action-Plans?
// In welchem Workflow wurde die Data-source verwendet

// In welchem Schedule wurde die Datasource verwendet?


export interface RelationInfo {
    // findDsInSchedule(ds:DataSource, plans:ActionPlan[]) : ActionPlan[]
    // findDsInWorkflow(ds:DataSource, workflows:Workflow[]) : Workflow[]

    predicate_DsInWorkflow(workflow:Workflow, ds:DataSource) : boolean
    /**
     * Liefert true, wenn der Action Plan mindestens eine Extract Action hat, in der diese Data Source vorkommt.
     * @param plan Action Plan
     * @param ds Data Source
     */
    predicate_DsInActionPlan(plan:ActionPlan, ds:DataSource) : boolean
}

export class ConcretRelationInfo implements RelationInfo {
    predicate_DsInWorkflow(workflow: Workflow, ds: DataSource): boolean {
        throw new Error("Method not implemented.");
    }
    predicate_DsInActionPlan(plan: ActionPlan, ds: DataSource): boolean {


        // durchsuche alle Actions des Plan, die eine Extract Action sind UND bei denen die Data Source übereinstimmt

        const target_actions = plan.Actions.filter(a => a._type == "com.bion.schedule.ExtractAction");
        const extract_actions = <ScheduleExtractActionFull[]>target_actions;

        return extract_actions.find(action => action.dataSource == ds.id) !== undefined;
    }

}

// class Mutter {
//   constructor(private bionApi: ApiBackendService){}

//     // Ich bin die Mutter, ich weiß, womit meine Tocher spielen darf.

//     typeClass = new DataSourceSchedulePart(this.bionApi);
//     tochter = new RelationComponent<DataSource>();

//     init() {
//         this.tochter.typeClass = this.typeClass;
//     }

// }

export class RelationComponent<T> {

    workflows:Workflow[] = [];
    dataSources:DataSource[] = [];
    actionPlans:ActionPlan[] = [];

    // // bietet mir die Hauptfunktionalität an: Filter auf Scheduler
    // relation_info:RelationInfo;

    // on_ds_click(ds:DataSource) {
    //     // Kalender Update -> zeige mir nur die Action-Pläne, in denen die Data Source vorkommt

    //     const filtered_actionPlans = this.actionPlans.filter(p => this.relation_info.predicate_DsInActionPlan(p, ds));

    // }


    // // Type Class Approach

    // /**
    //  * Ich kann dir sagen, wie du im Scheduler filtern kannst :-)
    //  */
    // typeClass:PartOfSchedule<T>;

    // on_generic_click(obj:T) {

    //     // Wir wissen nicht, wie wir filter können, aber die Typ Klasse weiß es :-)
    //     const filtered_plans = this.actionPlans.filter(plan => this.typeClass.isPartOf(obj, plan));
    // }

}

/**
 * Findet heraus ob das Objekt vom Typ T im Object vom Typ V vorhanden ist oder ein Teil davon.
 */
 export interface PartOfAny<T, V> {
    /**
     * Prädikat für Filter-Funktion. Ich liefere true, wenn das objekt im target vorhanden ist.
     * @param obj Objekt
     * @param targetToSearch Ziel-Objekt zum Durchsuchen
     */
    isPartOf(obj:T, targetToSearch:V) : boolean;
}

export class DataSourceInWorkflow implements PartOfAny<DataSource, WorkflowRepositoryEntry> {
    isPartOf(obj: DataSource, targetToSearch: WorkflowRepositoryEntry): boolean {

        const target_nodes = targetToSearch.workflowData.Nodes.filter(n => n.Engine.Name == "models.etl.PsaInput");

        const psa_configs = target_nodes.map(n => <PsaInputPlugIn.Settings>n.Properties.Configuration);

        return psa_configs.find(config => config.SelectedDataSource?.ID == obj.id) !== undefined;
    }
}

/**
 * Diese Typ-Klassen-Shape sagt, ob das Objekt Teil eines Schedule Action Plans ist.
 *
 * Das Prädikat 'isPartOf' kann somit in einer filter funktion verwendet werden.
 */
 export interface PartOfSchedule<T> {
    /**
     * Prädikat für filter funktion
     * @param obj Das Objekt, nachdem im Plan gesucht werden soll
     * @param plan Der Schedule Plan
     */
    getData(): Observable<any>;
    subscribeToEvents(): Observable<any>;
    buildModel(data: any, objList: T[], obj?: T): any;
    timeStampToEvent(data: any[]): any[];
    buildCalendarOptions(events: any[]): any;
    isPartOf(obj:T, plan:ActionPlan) : boolean;
}

export class DataSourceSchedulePart implements PartOfSchedule<DataSource> {
  constructor(private bionApi: ApiBackendService, private datasourcesService: DatasourcesService, private schedulesService: SchedulesService) {}
  subscribeToEvents(): Observable<DataSource> {
    let selectedObjectObs = this.datasourcesService.selectedDatasourceEmitter;
    return selectedObjectObs
  }
  timeStampToEvent(data: DateTimeStampInfo[]): any[] {

      let new_events = [];

      for (let timestamp of data) {
        for (let date of timestamp.DateObjects) {
          let newTitel: string;
          const actionPlan = Id.assertSet(timestamp.ActionPlan, new Error("No actionplan for this timestampInfo available"));

          newTitel = actionPlan.name;
          const new_date = date.toISOString();
          new_events.push({ title: newTitel, start: new_date });
        }
      }
      return new_events;

  }
    buildCalendarOptions(events: any[]): any {
      let options = {
        initialDate: Date.now(),
        // headerToolbar: {
        //   left: "prev,next today",
        //   center: "title",
        //   // right: "dayGridMonth,timeGridWeek,timeGridDay",
        // },

        editable: false,
        selectable: false,
        selectMirror: true,
        dayMaxEvents: true,

        // eventClick: (e) => {
        // 	this.eventDialog = true;

        // 	this.clickedEvent = e.event;

        // 	this.changedEvent.title = this.clickedEvent.title;
        // 	this.changedEvent.start = this.clickedEvent.start;
        // 	this.changedEvent.end = this.clickedEvent.end;
        // },
        events: events,
      };
      return options
    }
    getData(): Observable<[ScheduleActionPlan[],ScheduleBaseAction[],DateTimeStampInfo[]]> {
      let endDate = new Date(2100, 12, 31);
      let schedulesObs = this.bionApi.getScheduleActionPlan();
      let appointmentsObs = this.bionApi.getScheduleActions();
      let frequenciesObs = this.schedulesService.getFrequencyTimeStamps(endDate);

      let finalRecordsObs = forkJoin(schedulesObs,appointmentsObs,frequenciesObs);

      return finalRecordsObs
    }
    buildModel(data: any, objList: DataSource[], obj?: DataSource): any {
      let timeStamps: DateTimeStampInfo[] = data[2];

      let timeStampsFiltered:DateTimeStampInfo[] = [];

      // Filter Extract Actions only
      for(let stamp of timeStamps) {
        const actionPlan = Id.assertSet(stamp.ActionPlan, new Error("No actionplan for this timestampInfo available"));

        let actions = actionPlan.Actions;
        for(let action of actions) {
          if(action._type === "models.ScheduleExtractActionFullRow") {
            timeStampsFiltered.push(stamp);
          }
        }
      }
      // Filter selected object timestamps
      if(obj) {
        let objFiltered = [];
        for(let timeStampEvent of timeStampsFiltered) {
          const actionPlan = Id.assertSet(timeStampEvent.ActionPlan, new Error("No actionplan for this timestampInfo available"));

          let actions = <ExtractDataSourceAction[]>actionPlan.Actions;
          for(let action of actions) {
            if(action.dataSource === obj.id) {
              objFiltered.push(timeStampEvent);
            }
          }
        }
        console.log(objFiltered);
        timeStampsFiltered = [...objFiltered]
      }

      let timeStampsEvents = this.timeStampToEvent(timeStampsFiltered);
      return this.buildCalendarOptions(timeStampsEvents);
    }

    isPartOf(ds: DataSource, plan: ActionPlan): boolean {
        const target_actions = plan.Actions.filter(a => a._type == "com.bion.schedule.ExtractAction");
        const extract_actions = <ScheduleExtractActionFull[]>target_actions;

        return extract_actions.find(action => action.dataSource == ds.id) !== undefined;
    }

    subs = new SubSink;
}

export class WorkflowSchedulePart implements PartOfSchedule<WorkflowRepositoryEntry> {
  constructor(private bionApi: ApiBackendService, private workflowsService: WorkflowsService, private schedulesService: SchedulesService) {}

  subscribeToEvents(): Observable<WorkflowRepositoryEntry> {
    let selectedObjectObs = this.workflowsService.selectedWorkflowEmitter;
    return selectedObjectObs
  }
  timeStampToEvent(data: DateTimeStampInfo[]): any[] {

      let new_events = [];

      for (let timestamp of data) {
        for (let date of timestamp.DateObjects) {
          let newTitel: string;
          const actionPlan = Id.assertSet(timestamp.ActionPlan, new Error("No actionplan for this timestampInfo available"));
          newTitel = actionPlan.name;
          const new_date = date.toISOString();
          new_events.push({ title: newTitel, start: new_date });
        }
      }
      return new_events;

  }
    buildCalendarOptions(events: any[]): any {
      let options = {
        initialDate: Date.now(),
        // headerToolbar: {
        //   left: "prev,next today",
        //   right: "title",
        //   // right: "dayGridMonth,timeGridWeek,timeGridDay",
        // },

        editable: false,
        selectable: false,
        selectMirror: true,
        dayMaxEvents: true,

        events: events,
      };
      return options
    }
    getData(): Observable<[ScheduleActionPlan[],ScheduleBaseAction[],DateTimeStampInfo[]]> {
      let endDate = new Date(2100, 12, 31);
      let schedulesObs = this.bionApi.getScheduleActionPlan();
      let appointmentsObs = this.bionApi.getScheduleActions();
      let frequenciesObs = this.schedulesService.getFrequencyTimeStamps(endDate);

      let finalRecordsObs = forkJoin(schedulesObs,appointmentsObs,frequenciesObs);

      return finalRecordsObs
    }
    buildModel(data: any, objList: WorkflowRepositoryEntry[], obj?: WorkflowRepositoryEntry): any {
      let timeStamps: DateTimeStampInfo[] = data[2];

      let timeStampsFiltered:DateTimeStampInfo[] = [];

      // Filter Run Actions only
      for(let stamp of timeStamps) {
        const actionPlan = Id.assertSet(stamp.ActionPlan, new Error("No actionplan for this timestampInfo available"));

        let actions = actionPlan.Actions;
        for(let action of actions) {
          if(action._type === "models.ScheduleWorkflowActionFullRow") {
            let runAction = <RunWorkflowAction>action;
            if(obj) {
              if(obj.id === runAction.workflow)  timeStampsFiltered.push(stamp);
            } else {
              timeStampsFiltered.push(stamp);

            }
          }
        }
      }
      // // Filter selected object timestamps
      // if(obj) {
      //   let objFiltered = new Array();
      //   for(let timeStampEvent of timeStampsFiltered) {
      //     let actions = <RunWorkflowAction[]>timeStampEvent.ActionPlan.Actions;
      //     for(let action of actions) {
      //       if(action.workflow === obj.id) {
      //         objFiltered.push(timeStampEvent);
      //       }
      //     }
      //   }
      //   console.log(objFiltered);
      //   timeStampsFiltered = [...objFiltered];
      // }

      let timeStampsEvents = this.timeStampToEvent(timeStampsFiltered);
      return this.buildCalendarOptions(timeStampsEvents);
    }
    isPartOf(obj: WorkflowRepositoryEntry, plan: ActionPlan): boolean {
        const target_actions = plan.Actions.filter(a => a._type == "com.bion.schedule.RunWorkflowAction");
        const extract_actions = <ScheduleWorkflowActionFull[]>target_actions;

        return extract_actions.find(action => action.workflow == obj.id) !== undefined;
    }
    subs = new SubSink;
}

export class DestinationSchedulePart implements PartOfSchedule<DataStore> {
  constructor(private bionApi: ApiBackendService, private dataStoresService: CubesService, private schedulesService: SchedulesService) {}

  subscribeToEvents(): Observable<DataStore> {
    let selectedObjectObs = this.dataStoresService.selectedDataStoreEmitter;
    return selectedObjectObs
  }
  timeStampToEvent(data: DateTimeStampInfo[]): any[] {

      let new_events = [];

      for (let timestamp of data) {
        for (let date of timestamp.DateObjects) {
          let newTitel: string;
          const actionPlan = Id.assertSet(timestamp.ActionPlan, new Error("No actionplan for this timestampInfo available"));

          newTitel = actionPlan.name;
          const new_date = date.toISOString();
          new_events.push({ title: newTitel, start: new_date });
        }
      }
      return new_events;

  }
    buildCalendarOptions(events: any[]): any {
      let options = {
        initialDate: Date.now(),
        editable: false,
        selectable: false,
        selectMirror: true,
        dayMaxEvents: true,
        events: events,
      };
      return options
    }
    getData(): Observable<[ScheduleActionPlan[],ScheduleBaseAction[],DateTimeStampInfo[]]> {
      let endDate = new Date(2100, 12, 31);
      let schedulesObs = this.bionApi.getScheduleActionPlan();
      let appointmentsObs = this.bionApi.getScheduleActions();
      let frequenciesObs = this.schedulesService.getFrequencyTimeStamps(endDate);

      let finalRecordsObs = forkJoin(schedulesObs,appointmentsObs,frequenciesObs);

      return finalRecordsObs
    }
    buildModel(data: any, objList: DataStore[], obj?: DataStore): any {
      let timeStamps: DateTimeStampInfo[] = data[2];

      let timeStampsFiltered:DateTimeStampInfo[] = [];

      // Filter Extract Actions only
      for(let stamp of timeStamps) {
        const actionPlan = Id.assertSet(stamp.ActionPlan, new Error("No actionplan for this timestampInfo available"));

        let actions = actionPlan.Actions;
        for(let action of actions) {
          if(action._type === "models.ScheduleWorkflowActionFullRow") {
            timeStampsFiltered.push(stamp);
          }
        }
      }
      // Filter selected object timestamps
      if(obj) {
        let objFiltered = [];
        for(let timeStampEvent of timeStampsFiltered) {
          const actionPlan = Id.assertSet(timeStampEvent.ActionPlan, new Error("No actionplan for this timestampInfo available"));

          let actions = <RunWorkflowAction[]>actionPlan.Actions;
          for(let action of actions) {
            if(action.workflow === obj.id) {
              objFiltered.push(timeStampEvent);
            }
          }
        }
        console.log(objFiltered);
        timeStampsFiltered = [...objFiltered]
      }

      let timeStampsEvents = this.timeStampToEvent(timeStampsFiltered);
      return this.buildCalendarOptions(timeStampsEvents);
    }
    isPartOf(obj: DataStore, plan: ActionPlan): boolean {
        const target_actions = plan.Actions.filter(a => a._type == "com.bion.schedule.PipeDestinationAction");
        const extract_actions = <ScheduleWorkflowActionFull[]>target_actions;

        return extract_actions.find(action => action.workflow == obj.id) !== undefined;
    }
    subs = new SubSink;

}


export class SchedulePlanSchedulePart implements PartOfSchedule<ScheduleActionPlan> {
  constructor(private bionApi: ApiBackendService, private schedulesService: SchedulesService) {}

  subscribeToEvents(): Observable<ScheduleActionPlan> {
    let selectedObjectObs = this.schedulesService.selectedSchedulePlanEmitter;
    return selectedObjectObs
  }
  timeStampToEvent(data: DateTimeStampInfo[]): any[] {

      let new_events = [];

      for (let timestamp of data) {
        for (let date of timestamp.DateObjects) {
          let newTitel: string;
          const actionPlan = Id.assertSet(timestamp.ActionPlan, new Error("No actionplan for this timestampInfo available"));
          newTitel = actionPlan.name;
          const new_date = date.toISOString();
          new_events.push({ title: newTitel, start: new_date });
        }
      }
      return new_events;

  }
    buildCalendarOptions(events: any[]): any {
      let options = {
        initialDate: Date.now(),
        editable: false,
        selectable: false,
        selectMirror: true,
        dayMaxEvents: true,
        events: events,
      };
      return options
    }
    getData(): Observable<[ScheduleActionPlan[],ScheduleBaseAction[],DateTimeStampInfo[]]> {
      let endDate = new Date(2100, 12, 31);
      let schedulesObs = this.bionApi.getScheduleActionPlan();
      let appointmentsObs = this.bionApi.getScheduleActions();
      let frequenciesObs = this.schedulesService.getFrequencyTimeStamps(endDate);

      let finalRecordsObs = forkJoin(schedulesObs,appointmentsObs,frequenciesObs);

      return finalRecordsObs
    }
    buildModel(data: any, objList: ScheduleActionPlan[], obj?: ScheduleActionPlan): any {
      let timeStamps: DateTimeStampInfo[] = data[2];

      // Filter selected object timestamps
      let timeStampsFiltered:DateTimeStampInfo[] = [];
      if(obj) {
        let objFiltered = [];
        for(let timeStampEvent of timeStamps) {
          let ap = <ActionPlan>timeStampEvent.ActionPlan;
            if(ap.id === obj.id) {
              objFiltered.push(timeStampEvent);
            }
        }
        console.log(objFiltered);
        timeStampsFiltered = [...objFiltered]
      } else {
        timeStampsFiltered = timeStamps;
      }

      let timeStampsEvents = this.timeStampToEvent(timeStampsFiltered);
      return this.buildCalendarOptions(timeStampsEvents);
    }
    isPartOf(obj: ScheduleActionPlan, plan: ActionPlan): boolean {
        const target_actions = plan.Actions;

        return target_actions.find(action => action.actionPlan == obj.id) !== undefined;
    }
    subs = new SubSink;

}




@Component({
  selector: 'app-generic-calendar-widget',
  templateUrl: './generic-calendar-widget.component.html',
  styleUrls: ['./generic-calendar-widget.component.scss']
})
export class GenericCalendarWidgetComponent<T> implements OnInit {
  @Input() typeClass!: PartOfSchedule<T>;
  @Input() objList!: T[];
  options: any;
  appointmentCounts: number = 0;
  subs = new SubSink;
  constructor() { }

  ngOnInit(): void {
  }

  setObjects(typeClass: PartOfSchedule<T>, objList: T[]): Observable<boolean> {
    this.typeClass = typeClass;
    // this.subs.sink = this.typeClass.getData().subscribe((res) => {
    //   console.log(res);
    //   this.options = this.typeClass.buildModel(res,objList);
    //   this.appointmentCounts = this.options['events'].length;
    //   this.subs.sink = this.typeClass.subscribeToEvents().subscribe((obj) => {
    //     this.options = this.typeClass.buildModel(res,objList, obj);
    //     this.appointmentCounts = this.options['events'].length;
    //   })
    // });
    // return of(true)


    return this.typeClass.getData().pipe(map((res) => {
      this.options = this.typeClass.buildModel(res,objList);
      this.appointmentCounts = this.options['events'].length;

      this.subs.sink = this.typeClass.subscribeToEvents().subscribe((obj) => {
        this.options = this.typeClass.buildModel(res,objList, obj);
        this.appointmentCounts = this.options['events'].length;
      })

      return true

    }))
  }

}
