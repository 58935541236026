<app-new-user-dialog></app-new-user-dialog>
<app-invite-user-dialog></app-invite-user-dialog>
<app-delete-user-dialog></app-delete-user-dialog>
<app-change-user-password-dialog></app-change-user-password-dialog>
<app-create-user-role-dialog></app-create-user-role-dialog>
<app-delete-user-role-dialog></app-delete-user-role-dialog>
<app-add-permission-dialog [view]="'Views'"></app-add-permission-dialog>
<app-delete-permission-dialog [view]="'Views'"></app-delete-permission-dialog>
<app-change-permission-dialog [view]="'Views'"></app-change-permission-dialog>

<p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>


<div class="p-grid">
    <div class="p-col-6">
        <app-users-list [users]="users"></app-users-list>
    </div>
    <div class="p-col-6">
        <app-user-roles-list [userRolesView]="userRolesView" [groupRoles]="groupRoles"
            [genericPermissionView]="genericPermissionView"></app-user-roles-list>
    </div>
    <!-- <div class="p-col-12">
        <app-user-permissions-list [permissions]="permissions"></app-user-permissions-list>
    </div> -->
</div>
