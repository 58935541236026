
<div>
    <p-table #dt [value]="calculateInfoList" dataKey="FirstColName" editMode="row" [reorderableColumns]="false" sortMode="single"
        [globalFilterFields]="['FirstColName','Operator','SecondColName', 'NewColumName','NewDataType']">
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="{{'LogHistory.SearchForLog' | translate}}" />
                </span>
                <span class="p-input-icon-right">
                    <button style="float: right" pButton label="{{'Add' | translate}}" icon="fa fa-plus"
                        (click)="onAddEntry()"></button>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width:3rem"></th>
                <th pSortableColumn="FirstColName" tooltipPosition="top"
                    pTooltip="{{'Config.Calculate.Tooltip1' | translate}}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'First Column' | translate}}
                        <p-sortIcon field="FirstColName"></p-sortIcon>
                    </div>
                </th>

                <th tooltipPosition="top" pSortableColumn="Operator"
                    pTooltip="{{'Config.Calculate.Tooltip2' | translate}}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Operator' | translate}}
                        <p-sortIcon field="Operator"></p-sortIcon>
                    </div>
                </th>
                <th tooltipPosition="top" pSortableColumn="SecondColName"
                    pTooltip="{{'Config.Calculate.Tooltip3' | translate}}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Second Column' | translate}}
                        <p-sortIcon field="SecondColName"></p-sortIcon>
                    </div>
                </th>
                <th style="width:3rem"></th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-column let-editing="editing" let-expanded="expanded" let-ri="rowIndex">
            <tr [pEditableRow]="column" pReorderableRowHandle (onChange)="onUpdateSettings(false)"
                [pReorderableRow]="ri" [ngClass]="{'row-accessories': column.hasError.hasLeftError === true || column.hasError.hasRightError === true}">
                <td>
                    <button type="button" pButton pRipple [pRowToggler]="column"
                        class="p-button-text p-button-rounded p-button-plain"
                        [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                </td>
                <!-- <td>
                    <span class="pi pi-bars" pReorderableRowHandle (onChange)="onUpdateSettings(false)"></span>
                </td> -->
                <td>
                    <p-dropdown *ngIf="column.hasError.hasLeftError === false" appendTo="body"  filterBy="FirstColName" [options]="allDsFields" [autoDisplayFirst]="false"
                        [(ngModel)]="column.FirstColName" (onChange)="onUpdateSettings(false)" autoWidth="false"
                        [style]="{'width':'100%'}"></p-dropdown>
                        <p-dropdown *ngIf="column.hasError.hasLeftError === true" appendTo="body"  filterBy="FirstColName" [options]="allDsFields" [autoDisplayFirst]="false"  class="ng-invalid ng-dirty"
                        [(ngModel)]="column.FirstColName" (onChange)="onUpdateSettings(false)" autoWidth="false"
                        [style]="{'width':'100%'}"></p-dropdown>

                </td>
                <td>
                    <p-dropdown appendTo="body" [options]="operators" [autoDisplayFirst]="false" optionLabel="name"
                        [(ngModel)]="column.Operator" (onChange)="onUpdateSettings(false)" autoWidth="false"
                        [style]="{'width':'100%'}"></p-dropdown>

                </td>
                <td>
                    <p-dropdown *ngIf="column.hasError.hasRightError === false" appendTo="body" filterBy="SecondColName" [options]="allDsFields" [autoDisplayFirst]="false"
                        [(ngModel)]="column.SecondColName" (onChange)="onUpdateSettings(false)" autoWidth="false"
                        [style]="{'width':'100%'}"></p-dropdown>
                    <p-dropdown *ngIf="column.hasError.hasRightError === true" appendTo="body" filterBy="SecondColName" [options]="allDsFields" [autoDisplayFirst]="false" class="ng-invalid ng-dirty"
                        [(ngModel)]="column.SecondColName" (onChange)="onUpdateSettings(false)" autoWidth="false"
                        [style]="{'width':'100%'}"></p-dropdown>

                </td>
                <td>
                    <div class="ui-table-buttongroup">
                        <button pButton pRipple type="button" class="p-button-rounded p-button-text ui-button-danger"
                            icon="pi pi-trash" pTooltip="{{'Delete' | translate}}" (click)="onDeleteEntry(ri)"
                            tooltipPosition="bottom"></button>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-column>
            <tr>
                <td colspan="5">
                    <div class="p-3">
                        <div class="p-grid" style="margin-top: 10px">
                            <div class="p-col" tooltipPosition="top"
                                pTooltip="{{'Config.Calculate.Tooltip4' | translate}}">
                                <span class="p-float-label">
                                    <input inputId="newColNameInput" pInputText [(ngModel)]="column.NewColumnName"
                                        (blur)="onUpdateSettings(false)" [style]="{'width':'100%'}"
                                        [ngModelOptions]="{standalone: true}">
                                    <label for="newColNameInput">{{'New Column Name' | translate}}</label>
                                </span>
                            </div>
                            <div class="p-col" tooltipPosition="top"
                                pTooltip="{{'Config.Calculate.Tooltip5' | translate}}">
                                <span class="p-float-label">
                                    <p-dropdown inputId="dtDropdown" appendTo="body" [options]="dataTypesList"
                                        [autoDisplayFirst]="false" optionLabel="name" [(ngModel)]="column.NewDataType"
                                        (onChange)="onUpdateSettings(false)" autoWidth="false"
                                        [style]="{'width':'100%'}">
                                    </p-dropdown>
                                    <label for="dtDropdown">{{'NewDataType' | translate}}</label>
                                </span>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="5">{{'NoEntryFound' | translate}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>