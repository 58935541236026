import { Component, OnInit } from "@angular/core";
import { Id } from "src/app/helper/id";
import { CsvTableExporter } from "src/app/models/api/com/bion/etl/persist/CsvTableExporter";
import { ExcelTableExporter } from "src/app/models/api/com/bion/etl/persist/ExcelTableExporter";
import { WorkflowNodeSettings } from "src/app/models/api/com/bion/etl/Workflow";
import { ExportTypeInfo } from "src/app/models/api/models/export/ExportTypeInfo";
// import { WorkflowNodeSettings } from "src/app/models/workflow.model";
import { DesignerService } from "src/app/services/designer.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { NodeConfigComponentBase } from "../node-config-component-base";

export class ExportFileOutputSettings {
	Artifacts: ExportArtifactSettings[];
	SupportedTypes: ExportTypeInfo<any>[];

	constructor(Artifacts:ExportArtifactSettings[], SupportedTypes: ExportTypeInfo<any>[]) {
		this.Artifacts = Artifacts;
		this.SupportedTypes = SupportedTypes
	}
}

// export interface ExportFormatConfig extends PlayJsonInheritance {
// 	_type: string;
// }

export class ExportArtifactSettings {
	Name: string;
	Config: any;
	constructor(Name: string, Config: any) {
		this.Name = Name;
		this.Config = Config;
	}
}

// export class ExportTypeInfo<C extends any> {
// 	ID: number;
// 	Name: string;
// 	Extension: string;
// 	MimeType: string;
// 	InitialConfig: C;

// 	constructor(ID: number, Name: string, Extension: string,MimeType: string,InitialConfig: C) {
// 		this.ID = ID;
// 		this.Name = Name;
// 		this.Extension = Extension;
// 		this.MimeType = MimeType;
// 		this.InitialConfig = InitialConfig;
// 	}
// }

export class FileDownloaderInfo {
	Name: string;
	Type: string;
	Settings: any;

	constructor(Name: string, Type: string, Settings: any) {
		this.Name = Name;
		this.Type = Type;
		this.Settings = Settings
	}
}

export class FileDownloaderInfoView {
	Name: string;
	//Index: number;
	Type: ExportTypeInfo<any>;
	Settings: any;

	constructor(Name: string, Type: ExportTypeInfo<any>, Settings: any) {
		this.Name = Name;
		//this.Index = Index;
		this.Type = Type;
		this.Settings = Settings
	}
}

// export class GenericInfoSettings<T extends ExportFormatConfig> {
//   GenericSettings: T;
// }

// export class ExcelFormatConfig implements ExportFormatConfig {
// 	SheetName: string;
// 	_type: string;
// }

// export class CsvFormatConfig implements ExportFormatConfig {
// 	Separator: string;
// 	_type: string;
// }

@Component({
	selector: "app-file-downloader-node",
	templateUrl: "./file-downloader-node.component.html",
	styleUrls: ["./file-downloader-node.component.css"],
})
export class FileDownloaderNodeComponent
	extends NodeConfigComponentBase<ExportFileOutputSettings>
	implements OnInit
{
	settingsToView(settings: WorkflowNodeSettings): void {
		// -- Get Configuration
		let currentConfig = <ExportFileOutputSettings>settings;
		// -- Get Input Meta Data
		const meta_infos = this.getCurrentWorkflowNode().Properties.MetaInfo.get(
			this.InputPort
		);
		console.log("FILE DOWNLOAD: Checking Meta Infos of Port: ", meta_infos);

		// --If currentConfig not available (-> new node)
		// --set the default format to export for reach entry
		const defaultExportType = this.fileExportFormats[0];

		if (currentConfig == undefined) {
			this.fileDownloaderList = [];
		} else {
			// -- otherweise load existing config into view
			console.log("FILE DOWNLOAD: Load FilterView from Config...");
			const views = currentConfig.Artifacts.map((a) => {
				return this.ExportArtifactSettingsToFilterInfoView(a,defaultExportType);
			});

			this.fileDownloaderList = views;
		}
	}
	viewToSettings(): ExportFileOutputSettings {
		const newSettings = <ExportFileOutputSettings>{
			...this.getCurrentWorkflowNode().Properties.Configuration,
		};
		const fileDownloaderInfoViews = this.fileDownloaderList;
		// const fileDownloaderInfos = this.filterInfoViewToFilterInfo(fileDownloaderInfoViews);

		// newSettings.Artifacts = fileDownloaderInfos;
		const exportArtifactSettings = this.fileDownloaderList.map((l) => {
			return this.filterInfoViewToExportArtifactSettings(l);
		});
		newSettings.Artifacts = exportArtifactSettings;

		return newSettings;
	}

	onSettingsChanged(settings: ExportFileOutputSettings) {
		throw new Error("Method not implemented.");
	}

	// columnList: FieldInfo[] = [];
	fileDownloaderList: FileDownloaderInfoView[] = [];
	fileExportFormats: ExportTypeInfo<any>[] =  [];

	showSettingsDialog: boolean = false;
	selectedEntry?: FileDownloaderInfoView;
	selectedSettingsType?: string;

	//------------ EXCEL
	ExcelSheetName: string = "";
	//------------ CSV
	CsvSeparator: string ="";

	readonly InputPort = "Input";

	constructor(
		protected workflowService: WorkflowsService,
		protected designerEventService: DesignerService
	) {
		super(workflowService, designerEventService);
	}

	onAddEntry() {
		// -- create new fileDownloadInfo view
		const first_format = this.fileExportFormats[0];
		const settings = { ...first_format.InitialConfig };
		
		let newEntry = new FileDownloaderInfoView("",first_format,settings);


		// newEntry.Name = "";
		// newEntry.Index = this.fileDownloaderList.length + 1;
		// newEntry.Settings = { ...first_format.InitialConfig };
		// newEntry.Type = first_format;

		// -- push new fileDownloadInfoView to FileDownloaderArray
		this.fileDownloaderList.push(newEntry);
        this.onUpdateSettings(false);
		console.log("Current Filter Selected: ", this.fileDownloaderList);
		// -- transform from fileDownloadInfoView to fileDownloadInfos
		//this.filterInfoViewToFilterInfo(this.fileDownloaderList);
	}

	onSelectEntry(index: number) {
		let selectedEntry = this.fileDownloaderList[index];

		this.selectedEntry = selectedEntry;
		this.selectedSettingsType = selectedEntry.Type.Name;
		console.log(this.selectedEntry);
	}

	updateSettings() {
		this.showSettingsDialog = true;

		let entry = this.selectedEntry;
		let entryAssert = Id.assertSet(entry, new Error("No Entry selected, Please select an Entry"));

		console.log("Before loading Settings", entry);

		// TypeCheck einbauen
		if (entryAssert.Type.Name === "Excel") {
			let settings = <ExcelTableExporter.Config>entryAssert.Settings;

			//if(settings.GenericSettings.SheetName === '') return;

			this.ExcelSheetName = settings.SheetName;
		}
		if (entryAssert.Type.Name === "CSV") {
			let settings = <CsvTableExporter.Config>entryAssert.Settings;

			if (settings.Separator === "") return;
			this.CsvSeparator = settings.Separator;
		}
	}

	onSaveSettings(entry: FileDownloaderInfoView) {
		if (entry.Type.Name === "Excel") {
			let settings = <ExcelTableExporter.Config>entry.Settings;
			settings.SheetName = this.ExcelSheetName;
			entry.Settings = { ...settings };
		}
		if (entry.Type.Name === "CSV") {
			let settings = <CsvTableExporter.Config>entry.Settings;
			settings.Separator = this.CsvSeparator;
			entry.Settings = { ...settings };
		}

		console.log(entry);

		this.onCloseSettings();
		this.onUpdateSettings(false);
	}
	onCloseSettings() {
		this.selectedEntry = undefined;
		this.ExcelSheetName = "";
		this.CsvSeparator = "";

		this.showSettingsDialog = false;
	}
	onDeleteEntry(index: number) {
		console.log("Current Downloader Index: ", index);
		this.fileDownloaderList = [
			...this.fileDownloaderList.slice(0, index),
			...this.fileDownloaderList.slice(index + 1),
		];
		this.onUpdateSettings(false);
		console.log("Selected Downloader deleted: ", this.fileDownloaderList);

		// this.messageService.standardToastMessage({  severity : 'warn',
		//                                                 summary : 'Entry deleted',
		//                                                 detail : 'The selected entry was deleted successfully!'});
	}

	ExportArtifactSettingsToFilterInfoView(
		settings: ExportArtifactSettings, defaultType:ExportTypeInfo<any>
	): FileDownloaderInfoView {
		const view = new FileDownloaderInfoView(settings.Name,defaultType,settings.Config); // TODO: wie kriegt ich den Index heraus?

		view.Name = settings.Name;
		//view.Settings = new GenericInfoSettings<any>();
		view.Settings = settings.Config;

		return view;
	}

	filterInfoViewToExportArtifactSettings(
		view: FileDownloaderInfoView
	): ExportArtifactSettings {
		const settings = new ExportArtifactSettings(view.Name,view.Settings);
		// settings.Name = view.Name;
		// settings.Config = view.Settings;

		return settings;
	}

	filterInfoViewToFilterInfo(
		fileDownloaderList: FileDownloaderInfoView[]
	): FileDownloaderInfo[] {
		// -- starting with fileDownloadList from GUI
		console.log(fileDownloaderList);

		// -- create new empty array
		let newFilterInfos: FileDownloaderInfo[] = [];

		// -- Iterate through fileDownloadList and create new FilterInfo Rows for each given entry
		fileDownloaderList.map((entry) => {
			let newSettings = new FileDownloaderInfo(entry.Name,entry.Type.Name,undefined);
			// newSettings.Name = entry.Name;
			// newSettings.Type = entry.Type.Name;
			// newSettings.Settings = undefined;
			newFilterInfos.push(newSettings);
		});
		// // -- create respective DownloadConfig Class
		console.log("Final FileDownloadInfos: ", newFilterInfos);

		return newFilterInfos;
	}

	ngOnInit(): void {
		super.ngOnInit();

		// this.fileExportFormats = [
		//     {name: 'Excel', value: 'excel'},
		//     {name: 'CSV', value: 'csv'},
		// ];

		// -- Init Supported Formats dynamically
		const raw_config = this.getCurrentWorkflowNode().Properties.Configuration;
		const config = <ExportFileOutputSettings>raw_config;
		this.fileExportFormats = config.SupportedTypes;
	}

	ngOnDestroy(): void {}
    onFocusLost(event: FocusEvent) {
        const div_left: boolean = (event.relatedTarget === null);

        console.log("Send from Div: ", div_left);

        if (div_left) {
            //this.onCheckDirtyFlag();
            this.onUpdateSettings(true);
        }
    }
}
