import { EventEmitter, Injectable, Output } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SchedulerStatus } from "../models/api/controllers/scheduler/SchedulerStatus";
import { BasicFrequency, CronFrequency } from "../models/api/models/scheduler/CronFrequency";
import {
	ScheduleActionPlan,
	ScheduleBaseAction,

	TimeStampInfo,
} from "../models/schedule.model";
import { ApiBackendService } from "./api-backend.service";
import { BaseActionActionEvent, BaseFrequencyActionEvent, ScheduleActionEvent } from "../models/dialog-actions.model";

@Injectable({
	providedIn: "root",
})
export class SchedulesService {
	constructor(private api: ApiBackendService) { }

	// GUI EVENT EMITTER------------------------------------------------------------

	@Output() selectedSchedulePlanEmitter =
		new EventEmitter<ScheduleActionPlan>();
	@Output() selectedMenuSchedulePlanEmitter =
		new EventEmitter<ScheduleActionPlan>();

    @Output() schedulesChangedEmitter = new EventEmitter<string>();


	// @Output() newScheduleEmitter = new EventEmitter<ScheduleActionPlan>();
	// @Output() updatedScheduleEmitter = new EventEmitter<ScheduleActionPlan>();
	// @Output() deletedScheduleEmitter = new EventEmitter<ScheduleActionPlan>();
	@Output() runScheduleEmitter = new EventEmitter<ScheduleActionPlan>();

	//@Output() selectedActionEmitter = new EventEmitter<ScheduleBaseAction>();
	//@Output() selectedMenuActionEmitter = new EventEmitter<ScheduleBaseAction>();
    @Output() actionsChangedEmitter = new EventEmitter<string>();

	// @Output() newActionEmitter = new EventEmitter<ScheduleBaseAction>();
	// @Output() deletedActionEmitter = new EventEmitter<ScheduleBaseAction>();
	// @Output() updatedActionEmitter = new EventEmitter<ScheduleBaseAction>();

	@Output() selectedMenuFrequencyEmitter = new EventEmitter<BasicFrequency>();
    @Output() frequenciesChangedEmitter = new EventEmitter<string>();

	// @Output() newFrequencyEmitter = new EventEmitter<BasicFrequency>();
	// @Output() deletedFrequencyEmitter = new EventEmitter<BasicFrequency>();
	// @Output() updatedFrequencyEmitter = new EventEmitter<BasicFrequency>();

	// GUI DIALOG EMITTER ------------------------------------------------------------

	//@Output() displayCreateSchedule = new EventEmitter<boolean>();
	//@Output() displayEditSchedule = new EventEmitter<boolean>();
	//@Output() displayDeleteSchedule = new EventEmitter<boolean>();
	//@Output() displayRunSchedule = new EventEmitter<boolean>();

	// @Output() displayCreateAction = new EventEmitter<boolean>();
	// @Output() displayEditAction = new EventEmitter<boolean>();
	// @Output() displayDeleteAction = new EventEmitter<boolean>();
	//@Output() displayReorderAction = new EventEmitter<boolean>(); //TODO

	// @Output() displayCreateFrequency = new EventEmitter<boolean>();
	// @Output() displayEditFrequency = new EventEmitter<boolean>();
	// @Output() displayDeleteFrequency = new EventEmitter<boolean>();

	@Output() scheduleDialogActionSendEmitter = new EventEmitter<ScheduleActionEvent>();
	@Output() baseActionDialogActionSendEmitter = new EventEmitter<BaseActionActionEvent>();
	@Output() baseFrequencyDialogActionSendEmitter = new EventEmitter<BaseFrequencyActionEvent>();


	// displayCreateScheduleDialog(evt: boolean) {
	// 	console.log("Display CreateDialog", evt);
	// 	this.displayCreateSchedule.emit(evt);
	// 	return evt;
	// }
	// displayEditScheduleDialog(evt: boolean) {
	// 	console.log("Display EditDialog", evt);
	// 	this.displayEditSchedule.emit(evt);
	// 	return evt;
	// }
	// displayDeleteScheduleDialog(evt: boolean) {
	// 	console.log("Display DeleteDialog", evt);
	// 	this.displayDeleteSchedule.emit(evt);
	// 	return evt;
	// }

	// EMITTER SCHEDULE---------------------------------------------
	// emitUpdatedSchedule(sc: ScheduleActionPlan) {
	// 	this.updatedScheduleEmitter.emit(sc);
	// }
	// emitNewSchedule(sc: ScheduleActionPlan) {
	// 	this.newScheduleEmitter.emit(sc);
	// }
	// emitDeletedSchedule(sc: ScheduleActionPlan) {
	// 	this.deletedScheduleEmitter.emit(sc);
	// }
	// EMITTER ACTION---------------------------------------------
	// emitSelectedAction(a: ScheduleBaseAction) {
	// 	this.selectedActionEmitter.emit(a);
	// }
	// emitNewAction(a: ScheduleBaseAction) {
	// 	this.newActionEmitter.emit(a);
	// }
	// emitDeletedAction(a: ScheduleBaseAction) {
	// 	this.deletedActionEmitter.emit(a);
	// }
	// emitUpdatedAction(a: ScheduleBaseAction) {
	// 	this.updatedActionEmitter.emit(a);
	// }
	// EMITTER FREQUENCY---------------------------------------------
	emitMenuSelectedFrequency(a: BasicFrequency) {
		this.selectedMenuFrequencyEmitter.emit(a);
	}
	// emitNewFrequency(a: BasicFrequency) {
	// 	this.newFrequencyEmitter.emit(a);
	// }
	// emitDeletedFrequency(a: BasicFrequency) {
	// 	this.deletedFrequencyEmitter.emit(a);
	// }
	// emitUpdatedFrequency(a: BasicFrequency) {
	// 	this.updatedFrequencyEmitter.emit(a);
	// }

	public getScheduleActionPlan(id?: number): Observable<ScheduleActionPlan[]> {
		return this.api.getScheduleActionPlan(id);
	}
	public createScheduleActionPlan(
		item: ScheduleActionPlan
	): Observable<ScheduleActionPlan> {
		return this.api.createScheduleActionPlan(item);
	}
	public updateScheduleActionPlan(item: ScheduleActionPlan) {
		return this.api.updateScheduleActionPlan(item);
	}
	public deleteScheduleActionPlan(id: number): Observable<ScheduleActionPlan> {
		return this.api.deleteScheduleActionPlan(id);
	}
	public runScheduleActionPlan(id: number): Observable<ScheduleActionPlan> {
		return this.api.runScheduleActionPlan(id);
	}
	

	public getScheduleActions(
		id?: number,
		actionPlan?: number
	): Observable<ScheduleBaseAction[]> {
		return this.api.getScheduleActions(id, actionPlan);
	}
	public createScheduleActions(
		item: ScheduleBaseAction
	): Observable<ScheduleBaseAction> {
		return this.api.createScheduleActions(item);
	}
	public updateScheduleActions(item: ScheduleBaseAction) {
		return this.api.updateScheduleActions(item);
	}
	public deleteScheduleActions(id: number): Observable<number> {
		return this.api.deleteScheduleActions(id);
	}
	public updateActionBatch(items: ScheduleBaseAction[]): Observable<number[]> {
		return this.api.updateActionBatch(items);
	}	

	public getScheduleFrequencies(
		id?: number,
		actionPlan?: number
	): Observable<CronFrequency[]> {
		return this.api.getScheduleFrequencies(id, actionPlan);
	}
	public createScheduleFrequency(
		item: CronFrequency
	): Observable<CronFrequency> {
		return this.api.createScheduleFrequency(item);
	}
	public updateScheduleFrequencies(
		item: CronFrequency
	): Observable<CronFrequency> {
		return this.api.updateScheduleFrequency(item);
	}
	public deleteScheduleFrequency(
		id?: number,
		actionPlan?: number
	): Observable<number> {
		return this.api.deleteScheduleFrequency(id, actionPlan);
	}

	public getFrequencyTimeStamps(
		end: Date,
		id?: number,
		actionPlan?: number,
		start?: Date
	): Observable<DateTimeStampInfo[]> {
		const finalStart = start ? start.toISOString() : undefined;

		return this.api
			.getFrequencyTimeStamps(end.toISOString(), id, actionPlan, finalStart)
			.pipe(
				map((result) => {
					return result.map((s) => new DateTimeStampInfo(s));
					// return result.map((s) => new Date(s));
				})
			);
	}

	public getTimeZones(id?:string) {
		return this.api.getTimeZones(id);
	}

	public getDefaultTimeZone() {
		return this.api.getDefaultTimeZone();
	}

	public getSchedulerStatus(): Observable<SchedulerStatus> {
		return this.api.getSchedulerStatus();
	}
}



export class DateTimeStampInfo extends TimeStampInfo {

	readonly DateObjects: Date[];

	constructor(tsi:TimeStampInfo) {
		super();
		this.Frequency = tsi.Frequency;
		this.ActionPlan = tsi.ActionPlan;
		this.Dates = tsi.Dates;
		this.DateObjects = this.Dates.map(d => new Date(d));
	}
}
