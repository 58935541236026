<div class="p-grid" style="width:100%; margin-top:10px">
    <!-- <div class="p-col-12">
        <p-fileUpload auto="true" multiple="single" showCancelButton="false" customUpload="true" maxFileSize="200000000"
            (onRemove)="onRemoveFile($event)" chooseLabel="Browse" uploadLabel="Preview"
            (uploadHandler)="uploadFilesAsync($event)" accept=".xlsm,.xlx,.xlsx,application/msexcel">
            <ng-template pTemplate="content">
                <ul>
                    <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
                </ul>
            </ng-template>
        </p-fileUpload>
    </div> -->
    <div class="p-col-12 p-md-9">
        <div class="p-fluid">
            <span class="p-float-label">
                <input type="text" id="Pathinputtext" pInputText
                    [(ngModel)]="currentConnectorView.connectorSettings.Uri">
                <label for="Pathinputtext">{{'Path' | translate}}</label>
            </span>
        </div>
    </div>
    <div class="p-col-12 p-md-3">
        <div class="p-fluid">
            <span class="p-float-label">
                <button pButton type="button" id="connect" label="{{'Connect' | translate}}" pInputText
                    (click)="uploadFilesAsync($event)"></button>
            </span>
        </div>
    </div>
    <div class="p-col-12">
        <p-divider layout="horizontal" align="center">
            <span class="p-tag">{{'Choose table' | translate}}</span>
        </p-divider>
    </div>
    <div class="p-col-12 ">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-inputNumber [(ngModel)]="currentConnectorView.connectorSettings.ReadSettings.StartRow" [min]="1" [max]="100"></p-inputNumber>
                <label for="excelSheetStartRow">{{'Start Row' | translate}}</label>
            </span>
        </div>
    </div>
    <div class="p-col-12 ">
        <div class="p-fluid">
            <div class="field-checkbox">
                <p-checkbox id="hasHeaderCheckbox" [(ngModel)]="currentConnectorView.connectorSettings.ReadSettings.HasHeader" [binary]="true"></p-checkbox>
                <label for="hasHeaderCheckbox">{{'Start row has column header' | translate}}</label>
            </div>
        </div>
    </div>


    <div class="p-col-12">
        <p-table #fileListTable [lazy]="true" [columns]="fileListCols" [value]="excelTableList" sortMode="multiple"
            selectionMode="single" (onRowSelect)="onExcelSheetSelected($event)" (onLazyLoad)="excelTableList"
            [loading]="loading" [resizableColumns]="false" [rowHover]="true" [rows]="25" [showCurrentPageReport]="false"
            [rowsPerPageOptions]="[25,50,100]" appendTo="body" [paginator]="false"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">

            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" pResizableColumn [width]="col.width" [pSortableColumn]="col.field">
                        <!-- <p-sortIcon [field]="col.field"></p-sortIcon> -->
                        {{col.header | translate}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData">
                    <td *ngFor="let col of columns; let i = index">
                        {{rowData[col.field]}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="NoRecordsFound" *ngIf="excelTableList.length == 0">
            <p class="NoRecordsFound">
                No sheets available. Upload file to preview</p>
        </div>

    </div>
</div>
