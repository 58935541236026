import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { concatMap } from 'rxjs/operators';
import { GuiErrorCode, GuiErrorException } from 'src/app/models/gui.error.model';
import { SetNewPassword } from 'src/app/models/user.model';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers: [MessageService],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
    subs = new SubSink;
	form = new UntypedFormGroup({
		password: new UntypedFormControl("", [Validators.required]),
        passwordRepeat: new UntypedFormControl("", [Validators.required]),
	});
  isSuccessful: boolean = false;
  resultMessage: string;
  constructor(public route: ActivatedRoute, private bionApi: ApiBackendService, public messageService: MessageService, private errorService: SystemMessageLogService, private router: Router, private translate: TranslateService) {
    translate.addLangs(["en", "de"]);
    let browserLang = translate.getBrowserLang();

    if(browserLang === "de") {
        translate.setDefaultLang(browserLang);
    } else {
        translate.setDefaultLang("en")
    }
   }
    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

  ngOnInit(): void {
  }

  setNewPassword() {
      // Set new Password in BE
    const password = this.form.value.password;
    const passwordRepeat = this.form.value.passwordRepeat;

    if(password !== passwordRepeat) {
      this.errorService.handleError(new GuiErrorException(GuiErrorCode.notMatchPassword));

      //this.errorService.sendErrorCode(GuiErrorCode.notMatchPassword);
      this.resultMessage = "Password could not be changed. Please verify your entry and try again."

      return;
    }

    const NewPasswordObs = this.route.queryParams.pipe(concatMap((params) => {
        let token = params['name'];

        const newSetPasswordObj = new SetNewPassword(token,password, passwordRepeat);
        console.log("newSetPasswordObj",newSetPasswordObj);

        return this.bionApi.setNewPassword(newSetPasswordObj);
    }));



    this.subs.sink = NewPasswordObs.subscribe(() => {
      this.errorService.clearHttpError();
            this.messageService.add({
                severity: "success",
                summary: "Password changed!",
                detail:
                    "Your password was successfully changed. You can now login with your new password.",
            });
            this.isSuccessful = true;
            this.resultMessage = "Your password was changed. You can now login with your new password."

            setTimeout(function(){
              this.router.navigateByUrl("#");
           }, 5000);

          },err =>  {
            this.resultMessage = "Password could not be changed. Please verify your entry and try again."
            this.errorService.handleError(err);
          })

  }
}
