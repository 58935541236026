<div class="p-grid" style="width:100%; margin-top:10px">
    <div class="p-col-12 p-md-9">
        <div class="p-fluid">
            <span class="p-float-label">
                <input type="text" id="Hostinputtext" pInputText
                    [(ngModel)]="currentConnectorView.connectorSettings.Configuration.AsHost">
                <label for="Hostinputtext">{{'Host' | translate}}</label>
            </span>
        </div>
    </div>
    <div class="p-col-12 p-md-3">
        <div class="p-fluid">
            <span class="p-float-label">
                <input type="number" id="Portinputtext" pInputText
                    [(ngModel)]="currentConnectorView.connectorSettings.Configuration.SystemNumber">
                <label for="Portinputtext">{{'System' | translate}}</label>
            </span>
        </div>
    </div>

    <div class="p-col-12 ">
        <div class="p-fluid">
            <span class="p-float-label">
                <input type="text" id="DBinputtext" pInputText
                    [(ngModel)]="currentConnectorView.connectorSettings.Configuration.Client">
                <label for="DBinputtext">{{'Client' | translate}}</label>
            </span>
        </div>
    </div>
    <div class="p-col-12 ">
        <div class="p-fluid">
            <span class="p-float-label">
                <input type="text" id="LanguageInput" pInputText
                    [(ngModel)]="currentConnectorView.connectorSettings.Configuration.Language">
                <label for="LanguageInput">{{'Language' | translate}}</label>
            </span>
        </div>
    </div>
    <div class="p-col-12 p-md-6">
        <div class="p-fluid">
            <span class="p-float-label">
                <input type="text" id="Userinputtext" pInputText
                    [(ngModel)]="currentConnectorView.connectorSettings.Configuration.User">
                <label for="Userinputtext">{{'Username' | translate}}</label>
            </span>
        </div>
    </div>
    <div class="p-col-12 p-md-6">
        <div class="p-fluid">
            <span class="p-float-label">
                <input type="password" id="Passwordinputtext" pInputText
                    [(ngModel)]="currentConnectorView.connectorSettings.Configuration.Password">
                <label for="Passwordinputtext">{{'Password' | translate}}</label>
            </span>
        </div>
    </div>

    <div class="p-col-12 p-md-6">
        <div class="p-fluid">
            <span class="p-float-label">
                <input type="text" id="TableNameInput" pInputText
                    [(ngModel)]="currentConnectorView.connectorSettings.Table">
                <label for="TableNameInput">{{'TableName' | translate}}</label>
            </span>
        </div>
    </div>

    <!-- <div class="p-col-12 p-md-6">
        <div class="p-fluid">
            <span class="p-float-label">
                <input type="text" id="ResultLimitInput" pInputText
                    [(ngModel)]="currentConnectorView.connectorSettings.Configuration.User">
                <label for="ResultLimitInput">{{'Result Limit' | translate}}</label>
            </span>
        </div>
    </div> -->


    <div class="p-col-12 p-md-6">
        <div class="p-fluid">
            <span class="p-float-label">
                <button pButton type="button" id="connect" label="{{'Connect' | translate}}" pInputText
                    (click)="onConnect($event)"></button>
            </span>
        </div>
    </div>

    <div class="p-col-12 p-md-6">
        <div class="p-fluid">
            <span class="p-float-label">
                <input type="text" id="Statusinputtext" [disabled]=true pInputText [(ngModel)]="status_text">
                <label for="Statusinputtext">{{'Status' | translate}}</label>
            </span>
        </div>
    </div>
    <div class="p-col-12  p-md-12">
        <p-divider layout="horizontal" align="center">
            <span class="p-tag">{{'Choose table' | translate}}</span>
        </p-divider>
    </div>

    <div class="p-col-12  p-md-12">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-multiSelect appendTo="body" [options]="currentConnectorView.metaInfo.Fields" optionLabel="FIELDNAME" optionValue="FIELDNAME"
                    [(ngModel)]="currentConnectorView.connectorSettings.Fields" editable="true"></p-multiSelect>
                <label for="inputtext">{{'Table' | translate}}</label>
            </span>
        </div>
    </div>
    <div class="p-col-12 p-md-12">
        <div class="p-fluid">
            <span class="p-float-label">
                <input type="text" id="ClauseInput" pInputText [(ngModel)]="currentConnectorView.connectorSettings.Options">
                <label for="ClauseInput">{{'Clause' | translate}}</label>
            </span>
        </div>
    </div>


</div>
