<!-- <div class="p-grid"> -->
    <div #containerDiv *ngIf="allDsFields" tabindex="-1" >
        <p-table #dt  [value]="allDsFields"[(selection)]="selectedDsFields" (selectionChange)="onUpdateSettings(false)" dataKey="Name" editMode="row" sortMode="single" (onRowReorder)="onUpdateSettings(false)"
        [globalFilterFields]="['Name','NewName','DataType', 'Length','Precision']"
         [scrollable]="false" styleClass="p-datatable-customers p-datatable-sm">
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="{{'LogHistory.SearchForLog' | translate}}" />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <!-- <th style="width: 3rem">
                </th> -->
                <th style="width: 3rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th pSortableColumn="Name" tooltipPosition="top" pTooltip="{{'Config.Select.Tooltip1' | translate}}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Name' | translate}}
                        <p-sortIcon field="Name"></p-sortIcon>
                    </div>
                </th>

                <th pSortableColumn="NewName" tooltipPosition="top" pTooltip="{{'Config.Select.Tooltip2' | translate}}"  style="width: 12rem">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Rename' | translate}}
                        <p-sortIcon field="NewName"></p-sortIcon>
                    </div>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-column let-expanded="expanded" let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="column" [ngClass]="{'row-accessories': column.hasError === true}" [pReorderableRow]="ri" >
                <!-- <td>
                    <button type="button" pButton pRipple [pRowToggler]="column"
                        class="p-button-text p-button-rounded p-button-plain"
                        [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                </td> -->
                <td>
                    <p-tableCheckbox [value]="column" ></p-tableCheckbox>
                </td>
                <td pReorderableRowHandle>
                    <p-cellEditor>
                        <ng-template pTemplate="output">
                            {{column.Name}}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <input pInputText type="text" (blur)="onUpdateSettings(false)" placeholder="Enter new name" [(ngModel)]="column.NewName"
                        [ngModelOptions]="{standalone: true}"  [style]="{'width':'100%'}">
                </td>
            </tr>
        </ng-template>
        <!-- <ng-template pTemplate="rowexpansion" let-column>
            <tr>
                <td colspan="4">
                    <div class="p-3">
                        <div class="p-grid">
                            <div class="p-col" pTooltip="Change the datatype of a column. A datatype change may result in data losses. Make sure to choose the right one">
                                <h6>{{'Datatype' | translate}}</h6>

                                    <p-dropdown appendTo="body" [options]="dataTypesList" [(ngModel)]="column.NewDataType"
                                    optionLabel="Name" dataKey="Name" autoWidth="false" [style]="{'width':'100%'}"
                                    (onChange)="onUpdateSettings(false)"></p-dropdown>

                            </div>
                            <div class="p-col" pTooltip="Length defines the maximum length a string datatype should be.">
                                <h6>{{'Length' | translate}}</h6>

                                    <input pInputText type="number" [(ngModel)]="column.NewLength"  [style]="{'width':'100%'}" (blur)="onUpdateSettings(true)"
                                    [ngModelOptions]="{standalone: true}">

                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </ng-template> -->
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="3">{{'NoEntryFound' | translate}}</td>
            </tr>
        </ng-template>
    </p-table>

        <!-- <p-table [value]="allDsFields" [(selection)]="selectedDsFields" dataKey="Name" editMode="row" [lazy]="true"
            [loading]="loading">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem">
                        <p-tableHeaderCheckbox (click)="onUpdateSettings(true)"></p-tableHeaderCheckbox>
                    </th>
                    <th pTooltip="Current name of a column">Current Name</th>
                    <th pTooltip="The old name will be overwritten by the entered name">New Name</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-column let-editing="editing" let-ri="rowIndex">
                <tr [pEditableRow]="column" [ngClass]="{'row-accessories': column.hasError === true}">
                    <td>
                        <p-tableCheckbox [value]="column" (click)="onUpdateSettings(true)"></p-tableCheckbox>
                    </td>
                    <td>
                        <p-cellEditor>
                            <ng-template pTemplate="output">
                                {{column.Name}}
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td>
                        <input pInputText type="text" placeholder="Enter new name" [(ngModel)]="column.NewName"
                            (blur)="onUpdateSettings(true)" [ngModelOptions]="{standalone: true}">
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5">No settings found.</td>
                </tr>
            </ng-template>
        </p-table> -->
    </div>
<!-- </div> -->
