import { Component, OnInit } from '@angular/core';
import { AdapterTypeInfo } from 'src/app/models/connector.model';
import { DatasourcesService } from 'src/app/services/datasources.service';
import { SubSink } from 'subsink';
import * as dss from "src/app/models/datasource.model";
import { ConnectorView } from 'src/app/models/connectorView.model';
import { CsvConnectorView } from 'src/app/models/connectors/csv-connector-view.model';
import { CsvUrlConnectorView } from 'src/app/models/connectors/csv-url-connector-view.model';
import { ExcelConnectorView } from 'src/app/models/connectors/excel-connector-view.model';
import { ExcelUrlConnectorView } from 'src/app/models/connectors/excel-url-connector-view.model';
import { JdbcConnectorView } from 'src/app/models/connectors/jdbc-connector-view.model';
import { JsonConnectorView } from 'src/app/models/connectors/json-connector-view.model';
import { MeltanooPostgreConnectorView } from 'src/app/models/connectors/meltanoo/postgre-connector-view.model';
import { dataMarketConnectorView as DataMarketConnectorView } from "src/app/models/connectors/datamarket-connector-view.model";
import { PostgreConnectorView, H2EmbeddedConnectorView, HsqlEmbeddedConnectorView, H2ServerConnectorView, HsqlServerConnectorView, MariaDBConnectorView, RedshiftConnectorView, DB2ConnectorView } from 'src/app/models/connectors/postgre-connector-view.model';
import { RestApiConnectorView } from 'src/app/models/connectors/rest-api-connector-view.model';
import { SapConnectorView } from 'src/app/models/connectors/sap-connector-view.model';
import { SpssConnectorView } from 'src/app/models/connectors/spss-connector-view.model';
import { TwitterConnectorView } from 'src/app/models/connectors/twitter-connector-view.model';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { concatMap, map } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { DynamicItemInfo } from 'src/app/models/com-bion-json';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { MessageService } from 'primeng/api';
import { Id } from 'src/app/helper/id';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-connector-settings-dialog',
  templateUrl: './change-connector-settings-dialog.component.html',
  styleUrls: ['./change-connector-settings-dialog.component.scss'],
  providers: [MessageService],
  animations: [		trigger("fade", [
    state("void", style({ opacity: 0 })),
    transition(":enter", [animate(500)]),
    transition(":leave", [animate(500)]),
  ]),]
})
export class ChangeConnectorSettingsDialogComponent implements OnInit {
  displayChangeSettingsDialog: boolean = false;
  buttonDisabled: boolean = false;
  loading: boolean = false;
  headerText: string = "Change Connector Settings";
  isChangeSettingsOnly: boolean = true;
  subs = new SubSink;
  selectedAdapterInfo?: AdapterTypeInfo<any, any>;
  currentConnectorView?: ConnectorView<any, any, any>;
  selectedDs?: dss.DataSource;

  readonly EXCEL_ADAPTER: string = "Excel Adapter";
  readonly EXCEL_URL_ADAPTER: string = "Excel URL Adapter";
  readonly CSV_ADAPTER: string = "CSV Adapter";
  readonly CSV_URL_ADAPTER: string = "CSV URL Adapter";
  readonly JDBC_ADAPTER: string = "JDBC Adapter";
  readonly DATAMARKET_ADAPTER: string = "BION Data Market Adapter";
  readonly JSON_ADAPTER: string = "JSON File Adapter";
  readonly POSTGRE_ADAPTER: string = "PostgreSQL Server";
  readonly H2_EMBEDDED_ADAPTER: string = "H2 Embedded";
  readonly HSQL_EMBEDDED_ADAPTER: string = "HSQL Embedded";
  readonly H2_SERVER_ADAPTER: string = "H2 Server";
  readonly HSQL_SERVER_ADAPTER: string = "HSQL Server";
  readonly DB2_ADAPTER: string = "DB2";
  readonly MARIA_DB_ADAPTER: string = "MariaDB";
  readonly REDSHIFT_ADAPTER: string = "AWS Redshift";
  readonly TWITTER_ADAPTER: string = "Twitter Connector";
  readonly REST_API_ADAPTER: string = "REST API Adapter";
  readonly SPSS_FILE_ADAPTER: string = "SPSS File Adapter";
  readonly SAP_ADAPTER: string = "SAP ERP Adapter";
  readonly MELTANOO_POSTGRE_ADAPTER: string = "PostgresSql Server Singer";

  constructor(private datasourcesService: DatasourcesService, private bionApi: ApiBackendService, private errorService: SystemMessageLogService, private messageService: MessageService, private translate: TranslateService) { }

  ngOnInit(): void {
    const initObs = this.datasourcesService.displayConnectorSettingsDialogEmitter.pipe(concatMap((event: [dss.DataSource, boolean]) => {
      this.displayChangeSettingsDialog = event[1];
      this.selectedDs = event[0];

      return this.initializeDialog(event[0]);
    }));
    this.subs.sink = initObs.subscribe((cView) => {
      this.currentConnectorView = cView;
    },
    (err: Error) => {
      this.errorService.handleError(err);
    }
    )

  }
  initializeDialog(ds: dss.DataSource): Observable<ConnectorView<any, any, any>> {
    let connectorViewObs = this.getConnectorSettings(ds).pipe(concatMap((connectorSettings) => {
      return this.getConnectorAdapterInfo(connectorSettings).pipe(concatMap((adapterInfo) => {

        console.log("current AdapterInfo", adapterInfo);
        const connectorViewObs = this.buildConnectorView(adapterInfo).pipe(map(cView => {
          cView.setInitialSettings(connectorSettings.settings);
          this.currentConnectorView = cView;

          this.selectedAdapterInfo = adapterInfo;


          return cView
        }));

        return connectorViewObs;

      }))
    }));

    return connectorViewObs

  }
  buildConnectorView(adapterInfo: AdapterTypeInfo<any, any>): Observable<ConnectorView<any, any, any>> {
    return of(this.createAdapterViewModel(adapterInfo.Name))

  }
  /**
   * First, get connectorSettings of selected DS (Push Source)
   * @param id 
   */
  getConnectorSettings(ds: dss.DataSource): Observable<dss.DataSourceConnector<any>> {
    return this.datasourcesService.getConnectorsInfo(ds.id).pipe(concatMap(cInfos => {

      if (cInfos.length === 0) {
        return throwError(new Error("There is no adapterInfo for this DataSource"))
      }
      else {
        return of(cInfos[0])
      }

    }))

  }
  getConnectorAdapterInfo(connectorSettings: dss.DataSourceConnector<any>): Observable<AdapterTypeInfo<any, any>> {

    return this.datasourcesService.getAdapterTypeInfo(undefined, connectorSettings.Connector).pipe(concatMap(adapterInfos => {

      if (adapterInfos.length === 0) {
        return throwError(new Error("There is no adapterInfo for this DataSource"))
      }
      else {
        return of(adapterInfos[0])
      }

    }))

  }


  onChangeSettings() { 
    this.loading = true;
    this.buttonDisabled = true;

    const curr_connectorView = Id.assertSet(this.currentConnectorView, new Error("CurrentConnectorView not found, should not happen"));

    let new_settings = {...curr_connectorView.connectorSettings};
    console.log("Settings", new_settings);

    let connectorSettingsArg = new dss.CreateDataSourceConnectorArg();
    const select_ds = Id.assertSet(this.selectedDs, new Error("no Selected DS found, should not happen"));
    connectorSettingsArg.DataSource = select_ds.id;

    const dynamicInfo = new DynamicItemInfo(curr_connectorView.getConnectorID(),new_settings);
    connectorSettingsArg.Info = dynamicInfo;

    console.log("connectorSettingsArg",connectorSettingsArg);
  
    this.subs.sink = this.bionApi.updateCreateDataSourceConnectorArg(connectorSettingsArg).subscribe((res:number) => {

      this.displayChangeSettingsDialog = false;

      this.messageService.add({
        severity: "success",
        summary: this.translate.instant("Message.CreateNewDataSourceSuccess.Title"),
        detail: this.translate.instant("Message.CreateNewDataSourceSuccess.Text"),
      });
    }, (err) => {
      this.errorService.handleError(err);
    }, () => {
      this.loading = false;
      this.buttonDisabled = false;
    })

    
  }
  resetDialog() { }
  createAdapterViewModel(name: string): ConnectorView<any, any, any> {

    if (name === this.EXCEL_ADAPTER) return new ExcelConnectorView(this.bionApi, name);
    if (name === this.EXCEL_URL_ADAPTER) return new ExcelUrlConnectorView(this.bionApi, name);
    if (name === this.CSV_ADAPTER) return new CsvConnectorView(this.bionApi, name);
    if (name === this.CSV_URL_ADAPTER) return new CsvUrlConnectorView(this.bionApi, name);
    if (name === this.JDBC_ADAPTER) return new JdbcConnectorView(this.bionApi, name);
    if (name === this.DATAMARKET_ADAPTER) return new DataMarketConnectorView(this.bionApi, name);
    if (name === this.JSON_ADAPTER) return new JsonConnectorView(this.bionApi, name);
    if (name === this.POSTGRE_ADAPTER) return new PostgreConnectorView(this.bionApi, name);
    if (name === this.H2_EMBEDDED_ADAPTER) return new H2EmbeddedConnectorView(this.bionApi, name);
    if (name === this.HSQL_EMBEDDED_ADAPTER) return new HsqlEmbeddedConnectorView(this.bionApi, name);
    if (name === this.H2_SERVER_ADAPTER) return new H2ServerConnectorView(this.bionApi, name);
    if (name === this.HSQL_SERVER_ADAPTER) return new HsqlServerConnectorView(this.bionApi, name);
    if (name === this.MARIA_DB_ADAPTER) return new MariaDBConnectorView(this.bionApi, name);
    if (name === this.REDSHIFT_ADAPTER) return new RedshiftConnectorView(this.bionApi, name);
    if (name === this.DB2_ADAPTER) return new DB2ConnectorView(this.bionApi, name);
    if (name === this.TWITTER_ADAPTER) return new TwitterConnectorView(this.bionApi, name);
    if (name === this.REST_API_ADAPTER) return new RestApiConnectorView(this.bionApi, name);
    if (name === this.SPSS_FILE_ADAPTER) return new SpssConnectorView(this.bionApi, name);
    if (name === this.MELTANOO_POSTGRE_ADAPTER) return new MeltanooPostgreConnectorView(this.bionApi, name);
    if (name === this.SAP_ADAPTER) return new SapConnectorView(this.bionApi, name);

    throw new Error("No view found for this adapter:" + name);
  }

}
