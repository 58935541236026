
<div class="p-col-12 p-grid">
<div class="p-col-12 p-md-6 p-xl-9">
    <div class="card" style="height: calc(48vh);" *ngIf="currentUser && currentLicenses">


        <p-tabView>
            <p-tabPanel header="{{'MySubscription' | translate}}">
                <div class="main_container">
                    <div *ngIf="currentLicenses.length === 0">{{'No Subscription found' | translate}}</div>
                    <div class="content_block" *ngIf="currentLicenses.length > 0">
                        <span class="p-grid">
                            <div class="p-col-4">
                                {{'CurrentSubscription' | translate}}
                            </div>
                            <div class="p-col-8" style="text-align: right">
                                <p-badge value="{{currentLicenses[0].Product[0].Name}}"></p-badge>
                            </div>
                        </span>
                        <span class="p-grid">
                            <div class="p-col-4">
                                {{'Duration' | translate}}
                            </div>
                            <div class="p-col-8"  style="text-align: right" *ngIf="currentLicenses[0].ValidTo !== undefined">
                                {{currentLicenses[0].ValidFrom | date: 'dd/MM/yyyy' }} - {{currentLicenses[0].ValidTo | date: 'dd/MM/yyyy' }}
                            </div>
                            <div class="p-col-8"  style="text-align: right" *ngIf="currentLicenses[0].ValidTo === undefined">
                                {{currentLicenses[0].ValidFrom | date: 'dd/MM/yyyy' }} - {{'Unlimited' | translate}}
                            </div>
                        </span>
                
                    </div>
                
                    <div class="content_block" *ngIf="currentLimits.length > 0" >
                        <span class="p-grid" *ngFor="let limit of currentLimits">
                            <div class="p-col-4">
                                {{limit.name | translate}}
                            </div>
                            <div class="p-col-8" >
                                <span style="float:left; margin-right:10px">{{limit.current}}</span>                <span style="float:right;margin-left:10px">{{limit.max}}</span>
                                <p-progressBar [value]="limit.percentage"></p-progressBar>
                
                            </div>
                            <div class="p-col-8" *ngIf="!currentLicenses[0].Product[0].MetaData.Data">
                                {{'Unlimited' | translate}}
                            </div>
                        </span>
                    </div>
                
                    <div class="content_block" style="float: right" *ngIf="currentLicenses.length > 0">
                        <h4 *ngIf="currentLicenses[0].IsActive === false" >{{'Your subscription is currently' | translate}} {{ 'inactive' | translate}} </h4>
                        <h4 *ngIf="currentLicenses[0].ValidTo === undefined  && currentLicenses[0].IsActive === true" >{{'Your subscription is currently' | translate}} {{ 'active' | translate}} (unlimited)</h4>
                        <h4 *ngIf="currentLicenses[0].ValidTo !== undefined  && currentLicenses[0].IsActive === true">{{'Your subscription is currently' | translate}} {{ 'active' | translate}}  ({{diffDays}} days remaining)</h4>
                    </div>
                </div>
            </p-tabPanel>
            <!-- <p-tabPanel header="{{'Invoices' | translate}}">
                <div class="main_container">

                </div>
            </p-tabPanel>
            <p-tabPanel header="{{'Settings' | translate}}">
                <div class="main_container">

                </div>
            </p-tabPanel> -->
        </p-tabView>



<!-- 
        <div class="card-header">
            <div class="card-title">
                <h4>{{'MySubscription' | translate}}</h4>
            </div>
        </div> -->


    </div>
</div>


<div class="p-col-12 p-md-6 p-xl-3">
    <div class="card">
        <p-tabView>
            <p-tabPanel header="Pro">
                <div class="subscr_container">
                    <h4 class="subscr_header">{{'You want to unlock automation' | translate}}?</h4>
                    <img style="width:80%" src="assets/layout/images/misc/subscription-pro.svg" alt="sub-pro-img">

                    <div class="subscr_content">
                        <div>
                            <i class="pi pi-plus-circle"></i> {{'More resources' | translate}}
                        </div>
                        <div><i class="pi pi-plus-circle"></i> {{'Schedule Automation unlocked' | translate}}</div>
                    </div>
                    <div class="subscr_footer">
                        <button pButton (click)="requestSubscriptionUpgrade('Professional')">{{'Request upgrade' | translate}}</button>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Enterprise">
                <div class="subscr_container">
                    <h4 class="subscr_header">{{'Want to work as a team?' | translate}}</h4>
                    <img style="width:80%" src="assets/layout/images/misc/subscription-enterprise.svg" alt="sub-enterprise-img">
                    <div class="subscr_content">
                        <div><i class="pi pi-plus-circle"></i> {{'Unlimited users and resources' | translate}}</div>
                        <div><i class="pi pi-plus-circle"></i> {{'Collaboration and team management' | translate}}</div>
                        <div><i class="pi pi-plus-circle"></i> {{'On own dedicated server' | translate}}</div>
                    </div>
                    <div class="subscr_footer">
                        <button pButton (click)="requestSubscriptionUpgrade('Enterprise')">{{'Request upgrade' | translate}}</button>
                    </div>
                </div>
            </p-tabPanel>
            <!-- <p-tabPanel header="Enterprise">
                <div class="subscr_container">
                    <h4 class="subscr_header">Want to work as a team?</h4>
                    <div class="subscr_content">
                        <div><i class="pi pi-plus-circle"></i> Unlimited users and resources</div>
                        <div><i class="pi pi-plus-circle"></i> Collaboration and team management</div>
                    </div>
                    <div class="subscr_footer">
                        <button pButton>Upgrade to Enterprise</button>
                    </div>
                </div>
            </p-tabPanel> -->
        </p-tabView>
    </div>
</div>
</div>