import { Component, Input, OnInit } from '@angular/core';
import { UserInfo } from 'src/app/models/api/models/session/UserInfo';
// import { UserInfo, UserInfoView } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],  styles: [`
  :host ::ng-deep .p-datatable-gridlines p-progressBar {
      width: 100%;
  }

  @media screen and (max-width: 960px) {
      :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
          display: flex;
      }
  }
`],
})
export class UsersListComponent implements OnInit {

  @Input() users: UserInfo[] = [];
  selectedUser?: UserInfo;

  constructor(private userService: UserService) { }

  ngOnInit(): void {
  }


  // onSelectUser(evt) {
  //   let user = <UserInfo>evt.data;
  //   this.userService.emitSelectedUser(user);
  // }
  // onUnselectUser(evt) {
  //     let user = <UserInfo>evt.data;
  //     this.userService.emitSelectedUser(user);
  // }
  onMenuClicked(u:UserInfo) {
      this.userService.selectedMenuUserEmitter.emit(u);
  }


  displayNewUserDialog() {
    this.userService.displayNewUser.emit(true);
  }
  displayEditUserDialog() {
    this.userService.displayEditUser.emit(true);
  }
  displayDeleteUserDialog() {
      this.userService.displayDeleteUser.emit(true);
  }
  displayInviteUserDialog() {
    this.userService.displayInviteUser.emit(true);
  }
  displayChangePasswordDialog() {
    this.userService.displayChangeUserPassword.emit(true);

  }

}
