import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Id } from 'src/app/helper/id';
import { ConsoleLogger, LogLevel } from 'src/app/views/designer/components/workflow-graph/logger';

interface SpecInfo {
  IsPullSource: boolean;
  IsPushSource: boolean;
  InitialExtractArg: any;  // Main Config
  InitialMetaRequestArg: any;
}

interface BionConnectorSpecification {
  ID: number;
  Name: string;
  Version: number;
  Class: string;
  Description: string;
  Category: string;
  Info: SpecInfo
}

interface CreateEventData<C> {
  config:C;
  file:File;
}

@Component({
  selector: 'app-create-config-int-bion',
  templateUrl: './create-config-int-bion.component.html',
  styleUrls: ['./create-config-int-bion.component.scss']
})
export class CreateConfigIntBionComponent implements OnInit {

  constructor() { }

  readonly EXCEL_ADAPTER: string = "Excel Adapter";
  readonly EXCEL_URL_ADAPTER: string = "Excel URL Adapter";
  readonly CSV_ADAPTER: string = "CSV Adapter";
  readonly CSV_URL_ADAPTER: string = "CSV URL Adapter";

  selected_settings_view?: string = "";
  //selected_config?: any = undefined;
  connector_specs?: BionConnectorSpecification = undefined;

  // In deiner TypeScript-Datei
  selectedFileName: string;



  ngOnInit(): void {
  }

  @Input()
  get connectorSpecs(): BionConnectorSpecification { return this.model; }
  set connectorSpecs(m: BionConnectorSpecification) {
    this.loadBionForm(m);
  }

  @Output()
  public onConfigSet: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onFileChanged: EventEmitter<File> = new EventEmitter<File>();

  model?: any;  // Bion Default Config

  loadBionForm(model: BionConnectorSpecification) {

    this.connector_specs = model;
    this.model = model.Info.InitialExtractArg;
    this.selected_settings_view = model.Name;

    console.log("Load Bion Form", model);
    console.warn("Bion Dialog not implemented yet!");
  }

  onConfigChanged(config:any) {
    console.log("Config Changed", config)
  }

  submitClicked() {
    const config = this.model;
    console.log("Final Argument", config);

    this.onCreate(config);
  }

  onCreate(data: any) {
    console.log("Config is valid", data);
    this.onConfigSet.emit(data);
  }

  handle_error(e: Error) {
    // this.messages.push({ severity: 'error', summary: 'Error', detail: e.message });
    // console.log("Error Handler", e);
    // this.setLoading(false);
  }

  /**
   * Datei wurde hochgeladen.
   * Aktuell wird sofort der API Call gestartet, können wir aber noch verzögern
   * @param event Ereignis
   */
  onFileSelected(event: any): void {

    console.log("On file selected",event);

    try {
      const file = event.target.files[0];
      const inputTarget = event.target;
      if (inputTarget.files && inputTarget.files.length > 0) {
        this.selectedFileName = inputTarget.files[0].name;
      } else {
        this.selectedFileName = '';
      }
      this.onFileChanged.emit(file);
      this.uploadFile(file);
    } catch (err) {
      this.handle_error(err);
    }
  }

  onFileSelected_New(event: any): void {

    console.log("On file selected",event);

  
    try {
      const input = event.files;
      const file = input[0];
      const inputTarget = event.target;
      if (inputTarget.files && inputTarget.files.length > 0) {
        this.selectedFileName = inputTarget.files[0].name;
      } else {
        this.selectedFileName = '';
      }
      //const file = event.target.files[0];
      this.onFileChanged.emit(file);
      this.uploadFile(file);
    } catch (err) {
      this.handle_error(err);
    }
  }



  /**
   * Schickt die Datei an die API
   * @param file Datei
   */
  uploadFile(file: File): void {

    console.log("Upload File");
  }

}
