import { forkJoin, Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { WorkflowNode } from "src/app/models/api/com/bion/etl/Workflow";
import { WorkflowRepositoryEntry } from "src/app/models/api/models/workflow/WorkflowRepositoryEntry";
import { DataSource } from "src/app/models/datasource.model";
import { DataStore } from "src/app/models/datastore.model";
import { RunWorkflowAction, ScheduleActionPlan, ScheduleBaseAction } from "src/app/models/schedule.model";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { BottomToolbarProvider, NoDataProviderError, ToolbarResult } from "./bottom-toolbar-provider";


export interface WorkflowToolbarData {
  ScheduleActionPlans: ScheduleActionPlan[];
  ScheduleActions: ScheduleBaseAction[];
  DataSources: DataSource[];
  Workflows: WorkflowRepositoryEntry[];
  DataStores: DataStore[];
}

export class WorkflowToolbarResult implements ToolbarResult {
  PsaInNodes: WorkflowNode[];
  DataStoreOutNodes: WorkflowNode[];
  ScheduleActionPlans: ScheduleActionPlan[] = [];
  Matches: boolean[];

  constructor(PsaInNodes: WorkflowNode[],
    DataStoreOutNodes: WorkflowNode[],
    ScheduleActionPlans: ScheduleActionPlan[],
    Matches: boolean[]) {
    this.PsaInNodes = PsaInNodes;
    this.DataStoreOutNodes = DataStoreOutNodes;
    this.ScheduleActionPlans = ScheduleActionPlans;
    this.Matches = Matches;
  }

  DataSources: DataSource[] = [];
  DataStores: DataStore[] = [];
  ScheduleActions: ScheduleBaseAction[] = [];
  WorkflowNodes: WorkflowNode[] = [];
  Workflows: WorkflowRepositoryEntry[] = [];
}

export class WorkflowBottomToolbar
  implements BottomToolbarProvider<WorkflowRepositoryEntry, never, WorkflowToolbarResult, WorkflowToolbarData> {

  constructor(private bionApi: ApiBackendService, private workflowsService: WorkflowsService) { }
  getPsaData(obj: WorkflowRepositoryEntry) {
    return throwError(new NoDataProviderError("Workflows do not provide data."));
  }

  getData(): Observable<WorkflowToolbarData> {
    const data_sources_obs = this.bionApi.getDataSources();
    const schedulesObs = this.bionApi.getScheduleActionPlan();
    const scheduleActionsObs = this.bionApi.getScheduleActions();
    const workflowsObs = this.bionApi.getWorkflowObjectList();
    const dataStoresObs = this.bionApi.getDataStoreObjectList();

    return forkJoin(schedulesObs, scheduleActionsObs, data_sources_obs, workflowsObs, dataStoresObs).pipe(map(all => {
      return { ScheduleActionPlans: all[0], ScheduleActions: all[1], DataSources: all[2], Workflows: all[3], DataStores: all[4] }
    }))
  }
  subscribeToEmitter(): Observable<WorkflowRepositoryEntry> {
    return this.workflowsService.selectedWorkflowEmitter;
  }
  buildToolbar(data: WorkflowToolbarData, obj?: WorkflowRepositoryEntry): WorkflowToolbarResult {

    let schedules: ScheduleActionPlan[] = data.ScheduleActionPlans;
    let scheduleActions: ScheduleBaseAction[] = data.ScheduleActions;

    let runActions = <RunWorkflowAction[]>scheduleActions.filter((schedule) => {
      return schedule._type === "models.scheduler.RunWorkflowAction"
    });

    let related_schedules: ScheduleActionPlan[] = [];

    if (obj) {
      runActions = runActions.filter((action) => {
        return action.workflow === obj.id
      });
    }
    for (let i of runActions) {
      for (let y of schedules) {
        if (i.actionPlan === y.id) {
          related_schedules.push(y);
        }
      }
    }

    //Find related datasource
    let datasources: DataSource[] = data.DataSources;
    let workflows: WorkflowRepositoryEntry[] = data.Workflows;
    let dataStores: DataStore[] = data.DataStores;
    let psa_in_nodes: WorkflowNode[] = [];
    let data_store_out_nodes: WorkflowNode[] = [];
    if (obj) {
      let wfData = obj.workflowData;
      let wfNodes = wfData?.Nodes;

      let filteredInputNodes = wfNodes.filter((node) => { return node.Name == "PsaInput" });
      let filteredOutputNodes = wfNodes.filter((node) => { return node.Name == "DataStoreOutput" });
      psa_in_nodes = filteredInputNodes;
      data_store_out_nodes = filteredOutputNodes;
    } else {
      for (let wf of workflows) {
        let wfData = wf.workflowData;
        let wfNodes = wfData?.Nodes;

        let filteredInputNodes = wfNodes.filter((node) => { return node.Name == "PsaInput" });
        let filteredOutputNodes = wfNodes.filter((node) => { return node.Name == "DataStoreOutput" });
        if (filteredInputNodes.length > 0) psa_in_nodes = filteredInputNodes;
        if (filteredOutputNodes.length > 0) data_store_out_nodes = filteredOutputNodes;
      }
    }

    let statusSuccess: boolean;
    statusSuccess = !(psa_in_nodes.length === 0 && data_store_out_nodes.length === 0 && related_schedules.length === 0);
    console.log([data_store_out_nodes, psa_in_nodes, statusSuccess]);

    return new WorkflowToolbarResult(psa_in_nodes, data_store_out_nodes, related_schedules, [statusSuccess]);
  }
}