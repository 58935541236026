import { Component, OnInit } from '@angular/core';
import { ConfigComponent } from '../../../create-ab-source/config.component';
import { ComboBoxValue } from '../ui-models';

@Component({
  selector: 'app-hubspot',
  templateUrl: './hubspot.component.html',
  styleUrls: ['./hubspot.component.scss']
})
export class HubspotComponent implements OnInit, ConfigComponent {

  constructor() { 
    this.data = {
      "credentials" : {},
      "start_date" : ""
    };
  }
  data: any;
  connectorSpecs: any;

  // TODO: Hubspot Log-In auf Seite einbinden, damit wir ein refresh token bekommen.
  

  credential_options: ComboBoxValue[] = [
    { name: "OAuth", property: "credentials_title", value: "OAuth Credentials", description: "Name of the credentials" },
    { name: "Private App", property: "credentials_title", value: "Private App Credentials", description: "Name of the credentials set" }
  ];

  credential_options_selected?: ComboBoxValue;

  ngOnInit(): void {
  }

  // oneOfToComboValue(path:string[]) : ComboBoxValue[] {



  // }

  isUpdating:boolean = false;

  // help date: not nice, but may work
  _date:Date = new Date();

  onDateChanged(d:Date) {

    // erwartetes Format: 2023-06-01T00:00:00Z

    const d_str = d.toISOString();
    console.log("OnDateChanged - Date String", d_str);

    this.data["start_date"] = d.toISOString();
  }

  getDate(arg:string) : Date {
    return new Date(arg);
  }

  setDate(d:Date, obj:any, att:string) : void {
    this.isUpdating = true;
    const str = d.toISOString();
    obj[att] = str;
    this.isUpdating = false;
  }

  get formattedDate() : Date {
    return new Date(this.data.start_date);
  }

  set formattedDate(value:Date) {
    this.data.start_date = value.toISOString();
  }



}
