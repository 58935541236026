<p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>


<p-dialog header="{{headerText | translate}}" [(visible)]="displayNewDataStore" modal="modal" showEffect="fade"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '70vw'}" (onHide)="resetForms()">
    <app-system-message-log></app-system-message-log>

    <div class="p-grid">
        <div class="p-col-6 p-ai-center p-jc-center">
            <div class="p-grid card p-shadow-2" style="text-align: center;margin: 10px 40px 0px 20px;height:480px">
                <div class="card-header">
                    <h6 style="color: rgb(170, 170, 170)">{{'NameYourDataStore' | translate}}</h6>
                </div>
                <div class="p-col-12 p-mb-12 p-lg-12 p-mb-lg-0">
                    <div class="p-fluid">
                        <span class="p-float-label">
                            <input type="text" id="dsnameinputtext" pInputText [(ngModel)]="dataStoreName">
                            <label for="dsnameinputtext">{{'DataStoreName' | translate}}</label>
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-mb-2 p-lg-12 p-mb-lg-0">
                    <div class="p-fluid">
                        <span class="p-float-label">
                            <textarea style="min-height:10rem;" id="dsdescriptiontext" [cols]="30" pInputTextarea
                                [(ngModel)]="dataStoreDescription" autoResize="autoResize"></textarea>
                            <label for="dsdescriptiontext">{{'DescriptionOptional' | translate}}</label>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="p-col-6 p-ai-center p-jc-center">
            <div class="card p-shadow-2" style="height:480px;margin-top: 10px">
                <div class="card-header">
                    <h6 style="color: rgb(170, 170, 170)">{{'SelectWriteMode' | translate}}</h6>
                </div>

                <div class="chat-section chat" style="margin-top: 20px">
                    <p-tabView [(activeIndex)]="index" (onChange)="selectWriteMode($event)">
                        <p-tabPanel *ngFor="let mode of writeModes; let i=index" [selected]="i == 0">
                            <ng-template pTemplate="header">
                                <img src="assets/layout/images/{{mode.name}}.svg" width="50" height="50"
                                    alt="freya-layout" />
                                <span class="p-badge">{{mode.name}}</span>
                            </ng-template>
                            <div class="p-col-12 p-mb-12 p-lg-12 p-mb-lg-0" *ngIf="selectedWriteMode">
                                <div [ngSwitch]="selectedWriteMode.name">
                                    <div *ngSwitchCase="'Append'">
                                        <div class="p-grid">
                                            <div class="p-col-12" style="margin: 5px 0">
                                                <i class="fa fa-info-circle"></i>
                                                {{'AppendModeText' | translate}}
                                            </div>
                                            <div class="p-col-12" style="text-align: center;">
                                                <img class="writeModeImg" [src]="'assets/layout/images/Append.svg'"
                                                    width="150" height="150" />
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'Replace'">
                                        <div class=" p-grid">
                                            <div class="p-col-12" style="margin: 5px 0">
                                                <i class="fa fa-info-circle"></i>
                                                {{'ReplaceModeText' | translate}}
                                            </div>
                                            <div class="p-col-12" style="text-align: center;">
                                                <img class="writeModeImg" [src]="'assets/layout/images/Replace.svg'"
                                                    width="150" height="150" />
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'Update'">
                                        <div class=" p-grid">
                                            <div class="p-col-12" style="margin: 5px 0">
                                                <i class="fa fa-info-circle"></i>
                                                {{'DeltaModeText' | translate}}
                                            </div>
                                            <div class="p-col-12" style="text-align: center;">
                                                <img class="writeModeImg" [src]="'assets/layout/images/Update.svg'"
                                                    width="150" height="150" />
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'Delta'">
                                        <div class=" p-grid">
                                            <div class="p-col-12" style="margin: 5px 0">
                                                <i class="fa fa-info-circle"></i>
                                                {{'DeltaModeText' | translate}}
                                            </div>
                                            <div class="p-col-12" style="text-align: center;">
                                                <img class="writeModeImg" [src]="'assets/layout/images/Delta.svg'"
                                                    width="150" height="150" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </p-tabPanel>
                    </p-tabView>
                </div>

            </div>
        </div>
    </div>

    <p-footer>

        <button pButton pRipple type="button" icon="pi pi-times" (click)="displayNewDataStore=false"
            class="p-button-rounded p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'No' | translate}}"></button>
        <button pButton pRipple type="button" icon="pi pi-check" (click)="onValidateForms()"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'Yes' | translate}}" [icon]="savingInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'"></button>
    </p-footer>
</p-dialog>
