<div>
    <p-table #dt [value]="findReplaceList" dataKey="ColumnName" editMode="row" [reorderableColumns]="false"
        sortMode="single"
        [globalFilterFields]="['ColumnName','ReplaceValue','FindValue', 'ReplaceColumn','MatchWholeWord', 'CaseSensitive']">
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                <!-- <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="{{'LogHistory.SearchForLog' | translate}}" />
                </span> -->
                <span class="p-input-icon-right">
                    <button style="float: right" pButton label="{{'Add' | translate}}" icon="fa fa-plus"
                        (click)="onAddEntry()"></button>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <!-- <th style="width:3rem"></th> -->
                <th style="width:3rem"></th>
                <th pSortableColumn="ColumnName" pTooltip="{{'Config.FindReplace.Tooltip1' | translate}}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Name' | translate}}
                        <p-sortIcon field="ColumnName"></p-sortIcon>
                    </div>
                </th>

                <th tooltipPosition="top" pSortableColumn="FindValue"
                    pTooltip="{{'Config.FindReplace.Tooltip2' | translate}}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Find' | translate}}
                        <p-sortIcon field="FindValue"></p-sortIcon>
                    </div>
                </th>
                <th tooltipPosition="top" pSortableColumn="ReplaceValue"
                    pTooltip="{{'Config.FindReplace.Tooltip3' | translate}}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Replace' | translate}}
                        <p-sortIcon field="ReplaceValue"></p-sortIcon>
                    </div>
                </th>
                <!-- <th >Column Name</th>
                    <th  tooltipPosition="top" pTooltip="Enter the text or value you want to find">Find</th>
                    <th  tooltipPosition="top" pTooltip="Enter the texr or value you want to replace it with">Replace with
                    </th> -->
                <!-- <th  style="width:3rem" tooltipPosition="top"
                        pTooltip="Replace existing column. Otherwise a new column will be created instead.">R</th>
                    <th style="width:3rem" tooltipPosition="top" pTooltip="Case Sensitive">C</th>
                    <th style="width:3rem" tooltipPosition="top" pTooltip="Match Whole Word">M</th> -->
                <th style="width:3rem"></th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-column let-editing="editing" let-expanded="expanded" let-ri="rowIndex">
            <tr [pEditableRow]="column" pReorderableRowHandle [pReorderableRow]="ri"
                [ngClass]="{'row-accessories': column.hasError === true}">
                <td>
                    <button type="button" pButton pRipple [pRowToggler]="column"
                        class="p-button-text p-button-rounded p-button-plain"
                        [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                </td>
                <!-- <td>
                        <span class="pi pi-bars" pReorderableRowHandle (onChange)="onUpdateSettings(false)"></span>
                    </td> -->
                <td>
                    <p-dropdown appendTo="body" *ngIf="column.hasError === false" [options]="columnList"
                        (onChange)="onUpdateSettings(true)" [autoDisplayFirst]="false" [(ngModel)]="column.ColumnName"
                        autoWidth="false" [style]="{'width':'100%'}"></p-dropdown>
                    <p-dropdown *ngIf="column.hasError === true" appendTo="body" [options]="columnList"
                        (onChange)="onUpdateSettings(true)" [autoDisplayFirst]="false" [(ngModel)]="column.ColumnName"
                        class="ng-invalid ng-dirty" autoWidth="false" [style]="{'width':'100%'}"></p-dropdown>
                </td>
                <td>
                    <input pInputText [(ngModel)]="column.FindValue" [style]="{'width':'100%'}"
                        (blur)="onUpdateSettings(false)" [ngModelOptions]="{standalone: true}">
                </td>
                <td>
                    <input pInputText [(ngModel)]="column.ReplaceValue" [style]="{'width':'100%'}"
                        (blur)="onUpdateSettings(false)" [ngModelOptions]="{standalone: true}">
                </td>
                <!--  <td>
                        <p-checkbox [(ngModel)]="column.ReplaceColumn" [binary]="true"
                            (onChange)="onUpdateSettings(false)"></p-checkbox>
                    </td>
                    <td>
                        <p-checkbox [(ngModel)]="column.CaseSensitive" [binary]="true"
                            (onChange)="onUpdateSettings(false)"></p-checkbox>
                    </td>
                    <td>
                        <p-checkbox [(ngModel)]="column.MatchWholeWord" [binary]="true"
                            (onChange)="onUpdateSettings(false)"></p-checkbox>
                    </td> -->
                <td>
                    <div class="ui-table-buttongroup">
                        <button pButton pRipple type="button" class="p-button-rounded p-button-text ui-button-danger"
                            icon="pi pi-trash" pTooltip="{{'Delete' | translate}}" (click)="onDeleteEntry(ri)"
                            tooltipPosition="bottom"></button>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-column>
            <tr>
                <td colspan="5">
                    <div class="p-3">
                        <div class="p-grid">
                            <div class="p-col" tooltipPosition="top"
                                pTooltip="Replace existing column. Otherwise a new column will be created instead.">
                                <h6>{{'Replace Old' | translate}}</h6>

                                <p-checkbox [(ngModel)]="column.ReplaceColumn" [binary]="true"
                                    (onChange)="onUpdateSettings(false)"></p-checkbox>

                            </div>
                            <div class="p-col" tooltipPosition="top" pTooltip="Consider case sensitive key search">
                                <h6>{{'Case Sensitive' | translate}}</h6>

                                <p-checkbox [(ngModel)]="column.CaseSensitive" [binary]="true"
                                    (onChange)="onUpdateSettings(false)"></p-checkbox>

                            </div>
                            <div class="p-col" tooltipPosition="top" pTooltip="Should match whole word">
                                <h6>{{'Match Whole Word' | translate}}</h6>

                                <p-checkbox [(ngModel)]="column.MatchWholeWord" [binary]="true"
                                    (onChange)="onUpdateSettings(false)"></p-checkbox>

                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="5">{{'NoEntryFound' | translate}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>