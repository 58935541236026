<div class="p-grid help-page">
    <div class="p-col-12">
        <div class="card help-search">
            <div class="help-search-content">
                <h1>Getting started with Bion</h1>
                <!-- <div class="search-container">
                    <i class="pi pi-search"></i>
                    <input type="text" class="p-inputtext" placeholder="Search" />
                </div> -->
            </div>
        </div>
    </div>

    <div class="p-col-12 p-lg-3">
        <div class="card">
            <h4>Getting started</h4>
            <p-accordion>
                <p-accordionTab header="{{tab.name}}" *ngFor="let tab of introductionContent">
                    <div #content class="contentItem" *ngFor="let item of tab.content">
                        <div class="blog-post">
                            <div class="blog-text" *ngFor="let section of item.textarea">
                                <h5>{{item.title}}</h5>
                                <span>{{section.contentHeader}}</span>
                                <span *ngFor="let paragraph of section.contentText">{{paragraph}}</span>
                            </div>
                            <div class="blog-profile">
                                <!-- <img src="assets/demo/images/avatar/amyelsner.png" alt="diamond-layout" /> -->
                            </div>
                        </div>
                        <!-- <div class="imgContainer">
                            <img class="contentImg" [src]="'assets/layout/images/tutorial/' + item.img" [alt]="item.img" />
                        </div> -->
                    </div>
                </p-accordionTab>
            </p-accordion>

            <h4>FAQ</h4>
            <p-accordion>
                <p-accordionTab header="{{tab.name}}" *ngFor="let tab of faqContent">
                    <div #content class="contentItem" *ngFor="let item of tab.content">
                        <!-- <h6>{{item.title}}</h6>
                        <div class="p-grid">
                            <div class="p-col-12" *ngFor="let section of item.textarea">
                                <p class="sectionHeader" >{{section.contentHeader}}</p>
                                <p *ngFor="let paragraph of section.contentText">{{paragraph}}</p>
                            </div>
                            <div class="p-col-12">
                                <img class="contentImg" [src]="'assets/layout/images/tutorial/' + item.img" [alt]="item.img"/>
                            </div>
                        </div> -->
                        <div class="blog-post">
                            <div class="blog-text" *ngFor="let section of item.textarea">
                                <h5>{{item.title}}</h5>
                                <span>{{section.contentHeader}}</span>
                                <span *ngFor="let paragraph of section.contentText">{{paragraph}}</span>
                            </div>
                            <div class="blog-profile">
                                <!-- <img src="assets/demo/images/avatar/amyelsner.png" alt="diamond-layout" /> -->
                            </div>
                        </div>
                        <!-- <div class="imgContainer">
                            <img class="contentImg" [src]="'assets/layout/images/tutorial/' + item.img" [alt]="item.img" />
                        </div> -->
                    </div>
                </p-accordionTab>
            </p-accordion>
        </div>
    </div>

    <div class="p-col-12 p-lg-9">
        <div class="card" style="text-align: center;">
            <!-- <iframe width="780" height="400" src="https://www.youtube.com/embed/LtlLcNi1vqo" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe> -->
        </div>
        <!-- <div class="card">
            <h4>System Status</h4>
            <p>All services are operational.</p>
            <div class="status-bars">
                <div class="status-bar"></div>
                <div class="status-bar"></div>
                <div class="status-bar"></div>
                <div class="status-bar"></div>
                <div class="status-bar"></div>
                <div class="status-bar"></div>
                <div class="status-bar"></div>
                <div class="status-bar"></div>
                <div class="status-bar status-bar-failure"></div>
                <div class="status-bar"></div>
                <div class="status-bar"></div>
                <div class="status-bar"></div>
                <div class="status-bar"></div>
                <div class="status-bar"></div>
                <div class="status-bar"></div>
                <div class="status-bar"></div>
                <div class="status-bar"></div>
                <div class="status-bar"></div>
                <div class="status-bar"></div>
                <div class="status-bar"></div>
                <div class="status-bar"></div>
                <div class="status-bar"></div>
                <div class="status-bar"></div>
                <div class="status-bar"></div>
                <div class="status-bar"></div>
                <div class="status-bar"></div>
                <div class="status-bar"></div>
                <div class="status-bar"></div>
                <div class="status-bar"></div>
                <div class="status-bar"></div>
            </div>
            <div class="status-bar-footer">
                <span>30 Days Ago</span>
                <span>Today</span>
            </div>
        </div> -->

        <div class="card" *ngFor="let tab of tableOfContent;">

            <!-- <div *ngFor="let tab of tableOfContent">
                <div class="ContentItemHeader">
                    <h3>{{tab.name}}</h3>
                </div>
                <div #content class="contentItem" *ngFor="let item of tab.content">
                    <h2>{{item.title}}</h2>
                    <div class="p-grid">
                        <div class="p-col-12" *ngFor="let section of item.textarea">
                            <p class="sectionHeader">{{section.contentHeader}}</p>
                            <p *ngFor="let paragraph of section.contentText">{{paragraph}}</p>
                        </div>
                        <div class="p-col-12">
                            <img class="contentImg" [src]="'assets/layout/images/' + item.img" [alt]="item.img" />
                        </div>
                    </div>

                </div>
            </div> -->
            <h4>Tutorial ({{tab.name}})</h4>
            <!-- <p>Recent articles from our team.</p> -->
            <div *ngFor="let item of tab.content">
                <div class="blog-post">
                    <div class="blog-text" *ngFor="let section of item.textarea">
                        <h5>{{item.title}}</h5>
                        <span>{{section.contentHeader}}</span>
                        <span *ngFor="let paragraph of section.contentText">{{paragraph}}</span>
                    </div>


                    <div class="blog-profile">
                        <!-- <img src="assets/demo/images/avatar/amyelsner.png" alt="diamond-layout" /> -->
                    </div>
                </div>
                <div class="imgContainer">
                    <img class="contentImg" [src]="'assets/layout/images/tutorial/' + item.img" [alt]="item.img" />
                </div>
                <!--
                <div class="blog-post">
                    <div class="blog-text">
                        <h5>Building Revenue With Confidence</h5>
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                            ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat.</span>
                    </div>
                    <div class="blog-profile">
                        <img src="assets/demo/images/avatar/amyelsner.png" alt="diamond-layout" />
                    </div>
                </div>

                <div class="blog-post">
                    <div class="blog-text">
                        <h5>Latest Marketing Trends</h5>
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                            ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat.</span>
                    </div>
                    <div class="blog-profile">
                        <img src="assets/demo/images/avatar/annafali.png" alt="diamond-layout" />
                    </div>
                </div>

                <div class="blog-post">
                    <div class="blog-text">
                        <h5>How To Reach Your Audience</h5>
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                            ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat.</span>
                    </div>
                    <div class="blog-profile">
                        <img src="assets/demo/images/avatar/stephenshaw.png" alt="diamond-layout" />
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>
