import { Component, Input, OnInit } from '@angular/core';
import { CsvUtil, ParserCharacter } from 'src/app/models/csv-util';
import { CsvUrlDestinationView } from 'src/app/models/destinations/csv-url.model';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';

@Component({
  selector: 'app-csv-uri',
  templateUrl: './csv-uri.component.html',
  styleUrls: ['./csv-uri.component.scss']
})
export class CsvUriComponent implements OnInit {

  loading: boolean = false;
  parseCharaters:Array<ParserCharacter> = CsvUtil.getParserCharaters();
  selectedParserChar?:ParserCharacter;

  @Input() viewModel: CsvUrlDestinationView;

  constructor(private logService:SystemMessageLogService) { 
    // file:///C:/Users/Farin/IdeaProjects/BionPlayDataWarehouse/test/out/csv_dest_test.csv
  }

  ngOnInit(): void {
  }

  uploadFilesAsync(event) {
		this.viewModel.fetchMetaDataObs().subscribe(
			(res) => {

				//this.cd.detectChanges();
			},
			(error: Error) => {
				this.logService.handleError(error);
			},
      () => {
        this.loading = false;
      }
		);
	}

  setUrl(event) {
		console.log(event);
		this.viewModel.connectorSettings.Uri = event;
	}
}