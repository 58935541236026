import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { DynamicItemInfo } from "../com-bion-json";
import { RestApiAccess, RestApiMetaInfo, RestApiMetaAccess, ExtractMetaFromConnectorArg, AuthMode, BearerAuth } from "../connector.model";
import { ConnectorSettingsBuilder, ConnectorViewBase } from "../connectorView.model";


export class RestApiConnectorView extends ConnectorViewBase<RestApiAccess, RestApiMetaInfo, RestApiMetaAccess>{

    constructor(api: ApiBackendService, connectorID: string) {
		super(api, connectorID);
		this.metaInfo = new RestApiMetaInfo("", [], [], []);
        this.metaAccess = new RestApiMetaAccess();
	}

    url:string;

    fetchMetaDataObs(): Observable<RestApiMetaInfo> {

        const dynamic_meta_access_info = new DynamicItemInfo(this.connectorID, this.metaAccess);
        const arg = new ExtractMetaFromConnectorArg();
        arg.ConnectorMetaInfo = dynamic_meta_access_info;
        return this.api.api_extractMetaFromConnector(arg).pipe(map((extractRes) => {

            const meta_data = <RestApiMetaInfo>extractRes.MetaData;

            if(this.authModes === undefined || this.authModes.length == 0) {
                this.authModes = meta_data.AuthModes;
            }
            return meta_data;
        }));
    }
    getSettingsBuilder(): Observable<ConnectorSettingsBuilder<RestApiAccess>> {
        const connector_settings = {...this.connectorSettings};
        connector_settings.AuthMode = this.metaAccess.Preview.AuthMode;
        const builder = new RestApiSettingsBuilder(this.connectorID, connector_settings);
        return of(builder);
    }
    uploadFile(file: File): void {
        throw new Error("Method not implemented.");
    }
    getDataSourceNameSuggestion(): string {
        return this.connectorID;
    }
    isFileBased(): boolean {
        return false;
    }
    getBase64FileData(): string {
        return "";
    }
    setInitialSettings(settings: RestApiAccess): void {
        super.setInitialSettings(settings);
        console.log("Initial Settings");
        if(settings.AuthMode) {
            const am = settings.AuthMode;

            if(am._type === "com.bion.connect.rest.BearerAuth")
                this.bearerAuth = <BearerAuth>am;
        }
    }
    setInitialMetaSettings(settings: RestApiMetaAccess): void{
        super.setInitialMetaSettings(settings);
        console.log("Initial Meta Settings");
    }

    authModes:AuthMode[] = [];
    bearerAuth:BearerAuth;

}

export class RestApiSettingsBuilder implements ConnectorSettingsBuilder<RestApiAccess> {

    constructor(connectorID: string, access:RestApiAccess) {
        this.connectorID = connectorID;
        this.access = access;
    }

    connectorID:string;
    access:RestApiAccess;

    getConnectorId(): string {
        return this.connectorID;
    }
    getConnectorSettings() {
        return {...this.access};
    }
}
