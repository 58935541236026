import { PermissionBase } from "../authorization/PermissionBase";
import { WorkflowAccessible } from "./WorkflowAccessible";

export class WorkflowPermission implements PermissionBase<WorkflowAccessible> {
	ID: number;
	Role: number;
	IsOwner: boolean;
	Accessible: WorkflowAccessible;
	Rights: string[];
	constructor(
		ID: number,
		Role: number,
		IsOwner: boolean,
		Accessible: WorkflowAccessible,
		Rights: string[]
	) {
		if (ID === undefined)
			throw new Error(
				"Class 'WorkflowPermission': Required value 'ID' is undefined"
			);
		if (Role === undefined)
			throw new Error(
				"Class 'WorkflowPermission': Required value 'Role' is undefined"
			);
		if (IsOwner === undefined)
			throw new Error(
				"Class 'WorkflowPermission': Required value 'IsOwner' is undefined"
			);
		if (Accessible === undefined)
			throw new Error(
				"Class 'WorkflowPermission': Required value 'Accessible' is undefined"
			);
		if (Rights === undefined)
			throw new Error(
				"Class 'WorkflowPermission': Required value 'Rights' is undefined"
			);
		this.ID = ID;
		this.Role = Role;
		this.IsOwner = IsOwner;
		this.Accessible = Accessible;
		this.Rights = Rights;
	}
}