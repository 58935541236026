import { Component, OnInit } from "@angular/core";
import { FieldInfo, WorkflowNodeSettings } from "src/app/models/api/com/bion/etl/Workflow";
import {
	UnitConvertSettings,
	UnitConvertInfo,
} from "src/app/models/nodeSettings.model";
// import { FieldInfo, WorkflowNodeSettings } from "src/app/models/workflow.model";
import { DesignerService } from "src/app/services/designer.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { NodeConfigComponentBase } from "../node-config-component-base";

export class UnitConvertInfoView {
	ColumnName: string;
	baseUnit: Unit;
	targetUnits: Unit[];
	targetUnitOptions: Unit[];
	timeStamp?: number;
	hasError?: boolean;

	constructor(ColumnName: string, baseUnit: Unit, targetUnits: Unit[], targetUnitOptions: Unit[],timeStamp?: number, hasError?: boolean) {
		this.ColumnName = ColumnName;
		this.baseUnit = baseUnit;
		this.targetUnits = targetUnits;
		this.targetUnitOptions = targetUnitOptions;
		this.timeStamp = timeStamp;
		this.hasError = hasError;
	}
}

export class Unit {
	UnitName: string;
	UnitValue: string;
	Category?: string;

	constructor(UnitName: string, UnitValue: string, Category?: string) {
		this.UnitName = UnitName;
		this.UnitValue = UnitValue;
		this.Category = Category
	}
}

@Component({
	selector: "app-unit-convert-node",
	templateUrl: "./unit-convert-node.component.html",
	styleUrls: ["./unit-convert-node.component.css"],
})
export class UnitConvertNodeComponent
	extends NodeConfigComponentBase<UnitConvertSettings>
	implements OnInit
{
	settingsToView(settings: WorkflowNodeSettings): void {
		// -- Get Configuration
		let currentConfig = <UnitConvertSettings>settings;

		// -- Get Input Meta Data
		const meta_infos = this.getCurrentWorkflowNode().Properties.MetaInfo.get(
			this.InputPort
		);

		let columnListArray: string[] = [];
		// create SelectInfoViews from DsFields for table
		if (meta_infos != undefined && meta_infos.length > 0) {
			const input_field_infos = meta_infos[0].FieldsInfo;

			columnListArray = input_field_infos.map((fieldInfo) => {
				return fieldInfo.Name;
			});
			this.columnList = columnListArray;
		}
		// --If currentConfig not available (-> new node)
		if (currentConfig == undefined) {
			this.convertList = [];
		} else {
			// -- otherweise load existing config into view
			console.log(
				"UNIT CONVERT: Load FilterView from Config...",
				currentConfig
			);

			currentConfig.UnitConversionInfos.map((info) => {
				if (columnListArray.includes(info.ColumnName)) {
					// -- column still exists, do nothing
				} else {
					// column does not exist anymore, meaning column name currently not included -> include
					columnListArray.push(info.ColumnName);
				}
			});
			this.columnList = columnListArray;
                if(meta_infos !== undefined) {
                    this.convertList = this.convertInfoToConvertInfoView(
                        currentConfig.UnitConversionInfos,
                        meta_infos[0].FieldsInfo
                    );
                }
		}
	}
	viewToSettings(): UnitConvertSettings {
		const newSettings = <UnitConvertSettings>{
			...this.getCurrentWorkflowNode().Properties.Configuration,
		};
		const convertInfoViews = this.convertList;
		const convertInfos = this.convertInfoViewToConvertInfo(convertInfoViews);

		newSettings.UnitConversionInfos = convertInfos;

		return newSettings;
	}
	onSettingsChanged(settings: any) {
		throw new Error("Method not implemented.");
	}

	onAddConverter() {
		// -- create new convertInfo view
		//let newConvertInfo = new UnitConvertInfoView();

		const ColumnName = "";
		const baseUnit = this.baseUnits[0];
		const targetUnits: Unit[] = [];
		const targetUnitOptions: Unit[] = this.targetUnits;
		const hasError = false;

		let newConvertInfo = new UnitConvertInfoView(ColumnName,baseUnit,targetUnits,targetUnitOptions,undefined,hasError);


		// -- push new convertInfo to FilterArray
		this.convertList.push(newConvertInfo);
		// -- transform from convertInfoView to convertInfos
		this.convertInfoViewToConvertInfo(this.convertList);
		this.onUpdateSettings(false);
	}
	onDeleteEntry(index: number) {
		this.convertList = [
			...this.convertList.slice(0, index),
			...this.convertList.slice(index + 1),
		];
		this.onUpdateSettings(false);
	}
	convertInfoToConvertInfoView(
		list: UnitConvertInfo[],
		meta?: FieldInfo[]
	): UnitConvertInfoView[] {
		let newConvertInfosView: UnitConvertInfoView[] = [];

		list.map((entry: UnitConvertInfo) => {
			//let newInfoView = new UnitConvertInfoView();

			const ColumnName = entry.ColumnName;
			let fieldInfoFound = meta?.find((field) => {
				return field.Name === entry.ColumnName;
			});
			const hasError = !fieldInfoFound;

			let baseUnit: Unit = new Unit("","");
			for (let i = 0; i < this.baseUnits.length; i++) {
				if (entry.BaseValue == this.baseUnits[i].UnitValue) {
					baseUnit = this.baseUnits[i];
				}
			}

			let newTargetUnitsArray: Unit[] = [];
			for (let i = 0; i < entry.TargetValue.length; i++) {
				for (let j = 0; j < this.targetUnits.length; j++) {
					if (entry.TargetValue[i] == this.targetUnits[j].UnitValue) {
						newTargetUnitsArray.push(this.targetUnits[j]);
					}
				}
			}
			const targetUnits = newTargetUnitsArray;

			let targetunitFiltered: Unit[] = this.targetUnits.filter((unit) => { return unit.Category === newInfoView.baseUnit.Category});

			const targetUnitOptions = targetunitFiltered;

			let newInfoView = new UnitConvertInfoView(ColumnName,baseUnit,targetUnits,targetUnitOptions,undefined,hasError);


			newConvertInfosView.push(newInfoView);
		});
		// -- return final view to GUI
		return newConvertInfosView;
	}
	convertInfoViewToConvertInfo(convertList: UnitConvertInfoView[]) {
		// -- starting with convertList from GUI

		// -- create new empty array
		let newConvertInfo: UnitConvertInfo[] = [];

		// -- Iterate through convertList and create new FilterInfo Rows for each given entry
		convertList.map((entry: UnitConvertInfoView) => {
			let newSettings = new UnitConvertInfo();
			newSettings.ColumnName = entry.ColumnName;
			newSettings.BaseValue = entry.baseUnit.UnitValue;

			let newTargetArray: string[] = [];
			entry.targetUnits.map((unit) => {
				newTargetArray.push(unit.UnitValue);
			});
			newSettings.TargetValue = newTargetArray;
			newConvertInfo.push(newSettings);
		});

		return newConvertInfo;
	}

	readonly InputPort = "Input";

	// Final Table Object
	convertList: UnitConvertInfoView[] = [];

	// DropDowns
	columnList: string[] = [];

	baseUnits: Unit[];
	selectedBaseCurrency?: Unit;

	targetUnits: Unit[];
	selectedTargetCurrencies?: Unit;

	constructor(
		protected workflowService: WorkflowsService,
		protected designerEventService: DesignerService
	) {
		super(workflowService, designerEventService);
		this.baseUnits = [
			{ UnitName: "kilogram", UnitValue: "kg", Category: "weight" },
			{ UnitName: "gram", UnitValue: "g", Category: "weight" },
			{ UnitName: "milligram", UnitValue: "mg", Category: "weight" },
			{ UnitName: "pound", UnitValue: "lb", Category: "weight" },
			{ UnitName: "ounce", UnitValue: "oz", Category: "weight" },
			{ UnitName: "carat", UnitValue: "ct", Category: "weight" },
			{ UnitName: "meter", UnitValue: "m", Category: "lenght" },
			{ UnitName: "kilometer", UnitValue: "km", Category: "lenght" },
			{ UnitName: "centimeter", UnitValue: "cm", Category: "lenght" },
			{ UnitName: "millimeter", UnitValue: "mm", Category: "lenght" },
			{ UnitName: "micrometer", UnitValue: "um", Category: "lenght" },
			{ UnitName: "nanometer", UnitValue: "nm", Category: "lenght" },
			{ UnitName: "mile", UnitValue: "mi", Category: "lenght" },
			{ UnitName: "yard", UnitValue: "yd", Category: "lenght" },
			{ UnitName: "foot", UnitValue: "ft", Category: "lenght" },
			{ UnitName: "inch", UnitValue: "in", Category: "lenght" },
			{ UnitName: "lightyear", UnitValue: "ly", Category: "lenght" },
			{ UnitName: "hectare", UnitValue: "ha", Category: "area" },
			{ UnitName: "square inch", UnitValue: "sq_in", Category: "area" },
			{ UnitName: "square foot", UnitValue: "sq_ft", Category: "area" },
			{ UnitName: "square yard", UnitValue: "sq_yd", Category: "area" },
			{ UnitName: "square mile", UnitValue: "sq_mi", Category: "area" },
			{ UnitName: "liter", UnitValue: "l", Category: "volume" },
			{ UnitName: "milliliter", UnitValue: "ml", Category: "volume" },
			{ UnitName: "cubic meter", UnitValue: "cu_m", Category: "volume" },
			{ UnitName: "cubic centimeter", UnitValue: "cc", Category: "volume" },
			{ UnitName: "cubic inch", UnitValue: "cu_in", Category: "volume" },
			{ UnitName: "cubic foot", UnitValue: "cu_ft", Category: "volume" },
			{ UnitName: "cubic yard", UnitValue: "cu_yd", Category: "volume" },
			{ UnitName: "US liquid gallon", UnitValue: "gal", Category: "volume" },
			{ UnitName: "US liquid quart", UnitValue: "qt", Category: "volume" },
			{ UnitName: "US pint", UnitValue: "pt", Category: "volume" },
			{ UnitName: "US liquid cup", UnitValue: "cp", Category: "volume" },
			{ UnitName: "US liquid ounce", UnitValue: "floz", Category: "volume" },
			{ UnitName: "US tablespoon", UnitValue: "tbsp", Category: "volume" },
			{ UnitName: "US teaspoon", UnitValue: "tsp", Category: "volume" },
			{
				UnitName: "imperial gallon",
				UnitValue: "imperial_gal",
				Category: "volume",
			},
			{
				UnitName: "imperial quart",
				UnitValue: "imperial_qt",
				Category: "volume",
			},
			{
				UnitName: "imperial pint",
				UnitValue: "imperial_pt",
				Category: "volume",
			},
			{
				UnitName: "imperial fluid ounce",
				UnitValue: "imperial_floz",
				Category: "volume",
			},
			{ UnitName: "celsius", UnitValue: "degC", Category: "temperature" },
			{ UnitName: "kelvin", UnitValue: "degK", Category: "temperature" },
			{ UnitName: "fahrenheit", UnitValue: "degF", Category: "temperature" },
			{ UnitName: "second", UnitValue: "sec", Category: "time" },
			{ UnitName: "minute", UnitValue: "min", Category: "time" },
			{ UnitName: "hour", UnitValue: "hr", Category: "time" },
			{ UnitName: "day", UnitValue: "d", Category: "time" },
			{ UnitName: "week", UnitValue: "wk", Category: "time" },
			{ UnitName: "month", UnitValue: "mnth", Category: "time" },
			{ UnitName: "year", UnitValue: "yr", Category: "time" },
		];
		this.targetUnits = [
			{ UnitName: "kilogram", UnitValue: "kg", Category: "weight" },
			{ UnitName: "gram", UnitValue: "g", Category: "weight" },
			{ UnitName: "milligram", UnitValue: "mg", Category: "weight" },
			{ UnitName: "pound", UnitValue: "lb", Category: "weight" },
			{ UnitName: "ounce", UnitValue: "oz", Category: "weight" },
			{ UnitName: "carat", UnitValue: "ct", Category: "weight" },
			{ UnitName: "meter", UnitValue: "m", Category: "lenght" },
			{ UnitName: "kilometer", UnitValue: "km", Category: "lenght" },
			{ UnitName: "centimeter", UnitValue: "cm", Category: "lenght" },
			{ UnitName: "millimeter", UnitValue: "mm", Category: "lenght" },
			{ UnitName: "micrometer", UnitValue: "um", Category: "lenght" },
			{ UnitName: "nanometer", UnitValue: "nm", Category: "lenght" },
			{ UnitName: "mile", UnitValue: "mi", Category: "lenght" },
			{ UnitName: "yard", UnitValue: "yd", Category: "lenght" },
			{ UnitName: "foot", UnitValue: "ft", Category: "lenght" },
			{ UnitName: "inch", UnitValue: "in", Category: "lenght" },
			{ UnitName: "lightyear", UnitValue: "ly", Category: "lenght" },
			{ UnitName: "hectare", UnitValue: "ha", Category: "area" },
			{ UnitName: "square inch", UnitValue: "sq_in", Category: "area" },
			{ UnitName: "square foot", UnitValue: "sq_ft", Category: "area" },
			{ UnitName: "square yard", UnitValue: "sq_yd", Category: "area" },
			{ UnitName: "square mile", UnitValue: "sq_mi", Category: "area" },
			{ UnitName: "liter", UnitValue: "l", Category: "volume" },
			{ UnitName: "milliliter", UnitValue: "ml", Category: "volume" },
			{ UnitName: "cubic meter", UnitValue: "cu_m", Category: "volume" },
			{ UnitName: "cubic centimeter", UnitValue: "cc", Category: "volume" },
			{ UnitName: "cubic inch", UnitValue: "cu_in", Category: "volume" },
			{ UnitName: "cubic foot", UnitValue: "cu_ft", Category: "volume" },
			{ UnitName: "cubic yard", UnitValue: "cu_yd", Category: "volume" },
			{ UnitName: "US liquid gallon", UnitValue: "gal", Category: "volume" },
			{ UnitName: "US liquid quart", UnitValue: "qt", Category: "volume" },
			{ UnitName: "US pint", UnitValue: "pt", Category: "volume" },
			{ UnitName: "US liquid cup", UnitValue: "cp", Category: "volume" },
			{ UnitName: "US liquid ounce", UnitValue: "floz", Category: "volume" },
			{ UnitName: "US tablespoon", UnitValue: "tbsp", Category: "volume" },
			{ UnitName: "US teaspoon", UnitValue: "tsp", Category: "volume" },
			{
				UnitName: "imperial gallon",
				UnitValue: "imperial_gal",
				Category: "volume",
			},
			{
				UnitName: "imperial quart",
				UnitValue: "imperial_qt",
				Category: "volume",
			},
			{
				UnitName: "imperial pint",
				UnitValue: "imperial_pt",
				Category: "volume",
			},
			{
				UnitName: "imperial fluid ounce",
				UnitValue: "imperial_floz",
				Category: "volume",
			},
			{ UnitName: "celsius", UnitValue: "degC", Category: "temperature" },
			{ UnitName: "kelvin", UnitValue: "degK", Category: "temperature" },
			{ UnitName: "fahrenheit", UnitValue: "degF", Category: "temperature" },
			{ UnitName: "second", UnitValue: "sec", Category: "time" },
			{ UnitName: "minute", UnitValue: "min", Category: "time" },
			{ UnitName: "hour", UnitValue: "hr", Category: "time" },
			{ UnitName: "day", UnitValue: "d", Category: "time" },
			{ UnitName: "week", UnitValue: "wk", Category: "time" },
			{ UnitName: "month", UnitValue: "mnth", Category: "time" },
			{ UnitName: "year", UnitValue: "yr", Category: "time" },
		];
	}

	ngOnInit(): void {
		super.ngOnInit();
	}
    onFocusLost(event: FocusEvent) {
        const div_left: boolean = (event.relatedTarget === null);

        console.log("Send from Div: ", div_left);

        if (div_left) {
            //this.onCheckDirtyFlag();
            this.onUpdateSettings(true);
        }
    }

}
