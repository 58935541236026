import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { map } from "rxjs/operators";
import { DropDownEvents } from "src/app/helper/events";
import { Id } from "src/app/helper/id";
import { ExportFormatInfo } from "src/app/models/api/models/ExportModel";
import { DataProviderIdentifier, DataStoreIdentifier } from "src/app/models/api/models/staging/DataProviderIdentifier";
import { DataProviderInfo } from "src/app/models/api/models/staging/DataProviderInfo";
import { DataSegment } from "src/app/models/api/models/staging/DataSegment";
import { ExportDataConfig } from "src/app/models/api/models/staging/ExportDataConfig";
import { DataStore } from "src/app/models/datastore.model";
import { ExportResult } from "src/app/models/staging.model";
import { CubesService } from "src/app/services/cubes.service";
import { DatasourcesService } from "src/app/services/datasources.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";

@Component({
	selector: "app-export-datastore-dialog",
	templateUrl: "./export-datastore-dialog.component.html",
	styleUrls: ["./export-datastore-dialog.component.scss"],
	providers: [MessageService]
})
export class ExportDatastoreDialogComponent implements OnInit {
	displayExportDataStore: boolean = false;
	exportFormatInfos: ExportFormatInfo<any>[] = [];
	dataProviders: DataProviderInfo<DataProviderIdentifier>[] = [];

	dataStoreDataProvider?: DataProviderInfo<DataProviderIdentifier>;

	selectedExportFormatInfo?: ExportFormatInfo<any>;
	currentSelectedDataStore?: DataStore;

	constructor(
		private datasourceService: DatasourcesService,
		private messageService: MessageService,
		private errorService: SystemMessageLogService,
		private translate: TranslateService
	) { }

	ngOnInit(): void {
	}

	selectExportFormat(evt: DropDownEvents.OnChange<any, ExportFormatInfo<any>>) {
		// Format Type Check with ID => 1 = excel, 2 = csv ....
		this.selectedExportFormatInfo = <ExportFormatInfo<any>>(
			evt.value
		);
	}

	onDownloadFile(info?: ExportFormatInfo<any>) {
		try {
			this.downloadFile(info);
		} catch (e) {
			console.log(e);
			const err: Error = <Error>e;
			alert(err.message);
		}
	}

	downloadFile(info?: ExportFormatInfo<any>) {
		const format_info = Id.assertSet(info, new Error("Export Format Info not set"));

		const provider = Id.assertSet(this.dataStoreDataProvider, new Error("Data Provider not set!"));

		const identifier = <DataStoreIdentifier>{
			...provider.IdentifierExample,
		};

		const selected_data_store = Id.assertSet(this.currentSelectedDataStore, new Error("No Data Store selected"));

		identifier.ID = selected_data_store.id;
		// config.DataProvider = identifier;
		// config.DataSegment = new DataSegment();

		const config = new ExportDataConfig(identifier, new DataSegment(), format_info.InitialConfig);


		// Die neue Funktion nutzt eine Chunked Response (stream ähnlich), damit es zu einem Timeout oder Überlauf
		// bei großen Daten kommt. Die Alte Funktion ist erhalten, einfach das Flag unten 'use_chunked_stream' auf
		// 'false' setzen.

		const use_chunked_stream = true;
		const export_via_rest = this.datasourceService.exportDataProvider(config).pipe(map(res => res.Bytes));
		const export_via_stream = this.datasourceService.exportDataProviderStream(config);

		const exportDataProviderObs = use_chunked_stream ? export_via_stream : export_via_rest;

		exportDataProviderObs.subscribe(res => {
			console.log("Data Export Result from API: ", res);
			console.log("Data Export Result from API");

			// Prepare download for Blob

			//const byte_array = res.Bytes;
			const byte_array = res;
			const u_int_array = new Uint8Array(byte_array);
			const byteBlob = new Blob([u_int_array]);

			const target_blob = byteBlob;

			const data = window.URL.createObjectURL(target_blob);

			const link = document.createElement("a");
			link.href = data;
			link.download = format_info.Name + "." + format_info.Extension;

			// this is necessary as link.click() does not work on the latest firefox
			link.dispatchEvent(
				new MouseEvent("click", {
					bubbles: true,
					cancelable: true,
					view: window,
				})
			);

			this.selectedExportFormatInfo = undefined;
			this.displayExportDataStore = false;

			this.messageService.add({
				severity: "success",
				summary: this.translate.instant("Message.ExportDataStoreSuccess.Title"),
				detail: this.translate.instant("Message.ExportDataStoreSuccess.Text1") + selected_data_store.id +
				this.translate.instant("Message.ExportDataStoreSuccess.Text2")+
				format_info.Extension,
				//summary: "Datastore exported!",
				//detail:
				//	"Datastore with id " +
				//	selected_data_store.id +
				//	" was successfully exported as " +
				//	format_info.Extension,
			});
		},
			(err: Error) => {
				this.errorService.handleError(err);
			});

	}
}
