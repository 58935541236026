import { Component, OnDestroy, OnInit } from "@angular/core";
import { throwError } from "rxjs";
import { GroupRole } from "src/app/models/api/models/authorization/GroupRoleLike";
import { UserRole } from "src/app/models/api/models/authorization/UserRoleLIke";
import { UserInfo } from "src/app/models/api/models/session/UserInfo";
import { UserRoleView } from "src/app/models/auth-service-decorator.model";

// import { GroupRole, UserRole } from "src/app/models/authorization.model";
// import { UserInfo } from "src/app/models/user.model";
import { AuthorizationService } from "src/app/services/authorization.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { UserService } from "src/app/services/user.service";
import { SubSink } from "subsink";

@Component({
	selector: "app-delete-user-role-dialog",
	templateUrl: "./delete-user-role-dialog.component.html",
	styleUrls: ["./delete-user-role-dialog.component.scss"],
})
export class DeleteUserRoleDialogComponent implements OnInit, OnDestroy {
	subs = new SubSink();
	savingInProgress: boolean = false;
	displayDeleteUserRole: boolean = false;
	selectedRole?: UserRole | GroupRole;
	selectedUserInfo?: UserInfo;

	constructor(
		private userService: UserService,
		private authService: AuthorizationService,
		private errorService: SystemMessageLogService
	) {}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
		this.selectedRole = undefined;
		this.selectedUserInfo = undefined;
	}

	ngOnInit(): void {
		this.subs.sink = this.userService.displayDeleteUserRole.subscribe(
			(res: boolean) => {
				this.displayDeleteUserRole = res;
			},
			(err: Error) => {
				this.errorService.handleError(err);
			}
		);
		this.subs.sink = this.userService.selectedMenuUserRoleEmitter.subscribe(
			(res: UserRoleView) => {
				this.selectedUserInfo = res.userInfo;
				this.selectedRole = res.RightHolder;
			},
			(err: Error) => {
				this.errorService.handleError(err);
			}
		);
		this.subs.sink = this.userService.selectedMenuGroupRoleEmitter.subscribe(
			(res: GroupRole) => {
				this.selectedUserInfo = undefined;
				this.selectedRole = res;
			},
			(err: Error) => {
				this.errorService.handleError(err);
			}
		);
	}

	deleteRole() {
		this.savingInProgress = true;

		const obs = this.selectedRole?.ID ? this.authService.deleteRole(this.selectedRole.ID) : throwError(new Error("No Role selected."));

		this.subs.sink = obs.subscribe(deleted_items => {
			this.authService.userRolesChangedEmitter.emit(
				<UserRole>this.selectedRole
			);
			this.authService.groupRolesChangedEmitter.emit(
				<GroupRole>this.selectedRole
			);
			this.displayDeleteUserRole = false;
		},
		(err: Error) => {
			this.errorService.handleError(err);
		}, () => {
			this.savingInProgress = false;
		});
	}
	resetCurrentForm() {
		this.selectedRole = undefined;
		this.selectedUserInfo = undefined;
	}
}
