import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { Sources } from 'src/app/models/airbyte-api-structures';
import { AirbyteModel } from 'src/app/models/api/models/AirbyteModel';
import { AirbyteStagingService } from 'src/app/services/airbyte-staging.service';

@Component({
  selector: 'app-view-ab-source',
  templateUrl: './view-ab-source.component.html',
  styleUrls: ['./view-ab-source.component.scss']
})
export class ViewAbSourceComponent implements OnInit {

  constructor(private route: ActivatedRoute, private airbyteService: AirbyteStagingService) { }

  ngOnInit(): void {


    // We retrieve the Bion Source Info id via the URL
    // https://angular.io/guide/router
    // {path: 'item/:id', component: SomeItemComponent}

    // -- Diese Anleitung ist viel besser als die degenerierte von ANgular selbst.
    // https://www.samjulien.com/how-to-use-route-parameters-in-angular


    // App Routing: { path: "designer/:id", component: DesignerViewComponent }
    this.id = this.route.snapshot.paramMap.get('id');
    console.log("Source Info ID: " + this.id);

    if(this.id)
      this.sourceInfoToView(this.id);
    else
      this.handleError(new Error("No Bion Source Id provided in route."));
  }

  id?: string;
  sourceInfo?: AirbyteModel.SourceInfo<Sources.Source>;
  isLoading: boolean = false;
  progressMode: string = "determinate";

  /**
   * Loads the source connected to the given source info id.
   * @param id The source info id.
   */
  sourceInfoToView(id: string) {
    // read the url parameter and load the source

    // http://localhost:8000/api/v1/sources/get

    // {
    //   "sourceId": "92a5efc6-fb90-428a-a2e2-1e8b029cf47c"
    // }

    const _id = parseInt(id);
    const source_info_obs = this.getSourceInfo(_id);

    this.setLoading(true);

    source_info_obs.subscribe(
      source_info => this.handleResult(source_info),
      err => this.handleError(err),
      () => { this.setLoading(false) }
    )
  }

  /**
   * Get the single source info for this id.
   * 
   * CAUTION: This is not the Airbyte Source Id, it's the Bion Source Info id.
   * @param id Source info id
   * @returns 
   */
  getSourceInfo(id: number): Observable<AirbyteModel.SourceInfo<Sources.Source>> {
    return this.airbyteService.sourceInfos(id, undefined, true).pipe(concatMap(infos => {

      if (infos.length == 0) {
        return throwError(new Error("There is no source info with id " + id));
      }
      if (infos.length > 1) {
        return throwError(new Error("There are multiple source infos with id " + id))
      }

      const info = infos[0];
      return of(info);

    }));
  }

  handleError(err: Error) {
    this.setLoading(false);
    console.log("Get Source Info failed", err);
  }

  handleResult(info: AirbyteModel.SourceInfo<Sources.Source>) {
    console.log("Source Info Received", info);
    this.sourceInfo?.source?.name;
    this.sourceInfo?.id

    this.sourceInfo = info;
  }

  onCreateConnection() {
    
  }

  setLoading(loading: boolean): void {
    this.isLoading = loading;

    // https://www.primefaces.org/primeng-v14-lts/progressbar

    if (this.isLoading) {
      this.progressMode = "indeterminate";
    } else {
      this.progressMode = "determinate";
    }
  }
}