// import { UserRole, GroupRole, RightHolder } from "./authorization.model";
// import { UserInfo } from "./user.model";

import { GroupRole } from "./api/models/authorization/GroupRoleLike";
import { RightHolder } from "./api/models/authorization/RightHolder";
import { UserRole } from "./api/models/authorization/UserRoleLIke";
import { UserInfo } from "./api/models/session/UserInfo";


export enum RightHolderType {
	UserRole,
	GroupRole
}

export class RightHolderView<T extends RightHolder> {
	readonly label:string;
	readonly RightHolderType:RightHolderType;
	readonly RightHolder:T;
}

export class UserRoleView implements RightHolderView<UserRole> {

	constructor(userRole:UserRole, userInfo:UserInfo) {
		this.RightHolder = userRole;
		this.userInfo = userInfo;
		this.label = userInfo.Username;
	}

	readonly RightHolder:UserRole;
	readonly userInfo:UserInfo;
	
	readonly label:string;
	readonly RightHolderType:RightHolderType = RightHolderType.UserRole;
}

export class GroupRoleView implements RightHolderView<GroupRole> {


	constructor(groupRole:GroupRole) {
		this.RightHolder = groupRole;
		this.label = groupRole.Name;
	}

	readonly RightHolder:GroupRole;

	readonly label:string;
	readonly RightHolderType:RightHolderType = RightHolderType.GroupRole;
}