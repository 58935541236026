<p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>


<p-dialog header="{{headerText | translate}}" [(visible)]="displayWorkflowDialog" modal="modal" showEffect="fade"
    [style]="{width: '600px'}" (onHide)="resetForms()">
    <app-system-message-log></app-system-message-log>


    <div class="p-grid p-fluid p-formgrid">
        <div class="p-col-12 p-mb-12 p-lg-12 p-mb-lg-0" style="margin-top: 40px;">
            <div class="p-fluid">
                <span class="p-float-label">
                    <input type="text" id="dsnameinputtext" [disabled]="inputDisabled" pInputText
                        [(ngModel)]="workflowName">
                    <label for="dsnameinputtext">{{'Name' | translate}}</label>
                </span>
            </div>
        </div>
        <div class="p-col-12 p-mb-2 p-lg-12 p-mb-lg-0" style="margin-top: 40px;">
            <div class="p-fluid">
                <span class="p-float-label">
                    <textarea style="min-height:7rem;" id="dsdescriptiontext" [cols]="30" pInputTextarea
                        [(ngModel)]="workflowDescription" autoResize="autoResize"></textarea>
                    <label for="dsdescriptiontext">{{'DescriptionOptional' | translate}}</label>
                </span>
            </div>
        </div>
        <div class="p-col-12 p-mb-2 p-lg-12 p-mb-lg-0" style="margin-top: 40px;">
            <div class="p-fluid">

                <span class="p-float-label">
                    <label for="isSimuationInput">{{'Workflow Modus' | translate}}</label>

                    <p-toggleButton id="isSimuationInput" [(ngModel)]="isSimulationFlag"
                        onLabel="{{'Simulation' | translate}}" offLabel="{{'Production' | translate}}"></p-toggleButton>
                </span>
            </div>
        </div>
        <div class="p-col-12 p-mb-2 p-lg-12 p-mb-lg-0" *ngIf="isSimulationFlag === true" style="margin-top: 40px;">
            <div class="p-fluid">
                <span class="p-float-label">
                    <input type="number" id="maxRowsInput" [disabled]="inputDisabled" pInputText [(ngModel)]="maxRows">
                    <label for="maxRowsInput">{{'Max Rows' | translate}}</label>
                </span>
            </div>
        </div>
    </div>


    <p-footer>
        <button pButton pRipple type="button" icon="pi pi-times" (click)="displayWorkflowDialog=false"
            class="p-button-rounded p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'Close' | translate}}"></button>
        <button pButton pRipple type="button" (click)="openWorkflow()"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'Open' | translate}}" *ngIf="createWorkflowSuccess === true"
            [icon]="savingInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'"></button>
        <button pButton pRipple type="button" (click)="onValidateForms()" *ngIf="createWorkflowSuccess === false"
            [disabled]="savingInProgress"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{this.buttonLabel | translate}}"
            [icon]="savingInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'"></button>

    </p-footer>
</p-dialog>