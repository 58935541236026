<div class="login-body">
    <app-system-message-log></app-system-message-log>

    <div class="login-wrapper">
        <div [formGroup]="form" class="login-panel">
            <a [routerLink]="['/']" class="logo">
                <img src="assets/layout/images/logo-bion-single.svg" alt="freya-layout" />
            </a>
            <!-- <a style="margin-bottom: 5px; color: crimson;">{{errorMessage}}</a> -->

            <input pInputText type="text" class="form-control" name="username" formControlName="username" required
                placeholder="{{modeName | translate}}" />
            <input pPassword type="password" class="form-control" name="password" formControlName="password"
                (keyup.enter)="onSubmit()" id="password" placeholder="{{'Password' | translate}}" />
            <button pButton pRipple label="{{'LOGIN' | translate}}" type="button" (click)="onSubmit()"></button>
            <!-- <div style="margin-bottom: 10px"><a style="float:left;margin-right: 5px">Bion</a><p-inputSwitch (onChange)="changeLoginMode($event)" formControlName="loginMode"></p-inputSwitch><a style="float:right;margin-left: 5px">Azure</a>
            </div> -->

            <a routerLink="/forgotPassword" routerLinkActive="active" target="_blank">{{'Forget password? Reset your password here' |
                translate}}</a>
            <!-- <p>Don’t you have an account, <a [routerLink]="['/register']">sign up</a></p> -->
        </div>
        <div class="login-footer">
            <h4>bion</h4>
            <h6>Copyright Ⓒ Bion Analytics</h6>
        </div>
    </div>
</div>
