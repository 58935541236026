export namespace ChangeDatatypePlugIn {
	export class ChangeDatatypeInfo {
		ColumnName: string;
		Datatype?: string;
		NumberConvention?: string;
		constructor(ColumnName: string,Datatype?: string,NumberConvention?: string) {
			if(ColumnName === undefined) throw new Error( "Class 'ChangeDatatypeInfo': Required value 'ColumnName' is undefined" );
			this.ColumnName = ColumnName;
			this.Datatype = Datatype;
			this.NumberConvention = NumberConvention;
		}
	}
	export class Settings {
		ChangeDatatypeInfos: Array<ChangeDatatypeInfo>;
		NumberConvention: string;
		constructor(ChangeDatatypeInfos: Array<ChangeDatatypeInfo>,NumberConvention: string) {
			if(ChangeDatatypeInfos === undefined) throw new Error( "Class 'Settings': Required value 'ChangeDatatypeInfos' is undefined" );
			if(NumberConvention === undefined) throw new Error( "Class 'Settings': Required value 'NumberConvention' is undefined" );
			this.ChangeDatatypeInfos = ChangeDatatypeInfos;
			this.NumberConvention = NumberConvention;
		}
	}

}