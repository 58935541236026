
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Observable, forkJoin, throwError } from 'rxjs';
import { concatMap, map, mergeMap } from 'rxjs/operators';
import { AppMainComponent } from 'src/app/app.main.component';
import { IntegratedSourceModel } from 'src/app/models/api/models/IntegratedSourceModel';
import { RightHolder } from 'src/app/models/api/models/authorization/RightHolder';
import { UserInfo } from 'src/app/models/api/models/session/UserInfo';
import { DataStoreAccessible } from 'src/app/models/api/models/staging/DataStoreAccessible';
import { DataStorePermission } from 'src/app/models/api/models/staging/DataStorePermission';
import { DataStore } from 'src/app/models/datastore.model';
import { WriteMode } from 'src/app/models/helperClass.model';
import { UserDetailsRow } from 'src/app/models/user.model';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { CubesService } from 'src/app/services/cubes.service';
import { DatasourcesService } from 'src/app/services/datasources.service';
import { DestinationService } from 'src/app/services/destinationService';
import { ExperimentalApi } from 'src/app/services/experimental-api.service';
import { IntegratedSourceService } from 'src/app/services/integrated-source.service';
import { ObjectSearchService } from 'src/app/services/object-search.service';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { UserService } from 'src/app/services/user.service';
import { UtilFunctionsService } from 'src/app/services/util-functions.service';
import { PermissionOps } from 'src/app/views/authorization/permission-operations';
import { DataModel, JobInfo, NewPermission, PermissionData } from 'src/app/views/authorization/role-permissions/role-permissions.component';
import { GenericLatestActivitiesComponent } from 'src/app/views/objectManagement/generic-latest-activities/generic-latest-activities.component';
import { DestinationActivity, DestinationRichActivity } from 'src/app/views/objectManagement/generic-latest-activities/provider-data-store';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-destination-view',
  templateUrl: './destination-view.component.html',
  styleUrls: ['./destination-view.component.scss'],
  providers: [MessageService]
})
export class DestinationViewComponent implements OnInit {
  subs = new SubSink;
  loading: boolean = false;
  userDetails: UserDetailsRow[] = [];
  selectedDestination?: DataStore;
  selectedDestinationCopy?: DataStore;

  connectorInfo?: string = "DataStore";
  editModeOn: boolean = false;
  @ViewChild('psaTable') psaTable;

  //@ViewChild('permView') permView;
  @ViewChild('destinationLatestActivities') destinationLatestActivities!: GenericLatestActivitiesComponent<DataStore, any>;

  // WF specific TypeClass
  destinationActivityTypeClass = new DestinationActivity(this.bionApi, this.dataStoreService, this.userDetails, this.utilService, this.router);
  // -- NEW TYPE CLASSES - Using rich API
  destinationViewActivityTypeClass = new DestinationRichActivity(this.dataStoreService, [], this.userDetails, this.utilService, this.router);


  constructor(
    private bionApi: ApiBackendService,
    public appMain: AppMainComponent,
    private datasourcesService: DatasourcesService,
    private dataStoreService: CubesService,
    private systemLogService: SystemMessageLogService,
    private userService: UserService,
    private utilService: UtilFunctionsService,
    public translate: TranslateService,
    public experimentalApi: ExperimentalApi,
    public router: Router,
    private messageService: MessageService
  ) { }
  ngOnInit(): void {
    this.writeModes = this.datasourcesService.getWriteModes().filter(wm => wm.name !== "Update" && wm.name !== "Delta");

    this.subs.sink = this.initView().subscribe(() => {
      this.loading = false;
      console.log("Load Details complete and success");
      console.log(this.selectedDestination);
    }, err => {
      this.systemLogService.handleError(err);
    });
    this.subs.sink = this.dataStoreService.selectedDataStoreEmitter.subscribe(
      (wf: DataStore) => {
        this.subs.sink = this.initView().subscribe(() => {
          this.loading = false;
        }, err => {
          this.systemLogService.handleError(err);
        });
      }
    );
  }


  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Diese Version von InitView überschreibt die Typklassen für die neue Rich API.
   * @param selected Possible selected Data Store
   * @returns
   */
  initView(): Observable<boolean> {
    this.loading = true;
    const current_id = this.getUrlContext();
    // Call BE data from API
    const userDetailsObs = this.userService.getUserDetailsRow();
    const destObs = this.experimentalApi.getDataStoreView();
    const dTypesObs = this.bionApi.getDataTypes();
    //const connectorObs = this.service_api.getConnectors();
    const permissionObs = this.getPermissionData(current_id.Id);

    const source_key = this.getUrlContext();

    const obs = forkJoin(destObs, userDetailsObs, dTypesObs, permissionObs);

    const finalObs = obs.pipe(concatMap(rich_data => {
      const data_stores = rich_data[0].map(d => d.DataStore);
      console.log(rich_data);
      this.userDetails = rich_data[1];
      this.selectedDestination = data_stores.find(d => d.id === source_key.Id);
      this.selectedDestinationCopy = { ...this.selectedDestination };

      this.psaTable.initDataStore(this.selectedDestination);
      //this.objectPipeline.selectedDataStore = this.selectedDestination;
      //this.objectPipeline.createApiUrl(this.selectedDestination);
      this.updatePermissionModel(current_id.Id, rich_data[3]);

      //this.permView.selectedObject = this.selectedDestination;

      this.destinationViewActivityTypeClass = new DestinationRichActivity(this.dataStoreService, rich_data[0], this.userDetails, this.utilService, this.router);
      const activity_obs = this.destinationLatestActivities.setObject(this.destinationViewActivityTypeClass);

      return forkJoin(new Array(activity_obs));

    }))
    return finalObs.pipe(map(() => true));

  }

  onSendRequest(sender: string) {
    let subject: any;
    let bodyText: any;

    if (sender === "Api") {
      subject = this.translate.instant("I want to request a API");
      bodyText = this.translate.instant("Hi Bion support, i want to request a API. Here is the description:");
    }
    if (sender === "Filetype") {
      subject = this.translate.instant("I want to request a file type");
      bodyText = this.translate.instant("Hi Bion support, i want to request a new file type. Here is the description:");
    }
    const mailText = "mailto:support@bion-analytics.com?subject=" + subject + "&body=" + bodyText; // add the links to body
    window.location.href = mailText;
  }

  /**
   * Der Datasource Schlüssel aus der Route.
   * @returns Data Source Key
   */
  getUrlContext(): IntegratedSourceModel.DataSourceKey<number> {
    const arr = this.router.url.split('/');
    const id = parseInt(arr.last());
    const origin = arr.getRight(1);
    console.log("Data Source ID: " + id);
    console.log("Data Source Origin: " + origin);
    return new IntegratedSourceModel.DataSourceKey(id, origin);
  }


  confirmDialog: boolean = false;
  writeModes: WriteMode[] = [];
  onChangeEditMode() {
    if (this.editModeOn) {
      if (this.selectedDestination.name === this.selectedDestinationCopy.name && this.selectedDestination.description == this.selectedDestinationCopy.description && this.selectedDestination.writeMode == this.selectedDestinationCopy.writeMode) {
        // Do nothing, just disable input
        this.editModeOn = false;
      } else {
        // show popup to ask
        this.confirmDialog = true;
      }
    } else {
      // activate input field to change data
      this.editModeOn = true;
    }
  }
  onInfoChanged() {

  }
  onChangeDestination() {
    this.loading = true;

    this.subs.sink = this.dataStoreService.updateDataStoreObject(this.selectedDestinationCopy).subscribe((ds) => {
      this.subs.sink = this.initView().subscribe(() => {
        this.confirmDialog = false;
        this.dataStoreService.dataStoresChangedEmitter.emit(ds);
        this.messageService.add({
          severity: "success",
          summary: this.translate.instant("Message.UpdateDataStoreSuccess.Title"),
          detail: this.translate.instant("Message.UpdateDataStoreSuccess.Text1") + this.selectedDestinationCopy.id +
            this.translate.instant("Message.UpdateDataStoreSuccess.Text2"),
        });
      }, (err) => {
        this.systemLogService.handleError(err)
      });

    }, (err) => this.systemLogService.handleError(err), () => {
      this.editModeOn = false;
      this.loading = false;

    });


  }
  onResetDialog() {
    this.selectedDestinationCopy = this.selectedDestination;
    this.editModeOn = false;
    this.confirmDialog = false;
  }

  // == Permission Component

  /**
 * Holt alle Informationen, die für den Permission Dialog benötigt werden.
 * @param source_key DataStore Key
 */
  getPermissionData(source_key: number) {
    const role_obs = this.userService.getRightHolders();
    const perm_obs = this.bionApi.getDataStorePermission(undefined, source_key);
    const user_obs = this.userService.getUserCommon();
    const rights_obs = this.bionApi.getDataStoreRights();

    return role_obs
      .pipe(mergeMap(roles => perm_obs
        .pipe(mergeMap(permissions => user_obs
          .pipe(mergeMap(users => rights_obs.pipe(map(rights => {
            const result: [RightHolder[], DataStorePermission[], UserInfo[], string[]] = [roles, permissions, users, rights];
            return result;
          }))
          ))
        ))
      ));
  }

  /**
   * Schreibt die neuen Informationen in die Permission Komponente.
   * @param workflowKey Objektschlüssel
   * @param data Permission und Metadaten
   */
  updatePermissionModel(workflowKey: number, data: [RightHolder[], DataStorePermission[], UserInfo[], string[]]) {

    const permission_data: PermissionData<DataStoreAccessible, DataStorePermission> = {
      right_holders: data[0],
      source_permissions: data[1],
      users: data[2],
      rights: data[3]
    }

    this.permissions_model = {
      Accessible: new DataStoreAccessible(workflowKey),
      PermissionData: permission_data,
      NewPermission: new NewDataStorePermission()
    }
  }

  permissions_model?: DataModel<DataStoreAccessible, DataStorePermission>;
  lastJobInfo?: JobInfo<DataStoreAccessible> = undefined;

  onPermissionSelectionChanged(jobInfo: JobInfo<DataStoreAccessible>) {
    this.lastJobInfo = jobInfo;
  }

  /**
   * Wird ausgeführt, sobald auf den Permission Update button gegklickt wurde.
   */
  clickUpdatePermissionsComp() {

    if (this.lastJobInfo === undefined) {
      // just say OK!
    } else {

      const cf: (item: DataStorePermission) => Observable<DataStorePermission> = this.bionApi.createDataStorePermission.bind(this.bionApi);
      const uf: (item: DataStorePermission) => Observable<number> = this.bionApi.updateDataStorePermission.bind(this.bionApi);
      // const df: (p: DataStorePermission) => Observable<number> = PermissionOps
      //   .mkDeleteOp<
      //   DataStoreAccessible,
      //     DataStorePermission,
      //     number
      //   >(this.bionApi.deleteWorkflowPermission, p => p.Accessible.ID)
      //   .bind(this.bionApi)

      const df_bound = this.bionApi.deleteDataStorePermission.bind(this.bionApi);
      const df: (p: DataStorePermission) => Observable<number> = PermissionOps
        .mkDeleteOp<DataStoreAccessible, DataStorePermission, number>(df_bound, p => p.ID);

      const ob = PermissionOps.processPermissions(this.lastJobInfo.Create, this.lastJobInfo.Update, this.lastJobInfo.Delete, cf, uf, df);

      // update view
      const source_key = this.permissions_model.Accessible.ID;

      const final_ob = ob
        .pipe(concatMap(results => this.getPermissionData(source_key)
          .pipe(map(data => {
            this.updatePermissionModel(source_key, data);
            return results;
          }))))

      final_ob.subscribe(results => {
        // TODO: error handling and ui notification

        const failed = results.filter(r => r.Successful == false);
        console.log("Failed Operations", failed);

        if (failed.nonEmpty()) {

          const fail_texts = failed.map(f => f.Tag + " " + f.Argument.ID + " '" + f.Argument.Role + "' - " + f.Error.message);
          const fail_reduced = fail_texts.reduce((a, b) => a + ", " + b);
          const msg = "Could not process the following Permissions: " + fail_reduced;
          this.handleError(new Error(msg));
        } else {
          console.log("Permission processed via component", results);
          this.messageService.add({
            severity: "success",
            summary: this.translate.instant("Message.UpdatePermissionSuccess.Title"),
            detail: this.translate.instant("Message.UpdatePermissionSuccess.Text1") + source_key + this.translate.instant("Message.UpdatePermissionSuccess.Text2"),
          });

        }
      })

    }

  }

  handleError(err: Error) {
    this.systemLogService.handleError(err);
    console.error("Error Loading Data from PSA", err);
  }

  // == Permission Comopnent END



}



class NewDataStorePermission implements NewPermission<DataStoreAccessible, DataStorePermission> {
  create(role: number, accessible: DataStoreAccessible): DataStorePermission {
    return new DataStorePermission(-1, role, false, accessible, []);
  }
}
