import { Observable, from } from "rxjs";
import { mergeMap, map } from "rxjs/operators";
import { AsyncFileReader } from "src/app/helper/fileReader";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { DynamicItemInfo } from "../com-bion-json";
import { JsonMetaInfo, JsonPushAccess, JsonMetaAccess, ExtractMetaFromConnectorArg } from "../connector.model";
import { ConnectorSettingsBuilder, ConnectorViewBase } from "../connectorView.model";

export class JsonConnectorView extends ConnectorViewBase<JsonPushAccess, JsonMetaInfo, JsonMetaAccess> {
    constructor(api: ApiBackendService, connectorID: string) {
        super(api, connectorID);

        this.metaInfo = new JsonMetaInfo([], [], undefined);
    }

    base64FileData: string;

    setInitialMetaSettings(settings: JsonMetaAccess): void {
        super.setInitialMetaSettings(settings);
    }

    fetchMetaDataObs(): Observable<JsonMetaInfo> {
        const reader = new FileReader();
        reader.readAsDataURL(this.uploadedFile);


        const file_load_promise = new Promise<string>((resolve, reject) => {
            reader.onload = () => {
                // Collect Base64 File Info
                let base64Raw = reader.result as string;
                let base64FileData = base64Raw.split(",")[1];

                // update base 64 file data
                this.base64FileData = base64FileData;
                resolve(base64FileData);
            }
        });

        const file_obs = from(file_load_promise);

        const meta_obs = file_obs.pipe(mergeMap(file_res => {

            const json_meta_access = new JsonMetaAccess(true);

            const connector_info = new DynamicItemInfo(this.connectorID, json_meta_access);

            const extract_meta_arg = new ExtractMetaFromConnectorArg;
            extract_meta_arg.ConnectorMetaInfo = connector_info;
            extract_meta_arg.Base64FileData = file_res;

            console.log("Extract Meta from Connector", extract_meta_arg);

            return this.api.api_extractMetaFromConnector(extract_meta_arg).pipe(map((extractRes) => {
                console.log(extractRes);
                return <JsonMetaInfo>extractRes.MetaData;
            }));
        }));

        return meta_obs;
    }

    getSettingsBuilder(): Observable<ConnectorSettingsBuilder<JsonPushAccess>> {
        let file_reader = new AsyncFileReader();
        let file_obs = file_reader.readAsync(this.uploadedFile);

        let finalRes = file_obs.pipe(
            map((fileData: string) => {
                let innerRes = new JsonSettingsBuilder(this.connectorID, this.connectorSettings.ReadMode, this.connectorSettings.UseQuery, this.connectorSettings.Query, this.connectorSettings.NestedObjectsMode, fileData);
                return innerRes;
            })
        );
        return finalRes;
    }
    // uploadFile(file: File) {
    //     this.uploadedFile = file;
    // }
    getDataSourceNameSuggestion(): string {
        throw new Error('Method not implemented.');
    }
    getBase64FileData(): string {
        return this.base64FileData;
    }
    isFileBased(): boolean {
        return false;
    }
}

export class JsonSettingsBuilder implements ConnectorSettingsBuilder<JsonPushAccess> {
    constructor(connectorID: string, ReadMode: string, UseQuery: boolean, Query: string, NestedObjectsMode: string, base64FileData: string) {
        this.connectorID = connectorID;
        this.ReadMode = ReadMode;
        this.UseQuery = UseQuery;
        this.Query = Query;
        this.NestedObjectsMode = NestedObjectsMode;
        this.base64FileData = base64FileData;
    }
    connectorID: string;
    ReadMode: string;
    UseQuery: boolean;
    Query: string;
    NestedObjectsMode: string;
    base64FileData: string;


    getConnectorId(): string {
        return this.connectorID;
    }
    getConnectorSettings() {

        const settings = new JsonPushAccess(this.ReadMode,
            this.UseQuery,
            this.Query,
            this.NestedObjectsMode);

        return settings;
    }

}