<p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>

<div class="layout-rightpanel" (click)="appMain.rightPanelClick = true">
    <div class="rightpanel-wrapper">
        <!-- <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown inputId="languageInput" [options]="translate.getLangs()" [(ngModel)]="selectedLanguage"
                    placeholder="{{'SelectLanguage' | translate}}" (onChange)="switchLang(selectedLanguage)">
                    <ng-template pTemplate="selectedItem">
                        <div class="country-item country-item-value" *ngIf="selectedLanguage">
                            <img src="assets/demo/flags/flag_placeholder.png" [width]="20"
                                [class]="'flag flag-' + selectedLanguage.toLowerCase()" />
                            <span style="margin-left: 10px">{{selectedLanguage}}</span>
                        </div>
                    </ng-template>
                    <ng-template let-country pTemplate="item">
                        <div class="country-item">
                            <img src="assets/demo/flags/flag_placeholder.png"
                                [class]="'flag flag-' + country.toLowerCase()" />
                            <span style="margin-left: 10px">{{country}}</span>
                        </div>
                    </ng-template>
                </p-dropdown>
                <label for="languageInput">{{'Language' | translate}}</label>
            </span>
        </div> -->


        <!-- <div class="rightpanel-section favorites-section">
            <div class="favorite-items">
                <a [routerLink]="['/personal/profile']" pTooltip="{{'ViewProfile' | translate}}" tooltipPosition="top"
                    class="favorite-item" style="text-align: center;">
                    <i class="pi pi-user" style="font-size: 24px;">
                    </i>
                </a>
                <a [routerLink]="['/personal/users']" class="favorite-item" pTooltip="{{'ManageUsers' | translate}}"
                    tooltipPosition="top" style="text-align: center;">
                    <i class="pi pi-users" style="font-size: 24px;">
                    </i>
                </a>
                <a [routerLink]="['/tutorial']" class="favorite-item"
                    pTooltip="{{'GoToDocumentation' | translate}}" tooltipPosition="top" style="text-align: center;">
                    <i class="pi pi-question-circle" style="font-size: 24px;">
                    </i>
                </a>
                <a [routerLink]="[]" (click)="appMain.logout()" class="favorite-item"
                    pTooltip="{{'Logout' | translate}}" tooltipPosition="top"
                    style="text-align: center;background-color: rgb(255, 170, 170);color:white">
                    <i class="pi pi-sign-out" style="font-size: 24px;">
                    </i>
                </a>
            </div>
        </div> -->
        <!-- <span class="form-inline">
            <select
                class="form-control"
                #selectedLang
                (change)="switchLang(selectedLang.value)">
              <option *ngFor="let language of translate.getLangs()"
                [value]="language"
                [selected]="language === translate.currentLang">
                {{ language }}
              </option>
            </select>
          </span> -->
        <!-- <div class="rightpanel-section favorites-section">
            <div class="section-header">
                <h6>Your Capacity</h6>
            </div>
            <div class="favorite-items">
                <a href="#" class="favorite-item" style="text-align: center;">
                    <i class="pi pi-cloud" style="font-size: 36px">
                        <p style="font-size: 14px">67%</p>
                    </i>

                </a>
                <a href="#" class="favorite-item" style="text-align: center;">
                    <i class="pi pi-cog" style="font-size: 36px">
                        <p style="font-size: 14px">34%</p>
                    </i>
                </a>
                <a href="#" class="add-item" style="text-align: center;">
                    <p>Upgrade</p>
                </a>
            </div>
        </div> -->

        <!-- <div class="rightpanel-section weather-section">
            <div class="section-header">
                <h6>Today</h6>
            </div>
            <div class="weather">
                <img src="assets/demo/images/rightpanel/icon-sun.svg" alt="freya-layout" />
                <div class="weather-info">
                    <h6>Ankara, 22 May</h6>
                    <h1>24º</h1>
                </div>
            </div>
        </div> -->

        <!-- <div class="rightpanel-section favorites-section">
            <div class="favorite-items">
                <div class="card">
                    <div class="card-header">
                        <h6>{{'Logs' | translate}}</h6>
                        <p-menu appendTo="body" #logsmenu [popup]="true" [model]="logsMenu"></p-menu>
                        <button pButton type="button" icon="pi pi-ellipsis-v"
                            class="p-button-rounded p-button-plain p-button-text"
                            (click)="logsmenu.toggle($event)"></button>
                    </div>
                    <p-scrollPanel [style]="{height: '17rem'}">
                        <p-table #logdt [value]="GuiErrorInfo" sortField="code" sortMode="single"
                            [globalFilterFields]="['httpCode','httpMessage','httpObject','stackTrace']" [rows]="10"
                            [scrollable]="false" styleClass="p-datatable-customers p-datatable-sm">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>
                                        <div class="p-d-flex p-jc-between p-ai-center">
                                            {{'Error' | translate}}
                                        </div>
                                    </th>
                                    <th>
                                        <div class="p-d-flex p-jc-between p-ai-center">
                                            {{'Message' | translate}}
                                        </div>
                                    </th>
                                    <th style="width: 4rem"></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-log let-rowIndex="rowIndex">
                                <tr [pSelectableRow]="log">
                                    <td><span class="p-column-title">{{'Error' | translate}}</span>
                                        <span [class]="'customer-badge status-' + log.httpMessage"
                                            [value]="log.httpMessage">
                                            {{log.httpMessage}} ({{log.httpCode}})</span>
                                    </td>
                                    <td><span class="p-column-title">{{'Message' | translate}}</span>
                                        <span
                                            [class]="'customer-badge status-' + log.httpObject">{{log.httpObject}}</span>
                                    </td>
                                    <td>
                                        <div class="ui-table-buttongroup-right">
                                            <button pButton pRipple type="button" icon="pi pi-info-circle"
                                                class="p-button-rounded p-button-text p-mr-2 p-mb-2"
                                                pTooltip="View Stacktrace" (click)="onSelectErrorEntry(log)"
                                                (click)="op1.toggle($event)"></button>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="3">{{'NoLogFound' | translate}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <p-scrollTop target="parent" styleClass="custom-scrolltop" [threshold]="100"
                            icon="pi pi-arrow-up">
                        </p-scrollTop>
                    </p-scrollPanel>
                </div>
            </div>
        </div> -->



        <!-- <div class="rightpanel-section chat-section chat">
            <p-tabView>
                <p-tabPanel>
                    <ng-template pTemplate="header">
                        <img src="assets/demo/images/rightpanel/profile-1.png" alt="freya-layout" />
                        <span class="p-badge">3</span>
                    </ng-template>
                    <div class="chat">
                        <span class="fade"></span>
                        <div class="chat-content">
                            <div class="chat-message send">
                                <span class="name">You</span>
                                <div class="message">
                                    <p>Hey M. hope you are well. Our idea is accepted by the board. Now it’s time to
                                        execute it.</p>
                                    <span>3 mins ago</span>
                                </div>
                                <div class="message">
                                    <p>we did it! 🤠</p>
                                    <span>3 mins ago</span>
                                </div>
                            </div>
                            <div class="chat-message">
                                <span class="name">Micheal J.</span>
                                <div class="message">
                                    <p>That’s really good!</p>
                                    <span>3 mins ago</span>
                                </div>
                            </div>
                            <div class="chat-message send">
                                <span class="name">You</span>
                                <div class="message">
                                    <p>But it’s important to ship MVP ASAP</p>
                                    <span>3 mins ago</span>
                                </div>
                            </div>
                            <div class="chat-message">
                                <span class="name">Micheal J.</span>
                                <div class="message">
                                    <p>I’ll be looking at the process then, just to be sure 🤓 </p>
                                    <span>3 mins ago</span>
                                </div>
                            </div>
                            <div class="chat-message send">
                                <span class="name">You</span>
                                <div class="message">
                                    <p>That’s awesome. Thanks!</p>
                                    <span>3 mins ago</span>
                                </div>
                            </div>
                            <div class="chat-message">

                            </div>
                        </div>
                        <div class="chat-input">
                            <input pInputText type="text" placeholder="Write here...">
                        </div>
                    </div>
                </p-tabPanel>
                <p-tabPanel>
                    <ng-template pTemplate="header">
                        <img src="assets/demo/images/rightpanel/profile-2.png" alt="freya-layout" />
                        <span class="p-badge">1</span>
                    </ng-template>
                    <div class="chat">
                        <span class="fade"></span>
                        <div class="chat-content">
                            <div class="chat-message">
                                <span class="name">Sarah</span>
                                <div class="message">
                                    <p>That’s really good!</p>
                                    <span>3 mins ago</span>
                                </div>
                            </div>
                            <div class="chat-message send">
                                <span class="name">You</span>
                                <div class="message">
                                    <p>But it’s important to ship MVP ASAP</p>
                                    <span>3 mins ago</span>
                                </div>
                            </div>
                            <div class="chat-message">

                            </div>
                        </div>
                        <div class="chat-input">
                            <input pInputText type="text" placeholder="Write here..." />
                        </div>
                    </div>
                </p-tabPanel>
                <p-tabPanel>
                    <ng-template pTemplate="header">
                        <img src="assets/demo/images/rightpanel/profile-3.png" alt="freya-layout" />
                    </ng-template>
                    <div class="chat">
                        <div class="chat-content no-message">
                            <h4>No Message From Margret K.</h4>
                        </div>
                        <div class="chat-input">
                            <input pInputText type="text" placeholder="Write here..." />
                        </div>
                    </div>
                </p-tabPanel>
                <p-tabPanel>
                    <ng-template pTemplate="header">
                        <img src="assets/demo/images/rightpanel/profile-4.png" alt="freya-layout" />
                    </ng-template>
                    <div class="chat">
                        <div class="chat-content no-message">
                            <h4>No Message From Bob C.</h4>
                        </div>
                        <div class="chat-input">
                            <input pInputText type="text" placeholder="Write here..." />
                        </div>
                    </div>
                </p-tabPanel>
                <p-tabPanel>
                    <ng-template pTemplate="header">
                        <i class="pi pi-plus"></i>
                    </ng-template>
                    <div class="chat">
                        <span class="fade"></span>
                        <div class="contacts">
                            <ul>
                                <li>
                                    <img src="assets/demo/images/rightpanel/profile-1.png" alt="freya-layout" />
                                    <div class="contact-info">
                                        <h6>John Doe</h6>
                                        <span>Active</span>
                                    </div>
                                </li>
                                <li>
                                    <img src="assets/demo/images/rightpanel/profile-2.png" alt="freya-layout" />
                                    <div class="contact-info">
                                        <h6>John Doe</h6>
                                        <span>Active</span>
                                    </div>
                                </li>
                                <li>
                                    <img src="assets/demo/images/rightpanel/profile-3.png" alt="freya-layout" />
                                    <div class="contact-info">
                                        <h6>John Doe</h6>
                                        <span>Active</span>
                                    </div>
                                </li>
                                <li>
                                    <img src="assets/demo/images/rightpanel/profile-4.png" alt="freya-layout" />
                                    <div class="contact-info">
                                        <h6>John Doe</h6>
                                        <span>Active</span>
                                    </div>
                                </li>
                                <li>
                                    <img src="assets/demo/images/rightpanel/profile-1.png" alt="freya-layout" />
                                    <div class="contact-info">
                                        <h6>John Doe</h6>
                                        <span>Active</span>
                                    </div>
                                </li>
                                <li>
                                    <img src="assets/demo/images/rightpanel/profile-1.png" alt="freya-layout" />
                                    <div class="contact-info">
                                        <h6>John Doe</h6>
                                        <span>Active</span>
                                    </div>
                                </li>
                                <li>
                                    <img src="assets/demo/images/rightpanel/profile-1.png" alt="freya-layout" />
                                    <div class="contact-info">
                                        <h6>John Doe</h6>
                                        <span>Active</span>
                                    </div>
                                </li>
                                <li>
                                    <img src="assets/demo/images/rightpanel/profile-1.png" alt="freya-layout" />
                                    <div class="contact-info">
                                        <h6>John Doe</h6>
                                        <span>Active</span>
                                    </div>
                                </li>
                                <li>
                                    <img src="assets/demo/images/rightpanel/profile-1.png" alt="freya-layout" />
                                    <div class="contact-info">
                                        <h6>John Doe</h6>
                                        <span>Active</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="chat-input">
                            <input pInputText type="text" placeholder="Search new person" />
                        </div>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </div> -->

    </div>
</div>
