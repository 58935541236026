<!-- <div class="p-grid" style="margin-top:15px;display: flex; align-items: center;">
    <span class="p-col" style="display: flex; align-items: center;">
        <h5 style="margin-bottom:0">
            {{'Permissions' | translate }}
        </h5>
        <span style="margin-left:5px"><app-info-tooltip></app-info-tooltip></span>
    </span>
    <span class="p-col-5" style="float:right;text-align:right">
        <button type="button" pButton icon="pi pi-user-plus" label="{{'Save changes' | translate}}"
            (click)="valueCheckClicked($event)"></button>
    </span>
</div> -->

<div class="card">
    <div class="p-grid grid-nogutter">
        <div class="p-col-md-12 p-col-3 drag-column">
            <div *ngFor="let product of pl_source_permissions">
                <div class="product-item" pDraggable="permissions" (onDragStart)="permOnDragStart(product)"
                    (onDragEnd)="permOnDragEnd()">
                    <div class="image-container">
                        <!-- <img src="https://primefaces.org/cdn/primeng/images/demo/product/{{ product.image }}"
                        [alt]="product.name" class="product-image" /> -->
                    </div>
                    <div class="product-list-detail">
                        <h6 class="mb-2">{{product.Label}}
                            <span style="float:right; color:grey">
                                <p-chip *ngIf="product.Type === 'Group'" icon="pi pi-users"
                                    label="{{product.Type | translate}}"></p-chip>
                                <p-chip *ngIf="product.Type === 'User' && product.image === undefined" icon="pi pi-user"
                                    label="{{product.Type | translate}}">
                                </p-chip>
                                <p-chip *ngIf="product.Type === 'User' && product.image !== undefined"
                                    [image]="product.image" label="{{product.Type | translate}}"></p-chip>
                            </span>
                        </h6>
                        <p>{{product.RichRole?.EMail}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-col-md-12 p-col-9 drop-column" pDroppable="permissions" (onDrop)="permOnDrop()">
            <p-table [value]="pl_target_permissions">
                <ng-template pTemplate="header">
                    <tr>
                        <th></th>
                        <th>{{'Name' | translate }}</th>
                        <th>{{'Category' | translate }}</th>
                        <!-- <th>{{'IsOwner' | translate }}</th> -->
                        <th>{{'Rights' | translate }}</th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product>
                    <tr>
                        <td style="width: 4rem;"><p-badge *ngIf="checkState(product) === 'New'"
                                value="{{'New' | translate }}"></p-badge></td>
                        <td>{{product.Label}}</td>
                        <td>
                            <i *ngIf="product.Type === 'Group'" pTooltip="{{product.Type | translate}}"
                                class="pi pi-users">
                            </i>
                            <i *ngIf="product.Type === 'User' && product.image === undefined"
                                pTooltip="{{product.Type | translate}}" class="pi pi-user">
                            </i>
                            <i *ngIf="product.Type === 'User' && product.image  !== undefined"
                                [image]="product.image"></i>
                            {{product.Type | translate}}
                        </td>
                        <!-- <td>
                            <i *ngIf="product.IsOwner == true" pTooltip="{{product.IsOwner | translate}}"
                                class="pi pi-check"></i>
                            <i *ngIf="product.IsOwner == false" pTooltip="{{product.IsOwner | translate}}"
                                class="pi pi-delete"></i>
                        </td> -->
                        <td>
                            <span *ngFor="let category of model?.PermissionData.rights" class="p-field-checkbox">
                                <p-checkbox name="{{'perm_group_' + product.Role}}" [value]="category"
                                    [(ngModel)]="product.Rights" (onChange)="onRightChanged($event)" [inputId]="category">
                                </p-checkbox>
                                <label [for]="category">{{category | translate}}</label>
                            </span>
                        </td>
                        <!-- <td>
                            <p-button (click)="dropPermissionClick($event)">Drop</p-button>
                        </td> -->
                        <td style="width: 4rem;">
                            <div class="ui-table-buttongroup-right">
                                <button pButton pRipple type="button" icon="pi pi-trash"
                                    class="p-button-rounded p-button-text p-mr-2 p-mb-2"
                                    pTooltip="{{'Delete' | translate}}" (click)="dropPermissionClick(product)"></button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>