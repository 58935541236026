import { Component, OnInit } from "@angular/core";
import { Id } from "src/app/helper/id";
import { FieldInfo, WorkflowNodeSettings } from "src/app/models/api/com/bion/etl/Workflow";
import { SortSettings, SortInfo } from "src/app/models/nodeSettings.model";
// import { FieldInfo, WorkflowNodeSettings } from "src/app/models/workflow.model";
import { DesignerService } from "src/app/services/designer.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { NodeConfigComponentBase } from "../node-config-component-base";

export class SortInfoView {
	ColumnName: string;
	SortType: DropDownType;
	hasError?: boolean;

	constructor(ColumnName: string, SortType: DropDownType, hasError?: boolean) {
		this.ColumnName = ColumnName;
		this.SortType = SortType;
		this.hasError = hasError
	}
}

export class DropDownType {
	name: string;
	value: boolean;

	constructor(name: string, value: boolean) {
		this.name = name;
		this.value = value;
	}
}

@Component({
	selector: "app-sorting-node",
	templateUrl: "./sorting-node.component.html",
	styleUrls: ["./sorting-node.component.css"],
})
export class SortingNodeComponent
	extends NodeConfigComponentBase<SortSettings>
	implements OnInit
{
	settingsToView(settings: WorkflowNodeSettings): void {
		// -- Get Configuration
		let currentConfig = <SortSettings>settings;
		// -- Get Input Meta Data
		const meta_infos =
			this._currentWorkflowNodeAdapter.IWorkflowNode.Properties.MetaInfo.get(
				this.InputPort
			);

		let columnListArray: string[] = [];

		// create SelectInfoViews from DsFields for table
		if (meta_infos != undefined && meta_infos.length > 0) {
			const input_field_infos = meta_infos[0].FieldsInfo;

			columnListArray = input_field_infos.map((fieldInfo) => {
				return fieldInfo.Name;
			});
			this.columnList = columnListArray;
		}

		// --If currentConfig not available (-> new node)
		if (currentConfig == undefined) {
			this.sortList = [];
			//this.selectedMatchMode = this.matchModeOptions[0];
		} else {
			// -- otherweise load existing config into view
			if (!currentConfig.SortInfos) return;

			currentConfig.SortInfos.map((info) => {
				if (columnListArray.includes(info.ColumnName)) {
					// -- column still exists, do nothing
				} else {
					// column does not exist anymore, meaning column name currently not included -> include
					columnListArray.push(info.ColumnName);
				}
			});
			this.columnList = columnListArray;
            if(meta_infos !== undefined) {
                this.sortList = this.sortInfoToSortInfoView(
                    currentConfig.SortInfos,
                    meta_infos[0].FieldsInfo
                );
            }

		}
	}
	viewToSettings(): SortSettings {
		const newSettings = <SortSettings>{
			...this.getCurrentWorkflowNode().Properties.Configuration,
		};
		const sortInfoViews = this.sortList;
		const sortInfos = this.sortInfoViewToSortInfo(sortInfoViews);

		newSettings.SortInfos = sortInfos;

		// this.messageService.standardToastMessage({  severity : 'success',
		//                                                 summary : 'Filter successsfully added',
		//                                                 detail : 'New Filter was successfully added to the node.'});

		return newSettings;
	}

	onSettingsChanged(settings: SortSettings) {
		throw new Error("Method not implemented.");
	}

	sortList: SortInfoView[] = [];
	columnList: string[] = [];
	dropDownSortMethod: DropDownType[];

	readonly InputPort = "Input";

	onAddFilter() {
		const myColumnList = this.getCurrentWorkflowNode().Properties.MetaInfo.get(
			this.InputPort
		);

		if (myColumnList === undefined || myColumnList.length === 0) {
			//TODO: Add User notification that no input fields available
			return;
		}

		let newFilter = new SortInfoView("",this.dropDownSortMethod[0], false);
		// newFilter.ColumnName = "";
		// newFilter.SortType = this.dropDownSortMethod[0];
		// newFilter.hasError = false;

		this.sortList.push(newFilter);
		this.sortInfoViewToSortInfo(this.sortList);
		this.onUpdateSettings(false);
	}
	onDeleteEntry(index: number) {
		this.sortList = [
			...this.sortList.slice(0, index),
			...this.sortList.slice(index + 1),
		];
		this.onUpdateSettings(false);
		// this.messageService.standardToastMessage({  severity : 'warn',
		//                                                 summary : 'Filter deleted',
		//                                                 detail : 'The selected filter was deleted successfully!'});
	}
	sortInfoToSortInfoView(list: SortInfo[], meta?: FieldInfo[]): SortInfoView[] {
		let newSortInfosView: SortInfoView[] = [];

		list.map((sortInfo: SortInfo) => {
			//let newInfoView = new SortInfoView();

			// -- create selected SortMethod from config
			let sort_type;

			for (let i = 0; i < this.dropDownSortMethod.length; i++) {
				if (sortInfo.SortType == this.dropDownSortMethod[i].value) {
					sort_type = this.dropDownSortMethod[i];
				}
			}

			const sort_type_given = Id.assertSet(sort_type, new Error("Sort type not found, should not happen"));

			//newInfoView.ColumnName = sortInfo.ColumnName;

			let fieldInfoFound = meta?.find((field) => {
				return field.Name === sortInfo.ColumnName;
			});

			//newInfoView.hasError = !fieldInfoFound;

			let newInfoView = new SortInfoView(sortInfo.ColumnName,sort_type_given,!fieldInfoFound);

			newSortInfosView.push(newInfoView);
		});
		// -- return final view to GUI
		return newSortInfosView;
	}
	sortInfoViewToSortInfo(sortList: SortInfoView[]) {
		// -- create new empty array
		let newSortInfos: SortInfo[] = [];

		sortList.map((entry) => {
			let newSettings = new SortInfo();
			newSettings.ColumnName = entry.ColumnName;
			newSettings.SortType = entry.SortType.value;
			newSortInfos.push(newSettings);
		});
		return newSortInfos;
	}

	constructor(
		protected workflowService: WorkflowsService,
		protected designerService: DesignerService
	) {
		super(workflowService, designerService);
		this.dropDownSortMethod = [
			{ name: "Ascending", value: true },
			{ name: "Descending", value: false },
		];
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

	ngOnDestroy(): void {}
    onFocusLost(event: FocusEvent) {
        const div_left: boolean = (event.relatedTarget === null);

        console.log("Send from Div: ", div_left);

        if (div_left) {
            //this.onCheckDirtyFlag();
            this.onUpdateSettings(true);
        }
    }
}
