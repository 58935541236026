import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DatasourcesService } from 'src/app/services/datasources.service';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { SubSink } from 'subsink';
import * as dss from "../../../../models/datasource.model";
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-pull-datasource-dialog',
	templateUrl: './pull-datasource-dialog.component.html',
	styleUrls: ['./pull-datasource-dialog.component.scss'],
	providers: [MessageService],
})
export class PullDatasourceDialogComponent implements OnInit {

	displayPullDatasource: boolean = false;
	subs = new SubSink();
	savingInProgress: boolean = false;
	buttonDisabled: boolean = false;
	currentSelectedDatasource: dss.DataSource;

	constructor(
		private datasourceService: DatasourcesService,
		private messageService: MessageService,
		private systemLogService: SystemMessageLogService,
		private translate: TranslateService
	) { }
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngOnInit(): void {
		this.subs.sink = this.datasourceService.displayPullDatasource.subscribe(
			(res: boolean, ds: dss.DataSource) => {
				this.displayPullDatasource = res;
			}, (error: Error) => {
				this.systemLogService.handleError(error);
			}
		);
		this.subs.sink =
			this.datasourceService.selectedMenuDatasourceEmitter.subscribe(
				(ds: dss.DataSource) => {
					this.currentSelectedDatasource = ds;
				}, (error: Error) => {
					this.systemLogService.handleError(error);
				}
			);
	}

	// API Calls ------------------
	pullDatasource(id: number) {
		this.savingInProgress = true;

		let arg = new dss.ExtractDataToPsaArg();
		arg.DataSourceID = id;
		arg.Simulate = false;

		console.log(arg);

		this.subs.sink = this.datasourceService.extractToPsaAsync(arg).subscribe(
			(res) => {
				this.datasourceService.datasourceChangedEmitter.emit(this.currentSelectedDatasource);
				this.messageService.add({
					severity: "success",
					summary: this.translate.instant("Message.PullDataSourceTriggerSuccess.Title"),
					detail: this.translate.instant("Message.PullDataSourceTriggerSuccess.Text1") + id +
					this.translate.instant("Message.PullDataSourceTriggerSuccess.Text2"),
					//summary: "Datasource pull triggered!",
					//detail: "Data pull for datasource " + id + " was successfully triggered",
				});
				this.datasourceService.displayPullDatasourceDialog([this.currentSelectedDatasource,false]);

			},
			(err: Error) => {
				this.systemLogService.handleError(err);
			}, () => {
				this.savingInProgress = false;
				this.buttonDisabled = false;
			}
		);
	}
}
