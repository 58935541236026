import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Workflow } from 'src/app/models/api/com/bion/etl/Workflow';
import { WorkflowRepositoryEntry } from 'src/app/models/api/models/workflow/WorkflowRepositoryEntry';
import { WorkflowNodeInfo } from 'src/app/models/designer.models';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { SubSink } from 'subsink';
import { GraphViewComponent } from '../graph-view/graph-view.component';
import { PluginViewComponent } from '../plugin-view/plugin-view.component';
import { SqlDummyApiService } from '../sql-dummy-api.service';
import { GraphTabView, GraphView, SQLWorkflowView, ToolbarCommand, ToolbarCommands } from '../sql-workflow-sketch';
import { ToolbarViewComponent } from '../toolbar-view/toolbar-view.component';
import { WorkflowTabViewComponent } from '../workflow-tab-view/workflow-tab-view.component';

@Component({
  selector: 'app-designer-view',
  templateUrl: './designer-view.component.html',
  styleUrls: ['./designer-view.component.scss']
})
export class SqlDesignerViewComponent implements OnInit, SQLWorkflowView, GraphTabView {

  constructor(private sql_api: SqlDummyApiService, private cdr: ChangeDetectorRef, private workflowService: WorkflowsService, private apiService:ApiBackendService) { }
  //plugIns: PlugInInfo[] = [];
  plugIns: WorkflowNodeInfo[] = [];
  toolbarCommands: ToolbarCommand[] = [];
  workflows: WorkflowRepositoryEntry[] = [];
  subs = new SubSink;

  // @ViewChild('plugInView') plugInView: PluginViewComponent;
  // @ViewChild('toolbarView') toolbarView: ToolbarViewComponent;
  // @ViewChild('graphTabView') graphTabView: WorkflowTabViewComponent;


  ngOnInit(): void {

    //-- API Calls to get relevant data
    // this.sql_api.getSqlPlugIns().subscribe(plugIns => {
    //   this.plugIns = plugIns;
    // });
    this.subs.sink = this.workflowService.getSqlPlugIns().subscribe(plugIns => {
      console.log("PlugIns!!!")
      this.plugIns = plugIns;
    });

    this.getToolbarCommands().subscribe(commands => {
      //this.toolbarView.setCommands(commands);
      this.toolbarCommands = commands;
    })
    this.sql_api.toolbarCommandFired.subscribe(command => {
      this.onCommandClick(command);
    })

  }



  getToolbarCommands(): Observable<ToolbarCommand[]> {

    const commands: ToolbarCommand[] = [
      { Command: ToolbarCommands.Save, Enabled: true , IconPath: "pi pi-save"},
      { Command: ToolbarCommands.Load, Enabled: true, IconPath:"pi pi-refresh"  },
      { Command: ToolbarCommands.Execute, Enabled: true, IconPath:"pi pi-play"  },
    ]

    return of(commands);
  }

  onCommandClick(command: ToolbarCommand) {
    switch (command.Command) {
      case ToolbarCommands.Load:
        { alert("Load Received"); }
        break;
      case ToolbarCommands.Save:
        { alert("Save Received"); }
        break;
      default:
        alert("Command unknown: " + command.Command);
        break;
    }
  }



  // newTab() {
  //   // Neuer 'nackter' workflow tab, der direkt bentutz werden kann.
  //   // TODO: teste ob: name schon vergeben ist, etc....

  //   const graphView = new GraphViewComponent(this.sql_api);
  //   this.addGraphView(graphView);
  // }

  removeTab() {
    // TODO: Concept
  }

  // = Graph Tab View
  graphViewComponents: GraphViewComponent[] = [];

  workflowKeyDummy: number = 1;

  onAddGraph() {
    this.workflowKeyDummy = this.workflowKeyDummy + 1;
    const new_id = this.workflowKeyDummy.toString();
    const new_workflow: Workflow = new Workflow(new_id,[],[],"");
    const graph = new GraphViewComponent(this.sql_api, this.cdr, this.apiService);  // TODO: wait for NG on init completion!
    graph.loadWorkflow(new_workflow);
    this.graphViewComponents.push(graph);
  }
  onTabClicked(graph: GraphView) {
    this.setCurrentGraphView(graph);
  }

  getGraphViews(): GraphView[] {
    return this.graphViewComponents;
  }
  setCurrentGraphView(view: GraphView): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
  addGraphView(view: GraphView): Observable<boolean> {
    throw new Error('Method not implemented.');
  }

}