import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IntegratedSourceModel } from 'src/app/models/api/models/IntegratedSourceModel';
import { GenCatalog, GenStreamInfo } from '../create-datasource-int/define-streams.model';
import { ConsoleLogger, LogLevel } from 'src/app/views/designer/components/workflow-graph/logger';

// Nutze Katalog Struktur aus airbyte-staging.service.ts Catalog

/**
 * Definiert die Datenströme/Tabellen/Entitäten, die von der dieser Datenquelle extrahiert werden sollen.
 */
@Component({
  selector: 'app-define-streams-int',
  templateUrl: './define-streams-int.component.html',
  styleUrls: ['./define-streams-int.component.scss']
})
export class DefineStreamsIntComponent implements OnInit {

//   @Input()
//   public catalog: GenCatalog;  // TODO: add information, icons etc.
  @Input()
  // public genCatalog:IntegratedSourceModel.GenCatalog;  // New Version from Backend.
  public set genCatalog(value: IntegratedSourceModel.GenCatalog) {
    this._genCatalog = value;
    if (value !== undefined) {
      const selected_streams = value.streams.filter(s => s.config.selected);
      this.gen_selected_stream = selected_streams;
    }
  }
  public get genCatalog(): IntegratedSourceModel.GenCatalog { return this._genCatalog; }
  private _genCatalog: IntegratedSourceModel.GenCatalog;

  @Input()
  public writeModeInfos: IntegratedSourceModel.GenCatalog.WriteModeInfo[] = [];

  @Input()
  public disabled: boolean = false;

//   @Output()
//   public onStreamsDefined: EventEmitter<IntegratedSourceModel.GenCatalog> = new EventEmitter<IntegratedSourceModel.GenCatalog>();
  @Output()
  public onStreamsChanged: EventEmitter<IntegratedSourceModel.GenCatalog> = new EventEmitter<IntegratedSourceModel.GenCatalog>();

  private logger = new ConsoleLogger("CreateDatasourceIntComponent", LogLevel.Info);
  selected_streams: GenStreamInfo[] = [];
  gen_selected_stream: IntegratedSourceModel.GenCatalog.StreamInfo[] = [];

  constructor() { }

  ngOnInit(): void {
    //this.catalog.streams
  }

  /**
   * Die Stream-Auswahl hat sich geändert. Der Katalog wird aktualisiert
   * @param event
   */
  streamSelectionChanged(event: any) {
    console.log("Stream selection changed", event);

    // setze alle auf nicht-ausgewählt
    this._genCatalog.streams.forEach(s => s.config.selected = false);

    // setzt neue Selektion auf ausgewählt
    const new_selection: IntegratedSourceModel.GenCatalog.StreamInfo[] = <IntegratedSourceModel.GenCatalog.StreamInfo[]>event;
    new_selection.forEach(s => s.config.selected = true);

    this.gen_selected_stream = new_selection;

    this.onStreamsChanged.emit(this._genCatalog)
    //this.onStreamsDefined.emit(this.genCatalog)

    //this._genCatalog.streams = [...new_selection];



  }

//   onDefineStreams(event?: any) {

//     this.logger.debug("On Define Streams");

//     // TODO: add checks.

//     // TODO: write selected streams on selection info
//     this.catalog.streams.forEach(stream => {
//       const selected = this.selected_streams.find(s => s == stream) !== undefined;
//       stream.config.selected = selected;
//     })

//     this.logger.debug("Catalog to emit", this.catalog);

//     this.onStreamsDefined.emit(this.catalog);
//   }

  // 1. Load given Datasource
  // 2. Get Catalog
  // 3. Select Streams in Catalog

  // Currently we have two Catalog types, so the component will integrate them.

  datasource: IntegratedSourceModel.DataSourceKey<number>;

  getDatasourceContext(): IntegratedSourceModel.DataSourceKey<number> {
    throw new Error("Not implemented -> fetch from route or other context injection");
  }

}
