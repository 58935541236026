import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { DataStore, DataStoreField, DataStoreTypeInfo } from '../models/datastore.model';
import { ApiBackendService } from './api-backend.service';
import * as dss from '../models/datasource.model';
import { environment } from "../../environments/environment";
import { HttpClient } from '@angular/common/http';
import { ApiServiceUtils } from './api-util';
import {DataStore as DataStoreNs} from '../models/api/models/staging/DataStore.ns';
import { DestinationActionEvent } from '../models/dialog-actions.model';


@Injectable({
  providedIn: 'root'
})
export class CubesService {

    //@Output() displayNewDataStoreDialog = new EventEmitter<boolean>();
    //@Output() displayEditDataStoreDialog = new EventEmitter<boolean>();
    //@Output() displayDeleteDataStoreDialog = new EventEmitter<boolean>();
    //@Output() displayExportDataStoreDialog = new EventEmitter<boolean>();
    @Output() displayNewDestinationDialog = new EventEmitter<boolean>();
    //@Output() displayDeleteDataStoreDataPackageDialog = new EventEmitter<boolean>();
	@Output() destinationDialogActionSendEmitter = new EventEmitter<DestinationActionEvent>();



    @Output() dataStoresChangedEmitter = new EventEmitter<DataStore>();
    @Output() newDataStoreEmitter = new EventEmitter<DataStore>();
    // @Output() deletedDataStoreEmitter = new EventEmitter<DataStore>();
    // @Output() updatedDataStoreEmitter = new EventEmitter<DataStore>();
    @Output() selectedDataStoreEmitter = new EventEmitter<DataStore>();
    @Output() selectedMenuDataStoreEmitter = new EventEmitter<DataStore>();

    readonly BackEndUrl = environment.API_URL;

    constructor(private api: ApiBackendService, private http: HttpClient) { }

    getDataStoreObjectList(id?: number): Observable<DataStore[]> {
        return this.api.getDataStoreObjectList(id);
    }

    public createDataStoreObject(datastore: DataStore): Observable<DataStore> {
        return this.api.createDataStoreObject(datastore);
    }
    public updateDataStoreObject(datastore: DataStore) {
        return this.api.updateDataStoreObject(datastore);
    }
    public deleteDataStoreObject(id: number) {
        return this.api.deleteDataStoreObject(id);
    }

    public queryDataStore(dataStore: number, limit?: number, offset?: number): Observable<dss.DataTypePreview> {
        return this.api.queryDataStore(dataStore,limit,offset);
    }

    public datastoreApiQuery(dataStore: number, limit?: number, offset?: number, useLastDelta:boolean=false,
		includeMetaFields:boolean=false, lastPackageOnly:boolean=false) {
        return this.api.datastoreApiQuery(dataStore, limit, offset, useLastDelta,
            includeMetaFields, lastPackageOnly);
    }

    public datastoreApiCount(dataStore: number) {
        return this.api.datastoreApiCount(dataStore);
    }


    public getDataStoreField(id?:number) : Observable<DataStoreField[]> {
		return this.api.getDataStoreField(id);
	}
	public createDataStoreField(item:DataStoreField) : Observable<DataStoreField[]> {
		return this.api.createDataStoreField(item);
	}
	public updateDataStoreField(item:DataStoreField) : Observable<DataStoreField[]> {
		return this.api.updateDataStoreField(item);
	}
	public deleteDataStoreField(id?:number) : Observable<number> {
		return this.api.deleteDataStoreField(id);
	}

    public getDataStoreTypes() : Observable<DataStoreTypeInfo[]> {
        return this.api.getDataStoreTypes();
    }

    // Package Tracking
    public getDataPackage(id?: number, dataStore?: number): Observable<DataStoreNs.DataPackage[]> {
		const options = ApiServiceUtils.makeOptions({ param: "id", value: id }, { param: "dataStore", value: dataStore });
		return this.http.get<DataStoreNs.DataPackage[]>("/api/DataWarehouse/DataStore/Tracking/Packages", options);
	}
    public deleteDataPackage(id: number): Observable<number> {
		const options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.delete<number>("/api/DataWarehouse/DataStore/Tracking/Packages", options);
	}

    public getPackageTracking(): Observable<DataStoreNs.PackageTracking[]> {
		const options = ApiServiceUtils.makeOptions();
		return this.http.get<DataStoreNs.PackageTracking[]>("/api/DataWarehouse/DataStore/Tracking", options);
	}

    // Migrations
    public getMigrationLog(): Observable<DataStoreNs.MigrationLog[]> {
		const options = ApiServiceUtils.makeOptions();
		return this.http.get<DataStoreNs.MigrationLog[]>("/api/DataWarehouse/DataStore/Migrations", options);
	}

    // Data Package Protocols
    public getDataPackageProtocols(id?: number, dataStore?: number): Observable<DataStoreNs.PackageProtocolEntry[]> {
		const options = ApiServiceUtils.makeOptions({ param: "id", value: id }, { param: "dataStore", value: dataStore });
		return this.http.get<DataStoreNs.PackageProtocolEntry[]>("/api/DataWarehouse/DataStore/Tracking/PackageProtocols", options);
	}
}
