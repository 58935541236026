import { Component, OnInit } from '@angular/core';
import { LimitRowsPlugIn } from 'src/app/models/api/com/bion/etl/transformation/LimitRowsPlugIn';
import { WorkflowNodeSettings } from 'src/app/models/api/com/bion/etl/Workflow';
import { DesignerService } from 'src/app/services/designer.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { NodeConfigComponentBase } from '../node-config-component-base';

@Component({
  selector: 'app-limit-rows-node',
  templateUrl: './limit-rows-node.component.html',
  styleUrls: ['./limit-rows-node.component.scss']
})
export class LimitRowsNodeComponent extends NodeConfigComponentBase<LimitRowsPlugIn.Settings>
  implements OnInit {
  settingsToView(settings: WorkflowNodeSettings): void {
    // -- Get Configuration
    let currentConfig = <LimitRowsPlugIn.Settings>settings;
    // -- Get Input Meta Data
    const meta_infos =
      this._currentWorkflowNodeAdapter.IWorkflowNode.Properties.MetaInfo.get(
        this.InputPort
      );

    //let columnListArray: string[] = [];

    // create SelectInfoViews from DsFields for table
    if (meta_infos != undefined && meta_infos.length > 0) {
      // const input_field_infos = meta_infos[0].FieldsInfo;

      // columnListArray = input_field_infos.map((fieldInfo) => {
      //   return fieldInfo.Name;
      // });
      // this.columnList = columnListArray;
    }

    // --If currentConfig not available (-> new node)
    if (currentConfig == undefined) {
      //this.infoList = [];
      this.startRow = undefined;
      this.maxRows = undefined;

      //this.selectedMatchMode = this.matchModeOptions[0];
    } else {
      // -- otherweise load existing config into view
      if (!currentConfig.LimitRowsInfos || currentConfig.LimitRowsInfos.length === 0) return;

      // currentConfig.LimitRowsInfos.map((info) => {
      //   if (columnListArray.includes(info.ColumnName)) {
      //     // -- column still exists, do nothing
      //   } else {
      //     // column does not exist anymore, meaning column name currently not included -> include
      //     columnListArray.push(info.ColumnName);
      //   }
      // });
      // this.columnList = columnListArray;
      if (meta_infos !== undefined) {

        this.startRow = currentConfig.LimitRowsInfos[0].StartRow ? currentConfig.LimitRowsInfos[0].StartRow : undefined;
        this.maxRows = currentConfig.LimitRowsInfos[0].MaxRows ? currentConfig.LimitRowsInfos[0].MaxRows : undefined;

      }

    }
  }
  viewToSettings(): LimitRowsPlugIn.Settings {
    const newSettings = <LimitRowsPlugIn.Settings>{
      ...this.getCurrentWorkflowNode().Properties.Configuration,
    };

    const info = new LimitRowsPlugIn.LimitRowsInfo(this.startRow,this.maxRows)

    newSettings.LimitRowsInfos = [info];

    return newSettings

  }
  onSettingsChanged(settings: LimitRowsPlugIn.Settings): void {
    throw new Error('Method not implemented.');
  }

  constructor(
    protected workflowService: WorkflowsService,
    protected designerService: DesignerService
  ) {
    super(workflowService, designerService);
  }

  startRow?: number;
  maxRows?: number;
  readonly InputPort = "Input";
  columnList: string[] = [];

  ngOnInit(): void {
  }

}
