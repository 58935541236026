<div style="display:flex;align-items: center;margin-bottom: 20px">
    <img [alt]="'HeaderIcon'" src="assets/layout/images/HeaderIcon_New.svg" width="50" style="vertical-align: middle" />
    <h2 style="margin: 0px 10px;color:#6941C6;font-weight: bold;">{{'CreateNewDatasource' | translate }}</h2>
</div>
<div style="height:4px">
    <p-progressBar *ngIf="isLoading" [mode]="progressMode" [style]="{'height': '4px'}"></p-progressBar>
</div>
<div class="flex justify-content-center">
    <p-breadcrumb class="max-w-full" [model]="items"></p-breadcrumb>
</div>
<p-messages [(value)]="messages" [enableService]="false"></p-messages>
<div class="fixed-button">
    <p-button label="Streams definieren" (onClick)="define_streams_click($event)"></p-button>
</div>
<div>
    <app-define-streams-int #defineStreams [writeModeInfos]="write_mode_infos" [genCatalog]="new_generic_catalog"
        (onStreamsChanged)="on_streams_changed($event)"></app-define-streams-int>
</div>