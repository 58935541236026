import { mxCell } from "mxgraph";
import { SchedulerNodeInfo, SchedulerPlugInInfo, ScheduleWorkflow } from "src/app/services/dummy-backend.service";
import { mxSchedulerEdgeValue, mxSchedulerGraph, mxSchedulerGraphEx, mxSchedulerNodeValue, mxSchedulerPortValue } from "./mxSchedulerGraph";
import { NodeStyleInfo } from "./node-styles";
import { SchedulerGraph } from "src/app/models/api/models/scheduler/SchedulerGraph"
import schedule_graph_1_json from 'src/assets/test/scheduler-graph-1.json';
import { Arrays } from "src/app/helper/arrays";

/**
 * Scheduler mxGraph to Scheduler Workflow Object
 * @param A Encoding Settings Type
 */
export interface SchedulerGraphEncoder<A> {
	encode(graph: mxSchedulerGraph, settings: A): SchedulerGraph.Graph
}

/**
 * Scheduler Workflow Object to Scheduler mxGraph
 * @param A Decoding Settings Type
 */
export interface SchedulerGraphDecoder<A> {
	decode(workflow: SchedulerGraph.Graph, settings: A): mxSchedulerGraph
}

/**
 * Encoder/Decoder
 * @param A Decode Settings
 * @param B Encode Settings
 */
export interface SchedulerGraphCodec<A, B> extends SchedulerGraphDecoder<A>, SchedulerGraphEncoder<B> {
}

export class SchedulerGraphCodecImpl implements SchedulerGraphCodec<DecodeSettings, void> {

	baseSettings: CodecBaseSettings;

	constructor(settings: CodecBaseSettings) {
		this.baseSettings = settings;
	}

	encode(graph: mxSchedulerGraph, settings: void): SchedulerGraph.Graph {

		const parent = (graph as any).getDefaultParent();
		const node_cells: Array<mxCell> = (graph as any).getWorkflowCells(parent);

		// Head
		// workflow.Name = graph.getName();
		// workflow.Context = graph.getContext();

		// Nodes
		const nodes = node_cells.map((c) => this.cellToNode(c));

		// Edges
		const port_cell_arrays = node_cells.map((c) => mxSchedulerGraphEx.getPorts(c));
		const port_cells = Arrays.flatten(port_cell_arrays);
		//const port_cells = [].concat(...port_cell_arrays);
		this.log(port_cells);

		// Edges - Drop Duplicates
		const port_edge_arrays = port_cells.map((pc) => graph["getEdges"](pc));
		//const port_edges: Array<mxCell> = [].concat(...port_edge_arrays);
		const port_edges: Array<mxCell> = Arrays.flatten(port_edge_arrays);
		const port_edges_set = new Set<mxCell>(port_edges);
		const port_edges_unique = Array.from(port_edges_set.values());

		const workflow_edges = port_edges_unique.map((e) => this.edgeCellToEdge(e));

		const workflow = new SchedulerGraph.Graph(nodes, workflow_edges);
		this.log(workflow);
		return workflow;
	};

	logCallback?: (s: any) => void;

	protected log(message: any, is_error: boolean = false) {
		if (this.baseSettings.HoldOnError && is_error) throw new Error(message);
		else {
			if (this.logCallback !== undefined) this.logCallback(message);
		}
	}


	cellToNode(cell: mxCell): SchedulerGraph.Node {
		const cell_value = <mxSchedulerNodeValue>cell.getValue();

		const geo = cell.getGeometry();
		const gui_settings = new SchedulerGraph.GuiSettings(geo.getPoint().x, geo.getPoint().y, geo.width, geo.height);

		const node = new SchedulerGraph.Node(cell.getId(), gui_settings, cell_value.Node.Properties, cell_value.Node.EngineInfo);
		return node;
	}

	edgeCellToEdge(cell: mxCell): SchedulerGraph.Edge {

		const inPort = cell.getTerminal(true);
		const outPort = cell.getTerminal(false);
		this.log(inPort);
		this.log(outPort);
		this.log("In Cell is Port: " + mxSchedulerGraphEx.cellIsPort(inPort));
		this.log("Out Cell is Port" + mxSchedulerGraphEx.cellIsPort(outPort));

		const inNode = inPort.getParent();
		const outNode = outPort.getParent();

		this.log(inNode);
		this.log(outNode);

		// const inCellId = (<WorkflowNode>inNode.getValue()).ID;
		// const outCellId = (<WorkflowNode>outNode.getValue()).ID;
		const inCellId = inNode.getId();
		const outCellId = outNode.getId();
		const inPortName = (<mxSchedulerPortValue>inPort.getValue()).PortInfo.Name;
		const outPortName = (<mxSchedulerPortValue>outPort.getValue()).PortInfo.Name;

		// check label
		// let edge_label: string = undefined;
		// if (mxSchedulerGraphEx.cellIsWorkflowEdge(cell)) {
		// 	const wf_edge = <mxSchedulerEdgeValue>cell.getValue();
		// 	edge_label = wf_edge.Name;
		// }

		const workflowEdge = new SchedulerGraph.Edge(inCellId, inPortName, outCellId, outPortName);
		// workflowEdge.Name = edge_label;

		return workflowEdge;
	}


	/**
	 * Converts a workflow graph to a mxWorkflowGraph
	 * @param workflow A Workflow
	 * @param settings Decode Settings
	 * @returns The mxWorkflowGraph
	 */
	decode(workflow: ScheduleWorkflow, settings: DecodeSettings): mxSchedulerGraph {



		throw new Error("Not Implmeneted");
	}
}

export class SchedulerGraphDecoderImpl implements SchedulerGraphDecoder<DecodeSettings> {

	baseSettings: CodecBaseSettings;

	constructor(settings: CodecBaseSettings) {
		this.baseSettings = settings;
	}

	decode(workflow: SchedulerGraph.Graph, settings: DecodeSettings): mxSchedulerGraph {

		const graph = new mxSchedulerGraph(settings.Container);

		// Workflow
		// graph.setName(workflow.Name);
		// graph.setContext(workflow.Context);

		// -- update model
		// const model = graph.getModel();
		const model = graph["getModel"]();
		model.beginUpdate();
		try {

			// collect node styles and custom gui information
			const node_info_map = new Map<string, SchedulerNodeInfo>();
			settings.NodeInfo.map((ni) => node_info_map.set(ni.EngineInfo.Name, ni));

			const parent = (graph as any).getDefaultParent();

			// add nodes
			const mxNodes = workflow.Nodes.map(n => {

				if (!node_info_map.has(n.EngineInfo.Name))
					this.log("No Style available for " + n);

				const nodeInfo = node_info_map.get(n.EngineInfo.Name);

				if (nodeInfo)
					return this.insertWorkflowNode(graph, n, parent, nodeInfo, settings);
				else
					throw new Error("No node info available for: " + n.EngineInfo.Name);
			});

			console.log(graph);
			console.log("Create Edges");

			// add edges
			const mxEdges = workflow.Edges.map((e) => this.workflowEdgeToCell(e, graph, parent));
		} finally {
			model.endUpdate();
		}

		return graph;
	}


	logCallback?: (s: any) => void;

	protected log(message: any, is_error: boolean = false) {
		if (this.baseSettings.HoldOnError && is_error) throw new Error(message);
		else {
			if (this.logCallback !== undefined) this.logCallback(message);
		}
	}


	/**
 * Connects the port cells based on the workflow edge. The ports must exist at this point.
 * @param workflowEdge Workflow Edge
 * @param graph graph containing the nodes and their ports as mxCells.
 */
	workflowEdgeToCell(
		workflowEdge: SchedulerGraph.Edge,
		graph: mxSchedulerGraph,
		parent: mxCell
	): mxCell {
		// Find Node Cells
		const sourceNodes = graph.lookupWorkflowNode(
			workflowEdge.SourceNode,
			parent
		);
		if (sourceNodes.length === 0)
			this.log("No In Node Cell found for Node ID " + workflowEdge.SourceNode);

		const targetNodes = graph.lookupWorkflowNode(
			workflowEdge.TargetNode,
			parent
		);
		if (targetNodes.length === 0)
			this.log("No In Node Cell found for Node ID " + workflowEdge.TargetNode);

		// Find Port Cells
		const sourceNode = sourceNodes[0];
		const sourceNodePort = this.findTargetPortCell(
			sourceNode,
			workflowEdge.SourcePort
		);

		const targetNode = targetNodes[0];
		const targetNodePort = this.findTargetPortCell(
			targetNode,
			workflowEdge.TargetPort
		);

		// Create the Edge
		// const edge = graph.insertEdge(parent, null, null, sourceNodePort, targetNodePort);
		// const edge = graph.["insertEdge"](parent, null, null, sourceNodePort, targetNodePort);

		this.log("WARNING - Edge decoding maybe incomplete.");
		const edge_value = new mxSchedulerEdgeValue();
		// let edge_value: mxSchedulerEdgeValue = undefined;
		// if (workflowEdge.Name === undefined) {
		// 	edge_value = null;
		// } else {
		// 	edge_value = new mxSchedulerEdgeValue();
		// 	edge_value.Name = workflowEdge.Name;
		// }

		const edge = (graph as any).insertEdge(
			parent,
			null,
			edge_value,
			sourceNodePort,
			targetNodePort
		);

		return edge;
	}

	/**
 * Lookup the specific port cell in the given node cell.
 * @param nodeCell Node Cell to lookup
 * @param portName Port Name
 * @returns The Port Cell
 */
	findTargetPortCell(nodeCell: mxCell, portName: string): mxCell {
		const out_node_ports = mxSchedulerGraphEx.getPorts(nodeCell);
		const out_port_infos = out_node_ports.filter(
			(c) => (<mxSchedulerPortValue>c.getValue()).PortInfo.Name == portName
		);

		if (out_port_infos.length == 0) {
			throw new Error("No Port Found for Port with ID " + portName);
		}

		if (out_port_infos.length > 1) {
			throw new Error("Multiple ports found for port ID " + portName);
		}

		return out_port_infos[0];
	}

	/**
 * Converts a Workflow Node to an mxCell
 * @param graph Graph
 * @param workflowNode Workflow Node
 * @param parent Parent Cell
 * @param nodeInfo Workflow Node Info for Style and Port settings
 * @returns The Workflow Node mxCell
 */
	insertWorkflowNode(
		graph: mxSchedulerGraph,
		workflowNode: SchedulerGraph.Node,
		parent: mxCell,
		nodeInfo: SchedulerPlugInInfo,
		settings: DecodeSettings
	): mxCell {
		let label = "";
		if (workflowNode.Properties.Annotation !== undefined)
			label = workflowNode.Properties.Annotation.Text;

		// Determine Node Style
		const defaultNodeStyle =
			"rounded=1;whiteSpace=wrap;html=1;shadow=1;labelPosition=center;verticalLabelPosition=bottom;align=center;verticalAlign=top;fontStyle=1;imageAlign=center;imageWidth=30;imageHeight=30;fillColor=#99CCFF;strokeColor=#88B6E3;";
		const nodeStyleKey = nodeInfo.Name;
		const nodeStyles = settings.NodeStyles;

		let finalNodeStyle = defaultNodeStyle;
		if (nodeStyles.has(nodeStyleKey)) {
			this.log("Custom Node style found for node with key: " + nodeStyleKey);
			const n_style = nodeStyles.get(nodeStyleKey);
			if (n_style)
				finalNodeStyle = n_style.style;
		} else {
			this.log("Node style not found for " + nodeStyleKey + ". Using default Style");
		}
		this.log(finalNodeStyle);

		// // Insert Vertex: ID is null (auto generated by mxGraph). TODO: add 2nd ID for workflow edges
		// const gui = workflowNode.GuiSettings;
		// const id = workflowNode.ID;
		// // const v1 = graph.insertVertex(parent, id, label, gui.X, gui.Y, gui.Width, gui.Height, finalNodeStyle);
		// // const v1 = graph.["insertVertex"](parent, id, label, gui.X, gui.Y, gui.Width, gui.Height, finalNodeStyle);
		// const v1 = (graph as any).insertVertex(parent, id, label, gui.X, gui.Y, gui.Width, gui.Height, finalNodeStyle);
		// v1.setConnectable(false);

		// // Presets the collapsed size
		// v1.geometry.alternateBounds = new mx.mxRectangle(0, 0, 120, 40);

		const v1 = mxSchedulerGraphEx.insertWokflowNode(
			graph,
			parent,
			workflowNode,
			nodeInfo.Ports,
			finalNodeStyle
		);

		// Create & add the ports at various relative locations
		const inPorts = mxSchedulerGraphEx.createPorts(v1, nodeInfo, graph, true);
		const outPorts = mxSchedulerGraphEx.createPorts(v1, nodeInfo, graph, false);
		const ports = inPorts.concat(outPorts);
		this.log(ports);

		ports.forEach((p) => (graph as any).addCell(p, v1));

		// Add the workflow node value
		// v1.value = workflowNode;

		return v1;
	}
}

/**
 * Common Settings for Encoding/Decoding.
 */
export class CodecBaseSettings {
	HoldOnError: boolean;
	constructor(holdOnError: boolean) {
		this.HoldOnError = holdOnError;
	}
}

/**
 * Decoding Settings.
 *
 *
 * Requires an HTML Container for the mxGraph and Node Style Information.
 */
export class DecodeSettings {
	constructor(
		container: HTMLElement,
		nodeStyles: Map<string, NodeStyleInfo>,
		nodeInfo: SchedulerNodeInfo[]
	) {
		this.Container = container;
		this.NodeStyles = nodeStyles;
		this.NodeInfo = nodeInfo;
	}

	Container: HTMLElement;
	NodeStyles: Map<string, NodeStyleInfo>;
	NodeInfo: SchedulerNodeInfo[];
}

/**
 * Test class for the codec and graph serialization.
 */
class TestGraphCodec {
	/**
	 * Test if FE & BE use the same graph structure.
	 * @returns A graph object, if the backend returned a correct serialized JSON.
	 */
	static testStructure(): SchedulerGraph.Graph {
		const graph: SchedulerGraph.Graph = schedule_graph_1_json;
		console.log(graph);
		return graph;
	}
}
