import {
	Component,
	Input,
	OnDestroy,
	OnInit,
	SimpleChanges,
} from "@angular/core";
import { forkJoin } from "rxjs";
import { AdapterTypeInfo } from "src/app/models/connector.model";
import * as dss from "src/app/models/datasource.model";
import { DatasourcesService } from "src/app/services/datasources.service";
import { SubSink } from "subsink";

export class DataTypesChart {
	name: string;
	progress: number;
	count: number;

	constructor(name: string, progress: number, count: number) {
		this.name = name;
		this.progress = progress;
		this.count = count
	}
}

export class ConnectorInfo {
	data_source: number;
	connector: string;
	settings: string;

	constructor(data_source: number, connector: string, settings: string) {
		this.data_source = data_source;
		this.connector = connector;
		this.settings = settings
	}
}

@Component({
	selector: "app-datasource-charts",
	templateUrl: "./datasource-charts.component.html",
	styleUrls: ["./datasource-charts.component.scss"],
})
export class DatasourceChartsComponent implements OnInit, OnDestroy {
	subs = new SubSink();
	@Input() fieldInfos: dss.DataSourceField[] = [];

	fieldInfosFiltered: dss.DataSourceField[] = [];

	dataTypesCountList: DataTypesChart[] = [];
	dataTypesCountFilteredList: DataTypesChart[] = [];

	selectedDatasource?: dss.DataSource;
	adapterTypeInfos: AdapterTypeInfo<any, any>[] = [];
	adapterFilteredCount: number[] = [];

	pieData: any;
	pieOptions: any;

	constructor(private datasourceService: DatasourcesService) {}
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.fieldInfos.currentValue) {
			// Change detection if changes occured
			let fieldInfos: dss.DataSourceField[] = changes.fieldInfos.currentValue;

			let dataTypesList = fieldInfos.map((field) => {
				return field.dataType;
			});
			//console.log(dataTypesList);
			let dataTypesListSet = new Set(dataTypesList);
			let dataTypesArray = [];

			for (let type of dataTypesListSet) {
				// Iterate through list to get counts of each datatype
				let counter = 0;
				for (let i of dataTypesList) {
					if (i === type) {
						counter++;
					}
				}

				//Init new component class
				//newEntry.name = type;
				//newEntry.count = counter;
				let progress = (counter / dataTypesList.length) * 100;
				const progress_number = parseInt(progress.toFixed(2));

				let newEntry = new DataTypesChart(type,progress_number,counter);


				//Push new entry to final Array
				dataTypesArray.push(newEntry);
			}
			this.dataTypesCountList = dataTypesArray.sort(
				(a, b) => b.count - a.count
			);
			this.dataTypesCountFilteredList = dataTypesArray.sort(
				(a, b) => b.count - a.count
			);
			this.createPieChart();
		}
	}

	createChart(ds?: dss.DataSource) {
		let fieldInfos: dss.DataSourceField[] = [];

		if (!ds) {
			fieldInfos = this.fieldInfos;
			let dataTypesList = fieldInfos.map((field) => {
				return field.dataType;
			});
			//this.fieldInfosFiltered = fieldInfos;
		} else {
			fieldInfos = this.fieldInfos.filter((field) => {
				return field.dataSource === ds.id;
			});
			let dataTypesList = fieldInfos.map((field) => {
				return field.dataType;
			});
		}

		// Create DataTypes Chart
		let dataTypesList = fieldInfos.map((field) => {
			return field.dataType;
		});
		let dataTypesListSet = new Set(dataTypesList);
		let dataTypesArray = [];

		for (let type of dataTypesListSet) {
			// Iterate through list to get counts of each datatype
			let counter = 0;
			for (let i of dataTypesList) {
				if (i === type) {
					counter++;
				}
			}

			//Init new component class
			//let newEntry = new DataTypesChart();
			//newEntry.name = type;
			//newEntry.count = counter;
			let progress = (counter / dataTypesList.length) * 100;
			const progress_number = parseInt(progress.toFixed(2));

			let newEntry = new DataTypesChart(type,progress_number,counter);
			//Push new entry to final Array
			dataTypesArray.push(newEntry);
		}

		this.dataTypesCountFilteredList = dataTypesArray.sort(
			(a, b) => b.count - a.count
		);

		this.createPieChart(ds);
	}
	createPieChart(ds?: dss.DataSource) {
		// Get AdapterTypes & ConnectorAdapter
        let adapterTypeInfoObs = this.datasourceService
        .getAdapterTypeInfo();
        let connectorInfoObs = this.datasourceService.getConnectorsInfo();

        this.subs.sink = forkJoin(adapterTypeInfoObs,connectorInfoObs).subscribe(res => {
            let adapterTypeInfos = res[0];
            let connectorInfos = res[1];

            if (ds) {
                connectorInfos = connectorInfos.filter((val) => {
                    return val.DataSource === ds.id;
                });
            }
            // Create new Array with respective adapter
            let adapterFiltered: AdapterTypeInfo<any,any>[] = [];
            connectorInfos.map((connector: dss.DataSourceConnector<any>) => {
                adapterTypeInfos.map((adapter: AdapterTypeInfo<any,any>) => {
                    if (adapter.Name === connector.Connector)
                        adapterFiltered.push(adapter);
                });
            });

            // Create PieChart Label
            let pieLabels: string[] = [];
            adapterFiltered.map((i: AdapterTypeInfo<any,any>) => {
                pieLabels.push(i.Name);
            });

            let pieLabelSet = new Set(pieLabels);
            let pieLabelSetArray = [];

            for (let item of pieLabelSet.values()) {
                pieLabelSetArray.push(item);
            }

            // Create chart objects with counts
            let adapterFilteredSet = new Set(adapterFiltered);
            let adapterFilteredCount: number[] = [];

            for (let type of adapterFilteredSet) {
                // Iterate through list to get counts of each datatype
                let counter = 0;
                for (let i of adapterFiltered) {
                    if (i === type) {
                        counter++;
                    }
                }

                //Push new entry to final Array
                adapterFilteredCount.push(counter);
            }
            this.adapterFilteredCount = adapterFilteredCount;

            // Initialize pieChart data
            this.pieData = {
                labels: pieLabelSetArray,
                datasets: [
                    {
                        data: adapterFilteredCount,
                        backgroundColor: [
                            "rgb(118, 189, 220)",
                            "rgb(118, 189, 220)",
                            "rgb(205, 234, 250)",
                            "rgb(205, 234, 250)",
                            "rgb(205, 234, 250)",
                            "rgb(242, 249, 253)",
                        ],
                    },
                ],
            };
        })
	}

	ngOnInit(): void {
		this.pieOptions = {
			plugins: {
				legend: {
					display: false,
                    position: 'left',
					labels: {
						fontColor: "#A0A7B5",
					},
				},
			},
		};
		this.subs.sink = this.datasourceService.selectedDatasourceEmitter.subscribe(
			(ds: dss.DataSource) => {
				if (ds === this.selectedDatasource) {
					this.selectedDatasource = undefined;
					this.createChart();
					return;
				}
				this.selectedDatasource = ds;

				this.createChart(ds);
			}
		);
	}
}
