<p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>


<p-dialog header="{{headerText | translate}}" [(visible)]="displayNewFrequency" modal="modal" showEffect="fade"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '45vw'}" (onHide)="resetCurrentForm()">

    <div class="chat" style="margin-top: 10px;">
        <p-tabView [(activeIndex)]="activeIndex" (onChange)="onTabChanged($event)">
            <div class="p-grid p-fluid" style="margin-top: 40px">
                <div class="p-col-6">
                    <div class="p-fluid">
                        <span class="p-float-label">
                            <p-calendar appendTo=body [(ngModel)]="startDate" [inline]="false" id="startDatetext"
                                [showIcon]="true" inputId="startDatetext">
                            </p-calendar>
                            <label for="startDatetext">{{'StartDate' | translate}}</label>
                        </span>
                    </div>
                </div>
                <div class="p-col-6">
                    <div class="p-fluid">
                        <span class="p-float-label">
                            <p-calendar appendTo=body [(ngModel)]="endDate" [inline]="false" [showIcon]="true"
                                id="endDatetext" inputId="endDatetext">
                            </p-calendar>
                            <label for="endDatetext">{{'EndDate' | translate}}</label>
                        </span>
                    </div>
                </div>
                <div class="p-col-6 ">
                    <div class="p-fluid">
                        <span class="p-float-label">
                            <p-dropdown id="timeZoneInput" appendTo="body" [options]="time_zones" [(ngModel)]="system_time_zone"
                                optionLabel="ID" editable="false"></p-dropdown>
                            <label for="timeZoneInput">{{'TimeZone' | translate}}</label>
                        </span>
                    </div>
                </div>
                <div class="p-col-6">
                    <div class="p-fluid">
                        <span class="p-float-label">
                            <p-inputMask mask="99:99:99" id="Timetext" [(ngModel)]="clockTime"></p-inputMask>
                            <label for="Timetext">{{'Time' | translate}}</label>
                        </span>
                    </div>
                </div>
            </div>
            <p-tabPanel header="{{'Weekly' | translate}}">
                <ng-template pTemplate="header">
                    <i class="pi pi-calendar"></i>
                    <span class="p-badge">{{'Weekly' | translate}}</span>
                </ng-template>
                <div class=" p-grid p-fluid field-radiobutton">
                    <div class="p-col-6">
                        <p-radioButton name="every" [value]="false" (onClick)="setIsLastDayFlag()"
                            [(ngModel)]="isLastDayFlag" inputId="every"></p-radioButton>
                        <label for="every">{{'Every weekday' | translate}}</label>

                        <p-selectButton [options]="weekOptions" [disabled]="isLastDayFlag === true"
                            [(ngModel)]="selectedWeekOptions" (onChange)="onSelectWeekOptions($event)"
                            multiple="multiple" optionLabel="{{'name' | translate}}">
                        </p-selectButton>
                    </div>
                    <div class="p-col-6">
                        <p-radioButton name="last" [value]="true" (onClick)="setIsLastDayFlag()"
                            [(ngModel)]="isLastDayFlag" inputId="last"></p-radioButton>
                        <label for="last">{{'Last weekday' | translate}}</label>

                        <p-dropdown [showClear]="false" appendTo="body" [disabled]="isLastDayFlag === false" id="LastDayIndexInput"
                            [options]="weekOptions" [autoDisplayFirst]="false" [(ngModel)]="selectedLastDayIndex"
                            optionLabel="name" optionValue="value">
                        </p-dropdown>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="{{'Monthly' | translate}}">
                <ng-template pTemplate="header">
                    <i class="pi pi-calendar"></i>
                    <span class="p-badge">{{'Monthly' | translate}}</span>
                </ng-template>
                <div class=" p-grid p-fluid field-radiobutton">
                    <div class="p-col-6">
                        <p-radioButton name="every" [value]="false" (onClick)="setIsLastDayFlag()"
                            [(ngModel)]="isLastDayFlag" inputId="every"></p-radioButton>
                        <label for="every"> Day(s) of month</label>

                        <p-calendar [defaultDate]="defaultDate" [(ngModel)]="selectedMonthOptions"
                            [disabled]="isLastDayFlag === true" selectionMode="multiple" [inline]="true"
                            [showWeek]="false" [showOtherMonths]="false" [showButtonBar]="false"
                            [monthNavigator]="false" [yearNavigator]="false">
                        </p-calendar>
                    </div>
                    <div class="p-col-6">
                        <p-radioButton name="last" [value]="true" (onClick)="setIsLastDayFlag()"
                            [(ngModel)]="isLastDayFlag" inputId="last"></p-radioButton>
                        <label for="last"> Last day of month</label>

                        <p-inputNumber [max]="0" [(ngModel)]="LastDayOffset" [showButtons]="true" buttonLayout="vertical"
                            [disabled]="isLastDayFlag === false" spinnerMode="vertical" inputId="vertical"
                            decrementButtonClass="p-button-secondary" incrementButtonClass="p-button-secondary"
                            incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus">
                        </p-inputNumber>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>

    <app-system-message-log></app-system-message-log>

    <p-footer>
        <button pButton pRipple type="button" icon="pi pi-times" (click)="displayNewFrequency=false"
            class="p-button-rounded p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'No' | translate}}"></button>
        <button pButton pRipple type="button" icon="pi pi-check" (click)="validateCurrentForm()"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'Yes' | translate}}" [icon]="savingInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'"></button>
    </p-footer>
</p-dialog>
