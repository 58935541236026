import { Column } from '@amcharts/amcharts4/.internal/charts/elements/Column';
import { Component, OnInit } from '@angular/core';
import { FieldInfo, WorkflowNodeSettings } from 'src/app/models/api/com/bion/etl/Workflow';
import { AnonymizeInfo, AnonymizeSettings } from 'src/app/models/nodeSettings.model';
// import { FieldInfo, WorkflowNodeSettings } from 'src/app/models/workflow.model';
import { DesignerService } from 'src/app/services/designer.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { NodeConfigComponentBase } from '../node-config-component-base';

export class AnonymizeInfoView {
    ColumnName: string;
    AnonymizeMethod: string;
    CustomValue: string;
    DropInputColumn: boolean;
    hasError?: boolean;

    constructor(ColumnName: string, AnonymizeMethod: string, CustomValue: string, DropInputColumn: boolean, hasError?: boolean) {
        this.ColumnName = ColumnName;
        this.AnonymizeMethod = AnonymizeMethod;
        this.CustomValue = CustomValue;
        this.DropInputColumn = DropInputColumn;
        this.hasError = hasError;
    }
}

@Component({
    selector: 'app-anonymize-node',
    templateUrl: './anonymize-node.component.html',
    styleUrls: ['./anonymize-node.component.scss']
})
export class AnonymizeNodeComponent extends NodeConfigComponentBase<AnonymizeSettings> implements OnInit {
    settingsToView(settings: WorkflowNodeSettings): void {
        // -- Get Configuration
        let currentConfig = <AnonymizeSettings>settings;
        // -- Get Input Meta Data
        const meta_infos =
            this._currentWorkflowNodeAdapter.IWorkflowNode.Properties.MetaInfo.get(
                this.InputPort
            );
        let columnListArray: string[] = [];

        if(!meta_infos) {
            return
        }

        if (meta_infos.length === 0) {
            return;
        }

        // -- check for MetaInfos from Input Port & store dsField list
        if (meta_infos.length > 0) {
            const input_field_infos = meta_infos[0].FieldsInfo;

            // -- create string array for column dropdown
            columnListArray = input_field_infos.map((fieldInfo) => {
                return fieldInfo.Name;
            });
            this.columnList = columnListArray;
        }
        // -- if no configuration exists, set default
        if (currentConfig === undefined) {
            // -- create empty list
            this.anonymizeInfoList = [];
        } else {
            // -- otherwise load to F&E list from existing config
            // -- Check if metaInfo and F&E Info
            if (!currentConfig.AnonymizeInfos) return;

            currentConfig.AnonymizeInfos.map((info) => {
                if (columnListArray.includes(info.ColumnName)) {
                    // -- column still exists, do nothing
                } else {
                    // column does not exist anymore, meaning column name currently not included -> include
                    columnListArray.push(info.ColumnName);
                }
            });
            this.columnList = columnListArray;

            if (meta_infos[0] !== undefined) {
                let field_infos = meta_infos[0].FieldsInfo
                this.anonymizeInfoList = this.infoToView(currentConfig, field_infos);
            } else {

                this.anonymizeInfoList = this.infoToView(currentConfig);
            }

        }
    }
    viewToSettings(): AnonymizeSettings {
        const newSettings = <AnonymizeSettings>{
            ...this.getCurrentWorkflowNode().Properties.Configuration,
        };
        let newInfoList:AnonymizeInfo[] = [];

        this.anonymizeInfoList.map((entry) => {
            let newInfo = new AnonymizeInfo();
            newInfo.ColumnName = entry.ColumnName;
            newInfo.AnonymizeMethod = entry.AnonymizeMethod;
            newInfo.CustomValue = entry.CustomValue;
            newInfo.DropInputColumn = entry.DropInputColumn;

            newInfoList.push(newInfo);
        });

        newSettings.AnonymizeInfos = newInfoList;

        return newSettings;
    }
    onSettingsChanged(settings: AnonymizeSettings): void {
        throw new Error('Method not implemented.');
    }

    readonly InputPort = "Input";
    columnList: string[] = [];
    anonymizeInfoList: AnonymizeInfoView[] = [];
    anonymizeMethods: string[];

    constructor(
        protected workflowService: WorkflowsService,
        protected designerService: DesignerService
    ) {
        super(workflowService, designerService);

        this.anonymizeMethods = [
            "Custom Value", "Hash",
        ];
    }

    ngOnInit(): void {
        super.ngOnInit();
    }


    infoToView(config: AnonymizeSettings, meta?: FieldInfo[]): AnonymizeInfoView[] {
        let view: AnonymizeInfoView[] = [];
        config.AnonymizeInfos.map((info: AnonymizeInfo) => {

            // -- create selected SortMethod from config
            // newInfoView.ColumnName = info.ColumnName;
            // newInfoView.AnonymizeMethod = info.AnonymizeMethod;
            // newInfoView.CustomValue = info.CustomValue;
            // newInfoView.DropInputColumn = info.DropInputColumn;

            let fieldInfoFound = meta?.find((field) => {
                return field.Name === info.ColumnName;
            });
            let newInfoView = new AnonymizeInfoView(info.ColumnName,info.AnonymizeMethod,info.CustomValue,info.DropInputColumn,!fieldInfoFound);

            //newInfoView.hasError = !fieldInfoFound;

            view.push(newInfoView);
        });

        return view;
    }
    onAddEntry() {
        let newEntry = new AnonymizeInfoView(this.columnList[0], this.anonymizeMethods[0],"", true,false);
        // newEntry.ColumnName = this.columnList[0];
        // newEntry.AnonymizeMethod = this.anonymizeMethods[0];
        // newEntry.CustomValue = "";
        // newEntry.DropInputColumn = false;
        // newEntry.hasError = false;

        this.anonymizeInfoList.push(newEntry);
        this.onUpdateSettings(false);
    }
    onDeleteEntry(index: number) {
        this.anonymizeInfoList = [
            ...this.anonymizeInfoList.slice(0, index),
            ...this.anonymizeInfoList.slice(index + 1),
        ];
        this.onUpdateSettings(false);
    }
    /**
    * https://stackoverflow.com/questions/46391662/focusout-and-blur-not-working-in-angular
    * @param event
    */
    onFocusLost(event: FocusEvent) {
		const div_left: boolean = (event.relatedTarget === null);

		console.log("Send from Div: ", div_left);

		if (div_left) {
			this.onUpdateSettings(true);
		}
    }
}
