import { Injectable, Type } from "@angular/core";
import { ConfigItem } from "./config-item";
import { GoogleSheetsComponent } from "../create-ab-source-config/configViews/google-sheets/google-sheets.component";
import sourceDefinitionsJson from "./source-definitions.json"
import { PostgreSqlComponent } from "../create-ab-source-config/configViews/postgre-sql/postgre-sql.component";
import { UnknownSettingsComponent } from "../create-ab-source-config/configViews/unknown-settings/unknown-settings.component";
import { HubspotComponent } from "../create-ab-source-config/configViews/hubspot/hubspot.component";
import { StripeComponent } from "../create-ab-source-config/configViews/stripe/stripe.component";

/**
 * Liefert die individuellen Config-Dialoge für den Create Airbyte Source Dialog.
 */
@Injectable({
    providedIn: "root",
})
export class ConfigService {

    getViewItems(): ConfigItem[] {

        const all_sd_map = new Map<string,any>();

        const sd_sorted = sourceDefinitionsJson.sourceDefinitions.sort((a,b) => a.name.localeCompare(b.name));

        sourceDefinitionsJson.sourceDefinitions.forEach(sd => all_sd_map.set(sd.sourceDefinitionId,sd));

        const comp_sd_map = new Map<string,Type<any>>();
        comp_sd_map.set("71607ba1-c0ac-4799-8049-7f4b90dd50f7", GoogleSheetsComponent);
        comp_sd_map.set("decd338e-5647-4c0b-adf4-da0e75f5a750", PostgreSqlComponent);
        comp_sd_map.set("36c891d9-4bd9-43ac-bad2-10e12756272c", HubspotComponent);
        comp_sd_map.set("e094cb9a-26de-4645-8761-65c0c425d1de", StripeComponent);

        // Neue Liste
        const n_result:ConfigItem[] = [];
        const n_items = sd_sorted.map(sd => {

            const t = comp_sd_map.get(sd.sourceDefinitionId);
            let c:ConfigItem = undefined;
            if(t) {
                c = new ConfigItem(sd.sourceDefinitionId, t, sd);
            } else {
                c = new ConfigItem(sd.sourceDefinitionId, UnknownSettingsComponent, sd);
            }
            
            return c;
        })

        return n_items;
    }
}