import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable, Output } from "@angular/core";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { TargetMetaInfo } from "../models/api/com/bion/connect/TargetAccessLike";
import { DestinationTypeInfo } from "../models/api/controllers/connect/DestinationTypeInfo";
import { DestinationController } from "../models/api/controllers/DestinationController";
import { DestinationFieldRow, DestinationRow } from "../models/api/domain/NewDataSourceModelBase";
import { DestinationConnection } from "../models/api/models/staging/DestinationConnection";
import { WriteMode } from "../models/helperClass.model";
import { ApiServiceUtils } from "./api-util";

// export interface WriteModeInfo {
// 	Label:string;
// 	IsSupported:boolean;
// }

// export interface RecordWriteMode {
// 	Label:string;
// 	IsSupported:boolean;
// }

// export interface EntityWriteMode {
// 	Label:string;
// 	IsSupported:boolean;
// 	RecordWriteModes:Array<RecordWriteMode>;
// }

// export interface TargetWriteMode {
// 	Label:string;
// 	IsSupported:boolean;
// 	EntityWriteModes:Array<EntityWriteMode>;
// }




@Injectable({
	providedIn: "root",
})
export class DestinationService {
	readonly BackEndUrl = environment.API_URL;
	constructor(private http: HttpClient) {

	}
	/**
	 * Event Emitter
	 */
	@Output() displayNewDestinationDialog = new EventEmitter<boolean>();
	@Output() destinationChangedEmitter = new EventEmitter<string>();

	/**
	 * Adds the Back End API to the given url, e.g. '/my/collection' becomes '/api/my/collection'
	 * @param url The url which is appended to the back end url.
	 */
	api(url:string) : string {return this.BackEndUrl + url}

    public getDestinationTypeInfo(): Observable<DestinationTypeInfo<any,any>[]> {
		let options = ApiServiceUtils.makeOptions();
		return this.http.get<DestinationTypeInfo<any,any>[]>(this.api("/Staging/DestinationInfo"), options);
	}

	public getDestination(id?: number): Observable<DestinationRow[]> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.get<DestinationRow[]>(this.api("/Staging/Destinations"), options);
	}
	public createDestination(item: DestinationRow): Observable<DestinationRow> {
		return this.http.post<DestinationRow>(this.api("/Staging/Destinations"), item);
	}
	public updateDestination(item: DestinationRow): Observable<number> {
		return this.http.put<number>(this.api("/Staging/Destinations"), item);
	}
	public deleteDestination(id: number): Observable<number> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.delete<number>(this.api("/Staging/Destinations"), options);
	}

	public getDestinationField(id?: number, destination?: number): Observable<DestinationFieldRow[]> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id }, { param: "destination", value: destination });
		return this.http.get<DestinationFieldRow[]>(this.api("/Staging/DestinationFields"), options);
	}
	public createDestinationField(item: DestinationFieldRow): Observable<DestinationFieldRow> {
		return this.http.post<DestinationFieldRow>(this.api("/Staging/DestinationFields"), item);
	}
	public updateDestinationField(item: DestinationFieldRow): Observable<number> {
		return this.http.put<number>(this.api("/Staging/DestinationFields"), item);
	}
	public deleteDestinationField(id: number): Observable<number> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.delete<number>(this.api("/Staging/DestinationFields"), options);
	}

	public getDestinationConnection(id?: number): Observable<DestinationConnection<any>[]> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.get<DestinationConnection<any>[]>(this.api("/Staging/DestinationConnections"), options);
	}
	public createDestinationConnection(item: DestinationConnection<any>): Observable<DestinationConnection<any>> {
		return this.http.post<DestinationConnection<any>>(this.api("/Staging/DestinationConnections"), item);
	}
	public updateDestinationConnection(item: DestinationConnection<any>): Observable<number> {
		return this.http.put<number>(this.api("/Staging/DestinationConnections"), item);
	}
	public deleteDestinationConnection(id: number): Observable<number> {
		let options = ApiServiceUtils.makeOptions({ param: "id", value: id });
		return this.http.delete<number>(this.api("/Staging/DestinationConnections"), options);
	}

	public getMetaData<A>(arg:DestinationController.GetMetaDataArg<A>) : Observable<TargetMetaInfo> {
		return this.http.post<TargetMetaInfo>(this.api("/Staging/Destinations/api/getMetaData"), arg);
	}

	// (still) fake API

	// /**
	//  * Schreibmodus für die Datei/Datenbank/Endunkt
	//  * @param destination 
	//  * @returns 
	//  */
	// public getTargetWriteMode(destination:number) : Observable<string[]> {
	// 	return of(["Keep", "Drop"]);
	// }

	// /**
	//  * Schreibmodus
	//  * @param destination 
	//  * @returns 
	//  */
	// public getTargetEntityWriteMode(destination:number) : Observable<string[]> {
	// 	return of(["Keep", "Drop"]);
	// }

	// public getTargetReordWriteMode(destination:number) : Observable<string[]> {
	// 	return of(["Append", "Replace"]);
	// }

	// public getTargetWriteModes() : Observable<Array<TargetWriteMode>> {
	// 	const modes = [
	// 		{Label:"Keep", IsSupported:true, EntityWriteModes: [
	// 			{Label:"Keep", IsSupported:true, RecordWriteModes: [
	// 				{Label:"Append", IsSupported:true},
	// 				{Label:"Replace", IsSupported:true},
	// 				{Label:"Update", IsSupported:false},
	// 				{Label:"Delta", IsSupported:false}
	// 			]},
	// 			{Label:"Drop", IsSupported:true, RecordWriteModes: []}
	// 		]},
	// 		{Label:"Drop", IsSupported:true, EntityWriteModes: []}
	// 	]

	// 	return of(modes);
	// }

	/**
	 *
	 * @returns all write mode options
	 */
	getWriteModes(): WriteMode[] {
		return [
			{
				name: "Append",
				value: "append",
				description:
					" The Append Mode adds new data to the existing PSA without changing old data. This method is suitable for small data packages that only consists of new data (e.g. monthly data update for January, February Sales Data)",
			},
			{
				name: "Replace",
				value: "replace",
				description:
					" The Replace Mode will overwrite all existing data with new data. This method is suitable for e.g. master data.",
			},
			{
				name: "Update",
				value: "update",
				description:
					" The Update Mode will only update data which has been changed compared to the previous state. Old data will be overwritten.",
			},
			{
				name: "Delta",
				value: "delta",
				description:
					" The Delta Mode will only add changed data to the existing PSA. Old data will not be overwritten and remain in the PSA.",
			},
		];
	}
}
