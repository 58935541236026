<div class="p-grid">
    <div class="p-col-12 headerSection">
        <div class="p-fluid">
            <span class="p-float-label">
                <!-- <p-dropdown appendTo="body" [options]="columnList" (onChange)="onUpdateSettings(false)"
                [(ngModel)]="duplicatesColumns" autoWidth="false" [style]="{'width':'100%'}">
                </p-dropdown> -->
                <p-multiSelect appendTo="body" [options]="columnList" display="chip"
                (onChange)="onUpdateSettings(false)" [(ngModel)]="duplicatesColumns" autoWidth="false" [style]="{'width':'100%'}">
            </p-multiSelect>
                 <label for="datasourceName">{{'Column' |translate }}</label>
            </span>
        </div>
    </div>

    <div class="field p-col-12">
            <div class="p-fluid">
                <span class="p-float-label">
                    <p-dropdown appendTo="body" [options]="dropModes" (onChange)="onUpdateSettings(false)"
                    [(ngModel)]="dropmode" autoWidth="false" [style]="{'width':'100%'}">
                    </p-dropdown>
                     <label for="datasourceName">{{'Drop Mode' |translate }}</label>
                </span>
            </div>
    </div>

    <div class="p-col-12">
        <div class="p-field-checkbox">
            <p-checkbox name="group1" [binary]="true" (onChange)="onUpdateSettings(false)"
            [(ngModel)]="removeAllColsFlag" inputId="ny3" label="{{'Remove duplicates based on all columns' |translate }}"></p-checkbox>
        </div>
    </div>
</div>



