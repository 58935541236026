import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Message, MessageService } from "primeng/api";
import { GuiErrorCode, GuiErrorException, GuiErrorInfo } from "src/app/models/gui.error.model";
import { PlayErrorResponse, PlayHttpErrorResponse } from "src/app/models/play.error.model";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { SubSink } from "subsink";

interface ViewErrorInfo {
	summary: string;
	detail: string;
	date?: Date;
}

// /**
//  * Eine HTTP-Error Response aus Play. Das 'error' Attribut hat hier immer die entsprechende Form aus Play.
//  */
// export interface PlayHttpErrorResponse extends HttpErrorResponse {
// 	error: PlayErrorResponse;
// }

interface AsViewInfo<A> {
	toInfo(a: A): ViewErrorInfo;
}

interface AsGuiInfo<A> {
	toInfo(a: A): GuiErrorInfo
}

class HttpViewInfo implements AsViewInfo<HttpErrorResponse> {
	toInfo(a: HttpErrorResponse): ViewErrorInfo {
		const result: ViewErrorInfo = {
			summary: a.name,
			detail: a.message,
		};
		return result;
	}
}

class HttpGuiInfo implements AsGuiInfo<HttpErrorResponse> {
	toInfo(a: HttpErrorResponse): GuiErrorInfo {
		return new GuiErrorInfo(a.status, a.message, a.name, []);
	}
}

class PlayViewInfo implements AsViewInfo<PlayHttpErrorResponse> {
	toInfo(a: PlayHttpErrorResponse): ViewErrorInfo {

		const e = a.error.error;

		const result: ViewErrorInfo = {
			summary: e.exception.title,
			detail: e.exception.description,
		};
		return result;
	}
}

class PlayGuiInfo implements AsGuiInfo<PlayHttpErrorResponse> {
	toInfo(a: PlayHttpErrorResponse): GuiErrorInfo {

		const pl = a.error;

		return new GuiErrorInfo(a.status,
			pl.error.exception.description,
			pl.error.exception.title,
			pl.error.exception.stacktrace);
	}
}

class CommonViewInfo implements AsViewInfo<Error> {
	toInfo(a: Error): ViewErrorInfo {
		// err.name, err.message
		const result: ViewErrorInfo = {
			summary: a.name,
			detail: a.message,
		};

		return result;
	}

}

class CommonGuiInfo implements AsGuiInfo<Error> {
	toInfo(a: Error): GuiErrorInfo {
		return new GuiErrorInfo(GuiErrorCode.unknownError, a.message, a.name, a.stack?.split("\n"));
	}

}


@Component({
	selector: "app-system-message-log",
	templateUrl: "./system-message-log.component.html",
	styleUrls: ["./system-message-log.component.scss"],
	providers: [MessageService],
})
export class SystemMessageLogComponent implements OnInit, OnDestroy {
	subs = new SubSink();
	GuiErrorInfo?: GuiErrorInfo = undefined;
	messages: Message[] = [];
	constructor(private errorService: SystemMessageLogService) { }
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	// -- Type Class Instances for Error Type Abstraction
	// 
	// The Error hierarchy is as followed
	// Error > HttpErrorResponse > PlayHttpErrorResponse

	readonly httpErrorView = new HttpViewInfo();
	readonly httpGuiInfo = new HttpGuiInfo();
	readonly playErrorView = new PlayViewInfo();
	readonly playGuiInfo = new PlayGuiInfo();
	readonly commonErrorView = new CommonViewInfo();
	readonly commonGuiInfo = new CommonGuiInfo();

	// ---------------------------------------------

	/**
	 * Handles an error based on its type.
	 * @param err Error
	 */
	dispatchError(err: Error) {
		if (err instanceof HttpErrorResponse) {
			this.handleHttpError(<HttpErrorResponse>err);
		}
		else {
			this.handleGeneralError(err);
		}
	}

	/**
	 * Handle common TypeScript errors.
	 * @param err Error
	 */
	handleGeneralError(err: Error) {
		console.log("Common Error", err);
		this.showGeneralError(err);
	}

	/**
	 * Handle a Gui Error.
	 * @param err Gui Error
	 */
	handleGuiError(err: GuiErrorException) {
		console.log("Gui Error: " + err);
		this.showGuiError(err);
	}

	/**
	 * Handle error from HTTP-Requests.
	 * @param err Error
	 */
	handleHttpError(err: HttpErrorResponse) {
		console.log("Http Error", err);

		const e = err.error;

		if (PlayErrorResponse.isShape(e)) {
			this.showPlayError(e, err);
		} else {
			this.showHttpError(err);
		}
	}

	ngOnInit(): void {
		this.subs.sink = this.errorService.httpErrorEventEmitter.subscribe(
			(err: HttpErrorResponse) => {
				this.handleHttpError(err);
			}
		);

		this.subs.sink = this.errorService.guiErrorEventEmitter.subscribe(
			(err: GuiErrorException) => this.showGuiError(err)
		);

		this.subs.sink = this.errorService.generalErrorEventEmitter.subscribe(
			(err: Error) => this.dispatchError(err)
		)

		this.subs.sink = this.errorService.generalErrorsEventEmitter.subscribe(
			(errs: Error[]) => this.showErrorsDispatched(errs)
		)

		this.subs.sink = this.errorService.clearMessageEventEmitter.subscribe(
			() => {
				this.messages = [];
				this.GuiErrorInfo = undefined;
			}
		)


	}

	/**
	 * Konvertiert diese Exception in eine Generell-Darstellbare Form auf der UI.
	 * @param e Exception
	 * @param tc1 Gui Info Typklasse
	 * @param tc2 Error View Typklasse
	 * @returns View-Infos
	 */
	guiAndMessage<E>(e: E, tc1: AsGuiInfo<E>, tc2: AsViewInfo<E>): [GuiErrorInfo, ViewErrorInfo] {
		const r1 = tc1.toInfo(e);
		const r2 = tc2.toInfo(e);
		const result: [GuiErrorInfo, ViewErrorInfo] = [r1, r2];
		return result;
	}

	showErrorsDispatched(errs: Error[]) {

		// Build View and Gui Infos

		// Dispatch
		const infos = errs.map(err => {

			if (err instanceof HttpErrorResponse) {

				const e = err.error;
				if (PlayErrorResponse.isShape(e)) {
					const pe = <PlayHttpErrorResponse>err;
					return this.guiAndMessage(pe, this.playGuiInfo, this.playErrorView);
				} else {
					const he = <HttpErrorResponse>err;
					return this.guiAndMessage(he, this.httpGuiInfo, this.httpErrorView);
				}

			} else {
				return this.guiAndMessage(err, this.commonGuiInfo, this.commonErrorView);
			}

		})


		const views = infos.map(i => i[1]);
		this.setMessageViews(views);

	}

	setMessageViews(views: ViewErrorInfo[], severity: string = "warn", currentDate: number = Date.now()) {
		const error_date = new Date(currentDate);
		const locale_date_string = error_date.toLocaleString();

		this.messages = views.map(v => {
			const m = {
				severity: severity,
				summary: v.summary,
				detail: v.detail + " - " + locale_date_string,
			}
			return m;
		});
	}

	/**
	 * Shows the common typescript error type.
	 * @param err Error
	 */
	showGeneralError(err: Error): void {
		this.setMessages(err.name, err.message);
	}

	/**
	 * Shows a gui error
	 * @param err Gui Error Code
	 */
	showGuiError(err: GuiErrorException): void {
		this.setMessages(err.title, err.message);
		const errorInfo = new GuiErrorInfo(err.code, err.title, err.message);
		this.GuiErrorInfo = errorInfo;
	}

	/**
	 * Sets the log to one message with the given arguments.
	 * @param summary Short Text
	 * @param detail Long Text
	 * @param severity level, usually 'warn' for errors
	 * @param currentDate Error time stamp
	 */
	setMessages(summary: string, detail: string, severity: string = "warn", currentDate: number = Date.now()) {
		const currentDateString = new Date(currentDate);
		this.messages = [
			{
				severity: severity,
				summary: summary,
				detail: detail + " - " + currentDateString,
			},
		];
	}

	setGuiInfoT<E>(e: E, tc: AsGuiInfo<E>) {
		this.GuiErrorInfo = tc.toInfo(e);
	}

	setMessageViewT<E>(e: E, tc: AsViewInfo<E>, severity: string = "warn", currentDate: number = Date.now()) {
		this.setMessageView(tc.toInfo(e), severity, currentDate);
	}

	setMessageView(viewInfo: ViewErrorInfo, severity: string = "warn", currentDate: number = Date.now()) {
		this.setMessages(viewInfo.summary, viewInfo.detail, severity, currentDate);
	}

	/**
	 * Displays a Play Server Error, usually with HTTP Code 500
	 * @param err Play Exception
	 * @param httpError Encapsulating HTTP Exception
	 */
	showPlayError(err: PlayErrorResponse, httpError: HttpErrorResponse): void {

		const errorInfo = new GuiErrorInfo(httpError.status,
			err.error.exception.description,
			err.error.exception.title,
			err.error.exception.stacktrace);

		this.GuiErrorInfo = errorInfo;
		this.setMessages(err.error.exception.title, err.error.exception.description);
	}

	/**
	 * Displays any kind of HTTP Errors.
	 * @param err HTTP Exception
	 */
	showHttpError(err: HttpErrorResponse): void {
		const errorInfo = new GuiErrorInfo(err.status,
			err.message, err.name, []);

		this.GuiErrorInfo = errorInfo;
		this.setMessages(err.name, err.message);
	}

	clearMessages() {
		this.GuiErrorInfo = undefined;
	}
}
