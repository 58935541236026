<div class="container" *ngIf="nodeData"> 
    <div class="header"  >
        <h4>{{nodeData.value.Engine.Name}}</h4>
        <h4>{{nodeData.description}}</h4>

    </div>

    <div [ngSwitch]="nodeData.value.Engine.Name" class="settings">
        <div *ngSwitchCase="EXCEL_INPUT_NODE">
            <app-datasource-input [currentNode]="nodeData" (settingsChanged)="onSettingsChanged($event)"></app-datasource-input>
        </div>
    </div>

</div>
<div *ngIf="!nodeData">No Node selected</div>


