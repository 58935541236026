import { Component, OnDestroy, OnInit } from '@angular/core';
import { Id } from 'src/app/helper/id';
import { DataStore } from 'src/app/models/datastore.model';
import { GuiDropDown } from 'src/app/models/helperClass.model';
import { DataStoreOutPlugInSettings } from 'src/app/models/nodeSettings.model';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { CubesService } from 'src/app/services/cubes.service';
import { DesignerService } from 'src/app/services/designer.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { SubSink } from 'subsink';
import { NodeConfigComponentBase } from '../node-config-component-base';
import { DestinationActionEvent, DestinationDialogActionType } from 'src/app/models/dialog-actions.model';


@Component({
    selector: 'app-output-datastore-node',
    templateUrl: './output-datastore-node.component.html',
    styleUrls: ['./output-datastore-node.component.css']
})
export class OutputDatastoreNodeComponent extends NodeConfigComponentBase<DataStoreOutPlugInSettings> implements OnInit, OnDestroy {
    viewToSettings(): DataStoreOutPlugInSettings {
        // --- clone current settings
        let newSettings = <DataStoreOutPlugInSettings>this._currentWorkflowNodeAdapter.IWorkflowNode.Properties.Configuration;

            let dataStore = this.selectedDataStore;
            newSettings.SelectedDataStore = dataStore;
            newSettings.DataStores = this.dataStoresList;

            //const select_write_mode = Id.assertSet(this.selectedWriteModeOption, new Error("No selected write mode found, should not happen"));

            //newSettings.WriteMode = select_write_mode.value;
            newSettings.DataStoreFields = [];

            return newSettings;
    }
    settingsToView(settings: DataStoreOutPlugInSettings): void {

        let currentConfig = <DataStoreOutPlugInSettings>settings;

        if(!currentConfig) {
            //Load Default View
            this.dataStoresList = [];
            if(this.dataStoresList.length > 0) {
                this.selectedDataStore = this.dataStoresList[0];
                //const write_modes = Id.assertSet(this.writeModeOptions, new Error("No write modes found, should not happen"))
                //this.selectedWriteModeOption = this.writeModeOptions[0];

            }
        } else {
            this.dataStoresList = currentConfig.DataStores;
            this.selectedDataStore = currentConfig.SelectedDataStore;
            //this.selectedWriteModeOption = {name: currentConfig.WriteMode, value: currentConfig.WriteMode};
        }

    }

    onSettingsChanged(settings: DataStoreOutPlugInSettings) {
        throw new Error('Method not implemented.');
    }

    readonly InputPort = "Input";

    //createNewOutputDialog: boolean = false;
    subs = new SubSink;
    dataStoresList: DataStore[] = [];
    filteredDataStoreList: DataStore[] = [];
    selectedDataStore?: DataStore;
    //writeModeOptions?: GuiDropDown[];
    //selectedWriteModeOption?: GuiDropDown;

    constructor(protected workflowService: WorkflowsService,
        protected designerEventService: DesignerService,
        private cubeService: CubesService,
        public bionApi: ApiBackendService) {
        super(workflowService, designerEventService);
    }

    displayCreateDataStoreDialog() {
        // this.cubeService.displayNewDataStoreDialog.emit(true);
        this.cubeService.destinationDialogActionSendEmitter.emit(new DestinationActionEvent(true, "Create destination", DestinationDialogActionType.createDestination, "Create", undefined));

        
    }

    ngOnInit(): void {
        super.ngOnInit();

        //Subscribe to NewDataStoreEmitter
        this.subs.sink = this.cubeService.dataStoresChangedEmitter.subscribe((datastore) => {
            //this.getDataStores();
            //this.onUpdateSettings(false);

            this.dataStoresList.push(datastore);
        })
    }
    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
    // getDataStores() {
    //     this.subs.sink = this.cubeService.getDataStoreObjectList().subscribe((stores: DataStore[]) => {
    //         this.dataStoresList = stores;
    //     });
    // }



}
