<p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>
<div class="login-body">
    <app-system-message-log></app-system-message-log>

    <div class="login-wrapper">
        <div [formGroup]="form" class="login-panel">
            <a [routerLink]="['/']" class="logo">
                <img src="assets/layout/images/logo-bion-single.svg" alt="freya-layout" />
            </a>
            <!-- <a style="margin-bottom: 5px; color: crimson;">{{errorMessage}}</a> -->

            <h4 style="margin-bottom: 50px">{{'Enter your new password to change your credentials' | translate}}</h4>

            <input *ngIf="!isSuccessful" pPassword type="password" class="form-control" name="password" formControlName="password"
                id="password" placeholder="{{'Password' | translate}}" />

            <input *ngIf="!isSuccessful" pPassword type="password" class="form-control" name="passwordRepeat" formControlName="passwordRepeat"
                (keyup.enter)="setNewPassword()" id="passwordRepeat" placeholder="{{'RepeatPassword' | translate}}" />
            <button *ngIf="!isSuccessful" pButton pRipple label="{{'SETNEWPASSWORD' | translate}}" type="button"
                (click)="setNewPassword()"></button>
            <!-- <div style="margin-bottom: 10px"><a style="float:left;margin-right: 5px">Bion</a><p-inputSwitch (onChange)="changeLoginMode($event)" formControlName="loginMode"></p-inputSwitch><a style="float:right;margin-left: 5px">Azure</a>
            </div> -->

            <!-- <a href="#" (click)="openForgotPasswordPage()">{{'forget password? Reset your password here' |
                translate}}</a> -->
            <!-- <p>Don’t you have an account, <a [routerLink]="['/register']">sign up</a></p> -->

            <p *ngIf="isSuccessful && resultMessage" style="color: rgb(104, 191, 124)"><i class="pi pi-check-circle"></i>{{resultMessage}}</p>
            <p *ngIf="!isSuccessful && resultMessage" style="color: rgb(194, 98, 98)"><i class="pi pi-times-circle"></i>{{resultMessage}}</p>
        </div>
        <div class="login-footer">
            <h4>bion</h4>
            <h6>Copyright Ⓒ Bion Analytics</h6>
        </div>
    </div>
</div>
