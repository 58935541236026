import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SchedulerNodeGuiInfo } from 'src/app/models/scheduler-designer.model';
import { DummySchedulerService, SchedulerNodeInfo } from 'src/app/services/dummy-backend.service';

@Component({
  selector: 'app-schedule-actions-list',
  templateUrl: './schedule-actions-list.component.html',
  styleUrls: ['./schedule-actions-list.component.scss']
})
export class ScheduleActionsListComponent implements OnInit {
  plugInInfos: SchedulerNodeInfo[] = [];
  scheduleNodeInfos: SchedulerNodeGuiInfo[] = [];
  selectedScheduleNodeInfo?: SchedulerNodeGuiInfo;

  constructor(private dummyService: DummySchedulerService) { }

  ngOnInit(): void {
    this.loadPlugInInfo().subscribe((res) => {
      this.plugInInfos = res;
    });
  }
  /**
 * Loads the Plug In Information for the Workflow Codec, the Node GUI and the Toolbar.
 *
 * You must call this method BEFORE you load or create a new workflow!
 */
  loadPlugInInfo(): Observable<SchedulerNodeInfo[]> {
    return this.dummyService.getScheduleNodes();
  }

  dragStart(event, action) {

  }

  dragEnd(event) {

  }

}
