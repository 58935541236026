import { forkJoin, Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { WorkflowNode } from "src/app/models/api/com/bion/etl/Workflow";
import { WorkflowRepositoryEntry } from "src/app/models/api/models/workflow/WorkflowRepositoryEntry";
import { DataSource } from "src/app/models/datasource.model";
import { DataStore } from "src/app/models/datastore.model";
import { ScheduleBaseAction, ScheduleActionPlan, ExtractDataSourceAction, RunWorkflowAction } from "src/app/models/schedule.model";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { SchedulesService } from "src/app/services/schedules.service";
import { BottomToolbarProvider, NoDataProviderError, ToolbarResult } from "./bottom-toolbar-provider";

export interface SchedulerToolbarData {
  DataSources: DataSource[];
  Workflows: WorkflowRepositoryEntry[];
  DataStores: DataStore[];
  SchedulerActions: ScheduleBaseAction[];
}

export class SchedulerToolbarResult implements ToolbarResult {
  DataSources: DataSource[];
  Workflows: WorkflowRepositoryEntry[];
  DataStores: DataStore[];
  Matches: boolean[];
  constructor(DataSources: DataSource[], Workflows: WorkflowRepositoryEntry[], DataStores: DataStore[], Matches: boolean[]) {
    this.DataSources = DataSources;
    this.Workflows = Workflows;
    this.DataStores = DataStores;
    this.Matches = Matches;
  }
  DataStoreOutNodes: WorkflowNode[] = [];
  PsaInNodes: WorkflowNode[] = [];
  ScheduleActionPlans: ScheduleActionPlan[] = [];
  ScheduleActions: ScheduleBaseAction[] = [];
  WorkflowNodes: WorkflowNode[] = [];
}

export class ScheduleBottomToolbar implements BottomToolbarProvider<ScheduleActionPlan, never, SchedulerToolbarResult, SchedulerToolbarData> {
  constructor(private bionApi: ApiBackendService, private schedulesService: SchedulesService) { }
  getPsaData(obj: ScheduleActionPlan) {
    return throwError(new NoDataProviderError('Schedulers do not provide data.'))
  }

  getData(): Observable<SchedulerToolbarData> {
    let datasourcesObs = this.bionApi.getDataSources();
    let workflowsObs = this.bionApi.getWorkflowObjectList();
    let dataStoresObs = this.bionApi.getDataStoreObjectList();
    let scheduleActionsObs = this.bionApi.getScheduleActions();

    return forkJoin(datasourcesObs, workflowsObs, dataStoresObs, scheduleActionsObs).pipe(map(all => {
      return { DataSources: all[0], Workflows: all[1], DataStores: all[2], SchedulerActions: all[3] }
    }))
  }
  subscribeToEmitter(): Observable<ScheduleActionPlan> {
    return this.schedulesService.selectedSchedulePlanEmitter;
  }
  buildToolbar(data: SchedulerToolbarData, select?: ScheduleActionPlan): SchedulerToolbarResult {
    let dataSources: DataSource[] = data.DataSources;
    let wf: WorkflowRepositoryEntry[] = data.Workflows;
    let destinations: DataStore[] = data.DataStores;
    let scheduleActions: ScheduleBaseAction[] = data.SchedulerActions;

    if (select) {
      scheduleActions = scheduleActions.filter((sched) => { return sched.actionPlan === select.id })
    }

    let runActions = <RunWorkflowAction[]>scheduleActions.filter((schedule) => {
      return schedule._type === "models.scheduler.RunWorkflowAction"
    });
    let extractActions = <ExtractDataSourceAction[]>scheduleActions.filter((schedule) => {
      return schedule._type === "models.scheduler.ExtractDataSourceAction"
    });

    let filteredWf: WorkflowRepositoryEntry[] = [];
    for (let runAction of runActions) {
      let wfFound = wf.find((wf => { return wf.id === runAction.workflow }));
      if (wfFound) {
        filteredWf.push(wfFound)
      }
    }

    let filteredDs: DataSource[] = [];
    for (let eAction of extractActions) {
      let dsFound = dataSources.find((ds => { return ds.id === eAction.dataSource }));
      if (dsFound) {
        filteredDs.push(dsFound)
      }
    }

    let filteredDestination = new Array();

    let statusSuccess: boolean;
    statusSuccess = !(filteredWf.length === 0 && filteredDs.length === 0);

    return new SchedulerToolbarResult(filteredDs, filteredWf, filteredDestination, [statusSuccess]);
  }
}