<div #graphContainer pDroppable="plugInInfos"  
    class="graphContainer" style="overflow: hidden;">
</div>
<!-- <div *ngIf="isEditor" #outlineContainer id="outlineContainer"
    style="position:absolute;overflow:hidden;top:190px;right:525px;width:250px;height:150px;background:transparent;border-style:solid;border-width: 1px; border-color:rgb(191, 191, 191);">
</div> -->
<div *ngIf="isEditor" #outlineContainer id="outlineContainer"
    style="position:absolute; top:10px; overflow:hidden;width:200px;height:125px;background:transparent;border-style:solid;border-width: 1px; border-color:rgb(191, 191, 191);">
</div>

<!-- <p-contextMenu appendTo="body" [target]="graphContainer" [model]="items"></p-contextMenu> -->