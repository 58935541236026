<p>view-ab-connection works!</p>
<p-progressBar [mode]="progressMode" [style]="{'height': '6px'}"></p-progressBar>
<div>
    <div>
        <h1>{{connectionInfo?.connection?.name}}</h1>
    </div>
    <div>
        <h2>{{connectionInfo?.connection?.status}}</h2>
    </div>
    <div>
        <div style="float:left">Query</div>
        <div>
            <!-- <button (click)="onSync($event)"onSync($event)>Sync extract now</button> -->
        </div>
    </div>

    <div #StreamsToQuery>
        <p-table [value]="connectionInfo?.connection?.syncCatalog?.streams" dataKey="stream.name"
            [tableStyle]="{'min-width': '50rem'}">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 4rem"> <p-tableHeaderCheckbox></p-tableHeaderCheckbox> </th>
                    <th>Namespace</th>
                    <th>Stream name</th>
                    <th>Sync Mode</th>
                    <th>Quantity</th>
                    <th>Query</th>
                    <th>Query Raw</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product>
                <tr>
                    <td> <p-tableCheckbox [value]="product"></p-tableCheckbox> </td>
                    <td>{{product.stream.namespace}}</td>
                    <td>{{product.stream.name}}</td>
                    <td>{{product.stream.supportedSyncModes[0]}}</td>
                    <td>{{product.config.selected}}</td>
                    <td><button (click)="onQuery($event, product)">Query now!</button></td>
                    <td><button (click)="onQueryRaw($event, product)">Query now!</button></td>
                </tr>
            </ng-template>
        </p-table>

        <div>Stream Data</div>

        <p-table [value]="tableData">
            <ng-template pTemplate="header">
                <tr>
                    <th *ngFor="let col of tableCols">
                        {{col.label}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-car>
                <tr>
                    <td *ngFor="let col of tableCols">
                            {{car[col.key]}}
                    </td>
                </tr>
            </ng-template>
        </p-table>

    </div>

</div>