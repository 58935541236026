<div class="layout-footer">
    <div class="p-grid">
        <!-- <div class="p-col-12  p-lg-4">
            <div class="p-grid">
                <div class="p-col-6 ">
                    <span class="footer-menutitle">SITE MAP</span>
                    <ul>
                        <li><a [routerLink]="['/']">Home</a></li>
                        <li><a href="https://www.bion-analytics.com">Managed Services</a></li>
                    </ul>
                </div>
                <div class="p-col-6">
                    <span class="footer-menutitle"></span>
                    <ul>
                        <li><a [routerLink]="['/datamarket']">Get external data</a></li>
                        <li><a href="https://www.bion-analytics.com">Upgrade now</a></li>
                    </ul>
                </div>
            </div>

        </div>
        <div class="p-col-12 p-md-6 p-lg-3">
            <span class="footer-menutitle">TECH SUPPORT</span>
            <ul>
                <li>support@bion-analytics.com</li>
            </ul>
        </div>
        <div class="p-col-12 p-md-6 p-lg-5">
            <span class="footer-menutitle">NEWSLETTER</span>
            <span class="footer-subtitle">Join our newsletter to get notification about the new features.</span>
                <div class="newsletter-input">
                    <input pInputText type="text" placeholder="email address" />
                    <button pButton pRipple type="button" label="Join" class="p-button-secondary "></button>
                </div>
        </div> -->
        <div class="p-col-12">
            <div class="footer-bottom">
                <h4>Bion</h4>
                <h6>Copyright Ⓒ Bion Analytics</h6>
            </div>
        </div>
    </div>
</div>
