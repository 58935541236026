import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api/menuitem';
import { IntegratedDestinationService } from 'src/app/services/integrated-destination.service';
import { ConsoleLogger, LogLevel } from 'src/app/views/designer/components/workflow-graph/logger';

// Dummy Classen
export namespace IntegratedDestinationController {
  export class ConnectorInfo {
    Name: string;
    Key: IntegratedDestinationModel.ConnectorKey<string>;
  }
}
export namespace IntegratedDestinationModel {
  export class ConnectorKey<A> {
    Id: A;
    Origin: string;
    constructor(Id: A, Origin: string) {
      if (Id === undefined) throw new Error("Class 'ConnectorKey': Required value 'Id' is undefined");
      if (Origin === undefined) throw new Error("Class 'ConnectorKey': Required value 'Origin' is undefined");
      this.Id = Id;
      this.Origin = Origin;
    }
  }
  export class ConnectorSpec<K, S> {
    ConnectorId: ConnectorKey<K>;
    OriginalSpecs: S;
    constructor(ConnectorId: ConnectorKey<K>, OriginalSpecs: S) {
      if (ConnectorId === undefined) throw new Error("Class 'ConnectorSpec': Required value 'ConnectorId' is undefined");
      if (OriginalSpecs === undefined) throw new Error("Class 'ConnectorSpec': Required value 'OriginalSpecs' is undefined");
      this.ConnectorId = ConnectorId;
      this.OriginalSpecs = OriginalSpecs;
    }
  }
  export class CreateDestinationArg<C> {
    ConnectorKey: ConnectorKey<string>;
    Config: C;
    Name: string;
    constructor(ConnectorKey: ConnectorKey<string>, Config: C, Name: string) {
      if (ConnectorKey === undefined) throw new Error("Class 'CreateDataSourceArg': Required value 'ConnectorKey' is undefined");
      if (Config === undefined) throw new Error("Class 'CreateDataSourceArg': Required value 'Config' is undefined");
      if (Name === undefined) throw new Error("Class 'CreateDataSourceArg': Required value 'Name' is undefined");
      this.ConnectorKey = ConnectorKey;
      this.Config = Config;
      this.Name = Name;
    }
  }
  export class DestinationKey<A> {
    Id: A;
    Origin: string;
    constructor(Id: A, Origin: string) {
      if (Id === undefined) throw new Error("Class 'DataSourceKey': Required value 'Id' is undefined");
      if (Origin === undefined) throw new Error("Class 'DataSourceKey': Required value 'Origin' is undefined");
      this.Id = Id;
      this.Origin = Origin;
    }
  }
  export class CreateDestinationResult<A> {
    SourceKey: DestinationKey<number>;
    OriginalResult: A;
    constructor(SourceKey: DestinationKey<number>, OriginalResult: A) {
      if (SourceKey === undefined) throw new Error("Class 'CreateDataSourceResult': Required value 'SourceKey' is undefined");
      if (OriginalResult === undefined) throw new Error("Class 'CreateDataSourceResult': Required value 'OriginalResult' is undefined");
      this.SourceKey = SourceKey;
      this.OriginalResult = OriginalResult;
    }
  }
}



@Component({
  selector: 'app-choose-dest-connector-page',
  templateUrl: './choose-dest-connector-page.component.html',
  styleUrls: ['./choose-dest-connector-page.component.scss']
})
export class ChooseDestConnectorPageComponent implements OnInit {

  //items: MenuItem[] = [{ label: '1. Konnektor auswählen', disabled: false }, { label: '2. Destination einrichten', disabled: true }];
  items: MenuItem[] = [{ label: this.translate.instant('1. Konnektor auswählen'), disabled: false}, { label: this.translate.instant('2. Destination einrichten'),disabled: false }];

  connector_infos: IntegratedDestinationController.ConnectorInfo[] = [];
  connector_info_selected?: IntegratedDestinationController.ConnectorInfo;
  is_loading: boolean = false;
  goto_ds_button_disabled: boolean = true;
  private logger = new ConsoleLogger(typeof (this), LogLevel.Info);

  constructor(private service_api: IntegratedDestinationService, private router: Router, private translate: TranslateService) { }

  ngOnInit(): void {
    // load connectors
    this.setLoading(true);
    this.service_api.getConnectors().subscribe(
      connectors => this.handle_connectors(connectors),
      err => this.handle_error(err),
      () => this.setLoading(false))
  }

  setLoading(isLoading: boolean) {
    this.is_loading = isLoading;
  }

  handle_error(e: Error) {
    this.logger.error("Error Handler", e);
    this.setLoading(false);
  }

  /**
 * Alle Konnektoren wurden geholt
 * @param conns Konnektoren
 */
  handle_connectors(conns: IntegratedDestinationController.ConnectorInfo[]) {
    this.connector_infos = conns;
  }

  on_connector_selected(info: IntegratedDestinationController.ConnectorInfo) {
    this.logger.info("On Connector Selected", info);
    this.connector_info_selected = info;
    this.goto_ds_button_disabled = false;
    //const values:IntegratedSourceModel.ConnectorKey<string>[] = event;
    // const key: IntegratedSourceModel.ConnectorKey<string> = event.value;
    const key: IntegratedDestinationModel.ConnectorKey<string> = info.Key;
    // check
    // choose
    // go
    //this.gotoCreateSource(key);
  }

  on_create_destination_click(event: any) {
    this.logger.debug("On create source click", event);
    if (this.connector_info_selected) {
      this.logger.debug("call gotoCreateDestination()", this.connector_info_selected);
      this.gotoCreateDestination(this.connector_info_selected.Key);
    } else {
      alert("You must select a connector");
    }
  }

  gotoCreateDestination(key: IntegratedDestinationModel.ConnectorKey<string>) {
    const id = key.Id;
    const origin = key.Origin;
    // SourceIntegrationCreateDatasource/:origin/:id
    this.router.navigate(['/', 'createDestinationPage', origin, id]);
  }
}
