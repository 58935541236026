import { Component, OnInit } from '@angular/core';
import { UserRow } from 'src/app/models/api/domain/NewDataSourceModelBase';
import { UserInfo } from 'src/app/models/api/models/session/UserInfo';
// import { User, UserInfo } from 'src/app/models/user.model';
import { EmailService } from 'src/app/services/email.service';
import { UserService } from 'src/app/services/user.service';

interface UserInfoForm {
  username:string;
  email:string;
}

@Component({
  selector: 'app-invite-user-dialog',
  templateUrl: './invite-user-dialog.component.html',
  styleUrls: ['./invite-user-dialog.component.scss']
})
export class InviteUserDialogComponent implements OnInit {

  headerText: string = "Invite user";
  displayInviteUser: boolean = false;
  error_text:string = "";

  form:UserInfoForm = {
    username: "",
    email: "",
  };

  error_disabled = true;

  constructor(private userService: UserService, private mailService: EmailService) { }

  ngOnInit(): void {
    this.userService.displayInviteUser.subscribe(
      (res: boolean) => {
        console.log('Open Invite User Dialog:', res);
        this.displayInviteUser = res;
      },
      (err) => {
        console.log("Error occured" + err)
      }
    );

  }

  sendEmail() {
    // let user = new User();
    // user.eMail = this.form.email;
    // user.passWord = this.generatePassword(12);
    // user.id = -1;
    // user.isAdmin = true;
    // user.userName = this.form.username;

    if(this.form.username.length == 0) {
      this.error_text = "The username must not be empty";
      return;
    }
    if(this.form.email.length == 0) {
      this.error_text = "The email must not be empty";
      return;
    }
    this.error_text = "";

    const user = new UserRow(-1,
      this.form.username,
      this.generatePassword(12),
      this.form.email,
      false);

    this.userService.createUser(user).subscribe((res: UserInfo) => {

      this.mailService.inviteUserEmail(user).subscribe(data => {
        console.log(data);
        this.displayInviteUser = false;
      },
        (err) => {
          console.log('Email could not be sent.')
        });
    })
  }

  generatePassword(passwordLength: number) {
    const numberChars = "0123456789";
    const upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowerChars = "abcdefghijklmnopqrstuvwxyz";
    const allChars = numberChars + upperChars + lowerChars;
    let randPasswordArray = Array(passwordLength);
    randPasswordArray[0] = numberChars;
    randPasswordArray[1] = upperChars;
    randPasswordArray[2] = lowerChars;
    randPasswordArray = randPasswordArray.fill(allChars, 3);
    return this.shuffleArray(randPasswordArray.map(function (x) { return x[Math.floor(Math.random() * x.length)] })).join('');
  }

  shuffleArray<A>(array: Array<A>): Array<A> {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }


}
