
export class RegisterUserInfo {

    constructor(name:string, eMail:string, loginUrl: string) {
        this.Name = name;
        this.EMail = eMail;
        this.LogInUrl = loginUrl;
    }

    readonly Name:string;
    readonly EMail:string;
    readonly LogInUrl: string;
}

 export class ChangeUserPasswordInfo {
    constructor(name:string,  eMail:string, loginUrl: string) {
        this.Name = name;
        this.EMail = eMail;
        this.LogInUrl = loginUrl;
    }
    readonly Name:string;
    readonly EMail:string;
    readonly LogInUrl: string;
 }