<div class="p-grid" style="width:100%;">
    <div class="p-col-12" *ngIf="!isChangeSettingsOnlyFlag">
        <p-fileUpload auto="true" multiple="single" showCancelButton="false" customUpload="true" maxFileSize="200000000"
            (onRemove)="onRemoveFile($event)" chooseLabel="{{'Browse' | translate}}" uploadLabel="Preview"
            (uploadHandler)="uploadFilesAsync($event)" accept=".csv">
            <ng-template pTemplate="content">
                <ul>
                    <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
                </ul>
            </ng-template>
        </p-fileUpload>
    </div>

    <div class="p-col-12" *ngIf="!isChangeSettingsOnlyFlag">
        <p-divider layout="horizontal" align="center">
            <span class="p-tag">{{'Settings' |translate}}</span>
        </p-divider>
    </div>

    <div class="p-col-12 p-md-6">
        <div class="p-fluid">
            <span class="p-float-label">
                <input type="text" id="lineBreakText" pInputText
                    [(ngModel)]="currentConnectorView.connectorSettings.LineBreak"
                    value="{{currentConnectorView.connectorSettings.LineBreak}}" (blur)="onSettingsChanged()">
                <label for="lineBreakText">{{'Linebreak' | translate}}</label>
            </span>
        </div>
    </div>

    <div class="p-col-12 p-md-6">
        <div class="p-fluid">
            <span class="p-float-label">
                <input type="text" id="delimiterText" pInputText
                    [(ngModel)]="currentConnectorView.connectorSettings.Separator"
                    value="{{currentConnectorView.connectorSettings.Separator}}" (blur)="onSettingsChanged()">
                <label for="delimiterText">{{'Delimiter' | translate}}</label>
            </span>
        </div>
    </div>

    <div class="p-col-12 p-md-6">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown id="quoteCharOpt" [options]="parseCharaters"
                    [(ngModel)]="currentConnectorView.connectorSettings.QuoteCharacter" [editable]="true"
                    optionLabel="name" optionValue="char" (onChange)="onSettingsChanged()"></p-dropdown>
                <label for="quoteCharOpt">{{'QuoteChar' | translate}}</label>
            </span>
        </div>
    </div>

    <div class="p-col-12 p-md-6">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown id="escapeCharOpt" [options]="parseCharaters"
                    [(ngModel)]="currentConnectorView.connectorSettings.EscapeCharacter" [editable]="true"
                    optionLabel="name" optionValue="char" (onChange)="onSettingsChanged()"></p-dropdown>
                <label for="escapeCharOpt">{{'EscapeChar' | translate}}</label>
            </span>
        </div>
    </div>


</div>