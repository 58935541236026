<div>
    <p-table [value]="joinList" dataKey="ColumnName" editMode="row" [reorderableColumns]="false">
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                <span class="p-input-icon-left">
                    <span class="p-float-label">
                        <p-dropdown id="joinTypeName" appendTo="body" [options]="JoinTypeOptions" [(ngModel)]="selectedJoinType"
                            (onChange)="onUpdateSettings(false)" optionLabel="name"></p-dropdown>
                        <label for="joinTypeName">{{'Join Type' |translate }}</label>
                    </span>
                </span>

                <span class="p-input-icon-right">
                    <button style="float: right" pButton label="{{'Add' | translate}}" icon="fa fa-plus"
                        (click)="onAddJoinRow()"></button>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th tooltipPosition="top" pTooltip="Config.Join.Tooltip1">{{'Left' | translate}}</th>
                <th tooltipPosition="top" pTooltip="Config.Join.Tooltip2">{{'Right' | translate}}
                </th>
                <th style="width:3rem"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-column let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="column" [pReorderableRow]="ri"
                [ngClass]="{'row-accessories': column.hasError === true}">
                <td>
                    <!-- <p-dropdown appendTo="body" [options]="LeftInputOptions" [(ngModel)]="column.LeftColumn"
                            optionLabel="Name" dataKey="Name" (onChange)="onUpdateSettings(false)" autoWidth="false"
                            [style]="{'width':'100%'}">
                        </p-dropdown> -->
                    <p-dropdown appendTo="body" filterBy="column.LeftColumn" *ngIf="column.hasError.hasLeftError === false"
                        [autoDisplayFirst]="false" [options]="LeftInputOptions" [(ngModel)]="column.LeftColumn"
                        (onChange)="onUpdateSettings(false)"  autoWidth="false" [style]="{'width':'100%'}">
                    </p-dropdown>
                    <p-dropdown *ngIf="column.hasError.hasLeftError === true" filterBy="column.LeftColumn" appendTo="body" [autoDisplayFirst]="false"
                        [options]="LeftInputOptions" [(ngModel)]="column.LeftColumn" class="ng-invalid ng-dirty"
                        (onChange)="onUpdateSettings(false)" autoWidth="false" [style]="{'width':'100%'}">
                    </p-dropdown>
                </td>
                <td>
                    <!-- <p-dropdown appendTo="body" [options]="RightInputOptions" [(ngModel)]="column.RightColumn"
                            optionLabel="Name" dataKey="Name" (onChange)="onUpdateSettings(false)" autoWidth="false"
                            [style]="{'width':'100%'}">
                        </p-dropdown> -->
                    <p-dropdown appendTo="body" filterBy="column.RightColumn" *ngIf="column.hasError.hasRightError === false"
                        [autoDisplayFirst]="false" [options]="RightInputOptions" [(ngModel)]="column.RightColumn"
                        (onChange)="onUpdateSettings(false)" autoWidth="false" [style]="{'width':'100%'}">
                    </p-dropdown>
                    <p-dropdown *ngIf="column.hasError.hasRightError === true"  filterBy="RightColumn" appendTo="body" 
                        [autoDisplayFirst]="false" [options]="column.RightInputOptions" [(ngModel)]="column.RightColumn"
                        class="ng-invalid ng-dirty" (onChange)="onUpdateSettings(false)" autoWidth="false"
                        [style]="{'width':'100%'}">
                    </p-dropdown>
                </td>
                <td>
                    <div class="ui-table-buttongroup">
                        <button pButton pRipple type="button" class="p-button-rounded p-button-text ui-button-danger"
                            icon="pi pi-trash" pTooltip="Delete entry" (click)="onDeleteEntry(ri)"
                            tooltipPosition="bottom"></button>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="3">{{'NoEntryFound' |translate }}</td>
            </tr>
        </ng-template>
    </p-table>
</div>
