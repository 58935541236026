import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { AppMainComponent } from "src/app/app.main.component";
import { Id } from "src/app/helper/id";
import { UserInfo } from "src/app/models/api/models/session/UserInfo";
import { ChangeUserPasswordInfo } from "src/app/models/email.model";

import { ChangeUserPassword } from "src/app/models/user.model";
import { EmailService } from "src/app/services/email.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { UserService } from "src/app/services/user.service";
import { SubSink } from "subsink";

@Component({
	selector: "app-change-user-password-dialog",
	templateUrl: "./change-user-password-dialog.component.html",
	styleUrls: ["./change-user-password-dialog.component.scss"],
})
export class ChangeUserPasswordDialogComponent implements OnInit {
	subs = new SubSink();
	savingInProgress: boolean = false;
	headerText: string = "Change Password";
	selectedUser?: UserInfo;
	displayChangePassword: boolean = false;

	form = {
		newPassword: "",
		repeatPassword: "",
	};

	constructor(
		private userService: UserService,
		private mailService: EmailService,
		private systemLogService: SystemMessageLogService,
		private messageService: MessageService,
		private translate: TranslateService
	) {}

	ngOnInit(): void {
		this.subs.sink = this.userService.selectedMenuUserEmitter.subscribe(
			(res: UserInfo) => {
				this.selectedUser = res;
			},
			(err: Error) => {
				this.systemLogService.handleError(err);
			}
		);
		this.subs.sink = this.userService.displayChangeUserPassword.subscribe(
			(res: boolean) => {
                this.headerText = "ChangePassword"
				this.displayChangePassword = res;
			},
			(err: Error) => {
				this.systemLogService.handleError(err);
			}
		);
        this.subs.sink = this.userService.displayChangeMyPassword.subscribe(
			(res: boolean) => {
                this.headerText = "ChangeMyPassword"
				this.displayChangePassword = res;
			},
			(err: Error) => {
				this.systemLogService.handleError(err);
			}
		);
	}

	clearDialog() {
		this.form.newPassword = "";
		this.form.repeatPassword = "";
	}

	onChangeUserPassword() {
		try {
			this.changeUserPassword();
		} catch(e) {
			this.systemLogService.handleError(<Error>e);
		}
	}

	changeUserPassword() {
		this.savingInProgress = true;

        let userInfo: UserInfo|undefined = undefined;

        if(this.headerText === "ChangePassword") {
            userInfo  = this.selectedUser ? { ...this.selectedUser } : undefined;
        } else {

        }

		const user_info = Id.assertSet(userInfo, new Error("The User Info is not set!"));

		Id.assert(this.form.newPassword.length > 0, new Error("The password must not be empty"));
		Id.assert(this.form.repeatPassword.length > 0, new Error("The repeated password must not be empty"));
		if (this.form.newPassword !== this.form.repeatPassword) {
			throw new Error("Passwords do not match, please check your password!");
		}

		

		let changedPassword = new ChangeUserPassword();
		changedPassword.User = user_info;
		changedPassword.Password = this.form.newPassword;


		const hostUrl = window.location.host;
		const protocol = window.location.protocol;
		const finalUrl = protocol + "//" + hostUrl;
		let changePasswortInfo = new ChangeUserPasswordInfo(user_info.Username,user_info.EMail,finalUrl);


		this.userService.changeUserPassword(changedPassword).subscribe(
			(res: number) => {
				console.log(res);
				this.displayChangePassword = false;
				if (res === 0) {
					console.log("Changing PW failed!");
				} else {
					//this.mailService.changedUserPasswordEmail(changedPassword).subscribe(
						this.mailService.notifyChangedUserPassword(changePasswortInfo).subscribe(
						(data) => {
							console.log(data);
                            this.messageService.add({
                                severity: "success",
								summary: this.translate.instant("Message.UserPasswordChangedSuccess.Title"),
								detail: this.translate.instant("Message.UserPasswordChangedSuccess.Text"),
                            });
							this.displayChangePassword = false;
						},
						(err) => {
							this.systemLogService.handleError(err);
						}
					);
				}
			},
			(err) => {
				this.systemLogService.handleError(err);
			}, () => {
				this.savingInProgress = false;
			}
		);
	}
}
