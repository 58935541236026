import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { Observable, forkJoin, from } from 'rxjs';
import { concatMap, map, mergeMap } from 'rxjs/operators';
import { IntegratedSourceController } from 'src/app/models/api/controllers/IntegratedSourceController';
import { IntegratedSourceModel } from 'src/app/models/api/models/IntegratedSourceModel';
import { MColumn } from 'src/app/models/api/slick/jdbc/meta/MColumn';
import { DatasourcesService } from 'src/app/services/datasources.service';
import { IntegratedSourceService } from 'src/app/services/integrated-source.service';
import { UserService } from 'src/app/services/user.service';
import { UtilFunctionsService } from 'src/app/services/util-functions.service';
import { GenericLatestActivitiesComponent } from 'src/app/views/objectManagement/generic-latest-activities/generic-latest-activities.component';
import { DataSourceNewActivity } from 'src/app/views/objectManagement/generic-latest-activities/provider-data-source-new';
import * as dss from 'src/app/models/datasource.model';
import { SubSink } from 'subsink';
import { TranslateService } from '@ngx-translate/core';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { ExportFormatInfo } from 'src/app/models/api/models/ExportModel';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { DataSegment } from 'src/app/models/api/models/staging/DataSegment';
import { RightHolder } from 'src/app/models/api/models/authorization/RightHolder';
import { UserInfo } from 'src/app/models/api/models/session/UserInfo';
import { DataModel, JobInfo, NewPermission, PermissionData } from 'src/app/views/authorization/role-permissions/role-permissions.component';
import { PermissionOps } from 'src/app/views/authorization/permission-operations';
import { SafeObservableResult } from 'src/app/helper/observables';
import { PermissionRich } from 'src/app/views/authorization/permission-enrichment';
import { PlayErrorModel, PlayErrorResponse } from 'src/app/models/play.error.model';



@Component({
  selector: 'app-view-source-int',
  templateUrl: './view-source-int.component.html',
  styleUrls: ['./view-source-int.component.scss'],
  providers: [MessageService]
})
export class ViewSourceIntComponent implements OnInit {
  items: MenuItem[] = [];
  home: MenuItem = { icon: 'pi pi-table', label: "Datenquellen", routerLink: '/SourceIntegrationCreateDatasource' };
  @ViewChild('objectPipeline') objectPipeline;
  subs = new SubSink;



  editModeOn: boolean = false;
  constructor(private service_api: IntegratedSourceService, private translate: TranslateService,
    private router: Router, private messageService: MessageService, private datasourceService: DatasourcesService,
    public userService: UserService, public utilService: UtilFunctionsService, private errorService: SystemMessageLogService) { }

  isLoading: boolean = false;
  progressMode: string = "indeterminate";

  source_key?: IntegratedSourceModel.DataSourceKey<number> = undefined;
  data_source?: IntegratedSourceModel.DataSource<number> = undefined;
  data_source_copy?: IntegratedSourceModel.DataSource<number> = undefined;

  connectorInfo?: IntegratedSourceController.ConnectorInfo;
  activities: dss.DataPackageProtocolEntry[] = [];
  new_data_source_name: string = "";
  userDetails: any = this.userService.getUserDetailsRow();

  dataSourceActivityNewTypeClass: DataSourceNewActivity = new DataSourceNewActivity(this.datasourceService, [], this.userDetails, this.utilService, this.router);
  //@ViewChild("dsLatestActivities") dsObjectActivities!: GenericLatestActivitiesComponent<dss.DataSource, any>;

  exportFormats: ExportFormatInfo<any>[] = [];

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.home = { icon: 'pi pi-table', label: 'Datenquellen', routerLink: '/SourceIntegrationCreateDatasource' };

    this.source_key = this.getUrlContext();
    const source_key = this.source_key;

    console.warn("We filter. The backend will do this soon")
    const conns_ob = this.service_api.getConnectors();
    const ds_ob = this.service_api.getDataSource(false, true);
    const fina_ob = forkJoin(conns_ob, ds_ob);


    this.subs.sink = fina_ob.subscribe(sources => {
      this.data_source = sources[1].find(d => d.Id.Id == this.source_key?.Id && d.Id.Origin == this.source_key?.Origin);
      this.data_source_copy = { ...this.data_source };
      this.connectorInfo = sources[0].find(c => c.Key.Id === this.data_source.Connector.Id);
      this.new_data_source_name = this.data_source?.Name;
      this.items = [{ label: this.new_data_source_name, routerLink: '/SourceIntegrationCreateDatasource' }];

      console.warn("Move onInitStreams to event when streams tab is opened first time");
      const dataSourceActivityNewTypeClass = new DataSourceNewActivity(this.datasourceService, [], this.userDetails, this.utilService, this.router);

      //this.dsObjectActivities.setObject(dataSourceActivityNewTypeClass);

      this.onInitStreams(this.data_source.Id);

    })

    // -- export formats
    this.subs.sink = this.datasourceService.getFormats().subscribe(formats => {
      this.exportFormats = formats;
    },
      err => this.handleError(err)
    )


    // -- Permissions Component
    console.warn("TODO: add ui notifications (progress bar and messages for permission component")
    this.getPermissionData(source_key).subscribe(data => {
      this.updatePermissionModel(source_key, data);
    })

    // -- Job Log
    this.service_api.Jobs.list(new IntegratedSourceModel.GetJobsArg(source_key)).subscribe(jobInfos => {
      console.log("Job Infos", jobInfos)
    })
  }

  /**
   * Holt alle Informationen, die für den Permission Dialog benötigt werden.
   * @param source_key Data Source Key
   */
  getPermissionData(source_key: IntegratedSourceModel.DataSourceKey<number>) {
    const role_obs = this.userService.getRightHolders();
    const get_perm_arg = new IntegratedSourceModel.GetPermissionArg(source_key);
    const perm_obs = this.service_api.getIntSourcePermission(get_perm_arg);
    const user_obs = this.userService.getUserCommon();
    const rights_obs = this.service_api.getSupportedRights();

    return role_obs
      .pipe(mergeMap(roles => perm_obs
        .pipe(mergeMap(permissions => user_obs
          .pipe(mergeMap(users => rights_obs.pipe(map(rights => {
            const result: [RightHolder[], IntegratedSourceModel.IntSourcePermission[], UserInfo[], string[]] = [roles, permissions, users, rights];
            return result;
          }))
          ))
        ))
      ));
  }

  /**
   * Der Datasource Schlüssel aus der Route.
   * @returns Data Source Key
   */
  getUrlContext(): IntegratedSourceModel.DataSourceKey<number> {
    const arr = this.router.url.split('/');
    const id = parseInt(arr.last());
    const origin = arr.getRight(1);
    console.log("Data Source ID: " + id);
    console.log("Data Source Origin: " + origin);
    return new IntegratedSourceModel.DataSourceKey(id, origin);
  }

  /**
   * Prüft, ob der Data Source Name geändert wurde.
   * Nützlich für Update-Option.
   * @returns
   */
  dsNameIsDirty(): boolean {
    return this.new_data_source_name == this.data_source?.Name;
  }


  // Stream and Query setion = START

  // -- modular, portable to other components
  psa_infos: IntegratedSourceModel.PsaInfo[] = [];
  catalog?: IntegratedSourceModel.GenCatalog = undefined;
  streams_initialized: boolean = false;
  stream_psa_list: Map<string, IntegratedSourceModel.PsaInfo[]> = new Map();  // look up map for speed

  selected_stream?: IntegratedSourceModel.GenCatalog.StreamInfo = undefined;
  selected_stream_psa_infos: IntegratedSourceModel.PsaInfo[] = [];
  selected_psa?: IntegratedSourceModel.PsaInfo = undefined;

  psa_table_data: any[] = [];
  psa_table_cols: MColumn[] = [];

  onInitStreams(key: IntegratedSourceModel.DataSourceKey<number>) {
    console.log("On Init Streams");

    const psa_arg = new IntegratedSourceModel.GetPsaInfosArg(key)
    const psa_ob = this.service_api.psaInfos(psa_arg);

    const stream_arg = new IntegratedSourceModel.GetStreamsArg(key);
    const stream_ob = this.service_api.getStreams(stream_arg);

    //this.selected_psa?.Table

    const all_ob = stream_ob.pipe(mergeMap(catalog => {
      return psa_ob.pipe(map(psa_list => {
        const result: [IntegratedSourceModel.GetStreamsResult, IntegratedSourceModel.PsaInfo[]] = [catalog, psa_list];
        return result;
      }))
    }));

    this.subs.sink = all_ob.subscribe(pair => {

      this.psa_infos = pair[1];
      this.catalog = pair[0].Catalog;


      for (let info of this.psa_infos) {
        if (this.stream_psa_list.has(info.Stream)) {
          this.stream_psa_list.get(info.Stream).push(info);
        } else {
          this.stream_psa_list.set(info.Stream, [info]);
        }
      }

      this.streams_initialized = true;

    })
  }

  onStreamSelected(event: any) {
    console.log("Event", event);

    if (event.data !== undefined) {

      const stream_info: IntegratedSourceModel.GenCatalog.StreamInfo = event.data;
      console.log("Stream Info", stream_info);

      const sel_psa_infos = this.stream_psa_list.get(stream_info.stream.name);
      if (sel_psa_infos !== undefined)
        this.selected_stream_psa_infos = sel_psa_infos;
      else
        this.selected_stream_psa_infos = [];
    }
  }

  onPsaSelected(event: any) {
    console.log(event);

    if (event.data !== undefined) {
      this.setLoading(true);
      const psa_info: IntegratedSourceModel.PsaInfo = event.data;
      console.log(psa_info);

      console.warn("Fire psa query instantly... maybe on button or with flag");
      this.onQueryPsa(psa_info);
      this.onFetchPsaODataUrl(psa_info);
    } else {
      this.selected_psa = undefined;
    }
  }

  setLoading(active: boolean) {
    this.isLoading = active;
  }

  handleError(err: Error, info?: string) {
    this.errorService.handleError(err);

    if (info === undefined)
      console.error(err);
    else
      console.error(info, err);
  }

  /**
   * Verallgemeinerte Variante für mehrere Fehler.
   * @param errs Fehlerliste.
   */
  handleErrors(...errs: Error[]) {
    this.errorService.generalErrorsEventEmitter.emit(errs);
    errs.forEach(e => console.error(e));
  }

  onDownloadPsaClick(event: ExportFormatInfo<any>) {

    console.log("onDownloadPsaClick", event)

    if (this.selected_psa === undefined) {
      this.handleError(new Error("No PSA selected."));
    } else {
      const psa = this.selected_psa;
      if (event === undefined) {
        this.handleError(new Error("File Type for download not specified"));
      } else {
        this.onDownloadPsa(psa, event);
      }
    }
  }

  //isDownloading: boolean = false;
  exportLimit?: number = undefined;
  exportOffset?: number = undefined;
  export_loaded: number = 0;

  onDownloadPsa(psa: IntegratedSourceModel.PsaInfo, format: ExportFormatInfo<any>) {

    const segment = new DataSegment(this.exportLimit, this.exportOffset);
    const arg = new IntegratedSourceModel.ExportArg(psa, format.Name, format.InitialConfig, segment);
    console.log("Downlaod PSA", arg)

    this.setLoading(true);

    this.service_api.exportPsa(arg).subscribe(httpEvent => {

      if (httpEvent.type === HttpEventType.DownloadProgress) {
        console.log("download progress", httpEvent);
        this.export_loaded = httpEvent.loaded;
      }
      if (httpEvent.type === HttpEventType.Response) {
        console.log("donwload completed", httpEvent);
        const data = window.URL.createObjectURL(httpEvent.body);

        const link = document.createElement("a");
        link.href = data;
        link.download = format.Name + "." + format.Extension;

        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
          new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );

      }

    },
      err => this.handleError(err),
      () => this.setLoading(false)
    )
  }

  /**
   * Holt die OData URL für diese PSA/Tabelle
   * @param psa
   */
  onFetchPsaODataUrl(psa: IntegratedSourceModel.PsaInfo) {

    this.service_api.getPsaEndpoints(psa).subscribe(endpoints => {
      if (endpoints.length > 0) {
        const url = endpoints.head().Url;
        const base_and_table = ViewSourceIntComponent.parseODataUrl(url);
        this.finalUrl = base_and_table[0];
        // this.finalUrl = endpoints.head().Url;  // table specific version, disabled due OData bug.
      } else {
        this.finalUrl = undefined;
      }
    },
      err => this.handleError(err)
    )
  }

  static parseODataUrl(url: string): [string, string] {

    const last_slash = url.lastIndexOf("/");

    const basePart = url.substring(0, last_slash + 1);
    const tablePart = url.substring(last_slash + 1);

    return [basePart, tablePart];
  }

  onQueryPsa(psa: IntegratedSourceModel.PsaInfo) {
    // selected psa

    // fire query
    console.warn("Add checks: data source set, stream set");
    const arg = new IntegratedSourceModel.QueryStreamArg(this.data_source.Id, this.selected_stream.stream.name, psa);
    const obs = this.service_api.queryStream(arg).pipe(concatMap(blob => {

      const p = new Promise((resolve, reject) => {

        const reader = new FileReader();
        reader.onload = () => {
          const buffer = reader.result as ArrayBuffer;
          const jsonString = new TextDecoder().decode(buffer);
          console.log(jsonString);
          const json_strings = jsonString.split("\n");
          console.log(json_strings);

          const json_strings_safe = json_strings.slice(0, json_strings.length - 1);
          const json_data_arr = json_strings_safe.map(s => JSON.parse(s));

          console.log(json_data_arr);

          resolve(json_data_arr);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(blob);

      });

      const o = from(p);

      return o;

    }));


    this.subs.sink = obs.subscribe((json_data: any) => {

      console.log(json_data);
      console.log(typeof (json_data));

      console.log("PSA Columns", psa.Columns);

      const data_arr = <Array<any>>(json_data);
      console.log("Received PSA data:", data_arr);

      this.psa_table_cols = psa.Columns;
      this.psa_table_data = data_arr;

    },
      err => this.handleError(err),
      () => this.setLoading(false)
    );



    // display result
  }

  // Stream and Query setion = END

  editStreamsClicked(event: any) {
    const key = this.source_key;

    if (key !== undefined) {
      this.router.navigate(['/', 'SourceIntegrationGetStreams', key.Origin, key.Id]);
    }

  }

  switchEditMode: boolean = false;
  toggleStreamEdit() {
    if (this.switchEditMode) {
      this.switchEditMode = false
    } else {
      this.switchEditMode = true;
    }
  }

  onInitEndpoints() {
    this.objectPipeline.createApiUrl(this);
  }
  onChangeEditMode() {
    console.log("Save Source");

    if (this.editModeOn) {
      console.log("Save Source");
      const ds = this.data_source_copy;
      const arg = new IntegratedSourceModel.UpdateDataSourceArg(this.source_key, ds.Name, ds.ConnectorConfig)
      this.subs.sink = this.service_api.updateDataSource(arg).subscribe(() => {
        this.data_source = { ...ds };
        this.editModeOn = false;
        this.messageService.add({
          severity: "success",
          summary: this.translate.instant("Message.UpdateDataSourceSuccess.Title"),
          detail: this.translate.instant("Message.UpdateDataSourceSuccess.Text1") + ds.Id.Id +
            this.translate.instant("Message.UpdateDataSourceSuccess.Text2"),
        });
      }, (err) => {
        this.handleError(err)
      }, () => { this.editModeOn = false })

    } else {
      console.log("Activate EditMode");
      this.editModeOn = true;
    }

  }


  // == OData

  textToClipboard(url: string) {
    const dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = url;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);

    this.messageService.add({
      severity: "success",
      summary: this.translate.instant("Message.CopyURLSuccess.Title"),
      detail: this.translate.instant("Message.CopyURLSuccess.Text"),
      //summary: "Url copied!",
      //detail: "Url is copied to clipboard",
    });
  }

  finalUrl?: string = undefined;

  // == Permission Component

  /**
   * Schreibt die neuen Informationen in die Permission Komponente.
   * @param sourceKey Objektschlüssel
   * @param data Permission und Metadaten
   */
  updatePermissionModel(sourceKey: IntegratedSourceModel.DataSourceKey<number>, data: [RightHolder[], IntegratedSourceModel.IntSourcePermission[], UserInfo[], string[]]) {

    const permission_data: PermissionData<IntegratedSourceModel.IntSourceAccessible, IntegratedSourceModel.IntSourcePermission> = {
      right_holders: data[0],
      source_permissions: data[1],
      users: data[2],
      rights: data[3]
    }

    this.permissions_model = {
      Accessible: new IntegratedSourceModel.IntSourceAccessible(sourceKey),
      PermissionData: permission_data,
      NewPermission: new NewIntSourcePermission()
    }
  }

  permissions_model?: DataModel<IntegratedSourceModel.IntSourceAccessible, IntegratedSourceModel.IntSourcePermission>;
  /**
   * speichert die aktuell durchzuführenden Aktionen, sobald sich auf der Komponente etwas geändert hat.
   */
  lastJobInfo?: JobInfo<IntegratedSourceModel.IntSourceAccessible> = undefined;

  onPermissionSelectionChanged(jobInfo: JobInfo<IntegratedSourceModel.IntSourceAccessible>) {
    this.lastJobInfo = jobInfo;
    console.log(this.lastJobInfo);
  }

  /**
   * Wird ausgeführt, sobald auf den Permission Update button gegklickt wurde.
   */
  clickUpdatePermissionsComp() {

    if (this.lastJobInfo === undefined) {
      // just say OK!
      console.log("No Job Info available / changed");
    } else {

      const cf: (item: IntegratedSourceModel.IntSourcePermission) => Observable<IntegratedSourceModel.IntSourcePermission> = this.service_api.createIntSourcePermission.bind(this.service_api);
      const uf: (item: IntegratedSourceModel.IntSourcePermission) => Observable<number> = this.service_api.updateIntSourcePermission.bind(this.service_api);
      const df_bound = this.service_api.deleteIntSourcePermission.bind(this.service_api);
      const df: (p: IntegratedSourceModel.IntSourcePermission) => Observable<number> = PermissionOps
        .mkDeleteOp<
          IntegratedSourceModel.IntSourceAccessible,
          IntegratedSourceModel.IntSourcePermission,
          IntegratedSourceModel.DeletePermissionArg
        >(df_bound, p => new IntegratedSourceModel.DeletePermissionArg(p.ID, p.Accessible.ID.Origin))

      const ob = PermissionOps.processPermissions(this.lastJobInfo.Create, this.lastJobInfo.Update, this.lastJobInfo.Delete, cf, uf, df);

      // update view
      const source_key = this.permissions_model.Accessible.ID;

      const final_ob = ob
        .pipe(concatMap(results => this.getPermissionData(source_key)
          .pipe(map(data => {
            this.updatePermissionModel(source_key, data);
            return results;
          }))))

      final_ob.subscribe(results => {
        // TODO: error handling and ui notification


        const failed = results.filter(r => r.Successful == false);
        console.log("Failed Operations", failed);

        if (failed.nonEmpty()) {

          const fail_texts = failed.map(f => this.mkPermErrorTextArg(f))

          const use_multi_error = true;

          if (use_multi_error) {
            // patch the texts
            const _errors = failed.map(f => f.Error);

            for (let i = 0; i < failed.length; i++) {
              const e_opt = PlayErrorModel.asPlayError(_errors[i]);
              if (e_opt) {
                e_opt.error.error.exception.description = fail_texts[i];
              }
            }

            this.handleErrors(..._errors);
          } else {
            const fail_reduced = fail_texts.reduce((a, b) => a + ", " + b);
            const msg = "Could not process the following Permissions: " + fail_reduced;
            this.handleError(new Error(msg));
          }

        } else {
          console.log("Permission processed via component", results);
          this.messageService.add({
            severity: "success",
            summary: this.translate.instant("Message.UpdatePermissionSuccess.Title"),
            detail: this.translate.instant("Message.UpdatePermissionSuccess.Text1") + source_key.Id + this.translate.instant("Message.UpdatePermissionSuccess.Text2"),
          });

        }

      })

    }

  }

  mkPermErrorTextArg(f: SafeObservableResult<IntegratedSourceModel.IntSourcePermission, any>): string {

    const rich = <PermissionRich<any>>f.Argument;

    const http_err: HttpErrorResponse = <HttpErrorResponse>f.Error;

    const e = http_err.error;

    let err = "";
    if (PlayErrorResponse.isShape(e)) {
      const pe = <PlayErrorResponse>e;
      err = pe.error.exception.description;
    } else {
      err = f.Error.message;
    }

    return this.mkPermErrorText(f.Tag, rich.Label, rich.Type, err);
  }

  /**
   * Erstellt einen aussagenkräftigen Fehlertext für eine fehlgeschlagene Permission-Operation.
   * @param op Operation, z.B. 'Update'
   * @param role Rollenname
   * @param roleType Rollentyp
   * @param message Fehlermeldung
   * @returns Ein Satz in der Form: 'Update permission for User 'Sarah': No admin nor owner.
   */
  mkPermErrorText(op: string, role: string, roleType: string, message: string): string {
    return `${op} permission for ${roleType} '${role}': ${message}`;
  }

  // == Permission Comopnent END


}

/**
 * Typklasse für Permission Komponente
 */
class NewIntSourcePermission implements NewPermission<IntegratedSourceModel.IntSourceAccessible, IntegratedSourceModel.IntSourcePermission> {
  create(role: number, accessible: IntegratedSourceModel.IntSourceAccessible): IntegratedSourceModel.IntSourcePermission {
    return new IntegratedSourceModel.IntSourcePermission(-1, role, false, accessible, []);
  }
}
