<app-change-user-password-dialog></app-change-user-password-dialog>
<p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>
<div class="p-grid">
    <app-system-message-log></app-system-message-log>
    <div class="p-col-12 p-grid">

        
        <div class="p-col-4">
            <div  style="height: 250px;width:100%;position: relative;text-align: center">
                <!-- <div class="card-header">
                    <div class="card-title">
                        <h5>{{'Avatar' | translate}}
                        </h5>
                    </div>
                </div> -->

                <div style="position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);">
                    <img *ngIf="image" style="width: 150px;height:150px;border-radius: 50%;background-color: white;border: 1px" [src]='image' alt="freya-layout" />
                    <img *ngIf="!image" style="width: 150px;height:150px;border-radius: 50%;" src="assets/layout/images/avatar-placeholder.png"
                        alt="freya-layout" />

                    <div *ngIf="currentUser" class="card-footer" style="margin-top: 10px;">
                        <!-- <span style="margin-right: 5px;">
                            <p-badge [value]="currentUser.userInfo.Username"></p-badge>
                        </span> -->
                        <!-- <span style="margin-right: 5px;">
                            <p-badge *ngIf="currentUser.userInfo.IsAdmin === true" value="{{'Admin' | translate}}"
                                severity="warning"></p-badge>
                        </span>
                        <span style="margin-right: 5px;">
                            <p-badge *ngIf="currentUser.userInfo.IsAdmin === false" value="{{'User' | translate}}"
                                severity="success"></p-badge>
                        </span> -->
                        <!-- <span style="margin-right: 5px;">
                            <p-badge value="{{'Active' | translate}}" severity="success"></p-badge>
                        </span> -->
                    </div>
                    <div style="margin-top: 10px;">
                        <p-fileUpload mode="basic" customUpload="true" (uploadHandler)="onUploadAvatar($event)"
                            accept="image/*" maxFileSize="2000000" [auto]="true" chooseLabel="{{'Change picture' | translate}}">
                        </p-fileUpload>

                    </div>
                </div>

            </div>
            <!-- <app-app-subscriptions [currentUser]="currentUser" [currentLicenses]="currentLicenses">
            </app-app-subscriptions> -->

        </div>
        <div class="p-col-8  p-ai-center p-jc-center">
            <div class="card" style="height: 250px">
                <div class="card-header">
                    <div class="card-title">
                        <h4>{{'ProfileInformation' | translate}}
                        </h4>
                    </div>
                    <!-- <p class="subtitle">
                        <button pButton pRipple type="button" icon="pi pi-pencil" label="{{buttonText | translate}}"
                            (click)="onClickEdit()" pTooltip="{{'ChangeInformation' | translate}}"
                            class="p-button-rounded p-button p-button-text p-mr-2 p-mb-2"></button>
                    </p> -->
                </div>


                <div class="p-grid" style="width:100%" *ngIf="currentUser">
                    <div class="p-col-2">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <input type="text" id="ID" [disabled]="true" pInputText
                                    [(ngModel)]="currentUser.userInfo.ID">
                                <label for="ID">{{'ID' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-10">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <input type="text" id="Username" [disabled]="inputDisabled" pInputText
                                    [(ngModel)]="currentUser.userInfo.Username">
                                <label for="Username">{{'Username' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-6">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <input type="text" id="FirstNameInput" [disabled]="inputDisabled" pInputText
                                    [(ngModel)]="currentUser.userInfo.FirstName">
                                <label for="FirstNameInput">{{'FirstName' | translate}}</label>
                            </span>
                        </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <input type="text" id="LastNameInput" [disabled]="inputDisabled" pInputText
                                    [(ngModel)]="currentUser.userInfo.LastName">
                                <label for="LastNameInput">{{'LastName' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <input type="email" id="emailInput" [disabled]="inputDisabled" pInputText
                                    [(ngModel)]="currentUser.userInfo.EMail">
                                <label for="emailInput">{{'EMail' | translate}}</label>
                            </span>
                        </div>
                    </div>
                </div>
                <!-- <button [disabled]="inputDisabled" (click)="displayChangePasswordDialog()" pButton>{{'ChangePassword' |
                    translate}}</button> -->


            </div>

        </div>

    </div>



            <app-app-subscriptions style="width: 100%" [currentUser]="currentUser" [currentLicenses]="currentLicenses">
            </app-app-subscriptions>
        



</div>
