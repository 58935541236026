import { DynamicItemInfo } from "./com-bion-json";
import { DataSet } from "./datamarket.model";
import { PlayJsonInheritance } from "./play.model";

/**
 * Push Arguments
 * @param Connector Connector ID
 * @param Base64FileData Base64 encoded file data
 * @param ConnectorSettings Individual connector parameters
 * @param LoadSettings Data fetch options
 * @param DynamicItemInfo Data fetch options
 */
export class PushDataArg {
	Connector: string;
	Base64FileData: string;
	ConnectorSettings: any;
	LoadSettings: LoadSettings;
}

// export class ExtractFromConnectorArg {
// 	ConnectorInfo: DynamicItemInfo;
// 	LoadSettings: LoadSettings;
// 	Base64FileData?: string;
// }


export class ConnectorInfo<A, B> {
	IsPullSource: boolean;
	IsPushSource: boolean;
	InitialExtractArg: A;
	InitialMetaRequestArg: B;
}

/**
 * Adapter Type Information
 */
export class AdapterTypeInfo<A, B> {
	ID: number;
	Name: string;
	Version: number;
	Class: string;
	Description?: string;
	Category: string;
	Info: ConnectorInfo<A, B>;
}

// ExtractFromConnectorArg(ConnectorInfo:DynamicItemInfo, LoadSettings: LoadSettings, Base64FileData:Option[String])

// == META Extraction

export class ExtractMetaFromConnectorArg {
	ConnectorMetaInfo: DynamicItemInfo;
	Base64FileData?: string;
}

export class ExtractMetaFromConnectorResult {
	MetaData: any;
}

/**
 * Excel Sheet Information
 */
export class ExcelMetaAccess {
	Info: string[] = [];
}

export class SheetInfo {
	Index: number;
	Name: string;
	IsHidden: boolean;
}

// /**
//  * Excel Data Source Meta Information Result
//  */
// export class ExcelMetaInfo {
// 	Sheets: SheetInfo[];
// }

// export class ExcelMetaInfo {
// 	Sheets: Array<SheetInfo>;
// 	constructor(Sheets: Array<SheetInfo>) {
// 		if (Sheets === undefined) throw new Error("Class 'ExcelMetaInfo': Required value 'Sheets' is undefined");
// 		this.Sheets = Sheets;
// 	}
// }

// /**
//  * Excel Pull Information Result
//  */
// export class ExcelPullMetaInfo {
// 	Sheets: SheetInfo[];
// }




// ExcelPullMetaInfo
// == META Extraction - END

/**
 * Source contains named tables
 */
interface TableNavigation {
	Table: string;
}

interface ColumnDetectionSettings {
	DetectColumnTypes: boolean;
	UseDetectLimit: boolean;
	DetectLimit: number;
}

// /**
//  * Excel connector settings
//  */
// export class ExcelBlobAccess
// 	implements TableNavigation, ColumnDetectionSettings {
// 	Table: string;
// 	DetectColumnTypes: boolean;
// 	UseDetectLimit: boolean;
// 	DetectLimit: number;
// 	// DetectColumnTypes:Boolean, UseDetectLimit:Boolean, DetectLimit:Int
// }

// export class ExcelBlobAccess {
// 	Table: string;
// 	DetectColumnTypes: boolean;
// 	UseDetectLimit: boolean;
// 	DetectLimit: number;
// 	constructor(Table: string, DetectColumnTypes: boolean, UseDetectLimit: boolean, DetectLimit: number) {
// 		if (Table === undefined) throw new Error("Class 'ExcelBlobAccess': Required value 'Table' is undefined");
// 		if (DetectColumnTypes === undefined) throw new Error("Class 'ExcelBlobAccess': Required value 'DetectColumnTypes' is undefined");
// 		if (UseDetectLimit === undefined) throw new Error("Class 'ExcelBlobAccess': Required value 'UseDetectLimit' is undefined");
// 		if (DetectLimit === undefined) throw new Error("Class 'ExcelBlobAccess': Required value 'DetectLimit' is undefined");
// 		this.Table = Table;
// 		this.DetectColumnTypes = DetectColumnTypes;
// 		this.UseDetectLimit = UseDetectLimit;
// 		this.DetectLimit = DetectLimit;
// 	}
// }


// /**
//  * Excel connector settings
//  */
// export class ExcelUriAccess
// 	implements TableNavigation, ColumnDetectionSettings {
// 	Uri: string;
// 	Table: string;
// 	DetectColumnTypes: boolean;
// 	UseDetectLimit: boolean;
// 	DetectLimit: number;
// 	// DetectColumnTypes:Boolean, UseDetectLimit:Boolean, DetectLimit:Int
// }

// export class ExcelUriAccess {
// 	Uri: string;
// 	Table: string;
// 	DetectColumnTypes: boolean;
// 	UseDetectLimit: boolean;
// 	DetectLimit: number;
// 	constructor(Uri: string, Table: string, DetectColumnTypes: boolean, UseDetectLimit: boolean, DetectLimit: number) {
// 		if (Uri === undefined) throw new Error("Class 'ExcelUriAccess': Required value 'Uri' is undefined");
// 		if (Table === undefined) throw new Error("Class 'ExcelUriAccess': Required value 'Table' is undefined");
// 		if (DetectColumnTypes === undefined) throw new Error("Class 'ExcelUriAccess': Required value 'DetectColumnTypes' is undefined");
// 		if (UseDetectLimit === undefined) throw new Error("Class 'ExcelUriAccess': Required value 'UseDetectLimit' is undefined");
// 		if (DetectLimit === undefined) throw new Error("Class 'ExcelUriAccess': Required value 'DetectLimit' is undefined");
// 		this.Uri = Uri;
// 		this.Table = Table;
// 		this.DetectColumnTypes = DetectColumnTypes;
// 		this.UseDetectLimit = UseDetectLimit;
// 		this.DetectLimit = DetectLimit;
// 	}
// }

// export class ExcelPullMetaAccess {
// 	Uri: string;
// }

// export class CsvMetaAccess {
// 	Info: string[] = [];
// }
// /**
//  * CSV connector settings
//  */
// export class CsvMetaInfo { }
// export class CsvPushSettings implements ColumnDetectionSettings {
// 	Separator: string;
// 	// DetectColumnTypes:Boolean, UseDetectLimit:Boolean, DetectLimit:Int
// 	DetectColumnTypes: boolean;
// 	UseDetectLimit: boolean;
// 	DetectLimit: number;
// }

// export class CsvPushSettings {
// 	Separator: string;
// 	LineBreak: string;
// 	DetectColumnTypes: boolean;
// 	UseDetectLimit: boolean;
// 	DetectLimit: number;
// 	constructor(Separator: string, LineBreak: string, DetectColumnTypes: boolean, UseDetectLimit: boolean, DetectLimit: number) {
// 		if (Separator === undefined) throw new Error("Class 'CsvPushSettings': Required value 'Separator' is undefined");
// 		if (LineBreak === undefined) throw new Error("Class 'CsvPushSettings': Required value 'LineBreak' is undefined");
// 		if (DetectColumnTypes === undefined) throw new Error("Class 'CsvPushSettings': Required value 'DetectColumnTypes' is undefined");
// 		if (UseDetectLimit === undefined) throw new Error("Class 'CsvPushSettings': Required value 'UseDetectLimit' is undefined");
// 		if (DetectLimit === undefined) throw new Error("Class 'CsvPushSettings': Required value 'DetectLimit' is undefined");
// 		this.Separator = Separator;
// 		this.LineBreak = LineBreak;
// 		this.DetectColumnTypes = DetectColumnTypes;
// 		this.UseDetectLimit = UseDetectLimit;
// 		this.DetectLimit = DetectLimit;
// 	}
// }


// export class CsvUrlAccess {
// 	Uri: string;
// 	Separator: string;
// 	LineBreak: string;
// 	DetectColumnTypes: boolean;
// 	UseDetectLimit: boolean;
// 	DetectLimit: number;
// 	constructor(Uri: string, Separator: string, LineBreak: string, DetectColumnTypes: boolean, UseDetectLimit: boolean, DetectLimit: number) {
// 		if (Uri === undefined) throw new Error("Class 'CsvUrlAccess': Required value 'Uri' is undefined");
// 		if (Separator === undefined) throw new Error("Class 'CsvUrlAccess': Required value 'Separator' is undefined");
// 		if (LineBreak === undefined) throw new Error("Class 'CsvUrlAccess': Required value 'LineBreak' is undefined");
// 		if (DetectColumnTypes === undefined) throw new Error("Class 'CsvUrlAccess': Required value 'DetectColumnTypes' is undefined");
// 		if (UseDetectLimit === undefined) throw new Error("Class 'CsvUrlAccess': Required value 'UseDetectLimit' is undefined");
// 		if (DetectLimit === undefined) throw new Error("Class 'CsvUrlAccess': Required value 'DetectLimit' is undefined");
// 		this.Uri = Uri;
// 		this.Separator = Separator;
// 		this.LineBreak = LineBreak;
// 		this.DetectColumnTypes = DetectColumnTypes;
// 		this.UseDetectLimit = UseDetectLimit;
// 		this.DetectLimit = DetectLimit;
// 	}
// }

// export class CsvUrlMetaAccess {
// 	Uri: string;
// 	constructor(Uri: string) {
// 		if (Uri === undefined) throw new Error("Class 'CsvUrlMetaAccess': Required value 'Uri' is undefined");
// 		this.Uri = Uri;
// 	}
// }


// export class CsvUrlMetaInfo {
// 	Text: string;
// 	constructor(Text: string) {
// 		if (Text === undefined) throw new Error("Class 'CsvUrlMetaInfo': Required value 'Text' is undefined");
// 		this.Text = Text;
// 	}
// }



/**
 * JDBC connector settings
 */

// case class JdbcAccess(URL:String, User:String, Password:String, Table:String) extends TableNavigation
export class JdbcAccess implements TableNavigation {
	URL: string;
	User: string;
	Password: string;
	Table: string;

	constructor(URL: string, Table: string, User: string, Password: string) {
		this.URL = URL;
		this.User = User;
		this.Password = Password;
		this.Table = Table;
	}
}

export class JdbcAccessNew<T extends ConnectionSettings> {
	ConnectionSettings: T;
	Table: string;
	constructor(ConnectionSettings: T, Table: string) {
		if (ConnectionSettings === undefined)
			throw new Error(
				"Class 'JdbcAccess': Required value 'ConnectionSettings' is undefined"
			);
		if (Table === undefined)
			throw new Error(
				"Class 'JdbcAccess': Required value 'Table' is undefined"
			);
		this.ConnectionSettings = ConnectionSettings;
		this.Table = Table;
	}
}

export interface ConnectionSettings extends PlayJsonInheritance {
	User: string;
	Password: string;
	// URL(): string;       // Only needed by Backend Class
	_type: string;
}

export class CommonSettings implements ConnectionSettings {
	URL: string;
	User: string;
	Password: string;
	constructor(URL: string, User: string, Password: string) {
		this.URL = URL;
		this.User = User;
		this.Password = Password;
	}
	_type: string = "com.bion.connect.jdbc.CommonSettings";
}

export class PostgreSqlSettings implements ConnectionSettings {
	Host?: string;
	Port?: number;
	Database: string;
	User: string;
	Password: string;
	constructor(
		Database: string,
		User: string,
		Password: string,
		Host?: string,
		Port?: number
	) {
		if (Database === undefined)
			throw new Error(
				"Class 'PostgreSqlSettings': Required value 'Database' is undefined"
			);
		if (User === undefined)
			throw new Error(
				"Class 'PostgreSqlSettings': Required value 'User' is undefined"
			);
		if (Password === undefined)
			throw new Error(
				"Class 'PostgreSqlSettings': Required value 'Password' is undefined"
			);
		this.Host = Host;
		this.Port = Port;
		this.Database = Database;
		this.User = User;
		this.Password = Password;
	}
	_type: string = "com.bion.connect.jdbc.PostgreSqlSettings";
}

export class HsqlEmbeddedSettings implements ConnectionSettings {
	Path: string;
	User: string;
	Password: string;
	constructor(Path: string, User: string, Password: string) {
		if (Path === undefined)
			throw new Error(
				"Class 'HsqlEmbeddedSettings': Required value 'Path' is undefined"
			);
		if (User === undefined)
			throw new Error(
				"Class 'HsqlEmbeddedSettings': Required value 'User' is undefined"
			);
		if (Password === undefined)
			throw new Error(
				"Class 'HsqlEmbeddedSettings': Required value 'Password' is undefined"
			);
		this.Path = Path;
		this.User = User;
		this.Password = Password;
	}
	_type: string = "com.bion.connect.jdbc.HsqlEmbeddedSettings";
}

export class H2EmbeddedSettings implements ConnectionSettings {
	Path: string;
	User: string;
	Password: string;
	constructor(Path: string, User: string, Password: string) {
		if (Path === undefined)
			throw new Error(
				"Class 'H2EmbeddedSettings': Required value 'Path' is undefined"
			);
		if (User === undefined)
			throw new Error(
				"Class 'H2EmbeddedSettings': Required value 'User' is undefined"
			);
		if (Password === undefined)
			throw new Error(
				"Class 'H2EmbeddedSettings': Required value 'Password' is undefined"
			);
		this.Path = Path;
		this.User = User;
		this.Password = Password;
	}
	_type: string = "com.bion.connect.jdbc.H2EmbeddedSettings";
}

export class H2ServerSettings implements ConnectionSettings {
	Host: string;
	Database: string;
	User: string;
	Password: string;
	constructor(Host: string, Database: string, User: string, Password: string) {
		if (Host === undefined)
			throw new Error(
				"Class 'H2ServerSettings': Required value 'Path' is undefined"
			);
		if (Database === undefined)
			throw new Error(
				"Class 'H2ServerSettings': Required value 'Database' is undefined"
			);
		if (User === undefined)
			throw new Error(
				"Class 'H2ServerSettings': Required value 'User' is undefined"
			);
		if (Password === undefined)
			throw new Error(
				"Class 'H2ServerSettings': Required value 'Password' is undefined"
			);
		this.Host = Host;
		this.Database = Database;
		this.User = User;
		this.Password = Password;
	}
	_type: string = "com.bion.connect.jdbc.H2ServerSettings";
}
export class HsqlServerSettings implements ConnectionSettings {
	Host: string;
	User: string;
	Password: string;
	Database?: string;
	Port?: number;
	constructor(
		Host: string,
		User: string,
		Password: string,
		Database?: string,
		Port?: number
	) {
		if (Host === undefined)
			throw new Error(
				"Class 'HsqlServerSettings': Required value 'Path' is undefined"
			);
		if (User === undefined)
			throw new Error(
				"Class 'HsqlServerSettings': Required value 'User' is undefined"
			);
		if (Password === undefined)
			throw new Error(
				"Class 'HsqlServerSettings': Required value 'Password' is undefined"
			);
		this.Host = Host;
		this.Database = Database;
		this.User = User;
		this.Port = Port;
		this.Password = Password;
	}
	_type: string = "com.bion.connect.jdbc.HsqlServerSettings";
}
export class DB2Settings implements ConnectionSettings {
	Host: string;
	Database: string;
	User: string;
	Password: string;
	constructor(Host: string, Database: string, User: string, Password: string) {
		if (Host === undefined)
			throw new Error(
				"Class 'DB2Settings': Required value 'Path' is undefined"
			);
		if (Database === undefined)
			throw new Error(
				"Class 'DB2Settings': Required value 'Database' is undefined"
			);
		if (User === undefined)
			throw new Error(
				"Class 'DB2Settings': Required value 'User' is undefined"
			);
		if (Password === undefined)
			throw new Error(
				"Class 'DB2Settings': Required value 'Password' is undefined"
			);
		this.Host = Host;
		this.Database = Database;
		this.User = User;
		this.Password = Password;
	}
	_type: string = "com.bion.connect.jdbc.DB2Settings";
}

export class MariaDBSettings implements ConnectionSettings {
	Host: string;
	Port?: number;
	Database: string;
	User: string;
	Password: string;
	constructor(Host: string, Database: string, User: string, Password: string, Port?: number) {
		if (Host === undefined) throw new Error("Class 'MariaDBSettings': Required value 'Host' is undefined");
		if (Database === undefined) throw new Error("Class 'MariaDBSettings': Required value 'Database' is undefined");
		if (User === undefined) throw new Error("Class 'MariaDBSettings': Required value 'User' is undefined");
		if (Password === undefined) throw new Error("Class 'MariaDBSettings': Required value 'Password' is undefined");
		this.Host = Host;
		this.Port = Port;
		this.Database = Database;
		this.User = User;
		this.Password = Password;
	}
	_type: string = "com.bion.connect.jdbc.MariaDBSettings";
}

export class RedshiftSettings implements ConnectionSettings {
	Host: string;
	Database: string;
	User: string;
	Password: string;
	constructor(Host: string, Database: string, User: string, Password: string) {
		if (Host === undefined)
			throw new Error(
				"Class 'RedshiftSettings': Required value 'Path' is undefined"
			);
		if (Database === undefined)
			throw new Error(
				"Class 'RedshiftSettings': Required value 'Database' is undefined"
			);
		if (User === undefined)
			throw new Error(
				"Class 'RedshiftSettings': Required value 'User' is undefined"
			);
		if (Password === undefined)
			throw new Error(
				"Class 'RedshiftSettings': Required value 'Password' is undefined"
			);
		this.Host = Host;
		this.Database = Database;
		this.User = User;
		this.Password = Password;
	}
	_type: string = "com.bion.connect.jdbc.RedshiftSettings";
}

export class JdbcMetaAccess<T extends ConnectionSettings> {
	ConnectionSettings: T;
	Table?: string;
	constructor(ConnectionSettings: T, Table?: string) {
		this.ConnectionSettings = ConnectionSettings;
		this.Table = Table;
	}
}

export class TableColumnInfo {
	Name: string;
	DataType: number;
	ColumnSize: number;
	DecimalDigits: number;
	IsNullable: string;
	IsAutoIncrement: string;
	constructor(
		Name: string,
		DataType: number,
		ColumnSize: number,
		DecimalDigits: number,
		IsNullable: string,
		IsAutoIncrement: string
	) {
		this.Name = Name;
		this.DataType = DataType;
		this.ColumnSize = ColumnSize;
		this.DecimalDigits = DecimalDigits;
		this.IsNullable = IsNullable;
		this.IsAutoIncrement = IsAutoIncrement;
	}
}

export class JdbcMetaInfo {
	Tables: Array<string>;
	SelectedTable?: string;
	TableFields?: Array<TableColumnInfo>;
	constructor(
		Tables: Array<string>,
		SelectedTable?: string,
		TableFields?: Array<TableColumnInfo>
	) {
		this.Tables = Tables;
		this.SelectedTable = SelectedTable;
		this.TableFields = TableFields;
	}
}

// JSON

export class JsonPushAccess {
	ReadMode: string;
	UseQuery: boolean;
	Query: string;
	NestedObjectsMode: string;
	constructor(
		ReadMode: string,
		UseQuery: boolean,
		Query: string,
		NestedObjectsMode: string
	) {
		if (ReadMode === undefined)
			throw new Error(
				"Class 'JsonPushAccess': Required value 'ReadMode' is undefined"
			);
		if (UseQuery === undefined)
			throw new Error(
				"Class 'JsonPushAccess': Required value 'UseQuery' is undefined"
			);
		if (Query === undefined)
			throw new Error(
				"Class 'JsonPushAccess': Required value 'Query' is undefined"
			);
		if (NestedObjectsMode === undefined)
			throw new Error(
				"Class 'JsonPushAccess': Required value 'NestedObjectsMode' is undefined"
			);
		this.ReadMode = ReadMode;
		this.UseQuery = UseQuery;
		this.Query = Query;
		this.NestedObjectsMode = NestedObjectsMode;
	}
}

export class JsonMetaAccess {
	JsonPreview: boolean;
	constructor(JsonPreview: boolean) {
		if (JsonPreview === undefined)
			throw new Error(
				"Class 'JsonMetaAccess': Required value 'JsonPreview' is undefined"
			);
		this.JsonPreview = JsonPreview;
	}
}

export class JsonMetaInfo {
	ReadModes: Array<string>;
	NestedObjectsModes: Array<string>;
	Json?: any;
	constructor(
		ReadModes: Array<string>,
		NestedObjectsModes: Array<string>,
		Json?: any
	) {
		if (ReadModes === undefined)
			throw new Error(
				"Class 'JsonMetaInfo': Required value 'ReadModes' is undefined"
			);
		if (NestedObjectsModes === undefined)
			throw new Error(
				"Class 'JsonMetaInfo': Required value 'NestedObjectsModes' is undefined"
			);
		this.ReadModes = ReadModes;
		this.NestedObjectsModes = NestedObjectsModes;
		this.Json = Json;
	}
}

// /**
//  * DATAMARKET connector settings
//  */
// // case class DataMarketAccess(ServiceID:Int, Table:Int, Query:String)
// export class DataMarketAccess {
// 	ServiceID: number;
// 	Table: number;
// 	Query: string;
// 	IsPreview: boolean;
// 	PreviewRows: number;
// }

export class DataMarketAccess {
	ServiceID: number;
	Table: number;
	Query: string;
	IsPreview: boolean;
	PreviewRows: number;
	constructor(ServiceID: number, Table: number, Query: string, IsPreview: boolean, PreviewRows: number) {
		if (ServiceID === undefined) throw new Error("Class 'DataMarketAccess': Required value 'ServiceID' is undefined");
		if (Table === undefined) throw new Error("Class 'DataMarketAccess': Required value 'Table' is undefined");
		if (Query === undefined) throw new Error("Class 'DataMarketAccess': Required value 'Query' is undefined");
		if (IsPreview === undefined) throw new Error("Class 'DataMarketAccess': Required value 'IsPreview' is undefined");
		if (PreviewRows === undefined) throw new Error("Class 'DataMarketAccess': Required value 'PreviewRows' is undefined");
		this.ServiceID = ServiceID;
		this.Table = Table;
		this.Query = Query;
		this.IsPreview = IsPreview;
		this.PreviewRows = PreviewRows;
	}
}


// case class DataMarketMetaAccess()
export class DataMarketMetaAccess {
	Info: string[] = [];
}

// export class DataMarketMetaInfo {
// 	readonly Services: Array<DataMarketTableInfo>;
// 	constructor(Services: Array<DataMarketTableInfo>) {
// 		if(Services === undefined) throw new Error( "Class 'DataMarketMetaInfo': Required value 'Services' is undefined" );
// 		this.Services = Services;
// 	}
// }



export class DataMarketMetaInfo {
	Services: Array<DataSet>;
	constructor(Services: Array<DataSet>) {
		if (Services === undefined) throw new Error("Class 'DataMarketMetaInfo': Required value 'Services' is undefined");
		this.Services = Services;
	}
}

// case class DataMarketServiceInfo(ID:String)
export class DataMarketServiceInfo {
	ID: string;
}
export class DataMarketTableInfo {

	readonly source_id: number;
	readonly table_id: number;
	readonly category: string;
	readonly name: string;

	constructor(source_id: number, table_id: number, category: string, name: string) {
		if (source_id === undefined) throw new Error("Class 'DataMarketTableInfo': Required value 'source_id' is undefined");
		if (table_id === undefined) throw new Error("Class 'DataMarketTableInfo': Required value 'table_id' is undefined");
		if (category === undefined) throw new Error("Class 'DataMarketTableInfo': Required value 'category' is undefined");
		if (name === undefined) throw new Error("Class 'DataMarketTableInfo': Required value 'name' is undefined");
		this.source_id = source_id;
		this.table_id = table_id;
		this.category = category;
		this.name = name;
	}
}

/**
 * Settings for the load job
 * @param UseLimit Use Row Limit
 * @param Limit Row Limit Count
 */
export class LoadSettings {
	UseLimit: boolean;
	Limit: number;
}

export class ColumnInfo {
	Name: string;
	Type: DataType;
}

export class DataPreview {
	Columns: string[];
	Rows: string[][];
}

export class DataTypePreview {
	Columns: FieldInfo[];
	Rows: string[][];
}

export class DataType {
	Name: string;
	Length: number;
	Format: string;
	FixedSize: boolean;
}

export interface FieldInfoLike {
	Name: string;
	DataType: DataType;
	IsKey: boolean;
}

export class FieldInfo implements FieldInfoLike {
	Name: string;
	DataType: DataType;
	IsKey: boolean;
}

export class TableFieldInfo {
	Name: string;
	DataType: DataType;
	Position: number;
	IsKey: boolean;
	NameInTable: string;
}

export class Field {
	Name: string;
	FieldType: DataType;
	Value: any;
}


// REST API

export interface AuthMode extends PlayJsonInheritance {

}

export class NoAuth implements AuthMode {
	constructor() {
	}
	_type: string = "com.bion.connect.rest.NoAuth";
}

export class BasicAuth implements AuthMode {
	Name: string;
	Password: string;
	constructor(Name: string, Password: string) {
		if (Name === undefined) throw new Error("Class 'BasicAuth': Required value 'Name' is undefined");
		if (Password === undefined) throw new Error("Class 'BasicAuth': Required value 'Password' is undefined");
		this.Name = Name;
		this.Password = Password;
	}
	_type: string;
}

export class BearerAuth implements AuthMode {
	Token: string;
	constructor(Token: string) {
		if (Token === undefined) throw new Error("Class 'BearerAuth': Required value 'Token' is undefined");
		this.Token = Token;
	}
	_type: string = "com.bion.connect.rest.BearerAuth";
}

export class JsonQuery {
	Path: string;
	ReadMode: string;
	NestedObjectsMode: string;
	constructor(Path: string, ReadMode: string, NestedObjectsMode: string) {
		if (Path === undefined) throw new Error("Class 'JsonQuery': Required value 'Path' is undefined");
		if (ReadMode === undefined) throw new Error("Class 'JsonQuery': Required value 'ReadMode' is undefined");
		if (NestedObjectsMode === undefined) throw new Error("Class 'JsonQuery': Required value 'NestedObjectsMode' is undefined");
		this.Path = Path;
		this.ReadMode = ReadMode;
		this.NestedObjectsMode = NestedObjectsMode;
	}
}

export class RestApiAccess {
	URL: string;
	AuthMode: AuthMode;
	Query?: JsonQuery;
	constructor(URL: string, AuthMode: AuthMode, Query?: JsonQuery) {
		if (URL === undefined) throw new Error("Class 'RestApiAccess': Required value 'URL' is undefined");
		if (AuthMode === undefined) throw new Error("Class 'RestApiAccess': Required value 'AuthMode' is undefined");
		this.URL = URL;
		this.AuthMode = AuthMode;
		this.Query = Query;
	}
}

export class MetaPreview {
	URL: string;
	AuthMode: AuthMode;
	constructor(URL: string, AuthMode: AuthMode) {
		if (URL === undefined) throw new Error("Class 'MetaPreview': Required value 'URL' is undefined");
		if (AuthMode === undefined) throw new Error("Class 'MetaPreview': Required value 'AuthMode' is undefined");
		this.URL = URL;
		this.AuthMode = AuthMode;
	}
}
export class RestApiMetaAccess {
	Preview?: MetaPreview;
	constructor(Preview?: MetaPreview) {
		this.Preview = Preview;
	}
}

export class RestApiMetaInfo {
	Json: string;
	AuthModes: Array<AuthMode>;
	ReadModes: Array<string>;
	NestedObjectsModes: Array<string>;
	constructor(Json: string, AuthModes: Array<AuthMode>, ReadModes: Array<string>, NestedObjectsModes: Array<string>) {
		if (Json === undefined) throw new Error("Class 'RestApiMetaInfo': Required value 'Json' is undefined");
		if (AuthModes === undefined) throw new Error("Class 'RestApiMetaInfo': Required value 'AuthModes' is undefined");
		if (ReadModes === undefined) throw new Error("Class 'RestApiMetaInfo': Required value 'ReadModes' is undefined");
		if (NestedObjectsModes === undefined) throw new Error("Class 'RestApiMetaInfo': Required value 'NestedObjectsModes' is undefined");
		this.Json = Json;
		this.AuthModes = AuthModes;
		this.ReadModes = ReadModes;
		this.NestedObjectsModes = NestedObjectsModes;
	}
}

// SPSS
export class SpssPushAccess {
	UseLabelHeaders: boolean;
	ResolveLabels: boolean;
	Mode: string;
	constructor(UseLabelHeaders: boolean, ResolveLabels: boolean, Mode: string) {
		if (UseLabelHeaders === undefined) throw new Error("Class 'SpssPushAccess': Required value 'UseLabelHeaders' is undefined");
		if (ResolveLabels === undefined) throw new Error("Class 'SpssPushAccess': Required value 'ResolveLabels' is undefined");
		if (Mode === undefined) throw new Error("Class 'SpssPushAccess': Required value 'Mode' is undefined");
		this.UseLabelHeaders = UseLabelHeaders;
		this.ResolveLabels = ResolveLabels;
		this.Mode = Mode;
	}
}

export class SpssPushMetaAccess {
	Collection?: string;
	constructor(Collection?: string) {
		this.Collection = Collection;
	}
}

export class VariableFormatInfo {
	DecimalPlaces: number;
	ColumnWith: number;
	Type: number;
	constructor(DecimalPlaces: number, ColumnWith: number, Type: number) {
		if (DecimalPlaces === undefined) throw new Error("Class 'VariableFormatInfo': Required value 'DecimalPlaces' is undefined");
		if (ColumnWith === undefined) throw new Error("Class 'VariableFormatInfo': Required value 'ColumnWith' is undefined");
		if (Type === undefined) throw new Error("Class 'VariableFormatInfo': Required value 'Type' is undefined");
		this.DecimalPlaces = DecimalPlaces;
		this.ColumnWith = ColumnWith;
		this.Type = Type;
	}
}
export class VariableInfo {
	TypeCode: number;
	PrintFormat: VariableFormatInfo;
	WriteFormat: VariableFormatInfo;
	VariableName: string;
	VariableLabel: string;
	Index: number;
	IsNumeric: boolean;
	constructor(TypeCode: number, PrintFormat: VariableFormatInfo, WriteFormat: VariableFormatInfo, VariableName: string, VariableLabel: string, Index: number, IsNumeric: boolean) {
		if (TypeCode === undefined) throw new Error("Class 'VariableInfo': Required value 'TypeCode' is undefined");
		if (PrintFormat === undefined) throw new Error("Class 'VariableInfo': Required value 'PrintFormat' is undefined");
		if (WriteFormat === undefined) throw new Error("Class 'VariableInfo': Required value 'WriteFormat' is undefined");
		if (VariableName === undefined) throw new Error("Class 'VariableInfo': Required value 'VariableName' is undefined");
		if (VariableLabel === undefined) throw new Error("Class 'VariableInfo': Required value 'VariableLabel' is undefined");
		if (Index === undefined) throw new Error("Class 'VariableInfo': Required value 'Index' is undefined");
		if (IsNumeric === undefined) throw new Error("Class 'VariableInfo': Required value 'IsNumeric' is undefined");
		this.TypeCode = TypeCode;
		this.PrintFormat = PrintFormat;
		this.WriteFormat = WriteFormat;
		this.VariableName = VariableName;
		this.VariableLabel = VariableLabel;
		this.Index = Index;
		this.IsNumeric = IsNumeric;
	}
}
export class SpssPushMetaInfo {
	Variables: Array<VariableInfo>;
	constructor(Variables: Array<VariableInfo>) {
		if (Variables === undefined) throw new Error("Class 'SpssPushMetaInfo': Required value 'Variables' is undefined");
		this.Variables = Variables;
	}
}
