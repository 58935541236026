<div>
    <p-table [value]="changeDataTypeInfoViews" dataKey="ColumnName" editMode="row" [reorderableColumns]="false"
        sortMode="single" [globalFilterFields]="['Name','FilterValues','FilterTypes']">
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                <span class="p-input-icon-left">
                    <!-- <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                            placeholder="{{'LogHistory.SearchForLog' | translate}}" /> -->

                    <div class="p-inputgroup">
                        <p-dropdown id="matchModeName" [options]="numberConventionOptions" appendTo="body" [style]="{'width':'100%'}"
                            (onChange)="onUpdateSettings(false)" [(ngModel)]="selectedNumberConvention">
                        </p-dropdown>
                        <button type="button" pButton pRipple icon="pi pi-refresh"
                            (click)="onChangeGlobalNumberConvention(selectedNumberConvention)"
                            label="{{'Apply all' | translate }}" styleClass="p-button-warn"></button>
                    </div>
                </span>



                <span class="p-input-icon-right">

                    <button style="float: right" pButton label="{{'Add' | translate}}" icon="fa fa-plus"
                        (click)="onAddEntry()"></button>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <!-- <th style="width:3rem"></th> -->
                <th tooltipPosition="top" pTooltip="{{'Config.ChangeDatatype.Tooltip1' | translate}}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Name' | translate}}

                    </div>
                </th>

                <th tooltipPosition="top" pTooltip="{{'Config.ChangeDatatype.Tooltip2' | translate}}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'New DataType' | translate}}

                    </div>
                </th>
                <th style="width:6rem" tooltipPosition="top" pTooltip="{{'Config.Filter.Tooltip3' | translate}}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'' | translate}}

                    </div>
                </th>
                <!-- <th pReorderableColumn tooltipPosition="top" pTooltip="Column that should be filtered on">Column Name</th>
                    <th pReorderableColumn tooltipPosition="top" pTooltip="Filter method to apply to selected column">Filter Critera</th>
                    <th pReorderableColumn tooltipPosition="top" pTooltip="Match Expression">Filter Value</th> -->
                <th style="width:3rem"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-column let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="column" [pReorderableRow]="ri"
                [ngClass]="{'row-accessories': column.hasError === true}">
                <!-- <td>
                        <span class="pi pi-bars" pReorderableRowHandle (onChange)="onChangeSettings(this.filterList)"></span>
                    </td> -->
                <td>
                    <!-- <p-dropdown appendTo="body" *ngIf="column.hasError === false" [options]="columnsList"
                        [(ngModel)]="column.ColumnName" [autoDisplayFirst]="false" (onChange)="onUpdateSettings(false)"
                        autoWidth="false" [style]="{'width':'100%'}" [filter]="true" filterBy="column.ColumnName"></p-dropdown> -->

                    <!-- <p-dropdown *ngIf="column.hasError === true"  appendTo="body" [options]="columnsList"
                        [(ngModel)]="column.ColumnName" class="ng-invalid ng-dirty" [autoDisplayFirst]="false"
                        (onChange)="onUpdateSettings(false)" autoWidth="false" [style]="{'width':'100%'}" [filter]="true" filterBy="column.ColumnName"></p-dropdown> -->

                    <p-dropdown appendTo="body" [options]="columnsList" [optionValue]="ColumnName"
                        (onChange)="onUpdateSettings(false)" *ngIf="column.hasError === false"
                        [(ngModel)]="column.ColumnName" [autoDisplayFirst]="false" [filter]="true" filterBy="Name"
                        placeholder="Select a column" [style]="{'width':'100%'}">
                        <ng-template pTemplate="selectedItem">
                            <div class="country-item country-item-value" *ngIf="column.ColumnName">
                                {{column.ColumnName.Name}}
                            </div>
                        </ng-template>
                        <ng-template let-col pTemplate="item">
                            <div class="country-item">
                                {{col.Name}}
                                <span
                                    [class]="'customer-badge status-' + col.DataType.Name">{{col.DataType.Name}}</span>
                            </div>
                        </ng-template>
                    </p-dropdown>

                    <p-dropdown appendTo="body" [options]="columnsList" [optionValue]="ColumnName"
                        (onChange)="onUpdateSettings(false)" *ngIf="column.hasError === true"
                        class="ng-invalid ng-dirty" [(ngModel)]="column.ColumnName" [autoDisplayFirst]="false"
                        [filter]="true" filterBy="Name" placeholder="Select a column" [style]="{'width':'100%'}">
                        <ng-template pTemplate="selectedItem">
                            <div class="country-item country-item-value" *ngIf="column.ColumnName">
                                {{column.ColumnName.Name}}
                            </div>
                        </ng-template>
                        <ng-template let-col pTemplate="item">
                            <div class="country-item">
                                {{col.Name}}
                                <span
                                    [class]="'customer-badge status-' + col.DataType.Name">{{col.DataType.Name}}</span>
                            </div>
                        </ng-template>
                    </p-dropdown>





                </td>
                <td>
                    <!-- <p-dropdown appendTo="body" [options]="dataTypesList" [(ngModel)]="column.Datatype"
                        optionLabel="Name" dataKey="Name" (onChange)="onUpdateSettings(false)" autoWidth="false"
                        [style]="{'width':'100%'}"></p-dropdown> -->


                    <p-dropdown [options]="dataTypesList" appendTo="body" (onChange)="onUpdateSettings(false)"
                        [(ngModel)]="column.Datatype" optionLabel="Name" [optionValue]="Name" [filter]="true"
                        filterBy="Name" placeholder="Select a datatype" [style]="{'width':'100%'}">
                        <ng-template pTemplate="selectedItem">
                            <div class="country-item country-item-value" *ngIf="column.Datatype">
                                <span
                                    [class]="'customer-badge status-' + column.Datatype.Name">{{column.Datatype.Name}}</span>
                            </div>
                        </ng-template>
                        <ng-template let-type pTemplate="item">
                            <div class="country-item">
                                <span [class]="'customer-badge status-' + type.Name">{{type.Name}}</span>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </td>
                <td>
                    <p-dropdown appendTo="body" [options]="numberConventionOptions" [autoDisplayFirst]="false"
                        *ngIf="column.showNumberConvention" [(ngModel)]="column.NumberConvention"
                        (onChange)="onUpdateSettings(false)" autoWidth="false" [style]="{'width':'100%'}"></p-dropdown>
                </td>
                <td>
                    <div class="ui-table-buttongroup">
                        <button pButton pRipple type="button" class="p-button-rounded p-button-text ui-button-danger"
                            icon="pi pi-trash" pTooltip="{{'Delete' | translate}}" (click)="onDeleteEntry(ri)"
                            tooltipPosition="bottom"></button>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="4">{{'NoEntryFound' | translate}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>