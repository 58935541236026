<p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>

<p-dialog [baseZIndex]="1000" [(visible)]="displayDestinationDialog" modal="modal" showEffect="fade"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
    [style]="{'width': '100%', 'height': '100%', 'max-height': '100%'}" (onHide)="onCloseDialog()">
    <!-- <div class="spinner-container" *ngIf="savingInProgress">
        <app-loading-screen></app-loading-screen>
    </div> -->
    <p-header>
        <p-breadcrumb [style]="{'border': '0', 'font-size': '14px', 'color':'#c4dcea'}" [model]="currentSteps"
            [home]="homeStep"></p-breadcrumb>
        <div class="topSpinner" *ngIf="savingInProgress">
            <p-progressBar mode="indeterminate" [style]="{'height': '2px'}"></p-progressBar>
        </div>
    </p-header>

    <div *ngIf="activeindex === 0">
        <app-choose-destination (currentDestinationViewEmitter)="setcurrentDestinationView($event)">
        </app-choose-destination>
    </div>
    <div  *ngIf="activeindex === 1">
        <app-define-destination [destinationView]="currentDestinationView"
            (destinationChanged)="onDestinationChanged($event)">
        </app-define-destination>
    </div>
    <div *ngIf="activeindex === 2">
        <app-create-destination>
        </app-create-destination>
    </div>

    <app-system-message-log style="position:fixed; bottom:0"></app-system-message-log>

    <p-footer>
        <button pButton pRipple type="button" icon="pi pi-times" (click)="onBack($event)"
            *ngIf="activeindex !== 0" class="p-button-rounded p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'Back' | translate}}"></button>
        <button pButton pRipple type="button" icon="pi pi-check" (click)="onNext($event)"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'Next' | translate}}" [icon]="savingInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'"></button>
    </p-footer>
</p-dialog>
