import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ProtocolController } from "../models/api/controllers/tracking/ProtocolController";


/**
 * Protokoll Funktionen.
 */
@Injectable({
	providedIn: "root",
})
export class TrackingService {
    constructor(
		private http: HttpClient,
	) { }

    /**
     * Liefert die Protokolle für alle Objekte, auf die der angemeldete Nutzer irgendeine Form von Permissions hat.
     * @returns Protokoll, beinhaltet Data Sources, Workflows, Data Stores und Scheduler Action Plans
     */
    public getProtocolEntry(): Observable<ProtocolController.ProtocolEntry[]> {
        const options = {};
		return this.http.get<ProtocolController.ProtocolEntry[]>("/api/Tracking/Protocols", options);
	}
}