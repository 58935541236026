import { MenuItem } from "primeng/api/menuitem";
import { ScheduleActionPlan } from "src/app/models/schedule.model";
import { SchedulesService } from "src/app/services/schedules.service";
import { UserService } from "src/app/services/user.service";
import { ToolbarProvider } from "./toolbar-providers";
import { ScheduleActionEvent, ScheduleDialogActionType } from "src/app/models/dialog-actions.model";

export class ScheduleToolbar implements ToolbarProvider<ScheduleActionPlan> {
    constructor(private schedulesService: SchedulesService, private userService: UserService) { }
    subscribeToEmitters() {
      let selectedObs = this.schedulesService.selectedSchedulePlanEmitter;

      return selectedObs
    }

    initObjectMenu(): MenuItem[] {
      return [
        {
          label: "Create schedule",
          icon: "pi pi-fw pi-plus",
          styleClass: "p-button p-mr-2 p-mb-2",
          command: () => {
            //this.schedulesService.displayCreateSchedule.emit(true);
            this.schedulesService.scheduleDialogActionSendEmitter.emit(new ScheduleActionEvent(true, "Create schedule", ScheduleDialogActionType.createSchedule, "Create", undefined));

          }
        },
      ]
    }
    initActionMenu(): MenuItem[] {
      return [
        // {
        //   label: "Share",
        //   icon: "pi pi-fw pi-send",
        //   command: () => { this.userService.displayAddPermission.emit(true) }
        // },
      ]
    }
  }
