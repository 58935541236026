import { Component, OnInit } from "@angular/core";
import { DropDownEvents } from "src/app/helper/events";
import { FieldInfo, WorkflowNodeSettings } from "src/app/models/api/com/bion/etl/Workflow";
// import { GuiDropDown } from "src/app/models/helperClass.model";
import { PivotInfo, PivotSettings } from "src/app/models/nodeSettings.model";
// import { FieldInfo, WorkflowNodeSettings } from "src/app/models/workflow.model";
import { DesignerService } from "src/app/services/designer.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { NodeConfigComponentBase } from "../node-config-component-base";

export class PivotMethod {
	name: string;
	value: string;
	type: string;

	constructor(name: string, value: string, type: string) {
		this.name = name;
		this.value = value;
		this.type = type
	}
}

@Component({
	selector: "app-pivot-node",
	templateUrl: "./pivot-node.component.html",
	styleUrls: ["./pivot-node.component.scss"],
})
export class PivotNodeComponent
	extends NodeConfigComponentBase<PivotSettings>
	implements OnInit
{
	settingsToView(settings: WorkflowNodeSettings): void {
		let currentSettings = <PivotSettings>settings;

		const meta_infos = this.getCurrentWorkflowNode().Properties.MetaInfo.get(
			this.InputPort
		);

		// create SelectInfoViews from DsFields for table
		if (meta_infos != undefined && meta_infos.length > 0) {
			const input_field_infos = meta_infos[0].FieldsInfo;
			const columnList: FieldInfo[] = input_field_infos;
			this.columnList = columnList;
			this.columnListFiltered = columnList;
			this.pivotMethodsFiltered = this.pivotMethods;

            if(currentSettings.PivotInfos.length === 0) return;
            if(currentSettings.PivotInfos[0].ColumnNames.length === 0) return;

            let currentConfig = currentSettings.PivotInfos[0];

            if(currentConfig.ColumnNames[0]) {
                this.selectedColumns = meta_infos[0].FieldsInfo.find((fieldInfo) => { return fieldInfo.Name === currentConfig.ColumnNames[0]});
                this.columnListFiltered = columnList.filter((fieldInfo) => { return fieldInfo.Name !== currentSettings.PivotInfos[0].ColumnNames[0]})
            }
            if(currentConfig.ColumnHeaders) {
                this.selectedHeaderColumn = meta_infos[0].FieldsInfo.find((fieldInfo) => { return fieldInfo.Name === currentConfig.ColumnHeaders});
            }
            if(currentConfig.ValueColumn) {
                this.selectedValueColumn = meta_infos[0].FieldsInfo.find((fieldInfo) => { return fieldInfo.Name === currentConfig.ValueColumn});
            }
            if(currentConfig.Method) {
                this.selectedMethod = this.pivotMethods.find((method) => { return method.value === currentConfig.Method});
            }
		}
	}
	viewToSettings(): PivotSettings {
		const newSettings = <PivotSettings>{
			...this.getCurrentWorkflowNode().Properties.Configuration,
		};
		let newInfo = new PivotInfo();

		if (this.selectedColumns) {
			// let columnHeaderArray: string[] = this.selectedColumns.map((entry) => {
			// 	return entry.Name;
			// });
            let columnHeaderArray: string = this.selectedColumns.Name;

			newInfo.ColumnNames = [columnHeaderArray];
		} else {
			newInfo.ColumnNames = [];
		}

		if (this.selectedHeaderColumn) {
			newInfo.ColumnHeaders = this.selectedHeaderColumn.Name;
		} else {
			newInfo.ColumnHeaders = "";
		}

		if (this.selectedMethod) {
			newInfo.Method = this.selectedMethod.value;
		} else {
			newInfo.Method = "";
		}
		if (this.selectedValueColumn) {
			newInfo.ValueColumn = this.selectedValueColumn.Name;
		} else {
			newInfo.ValueColumn = "";
		}

		newSettings.PivotInfos = [newInfo];

		return newSettings;
	}
	onSettingsChanged(settings: PivotSettings): void {
		throw new Error("Method not implemented.");
	}

	constructor(
		protected workflowService: WorkflowsService,
		protected designerService: DesignerService
	) {
		super(workflowService, designerService);
		this.pivotMethods = [
			{ name: "Sum", value: "sum", type: "Double" },
			{ name: "Mean", value: "mean", type: "Double" },
			{ name: "Median", value: "median", type: "Double" },
			{ name: "Count", value: "count", type: "Double" },
			{ name: "Min", value: "min", type: "Double" },
			{ name: "Max", value: "max", type: "Double" },
			{ name: "Concatenate", value: "concatenate", type: "String" },
			{ name: "First", value: "min", type: "String" },
			{ name: "Last", value: "max", type: "String" },
			{ name: "Count", value: "count", type: "String" },
		];
	}

	columnList: FieldInfo[] = [];
	selectedColumns?: FieldInfo;
	columnListFiltered: FieldInfo[] = [];

	selectedHeaderColumn?: FieldInfo;
	selectedValueColumn?: FieldInfo;
	pivotMethods: PivotMethod[];
	pivotMethodsFiltered: PivotMethod[] = [];
	selectedMethod?: PivotMethod;

	InputPort: string = "Input";
	filterFieldInfos(event: DropDownEvents.OnChange<any, FieldInfo>) {
		let filtered: FieldInfo = event.value;

		let diff = this.columnList.filter((x) => {
			return x.Name !== filtered.Name;
		});

		this.columnListFiltered = diff;
		this.onUpdateSettings(false);
	}
	// filterMethod(event: DropDownEvents.OnChange<any, FieldInfo>) {

	// 	let selection: FieldInfo = event.value;
	// 	let filtered = this.pivotMethods.filter((info) => {
	// 		return info.type === selection.DataType.Name;
	// 	});

	// 	this.pivotMethodsFiltered = filtered;
	// }

	ngOnInit(): void {
		super.ngOnInit();
	}
    onFocusLost(event: FocusEvent) {
        const div_left: boolean = (event.relatedTarget === null);

        console.log("Send from Div: ", div_left);

        if (div_left) {
            //this.onCheckDirtyFlag();
            this.onUpdateSettings(true);
        }
    }
}
