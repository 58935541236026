import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { RichDataSourceCake } from "../models/api/controllers/staging/RichDataSourceCake";
import { RichSchedulerCake } from "../models/api/models/scheduler/RichSchedulerCake";
import { RichDataStoreCake } from "../models/api/models/staging/RichDataStoreCake";
import { RichtWorkflowCake } from "../models/api/models/workflow/RichWorkflowCake";
import { NodeConfigUtil } from "./api-util";


/**
 * Servie für Experimentelle APIs für Tests und Proof of Concepts.
 * 
 * ACHTUNG: Diese APIs sollten im Produktiv Betrieb nicht verwendet werden, da sie flüchtig sind!
 */
@Injectable({
  providedIn: "root",
})
export class ExperimentalApi {

  constructor(private http: HttpClient) {

  }

  public getDataSourceView(): Observable<RichDataSourceCake.DataSourceView<any>[]> {
    return this.http.get<RichDataSourceCake.DataSourceView<any>[]>("/api/experimental/DataSource/DataSourceViews");
  }

  public getDataStoreView(): Observable<RichDataStoreCake.DataStoreView[]> {
    const obs =  this.http.get<RichDataStoreCake.DataStoreView[]>("/api/experimental/DataStore/DataStoreViews");

    return obs.pipe(map(result => {
      // we need to convert the workflow node entires
      result.forEach(d => {
        d.Workflows.forEach(w => w.workflowData = NodeConfigUtil.workflow_object_to_map(w.workflowData))
      })
      
      return result;
    }))
  }

  public getWorkflowView(): Observable<RichtWorkflowCake.WorkflowView[]> {
    const obs = this.http.get<RichtWorkflowCake.WorkflowView[]>("/api/experimental/Workflow/WorkflowViews");

    return obs.pipe(map(result => {

      result.forEach(d => {
        d.Workflow.workflowData = NodeConfigUtil.workflow_object_to_map(d.Workflow.workflowData);
      })

      return result;
    }))
  }

  public getActionPlanView(): Observable<RichSchedulerCake.ActionPlanView[]> {
    const obs = this.http.get<RichSchedulerCake.ActionPlanView[]>("/api/experimental/Schedule/ActionPlans");

    return obs.pipe(map(result => {

      result.forEach(d => {
        d.Workflows.forEach(w => w.workflowData = NodeConfigUtil.workflow_object_to_map(w.workflowData))
      })

      return result;
    }))
  }

}