import { WorkflowNodeSettings } from "./api/com/bion/etl/Workflow";
import { DataStore, DataStoreField } from "./datastore.model";
// import { WorkflowNodeSettings } from "./workflow.model";

export class SelectSettings implements WorkflowNodeSettings {
	SelectInfos: SelectInfo[];
	SelectedFields: string[];
}

export class SelectInfo {
	constructor(
		sourceFieldName: string,
		targetFieldName: string,
		newType: string,
		newTypeLength: number
	) {
		this.SourceFieldName = sourceFieldName;
		this.TargetFieldName = targetFieldName;
		this.NewType = newType;
		this.NewTypeLength = newTypeLength;
	}

	SourceFieldName: string;
	TargetFieldName: string;
	NewType: string;
	NewTypeLength: number;
}

// export class ChangeDatatypeInfo {
// 	ColumnName: string;
// 	Datatype: string;
// }

// export class ChangeDatatypeSettings implements WorkflowNodeSettings {
// 	ChangeDatatypeInfos: ChangeDatatypeInfo[];
// }


export class FilterInfo {
	ColumnName: string;
	FilterTypes: string;
	FilterValues: string[];
}

export class FilterSettings implements WorkflowNodeSettings {
	FilterInfos: FilterInfo[];
	MatchMode: string;
}

export class FindAndReplaceInfo {
	ColumnName: string;
	FindValue: string;
	ReplaceValue: string;
	ReplaceColumn: boolean;
	CaseSensitive: boolean;
	MatchWholeWord: boolean;
}

export class FindAndReplaceSettings implements WorkflowNodeSettings {
	FindAndReplaceInfos: FindAndReplaceInfo[];
	//TypeCheck: string;
}

export class JoinInfo {
	LeftColumn: string;
	RightColumn: string;
}

export class JoinSettings implements WorkflowNodeSettings {
	JoinInfos: Array<JoinInfo>;
	JoinType: string;
}

export class SortInfo {
	ColumnName: string;
	SortType: boolean;
}

export class SortSettings implements WorkflowNodeSettings {
	SortInfos: SortInfo[];
}

export class TransposeSettings implements WorkflowNodeSettings {
	TransposeInfos: TransposeInfo[];
}

export class TransposeInfo {
	KeyColumns: string[] = [];
	DataColumns: string[] = [];
}

export class PivotSettings implements WorkflowNodeSettings {
	PivotInfos: PivotInfo[];
}

export class PivotInfo {
	ColumnNames: string[];
	ColumnHeaders: string;
	ValueColumn: string;
	Method: string;
}

export class UnionSettings implements WorkflowNodeSettings {
	Axis: number;
	UnionType: string;
	//UnionInfos: FieldInfo[];
	UnionInfos: string[];
	EdgeOrder: string[];
}

// export class UnionInfo {
//     ColumnName: string;
//     //dType: string;

// }

export class TableSplitterSettings implements WorkflowNodeSettings {
	TableSplitterInfos: TableSplitterInfo[];
}
export class TableSplitterInfo {
	LeftTable: string[];
	RightTable: string[];
}

export class ConstantValueSettings implements WorkflowNodeSettings {
	ConstantValueInfos: ConstantValueInfo[];
}
export class ConstantValueInfo {
	NewColumnName: string;
	NewColumnType: string;
	ConstantValue: any;
}

export class FXConvertSettings implements WorkflowNodeSettings {
	FxConversionInfos: FxConvertInfo[];
	FxConversionDate: string;
}

export class FxConvertInfo {
	ColumnName: string;
	BaseValue: string;
	TargetValue: string[];
}
export class UnitConvertSettings implements WorkflowNodeSettings {
	UnitConversionInfos: UnitConvertInfo[];
}
export class UnitConvertInfo {
	ColumnName: string;
	BaseValue: string;
	TargetValue: string[];
	//timeStamp: number;
}
export class CalculateSettings implements WorkflowNodeSettings {
	CalculateInfos: CalculateInfo[];
}
// export class CalculateInfo {
// 	NewColumnName: string;
// 	NewColumnType: string;
// 	FormulaString: string;
// }
export class CalculateInfo {
	FirstColName: string;
	Operator: number;
	SecondColName: string;
    NewColumnName: string;
    NewDataType: number;
}
export class MissingValuesSettings implements WorkflowNodeSettings {
	MissingValuesInfos: MissingValuesInfo[];
}

export class MissingValuesInfo {
	ColumnName: string;
	ColumnType: string;
	Operator: string;
	OperatorValue: any;
}

export class ColumnSplitterSettings implements WorkflowNodeSettings {
	ColumnSplitterInfos: ColumnSplitterInfo[];
}

export class ColumnSplitterInfo {
	ColumnName: string;
	SplitCondition: string;
	SplitMethod: string;
	DropColumn: boolean;
	MaxColumns: number;
}

export class PresentValueSettings implements WorkflowNodeSettings {
	PresentValueInfos: PresentValueInfo[];
}
export class PresentValueInfo {
	TargetColumn: string;
	DateColumn: string;
	RateColumn?: string;
	Rate: number;
	PaymentFrequency: string; // ggf number?
	PaymentMode: string;
	StartDate: Date; //ggf number?
	EndDate: Date; //ggf number?
}
export class ColumnAggregatorSettings implements WorkflowNodeSettings {
	ColumnAggregatorInfos: ColumnAggregatorInfo[];
	AggregationMethod: string;
	AggregationDelimiter: string;
	NewColumnName: string;
	DropOldColumn: boolean;
}
export class ColumnAggregatorInfo {
	LeftTable: string[];
	RightTable: string[];
}

export class SummarizeSettings implements WorkflowNodeSettings {
	SummarizeInfos: SummarizeInfo[];
}
export class SummarizeInfo {
	ColumnName: string;
	Method: string;
	NewFieldName: string;
}

export class UniqueValuesSettings implements WorkflowNodeSettings {
	UniqueValuesInfos: UniqueValuesInfo[];
}
export class UniqueValuesInfo {
	ColumnName: string;
}

export class DateParserSettings implements WorkflowNodeSettings {
	DateParserInfos: DateParserInfo[];
}

export class DateParserInfo {
	ConvertMode: number;
	ColumnName: string;
	NewColumnName?: string;
	TargetDateFormat: number;
	DropColumn: boolean;
}

export class RegexSettings implements WorkflowNodeSettings {
	RegExInfos: RegexInfo[];
}
export class RegexInfo {
	ColumnName: string;
	FormatExpression: string;
	IsCaseSensitive: boolean;
	OutputMethod: string;
	DropOld: boolean;
    NewColumnName?: string;
	//OutputColumnName?: string;
	//NewColumnType?: string;
	ReplacementText?: string;
	//SplitMethod?: string;

}

export class AnonymizeSettings implements WorkflowNodeSettings {
    AnonymizeInfos: AnonymizeInfo[];

}
export class AnonymizeInfo {
    ColumnName: string;
    AnonymizeMethod: string;
    CustomValue: string;
    DropInputColumn: boolean;
}

// export class SamplingSettings implements WorkflowNodeSettings {
//     SamplingInfos: SamplingInfo[];

// }
export class SamplingInfo {
    ColumnName: string;
    AnonymizeMethod: string;
    CustomValue: string;
    DropInputColumn: boolean;
}

export class EncryptionSettings implements WorkflowNodeSettings {
    EncryptionInfos: EncryptionInfo[];

}
export class EncryptionInfo {
    ColumnName: string;
    Mode: string;
    DropInputCol: boolean;
    Key: string;
    GenerateKey: boolean;
    EncryptStatusCol?: string;
}


export class DataStoreOutPlugInSettings implements WorkflowNodeSettings {
	DataStores: DataStore[];
	WriteMode: string;
	SelectedDataStore?: DataStore;
	DataStoreFields?: DataStoreField[];
}


// Sampling


export class RandomSamplingSettings {
	ColumnName: string;
	UseAbsSample: boolean;
	SampleSize: number;
	SamplePercentage: number;
	SampleWithReplacement: boolean;
	constructor(ColumnName: string,	UseAbsSample: boolean, SampleSize: number, SamplePercentage: number, SampleWithReplacement: boolean) {
		if(ColumnName === undefined) throw new Error( "Class 'RandomSamplingSettings': Required value 'ColumnName' is undefined" );
		if(UseAbsSample === undefined) throw new Error( "Class 'SampleAtConstantRateSettings': Required value 'UseAbsSample' is undefined" );
		if(SampleSize === undefined) throw new Error( "Class 'RandomSamplingSettings': Required value 'SampleSize' is undefined" );
		if(SamplePercentage === undefined) throw new Error( "Class 'RandomSamplingSettings': Required value 'SamplePercentage' is undefined" );
		if(SampleWithReplacement === undefined) throw new Error( "Class 'RandomSamplingSettings': Required value 'SampleWithReplacement' is undefined" );
		this.ColumnName = ColumnName;
		this.UseAbsSample = UseAbsSample;
		this.SampleSize = SampleSize;
		this.SamplePercentage = SamplePercentage;
		this.SampleWithReplacement = SampleWithReplacement;
	}
}

export class WeightedValueInfo {
	ColumnName: string;
	Weight: number;
	constructor(ColumnName: string, Weight: number) {
		if(ColumnName === undefined) throw new Error( "Class 'WeightedValueInfo': Required value 'ColumnName' is undefined" );
		if(Weight === undefined) throw new Error( "Class 'WeightedValueInfo': Required value 'Weight' is undefined" );
		this.ColumnName = ColumnName;
		this.Weight = Weight;
	}
}

export class WeightedSamplingSettings {
	ColumnName: string;
	UseAbsSample: boolean;
	SampleSize: number;
	SamplePercentage: number;
	SampleWithReplacement: boolean;
	WeightedValues: Array<WeightedValueInfo>;
	constructor(ColumnName: string, UseAbsSample: boolean, SampleSize: number, SamplePercentage: number, SampleWithReplacement: boolean, WeightedValues: Array<WeightedValueInfo>) {
		if(ColumnName === undefined) throw new Error( "Class 'WeightedSamplingSettings': Required value 'ColumnName' is undefined" );
		if(UseAbsSample === undefined) throw new Error( "Class 'SampleAtConstantRateSettings': Required value 'UseAbsSample' is undefined" );
		if(SampleSize === undefined) throw new Error( "Class 'WeightedSamplingSettings': Required value 'SampleSize' is undefined" );
		if(SamplePercentage === undefined) throw new Error( "Class 'WeightedSamplingSettings': Required value 'SamplePercentage' is undefined" );
		if(SampleWithReplacement === undefined) throw new Error( "Class 'WeightedSamplingSettings': Required value 'SampleWithReplacement' is undefined" );
		if(WeightedValues === undefined) throw new Error( "Class 'WeightedSamplingSettings': Required value 'WeightedValues' is undefined" );
		this.ColumnName = ColumnName;
		this.UseAbsSample = UseAbsSample;
		this.SampleSize = SampleSize;
		this.SamplePercentage = SamplePercentage;
		this.SampleWithReplacement = SampleWithReplacement;
		this.WeightedValues = WeightedValues;
	}
}

export class RateParamsInfo {
	StartValue?: number;
	EndValue?: number;
	StepValue: number;
	constructor(StepValue: number,StartValue?: number, EndValue?: number) {
		// if(StartValue === undefined) throw new Error( "Class 'RateParamsInfo': Required value 'StartValue' is undefined" );
		// if(EndValue === undefined) throw new Error( "Class 'RateParamsInfo': Required value 'EndValue' is undefined" );
		if(StepValue === undefined) throw new Error( "Class 'RateParamsInfo': Required value 'StepValue' is undefined" );
		this.StartValue = StartValue;
		this.EndValue = EndValue;
		this.StepValue = StepValue;
	}
}

export class SampleAtConstantRateSettings {
	ColumnName: string;
	ConstantRateParams: RateParamsInfo;
	constructor(ColumnName: string,	ConstantRateParams: RateParamsInfo) {
		if(ColumnName === undefined) throw new Error( "Class 'SampleAtConstantRateSettings': Required value 'ColumnName' is undefined" );
		if(ConstantRateParams === undefined) throw new Error( "Class 'SampleAtConstantRateSettings': Required value 'ConstantRateParams' is undefined" );
		this.ColumnName = ColumnName;
		this.ConstantRateParams = ConstantRateParams;
	}
}

export class SamplingFilterInfo {
	ColumnName: string;
	FilterType: string;
	FilterValue: string;
	constructor(ColumnName: string, FilterType: string, FilterValue: string) {
		if(ColumnName === undefined) throw new Error( "Class 'SamplingFilterInfo': Required value 'ColumnName' is undefined" );
		if(FilterType === undefined) throw new Error( "Class 'SamplingFilterInfo': Required value 'FilterTypes' is undefined" );
		if(FilterValue === undefined) throw new Error( "Class 'SamplingFilterInfo': Required value 'FilterValues' is undefined" );
		this.ColumnName = ColumnName;
		this.FilterType = FilterType;
		this.FilterValue = FilterValue;
	}
}

export class SampleByConditionSettings {
	// ColumnName: string;
	UseAbsSample: boolean;
	MatchMode: string;
	FilterConditions: Array<SamplingFilterInfo>;
	SampleSize: number;
	SamplePercentage: number;
	SampleWithReplacement: boolean;
	constructor(UseAbsSample: boolean, MatchMode: string, FilterConditions: Array<SamplingFilterInfo>, SampleSize: number, SamplePercentage: number, SampleWithReplacement: boolean) {

		if(UseAbsSample === undefined) throw new Error( "Class 'SampleAtConstantRateSettings': Required value 'UseAbsSample' is undefined" );
		if(MatchMode === undefined) throw new Error( "Class 'SampleByConditionSettings': Required value 'MatchMode' is undefined" );
		if(FilterConditions === undefined) throw new Error( "Class 'SampleByConditionSettings': Required value 'FilterConditions' is undefined" );
		if(SampleSize === undefined) throw new Error( "Class 'SampleByConditionSettings': Required value 'SampleSize' is undefined" );
		if(SamplePercentage === undefined) throw new Error( "Class 'SampleByConditionSettings': Required value 'SamplePercentage' is undefined" );
		if(SampleWithReplacement === undefined) throw new Error( "Class 'SampleByConditionSettings': Required value 'SampleWithReplacement' is undefined" );

		this.UseAbsSample = UseAbsSample;
		this.MatchMode = MatchMode;
		this.FilterConditions = FilterConditions;
		this.SampleSize = SampleSize;
		this.SamplePercentage = SamplePercentage;
		this.SampleWithReplacement = SampleWithReplacement;
	}
}

export class SampleRandomColumnsSettings {
	ColumnNames: Array<string>;
	UseAbsSample: boolean;
	SampleSize?: number;
	SamplePercentage?: number;
	constructor(ColumnNames: Array<string>,	UseAbsSample: boolean, SampleSize?: number, SamplePercentage?: number) {
		if(ColumnNames === undefined) throw new Error( "Class 'SampleRandomColumnsSettings': Required value 'ColumnNames' is undefined" );
		if(UseAbsSample === undefined) throw new Error( "Class 'SampleRandomColumnsSettings': Required value 'UseAbsSample' is undefined" );
		this.ColumnNames = ColumnNames;
		this.UseAbsSample = UseAbsSample;
		this.SampleSize = SampleSize;
		this.SamplePercentage = SamplePercentage;
	}
}

export class SamplingSettings {
	Method: number;
	RandomSamplingSettings: RandomSamplingSettings;
	WeightedSamplingSettings: WeightedSamplingSettings;
	ConstantRateSettings: SampleAtConstantRateSettings;
    SampleByConditionSettings: SampleByConditionSettings;
	SampleRandomColumnsSettings: SampleRandomColumnsSettings;
	constructor(Method: number, RandomSamplingSettings: RandomSamplingSettings, WeightedSamplingSettings: WeightedSamplingSettings, ConstantRateSettings: SampleAtConstantRateSettings, SampleByConditionSettings: SampleByConditionSettings, SampleRandomColumnsSettings: SampleRandomColumnsSettings) {
		if(Method === undefined) throw new Error( "Class 'SamplingSettings': Required value 'Method' is undefined" );
		if(RandomSamplingSettings === undefined) throw new Error( "Class 'SamplingSettings': Required value 'RandomSamplingSettings' is undefined" );
		if(WeightedSamplingSettings === undefined) throw new Error( "Class 'SamplingSettings': Required value 'WeightedSamplingSettings' is undefined" );
		if(ConstantRateSettings === undefined) throw new Error( "Class 'SamplingSettings': Required value 'ConstantRateSettings' is undefined" );
		if(SampleRandomColumnsSettings === undefined) throw new Error( "Class 'SamplingSettings': Required value 'SampleRandomColumnsSettings' is undefined" );
		this.Method = Method;
		this.RandomSamplingSettings = RandomSamplingSettings;
		this.WeightedSamplingSettings = WeightedSamplingSettings;
		this.ConstantRateSettings = ConstantRateSettings;
        this.SampleByConditionSettings = SampleByConditionSettings;
		this.SampleRandomColumnsSettings = SampleRandomColumnsSettings;
	}
}

export class CompareInfo {
    KeyColumns:string[];
    ExcludeCols?:string[];
    IncludeCols:string[]

    constructor(    KeyColumns:string[],
        ExcludeCols:string[],
        IncludeCols:string[]) {
            if(KeyColumns === undefined) throw new Error( "Class 'CompareSettings': Required value 'KeyColumns' is undefined" );
            if(ExcludeCols === undefined) throw new Error( "Class 'CompareSettings': Required value 'ExcludeCols' is undefined" );
            if(IncludeCols === undefined) throw new Error( "Class 'CompareSettings': Required value 'IncludeCols' is undefined" );
            this.KeyColumns = KeyColumns;
            this.ExcludeCols = ExcludeCols;
            this.IncludeCols = IncludeCols
    }
}
export class CompareSettings{
    CompareInfos:CompareInfo[]

    constructor(    CompareInfos:CompareInfo[]) {
            if(CompareInfos === undefined) throw new Error( "Class 'CompareSettings': Required value 'CompareInfos' is undefined" );
            this.CompareInfos = CompareInfos;
    }
}



export class AutoSelectSettings implements WorkflowNodeSettings {
	AutoSelectInfos: AutoSelectInfo[];
}

export class AutoSelectInfo {
	RemoveCols: boolean;
	RemoveRows: boolean;
	constructor(    RemoveRows:boolean,
					RemoveCols:boolean) {
       	     if(RemoveRows === undefined) throw new Error( "Class 'AutoSelectSettings': Required value 'RemoveCols' is undefined" );
            if(RemoveCols === undefined) throw new Error( "Class 'AutoSelectSettings': Required value 'RemoveCols' is undefined" );
            this.RemoveRows = RemoveRows;
            this.RemoveCols = RemoveCols;
    }
}

export class FormulaSettings implements WorkflowNodeSettings {
	FormulaInfos: FormulaInfo[];
}

export class FormulaInfo {
	NewColumnName: string;
	FormulaString: string;
	NewColumnType: string;
	constructor(    NewColumnName:string,
					FormulaString:string,
					NewColumnType: string) {
       	     if(NewColumnName === undefined) throw new Error( "Class 'FormulaSettings': Required value 'NewColumnName' is undefined" );
            if(FormulaString === undefined) throw new Error( "Class 'FormulaSettings': Required value 'Formula' is undefined" );
			if(NewColumnType === undefined) throw new Error( "Class 'FormulaSettings': Required value 'NewColumnType' is undefined" );
            this.NewColumnName = NewColumnName;
            this.FormulaString = FormulaString;
			this.NewColumnType = NewColumnType;
    }
}