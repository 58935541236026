import { HttpErrorResponse } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";

import {
	GuiErrorCode,
	GuiErrorException,
	GuiErrorInfo,
} from "../models/gui.error.model";

@Injectable({
	providedIn: "root",
})
export class SystemMessageLogService {
	errorEventEmitter = new EventEmitter<GuiErrorInfo>();
	guiErrorEventEmitter = new EventEmitter<GuiErrorException>();
	httpErrorEventEmitter = new EventEmitter<HttpErrorResponse>();
	generalErrorEventEmitter = new EventEmitter<Error>();
	generalErrorsEventEmitter = new EventEmitter<Error[]>();
	clearMessageEventEmitter = new EventEmitter<number>();
	constructor() { }

	/**
	 * Handle any kind of error.
	 * 
	 * The error is dispatched in the system-message-log component.
	 * @param error Error which must be a subclass of the interface 'Error'.
	 */
	handleError<E extends Error>(error: E) {
		this.emitError(error);
	}

	handleErrors<E extends Error>(errors: E[]) {
		this.emitErrors(errors);
	}

	// sendHttpError(error: HttpErrorResponse) {
	// 	this.httpErrorEventEmitter.emit(error);
	// }
	// sendErrorCode(code: GuiErrorCode) {
	// 	// let errorFound = GuiErrorList.find((err) => {
	// 	// 	return err.code === code;
	// 	// });

	// 	// this.guiErrorEventEmitter.emit(errorFound);

	// 	const gui_error = new GuiErrorException(code);
	// 	this.guiErrorEventEmitter.emit(gui_error);

	// }
	emitError(error: Error) {
		this.generalErrorEventEmitter.emit(error);
	}
	emitErrors(errors: Error[]) {
		this.generalErrorsEventEmitter.emit(errors);
	}
	clearHttpError() {
		this.clearMessageEventEmitter.emit(0);
	}
}
