<p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>

<p-dialog header="{{'Delete frequency' | translate}}" [(visible)]="displayDeleteFrequency" modal="modal"
    showEffect="fade" [style]="{width: '600px'}">
    <div *ngIf="selectedFrequency" style="line-height: 1.5">
        {{'DeleteFrequency?' | translate}} {{selectedFrequency.ID}} ?
    </div>
    <app-system-message-log></app-system-message-log>

    <p-footer>
        <!-- <button type="button" pButton icon="pi pi-check" (click)="deleteFrequency(selectedFrequency)" label="{{'Yes' | translate}}"
        class="p-button-text"></button>
        <button type="button" pButton icon="pi pi-times" (click)="displayDeleteFrequency=false" label="{{'No' | translate}}"
        class="p-button-text"></button> -->

        <button pButton pRipple type="button" icon="pi pi-times" (click)="displayDeleteFrequency=false"
            class="p-button-rounded p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'No' | translate}}"></button>
        <button pButton pRipple type="button" icon="pi pi-check" (click)="onDeleteFrequency(selectedFrequency)"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'Yes' | translate}}" [icon]="savingInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'"></button>
    </p-footer>
</p-dialog>
