<div>
    <p-table #dt (onRowReorder)="onUpdateSettings(false)" [value]="constantValueList" dataKey="ColumnName"
        editMode="row" [reorderableColumns]="false">
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="{{'LogHistory.SearchForLog' | translate}}" />
                </span>
                <span class="p-input-icon-right">
                    <button style="float: right" pButton label="{{'Add' | translate}}" icon="fa fa-plus"
                        (click)="onAddColumn()"></button>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width:3rem"></th>
                <th tooltipPosition="top" pTooltip="{{'Config.ConstantValue.Tooltip1' | translate}}">{{'New Column'
                    |translate}}</th>
                <th tooltipPosition="top" pTooltip="{{'Config.ConstantValue.Tooltip2' | translate}}">{{'Column Type'
                    |translate}}</th>
                <th tooltipPosition="top" pTooltip="{{'Config.ConstantValue.Tooltip3' | translate}}">
                    {{'Constant Value' |translate}}</th>
                <th style="width:3rem"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-column let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="column" [pReorderableRow]="ri">
                <td>
                    <span class="pi pi-bars" pReorderableRowHandle></span>
                </td>
                <td>
                    <input pInputText [(ngModel)]="column.NewColumnName" (blur)="onUpdateSettings(false)"
                        [style]="{'width':'100%'}" [ngModelOptions]="{standalone: true}">
                </td>
                <td>
                    <p-dropdown appendTo="body"  [options]="dataTypeOptions" [(ngModel)]="column.NewColumnType" optionLabel="name"
                        dataKey="value" (onChange)="onUpdateSettings(false)" autoWidth="false"
                        [style]="{'width':'100%'}"></p-dropdown>
                </td>
                <td>
                    <input pInputText [(ngModel)]="column.ConstantValue" (blur)="onUpdateSettings(false)"
                        [style]="{'width':'100%'}" [ngModelOptions]="{standalone: true}">
                </td>
                <td>
                    <div class="ui-table-buttongroup">
                        <button pButton pRipple type="button" class="p-button-rounded p-button-text ui-button-danger"
                            icon="pi pi-trash" pTooltip="Delete entry" (click)="onDeleteEntry(ri)"
                            tooltipPosition="bottom"></button>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="5">{{'NoEntryFound' | translate}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>