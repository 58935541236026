export namespace ApiModel {
    export namespace Sources {
        export class GetResp {
            sourceDefinitionId: string;
            sourceId: string;
            workspaceId: string;
            connectionConfiguration: string;
            name: string;
            sourceName?: string;
            icon?: string;
            constructor(sourceDefinitionId: string, sourceId: string, workspaceId: string, connectionConfiguration: string, name: string, sourceName?: string, icon?: string) {
                if (sourceDefinitionId === undefined) throw new Error("Class 'GetResp': Required value 'sourceDefinitionId' is undefined");
                if (sourceId === undefined) throw new Error("Class 'GetResp': Required value 'sourceId' is undefined");
                if (workspaceId === undefined) throw new Error("Class 'GetResp': Required value 'workspaceId' is undefined");
                if (connectionConfiguration === undefined) throw new Error("Class 'GetResp': Required value 'connectionConfiguration' is undefined");
                if (name === undefined) throw new Error("Class 'GetResp': Required value 'name' is undefined");
                this.sourceDefinitionId = sourceDefinitionId;
                this.sourceId = sourceId;
                this.workspaceId = workspaceId;
                this.connectionConfiguration = connectionConfiguration;
                this.name = name;
                this.sourceName = sourceName;
                this.icon = icon;
            }
        }
    }
    export namespace SourceDefinitionSpecs {
        export class GetReq {
            sourceDefinitionId: string;
            workspaceId: string;
            constructor(sourceDefinitionId: string, workspaceId: string) {
                if (sourceDefinitionId === undefined) throw new Error("Class 'GetReq': Required value 'sourceDefinitionId' is undefined");
                if (workspaceId === undefined) throw new Error("Class 'GetReq': Required value 'workspaceId' is undefined");
                this.sourceDefinitionId = sourceDefinitionId;
                this.workspaceId = workspaceId;
            }
        }
    }
}