<div class="p-grid" style="width:100%; margin-top:10px">
    <div class="p-col-12" *ngIf="!isChangeSettingsOnlyFlag">
        <p-fileUpload auto="true" multiple="single" showCancelButton="false" customUpload="true" maxFileSize="200000000"
            (onRemove)="onRemoveFile($event)" chooseLabel="{{'Browse' | translate}}" uploadLabel="Preview"
            (uploadHandler)="uploadFilesAsync($event)" accept=".json">
            <ng-template pTemplate="content">
                <ul>
                    <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
                </ul>
            </ng-template>
        </p-fileUpload>
    </div>
    <div class="p-col-9 ">
        <div class="p-fluid">
            <span class="p-float-label">
                <input [(ngModel)]="currentConnectorView.connectorSettings.Query" class="full-width" id="querytext"
                    type="text" pInputText>
                <label for="querytext">{{'Query' | translate}}</label>
            </span>
        </div>
    </div>
    <div class="p-col-3">
        <div class="p-fluid">
            <p-checkbox [(ngModel)]="currentConnectorView.connectorSettings.UseQuery" value="Use Query" binary="true"
                inputId="binary" id="UseQueryText" (onChange)="setJsonView()"></p-checkbox>{{'Use Query' | translate}}
        </div>
    </div>
    <div class="p-col-12 ">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown appendTo="body" id="readModeText" [autoDisplayFirst]="false"
                    [options]="currentConnectorView.metaInfo.ReadModes" [(ngModel)]="selectedReadMode"
                    (onChange)="setJsonView()"></p-dropdown>
                <label for="readModeText">{{'Read Mode' | translate}}</label>
            </span>
        </div>
    </div>
    <div class="p-col-9 ">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown appendTo="body" id="NestedModeText" [autoDisplayFirst]="false"
                    [options]="currentConnectorView.metaInfo.NestedObjectsModes" [(ngModel)]="selectedNestedObjectsMode"
                    (onChange)="setJsonView()"></p-dropdown>
                <label for="NestedModeText">{{'Nested Object Mode' | translate}}</label>
            </span>
        </div>
    </div>
    <div class="p-col-3">
        <div class="p-fluid">
            <p-checkbox [(ngModel)]="currentConnectorView.metaAccess.JsonPreview" value="Preview" binary="true"
                inputId="binary" id="JsonPreview" (onChange)="setJsonView()"></p-checkbox>{{'Preview' | translate}}
        </div>
    </div>
    <div class="p-col-12">
        <p-divider layout="horizontal" align="center">
            <span class="p-tag">{{'Select hierarchy' | translate}}</span>
        </p-divider>
    </div>
    <div class="p-col-12">
        <p-tree [value]="jsonHierarchy" id="hierarchy" selectionMode="single" [(selection)]="selectedJsonHierarchy"
            (onNodeSelect)="onNodeSelect($event)"></p-tree>
    </div>

    <!-- <div class="p-col-12">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-12">
                <label for="querytext">Enter your query here</label>
                <input [(ngModel)]="currentConnectorView.connectorSettings.Query" class="full-width" id="querytext" type="text" pInputText>
                <p-checkbox [(ngModel)]="currentConnectorView.connectorSettings.UseQuery" value="Use Query" binary="true" inputId="binary" id="UseQueryText" (onChange)="setJsonView()"></p-checkbox>Use Query
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="readModeText">Read Mode</label>
                <p-dropdown appendTo="body" id="readModeText" [options]="currentConnectorView.metaInfo.ReadModes" [(ngModel)]="selectedReadMode"  (onChange)="setJsonView()"></p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="NestedModeText">Nested Object Mode</label>
                <p-dropdown appendTo="body" id="NestedModeText" [options]="currentConnectorView.metaInfo.NestedObjectsModes" [(ngModel)]="selectedNestedObjectsMode" (onChange)="setJsonView()"></p-dropdown>
            </div>
            <div class="p-col-12 p-md-6">
                <div class="p-fluid">
                    <span class="p-float-label">
                        <button pButton type="button" id="treeTest" label="TreeTest" pInputText (click)="onTreeTest($event)"></button>
                    </span>
                </div>
            </div>
            <div class="p-field p-col-12">
                <p-checkbox [(ngModel)]="currentConnectorView.metaAccess.JsonPreview" value="Preview" binary="true" inputId="binary" id="JsonPreview" (onChange)="setJsonView()"></p-checkbox>Preview
                <p-tree [value]="jsonHierarchy" id="hierarchy" selectionMode="single" [(selection)]="selectedJsonHierarchy" (onNodeSelect)="onNodeSelect($event)"></p-tree>
            </div>
        </div>
    </div> -->
</div>
