export namespace DestinationWriteMode {
	export class EntityModeInfo {
		Mode: string;
		RecordModes: Array<string>;
		constructor(Mode: string,RecordModes: Array<string>) {
			if(Mode === undefined) throw new Error( "Class 'EntityModeInfo': Required value 'Mode' is undefined" );
			if(RecordModes === undefined) throw new Error( "Class 'EntityModeInfo': Required value 'RecordModes' is undefined" );
			this.Mode = Mode;
			this.RecordModes = RecordModes;
		}
	}
	export class TargetModeInfo {
		Mode: string;
		EntityModes: Array<EntityModeInfo>;
		constructor(Mode: string,EntityModes: Array<EntityModeInfo>) {
			if(Mode === undefined) throw new Error( "Class 'TargetModeInfo': Required value 'Mode' is undefined" );
			if(EntityModes === undefined) throw new Error( "Class 'TargetModeInfo': Required value 'EntityModes' is undefined" );
			this.Mode = Mode;
			this.EntityModes = EntityModes;
		}
	}
	export class WriteModeInfo {
		TargetModeInfos: Array<TargetModeInfo>;
		constructor(TargetModeInfos: Array<TargetModeInfo>) {
			if(TargetModeInfos === undefined) throw new Error( "Class 'WriteModeInfo': Required value 'TargetModeInfos' is undefined" );
			this.TargetModeInfos = TargetModeInfos;
		}
	}
}