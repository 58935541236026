<!-- <div class="animated fadeInDown" style="position:absolute; top:60px;left:0; width: calc(100% - 63px);margin-left: 63px;"
    id="DesignerNavbar">
    <div style="height: 2px">
        <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '2px'}"></p-progressBar>
    </div>

    <p-menubar appendTo=body>
        <ng-template pTemplate="start">
            <span class="p-input-icon-left">
                <div>
                    <button *ngFor="let action of navMenuItems" pButton pRipple type="button" [icon]="action.icon"
                        pTooltip="{{action.text | translate}}" label="{{action.label | translate}}"
                        tooltipPosition="top" class="p-button  p-mr-2 p-mb-2" (click)="action.command()"
                        [disabled]="loading"></button>
                </div>
            </span>
        </ng-template>
    </p-menubar>

</div> -->


<!-- <div class="p-grid" id="DesignerNavbar">

    <div class="p-col-12" style="display: flex; align-items: center;">
        <div style="height: 2px; width:100%">
            <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '2px'}"></p-progressBar>
        </div>
        <div style="float:left">

            <button #actualTarget pButton class="p-button-round p-button-text" icon="pi pi-filter"
                (click)="op.toggle($event, actualTarget)" label="Filter"></button>

        </div>
        <span style="display: flex; align-items: center;">
            <span style="margin-right:10px">
                <button pTooltip="{{'Related workflow' | translate}}" class="p-button-text" pButton
                    icon="pi pi-share-alt" [label]="3"></button>
            </span>
            <span style="margin-right:10px">
                <button pTooltip="{{'Related schedule' | translate}}" class="p-button-text" pButton
                    icon="pi pi-calendar" [label]="5"></button>
            </span>
            <i class="pi pi-check-circle" style="font-size: 24px; color:rgb(109, 195, 112); float: right"></i>
            <i class="pi pi-times-circle" style="font-size: 24px; color:rgb(195, 109, 109); float: right"></i>
        </span>


        <div style="float:right">
            <button *ngFor="let action of navMenuItems" pButton pRipple type="button" [icon]="action.icon"
                pTooltip="{{action.text | translate}}" label="{{action.label | translate}}" tooltipPosition="top"
                class="p-button  p-mr-2 p-mb-2" (click)="action.command()" [disabled]="loading"></button>
        </div>
    </div>

</div> -->



<div>
    <span style="float:left">
        <!-- <button pButton pRipple label="{{'ViewData' | translate}}"></button> -->
        <button *ngFor="let action of actionNavItems" pButton pRipple type="button" [icon]="action.icon"
            [disabled]="action.disabled" pTooltip="{{action.text | translate}}" label="{{action.label | translate}}"
            tooltipPosition="top" class="p-button p-mr-2 p-mb-2 p-button-text" (click)="action.command()"
            [disabled]="loading"></button>
        <button *ngFor="let action of navMenuItems" pButton pRipple type="button" [icon]="action.icon"
            pTooltip="{{action.text | translate}}" label="{{action.label | translate}}" tooltipPosition="top" [hidden]="action.disabled"
             [class]="action.styleClass" pTooltip="{{action.tooltip | translate}}" (click)="action.command()" [disabled]="loading"></button>
    </span>
</div>
