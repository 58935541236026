import {
	ChangeDetectorRef,
	Component,
	Input,
	OnInit,
	ViewChild,
} from "@angular/core";
import { Observable, of } from "rxjs";
import { concatMap } from "rxjs/operators";
import { ExcelPullMetaAccess } from "src/app/models/api/com/bion/connect/excel/ExcelPullMetaAccess";
import { ExcelPullMetaInfo } from "src/app/models/api/com/bion/connect/excel/ExcelPullMetaInfo";
import { ExcelUriAccess } from "src/app/models/api/com/bion/connect/excel/ExcelUriAccess";
import { ConnectorView } from "src/app/models/connectorView.model";
import { Table } from "src/app/models/designer.models";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { ExcelSheet } from "../excel-connector/excel-connector.component";

export interface IFieldListColumn {
	field: string;
	header: string;
	width: string;
}

@Component({
	selector: "app-excel-url-connector",
	templateUrl: "./excel-url-connector.component.html",
	styleUrls: ["./excel-url-connector.component.scss"],
})
export class ExcelUrlConnectorComponent implements OnInit {
	//selectedConnector: DataSourceAdapter;
	loading: boolean = false;
	excelTableList: ExcelSheet[] = [];
	@ViewChild("fileListTable") fileListTable: Table;
	@Input() currentConnectorView: ConnectorView<ExcelUriAccess, ExcelPullMetaInfo, ExcelPullMetaAccess>;

	fileListCols: IFieldListColumn[] = [];

	onExcelSheetSelected(event) {
		const selectedSourceTable = event.data.Name;
		this.currentConnectorView.connectorSettings.Table = selectedSourceTable;
		console.log(this.currentConnectorView);
	}

	//uploadFilesAsync(event: PointerEvent)
	uploadFilesAsync(event:any) {

		// let input = event.files;
		// console.log(event);
		// let firstFile = input[0];
		// let filePath = firstFile.getInputstream();
		// console.log(filePath);
		// this.currentConnectorView.uploadFile(firstFile);

		this.loading = true;

		this.currentConnectorView.fetchMetaDataObs().subscribe(
			(meta_info: ExcelPullMetaInfo) => {
				const excelTableList: ExcelSheet[] = [...meta_info.Sheets];

				this.excelTableList = [...excelTableList];

				if (excelTableList.length === 0) {
					console.log("No Sheets found!");
				} else {
					console.log("Excel Sheets:", this.excelTableList);
				}

				this.cd.detectChanges();
			},
			(error: Error) => {
				this.errorService.handleError(error);
			},
			() => {
				this.loading = false;
			}
		);
	}

	setUrl(event) {
		console.log(event);
		this.currentConnectorView.connectorSettings.Uri = event;
	}

	constructor(private cd: ChangeDetectorRef, private errorService: SystemMessageLogService) { }

	ngOnInit(): void {
		this.fileListCols = [
			// { field: 'Index', header: 'Index', width:'20%' },
			{ field: "Name", header: "Sheet Name", width: "70%" },
			{ field: "IsHidden", header: "Hidden Sheet", width: "30%" },
		];
	}
}
