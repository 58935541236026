import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { ApiBackendService } from "./api-backend.service";
import { UserAzureRow } from "../models/azure.model";
import { UserInfo } from "../models/api/models/session/UserInfo";
import { SessionController } from "../models/api/controllers/SessionController";

//const AUTH_API = 'http://localhost:8080/api/auth/';

const httpOptions = {
	headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
	providedIn: "root",
})
export class AuthService {
	constructor(private http: HttpClient, private api: ApiBackendService) {}

	login(username: string, password: string): Observable<string> {
		return this.api.loginUser(username, password);
	}
	loginPost(arg:SessionController.LogInArg) {
		return this.api.loginUserPost(arg);
	}
	loginAzure(username: string, password: string): Observable<string> {
		return this.api.azureLogin(username, password);
	}
	getUserAzureRow(id?: number,accountName?: string): Observable<UserAzureRow[]> {
		return this.api.getUserAzureRow(id, accountName);
	}
	createUserAzureRow(item: UserAzureRow): Observable<UserAzureRow> {
		return this.api.createUserAzureRow(item);
	}
	updateUserAzureRow(item: UserAzureRow): Observable<number> {
		return this.api.updateUserAzureRow(item);
	}

	registerUser(
		username: string,
		email: string,
		password: string
	): Observable<UserInfo> {
		return this.api.registerUser(username, password);
	}

	getUserInfo(): Observable<UserInfo> {
		return this.api.getUserInfo();
	}

	getUserInfoPure(): Observable<UserInfo> {
		return this.api.getUserInfoPure();
	}
}
