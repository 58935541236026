<!-- <p-dropdown appendTo="body" [options]="columnListFiltered" [(ngModel)]="selectedHeaderColumn"  placeholder="Select Header Column"
optionLabel="Name" dataKey="Name" (onChange)="onUpdateSettings(false)" autoWidth="false"
[style]="{'width':'100%'}">
</p-dropdown>
<p-dropdown appendTo="body" [options]="columnListFiltered" placeholder="Select Value Column" [(ngModel)]="selectedValueColumn"
optionLabel="Name" dataKey="Name" (onChange)="onUpdateSettings(false)" (onChange)="filterMethod($event)" autoWidth="false"
[style]="{'width':'100%'}">
</p-dropdown>
<p-dropdown appendTo="body" [options]="pivotMethodsFiltered"  placeholder="Select Method" [(ngModel)]="selectedMethod"
optionLabel="name" dataKey="name" (onChange)="onUpdateSettings(false)" autoWidth="false"
[style]="{'width':'100%'}">
</p-dropdown> -->


<div class="p-col-12 headerSection" style="min-height: 270px;margin-bottom: 20px">
    <label>{{'Key Column' | translate}}</label>
    <p-listbox id="selectedColumns" [options]="columnList" [(ngModel)]="selectedColumns"
        (onChange)="filterFieldInfos($event)" [metaKeySelection]="false" [checkbox]="true" [filter]="true"
        [multiple]="false" optionLabel="Name" [listStyle]="{'height':'220px'}">
        <ng-template let-column pTemplate="item">
            <div class="column-item">
                <!-- <img src="assets/showcase/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + column.code.toLowerCase()" /> -->
                <div>{{column.Name}}</div>
            </div>
        </ng-template>
    </p-listbox>
</div>
<div class="p-col-12 headerSection" style="margin-top: 20px">
    <div class="p-fluid dropDownInput">
        <span class="p-float-label">
            <p-dropdown id="selectedHeaderColumn" appendTo="body"  [autoDisplayFirst]="false" [options]="columnListFiltered"
                (onChange)="onUpdateSettings(false)" [(ngModel)]="selectedHeaderColumn" optionLabel="Name">
            </p-dropdown>
            <label for="selectedHeaderColumn">{{'Header Column' |translate }}</label>
        </span>
    </div>
    <div class="p-fluid dropDownInput">
        <span class="p-float-label">
            <p-dropdown id="selectedValueColumn" appendTo="body" [autoDisplayFirst]="false" [options]="columnListFiltered"
                (onChange)="onUpdateSettings(false)" [(ngModel)]="selectedValueColumn" optionLabel="Name">
            </p-dropdown>
            <label for="selectedValueColumn">{{'Value Column' |translate }}</label>
        </span>
    </div>
    <div class="p-fluid dropDownInput">
        <span class="p-float-label">
            <p-dropdown id="pivotMethodsFiltered" appendTo="body"  [autoDisplayFirst]="false" [options]="pivotMethodsFiltered"
                (onChange)="onUpdateSettings(false)" [(ngModel)]="selectedMethod" optionLabel="name">
            </p-dropdown>
            <label for="pivotMethodsFiltered">{{'Method' |translate }}</label>
        </span>
    </div>
</div>