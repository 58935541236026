<!-- <div class="p-grid">

    <div class="p-col headerSection">
        <span class="p-float-label">
            <p-calendar id="referenceDate" [(ngModel)]="referenceDate" class="lean-input-full-width" appendTo="body"
                [showIcon]="true" (onInput)="onUpdateSettings(false)" (onSelect)="onUpdateSettings(false)">
            </p-calendar>
            <label for="referenceDate">Reference Date</label>
        </span>
    </div>
    <div class="p-col-3 headerSection">
        <button style="float: right" pButton label="Add Converter" icon="fa fa-plus"
            (click)="onAddConverter()"></button>
    </div> -->

<div>
    <p-table [value]="convertList" dataKey="ColumnName" editMode="row" [reorderableColumns]="false">
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                <span class="p-input-icon-left">
                    <span class="p-float-label">
                        <p-calendar id="referenceDate" [(ngModel)]="referenceDate" class="lean-input-full-width"
                            (onChange)="onUpdateSettings(false)" appendTo="body" [showIcon]="true">
                        </p-calendar>
                        <label for="referenceDate">{{'Reference Date' |translate }}</label>
                    </span>
                </span>

                <span class="p-input-icon-right">
                    <button style="float: right" pButton label="{{'Add' | translate}}" icon="fa fa-plus"
                        (click)="onAddConverter()"></button>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <!-- <th style="width:3rem"></th> -->
                <th tooltipPosition="top" pTooltip="{{'Config.FXConvert.Tooltip1' | translate}}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Name' | translate}}

                    </div>
                </th>

                <th tooltipPosition="top" pTooltip="{{'Config.FXConvert.Tooltip2' | translate}}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Base Currency' | translate}}
                    </div>
                </th>
                <th tooltipPosition="top" pTooltip="{{'Config.FXConvert.Tooltip3' | translate}}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Target Currencies' | translate}}
                    </div>
                </th>
                <!-- <th pReorderableColumn>Column Name</th>
                <th pReorderableColumn tooltipPosition="top" pTooltip="Select a column as your base currency">Base
                    Currency</th>
                <th pReorderableColumn tooltipPosition="top" pTooltip="Choose your target currencies.">Target
                    Currencies</th> -->
                <th style="width:3rem"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-column let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="column" [pReorderableRow]="ri"
                [ngClass]="{'row-accessories': column.hasError === true}">
                <!-- <td>
                        <span class="pi pi-bars" pReorderableRowHandle (onChange)="onChangeSettings(this.filterList)"></span>
                    </td> -->
                <td>
                    <!-- <p-dropdown appendTo="body" [options]="currencyColumns" [(ngModel)]="column.fieldInfo"
                            optionLabel="Name" dataKey="Name" (onChange)="onUpdateSettings(false)" autoWidth="false"
                            [style]="{'width':'100%'}">
                        </p-dropdown> -->
                    <p-dropdown appendTo="body" *ngIf="column.hasError === false" [options]="columnsList"
                        (onChange)="onUpdateSettings(false)" [autoDisplayFirst]="false" [(ngModel)]="column.fieldInfo"
                        autoWidth="false" [style]="{'width':'100%'}"></p-dropdown>
                    <p-dropdown *ngIf="column.hasError === true" appendTo="body" [options]="columnsList"
                        (onChange)="onUpdateSettings(false)" [autoDisplayFirst]="false" [(ngModel)]="column.fieldInfo"
                        class="ng-invalid ng-dirty" autoWidth="false" [style]="{'width':'100%'}">
                    </p-dropdown>
                </td>
                <td>
                    <p-dropdown appendTo="body" [options]="baseCurrencies" [(ngModel)]="column.baseCurrency"
                        (onChange)="onUpdateSettings(false)" optionLabel="name" dataKey="value" autoWidth="false"
                        [style]="{'width':'100%'}">
                    </p-dropdown>
                </td>
                <td>
                    <p-multiSelect appendTo="body" [options]="targetCurrencies"
                        placeholder="Select your target currencies" display="chip" [(ngModel)]="column.targetCurrencies"
                        (onChange)="onUpdateSettings(false)" optionLabel="name" dataKey="value" autoWidth="false"
                        [style]="{'width':'100%'}">
                    </p-multiSelect>
                </td>
                <td>
                    <div class="ui-table-buttongroup">
                        <button pButton pRipple type="button" class="p-button-rounded p-button-text ui-button-danger"
                            icon="pi pi-trash" pTooltip="Delete entry" (click)="onDeleteEntry(ri)"
                            tooltipPosition="bottom"></button>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="4">{{'NoEntryFound' | translate}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>
<!-- </div> -->