import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { SapMetaInfo } from 'src/app/models/api/com/bion/connect/sap/SapMetaInfo';
import { SapConnectorView } from 'src/app/models/connectors/sap-connector-view.model';
import { PlayErrorResponse } from 'src/app/models/play.error.model';

@Component({
  selector: 'app-sap-connector',
  templateUrl: './sap-connector.component.html',
  styleUrls: ['./sap-connector.component.scss']
})
export class SapConnectorComponent implements OnInit {
	@Input() currentConnectorView: SapConnectorView;
  status_text: string;
  status: boolean;
  constructor() { }

  ngOnInit(): void {
  }


	onConnect(event: PointerEvent): void {
    console.log("onCONNECT");
		this.currentConnectorView.fetchMetaDataObs().subscribe(
			(res: SapMetaInfo) => {
				this.currentConnectorView.metaInfo = res;
				console.log(res);
				this.status_text = "OK!";
				this.status = true;
			},
			(error: any) => this.handleError(error)
		);
	}

	handleError(error: HttpErrorResponse) {
		// TODO: delegate or handle
		console.log(error);
		const play_error: PlayErrorResponse = error.error;
		this.status_text = play_error.error.exception.description;
		this.status = false;
	}

}
