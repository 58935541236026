import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WriteMode } from 'src/app/models/helperClass.model';
import { CubesService } from 'src/app/services/cubes.service';
import { DatasourcesService } from 'src/app/services/datasources.service';

interface SpecInfo {
  IsPullSource: boolean;
  IsPushSource: boolean;
  InitialExtractArg: any;  // Main Config
  InitialMetaRequestArg: any;
}

interface BionConnectorSpecification {
  ID: number;
  Name: string;
  Version: number;
  Class: string;
  Description: string;
  Category: string;
  Info: SpecInfo
}

@Component({
  selector: 'app-create-destination-config-bion',
  templateUrl: './create-destination-config-bion.component.html',
  styleUrls: ['./create-destination-config-bion.component.scss']
})
export class CreateDestinationConfigBionComponent implements OnInit {

  constructor(private dsService: DatasourcesService) { }
  writeModes: WriteMode[];
  selectedWritemode: WriteMode;
  destinationName?: string;
  destinationDescription?: string; 
  inputDisabled: boolean = false;
  buttonDisabled: boolean = true;

  readonly EXCEL_ADAPTER: string = "Excel Adapter";
  readonly EXCEL_URL_ADAPTER: string = "Excel URL Adapter";
  readonly CSV_ADAPTER: string = "CSV Adapter";
  readonly CSV_URL_ADAPTER: string = "CSV URL Adapter";

  selected_settings_view?: string = "";
  //selected_config?: any = undefined;
  connector_specs?: BionConnectorSpecification = undefined;



  ngOnInit(): void {
    this.writeModes = this.dsService.getWriteModes().filter( wm => wm.name !== "Update" && wm.name !== "Delta" );

  }

  @Input()
  get connectorSpecs(): BionConnectorSpecification { return this.model; }
  set connectorSpecs(m: BionConnectorSpecification) {
    this.loadBionForm(m);
  }

  @Output()
  public onConfigSet: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onFileChanged: EventEmitter<File> = new EventEmitter<File>();

  model?: any;  // Bion Default Config

  loadBionForm(model: BionConnectorSpecification) {

    this.connector_specs = model;
    this.model = model.Info.InitialExtractArg;
    this.selected_settings_view = model.Name;

    console.log("Load Bion Form", model);
    console.warn("Bion Dialog not implemented yet!");
  }

  onConfigChanged() {
    this.model = [{description: this.destinationDescription, writeMode: this.selectedWritemode.name}];
    this.validateForm();
    console.log("Config Changed", this.model)
  }
  validateForm() {
    if(this.selectedWritemode) { 
      this.buttonDisabled = true;

    } else {
      this.buttonDisabled = false;
    }
  }
  submitClicked() {
    const config = this.model;
    console.log("Final Argument", config);

    this.onCreateDestination(config);
  }

  onCreateDestination(data: any) {
    console.log("Config is valid", data);
    this.onConfigSet.emit(data);
  }

  handle_error(e: Error) {
    // this.messages.push({ severity: 'error', summary: 'Error', detail: e.message });
    // console.log("Error Handler", e);
    // this.setLoading(false);
  }





}
