import { Component, OnInit } from '@angular/core';
import { ConfigComponent } from '../../../create-ab-source/config.component';
import { ComboBoxValue } from '../ui-models';

// Google Sheets Settings, können wir später ein bischen aufräumen und in die 'api/...' Ordnerstruktur packen.

export namespace GoogleSheets {
  export class Credentials {
    auth_type: string;
    client_id: string;
    client_secret: string;
    refresh_token: string;
    constructor(auth_type: string, client_id: string, client_secret: string, refresh_token: string) {
      if (auth_type === undefined) throw new Error("Class 'Credentials': Required value 'auth_type' is undefined");
      if (client_id === undefined) throw new Error("Class 'Credentials': Required value 'client_id' is undefined");
      if (client_secret === undefined) throw new Error("Class 'Credentials': Required value 'client_secret' is undefined");
      if (refresh_token === undefined) throw new Error("Class 'Credentials': Required value 'refresh_token' is undefined");
      this.auth_type = auth_type;
      this.client_id = client_id;
      this.client_secret = client_secret;
      this.refresh_token = refresh_token;
    }
  }
  export class Config {
    credentials: Credentials;
    row_batch_size: number;
    spreadsheet_id: string;
    constructor(credentials: Credentials, row_batch_size: number, spreadsheet_id: string) {
      if (credentials === undefined) throw new Error("Class 'Config': Required value 'credentials' is undefined");
      if (row_batch_size === undefined) throw new Error("Class 'Config': Required value 'row_batch_size' is undefined");
      if (spreadsheet_id === undefined) throw new Error("Class 'Config': Required value 'spreadsheet_id' is undefined");
      this.credentials = credentials;
      this.row_batch_size = row_batch_size;
      this.spreadsheet_id = spreadsheet_id;
    }
  }
}

// Nicht schön aber Funktioniert.
// Konstanten und flexible Sub-Schemas sind schwer in Angular :-(



/**
 * Noch unvollständig, POC state.
 */
@Component({
  selector: 'app-google-sheets',
  templateUrl: './google-sheets.component.html',
  styleUrls: ['./google-sheets.component.scss']
})
export class GoogleSheetsComponent implements OnInit, ConfigComponent {

  //data: GoogleSheets.Config = this.makeDefaultConfig();
  data: any = this.makeDefaultConfig();
  connectorSpecs: any;

  credential_options: ComboBoxValue[] = [
    { name: "Authenticate via Google (OAuth)", property: "auth_type", value: "Client" },
    { name: "Service Account Key Authentication", property: "auth_type", value: "Service" }
  ];

  credential_options_selected?: ComboBoxValue;

  constructor() {
    console.log("Initialize default object - later from outside");
    console.log("Auth Type still missing!")
    this.data = this.makeDefaultConfig();
  }


  ngOnInit(): void {
    console.log(this.data);
  }

  makeDefaultConfig() {
    console.log("WARNING - Google Sheets credentials hard coded in file - Remove before check in!!");
    const c = new GoogleSheets.Credentials("Client", "888515449425-65rcu7o8nli5apueakd84js8ipr73tll.apps.googleusercontent.com", "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", "1//04LHA1OqdPmMyCgYIARAAGAQSNwF-L9IrGp6j4Ln5NnmUnv5SMKUT9Jis3CtM6f-HACvF04N34iKOuu4KBDrW0zF3UZe72mRSA1s");
    // const c = new GoogleSheets.Credentials("Client", "", "", "");
    return new GoogleSheets.Config(c, 100, "https://docs.google.com/spreadsheets/d/1h3PZcguCxBzbPijcuqaV06SGDX5--b1VntmvRmyDSKg/edit?usp=share_link");
  }
}