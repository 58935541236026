import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { CsvUrlAccess } from "../api/com/bion/csv/CsvUrlAccess";
import { CsvUrlMetaAccess } from "../api/com/bion/csv/CsvUrlMetaAccess";
import { CsvUrlMetaInfo } from "../api/com/bion/csv/CsvUrlMetaInfo";
import { DynamicItemInfo } from "../com-bion-json";
import { ExtractMetaFromConnectorArg } from "../connector.model";
import { ConnectorSettingsBuilder, ConnectorSettingsBuilderBase, ConnectorViewBase } from "../connectorView.model";


export class CsvUrlConnectorView extends ConnectorViewBase<CsvUrlAccess, CsvUrlMetaInfo, CsvUrlMetaAccess> {
    constructor(api: ApiBackendService, connectorID: string) {
        super(api, connectorID);
    }
    fetchMetaDataObs(): Observable<CsvUrlMetaInfo> {

        const meta_access = new CsvUrlMetaAccess(this.connectorSettings.Uri);

        const extract_meta_arg = new ExtractMetaFromConnectorArg;
        const dynamic_meta_item_info = new DynamicItemInfo(this.connectorID, meta_access);
        extract_meta_arg.ConnectorMetaInfo = dynamic_meta_item_info;

        return this.api.api_extractMetaFromConnector(extract_meta_arg).pipe(map((extractRes) => {
            return <CsvUrlMetaInfo>extractRes.MetaData;
        }));
    }

    getBase64FileData(): string {
        return this.base64FileData;
    }
    getDataSourceNameSuggestion(): string {
        return this.uploadedFile.name;
    }
    uploadFile(file: File) {
        this.uploadedFile = file;
    }

    getSettingsBuilder(): Observable<ConnectorSettingsBuilder<CsvUrlAccess>> {

        // const file_reader = new AsyncFileReader();
        // const file_obs = file_reader.readAsync(this.uploadedFile);

        // const settings_builder_obs = file_obs.pipe(
        //     map((fileData: string) => {
        //         let innerRes = new CsvUrlSettingsBuilder(this.connectorID, this.connectorSettings, fileData);
        //         return innerRes;
        //     })
        // );
        // return settings_builder_obs;

        let innerRes = new CsvUrlSettingsBuilder(this.connectorID, this.connectorSettings, undefined);
        return of(innerRes);

    }

    isFileBased(): boolean {
        return false;
    }

    fileIsUpdated: boolean = false;
    flattenedFileList: [];
    base64FileData: string;
}

export class CsvUrlSettingsBuilder extends ConnectorSettingsBuilderBase<CsvUrlAccess> {

}
