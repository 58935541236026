import { Component, OnInit } from '@angular/core';

import { Observable, Observer } from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

// Tutorial from
// https://indepth.dev/tutorials/angular/how-to-implement-websockets-in-angular-project

export interface Message {
  source: string;
  content: string;
}

@Component({
  selector: 'app-web-socket-tester',
  templateUrl: './web-socket-tester.component.html',
  styleUrls: ['./web-socket-tester.component.scss']
})
export class WebSocketTesterComponent implements OnInit {

  private subject: AnonymousSubject<MessageEvent>;
  public messages: Subject<Message>;

  received:Message[] = [];

  //readonly CHAT_URL = "ws://localhost:5000";
  readonly CHAT_URL = "ws://localhost:9000/api/experimental/websocket/ws";

  constructor() {

    this.messages = <Subject<Message>>this.connect(this.CHAT_URL).pipe(
      map(
        (response: MessageEvent): Message => {
          console.log(response.data);
          let data = JSON.parse(response.data)
          return data;
        }
      )
    );

    // Listening
    this.messages.subscribe(answer => {
      console.log("Web Socket Answer", answer);
      this.received.push(answer);
    });

  }

  ngOnInit(): void {
  }

  public connect(url): AnonymousSubject<MessageEvent> {
    if (!this.subject) {
      this.subject = this.create(url);
      console.log("Successfully connected: " + url);
    }
    return this.subject;
  }

  private create(url:string): AnonymousSubject<MessageEvent> {
    let ws = new WebSocket(url);
    let observable = new Observable((obs: Observer<MessageEvent>) => {
      ws.onmessage = obs.next.bind(obs);
      ws.onerror = obs.error.bind(obs);
      ws.onclose = obs.complete.bind(obs);
      return ws.close.bind(ws);
    });
    let observer = {
      error: null,
      complete: null,
      next: (data: Object) => {
        console.log('Message sent to websocket: ', data);
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify(data));
        }
      }
    };
    return new AnonymousSubject<MessageEvent>(observer, observable);
  }

  onClick() {
    const msg : Message = { source:"Angular", content:"Hallo!" }
    this.messages.next(msg)
  }

}
