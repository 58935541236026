import { EventEmitter, Injectable, Output } from "@angular/core";

@Injectable({
	providedIn: "root",
})
export class DataMarketService {
	@Output() displayDataSetDetailsDialog = new EventEmitter<boolean>();

	constructor() {}
}
