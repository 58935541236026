import { Component, OnInit } from '@angular/core';
import { GenderCheckPlugIn } from 'src/app/models/api/com/bion/etl/transformation/GenderCheckPlugIn';
import { FieldInfo, WorkflowNodeSettings } from 'src/app/models/api/com/bion/etl/Workflow';
import { DesignerService } from 'src/app/services/designer.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { NodeConfigComponentBase } from '../node-config-component-base';

export class GenderCheckInfoView {
  ColumnName: string;
  hasError?: boolean;
  constructor(ColumnName: string, hasError?: boolean) {
    this.ColumnName = ColumnName;
    this.hasError = hasError;
  }
}


@Component({
  selector: 'app-gender-check-node',
  templateUrl: './gender-check-node.component.html',
  styleUrls: ['./gender-check-node.component.scss']
})
export class GenderCheckNodeComponent extends NodeConfigComponentBase<GenderCheckPlugIn.Settings>  implements OnInit {
  settingsToView(settings: WorkflowNodeSettings): void {
        // -- Get Configuration
        let currentConfig = <GenderCheckPlugIn.Settings>settings;
        // -- Get Input Meta Data
        const meta_infos =
            this._currentWorkflowNodeAdapter.IWorkflowNode.Properties.MetaInfo.get(
                this.InputPort
            );
        let columnListArray: string[] = [];

        //this.encryptInfo = encryptInfo;
        // -- check for MetaInfos from Input Port & store dsField list
        if (meta_infos !== undefined && meta_infos.length > 0) {
            const input_field_infos = meta_infos[0].FieldsInfo;

            // -- create string array for column dropdown
            columnListArray = input_field_infos.map((fieldInfo) => {
                return fieldInfo.Name;
            });
            this.columnList = columnListArray;
        }

        this.apiKey = currentConfig.ApiKey;
        this.sendingActiveFlag = currentConfig.SendingActive;
        this.firstNameColumn = currentConfig.GenderCheckInfos[0].ColumnName;

        // -- if no configuration exists, exit
        if (currentConfig === undefined) {
            // -- if undefined, exit
            return;
        } else {
            // -- otherwise load from existing config
            if (!currentConfig.GenderCheckInfos || currentConfig.GenderCheckInfos.length === 0) return;

            // -- if given, check infos for columns
            currentConfig.GenderCheckInfos.map((info) => {
                if (columnListArray.includes(info.ColumnName)) {
                    // -- column still exists, do nothing
                } else {
                    // column does not exist anymore, meaning column name currently not included -> include
                    columnListArray.push(info.ColumnName);
                }
            });


            this.columnList = columnListArray;

            if (meta_infos !== undefined) {
                let field_infos = meta_infos[0].FieldsInfo;

                if (field_infos === undefined) return;

                this.emailValidationInfo = this.infoToView(currentConfig, field_infos);
            } else {
                this.emailValidationInfo = this.infoToView(currentConfig);
            }
        }
  }
  viewToSettings(): GenderCheckPlugIn.Settings {
        let newSettings = <GenderCheckPlugIn.Settings>{
            ...this.getCurrentWorkflowNode().Properties.Configuration,
        };
        let newInfo = new GenderCheckPlugIn.GenderCheckInfo(this.firstNameColumn);

        newSettings.GenderCheckInfos = [...[newInfo]];
        newSettings.ApiKey = this.apiKey;
        newSettings.SendingActive = this.sendingActiveFlag;

        return newSettings;
      }
  onSettingsChanged(settings: GenderCheckPlugIn.Settings): void {
    throw new Error('Method not implemented.');
  }

  readonly InputPort = "Input";
  emailValidationInfo: GenderCheckPlugIn.GenderCheckInfo;
  firstNameColumn: string = "";
  apiKey: string ="";
  sendingActiveFlag: boolean = false;
  columnList: string[];

  constructor(
    protected workflowService: WorkflowsService,
    protected designerService: DesignerService
) {
    super(workflowService, designerService);
}
  ngOnInit(): void {
    super.ngOnInit();
  }

  infoToView(config: GenderCheckPlugIn.Settings, meta?: FieldInfo[]): GenderCheckInfoView {
    //let view: EncryptInfoView = new EncryptInfoView();

    // -- create selected EncryptInfo from config
    const ColumnName = config.GenderCheckInfos[0].ColumnName;

    let hasErrorFlag: boolean;

    if (meta) {
        let fieldInfoFound = meta.find((field) => {
            return field.Name === config.GenderCheckInfos[0].ColumnName;
        });

        hasErrorFlag = !fieldInfoFound;
    } else {
        hasErrorFlag = false;
    }

    let view: GenderCheckInfoView = new GenderCheckInfoView(ColumnName, hasErrorFlag);

    return view;
}

}

