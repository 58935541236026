<div class="p-grid">
    <div class="p-col-4 p-ai-center p-jc-center">
        <div class="p-grid" style="text-align: center;margin: 10px 40px 0px 20px;height:480px">
            <div class="card-header">
                <h6 style="color: rgb(170, 170, 170)">{{'NameYourDatasource' | translate}}</h6>
            </div>
            <div class="p-col-12 p-mb-12 p-lg-12 p-mb-lg-0">
                <div class="p-fluid">
                    <span class="p-float-label">
                        <input type="text" id="dsnameinputtext" pInputText [(ngModel)]="dataSourceName"
                            (blur)="updateForms()">
                        <label for="dsnameinputtext">{{'DatasourceName' | translate}}</label>
                    </span>
                </div>
            </div>
            <div class="p-col-12 p-mb-2 p-lg-12 p-mb-lg-0">
                <div class="p-fluid">
                    <span class="p-float-label">
                        <textarea [rows]="10" id="dsdescriptiontext" [cols]="30" pInputTextarea
                            [(ngModel)]="dataSourceDescription" autoResize="autoResize"
                            (blur)="updateForms()"></textarea>
                        <label for="dsdescriptiontext">{{'DescriptionOptional' | translate}}</label>
                    </span>
                </div>
            </div>
            <div class="p-col-12 p-mb-2 p-lg-12 p-mb-lg-0">
                <p-checkbox [binary]="true" (click)="updateForms()" [(ngModel)]="LoadDataFlag"></p-checkbox>{{'Load Data
                to PSA' | translate}}

            </div>

        </div>
    </div>

    <div class="p-col-8 p-ai-center p-jc-center">
        <div class="card p-shadow-2" style="height:480px;margin-top: 10px">
            <div class="card-header">
                <h6 style="color: rgb(170, 170, 170)">{{'SelectWriteMode' | translate}}</h6>
            </div>
            <!-- <div class="p-col-12 p-mb-12 p-lg-12 p-mb-lg-0" style="margin-top: 30px">
                <div class="p-fluid">
                    <span class="p-float-label">
                        <p-dropdown placeholder="Select a mode" [options]="writeModeOptions"
                        [(ngModel)]="selectedWriteModeOption" optionLabel="name" (onChange)="selectWriteMode($event)"
                        [showClear]="false">
                    </p-dropdown>
                        <label for="dsdescriptiontext">Write mode</label>
                    </span>
                </div>
            </div> -->
            <div class="chat-section chat" style="margin-top: 20px">
                <p-tabView [(activeIndex)]="index" (onChange)="selectWriteMode($event)">
                    <p-tabPanel *ngFor="let mode of writeModeOptions; let i=index" [selected]="i == 0">
                        <ng-template pTemplate="header">
                            <!-- <img class="writeModeImg" [src]="'assets/layout/images/Append.svg'" /> -->
                            <img src="assets/layout/images/{{mode.name}}.svg" width="50" height="50"
                                alt="freya-layout" />
                            <span class="p-badge">{{mode.name}}</span>
                        </ng-template>
                        <!-- <div class="p-col-12 p-mb-12 p-lg-12 p-mb-lg-0" *ngIf="selectedWriteModeOption">
                            <div [ngSwitch]="selectedWriteModeOption.name">
                                <div *ngSwitchCase="'Append'">
                                    <div class="p-grid">
                                        <div class="p-col-12" style="margin: 5px 0">
                                            <i class="fa fa-info-circle"></i>
                                            {{'AppendModeText' | translate}}
                                        </div>
                                        <div class="p-col-12" style="text-align: center;">
                                            <img class="writeModeImg" [src]="'assets/layout/images/Append.svg'"
                                                width="150" height="150" />
                                        </div>
                                    </div>
                                </div>
                                <div *ngSwitchCase="'Replace'">
                                    <div class=" p-grid">
                                        <div class="p-col-12" style="margin: 5px 0">
                                            <i class="fa fa-info-circle"></i>
                                            {{'ReplaceModeText' | translate}}
                                        </div>
                                        <div class="p-col-12" style="text-align: center;">
                                            <img class="writeModeImg" [src]="'assets/layout/images/Replace.svg'"
                                                width="150" height="150" />
                                        </div>
                                    </div>
                                </div>
                                <div *ngSwitchCase="'Update'">
                                    <div class=" p-grid">
                                        <div class="p-col-12" style="margin: 5px 0">
                                            <i class="fa fa-info-circle"></i>
                                            {{'DeltaModeText' | translate}}
                                        </div>
                                        <div class="p-col-12" style="text-align: center;">
                                            <img class="writeModeImg" [src]="'assets/layout/images/Update.svg'"
                                                width="150" height="150" />
                                        </div>
                                    </div>
                                </div>
                                <div *ngSwitchCase="'Delta'">
                                    <div class=" p-grid">
                                        <div class="p-col-12" style="margin: 5px 0">
                                            <i class="fa fa-info-circle"></i>
                                            {{'DeltaModeText' | translate}}
                                        </div>
                                        <div class="p-col-12" style="text-align: center;">
                                            <img class="writeModeImg" [src]="'assets/layout/images/Delta.svg'"
                                                width="150" height="150" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </p-tabPanel>
                </p-tabView>
                <p-multiSelect [options]="previewColumnsClone" [style]="{'width':'100%'}" [(ngModel)]="selectedKeyCols" (onChange)="onSelectKeyCols($event)" defaultLabel="Select key column(s)" optionLabel="Name" display="chip"></p-multiSelect>
                <span *ngIf="selectedWriteModeOption.name === 'Update'"><p><span style="color: rgb(220, 105, 105)">{{'Required!' | translate}} </span>{{'At least one column must be selected as key column.' | translate}}</p></span>
                <span *ngIf="selectedWriteModeOption.name === 'Delta'"><p><span style="color: rgb(220, 105, 105)">{{'Required!' | translate}} </span>{{'At least one column must be selected as key column.' | translate}}</p></span>
                <span *ngIf="selectedWriteModeOption.name === 'Append'"><p><span style="color: rgb(105, 220, 116)">{{'Optional!' | translate}} </span>{{'Selecting a key column is recommanded but not required.' | translate}}</p></span>
                <span *ngIf="selectedWriteModeOption.name === 'Replace'"><p><span style="color: rgb(105, 220, 116)">{{'Optional!' | translate}} </span>{{'Selecting a key column is recommanded but not required.' | translate}}</p></span>


            </div>

        </div>
    </div>
</div>
