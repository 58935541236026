import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { Id } from "src/app/helper/id";
import { FieldInfo, WorkflowNodeSettings } from "src/app/models/api/com/bion/etl/Workflow";
import { DataTypeInfo } from "src/app/models/datasource.model";
import {
	MissingValuesSettings,
	MissingValuesInfo,
} from "src/app/models/nodeSettings.model";
// import { FieldInfo, WorkflowNodeSettings } from "src/app/models/workflow.model";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { DesignerService } from "src/app/services/designer.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { SubSink } from "subsink";
import { NodeConfigComponentBase } from "../node-config-component-base";

export class MissingValuesInfoView {
	ColumnName: string;
	ColumnDataType: DataTypeInfo;
	Operator: Rule;
	OperatorValue: any;
	hasError?: boolean;

	constructor(ColumnName: string, ColumnDataType: DataTypeInfo,Operator: Rule, OperatorValue: any, hasError?: boolean) {
		this.ColumnName = ColumnName;
		this.ColumnDataType = ColumnDataType;
		this.Operator = Operator;
		this.OperatorValue = OperatorValue;
		this.hasError = hasError
	}
}

export class Rule {
	name: string;
	value: string;
	category?: string;
	constructor(name: string, value: string, category?: string) {
		this.name = name;
		this.value = value;
		this.category = category
	}
}

@Component({
	selector: "app-missing-value-node",
	templateUrl: "./missing-value-node.component.html",
	styleUrls: ["./missing-value-node.component.css"],
})
export class MissingValueNodeComponent
	extends NodeConfigComponentBase<MissingValuesSettings>
	implements OnInit, OnDestroy
{
	settingsToView(settings: WorkflowNodeSettings): void {
		// -- Get Configuration
		let currentConfig = <MissingValuesSettings>settings;
		// -- Get Input Meta Data
		const meta_infos = this.getCurrentWorkflowNode().Properties.MetaInfo.get(
			this.InputPort
		);
		let columnListArray: string[] = [];
		// create SelectInfoViews from DsFields for table
		if (meta_infos != undefined && meta_infos.length > 0) {
			const input_field_infos = meta_infos[0].FieldsInfo;
			this.fieldInfos = input_field_infos;

			// -- create string array for column dropdown
			columnListArray = input_field_infos.map((fieldInfo) => {
				return fieldInfo.Name;
			});
			this.columnsList = columnListArray;
		}
		if (settings !== undefined) {
			// this.currentEngineInfo = this.currentWorkflowNode.Engine;
			this.currentNodeConfiguration = <MissingValuesSettings>settings;

			if (currentConfig.MissingValuesInfos === undefined) return;

			currentConfig.MissingValuesInfos.map((info) => {
				if (columnListArray.includes(info.ColumnName)) {
					// -- column still exists, do nothing
				} else {
					// column does not exist anymore, meaning column name currently not included -> include
					columnListArray.push(info.ColumnName);
				}
			});
			this.columnsList = columnListArray;
            if(meta_infos !== undefined) {
			this.missingValueList = this.infoToView(
				this.currentNodeConfiguration,
				meta_infos[0].FieldsInfo
			);
            }
		}
	}
	viewToSettings(): MissingValuesSettings {
		const newSettings = <MissingValuesSettings>{
			...this.getCurrentWorkflowNode().Properties.Configuration,
		};

		// -- Get select information
		let newInfoList: MissingValuesInfo[] = [];
		this.missingValueList.map((entry: MissingValuesInfoView) => {
			let newEntry = new MissingValuesInfo();
			newEntry.ColumnName = entry.ColumnName;
			newEntry.ColumnType = entry.ColumnDataType.Name;
			newEntry.Operator = entry.Operator.name;
			newEntry.OperatorValue = entry.OperatorValue;

			newInfoList.push(newEntry);
		});

		newSettings.MissingValuesInfos = newInfoList;

		return newSettings;
	}
	onSettingsChanged(settings: MissingValuesSettings): void {
		throw new Error("Method not implemented.");
	}

	//------------------------------------------------------------------------------------------------- Variables
	//@Input() currentWorkflowNode;
	currentNodeConfiguration?: MissingValuesSettings;
	fieldInfos: FieldInfo[] = [];
	columnsList: string[] = [];
	operationOptions: Rule[] = [];
	//   numberOperationOptions: DropDown[];

	readonly InputPort = "Input";
	subs = new SubSink;
	//dtypesSub: Subscription;
	missingValueList: MissingValuesInfoView[] = [];
	dataTypeOptions: DataTypeInfo[] = [];

	//------------------------------------------------------------------------------------------------- Constructor

	constructor(
		protected workflowService: WorkflowsService,
		protected designerEventService: DesignerService,
		protected bionApi: ApiBackendService
	) {
		super(workflowService, designerEventService);

		//this.dataTypeOptions = [{name: "Double", value: "Double"},{name: "String", value: "String"},{name: "Long", value: "Long"}];

		// --Create OperationOptions
		this.operationOptions = [
			{ name: "Constant Value", value: "constantValue", category: "" },
			{ name: "Drop Column", value: "dropColumn", category: "" },
			{ name: "Min", value: "min", category: "numberOperator" },
			{ name: "Max", value: "max", category: "numberOperator" },
			{ name: "Average", value: "average", category: "numberOperator" },
			{ name: "Median", value: "median", category: "numberOperator" },
		];
	}

	//------------------------------------------------------------------------------------------------- Functions

	//-- Transforms DSField into View Class
	infoToView(infos: MissingValuesSettings, meta?: FieldInfo[]) {
		let newInfoViews: MissingValuesInfoView[] = [];

		this.subs.sink = this.bionApi
			.getDataTypes()
			.subscribe((result: DataTypeInfo[]) => {
				this.dataTypeOptions = result;

				if (infos.MissingValuesInfos.length > 0) {
					infos.MissingValuesInfos.map((info: MissingValuesInfo) => {
						//let newInfoEntry = new MissingValuesInfoView();

						//newInfoEntry.ColumnName = info.ColumnName;
						const fieldInfoFound = meta?.find((field) => {
							return field.Name === info.ColumnName;
						});
						//newInfoEntry.hasError = !fieldInfoFound;

						// -- Select respective DataTypeInfo of selected dsFieldInfo
						const ColumnDataType = this.dataTypeOptions.find((entry) => {
							return info.ColumnType === entry.Name;
						});
						const col_data_type = Id.assertSet(ColumnDataType, new Error("Column DataType not found,should not happen"));

						// -- Select Operation Rule
						const Operator = this.operationOptions.find((entry) => {
							return info.Operator === entry.name;
						});
						const _operator = Id.assertSet(Operator, new Error("Operator not found,should not happen"));

						// -- Select Operation Value (if given)
						//newInfoEntry.OperatorValue = info.OperatorValue;

						let newInfoEntry = new MissingValuesInfoView(info.ColumnName,col_data_type,_operator,info.OperatorValue,!fieldInfoFound);


						newInfoViews.push(newInfoEntry);
					});
				} else {
					console.log(
						"MISSINGVALUES: No MissingValuesInfo Entries found, do nothing."
					);
				}
			});
		return newInfoViews;
	}

	//-- Add new table row on GUI
	onAddRow() {
		//let newRow = new MissingValuesInfoView();

		let col_name = "";
		let col_datatype = new DataTypeInfo();

		// -- Select dataType of preselected first entry
		if (this.fieldInfos) {
			// -- Preselect first entry of dsFieldInfos
			col_name = this.fieldInfos[0].Name;
			const datatype_found = this.dataTypeOptions.find((entry) => {
				let fieldInfoFound = this.fieldInfos.find((field) => {
					return newRow.ColumnName === field.Name;
				});
				return fieldInfoFound?.DataType.Name === entry.Name;
			});

			col_datatype = Id.assertSet(datatype_found, new Error("DataType for this field not found, should not happen"));
		} else {
			col_name = "";
			const datatype_found = this.dataTypeOptions.find((type) => {
				return type.Name === "String";
			});
			col_datatype = Id.assertSet(datatype_found, new Error("DataType for this field not found, should not happen"));


		}
		// -- Preselect first operation entry
		//newRow.Operator = this.operationOptions[0];
		// -- Preset value as undefined
		//newRow.OperatorValue = "";
		//newRow.hasError = false;

		let newRow = new MissingValuesInfoView(col_name,col_datatype,this.operationOptions[0],"", false);

		this.missingValueList.push(newRow);
		this.onUpdateSettings(false);
	}
	//-- Delete table row on GUI
	onDeleteEntry(i: number) {
		this.missingValueList = [
			...this.missingValueList.slice(0, i),
			...this.missingValueList.slice(i + 1),
		];
		this.onUpdateSettings(false);

		// this.messageService.standardToastMessage({  severity : 'warn',
		//                                                 summary : 'Row deleted',
		//                                                 detail : 'The selected row was deleted successfully!'});
	}

	//------------------------------------------------------------------------------------------------- Cycle Hook
	ngOnInit(): void {
		super.ngOnInit();
	}

	ngOnDestroy(): void {
		//if (this.dtypesSub) this.dtypesSub.unsubscribe();
		this.subs.unsubscribe();
	}
    onFocusLost(event: FocusEvent) {
        const div_left: boolean = (event.relatedTarget === null);

        console.log("Send from Div: ", div_left);

        if (div_left) {
            //this.onCheckDirtyFlag();
            this.onUpdateSettings(true);
        }
    }
}
