<div class="menu-wrapper" [ngClass]="{'layout-sidebar-active': appMain.sidebarActive}"
    (click)="appMain.onSidebarClick($event)" (mouseover)="appMain.onSidebarMouseOver($event)"
    (mouseleave)="appMain.onSidebarMouseLeave($event)">
    <div class="sidebar-logo">
        <a [routerLink]="['/']">
            <img src="assets/layout/images/logo-bion-new.png" alt="freya-layout" style="width:35px; height:35px"
                class="logoIcon">
        </a>
        <a href="#" class="sidebar-pin" (click)="appMain.onToggleMenu($event)">
            <span class="pin"></span>
        </a>
    </div>

    <div class="layout-menu-container">
        <ul class="layout-menu">
            <li style="margin: 5px" app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i"
                [root]="true"></li>
        </ul>
    </div>

    <div class="layout-menu-container">
        <ul class="layout-menu">
            <li style="margin-top:20px; margin-left: 2px;  position: fixed;
                bottom: 0; float: left;
                width: 50px;">
                <!-- <button *ngIf="appMain.currentUser.userInfo.IsAdmin === true" [routerLink]="['/personal/users']" pButton
                    pRipple type="button" pTooltip="{{ 'UserManagement' | translate}}" icon="pi pi-users"
                    class="p-button-rounded p-button-text"></button><br> -->
                <!-- <button (click)="openCommunityPage()" pButton pRipple type="button"
                    pTooltip="{{ 'Community' | translate}}" icon="pi pi-globe"
                    class="p-button-rounded p-button-text"></button><br> -->
                <!-- <button [routerLink]="['/tutorial']" pButton pRipple type="button"
                    pTooltip="{{ 'Documentation' | translate}}" icon="pi pi-book"
                    class="p-button-rounded p-button-text"></button> -->
            </li>
        </ul>
    </div>

</div>
