<div class="login-body">
    <app-system-message-log></app-system-message-log>

    <div class="p-grid login-wrapper" style="margin: 0 0 0 0">


        <div [formGroup]="form" class="login-panel p-col-12">
            <a [routerLink]="['/']" class="logo">
                <img src="assets/layout/images/logo-bion-single.svg" alt="freya-layout" />
            </a>

            <!-- <div class="p-grid">
                <div class="card p-col-3" *ngFor="let license of stripeLicensePrices">
                    <h5>{{license[1].name}}</h5>
                    <p>{{license[1].description}}</p>
                </div>
            </div> -->
            <!-- <a style="margin-bottom: 5px; color: crimson;">{{errorMessage}}</a> -->
            <!-- <p-dropdown [style]="{'width':'100%', 'margin-bottom': '10px'}" autoWidth="true" [options]="stripeLicensePrices" optionLabel="[1].name" name="license" formControlName="stripeLicensePrice"></p-dropdown> -->
            
            <p-dropdown [disabled]="true" [style]="{'width':'100%', 'margin': '10px'}" autoWidth="true" [options]="stripeLicensePrices" formControlName="stripeLicensePrice" (onChange)="onSelectSubscription($event)" [showClear]="false" placeholder="{{'Select a subscription'| translate}}">
                <ng-template pTemplate="selectedItem">
                    <div class="country-item country-item-value" *ngIf="form.value.stripeLicensePrice">
                        <!-- <img src="assets/showcase/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + selectedCountry.code.toLowerCase()" /> -->
                        <div>{{form.value.stripeLicensePrice[1].name}}</div>
                    </div>
                </ng-template>
                <ng-template let-license pTemplate="item">
                    <div class="country-item">
                        <!-- <img src="assets/showcase/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + country.code.toLowerCase()" /> -->
                        <div>{{license[1].name}}</div>
                        <div>{{license[1].description}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
            
            <input pInputText type="text" class="form-control" name="username" formControlName="username" required
                id="username" placeholder="{{'Username'| translate}}" />
            <input pInputText type="text" class="form-control" name="email" formControlName="email" required id="email"
                placeholder="{{'Email'| translate}}" />
            <input pPassword type="password" class="form-control" name="password" formControlName="password" required
                minlength="0" id="password" placeholder="{{'Password'| translate}}" />
            <input pPassword type="password" class="form-control" name="password" formControlName="repeatPassword"
                (keyup.enter)="onSubmit()" required minlength="0" id="repeatPassword"
                placeholder="{{'Repeat password'| translate}}" />
            <div style="margin: 20px 0">
                <p-checkbox formControlName="agreeToTerms" [binary]="true"></p-checkbox>
                <p>{{'I have read and agree to the' | translate }} <a style="cursor: pointer;" (click)="openTermsOfCondition()">{{'terms
                        of condition' |translate }}</a> {{'and' | translate}}
                    <a style="margin-bottom: 20px;cursor: pointer;" (click)="openTermsOfDataProtection()">{{'data protection' |
                        translate }}
                    </a> {{'statements' | translate }}
                </p>
            </div>

            <p-captcha siteKey="6LfEm_4fAAAAAESKLXXtxuQFiVx-PHInBe8J87eg" (onResponse)="responseCaptcha()">
            </p-captcha>
  
            <span style="margin-top: 15px"><button pButton (click)="onSubmit()" pRipple label="{{'SUBMIT'| translate}}"
                    type="button"></button></span>

            <p>{{'You already have an account?' | translate}} <a [routerLink]="['/login']">{{'log in here' | translate
                    }}</a></p>
            <!-- <div class="p-fluid">
                <div class="p-col-12 p-md-12">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><p-checkbox (onChange)="setAccount($event)" formControlName="isAzureAccount"></p-checkbox></span>
                        <input  pInputText
                        [disabled]="isAzure"
                        type="text"
                        class="form-control"
                        name="email"
                        formControlName="emailAzure"
                        id="emailAzure" placeholder="Azure Account (optional)" />
                    </div>
                </div>
            </div> -->

        </div>

        <!-- <div class="login-footer">
            <h4>bion</h4>
            <h6>Copyright Ⓒ Bion Analytics</h6>
        </div> -->
    </div>
</div>
