import { FieldInfoLike } from "./datasource.model";


export class ExportResult {
    Bytes: Array<number>;
}

export class DataTablePreview {
    Columns: Array<FieldInfoLike>;
    Rows: Array<Array<any>>;
    constructor(Columns: Array<FieldInfoLike>, Rows: Array<Array<any>>) {
        if (Columns === undefined) throw new Error("Class 'DataTablePreview': Required value 'Columns' is undefined");
        if (Rows === undefined) throw new Error("Class 'DataTablePreview': Required value 'Rows' is undefined");
        this.Columns = Columns;
        this.Rows = Rows;
    }
}
