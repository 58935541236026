import { HttpClient, HttpEvent } from "@angular/common/http";
import { EventEmitter, Injectable, Output } from "@angular/core";
import { Observable } from "rxjs";
import { ExportFormatInfo } from "../models/api/models/ExportModel";
import { DataProviderIdentifier } from "../models/api/models/staging/DataProviderIdentifier";
import { DataProviderInfo } from "../models/api/models/staging/DataProviderInfo";
import { ExportDataConfig } from "../models/api/models/staging/ExportDataConfig";
//import { ExportFormatConfig } from "../models/api/models/staging/ExportFormatConfig";
import { ExtractDataToPsaStream } from "../models/api/models/api/ExtractDataToPsaStream";
import { AdapterTypeInfo, DataMarketAccess } from "../models/connector.model";
import { DataSet } from "../models/datamarket.model";
import * as dss from "../models/datasource.model";
import { WriteMode } from "../models/helperClass.model";
import { ExportResult, } from "../models/staging.model";
// import { JobRequestResult, JobStatusInfo } from "../models/staging/LoadDataModel";

import { ApiBackendService } from "./api-backend.service";
import { TaskJobModel } from "../models/api/models/staging/TaskJobModel";
import { LoadToPsaArgs } from "../views/datasources/dialogs/create-datasource-dialog-new/create-datasource-dialog-new.component";
import { CreatePsaArg } from "../models/api/controllers/api/CreatePsaArg";
import { CreatePsaResult } from "../models/api/models/staging/CreatePsaResult";

@Injectable({
	providedIn: "root",
})
export class DatasourcesService {

	// NEW PROTOTYPING ----------------- GENERIC OBJECT EMITTER
	@Output() selectedObjectEmitter = new EventEmitter<any>()
	emitSelectedObject(obj: any) {
		this.selectedObjectEmitter.emit(obj);
	}
	emitUnselectedObject(obj: any) {
		this.selectedDatasourceEmitter.emit(undefined);
	}

	@Output() selectedDatasourceEmitter = new EventEmitter<dss.DataSource>();
	@Output() selectedMenuDatasourceEmitter = new EventEmitter<dss.DataSource>();
	@Output() selectedDataSetEmitter = new EventEmitter<DataSet>();

	@Output() displayCreateDatasource = new EventEmitter<boolean>();
	@Output() displayEditDatasource = new EventEmitter<[dss.DataSource, boolean]>();
	@Output() displayDeleteDatasource = new EventEmitter<[dss.DataSource, boolean]>();
	@Output() displayChangeWriteMode = new EventEmitter<[dss.DataSource, boolean]>();
	@Output() displayDeleteDataPackage = new EventEmitter<[dss.DataSource, boolean]>();
	@Output() displayLoadToPsa = new EventEmitter<boolean>();
	@Output() displayCreateDataSet = new EventEmitter<[string, DataMarketAccess]>();
	@Output() displayPsaDialog = new EventEmitter<boolean>();
	@Output() displayConnectorSettingsDialogEmitter = new EventEmitter<[dss.DataSource, boolean]>();
	@Output() displayPushNewDataDialogEmitter = new EventEmitter<[dss.DataSource, boolean]>();


	@Output() loadToPSAEmitter = new EventEmitter<LoadToPsaArgs>();


	@Output() displayExportDatasource = new EventEmitter<boolean>();
	@Output() displayPullDatasource = new EventEmitter<[dss.DataSource, boolean]>();


	//@Output() newDatasourceEmitter = new EventEmitter<dss.DataSource>();
	//@Output() deletedDataSourceEmitter = new EventEmitter<number>();
	//@Output() updatedDataSourceEmitter = new EventEmitter<dss.DataSource>();

	@Output() datasourceChangedEmitter = new EventEmitter<dss.DataSource>();
	@Output() changedObjectLoadingEmitter = new EventEmitter<boolean>();


	//@Output() changedPsaInfoEmitter = new EventEmitter<dss.PersistentStagingArea>();
	//@Output() deletedDataPackageEmitter = new EventEmitter<dss.DataPackage>();
	@Output() currentPsaInfoEmitter =
		new EventEmitter<dss.PersistentStagingArea>();
	//@Output() exportDatasourceEmitter = new EventEmitter<>();

	constructor(public api: ApiBackendService, private http: HttpClient) { }
	// Dialog --------------------------------------------
	displayCreateDatasourceDialog(evt: boolean) {
		this.displayCreateDatasource.emit(evt);

		return evt;
	}
	displayChangeConnectorSettingsDialog(evt: [dss.DataSource, boolean]) {
		this.displayConnectorSettingsDialogEmitter.emit(evt);

		return evt;
	}
	displayPushNewDataDialog(evt: [dss.DataSource, boolean]) {
		this.displayPushNewDataDialogEmitter.emit(evt);

		return evt;
	}
	displayEditDatasourceDialog(evt: [dss.DataSource, boolean]) {
		this.displayEditDatasource.emit(evt);
		return evt;
	}
	displayDeleteDatasourceDialog(evt: [dss.DataSource, boolean]) {
		this.displayDeleteDatasource.emit(evt);
		return evt;
	}
	displayChangeWriteModeDialog(evt: [dss.DataSource, boolean]) {
		this.displayChangeWriteMode.emit(evt);
		return evt;
	}
	displayDeleteDataPackageDialog(evt: [dss.DataSource, boolean]) {
		this.displayDeleteDataPackage.emit(evt);
		return evt;
	}
	displayLoadToPsaDialog(evt: boolean) {
		this.displayLoadToPsa.emit(evt);
		return evt;
	}
	displayExportDatasourceDialog(evt: boolean) {
		this.displayExportDatasource.emit(evt);
		return evt;
	}
	displayPullDatasourceDialog(evt: [dss.DataSource, boolean]) {
		this.displayPullDatasource.emit(evt);
		return evt;
	}

	// Datasource --------------------------------------------

	getDatasources(
		id?: number,
		sourceSystem?: number
	): Observable<dss.DataSource[]> {
		return this.api.getDataSources(id, sourceSystem);
	}

	createDataSource(arg: dss.DataSource): Observable<dss.DataSource> {
		return this.api.createDataSource(arg);
	}
	deleteDataSource(id: number): Observable<HttpEvent<number>> {
		return this.api.deleteDataSource(id);
	}
	updateDataSource(arg: dss.DataSource): Observable<dss.DataSource> {
		return this.api.updateDataSource(arg);
	}

	emitSelectedDatasource(ds?: dss.DataSource) {
		this.selectedDatasourceEmitter.emit(ds);
	}
	emitUnselectedDatasource(ds: dss.DataSource) {
		this.selectedDatasourceEmitter.emit(undefined);
	}


	// emitNewDatasource(ds: dss.DataSource) {
	// 	this.newDatasourceEmitter.emit(ds);
	// }
	// emitDeletedDatasource(id: number) {
	// 	this.deletedDataSourceEmitter.emit(id);
	// }
	// emitUpdatedDatasource(ds: dss.DataSource) {
	// 	this.updatedDataSourceEmitter.emit(ds);
	// }
	// emitDataSourceChanged() {
	// 	this.datasourceChangedEmitter.emit("Changed");
	// }
	emitDataSourceLoading(loading: boolean) {
		this.changedObjectLoadingEmitter.emit(loading);
	}
	emitCurrentPsaInfo(evt: dss.PersistentStagingArea) {
		this.currentPsaInfoEmitter.emit(evt);
	}
	// emitChangedPsaInfo(psa: dss.PersistentStagingArea) {
	// 	this.changedPsaInfoEmitter.emit(psa);
	// }
	// emitDeletedDataPackage(dp: dss.DataPackage) {
	// 	this.deletedDataPackageEmitter.emit(dp);
	// }

	// Datasource Fields -----------------------------------
	createDataSourceFields(
		arg: dss.CreateDataSourceFieldsArg
	): Observable<dss.DataSourceField[]> {
		return this.api.api_createDataSourceFields(arg);
	}

	// Export Formats --------------------------------------
	public getFormats(): Observable<ExportFormatInfo<any>[]> {
		return this.api.getFormats();
	}
	public getDataProviders(): Observable<DataProviderInfo<DataProviderIdentifier>[]> {
		return this.api.getDataProviders();
	}
	public exportDataProvider<T extends DataProviderIdentifier>(arg: ExportDataConfig<T, any>): Observable<ExportResult> {
		return this.api.exportDataProvider(arg);
	}
	public exportDataProviderStream<T extends DataProviderIdentifier>(arg: ExportDataConfig<T, any>): Observable<Array<number>> {
		return this.api.exportDataProviderStream(arg);
	}
	public exportDataProvChunkedStream<T extends DataProviderIdentifier>(arg: ExportDataConfig<T, any>) {
		return this.api.exportDataProvChunkedStream(arg);
	}

	public exportDataProvChunkedStreamBody<T extends DataProviderIdentifier>(arg: ExportDataConfig<T, any>) {
		return this.api.exportDataProvChunkedStreamBody(arg);
	}

	// Connectors --------------------------------------------
	getConnectors(): Observable<dss.DataSourceAdapter[]> {
		return this.api.getConnectors();
	}
	extractFromConnector(arg: dss.ExtractFromConnectorArg) {
		return this.api.extractFromConnector(arg);
	}

	public getConnectorsInfo(id?: number): Observable<dss.DataSourceConnector<any>[]> {
		return this.api.getConnectorsInfo(id);
	}
	public getAdapterTypeInfo(
		id?: number,
		name?: string
	): Observable<AdapterTypeInfo<any, any>[]> {
		return this.api.getAdapterTypeInfo(id, name);
	}

	createConnectorInfo(
		arg: dss.CreateDataSourceConnectorArg
	): Observable<number> {
		return this.createConnectorInfo(arg);
	}

	// PSA --------------------------------------------

	api_createPersistentStagingArea(
		arg: CreatePsaArg
	): Observable<CreatePsaResult> {
		return this.api.api_createPersistentStagingArea(arg);
	}
	extractToPsa(
		arg: dss.ExtractDataToPsaArg
	): Observable<dss.ExtractDataToPsaResult> {
		return this.api.ExtractDataToPsaNew(arg);
	}

	// Async function
	extractToPsaAsync(arg: dss.ExtractDataToPsaArg): Observable<TaskJobModel.JobRequestResult> {
		return this.api.ExtractDataToPsa_async(arg);
	}
	extractDataToPsaStatus(id: string): Observable<TaskJobModel.JobStatusInfo<any>> {
		return this.api.ExtractDataToPsa_status(id);
	}
	public extractDataToPsaResult(id: String): Observable<dss.ExtractDataToPsaResult> {
		return this.api.ExtractDataToPsa_result(id);
	}
	extractDataToPsaJobsStatus(): Observable<TaskJobModel.JobStatusInfo<any>[]> {
		return this.api.ExtractDataToPsa_jobs_status();
	}

	// Stream function
	public ExtractDataToPsaStream_async(arg: ExtractDataToPsaStream.Argument): Observable<TaskJobModel.JobRequestResult> {
		return this.api.ExtractDataToPsaStream_async(arg);
	}

	public ExtractDataToPsaStream_status(id: string): Observable<TaskJobModel.JobStatusInfo<any>> {
		return this.api.ExtractDataToPsaStream_status(id);
	}

	public ExtractDataToPsaStream_jobs_status(): Observable<TaskJobModel.JobStatusInfo<any>[]> {
		return this.api.ExtractDataToPsaStream_jobs_status();
	}

	public ExtractDataToPsaStream_result(id: String): Observable<ExtractDataToPsaStream.Result> {
		return this.api.ExtractDataToPsaStream_result(id);
	}

	updatePsa(psa: dss.PersistentStagingArea): Observable<number> {
		return this.api.updatePsa(psa);
	}
	getPsa(id?: number): Observable<dss.PersistentStagingArea[]> {
		return this.api.getPsa(id);
	}
	getPsaOfDataSource(dataSource: number): Observable<dss.PersistentStagingArea> {
		return this.api.getPsaOfDataSource(dataSource);
	}

	// Data Packages -----------------------------------------
	getDataPackage(id: number): Observable<dss.DataPackage> {
		return this.api.getDataPackage(id);
	}
	deleteDataPackage(id: number) {
		return this.api.deleteDataPackage(id);
	}

	// WriteMode --------------------------------------------
	getWriteModes(): WriteMode[] {
		return [
			{
				name: "Append",
				value: "append",
				description:
					" The Append Mode adds new data to the existing PSA without changing old data. This method is suitable for small data packages that only consists of new data (e.g. monthly data update for January, February Sales Data)",
			},
			{
				name: "Replace",
				value: "replace",
				description:
					" The Replace Mode will overwrite all existing data with new data. This method is suitable for e.g. master data.",
			},
			{
				name: "Update",
				value: "update",
				description:
					" The Update Mode will only update data which has been changed compared to the previous state. Old data will be overwritten.",
			},
			{
				name: "Delta",
				value: "delta",
				description:
					" The Delta Mode will only add changed data to the existing PSA. Old data will not be overwritten and remain in the PSA.",
			},
		];
	}

	// DataTypes -------------------------
	getDataTypes(): Observable<dss.DataType[]> {
		return this.api.getDataTypes();
	}

	// DataMarket --------------------------------------------
	getDataMarketRepository(): Observable<DataSet[]> {
		return this.api.getDataMarketRepository();
	}
}
