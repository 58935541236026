import { EngineInfo } from "./EngineInfo";
import { NodeMetaData } from "./NodeMetaData";

export class GuiSettings {
    X: number;
    Y: number;
    Width: number;
    Height: number;
    constructor(X: number, Y: number, Width: number, Height: number) {
        if (X === undefined) throw new Error("Class 'GuiSettings': Required value 'X' is undefined");
        if (Y === undefined) throw new Error("Class 'GuiSettings': Required value 'Y' is undefined");
        if (Width === undefined) throw new Error("Class 'GuiSettings': Required value 'Width' is undefined");
        if (Height === undefined) throw new Error("Class 'GuiSettings': Required value 'Height' is undefined");
        this.X = X;
        this.Y = Y;
        this.Width = Width;
        this.Height = Height;
    }
}
export interface WorkflowNodeSettings {

}
export class Annotation {
    Text: string;
    constructor(Text: string) {
        if (Text === undefined) throw new Error("Class 'Annotation': Required value 'Text' is undefined");
        this.Text = Text;
    }
}

export class NodeProperties {
    Configuration: WorkflowNodeSettings;
    Annotation?: Annotation;
    MetaInfo: Map<string, Array<NodeMetaData>>;
    constructor(Configuration: WorkflowNodeSettings, MetaInfo: Map<string, Array<NodeMetaData>>, Annotation?: Annotation) {
        if (Configuration === undefined) throw new Error("Class 'NodeProperties': Required value 'Configuration' is undefined");
        if (MetaInfo === undefined) throw new Error("Class 'NodeProperties': Required value 'MetaInfo' is undefined");
        this.Configuration = Configuration;
        this.Annotation = Annotation;
        this.MetaInfo = MetaInfo;
    }
}



export class WorkflowNode {
    ID: string;
    GuiSettings: GuiSettings;
    Properties: NodeProperties;
    Engine: EngineInfo;
    Name?: string;
    constructor(ID: string, GuiSettings: GuiSettings, Properties: NodeProperties, Engine: EngineInfo, Name?: string) {
        if (ID === undefined) throw new Error("Class 'WorkflowNode': Required value 'ID' is undefined");
        if (GuiSettings === undefined) throw new Error("Class 'WorkflowNode': Required value 'GuiSettings' is undefined");
        if (Properties === undefined) throw new Error("Class 'WorkflowNode': Required value 'Properties' is undefined");
        if (Engine === undefined) throw new Error("Class 'WorkflowNode': Required value 'Engine' is undefined");
        this.ID = ID;
        this.GuiSettings = GuiSettings;
        this.Properties = Properties;
        this.Engine = Engine;
        this.Name = Name;
    }
}

export class WorkflowEdge {
    SourceNode: string;
    SourcePort: string;
    TargetNode: string;
    TargetPort: string;
    Name?: string;
    constructor(SourceNode: string, SourcePort: string, TargetNode: string, TargetPort: string, Name?: string) {
        if (SourceNode === undefined) throw new Error("Class 'WorkflowEdge': Required value 'SourceNode' is undefined");
        if (SourcePort === undefined) throw new Error("Class 'WorkflowEdge': Required value 'SourcePort' is undefined");
        if (TargetNode === undefined) throw new Error("Class 'WorkflowEdge': Required value 'TargetNode' is undefined");
        if (TargetPort === undefined) throw new Error("Class 'WorkflowEdge': Required value 'TargetPort' is undefined");
        this.SourceNode = SourceNode;
        this.SourcePort = SourcePort;
        this.TargetNode = TargetNode;
        this.TargetPort = TargetPort;
        this.Name = Name;
    }
}
export class Workflow {
    Name: string;
    Nodes: Array<WorkflowNode>;
    Edges: Array<WorkflowEdge>;
    Context: string;
    constructor(Name: string, Nodes: Array<WorkflowNode>, Edges: Array<WorkflowEdge>, Context: string) {
        if (Name === undefined) throw new Error("Class 'Workflow': Required value 'Name' is undefined");
        if (Nodes === undefined) throw new Error("Class 'Workflow': Required value 'Nodes' is undefined");
        if (Edges === undefined) throw new Error("Class 'Workflow': Required value 'Edges' is undefined");
        if (Context === undefined) throw new Error("Class 'Workflow': Required value 'Context' is undefined");
        this.Name = Name;
        this.Nodes = Nodes;
        this.Edges = Edges;
        this.Context = Context;
    }
}

export class DataType {
    Name: string;
    Length: number;
    Format: string;
    FixedSize: boolean;
    Precision: number;
    constructor(Name: string,Length: number,Format: string,FixedSize: boolean,Precision: number) {
        if(Name === undefined) throw new Error( "Class 'DataType': Required value 'Name' is undefined" );
        if(Length === undefined) throw new Error( "Class 'DataType': Required value 'Length' is undefined" );
        if(Format === undefined) throw new Error( "Class 'DataType': Required value 'Format' is undefined" );
        if(FixedSize === undefined) throw new Error( "Class 'DataType': Required value 'FixedSize' is undefined" );
        if(Precision === undefined) throw new Error( "Class 'DataType': Required value 'Precision' is undefined" );
        this.Name = Name;
        this.Length = Length;
        this.Format = Format;
        this.FixedSize = FixedSize;
        this.Precision = Precision;
    }
}

export class FieldInfo {
    Name: string;
    DataType: DataType;
    IsKey: boolean;
    constructor(Name: string,DataType: DataType,IsKey: boolean) {
        if(Name === undefined) throw new Error( "Class 'FieldInfo': Required value 'Name' is undefined" );
        if(DataType === undefined) throw new Error( "Class 'FieldInfo': Required value 'DataType' is undefined" );
        if(IsKey === undefined) throw new Error( "Class 'FieldInfo': Required value 'IsKey' is undefined" );
        this.Name = Name;
        this.DataType = DataType;
        this.IsKey = IsKey;
    }
}