import { EventEmitter, Injectable, Output } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SubscriptionInfo } from "../models/api/controllers/license/SubscriptionInfo";
import { RoleMembershipRow, ViewInfoRow } from "../models/api/domain/NewDataSourceModelBase";
import { GroupRole } from "../models/api/models/authorization/GroupRoleLike";
import { UserRole } from "../models/api/models/authorization/UserRoleLIke";
import { ViewPermission } from "../models/api/models/authorization/ViewPermission";
import { DataStorePermission } from "../models/api/models/staging/DataStorePermission";
import { PlayWorkflowPermission } from "../models/api/models/workflow/PlayWorkflowPermission";
import { WorkflowPermission } from "../models/api/models/workflow/WorkflowPermission";
import {
	GroupRoleView,
	UserRoleView,
} from "../models/auth-service-decorator.model";
import { Permission } from "../models/authorization.model";
// import {
// 	GroupRole,
// 	Permission,
// 	RoleMembership,
// 	UserRole,
// 	ViewInfoRow,
//     ViewPermission,
// } from "../models/authorization.model";
import {
	DataSourcePermission,

} from "../models/datasource.model";
// import { SubscriptionInfo } from "../models/license.model";
import { ActionPlanPermission } from "../models/schedule.model";
// import {
// 	PlayWorkflowPermission,
// 	WorkflowPermission,
// } from "../models/workflow.model";
import { ApiBackendService } from "./api-backend.service";

@Injectable({
	providedIn: "root",
})
export class AuthorizationService {
	constructor(private api: ApiBackendService) {}

	// Subscription & Client-------------------------------------

	public getSubscriptionInfo(): Observable<SubscriptionInfo[]> {
		return this.api.getSubscriptionInfo();
	}

	// public getStribeSubscriptionInfo(): Observable<SubscriptionInfo[]> {
	// 	return this.api.getStribeSubscriptionInfo();
	// }

	// GENERIC ROLE-----------------------------------------------
	deleteRole(id: number): Observable<number> {
		return this.api.deleteRole(id);
	}

	// USER ROLE---------------------------------------------------
	@Output() newUserRoleEmitter = new EventEmitter<UserRole>();
	@Output() updatedUserRoleEmitter = new EventEmitter<UserRole>();
	@Output() deletedUserRoleEmitter = new EventEmitter<UserRole>();
	// NEW
	@Output() userRolesChangedEmitter = new EventEmitter<UserRole>();

	public getUserRoles(id?: number, userId?: number): Observable<UserRole[]> {
		return this.api.getUserRoles(id);
	}
	public createUserRole(role: UserRole): Observable<UserRole> {
		return this.api.createUserRole(role);
	}
	public updateUserRole(role: UserRole): Observable<number> {
		return this.api.updateUserRole(role);
	}
	public deleteUserRole(id?: number): Observable<number> {
		return this.api.deleteUserRole(id);
	}

	// GROUP ROLE-----------------------------------------------------
	@Output() newGroupRoleEmitter = new EventEmitter<GroupRole>();
	@Output() updatedGroupRoleEmitter = new EventEmitter<GroupRole>();
	@Output() deletedGroupRoleEmitter = new EventEmitter<GroupRole>();

	@Output() groupRolesChangedEmitter = new EventEmitter<GroupRole>();

	public getGroupRoles(id?: number): Observable<GroupRole[]> {
		return this.api.getGroupRoles(id);
	}
	public createGroupRole(role: GroupRole): Observable<GroupRole> {
		return this.api.createGroupRole(role);
	}
	public updateGroupRole(role: GroupRole): Observable<number> {
		return this.api.updateGroupRole(role);
	}
	public deleteGroupRole(id?: number): Observable<number> {
		return this.api.deleteGroupRole(id);
	}

	// USER MEMBERSHIP-------------------------------------------------
	public getRoleMembership(childRole?: number): Observable<RoleMembershipRow[]> {
		return this.api.getRoleMembership(childRole);
	}
	public createRoleMembership(
		item: RoleMembershipRow
	): Observable<RoleMembershipRow> {
		return this.api.createRoleMembership(item);
	}
	public updateRoleMembership(item: RoleMembershipRow): Observable<number> {
		return this.api.updateRoleMembership(item);
	}
	public deleteRoleMembership(childRole: number): Observable<number> {
		return this.api.deleteRoleMembership(childRole);
	}

	public createRoleMembershipUtil(items: RoleMembershipRow[]) {
		return this.api.createRoleMembershipUtil(items);
	}

	// VIEWS
	public getViewInfoRow(name?: string): Observable<ViewInfoRow[]> {
		return this.api.getViewInfoRow(name);
	}
    // = View Permissions
	public getViewPermission(id?:number,name?:string): Observable<ViewPermission[]> {
        return this.api.getViewPermission(id,name);
	}
	public createViewPermission(item: ViewPermission): Observable<ViewPermission> {
        return this.api.createViewPermission(item);
	}
	public updateViewPermission(item: ViewPermission): Observable<number> {
        return this.api.updateViewPermission(item);
	}
	public deleteViewPermission(id:number): Observable<number> {
        return this.api.deleteViewPermission(id);
	}

	// PERMISSIONS-----------------------------------------------------

	@Output() permissionsChangedEmitter = new EventEmitter<Permission>();

	public getPermissions(name?: string): Observable<Permission[]> {
		return this.api.getPermissions(name);
	}

	public permissionsChanged(permission: Permission) {
		this.permissionsChangedEmitter.emit(permission);
	}

	// Workflow Permissions
	public getWorkflowAuthPermissions(
		id?: number,
		workflow?: number
	): Observable<WorkflowPermission[]> {
		return this.api.getWorkflowAuthPermissions(id, workflow);
	}
	public getWorkflowAuthPlayPermissions(
		id?: number,
		workflow?: number
	): Observable<PlayWorkflowPermission[]> {
		return this.api.getWorkflowAuthPlayPermissions(id, workflow);
	}
	public createWorkflowPermission(
		item: WorkflowPermission
	): Observable<WorkflowPermission> {
		return this.api.createWorkflowPermission(item);
	}
	public updateWorkflowPermission(
		item: WorkflowPermission
	): Observable<number> {
		return this.api.updateWorkflowPermission(item);
	}
	public deleteWorkflowPermission(id: number): Observable<number> {
		return this.api.deleteWorkflowPermission(id);
	}

	public getWorkflowRights(): Observable<string[]> {
		return this.api.getWorkflowRights();
	}

	// DataSource Permissions
	public getDataSourcePermissions(
		id?: number,
		ds?: number
	): Observable<DataSourcePermission[]> {
		return this.api.getDataSourcePermission(id, ds);
	}
	public createDataSourcePermission(
		item: DataSourcePermission
	): Observable<DataSourcePermission> {
		return this.api.createDataSourcePermission(item);
	}
	public updateDataSourcePermission(
		item: DataSourcePermission
	): Observable<number> {
		return this.api.updateDataSourcePermission(item);
	}
	public deleteDataSourcePermission(id: number): Observable<number> {
		return this.api.deleteDataSourcePermission(id);
	}

	// Schedule Action Plan Permissions
	public getActionPlanPermissions(
		id?: number,
		planId?: number
	): Observable<ActionPlanPermission[]> {
		return this.api.getActionPlanPermission(id, planId);
	}
	public createActionPlanPermission(
		item: ActionPlanPermission
	): Observable<ActionPlanPermission> {
		return this.api.createActionPlanPermission(item);
	}
	public updateActionPlanPermission(
		item: ActionPlanPermission
	): Observable<number> {
		return this.api.updateActionPlanPermission(item);
	}
	public deleteActionPlanPermission(id: number): Observable<number> {
		return this.api.deleteActionPlanPermission(id);
	}

	//Destination Permissions
	public getDataStorePermissions(
		id?: number,
		planId?: number
	): Observable<DataStorePermission[]> {
		return this.api.getDataStorePermission(id, planId);
	}
	public createDataStorePermission(
		item: DataStorePermission
	): Observable<DataStorePermission> {
		return this.api.createDataStorePermission(item);
	}
	public updateDataStorePermission(
		item: DataStorePermission
	): Observable<number> {
		return this.api.updateDataStorePermission(item);
	}
	public deleteDataStorePermission(id: number): Observable<number> {
		return this.api.deleteDataStorePermission(id);
	}










	// DECORATORS ------------------------------------------------------
	//
	// Nice API for use in Views :-)

	// User Role Views
	public getUserRoleViews(): Observable<UserRoleView[]> {
		let userRolesObs = this.getUserRoles();
		let usersObs = this.api.getUserCommon();

		const join_result = forkJoin(userRolesObs, usersObs).pipe(
			map((rolesResult) => {
				const user_roles = rolesResult[0];
				const users = rolesResult[1];

				return user_roles.map((user_role) => {
					const user_info = users.find((user) => {
						return user.ID === user_role.UserID;
					});

					return new UserRoleView(user_role, user_info);
				});
			})
		);

		return join_result;
	}

	// Group Role Views
	public getGroupRoleViews(): Observable<GroupRoleView[]> {
		let groupRolesObs = this.getGroupRoles();

		return groupRolesObs.pipe(
			map((group_roles) => {
				return group_roles.map((group_role) => {
					return new GroupRoleView(group_role);
				});
			})
		);
	}
}
