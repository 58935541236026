import { Component, Input, OnInit, SimpleChanges } from '@angular/core';


export class PieChartData {

}
@Component({
  selector: 'app-kpi-indicator',
  templateUrl: './kpi-indicator.component.html',
  styleUrls: ['./kpi-indicator.component.scss']
})
export class KpiIndicatorComponent implements OnInit {
  pieOptions: any;
  pieData: any;
  @Input() percentage: number;

  constructor() { }

  	ngOnChanges(changes: SimpleChanges) {
		if (changes.percentage.currentValue) {
      this.initChartData();
    }
  }
  ngOnInit(): void {
    this.pieOptions = {
      plugins: {
        legend: {
          display: false,
          position: 'left',
          labels: {
            fontColor: "#A0A7B5",
          },
        },
      },
    };
    this.initChartData()

  }

  initChartData() {
    // Initialize pieChart data
    const percentage = this.percentage;
    const remain = 100 - percentage;
    this.pieData = {
      labels: ["Remaining", "Automated"],
      datasets: [
        {
          data: [remain, percentage],
          backgroundColor: [
            "#f2f9fd",
            "#9bdca0"
          ],
        },
      ],
    };
  }
}
