import { Component, OnInit, ViewChild } from '@angular/core';
import { SubSink } from 'subsink';
import * as dss from 'src/app/models/datasource.model';
import { UserDetailsRow } from 'src/app/models/user.model';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DatasourcesService } from 'src/app/services/datasources.service';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { UtilFunctionsService } from 'src/app/services/util-functions.service';
import { UserService } from 'src/app/services/user.service';
import { ObjectSearchService } from 'src/app/services/object-search.service';
import { IntegratedSourceModel } from 'src/app/models/api/models/IntegratedSourceModel';
import { IntegratedSourceService } from 'src/app/services/integrated-source.service';
import { GenericTopToolbarComponent } from 'src/app/views/objectManagement/generic-top-toolbar/generic-top-toolbar.component';
import { GeneralObjectViewComponent } from 'src/app/views/objectManagement/general-object-view/general-object-view.component';

export class IntDataSourceViewRecord {

}

export class IntDataSourceToolbar {

}

@Component({
  selector: 'app-ab-ds-view',
  templateUrl: './ab-ds-view.component.html',
  styleUrls: ['./ab-ds-view.component.scss'],
  animations: [trigger("fade", [
    state("void", style({ opacity: 0 })),
    transition(":enter", [animate(500)]),
    transition(":leave", [animate(500)]),
    ]),
    trigger('slideInOut', [
        transition(':enter', [
          style({transform: 'translateY(-100%)'}),
          animate('200ms ease-in', style({transform: 'translateY(0%)'}))
        ]),
        transition(':leave', [
          animate('200ms ease-in', style({transform: 'translateY(-100%)'}))
        ])
      ])
    ]
})

export class AbDsViewComponent implements OnInit {
  private subs = new SubSink();
  loading: boolean = false;
  userDetails: UserDetailsRow[] = [];

  datasources: IntegratedSourceModel.DataSource<number>[];
  selectedDatasource?: IntegratedSourceModel.DataSource<number>;

  intDataSourceRecordTypeClass: IntDataSourceViewRecord = new IntDataSourceViewRecord();
  dataSourceToolbarTypeClass: IntDataSourceToolbar = new IntDataSourceToolbar();

  @ViewChild("dsObjectToolbar") dsobjectToolbar!: GenericTopToolbarComponent<IntegratedSourceModel.DataSource<number>>;
  @ViewChild("dsObjectView") dsobjectView!: GeneralObjectViewComponent<IntegratedSourceModel.DataSource<number>, IntDataSourceViewRecord, any, any>;

  constructor(public intdatasourceService: IntegratedSourceService, public systemLogService: SystemMessageLogService, private utilService: UtilFunctionsService, private userService: UserService, private objectSearchService: ObjectSearchService) { }

  ngOnInit(): void {

  }

}
