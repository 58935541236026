import {
	Component,
	Input,
	OnDestroy,
	OnInit,
	SimpleChanges,
} from "@angular/core";
import { ChartType, TooltipItem } from "chart.js";
import { WorkflowNode } from "src/app/models/api/com/bion/etl/Workflow";
import { WorkflowRepositoryEntry } from "src/app/models/api/models/workflow/WorkflowRepositoryEntry";
import { WorkflowNodeGuiInfo } from "src/app/models/designer.models";
// import {
// 	WorkflowNode,
// 	WorkflowRepositoryEntry,
// } from "src/app/models/workflow.model";
import { WorkflowsService } from "src/app/services/workflows.service";
import { SubSink } from "subsink";

export class PlugInChart {
	name: string;
	count: number;
	progress: number;
	data?: WorkflowNodeGuiInfo;

	constructor(name: string, count: number, progress: number, data?: WorkflowNodeGuiInfo) {
		this.name = name;
		this.count = count;
		this.progress = progress;
		this.data = data;
	}
}

@Component({
	selector: "app-workflow-charts",
	templateUrl: "./workflow-charts.component.html",
	styleUrls: ["./workflow-charts.component.scss"],
	styles: [
		`
			:host ::ng-deep .p-datatable-gridlines p-progressBar {
				width: 100%;
			}

			@media screen and (max-width: 960px) {
				:host
					::ng-deep
					.p-datatable.p-datatable-customers.rowexpand-table
					.p-datatable-tbody
					> tr
					> td:nth-child(6) {
					display: flex;
				}
			}
		`,
	],
})
export class WorkflowChartsComponent implements OnInit, OnDestroy {
	subs = new SubSink();

	@Input() plugInInfos: WorkflowNodeGuiInfo[] = [];

	inputNodes: WorkflowNode[] = [];
	transformationNodes: WorkflowNode[] = [];
	outputNodes: WorkflowNode[] = [];
	@Input() workflows: WorkflowRepositoryEntry[] = [];
	selectedWorkflow?: WorkflowRepositoryEntry;
	isReset: boolean = false;

	nodesCountFilteredList: PlugInChart[] = [];
	nodesCountList: PlugInChart[] = [];

	categoryArray:number[] = [];
	categoryArrayFiltered: number[] = [];

	categorylabels: string[];
	barData: any;
	barOptions: any;

	constructor(private workflowService: WorkflowsService) {
		this.categorylabels = [
			"In/Out",
			"Aggregation",
			"Transformation",
			"Format",
			"Enrichment",
			"Validation",
		];
	}
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}
	ngOnChanges(changes: SimpleChanges) {
		if (changes.workflows !== undefined) {
			if (changes.workflows.currentValue) {
				// Change detection if changes occured
				let wf: WorkflowRepositoryEntry[] = changes.workflows.currentValue;
				if (wf !== undefined) {
					this.createChart(false, wf);
				}
			}
		}
	}
	ngOnInit(): void {
		this.barOptions = {
			plugins: {
				// legend: {
				// 	display: false,
				// 	labels: {
				// 		fontColor: "#A0A7B5",
				// 	},
				// },

				legend: {
					display: false
				},
				tooltips: {
					callbacks: {
						label: function (tooltipItem: any) {
							return tooltipItem.yLabel;
						}
					}
				}
			},
			scales: {
				x: {
					display: false,
					grid: {
						color: "transparent",
					},
					ticks: {
						color: "#BFC2C6",
						display: false,
					},
				},
				y: {
					display: false,
					grid: {
						color: "rgba(191, 194, 198, .45)",
						borderDash: [5, 10],
					},
					ticks: {
						color: "#BFC2C6",
						min: 0,
						stepSize: 5,
					},
				},
			},
		};

		this.subs.sink = this.workflowService.selectedWorkflowEmitter.subscribe(
			(wf: WorkflowRepositoryEntry) => {
				if (wf === this.selectedWorkflow) {
					this.selectedWorkflow = undefined;
					this.createChart(false, this.workflows);
				} else {
					this.selectedWorkflow = wf;
					this.createChart(false, [wf]);
				}
			}
		);
	}

	createChart(isReset: boolean, wfEntries: WorkflowRepositoryEntry[]) {

		// Go through each workflow node
		let engineClassNames: string[] = [];
		for (let entry of wfEntries) {
			entry.workflowData.Nodes.map(node => engineClassNames.push(node.Engine.ClassName));
		}

		// Create unique Nodes entries (e.g. Select, Transpose)
		let engineClassNamesSet = new Set(engineClassNames);

		//Create Plugin Chart Data
		let nodesArray: PlugInChart[] = [];

		for (let className of engineClassNamesSet) {
			// Iterate through list to get counts of each engineInfo
			let counter = 0;
			for (let i of engineClassNames) {
				if (i === className) {
					counter++;
				}
			}

			//Init new component class
			const progress = (counter / engineClassNames.length) * 100;
			const plug_in_lookup = this.plugInInfos.find((i: WorkflowNodeGuiInfo) => {
				//const nameString = i.Name;
				//let nameStringParse = nameString.split(".")[2];
				
				return className === i.Engine.ClassName;
			});

			//Push new entry to final Array
			if (plug_in_lookup) {
				const newEntry = new PlugInChart(plug_in_lookup.NameLabel, counter, parseInt(progress.toFixed(2)), plug_in_lookup);
				nodesArray.push(newEntry);
			}

		}
		if (isReset) {
			this.nodesCountList = nodesArray;
		}
		this.nodesCountFilteredList = nodesArray.sort((a, b) => b.count - a.count);

		// Create Category Data
		let categoryDataArray: PlugInChart[] = [];

		nodesArray.map((entry: PlugInChart) => {
			// let chartInfo = new PlugInChart();
			// chartInfo.name = entry.data.Category;
			// chartInfo.count = entry.count;
			// chartInfo.progress = entry.progress;
			const category = entry.data?.Category ? entry.data.Category : "UNKNOWN";
			const chartInfo = new PlugInChart(category, entry.count, entry.progress);
			categoryDataArray.push(chartInfo);
		});

		let categoryArrayFiltered: PlugInChart[] = [];
		for (let i of this.categorylabels) {
			//let chart = new PlugInChart();
			// chart.name = i;
			let counter: number = 0;
			let progress: number = 0;

			for (let j of categoryDataArray) {
				if (i === j.name) {
					counter = counter + j.count;
					progress = progress + j.progress;
				}
			}
			// chart.count = counter;
			// chart.progress = counter;
			const chart = new PlugInChart(i, counter, counter);
			categoryArrayFiltered.push(chart);
		}

		this.createBarChart(isReset, categoryArrayFiltered);
	}

	createBarChart(isReset: boolean, charts: PlugInChart[]) {
		let lineData: number[] = [];
		let barData: number[] = [];

		charts.map((val: PlugInChart) => {
			barData.push(val.count);
			lineData.push(val.progress);
		});

		if (isReset) {
			this.categoryArray = barData;
		}
		this.categoryArrayFiltered = barData;

		this.barData = {
			labels: this.categorylabels,
			datasets: [
				{
					type: "bar",
					label: "Amount",
					backgroundColor: [
						"rgb(242, 249, 253)",
						"rgb(205, 234, 250)",
						"rgb(205, 234, 250)",
						"rgb(205, 234, 250)",
						"rgb(205, 234, 250)",
						"rgb(205, 234, 250)",
						"rgb(118, 189, 220)",
					],
					data: barData,
					borderColor: "white",
					borderWidth: 2,
				},
			],
		};
	}

	clearCharts() {
		this.nodesCountFilteredList = [...this.nodesCountList];
		//this.createBarChart(false, this.categoryArray);
		this.createBarChart(false, this.nodesCountFilteredList);
	}
}
