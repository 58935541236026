<div style="z-index: 1000000;">
    <p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
    </p-toast>
</div>

<!-- <app-create-datasource-dialog [psaInfos]="psaInfos"></app-create-datasource-dialog> -->
<app-edit-datasource-dialog></app-edit-datasource-dialog>
<app-delete-datasource-dialog></app-delete-datasource-dialog>
<app-delete-data-package-dialog (click)="appMain.rightPanelClick = true"></app-delete-data-package-dialog>
<app-change-write-mode-dialog>
</app-change-write-mode-dialog>
<app-add-permission-dialog sender="Data Source" [view]="'DataSourceView'"
    (click)="appMain.rightPanelClick = true"></app-add-permission-dialog>
<app-pull-datasource-dialog></app-pull-datasource-dialog>
<!-- <app-psa-table></app-psa-table> -->
<app-delete-permission-dialog [view]="'DataSourceView'"
    (click)="appMain.rightPanelClick = true"></app-delete-permission-dialog>
<app-change-permission-dialog [view]="'DataSourceView'"
    (click)="appMain.rightPanelClick = true"></app-change-permission-dialog>
<app-create-datasource-dialog-new></app-create-datasource-dialog-new>
<app-load-data-to-psadialog></app-load-data-to-psadialog>
<app-push-new-data-dialog></app-push-new-data-dialog>
<app-change-connector-settings-dialog></app-change-connector-settings-dialog>

<!-- <p-dialog [(visible)]="displayPsa" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '70vw'}">
    <app-psa-table [psaInfos]="psaInfos"></app-psa-table>
</p-dialog> -->

<!-- <p-overlayPanel #op1 [showCloseIcon]="false">
    <ng-template pTemplate>
        <div style="float: left">
            <button pButton pRipple type="button" icon="pi pi-cog" class="p-button-rounded p-button-text p-mr-2 p-mb-2"
                pTooltip="{{'ChangeDatasourceInformation' | translate }}"
                (click)="displayEditDatasourceDialog(true)"></button>
            <button pButton pRipple type="button" icon="pi pi-trash"
                pTooltip="{{'DeleteDatasourcePermanently' | translate }}" (click)="displayDeleteDatasourceDialog(true)"
                class="p-button-rounded p-button-danger p-button-text p-mr-2 p-mb-2"></button>
        </div>

    </ng-template>
</p-overlayPanel> -->
<div class="spinner-container" *ngIf="loading" @fade>
    <app-loading-screen></app-loading-screen>
</div>


<div class="p-grid">
    <!-- <div style="height: 2px; width:100%">
        <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '2px'}"></p-progressBar>
    </div> -->
    <!-- <span class="p-col-12 p-md-12 p-lg-12">
        <span>
            <app-generic-top-toolbar #dsObjectToolbar [typeClass]="dataSourceToolbarTypeClass"
                [currentView]="'Datasources'" @fade>
            </app-generic-top-toolbar>
            <span style="display: flex; align-items: center; float: right">
                <app-general-search #dsObjectSearch [typeClass]="dataSourceSearchTypeClass"
                    [currentView]="'DataSources'">
                </app-general-search>
                <span style="color: white;margin:0px 10px">|</span>
                <span style="display: flex; align-items: center;">
                    <app-generic-object-tasks #dsObjectTasks [currentView]="'DataSources'"></app-generic-object-tasks>
                </span>
            </span>
        </span>
    </span> -->
</div>




<div class="p-grid content-container" id="container" @fade>
    <!-- <div class="p-col-12 WidgetsMenu">
        <span style="float: left">
            <button pButton class="p-button-rounded p-button-text" (click)="toggleSearchMenu()" label="Search" [icon]="displaySearchPanel ? 'pi pi-arrow-right' : 'pi pi-arrow-left'"></button>
        </span>
        <span style="float: right">
            <button pButton class="p-button-rounded p-button-text" (click)="toggleWidgetMenu()" label="Details" [icon]="displayWidgets ? 'pi pi-arrow-right' : 'pi pi-arrow-left'"></button>
        </span>
    </div> -->
    <!-- <div class="p-col-12 p-md-2 p-lg-2" style="padding: 0;margin-top: 20px; margin-left: 20px; height: 100%;" *ngIf="displaySearchPanel">
       <app-general-search #dsObjectSearch [typeClass]="dataSourceSearchTypeClass" [currentView]="'DataSources'"></app-general-search>
    </div> -->
    <div class="p-col" style="padding: 0 20px;margin-top: 20px;">
        <div class="card">
            <!-- <p-tabView>
                <p-tabPanel header="{{'My Datasources' | translate }}">
                </p-tabPanel>
                <p-tabPanel header="{{'Shared With Me' | translate }}">
                </p-tabPanel>
            </p-tabView> -->
            <!-- <app-general-object-view [objectList]="datasources" [userDetails]="userDetails" #dsObjectView
                [typeClass]="dataSourceRecordTypeClass" [currentView]="'Datasources'"></app-general-object-view> -->
        </div>
    </div>

    <!-- <div class="layout-rightpanel" (click)="appMain.rightPanelClick = true">
        <div class="rightpanel-wrapper">
            <p-tabView [scrollable]="false">
                <p-tabPanel header="{{'Activities' | translate }}">
                    <ng-template pTemplate="header">
                        <img class="static" style="width: 30px" pTooltip="{{'Activities' | translate }}"
                            tooltipPosition="top" src="assets/layout/images/animatedIcons/auffuhren.png"
                            alt="freya-layout" />
                        <img class="active" style="width: 30px" pTooltip="{{'Activities' | translate }}"
                            tooltipPosition="top" src="assets/layout/images/animatedIcons/auffuhren.gif"
                            alt="freya-layout" />
                    </ng-template>
                    <app-generic-latest-activities #dsLatestActivities [typeClass]="dataSourceActivityTypeClass"
                        [currentView]="'Datasources'"></app-generic-latest-activities>
                </p-tabPanel>
                <p-tabPanel header="{{'Streams' | translate }}">
                    <ng-template pTemplate="header">
                        <img class="static" style="width: 30px" pTooltip="{{'Data' | translate }}" tooltipPosition="top"
                            src="assets/layout/images/animatedIcons/buch.png" alt="freya-layout" />
                        <img class="active" style="width: 30px" pTooltip="{{'Data' | translate }}" tooltipPosition="top"
                            src="assets/layout/images/animatedIcons/buch.gif" alt="freya-layout" />
                    </ng-template>
                    <app-psa-table></app-psa-table>
                </p-tabPanel>
                <p-tabPanel header="{{'Permissions' | translate }}"
                    *ngIf="appMain.currentUserFull.UserInfo.IsAdmin === true">
                    <ng-template pTemplate="header">
                        <img class="static" style="width: 30px" pTooltip="{{'Permissions' | translate }}"
                            tooltipPosition="top" src="assets/layout/images/animatedIcons/nutzer.png"
                            alt="freya-layout" />
                        <img class="active" style="width: 30px" pTooltip="{{'Permissions' | translate }}"
                            tooltipPosition="top" src="assets/layout/images/animatedIcons/nutzer.gif"
                            alt="freya-layout" />
                    </ng-template>

                    <app-user-permissions-list view="DatasourceView"></app-user-permissions-list>
                </p-tabPanel>
                <p-tabPanel header="{{'Fields' | translate }}">
                    <ng-template pTemplate="header">
                        <img class="static" style="width: 30px" pTooltip="{{'Fields' | translate }}"
                            tooltipPosition="top" src="assets/layout/images/animatedIcons/liste.png"
                            alt="freya-layout" />
                        <img class="active" style="width: 30px" pTooltip="{{'Fields' | translate }}"
                            tooltipPosition="top" src="assets/layout/images/animatedIcons/liste.gif"
                            alt="freya-layout" />
                    </ng-template>
                    <app-generic-field-infos #datasourceFieldInfos [typeClass]="dataSourceFieldInfosClass"
                        [currentView]="'Datasources'"></app-generic-field-infos>
                </p-tabPanel>
                <p-tabPanel header="{{'API' | translate }}">
                    <ng-template pTemplate="header">
                        <img class="static" style="width: 30px" pTooltip="{{'API' | translate }}" tooltipPosition="top"
                            src="assets/layout/images/animatedIcons/mischen.png" alt="freya-layout" />
                        <img class="active" style="width: 30px" pTooltip="{{'API' | translate }}" tooltipPosition="top"
                            src="assets/layout/images/animatedIcons/mischen.gif" alt="freya-layout" />
                    </ng-template>
                    <app-pipeline [extensionUrl]="'dataSource/'"></app-pipeline>
                    <div class="card selected">
                        <div class="p-col-12">
                            <img style="width: 50px" src="assets/layout/images/misc/die-info.png" alt="freya-layout" />
                        </div>
                        <div class="p-col-12">
                            <h5>{{'ApiNotSupportedContactUs.Text' | translate}}</h5>
                            <a class="Link" (click)="onSendRequest('Api')">{{'ApiNotSupportedContactUs.LinkText' |
                                translate}}</a>
                        </div>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="{{'Download' | translate }}">
                    <ng-template pTemplate="header">
                        <img class="static" style="width: 30px" pTooltip="{{'Download' | translate }}"
                            tooltipPosition="top" src="assets/layout/images/animatedIcons/download.png"
                            alt="freya-layout" />
                        <img class="active" style="width: 30px" pTooltip="{{'Download' | translate }}"
                            tooltipPosition="top" src="assets/layout/images/animatedIcons/download.gif"
                            alt="freya-layout" />
                    </ng-template>
                    <app-pipeline></app-pipeline>
                    <div class="card selected">
                        <div class="p-col-12">
                            <img style="width: 50px" src="assets/layout/images/misc/plaudern.png" alt="freya-layout" />
                        </div>
                        <div class="p-col-12">
                            <h5>{{'FiletypeNotSupportedContactUs.Text' | translate}}</h5>
                            <a class="Link"
                                (click)="onSendRequest('Filetype')">{{'FiletypeNotSupportedContactUs.LinkText' |
                                translate}}</a>
                        </div>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div> -->

</div>