<p-dialog header="{{headerText | translate}}" [(visible)]="displayDialog" modal="modal" (onHide)="clearDialog()"
    showEffect="fade" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '1200px'}">


    <div class="p-grid p-ai-center p-jc-center">
        <!-- <div class="p-col-12 p-input-icon-right" style="margin-top: 5px">
                    <span class="p-float-label">
                    <div class="p-inputgroup" style="width: 100%;">
                        <p-autoComplete appendTo="body" [(ngModel)]="selectedUser" placeholder="Search User" [showEmptyMessage]="true" [suggestions]="filteredUsers" (onSelect)="setUser($event)" (completeMethod)="filterUser($event)" field="Username" [minLength]="1"></p-autoComplete>
                        <button type="button" pButton pRipple label="Add" (click)="addToList(selectedUser)"></button>
                    </div>
                </span>
        </div> -->
        <div class="p-col-12">
            <p-table #dtc [value]="permissions" styleClass="p-datatable-customers p-datatable-sm" [rows]="10"
                paginator=true [filterDelay]="0" [globalFilterFields]="['id','name','view','viewName','permission']">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dtc.filterGlobal($event.target.value, 'contains')"
                                placeholder="{{'Search' | translate}}" />
                        </span>
                        <!-- <span class="p-input-icon-right">
                        <div class="p-inputgroup">
                            <p-autoComplete appendTo="body" [(ngModel)]="selectedUser" placeholder="Add User" [showEmptyMessage]="true" [suggestions]="filteredUsers" (onSelect)="setUser($event)" (completeMethod)="filterUser($event)" field="Username" [minLength]="1"></p-autoComplete>
                            <button type="button" pButton pRipple label="Add" (click)="addToList(selectedUser)"></button>
                        </div>
                    </span> -->
                        <span class="p-input-icon-right">
                            <button pButton pRipple type="button" icon="pi pi-plus" label="{{'Add' | translate}}"
                                pTooltip="{{'Add' | translate}}" (click)="addToList()"
                                class="p-button-rounded p-button p-button-text p-mr-2 p-mb-2"></button>
                            <!-- <button type="button" pButton pRipple (click)="addToList()">{{'Add' | translate}}</button> -->

                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th colspan="2">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                {{'Type' | translate}}
                                <!-- <p-columnFilter type="text" field="Accessible" display="menu" class="p-ml-auto">
                            </p-columnFilter> -->
                            </div>
                        </th>
                        <th colspan="2">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                {{'Role' | translate}}
                                <!-- <p-columnFilter type="text" field="RightHolder" display="menu" class="p-ml-auto">
                            </p-columnFilter> -->
                            </div>
                        </th>
                        <!-- <th pSortableColumn="PermissionLevel">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Permission
                            <p-columnFilter type="text" field="PermissionLevel" display="menu" class="p-ml-auto">
                            </p-columnFilter>
                        </div>
                    </th> -->
                        <th colspan="2">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                {{'Accessible' | translate}}
                                <!-- <p-columnFilter type="text" field="Accessible" display="menu" class="p-ml-auto">
                            </p-columnFilter> -->
                            </div>
                        </th>
                        <th colspan="1">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                {{'IsOwner' | translate}}
                                <!-- <p-columnFilter type="text" field="permission.IsOwner" display="menu" class="p-ml-auto">
                            </p-columnFilter> -->
                            </div>
                        </th>
                        <th colspan="5">
                            <div class="p-d-flex p-jc-between p-ai-center" style="text-align: center;">
                                {{'Rights' | translate}}
                                <!-- <p-columnFilter type="text" field="Rights" display="menu" class="p-ml-auto">
                            </p-columnFilter> -->
                            </div>
                        </th>
                        <th style="width: 4rem"></th>
                    </tr>
                    <tr>
                        <th colspan="7"></th>
                        <!-- <th colspan="1" style="text-align: center;">CRUD</th> -->
                        <th *ngFor="let mode of rights" colspan="1" style="text-align: center;">{{mode}}</th>
                        <!-- <th colspan="1" style="text-align: center;">Read</th>
                    <th colspan="1" style="text-align: center;">Write</th>
                    <th colspan="1" style="text-align: center;">Delete</th>
                    <th colspan="1" style="text-align: center;">Admin</th> -->
                        <th style="width: 4rem"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-permission>
                    <tr [pSelectableRow]="permission">
                        <td colspan="2">
                            <span class="p-column-title">{{'Type' | translate}}</span>
                            <p-dropdown appendTo="body" [autoDisplayFirst]="false" placeholder="Select a role type"
                                [(ngModel)]="permission.selectedRoleType" [style]="{'width':'100%'}"
                                [options]="roleTypes" (onChange)="onSetRoleType($event, permission)"></p-dropdown>
                        </td>
                        <td colspan="2">
                            <span class="p-column-title">{{'Role' | translate}}</span>
                            <p-dropdown appendTo="body" [autoDisplayFirst]="false" placeholder="Select a role"
                                [(ngModel)]="permission.selectedRoleId" [style]="{'width':'100%'}"
                                [options]="permission.roleOptions" optionalValue="Rightholder.ID" optionLabel="label">
                            </p-dropdown>
                        </td>
                        <!-- <td colspan="2">
                        <span class="p-column-title">Permission</span>
                        <p-dropdown [(ngModel)]="permission.selectedPermissionLevel" [options]="permissionLevels"></p-dropdown>
                    </td> -->
                        <td colspan="2">
                            <span class="p-column-title">{{'Accessible' | translate}}</span>
                            <p-dropdown appendTo="body" [autoDisplayFirst]="false" placeholder="Select an accessible"
                                [(ngModel)]="permission.selectedAccessible" [style]="{'width':'100%'}"
                                [options]="permission.accessibles" optionLabel="name"></p-dropdown>
                            <!-- <span [class]="'customer-badge status-' + permission.IsOwner">{{permission.IsOwner}}</span> -->
                        </td>
                        <td colspan="1" style="text-align: center;">
                            <!-- <span class="p-column-title">Owner</span> -->
                            <p-checkbox [(ngModel)]="permission.isOwnerFlag" binary=true></p-checkbox>
                            <!-- <span [class]="'customer-badge status-' + permission.IsOwner">{{permission.IsOwner}}</span> -->
                        </td>
                        <td colspan="1" *ngFor="let right of rights" style="text-align: center;">
                            <!-- <span class="p-column-title">Owner</span> -->
                            <p-checkbox name="checkboxGroup" value="right" [value]="right"
                                [(ngModel)]="permission.rights" [inputId]="right"></p-checkbox>
                        </td>
                        <td>
                            <div class="ui-table-buttongroup-right">
                                <button pButton pRipple type="button" icon="pi pi-trash"
                                    class="p-button-rounded p-button-text p-mr-2 p-mb-2" pTooltip="Delete permission"
                                    (click)="deleteRow(permission)"></button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="13">{{'NoEntryFound' | translate}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>

    <app-system-message-log></app-system-message-log>

    <p-footer>
        <!-- <button type="button" pButton icon="pi pi-check"  label="Create" (click)="onCreatePermission($event)"
            class="p-button-text"></button>
        <button type="button" pButton icon="pi pi-times" (click)="displayDialog=false" label="Cancel"
            class="p-button-text"></button> -->
        <button pButton pRipple type="button" icon="pi pi-times" (click)="displayDialog=false"
            class="p-button-rounded p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'No' | translate}}"></button>
        <button pButton pRipple type="button" icon="pi pi-check" (click)="onCreatePermission($event)"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'Yes' | translate}}" [icon]="savingInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'"></button>
    </p-footer>
</p-dialog>
