<div>
    <p-table #dt [value]="columnSplitterList" dataKey="ColumnName" editMode="row" [reorderableColumns]="false">
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                <!-- <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="{{'LogHistory.SearchForLog' | translate}}" />
                </span> -->
                <span class="p-input-icon-right">
                    <button style="float: right" pButton label="{{'Add' | translate}}" icon="fa fa-plus"
                        (click)="onAddRow()"></button>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width:3rem"></th>
                <th>{{'Column' |translate}}</th>
                <th pTooltip="{{'Config.ColumnSplitter.Tooltip1' | translate}}" tooltipPosition="top">{{'Delimiter'
                    |translate}}
                </th>
                <th tooltipPosition="top" pTooltip="{{'Config.ColumnSplitter.Tooltip2' | translate}}">
                    {{'Split Method' | translate}}</th>
                <th tooltipPosition="top" pTooltip="{{'Config.ColumnSplitter.Tooltip3' | translate}}">
                    {{'Number of Splits' | translate}}</th>
                <th tooltipPosition="top" pTooltip="{{'Config.ColumnSplitter.Tooltip4' | translate}}"
                    style="width:3rem"> {{'Drop Old' | translate}}
                </th>
                <th style="width:3rem"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-column let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="column" [pReorderableRow]="ri"
                [ngClass]="{'row-accessories': column.hasError === true}">
                <td>
                    <span class="pi pi-bars" pReorderableRowHandle (onChange)="onUpdateSettings(false)"></span>
                </td>
                <td>
                    <p-dropdown appendTo="body" *ngIf="column.hasError === false" [options]="columnList"
                        (onChange)="onUpdateSettings(false)" [(ngModel)]="column.ColumnName" autoWidth="false"
                        [style]="{'width':'100%'}"></p-dropdown>
                    <p-dropdown *ngIf="column.hasError === true" appendTo="body" [options]="columnList"
                        (onChange)="onUpdateSettings(false)" [(ngModel)]="column.ColumnName" class="ng-invalid ng-dirty"
                        autoWidth="false" [style]="{'width':'100%'}">
                    </p-dropdown>
                </td>
                <td pEditableColumn>
                    <input pInputText [(ngModel)]="column.SplitCondition" [ngModelOptions]="{standalone: true}"
                        (blur)="onUpdateSettings(false)" [style]="{'width':'100%'}">
                    <!-- <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText [(ngModel)]="column.SplitCondition" (blur)="onUpdateSettings(false)"
                                    [ngModelOptions]="{standalone: true}">
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{column.SplitCondition}}
                            </ng-template>
                        </p-cellEditor> -->
                </td>
                <td>
                    <p-dropdown appendTo="body" [options]="splitMethodOptions" [(ngModel)]="column.SplitMethod"
                        (onChange)="onUpdateSettings(false)" optionLabel="name" dataKey="value"
                        (onChange)="onUpdateSettings(false)" autoWidth="false" [style]="{'width':'100%'}"></p-dropdown>
                </td>
                <td>
                    <input pInputText type="number" [(ngModel)]="column.MaxColumns" (blur)="onUpdateSettings(false)"
                        [ngModelOptions]="{standalone: true}" [style]="{'width':'100%'}">
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <p-checkbox [disabled]="column.SplitMethod.value == 'row'" [(ngModel)]="column.DropColumn"
                                [binary]="true" (onChange)="onUpdateSettings(false)"></p-checkbox>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <p-checkbox [disabled]="column.SplitMethod.value == 'row'" [(ngModel)]="column.DropColumn"
                                [binary]="true" (onChange)="onUpdateSettings(false)"></p-checkbox>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <button type="button" pButton class="ui-button-danger" icon="pi pi-trash" pTooltip="delete"
                        (click)="onDeleteRow(ri)" tooltipPosition="bottom"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="7">{{'NoEntryFound' | translate}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>