import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { PrimeIcons } from "primeng/api";
import { Id } from "src/app/helper/id";
import { ScheduleActionPlan } from "src/app/models/schedule.model";
import { DateTimeStampInfo, SchedulesService } from "src/app/services/schedules.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { SubSink } from "subsink";
import { Appointment } from "../schedule-charts/schedule-charts.component";

@Component({
	selector: "app-upcoming-appointments-timeline",
	templateUrl: "./upcoming-appointments-timeline.component.html",
	styleUrls: ["./upcoming-appointments-timeline.component.scss"],
})
export class UpcomingAppointmentsTimelineComponent implements OnInit, OnDestroy {
    subs = new SubSink;
	@Input() schedules: ScheduleActionPlan[] = [];

    appointments: DateTimeStampInfo[] = [];
	upcomingAppointments: Appointment[] = [] = [];
    selectedSchedule?: ScheduleActionPlan;

	icons: any;
	constructor(private scheduleService: SchedulesService, private errorService: SystemMessageLogService) {
		this.icons = { icon: PrimeIcons.CALENDAR, color: "#673AB7" };
	}
    ngOnDestroy(): void {
        this.subs.unsubscribe()
    }

	ngOnInit(): void {
        this.getAppointments();

        this.subs.sink = this.scheduleService.selectedSchedulePlanEmitter.subscribe((res:ScheduleActionPlan) => {
            if (res === this.selectedSchedule) {
                this.selectedSchedule = undefined;
                this.getAppointments();
            } else {
                this.selectedSchedule = res;
                this.getAppointments(res.id);
            }
        },
        (err: Error) => {
            this.errorService.handleError(err);
        })
    }

    getAppointments(id?: number, actionPlan?: number, start?: Date) {
		const defaultstartDate = new Date(2000, 1, 1);
		const defaultEndDate = new Date(2100, 12, 31);

		this.subs.sink = this.scheduleService
			.getFrequencyTimeStamps(defaultEndDate, id, actionPlan, defaultstartDate)
			.subscribe((res: DateTimeStampInfo[]) => {
				this.appointments = res;
				this.createUpcomingAppointments(res);
			},
			(err: Error) => {
				this.errorService.handleError(err);
			});
	}

    createUpcomingAppointments(ap: DateTimeStampInfo[]) {
		let array: Appointment[] = [];
		for (let i of ap) {
			let datesArray = i.DateObjects;
			let orderedDates = datesArray.sort((x, y) => +new Date(x) - +new Date(y));
			let firstFiveDates = orderedDates.slice(0, 4);

			for (let y of firstFiveDates) {
				// let newAp = new Appointment();
				// newAp.date = y.toUTCString();
				// newAp.actionPlan = i.ActionPlan;
				//newAp.actionPlan.name = newAp.actionPlan.name.substring(0,19);
				const actionPlan = Id.assertSet(i.ActionPlan, new Error("No Actionplan for this DateTimeStampInfo"))

				const newAp = new Appointment(y.toUTCString(), actionPlan);
				array.push(newAp);
			}
		}
		let orderedArray = array
			.sort((x, y) => +new Date(x.date) - +new Date(y.date))
			.slice(0, 5);
		this.upcomingAppointments = orderedArray;
	}
}
