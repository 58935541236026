import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { StyleMap, mxEditor, mxEventObject, mxEventSource, mxGraph, mxOutline } from 'mxgraph';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NodeGuiInfo, SchedulerNodeGuiInfo } from 'src/app/models/scheduler-designer.model';
import { DummySchedulerService, SchedulerNodeInfo, ScheduleWorkflow } from 'src/app/services/dummy-backend.service';
import { mxSchedulerEditor } from './mx-scheduler-editor';
import { mxSchedulerGraph } from './mxSchedulerGraph';
import { SchedulerStyleInfo } from './node-styles';
import { ScheduleNodePlugInInfos as ScheduleNodeInfoProvider } from './schedule-plugIn';
import mx from "./mx-graph-loader"; // <- import values from factory()
import { DecodeSettings, CodecBaseSettings, SchedulerGraphCodecImpl } from './schedule-graph-codec';

/**
 * Schedule Workflow Layout and Behaviour information.
 */
export class ScheduleWorkflowConfiguration {
	vertexWidth: number;
	vertexHeight: number;
	useEditorMode: boolean;

	constructor(
		vertexWidth: number,
		vertexHeight: number,
		useEditorMode: boolean
	) {
		this.vertexWidth = vertexWidth;
		this.vertexHeight = vertexHeight;
		this.useEditorMode = useEditorMode;
	}
}




@Component({
	selector: 'app-schedule-graph-workflow',
	templateUrl: './schedule-graph-workflow.component.html',
	styleUrls: ['./schedule-graph-workflow.component.scss']
})
export class ScheduleGraphWorkflowComponent implements OnInit {
	protected editor?: mxEditor;
	protected graph?: mxGraph;
	@ViewChild("graphContainer") graphContainer!: ElementRef;
	@ViewChild("outlineContainer") outlineContainer!: ElementRef;
	protected outlineWindow?: mxOutline;
	protected config = new ScheduleWorkflowConfiguration(80, 80, true);
	plugInInfos: SchedulerNodeInfo[] = [];
	protected nodeGuiInfo: NodeGuiInfo[] = [];
	protected workflowNodeGuiInfo: SchedulerNodeGuiInfo[] = [];
	@Input() isEditor: boolean = false;

	constructor(private dummyService: DummySchedulerService) { }

	ngOnInit(): void {
	}

	/**
	   * Open the given workflow in the Component
	   * @param workflow Workflow
	 *
	   */
	protected openWorkflow(workflow: ScheduleWorkflow): Observable<mxGraph> {
		this.purgeGraph();

		return this.loadPlugInInfo().pipe(map(info => {

			// convert and load
			const decoded_graph = ScheduleGraphWorkflowComponent.convertWorkflowToMxGraph(
				workflow,
				this.graphContainer.nativeElement,
				this.plugInInfos
			);

			if (this.config.useEditorMode) {
				this.editor = this.newWorkflowGraphEditor(
					this.graphContainer.nativeElement,
					<mxSchedulerGraph>decoded_graph
				);

				this.graph = this.editor.graph;
			} else {
				this.graph = decoded_graph;
			}

			if (this.outlineContainer) {
				this.graph = this.loadGraphIntoView(
					this.graph,
					this.outlineContainer.nativeElement
				);
			} else {
				this.graph = this.loadGraphIntoView(this.graph, undefined);
			}

			this.graph.refresh();

			return this.graph;
		}));
	}

	/**
	   * Load the graph into the view, setting styles and connect event handlers
	   * @param graph Graph
	   * @param outline mxOutline Container
	   * @param readOnly Graph read-only flag
	   * @returns The updated graph
	   */
	protected loadGraphIntoView(
		graph: mxGraph,
		outline?: HTMLElement,
		readOnly: boolean = false
	): mxGraph {

		// -- Initialize
		mx.mxEdgeHandler.prototype.snapToTerminals = true;

		// -- Collect References for callbacks
		const targetGraph = graph;
		const parentView = this;

		// -- Set graph handling
		if (readOnly) {
			targetGraph.setConnectable(false);
			// TODO: add additional parameters
		} else {
			targetGraph.setAllowDanglingEdges(false);
			targetGraph.setDisconnectOnMove(false);
			targetGraph.connectionHandler.targetConnectImage = true; // Centers the port icon on the target port
			targetGraph.setConnectable(true);
			// targetGraph.setHtmlLabels(true); // ehemals hier gesetzt
		}

		// -- Register Events
		const graphClickedFunction = this.onGraphClicked;
		const boundGraphClickedFunction = graphClickedFunction.bind(parentView);
		targetGraph.addListener(mx.mxEvent.CLICK, boundGraphClickedFunction);

		const cellConnectedFunction = this.onCellConnected;
		const boundCellConnectedFunction = cellConnectedFunction.bind(parentView);
		targetGraph.addListener(
			mx.mxEvent.CELL_CONNECTED,
			boundCellConnectedFunction
		);

		const cellAddedFunction = this.onCellAdded;
		const boundOnCellAddedFunction = cellAddedFunction.bind(parentView);
		targetGraph.addListener(mx.mxEvent.CELLS_ADDED, boundOnCellAddedFunction);

		// -- Insert image bundles for node images
		targetGraph.htmlLabels = true;
		const image_bundle = SchedulerStyleInfo.getDefaultImageBundle(
			false,
			this.imageBundleFallback
		);
		targetGraph.addImageBundle(image_bundle);

		// -- Set default styles
		this.configureStylesheet(targetGraph);

		// -- Disable Tooltips, maybe reactivate later
		targetGraph.tooltipHandler.setEnabled(false);

		// Enables HTML labels
		targetGraph.setHtmlLabels(true);

		// Creates the outline (navigator, overview) for moving
		// around the graph in the top, right corner of the window.



		// To show the images in the outline, uncomment the following code
		//this.outln.outline.labelsVisible = true;
		//this.outln.outline.setHtmlLabels(true);

		// Highlight cells on hover Over
		const highlight = new mx.mxCellTracker(graph, "#deeff6");

		if (!this.isEditor) {
			this.fit();
			graph.setEnabled(false);
		}

		targetGraph.refresh();

		if (outline) {
			this.outlineWindow = new mx.mxOutline(targetGraph, outline);
		}

		return graph;
	}

	/**
	 * Destroy old graph and drop references including editor.
	 */
	protected purgeGraph() {
		if (this.outlineWindow !== undefined) {
			this.outlineWindow.destroy();
			this.outlineWindow = undefined;
		}
		if (this.graph !== undefined) {
			this.graph.destroy();
			this.graph = undefined;
		}
		if (this.editor !== undefined) {
			this.editor = undefined;
		}
	}

	/**
 * Create a new workflow editor
 * @param element Graph Container
 * @param graph Graph, if none is given a new is created
 * @returns The new editor
 */
	protected newWorkflowGraphEditor(
		element: HTMLElement,
		graph?: mxSchedulerGraph
	): mxSchedulerEditor {
		const editor = new mxSchedulerEditor();

		if (graph === undefined) {
			editor.createGraph();
		} else {
			editor.loadGraph(graph);
		}

		// editor.setGraphContainer(element);
		// editor.["setGraphContainer"](element);
		(editor as any).setGraphContainer(element);

		// // -- Configure Editor
		// var group = new mx.mxCell('Group', new mx.mxGeometry(), 'group');
		// group.setVertex(true);
		// group.setConnectable(false);
		// this.editor.defaultGroup = group;
		// this.editor.groupBorderSize = 20;

		const config = mx.mxUtils
			.load("assets/mxgraph/keyhandler-commons.xml")
			.getDocumentElement();
		(editor as any).configure(config);

		return editor;
	}

	/**
	 * The name sais it all.
	 * @param workflow Workflow Object
	 * @param container HTML container in which the method creates the Workflow Graph
	 * @returns The created Workflow Graph
	 */
	static convertWorkflowToMxGraph(
		workflow: ScheduleWorkflow,
		container: HTMLElement,
		plugInInfos: SchedulerNodeInfo[]
	): mxSchedulerGraph {
		const base_settings = new CodecBaseSettings(true);

		//const converter = new mxWorkflowGraphDecoder(settings);
		const converter = new SchedulerGraphCodecImpl(base_settings);


		const decode_settings = new DecodeSettings(
			container,
			SchedulerStyleInfo.NodeStyles(),
			plugInInfos
		);
		const graph = converter.decode(workflow, decode_settings);

		return graph;
		//throw new Error("Not Implemented")
	}


	/**
 * Loads the Plug In Information for the Workflow Codec, the Node GUI and the Toolbar.
 *
 * You must call this method BEFORE you load or create a new workflow!
 */
	loadPlugInInfo(): Observable<[SchedulerNodeInfo[], NodeGuiInfo[], SchedulerNodeGuiInfo[]]> {
		return this.dummyService.getScheduleNodes().pipe(map((plugIns) => {
			this.plugInInfos = plugIns;
			this.nodeGuiInfo = ScheduleNodeInfoProvider.getNodeGuiInfo();
			this.workflowNodeGuiInfo = ScheduleNodeInfoProvider.getSchedulerNodeGuiInfo(
				plugIns,
				this.nodeGuiInfo
			);

			const result: [SchedulerNodeInfo[], NodeGuiInfo[], SchedulerNodeGuiInfo[]]
				= [this.plugInInfos, this.nodeGuiInfo, this.workflowNodeGuiInfo];

			return result;
		}));
	}

	// TODO - auf die events anpassen
	onGraphClicked(sender: mxEventSource, evt: mxEventObject) {

		// const cell = <mxCell>evt.properties["cell"];
		// if (cell === undefined) {
		// 	const data = new mxGraphEventData(sender, evt);
		// 	// this.op.hide();
		// 	this.onFireEvent(WorkflowGraphEventType.GraphClicked, data);
		// 	return;
		// }

		// if (mxWorkflowExtension.cellIsNode(cell)) {
		// 	const data = new NodeCellClickedData(
		// 		sender,
		// 		evt,
		// 		cell,
		// 		<mxWorkflowNodeValue>cell.getValue()
		// 	);
		// 	// this.op.show(data);
		// 	this.onFireEvent(WorkflowGraphEventType.NodeCellClicked, data);

		// 	return;
		// }

		// if (mxWorkflowExtension.cellIsPort(cell)) {
		// 	const data = new PortCellClickedData(
		// 		cell,
		// 		<mxWorkflowPortValue>cell.getValue(),
		// 		sender,
		// 		evt
		// 	);
		// 	const event = new WorkflowGraphEvent(
		// 		WorkflowGraphEventType.PortCellClicked,
		// 		data
		// 	);
		// 	this.fireEvent(event);
		// 	return;
		// }

		// const data = new CellClickedData(sender, evt, cell);
		// const event = new WorkflowGraphEvent(
		// 	WorkflowGraphEventType.CellClicked,
		// 	data
		// );
		// this.fireEvent(event);
	}

	// TODO: Fertig machen
	onCellAdded(sender: mxEventSource, evt: mxEventObject) {
		// const cells = <mxCell[]>evt.properties["cells"];
		// const data = new CellsAddedData(sender, evt, cells);
		// this.onFireEvent(WorkflowGraphEventType.CellsAdded, data);
	}
	// TODO: Fertig machen
	onCellConnected(sender: mxEventSource, evt: mxEventObject) {
		// const data = new mxGraphEventData(sender, evt);
		// const event = new WorkflowGraphEvent(
		// 	WorkflowGraphEventType.CellConnected,
		// 	data
		// );
		// this.fireEvent(event);

		// if (evt.properties["source"]) return; // source of edge updated, too early to fetch target -> QUIT!

		// const edge = <mxCell>evt.properties["edge"];
		// const sourcePortCell = edge.source;
		// const targetPortCell = edge.target;

		// const sourceNodeCell = sourcePortCell.getParent();
		// const targetNodeCell = targetPortCell.getParent();

		// const targetPort = <mxSchedulerPortValue>targetPortCell.getValue(); // legacy only - delete later

		// // Multi Port
		// if (targetPort.IsMulti) {
		// 	const incom_edge_set = mxWorkflowExtension.getConnectedEdges(
		// 		targetPortCell,
		// 		this.graph
		// 	);
		// 	incom_edge_set.delete(targetPortCell);

		// 	let given_names = new Array<string>();
		// 	for (const incom_cell of incom_edge_set.values()) {
		// 		if (incom_cell.value !== undefined) {
		// 			const call_value_obj = <mxSchedulerEdgeValue>incom_cell.getValue();
		// 			given_names.push(call_value_obj.Name);
		// 		}
		// 	}

		// 	const new_edge_name = this.generateEdgeName(given_names);
		// 	const new_edge_value = new mxWorkflowEdgeValue();
		// 	new_edge_value.Name = new_edge_name;
		// 	edge.setValue(new_edge_value);
		// }

		// // -- flood data from source to taget port
		// // collect all in edges, combine the data and copy it to the target port
		// const incom_edge_set = mxWorkflowExtension.getConnectedEdges(
		// 	targetPortCell,
		// 	this.graph
		// );
		// // merge edge result
		// const merged_data = this.mergeEdgeData(incom_edge_set);
		// // copy result to target node
		// targetPort.Data = merged_data;
		// const targetCell = <mxWorkflowNodeValue>targetNodeCell.getValue();
		// if (targetCell.Data == undefined) {
		// 	targetCell.Data = new Map();
		// }
		// targetCell.Data.set(targetPort.Name, merged_data);

		// // also write the new edge labels to the meta data
		// // TODO: check if the '_type' attrib is given corretly
		// const old_cell_meta_info = targetCell.Properties.MetaInfo.get(
		// 	targetPort.Name
		// );
		// const new_cell_meta_info = { ...old_cell_meta_info };
		// const merged_meta_info = merged_data.map((md) => {
		// 	return md.Table.MetaData;
		// });
		// targetCell.Properties.MetaInfo.set(targetPort.Name, merged_meta_info);

		// // TODO: Fire node data changed event here?

		// // -- Update Settings
		// const targetNodeId = targetNodeCell.id;
		// this.updateNodeSettings(targetNodeId);

		// // select target cell explicitly
		// //this.graph.selectionModel.setCell(sourceNodeCell);
	}

	configureStylesheet(graph: mxGraph) {
		const defaultVertexStyle:StyleMap = new Object();
		defaultVertexStyle[mx.mxConstants.STYLE_SHAPE] = mx.mxConstants.SHAPE_LABEL;

		mx.mxConstants.VERTEX_SELECTION_COLOR = "#000000";
		mx.mxConstants.VERTEX_SELECTION_DASHED = false;
		mx.mxConstants.VERTEX_SELECTION_STROKEWIDTH = 2;

		graph.getStylesheet().putDefaultVertexStyle(defaultVertexStyle);

		let edgeStyle = graph.getStylesheet().getDefaultEdgeStyle();
		edgeStyle[mx.mxConstants.STYLE_LABEL_BACKGROUNDCOLOR] = "#FFFFFF";
		edgeStyle[mx.mxConstants.STYLE_STROKEWIDTH] = "2";
		edgeStyle[mx.mxConstants.STYLE_ROUNDED] = true;
		edgeStyle[mx.mxConstants.STYLE_EDGE] = mx.mxEdgeStyle.EntityRelation;

		console.log("defaultVertexStyle", defaultVertexStyle)
	}


	// == INTERFACE

	unDo(): void {
		this.editor?.execute("undo");
	}
	reDo(): void {
		this.editor?.execute("redo");
	}
	cut(): void {
		this.editor?.execute("cut");
	}
	copy(): void {
		this.editor?.execute("copy");
	}
	paste(): void {
		this.editor?.execute("paste");
	}
	delete(): void {
		this.editor?.execute("delete");
		//this.designerService.displayConfigEmitter.emit(false);
	}
	zoomIn(): void {
		this.editor?.execute("zoomIn");
	}
	zoomOut(): void {
		this.editor?.execute("zoomOut");
	}
	actualSize(): void {
		this.editor?.execute("actualSize");
	}
	fit(): void {
		this.editor?.execute("fit");
	}

	/**
 * Callback for mxGraph Style preparation.
 */
	imageBundleFallback(): any { }


}
