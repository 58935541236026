import { Component, Input, OnInit } from '@angular/core';
import { TwitterAccess } from 'src/app/models/connectors/twitter-connector-view.model';
import { ConnectorView } from 'src/app/models/connectorView.model';

@Component({
  selector: 'app-twitter-connector',
  templateUrl: './twitter-connector.component.html',
  styleUrls: ['./twitter-connector.component.scss']
})
export class TwitterConnectorComponent implements OnInit {
	@Input() currentConnectorView: ConnectorView<TwitterAccess,undefined,undefined>;
  
  constructor() { }

  ngOnInit(): void {
  }

  onConnect(event) {

  }

}
