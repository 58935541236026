import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { TokenStorageService } from "../services/token-storage.service";
import * as CryptoJS from "crypto-js";
import { map, mergeMap } from "rxjs/operators";
// import { UserInfo } from "../models/user.model";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { SystemMessageLogService } from "../services/system-message-log.service";
import { HttpErrorResponse } from "@angular/common/http";
import { GuiErrorCode, GuiErrorException } from "../models/gui.error.model";
import { UserInfo } from "../models/api/models/session/UserInfo";
import { SessionController } from "../models/api/controllers/SessionController";

@Component({
	selector: "app-login",
	templateUrl: "./app.login.component.html",
})
export class AppLoginComponent {
	passwordEncryptOutput: string;

	form = new UntypedFormGroup({
		username: new UntypedFormControl("", [Validators.required]),
		password: new UntypedFormControl("", [Validators.required]),
		loginMode: new UntypedFormControl(false, [Validators.required]),
	});

	//loginMode: boolean = false;
	modeName: string = "Username";
	isLoggedIn = false;
	isLoginFailed = false;
	errorMessage = "";
	roles: string[] = [];

	constructor(
		private authService: AuthService,
		private tokenStorage: TokenStorageService,
		private errorService: SystemMessageLogService,
		private route: Router
	) {}

	ngOnInit(): void {
		if (this.tokenStorage.getToken()) {
			this.isLoggedIn = true;
		}
	}

	changeLoginMode(event) {
		console.log(event);
		if (!event.checked) {
			this.modeName = "Username";
		} else {
			this.modeName = "Azure Email";
		}
	}
    openForgotPasswordPage() {
        const url = this.route.serializeUrl(
            //this.route.createUrlTree([`/forgotPassword/${city.id}`])
            this.route.createUrlTree([`./#/forgotPassword/`])
          );
		window.open(
            url, "_blank");
	}

	onSubmit(): void {
		const { username, password } = this.form.value;

		if (!this.form.valid) {
			//this.errorMessage = "Missing credentials";
			// let error = new GuiErrorInfo(
			// 	100,
			// 	"Invalid Parameters",
			// 	"Not all mandatory fields entered"
			// );
			//this.errorService.sendErrorCode(GuiErrorCode.notAllFieldsEntered);
			this.errorService.handleError(new GuiErrorException(GuiErrorCode.notAllFieldsEntered));
			//this.errorService.sendError(error);
			this.isLoginFailed = true;
			return;
		}

		// BION LOGIN
		if (this.form.value.loginMode === false) {
			const login_arg = new SessionController.LogInArg(username, password);
			const login_obs = this.authService.loginPost(login_arg).pipe(
				mergeMap((user_token) => {
					console.log("Received UserToken", user_token);
					this.tokenStorage.saveToken(user_token);

					const user_obs = this.authService.getUserInfo().pipe(
						map((userInfo) => {
							this.tokenStorage.saveUser(userInfo);

							const token_storage_user = this.tokenStorage.getUser();
							console.log("User from token storage", token_storage_user);

							// this.roles = this.tokenStorage.getUser().roles;

							let result: [string, UserInfo];
							result = [user_token, userInfo];
							return result;
						})
					);

					return user_obs;
				})
			);
			login_obs.subscribe(
				(result) => {
					this.isLoginFailed = false;
					this.isLoggedIn = true;
					this.reloadPage();
					//this.route.navigateByUrl("#");
				},
				(err: Error) => {
					//this.errorMessage = err.error.message;
					this.errorService.handleError(err);
					this.isLoginFailed = true;
				}
			);
		} else {
			// AZURE LOGIN
			const login_obs = this.authService.loginAzure(username, password).pipe(
				mergeMap((user_token) => {
					console.log("Received UserToken", user_token);
					this.tokenStorage.saveToken(user_token);

					const user_obs = this.authService.getUserInfo().pipe(
						map((userInfo) => {
							this.tokenStorage.saveUser(userInfo);

							const token_storage_user = this.tokenStorage.getUser();
							console.log("User from token storage", token_storage_user);

							// this.roles = this.tokenStorage.getUser().roles;

							let result: [string, UserInfo];
							result = [user_token, userInfo];
							return result;
						})
					);

					return user_obs;
				})
			);

			login_obs.subscribe(
				(result) => {
					this.isLoginFailed = false;
					this.isLoggedIn = true;
					this.reloadPage();
					this.route.navigateByUrl("#");
				},
				(err) => {
					//this.errorMessage = err.error.message;
					this.errorService.handleError(err);
					this.isLoginFailed = true;
				}
			);
		}
	}
	//method is used to encrypt and decrypt the text
	encodePassword(conversion: string) {
		this.passwordEncryptOutput = CryptoJS.AES.encrypt(
			this.form.value.password.trim()
		).toString();
		console.log(this.passwordEncryptOutput);
	}

	reloadPage(): void {
		window.location.reload();
	}
}
