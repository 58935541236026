<div>
    <p-table #dt [value]="anonymizeInfoList" dataKey="ColumnName" editMode="row" [reorderableColumns]="false">
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="{{'LogHistory.SearchForLog' | translate}}" />
                </span>
                <span class="p-input-icon-right">
                    <button style="float: right" pButton label="{{'Add' | translate}}" icon="fa fa-plus"
                        (click)="onAddEntry()"></button>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <!-- <th style="width:3rem"></th> -->
                <th tooltipPosition="top" pTooltip="{{'Config.Anonymize.ToolTip1' | translate}}">{{'Column' |translate}}
                </th>
                <th tooltipPosition="top" pTooltip="{{'Config.Anonymize.ToolTip2' | translate}}">
                    {{'Method' |translate}}</th>
                <th tooltipPosition="top" pTooltip="{{'Config.Anonymize.ToolTip3' | translate}}">{{'Value' |translate}}
                </th>
                <th tooltipPosition="top" pTooltip="{{'Config.Anonymize.ToolTip4' | translate}}">{{'Drop Old'
                    |translate}}</th>
                <th style="width:3rem"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-column let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="column" [pReorderableRow]="ri"
                [ngClass]="{'row-accessories': column.hasError === true}">
                <!-- <td>
                    <span class="pi pi-bars" pReorderableRowHandle (onChange)="onChangeSettings(this.filterList)"></span>
                </td> -->
                <td>
                    <p-dropdown appendTo="body" *ngIf="column.hasError === false" [options]="columnList"
                        [(ngModel)]="column.ColumnName" (onChange)="onUpdateSettings(false)" autoWidth="false"
                        [autoDisplayFirst]="false" [style]="{'width':'100%'}"></p-dropdown>
                    <p-dropdown *ngIf="column.hasError === true" appendTo="body" [options]="columnList"
                        [(ngModel)]="column.ColumnName" class="ng-invalid ng-dirty" [autoDisplayFirst]="false"
                        (onChange)="onUpdateSettings(false)" autoWidth="false" [style]="{'width':'100%'}">
                    </p-dropdown>
                </td>
                <td>
                    <p-dropdown appendTo="body" [options]="anonymizeMethods" (onChange)="onUpdateSettings(false)"
                        [(ngModel)]="column.AnonymizeMethod" autoWidth="false" [style]="{'width':'100%'}">
                    </p-dropdown>
                </td>
                <td>
                    <input *ngIf="column.AnonymizeMethod === 'Custom Value'" (blur)="onUpdateSettings(false)" pInputText
                        [(ngModel)]="column.CustomValue" [style]="{'width':'100%'}"
                        [ngModelOptions]="{standalone: true}">
                </td>
                <td>
                    <p-checkbox [binary]="true" [(ngModel)]="column.DropInputColumn"
                        (onChange)="onUpdateSettings(false)">
                    </p-checkbox>
                </td>
                <td>
                    <div class="ui-table-buttongroup">
                        <button pButton pRipple type="button" class="p-button-rounded p-button-text ui-button-danger"
                            icon="pi pi-trash" pTooltip="Delete entry" (click)="onDeleteEntry(ri)"
                            tooltipPosition="bottom"></button>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="5">{{'NoEntryFound' | translate}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>