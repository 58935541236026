export class DataSource {
    ID: number;
    Name: string;
    IsRealTime: boolean;
    UsePsa: boolean;
    Description?: string;
    constructor(ID: number,Name: string,IsRealTime: boolean,UsePsa: boolean,Description?: string) {
        if(ID === undefined) throw new Error( "Class 'DataSource': Required value 'ID' is undefined" );
        if(Name === undefined) throw new Error( "Class 'DataSource': Required value 'Name' is undefined" );
        if(IsRealTime === undefined) throw new Error( "Class 'DataSource': Required value 'IsRealTime' is undefined" );
        if(UsePsa === undefined) throw new Error( "Class 'DataSource': Required value 'UsePsa' is undefined" );
        this.ID = ID;
        this.Name = Name;
        this.IsRealTime = IsRealTime;
        this.UsePsa = UsePsa;
        this.Description = Description;
    }
}