import { Component, OnDestroy, OnInit } from '@angular/core';
import { WorkflowNodeSettings } from 'src/app/models/api/com/bion/etl/Workflow';
// import { WorkflowNodeSettings } from 'src/app/models/workflow.model';
import { DesignerService } from 'src/app/services/designer.service';
import { WorkflowsService } from 'src/app/services/workflows.service';

import { NodeConfigComponentBase } from '../node-config-component-base';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.css']
})
export class DefaultComponent extends NodeConfigComponentBase<any> implements OnInit, OnDestroy {
  settingsToView(settings: WorkflowNodeSettings): void {
    console.log("Default View - no action");
  }
  viewToSettings() {
      console.log("Default View - no action");
  }
  onSettingsChanged(settings: any): void {
      throw new Error('Method not implemented.');
  }

//   @Output() configSettingsUpdated = new EventEmitter<WorkflowNode>();


  constructor(protected workflowService: WorkflowsService,
     protected designerEventService: DesignerService) {
    super(workflowService, designerEventService);
}


ngOnInit(): void { }


ngOnDestroy(): void { }

}
