import { Component, OnInit } from "@angular/core";
import { MenuService } from "./app.menu.service";
import { PrimeNGConfig } from "primeng/api";
import { AppComponent } from "./app.component";
import { SystemMessageLogService } from "./services/system-message-log.service";
import { SubSink } from "subsink";

import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { GuiErrorInfo } from "./models/gui.error.model";
//import { KeycloakService } from "keycloak-angular";
import { UserInfoController } from "./models/api/controllers/UserInfoController";
import { AppStatus, LoadingAppComponent } from "./pages/loading-app/loading-app.component";
import { BackendAuthService } from "./services/backend-auth.service";
import { map } from "rxjs/operators";
import { UserSubscriptionService } from "./services/user-subscription.service";
import { environment } from "src/environments/environment";
import { CheckBoxEvents } from "./helper/events";

type ProfileType = {
	givenName?: string;
	surname?: string;
	userPrincipalName?: string;
	id?: string;
};

@Component({
	selector: "app-main",
	templateUrl: "./app.main.component.html",
	providers: [LoadingAppComponent],
})
export class AppMainComponent implements OnInit {
	sidebarStatic: boolean = true;
	sidebarActive = false;
	staticMenuMobileActive: boolean = false;
	menuClick: boolean = false;
	topbarItemClick: boolean = false;
	activeTopbarItem: any;
	topbarMenuActive: boolean = false;
	searchClick = false;
	search = false;

	rightPanelClick: boolean = false;
	rightPanelActive: boolean = false;

	configActive: boolean = false;
	configClick: boolean = false;
	menuHoverActive = false;

	profile!: ProfileType;
	appStatus: AppStatus = { status: "Starting", loading: true, userLoggedIn: false };
	currentUserFull?: UserInfoController.FullUserInfo;
	subs = new SubSink();
	GuiErrorInfo?: GuiErrorInfo = undefined;



	// MessageHidden:boolean = false;
	// onClickUpdateButton() {
	//     window.open(
	//         "https://buy.stripe.com/14kcNO9uq6sR3Dy288", "_blank");
	// }
	// clearMessage() {
	//     this.MessageHidden = true;
	// }
	constructor(
		private loadApp: LoadingAppComponent,
		private menuService: MenuService,
		private primengConfig: PrimeNGConfig,
		public translate: TranslateService,
		public app: AppComponent,
		private errorService: SystemMessageLogService,
		//private keycloak: KeycloakService,
		private beAuthService: BackendAuthService,
		private subService: UserSubscriptionService,
	) {
		translate.addLangs(["en", "de"]);
		let browserLang = translate.getBrowserLang();

		if (browserLang === "de") {
			translate.setDefaultLang(browserLang);
		} else {
			translate.setDefaultLang("en")
		}

	}

	public ngOnInit() {

		// -- Activate this to check the environment variables
		console.log("Initialize Main Component");
		console.log("Environment Variables");
		console.log("Backend Base URL", environment.BackEnd.baseUrl);

		this.appStatus = { status: "Initialize Login", loading: true, userLoggedIn: false };
		this.onLoginViaKeycloak(undefined);
	}

	// Backend Keycloak Authentication
	onLoginViaKeycloak(event: any) {
		this.appStatus = { status: "Initializee Login", loading: true, userLoggedIn: false };

		//return this.loginViaKeycloak();
		this.loginViaKeycloak().subscribe(v => {
			this.appStatus = { status: "Login Successful", loading: false, userLoggedIn: false };
		})
	}

	/**
	 * Checks if we are logged in via Keycloak and redirects to the login page if not.
	 * You can call this method in NG on init.
	 * Alternativ: Teste mit 'isLoggedIn()' ob wir eingelogged sind und zeige das dem User und biete einen Login-Knopf an.
	 */
	loginViaKeycloak() {

		this.appStatus = { status: "Loggin user", loading: true, userLoggedIn: false };

		// Check if logged in, if not goto ...
		return this.beAuthService.isLoggedIn().pipe(map(loggedIn => {
			if (loggedIn) {
				const obs = this.beAuthService.getFullUserInfo().pipe(map(full_user => {
					this.appStatus = { status: "Get User Info", loading: true, userLoggedIn: false };

					this.initFullUserInfo(full_user);
				}));

				obs.subscribe(res2 => {
					console.log("Full User info Performed");
				}, (err) => this.errorService.handleError(err))

			} else {

				//console.log("Not logged in");
				console.log("Not logged in, opening Keycloak Login Page - WARNING: URL NOT VERIFIED/STATIC!");

				// The backend is not logged in into keycloak, so we call a secured URL. When we do so, the BE forwards
				// the request to Keycloak BUT sets the redirect URL to the BE. This means, when we successfully logged in
				// Keycloak redirects to the BE. Then the BE creates a session cookie for the FE and so the FE is indirectly
				// linked to the Keycloak session via the BE.

				// The forward parameter forwards the succeessful returned keycloak login (redirected to the backend)
				// to this page again. It is called 'forward' to prevent colissions with 'redirect' parameter names.

				// const param = "?forward=" + encodeURIComponent(window.location.origin);
				// window.open("/auth/startLogin" + param, "_self");


				// UPDATE: the forward parameter is dangerours: A Phishing Attack is possible
				// We can not use a proxy call here, because the created open id session is then created for the FE
				// URL (e.g. demo.mybion.de) but the callback happens to the BE (e.g. demobackendapi.mybion.de)

				// Therefore we must use the direct BE login URL and avoid the forward parameter.
				// Guess we can use the origin the for redirecting or just Plays redirect function.


				// //const be_url = "demobackendapi.mybion.de";   			// :-(
				// //const be_url = "http://demobackendapi.mybion.de";		// besser!
				// const be_url = "http://localhost:9000";
				// const login_url = "/auth/startLogin";

				// We use configuration parameters which we can change due to our build configuration so we don't need to change
				// the component code every time.

				const be_url = environment.BackEnd.baseUrl;
				const login_url = environment.BackEnd.loginUrl;

				const full_url = be_url + login_url;
				console.log("Target BE Login URL", full_url);
				//console.log("WARNING: Login URL is hardcoded. Add the BE url to the environment settings: ", full_url);
				window.open(full_url, "_self");
			}
		}))
	}
	initFullUserInfo(full_user: UserInfoController.FullUserInfo) {
		const kc_userInfo = full_user.UserInfo;
		const userInfoDetails = full_user.Details;
		const userLicenses = full_user.Licenses;

		this.currentUserFull = full_user;
		this.subService._licenses.emit(full_user.Licenses);

		console.log("Full User Object", full_user);
		this.appStatus = { status: "Finished", loading: false, userLoggedIn: false };

		// this.loadIntroduceYourself();
		//this.loadChatbotScript(this.currentUserFull.UserInfo.KeyCloakId, this.currentUserFull.UserInfo.EMail);
		//this.loadInvolveMeScripts();

		//this.beAuthService.emitFullUser(full_user);
		//this.router.navigateByUrl('/main');

	}


	getGistContracts() {

	}

	loadChatbotScript(id: string, email: string): void {
		const gist: any = (window as any).gist = (window as any).gist || [];
		gist.methods = ['trackPageView', 'identify', 'track', 'setAppId', 'trigger'];
		gist.factory = function(t: any) {
		  return function() {
			const e = Array.prototype.slice.call(arguments);
			e.unshift(t);
			gist.push(e);
			return gist;
		  };
		};

		for (let i = 0; i < gist.methods.length; i++) {
		  const c = gist.methods[i];
		  gist[c] = gist.factory(c);
		}

		const script = document.createElement('script');
		script.src = 'https://widget.getgist.com';
		script.async = true;

		script.onload = () => {
		  // Script loaded successfully, execute any additional code here
		  console.log('Gist Script loaded');
		  (window as any).gist?.setAppId('cmlacopk');
		  (window as any).gist?.trackPageView();
		  (window as any).gist?.identify(id, {
			email: email,
			// Add any other properties you need
		  });
		  //(window as any).gist?.trigger('survey', 460);
		  (window as any).gist?.trigger('bot', 6485);
		  console.log('Gist script filled out', (window as any).gist);

		  // Check if gist.chat is available
		  if ((window as any).gist?.chat) {
			// Execute code when gist.chat is ready
			console.log('The gist.chat() API is ready now');
		  } else {
			// Wait for gistChatReady event to be fired
			document.addEventListener('gistChatReady', (res) => {
			  console.log('The gist.chat() API is ready now',res);
			  // Perform additional operations on gist.chat here
			});
		  }
		};

		script.onerror = () => {
		  // Script failed to load
		  console.log('Failed to load Gist Script');
		};

		document.head.appendChild(script);
	  }

	  updateChatbotScript(): void {
		const scriptElement = <HTMLScriptElement>document.querySelector('script[src="https://widget.getgist.com"]');
		
		if (scriptElement) {
		  scriptElement.onload = () => {
			(window as any).gist?.trigger('tour', 326);
			console.log('Onboarding started', (window as any).gist);
	  
			// Check if gist.chat is available
			if ((window as any).gist?.chat) {
			  // Execute code when gist.chat is ready
			  console.log('The gist.chat() API is ready now');
			} else {
			  // Wait for gistChatReady event to be fired
			  document.addEventListener('gistChatReady', (res) => {
				console.log('The gist.chat() API is ready now', res);
				// Perform additional operations on gist.chat here
			  });
			}
		  };
		
		  scriptElement.onerror = () => {
			// Script failed to load
			console.log('Failed to load Gist Script');
		  };
		}
	  }

	//   loadIntroduceYourself() {
	// 	const script = document.createElement('script');
	// 	script.src = 'https://survey-widget.getgist.com/';
	// 	script.defer = true;
	// 	document.head.appendChild(script);

	// 	}

	  loadInvolveMeScripts(): void {
		const script1 = document.createElement('script');
		script1.src = 'https://bion-analytics.involve.me/embed?type=popup';

		script1.onload = () => {
		  //this.loadInvolveMePopupScript();
		  this.loadInvolveMePopupScript2();
		};

		document.head.appendChild(script1);
	  }

	  loadInvolveMePopupScript(): void {
		const script2 = document.createElement('script');
		script2.innerHTML = `
		  involvemeEmbedPopup.createTriggerEvent({
			projectUrl: "first-login-survey",
			organizationUrl: "https://bion-analytics.involve.me",
			embedMode: "fullscreen",
			triggerEvent: "load",
			popupSize: "large",
			closeOnCompletionTimer: "10",
			stopShowingDuration: "indefinite",
			// hideAfterViewedFor: "24hours",
		  });
		`;

		document.getElementById('involve-me-popup-first-login')?.appendChild(script2);
	  }

	  loadInvolveMePopupScript2(): void {
		const script2 = document.createElement('script');
		script2.innerHTML = `
		  involvemeEmbedPopup.createTriggerEvent({
			projectUrl: "beta-tester-saas-survey",
			organizationUrl: "https://bion-analytics.involve.me",
			embedMode: "sidePanel",
			triggerEvent: "fixedButton",
			popupSize: "small",
			buttonTextColor: "#FFFFFF",
			buttonColor: "#94a3b8",
			icon: "user",
			position: "right",
			buttonText: "Feedback",
		  });
		`;

		document.getElementById('involve-me-popup')?.appendChild(script2);
	  }


	// Bion Login functions -------------------------------------------------

	// logout(): void {
	// 	this.tokenStorageService.signOut();
	// 	window.location.reload();
	// }

	logout() {
		//this.keycloak.logout('http://localhost:9000/auth/logout');
		//this.router.navigateByUrl('/logout')
		this.logoutViaBrowser();
	}

	logoutViaApi() {
		(window as any).gist?.chat('shutdown');
		//this.keycloak.logout('/auth/logout');
	}

	logoutViaBrowser() {
		window.open("/auth/logout", "_self");
		//window.open("/auth/logoutPre","_self");
	}


	// Azure Login functions -------------------------------------------------

	// logout() {
	// 	// Add log out function here
	// 	this.authService.logoutRedirect({
	// 		postLogoutRedirectUri: environment.redirectUrl,
	// 	});
	// }
	// login() {
	// 	this.authService.loginRedirect();
	// }
	// setLoginDisplay() {
	// 	console.log(this.authService.instance.getAllAccounts());
	// 	//this.LoggedIn = this.authService.instance.getAllAccounts().length > 0;
	// }
	// getProfile() {
	// 	this.http.get(this.GRAPH_ENDPOINT).subscribe((profile) => {
	// 		console.log("Azure User Profile", profile);
	// 		this.profile = profile;
	// 	});
	// }
	// ngOnDestroy(): void {
	// 	this._destroying$.next(undefined);
	// 	this._destroying$.complete();
	// }

	// isLoggedIn(): boolean {
	// 	return this.authService.instance.getAllAccounts().length > 0;
	// }

	//Layout functions --------------------------------------------------

	onLayoutClick() {
		if (!this.topbarItemClick) {
			this.activeTopbarItem = null;
			this.topbarMenuActive = false;
		}

		if (!this.menuClick && (this.isHorizontal() || this.isSlim())) {
			this.menuService.reset();
		}

		if (this.configActive && !this.configClick) {
			this.configActive = false;
		}

		// if (!this.rightPanelClick) {
		// 	// #evilRigh
		// 	this.rightPanelActive = false;
		// }

		if (!this.menuClick) {
			if (this.staticMenuMobileActive) {
				this.staticMenuMobileActive = false;
			}

			this.menuHoverActive = false;
			this.unblockBodyScroll();
		}

		if (!this.searchClick) {
			this.search = false;
		}

		this.searchClick = false;

		this.configClick = false;
		this.topbarItemClick = false;
		this.menuClick = false;
		this.rightPanelClick = false;  // #evilRight
	}

	onMenuButtonClick(event:Event) {
		this.menuClick = true;
		this.topbarMenuActive = false;
		this.rightPanelActive = false; // #evilRight

		if (this.isMobile()) {
			this.staticMenuMobileActive = !this.staticMenuMobileActive;
			if (this.staticMenuMobileActive) {
				this.blockBodyScroll();
			} else {
				this.unblockBodyScroll();
			}
		}

		event.preventDefault();
	}

	onTopbarItemClick(event:Event, item:any) {
		this.topbarItemClick = true;

		if (this.activeTopbarItem === item) {
			this.activeTopbarItem = null;
		} else {
			this.activeTopbarItem = item;
		}

		if (item.className === "topbar-item search-item") {
			this.search = !this.search;
			this.searchClick = !this.searchClick;
		}

		event.preventDefault();
	}

	onRightPanelClick(event: boolean) {
		this.rightPanelClick = true;
		console.log("Clicked", event);
		//this.rightPanelActive = !this.rightPanelActive;
		this.rightPanelActive = event;  // #evilRight

		this.staticMenuMobileActive = false;

		//event.preventDefault();
	}

	onRippleChange(event:CheckBoxEvents.OnChange<any>) {
		this.app.ripple = event.checked;
		this.primengConfig.ripple = event.checked;
	}

	onConfigClick(event:Event) {
		this.configClick = true;
	}

	onSidebarClick($event:Event) {
		this.menuClick = true;
	}

	onToggleMenu(event:Event) {
		this.menuClick = true;
		this.sidebarStatic = !this.sidebarStatic;

		event.preventDefault();
	}

	onSidebarMouseOver(event:Event) {
		if (this.app.menuMode === "sidebar" && !this.sidebarStatic) {
			this.sidebarActive = !this.isMobile();
		}
	}

	onSidebarMouseLeave(event:Event) {
		if (this.app.menuMode === "sidebar" && !this.sidebarStatic) {
			setTimeout(() => {
				this.sidebarActive = false;
			}, 250);
		}
	}

	isSlim() {
		return this.app.menuMode === "slim";
	}

	isHorizontal() {
		return this.app.menuMode === "horizontal";
	}

	isDesktop() {
		return window.innerWidth > 991;
	}

	isMobile() {
		return window.innerWidth <= 991;
	}

	blockBodyScroll(): void {
		if (document.body.classList) {
			document.body.classList.add("blocked-scroll");
		} else {
			document.body.className += " blocked-scroll";
		}
	}

	unblockBodyScroll(): void {
		if (document.body.classList) {
			document.body.classList.remove("blocked-scroll");
		} else {
			document.body.className = document.body.className.replace(
				new RegExp(
					"(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)",
					"gi"
				),
				" "
			);
		}
	}
}
