import { Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";

declare module 'rxjs' {
    interface Observable<T> {
        zip<U>(o: Observable<U>): Observable<[T, U]>;
    }
}

Observable.prototype.zip = function <B>(o: Observable<B>) {
    return Observables.zip(this, o);
}

export class Observables {

    /**
     * Zips two Observables together. If one fails, both will fail.
     * @param o1 First
     * @param o2 Second
     * @returns Both results as tuple
     */
    static zip<A, B>(o1: Observable<A>, o2: Observable<B>) {

        return o1.pipe(mergeMap(r1 => {
            return o2.pipe(map(r2 => {
                const result: [A, B] = [r1, r2];
                return result;
            }))
        }))

    }
}

/**
 * Ergebnis für einen Observable, der nicht fehlschlagen kann.
 * Wenn es Fehler gibt, wird das durch die Attribute Successful, Result und Error angezeigt.
 */
export interface SafeObservableResult<A, R> {
    /**
     * Das Observable Argument, falls notwendig.
     */
    Argument?: A
    /**
     * Ein Tag für Anmerkungen oder als Schüssel.
     */
    Tag?: string;
    /**
     * true, falls der Observable erfolgreich war, sonst false.
     */
    Successful: boolean;
    /**
     * Im Erfolgsfall das Ergebnis.
     */
    Result?: R;
    /**
     * Im Fehlerfall der Error.
     */
    Error?: Error
}

/**
 * Funktionen für den Umgang mit Observables, die nicht fehlschlagen können.
 * Dies ermöglich eine effektive Nutzung von fork-joins und eine gute Fehlerauswertung.
 */
export class SafeObservables {
    /**
 * Verhindert das Observables fehlschlage im Fork-Join.
 * <br>
 * Auf diese Weise können wir von jedem Obs herausfinden, ob er funktioniert hat.
 * @param ob Observable
 * @param arg Original function argument
 * @param tag Additional information
 */
    static recoverObs<A, R>(ob: Observable<R>, arg: A, tag?: string): Observable<SafeObservableResult<A, R>> {
        return ob
            .pipe(map(r => this.mkSuccess(arg, r, tag)))
            .pipe(catchError(e => of(this.mkFailure<A, R>(arg, e, tag))));
    }

    /**
   * Erstellt das Ergebnis für einen erfolgreichen Observable
   * @param arg Argument
   * @param result Ergebnis
   * @param tag Tag
   * @returns Safe Result
   */
    static mkSuccess<A, R>(arg: A, result: R, tag?: string): SafeObservableResult<A, R> {
        return { Argument: arg, Tag: tag, Successful: true, Result: result, Error: undefined }
    }

    /**
     * Erstellt das Ergebnis für einen fehlgeschlagenen Observable.
     * @param arg Argument
     * @param error Fehler
     * @param tag Tag
     * @returns Safe Result
     */
    static mkFailure<A, R>(arg: A, error: Error, tag?: string): SafeObservableResult<A, R> {
        const r: R | undefined = undefined;
        return { Argument: arg, Tag: tag, Successful: false, Result: r, Error: error }
    }
}