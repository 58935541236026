import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TaskJobModel } from '../models/api/models/staging/TaskJobModel';
import { ExtractDataToPsaArg, ExtractDataToPsaResult } from '../models/datasource.model';
// import { JobRequestResult, JobStatusInfo } from '../models/staging/LoadDataModel';
import { TaskInfo } from '../models/task.model';
import { ApiBackendService } from './api-backend.service';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  constructor(private api: ApiBackendService) { }

  ExtractDataToPsa_status(id:string): Observable<TaskJobModel.JobStatusInfo<any>> {
    return this.api.ExtractDataToPsa_status(id);
  }

  public ExtractDataToPsa_jobs_status(): Observable<TaskJobModel.JobStatusInfo<any>[]> {
    return this.api.ExtractDataToPsa_jobs_status();
  }

  public ExtractDataToPsa_async(arg: ExtractDataToPsaArg): Observable<TaskJobModel.JobRequestResult> {
    return this.api.ExtractDataToPsa_async(arg);
  }

  public ExtractDataToPsa_result(id: String): Observable<ExtractDataToPsaResult> {

    return this.api.ExtractDataToPsa_result(id);
  }

  getTasks(id?: number): Observable<TaskInfo[]> {
    return this.api.getTasks(id);
  }

  public killTask(id: number): Observable<number> { return this.api.killTask(id) }

  public clearTasks(keepFailed: boolean): Observable<number[]> { return this.api.clearTasks(keepFailed) }


	public ExtractDataToPsaStream_cancel(id: String): Observable<boolean> {
    return this.api.ExtractDataToPsaStream_cancel(id);
	}
}
