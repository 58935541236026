import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { DropDownEvents } from "src/app/helper/events";
import { Id } from "src/app/helper/id";
import { ViewInfoRow } from "src/app/models/api/domain/NewDataSourceModelBase";
import { RightHolder } from "src/app/models/api/models/authorization/RightHolder";
import { ViewAccessible } from "src/app/models/api/models/authorization/ViewAccessible";
import { ViewPermission } from "src/app/models/api/models/authorization/ViewPermission";
import { UserInfo } from "src/app/models/api/models/session/UserInfo";
import { DataStoreAccessible } from "src/app/models/api/models/staging/DataStoreAccessible";
import { DataStorePermission } from "src/app/models/api/models/staging/DataStorePermission";
import { WorkflowAccessible } from "src/app/models/api/models/workflow/WorkflowAccessible";
import { WorkflowPermission } from "src/app/models/api/models/workflow/WorkflowPermission";
import { WorkflowRepositoryEntry } from "src/app/models/api/models/workflow/WorkflowRepositoryEntry";
import {
	GroupRoleView,
	RightHolderView,
	UserRoleView,
} from "src/app/models/auth-service-decorator.model";
// import {
// 	RightHolder,
// 	ViewAccessible,
// 	ViewInfoRow,
// 	ViewPermission,
// } from "src/app/models/authorization.model";
import { DataSource, DataSourceAccessible, DataSourcePermission } from "src/app/models/datasource.model";
import { DataStore } from "src/app/models/datastore.model";
import { GuiErrorCode, GuiErrorException } from "src/app/models/gui.error.model";
import { ActionPlanAccessible, ActionPlanPermission, ScheduleActionPlan } from "src/app/models/schedule.model";
// import { UserInfo } from "src/app/models/user.model";
// import {
// 	WorkflowAccessible,
// 	WorkflowPermission,
// 	WorkflowRepositoryEntry,
// } from "src/app/models/workflow.model";
import { AuthorizationService } from "src/app/services/authorization.service";
import { CubesService } from "src/app/services/cubes.service";
import { DatasourcesService } from "src/app/services/datasources.service";
import { SchedulesService } from "src/app/services/schedules.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { UserService } from "src/app/services/user.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { SubSink } from "subsink";

export class GenericPermissionView {
	constructor(
		roleTypes: string[],
		userRoles: UserRoleView[],
		groupRoles: GroupRoleView[],
		accessibles: WorkflowRepositoryEntry[] | DataSource[] | ViewInfoRow[] | ScheduleActionPlan[] | DataStore[],
		rights: string[]
	) {
		this.selectedRoleType = roleTypes[0];
		this.isOwnerFlag = false;
		this.userRoles = userRoles;
		this.groupRoles = groupRoles;
		this.roleOptions = userRoles; // TODO make dynamic
		this.accessibles = accessibles; // TODO make dynamic
		this.rights = rights;
	}
	selectedRoleId?: RightHolderView<RightHolder>;
	selectedRoleType: string;
	isOwnerFlag: boolean;
	accessibles: WorkflowRepositoryEntry[] | DataSource[] | ViewInfoRow[] | ScheduleActionPlan[] |  DataStore[];
	selectedAccessible?: WorkflowRepositoryEntry | DataSource | ViewInfoRow | ScheduleActionPlan | DataStore;
	rights: string[];
	readonly userRoles: UserRoleView[];
	readonly groupRoles: GroupRoleView[];

	roleOptions: RightHolderView<RightHolder>[];
}

@Component({
	selector: "app-add-permission-dialog",
	templateUrl: "./add-permission-dialog.component.html",
	styleUrls: ["./add-permission-dialog.component.scss"],
})
export class AddPermissionDialogComponent implements OnInit, OnDestroy {
	@Input() view!: string;
	savingInProgress: boolean = false;
	subs = new SubSink();
	displayDialog: boolean = false;
	headerText: string = "";
	users: UserInfo[] = []

	// META DATA
	rightHolderViews: RightHolderView<RightHolder>[] = [];
	userRoleViews: UserRoleView[] = [];
	groupRoleViews: GroupRoleView[] = [];
	viewInfos: ViewInfoRow[] = []

	permissions: GenericPermissionView[] = [];

	permissionLevels: string[] = []
	selectedPermissionLevel?: string;

	roleTypes: string[] = []

	isOwnerFlag: boolean = false;
	rights: string[] = []

	//view attributes --> dynamically
	workflows: WorkflowRepositoryEntry[] = []
	selectedWorkflow?: WorkflowRepositoryEntry;

	datasources: DataSource[] = []
	selectedDatasource?: DataSource;

	schedules: ScheduleActionPlan[] = []
	selectedSchedulePlan? : ScheduleActionPlan;

	destinations: DataStore[] = []
	selectedDestination? : DataStore;

	onSetRoleType(event:DropDownEvents.OnChange<any,string>, perm: GenericPermissionView) {
		if (event.value === "User Role") {
			perm.roleOptions = perm.userRoles;
		}
		if (event.value === "Group Role") {
			perm.roleOptions = perm.groupRoles;
		}
	}

	addToList() {
		this.subs.sink = this.userService.getUserCommon().subscribe((res: UserInfo[]) => {
			this.users = res;
		});

		if (this.view === "WorkflowView") {
			this.subs.sink = this.workflowsService
				.getWorkflowObjectList()
				.subscribe((wfs) => {
					this.workflows = wfs;
					let permission = new GenericPermissionView(
						this.roleTypes,
						this.userRoleViews,
						this.groupRoleViews,
						this.workflows,
						this.permissionLevels
					);
					this.permissions.push(permission);
				});
		}
		if (this.view === "DataSourceView") {
			this.subs.sink = this.datasourceService
				.getDatasources()
				.subscribe((ds) => {
					this.datasources = ds;
					let permission = new GenericPermissionView(
						this.roleTypes,
						this.userRoleViews,
						this.groupRoleViews,
						this.datasources,
						this.permissionLevels
					);
					this.permissions.push(permission);
				});
		}
		if (this.view === "SchedulerView") {
			this.subs.sink = this.scheduleService
				.getScheduleActionPlan()
				.subscribe((plans) => {
					this.schedules = plans;
					let permission = new GenericPermissionView(
						this.roleTypes,
						this.userRoleViews,
						this.groupRoleViews,
						this.schedules,
						this.permissionLevels
					);
					this.permissions.push(permission);
				});
		}
		if (this.view === "DestinationView") {
			this.subs.sink = this.destinationService
				.getDataStoreObjectList()
				.subscribe((dest) => {
					this.destinations = dest;
					let permission = new GenericPermissionView(
						this.roleTypes,
						this.userRoleViews,
						this.groupRoleViews,
						this.destinations,
						this.permissionLevels
					);
					this.permissions.push(permission);
				});
		}
        if (this.view === "Views") {
			this.subs.sink = this.authService
				.getViewInfoRow()
				.subscribe((vi) => {
					this.viewInfos = vi;
					let permission = new GenericPermissionView(
						this.roleTypes,
						this.userRoleViews,
						this.groupRoleViews,
						this.viewInfos,
						this.permissionLevels
					);
					this.permissions.push(permission);
				});
		}

	}

	deleteRow(permission: GenericPermissionView) {
		this.permissions = [
			...this.permissions.filter((item) => item !== permission),
		];
	}

	onCreatePermission(event:PointerEvent) {
		try {
			this.createPermissions();
		}catch(e) {

		}
	}

	createPermissions() {
		this.savingInProgress = true;
		// Create Permissions Arguments

		if (!this.permissions || this.permissions.length === 0) {
			this.systemLogService.handleError(new GuiErrorException(GuiErrorCode.entryIncomplete));
			return;
		}

		const create_permission_obs = this.permissions.map((views) => {
			if (views.selectedAccessible === undefined) {
				this.systemLogService.handleError(new GuiErrorException(GuiErrorCode.noObjectSelected));
			}
			if (views.selectedRoleId === undefined) {
				this.systemLogService.handleError(new GuiErrorException(GuiErrorCode.noRoleSelected));
			}
			if (views.rights === undefined || views.rights.length === 0) {
				this.systemLogService.handleError(new GuiErrorException(GuiErrorCode.noRightsSelected));
			}

            let obMain = new Observable();
			const selected_role = Id.assertSet(views.selectedRoleId, new Error("You must select a user role or group"));

			//TODO: Check current object class to cast to right class type -> DS,WF,VIEW,SCHED,CUBE
            if(this.view === "WorkflowView") {
                const wf_object = <WorkflowRepositoryEntry>views.selectedAccessible;
                const wf_acc = new WorkflowAccessible(wf_object.id);

                const perm_obj = new WorkflowPermission(
                    -1,
                    selected_role.RightHolder.ID,
                    views.isOwnerFlag,
                    wf_acc,
                    views.rights
                );
                const ob = this.authService.createWorkflowPermission(perm_obj);
                obMain = ob;
            }
            if(this.view === "DataSourceView") {
                const ds_object = <DataSource>views.selectedAccessible;
                const ds_acc = new DataSourceAccessible(ds_object.id);

                const perm_obj = new DataSourcePermission(
                    -1,
                    selected_role.RightHolder.ID,
                    views.isOwnerFlag,
                    ds_acc,
                    views.rights
                );
                const ob = this.authService.createDataSourcePermission(perm_obj);
                obMain =  ob;
            }
			if(this.view === "SchedulerView") {
                const ds_object = <ScheduleActionPlan>views.selectedAccessible;
                const ds_acc = new ActionPlanAccessible(ds_object.id);

                const perm_obj = new ActionPlanPermission(
                    -1,
                    selected_role.RightHolder.ID,
                    views.isOwnerFlag,
                    ds_acc,
                    views.rights
                );
                const ob = this.authService.createActionPlanPermission(perm_obj);
                obMain =  ob;
            }
			if(this.view === "DestinationView") {
                const ds_object = <DataStore>views.selectedAccessible;
                const ds_acc = new DataStoreAccessible(ds_object.id);

                const perm_obj = new DataStorePermission(
                    -1,
                    selected_role.RightHolder.ID,
                    views.isOwnerFlag,
                    ds_acc,
                    views.rights
                );
                const ob = this.authService.createDataStorePermission(perm_obj);
                obMain =  ob;
            }
            if(this.view === "Views") {
                const vi_object = <ViewInfoRow>views.selectedAccessible;
                const vi_acc = new ViewAccessible(vi_object.name);

                const perm_obj = new ViewPermission(
                    -1,
                    selected_role.RightHolder.ID,
                    views.isOwnerFlag,
                    vi_acc,
                    views.rights
                );
                const ob = this.authService.createViewPermission(perm_obj);
                obMain = ob;
            }
            return obMain
		});

		// Create Permissions
		forkJoin(create_permission_obs).subscribe(
			(permission_results) => {
				// TODO: Look at results
				this.savingInProgress = false;
				this.authService.permissionsChangedEmitter.emit();
				this.clearDialog();
				this.displayDialog = false;
			},
			(err) => {
				this.savingInProgress = false;
				this.systemLogService.handleError(err);
			}
		);
	}

	clearDialog() {
		this.permissions = [];
	}

	constructor(
		private userService: UserService,
		private workflowsService: WorkflowsService,
		private datasourceService: DatasourcesService,
		private destinationService: CubesService,
		private scheduleService: SchedulesService,
		private authService: AuthorizationService,
		private systemLogService: SystemMessageLogService
	) {}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngOnInit(): void {
		this.subs.sink = this.userService
			.getPermissionLevels()
			.subscribe((levels) => {
				this.permissionLevels = levels;
			},
			(err) => {
				this.systemLogService.handleError(err);
			});
		this.subs.sink = this.workflowsService.selectedWorkflowEmitter.subscribe(
			(wf) => {
				this.selectedWorkflow = wf;
			},
			(err) => {
				this.systemLogService.handleError(err);
			}
		);
		this.roleTypes = ["User Role", "Group Role"];
		this.subs.sink = this.userService
			.getUser()
			.subscribe((userinfos: UserInfo[]) => {
				this.users = userinfos;
			},
			(err) => {
				this.systemLogService.handleError(err);
			});
		this.subs.sink = this.userService.displayAddPermission.subscribe((res) => {
			this.displayDialog = res;
			this.headerText = "Permission.AddPermission";
			this.initPermissionView();
		},
		(err) => {
			this.systemLogService.handleError(err);
		});
		this.subs.sink = this.authService
			.getWorkflowRights()
			.subscribe((res: string[]) => {
				this.rights = res;
			},
			(err) => {
				this.systemLogService.handleError(err);
			});
	}

	initPermissionView() {
		const user_role_view_obs = this.authService.getUserRoleViews();
		const group_role_view_obs = this.authService.getGroupRoleViews();


		this.subs.sink = forkJoin(
			user_role_view_obs,
			group_role_view_obs,
		).subscribe((role_views) => {
			this.userRoleViews = role_views[0];
			this.groupRoleViews = role_views[1];

			const rh_1: RightHolderView<RightHolder>[] = this.userRoleViews;
			const rh_2: RightHolderView<RightHolder>[] = this.groupRoleViews;

			this.rightHolderViews = rh_1.concat(rh_2);

		},
		(err) => {
			this.systemLogService.handleError(err);
		});
	}
}
