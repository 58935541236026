<p-dialog header="{{headerText | translate}}" [(visible)]="displayDialog" modal="modal" (onHide)="clearDialog()"
    showEffect="fade" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '600px'}">
    <div class="p-grid p-ai-center p-jc-center">

        <div *ngIf="selectedPermissionView" class="p-grid" style="width:100%;margin-top: 20px">
            <div class="p-col-12">
                <div class="p-fluid">
                    <span class="p-float-label">
                        <p-dropdown [disabled]="true" pTooltip="Cannot be changed" [options]="roleTypes"
                            [(ngModel)]="selectedPermissionView.roleType" editable="true"></p-dropdown>
                        <label for="RoleTypeObject">{{'Role Type' | translate}}</label>
                    </span>
                </div>
            </div>
            <div class="p-col-12">
                <div class="p-fluid">
                    <span class="p-float-label">
                        <p-dropdown [options]="rightHolderViews" [disabled]="true"  [(ngModel)]="selectedRightHolderView"
                            optionLabel="label"></p-dropdown>
                        <label for="RoleObject">{{'Role' | translate}}</label>
                    </span>
                </div>
            </div>

            <div class="p-col-12 p-md-6">
                <div class="p-fluid">
                    <span class="p-float-label">
                        <p-dropdown pTooltip="Cannot be changed" [disabled]="true" [options]="accessibles"
                            [(ngModel)]="selectedAccessible" optionLabel="name"></p-dropdown>
                        <label for="AccessibleObject">{{'Accessible' | translate}}</label>
                    </span>
                </div>
            </div>

            <div class="p-col-12 p-md-6">
                <div class="p-fluid">
                    <span class="p-float-label">
                        <p-multiSelect appendTo="body" [options]="rights"
                            [(ngModel)]="selectedPermissionView.permission.Rights" editable="false"></p-multiSelect>
                        <label for="PermissionLevel">{{'Rights' | translate}}</label>
                    </span>
                </div>
            </div>

            <!-- <div class="p-col-12">
                <div *ngFor="let right of rights" style="text-align: center;">
                    <p-checkbox name="checkboxGroup" value="right" [value]="right" [(ngModel)]="permissionView.rights"  [inputId]="right"></p-checkbox>
                </div>
            </div> -->

            <div class="p-col-12">
                <p-checkbox binary="true" [disabled]="true" [(ngModel)]="selectedPermissionView.permission.IsOwner"
                    label="{{'IsOwner' | translate}}"> </p-checkbox>
            </div>
        </div>
    </div>
    <app-system-message-log></app-system-message-log>

    <p-footer>
        <!-- <button type="button" pButton icon="pi pi-check"  label="Change" (click)="onChangePermission($event)"
            class="p-button-text"></button>
        <button type="button" pButton icon="pi pi-times" (click)="displayDialog=false" label="Cancel"
            class="p-button-text"></button> -->
        <button pButton pRipple type="button" icon="pi pi-times" (click)="displayDialog=false"
            class="p-button-rounded p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'No' | translate}}"></button>
        <button pButton pRipple type="button" icon="pi pi-check" (click)="onChangePermission($event)"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'Yes' | translate}}" [icon]="savingInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'"></button>
    </p-footer>
</p-dialog>
