<div style="z-index: 1000000;">
    <p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
    </p-toast>
</div>
<div class="spinner-container" *ngIf="isLoading" @fade>
    <app-loading-screen></app-loading-screen>
</div>

<p-dialog header="{{'Delete' | translate}}" [(visible)]="displayDeleteDatasource" modal="modal" showEffect="fade"
    [style]="{width: '600px'}">
    <div style="line-height: 1.5">
        {{'DeleteDatasource?' | translate}}
    </div>
    <app-system-message-log></app-system-message-log>

    <p-footer>
        <button pButton pRipple type="button" icon="pi pi-times" (click)="displayDeleteDatasource=false"
            class="p-button-rounded p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'No' | translate}}"></button>
        <button pButton pRipple type="button" icon="pi pi-check" (click)="on_delete_source(selectedInfo)"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'Yes' | translate}}" [icon]="isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
            [disabled]="buttonDisabled"></button>
    </p-footer>
</p-dialog>


<div style="display:flex;align-items: center;margin-bottom: 20px">
    <img [alt]="'HeaderIcon'" src="assets/layout/images/HeaderIcon_DS.svg" width="50" style="vertical-align: middle" />
    <h2 style="margin: 0px 10px;color:#6941C6;font-weight: bold;">{{'Datasources' | translate }}</h2>
</div>

<!-- <p-table [value]="infos">
    <ng-template pTemplate="header">
        <tr>
            <th>ID</th>
            <th>Origin</th>
            <th>Name</th>
            <th>Type</th>
            <th>Description</th>
            <th>Stream</th>
            <th>Sync</th>
            <th>Drop</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-src>
        <tr>
            <td>{{src.Id.Id}}</td>
            <td>{{src.Id.Origin}}</td>
            <td>
                <a routerLink="/SourceIntegrationSource/{{src.Id.Origin}}/{{src.Id.Id}}"
                    routerLinkActive="active" ariaCurrentWhenActive="page">{{src.Name}}</a>
            </td>
            <td>{{src.ConnectorInfo?.Name}}</td>
            <td>{{src.Description}}</td>
            <td>
                <a routerLink="/SourceIntegrationGetStreams/{{src.Id.Origin}}/{{src.Id.Id}}"
                    routerLinkActive="active" ariaCurrentWhenActive="page">View Streams</a>
            </td>
            <td>
                <p-button *ngIf="!src.ConnectorInfo?.IsPush" label="Sync" (onClick)="on_sync_source(src)"></p-button>
                <p-button *ngIf="src.ConnectorInfo?.IsPush" label="Sync File" (onClick)="on_sync_file_source(src)"></p-button>
            </td>
            <td>
                <p-button class="p-button-danger" label="Drop" severity="danger" (onClick)="on_delete_source(src)"></p-button>
            </td>
        </tr>
    </ng-template>
</p-table> -->

<div style="height:4px">
    <p-progressBar *ngIf="isLoading" [mode]="progressMode" [style]="{'height': '4px'}"></p-progressBar>
</div>
<!-- <div class="flex justify-content-center">
    <p-breadcrumb class="max-w-full" [model]="items"></p-breadcrumb>
</div> -->
<p-messages [(value)]="messages" [enableService]="false"></p-messages>
<div class="card">
    <p-table #dt [value]="infos" [(selection)]="selectedInfo" (onRowSelect)="onSelectInfo($event)"
        (onRowUnselect)="onUnselectInfo($event)" selectionMode="single" sortMode="single"
        [globalFilterFields]="['datasource.object.id','datasource.connector.Connector','usePsa','age']" [rows]="20"
        paginator=true styleClass="p-datatable-customers p-datatable-sm">
        <ng-template pTemplate="caption">

            <div class="flex align-items-center justify-content-between">

                <button pButton pRipple label="{{'Create datasource' | translate }}"
                    class="p-button-rounded p-mr-2 p-mb-2" (click)="onCreateSource()"></button>

                <div class="ui-table-buttongroup-right" *ngIf="selectedInfo" style="float:right">
                    <button pButton pRipple *ngIf="!selectedInfo.ConnectorInfo?.IsPush" label="{{'Sync' | translate }}"
                        (click)="on_sync_source(selectedInfo)" type="button" icon="pi pi-refresh"
                        class="p-button-rounded p-button-text p-button-success p-mr-2 p-mb-2"
                        pTooltip="{{'PullData' | translate }}"></button>
                    <button pButton pRipple type="button" icon="pi pi-upload"
                        *ngIf="selectedInfo?.ConnectorInfo?.IsPush" label="{{'Upload' | translate }}"
                        (click)="on_sync_file_source(selectedInfo)"
                        class="p-button-rounded p-button-text p-button-success p-mr-2 p-mb-2"
                        pTooltip="{{'UploadFile' | translate }}"></button>
                    <!-- <button pButton pRipple type="button" icon="pi pi-copy"
                    class="p-button-rounded p-button-text p-mr-2 p-mb-2"
                    label="{{'Duplicate selected' | translate }}"
                    pTooltip="{{'Duplicate' | translate }}"
                    ></button> -->
                    <button pButton pRipple type="button" icon="pi pi-trash"
                        class="p-button-rounded p-button-text p-button-danger p-mr-2 p-mb-2"
                        (click)="displayDeleteDatasource=true" label="{{'Delete selected' | translate }}"
                        pTooltip="{{'Delete datasource' | translate }}"></button>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th *ngFor="let col of cols" [width]="col.width" [pSortableColumn]="col.field">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{col.header | translate }}
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                    </div>
                </th>
                <th width="25px">
                    <div class="p-d-flex p-jc-between p-ai-center">
                    </div>
                </th>
            </tr>
        </ng-template>
        <!-- <ng-template pTemplate="groupheader" let-datasource>
        <tr pRowGroupHeader>
            <td colspan="4" [pSelectableRow]="" >
                <img [alt]="datasource.ConnectorInfo.Icon" src="assets/layout/images/connectors/{{datasource.ConnectorInfo.Icon}}.png" width="32" style="vertical-align: middle" />
                <span class="font-bold ml-2" style="margin-left: 10px; font-weight: bold;">{{datasource.ConnectorInfo?.Name}}</span>
            </td>
        </tr>
    </ng-template> -->
        <ng-template pTemplate="body" let-datasource let-rowIndex="rowIndex">
            <tr [pSelectableRow]="datasource" class="icon-highlight">
                <td>
                    <span class="p-column-title">{{'Status' | translate }}</span>
                    <span style="display:flex;align-items: center;">
                        <i *ngIf="datasource.PrimaryConnection?.IsSyncing" class="pi pi-spin pi-spinner"></i>
                    </span>
                </td>
                <td>
                    <span class="p-column-title">{{'Name' | translate }}</span>
                    <span style="display:flex;align-items: center;">
                        <span style="margin-left:0px; margin-right: 30px">
                            <!-- <a routerLink="/SourceIntegrationSource/{{datasource.Id.Origin}}/{{datasource.Id.Id}}"
                        routerLinkActive="active" ariaCurrentWhenActive="page">{{datasource.Name}}</a> -->
                            {{datasource.Name}}
                        </span>
                    </span>
                </td>
                <td>
                    <span class="p-column-title">{{'Connector' | translate }}</span>
                    <span style="display:flex;align-items: center;">
                        <span style="display:flex;align-items: center;">

                            <img [style.max-width.px]="'25'" [style.max-height.px]="'25'"
                                *ngIf="!datasource.ConnectorInfo?.Icon"
                                src="assets/layout/images/connectors/{{datasource.ConnectorInfo?.Name}}.png"
                                style="margin-bottom: 5px; margin-top: 5px" [alt]="datasource.ConnectorInfo?.Name" />
                            <div *ngIf="datasource.ConnectorInfo?.Icon" [style.max-width.%]="'25'"
                                [style.max-height.%]="'25'" [innerHTML]="getSafeHtml(datasource.ConnectorInfo?.Icon)">
                            </div>
                            <span style="margin-left: 10px;">{{datasource.ConnectorInfo?.Name}}</span>
                        </span>
                    </span>
                </td>
                <td><span class="p-column-title">{{'Shared with' | translate }}</span>
                    <div style="display:flex;align-items: center;">
                        <p-avatarGroup styleClass="mb-3">
                            <p-avatar *ngFor="let i of datasource.images" [image]="i" size="large"
                                shape="circle"></p-avatar>
                            <p-avatar *ngIf="datasource.permsCount > 0" [label]="datasource.permsCount" shape="circle"
                                size="large" [style]="{ 'background-color': '#9c27b0', color: '#ffffff' }"></p-avatar>
                        </p-avatarGroup>
                    </div>
                </td>
                <td>
                    <span class="p-column-title">{{'LastSync' | translate }}</span>
                    <span style="display:flex;align-items: center;">
                        <span style="margin-left:10px; margin-right: 30px">
                            {{datasource.PrimaryConnection?.LatestSyncJob?.CreatedAt | date: 'short'}}
                        </span>
                    </span>
                </td>
                <td>
                    <span style="display:flex;align-items: center;">
                        <span style="margin-left:10px; margin-right: 30px">
                            <button pButton class="p-button-rounded"
                                style="background-color: rgba(255, 255, 255, 0.712);color:#7F56D9" icon="pi pi-eye"
                                pTooltip="{{'View' | translate }}" (click)="onViewSource(datasource)"></button>
                        </span>
                    </span>
                </td>


                <!-- <td><span class="p-column-title">{{'LastUpdated' | translate }}</span>
                <div style="display:flex;align-items: center;">
                    <span style="margin-right: 10px">{{datasource.lastUpdated.start | date: 'short'}}</span>
                </div>
            </td>
            <td><span class="p-column-title">{{'by' | translate}}</span>
                <p-chip *ngIf="datasource.image === undefined" label="{{datasource.lastUpdated?.user}}"
                    image="assets/layout/images/avatar-placeholder.png">
                </p-chip>
                <p-chip *ngIf="datasource.image  !== undefined" label="{{datasource.lastUpdated?.user}}"
                    [image]="datasource.image"></p-chip>
            </td> -->
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td style="align-items: center;" colspan="4">{{'NoEntryFound' | translate }}. <a
                        (click)="onCreateSource()">{{'Create new entry' |
                        translate}}</a></td>
            </tr>
        </ng-template>
    </p-table>
</div>


<app-push-int-source-dialog [(showPushFileDialog)]="show_file_dialog" [sourceKey]="file_dialog_source?.Id"
    [source]="file_dialog_source">

</app-push-int-source-dialog>