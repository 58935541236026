<p-overlayPanel #op1 [showCloseIcon]="false">
    <ng-template pTemplate>
        <div style="float: left">
            <button pButton pRipple type="button" icon="pi pi-cog" class="p-button-rounded p-button-text p-mr-2 p-mb-2"
                pTooltip="{{'Actions.ChangeAction' | translate}}" (click)="displayEditActionDialog()"></button>
            <button pButton pRipple type="button" icon="pi pi-trash" pTooltip="{{'Actions.DeleteAction' | translate}}"
                (click)="displayDeleteActionDialog()"
                class="p-button-rounded p-button-danger p-button-text p-mr-2 p-mb-2"></button>
        </div>
    </ng-template>
</p-overlayPanel>

<p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>

<div style="margin:20px 0">
    <h5>
        {{'Actions' | translate }} <app-info-tooltip></app-info-tooltip>
        <span style="float: right">
            <button style="float: right" pButton pRipple type="button" icon="pi pi-plus"
            label="{{'Create' | translate}}" class="p-button-rounded p-button-text p-mr-2 p-mb-2"
            pTooltip="{{'Actions.CreateNewAction' | translate}}" (click)="displayNewActionDialog()"></button>
        </span>
    </h5>
</div>

<div class="card">
    <p-table #dt [value]="baseActionsFiltered" (onRowReorder)="onRowOrderChanged(baseActionsFiltered, $event)" sortField="data" sortMode="single" dataKey="data.id">
        <ng-template pTemplate="header">
            <tr>
                <th  style="width: 10rem"></th>
                <!-- <th style="width:20%">{{'Order' | translate}}</th> -->
                <th style="width:100%">{{'Action' | translate}}</th>
                <th  style="width: 4rem">
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-actionTreeNode  let-index="rowIndex">
            <tr [pReorderableRow]="index" >

            <!-- <td pReorderableRowHandle > <span class="pi pi-bars"></span>
            </td> -->
            <td  pReorderableRowHandle>
                <span><span class="pi pi-bars"></span>
                    {{actionTreeNode.index+1}}.</span>
            </td>
            <td><span class="p-column-title">{{'Type' | translate}}</span>

                <span *ngIf="actionTreeNode._type === ExtractType">
                    <p-tag severity="success"> {{'Extract Datasource' | translate}} {{actionTreeNode.dataSource}}
                    </p-tag>
                </span>
                <span *ngIf="actionTreeNode._type === WorkflowType">
                    <p-tag severity="warning">{{'Run Workflow' | translate}} {{actionTreeNode.workflow}}
                    </p-tag>
                </span>
            </td>
            <td  pReorderableRowHandle>
                <div class="ui-table-buttongroup-right">
                    <button pButton pRipple type="button" icon="pi pi-ellipsis-v"
                        class="p-button-rounded p-button-text p-mr-2 p-mb-2"
                        pTooltip="{{'Actions.ChangeAction' | translate}}" (click)="op1.toggle($event)"
                        (click)="onMenuClicked(actionTreeNode)"></button>
                </div>
            </td>
        </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="3">{{'NoEntryFound' | translate}}</td>
            </tr>
        </ng-template>
    </p-table>


</div>

