import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";
import { Message } from "primeng/api";

@Injectable({
	providedIn: "root",
})
export class BionMessageService {
	messages: Message[];

	constructor(private messageService: MessageService) {}

	standardToastMessage(parameter) {
		this.messageService.add({
			key: "tst",
			severity: "success",
			summary: "Default",
			detail: "default",
			sticky: false,
			...parameter,
		});
	}
	addMessage(msg: Message) {
		this.messageService.add(msg);
	}
	clearMessages() {
		this.messages = [];
	}
}
