<p-dialog header="{{headerText | translate}}" [(visible)]="displayNewUser" modal="modal" (hide)="resetForms()"
    showEffect="fade" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '800px'}">
    <app-system-message-log></app-system-message-log>
    <div class="p-grid p-ai-center p-jc-center" style="margin-top: 10px">
        <div class="p-col-12">
            <div>
                <div class="p-grid">
                    <div class="p-col-12  p-md-12">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <input type="text" id="username" pInputText [(ngModel)]="form.username">
                                <label for="username">{{'Username' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-6">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <input type="text" id="firstName" pInputText [(ngModel)]="form.firstName">
                                <label for="firstName">{{'FirstName' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-6">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <input id="lastName" type="text" pInputText [(ngModel)]="form.lastName">
                                <label for="lastName">{{'LastName' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <input id="email" type="text" pInputText [(ngModel)]="form.email">
                                <label for="email">{{'EMail' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <p-multiSelect [options]="groupRoles" id="groupMultiSelect"
                                    [(ngModel)]="form.selectedGroupRoles" optionLabel="Name"></p-multiSelect>
                                <label for="groupMultiSelect">{{'AssignToGroup' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <!-- <div class="p-field p-col-12 p-md-12">
                        <label for="username">Username</label>
                        <input type="text" id="username" pInputText [(ngModel)]="form.username">
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="firstName">Firstname</label>
                        <input type="text" id="firstName" pInputText [(ngModel)]="form.firstName">
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="lastName">Lastname</label>
                        <input id="lastName" type="text" pInputText [(ngModel)]="form.lastName">
                    </div>
                    <div class="p-field p-col-12">
                        <label for="email">E-Mail</label>
                        <input type="text" id="email" pInputText [(ngModel)]="form.email">
                    </div> -->
                    <div class="p-field p-col-12 p-md-12">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input pInputText [disabled]="form.isAzure === false" type="text" class="form-control"
                                    name="azureEmail" [(ngModel)]="form.azureEmail" id="emailAzure" />
                                <label for="emailAzure">{{'AzureEmail' | translate}}</label>
                            </span>
                            <span class="p-inputgroup-addon">
                                <p-checkbox (onChange)="setAccount($event)" binary=true [(ngModel)]="form.isAzure">
                                </p-checkbox>
                            </span>
                        </div>
                    </div>

                    <div *ngIf="isNew" class="p-col-12 p-md-12">
                        <div class="p-fluid">
                            <span class="p-float-label">
                                <input id="password" type="password" pInputText [(ngModel)]="form.password">
                                <label for="password">{{'InitialPassword' | translate}}</label>
                            </span>
                        </div>
                    </div>
                    <!-- <div class="p-field p-col-12">
                        <p-checkbox [binary]="true" [(ngModel)]="form.isAdmin" label="{{'GrantAdminRights' | translate}}"></p-checkbox>
                    </div> -->
                </div>
            </div>
        </div>

    </div>

    <p-footer>
        <button pButton pRipple type="button" icon="pi pi-times" (click)="displayNewUser=false"
            class="p-button-rounded p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'No' | translate}}"></button>
        <button pButton pRipple type="button" icon="pi pi-check" (click)="validateForms()"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'Yes' | translate}}" [icon]="savingInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'"></button>
    </p-footer>
</p-dialog>
