import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { map } from 'rxjs/operators';
import { PowerBiActionEvent, PowerBiDialogActionType } from 'src/app/models/api/models/powerbi-report/PowerBiActionEvent';
import { PowerBiEntry } from 'src/app/models/api/models/powerbi-report/PowerBiEntry';
import { WorkflowRepositoryEntry } from 'src/app/models/api/models/workflow/WorkflowRepositoryEntry';
import { PowerBiReportService } from 'src/app/services/power-bi-report.service';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-delete-powerbi-report',
  templateUrl: './delete-powerbi-report.component.html',
  styleUrls: ['./delete-powerbi-report.component.scss']
})
export class DeletePowerbiReportComponent implements OnInit {

	subs = new SubSink();
	savingInProgress: boolean = false;
	displayDialog: boolean = false;
	selectedReport?: PowerBiEntry;
  header: string;

	constructor(
		private biService: PowerBiReportService,
		private systemLogService: SystemMessageLogService,
		private messageService: MessageService,
		private translate: TranslateService
	) {}

	ngOnInit(): void {
		this.subs.sink = this.biService.PowerBiActionEmitter.subscribe(
			(res: PowerBiActionEvent) => {
				if(res.actionType === PowerBiDialogActionType.deleteReport) {
          console.log("EVENT: DELETE REPORT", res);
					this.selectedReport = res.report;
          this.header = res.header;
					this.displayDialog = res.display;
				}
			}, (err: Error) => {
				this.systemLogService.handleError(err)
			}
		);
	}
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	deleteReport(event?: PowerBiEntry) {

		if(event === undefined) return;

		this.savingInProgress = true;
		this.subs.sink = this.biService.deleteReportInfo(event.id).subscribe(
			(res) => {
				this.savingInProgress = false;
        this.biService.PowerBiActionEmitter.emit(new PowerBiActionEvent(false,"Report Changed",PowerBiDialogActionType.ReportsChanged,"Changed"));

				this.messageService.add({
					severity: "success",
					summary: this.translate.instant("Message.DeleteReportSuccess.Title"),
					detail: this.translate.instant("Message.DeleteReportSuccess.Text1") + event.id + this.translate.instant("Message.DeleteReportSuccess.Text2"),
				});

				this.displayDialog = false;
			},
			(err: Error) => {
				this.savingInProgress = false;
				this.systemLogService.handleError(err);
			}
		);
	}

}
