<div class="p-grid p-fluid p-formgrid">
    <div class="p-col-12" style="margin-top: 40px;">
        <div class="p-fluid">
            <span class="p-float-label">
                <textarea style="min-height:7rem;" id="dsdescriptiontext" (blur)="onConfigChanged()" [cols]="30"
                    pInputTextarea [(ngModel)]="destinationDescription" autoResize="autoResize"></textarea>
                <label for="dsdescriptiontext">{{'Description' | translate}}</label>
            </span>
        </div>
    </div>

    <div class="p-col-12" style="margin-top: 40px;">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown [options]="writeModes" optionLabel="name" id="selectedWritemodeInput"
                    (onChange)="onConfigChanged()" [(ngModel)]="selectedWritemode"></p-dropdown>
                <label for="selectedWritemodeInput">{{'Select a write mode' | translate}}</label>
            </span>
        </div>
    </div>
</div>

<!-- <div id="settingsView" [ngSwitch]="selected_settings_view">
        <app-excel-adapter *ngSwitchCase="EXCEL_ADAPTER" [config]="model" (configChanged)="onConfigChanged($data)"></app-excel-adapter>
        <app-csv-adapter *ngSwitchCase="CSV_ADAPTER" [config]="model" (configChanged)="onConfigChanged($data)"></app-csv-adapter>
        <div *ngSwitchDefault="">Für diesen Konnektor gibt es keine Konfigurationsanzeige</div>
    </div> -->

<div class="fixed-button">
    <p-button raised="true" (onClick)="submitClicked()"
        [label]="buttonDisabled ? 'Destination anlegen' : 'Bitte ausfüllen'" [disabled]="!buttonDisabled"></p-button>
</div>