import { Component, OnInit } from '@angular/core';
import { Id } from 'src/app/helper/id';
import { TrimPlugIn } from 'src/app/models/api/com/bion/etl/transformation/TrimPlugIn';
import { FieldInfo, WorkflowNodeSettings } from 'src/app/models/api/com/bion/etl/Workflow';
import { DesignerService } from 'src/app/services/designer.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { NodeConfigComponentBase } from '../node-config-component-base';
import { NodeMetaData } from 'src/app/models/api/com/bion/etl/NodeMetaData';

export class TrimInfoView {
	ColumnName: string;
	RemoveCharacters: string;
	hasError?: boolean;

	constructor(ColumnName: string, RemoveCharacters: string, hasError?: boolean) {
		this.ColumnName = ColumnName;
		this.RemoveCharacters = RemoveCharacters;
		this.hasError = hasError
	}
}

@Component({
  selector: 'app-trim-node',
  templateUrl: './trim-node.component.html',
  styleUrls: ['./trim-node.component.scss']
})
export class TrimNodeComponent  extends NodeConfigComponentBase<TrimPlugIn.Settings>  implements OnInit {
  settingsToView(settings: WorkflowNodeSettings): void {
		// -- Get Configuration
		let currentConfig = <TrimPlugIn.Settings>settings;
		// -- Get Input Meta Data
		const meta_infos =
			this._currentWorkflowNodeAdapter.IWorkflowNode.Properties.MetaInfo.get(
				this.InputPort
			);

		let columnListArray: string[] = [];

		// create SelectInfoViews from DsFields for table
		if (meta_infos != undefined && meta_infos.length > 0) {
			const input_field_infos = meta_infos[0].FieldsInfo;

			columnListArray = input_field_infos.map((fieldInfo) => {
				return fieldInfo.Name;
			});
			this.columnList = columnListArray;
		}

		// --If currentConfig not available (-> new node)
		if (currentConfig == undefined) {
			this.trimInfoList = [];
			//this.selectedMatchMode = this.matchModeOptions[0];
		} else {
			// -- otherweise load existing config into view
			if (!currentConfig.TrimInfos) return;

			currentConfig.TrimInfos.map((info) => {
				if (columnListArray.includes(info.ColumnName)) {
					// -- column still exists, do nothing
				} else {
					// column does not exist anymore, meaning column name currently not included -> include
					columnListArray.push(info.ColumnName);
				}
			});
			this.columnList = columnListArray;
            if(meta_infos !== undefined && meta_infos[0] !== undefined) {
                this.trimInfoList = this.infoToInfoView(
                    currentConfig.TrimInfos,
                    meta_infos[0].FieldsInfo
                );
            }

		}
  }
  viewToSettings(): TrimPlugIn.Settings {
		const newSettings = <TrimPlugIn.Settings>{
			...this.getCurrentWorkflowNode().Properties.Configuration,
		};
		const sortInfoViews = this.trimInfoList;
		const sortInfos = this.infoViewToInfo(sortInfoViews);

		newSettings.TrimInfos = sortInfos;

		// this.messageService.standardToastMessage({  severity : 'success',
		//                                                 summary : 'Filter successsfully added',
		//                                                 detail : 'New Filter was successfully added to the node.'});

		return newSettings;
  }
  onSettingsChanged(settings: TrimPlugIn.Settings): void {
    throw new Error('Method not implemented.');
  }

  constructor(
    protected workflowService: WorkflowsService,
    protected designerService: DesignerService
) {
    super(workflowService, designerService);
}
  ngOnInit(): void {
    super.ngOnInit();
  }

  trimInfoList: TrimInfoView[] = [];
	columnList: string[] = [];


	readonly InputPort = "Input";

	onAddFilter() {
		const myColumnList:NodeMetaData[] | undefined = this.getCurrentWorkflowNode().Properties.MetaInfo.get(
			this.InputPort
		);

		// if (myColumnList === undefined || myColumnList.length === 0) {
		// 	//TODO: Add User notification that no input fields available
		// 	return;
		// }

		let newFilter = new TrimInfoView("","", false);
		// newFilter.ColumnName = "";
		// newFilter.SortType = this.dropDownSortMethod[0];
		// newFilter.hasError = false;

		this.trimInfoList.push(newFilter);
		this.infoViewToInfo(this.trimInfoList);
		this.onUpdateSettings(false);
	}
	onDeleteEntry(index: number) {
		this.trimInfoList = [
			...this.trimInfoList.slice(0, index),
			...this.trimInfoList.slice(index + 1),
		];
		this.onUpdateSettings(false);
		// this.messageService.standardToastMessage({  severity : 'warn',
		//                                                 summary : 'Filter deleted',
		//                                                 detail : 'The selected filter was deleted successfully!'});
	}
	infoToInfoView(list: TrimPlugIn.TrimInfo[], meta?: FieldInfo[]): TrimInfoView[] {
		let newInfosViewsArray: TrimInfoView[] = [];

		list.map((trimInfo: TrimPlugIn.TrimInfo) => {

			// -- create selected SortMethod from config
			let remove_chars = trimInfo.RemoveChars;
			const remove_chars_given = Id.assertSet(remove_chars, new Error("Remove Chars not found, should not happen"));

			let fieldInfoFound = meta?.find((field) => {
				return field.Name === trimInfo.ColumnName;
			});

			let newInfoView = new TrimInfoView(trimInfo.ColumnName,remove_chars_given,!fieldInfoFound);

			newInfosViewsArray.push(newInfoView);
		});
		// -- return final view to GUI
		return newInfosViewsArray;
	}
	infoViewToInfo(sortList: TrimInfoView[]) {
		// -- create new empty array
		let newInfos: TrimPlugIn.TrimInfo[] = [];

		sortList.map((entry) => {
			let newSettings = new TrimPlugIn.TrimInfo(entry.ColumnName,entry.RemoveCharacters);
			newSettings.ColumnName = entry.ColumnName;
			newSettings.RemoveChars = entry.RemoveCharacters;
			newInfos.push(newSettings);
		});
		return newInfos;
	}

}
