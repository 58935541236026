import {
	Component,
	Input,
	OnInit,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { AppMainComponent } from "./app.main.component";


@Component({
	selector: "app-rightpanel",
	templateUrl: "./app.rightpanel.component.html",
	providers: [MessageService]
})
export class AppRightpanelComponent implements OnInit {
    @Input() rightPanelActive: boolean = false;

	constructor(
		public appMain: AppMainComponent,
		public messageService: MessageService
	) {

	}

	ngOnInit(): void {

	}


}
