

// SQL Designer Component & Control Sketches

import { EventEmitter } from "@angular/core";
import { Observable } from "rxjs";
import { NodeProperties, Workflow, WorkflowNode, WorkflowNodeSettings } from "src/app/models/api/com/bion/etl/Workflow";
import { UserInfo } from "src/app/models/api/models/session/UserInfo";
import { WorkflowRepositoryEntry } from "src/app/models/api/models/workflow/WorkflowRepositoryEntry";
import { WorkflowNodeInfo } from "src/app/models/designer.models";
import { GoNode } from "./graph-view/goJs.model";

/**
 * Designer Mutter Komponente
 */
export interface SQLWorkflowView {
    workflows: WorkflowRepositoryEntry[];
}


export enum ToolbarCommands {
    Save,
    Load,
    Execute,
    UpdateSettings,
    ExecuteTill,
    UpdateSettingsTill,
    zoomOut //analog auch andere
}

/**
 * Shape eines Command Events
 */
export interface ToolbarCommand {

    Command:ToolbarCommands;
    Enabled:boolean;
    IconPath?:string;
    onAction?(context:any) : Observable<boolean>;
}

/**
 * Ich bin das generische Event Objekt!
 */
export interface EventObject<EvtType, SenType, DType> {
    Type: EvtType;
    Sender: SenType;
    Data: DType;
}

export enum WorkflowGraphEvents {
    NodeSelected,
    NodeSettingsChanged,
}

/**
 * Ich bin das ganz allgemeine Workflow Graph Event
 */
export interface WorkflowGraphEvent<SenType,DType> extends EventObject<WorkflowGraphEvents,SenType,DType> {

}

// export class NodeSelectedEvent<SenType> implements WorkflowGraphEvent<SenType, WorkflowNode> {
//     Type: WorkflowGraphEvents = WorkflowGraphEvents.NodeSelected
//     Sender: SenType;
//     Data: WorkflowNode;

//     constructor(sender:SenType, data:WorkflowNode) {
//         this.Sender = sender;
//         this.Data = data;
//     }
// }

/**
 * Ich kann graph events sehr leicht bauen
 */
export class WorkflowGraphEventFactory {
    static buildNodeClicked<SenType>(sender:SenType, node:GoNode, workflow:Workflow) : WorkflowGraphEvent<SenType,NodeSelectedData> {
        const data : NodeSelectedData = { Workflow:workflow, GoNode:node };
        return { Type:WorkflowGraphEvents.NodeSelected, Sender:sender, Data:data };
    }
    static buildNodeSettingsChanged<SenType>(sender:SenType, node:GoNode, settings:NodeProperties) : WorkflowGraphEvent<SenType,NodeSettingsChangedData> {
        const data : NodeSettingsChangedData = { GoNode:node , Settings:settings };
        return { Type:WorkflowGraphEvents.NodeSelected, Sender:sender, Data:data };
    }
}

export interface NodeSelectedData {
    Workflow:Workflow;
    GoNode:GoNode;
    Port?:string;
}

export interface NodeSettingsChangedData {
    GoNode:GoNode;
    Settings: NodeProperties;
}



/**
 * Shape eines Status Events
 */
export interface DesignerStatusEvent {
    Type: string;
    Sender: string;
    Data: any;
}

/**
 * Ich verwalte die Toolbar.
 * Ich feuere Events ab, die die Kommandos beschreiben UND höre auf gewisse Ereignisse
 */
export interface HeadToolbar {
    //commandFired:EventEmitter<ToolbarCommand>;

    setCommands(commands:ToolbarCommand[]) : Observable<boolean>;
    //setCommands(commands:ToolbarCommand[]);

    setStatusInfo(event: DesignerStatusEvent) : Observable<boolean>;
}


// /**
//  * Ich beschreibe in Plug-In... mit Bild :-)
//  */
// export interface PlugInInfo {
//     Name:string;
//     NameLabel: string;
//     Category: string;
//     Settings: string[];
//     //ComplexityLvl: NodeComplexity;
//     //UseCases: string[];
//     //NodeDescription:DescriptionLabel;
//     //Examples?: NodeExample[];
//     //IsFavourite: boolean;
// }

/**
 * Ich bin das PlugIn Child und visualisiere die PlugIns in einer view
 */
export interface PlugInView {

    plugIns:WorkflowNodeInfo[];

    /**
     * Füllt die Plug-Ins in diese Komponente und rendert sie neu.
     * @param values Plug-In Liste.
     */
    //setPlugIns(values:PlugInInfo[]) : void;
    getPlugIns() : WorkflowNodeInfo[];

    // Drag Drop ? Ist mir egal! Ich zeige nur Dinge an. Ich nehme nichts entgegen!
}

export interface GraphTabView {
    // Ich habe viele Graph Views!
    getGraphViews() : GraphView[];
    setCurrentGraphView(view: GraphView): Observable<boolean>;
    addGraphView(view:GraphView) : Observable<boolean>;

    // Ich organisiere die Tabs, erzeuge neue, bla bla, lösche, etc.
}

// export interface WorkflowNode {
//     id:string;
//     Settings: WorkflowNodeSettings
// }

// export interface Workflow {
//     id:string;
// }

export interface GraphView {
    workflow: Workflow;
    // Ich habe nur einen Graph!
    onPlugInDrop(plugIn:WorkflowNodeInfo) : Observable<void>;

    // Events
    nodeSelected:EventEmitter<WorkflowNode>;
    nodeDeleted:EventEmitter<WorkflowNode>;
    nodesCreated:EventEmitter<WorkflowNode[]>;
    workflowLoaded:EventEmitter<Workflow>;  // Ein neuer Workflow wurde geladen

    // Funktionen
    loadWorkflow(workflow:Workflow) : Observable<Workflow>;  // Der Aufrufer hat den Workflow aus dem Repo geholt und will ihn jetzt in den GraphView laden.
    getWorkflow() : Workflow;  // Die braucht der Aufrufer, um den WF zu speichern!

    // Das Backend muss in Zukunft die Graph Bearbetiung koordinieren
    // Workflow Actor! Er managed den Zugriff mehrerer Sessions auf ein Workflow, ENTWEDER
    // - indem er den Zugriff für den anderen Sperrt (nicht gut) ODER
    // - indem er den Usern anzeigt, dass parallel gearbeitet wird => Backend Event / Webhook / Websocket
}


interface WorkflowChangedEvent {

}

/**
 * Ich kümmere mich um die Plug_in Settings
 */
export interface SettingsView {

    // Triggers
    onNodeSelected(node:GoNode) : GoNode;
    onSettingsChanged<T>(node:GoNode) : Observable<void>;

    // Events
    settingsChanged:EventEmitter<WorkflowGraphEvent<string, NodeSettingsChangedData>>;  // Ich habe den WF Node geändert und schicke ihn nun!
}

export interface DataNavigation {
    Workflow:Workflow;
    Node:GoNode;
    Port:string;
    TableIndex:number;
}

/**
 * Ich zeicge die Daten pro Port und hole mir auf Anfrage die aktuellen Daten aus dem DB Backend
 */
export interface DataNodeView {
    // currentWorkflow: Workflow;
    // currentNode: WorkflowNode;
    // currentPort: string;
    // currentTableIndex: number; // nur für MultiPorts wichtig, ansonsten per default auf 0
    currentNavigation?:DataNavigation;
    dataMode: string; // entweder Record oder Meta Daten

    onNodeSelected(node:GoNode) : Observable<void>;
    onPreviewData(): Observable<boolean>;
    onChangeNavigation(newNavigation:DataNavigation) : Observable<DataNavigation>;
}


interface RunParam {
    workflow: Workflow;     // Input Workflow, der vom Client an BE zur Validierung übergeben wurde
    isSimulation: boolean; // Bestimmt ob ein WF simuliert wird oder produktiv gestartet wird
    stopNode?: WorkflowNode // Für eine partielle Ausführung, wenn nix angegeben, läuft der Workflow bis zum Ende
}

interface NodeLogEntry {
    timestamp: string;
    severity: string; // Schweregrad eines NodeLogs: Info, Warning, Error
    message: string;
    port?: string;      // optional; sofern ein Fehler bei einem bestimmten Port aufgetreten ist (z.B. Join, Union)
}
interface RunNodeResult {
    node: WorkflowNode;
    status: string;   // Node Status Enum: Pending, Processing, Successful, Failed, Ignored
    logs: NodeLogEntry[]
}
interface RunResult {
    runType: string;    // Enum: UpdateSettings, Execute
    status: string;     // Workflow Status Enum: Pending, Processing, Successful, Failed
    nodeResults: RunNodeResult[];
    workflow: Workflow      // Aktualisierter & vom BE validierter Workflow
}

interface DesignerLogEntry {
    user: UserInfo;
    //workflow: Workflow;
    runParam: RunParam;
    runResult: RunResult
}

/**
 * Ein Repository, was für alle Workflows/Auführungen das Protokol vorhält
 */
interface DesignerLog {
    logs: DesignerLogEntry[];
}
