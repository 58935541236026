import { Component, OnInit } from '@angular/core';
import { RemoveDuplicatesPlugIn } from 'src/app/models/api/com/bion/etl/transformation/RemoveDuplicatesPlugIn';
import { FieldInfo, WorkflowNodeSettings } from 'src/app/models/api/com/bion/etl/Workflow';
import { DesignerService } from 'src/app/services/designer.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { NodeConfigComponentBase } from '../node-config-component-base';

export class RemoveDuplicatesInfoView {
    ColumnNames: Array<string>;
    DropMode: string;
    DropAllCols: boolean;
    hasError?: boolean;

    constructor(ColumnNames: Array<string>, DropMode: string, DropAllCols: boolean, hasError?: boolean) {
        if (ColumnNames === undefined) throw new Error("Class 'RemoveDuplicatesInfo': Required value 'ColumnNames' is undefined");
        if (DropMode === undefined) throw new Error("Class 'RemoveDuplicatesInfo': Required value 'DropMode' is undefined");
        if (DropAllCols === undefined) throw new Error("Class 'RemoveDuplicatesInfo': Required value 'DropAllCols' is undefined");
        this.ColumnNames = ColumnNames;
        this.DropMode = DropMode;
        this.DropAllCols = DropAllCols;
        this.hasError = hasError;
    }
}

@Component({
    selector: 'app-remove-duplicates-node',
    templateUrl: './remove-duplicates-node.component.html',
    styleUrls: ['./remove-duplicates-node.component.scss']
})
export class RemoveDuplicatesNodeComponent extends NodeConfigComponentBase<RemoveDuplicatesPlugIn.Settings> implements OnInit {
    settingsToView(settings: WorkflowNodeSettings): void {
        
        // -- Get Configuration
        let currentConfig = <RemoveDuplicatesPlugIn.Settings>settings;
        
        // -- Get Input Meta Data
        const meta_infos =
            this._currentWorkflowNodeAdapter.IWorkflowNode.Properties.MetaInfo.get(this.InputPort);
            
        let col_names: string[] = [];

        //this.encryptInfo = encryptInfo;
        // -- check for MetaInfos from Input Port & store dsField list
        if (meta_infos !== undefined && meta_infos.length > 0) {
            const input_field_infos = meta_infos[0].FieldsInfo;

            // -- create string array for column dropdown
            col_names = input_field_infos.map(fieldInfo =>fieldInfo.Name);
            this.columnList = col_names;
        }

        // -- if no configuration exists, exit
        if (currentConfig === undefined) {
            // -- if undefined, exit
            return;
        } else {
            // -- otherwise load from existing config
            if (!currentConfig.RemoveDuplicateInfos || currentConfig.RemoveDuplicateInfos.length === 0) return;


            // -- if given, check infos for columns
            currentConfig.RemoveDuplicateInfos[0].ColumnNames.map((col) => {
                if (col_names.includes(col)) {
                    // -- column still exists, do nothing
                } else {
                    // column does not exist anymore, meaning column name currently not included -> include
                    col_names.push(col);
                }
            });

            this.duplicatesColumns = currentConfig.RemoveDuplicateInfos[0] ? currentConfig.RemoveDuplicateInfos[0].ColumnNames : [];
            this.removeAllColsFlag = currentConfig.RemoveDuplicateInfos[0].DropAllCols;
            this.dropmode = currentConfig.RemoveDuplicateInfos[0].DropMode ? currentConfig.RemoveDuplicateInfos[0].DropMode : this.dropModes[0];

            this.columnList = col_names;

            if (meta_infos !== undefined) {
                const field_infos = meta_infos[0].FieldsInfo;

                if (field_infos === undefined) return;

                this.removeDuplicatesInfo = this.infoToView(currentConfig, field_infos);
            } else {
                this.removeDuplicatesInfo = this.infoToView(currentConfig);
            }
        }
    }
    viewToSettings(): RemoveDuplicatesPlugIn.Settings {
        let newSettings = <RemoveDuplicatesPlugIn.Settings>{
            ...this.getCurrentWorkflowNode().Properties.Configuration,
        };
        let newInfo = new RemoveDuplicatesPlugIn.RemoveDuplicatesInfo(this.duplicatesColumns, this.dropmode, this.removeAllColsFlag);

        newSettings.RemoveDuplicateInfos = [...[newInfo]];

        return newSettings;
    }
    onSettingsChanged(settings: RemoveDuplicatesPlugIn.Settings): void {
        throw new Error('Method not implemented.');
    }

    readonly InputPort = "Input";
    removeDuplicatesInfo?: RemoveDuplicatesInfoView;    // Warum existiert dieses Variable
    duplicatesColumns: string[] = [];
    dropModes: string[] = ["Keep first", "Keep last", "Drop all"];
    // dropmode: string ="";                    // Bitte nie eine Variable mit einem ungültigen Wert initalisieren, dann lieber undefined.
    dropmode: string = this.dropModes[0];       // Gültiger Standardwert :-)
    removeAllColsFlag: boolean = false;
    columnList: string[] = [];

    constructor(
        protected workflowService: WorkflowsService,
        protected designerService: DesignerService
    ) {
        super(workflowService, designerService);
    }
    ngOnInit(): void {
        super.ngOnInit();
    }

    infoToView(settings: RemoveDuplicatesPlugIn.Settings, meta?: FieldInfo[]): RemoveDuplicatesInfoView {
        //let view: EncryptInfoView = new EncryptInfoView();
        const info = settings.RemoveDuplicateInfos[0];

        // -- create selected EncryptInfo from config
        const ColumnNames = info.ColumnNames;

        let hasErrorFlag: boolean;

        if (meta) {
            let fieldInfoFound = meta.find((field) => {
                return info.ColumnNames.includes(field.Name)
                //return field.Name === settings.RemoveDuplicatesInfos[0].ColumnNames;
            });

            hasErrorFlag = !fieldInfoFound;
        } else {
            hasErrorFlag = false;
        }

        let view: RemoveDuplicatesInfoView = new RemoveDuplicatesInfoView(ColumnNames, info.DropMode, info.DropAllCols, hasErrorFlag);

        return view;
    }

}

