import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-ab-menu',
  templateUrl: './view-ab-menu.component.html',
  styleUrls: ['./view-ab-menu.component.scss']
})
export class ViewAbMenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
