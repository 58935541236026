import { Observable, throwError } from "rxjs";
import { DestinationService } from "src/app/services/destinationService";
import { UriTargetAccess } from "../api/com/bion/connect/csv/UriTargetAccess";
import { TargetAccessLike } from "../api/com/bion/connect/TargetAccessLike";
import { ConnectorSettingsBuilder } from "../connectorView.model";
import { DestinationViewBase } from "./destination-view-base.model";


export class CsvUrlDestinationView extends DestinationViewBase<UriTargetAccess, UriTargetAccess> {
    constructor(api: DestinationService, connectorID: string, connector_key:number) {
        super(api, connectorID, connector_key);
    }

    getBase64FileData(): string {
        return this.base64FileData;
    }
    getDataSourceNameSuggestion(): string {
        return this.uploadedFile.name;
    }
    uploadFile(file: File) {
        this.uploadedFile = file;
    }

    getSettingsBuilder(): Observable<ConnectorSettingsBuilder<UriTargetAccess>> {

        // let innerRes = new CsvUrlSettingsBuilder(this.connectorID, this.connectorSettings, undefined);
        // return of(innerRes);
        return throwError(new Error("Not implemented yet"));
    }

    isFileBased(): boolean {
        return false;
    }

    fileIsUpdated: boolean = false;
    flattenedFileList: [] = [];
    base64FileData?: string;
}