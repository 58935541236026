import { Component, OnInit } from '@angular/core';
import { IntegratedSourceModel } from 'src/app/models/api/models/IntegratedSourceModel';
import { ActivityEntry } from 'src/app/views/objectManagement/generic-latest-activities/latest-activity-provider';
import * as dss from 'src/app/models/datasource.model';

@Component({
  selector: 'app-view-source-int-history',
  templateUrl: './view-source-int-history.component.html',
  styleUrls: ['./view-source-int-history.component.scss']
})
export class ViewSourceIntHistoryComponent implements OnInit {
    activities: any[] = [];

  constructor() { }

  ngOnInit(): void {
  }


}
