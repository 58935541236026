<p-dialog header="{{'Workflows.OpenWorkflow'| translate}}" [(visible)]="displayOpenWorkflow" modal="modal"
    showEffect="fade" [style]="{width: '800px'}">
    <p-table #wt [value]="workflows" (onRowSelect)="onSelectWorkflow($event)"
        (onRowUnselect)="onUnselectWorkflow($event)" [(selection)]="selectedWorkflow" selectionMode="single"
        sortField="representative.name" sortMode="single" styleClass="p-datatable-customers" [loading]="savingInProgress"
        [globalFilterFields]="['id','name','description','createdBy']" [rows]="10" paginator=true [scrollable]="false"
        styleClass="p-datatable-customers p-datatable-sm">
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="wt.filterGlobal($event.target.value, 'contains')"
                        placeholder="{{'Workflows.SearchForWorkflow' | translate}}" />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Name'| translate}}
                        <p-columnFilter type="text" field="name" display="menu" class="p-ml-auto">
                        </p-columnFilter>
                    </div>
                </th>
                <th>
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Description'| translate}}
                        <p-columnFilter type="text" field="description" display="menu" class="p-ml-auto">
                        </p-columnFilter>
                    </div>
                </th>
                <!-- <th style="width: 4rem"></th> -->
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-workflow let-rowIndex="rowIndex">
            <tr [pSelectableRow]="workflow">
                <td><span class="p-column-title">{{'Name'| translate}}</span>
                    {{workflow.name}}
                </td>
                <td><span class="p-column-title">{{'Description'| translate}}</span>
                    <span [class]="'customer-badge status-' + workflow.status">{{workflow.description}}</span>
                </td>

                <!-- <td>
                    <div class="ui-table-buttongroup-right">
                        <button pButton pRipple type="button" icon="pi pi-ellipsis-v"
                            class="p-button-rounded p-button-text p-mr-2 p-mb-2"
                            pTooltip="Change datasource information" (click)="op1.toggle($event)"
                            (click)="onMenuClicked(workflow)"></button>
                    </div>
                </td> -->

            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="2">{{'NoEntryFound'| translate}}</td>
            </tr>
        </ng-template>
    </p-table>

    <p-footer>
        <!-- <button type="button" pButton icon="pi pi-check" (click)="openWorkflow()" label="Open"
            class="p-button-text"></button>
        <button type="button" pButton icon="pi pi-times" (click)="displayOpenWorkflow=false" label="Close"
            class="p-button-text"></button> -->
        <button pButton pRipple type="button" icon="pi pi-times" (click)="displayOpenWorkflow=false"
            class="p-button-rounded p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'Close' | translate}}"></button>
        <button pButton pRipple type="button" icon="pi pi-check" (click)="openWorkflow()"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'Open' | translate}}" [icon]="savingInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'"></button>
    </p-footer>
</p-dialog>
