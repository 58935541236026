import { Monoid, Semigroup } from "./algebra";

declare global {
    interface Array<T> {
        /**
         * Builds a new array by applying a function to all elements of this array and
         * using the elements of the resulting array.
         * @param fun Function

        */
        flatMap<U>(fun: (arg: T) => Array<U>): Array<U>;
        /**
         * Selects the first element of this array. If there is non it throws an exception.
         */
        head(): T;
        /**
         * Optionally selects the first element.
         */
        headOption(): T | undefined;
        /**
         * Liefert das letzte Element.
         * @throws Empty Array
         */
        last(): T;
        /**
         * Liefert das letzte element oder undefined wenn das Array leer ist.
         */
        lastOption(): T | undefined;
        /**
         * Tests whether this array is empty.
         */
        isEmpty(): boolean;
        /**
         * Tests whether the array is not empty.
         */
        nonEmpty(): boolean;
        /**
         * Converts this array to a set.
         */
        toSet(): Set<T>;

        /**
         * Returns a sequence formed from this sequence and another iterable collection by combining
         * corresponding elements in pairs. If one of the two collections is longer than the other,
         * its remaining elements are ignored.
         * @param that Second array
         * @returns Array with pairs of the first and second array elements.
         */
        zip<U>(that: Array<U>): Array<[T, U]>;

        /**
         * Fügt jedem element seinen index hinzu. Nützlich für Kombinationen mit forEach.
         */
        zipWithIndex(): Array<[T, number]>;

        /**
         * Drops null elements
         */
        dropNulls(): Array<T>;

        /**
         * Drop undefined elements
         */
        dropUndef(): Array<T>;

        /**
         * Drops null and undefined elements.
         */
        dropUnsets(): Array<T>;

        /**
         * Gets the element from the end with a zero-based index.
         * E.g. 0=last, 1=before last, ...
         * @param index 
         */
        getRight(index: number): T;

        partition(p: (a: T) => boolean): [Array<T>, Array<T>];
    }
}

Array.prototype.flatMap = function <T, U>(fun: (arg: T) => Array<U>) {
    return Arrays.flatMap(this, fun);
}

Array.prototype.head = function () {
    return Arrays.head(this);
}

Array.prototype.headOption = function () {
    return Arrays.headOption(this);
}

Array.prototype.last = function () {
    return Arrays.last(this);
}

Array.prototype.lastOption = function () {
    return Arrays.lastOption(this);
}

Array.prototype.isEmpty = function () {
    return Arrays.isEmpty(this);
}

Array.prototype.nonEmpty = function () {
    return Arrays.nonEmpty(this);
}

Array.prototype.toSet = function () {
    return Arrays.toSet(this);
}

Array.prototype.zip = function <U>(that: Array<U>) {
    return Arrays.zip(this, that);
}

Array.prototype.zipWithIndex = function () {
    return Arrays.zipWithIndex(this);
}

Array.prototype.dropNulls = function () {
    return Arrays.dropNulls(this);
}

Array.prototype.dropUndef = function () {
    return Arrays.dropUndef(this);
}

Array.prototype.dropUnsets = function () {
    return Arrays.dropUnsets(this);
}

Array.prototype.getRight = function (index: number) {
    return Arrays.getRight(this, index);
}

Array.prototype.partition = function (p: (a: any) => boolean) {
    return Arrays.partition(this, p);
}


/**
 * Array helpers.
 */
export class Arrays {
    /**
     * Checks if the array is undefined or empty
     * @param a The array
     * @returns true, if the array is undefined or empty
     */
    static isNullOrEmpty<A>(a: Array<A> | undefined): boolean {
        return (a === undefined || a.isEmpty());
    }

    /**
     * Delivers the first element or undefined if the array is undefined or empty.
     * @param a Array
     * @returns The first element.
     */
    static headOption<A>(a?: Array<A>): A | undefined {
        if (a) {
            return a.nonEmpty() ? a[0] : undefined;
        } else {
            return undefined;
        }
    }

    /**
     * Delivers the first element or throws an error otherwise.
     * @param a Array
     * @returns The first element.
     */
    static head<A>(a?: Array<A>): A {

        if (a === undefined) throw new Error("The given array is undefined");

        const opt = this.headOption(a);
        if (opt) {
            return opt;
        }
        else {
            throw new Error("The given array does not contain elements");
        }
    }

    static last<A>(a: Array<A>): A {
        const res = this.lastOption(a);
        if (res)
            return res;
        else
            throw new Error("Last element not available because the array ist empty");
    }

    static lastOption<A>(a: Array<A>): A | undefined {
        return a.nonEmpty() ? a[a.length - 1] : undefined;
    }

    static dropUnsets<A>(a: Array<A | undefined | null>): Array<A> {
        return this.dropNulls(this.dropUndef(a));
    }

    /**
     * Drop all undefined entries from an array.
     * @param a Array
     */
    static dropUndef<A>(a: Array<A | undefined>): Array<A> {

        const result = new Array<A>();
        for (let entry of a) {
            if (entry !== undefined) result.push(entry);
        }

        return result;
    }

    static flatMap<A, B>(a: Array<A>, fun: (arg: A) => Array<B>): Array<B> {
        let result = new Array<B>();

        for (let arr of a) {
            const fun_res = fun(arr);
            result = result.concat(fun_res);
        }

        return result;
    }

    /**
     * Converts a nested array into a flat array.
     * @param nested Nested Array
     * @returns Flattened Array
     */
    static flatten<A>(nested: Array<Array<A>>): Array<A> {
        let result = new Array<A>();

        for (let arr of nested) {
            result = result.concat(arr);

        }
        return result;
    }

    static isEmpty<A>(arr: Array<A>): boolean {
        return arr.length == 0;
    }

    static nonEmpty<A>(arr: Array<A>): boolean {
        return !this.isEmpty(arr);
    }

    static dropNulls<A>(arr: Array<A | null>): Array<A> {
        const result = new Array<A>();
        for (let entry of arr) {
            if (entry !== null) result.push(entry);
        }

        return result;
    }

    static toSet<A>(arr: Array<A>): Set<A> {
        return new Set(arr);
    }

    static toMap<A, B>(arr: Array<[A, B]>): Map<A, B> {
        return new Map<A, B>(arr);
    }


    /**
     * Returns a sequence formed from this sequence and another iterable collection by combining
     * corresponding elements in pairs. If one of the two collections is longer than the other,
     * its remaining elements are ignored.
     * @param this First array
     * @param second Second array
     * @returns
     */
    static zip<A, B>(first: Array<A>, second: Array<B>): Array<[A, B]> {
        const result = new Array<[A, B]>();

        const len = Math.min(first.length, second.length);

        for (let i = 0; i < len; i++) {
            result.push([first[i], second[i]])
        }

        return result;
    }

    static zipWithIndex<T>(arr: Array<T>): Array<[T, number]> {
        const result = new Array<[T, number]>();
        for (let i = 0; i < arr.length; i++) {
            result.push([arr[i], i]);
        }
        return result;
    }

    /**
     * Gets the element which is i steps before the last element.
     * @param arr Array
     * @param index Positive index steps before the last element where 0 marks the last element.
     * @returns The Element
     */
    static getRight<A>(arr: Array<A>, index: number): A {
        const end_index = arr.length - 1;
        const i = end_index - index;
        return arr[i];
    }

    /**
     * Teilt das Array anhand des Prädikats
     * @param arr Array
     * @param p Prädikat
     * @returns Das Prädikat-erfüllende und das nicht-Prädikat-erfüllende Array.
     */
    static partition<A>(arr: Array<A>, p: (a: A) => boolean): [Array<A>, Array<A>] {
        const t = new Array<A>();
        const f = new Array<A>();
        arr.forEach(e => {
            if (p(e))
                t.push(e);
            else
                f.push(e);
        });
        return [t, f];
    }
}

export class ArraySemiGroup implements Semigroup<Array<any>> {
    combine(a1: any[], a2: any[]): any[] {
        return a1.concat(a2);
    }   
}

export class ArrayMonoid extends ArraySemiGroup implements Monoid<Array<any>> {
    empty(): any[] {
        return new Array<any>();
    }
}