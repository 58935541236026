import { Component, OnInit } from '@angular/core';
import { CheckBoxEvents } from 'src/app/helper/events';
import { WorkflowNodeSettings } from 'src/app/models/api/com/bion/etl/Workflow';
import { AutoSelectInfo, AutoSelectSettings } from 'src/app/models/nodeSettings.model';
// import { WorkflowNodeSettings } from 'src/app/models/workflow.model';
import { DesignerService } from 'src/app/services/designer.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { NodeConfigComponentBase } from '../node-config-component-base';

@Component({
  selector: 'app-auto-select-node',
  templateUrl: './auto-select-node.component.html',
  styleUrls: ['./auto-select-node.component.scss']
})
export class AutoSelectNodeComponent 	extends NodeConfigComponentBase<AutoSelectSettings> implements OnInit {
  viewToSettings(): AutoSelectSettings {
    let settingsCopy = <AutoSelectSettings>{
			...this.getCurrentWorkflowNode().Properties.Configuration,
		};

		//let newSettings = new AutoSelectSettings;

		let newSettings = {...settingsCopy};
    console.log(newSettings);

    if(newSettings.AutoSelectInfos && newSettings.AutoSelectInfos.length > 0) {
      newSettings.AutoSelectInfos[0].RemoveCols = this.removeCols;
      newSettings.AutoSelectInfos[0].RemoveRows = this.removeRows;
    } else {
      newSettings.AutoSelectInfos = [new AutoSelectInfo(true,true)];
    }



    // let settings = new AutoSelectSettings();
    // let config = new AutoSelectInfo(this.removeRows,this.removeCols);

    //settings.AutoSelectInfos = [config]

    return newSettings;

  }
  onSettingsChanged(settings: AutoSelectSettings): void {
    throw new Error('Method not implemented.');
  }
  settingsToView(settings: WorkflowNodeSettings): void {
        // -- Get Configuration
    let currentConfig = <AutoSelectSettings>settings;

    if(currentConfig.AutoSelectInfos && currentConfig.AutoSelectInfos.length > 0) {
      this.removeCols = currentConfig.AutoSelectInfos[0].RemoveCols;
      this.removeRows = currentConfig.AutoSelectInfos[0].RemoveRows;

    } else {
      currentConfig.AutoSelectInfos = [new AutoSelectInfo(true,true)];
    }
  }

  removeCols: boolean = false;
  removeRows: boolean = false;

	constructor(
		protected workflowService: WorkflowsService,
		protected designerEventService: DesignerService
	) {
		super(workflowService, designerEventService);
	}


  ngOnInit(): void {
  }
  onCheckRemoveColsFlag(event: CheckBoxEvents.OnChange<any>) {
    this.removeCols = event.checked;
    this.onUpdateSettings(false);
  }
  onCheckRemoveRowsFlag(event: CheckBoxEvents.OnChange<any>) {
    this.removeRows = event.checked;
    this.onUpdateSettings(false);
  }

  onFocusLost(event: FocusEvent) {
    const div_left: boolean = (event.relatedTarget === null);

    console.log("Send from Div: ", div_left);

    if (div_left) {
        //this.onCheckDirtyFlag();
        this.onUpdateSettings(true);
    }
}
}
