import { Component, OnInit } from "@angular/core";
import { FieldInfo, WorkflowNodeSettings } from "src/app/models/api/com/bion/etl/Workflow";
import { GuiDropDown } from "src/app/models/helperClass.model";
import {
	SummarizeSettings,
	SummarizeInfo,
} from "src/app/models/nodeSettings.model";
// import { FieldInfo, WorkflowNodeSettings } from "src/app/models/workflow.model";
import { DesignerService } from "src/app/services/designer.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { NodeConfigComponentBase } from "../node-config-component-base";

export class SummarizeInfoView {
	ColumnName: string;
	Method: GuiDropDown;
	NewColumnName: string;
	hasError: boolean;

	constructor(ColumName: string, Method: GuiDropDown, NewColumnName: string, hasError: boolean){
		this.ColumnName = ColumName;
		this.Method = Method;
		this.NewColumnName = NewColumnName;
		this.hasError = hasError
	}
}

@Component({
	selector: "app-summarize-node",
	templateUrl: "./summarize-node.component.html",
	styleUrls: ["./summarize-node.component.css"],
})
export class SummarizeNodeComponent
	extends NodeConfigComponentBase<SummarizeSettings>
	implements OnInit
{
	settingsToView(settings: WorkflowNodeSettings): void {
		// -- Get Configuration
		let currentConfig = <SummarizeSettings>settings;
		// -- Get Input Meta Data
		const meta_infos = this.getCurrentWorkflowNode().Properties.MetaInfo.get(
			this.InputPort
		);

		let columnListArray: string[] = [];
		// create SelectInfoViews from DsFields for table
		if (meta_infos != undefined && meta_infos.length > 0) {
			const input_field_infos = meta_infos[0].FieldsInfo;

			columnListArray = input_field_infos.map((fieldInfo) => {
				return fieldInfo.Name;
			});
			this.columnList = columnListArray;
		}

		// --If currentConfig not available (-> new node)
		if (currentConfig == undefined) {
			this.summarizeList = [];
		} else {
			// -- otherweise load existing config into view

			currentConfig.SummarizeInfos.map((info) => {
				if (columnListArray.includes(info.ColumnName)) {
					// -- column still exists, do nothing
				} else {
					// column does not exist anymore, meaning column name currently not included -> include
					columnListArray.push(info.ColumnName);
				}
			});
			this.columnList = columnListArray;
            if(meta_infos !== undefined) {
                this.summarizeList = this.InfoToInfoView(
                    currentConfig.SummarizeInfos,
                    meta_infos[0].FieldsInfo
                );
            }

		}
	}
	viewToSettings(): SummarizeSettings {
		const newSettings = <SummarizeSettings>{
			...this.getCurrentWorkflowNode().Properties.Configuration,
		};
		const summarizeInfoViews = this.summarizeList;
		const summarizeInfos = this.InfoViewToInfo(summarizeInfoViews);

		newSettings.SummarizeInfos = summarizeInfos;

		return newSettings;
	}
	onSettingsChanged(settings: SummarizeSettings): void {
		throw new Error("Method not implemented.");
	}

	summarizeList: SummarizeInfoView[] = [];
	columnList: string[] = [];
	dropDownSummarizeMethod: GuiDropDown[] = [];

	readonly InputPort = "Input";

	onAddEntry() {
		let newEntry = new SummarizeInfoView("",this.dropDownSummarizeMethod[0], "", false);
		// newEntry.ColumnName = "";
		// newEntry.Method = this.dropDownSummarizeMethod[0];
		// newEntry.NewColumnName = "";
		// newEntry.hasError = false;

		this.summarizeList.push(newEntry);
		this.InfoViewToInfo(this.summarizeList);
		this.onUpdateSettings(false);
	}
	onDeleteFilter(index: number) {
		this.summarizeList = [
			...this.summarizeList.slice(0, index),
			...this.summarizeList.slice(index + 1),
		];
		this.onUpdateSettings(false);
	}
	InfoToInfoView(
		list: SummarizeInfo[],
		meta?: FieldInfo[]
	): SummarizeInfoView[] {
		this.dropDownSummarizeMethod = [
			{ name: "Group by", value: "groupby" },
			{ name: "Sum", value: "sum" },
			{ name: "Count", value: "count" },
			{ name: "Count not null", value: "count_non_null" },
			{ name: "Count null", value: "count_null" },
		];

		let newSummarizeInfosView: SummarizeInfoView[] = [];

		list.map((entry: SummarizeInfo) => {


			// -- Get respective fieldInfo class from config
			const ColumnName = entry.ColumnName;

			let fieldInfoFound = meta?.find((field) => {
				return field.Name === entry.ColumnName;
			});

			const hasError = !fieldInfoFound;
			let method = new GuiDropDown();
			// -- create selected Summarize Method from config
			for (let i = 0; i < this.dropDownSummarizeMethod.length; i++) {
				if (entry.Method == this.dropDownSummarizeMethod[i].value) {
					method = this.dropDownSummarizeMethod[i];
				}
			}

			const NewColumnName = entry.NewFieldName;

			let newInfoView = new SummarizeInfoView(ColumnName,method,NewColumnName,hasError);

			newSummarizeInfosView.push(newInfoView);
		});
		// -- return final view to GUI
		return newSummarizeInfosView;
	}
	InfoViewToInfo(sortList: SummarizeInfoView[]) {
		// -- create new empty array
		let newSummarizeInfos: SummarizeInfo[] = [];

		sortList.map((entry) => {
			let newSettings = new SummarizeInfo();
			newSettings.ColumnName = entry.ColumnName;
			newSettings.Method = entry.Method.value;
			newSettings.NewFieldName = entry.NewColumnName;
			newSummarizeInfos.push(newSettings);
		});
		return newSummarizeInfos;
	}

	constructor(
		protected workflowService: WorkflowsService,
		protected designerService: DesignerService
	) {
		super(workflowService, designerService);
	}

	ngOnInit(): void {
		super.ngOnInit();
	}
    onFocusLost(event: FocusEvent) {
        const div_left: boolean = (event.relatedTarget === null);

        console.log("Send from Div: ", div_left);

        if (div_left) {
            //this.onCheckDirtyFlag();
            this.onUpdateSettings(true);
        }
    }
}
