import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SqlDummyApiService } from '../sql-dummy-api.service';
import { GraphView, NodeSettingsChangedData, WorkflowGraphEvent, WorkflowGraphEventFactory, WorkflowGraphEvents } from '../sql-workflow-sketch';
import * as wf from '../../../models/api/com/bion/etl/Workflow';




import { ChangeDetectorRef, ViewChild, ViewEncapsulation } from '@angular/core';
import * as go from 'gojs';
import { DataSyncService, DiagramComponent, PaletteComponent } from 'gojs-angular';
import { produce } from "immer";
import workflow_graph from './test/workflow-graph-framework-poc.json';
import plug_in_list from "./test/plug-in-list.json";
import { NodeConfigUtil } from 'src/app/services/api-util';
import { WorkflowNodeInfo } from 'src/app/models/designer.models';
import { GoPaletteEntry, GoNode, GoPortEntry, GoEdgeEntry } from './goJs.model';
import { CodecHelper, GoJsCodec, GoPaletteCodec } from './goJs-codec';
import { Workflow, WorkflowNode } from '../../../models/api/com/bion/etl/Workflow';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { SubSink } from 'subsink';
import lodash from 'lodash';




export class CustomLink extends go.Link {
  findSidePortIndexAndCount(node, port) {
    const nodedata = node.data;
    let len = 0;
    if (nodedata !== null) {
      const portdata = port.data;
      const side = port._side;
      const arr = nodedata[side + "Array"];
      //const len = arr.length;
      len = arr.length;
      for (let i = 0; i < len; i++) {
        if (arr[i] === portdata) return [i, len];
      }
    }
    return [-1, len];
  }

  computeEndSegmentLength(node:go.Node, port, spot:go.Spot, from:boolean) {
    const esl = super.computeEndSegmentLength(node, port, spot, from);
    const other = this.getOtherPort(port);
    if (port !== null && other !== null) {
      const thispt = port.getDocumentPoint(this.computeSpot(from));
      const otherpt = other.getDocumentPoint(this.computeSpot(!from));
      if (Math.abs(thispt.x - otherpt.x) > 20 || Math.abs(thispt.y - otherpt.y) > 20) {
        const info = this.findSidePortIndexAndCount(node, port);
        const idx = info[0];
        const count = info[1];
        if (port._side == "top" || port._side == "bottom") {
          if (otherpt.x < thispt.x) {
            return esl + 4 + idx * 8;
          } else {
            return esl + (count - idx - 1) * 8;
          }
        } else {  // left or right
          if (otherpt.y < thispt.y) {
            return esl + 4 + idx * 8;
          } else {
            return esl + (count - idx - 1) * 8;
          }
        }
      }
    }
    return esl;
  }

  hasCurviness() {
    if (isNaN(this.curviness)) return true;
    return super.hasCurviness();
  }

  computeCurviness() {
    if (isNaN(this.curviness)) {
      const fromnode = this.fromNode;
      const fromport = this.fromPort;
      const fromspot = this.computeSpot(true);
      const frompt = fromport.getDocumentPoint(fromspot);
      const tonode = this.toNode;
      const toport = this.toPort;
      const tospot = this.computeSpot(false);
      const topt = toport.getDocumentPoint(tospot);
      if (Math.abs(frompt.x - topt.x) > 20 || Math.abs(frompt.y - topt.y) > 20) {
        if ((fromspot.equals(go.Spot.Left) || fromspot.equals(go.Spot.Right)) &&
          (tospot.equals(go.Spot.Left) || tospot.equals(go.Spot.Right))) {
          const fromseglen = this.computeEndSegmentLength(fromnode, fromport, fromspot, true);
          const toseglen = this.computeEndSegmentLength(tonode, toport, tospot, false);
          const c = (fromseglen - toseglen) / 2;
          if (frompt.x + fromseglen >= topt.x - toseglen) {
            if (frompt.y < topt.y) return c;
            if (frompt.y > topt.y) return -c;
          }
        } else if ((fromspot.equals(go.Spot.Top) || fromspot.equals(go.Spot.Bottom)) &&
          (tospot.equals(go.Spot.Top) || tospot.equals(go.Spot.Bottom))) {
          const fromseglen = this.computeEndSegmentLength(fromnode, fromport, fromspot, true);
          const toseglen = this.computeEndSegmentLength(tonode, toport, tospot, false);
          const c = (fromseglen - toseglen) / 2;
          if (frompt.x + fromseglen >= topt.x - toseglen) {
            if (frompt.y < topt.y) return c;
            if (frompt.y > topt.y) return -c;
          }
        }
      }
    }
    return super.computeCurviness();
  }
}
// end CustomLink class






@Component({
  selector: 'app-graph-view',
  templateUrl: './graph-view.component.html',
  styleUrls: ['./graph-view.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class GraphViewComponent implements OnInit, OnDestroy, GraphView {

  constructor(private sql_api: SqlDummyApiService, private cdr: ChangeDetectorRef, private apiService: ApiBackendService) {
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
  workflow: Workflow;
  @Input() plugIns: WorkflowNodeInfo[] = [];
  subs = new SubSink;
  readonly self = this;

  ngOnInit(): void {
    // this.subs.sink = this.sql_api.nodeSettingsChanged2.subscribe( (data: WorkflowGraphEvent<string, NodeSettingsChangedData>) => {
    //   this.onNodeSettingsChanged(data)
    // })
  }

  // Ich habe nur einen Graph!
  onPlugInDrop(plugIn: WorkflowNodeInfo): Observable<void> {
    throw new Error("not Implemented");
  }

  // Events
  nodeSelected: EventEmitter<WorkflowNode>;
  nodeDeleted: EventEmitter<WorkflowNode>;
  nodesCreated: EventEmitter<WorkflowNode[]>;
  workflowLoaded: EventEmitter<Workflow>;  // Ein neuer Workflow wurde geladen

  loadWorkflow(workflow: Workflow): Observable<Workflow> {
    this.workflow = workflow;
    return of(this.workflow);
  }
  getWorkflow(): Workflow {
    return this.workflow;
  }
  // nodeClicked(id) {
  //   const node: WorkflowNode = { ID: id, Settings: undefined };
  //   const event = WorkflowGraphEventFactory.buildNodeClicked(this, node, this.workflow);
  //   this.sql_api.nodeSelectedEmitter.emit(event);
  // }




  //________________________________GO JS TESTCODE_________________________________________//

  @ViewChild('myDiagram', { static: true }) public myDiagramComponent!: DiagramComponent;
  @ViewChild('myPalette', { static: true }) public myPaletteComponent!: PaletteComponent;

  uplugInList: unknown = <unknown>plug_in_list;
  plugInList: WorkflowNodeInfo[] = <WorkflowNodeInfo[]>this.uplugInList;
  goJsCodec = new GoJsCodec(this.plugInList);
  typedWorkflow = CodecHelper.cast<wf.Workflow>(workflow_graph);

  // Big object that holds app-level state data
  // As of gojs-angular 2.0, immutability is expected and required of state for ease of change detection.
  // Whenever updating state, immutability must be preserved. It is recommended to use immer for this, a small package that makes working with immutable data easy.
  public state = {
    // Diagram state props
    // diagramNodeData: [
    //   { id: 'Alpha', text: "Alpha", color: 'lightblue', loc: "0 0" },
    //   { id: 'Beta', text: "Beta", color: 'orange', loc: "100 0" },
    //   { id: 'Gamma', text: "Gamma", color: 'lightgreen', loc: "0 100" },
    //   { id: 'Delta', text: "Delta", color: 'pink', loc: "100 100" }
    // ],
    // diagramLinkData: [
    //   { key: -1, from: 'Alpha', to: 'Beta', fromPort: 'r', toPort: '1' },
    //   { key: -2, from: 'Alpha', to: 'Gamma', fromPort: 'b', toPort: 't' },
    //   { key: -3, from: 'Beta', to: 'Beta' },
    //   { key: -4, from: 'Gamma', to: 'Delta', fromPort: 'r', toPort: 'l' },
    //   { key: -5, from: 'Delta', to: 'Alpha', fromPort: 't', toPort: 'r' }
    // ],
    //diagramNodeData: this.getDiagramNodeData(),
    //diagramNodeData: this.diagramDataFromWorkflow(),

    diagramNodeData: this.goJsCodec.encode(this.typedWorkflow).nodeData,
    diagramLinkData: this.goJsCodec.encode(this.typedWorkflow).linkData,
    //diagramNodeData: this.multiSampleNodeData(),
    //diagramLinkData: this.multiSampleLinkData(),

    diagramModelData: { prop: 'value' },
    skipsDiagramUpdate: false,
    selectedNodeData: null, // used by InspectorComponent

    // Palette state props
    // paletteNodeData: [
    //   { key: 'Epsilon', text: 'Epsilon', color: 'red' },
    //   { key: 'Kappa', text: 'Kappa', color: 'purple' }
    // ],
    //paletteNodeData: GoPaletteCodec.createGoPalette(this.plugInList),
    //paletteNodeData: GoPaletteCodec.createGoNode(this.plugInList),
    paletteNodeData: GoPaletteCodec.createGoNodePalette(this.plugInList),


    paletteModelData: { prop: 'val' }
  };

  public diagramDivClassName: string = 'myDiagramDiv';
  public paletteDivClassName = 'myPaletteDiv';

  // initialize diagram / templates
  public initDiagram(): go.Diagram {

    const $ = go.GraphObject.make;

    // -- ORIGINAL VERSION
    const dia = $(go.Diagram, {
      'undoManager.isEnabled': true,
      'clickCreatingTool.archetypeNodeData': { text: 'new node', color: 'lightblue' },
      model: $(go.GraphLinksModel,
        {
          nodeKeyProperty: 'id',
          linkToPortIdProperty: 'toPort',
          linkFromPortIdProperty: 'fromPort',
          linkKeyProperty: 'key' // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
        }
      )
    });


    // -- ROUVEN VERSION
    // const dia = $(go.Diagram, {
    //   'undoManager.isEnabled': true,
    //   'clickCreatingTool.archetypeNodeData': { text: 'new node', color: 'lightblue' },
    //   model: this.getDiagramModel3()
    // });


    dia.commandHandler.archetypeGroupData = { key: 'Group', isGroup: true };

    // const makePort = function (id: string, spot: go.Spot) {
    //   return $(go.Shape, 'Circle',
    //     {
    //       opacity: .5,
    //       fill: 'gray', strokeWidth: 0, desiredSize: new go.Size(8, 8),
    //       portId: id, alignment: spot,
    //       fromLinkable: true, toLinkable: true
    //     }
    //   );
    // }

    const makePort = function (name, leftside) {
      var port = $(go.Shape, "Rectangle",
        {
          fill: "gray", stroke: null,
          desiredSize: new go.Size(8, 8),
          portId: name,  // declare this object to be a "port"
          toMaxLinks: 1,  // don't allow more than one link into a port
          cursor: "pointer"  // show a different cursor to indicate potential link point
        });

      var lab = $(go.TextBlock, name,  // the name of the port
        { font: "7pt sans-serif" });

      var panel = $(go.Panel, "Horizontal",
        { margin: new go.Margin(2, 0) });

      // set up the port/panel based on which side of the node it will be on
      if (leftside) {
        port.toSpot = go.Spot.Left;
        port.toLinkable = true;
        lab.margin = new go.Margin(1, 0, 0, 1);
        panel.alignment = go.Spot.TopLeft;
        panel.add(port);
        panel.add(lab);
      } else {
        port.fromSpot = go.Spot.Right;
        port.fromLinkable = true;
        lab.margin = new go.Margin(1, 1, 0, 0);
        panel.alignment = go.Spot.TopRight;
        panel.add(lab);
        panel.add(port);
      }
      return panel;
    }

    // define the Node template
    // Original Version
    // dia.nodeTemplate =
    //   $(go.Node, 'Spot',
    //     {
    //       contextMenu:
    //         $('ContextMenu',
    //           $('ContextMenuButton',
    //             $(go.TextBlock, 'Group'),
    //             { click: function (e, obj) { e.diagram.commandHandler.groupSelection(); } },
    //             new go.Binding('visible', '', function (o) {
    //               return o.diagram.selection.count > 1;
    //             }).ofObject())
    //         )
    //     },
    //     new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
    //     $(go.Panel, 'Auto',
    //       $(go.Shape, 'RoundedRectangle', { stroke: null },
    //         new go.Binding('fill', 'color', (c, panel) => {

    //           return c;
    //         })
    //       ),
    //       $(go.TextBlock, { margin: 8, editable: true },
    //         new go.Binding('text').makeTwoWay())
    //     ),
    //     // Ports
    //     makePort('t', go.Spot.TopCenter),
    //     makePort('l', go.Spot.Left),
    //     makePort('r', go.Spot.Right),
    //     makePort('b', go.Spot.BottomCenter)
    //   );

    // Rouven Version
    // dia.nodeTemplate =
    //   $(go.Node, "Auto",
    //     $(go.Shape, "Rectangle", { fill: "lightgray" }),
    //     $(go.Panel, "Table",
    //       $(go.RowColumnDefinition,
    //         { column: 0, alignment: go.Spot.Left }),
    //       $(go.RowColumnDefinition,
    //         { column: 2, alignment: go.Spot.Right }),
    //       $(go.TextBlock,  // the node title
    //         {
    //           column: 0, row: 0, columnSpan: 3, alignment: go.Spot.Center,
    //           font: "bold 10pt sans-serif", margin: new go.Margin(4, 2)
    //         },
    //         new go.Binding("text", "key")),
    //       $(go.Panel, "Horizontal",
    //         { column: 0, row: 1 },
    //         $(go.Shape,  // the "A" port
    //           { width: 6, height: 6, portId: "A", toSpot: go.Spot.Left }),
    //         $(go.TextBlock, "A")  // "A" port label
    //       ),
    //       $(go.Panel, "Horizontal",
    //         { column: 0, row: 2 },
    //         $(go.Shape,  // the "B" port
    //           { width: 6, height: 6, portId: "B", toSpot: go.Spot.Left }),
    //         $(go.TextBlock, "B")  // "B" port label
    //       ),
    //       $(go.Panel, "Horizontal",
    //         { column: 2, row: 1, rowSpan: 2 },
    //         $(go.TextBlock, "Out"),  // "Out" port label
    //         $(go.Shape,  // the "Out" port
    //           { width: 6, height: 6, portId: "Out", fromSpot: go.Spot.Right })
    //       )
    //     )
    //   );


    // the node template
    // includes a panel on each side with an itemArray of panels containing ports

    // from:
    // https://github.com/NorthwoodsSoftware/GoJS/blob/master/samples/dynamicPorts.html
    const portSize = new go.Size(8, 8);

    // context menu disabled for speed reasons
    dia.nodeTemplate =

      $(go.Node, "Table",
        {
          locationObjectName: "BODY",
          locationSpot: go.Spot.Center,
          selectionObjectName: "BODY",
          //contextMenu: nodeMenu
        },

        new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),

        // the body
        $(go.Panel, "Auto",
          {
            row: 1, column: 1, name: "BODY",
            stretch: go.GraphObject.Fill
          },
          $(go.Shape, "Rectangle",
            {
              fill: "#dbf6cb", stroke: null, strokeWidth: 0,
              minSize: new go.Size(120, 80)
            }),
          // Add Picture to Node (currently not working)
          $(go.Picture,
            {
              name: 'Picture',
              desiredSize: new go.Size(40, 30),
              //margin: new go.Margin(6, 8, 6, 10)
            },
            { alignment: new go.Spot(0, 0) },
            new go.Binding('source', 'name', function (key) {
              if (key < 0 || key > 16) return ''; // There are only 16 images on the server
              return 'assets/layout/images/nodes/' + key + '.png';
            })
          ),
          // Add Header Text (here: Node Name)
          $(go.TextBlock,
            {
              row: 0,
              column: 0,
              margin: 3,
              maxSize: new go.Size(80, NaN),
              stroke: "black",
              font: "bold 10pt sans-serif",
            },
            { alignment: new go.Spot(0.5, 0.1) },
            new go.Binding("text", "name").makeTwoWay()),
          //Add body content (to define what to show here)
          $(go.TextBlock, new go.Binding("text", "description").makeTwoWay(), { alignment: new go.Spot(0, 0.7) }),

          // $(go.TextBlock,
          //   {
          //     row: 1, column: 0, margin: 10,
          //     //textAlign: "center",
          //     font: "bold 14px Segoe UI,sans-serif", stroke: "#484848", editable: true
          //   },
          //   new go.Binding("text", "name").makeTwoWay())

          // Add Context Menu when Right-Click on Node
          {
            contextMenu:     // define a context menu for each node
              $("ContextMenu",  // that has one button
                $("ContextMenuButton",
                  {
                    "ButtonBorder.fill": "white",
                    "_buttonFillOver": "skyblue"
                  },
                  $(go.TextBlock, "Change Color"),
                  { click: this.changeColor })
                // more ContextMenuButtons would go here
              )  // end Adornment
          }


        ),  // end Auto Panel body

        // the Panel holding the left port elements, which are themselves Panels,
        // created for each item in the itemArray, bound to data.leftArray

        $(go.Panel, "Vertical",
          new go.Binding("itemArray", "leftArray"),
          {
            row: 1, column: 0,
            itemTemplate:
              $(go.Panel,
                {
                  _side: "left",  // internal property to make it easier to tell which side it's on
                  fromSpot: go.Spot.Left, toSpot: go.Spot.Left,
                  fromLinkable: true, toLinkable: true, cursor: "pointer",
                  //contextMenu: portMenu
                },
                new go.Binding("portId", "portId"),
                $(go.Shape, "Rectangle",
                  {
                    stroke: null, strokeWidth: 0,
                    desiredSize: portSize,
                    margin: new go.Margin(1, 0)
                  },
                  new go.Binding("fill", "portColor"))
              )  // end itemTemplate
          }
        ),  // end Vertical Panel

        // // the Panel holding the top port elements, which are themselves Panels,
        // // created for each item in the itemArray, bound to data.topArray
        // $(go.Panel, "Horizontal",
        //   new go.Binding("itemArray", "topArray"),
        //   {
        //     row: 0, column: 1,
        //     itemTemplate:
        //       $(go.Panel,
        //         {
        //           _side: "top",
        //           fromSpot: go.Spot.Top, toSpot: go.Spot.Top,
        //           fromLinkable: true, toLinkable: true, cursor: "pointer",
        //           //contextMenu: portMenu
        //         },
        //         new go.Binding("portId", "portId"),
        //         $(go.Shape, "Rectangle",
        //           {
        //             stroke: null, strokeWidth: 0,
        //             desiredSize: portSize,
        //             margin: new go.Margin(0, 1)
        //           },
        //           new go.Binding("fill", "portColor"))
        //       )  // end itemTemplate
        //   }
        // ),  // end Horizontal Panel


        // the Panel holding the right port elements, which are themselves Panels,
        // created for each item in the itemArray, bound to data.rightArray
        $(go.Panel, "Vertical",
          new go.Binding("itemArray", "rightArray"),
          {
            row: 1, column: 2,
            itemTemplate:
              $(go.Panel,
                {
                  _side: "right",
                  fromSpot: go.Spot.Right, toSpot: go.Spot.Right,
                  fromLinkable: true, toLinkable: true, cursor: "pointer",
                  //contextMenu: portMenu
                },
                new go.Binding("portId", "portId"),
                $(go.Shape, "Rectangle",
                  {
                    stroke: null, strokeWidth: 0,
                    desiredSize: portSize,
                    margin: new go.Margin(1, 0)
                  },
                  new go.Binding("fill", "portColor"))
              )  // end itemTemplate
          }
        ),  // end Vertical Panel

        // // the Panel holding the bottom port elements, which are themselves Panels,
        // // created for each item in the itemArray, bound to data.bottomArray
        // $(go.Panel, "Horizontal",
        //   new go.Binding("itemArray", "bottomArray"),
        //   {
        //     row: 2, column: 1,
        //     itemTemplate:
        //       $(go.Panel,
        //         {
        //           _side: "bottom",
        //           fromSpot: go.Spot.Bottom, toSpot: go.Spot.Bottom,
        //           fromLinkable: true, toLinkable: true, cursor: "pointer",
        //           //contextMenu: portMenu
        //         },
        //         new go.Binding("portId", "portId"),
        //         $(go.Shape, "Rectangle",
        //           {
        //             stroke: null, strokeWidth: 0,
        //             desiredSize: portSize,
        //             margin: new go.Margin(0, 1)
        //           },
        //           new go.Binding("fill", "portColor"))
        //       )  // end itemTemplate
        //   }
        // )  // end Horizontal Panel
      );  // end Node



    // dia.linkTemplate =
    //   $(go.Link,
    //     { routing: go.Link.Orthogonal, corner: 3 },
    //     $(go.Shape),
    //     $(go.Shape, { toArrow: "Standard" })
    //   );

    // dia.linkTemplate =
    //   $(CustomLink,  // defined below
    //     {
    //       routing: go.Link.AvoidsNodes,
    //       corner: 4,
    //       curve: go.Link.JumpGap,
    //       reshapable: true,
    //       resegmentable: true,
    //       relinkableFrom: true,
    //       relinkableTo: true
    //     },
    //     new go.Binding("points").makeTwoWay(),
    //     $(go.Shape, { stroke: "#2F4F4F", strokeWidth: 2 })
    //   );

    // from
    // https://github.com/NorthwoodsSoftware/GoJS/blob/master/samples/dynamicPorts.html
    // an orthogonal link template, reshapable and relinkable
    dia.linkTemplate =
      $(CustomLink,  // defined below
        {
          routing: go.Link.AvoidsNodes,
          corner: 4,
          curve: go.Link.JumpGap,
          reshapable: true,
          resegmentable: true,
          relinkableFrom: true,
          relinkableTo: true
        },
        new go.Binding("points").makeTwoWay(),
        // Initialisiert die Kantenfarben (je nach status)
        $(go.Shape, { stroke: "#2F4F4F", strokeWidth: 2 },
          // TODO: Checks for edge state property and colors the edge accordingly --> TODO: More than 2 states!!!
          new go.Binding('stroke', 'progress', progress => progress === "success" ? "#52ce60" /* green */ : 'black'),
          new go.Binding('stroke', 'progress', progress => progress === "fail" ? "red" /* red */ : 'black')
        )
      );


    //       // this is a normal Node template that also has a contextMenu defined for it
    // dia.nodeTemplate =
    // $(go.Node, "Auto",
    //   $(go.Shape, "RoundedRectangle",
    //     { fill: "white" },
    //     new go.Binding("fill", "color")),
    //   $(go.TextBlock, { margin: 5 },
    //     new go.Binding("text", "key")),
    //   {
    //     contextMenu:     // define a context menu for each node
    //       $("ContextMenu",  // that has one button
    //         $("ContextMenuButton",
    //           {
    //             "ButtonBorder.fill": "white",
    //             "_buttonFillOver": "skyblue"
    //           },
    //           $(go.TextBlock, "Change Color"),
    //           { click: this.changeColor })
    //         // more ContextMenuButtons would go here
    //       )  // end Adornment
    //   }
    // );

    // also define a context menu for the diagram's background
    dia.contextMenu =
      $("ContextMenu",
        $("ContextMenuButton",
          $(go.TextBlock, "Undo"),
          { click: (e, obj) => e.diagram.commandHandler.undo() },
          new go.Binding("visible", "", o => o.diagram.commandHandler.canUndo()).ofObject()),
        $("ContextMenuButton",
          $(go.TextBlock, "Redo"),
          { click: (e, obj) => e.diagram.commandHandler.redo() },
          new go.Binding("visible", "", o => o.diagram.commandHandler.canRedo()).ofObject()),
        // no binding, always visible button:
        $("ContextMenuButton",
          $(go.TextBlock, "New Node"),
          {
            click: (e, obj) => {
              e.diagram.commit(d => {
                var data = {};
                d.model.addNodeData(data);
                //part = d.findPartForData(data);  // must be same data reference, not a new {}
                // set location to saved mouseDownPoint in ContextMenuTool
                //part.location = d.toolManager.contextMenuTool.mouseDownPoint;
              }, 'new node');
            }
          })
      );


    return dia;
  }

  // When the diagram model changes, update app data to reflect those changes. Be sure to use immer's "produce" function to preserve immutability
  public diagramModelChange = function (changes: go.IncrementalData) {

    if (!changes) return;

    // Note: We disabled the line below because of this-pointer shadowing. If this leads to problems, reactive it
    //console.log("Diagram Change: WARNING: This pointer changed, see code comments in case of erros");
    const appComp = this;     // Tutorial version
    //const appComp = self;

    this.state = produce(this.state, draft => {
      // set skipsDiagramUpdate: true since GoJS already has this update
      // this way, we don't log an unneeded transaction in the Diagram's undoManager history
      draft.skipsDiagramUpdate = true;
      console.log("DiagramModelChanges", changes);
      draft.diagramNodeData = DataSyncService.syncNodeData(changes, draft.diagramNodeData, appComp.observedDiagram.model);
      draft.diagramLinkData = DataSyncService.syncLinkData(changes, draft.diagramLinkData, appComp.observedDiagram.model);
      draft.diagramModelData = DataSyncService.syncModelData(changes, draft.diagramModelData);
      // If one of the modified nodes was the selected node used by the inspector, update the inspector selectedNodeData object
      const modifiedNodeDatas = changes.modifiedNodeData;
      if (modifiedNodeDatas && draft.selectedNodeData) {
        for (let i = 0; i < modifiedNodeDatas.length; i++) {
          const mn = modifiedNodeDatas[i];
          const nodeKeyProperty = appComp.myDiagramComponent.diagram.model.nodeKeyProperty as string;
          if (mn[nodeKeyProperty] === draft.selectedNodeData[nodeKeyProperty]) {
            draft.selectedNodeData = mn;
          }
        }
      }
    });
  };

  // When the diagram model changes, update app data to reflect those changes. Be sure to use immer's "produce" function to preserve immutability
  public diagramModelChange2 = function (changes: go.IncrementalData) {

    // https://forum.nwoods.com/t/updating-diagram-on-model-change/9153
    //

    if (!changes) return;
    const appComp = this;
    this.state = produce(this.state, draft => {
      // set skipsDiagramUpdate: true since GoJS already has this update
      // this way, we don't log an unneeded transaction in the Diagram's undoManager history
      draft.skipsDiagramUpdate = true;
      console.log("DiagramModelChanges", changes);
      draft.diagramNodeData = DataSyncService.syncNodeData(changes, draft.diagramNodeData, appComp.observedDiagram.model);
      draft.diagramLinkData = DataSyncService.syncLinkData(changes, draft.diagramLinkData, appComp.observedDiagram.model);
      draft.diagramModelData = DataSyncService.syncModelData(changes, draft.diagramModelData);
      // If one of the modified nodes was the selected node used by the inspector, update the inspector selectedNodeData object
      const modifiedNodeDatas = changes.modifiedNodeData;
      if (modifiedNodeDatas && draft.selectedNodeData) {
        for (let i = 0; i < modifiedNodeDatas.length; i++) {
          const mn = modifiedNodeDatas[i];
          const nodeKeyProperty = appComp.myDiagramComponent.diagram.model.nodeKeyProperty as string;
          if (mn[nodeKeyProperty] === draft.selectedNodeData[nodeKeyProperty]) {
            draft.selectedNodeData = mn;
          }
        }
      }
    });

    // Check for added node and call getNodeSettings if true
    if (changes.insertedNodeKeys) {

      // get node settings
      if (changes.modifiedNodeData) {
        const old_go_node = changes.modifiedNodeData[0];
        const node_key = old_go_node.id;
        const wni: WorkflowNodeInfo = changes.modifiedNodeData[0].value;

        appComp.onNewNodeAdded(wni, node_key);  // side effect
      }
    };

  }

  onNewNodeAdded(nodeInfo: WorkflowNodeInfo, nodeKey: string) {

    const appComp = this;

    appComp.apiService.getNodeSettings(nodeInfo.Engine).subscribe(nodeSettings => {
      console.log("Fetched Node Settings", nodeSettings);

      const nodeProp = new wf.NodeProperties(nodeSettings.Configuration, nodeSettings.MetaInfo);

      const left_ports = GoJsCodec.createPorts(nodeInfo, true);
      const right_ports = GoJsCodec.createPorts(nodeInfo, false);

      appComp.myDiagramComponent.diagram.model.commit(m => {

        const data = m.findNodeDataForKey(nodeKey);

        if (data) {
          m.set(data, "leftArray", left_ports);
          m.set(data, "rightArray", right_ports);
          m.set(data, "nodeProperties", nodeProp);
          m.set(data, "description", "no settings");
        } else {
          console.log("No data for node key", nodeKey);
        }


      }, "addNewNode");

    })


  }


  // onCurrentNodeSetText2(key: string) {
  //   const _dia = this.myDiagramComponent.diagram;
  //   console.log("Modifying text for node with key", key)

  //   _dia.model.commit(m => {
  //     const data = m.findNodeDataForKey(key);
  //     m.set(data, "name", "Changed!");
  //   }, "setName");
  // }


  // public diagramModelChangeNew = function (changes: go.IncrementalData) {
  //   if (!changes) return;

  //   const appComp = this;

  //   // check if a new node was added
  //   if (changes.insertedNodeKeys) {
  //     // get node settings
  //     const old_go_node = changes.modifiedNodeData[0];
  //     const wni: WorkflowNodeInfo = changes.modifiedNodeData[0].value;

  //     appComp.apiService.getNodeSettings(wni.Engine).subscribe(nodeSettings => {
  //       console.log("Fetched Node Settings", nodeSettings);

  //       //const codec = new GoJsCodec(appComp.plugIns);

  //       old_go_node["leftArray"] = GoJsCodec.createPorts(wni, true);
  //       old_go_node["rightArray"] = GoJsCodec.createPorts(wni, false);
  //       old_go_node["settings"] = nodeSettings;

  //       this.state = produce(this.state, draft => {
  //         // set skipsDiagramUpdate: true since GoJS already has this update
  //         // this way, we don't log an unneeded transaction in the Diagram's undoManager history
  //         draft.skipsDiagramUpdate = true;
  //         console.log("DiagramModelChanges", changes);
  //         draft.diagramNodeData = DataSyncService.syncNodeData(changes, draft.diagramNodeData, appComp.observedDiagram.model);
  //         draft.diagramLinkData = DataSyncService.syncLinkData(changes, draft.diagramLinkData, appComp.observedDiagram.model);
  //         draft.diagramModelData = DataSyncService.syncModelData(changes, draft.diagramModelData);
  //         // If one of the modified nodes was the selected node used by the inspector, update the inspector selectedNodeData object
  //         const modifiedNodeDatas = changes.modifiedNodeData;
  //         if (modifiedNodeDatas && draft.selectedNodeData) {
  //           for (let i = 0; i < modifiedNodeDatas.length; i++) {
  //             const mn = modifiedNodeDatas[i];
  //             const nodeKeyProperty = appComp.myDiagramComponent.diagram.model.nodeKeyProperty as string;
  //             if (mn[nodeKeyProperty] === draft.selectedNodeData[nodeKeyProperty]) {
  //               draft.selectedNodeData = mn;
  //             }
  //           }
  //         }
  //       });

  //     })
  //   } else {
  //     this.state = produce(this.state, draft => {
  //       // set skipsDiagramUpdate: true since GoJS already has this update
  //       // this way, we don't log an unneeded transaction in the Diagram's undoManager history
  //       draft.skipsDiagramUpdate = true;
  //       console.log("DiagramModelChanges", changes);
  //       draft.diagramNodeData = DataSyncService.syncNodeData(changes, draft.diagramNodeData, appComp.observedDiagram.model);
  //       draft.diagramLinkData = DataSyncService.syncLinkData(changes, draft.diagramLinkData, appComp.observedDiagram.model);
  //       draft.diagramModelData = DataSyncService.syncModelData(changes, draft.diagramModelData);
  //       // If one of the modified nodes was the selected node used by the inspector, update the inspector selectedNodeData object
  //       const modifiedNodeDatas = changes.modifiedNodeData;
  //       if (modifiedNodeDatas && draft.selectedNodeData) {
  //         for (let i = 0; i < modifiedNodeDatas.length; i++) {
  //           const mn = modifiedNodeDatas[i];
  //           const nodeKeyProperty = appComp.myDiagramComponent.diagram.model.nodeKeyProperty as string;
  //           if (mn[nodeKeyProperty] === draft.selectedNodeData[nodeKeyProperty]) {
  //             draft.selectedNodeData = mn;
  //           }
  //         }
  //       }
  //     });
  //   }

  // };

  // updateStateChanges(changes: go.IncrementalData, appComp:any) : void {
  //   this.state = produce(this.state, draft => {
  //     // set skipsDiagramUpdate: true since GoJS already has this update
  //     // this way, we don't log an unneeded transaction in the Diagram's undoManager history
  //     draft.skipsDiagramUpdate = true;
  //     console.log("DiagramModelChanges", changes);
  //     draft.diagramNodeData = DataSyncService.syncNodeData(changes, draft.diagramNodeData, appComp.observedDiagram.model);
  //     draft.diagramLinkData = DataSyncService.syncLinkData(changes, draft.diagramLinkData, appComp.observedDiagram.model);
  //     draft.diagramModelData = DataSyncService.syncModelData(changes, draft.diagramModelData);
  //     // If one of the modified nodes was the selected node used by the inspector, update the inspector selectedNodeData object
  //     const modifiedNodeDatas = changes.modifiedNodeData;
  //     if (modifiedNodeDatas && draft.selectedNodeData) {
  //       for (let i = 0; i < modifiedNodeDatas.length; i++) {
  //         const mn = modifiedNodeDatas[i];
  //         const nodeKeyProperty = appComp.myDiagramComponent.diagram.model.nodeKeyProperty as string;
  //         if (mn[nodeKeyProperty] === draft.selectedNodeData[nodeKeyProperty]) {
  //           draft.selectedNodeData = mn;
  //         }
  //       }
  //     }
  //   });
  // }

  public initPalette(): go.Palette {
    const $ = go.GraphObject.make;
    const palette = $(go.Palette);

    // define the Node template
    palette.nodeTemplate =
      $(go.Node, 'Auto',
        $(go.Shape, 'RoundedRectangle',
          {
            stroke: null
          },
          new go.Binding('fill', 'color')
        ),
        $(go.TextBlock, { margin: 8 },
          new go.Binding('text', 'key')),
      );

    palette.model = $(go.GraphLinksModel);
    return palette;
  }

  //constructor(private cdr: ChangeDetectorRef) { }

  // Overview Component testing
  public oDivClassName = 'myOverviewDiv';
  public initOverview(): go.Overview {
    const $ = go.GraphObject.make;
    const overview = $(go.Overview);
    return overview;
  }
  public observedDiagram: go.Diagram = null;

  // currently selected node; for inspector
  public selectedNodeData?: go.ObjectData | null = null;


  public ngAfterViewInit() {

    if (this.observedDiagram) return;

    this.observedDiagram = this.myDiagramComponent.diagram;
    this.cdr.detectChanges(); // IMPORTANT: without this, Angular will throw ExpressionChangedAfterItHasBeenCheckedError (dev mode only)

    const appComp: GraphViewComponent = this;

    // listener for inspector
    this.myDiagramComponent.diagram.addDiagramListener('ChangedSelection', function (e) {
      if (e.diagram.selection.count === 0) {
        appComp.selectedNodeData = null;
      }
      const node = e.diagram.selection.first();
      appComp.state = produce(appComp.state, draft => {

        if (node instanceof go.Node) {
          var idx = draft.diagramNodeData.findIndex(nd => nd.id == node.data.id);
          var nd = draft.diagramNodeData[idx];
          draft.selectedNodeData = nd;
          console.log("draft.selectedNodeData", draft.selectedNodeData);


        } else {
          draft.selectedNodeData = null;
        }

        // EMIT COPY of selected GoNode
        // appComp.onObjectSingleClicked(e, appComp);

      });
    });
    // Register GoJs Events
    //this.registerGoEvents();

    // Animate the flow in the pipes
    const animation = new go.Animation();
    animation.easing = go.Animation.EaseLinear;

    this.observedDiagram.links.each(link => animation.add(link.findObject("PIPE"), "strokeDashOffset", 20, 0));

    // Run indefinitely
    animation.runCount = Infinity;
    animation.start();
  } // end ngAfterViewInit


  /**
   * Update a node's data based on some change to an inspector row's input
   * @param changedPropAndVal An object with 2 entries: "prop" (the node data prop changed), and "newVal" (the value the user entered in the inspector <input>)
   */
  public handleInspectorChange(changedPropAndVal) {

    const path = changedPropAndVal.prop;
    const value = changedPropAndVal.newVal;

    this.state = produce(this.state, draft => {
      var data = draft.selectedNodeData;

      data[path] = value;
      const key = data.id;

      const idx = draft.diagramNodeData.findIndex(nd => nd.id == key);
      if (idx >= 0) {
        draft.diagramNodeData[idx] = data;
        draft.skipsDiagramUpdate = false; // we need to sync GoJS data with this new app state, so do not skips Diagram update
      }
    });
  }


  // // Offenbar ist es egal ob es 'id' oder 'key' heißt.
  // // Der State wird von der 'produce' funktion von 'state' in 'draft' umkopiert.
  // // Die Kanten sind im GraphLinkModel index-basiert.

  // multiSampleNodeData() {

  //   // renamed key to id

  //   const data = [
  //     {
  //       //"key": 1, "name": "Unit One", "loc": "101 204",
  //       "id": 1, "type": "Input", "name": "Test_Excel", "loc": "101 204",
  //       //"leftArray": [{ "portColor": "#fae3d7", "portId": "left0" }],
  //       //"topArray": [{ "portColor": "#d6effc", "portId": "top0" }],
  //       //"bottomArray": [{ "portColor": "#ebe3fc", "portId": "bottom0" }],
  //       "rightArray": [{ "portColor": "#eaeef8", "portId": "right0" }, { "portColor": "#fadfe5", "portId": "right1" }]
  //     },
  //     {
  //       //"key": 2, "name": "Unit Two", "loc": "320 152",
  //       "id": 2, "type": "Select", "name": "12 fields selected", "loc": "320 152",
  //       "leftArray": [{ "portColor": "#6cafdb", "portId": "left0" }, { "portColor": "#66d6d1", "portId": "left1" }, { "portColor": "#fae3d7", "portId": "left2" }],
  //       //"topArray": [{ "portColor": "#d6effc", "portId": "top0" }],
  //       //"bottomArray": [{ "portColor": "#eaeef8", "portId": "bottom0" }, { "portColor": "#eaeef8", "portId": "bottom1" }, { "portColor": "#6cafdb", "portId": "bottom2" }],
  //       "rightArray": [{ "portColor": "#eaeef8", "portId": "right0" },]
  //     },
  //     {
  //       //"key": 3, "name": "Unit Three", "loc": "384 319",
  //       "id": 3, "type": "Filter", "name": "3 filters applied", "loc": "387 245",
  //       "leftArray": [{ "portColor": "#66d6d1", "portId": "left0" }, { "portColor": "#fadfe5", "portId": "left1" }, { "portColor": "#6cafdb", "portId": "left2" }],
  //       //"topArray": [{ "portColor": "#66d6d1", "portId": "top0" }],
  //       //"bottomArray": [{ "portColor": "#6cafdb", "portId": "bottom0" }],
  //       "rightArray": [{ "portColor": "#eaeef8", "portId": "right0" },]
  //     },
  //     {
  //       //"key": 4, "name": "Unit Four", "loc": "138 351",
  //       "id": 4, "type": "Union", "name": "Unit Four", "loc": "588 246",
  //       "leftArray": [{ "portColor": "#fae3d7", "portId": "left0" }],
  //       //"topArray": [{ "portColor": "#6cafdb", "portId": "top0" }],
  //       //"bottomArray": [{ "portColor": "#6cafdb", "portId": "bottom0" }],
  //       "rightArray": [{ "portColor": "#6cafdb", "portId": "right0" }, { "portColor": "#66d6d1", "portId": "right1" }]
  //     }
  //   ];

  //   return data;
  // }


  // multiSampleNodeData() {
  //   const data = [
  //     { "id": 1, "type": "Table", "name": "Product" },
  //     { "id": 2, "type": "Table", "name": "Sales" },
  //     { "id": 3, "type": "Table", "name": "Period" },
  //     { "id": 4, "type": "Table", "name": "Store" },
  //     { "id": 11, "type": "Join", "name": "Product, Class" },
  //     { "id": 12, "type": "Join", "name": "Period" },
  //     { "id": 13, "type": "Join", "name": "Store" },
  //     { "id": 21, "type": "Project", "name": "Product, Class" },
  //     { "id": 31, "type": "Filter", "name": "Boston, Jan2014" },
  //     { "id": 32, "type": "Filter", "name": "Boston, 2014" },
  //     { "id": 41, "type": "Group", "name": "Sales" },
  //     { "id": 42, "type": "Group", "name": "Total Sales" },
  //     { "id": 51, "type": "Join", "name": "Product Name" },
  //     { "id": 61, "type": "Sort", "name": "Product Name" },
  //     { "id": 71, "type": "Export", "name": "File" },
  //     { "id": 77, "type": "Sort", "name": "FUCKYOU" }
  //   ];

  //   return data

  // }


  // // Edges mit progress attribut um zu erkennen, welcher Schritt erfolgreich, fehlgeschlagen oder noch nicht begonnen hat
  // multiSampleLinkData() {
  //   const data = [
  //     // { "from": 4, "to": 2, "fromPort": "top0", "toPort": "bottom0" },
  //     // { "from": 4, "to": 2, "fromPort": "top0", "toPort": "bottom0" },
  //     // { "from": 3, "to": 2, "fromPort": "top0", "toPort": "bottom1" },
  //     { "from": 3, "to": 4, "fromPort": "right0", "toPort": "left0" },
  //     { "from": 2, "to": 3, "fromPort": "right0", "toPort": "left2","progress":"fail" },
  //     { "from": 1, "to": 2, "fromPort": "right0", "toPort": "left1", "progress":"success"  },
  //     { "from": 1, "to": 2, "fromPort": "right1", "toPort": "left2","progress":"success" }
  //   ];

  //   return data;
  // }


  getDiagramNodeData() {

    // welche Attribute gibt es pro Knoten
    // id -> id
    // text -> label
    // color -> color
    // loc -> position

    const data = [
      {
        id: 'Alpha', text: "Alpha", color: 'lightblue', loc: "0 0", rightArray: [
          {
            "portColor": "#eaeef8",
            "portId": "right0"
          },
          {
            "portColor": "#fadfe5",
            "portId": "right1"
          }]
      },
      { id: 'Beta', text: "Beta", color: 'orange', loc: "100 0" },
      { id: 'Gamma', text: "Gamma", color: 'lightgreen', loc: "0 100" },
      { id: 'Delta', text: "Delta", color: 'pink', loc: "100 100" }
    ];
    return data;
  }

  getDiagramNodeData2() {
    const data = [
      { key: 1, text: "Alpha", color: "lightblue" },
      { key: 2, text: "Beta", color: "orange" },
      { key: 3, text: "Gamma", color: "lightgreen" },
      { key: 4, text: "Delta", color: "pink" }
    ]

    return data;
  }

  getDiagramModel3() {

    // Diese parameter können die Property-Namen steuern!

    //       nodeKeyProperty: 'id',
    //       linkToPortIdProperty: 'toPort',
    //       linkFromPortIdProperty: 'fromPort',
    //       linkKeyProperty: 'key' // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel

    const model = new go.GraphLinksModel(
      [
        { key: "Alpha", color: "lightblue", loc: "0 0" },
        { key: "Beta", color: "orange", loc: "100 0" },
        { key: "Gamma", color: "lightgreen", loc: "0 100" },
        { key: "Delta", color: "pink", loc: "100 100" }
      ],
      [
        { from: 1, to: 2 },
        { from: 1, to: 4 }
      ]);

    return model;
  }

  diagramDataFromWorkflow() {
    const graph = workflow_graph;


    const result = new Array<{ id: string; text: string; color: string; loc: string; }>();

    for (let node of graph.Nodes) {

      const loc = String(node.GuiSettings.X) + " " + String(node.GuiSettings.Y);
      const entry = { id: node.ID, text: node.Name, color: 'lightblue', loc: loc };

      result.push(entry);
    }

    return result;


    // MULTI PORT


    //   { "class": "go.GraphLinksModel",
    //   "copiesArrays": true,
    //   "copiesArrayObjects": true,
    //   "linkFromPortIdProperty": "fromPort",
    //   "linkToPortIdProperty": "toPort",
    //   "nodeDataArray": [
    // {"key":1, "name":"Unit One", "loc":"101 204",
    //  "leftArray":[ {"portColor":"#fae3d7", "portId":"left0"} ],
    //  "topArray":[ {"portColor":"#d6effc", "portId":"top0"} ],
    //  "bottomArray":[ {"portColor":"#ebe3fc", "portId":"bottom0"} ],
    //  "rightArray":[ {"portColor":"#eaeef8", "portId":"right0"},{"portColor":"#fadfe5", "portId":"right1"} ] },
    // {"key":2, "name":"Unit Two", "loc":"320 152",
    //  "leftArray":[ {"portColor":"#6cafdb", "portId":"left0"},{"portColor":"#66d6d1", "portId":"left1"},{"portColor":"#fae3d7", "portId":"left2"} ],
    //  "topArray":[ {"portColor":"#d6effc", "portId":"top0"} ],
    //  "bottomArray":[ {"portColor":"#eaeef8", "portId":"bottom0"},{"portColor":"#eaeef8", "portId":"bottom1"},{"portColor":"#6cafdb", "portId":"bottom2"} ],
    //  "rightArray":[  ] },
    // {"key":3, "name":"Unit Three", "loc":"384 319",
    //  "leftArray":[ {"portColor":"#66d6d1", "portId":"left0"},{"portColor":"#fadfe5", "portId":"left1"},{"portColor":"#6cafdb", "portId":"left2"} ],
    //  "topArray":[ {"portColor":"#66d6d1", "portId":"top0"} ],
    //  "bottomArray":[ {"portColor":"#6cafdb", "portId":"bottom0"} ],
    //  "rightArray":[  ] },
    // {"key":4, "name":"Unit Four", "loc":"138 351",
    //  "leftArray":[ {"portColor":"#fae3d7", "portId":"left0"} ],
    //  "topArray":[ {"portColor":"#6cafdb", "portId":"top0"} ],
    //  "bottomArray":[ {"portColor":"#6cafdb", "portId":"bottom0"} ],
    //  "rightArray":[ {"portColor":"#6cafdb", "portId":"right0"},{"portColor":"#66d6d1", "portId":"right1"} ] }
    //  ],
    //   "linkDataArray": [
    // {"from":4, "to":2, "fromPort":"top0", "toPort":"bottom0"},
    // {"from":4, "to":2, "fromPort":"top0", "toPort":"bottom0"},
    // {"from":3, "to":2, "fromPort":"top0", "toPort":"bottom1"},
    // {"from":4, "to":3, "fromPort":"right0", "toPort":"left0"},
    // {"from":4, "to":3, "fromPort":"right1", "toPort":"left2"},
    // {"from":1, "to":2, "fromPort":"right0", "toPort":"left1"},
    // {"from":1, "to":2, "fromPort":"right1", "toPort":"left2"}
    //  ]}



    // ORIGINAL SAMPLE


    // // Diagram state props
    // diagramNodeData: [
    //   { id: 'Alpha', text: "Alpha", color: 'lightblue', loc: "0 0" },
    //   { id: 'Beta', text: "Beta", color: 'orange', loc: "100 0" },
    //   { id: 'Gamma', text: "Gamma", color: 'lightgreen', loc: "0 100" },
    //   { id: 'Delta', text: "Delta", color: 'pink', loc: "100 100" }
    // ],
    // diagramLinkData: [
    //   { key: -1, from: 'Alpha', to: 'Beta', fromPort: 'r', toPort: '1' },
    //   { key: -2, from: 'Alpha', to: 'Gamma', fromPort: 'b', toPort: 't' },
    //   { key: -3, from: 'Beta', to: 'Beta' },
    //   { key: -4, from: 'Gamma', to: 'Delta', fromPort: 'r', toPort: 'l' },
    //   { key: -5, from: 'Delta', to: 'Alpha', fromPort: 't', toPort: 'r' }
    // ],
    // diagramModelData: { prop: 'value' },
    // skipsDiagramUpdate: false,
    // selectedNodeData: null, // used by InspectorComponent

    // // Palette state props
    // paletteNodeData: [
    //   { key: 'Epsilon', text: 'Epsilon', color: 'red' },
    //   { key: 'Kappa', text: 'Kappa', color: 'purple' }
    // ],
    // paletteModelData: { prop: 'val' }
  }

  registerGoEvents() {
    // Object Single Clicked

    // this.myDiagramComponent.diagram.addDiagramListener("ObjectSingleClicked",
    //   function (e) {
    //     var part = e.subject.part;
    //     if (!(part instanceof go.Link)) alert("Clicked on " + part.data.key);
    //   });

    const comp = this;

    // Diagram Event - Object Single Click
    const my_digramEvent_callback: (e: go.DiagramEvent) => void = (arg: go.DiagramEvent) => {
      this.onObjectSingleClicked(arg, comp);
    }
    this.myDiagramComponent.diagram.addDiagramListener("ObjectSingleClicked", my_digramEvent_callback);

    // Model change Event
    // const my_changedEvent_callback: (e: go.ChangedEvent) => void = (arg: go.ChangedEvent) => {
    //   this.onModelChanged(arg, comp);
    // }
    // this.myDiagramComponent.diagram.addModelChangedListener(my_changedEvent_callback);
    //Event 2
    //Event 3
  }
  onCurrentNodeSetText2(key: string) {
    const _dia = this.myDiagramComponent.diagram;
    console.log("Modifying text for node with key", key);

    _dia.model.commit(m => {
      const data = m.findNodeDataForKey(key);

      if(data) {
        m.set(data, "name", "Changed!");
      } else {
        console.log("No data found for node key", key);
      }
      

    }, "setName");
  }

  onCurrentNodeSetText(key: string) {

    console.log("Modifiying text for node with key", key);

    const trans_name = "modify";

    const start_res = this.myDiagramComponent.diagram.startTransaction(trans_name);

    const _model = this.myDiagramComponent.diagram.model;
    const obj_data = _model.findNodeDataForKey(key);
    if (obj_data) {
      obj_data["name"] = "Changed!";
      const comit_res = this.myDiagramComponent.diagram.commitTransaction(trans_name);
    } else {
      this.myDiagramComponent.diagram.rollbackTransaction();
    }

  }

  // Wird nicht mehr gebraucht, da es über die Transaktionen durchgeführt wird.

  // onModelChanged(evt: go.ChangedEvent, comp: GraphViewComponent): void {
  //   //console.log("Model Changed", evt);
  //   // console.log("Old Value", e.oldValue);
  //   // console.log("Old Param", e.oldParam);
  //   // console.log("Param", e.getParam(false));
  //   // console.log("Value", e.getValue(false));



  //   // ignore unimportant Transaction events
  //   if (!evt.isTransactionFinished) return;
  //   var txn = evt.object;  // a Transaction
  //   if (txn === null) return;
  //   // iterate over all of the actual ChangedEvents of the Transaction

  //   txn.changes.each(e => {
  //     // ignore any kind of change other than adding/removing a node
  //     if (e.modelChange !== "nodeDataArray") return;
  //     // record node insertions and removals
  //     if (e.change === go.ChangedEvent.Insert) {
  //       console.log(evt.propertyName + " added node with key: " + e.newValue.key);
  //       //console.log("New Value", e.newValue);
  //       //this.callApiGetSettings(e.newValue.value);
  //       comp.callApiGetSettings(e.newValue.value);
  //     } else if (e.change === go.ChangedEvent.Remove) {
  //       console.log(evt.propertyName + " removed node with key: " + e.oldValue.key);
  //     }
  //   });
  // }

  // callApiGetSettings(plugIn: WorkflowNodeInfo): Observable<number> {

  //   console.log("Call API getNodeSettings with", plugIn);


  //   return of(1);
  // }

  onObjectSingleClicked(e: go.DiagramEvent, comp: GraphViewComponent): void {
    var part = e.subject.part;
    if (!(part instanceof go.Link)) {
      //alert("Clicked on " + part.data.key);

      // TODO: emit generic event
      console.log("part Data", part.data);
      const node: GoNode = part.data;
      const workflow: Workflow = this.workflow;

      const wfClone = lodash.cloneDeep(this.workflow);
      const nodeClone = lodash.cloneDeep(node);

      const wfGraphEvent = WorkflowGraphEventFactory.buildNodeClicked("graphView", nodeClone, wfClone);
      comp.sql_api.nodeSelectedEmitter.emit(wfGraphEvent);
    }

    // console.log("Looking Up node Key");
    // if (e.subject.part.data) {
    //   console.log("Clicked on Node");
    //   const go_node = e.subject.part.data;
    //   const node_key = go_node.id;
    //   comp.onCurrentNodeSetText2(node_key);
    // }

  }

  onNodeSettingsChanged(wfevent: WorkflowGraphEvent<string, NodeSettingsChangedData>) {

    // const _dia = this.myDiagramComponent.diagram;
    // console.log("Modifying node for node with key", node);
    // console.log("Modified NodeSettings: ", node.settings, node.description);

    // _dia.model.commit(m => {
    //   const data = m.findNodeDataForKey(node.id);
    //   m.set(data, "settings", node.settings);
    //   m.set(data, "description", node.description);
    // }, "settingsChanged");

    const node = wfevent.Data.GoNode;
    const nodeProperties = wfevent.Data.Settings;


    console.log("Modifiying text for node with key", node);

    // // OPTION 1: Über Transaktion -> funktioniert nicht, Model wird aktualisiert, aber nicht der Graph
    // const trans_name = "modify";
    // const start_res = this.myDiagramComponent.diagram.startTransaction(trans_name);

    // const _model = this.myDiagramComponent.diagram.model;
    // const obj_data = _model.findNodeDataForKey(node.id);
    // if (obj_data) {
    //   //obj_data["name"] = "Changed!";
    //   obj_data["settings"] = settings;
    //   obj_data["description"] = node.description;
    //   const comit_res = this.myDiagramComponent.diagram.commitTransaction(trans_name);
    //   console.log("commit_res", comit_res);
    // } else {
    //   this.myDiagramComponent.diagram.rollbackTransaction();
    // }


    // OPTION 2: über State Object und produce-Funktion -> funktioniert grds, aber führt zu NG-Fehler (CheckBefore)
    const path: string = "description";
    const desc: string | undefined = node.description;

    const settingsPath: string = "nodeProperties";
    const goSettings: wf.NodeProperties = nodeProperties;
    console.log("goSettings", goSettings);

    this.state = produce(this.state, draft => {
      var data = draft.selectedNodeData;
      console.log(path, desc);

      data[path] = desc;
      data[settingsPath] = goSettings;
      const key = data.id;
      console.log("data", data);
      console.log("key", key);

      const idx = draft.diagramNodeData.findIndex(nd => nd.id == key);
      console.log("idx", idx);

      if (idx >= 0) {
        draft.diagramNodeData[idx] = data;
        draft.skipsDiagramUpdate = false; // we need to sync GoJS data with this new app state, so do not skips Diagram update
      }
    });

  }


  // Context MENU Functions
  changeColor(e, obj) {
    this.myDiagramComponent.diagram.commit(d => {
      // get the context menu that holds the button that was clicked
      var contextmenu = obj.part;
      // get the node data to which the Node is data bound
      var nodedata = contextmenu.data;
      // compute the next color for the node
      var newcolor = "lightblue";
      switch (nodedata.color) {
        case "lightblue": newcolor = "lightgreen"; break;
        case "lightgreen": newcolor = "lightyellow"; break;
        case "lightyellow": newcolor = "orange"; break;
        case "orange": newcolor = "lightblue"; break;
      }
      // modify the node data
      // this evaluates data Bindings and records changes in the UndoManager
      d.model.set(nodedata, "color", newcolor);
    }, "changed color");
  }




}
