<p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>


<p-dialog header="{{headerText | translate}}" [(visible)]="displayDialog" modal="modal" showEffect="fade" editMode="row" dataKey="Title"
    [style]="{width: '600px'}" (onHide)="resetForms()">
    <app-system-message-log></app-system-message-log>


    <p-table #wt [value]="reports" sortField="Title" sortMode="single" styleClass="p-datatable-customers" [loading]="savingInProgress"
        [globalFilterFields]="['Title']" [rows]="10" paginator=true [scrollable]="false"
        styleClass="p-datatable-customers p-datatable-sm">
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="wt.filterGlobal($event.target.value, 'contains')"
                        placeholder="{{'Workflows.SearchForWorkflow' | translate}}" />
                </span>
                <span class="p-input-icon-right">
                    <button pButton class="p-button p-button-text" label="New Entry" (click)="addEntry()"></button>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Title'| translate}}
                        <p-columnFilter type="text" field="Title" display="menu" class="p-ml-auto">
                        </p-columnFilter>
                    </div>
                </th>
                <th>
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Url'| translate}}
                        <p-columnFilter type="text" field="Url" display="menu" class="p-ml-auto">
                        </p-columnFilter>
                    </div>
                </th>
                <th>
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{'Permissions'| translate}}
                        <p-columnFilter type="text" field="Permissions" display="menu" class="p-ml-auto">
                        </p-columnFilter>
                    </div>
                </th>
                <th style="width: 4rem"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-report let-rowIndex="rowIndex">
            <tr [pEditableRow]="column" [pSelectableRow]="report">
                <td><span class="p-column-title">{{'Title'| translate}}</span>
                    <input pInputText [(ngModel)]="report.Title" [style]="{'width':'100%'}"
                     [ngModelOptions]="{standalone: true}">
        
                </td>
                <td><span class="p-column-title">{{'Url'| translate}}</span>
                    <input pInputText [(ngModel)]="report.Url" [style]="{'width':'100%'}"
                     [ngModelOptions]="{standalone: true}">
                </td>
                <td><span class="p-column-title">{{'Permissions'| translate}}</span>
                    <p-multiSelect appendTo="body" [options]="userInfos" [(ngModel)]="report.Permissions" optionalValue="ID" optionLabel="Username"></p-multiSelect>
                </td>
                <td>
                    <button pButton icon="pi pi-trash" (click)="deleteEntry(rowIndex)"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="3">{{'NoEntryFound'| translate}}</td>
            </tr>
        </ng-template>
    </p-table>

    <p-footer>

        <button pButton pRipple type="button" icon="pi pi-times" (click)="displayDialog=false"
            class="p-button-rounded p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'Close' | translate}}"></button>
        <button pButton pRipple type="button" (click)="createReport()"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'Create' | translate}}"
            [icon]="savingInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'"></button>


    </p-footer>
</p-dialog>
