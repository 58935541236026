import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { forkJoin, of, throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { concatMap, map, mergeMap } from 'rxjs/operators';
import { TargetAccessLike } from 'src/app/models/api/com/bion/connect/TargetAccessLike';
import { DestinationRow } from 'src/app/models/api/domain/NewDataSourceModelBase';
import { DestinationConnection } from 'src/app/models/api/models/staging/DestinationConnection';
import { DestinationView } from 'src/app/models/destination-view.model';
import { GuiErrorException, GuiErrorCode } from 'src/app/models/gui.error.model';
import { DestinationService } from 'src/app/services/destinationService';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { SubSink } from 'subsink';
import { DestinationChangedArg } from '../../components/define-destination/define-destination.component';
import { TranslateService } from '@ngx-translate/core';


export enum DialogPage {
  SelectDestination,
  DefineDestination,
  Finish
}

@Component({
  selector: 'app-create-destination-dialog',
  templateUrl: './create-destination-dialog.component.html',
  styleUrls: ['./create-destination-dialog.component.scss'],
  animations: [trigger("fade", [
    state("void", style({ opacity: 0 })),
    transition(":enter", [animate(300)]),
    transition(":leave", [animate(500)]),
  ]),],
  providers: [MessageService],
})
export class CreateDestinationDialogComponent<T, C> implements OnInit, OnDestroy {
  displayDestinationDialog: boolean = false;
  subs = new SubSink;
  homeStep: MenuItem = { label: " Create new Destination", icon: 'pi pi-plus-circle', disabled: true };
  currentSteps: MenuItem[] = [];
  savingInProgress: boolean = false;
  buttonDisabled: boolean = false;

  currentDestinationRow: DestinationRow = new DestinationRow(-1, "", "default", "Keep", "Keep", "Append");
  currentDestinationView?: DestinationView<any, any, any>;

  @Output() currentDestinationViewEmitter = new EventEmitter<DestinationView<any, any, any>>();

  constructor(private errorService: SystemMessageLogService,
    private destinationApi: DestinationService, private translate: TranslateService,private messageService: MessageService) { }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
    //this.resetForms();
  }

  ngOnInit(): void {
    this.subs.sink = this.initializeComponent().subscribe((display: boolean) => {
      this.displayDestinationDialog = display;
    });

  }

  onDestinationChanged(event: DestinationChangedArg) {
    this.currentDestinationRow.name = event.Name;
    this.currentDestinationRow.description = event.Description;
    this.currentDestinationRow.targetWriteMode = event.TargetMode;
    this.currentDestinationRow.entityWriteMode = event.EntityMode;
    this.currentDestinationRow.recordsWriteMode = event.RecordMode;
    this.currentDestinationRow.stream = event.stream;

    // TODO: Add WriteModes
    //this.currentDestinationRow.writeMode = event.WriteMode.name;  // TODO: check if name OR value!

  }

  initializeComponent(): EventEmitter<boolean> {
    this.currentSteps = this.setSteps(0);
    let displayDestDialogObs = this.destinationApi.displayNewDestinationDialog;

    return displayDestDialogObs
  }

  setSteps(activeIndex:number): MenuItem[] {
    let stepsItems:MenuItem[] = [];
    if (activeIndex == 1) {
      stepsItems = [
        { label: "Choose Target", disabled: true },
        { label: "Define Destination", disabled: false }
      ];
    }
    if (activeIndex === 0) {
      stepsItems = [
        { label: "Choose Target", disabled: false },
      ];

    }

    return stepsItems
  }


  // UI Callbacks
  onNext(event: PointerEvent) {

    this.savingInProgress = true;
    this.buttonDisabled = true;

    this.subs.sink = this.stepForward().subscribe((activeindex: number) => {
      this.errorService.clearHttpError();
      this.activeindex = activeindex;
      this.currentSteps = this.setSteps(activeindex);

      if (activeindex === DialogPage.Finish) {

        // TODO: add new end logic here
        this.displayDestinationDialog = false;

        this.messageService.add({
          severity: "success",
					summary: this.translate.instant("Message.UpdateDataStoreSuccess.Title"),
					detail: this.translate.instant("Message.UpdateDataStoreSuccess.Text"),
        });

      }
    },
      (error: Error) => {
        this.errorService.handleError(error);
        this.savingInProgress = false;
        this.buttonDisabled = false;
      }, () => {
        this.savingInProgress = false;
        this.buttonDisabled = false;
      })
  }

  onBack(event: PointerEvent) {

    this.savingInProgress = true;
    this.buttonDisabled = true;

    this.subs.sink = this.stepBackward().subscribe((activeindex: number) => {
      this.activeindex = activeindex;
      this.currentSteps = this.setSteps(activeindex);
    },
      (error: Error) => {
        this.errorService.handleError(error);

      }, () => {
        this.savingInProgress = false;
        this.buttonDisabled = false;
      })
  }

  /**
   * Controls for step 1 - Select Connector
   * @param event
   */
  setcurrentDestinationView(event:DestinationView<any, any, any>) {
    this.currentDestinationView = event;
  }

  onCloseDialog() {
    this.subs.sink = this.resetAllTabs().subscribe(() => {
      this.displayDestinationDialog = false;
      this.activeindex = 0;

    }, (err: Error) => {
      this.errorService.handleError(err);

    }, () => {
      this.savingInProgress = false;
      this.buttonDisabled = false;
    })
  }

  // DEA (Zustandsmaschine)
  activeindex = 0;

  stepForward(): Observable<number> {
    if (this.activeindex == DialogPage.SelectDestination) {
      return this.from_select_dest_to_define_dest();
    }
    if (this.activeindex == DialogPage.DefineDestination) {
      return this.from_define_dest_to_create_dest();
    }
    // if (this.activeindex == 2) {
    //   return this.from_define_dest_to_create_dest();
    // }
    const obs = throwError("Step does not exist. CurrentIndex: " + this.activeindex);
    return obs
  }
  stepBackward(): Observable<number> {
    if (this.activeindex == DialogPage.DefineDestination) {
      return this.fromDefineDestToSelectDest();
    }
    // if (this.activeindex == 2) {
    //   return this.fromDefineDataSourceToSelectFields();
    // }
    const obs = throwError("Step does not exist. CurrentIndex: " + this.activeindex);
    return obs
  }
  // goToSelectConnector(): Observable<number> {
  //   this.activeindex = 0;
  //   let indexObs = of(0);

  //   return indexObs
  // }
  // fromSelectConnectorToSelectFields(): Observable<number> {
  //   if (this.currentDestinationView === undefined) return throwError(new GuiErrorException(GuiErrorCode.noConnectorSelected));
  //   if (this.currentDestinationView.connectorSettings === undefined) return throwError(new GuiErrorException(GuiErrorCode.noSettingsProvided));
  //   if (this.currentDestinationView.isFileBased() && this.currentDestinationView.getBase64FileData() === undefined) return throwError(new GuiErrorException(GuiErrorCode.noFileProvided));

  //   // let indexObs = this.loadTablePreview().pipe(map(() => {
  //   //   return 1
  //   // }))

  //   // return indexObs

  //   return of(1);
  // }

  from_select_dest_to_define_dest(): Observable<number> {
    if (this.currentDestinationView === undefined) return throwError(new GuiErrorException(GuiErrorCode.noConnectorSelected));
    if (this.currentDestinationView.connectorSettings === undefined) return throwError(new GuiErrorException(GuiErrorCode.noSettingsProvided));

    return of(DialogPage.DefineDestination);
  }


  // fromSelectFieldsToDefineDataSource(): Observable<number> {
  //   if (this.selectedDataSourceFields === undefined || this.selectedDataSourceFields.length === 0) return throwError(new GuiErrorException(GuiErrorCode.noColumnsSelected));

  //   let indexObs = of(2);
  //   return indexObs
  // }

  // fromDefineDataSourceToSelectFields(): Observable<number> {
  //   const indexObs = this.resetDefineDataSourceTab().pipe(map(() => {
  //     return 1
  //   }));
  //   return indexObs


  // }

  from_define_dest_to_select_dest(): Observable<number> {
    return of(DialogPage.SelectDestination);
  }

  fromDefineDestToSelectDest(): Observable<number> {
    return this.resetSelectDestTab().pipe(map(() => {
      return 0
    }));
  }
  // resetSelectConnectorTab(): Observable<number> {
  //   //this.selectedAdapterTypeInfo = undefined;
  //   return of(-1)
  // }
  resetSelectDestTab(): Observable<number> {
    this.currentDestinationView = undefined;

    return of(-1)
  }
  // resetDefineDataSourceTab(): Observable<number> {
  //   this.currentDestinationName = undefined;
  //   this.currentDestinationDescription = undefined;
  //   // this.selectedWriteModeOption = undefined;
  //   return of(-1)
  // }
  resetAllTabs(): Observable<boolean> {
    //const resetConnectorObs = this.resetSelectConnectorTab();
    const resetSelectFieldsObs = this.resetSelectDestTab();
    //const resetDefineDSObs = this.resetDefineDataSourceTab();

    let finalResetObs = forkJoin( resetSelectFieldsObs).pipe(mergeMap(() => {
      return of(true)
    }));

    return finalResetObs
  }

  from_define_dest_to_create_dest(): Observable<number> {

    try {

      if (this.currentDestinationRow.name === undefined) throw new Error("You must specify a name");
      //if(this.currentDestinationRow.writeMode === undefined) throw new Error("You must select a write mode");
      if (this.currentDestinationView === undefined) throw new Error("Please select a destination adapter");

      //const destination = new DestinationRow(-1, this.currentDestinationRow.name, this.currentDestinationRow.writeMode);
      const destination = this.currentDestinationRow;
      return this.destinationApi.createDestination(destination).pipe(concatMap(new_dest => {

        const view = this.currentDestinationView;
        const adapter_id = view.getConnectorID();
        const connectorSettings = <TargetAccessLike<any>>view.connectorSettings;
        const dest_settings = new DestinationConnection(new_dest.id, adapter_id, connectorSettings);
        // todo: update current destination from result
        return this.destinationApi.createDestinationConnection(dest_settings).pipe(map(new_dest_settings => DialogPage.Finish));
      }))
    } catch (e) {
      return throwError(<Error>e);
    }
  }

  // /**
  //  * Final state where ds is created
  //  */
  // fromDefineDataSourceToCreateDataSource(): Observable<number> {
  //   if (!this.page_define_datasource.isValid()) return throwError(new GuiErrorException(GuiErrorCode.dsNameOrWriteModeNotSet));

  //   let createDSObs = this.createDataSourcesAsync_New().pipe(map((psa_res) => {
  //     this.currentSelectedDatasource = psa_res['currentSelectedDatasource'];
  //     this.datasourceService.datasourceChangedEmitter.emit("Changed");

  //   }));

  //   return createDSObs.pipe(map(() => {
  //     return 3
  //   }))
  // }
}
