import { Component, OnDestroy, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { CheckBoxEvents } from 'src/app/helper/events';
import { DataStoreInputPlugIn } from 'src/app/models/api/models/etl/DataStoreInputPlugIn.ns';
import { DataStore, DataStoreInputSettings } from 'src/app/models/datastore.model';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { CubesService } from 'src/app/services/cubes.service';
import { DesignerService } from 'src/app/services/designer.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { SubSink } from 'subsink';
import { NodeConfigComponentBase } from '../node-config-component-base';

@Component({
  selector: 'app-input-datastore-node',
  templateUrl: './input-datastore-node.component.html',
  styleUrls: ['./input-datastore-node.component.scss']
})
export class InputDatastoreNodeComponent extends NodeConfigComponentBase<DataStoreInputPlugIn.Settings>
  implements OnInit, OnDestroy {

  settingsToView(settings: DataStoreInputPlugIn.Settings): void {
    console.log("settings", settings);
    this.currentConfig = settings;

    const dsFromSettings = settings.DataStores;
    this.subs.sink = this.bionApi.getDataStoreObjectList().subscribe(ds => {
          this.datastores = ds;

          if (settings.SelectedDataStore) {
            this.selectedDataStore = settings.SelectedDataStore;
          } else {
      
          }
      
          this.latestPackageOnly = settings.QuerySettings.LastPackageOnly;
          this.includeMetaFields = settings.QuerySettings.IncludeMetaFields;

    })
    //this.datastores = settings.DataStores;


  }
  viewToSettings(): DataStoreInputPlugIn.Settings {

    if (this.currentConfig === undefined)
      throw new Error("No configuration set");

    const new_settings = { ... this.currentConfig };

    new_settings.DataStores = this.datastores;
    new_settings.SelectedDataStore = this.selectedDataStore;

    new_settings.QuerySettings.IncludeMetaFields = this.includeMetaFields;
    new_settings.QuerySettings.LastPackageOnly = this.latestPackageOnly;

    return new_settings;

    // throw new Error('Method not implemented.');
  }
  onSettingsChanged(settings: DataStoreInputSettings): void {
    throw new Error('Method not implemented.');
  }

  readonly InputPort = "Input";
  subs = new SubSink;
  currentConfig?: DataStoreInputPlugIn.Settings = undefined;
  selectedDataStore?: DataStore;
  datastores: DataStore[] = [];
  latestPackageOnly: boolean = false;
  includeMetaFields: boolean = false;

  constructor(
    protected workflowService: WorkflowsService,
    protected designerService: DesignerService,
    protected cubeService: CubesService,
    protected bionApi: ApiBackendService
  ) {
    super(workflowService, designerService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    // this.subs.sink = this.cubeService.dataStoresChangedEmitter.subscribe((datastore) => {
    //   this.getDataStores();
    //   //this.datastores.push(datastore);
    //   //this.onUpdateSettings(false);
    // });
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
  // onChangeUseOnlyLatestDelta(event: CheckBoxEvents.OnChange<any>) {
  //   //TODO: Attribute in BE einfügen
  //   let flagChanged = event.checked;
  //   this.onUpdateSettings(true);
  // }
  getDataStores() {
    this.subs.sink = this.cubeService.getDataStoreObjectList().subscribe((stores: DataStore[]) => {
      this.datastores = stores;
    });
  }
}
