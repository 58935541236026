import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CsvPushSettings } from 'src/app/models/api/com/bion/csv/CsvPushSettings';
import { CsvConnectorView } from 'src/app/models/connectors/csv-connector-view.model';
import { ConnectorSettingsBuilder } from 'src/app/models/connectorView.model';
import { CsvUtil, ParserCharacter } from 'src/app/models/csv-util';
import { DataSourceAdapter } from 'src/app/models/datasource.model';

@Component({
  selector: 'app-csv-connector',
  templateUrl: './csv-connector.component.html',
  styleUrls: ['./csv-connector.component.scss']
})
export class CsvConnectorComponent implements OnInit {
  selectedConnector: DataSourceAdapter;
  loading: boolean;
  parseCharaters:Array<ParserCharacter> = CsvUtil.getParserCharaters();
  selectedParserChar?:ParserCharacter;

  @Input() currentConnectorView: CsvConnectorView;
  @Input() isChangeSettingsOnlyFlag: boolean = false;
  @Output() onSettingsChangedEmitter = new EventEmitter<boolean>();

	onSettingsChanged() {
		this.onSettingsChangedEmitter.emit(true);
	}
  uploadFilesAsync(event) {
    let input = event.files;
    let firstFile = input[0];
    this.currentConnectorView.uploadFile(firstFile);
    //this.currentConnectorView.connectorSettings.QuoteCharacter;

    this.currentConnectorView.fetchMetaDataObs().subscribe(
      () => {

        this.cd.detectChanges();

        this.loading = false;
      }
    )


    this.currentConnectorView.getSettingsBuilder().subscribe(
      (builder:ConnectorSettingsBuilder<CsvPushSettings>) => {
        console.log(builder);
        this.currentConnectorView.connectorSettings = builder.getConnectorSettings();
      }
    );
  }
  onRemoveFile(event) {
    console.log(event);
    this.currentConnectorView.base64FileData = undefined;
  }

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit(): void {

  }
}