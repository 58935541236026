import { Component, OnInit } from "@angular/core";
import { forkJoin, throwError } from "rxjs";
import { DropDownEvents } from "src/app/helper/events";
import { Id } from "src/app/helper/id";
import { RoleMembershipRow } from "src/app/models/api/domain/NewDataSourceModelBase";
import { GroupRole } from "src/app/models/api/models/authorization/GroupRoleLike";
import { UserRole } from "src/app/models/api/models/authorization/UserRoleLIke";
import { UserInfo } from "src/app/models/api/models/session/UserInfo";
import { GroupRoleView, UserRoleView } from "src/app/models/auth-service-decorator.model";
// import {
// 	GroupRole,
// 	RoleMembership,
// 	UserRole,
// } from "src/app/models/authorization.model";
import { GeneralTypeInfo } from "src/app/models/types.model";
// import { UserInfo } from "src/app/models/user.model";
import { AuthorizationService } from "src/app/services/authorization.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { UserService } from "src/app/services/user.service";
import { SubSink } from "subsink";

export class GroupRightHolder { }

@Component({
	selector: "app-create-user-role-dialog",
	templateUrl: "./create-user-role-dialog.component.html",
	styleUrls: ["./create-user-role-dialog.component.scss"],
})
export class CreateUserRoleDialogComponent implements OnInit {
	subs = new SubSink();
	savingInProgress: boolean = false;
	displayNewUserRole: boolean = false;
	headerText: string = "";
	isGroupType: boolean = false;
	RightHolderTypes: GeneralTypeInfo<string>[] = [];
	selectedRoleType?: GeneralTypeInfo<string>;
	disabled: boolean = false;
	selectedUserRoleView?: UserRoleView;
	users: UserInfo[] = [];
	selectedUser?: UserInfo;

	groupRoles: GroupRoleView[] = [];
	selectedGroupRoles: GroupRole[] = [];

	selectedGroupRole?: GroupRoleView;

	roleName?: string;
	roleDescription?: string;
	views: string[] = [];
	selectedViews: string[] = [];

	constructor(
		private userService: UserService,
		private authService: AuthorizationService,
		private errorService: SystemMessageLogService
	) {
		this.views = ["Datasource", "Workflow", "Designer", "Scheduler", "Cube"];
	}

	ngOnInit(): void {
		this.subs.sink = this.userService.selectedMenuUserRoleEmitter.subscribe(
			(res: UserRoleView) => {
				this.selectedUserRoleView = res;
			},
			(err: Error) => {
				this.errorService.handleError(err);
			}
		);
		this.subs.sink = this.userService.selectedMenuGroupRoleEmitter.subscribe(
			(res: GroupRoleView) => {
				this.selectedGroupRole = res;
			},
			(err: Error) => {
				this.errorService.handleError(err);
			}
		);
		this.subs.sink = this.userService.displayNewRole.subscribe(
			(res: boolean) => {
				this.displayNewUserRole = res;
				this.headerText = "New Role";
				this.disabled = false;
				this.initGui("New Role");
			},
			(err: Error) => {
				this.errorService.handleError(err);
			}
		);
		this.subs.sink = this.userService.displayEditRole.subscribe(
			(res: boolean) => {
				this.displayNewUserRole = res;
				this.headerText = "Edit Role";
				this.disabled = true;
				this.initGui("Edit Role");
			},
			(err: Error) => {
				this.errorService.handleError(err);
			}
		);
	}

	initGui(event?: string) {
		let roleTypeObs = this.userService.getRightHolderTypes();
		let usersObs = this.userService.getUserCommon();
		let groupRolesObs = this.authService.getGroupRoleViews();

		this.subs.sink = forkJoin(roleTypeObs, usersObs, groupRolesObs).subscribe(
			(res) => {
				let roleTypes = res[0];
				let users = res[1];

				this.RightHolderTypes = roleTypes;
				this.users = users;
				this.groupRoles = res[2];

				//Checks
				if (event === "New Role") {
					this.isGroupType = true;
					this.selectedRoleType = roleTypes[0];
				}
				if (event === "Edit Role") {
					if (this.selectedUserRoleView) {
						this.selectedRoleType = roleTypes[1];
						this.isGroupType = false;
						this.selectedUser = this.selectedUserRoleView.userInfo;
						this.roleDescription =
							this.selectedUserRoleView.RightHolder.Description;
					}
					if (this.selectedGroupRole) {
						this.selectedRoleType = roleTypes[0];
						this.isGroupType = true;
						this.roleName = this.selectedGroupRole.label;
						this.roleDescription = this.selectedGroupRole.RightHolder.Description;
						//TODO: set permitted views
					}
				}
			}
		);
	}

	resetCurrentForm() {
		this.selectedRoleType = undefined;
		this.roleName = "";
		this.roleDescription = "";
		this.selectedViews = [];
	}
	setRoleType(event: DropDownEvents.OnChange<any,GeneralTypeInfo<string>>) {
		let role = event.value;
		if (role.Name === "User Role") {
			this.isGroupType = false;
			this.selectedUser = this.users[0];
		}
		if (role.Name === "Group Role") {
			this.isGroupType = true;
		}
	}

	validateForm() {
		this.savingInProgress = true;
		if (this.headerText === "New Role") {
			if (this.isGroupType) {
				this.createGroupRole();
			} else {
				this.createUserRole();
			}
		}
		if (this.headerText === "Edit Role") {
			if (this.isGroupType) {
				this.updateGroupRole();
			} else {
				this.updateUserRole();
			}
		}
	}

	createUserRole() {


		const obs = this.selectedUser?.ID ?
			this.authService.createUserRole(new UserRole(-1, this.selectedUser.ID, true, this.roleDescription))
			: throwError(new Error("No User Selected"));


		this.subs.sink = obs.subscribe(
			(userrole: UserRole) => {
				// if user role was successfully created, register UserMembership(s)

				let selectedGroupRoles = this.selectedGroupRoles;

				let membershipObs = selectedGroupRoles.map((grouprole: GroupRole) => {
					let membership = new RoleMembershipRow(userrole.ID, grouprole.ID);

					return this.authService.createRoleMembership(membership);
				});
				forkJoin(membershipObs).subscribe((membershipResults) => {
					console.log("membershipResults", membershipResults);
				});

				this.authService.userRolesChangedEmitter.emit(userrole);
				this.displayNewUserRole = false;
			},
			(err: Error) => {
				this.errorService.handleError(err);
			}, () => {
				this.savingInProgress = false;
			}
		);
	}
	updateUserRole() {

		const userRole = this.selectedUserRoleView?.RightHolder;

		if (userRole) {
			if (this.selectedUser) {
				userRole.Description = this.roleDescription;
				userRole.UserID = this.selectedUser.ID;
			} else {
				throw new Error("No User selected");
			}
		} else {
			throw new Error("No User Role selected");
		}

		this.subs.sink = this.authService.updateUserRole(userRole).subscribe(
			(updated_users) => {

				this.authService.userRolesChangedEmitter.emit(userRole);
				this.displayNewUserRole = false;
			},
			(err: Error) => {

				this.errorService.handleError(err);
			}, () => {
				this.savingInProgress = false;
			}
		);
	}

	createGroupRole() {

		let safe_role_name: string = "";
		if (this.roleName)
			safe_role_name = this.roleName;
		else
			throw new Error("No Role Name selected!");

		const new_group_role = new GroupRole(-1, this.roleName, true, this.roleDescription);


		//TODO: API REQUEST
		this.subs.sink = this.authService.createGroupRole(new_group_role).subscribe(
			(res: GroupRole) => {
				this.authService.groupRolesChangedEmitter.emit(res);
				this.displayNewUserRole = false;
			},
			(err: Error) => {
				this.errorService.handleError(err);
			}, () => {
				this.savingInProgress = false;
			}
		);
	}
	updateGroupRole() {
		let groupRoleView = { ...this.selectedGroupRole };

		try {
			const right_holder = Id.assertSet(groupRoleView.RightHolder, new Error("The group role must be set."));
			const role_name = Id.assertSet(this.roleName, new Error("The role name must be set."));

			let groupRole = new GroupRole(right_holder.ID, role_name, right_holder.IsActive, this.roleDescription);

			this.subs.sink = this.authService.updateGroupRole(groupRole).subscribe(
				(res) => {
					this.authService.groupRolesChangedEmitter.emit(groupRole);
					this.displayNewUserRole = false;
				},
				(err: Error) => {
					this.errorService.handleError(err);
				}, () => {
					this.savingInProgress = false;
				}
			);
		} catch (e) {
			this.errorService.handleError(<Error>e);
			this.savingInProgress = false;
		}

	}
}
