import { Workflow } from "../../com/bion/etl/Workflow";
import { WorkflowRunInfo } from "./WorkflowRunInfo";

export class WorkflowRepositoryEntry {
    id: number;
    name: string;
    description?: string;
    createdBy: string;
    lastChange: string;
    context: string;
    workflowData: Workflow;
    runInfo?: WorkflowRunInfo;
    constructor(id: number,name: string,createdBy: string,lastChange: string,context: string,workflowData: Workflow,description?: string,runInfo?: WorkflowRunInfo) {
        if(id === undefined) throw new Error( "Class 'WorkflowRepositoryEntry': Required value 'id' is undefined" );
        if(name === undefined) throw new Error( "Class 'WorkflowRepositoryEntry': Required value 'name' is undefined" );
        if(createdBy === undefined) throw new Error( "Class 'WorkflowRepositoryEntry': Required value 'createdBy' is undefined" );
        if(lastChange === undefined) throw new Error( "Class 'WorkflowRepositoryEntry': Required value 'lastChange' is undefined" );
        if(context === undefined) throw new Error( "Class 'WorkflowRepositoryEntry': Required value 'context' is undefined" );
        if(workflowData === undefined) throw new Error( "Class 'WorkflowRepositoryEntry': Required value 'workflowData' is undefined" );
        this.id = id;
        this.name = name;
        this.description = description;
        this.createdBy = createdBy;
        this.lastChange = lastChange;
        this.context = context;
        this.workflowData = workflowData;
        this.runInfo = runInfo;
    }
}

