
<p-dialog header="{{'Delete' | translate}}" [(visible)]="displayDeleteUserRole" modal="modal" showEffect="fade"  (onHide)="resetCurrentForm()"
    [style]="{width: '600px'}">

    <div style="line-height: 1.5">
        {{'DeleteRoleText' | translate}}
    </div>
    <div *ngIf="selectedRole">
        <span *ngIf="selectedUserInfo">
            {{'DeleteUserRoleText' | translate}}<b>{{selectedUserInfo.Username}}</b>?
        </span>
        <span *ngIf="selectedRole.Name">
            {{'DeleteGroupRoleText' | translate}}<b>{{selectedRole.Name}}</b>?
        </span>
    </div>
    <div>
        <app-system-message-log></app-system-message-log>
    </div>
    <p-footer>
        <!-- <button type="button" pButton icon="pi pi-check" (click)="deleteRole()"
            label="Yes" class="p-button-text"></button>
        <button type="button" pButton icon="pi pi-times" (click)="displayDeleteUserRole=false" label="No"
            class="p-button-text"></button> -->
            <button pButton pRipple type="button" icon="pi pi-times" (click)="displayDeleteUserRole=false"
            class="p-button-rounded p-button-text  p-button-outlined p-mr-2 p-mb-2" label="{{'No' | translate}}"></button>
        <button pButton pRipple type="button" icon="pi pi-check" (click)="deleteRole()"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2" label="{{'Yes' | translate}}"
            [icon]="savingInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'"></button>
    </p-footer>
</p-dialog>
