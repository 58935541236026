import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, Message, MessageService } from 'primeng/api';
import { Observable, interval, of } from 'rxjs';
import { concatMap, map, mergeMap, startWith, switchMap } from 'rxjs/operators';
import { IntegratedSourceController } from 'src/app/models/api/controllers/IntegratedSourceController';
import { IntegratedSourceModel } from 'src/app/models/api/models/IntegratedSourceModel';
import { IntegratedSourceService } from 'src/app/services/integrated-source.service';
import { TasksService } from 'src/app/services/tasks.service';
import { SubSink } from 'subsink';
import { HeaderRow } from 'src/app/views/objectManagement/general-object-view/record-provider';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatasourcesService } from 'src/app/services/datasources.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilFunctionsService } from 'src/app/services/util-functions.service';
import { UserService } from 'src/app/services/user.service';
import { UserDetailsRow } from 'src/app/models/user.model';
import { ConnectorInfo } from 'src/app/models/connector.model';


interface PermissionInfo {
  permsCount: number;
  images?: SafeUrl[];

}
type DataSourceView = IntegratedSourceModel.DataSource<number> & PermissionInfo

/**
 * Diese Komponente rendert eine Liste integirerter Sources.
 *
 * Features:
 * - Source Name und Herkunft
 *
 * Aktionen:
 * - Streams anschauen
 * - Source löschen
 */
@Component({
  selector: 'app-view-sources-int',
  templateUrl: './view-sources-int.component.html',
  styleUrls: ['./view-sources-int.component.scss'],
  animations: [trigger("fade", [
    state("void", style({ opacity: 0 })),
    transition(":enter", [animate(500)]),
    transition(":leave", [animate(500)]),
  ]),],
  providers: [MessageService]
})
export class ViewSourcesIntComponent implements OnInit {

  isLoading: boolean = false;
  buttonDisabled: boolean = false;
  progressMode: string = "indeterminate";
  //infos: IntegratedSourceModel.DataSource<number>[] = [];
  infos: DataSourceView[] = [];
  userDetailsMap: Map<number, SafeUrl> = new Map<number, SafeUrl>();
  connectorInfos: IntegratedSourceController.ConnectorInfo[] = [];
  messages: Message[] = [];
  selectedInfo?: IntegratedSourceModel.DataSource<number>;
  // file upload dialog
  show_file_dialog: boolean = false;
  file_dialog_source?: IntegratedSourceModel.DataSource<number> = undefined;
  // Progress monitoring
  subs = new SubSink;

  cols: HeaderRow[] = [
    { field: 'PrimaryConnection.IsSyncing', header: '', width: "25px" },
    { field: 'Name', header: 'Name', width: "60%" },
    { field: 'ConnectorInfo.Name', header: 'Connector', width: "15%" },
    { field: 'AdditionInfo.RolePermissions', header: 'SharedWith', width: "10%" },
    { field: 'PrimaryConnection.CreatedAt', header: 'LastSync', width: "15%" },

  ];
  items: MenuItem[] = [{ icon: 'pi pi-table', label: "Datenquellen", routerLink: '/SourceIntegrationSources' }];

  constructor(private service_api: IntegratedSourceService, private router: Router, private sanitizer: DomSanitizer, private messageService: MessageService, private translate: TranslateService, private utilService: UtilFunctionsService, private userService: UserService) { }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {

    this.isLoading = true;

    const sources_ob = this.service_api.getDataSource(true, true);
    const conns_ob = this.service_api.getConnectors();
    const userDetails_obs = this.userService.getUserDetailsRow();

    //const all_ob = sources_ob.pipe(mergeMap(sources => conns_ob.pipe(map(connectors => [sources, connectors]))))
    const all_ob = sources_ob.pipe(mergeMap(sources => conns_ob.pipe(mergeMap(connectors => userDetails_obs.pipe(map((userDetails) => [sources, connectors, userDetails]))))))

    console.warn("Connector Attribut 'ConnectorInfo' bitte analog so anpassen wie Data Source View via intersection type.");

    const initObs = all_ob.pipe(map(pair => {
      const datasources = <IntegratedSourceModel.DataSource<number>[]>pair[0];
      const connectors = <IntegratedSourceController.ConnectorInfo[]>pair[1];
      const userDetails = <UserDetailsRow[]>pair[2];
      let userDetailsMap = new Map<number, SafeUrl>();
      userDetails.forEach((detail) => {
        if (detail.avatar) {
          const safeUrl = this.utilService.int8ArrayToBase64Image(detail.avatar);
          userDetailsMap.set(detail.id, safeUrl)
        }
      })
      this.userDetailsMap = userDetailsMap;
      this.infos = datasources.map((ds) => ViewSourcesIntComponent.IntDsToView(ds, userDetailsMap));
      this.connectorInfos = connectors;
      this.isLoading = false;

      for (let info of this.infos) {
        const conn = this.connectorInfos.find(c => info.Connector.Id == c.Key.Id && info.Connector.Origin == c.Key.Origin);
        info["ConnectorInfo"] = conn;
      }

      const tuple: [IntegratedSourceController.ConnectorInfo[], Map<number,SafeUrl>]= [connectors, userDetailsMap]
      return tuple

    }));

    const finalObs = initObs.pipe(concatMap( a => {
        return this.startPollingObs(a[0],a[1]).pipe(map( res => {
            const t: [IntegratedSourceModel.DataSource<number>[],IntegratedSourceController.ConnectorInfo[], Map<number,SafeUrl>] = [res, a[0], a[1]];
            return t
        }))
    }));

    this.subs.sink = finalObs.subscribe((b) => {
        this.infos = ViewSourcesIntComponent.enrichDataSources(b[0], b[1] , b[2])
        console.log(this.infos);
    },(err:Error) => {
        this.handle_error(err);
    })

    //this.startPollingDS(connectors, userDetailsMap);

  }

  startPollingObs(connectors: IntegratedSourceController.ConnectorInfo[],userDetailsMap: Map<number,SafeUrl>) {
    const dsObs = this.service_api.getDataSource(true,true)

    return interval(5000)
      .pipe(
        startWith(0),
        switchMap(() => dsObs)
      )
  }

  /**
   * Startet das Polling der Datenquellen, um aktuellen Sync Status anzuzeigen
   * Beim Pollen müssen wir die Zusatzinformationen nicht erneut holen.
   */
  startPollingDS(connectors: IntegratedSourceController.ConnectorInfo[],userDetailsMap: Map<number,SafeUrl>) {
    //const dsObs = ViewSourcesIntComponent.loadDataSourcesObs(this.service_api, true, true);
    const dsObs = this.service_api.getDataSource(true,true)

    this.subs.sink = interval(5000)
      .pipe(
        startWith(0),
        switchMap(() => dsObs)
      )
      .subscribe(
        (result) => {
        //   const datasources = <IntegratedSourceModel.DataSource<number>[]>result[0];
        //   const connectors = <IntegratedSourceController.ConnectorInfo[]>result[1];
        //   this.infos = datasources.map((ds) => this.IntDsToView(ds, this.userDetailsMap));
        //   console.log("Polling datasources",this.infos, connectors);
        //   //this.infos = datasources;
        //   this.connectorInfos = connectors;
        //   for (let info of this.infos) {
        //     const conn = this.connectorInfos.find(c => info.Connector.Id == c.Key.Id && info.Connector.Origin == c.Key.Origin);
        //     info["ConnectorInfo"] = conn;
        //   }

        this.infos = ViewSourcesIntComponent.enrichDataSources(result, connectors , userDetailsMap)

        },
        (err: Error) => {
          this.handle_error(err);
        }
      );
  }

  static enrichDataSources(datasources:IntegratedSourceModel.DataSource<number>[], connectors: IntegratedSourceController.ConnectorInfo[], userDetailsMap: Map<number, SafeUrl>) {

    const infos = datasources.map((ds) => this.IntDsToView(ds, userDetailsMap));

    for (let info of infos) {
      const conn = connectors.find(c => info.Connector.Id == c.Key.Id && info.Connector.Origin == c.Key.Origin);
      info["ConnectorInfo"] = conn;
    }

    return infos
  }


  // Observable<(IntegratedSourceModel.DataSource<number>[] | IntegratedSourceController.ConnectorInfo[])[]>


static getDataSourceDummy(): Observable<IntegratedSourceModel.DataSource<number>[]>  {
    return of([])
}
static getConnectorsDummy():Observable<IntegratedSourceController.ConnectorInfo[]> {
    return of([])
}

  /**
   * The returned observable fetches all required information for the datasources and adds their connector as extra field.
   * @param service_api API Service
   * @param fetchPrimaryConnection Dies holt die Sync-Informationen und sollte daher auf true gesetzt sein.
   * @param fetchAdditionalInfo Holt Zusatzinformationen (Permissions, etc.). Solle nur in der Detailansicht oder initial, aber nicht beim Pollen auf true sein, da sich diese Infos
   * nicht kurzfristig ändern.
   */
  static loadDataSourcesObs(service_api: IntegratedSourceService, fetchPrimaryConnection?: boolean, fetchAdditionalInfo?: boolean): Observable<[IntegratedSourceModel.DataSource<number>[], IntegratedSourceController.ConnectorInfo[]]> {

    const sources_ob = service_api.getDataSource(fetchPrimaryConnection, fetchAdditionalInfo);
    const conns_ob = service_api.getConnectors();

    //const sources_ob = ViewSourcesIntComponent.getDataSourceDummy();
    //const conns_ob = ViewSourcesIntComponent.getConnectorsDummy();

    const all_ob = sources_ob.pipe(mergeMap(sources => conns_ob.pipe(map(connectors => [sources, connectors]))))
    //const all_ob = sources_ob.pipe(concatMap(sources => conns_ob.pipe(map(connectors => [sources, connectors]))))

    return all_ob.pipe(map(pair => {

      const datasources = <IntegratedSourceModel.DataSource<number>[]>pair[0];
      const connectors = <IntegratedSourceController.ConnectorInfo[]>pair[1];

      console.log("Load datasources before",datasources, connectors);

      for (let info of datasources) {
        const conn = connectors.find(c => info.Connector.Id == c.Key.Id && info.Connector.Origin == c.Key.Origin);
        console.log("Datasource Connector, must find one!",conn);
        info["ConnectorInfo"] = conn;
      }

      console.log("Load datasources after",datasources, connectors);


      return [datasources, connectors]
    }))
  }

  on_sync_source(ds: IntegratedSourceModel.DataSource<number>) {
    console.log("On Sync Source");

    const sync_arg = new IntegratedSourceModel.SyncArg(ds.Id, undefined);
    this.subs.sink = this.service_api.sync(sync_arg).subscribe(result => {
      console.log("Sync Started with Job", result);
      console.log("TODO: keep the job infos somewhere so we see which data sources are synced right now");
    },
      err => this.handle_error(err),
      () => this.setLoading(false)
    )
  }

  /**
   * Click auf File Upload.
   * Jetzt wird der Dialog angezeigt, wo der Nutzer die Datei auswählen kann.
   * @param ds
   */
  on_sync_file_source(ds: IntegratedSourceModel.DataSource<number>) {
    // push source key to component and display
    console.log("On Sync File Source");

    console.log("Show Dialog Flag", this.show_file_dialog);

    this.file_dialog_source = ds;
    this.show_file_dialog = true;
  }

  on_delete_source(ds: IntegratedSourceModel.DataSource<number>) {
    console.log("On Delete Source");

    // const dlg = confirm("Do you want to delete this data source?");
    // if (dlg) {
    const del_arg = new IntegratedSourceModel.DeleteDataSourceArg(ds.Id);

    const refresh_ob = ViewSourcesIntComponent.loadDataSourcesObs(this.service_api, true, true);
    const all_obs = this.service_api.deleteDataSource(del_arg)
      .pipe(concatMap(count => refresh_ob
        .pipe(map(pair => {
          const result: [number, [IntegratedSourceModel.DataSource<number>[], IntegratedSourceController.ConnectorInfo[]]] = [count, pair];
          return result;
        }))))

    this.setLoading(true);

    this.subs.sink = all_obs.subscribe(pairs => {
      const pair = pairs[1];
      const result = pairs[0];

      console.log("Delete Data Source successful", result);
      this.infos = pair[0].map((ds) => ViewSourcesIntComponent.IntDsToView(ds, this.userDetailsMap));
      //this.infos = pair[0];
      this.connectorInfos = pair[1];

      this.messageService.add({
        severity: "success",
        summary: this.translate.instant("Message.DeleteDataSourceSuccess.Title"),
        detail: this.translate.instant("Message.DeleteDataSourceSuccess.Text1") + del_arg.SourceKey.Id +
          this.translate.instant("Message.DeleteDataSourceSuccess.Text2"),
      });
    },
      err => this.handle_error(err),
      () => {
        this.setLoading(false);
        this.displayDeleteDatasource = false;
      }
    )
  }

  displayDeleteDatasource: boolean = false;
  on_display_delete_dialog() {
    this.displayDeleteDatasource = true;
  }

  handle_error(e: Error) {
    this.messages.push({ severity: 'error', summary: 'Error', detail: e.message });
    console.log("Error Handler", e);
    this.setLoading(false);
  }

  handle_monitor_error(e: Error) {
    console.error(e);
  }

  setLoading(loading: boolean): void {
    this.isLoading = loading;

    // https://www.primefaces.org/primeng-v14-lts/progressbar

    if (this.isLoading) {
      this.progressMode = "indeterminate";
    } else {
      this.progressMode = "determinate";
    }
  }

  /**
   * Callback, navigates to Create Data Source Wizard.
   */
  onCreateSource() {
    //this.router.navigate(['/', '/SourceIntegrationChooseConnector']);
    this.router.navigateByUrl('/' + 'SourceIntegrationChooseConnector');

  }
  onViewSource(ds: IntegratedSourceModel.DataSource<number>) {
    this.router.navigateByUrl('/SourceIntegrationSource/' + ds.Id.Origin + "/" + ds.Id.Id);
  }


  onSelectInfo(event: any) {
    console.log(event);
    this.selectedInfo = <IntegratedSourceModel.DataSource<number>>event.data;
  }
  onUnselectInfo(event: any) {
    console.log(event);
    this.selectedInfo = undefined;
  }
  getSafeHtml(icon?: string): SafeHtml {
    console.warn("Es muss ein Alternativbild angegeben werden sonst könnte ein undefined SafeHtml zurückgegeben werden.");
    if (icon) {
      const fixedWidth = 100;
      const modifiedSvgString = icon.replace(/<svg[^>]*width\s*=\s*["']?\d+["']?/i, `<svg width="25px"`);
      const finalSvgString = modifiedSvgString.replace(/<svg[^>]*height\s*=\s*["']?\d+["']?/i, `<svg height="25px" viewBox="0 0 25 25"`);
      return this.sanitizer.bypassSecurityTrustHtml(finalSvgString);
    } else {
      return this.sanitizer.bypassSecurityTrustHtml("<div>C</div>");
    }
  }

  /**
   * Turns an IntDatasource into enriched View Class
   * @param ds IntDataSource
   * @returns DataSourceView
   */
  static IntDsToView(ds: IntegratedSourceModel.DataSource<number>, userAvatars: Map<number, SafeUrl>): DataSourceView {

    const perm_disp_limit = 3;
    const perm_sort_pre = ds.AdditionalInfo?.RolePermissions?.slice(0, 2);
    const permSorted_cut = perm_sort_pre ? perm_sort_pre : [];
    const user_icon_url= "assets/layout/images/avatar-placeholder.png";
    const group_icon_url = "assets/layout/images/avatar-placeholder.png";

    const perm_images_u = permSorted_cut.map(perm => {
      if (perm.IsGroup === true) {
        return group_icon_url
        //return undefined // url für ein gruppenicon
      } else {
        const u_id = perm.User?.ID;
        if (u_id) {
            const avatar = userAvatars.get(u_id);
            if(avatar) {
                return avatar
            } else {
                return user_icon_url
            }
        }
        else
          return user_icon_url
          //return undefined; // url für ein usericon
      }
    })



    let others_count: number = 0;

    const rp_len = ds.AdditionalInfo?.RolePermissions?.length;

    if (rp_len) {
      if (rp_len > perm_disp_limit) {
        others_count = 0;
      } else {
        others_count = rp_len - permSorted_cut.length;
      }
    } else {
      others_count = 0;
    }

    let perm_images: SafeUrl[] = [];

    perm_images_u.forEach(v => {
      if (v) {
        perm_images.push(v);
      }
    })

    let permInfo: PermissionInfo = { permsCount: others_count, images: perm_images };
    const result: DataSourceView = { ...ds, ...permInfo };

    return result
  }



  // getUserIcon(perm) {
  //   const perm_disp_limit = 3;
  //   const permSorted_cut = perm.slice(0, 2);
  //   const usersObs = this.userService.getUserDetailsRow();

  //   let others_count: number = 0;

  //   if (perm.length > perm_disp_limit) {
  //     others_count = 0;

  //   } else {
  //     others_count = perm.length - permSorted_cut.length;
  //   }

  //   this.subs.sink = usersObs.subscribe((users) => {

  //     const shared = permSorted_cut.map((p) => {

  //       const userdetailinfos = users.find((detail) => { return p.Role == detail.id });

  //       if (userdetailinfos && userdetailinfos.avatar) {
  //         const image = this.utilService.int8ArrayToBase64Image(userdetailinfos.avatar)
  //         return image;
  //       } else {
  //         return undefined;
  //       }
  //     });

  //     const otherCount = others_count > 0 ? "+" + others_count.toString() : undefined;

  //     return [shared, otherCount]
  //   })



  // }


}
