import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Id } from 'src/app/helper/id';
import { BasicFrequency } from 'src/app/models/api/models/scheduler/CronFrequency';
import { BaseFrequencyActionEvent, BaseFrequencyDialogActionType } from 'src/app/models/dialog-actions.model';
import { SchedulesService } from 'src/app/services/schedules.service';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-delete-frequency-dialog',
  templateUrl: './delete-frequency-dialog.component.html',
  styleUrls: ['./delete-frequency-dialog.component.scss'],
  providers: [MessageService]
})
export class DeleteFrequencyDialogComponent implements OnInit,OnDestroy {
    savingInProgress: boolean = false;
    subs = new SubSink();
    displayDeleteFrequency: boolean = false;
    selectedFrequency?: BasicFrequency;

  constructor(private schedulesService:SchedulesService, private translate: TranslateService, private systemLogService: SystemMessageLogService,	private messageService: MessageService) { }

  ngOnInit(): void {
    this.subs.sink = this.schedulesService.baseFrequencyDialogActionSendEmitter.subscribe((event: BaseFrequencyActionEvent) => {
      if(event.actionType === BaseFrequencyDialogActionType.deleteFrequency) {
        if(!event.frequency) return;

        this.displayDeleteFrequency = event.display;
        this.selectedFrequency = event.frequency;
      }
    }, (err:Error) => {
      this.systemLogService.handleError(err);
    });

    // this.subs.sink = this.schedulesService.displayDeleteFrequency.subscribe((res:boolean) => {
    //     this.displayDeleteFrequency = res;
    // }, (error: Error) => {
    //   this.systemLogService.handleError(error);
    // });
    // this.subs.sink = this.schedulesService.selectedMenuFrequencyEmitter.subscribe((sc:BasicFrequency) => {
    //     console.log('Current Menu Selected Frequency:',sc);
    //     this.selectedFrequency = sc;
    // }, (error: Error) => {
    //   this.systemLogService.handleError(error);
    // });
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onDeleteFrequency(freq?:BasicFrequency) {
    try {
      this.deleteFrequency(freq)
    } catch(e) {

    }
  }

  deleteFrequency(freq?: BasicFrequency) {
    const selected_freq = Id.assertSet(freq, new Error("No Frequency selected"))

      this.savingInProgress = true;

      this.schedulesService.deleteScheduleFrequency(selected_freq.ID).subscribe((res)=> {
        this.schedulesService.frequenciesChangedEmitter.emit("Delete Frequency");

        this.messageService.add({
					severity: "success",
          summary: this.translate.instant("Message.DeleteFrequencySuccess.Title"),
          detail: this.translate.instant("Message.DeleteFrequencySuccess.Text1") + selected_freq.ID +
          this.translate.instant("Message.DeleteFrequencySuccess.Text2"),
				});
        this.displayDeleteFrequency = false;
      }, (err:Error) => {
          this.systemLogService.handleError(err);
      }, () => {
        this.savingInProgress = false
      });



  }

}
