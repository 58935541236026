import { DataStoreAccessible } from "./DataStoreAccessible";

export class DataStorePermission {
    ID: number;
    Role: number;
    IsOwner: boolean;
    Accessible: DataStoreAccessible;
    Rights: string[];
    constructor(ID: number,Role: number,IsOwner: boolean,Accessible: DataStoreAccessible,Rights: string[]) {
        if(ID === undefined) throw new Error( "Class 'DataStorePermission': Required value 'ID' is undefined" );
        if(Role === undefined) throw new Error( "Class 'DataStorePermission': Required value 'Role' is undefined" );
        if(IsOwner === undefined) throw new Error( "Class 'DataStorePermission': Required value 'IsOwner' is undefined" );
        if(Accessible === undefined) throw new Error( "Class 'DataStorePermission': Required value 'Accessible' is undefined" );
        if(Rights === undefined) throw new Error( "Class 'DataStorePermission': Required value 'Rights' is undefined" );
        this.ID = ID;
        this.Role = Role;
        this.IsOwner = IsOwner;
        this.Accessible = Accessible;
        this.Rights = Rights;
    }
}