

            <p-chart type="bar" [data]="barData" [options]="barOptions"
            *ngIf="barData.datasets[0]['data'] !== undefined" [responsive]="true" height="120px">
        </p-chart>
        <div style="height: 160px" *ngIf="barData.datasets[0]['data'] === undefined">
            <app-no-data-available [view]="'chart'" [url]="'/chart'"></app-no-data-available>
        </div>

<!-- <div>
    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-xl-12 device-status">
            <div class="card-header">
                <div class="card-title">
                    <h6>{{'Allocation' | translate}}</h6>
                </div>
            </div>
            <p-chart type="bar" [data]="barData" [options]="barOptions"
                *ngIf="barData.datasets[0]['data'] !== undefined" [responsive]="true" height="160px">
            </p-chart>
            <div style="height: 160px" *ngIf="barData.datasets[0]['data'] === undefined">
                <app-no-data-available [view]="'chart'" [url]="'/chart'"></app-no-data-available>
            </div>

        </div>


        <div class="p-col-12 p-md-12 p-xl-12  device-status">
            <div class="card-header">
                <div class="card-title">
                    <h6>{{'Nodes' | translate}}</h6>
                </div>
            </div>
            <div class="progress" *ngFor="let type of nodesCountFilteredList">
                <span style="width: 90%;">
                    <img [alt]="type.name" [src]="'assets/layout/images/nodes/' + type.name  + '.png'" width="20"
                        style="vertical-align: middle" />
                    {{type.name}}</span>
                <p-progressBar [value]="type.progress" [showValue]="true"></p-progressBar>
                <span>{{type.count}}</span>
            </div>
            <div style="height: 150px"
                *ngIf="nodesCountFilteredList.length === 0 || nodesCountFilteredList === undefined">
                <app-no-data-available [view]="'chart'" [url]="'/chart'"></app-no-data-available>
            </div>
        </div>

    </div>
</div> -->
