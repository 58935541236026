

<div class="p-col-12 desktop-teams">
    <div class="team">
        <div class="card-header">
            <div class="card-title">
                <h6>Team</h6>
                <a class="subtitle">{{permissions.length}} Members</a>
            </div>
        </div>
        <div class="peoples">
            <span *ngFor="let avatar of permissions">
                <img *ngIf="avatar.IsOwner === true" src="assets/layout/images/{{avatar.RightHolder.Name}}.png" style="width: 28px" alt="freya-layout" />
                <!-- <img src="assets/demo/images/dashboard/avatar/avatar-1.png" alt="freya-layout" />
                <img src="assets/demo/images/dashboard/avatar/avatar-2.png" alt="freya-layout" /> -->
                <!-- <div class="no-picture" style="background: #BAE6FF;"><span>AT</span></div> -->

            </span>
            <!-- <div class="no-picture" *ngIf="permissionCoutWithoutOwners !== 0"><span>+{{permissionCoutWithoutOwners}}</span></div> -->

            <button pButton pRipple type="button" icon="pi pi-user-edit" pTooltip="Edit team"
                (click)="displayPermissionDialog()"
                class="p-button-rounded p-button p-button-text p-mr-2 p-mb-2"></button>
        </div>
    </div>
</div>
