import { Component, OnInit } from '@angular/core';
import { SplitColumnPlugIn } from 'src/app/models/api/com/bion/etl/plugins/SplitColumnPlugIn';
import { FieldInfo, WorkflowNodeSettings } from 'src/app/models/api/com/bion/etl/Workflow';
import { GuiDropDown } from 'src/app/models/helperClass.model';
import { DesignerService } from 'src/app/services/designer.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { NodeConfigComponentBase } from '../node-config-component-base';

export class SplitColumnInfoView {
  ColumnName: string;
  SplitCondition: string;
  SplitMethod: GuiDropDown;
  DropColumn: boolean;
  MaxColumns: number;
  hasError?: boolean;

  constructor(ColumnName: string,
    SplitCondition: string,
    SplitMethod: GuiDropDown,
    DropColumn: boolean,
    MaxColumns: number,
    hasError?: boolean) {
    this.ColumnName = ColumnName;
    this.SplitCondition = SplitCondition;
    this.SplitMethod = SplitMethod;
    this.DropColumn = DropColumn;
    this.MaxColumns = MaxColumns;
    this.hasError = hasError;
  }
}

@Component({
  selector: 'app-spark-split-column-node',
  templateUrl: './spark-split-column-node.component.html',
  styleUrls: ['./spark-split-column-node.component.scss']
})
export class SparkSplitColumnNodeComponent
  extends NodeConfigComponentBase<SplitColumnPlugIn.Settings>
  implements OnInit {

  settingsToView(settings: WorkflowNodeSettings): void {
    // -- Get Configuration
    let currentConfig = <SplitColumnPlugIn.Settings>settings;
    // -- Get Input Meta Data
    const meta_infos = this.getCurrentWorkflowNode().Properties.MetaInfo.get(this.InputPort);

    let columnListArray: string[] = [];

    // create SelectInfoViews from DsFields for table
    if (meta_infos != undefined && meta_infos.length > 0) {
      const input_field_infos = meta_infos[0].FieldsInfo;
      // -- create string array for dropdown
      columnListArray = input_field_infos.map((fieldInfo) => {
        return fieldInfo.Name;
      });
      this.columnList = columnListArray;
    }

    // --If currentConfig not available (-> new node)
    if (currentConfig == undefined) {
      this.columnSplitterList = [];
    } else {
      // -- otherweise load existing config into view
      console.log("COLUMN SPLITTER: Load View from Config...", currentConfig);

      currentConfig.Infos.map((info) => {
        if (columnListArray.includes(info.SelectedColumn)) {
          // -- column still exists, do nothing
        } else {
          // column does not exist anymore, meaning column name currently not included -> include
          columnListArray.push(info.SelectedColumn);
        }
      });
      this.columnList = columnListArray;

      if(meta_infos) {
        const first = meta_infos.length > 0 ? meta_infos[0] : undefined;
        if(first) {
          this.columnSplitterList = this.infoToView(currentConfig.Infos, first.FieldsInfo);
        }
      }

      // if (meta_infos[0] !== undefined) {
      //   this.columnSplitterList = this.infoToView(currentConfig.Infos, meta_infos[0].FieldsInfo);
      // }
    }
  }
  viewToSettings(): SplitColumnPlugIn.Settings {
    const newSettings = <SplitColumnPlugIn.Settings>{
      ...this.getCurrentWorkflowNode().Properties.Configuration,
    };
    const sortInfoViews = this.columnSplitterList;
    const columnSplitterInfos = this.ViewToInfo(sortInfoViews);

    newSettings.Infos = columnSplitterInfos;

    return newSettings;
  }
  onSettingsChanged(settings: SplitColumnPlugIn.Settings): void {
    throw new Error("Method not implemented.");
  }

  readonly InputPort = "Input";
  columnList: string[] = [];
  splitMethodOptions: GuiDropDown[];
  columnSplitterList: SplitColumnInfoView[] = [];

  constructor(
    protected workflowService: WorkflowsService,
    protected designerEventService: DesignerService
  ) {
    super(workflowService, designerEventService);
    this.splitMethodOptions = [
      { name: "Split to column", value: "SplitToColumn" },
      { name: "Split to row", value: "SplitToRow" },
    ];
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onAddRow() {
    // let newFilter = new SplitColumnInfoView();
    // newFilter.ColumnName = "";
    // newFilter.SplitMethod = this.splitMethodOptions[0];
    // newFilter.SplitCondition = "";
    // newFilter.DropColumn = true;
    // newFilter.MaxColumns = 5;
    // newFilter.hasError = false;

    const newFilter = new SplitColumnInfoView("", "", this.splitMethodOptions[0], true, 5, false);

    this.columnSplitterList.push(newFilter);

    this.ViewToInfo(this.columnSplitterList);
    this.onUpdateSettings(false);
  }
  onDeleteRow(index: number) {
    this.columnSplitterList = [
      ...this.columnSplitterList.slice(0, index),
      ...this.columnSplitterList.slice(index + 1),
    ];
    this.onUpdateSettings(false);
    // this.messageService.standardToastMessage({  severity : 'warning',
    //                                                 summary : 'Row deleted',
    //                                                 detail : 'The selected row was deleted successfully!'});
  }

  infoToView(list: SplitColumnPlugIn.SplitColumnInfo[], meta?: FieldInfo[]): SplitColumnInfoView[] {

    let newInfosView: SplitColumnInfoView[] = [];

    list.map((entry: SplitColumnPlugIn.SplitColumnInfo) => {
      // let newInfoView = new SplitColumnInfoView();

      // // -- Get respective fieldInfo class from config
      // newInfoView.ColumnName = entry.SelectedColumn;

      const fieldInfoFound = meta?.find(field => field.Name === entry.SelectedColumn);
      const has_error = !fieldInfoFound;
      // newInfoView.hasError = has_error;

      // // -- get split condition
      // newInfoView.SplitCondition = entry.SplitPattern;

      // // -- get DropColumn Booolean
      // newInfoView.DropColumn = entry.DropOldColumn;
      // newInfoView.MaxColumns = entry.NewColumnCount;

      // -- create selected SplitMethod from config
      // for (let i = 0; i < this.splitMethodOptions.length; i++) {
      //   if (entry.SplitMode == this.splitMethodOptions[i].value) {
      //     newInfoView.SplitMethod = this.splitMethodOptions[i];
      //   }
      // }

      // const sm = this.splitMethodOptions.find(o => o.value == entry.SplitMode);
      const splitMode = this.assertSplitMethod(this.splitMethodOptions, entry);

      const newInfoView = new SplitColumnInfoView(entry.SelectedColumn, entry.SplitPattern, splitMode, entry.DropOldColumn,
        entry.NewColumnCount, has_error);


      newInfosView.push(newInfoView);
    });
    // -- return final view to GUI
    console.log("Final ColumnSplitterInfosView: ", newInfosView);

    return newInfosView;
  }

  assertSplitMethod(splitOptions: GuiDropDown[], splitInfo: SplitColumnPlugIn.SplitColumnInfo): GuiDropDown {
    const sm = this.splitMethodOptions.find(o => o.value == splitInfo.SplitMode);
    if (sm)
      return sm;
    else
      throw new Error("The split mode " + splitInfo.SplitMode + "from the split info for column '" + splitInfo.SelectedColumn + "' is not supported!")
  }

  ViewToInfo(list: SplitColumnInfoView[]): SplitColumnPlugIn.SplitColumnInfo[] {
    // -- create new empty array
    let newInfos = [];

    list.map((entry) => {
      // let newSettings = new SplitColumnPlugIn.SplitColumnInfo();
      // newSettings.ColumnName = entry.ColumnName;
      // newSettings.SplitCondition = entry.SplitCondition;
      // newSettings.SplitMethod = entry.SplitMethod.value;
      // newSettings.DropColumn = entry.DropColumn;
      // newSettings.MaxColumns = entry.MaxColumns;

      const newSettings = new SplitColumnPlugIn.SplitColumnInfo(entry.ColumnName, entry.SplitMethod.value, entry.DropColumn, entry.SplitCondition, entry.MaxColumns)

      newInfos.push(newSettings);
    });
    console.log("Final ColumnSplitterInfos: ", newInfos);
    return newInfos;
  }

}
