<!-- <div id="1" (click)="nodeClicked(1)"> Node 1</div>
<div id="2" (click)="nodeClicked(2)">Node 2 </div>
<div id="3" (click)="nodeClicked(3)">Node 3 </div> -->



<div class="left">
  Palette
  <gojs-palette #myPalette [initPalette]='initPalette' [divClassName]='paletteDivClassName' [nodeDataArray]='state.paletteNodeData'
     [modelData]='state.paletteModelData'></gojs-palette>
     

<div class="card flex justify-content-center">
  <p-panelMenu [model]="state.paletteNodeData" [style]="{'width':'300px'}" [multiple]="true"></p-panelMenu>
</div>

</div>

<div class="left">
  Diagram
  <gojs-diagram #myDiagram [initDiagram]='initDiagram' [nodeDataArray]='state.diagramNodeData' [linkDataArray]='state.diagramLinkData'
    [divClassName]='diagramDivClassName' [modelData]='state.diagramModelData' [(skipsDiagramUpdate)]='state.skipsDiagramUpdate'
    (modelChange)='diagramModelChange2($event)'>
  </gojs-diagram>
</div>

<div class="miniMap">
  Overview
  <gojs-overview #myOverview [initOverview]='initOverview' [divClassName]='oDivClassName' [observedDiagram]='observedDiagram'></gojs-overview>
</div>

<!-- 
<div class="left">
  <app-inspector [nodeData]='state.selectedNodeData' (onInspectorChange)='handleInspectorChange($event)'></app-inspector>
</div> -->
<div>
  <app-settings-view [nodeData]="state.selectedNodeData" (settingsChanged)="onNodeSettingsChanged($event)">
  </app-settings-view>
</div>

<div class="clear left box">
  <h3>Links in app data</h3>
  <ul>
    <li *ngFor="let ld of state.diagramLinkData">
      Link Key: {{ld.key}}, To: {{ld.to}}, From: {{ ld.from }}
    </li>
  </ul>
</div>

<div class="left box">
  <h3>Nodes in app data</h3>
  <ul>
    <li *ngFor="let nd of state.diagramNodeData">
      Node ID: {{nd.id}}, Text: {{nd.text}}, Color: {{nd.color}}, Loc: {{nd.loc}}, plugIn: {{nd.value}},  desr: {{nd.description}}
    </li>
  </ul>
</div>

<div class="clear left">
  <!-- <p>
    This <code>gojs-angular</code> sample demonstrates how to use the Diagram, Palette, and Overview <a href="https://www.npmjs.com/package/gojs-angular">gojs-angular</a> components, made
    and
    maintained by <a href="http://www.nwoods.com/">Northwoods Software</a>.
  </p>
  <p>
    Notice how when a Node or Link is removed or added to the Diagram, the Nodes and Links the top-level App Component
    has update accordingly.
  </p>
  <p>
    For more information on how to use Angular with GoJS, see the <a href="#">GoJS with Angular intro page</a>.
  </p> -->

</div>
