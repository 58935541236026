import { Component, OnDestroy, OnInit } from "@angular/core";
import { MessageService } from "primeng/api";
import { DatasourcesService } from "src/app/services/datasources.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { SubSink } from "subsink";
import * as dss from "../../../../models/datasource.model";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-delete-data-package-dialog",
	templateUrl: "./delete-data-package-dialog.component.html",
	styleUrls: ["./delete-data-package-dialog.component.scss"],
	providers: [MessageService],
})
export class DeleteDataPackageDialogComponent implements OnInit, OnDestroy {
	subs = new SubSink();
	displayDeleteDataPackage: boolean = false;
	savingInProgress: boolean = false;

	currentSelectedDatasource?: dss.DataSource;
	buttonDisabled: boolean = false;
	dataPackages: dss.DataPackage;
	selectedDataPackage: dss.DataPackage;

	constructor(
		private datasourceService: DatasourcesService,
		private systemLogService: SystemMessageLogService,
		public messageService: MessageService,
		private translate: TranslateService
	) {}

	ngOnInit(): void {
		this.subs.sink = this.datasourceService.displayDeleteDataPackage.subscribe(
			(res: [dss.DataSource,boolean]) => {
				this.currentSelectedDatasource = res[0];
				this.getDataPackages(res[0]);
				this.displayDeleteDataPackage = res[1];

				if (!res) return;
			}, (err: Error) => {
				this.systemLogService.handleError(err)
			}
		);
        this.subs.sink = this.datasourceService.datasourceChangedEmitter.subscribe(
			() => {
                if(this.currentSelectedDatasource) this.getDataPackages(this.currentSelectedDatasource)
			}
		);
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	// API Calls ------------------

    getDataPackages(ds?: dss.DataSource) {
		if(ds) {
			this.subs.sink = this.datasourceService
			.getDataPackage(ds.id)
			.subscribe((res: dss.DataPackage) => {
				this.dataPackages = res;
				this.selectedDataPackage = res[0];
			},(err: Error) => {
				this.systemLogService.handleError(err);
			});
		}

    }

	selectDataPackage(evt) {
		this.selectedDataPackage = evt.value;
	}

	deleteDataPackage() {
		this.savingInProgress = true;
		let dataPackage = this.selectedDataPackage;

		this.datasourceService.deleteDataPackage(dataPackage.id).then(
			(result) => {
				this.datasourceService.datasourceChangedEmitter.emit(this.currentSelectedDatasource);

				this.savingInProgress = false;
				this.buttonDisabled = false;
				this.messageService.add({
					severity: "success",
					summary: this.translate.instant("Message.DeleteDatapackageSuccess.Title"),
					detail: this.translate.instant("Message.DeleteDatapackageSuccess.Text1") + dataPackage.id +
					this.translate.instant("Message.DeleteDatapackageSuccess.Text2"),
				});
				this.displayDeleteDataPackage = false;
			},
			(err: Error) => {
				this.savingInProgress = false;
				this.buttonDisabled = false;
				this.systemLogService.handleError(err);
			}
		);
	}
}
