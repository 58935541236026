import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { interval, Observable, of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { TaskStatus } from 'src/app/models/api/controllers/scheduler/TaskStatus';
import { TaskJobModel } from 'src/app/models/api/models/staging/TaskJobModel';
//import { TaskStatus } from 'src/app/models/schedule.model';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { SubSink } from 'subsink';


@Component({
  selector: 'app-generic-object-tasks',
  templateUrl: './generic-object-tasks.component.html',
  styleUrls: ['./generic-object-tasks.component.scss']
})
export class GenericObjectTasksComponent implements OnInit {

    @Input() currentView!: string;
    tasks: TaskJobModel.JobStatusInfo<any>[] = [];
    scheduleTasks: TaskStatus[] = [];
    subs = new SubSink;
    taskLabel: string = "";
    currentRunning: boolean = false;

  constructor(private bionApi: ApiBackendService, private translate: TranslateService, private errorService: SystemMessageLogService,) { }

  ngOnInit(): void {
    this.subs.sink = this.translate.onLangChange.subscribe(() => {
        if(this.tasks.length === 0) {
            this.taskLabel = this.translate.instant("NoCurrentTasksRunning");
        }else {
            this.taskLabel = this.tasks.length + this.translate.instant("TasksCurrentlyRunning");
        }
    })
  }
  setObject(): Observable<boolean> {
    if(this.currentView !== "Schedules") {
        this.subs.sink = interval(5000)
        .pipe(
            startWith(0),
            switchMap(() => this.bionApi.ExtractDataToPsa_jobs_status())
        )
        .subscribe(
            (result) => {

            this.subs.sink = this.prepareGuiData(result).subscribe(taskInfos => {
                this.tasks = taskInfos[0];
                this.taskLabel = taskInfos[1];
                this.currentRunning = taskInfos[2];

                    return of(true);
                });
        });
    };
    // if(this.currentView === "Workflows") {
    //     this.subs.sink = interval(5000)
    //     .pipe(
    //         startWith(0),
    //         switchMap(() => this.bionApi.ExtractDataToPsa_jobs_status())
    //     )
    //     .subscribe(
    //         (result) => {

    //         this.subs.sink = this.prepareGuiData(result).subscribe(taskInfos => {
    //             this.tasks = taskInfos[0];
    //             this.taskLabel = taskInfos[1];
    //             this.currentRunning = taskInfos[2];

    //                 return of(true);
    //             });
    //     });
    // };


    if(this.currentView === "Schedules") {
        this.subs.sink = interval(5000)
        .pipe(
            startWith(0),
            switchMap(() => this.bionApi.getSchedulerStatus())
        )
        .subscribe(
            (scheduleStatus) => {
                //console.log("Updating Schedule Tasks", scheduleStatus.TaskStates);
                this.subs.sink = this.prepareScheduleGuiData(scheduleStatus.TaskStates).subscribe(taskInfos => {
                    this.scheduleTasks = taskInfos[0];
                    this.taskLabel = taskInfos[1];
                    this.currentRunning = taskInfos[2];

                        return of(true);
                    });
            },
            (err: Error) => {
                this.errorService.handleError(err);
            }
        );
        return of(true)
    }

    return of(true);


  }

  prepareScheduleGuiData(result: TaskStatus[]): Observable<[TaskStatus[],string,boolean]> {
    //let finaltaskInfos:TaskStatus[] = new Array();
    let currentRunningTasks = result.filter(task => task.State === 'Pending');
    let taskLabel: string = "";
    // No current running task avaiable, set default and stop
    if(currentRunningTasks === undefined || currentRunningTasks.length === 0) {
        //console.log("No tasks found, return" )
        this.currentRunning = false;
        taskLabel = this.translate.instant("NoCurrentTasksRunning");

        return of([currentRunningTasks,taskLabel,false])
    }
    taskLabel = currentRunningTasks.length + this.translate.instant("TasksCurrentlyRunning");

    return of([currentRunningTasks,taskLabel,true])

  }

  prepareGuiData(result:TaskJobModel.JobStatusInfo<any>[]): Observable<[TaskJobModel.JobStatusInfo<any>[],string,boolean]> {
    let finaltaskInfos:TaskJobModel.JobStatusInfo<any>[] = new Array();
    let currentRunningTasks = result.filter(task => task.Completed === false);
    let taskLabel: string = "";

    // No current running task avaiable, set default and stop
    if(currentRunningTasks === undefined || currentRunningTasks.length === 0) {
        //console.log("No tasks found, return" )
        this.currentRunning = false;
        taskLabel = this.translate.instant("NoCurrentTasksRunning");

        return of([finaltaskInfos,taskLabel,false])
    }
    // Current running task found, filter for object-related data (ds,wf etc.)
    if(this.currentView === "DataSources") {
        let extractDataSourceTasks = currentRunningTasks.filter((taskInfo) => {
            return taskInfo.OriginInfo.SimpleName == "ExtractDataToPsa";
        });
        finaltaskInfos = extractDataSourceTasks
    }
    if(this.currentView === "Workflows") {
        let executeWorkflowTasks = currentRunningTasks.filter((taskInfo) => {
            return taskInfo.OriginInfo.SimpleName == "FutureWorkflowEngine";
        });
        finaltaskInfos =executeWorkflowTasks
    }
    // Adding progress information
    let taskInfosWithJobs = this.addProgressPercentageToJobInfo(finaltaskInfos);
    taskLabel = taskInfosWithJobs.length + this.translate.instant("TasksCurrentlyRunning");

    let currentRunning: boolean;
    if(taskInfosWithJobs.length === 0) {
        currentRunning = false;
        taskLabel = this.translate.instant("NoCurrentTasksRunning");
    } else {
        currentRunning = true;
    }
    return of([taskInfosWithJobs,taskLabel,currentRunning])
  }

  	/**
	 * Add Percentage information to JobInfos when possible
	 * @param jobInfos JobInfos without percentage value
	 * @returns JobInfos with percentage value
	 */
	addProgressPercentageToJobInfo(jobInfos: TaskJobModel.JobStatusInfo<any>[]): TaskJobModel.JobStatusInfo<any>[] {
		//console.log(jobInfos);
		let jobInfosWithProgress = jobInfos.map((job) => {
			if (!job.Progress) {

			} else {
				if (job.Progress.Max) {
					let percNum = job.Progress.Value / job.Progress.Max * 100;
					let percNumRounded = parseInt(percNum.toFixed(2));
					// job.Progress.Percentage = percNumRounded;  // TODO: recheck

				}
			}

			return job
		})
		return jobInfosWithProgress
	}

}
