

export namespace FindReplacePlugIn{
    export class FindReplaceInfo {
		SelectedColumn: string;
		FindValue: string;
		ReplaceValue: string;
		NewName: string;
		ReplaceColumn: boolean;
		IgnoreCase: boolean;
		MatchWholeWord:boolean
		constructor(SelectedColumn: string,FindValue: string,ReplaceValue: string,NewName: string,ReplaceColumn: boolean,IgnoreCase: boolean, MatchWholeWord:boolean) {
			if(SelectedColumn === undefined) throw new Error( "Class 'FindReplaceInfo': Required value 'SelectedColumn' is undefined" );
			if(FindValue === undefined) throw new Error( "Class 'FindReplaceInfo': Required value 'FindValue' is undefined" );
			if(ReplaceValue === undefined) throw new Error( "Class 'FindReplaceInfo': Required value 'ReplaceValue' is undefined" );
			if(NewName === undefined) throw new Error( "Class 'FindReplaceInfo': Required value 'NewName' is undefined" );
			if(ReplaceColumn === undefined) throw new Error( "Class 'FindReplaceInfo': Required value 'DropOld' is undefined" );
			if(IgnoreCase === undefined) throw new Error( "Class 'FindReplaceInfo': Required value 'IgnoreCase' is undefined" );
			if(MatchWholeWord === undefined) throw new Error( "Class 'FindReplaceInfo': Required value 'MatchWholeWord' is undefined" );
			this.SelectedColumn = SelectedColumn;
			this.FindValue = FindValue;
			this.ReplaceValue = ReplaceValue;
			this.NewName = NewName;
			this.ReplaceColumn = ReplaceColumn;
			this.IgnoreCase = IgnoreCase;
			this.MatchWholeWord = MatchWholeWord;
		}
	}
	export class Settings {
		Infos: Array<FindReplaceInfo>;
		constructor(Infos: Array<FindReplaceInfo>) {
			if(Infos === undefined) throw new Error( "Class 'Settings': Required value 'Infos' is undefined" );
			this.Infos = Infos;
		}
	}
}