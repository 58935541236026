import { PlayJsonInheritance } from "src/app/models/play.model";

export class DestinationConnection<C extends PlayJsonInheritance> {
	Id: number;
	Adapter: string;
	Settings: C;
	constructor(Id: number, Adapter: string, Settings: C) {
		if (Id === undefined) throw new Error("Class 'DestinationConnection': Required value 'id' is undefined");
		if (Adapter === undefined) throw new Error("Class 'DestinationConnection': Required value 'adapter' is undefined");
		if (Settings === undefined) throw new Error("Class 'DestinationConnection': Required value 'settings' is undefined");
		this.Id = Id;
		this.Adapter = Adapter;
		this.Settings = Settings;
	}
}