<p-toolbar>
    <div class="p-toolbar-group-left">
        <p-button label="{{'Input' | translate}}" icon="pi pi-table" ></p-button>
        <p-button label="{{'Output' | translate}}" icon="pi pi-table" ></p-button>
        <p-button label="{{'State' | translate}}" icon="pi pi-table" ></p-button>

        <!-- <app-workflow-artefact [artifacts]="artifacts" [artifactsMap]="artifactsMap" [toggleView]="true">
        </app-workflow-artefact> -->
    </div>

    <!-- <span *ngIf="totalRecords"> {{totalRecords}} {{'rows' | translate}} | {{cols.length}} {{'columns' | translate}}
    </span> -->

    <div class="p-toolbar-group-right">
        <!-- <app-nodes-errors [errorLogResult]="errorLogResult" [toggleView]="true"></app-nodes-errors> -->
    </div>
</p-toolbar>
