<p-overlayPanel #op>
    <ng-template pTemplate>
        <p-table #dt [columns]="previewColumns" [value]="previewRows" styleClass="p-datatable-sm" scrollDirection="both"
            [scrollable]="true" scrollHeight="520px" autoLayout="true" [lazy]="true" [rows]="20">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th pResizableColumn [style]="{'min-width':'90px','max-width':'90px', 'font-size':'12px'}"
                        *ngFor="let col of columns">
                        {{col.Name}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                    <td [style]="{'min-width':'90px','max-width':'90px', 'font-size':'12px'}"
                        *ngFor="let col of columns">
                        {{rowData[col.Name]}}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="8">{{'NoEntryFound' | translate}}</td>
                </tr>
            </ng-template>
        </p-table>
    </ng-template>
</p-overlayPanel>

<div class="p-grid">
    <!-- <div class="p-col-6"> -->
    <div class="p-col-12">
        <div class="card-header">
            <h6 style="color: rgb(170, 170, 170)">{{'SelectColumns' | translate}}
                <span style="float: right">
                    <button type="text" pButton label="{{'Preview' | translate}}" (click)="op.toggle($event)"></button>
                </span>
            </h6>
        </div>
        <div style="margin-top: 5px">
            <p-scrollPanel [style]="{height: '510px'}">
                <p-table [value]="dataPreviewColumnClone" [(selection)]="this.selectFormGroup.value.COLUMNSCOPE"
                    dataKey="ColumnIndex" editMode="row">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 3rem">
                                <p-tableHeaderCheckbox (click)="DsFieldsSelectionToMapping()">
                                </p-tableHeaderCheckbox>
                            </th>
                            <th style="width:6rem">{{'Key' | translate}}</th>
                            <th>{{'Name' | translate}}</th>
                            <th style="width: 12rem;">{{'Rename' | translate}}</th>
                            <th style="width: 12rem;">{{'Datatype' | translate}}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-column let-editing="editing" let-ri="rowIndex">
                        <tr [pEditableRow]="column">
                            <td>
                                <p-tableCheckbox [value]="column" (click)="DsFieldsSelectionToMapping()">
                                </p-tableCheckbox>
                            </td>
                            <td>
                                <p-checkbox [(ngModel)]="column.IsKey" [(value)]="column.IsKey"
                                    [ngModelOptions]="{standalone: true}" [binary]="true"></p-checkbox>
                            </td>
                            <td>
                                {{column.Name}}
                                <!-- <span class="p-float-label">
                                        <input type="text" id="inputtext" pInputText [(ngModel)]="column.Name" (blur)="DsFieldsSelectionToMapping()" [style]="{'width':'100%'}">
                                    </span> -->
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" id="inputtext" pInputText
                                        placeholder="{{'EnterNewName' | translate}}" [(ngModel)]="column.NewName"
                                        (blur)="DsFieldsSelectionToMapping()" [style]="{'width':'100%'}">
                                </span>
                            </td>
                            <!-- <td>
                                    {{column.DataType.Name}}
                                </td> -->
                            <td><span class="p-column-title">{{'DataType' | translate}}</span>
                                <p-dropdown [style]="{'width':'100%'}" [options]="dataTypes"
                                    (onChange)="DsFieldsSelectionToMapping()" [(ngModel)]="column.DataType"
                                    optionLabel="Name" [filter]="false" filterBy="Name" [showClear]="false"
                                    placeholder="{{'Select a datatype' |translate}}">
                                    <ng-template pTemplate="selectedItem">
                                        <div class="country-item country-item-value" *ngIf="column.DataType">
                                            <span
                                                [class]="'customer-badge status-' + column.DataType.Name">{{column.DataType.Name}}</span>
                                            <!-- <img src="assets/showcase/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + column.DataType.Name.toLowerCase()" /> -->
                                            <!-- <div>{{column.DataType.Name}}</div> -->
                                        </div>
                                    </ng-template>
                                    <ng-template let-column pTemplate="item">
                                        <div class="country-item">
                                            <span
                                                [class]="'customer-badge status-' + column.Name">{{column.Name}}</span>
                                            <!-- <img src="assets/showcase/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + column.DataType.Name.toLowerCase()" /> -->
                                            <!-- <div>{{column.DataType.Name}}</div> -->
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="4">{{'NoEntryFound' | translate}}</td>
                        </tr>
                    </ng-template>
                </p-table>

                <p-scrollTop target="parent" styleClass="custom-scrolltop" [threshold]="100" icon="pi pi-arrow-up">
                </p-scrollTop>
            </p-scrollPanel>
        </div>
    </div>
    <!-- </div> -->
    <!-- <div class="p-col-6">
        <div class="card p-shadow-2">
            <div class="card-header">
                <h6 style="color: rgb(170, 170, 170)">{{'DataPreview' | translate}}</h6>
            </div>
            <p-table #dt [columns]="previewColumns" [value]="previewRows"
                styleClass="p-datatable-sm" scrollDirection="both" [scrollable]="true" scrollHeight="520px"
                autoLayout="true" [lazy]="true" [rows]="20">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th pResizableColumn [style]="{'min-width':'90px','max-width':'90px', 'font-size':'12px'}"
                            *ngFor="let col of columns">
                            {{col.Name}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr>
                        <td [style]="{'min-width':'90px','max-width':'90px', 'font-size':'12px'}" *ngFor="let col of columns">
                            {{rowData[col.Name]}}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">{{'NoEntryFound' | translate}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div> -->

</div>
