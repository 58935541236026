import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable, Output } from "@angular/core";
import { Observable } from "rxjs";
import { WorkflowRepositoryEntry } from "../models/api/models/workflow/WorkflowRepositoryEntry";
import { WorkflowController } from "../models/api/controllers/WorkflowController";
import { WorkflowNodeInfo } from "../models/designer.models";
import { NewWorkflowArg, } from "../views/workflows/dialogs/create-workflow-dialog/create-workflow-dialog.component";
import { ApiBackendService } from "./api-backend.service";
import { environment } from "../../environments/environment";
import { WorkflowActionEvent, WorkflowActionEventStatus } from "../models/dialog-actions.model";




@Injectable({
	providedIn: "root",
})

export class WorkflowsService {
	constructor(public api: ApiBackendService, private http: HttpClient) { }

	// API CALLS -------------------------------------------------------------------

	getWorkflowObjectList(id?: number): Observable<WorkflowRepositoryEntry[]> {
		return this.api.getWorkflowObjectList(id);
	}

	public createWorkflowObject(entry: WorkflowRepositoryEntry) {
		return this.api.createWorkflowObject(entry);
	}
	public updateWorkflowObject(entry: WorkflowRepositoryEntry) {
		return this.api.updateWorkflowObject(entry);
	}
	public deleteWorkflowObject(id: string) {
		return this.api.deleteWorkflowObject(id);
	}
	getNodePlugIns(): Observable<WorkflowNodeInfo[]> {
		return this.api.listPlugIns();
	}

	// Export/Import
	public importWorkflows(entries: Array<WorkflowRepositoryEntry>): Observable<Array<WorkflowController.ImportResult<WorkflowRepositoryEntry>>> {
		return this.api.importWorkflows(entries);
	}



	// DIALOG EMITTER -------------------------------------------------------------------

	@Output() displayOpenWorkflow = new EventEmitter<boolean>();
	// @Output() displaySaveWorkflow = new EventEmitter<[WorkflowRepositoryEntry,boolean]>();
	// @Output() displaySaveAsWorkflow = new EventEmitter<[WorkflowRepositoryEntry,boolean]>();

	// @Output() displayCreateWorkflow = new EventEmitter<boolean>();
	// @Output() displayEditWorkflow = new EventEmitter<[WorkflowRepositoryEntry,boolean]>();
	//@Output() displayDeleteWorkflow = new EventEmitter<[WorkflowRepositoryEntry,boolean]>();

	@Output() workflowDialogActionSendEmitter = new EventEmitter<WorkflowActionEvent>();
	@Output() workflowDialogActionReceivedEmitter = new EventEmitter<WorkflowActionEvent>();
	@Output() workflowDialogActionStatusEmitter = new EventEmitter<WorkflowActionEventStatus>();

	// CRUID EMITTER -------------------------------------------------------------------
	//@Output() newWorkflowEmitter = new EventEmitter<WorkflowRepositoryEntry>();
	// @Output() deletedWorkflowEmitter =
	// 	new EventEmitter<WorkflowRepositoryEntry>();
	// @Output() updatedWorkflowEmitter =
	// 	new EventEmitter<WorkflowRepositoryEntry>();

	@Output() WorkflowDialogNewEmitter = new EventEmitter<NewWorkflowArg>(); // for designer only
	@Output() UpdatedWorkflowEmitter =
		new EventEmitter<WorkflowRepositoryEntry>(); // for designer only

	//@Output() NewWorkflowWorkflowEmitter = new EventEmitter<NewWorkflowArg>(); // for workflow

	@Output() workflowsChangedEmitter = new EventEmitter<string>(); // for workflow


	// SELECTION EMITTER ---------------------------------------------------------------
	@Output() selectedWorkflowEmitter =
		new EventEmitter<WorkflowRepositoryEntry>();
	@Output() selectedMenuWorkflowEmitter =
		new EventEmitter<WorkflowRepositoryEntry>();

	@Output() selectedWorkflowToOpenEmitter = new EventEmitter<WorkflowRepositoryEntry>();

	/**
	 * The relative Back End URL Proxy, usually this is '/api'
	 */
	readonly BackEndUrl = environment.API_URL;


	// SQL Workflow --------------------------------------------------------------------
	// Note: BETA, URLs & signatures may change!

	/**
	 * Retrieve the SQL Workflow Plug-In list.
	 */
	getSqlPlugIns(): Observable<WorkflowNodeInfo[]> {
		return this.http.get<WorkflowNodeInfo[]>(this.BackEndUrl + "/Workflow/Sql/plugIns");
	}
}
