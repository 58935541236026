import { Component, OnInit } from "@angular/core";
import { Id } from "src/app/helper/id";
import { FieldInfo, WorkflowNodeSettings } from "src/app/models/api/com/bion/etl/Workflow";
import {
	DateParserInfo,
	DateParserSettings,
} from "src/app/models/nodeSettings.model";
// import { FieldInfo, WorkflowNodeSettings } from "src/app/models/workflow.model";
import { DesignerService } from "src/app/services/designer.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { NodeConfigComponentBase } from "../node-config-component-base";

export class DateParserView {
	ConvertMode: ParserDropDown;
	ColumnName: string;
	TargetDateFormat: ParserDropDown;
	DropColumn: boolean;
	NewColumnName?: string;
	hasError?: boolean;

    constructor(ConvertMode: ParserDropDown, ColumnName: string, TargetDateFormat: ParserDropDown, DropColumn: boolean, NewColumnName? : string, hasError?: boolean) {
        this.ConvertMode = ConvertMode;
        this.ColumnName = ColumnName;
        this.TargetDateFormat = TargetDateFormat;
        this.DropColumn = DropColumn;
        this.NewColumnName = NewColumnName;
        this.hasError = hasError;
    }
}

export class ParserDropDown {
	name: string;
	value: number;

    constructor(name: string, value: number) {
        this.name = name;
        this.value = value;
    }
}

@Component({
	selector: "app-dateparser-node",
	templateUrl: "./dateparser-node.component.html",
	styleUrls: ["./dateparser-node.component.scss"],
})
export class DateparserNodeComponent
	extends NodeConfigComponentBase<DateParserSettings>
	implements OnInit
{
	settingsToView(settings: WorkflowNodeSettings): void {
		// -- Get Configuration
		let currentConfig = <DateParserSettings>settings;
		// -- Get Input Meta Data
		//const meta_infos = this.getCurrentWorkflowNode().Properties.MetaInfo.get(this.InputPort);
		const meta_infos =
			this._currentWorkflowNodeAdapter.IWorkflowNode.Properties.MetaInfo.get(
				this.InputPort
			);

		let columnListArray: string[] = [];
		// -- check for MetaInfos from Input Port & store dsField list
		if (meta_infos !== undefined && meta_infos.length > 0) {
			const input_field_infos = meta_infos[0].FieldsInfo;

			// -- filter for string dsfield type entries only
			const input_field_infos_string = input_field_infos.filter((dsfield) => {
				return dsfield.DataType.Name === "String";
			});

			// -- write to variable
			columnListArray = input_field_infos.map((fieldInfo) => {
				return fieldInfo.Name;
			});
			this.columnList = columnListArray;
		}


		// -- if no configuration exists, set default
		if (currentConfig == undefined) {
			// -- create empty list
			this.dateParserList = [];
			// -- select default TypeCheck Option (first entry)
			this.selectedConvertModeOption = this.convertModeOptions[0];
			this.selectedDateFormatOption = this.dateFormatOptions[0];
		} else {
			// -- otherwise load to Parser list from existing config
			currentConfig.DateParserInfos.map((info) => {
				if (columnListArray.includes(info.ColumnName)) {
					// -- column still exists, do nothing
				} else {
					// column does not exist anymore, meaning column name currently not included -> include
					columnListArray.push(info.ColumnName);
				}
			});
			this.columnList = columnListArray;

			//const fieldInfos = meta_infos[0].FieldsInfo;
			const fieldInfos_option = meta_infos?.headOption()?.FieldsInfo; 

			this.dateParserList = this.infoToView(
				currentConfig,
				fieldInfos_option
			);
		}
	}
	viewToSettings(): DateParserSettings {
		const newSettings = <DateParserSettings>{
			...this.getCurrentWorkflowNode().Properties.Configuration,
		};

		let newInfoList: DateParserInfo[] = [];

		this.dateParserList.map((entry) => {
			let newInfo = new DateParserInfo();
			newInfo.ColumnName = entry.ColumnName;
			newInfo.ConvertMode = entry.ConvertMode.value;
			newInfo.NewColumnName = entry.NewColumnName;
			newInfo.TargetDateFormat = entry.TargetDateFormat.value;
			newInfo.DropColumn = entry.DropColumn;

			newInfoList.push(newInfo);
		});

		newSettings.DateParserInfos = newInfoList;
		console.log("DateParser: Final Settings: ", newSettings);

		// this.messageService.standardToastMessage({  severity : 'success',
		//                                                 summary : 'Filter successsfully added',
		//                                                 detail : 'New Filter was successfully added to the node.'});

		return newSettings;
	}
	onSettingsChanged(settings: DateParserSettings) {
		throw new Error("Method not implemented.");
	}

	columnList: string[]= [];
	//dropDownColumn: GuiDropDown[];
	dateParserList: DateParserView[] = [];
	targetDateFormatOptions: ParserDropDown[];
	convertModeOptions: ParserDropDown[];
	selectedConvertModeOption?: ParserDropDown;
	dateFormatOptions: ParserDropDown[] = [];
	selectedDateFormatOption?: ParserDropDown;

	readonly InputPort = "Input";

	infoToView(
		settings: DateParserSettings,
		meta?: FieldInfo[]
	): DateParserView[] {
		let newViewList: DateParserView[] = [];

		if (settings.DateParserInfos.length > 0) {
			settings.DateParserInfos.map((info) => {


				//newView.ColumnName = info.ColumnName;

				const fieldInfoFound = meta?.find((field) => {
					return field.Name === info.ColumnName;
				});

				//newView.hasError = !fieldInfoFound;

				const ConvertMode = this.convertModeOptions.find((i) => {
					return info.ConvertMode === i.value;
				});
                const ConvertModeAssert = Id.assertSet(ConvertMode, new Error("ConvertMode not found, should not happen!!"));

                const newColName = info.NewColumnName ? info.NewColumnName : undefined;
				// if (info.NewColumnName) {
				// 	newView.NewColumnName = info.NewColumnName;
				// }

				const TargetDateFormat = this.targetDateFormatOptions.find(
					(i: ParserDropDown) => {
						return info.TargetDateFormat === i.value;
					}
				);
                const TargetDateFormatAssert = Id.assertSet(TargetDateFormat, new Error("TargetDateFormat not found, should not happen!!"))
				//newView.DropColumn = info.DropColumn;

                let newView = new DateParserView(ConvertModeAssert,info.ColumnName,TargetDateFormatAssert,info.DropColumn,newColName,!fieldInfoFound);

				newViewList.push(newView);
			});
		}
		return newViewList;
	}

	constructor(
		protected workflowService: WorkflowsService,
		protected designerService: DesignerService
	) {
		super(workflowService, designerService);

		this.targetDateFormatOptions = [
			{ name: "DD.MM.YYYY", value: 1 },
			{ name: "MM.DD.YYYY", value: 2 },
			{ name: "YYYY.MM.DD", value: 3 },

			{ name: "DD/MM/YYYY", value: 4 },
			{ name: "MM/DD/YYYY", value: 5 },
			{ name: "YYYY/MM/DD", value: 6 },

			{ name: "DD-MM-YYYY", value: 7 },
			{ name: "MM-DD-YYYY", value: 8 },
			{ name: "YYYY-MM-DD", value: 9 },

			{ name: "YYYY-MM", value: 10 },
			{ name: "YYYY/MM", value: 11 },

			{ name: "MM-YYYY", value: 12 },
			{ name: "MM/YYYY", value: 13 },

			{ name: "DD.MM.YYYY,hh:mm:ss", value: 14 },
			{ name: "DD/MM/YYYY,hh:mm:ss", value: 15 },
			{ name: "DD-MM-YYYY,hh:mm:ss", value: 16 },

			{ name: "hh:mm:ss", value: 17 },
		];
		this.convertModeOptions = [
			{ name: "StringToDate", value: 1 },
			{ name: "DateToString", value: 2 },
		];
	}
	ngOnInit(): void {
		super.ngOnInit();
	}

	onAddEntry() {
		let newDateParserEntry = new DateParserView(this.convertModeOptions[0],"",this.targetDateFormatOptions[0],false,"", false);
		// newDateParserEntry.ColumnName = "";
		// newDateParserEntry.NewColumnName = "";
		// newDateParserEntry.ConvertMode = this.convertModeOptions[0];
		// newDateParserEntry.TargetDateFormat = this.targetDateFormatOptions[0];
		// newDateParserEntry.DropColumn = false;
		// newDateParserEntry.hasError = false;

		this.dateParserList.push(newDateParserEntry);
		this.onUpdateSettings(false);
	}

	onDeleteEntry(index: number) {
		this.dateParserList = [
			...this.dateParserList.slice(0, index),
			...this.dateParserList.slice(index + 1),
		];
		this.onUpdateSettings(false);

		// this.messageService.standardToastMessage({  severity : 'warning',
		//                             summary : 'Filter deleted',
		//                             detail : 'The selected filter was deleted successfully!'});
	}
    onFocusLost(event: FocusEvent) {
        const div_left: boolean = (event.relatedTarget === null);

        console.log("Send from Div: ", div_left);

        if (div_left) {
            //this.onCheckDirtyFlag();
            this.onUpdateSettings(true);
        }
    }
}
