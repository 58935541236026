import { Injectable } from "@angular/core";
import { IntegratedSourceController } from "../models/api/controllers/IntegratedSourceController";
import { Observable } from "rxjs";
import { HttpClient, HttpEvent, HttpHeaders } from "@angular/common/http";
import { ApiServiceUtils } from "./api-util";
import { IntegratedSourceModel } from "../models/api/models/IntegratedSourceModel";

/**
 * Erstellt Data Sources für Bion und Airbyte über eine überwiegend eiheitliche Schnittstelle.
 * 'Überwiegend' deswegen, weil sich beide Modeelle nicht exakt integrieren lassen. Dies wird aber
 * subkzessive nachgezogen.
 * 
 * Im Folgenden ist mit Origin-API die jeweilige API von Bion bzw. Airbyte genannt.
 * 
 * Wichtigste Kernkonzepte
 * - Bion und Airbyte werden über einen einheitlichen Schlüssel identifiziert. Dieser besteht aus
 *   DB-Primärschlüssel und Origin (Bion,Airbyte)
 * - Jeder Call liefert auch das Original API Ergebnis der Origin-API.
 * - Die Calls nutzen nur GET- und POST-Requests, weil die Paramter komplex sein können.
 * - Die Bion-Metadaten und der Airbyte-Catalog werden über einen generischen Katalog abstrahiert. Dieser ist ein Super-Set und
 *   dienst der Auswahl von Streams und Schreibmodi.
 */
@Injectable({
    providedIn: "root",
})
export class IntegratedSourceService {

    constructor(private http: HttpClient) {

    }

    /**
     * 
     * @returns Alle Bion und Airbyte Konnektoren. 
     */
    getConnectors(): Observable<IntegratedSourceController.ConnectorInfo[]> {
        const options = ApiServiceUtils.makeOptions();
        return this.http.get<IntegratedSourceController.ConnectorInfo[]>("/api/Staging/DataSources/Integrate/api/getConnectors", options);
    }

    /**
     * Konnektor Details und Konfigurationsinformationen.
     * @param arg Konnektor Schlüssel
     * @returns 
     */
    getConnectorSpecs(arg: IntegratedSourceModel.ConnectorKey<string>): Observable<IntegratedSourceModel.ConnectorSpec<string, any>> {
        return this.http.post<IntegratedSourceModel.ConnectorSpec<string, any>>("/api/Staging/DataSources/Integrate/api/getConnectorSpecs", arg);
    }

    /**
     * Erstellt eine neue Datasource
     * @param arg Konnektorschlüssel, Konfiguration und Name.
     * @returns Datasource Id und Original API Ergebins
     */
    createDataSource<C, R>(arg: IntegratedSourceModel.CreateDataSourceArg<C>): Observable<IntegratedSourceModel.CreateDataSourceResult<R>> {
        return this.http.post<IntegratedSourceModel.CreateDataSourceResult<R>>("/api/Staging/DataSources/Integrate/api/createDataSource", arg);
    }

    /**
     * Holt den Metadaten-Katalog für die angegebene Datasource.
     * @param arg Datasource Typ und Schlüssel.
     * @returns Verfügbare Metadaten. Diese sind je nach Typ unterschiedlich
     */
    getCatalog<R>(arg: IntegratedSourceModel.GetCatalogArg): Observable<IntegratedSourceModel.GetCatalogResult<R>> {
        return this.http.post<IntegratedSourceModel.GetCatalogResult<R>>("/api/Staging/DataSources/Integrate/api/getCatalog", arg);
    }

    /**
     * Verbesserte Variante von getCatalog mit Datei-Upload
     * @param sourceKey Source Schlüssel
     * @param file Datei
     * @param progressMode Falls true die Event-basierte Variante, sonst das Standard-Ergebnis. 
     * @returns 
     */
    getCatalogFile<R>(sourceKey: IntegratedSourceModel.DataSourceKey<number>, file: File, progressMode: boolean): Observable<HttpEvent<Object>> | Observable<IntegratedSourceModel.GetCatalogResult<R>> {
        console.log("Upload File");

        const formData = new FormData();
        formData.append('file', file);
        formData.append('sourceKey', JSON.stringify(sourceKey));

        // return this.http.post('/api/Staging/DataSources/Integrate/api/syncFile', formData, { reportProgress: true, observe: 'events' });

        if (progressMode)
            return this.http.post('/api/Staging/DataSources/Integrate/api/getCatalogFile', formData, { reportProgress: true, observe: 'events' });
        else
            return this.http.post<IntegratedSourceModel.GetCatalogResult<R>>('/api/Staging/DataSources/Integrate/api/getCatalogFile', formData);
    }

    /**
     * Legt die zu extrahierenden Streams/Tabellen/Entitäten fest.
     * @param arg Datasource Schlüssel und der Katalog mit den ausgewählten Streams.
     * @returns Das original API-Ergebnis. Wenn kein Fehler erzeugt wird war die Auswahl erfolgreich.
     */
    defineStreams<S, R>(arg: IntegratedSourceModel.DefineStreamsArg<S>): Observable<IntegratedSourceModel.DefineStreamsResult<R>> {
        return this.http.post<IntegratedSourceModel.DefineStreamsResult<R>>("/api/Staging/DataSources/Integrate/api/defineStreams", arg);
    }

    sync(arg: IntegratedSourceModel.SyncArg): Observable<IntegratedSourceModel.SyncResult> {
        return this.http.post<IntegratedSourceModel.SyncResult>("/api/Staging/DataSources/Integrate/api/sync", arg);
    }

    /**
     * Synchronisiert einen Bion File-Konnektor.
     * Diese optimierte Variante der sync-Funktion nutzt Form Data um die Datei hochuladen und liefert Status-Ereignisse für den Upload.
     * @param file Datei
     * @param source_key Data Source Schlüssel 
     */
    syncFile(file: File, source_key: IntegratedSourceModel.DataSourceKey<number>) {

        const formData = new FormData();
        formData.append('file', file);
        formData.append('sourceKey', JSON.stringify(source_key));

        return this.http.post('/api/Staging/DataSources/Integrate/api/syncFile', formData, { reportProgress: true, observe: 'events' })
    }

    // Rest der Data Source CRUD-Operationen

    /**
     * Holt alle Datenquellen. Falls Sync-Status und Infos zum letzten Extrakt geholt werden sollen muss 'fetchPrimaryConnection' auf true gesetzt werden.
     * @param fetchPrimaryConnection Holt die Infos der Primärverbindung dieser Source.
     * @param fetchAdditionalInfo Holt Zusatzinformationen wie Permissions.
     * @returns Alle Bion und Airbyte Datenquellen.
     */
    getDataSource(fetchPrimaryConnection?: boolean, fetchAdditionalInfo?: boolean): Observable<IntegratedSourceModel.DataSource<number>[]> {
        const options = ApiServiceUtils.makeOptions(
            { param: "fetchPrimaryConnection", value: fetchPrimaryConnection },
            { param: "fetchAdditionalInfo", value: fetchAdditionalInfo }
        );
        return this.http.get<IntegratedSourceModel.DataSource<number>[]>("/api/Staging/DataSources/Integrate/DataSource", options);
    }

    getWriteModeInfos(): Observable<IntegratedSourceModel.GenCatalog.WriteModeInfo[]> {
        const options = ApiServiceUtils.makeOptions();
        return this.http.get<IntegratedSourceModel.GenCatalog.WriteModeInfo[]>("/api/Staging/DataSources/Integrate/WriteModeInfos", options);
    }

    // = Data Source CRUD

    // Create : see above

    /**
     * Aktualisiert die Data Source. ACHTUNG: aktuell werden noch nicht alle Attribute unterstützt. Bei Bion ist das z.B. der Write-Mode,
     * denn dieser soll in Zukunft über den neuen Bion-Katalog aktualisiert werden.
     * @param arg 
     * @returns 
     */
    updateDataSource<C>(arg: IntegratedSourceModel.UpdateDataSourceArg<C>) {
        return this.http.post<number>("/api/Staging/DataSources/Integrate/DataSource/update", arg);
    }

    /**
     * Löscht die Data Source und ihre Verbindungen. Es werden bei Airbyte keine Daten gelöscht. Bei Bion schon, das muss noch vereinheitlicht werden.
     * @param arg 
     * @returns 
     */
    deleteDataSource(arg: IntegratedSourceModel.DeleteDataSourceArg): Observable<number> {
        return this.http.post<number>("/api/Staging/DataSources/Integrate/DataSource/delete", arg);
    }

    // == Streams CRUD

    getStreams(arg: IntegratedSourceModel.GetStreamsArg): Observable<IntegratedSourceModel.GetStreamsResult> {
        return this.http.post<IntegratedSourceModel.GetStreamsResult>("/api/Staging/DataSources/Integrate/DataSource/Streams/get", arg);
    }

    updateStreams(arg: IntegratedSourceModel.UpdateStreamsArg) {
        return this.http.post<IntegratedSourceModel.UpdateStreamsResult>("/api/Staging/DataSources/Integrate/DataSource/Streams/update", arg);
    }

    extractFromDataSource() {
        // Extrahiert alle ausgewählten Streams. 

        // sync (Airbyte)
        // extract (Bion)
    }

    psaInfos(arg: IntegratedSourceModel.GetPsaInfosArg) {
        return this.http.post<IntegratedSourceModel.PsaInfo[]>("/api/Staging/DataSources/Integrate/api/psaInfos", arg);
    }

    /**
     * Query the Streams PSA as chunked response
     * @param arg 
     * @returns 
     */
    queryStream(arg: IntegratedSourceModel.QueryStreamArg) {

        const url = "/api/Staging/DataSources/Integrate/api/queryStream";

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        const options = { headers, responseType: 'blob' as 'json' }; // Wichtig: responseType auf 'blob' setzen

        return this.http.post<Blob>(url, arg, options);
    }

    /**
     * Prüft, ob die Verbindung valide ist.
     * @param arg 
     * @returns 
     */
    checkConnection<A>(arg: IntegratedSourceModel.CheckConnectionArg<A>) {
        return this.http.post<IntegratedSourceModel.CheckConnectionResult>("/api/Staging/DataSources/Integrate/api/checkConnection", arg);
    }

    getPsaEndpoints(arg: IntegratedSourceModel.PsaInfo): Observable<IntegratedSourceController.EndpointInfo[]> {
        return this.http.post<IntegratedSourceController.EndpointInfo[]>("/api/Staging/DataSources/Integrate/api/psaEndpoints", arg);
    }

    // -- Export

    /**
     * Exportiert die PSA des Streams als Chunked-Response.
     * @param arg Argument
     * @returns Chunked-Response.
     */
    exportPsa<A>(arg: IntegratedSourceModel.ExportArg<A>) {

        const result = this.http.post("/api/Staging/DataSources/Integrate/DataSource/Export/api/exportPsa", arg, {
            reportProgress: true,
            observe: 'events',
            responseType: 'blob'
        });

        return result;
    }

    // -- Permissions
    public getIntSourcePermission(arg?: IntegratedSourceModel.GetPermissionArg): Observable<IntegratedSourceModel.IntSourcePermission[]> {
        return this.http.post<IntegratedSourceModel.IntSourcePermission[]>("/api/Staging/DataSources/Integrate/DataSource/Auth/Permissions/list", arg);
    }
    public createIntSourcePermission(item: IntegratedSourceModel.IntSourcePermission): Observable<IntegratedSourceModel.IntSourcePermission> {
        return this.http.post<IntegratedSourceModel.IntSourcePermission>("/api/Staging/DataSources/Integrate/DataSource/Auth/Permissions/create", item);
    }
    public updateIntSourcePermission(item: IntegratedSourceModel.IntSourcePermission): Observable<number> {
        return this.http.post<number>("/api/Staging/DataSources/Integrate/DataSource/Auth/Permissions/update", item);
    }
    public deleteIntSourcePermission(arg: IntegratedSourceModel.DeletePermissionArg): Observable<number> {
        return this.http.post<number>("/api/Staging/DataSources/Integrate/DataSource/Auth/Permissions/delete", arg);
    }

    public getSupportedRights(): Observable<string[]> {
        const options = ApiServiceUtils.makeOptions();
        return this.http.get<string[]>("/api/Staging/DataSources/Integrate/DataSource/Auth/Rights", options);
    }

    // -- Jobs
    Jobs: Staging.Integrate.Jobs = new Staging.Integrate.Jobs(this.http);
}

export namespace Staging {
    export namespace Integrate {
        export namespace DataSource {

            @Injectable({ providedIn: "root" })
            export class Auth {
                public constructor(private http: HttpClient) {
                    this.Rights = new Auth.Rights(http);
                    this.Permissions = new Auth.Permissions(http);
                }
                Rights: Auth.Rights;
                Permissions: Auth.Permissions;
            }

            export namespace Auth {

                @Injectable({ providedIn: "root" })
                export class Permissions {
                    public constructor(private http: HttpClient) {
                        // TODO: inject base url
                        this.baseUrl = "/api";
                        this.subUrl = "/Staging/DataSources/Integrate/DataSource/Auth/Permissions"
                        this.apiUrl = this.baseUrl + this.subUrl;
                    }

                    readonly baseUrl: string;
                    readonly subUrl: string;
                    readonly apiUrl: string;

                    public get(arg?: IntegratedSourceModel.GetPermissionArg): Observable<IntegratedSourceModel.IntSourcePermission[]> {
                        return this.http.post<IntegratedSourceModel.IntSourcePermission[]>(this.apiUrl + "/list", arg);
                    }
                    public create(item: IntegratedSourceModel.IntSourcePermission): Observable<IntegratedSourceModel.IntSourcePermission> {
                        return this.http.post<IntegratedSourceModel.IntSourcePermission>(this.apiUrl + "/create", item);
                    }
                    public update(item: IntegratedSourceModel.IntSourcePermission): Observable<number> {
                        return this.http.post<number>(this.apiUrl + "/update", item);
                    }
                    public delete(arg: IntegratedSourceModel.DeletePermissionArg): Observable<number> {
                        return this.http.post<number>(this.apiUrl + "/delete", arg);
                    }
                }

                @Injectable({ providedIn: "root" })
                export class Rights {
                    public constructor(private http: HttpClient) {
                        // TODO: inject base url
                        this.baseUrl = "/api";
                        this.subUrl = "/Staging/DataSources/Integrate/DataSource/Auth/Rights"
                        this.apiUrl = this.baseUrl + this.subUrl;
                    }

                    readonly baseUrl: string;
                    readonly subUrl: string;
                    readonly apiUrl: string;

                    public get(): Observable<string[]> {
                        const options = ApiServiceUtils.makeOptions();
                        return this.http.get<string[]>(this.apiUrl, options);
                    }
                }
            }
        }

        @Injectable({ providedIn: "root" })
        export class Jobs {
            constructor(private http: HttpClient) {
            }

            public list(arg: IntegratedSourceModel.GetJobsArg): Observable<Array<IntegratedSourceModel.JobInfo>> {
                return this.http.post<Array<IntegratedSourceModel.JobInfo>>("/api/Staging/DataSources/Integrate/Jobs/list", arg);
            }
        }

        export namespace Jobs {

        }
    }
}