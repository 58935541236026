import { Component, OnInit } from '@angular/core';
import { Id } from 'src/app/helper/id';
import { ConvertCasePlugIn } from 'src/app/models/api/com/bion/etl/transformation/ConvertCasePlugIn';
import { FieldInfo, WorkflowNodeSettings } from 'src/app/models/api/com/bion/etl/Workflow';
import { DesignerService } from 'src/app/services/designer.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { NodeConfigComponentBase } from '../node-config-component-base';


export class ConvertCaseInfoView {
	ColumnName: string;
	ConvertMode: DropDownType;
	hasError?: boolean;

	constructor(ColumnName: string, ConvertMode: DropDownType, hasError?: boolean) {
		this.ColumnName = ColumnName;
		this.ConvertMode = ConvertMode;
		this.hasError = hasError
	}
}

export class DropDownType {
	name: string;
	value: number;

	constructor(name: string, value: number) {
		this.name = name;
		this.value = value;
	}
}



@Component({
  selector: 'app-convert-case-node',
  templateUrl: './convert-case-node.component.html',
  styleUrls: ['./convert-case-node.component.scss']
})
export class ConvertCaseNodeComponent extends NodeConfigComponentBase<ConvertCasePlugIn.Settings>
implements OnInit
{
settingsToView(settings: WorkflowNodeSettings): void {
  // -- Get Configuration
  let currentConfig = <ConvertCasePlugIn.Settings>settings;
  // -- Get Input Meta Data
  const meta_infos =
    this._currentWorkflowNodeAdapter.IWorkflowNode.Properties.MetaInfo.get(
      this.InputPort
    );

  let columnListArray: string[] = [];

  // create SelectInfoViews from DsFields for table
  if (meta_infos != undefined && meta_infos.length > 0) {
    const input_field_infos = meta_infos[0].FieldsInfo;

    columnListArray = input_field_infos.map((fieldInfo) => {
      return fieldInfo.Name;
    });
    this.columnList = columnListArray;
  }

  // --If currentConfig not available (-> new node)
  if (currentConfig == undefined) {
    this.infoList = [];
    //this.selectedMatchMode = this.matchModeOptions[0];
  } else {
    // -- otherweise load existing config into view
    if (!currentConfig.ConvertCaseInfos) return;

    currentConfig.ConvertCaseInfos.map((info) => {
      if (columnListArray.includes(info.ColumnName)) {
        // -- column still exists, do nothing
      } else {
        // column does not exist anymore, meaning column name currently not included -> include
        columnListArray.push(info.ColumnName);
      }
    });
    this.columnList = columnListArray;
          if(meta_infos !== undefined) {
              this.infoList = this.sortInfoToSortInfoView(
                  currentConfig.ConvertCaseInfos,
                  meta_infos[0].FieldsInfo
              );
          }

  }
}
viewToSettings(): ConvertCasePlugIn.Settings {
  const newSettings = <ConvertCasePlugIn.Settings>{
    ...this.getCurrentWorkflowNode().Properties.Configuration,
  };
  const sortInfoViews = this.infoList;
  const sortInfos = this.sortInfoViewToSortInfo(sortInfoViews);

  newSettings.ConvertCaseInfos = sortInfos;

  // this.messageService.standardToastMessage({  severity : 'success',
  //                                                 summary : 'Filter successsfully added',
  //                                                 detail : 'New Filter was successfully added to the node.'});

  return newSettings;
}

onSettingsChanged(settings: ConvertCasePlugIn.Settings) {
  throw new Error("Method not implemented.");
}

infoList: ConvertCaseInfoView[] = [];
columnList: string[] = [];
dropDownConvertCaseMethods: DropDownType[];

readonly InputPort = "Input";

onAddEntry() {
  const myColumnList = this.getCurrentWorkflowNode().Properties.MetaInfo.get(
    this.InputPort
  );

  if (myColumnList === undefined || myColumnList.length === 0) {
    //TODO: Add User notification that no input fields available
    return;
  }

  let newFilter = new ConvertCaseInfoView("",this.dropDownConvertCaseMethods[0], false);
  // newFilter.ColumnName = "";
  // newFilter.SortType = this.dropDownSortMethod[0];
  // newFilter.hasError = false;

  this.infoList.push(newFilter);
  this.sortInfoViewToSortInfo(this.infoList);
  this.onUpdateSettings(false);
}
onDeleteEntry(index: number) {
  this.infoList = [
    ...this.infoList.slice(0, index),
    ...this.infoList.slice(index + 1),
  ];
  this.onUpdateSettings(false);
  // this.messageService.standardToastMessage({  severity : 'warn',
  //                                                 summary : 'Filter deleted',
  //                                                 detail : 'The selected filter was deleted successfully!'});
}
sortInfoToSortInfoView(list: ConvertCasePlugIn.ConvertCaseInfo[], meta?: FieldInfo[]): ConvertCaseInfoView[] {
  let newSortInfosView: ConvertCaseInfoView[] = [];

  list.map((sortInfo: ConvertCasePlugIn.ConvertCaseInfo) => {
    //let newInfoView = new SortInfoView();

    // -- create selected SortMethod from config
    let convert_method;

    for (let i = 0; i < this.dropDownConvertCaseMethods.length; i++) {
      if (sortInfo.ConvertMode == this.dropDownConvertCaseMethods[i].value) {
        convert_method = this.dropDownConvertCaseMethods[i];
      }
    }

    const sort_type_given = Id.assertSet(convert_method, new Error("Sort type not found, should not happen"));

    //newInfoView.ColumnName = sortInfo.ColumnName;

    let fieldInfoFound = meta?.find((field) => {
      return field.Name === sortInfo.ColumnName;
    });

    //newInfoView.hasError = !fieldInfoFound;

    let newInfoView = new ConvertCaseInfoView(sortInfo.ColumnName,sort_type_given,!fieldInfoFound);

    newSortInfosView.push(newInfoView);
  });
  // -- return final view to GUI
  return newSortInfosView;
}
sortInfoViewToSortInfo(sortList: ConvertCaseInfoView[]) {
  // -- create new empty array
  let newSortInfos: ConvertCasePlugIn.ConvertCaseInfo[] = [];

  sortList.map((entry) => {
    let newSettings = new ConvertCasePlugIn.ConvertCaseInfo(entry.ColumnName, entry.ConvertMode.value);
    newSettings.ColumnName = entry.ColumnName;
    newSettings.ConvertMode = entry.ConvertMode.value;
    newSortInfos.push(newSettings);
  });
  return newSortInfos;
}

constructor(
  protected workflowService: WorkflowsService,
  protected designerService: DesignerService
) {
  super(workflowService, designerService);
  this.dropDownConvertCaseMethods = [
    { name: "Capitalize all", value: 1 },
    { name: "Uncapitalize all", value: 2 },
    { name: "Capitalize each words first letter", value: 3 },
    { name: "Swapcase", value: 4 },
  ];
}

ngOnInit(): void {
  super.ngOnInit();
}

ngOnDestroy(): void {}
  onFocusLost(event: FocusEvent) {
      const div_left: boolean = (event.relatedTarget === null);

      console.log("Send from Div: ", div_left);

      if (div_left) {
          //this.onCheckDirtyFlag();
          this.onUpdateSettings(true);
      }
  }
}
