
/**
 * Helper to serialize backend types which have no common interface.
 */
export class DynamicItemInfo {

    constructor(type:string, data:any) {
        this.Type = type;
        this.Data = data;
    }

    Type: string;
    Data: any;
}