import { EventEmitter, Injectable, Output } from '@angular/core';
import { DropDownModel } from '../views/objectManagement/general-search/drop-down-model';
import { FilterProvider } from '../views/objectManagement/general-search/filter-provider';

@Injectable({
  providedIn: 'root'
})
export class ObjectSearchService {

  @Output() objectSearchParamsEmitter = new EventEmitter<[Map<string,DropDownModel>, Map<string, FilterProvider<any>>]>();
  @Output() emitChangedFilteredObjectListEmitter = new EventEmitter<any[]>();

  constructor() { }

  emitSearchParams(params: any, filters: Map<string, any>) {
    this.objectSearchParamsEmitter.emit([params,filters]);
  }
  emitChangedFilteredObjectList(objViewList: any[]) {
    this.emitChangedFilteredObjectListEmitter.emit(objViewList);

  }
}
