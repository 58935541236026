<p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>


<p-dialog header="{{'ChangeWriteModeHeader' | translate}}" [(visible)]="displayChangeWriteMode" modal="modal"
    showEffect="fade" [style]="{width: '600px'}" >
    <div style="line-height: 1.5">
        {{'ChangeWriteModeText' | translate}}
    </div>
    <div class="p-grid p-formgrid">
        <div class="p-col-12 p-mb-2 p-lg-12 p-mb-lg-0">
            <p-dropdown placeholder="Select a mode" appendTo="body" [showClear]="false" [options]="writeModeOptions"
                (onChange)="selectWriteModeOption($event)" [(ngModel)]="selectedWriteModeOption" optionLabel="name">
            </p-dropdown>
        </div>
    </div>
    <app-system-message-log></app-system-message-log>
    <p-footer>
        <button pButton pRipple type="button" icon="pi pi-times" (click)="displayChangeWriteMode=false"
            class="p-button-rounded p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'No' | translate}}"></button>
        <button pButton pRipple type="button" icon="pi pi-check" (click)="updatePsaWriteMode()"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2"
            label="{{'Yes' | translate}}" [disabled]="buttonDisabled" [icon]="savingInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'"></button>
    </p-footer>
</p-dialog>
