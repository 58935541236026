export namespace GenderCheckPlugIn {
    export class GenderCheckInfo {
        ColumnName: string;
        constructor(ColumnName: string) {
            if(ColumnName === undefined) throw new Error( "Class 'GenderCheckInfo': Required value 'ColumnName' is undefined" );
            this.ColumnName = ColumnName;
        }
    }
    export class Settings {
        GenderCheckInfos: Array<GenderCheckInfo>;
        ApiKey: string;
        SendingActive: boolean;
        constructor(GenderCheckInfos: Array<GenderCheckInfo>,ApiKey: string,SendingActive: boolean) {
            if(GenderCheckInfos === undefined) throw new Error( "Class 'Settings': Required value 'GenderCheckInfos' is undefined" );
            if(ApiKey === undefined) throw new Error( "Class 'Settings': Required value 'ApiKey' is undefined" );
            if(SendingActive === undefined) throw new Error( "Class 'Settings': Required value 'SendingActive' is undefined" );
            this.GenderCheckInfos = GenderCheckInfos;
            this.ApiKey = ApiKey;
            this.SendingActive = SendingActive;
        }
    }
}