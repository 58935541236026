import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { WorkflowRepositoryEntry } from 'src/app/models/api/models/workflow/WorkflowRepositoryEntry';
import { WorkflowActionEvent, WorkflowDialogActionType } from 'src/app/models/dialog-actions.model';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-import-workflows-dialog',
  templateUrl: './import-workflows-dialog.component.html',
  styleUrls: ['./import-workflows-dialog.component.scss']
})
export class ImportWorkflowsDialogComponent implements OnInit {
  subs = new SubSink;
  dialogEvent?: WorkflowActionEvent;
  displayDialog: boolean = false;
  header: string = "";
  savingInProgress: boolean = false;
  workflowEntries: WorkflowRepositoryEntry[] = [];

  constructor(private workflowService: WorkflowsService, private errorService: SystemMessageLogService, private messageService: MessageService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.subs.sink = this.workflowService.workflowDialogActionSendEmitter.subscribe(wfActionEvent => {
      if(wfActionEvent.actionType === WorkflowDialogActionType.importWorkflows) {
        this.dialogEvent = wfActionEvent;
        this.displayDialog = wfActionEvent.display;
        this.header = wfActionEvent.header

      }
    },(err:Error) => {
      this.errorService.handleError(err)
    })
  }


  onBasicUploadAuto(event: any) {
    const file = event.files[0];
    const reader = new FileReader();
    
    reader.onload = (e: any) => {
      try {
        const jsonData = JSON.parse(e.target.result);
  
        if (!Array.isArray(jsonData)) {
          //this.showMessage('Invalid JSON data. The uploaded file should contain an array.');
          this.messageService.add({
            severity: "warn",
            summary: this.translate.instant("Invalid JSON data"),
            detail: this.translate.instant("Invalid JSON data. The uploaded file should contain an array."),
          });
          return;
        }
  
        const workflowEntries = jsonData as WorkflowRepositoryEntry[];
  
        // Store the workflow entries in the global variable
        this.workflowEntries = workflowEntries;
        console.log(workflowEntries)
  
        //this.showMessage('Uploaded file is a valid file! Ready for import');
        this.messageService.add({
          severity: "success",
          summary: this.translate.instant("Message.ImportValidWorkflowSuccess.Title"),
          detail: this.translate.instant("Message.ImportValidWorkflowSuccess.Text"),
        });
  
      } catch (error) {
        //this.showMessage('Error parsing JSON file.');
        this.messageService.add({
          severity: "warn",
          summary: this.translate.instant("Message.ImportParseError.Title"),
          detail: this.translate.instant("Message.ImportParseError.Text"),
        });
      }
    };
  
    reader.readAsText(file);
  }
  
  
  // showMessage(message: string) {
  //   this.messageService.add({ severity: 'error', summary: 'File Upload', detail: message });
  // }
  
  onRemoveFile(event:any) {
    this.workflowEntries = [];
  }
  importWorkflows() {
    const entries = this.workflowEntries;
    this.workflowService.importWorkflows(entries).subscribe((res) => {
      console.log("Successfully imported workflows");
      this.savingInProgress = false;
      this.displayDialog = false;
      this.workflowService.workflowsChangedEmitter.emit("Workflow Changed");

      this.messageService.add({
        severity: "success",
        summary: this.translate.instant("Message.ImportWorkflowSuccess.Title"),
        detail: this.workflowEntries.length + this.translate.instant("Message.ImportWorkflowSuccess.Text"),
      });

    },(err) => {
      this.errorService.handleError(err);
      this.savingInProgress = false;
    })

  }
}
