import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DestinationTypeInfo } from 'src/app/models/api/controllers/connect/DestinationTypeInfo';
import { AdapterTypeInfo } from 'src/app/models/connector.model';
import { DestinationView } from 'src/app/models/destination-view.model';
import { CsvUrlDestinationView } from 'src/app/models/destinations/csv-url.model';
import { PostgreDestinationView } from 'src/app/models/destinations/postgre-destination-view.model';
import { DestinationService } from 'src/app/services/destinationService';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { SubSink } from 'subsink';
//import { DialogProvider } from '../../dialogs/create-destination-dialog/create-destination-dialog.component';

@Component({
  selector: 'app-choose-destination',
  templateUrl: './choose-destination.component.html',
  styleUrls: ['./choose-destination.component.scss'],
  animations: [trigger("fade", [
    state("void", style({ opacity: 0 })),
    transition(":enter", [animate(300)]),
    transition(":leave", [animate(500)]),
  ]),]
})
export class ChooseDestinationComponent<T, C> implements OnInit {
  subs = new SubSink;
  destinationCategories = ["File", "Database", "API"];
  targetTypeInfoList: DestinationTypeInfo<any, any>[] = [];
  filteredTargetTypeInfoList: DestinationTypeInfo<any, any>[] = [];
  selectedTargetTypeInfo?: DestinationTypeInfo<any, any>;
  //savingInProgress: boolean = false;
  @ViewChild('dv') dv;

  //actionEventEmitter = new EventEmitter<any>();
  //disableInputField: boolean = true;

  currentDestinationView?: DestinationView<any, any, any>;
  DestinationViewMap: Map<string, DestinationView<any, any, any>> = new Map();

  @Output() currentDestinationViewEmitter = new EventEmitter<DestinationView<any, any, any>>();


  // GUI Controls -------------------------------
  // sortOptions: SelectItem[] = [];
  // sortOrder: number = 1;
  // sortField: string;

  // onSortChange(event) {
  //   let value = event.value;

  //   if (value.indexOf('!') === 0) {
  //     this.sortOrder = -1;
  //     this.sortField = value.substring(1, value.length);
  //   }
  //   else {
  //     this.sortOrder = 1;
  //     this.sortField = value;
  //   }
  // }
  onSelectCategory(event) {
    console.log(event);
    const selectedCategories = event.value;
    console.log(this.dv);

    if(selectedCategories.length === 0) {
      this.filteredTargetTypeInfoList = this.targetTypeInfoList;
    } else {
      const filteredDestinations = this.targetTypeInfoList.filter((dest: DestinationTypeInfo<any,any>) => {return selectedCategories.includes(dest.Category)});
      console.log(filteredDestinations);
      this.filteredTargetTypeInfoList = filteredDestinations;
    }

  }


  /**
* Creates the adapter/connector view from the given adapter type information and preset with initial settings.
* @param adapter Adapter Information
* @returns Connector View
*/
  protected buildAdapterViewModel(adapter: AdapterTypeInfo<any, any>): DestinationView<any, any, any> {
    const viewModel = this.createAdapterViewModel(adapter.Name, adapter.ID);

    viewModel.setInitialSettings(adapter.Info.InitialExtractArg);
    viewModel.setInitialMetaSettings(adapter.Info.InitialMetaRequestArg);

    return viewModel;
  }

  createAdapterViewModel(name: string,id:number): DestinationView<any, any, any> {

    // if (name === this.EXCEL_ADAPTER) return new ExcelDestinationView(this.bionApi, name);
    // if (name === this.CSV_ADAPTER) return new CsvDestinationView(this.bionApi, name);
    // if (name === this.JDBC_ADAPTER) return new JdbcDestinationView(this.bionApi, name);
    if (name === this.POSTGRE_ADAPTER) return new PostgreDestinationView(this.destinationApi, name, id);
    if (name === this.CSV_URL_ADAPTER) return new CsvUrlDestinationView(this.destinationApi, name, id);

    // file:///C:/Users/Farin/IdeaProjects/BionPlayDataWarehouse/test/out/csv_real_dest_test.csv

    throw new Error("No view found for this adapter:" + name);
  }


  /**
 * UI Callback: Adapter/Connector Selected
 * @param adapter Adapter/Connector
 */
  onDestinationSelected(adapter: DestinationTypeInfo<any, any>) {

    // if the view already exists THEN use it ELSE create a new and add it to the view map END

    const adapterViewModelObs = this.getOrBuildViewModel(adapter);

    adapterViewModelObs.subscribe(av => {

      const a = av[0];
      const viewModel = av[1];

      this.setCurrentDestinationView(viewModel);
      this.selectedTargetTypeInfo = a;
    });
  }

  /**
 * Sets the current connector view and emits an event.
 * @param viewModel Connector View Model
 */
  protected setCurrentDestinationView(viewModel: DestinationView<any, any, any>) {
    this.currentDestinationView = viewModel;
    this.currentDestinationViewEmitter.emit(this.currentDestinationView);
  }

  /**
 * Get the view model for the given adapter or create one, if it does not exist already
 * @param adapter Adapter Type Info
 * @returns The View Model
 */
  protected getOrBuildViewModel(adapter: DestinationTypeInfo<any, any>): Observable<[DestinationTypeInfo<any, any>, DestinationView<any, any, any>]> {

    let init_obs: Observable<[DestinationTypeInfo<any, any>, DestinationView<any, any, any>]>;

    let viewModel: DestinationView<any, any, any> = undefined;

    if (this.DestinationViewMap.has(adapter.Name)) {
      viewModel = this.DestinationViewMap.get(adapter.Name);
      init_obs = of([adapter, viewModel]);
    } else {
      viewModel = this.createAdapterViewModel(adapter.Name, adapter.ID);
      init_obs = viewModel.initialize().pipe(map(init_res => {
        viewModel.setInitialSettings(adapter.Info.InitialExtractArg);
        viewModel.setInitialMetaSettings(adapter.Info.InitialMetaRequestArg);
        return [adapter, viewModel];
      }));
    }

    const final_obs = init_obs.pipe(map(av => {
      const a = av[0];
      const v = av[1];
      this.DestinationViewMap.set(a.Name, v);
      return av;
    }));

    return final_obs;
  }


  readonly CSV_URL_ADAPTER: string = "CSV URI";
  readonly EXCEL_ADAPTER: string = "Excel Adapter";

  readonly JDBC_ADAPTER: string = "Jdbc";
  readonly DB2_ADAPTER: string = "DB2";
  readonly H2_EMBEDDED_ADAPTER: string = "H2 Embedded";
  readonly H2_SERVER: string = "H2 Server";
  readonly HSQL_EMBEDDED_ADAPTER: string = "HSQL Embedded";
  readonly HSQL_SERVER_ADAPTER: string = "HSQL Server";
  readonly MARIA_DB_ADAPTER: string = "Maria DB";
  readonly POSTGRE_ADAPTER: string = "PostgreSql";
  readonly REDSHIFT_ADAPTER: string = "Redshift";

  constructor(private destinationApi: DestinationService, private logService:SystemMessageLogService) { }

  ngOnInit(): void {

    this.subs.sink = this.destinationApi.getDestinationTypeInfo().subscribe(
      items => {
      this.targetTypeInfoList = items;
      this.filteredTargetTypeInfoList = [...this.targetTypeInfoList];
    }, (error:Error) => {
      this.logService.handleError(error);
    })
  }
}