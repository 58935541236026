import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { DatasourcesService } from "src/app/services/datasources.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { SubSink } from "subsink";
import * as dss from "../../../../models/datasource.model";

export interface IndexedFieldInfo extends dss.FieldInfoLike {
	ColumnIndex: number;
}

@Component({
	selector: "app-create-new-datasource",
	templateUrl: "./create-new-datasource.component.html",
	styleUrls: ["./create-new-datasource.component.scss"],
})
export class CreateNewDatasourceComponent implements OnInit, OnDestroy, OnChanges {
	subs = new SubSink();
	savingInProgress: boolean = false;
	selectFormGroup?: UntypedFormGroup; // (3) FormGroup for Column & PrimaryKey Selection

	dataTypes: dss.DataType[] = [];

	@Input() dataPreviewColumnClone: dss.FieldInfo[] = [];
	@Input() dataPreviewColumns: dss.FieldInfo[] = [];
	@Input() dataPreviewRows: any[] = [];

	@Input() previewColumns: any[] = [];
	@Input() previewRows: any[] = [];

	//dataPreviewRowsSelected:any[];
	@Output() DataSourceFieldsEmitter = new EventEmitter<dss.DataSourceField[]>();
	//LoadDataFlag: boolean = true;

	constructor(
		private datasourceService: DatasourcesService,
		private formBuilder: UntypedFormBuilder,
		private errorService: SystemMessageLogService
	) { }
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}
	ngOnChanges(changes: SimpleChanges) {
		if (changes.previewColumns) {
			this.previewColumns = changes.previewColumns.currentValue;
		}
	}
	ngOnInit(): void {
		this.selectFormGroup = this.formBuilder.group(
			{
				PRIMARY: [{ value: [], disabled: false }],
				COLUMNSCOPE: [{ value: [], disabled: false }],
			},
			{}
		);
		this.subs.sink = this.datasourceService.getDataTypes().subscribe((res) => {
			this.dataTypes = res;
		}, (err: Error) => {
			this.errorService.handleError(err);
		});
		this.dataPreviewColumnClone = this.dataPreviewColumns;
		this.selectFormGroup.value.COLUMNSCOPE = this.dataPreviewColumns;
		this.DsFieldsSelectionToMapping();
	}

	initialFieldMapping: dss.FieldMappingEntry[] = [];
	DsFieldsSelectionToMapping() {
		this.DataSourceFieldsEmitter.emit(this.selectFormGroup?.value.COLUMNSCOPE);
	}
}