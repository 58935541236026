import { Component, OnInit } from '@angular/core';
import { map, mergeMap } from 'rxjs/operators';
import { IntegratedSourceModel } from 'src/app/models/api/models/IntegratedSourceModel';
import { ActivatedRoute, Router } from '@angular/router';
import { IntegratedSourceService } from 'src/app/services/integrated-source.service';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-get-streams-int-page',
  templateUrl: './get-streams-int-page.component.html',
  styleUrls: ['./get-streams-int-page.component.scss']
})
export class GetStreamsIntPageComponent implements OnInit {

  constructor(private service_api: IntegratedSourceService, private router: Router, private route: ActivatedRoute) { }

  // -- API
  write_mode_infos: IntegratedSourceModel.GenCatalog.WriteModeInfo[] = [];
  new_generic_catalog?: IntegratedSourceModel.GenCatalog;
  get_catalog_result?: IntegratedSourceModel.GetStreamsResult = undefined;
  source_key?: IntegratedSourceModel.DataSourceKey<number>;

  // -- UI
  isLoading: boolean = false;
  progressMode: string = "determinate";
  messages: Message[] = [];

  ngOnInit(): void {
    this.source_key = this.getUrlContext();
    const source_key = this.source_key;

    const get_cat_arg = new IntegratedSourceModel.GetStreamsArg(source_key);
    const cat_obs = this.service_api.getStreams(get_cat_arg);

    const write_mode_obs = this.service_api.getWriteModeInfos();

    const cat_and_write_modes = cat_obs.pipe(mergeMap(cat => write_mode_obs.pipe(map(wm => [cat, wm]))));

    this.setLoading(true);

    cat_and_write_modes.subscribe(cat_and_write => {

      const pair: [IntegratedSourceModel.GetStreamsResult, IntegratedSourceModel.GenCatalog.WriteModeInfo[]] =
        <[IntegratedSourceModel.GetStreamsResult, IntegratedSourceModel.GenCatalog.WriteModeInfo[]]>cat_and_write;

      const cat = pair[0];
      this.write_mode_infos = pair[1];

      this.get_catalog_result = cat;
      this.new_generic_catalog = this.get_catalog_result.Catalog;
    },
      err => this.handle_error(err),
      () => this.setLoading(false)
    )
  }

  setLoading(loading: boolean): void {
    this.isLoading = loading;

    // https://www.primefaces.org/primeng-v14-lts/progressbar

    if (this.isLoading) {
      this.progressMode = "indeterminate";
    } else {
      this.progressMode = "determinate";
    }
  }

  handle_error(e: Error) {
    this.messages.push({ severity: 'error', summary: 'Error', detail: e.message });
    console.error(e);
    this.setLoading(false);
  }

  /**
 * Der Datasource Schlüssel aus der Route.
 */
  getUrlContext(): IntegratedSourceModel.DataSourceKey<number> {
    const arr = this.router.url.split('/');
    const id = parseInt(arr.last());
    const origin = arr.getRight(1);
    console.log("Data Source ID: " + id);
    console.log("Data Source Origin: " + origin);
    return new IntegratedSourceModel.DataSourceKey(id, origin);
  }

  edit_streams_click(event:any) {

    const key = this.getUrlContext();

    this.router.navigate(['/', 'SourceIntegrationUpdateStreams', key.Origin, key.Id]);
  }

  back_to_source_click(event:any) {
    const key = this.getUrlContext();

    this.router.navigate(['/', 'SourceIntegrationSource', key.Origin, key.Id]);
  }
}
