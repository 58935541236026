import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import {
	ChangeDetectorRef,
	Component,
	Input,
	OnInit,
} from "@angular/core";
import { TreeNode } from "primeng/api/treenode";
import {
	AuthMode,
	MetaPreview,
	RestApiMetaAccess,
	RestApiMetaInfo,
} from "src/app/models/connector.model";
import { RestApiConnectorView } from "src/app/models/connectors/rest-api-connector-view.model";
import {
	DataSourceAdapter,
} from "src/app/models/datasource.model";
import { PlayErrorResponse } from "src/app/models/play.error.model";
import { ApiBackendService } from "src/app/services/api-backend.service";

@Component({
	selector: "app-rest-api-connector",
	templateUrl: "./rest-api-connector.component.html",
	styleUrls: ["./rest-api-connector.component.scss"],
})
export class RestApiConnectorComponent implements OnInit {

	selectedConnector: DataSourceAdapter;
	loading: boolean;
	@Input() currentConnectorView: RestApiConnectorView;

	jsonPreviewCols: any;
	jsonPreviewData: any;

	jsonHierarchy: TreeNode[];
	selectedJsonHierarchy: TreeNode;

	selectedReadMode: string;
	selectedNestedObjectsMode: string;

	testt() {
		this.currentConnectorView.metaInfo.AuthModes
		this.currentConnectorView.connectorSettings.Query.NestedObjectsMode
		this.currentConnectorView.connectorSettings.Query.Path
	}

	handleError(error: HttpErrorResponse) {
		const play_error: PlayErrorResponse = error.error;
		console.log(play_error);
	}

	constructor(private bionApi: ApiBackendService, private cd: ChangeDetectorRef, private http: HttpClient) { }

	on_preview(event) {

		console.log("Preview");
		const cur_view = this.currentConnectorView;
		cur_view.connectorSettings.AuthMode = this.buildAuth();
		const meta_access = new RestApiMetaAccess(
			new MetaPreview(cur_view.connectorSettings.URL, cur_view.connectorSettings.AuthMode));

		this.currentConnectorView.metaAccess = meta_access;

		this.loading = true;
		this.currentConnectorView.fetchMetaDataObs().subscribe(
			(meta_info) => {

				this.metaInfoToView(meta_info, this.currentConnectorView);

				const tree_data = <TreeNode[]>[meta_info.Json];
				this.jsonHierarchy = tree_data;

				const new_tree_data = this.toTreeNodes(meta_info.Json);
				console.log(new_tree_data);
				this.jsonHierarchy = new_tree_data;


				this.cd.detectChanges();
				this.loading = false;
			},
			(error: HttpErrorResponse) => {
				this.handleError(error);
				this.loading = false;
			}
		);

	}

	//bearerToken:string = "";
	//bearerAuth:BearerAuth = undefined;

	buildAuth() : AuthMode {

		return this.currentConnectorView.connectorSettings.AuthMode;

		// const auth_type_name = this.currentConnectorView.connectorSettings.AuthMode._type;
		// if(!auth_type_name) {

		// }

		// const target_mode = this.currentConnectorView.authModes.find(m => m._type === auth_type_name)
		// if(target_mode === undefined)
		// 	throw new Error("The auth type " + auth_type_name + " is unknown");

		// return target_mode;
	}

	/**
	 * Write the Meta Info into the component view.
	 *
	 * If no auth mode is available, the first from the meta info is taken as default, otherwise
	 * the existing is used.
	 *
	 * @param meta_info Fresh Meta Information
	 * @param cur_view The current connector view model
	 */
	metaInfoToView(meta_info:RestApiMetaInfo, cur_view:RestApiConnectorView) {
		console.log("Meta fetched");
		//const cur_view = this.currentConnectorView;

		if(cur_view.metaInfo.AuthModes.length > 0) {
			if(cur_view.connectorSettings.AuthMode === undefined) {
				cur_view.connectorSettings.AuthMode = cur_view.metaInfo.AuthModes[0];
			} else {
				for(let i = 0; i < cur_view.authModes.length; i++) {
					if(cur_view.authModes[i]._type === cur_view.connectorSettings.AuthMode._type) {
						cur_view.authModes[i] = cur_view.connectorSettings.AuthMode;
					}
				}
			}
		}
	}

	ngOnInit(): void {
		console.log("ngOnInit");
		this.loading = true;
		this.currentConnectorView.fetchMetaDataObs().subscribe(
			(meta_info) => {

				this.metaInfoToView(meta_info, this.currentConnectorView);
				this.currentConnectorView.authModes = meta_info.AuthModes;

				const cur_view = this.currentConnectorView;

				if(cur_view.metaAccess.Preview === undefined && cur_view.connectorSettings.Query) {
					cur_view.metaAccess.Preview.AuthMode = cur_view.connectorSettings.AuthMode;
					cur_view.metaAccess.Preview.URL = cur_view.connectorSettings.URL

				}

				//this.currentConnectorView.bearerAuth = <BearerAuth>this.currentConnectorView.authModes.find(am => am._type === "com.bion.connect.rest.BearerAuth");

				this.cd.detectChanges();
				this.loading = false;
			},
			(error: HttpErrorResponse) => {
				this.handleError(error);
				this.loading = false;
			}
		);
	}

	toTreeNodes(value: any): TreeNode[] {

		if (value instanceof Object) return this.toTreeNodeObj(<Object>value);

		if (value instanceof Array) return this.toTreeNodeArray(<Array<any>>value);

		throw new Error("Only objects and arrays are supported");
	}

	toTreeNodeObj(value: Object) {

		let nodes = new Array<TreeNode>();

		for (let attr in value) {

			const attr_value = value[attr];

			let children = undefined;
			try {
				children = this.toTreeNodes(attr_value);
			} catch (e) {
				console.log("No more sub tree -> stopping!");
			}


			const node = {
				label: attr,
				data: attr_value,
				expandedIcon: "pi pi-folder-open",
				collapsedIcon: "pi pi-folder",
				children: children
			}

			nodes.push(node);

		}

		return nodes;
	}

	toTreeNodeArray(value: Array<any>): TreeNode[] {

		let nodes = new Array<TreeNode>();

        let i = 0;
        const len = value.length;
        for (; i < len; i++) {

			const v = value[i];
			const children = this.toTreeNodes(v);

			const new_label = i.toString();

			const node = {
				label: new_label,
				data: v,
				expandedIcon: "pi pi-folder-open",
				collapsedIcon: "pi pi-folder",
				children: children
			}

			nodes.push(node);
		}

		return nodes;
	}

	setJsonView() {

		if (this.currentConnectorView.connectorSettings.Query) {
			const q = this.currentConnectorView.connectorSettings.Query;
			q.ReadMode = (this.selectedReadMode === undefined) ? undefined : this.selectedReadMode;
			q.NestedObjectsMode = (this.selectedNestedObjectsMode === undefined) ? undefined : this.selectedNestedObjectsMode;
		}

		//this.currentConnectorView.connectorSettings.ReadMode = (this.selectedReadMode === undefined) ? undefined : this.selectedReadMode;
		//this.currentConnectorView.connectorSettings.NestedObjectsMode = (this.selectedNestedObjectsMode === undefined) ? undefined : this.selectedNestedObjectsMode;

		console.log(this.currentConnectorView);
	}

	onTreeTest(event: PointerEvent) {
		console.log(event);
		this.getFiles().subscribe(data => {
			const tree_data = <TreeNode[]>data.data;
			this.jsonHierarchy = tree_data;
		})
	}

	onNodeSelect(event: PointerEvent) {
		console.log(event);

		const path = this.makeJsonPathFromEvent(event);
		console.log(path);

		const reverse_path = path.reverse();      // Query needs TOP DOWN path

		const query = this.makeQueryFromPath(reverse_path);
		console.log(query);

		const q = this.currentConnectorView.connectorSettings.Query;
		if(q) {
			q.Path = query;
		}
		//this.currentConnectorView.connectorSettings.Query = query;
	}

	/**
	 * Builds a query string from the given node path.
	 * @param nodes Nodes in path. Must be given in TOP DOWN order.
	 * @returns The query string
	 */
	makeQueryFromPath(nodes: Array<TreeNode>): string {

		const node_part = nodes.map(n => "." + n.label).reduce((a, b) => a + b);

		const query = "$" + node_part + "[*]";

		return query;
	}

	/**
	 * Creates the BOTTOM-UP Path from a clicked node
	 * @param event Event
	 * @returns The path from the clicked node to the root
	 */
	makeJsonPathFromEvent(event: PointerEvent): TreeNode[] {

		const start_node = <TreeNode>event["node"];
		console.log(start_node);

		return this.makeJsonPathFromNode(start_node);
	}

	/**
   * Creates the BOTTOM-UP Path from a node
   * @param event Event
   * @returns The path from the node to the root
   */
	makeJsonPathFromNode(node: TreeNode): TreeNode[] {

		if (node == undefined)
			return new Array<TreeNode>();
		else {
			const arr = new Array<TreeNode>(node);
			return arr.concat(this.makeJsonPathFromNode(node.parent));
		}
	}

	getFiles() {
		return this.http.get<any>('./assets/data/testdata.json');
	}
}
