<div class="p-grid" style="width:100%; margin-top:10px">
    <div class="p-col-12 p-md-9">
        <div class="p-fluid">
            <span class="p-float-label">
                <input type="text" id="Hostinputtext" pInputText
                    [(ngModel)]="currentConnectorView.connectorSettings.ConnectionSettings.Host">
                <label for="Hostinputtext">{{'Host' | translate}}</label>
            </span>
        </div>
    </div>
    <div class="p-col-12 p-md-3">
        <div class="p-fluid">
            <span class="p-float-label">
                <input type="number" id="Portinputtext" pInputText
                    [(ngModel)]="currentConnectorView.connectorSettings.ConnectionSettings.Port">
                <label for="Portinputtext">{{'Port' | translate}}</label>
            </span>
        </div>
    </div>

    <div class="p-col-12 ">
        <div class="p-fluid">
            <span class="p-float-label">
                <input type="text" id="DBinputtext" pInputText
                    [(ngModel)]="currentConnectorView.connectorSettings.ConnectionSettings.Database">
                <label for="DBinputtext">{{'Database' | translate}}</label>
            </span>
        </div>
    </div>

    <div class="p-col-12 p-md-6">
        <div class="p-fluid">
            <span class="p-float-label">
                <input type="text" id="Userinputtext" pInputText
                    [(ngModel)]="currentConnectorView.connectorSettings.ConnectionSettings.User">
                <label for="Userinputtext">{{'Username' | translate}}</label>
            </span>
        </div>
    </div>
    <div class="p-col-12 p-md-6">
        <div class="p-fluid">
            <span class="p-float-label">
                <input type="password" id="Passwordinputtext" pInputText
                    [(ngModel)]="currentConnectorView.connectorSettings.ConnectionSettings.Password">
                <label for="Passwordinputtext">{{'Password' | translate}}</label>
            </span>
        </div>
    </div>

    <div class="p-col-12 p-md-6">
        <div class="p-fluid">
            <span class="p-float-label">
                <button pButton type="button" id="connect" label="{{'Connect' | translate}}" pInputText
                    (click)="onConnect($event)"></button>
            </span>
        </div>
    </div>

    <div class="p-col-12 p-md-6">
        <div class="p-fluid">
            <span class="p-float-label">
                <input type="text" id="Statusinputtext" [disabled]=true pInputText [(ngModel)]="status_text">
                <label for="Statusinputtext">{{'Status' | translate}}</label>
            </span>
        </div>
    </div>
    <div class="p-col-12  p-md-12">
        <p-divider layout="horizontal" align="center">
            <span class="p-tag">{{'Choose table' | translate}}</span>
        </p-divider>
    </div>

    <div class="p-col-12  p-md-12">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown [options]="currentConnectorView.metaInfo.Tables"
                    [(ngModel)]="currentConnectorView.connectorSettings.Table" editable="true"></p-dropdown>
                <!-- <input type="text" id="inputtext" pInputText [(ngModel)]="currentConnectorView.dbtable"> -->
                <label for="inputtext">{{'Table' | translate}}</label>
            </span>
        </div>
    </div>



</div>
