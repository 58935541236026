import { Component, OnInit } from '@angular/core';
import { Sources } from 'src/app/models/airbyte-api-structures';
import { AirbyteModel } from 'src/app/models/api/models/AirbyteModel';
import { AirbyteStagingService } from 'src/app/services/airbyte-staging.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-view-ab-sources',
  templateUrl: './view-ab-sources.component.html',
  styleUrls: ['./view-ab-sources.component.scss']
})
export class ViewAbSourcesComponent implements OnInit {

  constructor(private airbyteService: AirbyteStagingService, private _sanitizer: DomSanitizer) { }

  isLoading: boolean = false;
  progressMode: string = "determinate";
  infos: AirbyteModel.SourceInfo<Sources.Source>[] = [];

  ngOnInit(): void {

    console.log("SVG laden geht noch nicht. Liegt wohl am Sicherheitsmodell. Siehe Link im Code");
    // https://stackoverflow.com/questions/53066823/how-do-i-import-svg-from-file-to-a-component-in-angular-5

    this.setLoading(true);
    this.airbyteService.sourceInfos(undefined, undefined, true).subscribe(
      infos => { this.infos = infos; },
      err => this.handleError(err),
      () => this.setLoading(false))
  }

  setLoading(loading: boolean): void {
    this.isLoading = loading;

    // https://www.primefaces.org/primeng-v14-lts/progressbar

    if (this.isLoading) {
      this.progressMode = "indeterminate";
    } else {
      this.progressMode = "determinate";
    }
  }

  handleError(err: Error, message?: any) {
    this.setLoading(false);
    if (message !== undefined)
      console.log(message);

    console.log(err);
  }

  getSVGImageUrl(image) {
    console.log("SVG to Image conversion is inefficient. Try to render SVG directly")
    let base64string = btoa(image);
    return this._sanitizer.bypassSecurityTrustResourceUrl(
      `data:image/svg+xml;base64,${base64string}`
    );
  }
}