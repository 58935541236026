import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

import { interval } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { AppMainComponent } from 'src/app/app.main.component';
import { Id } from 'src/app/helper/id';
import { TaskStatus } from 'src/app/models/api/controllers/scheduler/TaskStatus';
import { TaskJobModel } from 'src/app/models/api/models/staging/TaskJobModel';
import { GuiErrorInfo } from 'src/app/models/gui.error.model';
//import { TaskStatus } from 'src/app/models/schedule.model';
import { SchedulesService } from 'src/app/services/schedules.service';
// import { JobStatusInfo } from 'src/app/models/staging/LoadDataModel';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { TasksService } from 'src/app/services/tasks.service';
import { SubSink } from 'subsink';

@Component({
	selector: 'app-operations-overview',
	templateUrl: './operations-overview.component.html',
	styleUrls: ['./operations-overview.component.scss'],
	providers: [MessageService]
})
export class OperationsOverviewComponent implements OnInit, OnDestroy {
	subs = new SubSink();
	extractDataSourceTaskInfos: TaskJobModel.JobStatusInfo<any>[] = [];
	workflowTaskInfos: TaskJobModel.JobStatusInfo<any>[] = [];
	scheduleTaskStates: TaskStatus[] = [];

	taskInfoQueue: TaskJobModel.JobStatusInfo<any>[][] = [];

	GuiErrorInfo: GuiErrorInfo[] = [];
	selectedGuiErrorInfo?: GuiErrorInfo;


	constructor(
		public appMain: AppMainComponent,
		private taskService: TasksService,
		private scheduleService: SchedulesService,
		private errorService: SystemMessageLogService,
		public translate: TranslateService,
		public messageService: MessageService
	) { }
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngOnInit(): void {
		this.subs.sink = interval(5000)
			.pipe(
				startWith(0),
				switchMap(() => this.taskService.ExtractDataToPsa_jobs_status())
			)
			.subscribe(
				(result) => {
					console.log("Updating Tasks", result);
					let taskInfosWithJobs = this.addProgressPercentageToJobInfo(result);

					let extractDataSourceTasks = taskInfosWithJobs.filter((taskInfo) => {
						return taskInfo.OriginInfo.SimpleName == "ExtractDataToPsa";
					});
					let executeWorkflowTasks = taskInfosWithJobs.filter((taskInfo) => {
						return taskInfo.OriginInfo.SimpleName == "FutureWorkflowEngine";
					});
					this.extractDataSourceTaskInfos = extractDataSourceTasks;
					this.workflowTaskInfos = executeWorkflowTasks;

					this.checkJobsChanged(taskInfosWithJobs);
				},
				(err: Error) => {
					this.errorService.handleError(err);
				}
			);
		this.subs.sink = interval(5000)
			.pipe(
				startWith(0),
				switchMap(() => this.scheduleService.getSchedulerStatus())
			)
			.subscribe(
				(scheduleStatus) => {
					console.log("Updating Schedule Tasks", scheduleStatus.TaskStates);
					//let taskInfosWithJobs = this.addProgressPercentageToJobInfo(result);
					this.scheduleTaskStates = scheduleStatus.TaskStates;

					this.checkScheduleJobsChanged(scheduleStatus.TaskStates);
				},
				(err: Error) => {
					this.errorService.handleError(err);
				}
			);
	}

	/**
	 * Checks each jobInfo if job has changed
	 * @param jobStates
	 * @returns
	 */
	checkJobsChanged(jobStates: TaskJobModel.JobStatusInfo<any>[]) {
		this.taskInfoQueue.push(jobStates);
		// Create Queue Array and scope the last two entries only
		if (this.taskInfoQueue.length === 1) {
			return;
		} else {
			this.taskInfoQueue = this.taskInfoQueue.slice(this.taskInfoQueue.length - 2, this.taskInfoQueue.length);
		}

		let oldJobs = this.taskInfoQueue[0];
		let newJobs = this.taskInfoQueue[1];

		let oldJobsMap = new Map<string, TaskJobModel.JobStatusInfo<any>>();

		for (let old of oldJobs) {
			oldJobsMap.set(old.Job, old);
		}

		let newJobsMap = new Map<string, TaskJobModel.JobStatusInfo<any>>();

		for (let n of newJobs) {
			newJobsMap.set(n.Job, n)
		}

		for (let newMap of newJobsMap) {
			if (oldJobsMap.has(newMap[0])) {
				// Compare Job
				let newJob = newMap[1];
				let oldJob = oldJobsMap.get(newJob.Job);

				if (oldJob && this.hasJobChanged(oldJob, newJob)) {
					// fire event / message

					this.fireJobChangedEvent(oldJob, newJob);
				}

			} else {

			}

		}
	}
	/**
	 * Add Percentage information to JobInfos when possible
	 * @param jobInfos JobInfos without percentage value
	 * @returns JobInfos with percentage value
	 */
	addProgressPercentageToJobInfo(jobInfos: TaskJobModel.JobStatusInfo<any>[]): TaskJobModel.JobStatusInfo<any>[] {
		console.log(jobInfos);
		let jobInfosWithProgress = jobInfos.map((job) => {
			if (!job.Progress) {

			} else {
				if (job.Progress.Max) {
					let percNum = job.Progress.Value / job.Progress.Max * 100;
					let percNumRounded = parseInt(percNum.toFixed(2));
					//job.Progress.Percentage = percNumRounded;  / TODO: check

				}
			}

			return job
		})
		return jobInfosWithProgress
	}

	hasJobChanged(oldJob: TaskJobModel.JobStatusInfo<any>, newJob: TaskJobModel.JobStatusInfo<any>): boolean {
		return oldJob.Completed !== newJob.Completed
	}

	fireJobChangedEvent(oldJob: TaskJobModel.JobStatusInfo<any>, newJob: TaskJobModel.JobStatusInfo<any>) {
		if (newJob.Successful) {
			this.messageService.add({
				severity: "success",
				summary: this.translate.instant("Message.RunJobTaskSuccess.Title"),
				detail: this.translate.instant("Message.RunJobTaskSuccess.Text"),
				//summary: "Job was successful",
				//detail: "You can now view your changes",
			});
		} else {
			this.messageService.add({
				severity: "warn",
				summary: this.translate.instant("Message.RunJobTaskFail.Title"),
				detail: this.translate.instant("Message.RunJobTaskFail.Text"),
				//summary: "Job has failed",
				//detail: "Please check your settings!",
			});
		}
	}

	// protected handle_error(error: any) {
	// 	// TODO: Display or Delegate
	// }


	// Stream Functions
	/**
	 * Event to cancel a specific job
	 * @param task current task to be cancelled
	 */
	onCancelProgress(task: TaskJobModel.JobStatusInfo<any>) {
		console.log("Job to cancel:", task);
		this.subs.sink = this.taskService.ExtractDataToPsaStream_cancel(task.Job).subscribe((cancelStatus: boolean) => {
			console.log("ExtractDataToPsaStream_cancel", cancelStatus);
		},
			(err: Error) => {
				this.errorService.handleError(err);
			})
	}


	checkScheduleJobsChanged(states: TaskStatus[]) {
		console.log(states);
	}
}
