<p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>

<p-dialog header="{{'ChangeInformation' | translate}}" [(visible)]="displayEditDatasource" modal="modal" showEffect="fade"
    [style]="{width: '600px'}">
    <div class="p-grid p-ai-center p-jc-center">
        <div class="p-fluid p-formgrid">
            <div class="p-col-12 p-mb-12 p-lg-12 p-mb-lg-0">
                <div class="p-fluid">
                    <span class="p-float-label" style="margin-top: 40px;">
                        <input type="text" id="dsnameinputtext" pInputText [(ngModel)]="dataSourceName">
                        <label for="dsnameinputtext">{{'Name' | translate}}</label>
                    </span>
                </div>
            </div>
            <div class="p-col-12 p-mb-2 p-lg-12 p-mb-lg-0">
                <div class="p-fluid">
                    <span class="p-float-label">
                        <textarea style="min-height:7rem;" id="dsdescriptiontext" [cols]="30" pInputTextarea
                            [(ngModel)]="dataSourceDescription" autoResize="autoResize"></textarea>
                        <label for="dsdescriptiontext">{{'DescriptionOptional' | translate}}</label>
                    </span>
                </div>

            </div>
        </div>
    </div>
    <app-system-message-log></app-system-message-log>

    <p-footer>
        <button pButton pRipple type="button" icon="pi pi-times" (click)="displayEditDatasource=false"
            class="p-button-rounded p-button-text  p-button-outlined p-mr-2 p-mb-2" label="{{'No' | translate}}"></button>
        <button pButton pRipple type="button" icon="pi pi-check" (click)="updateDatesource()"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2" label="{{'Yes' | translate}}"
            [icon]="savingInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
            [disabled]="buttonDisabled"></button>
    </p-footer>
</p-dialog>
