import { Component, OnInit } from '@angular/core';
import { ConstantValuePlugIn } from 'src/app/models/api/com/bion/etl/plugins/ConstantValuePlugIn';
import { FieldInfo, WorkflowNodeSettings } from 'src/app/models/api/com/bion/etl/Workflow';
import { GuiDropDown } from 'src/app/models/helperClass.model';
import { DesignerService } from 'src/app/services/designer.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
import { NodeConfigComponentBase } from '../node-config-component-base';

export class ConstantValueInfoView {
	NewColumnName: string;
	NewColumnType: GuiDropDown;
	ConstantValue: any;
}

@Component({
  selector: 'app-spark-constant-value-node',
  templateUrl: './spark-constant-value-node.component.html',
  styleUrls: ['./spark-constant-value-node.component.scss']
})
export class SparkConstantValueNodeComponent
  extends NodeConfigComponentBase<ConstantValuePlugIn.Settings>
  implements OnInit {
  settingsToView(settings: WorkflowNodeSettings): void {
    // -- Get Configuration
    let currentConfig = <ConstantValuePlugIn.Settings>settings;
    // -- Get Input Meta Data
    const meta_infos = this.getCurrentWorkflowNode().Properties.MetaInfo.get(
      this.InputPort
    );

    // create SelectInfoViews from DsFields for table
    if (meta_infos != undefined && meta_infos.length > 0) {
      const input_field_infos = meta_infos[0].FieldsInfo;
      this.columnList = input_field_infos;
    }

    if (currentConfig.Infos.length > 0) {
      // -- otherweise load existing config into view
      this.constantValueList = this.infoToView(this.columnList, currentConfig);
    } else {
      this.constantValueList = [];
    }
  }
  viewToSettings(): ConstantValuePlugIn.Settings {
    const newSettings = <ConstantValuePlugIn.Settings>{
      ...this.getCurrentWorkflowNode().Properties.Configuration,
    };

    const constantValueListView = this.constantValueList;
    const finalConstantValueConfig = [];

    constantValueListView.map((entry) => {
      // let newInfo = new ConstantValueInfo();
      // newInfo.NewColumnName = entry.NewColumnName;
      // newInfo.NewColumnType = entry.NewColumnType.value;
      // newInfo.ConstantValue = entry.ConstantValue;

      const newInfo = new ConstantValuePlugIn.ConstantValueInfo(entry.NewColumnName, 0, entry.ConstantValue, entry.NewColumnType.value)

      finalConstantValueConfig.push(newInfo);
    });

    newSettings.Infos = finalConstantValueConfig;

    return newSettings;
  }

  onSettingsChanged(settings: ConstantValuePlugIn.Settings): void {
    throw new Error("Method not implemented.");
  }

  readonly InputPort = "Input";
  constantValueList: ConstantValueInfoView[] = [];
  columnList: FieldInfo[];
  dataTypeOptions: GuiDropDown[];

  onDeleteFilter(ri) {
    this.constantValueList = [
      ...this.constantValueList.slice(0, ri),
      ...this.constantValueList.slice(ri + 1),
    ];

    this.onUpdateSettings(false);
    // this.messageService.standardToastMessage({  severity : 'warn',
    //                                                 summary : 'Row deleted',
    //                                                 detail : 'The selected row was deleted successfully!'});
  }
  onAddColumn() {
    let newConstantValue = new ConstantValueInfoView();
    newConstantValue.ConstantValue = "";
    newConstantValue.NewColumnName = "";
    newConstantValue.NewColumnType = this.dataTypeOptions[0];

    this.constantValueList.push(newConstantValue);

    this.onUpdateSettings(false);
  }
  infoToView(list: FieldInfo[], config: ConstantValuePlugIn.Settings) {
    let newFilterInfosView: ConstantValueInfoView[] = [];

    config.Infos.map((entry) => {
      let newInfoView = new ConstantValueInfoView();

      // -- set newColumnName from existing config
      newInfoView.NewColumnName = entry.ColumnName;

      // -- create selected SortMethod from config
      for (let i = 0; i < this.dataTypeOptions.length; i++) {
        if (entry.ColumnType == this.dataTypeOptions[i].value) {
          newInfoView.NewColumnType = this.dataTypeOptions[i];
        }
      }
      newInfoView.ConstantValue = entry.NewValue;
      newFilterInfosView.push(newInfoView);
    });

    return newFilterInfosView;
  }

  constructor(
    protected workflowService: WorkflowsService,
    protected designerService: DesignerService
  ) {
    super(workflowService, designerService);
    this.dataTypeOptions = [
      { name: "Double", value: "Double" },
      { name: "String", value: "String" },
      { name: "Integer", value: "Int64" },
    ];
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

}
