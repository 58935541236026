import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { DropDownEvents } from 'src/app/helper/events';
import { WorkflowNodeSettings } from 'src/app/models/api/com/bion/etl/Workflow';
import { CompareInfo, CompareSettings } from 'src/app/models/nodeSettings.model';
// import { WorkflowNodeSettings } from 'src/app/models/workflow.model';
import { DesignerService } from 'src/app/services/designer.service';
import { WorkflowsService } from 'src/app/services/workflows.service';
// import { InfoView } from '../column-aggregator-node/column-aggregator-node.component';
import { NodeConfigComponentBase } from '../node-config-component-base';

@Component({
  selector: 'app-compare-node',
  templateUrl: './compare-node.component.html',
  styleUrls: ['./compare-node.component.scss']
})
export class CompareNodeComponent extends NodeConfigComponentBase<CompareSettings>  implements OnInit {
   allValueFields: string[] = [];
   selectedValueFields: string[] = [];
   keyFields:string[] = [];
   selectedKeyFields:string[] = [];
   @ViewChild('dt') dt!: Table;

   onChangeKeyColumns(event: DropDownEvents.OnChange<any,string[]>) {
    console.log(event);

    let keyColumns: string[] = event.value;
    this.allValueFields = this.allValueFields.filter((keyCol) => {
        return !keyColumns.includes(keyCol)
    });
    this.selectedValueFields = this.selectedValueFields.filter((valueField) => {
        return !keyColumns.includes(valueField)
    });

    this.onUpdateSettings(false);
   }

  settingsToView(settings: WorkflowNodeSettings): void {
    console.log("Start", settings);
    let currentConfig = <CompareSettings>settings;

    // -- Get Input Meta Data
    const meta_infos_left = this.getCurrentWorkflowNode().Properties.MetaInfo.get(
        this.LeftInputPort
    );
    const meta_infos_right = this.getCurrentWorkflowNode().Properties.MetaInfo.get(
        this.RightInputPort
    );

    // Dummy Function
    this.allValueFields = ["BestellId","Bestellung"];
    this.selectedValueFields = ["Bestellung"];

    if(meta_infos_left === undefined || meta_infos_right === undefined) return;

    const left_fields = meta_infos_left[0].FieldsInfo.map(f => f.Name);
    const right_fields = meta_infos_right[0].FieldsInfo.map(f => f.Name);

    // -- Intersect Fields from both ports --> get common fields
    const common_new_fields_set = new Set(left_fields.concat(right_fields));
    const common_new_fields = Array.from(common_new_fields_set.values());

    // -- Fill Key Columns
    this.keyFields = common_new_fields;
    this.selectedKeyFields = [];

    let old_key_selection : string[] = [];

    if(currentConfig.CompareInfos[0])  {
        old_key_selection = currentConfig.CompareInfos[0].KeyColumns;
    }

    const new_key_selection = old_key_selection.filter(o => common_new_fields_set.has(o));
    this.selectedKeyFields = new_key_selection;

    // -- Fill Value Columns (excl key fields in existing config)
    // 1) Intersect old fields and new fields

    //this.allValueFields = common_new_fields;
    this.allValueFields = [...common_new_fields.filter(c => {
        return new_key_selection.find(n => n == c) === undefined;
    })];

    // 2) Intersect old selection and new selection
    let old_selection : string[] = [];

    if(currentConfig.CompareInfos[0])  {
        old_selection = currentConfig.CompareInfos[0].IncludeCols;
    }
    const new_selection = old_selection.filter(o => this.allValueFields.find(v => v === o));
    this.selectedValueFields = new_selection;

  }
  viewToSettings(): CompareSettings {
    let newSettings = <CompareSettings>{
        ...this.getCurrentWorkflowNode().Properties.Configuration,
    };

    let newConfig = new CompareInfo([],[],[]);

    newConfig.KeyColumns = this.selectedKeyFields;
    // newConfig.ExcludeCols = this.ExcludeCols.map((col) => {
    //     return col.columnName;
    // });
    newConfig.IncludeCols = this.selectedValueFields;

    newSettings.CompareInfos = [newConfig];

    return newSettings;
  }
  onSettingsChanged(settings: CompareSettings): void {
      throw new Error('Method not implemented.');
  }

  constructor(
    protected workflowService: WorkflowsService,
    protected designerService: DesignerService
) {
    super(workflowService, designerService);
}

  ngOnInit(): void {
  }

  readonly LeftInputPort: string ="Left";
  readonly RightInputPort: string ="Right";

  onFocusLost(event: FocusEvent) {
    const div_left: boolean = (event.relatedTarget === null);

    console.log("Send from Div: ", div_left);

    if (div_left) {
        //this.onCheckDirtyFlag();
        this.onUpdateSettings(true);
    }
}

}
