import { Id } from "src/app/helper/id";
import { GroupRole } from "src/app/models/api/models/authorization/GroupRoleLike";
import { RightHolder } from "src/app/models/api/models/authorization/RightHolder";
import { UserRole } from "src/app/models/api/models/authorization/UserRoleLIke";
import { UserInfo } from "src/app/models/api/models/session/UserInfo";

/**
 * Enriched version for the Pick List.
 */
export interface RightHolderRich extends RightHolder {
    Label: string;
    Type: string;
    Icon?: string;
    EMail?: string;
}

export class RoleEnrichment {

    /**
     * Backend Klassenname
     */
    static readonly UserClassName = "models.authorization.UserRole";
    /**
     * Backend Klassenname
     */
    static readonly GroupClassName = "models.authorization.GroupRole";
    static readonly UserTag = "User";
    static readonly GroupTag = "Group";

    /**
     * Reichert die Rollen um User-Infos und UI-Informationen an.
     * @param roles Rollen
     * @param users Users falls User-Rollen dabei sind
     * @returns Angereicherte Rollen
     */
    static prepareRightHolders(roles: RightHolder[], users: UserInfo[]): RightHolderRich[] {

        // Label und Typ Festlegen
        console.warn("No icons leaded yet");

        const views = roles.map(r => {

            if (r._type == this.UserClassName) {
                const u = <UserRole>r;
                const usr = users.find(us => us.ID == u.UserID);
                const usr_safe = Id.assertSet(usr, new Error("The user with id " + u.UserID + " does not exist anymore."))
                return this.toRightHolderRich(r, usr_safe.Username, this.UserTag, undefined, usr_safe.EMail);
            } else if (r._type == this.GroupClassName) {
                const g = <GroupRole>r;
                return this.toRightHolderRich(r, g.Name, this.GroupTag, undefined);
            } else {
                throw new Error("Right Holder Type " + r._type + " is not supported.")
            }
        })

        return views;
    }

    static toRightHolderRich(r: RightHolder, label: string, type: string, icon?: string, email?: string): RightHolderRich {
        const part = { Label: label, Type: type, Icon: icon, EMail: email };
        const view = Object.assign(r, part);
        return view;
    }
}