<div style="display:flex;align-items: center;margin-bottom: 15px">
    <img [alt]="'HeaderIcon'" src="assets/layout/images/HeaderIcon_New.svg" width="50" style="vertical-align: middle" />
    <h2 style="margin: 0px 10px;color:#6941C6;font-weight: bold;">{{'Create new destination' | translate }}</h2>
</div>
<div style="height:4px">
    <p-progressBar *ngIf="is_loading" [mode]="progressMode" [style]="{'height': '4px'}"></p-progressBar>
</div>

<div class="flex justify-content-center">
    <p-breadcrumb class="max-w-full" [model]="items"></p-breadcrumb>
</div>
<div class="left">
    <app-choose-dest-connector #chooseConnector [ConnectorInfos]='connector_infos' (onConnectorChanged)='on_connector_selected($event)'></app-choose-dest-connector>
</div>
<div class="fixed-button">
    <p-button raised="true" [label]="goto_ds_button_disabled ? 'Wählen Sie einen Konnektor aus' : (connector_info_selected?.Name ? connector_info_selected.Name + ' wählen' : '')" (onClick)="on_create_destination_click($event)" [disabled]="goto_ds_button_disabled"></p-button>
</div>
