<p-dialog header="{{headerText}}" [(visible)]="displayNewUserRole" modal="modal" showEffect="fade"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '45vw'}" (onHide)="resetCurrentForm()">

    <div class="p-grid" style="width:100%; margin-top: 10px">
        <div class="p-col-12">
            <app-system-message-log></app-system-message-log>
        </div>

        <div class="p-col-12">
            <div class="p-fluid">
                <span class="p-float-label">
                    <p-dropdown [disabled]="disabled" [options]="RightHolderTypes" [(ngModel)]="selectedRoleType" (onChange)="setRoleType($event)" optionLabel="Name"></p-dropdown>
                    <label for="Hostinputtext">{{'Role Type' | translate}}</label>
                </span>
            </div>
        </div>
        <div class="p-col-12" *ngIf="isGroupType">
            <div class="p-fluid">
                <span class="p-float-label">
                    <input type="text" id="Hostinputtext" pInputText [(ngModel)]="roleName">
                    <label for="Hostinputtext">{{'Role Name' | translate}}</label>
                </span>
            </div>
        </div>
        <div class="p-col-12" *ngIf="!isGroupType">
            <div class="p-fluid">
                <span class="p-float-label">
                    <p-dropdown [options]="users" [(ngModel)]="selectedUser" optionLabel="Username"></p-dropdown>
                    <label for="Hostinputtext">{{'User' | translate}}</label>
                </span>
            </div>
        </div>
        <div class="p-col-12">
            <div class="p-fluid">
                <span class="p-float-label">
                    <textarea style="min-height:7rem;" id="dsdescriptiontext" [cols]="30" [(ngModel)]="roleDescription" pInputTextarea autoResize="autoResize"></textarea>
                    <label for="Hostinputtext">{{'Description' | translate}}</label>
                </span>
            </div>
        </div>
        <!-- <div class="p-col-12" *ngIf="isGroupType">
            <label for="Hostinputtext">Group Views</label>
            <p-listbox [options]="views" [(ngModel)]="selectedViews" [metaKeySelection]="false" [checkbox]="true" [filter]="true" [multiple]="true" optionLabel="name" [listStyle]="{'max-height':'250px'}" >
                <ng-template let-view pTemplate="item">
                    <div class="country-item">
                        <div>{{view}}</div>
                    </div>
                </ng-template>
            </p-listbox>
        </div> -->
        <!-- <div class="p-col-12" *ngIf="isGroupType">
            <div class="p-fluid">
                <span class="p-float-label">
                    <p-multiSelect appendTo="body" [options]="views" id="viewsInput" [(ngModel)]="selectedViews" display="chip"></p-multiSelect>
                    <label for="viewsInput">Permitted view(s)</label>
                </span>
            </div>
        </div> -->
        <!-- <div class="p-col-12">
            <div class="p-fluid">
                <span class="p-float-label">
                    <p-multiSelect appendTo="body" [options]="groupRoles" id="viewsInput" [(ngModel)]="selectedGroupRoles" optionLabel="Name" display="chip"></p-multiSelect>
                    <label for="viewsInput">Group view(s)</label>
                </span>
            </div>
        </div> -->
    </div>


    <p-footer>
        <!-- <button pButton pRipple type="button" icon="pi pi-arrow-left" label="Back"
             class="p-button-text p-button-rounded p-mr-2 p-mb-2"></button>
        <button pButton pRipple type="button" icon="pi pi-check" (click)="validateForm()"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2" label="Next"
            ></button> -->
            <button pButton pRipple type="button" icon="pi pi-times" (click)="displayNewUserRole=false"
            class="p-button-rounded p-button-text  p-button-outlined p-mr-2 p-mb-2" label="{{'No' | translate}}"></button>
        <button pButton pRipple type="button" icon="pi pi-check" (click)="validateForm()"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2" label="{{'Yes' | translate}}"
            [icon]="savingInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'"></button>
    </p-footer>
</p-dialog>
