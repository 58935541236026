

export namespace ConvertCasePlugIn {
    export class ConvertCaseInfo {
		ColumnName: string;
		ConvertMode: number;
		constructor(ColumnName: string,ConvertMode: number) {
			if(ColumnName === undefined) throw new Error( "Class 'ConvertCaseInfo': Required value 'NewColumnName' is undefined" );
			if(ConvertMode === undefined) throw new Error( "Class 'ConvertCaseInfo': Required value 'ConstantValue' is undefined" );
			this.ColumnName = ColumnName;
			this.ConvertMode = ConvertMode;
		}
	}
	export class Settings {
		ConvertCaseInfos: Array<ConvertCaseInfo>;
		constructor(ConvertCaseInfos: Array<ConvertCaseInfo>) {
			if(ConvertCaseInfos === undefined) throw new Error( "Class 'Settings': Required value 'ConvertCaseInfos' is undefined" );
			this.ConvertCaseInfos = ConvertCaseInfos;
		}
	}
}