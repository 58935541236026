import {
	DB2Settings,
	H2EmbeddedSettings,
	H2ServerSettings,
	HsqlEmbeddedSettings,
	HsqlServerSettings,
	MariaDBSettings,
	PostgreSqlSettings,
	RedshiftSettings,
} from "../connector.model";
import { JdbcConnectorViewBase } from "./jdbc-connector-base.model";

export class PostgreConnectorView extends JdbcConnectorViewBase<PostgreSqlSettings> {}
export class HsqlEmbeddedConnectorView extends JdbcConnectorViewBase<HsqlEmbeddedSettings> {}
export class HsqlServerConnectorView extends JdbcConnectorViewBase<HsqlServerSettings> {}
export class H2EmbeddedConnectorView extends JdbcConnectorViewBase<H2EmbeddedSettings> {}
export class H2ServerConnectorView extends JdbcConnectorViewBase<H2ServerSettings> {}
export class DB2ConnectorView extends JdbcConnectorViewBase<DB2Settings> {}
export class MariaDBConnectorView extends JdbcConnectorViewBase<MariaDBSettings> {}
export class RedshiftConnectorView extends JdbcConnectorViewBase<RedshiftSettings> {}