import { PlayJsonInheritance } from "src/app/models/play.model";
import { Clock } from "./Clock";
import { RhythmSettings } from "./RhythmSettings";

/**
 * Marker Interface for Scheduler Frequencies
 */
export interface CronFrequency extends PlayJsonInheritance {
	ID: number;
	ActionPlan: number;
	Description?: string;
	ValidFrom?: string;
	ValidTo?: string;
	ZoneId?: string;
    _type: string
}

export class BasicFrequency implements CronFrequency {
	ID: number;
	ActionPlan: number;
	Clock: Clock;
	RhythmSettings: RhythmSettings;
	Description?: string;
	ValidFrom?: string;
	ValidTo?: string;
	ZoneId: string;
	_type: string = "models.scheduler.BasicFrequency";
	constructor(ID: number, ActionPlan: number, Clock: Clock, RhythmSettings: RhythmSettings, ZoneId: string, Description?: string, ValidFrom?: string, ValidTo?: string) {
		if (ID === undefined) throw new Error("Class 'BasicFrequency': Required value 'ID' is undefined");
		if (ActionPlan === undefined) throw new Error("Class 'BasicFrequency': Required value 'ActionPlan' is undefined");
		if (Clock === undefined) throw new Error("Class 'BasicFrequency': Required value 'Clock' is undefined");
		if (RhythmSettings === undefined) throw new Error("Class 'BasicFrequency': Required value 'RhythmSettings' is undefined");
		if (ZoneId === undefined) throw new Error("Class 'BasicFrequency': Required value 'ZoneId' is undefined");
		this.ID = ID;
		this.ActionPlan = ActionPlan;
		this.Clock = Clock;
		this.RhythmSettings = RhythmSettings;
		this.Description = Description;
		this.ValidFrom = ValidFrom;
		this.ValidTo = ValidTo;
		this.ZoneId = ZoneId;
	}
}