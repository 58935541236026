import { Component, OnDestroy, OnInit } from "@angular/core";
import { MessageService, } from "primeng/api";

import { DatasourcesService } from "src/app/services/datasources.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { SubSink } from "subsink";
import * as dss from "../../../../models/datasource.model";
import { TranslateService } from "@ngx-translate/core";
import { concatMap, map } from "rxjs/operators";
import { IntegratedSourceController } from "src/app/models/api/controllers/IntegratedSourceController";
import { IntegratedSourceModel } from "src/app/models/api/models/IntegratedSourceModel";
import { ViewSourcesIntComponent } from "../../components/view-sources-int/view-sources-int.component";
import { IntegratedSourceService } from "src/app/services/integrated-source.service";
@Component({
	selector: "app-delete-datasource-dialog",
	templateUrl: "./delete-datasource-dialog.component.html",
	styleUrls: ["./delete-datasource-dialog.component.scss"],
	providers: [MessageService],
})
export class DeleteDatasourceDialogComponent implements OnInit, OnDestroy {
	displayDeleteDatasource: boolean = false;
	subs = new SubSink();
	savingInProgress: boolean = false;
	buttonDisabled: boolean = false;
	currentSelectedDatasource: dss.DataSource;

	constructor(
		private datasourceService: DatasourcesService,
		private messageService: MessageService,
		private systemLogService: SystemMessageLogService,
		private translate: TranslateService,
		private service_api: IntegratedSourceService
	) {}
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngOnInit(): void {
		this.subs.sink = this.datasourceService.displayDeleteDatasource.subscribe(
			(res: [dss.DataSource,boolean]) => {
				this.currentSelectedDatasource = res[0]
				this.displayDeleteDatasource = res[1];

			}, (err: Error) => {
				this.systemLogService.handleError(err)
			}
		);
	}

	// API Calls ------------------
	deleteDatasource(id: number) {
		this.savingInProgress = true;
		this.buttonDisabled = true;
		this.subs.sink = this.datasourceService.deleteDataSource(id).subscribe(
			(res) => {
                this.datasourceService.datasourceChangedEmitter.emit(this.currentSelectedDatasource);

				this.messageService.add({
					severity: "success",
					summary: this.translate.instant("Message.DeleteDataSourceSuccess.Title"),
					detail: this.translate.instant("Message.DeleteDataSourceSuccess.Text1") + id +
					this.translate.instant("Message.DeleteDataSourceSuccess.Text2"),
					//summary: "Datasource deleted!",
					//detail: "Datasource with id " + id + " was successfully deleted",
				});
				this.datasourceService.displayDeleteDatasourceDialog([undefined,false]);
			},
			(err: Error) => {
				this.systemLogService.handleError(err);
			}, () => {
				this.savingInProgress = false;
				this.buttonDisabled = false;
			}
		);
	}


}
