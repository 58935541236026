<div class="container">

    <!-- <div class="p-d-flex p-ai-center p-jc-between" style="display:flex;align-items: center;">
      <button pButton label="{{'Last' | translate}}" icon="pi pi-arrow-left" (click)="onLastPage()"></button>
      <h3>{{currentReport.title}}</h3>
      <button pButton label="{{'Next' | translate}}" icon="pi pi-arrow-right" (click)="onNextPage()"></button>
    </div> -->

    <p-tabView [(activeIndex)]="activeIndex" [scrollable]="true" (onChange)="onTabClicked($event)">
      <p-tabPanel *ngFor="let tab of reports" [header]="tab.title">
    </p-tabPanel>
    </p-tabView>
    <div class="controls" *ngIf="currentReport">
     <!-- <ng-container *ngIf="isEmbedded; else embedReportFlow">
        <button pButton (click)="changeVisualType()">Change visual type</button>
        <button pButton (click)="hideFilterPane()">Hide filter pane</button>
        <button pButton (click)="setDataSelectedEvent()">Set event</button>
        <label class="display-message">{{ displayMessage }}</label>
      </ng-container>
      <ng-template #embedReportFlow>
        <label class="display-message position">
          {{ displayMessage }}
        </label>
        <button pButton (click)="embedReport()" class="embed-report">Embed Report</button>
      </ng-template>
      <ng-container *ngIf="isEmbedded">
        <powerbi-report [embedConfig]="reportConfig" cssClassName="report-container" [phasedEmbedding]="phasedEmbeddingFlag" [eventHandlers]="eventHandlersMap">
        </powerbi-report>
      </ng-container> -->
      <iframe [title]="currentReport.title" class="report-container" [src]="currentReport.url" frameborder="0" allowFullScreen="true"></iframe>

      <!-- <ng-container>
      </ng-container> -->
    </div>

    <!-- <div class="footer">
      <p>This demo is powered by Power BI Embedded Analytics</p>
      <label class="separator-pipe">|</label>
      <img title="Power-BI" alt="PowerBI_Icon" class="footer-icon" src="./assets/PowerBI_Icon.png">
      <p>Explore our<a href="https://aka.ms/pbijs/" target="_blank" rel="noreferrer noopener">Playground</a></p>
      <label class="separator-pipe">|</label>
      <img title="GitHub" alt="GitHub_Icon" class="footer-icon" src="./assets/GitHub_Icon.png">
      <p>Find the<a href="https://github.com/microsoft/powerBI-client-angular" target="_blank" rel="noreferrer noopener">source code</a></p>
    </div> -->
  </div>
