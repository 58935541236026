import { HttpErrorResponse } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import {
	JdbcMetaInfo,
} from "src/app/models/connector.model";
import { HsqlEmbeddedConnectorView } from "src/app/models/connectors/postgre-connector-view.model";
import { PlayErrorResponse } from "src/app/models/play.error.model";
import { ApiBackendService } from "src/app/services/api-backend.service";

@Component({
	selector: "app-hsql-embedded-connector",
	templateUrl: "./hsql-embedded-connector.component.html",
	styleUrls: ["./hsql-embedded-connector.component.scss"],
})
export class HsqlEmbeddedConnectorComponent implements OnInit {
	@Input() currentConnectorView: HsqlEmbeddedConnectorView;

	constructor(private api_service: ApiBackendService) {}

	status_text: string = "";
	status: boolean = false;
	// ConnectionSettingsTypes: SampleTypeInfo<ConnectionSettings>[] = []; // Use this for Driver Specifif UI

	ngOnInit(): void {
		// this.api_service.getJdbcConnectionSettings().subscribe((result) => {
		// 	console.log(result);
		// 	this.ConnectionSettingsTypes = result;
		// });
	}

	onConnect(event: PointerEvent): void {
		this.currentConnectorView.fetchMetaDataObs().subscribe(
			(res: JdbcMetaInfo) => {
				this.currentConnectorView.metaInfo = res;
				console.log(res);
				this.status_text = "OK!";
				this.status = true;
			},
			(error: any) => this.handleError(error)
		);
	}

	handleError(error: HttpErrorResponse) {
		// TODO: delegate or handle
		console.log(error);
		const play_error: PlayErrorResponse = error.error;
		this.status_text = play_error.error.exception.description;
		this.status = false;
	}
}
