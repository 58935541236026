export namespace SchedulerGraph {
	export class GuiSettings {
		X: number;
		Y: number;
		Width: number;
		Height: number;
		constructor(X: number, Y: number, Width: number, Height: number) {
			if (X === undefined) throw new Error("Class 'GuiSettings': Required value 'X' is undefined");
			if (Y === undefined) throw new Error("Class 'GuiSettings': Required value 'Y' is undefined");
			if (Width === undefined) throw new Error("Class 'GuiSettings': Required value 'Width' is undefined");
			if (Height === undefined) throw new Error("Class 'GuiSettings': Required value 'Height' is undefined");
			this.X = X;
			this.Y = Y;
			this.Width = Width;
			this.Height = Height;
		}
	}
	export class Annotation {
		Text: string;
		constructor(Text: string) {
			if (Text === undefined) throw new Error("Class 'Annotation': Required value 'Text' is undefined");
			this.Text = Text;
		}
	}
	export class Properties {
		Configuration: any;
		Annotation?: Annotation;
		constructor(Configuration: any, Annotation?: Annotation) {
			if (Configuration === undefined) throw new Error("Class 'Properties': Required value 'Configuration' is undefined");
			this.Configuration = Configuration;
			this.Annotation = Annotation;
		}
	}
	export class EngineInfo {
		Action: string;
		Name: string;
		constructor(Action: string, Name: string) {
			if (Action === undefined) throw new Error("Class 'NodeInfo': Required value 'Action' is undefined");
			if (Name === undefined) throw new Error("Class 'NodeInfo': Required value 'Type' is undefined");
			this.Action = Action;
			this.Name = Name;
		}
	}
	export class Node {
		ID: string;
		GuiSettings: GuiSettings;
		Properties: Properties;
		EngineInfo: EngineInfo;
		constructor(ID: string, GuiSettings: GuiSettings, Properties: Properties, EngineInfo: EngineInfo) {
			if (ID === undefined) throw new Error("Class 'Node': Required value 'ID' is undefined");
			if (GuiSettings === undefined) throw new Error("Class 'Node': Required value 'GuiSettings' is undefined");
			if (Properties === undefined) throw new Error("Class 'Node': Required value 'Properties' is undefined");
			if (EngineInfo === undefined) throw new Error("Class 'Node': Required value 'EngineInfo' is undefined");
			this.ID = ID;
			this.GuiSettings = GuiSettings;
			this.Properties = Properties;
			this.EngineInfo = EngineInfo;
		}
	}
	export class Edge {
		SourceNode: string;
		SourcePort: string;
		TargetNode: string;
		TargetPort: string;
		constructor(SourceNode: string, SourcePort: string, TargetNode: string, TargetPort: string) {
			if (SourceNode === undefined) throw new Error("Class 'Edge': Required value 'SourceNode' is undefined");
			if (SourcePort === undefined) throw new Error("Class 'Edge': Required value 'SourcePort' is undefined");
			if (TargetNode === undefined) throw new Error("Class 'Edge': Required value 'TargetNode' is undefined");
			if (TargetPort === undefined) throw new Error("Class 'Edge': Required value 'TargetPort' is undefined");
			this.SourceNode = SourceNode;
			this.SourcePort = SourcePort;
			this.TargetNode = TargetNode;
			this.TargetPort = TargetPort;
		}
	}
	export class Graph {
		Nodes: Array<Node>;
		Edges: Array<Edge>;
		constructor(Nodes: Array<Node>, Edges: Array<Edge>) {
			if (Nodes === undefined) throw new Error("Class 'Graph': Required value 'Nodes' is undefined");
			if (Edges === undefined) throw new Error("Class 'Graph': Required value 'Edges' is undefined");
			this.Nodes = Nodes;
			this.Edges = Edges;
		}
	}
}


// interface SchedulerGraph {
//     Nodes:Array<SchedulerNode>;
//     Edges:Array<SchedulerEdge>;
// }

interface SchedulerElement {
	ID: string;
	GuiSettings: GuiSettings;
}

interface NodeInfo {
	/**
	 * Action Type Identifier, e.g. models.api.ExtractDataToPsa
	 */
	Action: string;
	/**
	 * Argument Shape example
	 */
	Argument: any;

	/**
	 * Determines the node type = {Action, Split, Port, Start, End, ...}
	 */
	Type: string;
}

interface SchedulerNode extends SchedulerElement {
	Properties: Properties;
	NodeInfo: NodeInfo;
}

interface SchedulerEdge extends SchedulerElement {
	Type: string;
}

interface GuiSettings {
	x: number;
	y: number;
}

interface Properties {
	Configuration: any;
	Annotation?: Annotation;
}

interface Annotation {
	Text: string;
}

interface PortInfo {

}

interface ScheduleNodeInfo {
	Repository: string;
	NodeInfo: NodeInfo;
	Name: string;
	Ports: Array<PortInfo>;
}

// interface ScheduleGraphService {
//     getActions() : Array<ScheduleNodeInfo>;
// }

// interface SchedulerGraphCodec {
//     encode(scheduleGraph:SchedulerGraph) : mxGraph;
//     decode(graph:mxGraph) : SchedulerGraph;
// }
