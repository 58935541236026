<p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>

<div class="login-body">
    <app-system-message-log></app-system-message-log>

    <div class="login-wrapper">
        <div [formGroup]="form" class="login-panel">
            <a [routerLink]="['/']" class="logo">
                <img src="assets/layout/images/logo-bion-single.svg" alt="freya-layout" />
            </a>
            <!-- <a style="margin-bottom: 5px; color: crimson;">{{errorMessage}}</a> -->

            <h4 style="margin-bottom: 50px">{{'You have forgotten your password? Enter your email address to receive a link to reset your password' | translate}}</h4>

            <input pInputText type="email" class="form-control" name="email" formControlName="email" required
                placeholder="{{'email' | translate}}" (keyup.enter)="requestResetPassword()" />
            <button *ngIf="!isSuccessful" pButton pRipple label="{{'RESET' | translate}}" type="button"
                (click)="requestResetPassword()"></button>
            <!-- <div style="margin-bottom: 10px"><a style="float:left;margin-right: 5px">Bion</a><p-inputSwitch (onChange)="changeLoginMode($event)" formControlName="loginMode"></p-inputSwitch><a style="float:right;margin-left: 5px">Azure</a>
            </div> -->

            <!-- <a href="#" (click)="openForgotPasswordPage()">{{'forget password? Reset your password here' |
                translate}}</a> -->
            <p *ngIf="isSuccessful && resultMessage" style="color: rgb(104, 191, 124)"><i class="pi pi-check-circle"></i>{{resultMessage}}</p>
            <p *ngIf="!isSuccessful && resultMessage" style="color: rgb(194, 98, 98)"><i class="pi pi-times-circle"></i>{{resultMessage}}</p>
        </div>
        <div class="login-footer">
            <h4>bion</h4>
            <h6>Copyright Ⓒ Bion Analytics</h6>
        </div>
    </div>
</div>
