import { Component, OnDestroy, OnInit } from "@angular/core";
import { MessageService } from "primeng/api";
import { Id } from "src/app/helper/id";
import { ScheduleActionEvent, ScheduleDialogActionType } from "src/app/models/dialog-actions.model";
import { ScheduleActionPlan } from "src/app/models/schedule.model";
import { SchedulesService } from "src/app/services/schedules.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { SubSink } from "subsink";

@Component({
	selector: "app-delete-schedule-dialog",
	templateUrl: "./delete-schedule-dialog.component.html",
	styleUrls: ["./delete-schedule-dialog.component.scss"],
	providers: [MessageService]
})
export class DeleteScheduleDialogComponent implements OnInit, OnDestroy {
	subs = new SubSink();
	displayDeleteSchedule: boolean = false;
	savingInProgress: boolean = false;
	currentSelectedSchedule?: ScheduleActionPlan;

	constructor(
		private schedulesService: SchedulesService,
		private systemLogService: SystemMessageLogService,
		private messageService: MessageService
	) {}
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngOnInit(): void {
		this.subs.sink = this.schedulesService.scheduleDialogActionSendEmitter.subscribe((res: ScheduleActionEvent) => {
			if(res.actionType === ScheduleDialogActionType.deleteSchedule) {
				this.displayDeleteSchedule = res.display;
				this.currentSelectedSchedule = res.schedule;
			}
		}, (error: Error) => {
			this.systemLogService.handleError(error);
		  });
	}

	onDeleteSchedule(id?:number) {
		try {
			const safe_id = Id.assertSet(id, new Error("No scheduler selected"));
			this.deleteSchedule(safe_id);
		} catch(e) {
			console.log(e);
			const err = <Error>e;
			alert(err.message);
		}
	}

	deleteSchedule(id:number) {
		this.savingInProgress = true;
		this.subs.sink = this.schedulesService.deleteScheduleActionPlan(id).subscribe(
			(res) => {
                this.schedulesService.schedulesChangedEmitter.emit("Delete Schedule");

				this.messageService.add({
					severity: "success",
					summary: "Schedule deleted!",
					detail: "Schedule with id " + res.id + " was successfully deleted",
				});
				this.displayDeleteSchedule = false;
			},
			(error: Error) => {

				this.systemLogService.handleError(error);
			}, () => {
				this.savingInProgress = false;
			}
		);
	}
}
