<p-overlayPanel #op [showCloseIcon]="true">
    <ng-template pTemplate>
        <div style="width:600px">
            <div class="p-col-12" *ngIf="toggleView === true">
                <div class="">
                    <div class="card-header">
                        <div class="card-title">
                            <h6>{{'Node Status' | translate}}</h6>
                        </div>
                    </div>
                    <p-table #StatesTable [value]="workflowStatesLog" [columns]="nodeStatesCols"
                        [resizableColumns]="false" [reorderableColumns]="false" [rowHover]="true" [filterDelay]="0"
                        [lazy]="true" [globalFilterFields]="nodeStatesCols">

                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th [ngStyle]="{'width': col.width}" *ngFor="let col of columns" field="col.field"
                                    style="width: 100px">
                                    {{col.header}}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr>
                                <td>{{rowData.NodeID}} </td>
                                <td><span class="p-column-title">{{'Mssage' | translate}}</span>
                                    <span
                                        [class]="'customer-badge status-' + rowData.Message">{{rowData.Message}}</span>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="8">{{'NoEntryFound' | translate}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>


<div *ngIf="!workflowStatesLog && toggleView === true">
    <p-button icon="pi pi-pause" styleClass="p-button  p-button-rounded" [disabled]="true"></p-button>
</div>

<div *ngIf="workflowStatesLog && toggleView === true">
    <p-button icon="pi pi-times" (click)="op.toggle($event)" styleClass="p-button-danger p-button-rounded"
        *ngIf="workflowFailed === true"></p-button>
    <p-button icon="pi pi-check" (click)="op.toggle($event)" styleClass="p-button-success  p-button-rounded"
        *ngIf="wworkflowFailed === false"></p-button>

    <!-- <div class="p-d-flex p-justify-center" style="margin-top: 5px;">
        <p-chart type="pie" [data]="chartData" [options]="chartOptions" [style]="{'width': '40px'}"></p-chart>
    </div> -->
</div>
