<!-- <div class="p-grid">

        <div class="p-col headerSection">
            <div class="p-fluid">
                <span class="p-float-label">
                    <p-dropdown id="mode" [options]="aggregationMethods" [(ngModel)]="selectedAggregationMethod"
                    (onChange)="onUpdateSettings(false)" optionLabel="name"></p-dropdown>
                        <label for="mode">Aggregation Method</label>
                </span>
            </div>
        </div>
        <div class="p-col headerSection">
            <div class="p-fluid">
                <span class="p-float-label">
                    <input pInputText id="delimiter" [(ngModel)]="aggregationDelimiter" (blur)="onUpdateSettings(false)"
                    [ngModelOptions]="{standalone: true}">
                        <label for="delimiter">Delimiter</label>
                </span>
            </div>
        </div>
    <div class="p-col-12">
        <p-pickList [source]="leftTableList" [target]="rightTableList" sourceHeader="Exclude" targetHeader="Include"
            dragdrop="true" [responsive]="true" [sourceStyle]="{ height: '300px' }" [targetStyle]="{ height: '300px' }"
            filterBy="columnName" sourceFilterPlaceholder="Search by column name"
            targetFilterPlaceholder="Search by column name" (onMoveAllToSource)="onUpdateSettings(false)"
            (onMoveAllToTarget)="onUpdateSettings(false)" (onMoveToTarget)="onUpdateSettings(false)"
            (onMoveToSource)="onUpdateSettings(false)" (onSourceReorder)="onUpdateSettings(false)"
            (onTargetReorder)="onUpdateSettings(false)">
            <ng-template let-product pTemplate="item">
                <div class="product-item" [ngClass]="{'row-accessories': product.hasError === true}">
                    <div class="product-list-detail">
                        <p class="p-mb-2">
                            {{ product.columnName }}
                        </p>
                    </div>
                </div>
            </ng-template>
        </p-pickList>
    </div>
</div> -->

<div class="p-grid">
    <div class="p-col-6 headerSection">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown id="mode" [options]="aggregationMethods" appendTo="body"  [(ngModel)]="selectedAggregationMethod"
                    (onChange)="onUpdateSettings(false)" optionLabel="name"></p-dropdown>
                <label for="mode">{{'Method' |translate}}</label>
            </span>
        </div>
    </div>
    <div class="p-col-6 headerSection">
        <div class="p-fluid">
            <span class="p-float-label">
                <input pInputText id="delimiter" [disabled]="selectedAggregationMethod.name === 'count'" [(ngModel)]="aggregationDelimiter" (blur)="onUpdateSettings(false)"
                    [ngModelOptions]="{standalone: true}">
                <label for="delimiter">{{'Delimiter' |translate}}</label>
            </span>
        </div>
    </div>
    <div class="p-col-6 ">
        <div class="p-fluid">
            <span class="p-float-label">
                <input pInputText id="newColNameInput" [(ngModel)]="newColName" (blur)="onUpdateSettings(false)"
                    [ngModelOptions]="{standalone: true}">
                <label for="newColNameInput">{{'New Column Name' |translate}}</label>
            </span>
        </div>
    </div>
    <div class="p-col-6 ">
        <div class="p-fluid">
            <p-checkbox label="{{'Drop Old' | translate}}" [(ngModel)]="dropOldCol" [binary]="true"  (onChange)="onUpdateSettings(false)"></p-checkbox>
        </div>
    </div>
</div>
<div>
    <p-orderList [value]="leftTableList" [listStyle]="{'max-height':'12rem'}" header="{{'Exclude' | translate}}"
        filterBy="columnName" filterPlaceholder="{{'Filter by name' |translate}}">
        <ng-template let-product pTemplate="item">
            <div class="product-item" pDraggable="leftTableList" (onDragStart)="dragStart(product)"
                (onDragEnd)="dragEnd()">
                <div class="product-list-detail">
                    <p class="mb-2">{{product.columnName}}</p>
                </div>
            </div>
        </ng-template>
    </p-orderList>
</div>

<div>
    <p-orderList [value]="rightTableList" [listStyle]="{'max-height':'15rem'}" header="{{'Include' | translate}}"
        filterBy="columnName" filterPlaceholder="{{'Filter by name' |translate}}" pDroppable="leftTableList"
        (onDrop)="drop()">
        <ng-template let-product pTemplate="item" >
            <div class="product-item" [ngStyle]="{'background-color':product.isMissing === false ? undefined : '#FFF0F0'}"  >
                <div class="product-list-detail" >
                    <p class="mb-2">{{product.columnName}} <span style="float:right"><button pButton icon="pi pi-times"
                                class="p-button-rounded p-button-text p-button-danger"
                                (click)="deleteEntry(product)"></button></span></p>
                </div>
            </div>
        </ng-template>
    </p-orderList>
</div>
