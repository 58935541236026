import { Simulation } from "./RunWorkflowSettings.ns";

export class RunWorkflowSettings {
	EndNode?: string;
	Simulation: Simulation;
	constructor(Simulation: Simulation,EndNode?: string) {
		if(Simulation === undefined) throw new Error( "Class 'RunWorkflowSettings': Required value 'Simulation' is undefined" );
		this.EndNode = EndNode;
		this.Simulation = Simulation;
	}
}