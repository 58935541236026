
    <!-- <div class="card-header">
        <div class="card-title">
            <h6>{{'UpcomingAppointments' | translate}}</h6>
        </div>
    </div> -->
    <span style="width:100%">
        <p-timeline [align]="'left'" [value]="upcomingAppointments"  styleClass="customized-timeline" >
            <ng-template pTemplate="marker" let-event>
                <span class="custom-marker shadow-0" [style.backgroundColor]="'#FF9800'" >
                    <i [ngClass]="'pi pi-calendar'"></i>
                </span>
            </ng-template>
            <!-- <ng-template pTemplate="opposite" let-event>
                <small class="p-text-secondary">{{event.date | date: 'dd/MM/yyyy' }}</small>
            </ng-template> -->
            <ng-template pTemplate="content" let-event >
                <div style="width:200px">
                    {{event.actionPlan.name}}<br>
                    <small class="p-text-secondary">{{event.date | date: 'medium' }}</small>
                </div>
    
            </ng-template>
        </p-timeline>
    </span>

    <div style="height: 250px" *ngIf="upcomingAppointments.length === 0">
        <app-no-data-available [view]="'appointment'" [url]="'/appointment'"></app-no-data-available>
    </div>
