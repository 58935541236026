import { Component, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { ConnectorSettingsBuilder } from "src/app/models/connectorView.model";
import { DataSet } from "src/app/models/datamarket.model";
import {
	ExtractFromConnectorArg,
	LoadSettings,
} from "src/app/models/datasource.model";
import { ApiBackendService } from "src/app/services/api-backend.service";
import { DatasourcesService } from "src/app/services/datasources.service";
import icons from "currency-icons";
import { DynamicItemInfo } from "src/app/models/com-bion-json";
import { SubSink } from "subsink";
import { CreateDatasourceDialogComponent } from "../datasources/dialogs/create-datasource-dialog/create-datasource-dialog.component";
import { AdapterTypeInfo, DataMarketAccess } from "src/app/models/connector.model";
import { DataMarketService } from "src/app/services/data-market.service";


interface DropDownEntry<T> {
    name:String;
    value:T;
}

interface FilterProvider<T> {
    getPropertyPath():string[];
    entrySet(values:T[]) : Set<T>;
    dropDownOption(value:T) : DropDownEntry<T>;
    filter(value:T, dataSet:DataSet) : boolean;
}

class ObjectFilterProvider<T> implements FilterProvider<T> {
    propertyPath: string[];
    equalityAttributes: string[];
    dropDownNameAttribute : string;
    dropDownIdAttribute : string;

    constructor(propertyPath:string[], equalityAttributes: string[], dropDownNameAttribute:string, ) {
        this.propertyPath = propertyPath;
        this.equalityAttributes = equalityAttributes;
        this.dropDownNameAttribute = dropDownNameAttribute;
    }

    getPropertyPath(): string[] {
        return this.propertyPath;
    }
    entrySet(values: T[]): Set<T> {

        const newValues = values.map(value => {
            // drop all attributes which are not in scope
            Object.getOwnPropertyNames(value);
            const allAttributes = Object.getOwnPropertyNames(value);
            for (let att of allAttributes) {
                if (!this.equalityAttributes.includes(att)) {
                    delete value[att];
                }
            }
            return value;
        });

        const uniqueValues = this.getUniqueElements(newValues);

        return new Set(uniqueValues);
    }
    dropDownOption(value: T): DropDownEntry<T> {

        const dropDownAttribute = value[this.dropDownNameAttribute];

        return ({ name:dropDownAttribute.toString(), value:value });
    }
    filter(value: T, dataSet: DataSet): boolean {
        //console.log("ObjectFilterProvider.filter(...)")
        //console.log(dataSet);
        //console.log(this.getPropertyPath());
        const dataSetValue = this.resolvePath(dataSet, this.getPropertyPath());

        if(dataSetValue === undefined) return false;

        // console.log(dataSetValue);
        // var equals = value == dataSetValue;
        //console.log("Filter object: " + value);
        //console.log("Data object: " + dataSetValue);
        const equals = this.compareObject(value, dataSetValue);
        //console.log("Filter equals Data object: " + equals );
        //console.log("equals: " + equals);
        return equals;
    }

    compareObject(value:any, dataSetValue:any) : boolean {
        // compare each attribute of interest
        for(let att of this.equalityAttributes) {
            if( value[att] != dataSetValue[att] ) return false;
        }

        return true;
    }

    resolvePath(obj:any, path:string[]) : any{
        let now = obj;
        for(let step of path) {
            now = now[step];

            if(now === undefined) {
                console.log("The object does not have the attribute '" + step + "' - DROPPING!");
                return now;
            }
        }

        return now;
    }

    getUniqueElements(values:T[]) : T[] {

        const result = new Array<T>();

        console.log("get unique elements");

        for(let value of values) {
            // check if value ia alreay in result
            // if so, push else ignore, because it is already there
            let isInResult: boolean = false;
            for(let resValue of result) {

                if(this.compareObject(value,resValue)) {
                    isInResult = true;
                    break;
                }
            }

            if(!isInResult) result.push(value);
        }

        return result;
    }

}

interface FilterComparator<T> {
    equals(value1:T,value2:T) : boolean;
    dropDownLabel(value:T) : string;
}

class AtomicComparator<T> implements FilterComparator<T> {
    equals(value1: T, value2: T): boolean {
        return value1 == value2;
    }
    dropDownLabel(value: T): string {
        return value.toString();
    }

}

class ObjectComparator<T> implements FilterComparator<T> {

    equalityAttributes:string[];
    labelFunction : (value:T) => string;

    constructor(equalityAttributes: string[], labelFunction : (value:T) => string) {
        this.equalityAttributes = equalityAttributes;
        this.labelFunction = labelFunction;
    }

    equals(value1: T, value2: T): boolean {
        for(let att of this.equalityAttributes) {

            if( value1[att] != value2[att] ) return false;

        }

        return true;
    }
    dropDownLabel(value: T): string {
        return this.labelFunction(value);
    }

}


class ArrayFilterProvider<T> implements FilterProvider<T> {
    propertyPath:string[];
    comparator: FilterComparator<T>;

    constructor(propertyPath:string[], comparator: FilterComparator<T>=new AtomicComparator<T>()) {
        this.propertyPath = propertyPath;
        this.comparator = comparator;
    }

    getPropertyPath(): string[] {
        return this.propertyPath;
    }
    entrySet(values: T[]): Set<T> {
        // const result = values.reduce((accumulator, value) => accumulator.concat(value), []);
        const result = this.getUniqueElements(values);
        return new Set(result);
    }
    dropDownOption(value: T): DropDownEntry<T> {
        //console.log(value);
        return ({ name:this.comparator.dropDownLabel(value), value:value });
    }
    filter(value: T, dataSet: DataSet): boolean {
        console.log(dataSet);
        console.log(this.getPropertyPath());
        const dataSetValue: [] = this.resolvePath(dataSet, this.getPropertyPath());

        if(dataSetValue === undefined) return false;

        console.log(dataSetValue);
        // var equals = (dataSetValue.filter(x => x == value).length > 0);

        const equals = (dataSetValue.filter(x => this.comparator.equals(x, value)).length > 0);

        console.log("equals: " + equals);
        return equals;
    }

    resolvePath(obj:any, path:string[]) : any{
        let now = obj;
        for(let step of path) {
            now = now[step];

            if(now === undefined) {
                console.log("The object does not have the attribute '" + step + "' - DROPPING!");
                return now;
            }

        }

        return now;
    }

    getUniqueElements(values:T[]) : T[] {

        const result = new Array<T>();

        console.log("get unique elements");

        for(let value of values) {
            // check if value ia alreay in result
            // if so, push else ignore, because it is already there
            let isInResult: boolean = false;
            for(let resValue of result) {

                // console.log("compare objects in get unique elements");
                // console.log("Object a" + value);
                // console.log("Object b" + resValue);

                if(this.comparator.equals(value,resValue)) {
                    isInResult = true;
                    break;
                }
            }

            if(!isInResult) result.push(value);
        }

        return result;
    }
}

class AtomicFilterProvider<T> implements FilterProvider<T> {

    propertyPath:string[];
    comparator: FilterComparator<T>;

    constructor(propertyPath:string[], comparator: FilterComparator<T> = new AtomicComparator<T>()) {
        this.propertyPath = propertyPath;
        this.comparator = comparator;
    }

    getPropertyPath(): string[] {
        return this.propertyPath;
    }

    // constructor(name:string) {
    //     this.propertyName = name;
    // }

    entrySet(values:T[]): Set<T> {
        return new Set(values);
    }
    dropDownOption(value: T): DropDownEntry<T> {
        return ({ name:this.comparator.dropDownLabel(value), value:value });
    }
    filter(value: T, dataSet: DataSet): boolean {

        //console.log(dataSet);
        //console.log(this.getPropertyPath());

        const dataSetValue = this.resolvePath(dataSet, this.getPropertyPath());
        //console.log(value);
        //console.log(dataSetValue);
        if(dataSetValue === undefined) return false;

        //console.log(dataSetValue);

        // var equals = value == dataSetValue;
        const equals = this.comparator.equals(value, dataSetValue);

        //console.log("equals: " + equals);
        return equals;
    }

    resolvePath(obj:any, path:string[]) : any{
        let now = obj;
        for(let step of path) {
            now = now[step];

            if(now === undefined) {
                console.log("The object does not have the attribute '" + step + "' - DROPPING!");
                return now;
            }

        }

        return now;
    }

    getUniqueElements(values:T[]) : T[] {

        const result = new Array<T>();

        console.log("get unique elements");

        for(let value of values) {
            // check if value ia alreay in result
            // if so, push else ignore, because it is already there
            let isInResult: boolean = false;
            for(let resValue of result) {

                // console.log("compare objects in get unique elements");
                // console.log("Object a" + value);
                // console.log("Object b" + resValue);

                if(this.comparator.equals(value,resValue)) {
                    isInResult = true;
                    break;
                }
            }

            if(!isInResult) result.push(value);
        }

        return result;
    }
}

@Component({
	selector: "app-data-market-view",
	templateUrl: "./data-market-view.component.html",
	styleUrls: ["./data-market-view.component.scss"],
})
export class DataMarketViewComponent implements OnInit, OnDestroy {
	loading: boolean;
    index: number;
	subs = new SubSink();
	currencyicons;
	//originalDataSets: DataSet[];
	datasets: DataSet[];
	selectedDataSet: DataSet;
	readonly DATAMARKET_ADAPTER: string = "BION Data Market Adapter";
	//selectedConnector?: DataSourceAdapter;
	selectedConnector?: AdapterTypeInfo<any,any>;

	@ViewChild('dv') dv;
	@ViewChild("createDataSourceDialog") createDataSourceDialog:CreateDatasourceDialogComponent;
	//createDataSourceDialog: CreateDatasourceDialogComponent;

	dataSetMetaData;

	// sortOptions: SelectItem[];
	// sortOrder: number;
	// sortField: string;
	// columns: number[];

	// custom filter for data set table
	originalDataSets : DataSet[];
	detailFilterDataSets: DataSet[];


	// Possible Filters in View
	dataSetCategories: any[] = [];
	selectedDataSetCategories: any[] = [];

	dataSetProviders: any[] = [];
	selectedProviders: any[] = [];

	filterProviders : Map<string,FilterProvider<{}>> = new Map<string,FilterProvider<{}>>();
    dropDownModels : Map<string, {model:any[],selection:any[]}> = new Map<string,{model:[],selection:[],getSelection():[]}>();

    readonly FILTER_CATEGORY:string = "Category";
    readonly FILTER_PROVIDER: string = "Provider";


	constructor(
		private datasourceService: DatasourcesService,
		private dataMarketService: DataMarketService,
		public bionApi: ApiBackendService
	) {}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}
    ngOnChanges(changes: SimpleChanges) {
        if(changes.datasets) {
            this.dv.value = changes.datasets.currentValue;
        }
    }

	ngOnInit() {
		//this.columns = [0, 1, 2, 3, 4, 5];

		this.loading = true;
		this.subs.sink = this.datasourceService
			.getDataMarketRepository()
			.subscribe((res) => {
				this.originalDataSets = res;
				this.datasets = res;
				//console.log(res);

				//Fill Filter Options with Values
				// -- new category
                const dataSetCategories = res.map(t => {
                    return t.Category
                });
                const categoryFilterProvider = new AtomicFilterProvider<string>([this.FILTER_CATEGORY]);
                this.dataSetCategories = Array.from(categoryFilterProvider.entrySet(dataSetCategories)).map(x => { return categoryFilterProvider.dropDownOption(x)});
				this.filterProviders.set(this.FILTER_CATEGORY, categoryFilterProvider);
				this.dropDownModels.set(this.FILTER_CATEGORY, {model:dataSetCategories, selection:this.selectedDataSetCategories});

				// -- new category
                const dataSetProviders = res.map(t => {
                    return t.Provider
                });
                const providerFilterProvider = new AtomicFilterProvider<string>([this.FILTER_PROVIDER]);
                this.dataSetProviders = Array.from(providerFilterProvider.entrySet(dataSetProviders)).map(x => { return providerFilterProvider.dropDownOption(x)});
				this.filterProviders.set(this.FILTER_PROVIDER, providerFilterProvider);
				this.dropDownModels.set(this.FILTER_PROVIDER, {model:dataSetProviders, selection:this.selectedProviders});

				this.subs.sink = this.datasourceService
					.getAdapterTypeInfo()
					.subscribe((res: AdapterTypeInfo<any,any>[]) => {
						res.filter((adapter) => {
							if (adapter.Name === this.DATAMARKET_ADAPTER) {
								//console.log("Preparing View");
								this.selectedConnector = adapter;
								//console.log(this.selectedConnector);
							}
						});
				});

				this.loading = false;
			});

		// this.sortOptions = [
		// 	{ label: "Price High to Low", value: "!price" },
		// 	{ label: "Price Low to High", value: "price" },
		// ];
	}

	// onSortChange(event) {
	// 	const value = event.value;

	// 	if (value.indexOf("!") === 0) {
	// 		this.sortOrder = -1;
	// 		this.sortField = value.substring(1, value.length);
	// 	} else {
	// 		this.sortOrder = 1;
	// 		this.sortField = value;
	// 	}
	// }

	// addColumn() {
	// 	this.columns.push(this.columns.length);
	// }

	// removeColumn() {
	// 	this.columns.splice(-1, 1);
	// }

	displayDataSetDetailsDialog(dataset: DataSet) {
		this.onSelectDataSet(dataset);
		this.dataMarketService.displayDataSetDetailsDialog.emit(true);
	}
	onSelectDataSet(dataset: DataSet) {
		//console.log(dataset);
		this.selectedDataSet = dataset;
		this.datasourceService.selectedDataSetEmitter.emit(this.selectedDataSet);

		const extract_settings = new DataMarketAccess(
			dataset.ServiceID,
			dataset.DataSetID,
			"",
			true,
			100
		);
		this.createDataSourceDialog.cmd_selectConnector(
			this.DATAMARKET_ADAPTER,
			extract_settings
		).subscribe(result => {
            console.log("Connector Selected: ", result);
        },
        error => {console.log(error)});

        // this.createDataSourceDialog.cmd_goToPage()

		if (dataset.serviceMetaData.Currencies) {
			this.currencyicons = dataset.serviceMetaData.Currencies.map((x) => {
				return icons[x];
			});
		}
	}

	dataPreviewColumns: any;
	updateTablePreview(
		previewAdapter: ConnectorSettingsBuilder<any>,
		base64FileData: string
	) {
		// // Initialize extractDataArg Class
		const connector_info =  new DynamicItemInfo(previewAdapter.getConnectorId(),previewAdapter.getConnectorSettings());
		const load_settings = new LoadSettings(true, 100);
		const arg = new ExtractFromConnectorArg(connector_info, load_settings, base64FileData);

		let preview = this.datasourceService.extractFromConnector(arg);


		preview.subscribe((x) => {
			let indexedColumnData = x.Columns.map((v, i, a) => {
                const ici = {...v, ColumnIndex: i};
                return ici;
			});
			this.dataPreviewColumns = indexedColumnData;
			console.log(this.dataPreviewColumns);
		});
	}

	onDisplayNewDataSourceDialog(dataset: DataSet) {
		//this.onSelectDataSet(dataset);

        const extract_settings = new DataMarketAccess(
			dataset.ServiceID,
			dataset.DataSetID,
			"",
			true,
			100
		);
		// this.createDataSourceDialog.cmd_selectConnector(
		// 	this.DATAMARKET_ADAPTER,
		// 	extract_settings
		// );

		this.datasourceService.displayCreateDataSet.emit([this.DATAMARKET_ADAPTER, extract_settings]);
	}


    onFilterChange(evt){
        console.log("FilterEvent",evt);

        const customFilter = true;

        // reset table
        this.dv.value = this.originalDataSets;

        if(customFilter) {

            // get the actual filter settings
            // WRITE ALL FILTER SETTINGS TO THE MODEL

            this.dropDownModels.clear();
            this.dropDownModels.set(this.FILTER_CATEGORY, {model: this.dataSetCategories, selection:this.selectedDataSetCategories});
            this.dropDownModels.set(this.FILTER_PROVIDER, {model: this.dataSetProviders, selection:this.selectedProviders});

            let intermediateDataSets = this.originalDataSets;

            for(let providerEntry of this.filterProviders.keys() ) {

                const provider = this.filterProviders.get(providerEntry);

                const xxx = this.dropDownModels.get(providerEntry);

                const dropDownSelection: DropDownEntry<{}>[] = this.dropDownModels.get(providerEntry).selection;

                //console.log("Selection",dropDownSelection);
                const dropDownValues = dropDownSelection.map(s => s.value);

                if(dropDownValues.length == 0) continue;

                intermediateDataSets = intermediateDataSets.filter( ds =>
                {
                    const foundValues = dropDownValues.filter(va => {
                        return provider.filter(va, ds)
                    });
                    return foundValues.length > 0
                });

            }
            //this.dv.value = intermediateDataSets;
            this.datasets = [...intermediateDataSets];
            console.log(this.dv.value);

        } else {
            const targetValue = evt.value;

            const valueArray = targetValue.map(x => x.value);

            if(valueArray.length == 0) {
                // reset
                return;
            }
            const targetString = valueArray[0];
            this.dv.filter(targetString,'contains');
        }

    }



}
