import { Observable, throwError } from "rxjs";
import { DestinationService } from "src/app/services/destinationService";
import { DestinationWriteMode } from "../api/com/bion/connect/DestinationWriteMode";
import { TargetAccessLike, TargetMetaInfo } from "../api/com/bion/connect/TargetAccessLike";
import { ConnectionSettings } from "../connector.model";
import { ConnectorSettingsBuilder } from "../connectorView.model";
import { DestinationViewBase } from "./destination-view-base.model";

/**
 * Extend this class for your individual JDBC Destination View Model
 * @param T JDBC Connection Type
 */
export abstract class JdbcDestinationViewBase<T extends ConnectionSettings>
    extends DestinationViewBase<T, T> {

    constructor(api: DestinationService, connectorID: string, connector_key: number) {
        super(api, connectorID, connector_key);
        this.metaInfo = new TargetMetaInfo([], new DestinationWriteMode.WriteModeInfo([]));
    }
    getBase64FileData(): string {
        return '';
    }
    getDataSourceNameSuggestion(): string {
        return this.connectorID;
    }
    uploadFile(file: File) {
        throw new Error('JDBC does not support file upload');
    }
    getSettingsBuilder(): Observable<ConnectorSettingsBuilder<T>> {

        return throwError(new Error("Not Implemented"));

        // let jdbcSettings = new JdbcSettingsBuilder(this.connectorID, this.connectorSettings.ConnectionSettings, this.connectorSettings.Table);

        // return of(jdbcSettings);
    }
    isFileBased(): boolean {
        return false;
    }
}