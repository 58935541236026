import { Component, Input, OnInit } from "@angular/core";
import { DataSet } from "src/app/models/datamarket.model";

@Component({
	selector: "app-data-set-details",
	templateUrl: "./data-set-details.component.html",
	styleUrls: ["./data-set-details.component.scss"],
})
export class DataSetDetailsComponent implements OnInit {
	//@Input() datasets: DataSet[];
	@Input() selectedDataSet: DataSet;
	@Input() dataPreviewColumns;
	options: any;
	displayDataSetBuyView: boolean = false;
	//dataMarketView: dataMarketConnectorView;

	overlays: any[];
	@Input() currencyicons;
	// toggleDataSetBuyDialog(evt: boolean) {
	// 	if (evt == true) {
	// 		this.displayDataSetBuyView = false;
	// 	}
	// 	if (evt == false) {
	// 		this.displayDataSetBuyView = true;
	// 	}
	// }
	// onDataSourceSubmitted(evt: boolean) {
	// 	console.log(evt);
	// 	this.displayDataSetBuyView = evt;
	// }

	constructor() {}

	ngOnInit(): void {
		this.options = {
			center: { lat: 36.890257, lng: 30.707417 },
			zoom: 12,
		};
	}

	ngAfterViewInit() {}
}
