<app-create-workflow-dialog [sender]="sender" [selectedWorkflow]="selectedWorkflow"
    (click)="appMain.rightPanelClick = true"></app-create-workflow-dialog>
<app-open-workflow-dialog (click)="appMain.rightPanelClick = true"></app-open-workflow-dialog>
<app-create-datastore-dialog (click)="appMain.rightPanelClick = true"></app-create-datastore-dialog>
<app-create-datasource-dialog-new (click)="appMain.rightPanelClick = true"></app-create-datasource-dialog-new>
<app-load-data-to-psadialog (click)="appMain.rightPanelClick = true"></app-load-data-to-psadialog>
<app-node-data-preview (click)="appMain.rightPanelClick = true"></app-node-data-preview>

<div style="z-index: 1000000;">
    <p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
    </p-toast>
</div>

<p-overlayPanel #op>
    <div *ngIf="selectedWorkflow && selectedWorkflow.runInfo">
        <div class="p-col-12 p-mb-2 p-lg-12 p-mb-lg-0">
            <div class="field-checkbox">
                <p-checkbox [(ngModel)]="IsSimulationFlag" [binary]="true"></p-checkbox>
                <label for="binary">{{'Simulation' | translate}}</label>
            </div>
        </div>
        <div class="p-col-12 p-mb-2 p-lg-12 p-mb-lg-0" *ngIf="IsSimulationFlag === true">
            <div class="p-fluid">
                <span class="p-float-label">
                    <input type="number" id="maxRowsInput" [disabled]="inputDisabled" pInputText [(ngModel)]="maxRows">
                    <label for="maxRowsInput">{{'Max Rows' | translate}}</label>
                </span>
            </div>
        </div>
    </div>
    <div class="p-col-12 p-mb-2 p-lg-12 p-mb-lg-0" style="text-align: center;">
        <button pButton label="{{'Save' | translate }}" class="p-button-succes p-button-rounded p-button-text"
            (click)="onChangeRunMode()"></button>
    </div>

</p-overlayPanel>

<div class="p-grid" id="container" @fade
    style="top:-40px;left: -20px;width: calc(100% + 55px);padding: 0; margin: 0;height: calc(100% - 50px)">
    <div style="z-index: 1005; position:absolute;top:-58px;right:40%; display:flex;align-items: center">
        <input *ngIf="selectedWorkflow" type="text" pInputText [(ngModel)]="selectedWorkflow.name"
            (blur)="onChangeWorkflowName()" />
        <input *ngIf="!selectedWorkflow" [disabled]="true" type="text" pInputText [(ngModel)]="workflowName"
            (blur)="onChangeWorkflowName()" />
        <!-- <p-toggleButton  *ngIf="selectedWorkflow && selectedWorkflow.runInfo" [(ngModel)]="selectedWorkflow.runInfo.simulation.ReadOnly"  onLabel="'Simulation Mode'" offLabel="'Production Mode'" (onChange)="onChangeRunMode($event)"></p-toggleButton>
            <p-toggleButton  *ngIf="!selectedWorkflow || !selectedWorkflow.runInfo" [(ngModel)]="isSimulationFlag"  onLabel="'Simulation Mode'" offLabel="'Production Mode'" (onChange)="onChangeRunMode($event)"></p-toggleButton> -->
        <div stle="margin-left: 10px" *ngIf="selectedWorkflow && selectedWorkflow.runInfo">
            <p-tag class="simulationTag" value="Simulation"
                *ngIf="selectedWorkflow.runInfo.simulation.ReadOnly === true" [rounded]="true"
                (click)="op.toggle($event)"></p-tag>
            <p-tag class="simulationTag" severity="success"
                *ngIf="selectedWorkflow.runInfo.simulation.ReadOnly === false" value="Production" [rounded]="true"
                (click)="op.toggle($event)"></p-tag>
        </div>
        <!-- <h5 *ngIf="selectedWorkflow">{{selectedWorkflow.name}}</h5> -->
    </div>
    <!-- <span style="z-index: 1005; position:absolute;top:0px;left:50%" *ngIf="selectedWorkflow && selectedWorkflow.runInfo">
        <p-toggleButton [(ngModel)]="selectedWorkflow.runInfo.simulation.ReadOnly"  onLabel="'Simulation Mode'" offLabel="'Production Mode'" (onChange)="onChangeRunMode($event)"></p-toggleButton>
    </span> -->

    <div class="fadeInDown" style="z-index: 105; position:absolute;top: -10px;padding: 0; margin: 0 0px;"
        (click)="appMain.rightPanelClick = true" id="DesignerNavbar">
        <app-designer-nav-toolbar id="step-one"></app-designer-nav-toolbar>
        <!-- <app-designer-status id="step-five" style="z-index: 102; position:absolute; top: 10px;right:-40px;">
        </app-designer-status> -->
        <app-nodes-errors id="step-five"
            style="z-index: 102; position:absolute; top: 10px;right:-40px;"></app-nodes-errors>
    </div>
    <div style="z-index: 102;position:absolute;left: 0px;top:57px; width: 200px"
        (click)="appMain.rightPanelClick = true">
        <!-- <p-scrollPanel [style]="{ width: '100%' }" styleClass="custombar1">
            <p-panel header="{{'Basic' | translate}}" [toggleable]="true" [collapsed]="false">
                <app-nodes-list [plugInInfos]="plugInInfos" id="step-two" #plugInList></app-nodes-list>
            </p-panel>
            <p-scrollTop target="parent" styleClass="custom-scrolltop" [threshold]="100" icon="pi pi-arrow-up">
            </p-scrollTop>
        </p-scrollPanel> -->
        <app-nodes-list [plugInInfos]="plugInInfos" id="step-two" #plugInList></app-nodes-list>

    </div>

    <!-- <div class="p-col-3 p-md-2 p-lg-2" style="padding: 0;margin: 0 0px;" >
        <app-nodes-list [plugInInfos]="plugInInfos" id="step-two"></app-nodes-list>
    </div> -->
    <!-- <div class="p-col-12 p-md-12 p-lg-12" style="height: calc(100% - 50px);padding: 0;margin: 0 0px" >
        <app-workflow-graph [isEditor]=true #workflowGraph style="height: calc(100% - 50px);flex-basis: auto;overflow: hidden" id="step-three">
        </app-workflow-graph>
    </div> -->
    <!-- <div class="box" id="infoi" [hidden]="!displayConfig" @fade>
        <app-node-config #WorkflowConfig id="step-four" ></app-node-config>
    </div> -->

</div>

<app-workflow-graph [isEditor]=true #workflowGraph [selectedWorkflow]="selectedWorkflow" id="step-three">
</app-workflow-graph>

<!-- <div class="sticky-button">
    <button pButton [icon]="appMain.rightPanelActive ? 'pi pi-arrow-right':'pi pi-arrow-left'"
        [label]="appMain.rightPanelActive ? (translate.instant('Close Config')): (translate.instant('Close Config'))"
        (click)="toggleConfig()"></button>
</div> -->
<div class="sticky-button">
    <button pButton [icon]="appMain.rightPanelActive ? 'pi pi-arrow-right' : 'pi pi-arrow-left'"
        [label]="appMain.rightPanelActive ? (translate.instant('Close Config')) : (translate.instant('Open Config'))"
        (click)="toggleConfig()"></button>
</div>

<div class="layout-rightpanel" (click)="appMain.rightPanelClick = true">
    <div class="rightpanel-wrapper">
        <app-node-config #WorkflowConfig id="step-four"></app-node-config>
    </div>
</div>







<p-dialog [baseZIndex]="10001" [(visible)]="wizzardDialog" [modal]="true"  [draggable]="false" [closable]="false" showEffect="fade"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '70vw'}">

    <app-system-message-log></app-system-message-log>

    <div class="p-grid"  *ngIf="currentView ==='Select'">
            <!-- <div class="p-col-12" style="text-align: center;">
                <h2>Welcome to the Designer! What do you want to do?</h2>
            </div> -->
            <div class="p-col-12" style="display:flex;align-items: center;margin-bottom: 20px">
                <img [alt]="'HeaderIcon'" src="assets/layout/images/HeaderIcon_Wizard.svg" width="50"
                    style="vertical-align: middle" />
                <h2 style="margin: 0px 10px;color:#6941C6;font-weight: bold;">{{'Welcome to the Designer! What do you want to do?' | translate }}</h2>
            </div>
            <div class="p-col-6" >
                <div class="card ChoiceItem" (click)="onClickNewWorkflow()">
                    <img [style.max-width.%]="'50'" [style.max-height.%]="'50'" 
                    src="assets/layout/images/NewWf.svg"
                    style="margin-bottom: 5px; margin-top: 5px" [alt]="'Img'" />
                    <h5>{{'Create new workflow' | translate}}</h5>
                    <p>{{'You want to create a new automation? Create a workflow first and start right away!' | translate }}</p>
                </div>
            </div>
            <div class="p-col-6">
                <div class="card ChoiceItem" (click)="onClickOpenWorkflow()">
                    <img [style.max-width.%]="'50'" [style.max-height.%]="'50'" 
                    src="assets/layout/images/Open_Folder.svg"
                    style="margin-bottom: 5px; margin-top: 5px" [alt]="'Img'" />
                    <h5>{{'Open existing workflow' | translate }}</h5>
                    <p>{{'Work on an existing workflow that you have created or that someone else has shared with you.' | translate }}</p>
                </div>
            </div>
    
    </div>

    <div class="p-grid" *ngIf="currentView ==='New'">

        <div class="p-col-12" style="display:flex;align-items: center;margin-bottom: 20px">
            <img [alt]="'HeaderIcon'" src="assets/layout/images/HeaderIcon_New.svg" width="50"
                style="vertical-align: middle" />
            <h2 style="margin: 0px 10px;color:#6941C6;font-weight: bold;">{{'Create new workflow' | translate }}</h2>
        </div>
        <div class="p-col-12">
            <div class="p-grid p-fluid p-formgrid">
                <div class="p-col-12 p-mb-12 p-lg-12 p-mb-lg-0" style="margin-top: 40px;">
                    <div class="p-fluid">
                        <span class="p-float-label">
                            <input type="text" id="dsnameinputtext" pInputText
                                [(ngModel)]="newWorkflow.name">
                            <label for="dsnameinputtext">{{'Name' | translate}}</label>
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-mb-2 p-lg-12 p-mb-lg-0" style="margin-top: 40px;">
                    <div class="p-fluid">
                        <span class="p-float-label">
                            <textarea style="min-height:7rem;" id="dsdescriptiontext" [cols]="30" pInputTextarea
                                [(ngModel)]="newWorkflow.description" autoResize="autoResize"></textarea>
                            <label for="dsdescriptiontext">{{'DescriptionOptional' | translate}}</label>
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-mb-2 p-lg-12 p-mb-lg-0" style="margin-top: 40px;">
                    <div class="p-fluid">
        
                        <span class="p-float-label">
                            <label for="isSimuationInput">{{'Workflow Modus' | translate}}</label>
        
                            <p-toggleButton id="isSimuationInput" [(ngModel)]="newWorkflow.runInfo?.simulation.ReadOnly"
                                onLabel="{{'Simulation' | translate}}" offLabel="{{'Production' | translate}}"></p-toggleButton>
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-mb-2 p-lg-12 p-mb-lg-0" *ngIf="newWorkflow.runInfo?.simulation.ReadOnly === true" style="margin-top: 40px;">
                    <div class="p-fluid">
                        <span class="p-float-label">
                            <input type="number" id="maxRowsInput" pInputText [(ngModel)]="newWorkflow.runInfo?.simulation.maxRows">
                            <label for="maxRowsInput">{{'Max Rows' | translate}}</label>
                        </span>
                    </div>
                </div>
            </div>        
        </div>


    </div>




    <div class="p-grid" *ngIf="currentView === 'Open'">
        <div class="p-col-12" style="display:flex;align-items: center;margin-bottom: 20px">
            <img [alt]="'HeaderIcon'" src="assets/layout/images/Open_Folder_card.svg" width="50"
                style="vertical-align: middle" />
            <h2 style="margin: 0px 10px;color:#6941C6;font-weight: bold;">{{'Select a workflow to open' | translate }}</h2>
        </div>
        <div  class="p-col-12">
            <p-table #wt [value]="workflows" (onRowSelect)="onSelectWorkflow($event)"
            (onRowUnselect)="onUnselectWorkflow($event)" [(selection)]="wf_to_open" selectionMode="single"
            sortField="name" sortMode="single" styleClass="p-datatable-customers" [loading]="loading"
            [globalFilterFields]="['id','name','description','createdBy']" [rows]="10" paginator=true [scrollable]="false"
            styleClass="p-datatable-customers p-datatable-sm">
            <ng-template pTemplate="caption">
                <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="wt.filterGlobal($event.target.value, 'contains')"
                            placeholder="{{'Workflows.SearchForWorkflow' | translate}}" />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>
                        <div class="p-d-flex p-jc-between p-ai-center">
                            {{'Name'| translate}}
                            <p-columnFilter type="text" field="name" display="menu" class="p-ml-auto">
                            </p-columnFilter>
                        </div>
                    </th>
                    <th>
                        <div class="p-d-flex p-jc-between p-ai-center">
                            {{'Description'| translate}}
                            <p-columnFilter type="text" field="description" display="menu" class="p-ml-auto">
                            </p-columnFilter>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-workflow let-rowIndex="rowIndex">
                <tr [pSelectableRow]="workflow">
                    <td><span class="p-column-title">{{'Name'| translate}}</span>
                        {{workflow.name}}
                    </td>
                    <td><span class="p-column-title">{{'Description'| translate}}</span>
                        <span [class]="'customer-badge status-' + workflow.status">{{workflow.description}}</span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="2">{{'NoEntryFound'| translate}}</td>
                </tr>
            </ng-template>
        </p-table>
        </div>
    </div>






    <p-footer>
        <button pButton pRipple type="button" icon="pi pi-home" *ngIf="currentView == 'Select'" label="{{'Back to Home' | translate}}" (click)="backToHome()" class="p-button-text p-button-rounded p-mr-2 p-mb-2"></button>

        <button pButton pRipple type="button" icon="pi pi-arrow-left" *ngIf="currentView !== 'Select'" label="{{'Back' | translate}}" (click)="backToBegin()" class="p-button-text p-button-rounded p-mr-2 p-mb-2"></button>
        <button pButton  pRipple type="button" icon="pi pi-plus" *ngIf="currentView !== 'Select' && currentView === 'New'" class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2" label="{{'Create' | translate}}"
            [icon]="loading ? 'pi pi-spin pi-spinner' : 'pi pi-plus'" (click)="onCreateNewWorkflow()"></button>
            <button pButton  pRipple type="button" icon="pi pi-folder" *ngIf="currentView !== 'Select' && currentView === 'Open'" class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2" label="{{'Open' | translate}}"
            [icon]="loading ? 'pi pi-spin pi-spinner' : 'pi pi-folder'" (click)="openWorkflow()"></button>
    </p-footer>
</p-dialog>
