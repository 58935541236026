<div>
    <h1>Sources</h1>
    <button routerLink="/testAirbyteDialogs" routerLinkActive="active" ariaCurrentWhenActive="page">Create
        Source</button>
</div>
<p-progressBar [mode]="progressMode" [style]="{'height': '6px'}"></p-progressBar>
<div class="card">
    <p-table [value]="infos">
        <ng-template pTemplate="header">
            <tr>
                <th>ID</th>
                <th>Airbyte ID</th>
                <th>Name</th>
                <th>Connector</th>
                <th>Icon</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-src>
            <tr>
                <td><a routerLink="/testAirbyteViewSource/{{src.id}}" routerLinkActive="active"
                        ariaCurrentWhenActive="page">{{src.id}}</a></td>
                <td>{{src.airbyteSourceId}}</td>
                <td><a routerLink="/testAirbyteViewSource/{{src.id}}" routerLinkActive="active"
                        ariaCurrentWhenActive="page">{{src.source?.name}}</a> </td>
                <td>{{src.source?.sourceName}}</td>
                <!-- <td>{{src.Source?.icon}}</td> -->
                <td>
                    <!-- <div><img width="64" height="64" [src]="getSVGImageUrl(src.Source?.icon)" /></div> -->
                    <!-- <div [innerHTML]="src.Source?.icon"></div> -->
                    <!-- <div>{{src.Source?.icon}}</div> -->
                    <object type="image/svg+xml" [attr.data]="src.Source?.icon"></object>
                </td>
            </tr>
        </ng-template>
    </p-table>
    
    
</div>
