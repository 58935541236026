import { Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { PermissionLike } from "src/app/models/api/models/authorization/PermissionLike";
import { WorkflowRepositoryEntry } from "src/app/models/api/models/workflow/WorkflowRepositoryEntry";
import { Permission } from "src/app/models/authorization.model";
// import { Permission, PermissionLike } from "src/app/models/authorization.model";
import { DataSource } from "src/app/models/datasource.model";
// import { User, UserInfo } from "src/app/models/user.model";
// import { WorkflowRepositoryEntry } from "src/app/models/workflow.model";

import { DatasourcesService } from "src/app/services/datasources.service";
import { UserService } from "src/app/services/user.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { SubSink } from "subsink";

@Component({
	selector: "app-team-widget",
	templateUrl: "./team-widget.component.html",
	styleUrls: ["./team-widget.component.scss"],
})
export class TeamWidgetComponent implements OnInit {
	subs = new SubSink();
	@Input() view!: string;
	//selectedViewObjectId?: number;
	permissions: Permission[] = [];
	permissionCoutWithoutOwners: number = 0;

	constructor(
		private userService: UserService,
		private datasourceService: DatasourcesService,
		private workflowService: WorkflowsService
	) {}

	ngOnInit(): void {
		this.initGui();
		//this.getObjectPermissions();
		this.subs.sink = this.datasourceService.selectedDatasourceEmitter.subscribe(
			(res: DataSource) => {
				this.initGui(res.id);
			}
		);
		this.subs.sink = this.workflowService.selectedWorkflowEmitter.subscribe(
			(res: WorkflowRepositoryEntry) => {
				this.initGui(res.id);
			}
		);
	}

	initGui(id?: number) {
		// Create Observable
		let permissionObs = new Observable<PermissionLike[]>();
		let objectIdString: string | undefined = undefined;

		// Check if id is given
		if(id) {
			objectIdString = id.toString();
		}

		// Check for View Type and create respective Permission Observable
		if(this.view === "Data Source") {
			permissionObs = this.userService.getPermissions(objectIdString);
		}
		if(this.view === "Workflow") {
			permissionObs = this.userService.getPermissions(objectIdString);
		}
		// Subscribe to Observable
		this.subs.sink = permissionObs.subscribe((res: PermissionLike[]) => {
			this.permissions = res;

			// Create Metrics to display
			this.createPermissionMetrics(res);
		})

	}
	createPermissionMetrics(res: PermissionLike[]) {
		this.permissionCoutWithoutOwners = res.filter((res) => {
			return !res.IsOwner;
		}).length;

	}

	// getObjectPermissions(id?: number) {
	// 	this.subs.sink = this.userService
	// 		.getPermissions()
	// 		.subscribe((res: PermissionLike[]) => {
	// 			this.permissions = res.filter((res) => {
	// 				return res.Accessible.Value === this.view;
	// 			});

	// 			if (id) {
	// 				this.permissions = this.permissions.filter((res: PermissionLike) => {
	// 					let obj = <ViewObjectLike>res.Accessible;
	// 					return obj.ID === id;
	// 				});
	// 			}

	// 			console.log("permissions", this.permissions);
	// 			this.permissionCoutWithoutOwners = this.permissions.filter((res) => {
	// 				return res.IsOwner === false;
	// 			}).length;
	// 		});
	// }

	displayPermissionDialog() {
		this.userService.displayAddPermission.emit(true);
	}
}
