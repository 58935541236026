import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { of } from 'rxjs';
import { WorkflowRepositoryEntry } from 'src/app/models/api/models/workflow/WorkflowRepositoryEntry';
import { ScheduleActionPlan } from 'src/app/models/schedule.model';
import { UserDetailsRow } from 'src/app/models/user.model';
import { GeneralSearchComponent } from '../general-search/general-search.component';
import { ToolbarProvider } from './toolbar-providers';
import * as dss from 'src/app/models/datasource.model';

// export interface ToolbarProvider {
//   initObjectMenu(): MenuItem[];
//   initActionMenu(): MenuItem[];
//   subscribeToEmitters();
// }
// export class DataSourceToolbar implements ToolbarProvider {
//   constructor(private datasourcesService: DatasourcesService, private userService: UserService) { }
//   subscribeToEmitters() {
//     let selectedObs = this.datasourcesService.selectedDatasourceEmitter;

//     return selectedObs
//   }
//   initObjectMenu(): MenuItem[] {
//     return [
//       {
//         label: "New",
//         icon: "pi pi-fw pi-plus",
//         command: () => {
//           this.datasourcesService.displayCreateDatasource.emit(true)
//         }
//       },
//     ]
//   }
//   initActionMenu() {
//     return [
//       {
//         label: "Share",
//         icon: "pi pi-fw pi-send",
//         command: () => { this.userService.displayAddPermission.emit(true) }
//       },
//     ]
//   }
// }
// export class WorkflowToolbar implements ToolbarProvider {
//   constructor(private workflowsService: WorkflowsService, private userService: UserService) { }
//   subscribeToEmitters() {
//     let selectedObs = this.workflowsService.selectedWorkflowEmitter;

//     return selectedObs
//   }

//   initObjectMenu(): MenuItem[] {
//     return [
//       {
//         label: "New",
//         icon: "pi pi-fw pi-plus",
//         command: () => {
//           this.workflowsService.displayCreateWorkflow.emit(true);
//         }
//       },
//     ]
//   }
//   initActionMenu(): MenuItem[] {
//     return [
//       {
//         label: "Share",
//         icon: "pi pi-fw pi-send",
//         command: () => { this.userService.displayAddPermission.emit(true) }
//       },
//     ]
//   }

// }
// export class DestinationToolbar implements ToolbarProvider {
//   constructor(private dataStoresService: CubesService, private userService: UserService, private destService: DestinationService) { }
//   subscribeToEmitters() {
//     let selectedObs = this.dataStoresService.selectedDataStoreEmitter;

//     return selectedObs
//   }

//   initObjectMenu(): MenuItem[] {
//     return [
//       {
//         label: "New",
//         icon: "pi pi-fw pi-plus",
//         command: () => {
//           this.dataStoresService.displayNewDataStoreDialog.emit(true);
//         }
//       },
//       {
//         label: "New_New",
//         icon: "pi pi-fw pi-plus",
//         command: () => {
//           this.destService.displayNewDestinationDialog.emit(true);
//         },
//       },
//     ]
//   }
//   initActionMenu(): MenuItem[] {
//     return [
//       {
//         label: "Share",
//         icon: "pi pi-fw pi-send",
//         command: () => { this.userService.displayAddPermission.emit(true) }
//       },
//     ]
//   }
// }
// export class ScheduleToolbar implements ToolbarProvider {
//   constructor(private schedulesService: SchedulesService, private userService: UserService) { }
//   subscribeToEmitters() {
//     let selectedObs = this.schedulesService.selectedSchedulePlanEmitter;

//     return selectedObs
//   }

//   initObjectMenu(): MenuItem[] {
//     return [
//       {
//         label: "New",
//         icon: "pi pi-fw pi-plus",
//         command: () => {
//           this.schedulesService.displayCreateSchedule.emit(true);
//         }
//       },
//     ]
//   }
//   initActionMenu(): MenuItem[] {
//     return [
//       {
//         label: "Share",
//         icon: "pi pi-fw pi-send",
//         command: () => { this.userService.displayAddPermission.emit(true) }
//       },
//     ]
//   }
// }


@Component({
  selector: 'app-generic-top-toolbar',
  templateUrl: './generic-top-toolbar.component.html',
  styleUrls: ['./generic-top-toolbar.component.scss']
})
export class GenericTopToolbarComponent<T> implements OnInit {
  @Input() typeClass!: ToolbarProvider<T>;
  @Input() currentView!: string;
  navMenuItems: MenuItem[] = [];
  actionNavItems: MenuItem[] = [];
  selectedObject?: T;
  buttonDisabled: boolean = true;
  loading: boolean = false;

  constructor() { }

  ngOnInit(): void {
    //this.stateOptions = [{label: 'Off', value: 'off'}, {label: 'On', value: 'on'}];
    this.typeClass.subscribeToEmitters().subscribe((res) => {
      if (res === undefined || res === this.selectedObject) {
        this.selectedObject = undefined;
        this.buttonDisabled = true;
      } else {
        this.selectedObject = res;
        this.buttonDisabled = false;

      }
    })
  }

  setToolbar() {
    console.log("SETTOOLBAR");
    this.navMenuItems = this.typeClass.initObjectMenu();
    this.actionNavItems = this.typeClass.initActionMenu();

    return of(true);
  }
}
