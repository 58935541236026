/**
 * CSV Parser Character consisting of the name and the char, e.g. Comma -> ','
 */
export interface ParserCharacter {
    name: string,
    char: string
}

/**
 * Supports CSV interaction.
 */
export class CsvUtil {

    static readonly Empty = "";
    static readonly LineBreak = "\n";
    static readonly Comma = ",";
    static readonly Dot = ".";
    static readonly Semicolon = ";";
    static readonly DoubleQuote = "\"";
    static readonly SingleQuote = "'";
    static readonly Tab = "\t"
    static readonly Backslash = "\\";

    /**
     * Gets the most common CSV parse characters. Useful for UI selection elements.
     * @returns 
     */
    static getParserCharaters(): ParserCharacter[] {
        const chars = new Array<ParserCharacter>();

        chars.push({ name: "Backslash", char: this.Backslash });
        chars.push({ name: "Comma", char: this.Comma });
        chars.push({ name: "Dot", char: this.Dot });
        chars.push({ name: "Double Quote", char: this.DoubleQuote });
        chars.push({ name: "Line Break", char: this.LineBreak });
        chars.push({ name: "Semicolon", char: this.Semicolon });
        chars.push({ name: "Single Quote", char: this.SingleQuote });
        chars.push({ name: "Tab", char: this.Tab });

        return chars;
    }
}
