<div>
    <div class="field dropDownContainer">
        <span class="p-float-label">
            <p-multiSelect inputId="keymultiselect" appendTo="body"  *ngIf="transposeInfoView.keyColsHasError === false"
                (onChange)="onKeysOrValuesChanged($event)" [options]="transposeInfoView.availableKeyColums"
                [(ngModel)]="transposeInfoView.selectedKeyColumns" [style]="{'width':'100%'}" optionLabel="columnName"
                [filter]="true">
            </p-multiSelect>
            <p-multiSelect inputId="keymultiselect" appendTo="body" *ngIf="transposeInfoView.keyColsHasError === true" appendTo="body"
                [options]="transposeInfoView.availableKeyColums" [autoDisplayFirst]="false"
                [(ngModel)]="transposeInfoView.selectedKeyColumns" optionLabel="columnName" class="ng-invalid ng-dirty"
                (onChange)="onKeysOrValuesChanged($event)" [filter]="true" autoWidth="false"
                [style]="{'width':'100%'}"></p-multiSelect>
            <label for="keymultiselect">{{'Key Columns' |translate }}</label>
        </span>
    </div>
    <div class="field dropDownContainer">
        <span class="p-float-label">
            <p-multiSelect inputId="valmultiselect" appendTo="body" *ngIf="transposeInfoView.dataColsHasError === false"
                (onChange)="onUpdateSettings(false)" [options]="transposeInfoView.availableDataColumns"
                [(ngModel)]="transposeInfoView.selectedDataColumns" [style]="{'width':'100%'}" optionLabel="columnName"
                [filter]="true">
            </p-multiSelect>
            <p-multiSelect inputId="valmultiselect" appendTo="body" *ngIf="transposeInfoView.dataColsHasError === true"
                (onChange)="onUpdateSettings(false)" class="ng-invalid ng-dirty"
                [options]="transposeInfoView.availableDataColumns" [(ngModel)]="transposeInfoView.selectedDataColumns"
                [style]="{'width':'100%'}" optionLabel="columnName" [filter]="true">
            </p-multiSelect>
            <label for="valmultiselect">{{'Value Columns' |translate }}</label>
        </span>
    </div>
</div>