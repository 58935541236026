<p>view-ab-connections works!</p>
<p-progressBar [mode]="progressMode" [style]="{'height': '6px'}"></p-progressBar>
<p-table [value]="infos">
    <ng-template pTemplate="header">
        <tr>
            <th>ID</th>
            <th>Airbyte ID</th>
            <th>Name</th>
            <th>Status</th>
            <th>Source</th>
            <th>Aktiviert</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-conn>
        <tr>
            <td>{{conn.id}}</td>
            <td>{{conn.airbyteConnectionId}}</td>
            <!-- <td>{{conn.Connection?.name}}</td> -->
            <td><a routerLink="/testAirbyteViewConnection/{{conn.id}}" routerLinkActive="active" ariaCurrentWhenActive="page">{{conn.connection?.name}}</a></td>
            <td>{{conn.connection?.status}}</td>
            <!-- <td>{{conn.Connection?.source?.name}}</td> -->
            <td>Icon coming soon</td>
            <td><button (click)="onSync(conn)">Synchronisieren</button></td>
        </tr>
    </ng-template>
</p-table>