<p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>

<p-dialog header="{{'Export data' | translate}}" [(visible)]="displayExportDataStore" modal="modal" showEffect="fade"
    [style]="{width: '400px'}">
    <div style="line-height: 1.5">
        {{'Please specifiy your export file(s) for this datasource' | translate}}
    </div>
    <div class="p-grid p-formgrid">
        <div class="p-col-12 p-mb-2 p-lg-12 p-mb-lg-0">
            <p-selectButton [options]="exportFormatInfos" [(ngModel)]="selectedExportFormatInfo"
                (onChange)="selectExportFormat($event)" optionLabel="Name">
                <ng-template let-item>
                    <!-- <i [class]="item.icon"></i> -->
                    <!-- {{item.Name}} -->
                </ng-template>
            </p-selectButton>
        </div>
    </div>
    <div class="p-grid p-formgrid" *ngIf="selectedExportFormatInfo">
        <div class="p-col-12 p-mb-2 p-lg-12 p-mb-lg-0" [ngSwitch]="selectedExportFormatInfo.Name">
            <div *ngSwitchDefault>
                Default
            </div>
            <div *ngSwitchCase="'Excel'">
                Excel
            </div>
            <div *ngSwitchCase="'CSV'">
                CSV
            </div>
        </div>
    </div>
    <p-footer>
        <button type="button" pButton icon="pi pi-check" (click)="onDownloadFile(selectedExportFormatInfo)" label="Yes"
            class="p-button-text"></button>
        <button type="button" pButton icon="pi pi-times" (click)="displayExportDataStore=false" label="No"
            class="p-button-text"></button>
    </p-footer>
</p-dialog>
