import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { concatMap, map, mergeMap } from 'rxjs/operators';
import { DynamicItemInfo } from 'src/app/models/com-bion-json';
import { AdapterTypeInfo } from 'src/app/models/connector.model';
import { CsvConnectorView } from 'src/app/models/connectors/csv-connector-view.model';
import { ExcelConnectorView } from 'src/app/models/connectors/excel-connector-view.model';
import { JsonConnectorView } from 'src/app/models/connectors/json-connector-view.model';
import { SpssConnectorView } from 'src/app/models/connectors/spss-connector-view.model';
import { ConnectorSettingsBuilder, ConnectorView } from 'src/app/models/connectorView.model';
import { DataSource, DataSourceConnector, DataSourceField, FieldInfo } from 'src/app/models/datasource.model';
import { GuiErrorException } from 'src/app/models/gui.error.model';
import { ApiBackendService } from 'src/app/services/api-backend.service';
import { DatasourcesService } from 'src/app/services/datasources.service';
import { SystemMessageLogService } from 'src/app/services/system-message-log.service';
import { SubSink } from 'subsink';
import * as dss from "src/app/models/datasource.model";
import { CreateDataSourceNewLib } from '../create-datasource-dialog-new/createDataSourceNewLib';
import { MessageService } from 'primeng/api';
import { LoadToPsaArgs } from '../create-datasource-dialog-new/create-datasource-dialog-new.component';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-push-new-data-dialog',
  templateUrl: './push-new-data-dialog.component.html',
  styleUrls: ['./push-new-data-dialog.component.scss'],
  providers: [MessageService],
  animations: [trigger("fade", [
    state("void", style({ opacity: 0 })),
    transition(":enter", [animate(500)]),
    transition(":leave", [animate(500)]),
  ]),]
})
export class PushNewDataDialogComponent implements OnInit {
  displayPushNewDataDialog: boolean = false;
  headerText: string = "Push new data from File";
  subs = new SubSink;
  selectedDs?: DataSource;
  dsFields: DataSourceField[] = [];
  currentConnectorView?: ConnectorView<any, any, any>;
  loading: boolean = false;
  buttonDisabled: boolean = false;

  selectedAdapterInfo?: AdapterTypeInfo<any, any>;
  readonly EXCEL_ADAPTER: string = "Excel Adapter";
  readonly CSV_ADAPTER: string = "CSV Adapter";
  readonly JSON_ADAPTER: string = "JSON File Adapter";
  readonly SPSS_FILE_ADAPTER: string = "SPSS File Adapter";

  previewColumns: FieldInfo[] = [];
  previewRows: any[] = [];
  previewErrorCheckResult: Array<[DataSourceField, boolean, string]> = [];

  constructor(private datasourcesService: DatasourcesService, private translate: TranslateService, private errorService: SystemMessageLogService, private bionApi: ApiBackendService, private messageService: MessageService) { }

  ngOnInit(): void {

    const initObs = this.datasourcesService.displayPushNewDataDialogEmitter.pipe(concatMap((event: [DataSource, boolean]) => {
      this.displayPushNewDataDialog = event[1];
      this.selectedDs = event[0];

      return this.initializeDialog(event[0]);
    }))
    this.subs.sink = initObs.subscribe((res: [ConnectorView<any, any, any>, DataSourceField[]]) => {
      this.dsFields = res[1];
      const view = res[0];
      view.newFileEventEmitter().subscribe(
        new_file => {
          this.onUploadFile(new_file)
        }, (err: Error) => {
          this.errorService.handleError(err);
          this.loading = false;
        }, () => {
          this.loading = false;
        });
      //view.newFileEventEmitter().pipe(map((new_file) => {this.onUploadFile(new_file)}));
      console.log("Component successfully initialized!");

    }, (err) => {
      this.errorService.handleError(err);
    }, () => {
      this.loading = false;
    })

  }
  initializeDialog(ds: DataSource): Observable<[ConnectorView<any, any, any>, DataSourceField[]]> {
    let connectorViewObs = this.getConnectorSettings(ds).pipe(concatMap((connectorSettings) => {
      return this.getConnectorAdapterInfo(connectorSettings).pipe(concatMap((adapterInfo) => {

        console.log("current AdapterInfo", adapterInfo);
        const connectorViewObs = this.buildConnectorView(connectorSettings, adapterInfo).pipe(map(cView => {
          cView.setInitialSettings(connectorSettings.settings);
          this.currentConnectorView = cView;

          this.selectedAdapterInfo = adapterInfo;

          return cView
        }));

        return connectorViewObs;

      }))
    }));
    let dsFieldsObs = this.getDataSourceFields(ds);

    let finalObs = forkJoin(connectorViewObs, dsFieldsObs);

    return finalObs

  }
  buildConnectorView(connectorSettings: DataSourceConnector<any>, adapterInfo: AdapterTypeInfo<any, any>): Observable<ConnectorView<any, any, any>> {
    return of(this.createAdapterViewModel(adapterInfo.Name))

  }
  getConnectorAdapterInfo(connectorSettings: DataSourceConnector<any>): Observable<AdapterTypeInfo<any, any>> {

    return this.datasourcesService.getAdapterTypeInfo(undefined, connectorSettings.Connector).pipe(concatMap(adapterInfos => {

      if (adapterInfos.length === 0) {
        return throwError(new Error("There is no adapterInfo for this DataSource"))
      }
      else {
        return of(adapterInfos[0])
      }

    }))

  }
  createAdapterViewModel(name: string): ConnectorView<any, any, any> {

    if (name === this.EXCEL_ADAPTER) return new ExcelConnectorView(this.bionApi, name);
    if (name === this.CSV_ADAPTER) return new CsvConnectorView(this.bionApi, name);
    if (name === this.JSON_ADAPTER) return new JsonConnectorView(this.bionApi, name);
    if (name === this.SPSS_FILE_ADAPTER) return new SpssConnectorView(this.bionApi, name);

    throw new Error("No view found for this adapter:" + name);
  }
  /**
   * First, get connectorSettings of selected DS (Push Source)
   * @param id
   */
  getConnectorSettings(ds: DataSource): Observable<DataSourceConnector<any>> {
    return this.datasourcesService.getConnectorsInfo(ds.id).pipe(concatMap(cInfos => {

      if (cInfos.length === 0) {
        return throwError(new Error("There is no adapterInfo for this DataSource"))
      }
      else {
        return of(cInfos[0])
      }

    }))

  }
  getDataSourceFields(ds: DataSource): Observable<DataSourceField[]> {
    return this.bionApi.getDataSourceFields(undefined, ds.id)
  }

  onUploadFile(event: File) {
    this.loading = true;
    // let input = event.files;
    // let firstFile = input[0];
    const firstFile = event;
    //this.currentConnectorView.uploadFile(firstFile);
    let uploadResObs: Observable<dss.ExtractFromConnectorResult> = this.currentConnectorView.fetchMetaDataObs().pipe(concatMap(meta_data => {

      // prüfe, ob die aktuellen settings noch mit den (neuen) meta_daten übereinstimmen
      // Beispiel: Sheet in den Settings gibt es nicht mehr!
      const check = this.currentConnectorView.prüfeSettingsIntegrität(meta_data, this.currentConnectorView.connectorSettings);


      return check.pipe(concatMap((meta_data, settings) => this.updatePreview()));
      //return this.updatePreview();
    }));

    this.subs.sink = uploadResObs.subscribe(() => {
      console.log("Upload was successful");
      this.loading = false;
    }, (err: Error) => {
      this.errorService.handleError(err);
      this.loading = false;
    }, () => {
      this.loading = false;
    })

  }

  updatePreview(): Observable<dss.ExtractFromConnectorResult> {

    const previewAdapterFuture = this.currentConnectorView.getPreviewBuilder(100, 0)
      .pipe(concatMap(previewSettingsBuilder => this.getFileData()
        .pipe(concatMap(base64 => this.updateTablePreview(previewSettingsBuilder, base64)))));

    // const previewAdapterFuture = this.currentConnectorView.getPreviewBuilder(100, 0).pipe(concatMap(previewSettingsBuilder => {
    //   return this.getFileData().pipe(concatMap(base64 => {
    //     return this.updateTablePreview(previewSettingsBuilder, base64)

    //   }));
    // }));

    return previewAdapterFuture

  }
  updateTablePreview(previewAdapter: ConnectorSettingsBuilder<any>,
    base64FileData: string,
    fieldSelection?: DataSourceField[]): Observable<dss.ExtractFromConnectorResult> {

    const connector_info = new DynamicItemInfo(
      previewAdapter.getConnectorId(),
      previewAdapter.getConnectorSettings()
    );
    const load_settings = new dss.LoadSettings(true, 100);
    const arg = new dss.ExtractFromConnectorArg(
      connector_info,
      load_settings,
      base64FileData
    );

    let preview = this.datasourcesService.extractFromConnector(arg);

    let previewObs = preview.pipe(
      map((extractRes) => {
        this.data_to_preview(extractRes);
        return extractRes;
      })
    );

    return previewObs

  }

  data_to_preview(res: dss.DataTypePreview) {
    let previewResult = CreateDataSourceNewLib.data_to_preview_load(res);

    this.previewRows = previewResult;
    this.previewColumns = res.Columns;

    this.previewErrorCheckResult = this.isDsFieldCompliant(this.dsFields, res.Columns).filter((res) => { return res[1] === false });

  }



  getFileData(): Observable<string> {
    try {
      return of(this.currentConnectorView.getBase64FileData());
    } catch (e) {
      return throwError(new Error("No file data available for preview"))
    }
  }



  onLoadToPSA() {
    this.loading = true;
    this.buttonDisabled = true;

    let args: LoadToPsaArgs = {
      openDialog: true,
      dataSource: this.selectedDs,
      connectorView: this.currentConnectorView,
      dsService: this.datasourcesService,
      simulateFlag: false,
      writeMode: undefined,
      base64: CreateDataSourceNewLib.tryGetFileData(this.currentConnectorView)
    }

    this.subs.sink = CreateDataSourceNewLib.loadPSAStreamAsync(
      args.dataSource,
      args.connectorView,
      this.datasourcesService,
      args.simulateFlag,
      args.writeMode,
      args.base64).subscribe(() => {
        this.displayPushNewDataDialog = false;
        this.messageService.add({
          severity: "info",
					summary: this.translate.instant("Message.LoadDataStartedSuccess.Title"),
					detail: this.translate.instant("Message.LoadDataStartedSuccess.Text") ,
        });
      }, (error) => {
        this.errorService.handleError(error);
      }, () => {
        this.loading = false;
        this.buttonDisabled = false;
      });
  }

  isDsFieldCompliant(dsFields: DataSourceField[], previewCols: FieldInfo[]): Array<[DataSourceField, boolean, string]> {
    // Fall 1: DsField is in FieldInfos vorhanden (Name, Type)
    // Fall 1.1: Type passt
    // Fall 1.2: Type passt nicht --> Warnung anzeigen
    // Fall 2: DsField ist nicht in FieldInfos vorhanden --> Warnung anzeigen, dass DsFields und FieldInfos nicht übereinstimmen

    let resultArray: Array<[DataSourceField, boolean, string]> = [];

    for (let dsField of dsFields) {
      let previewField = previewCols.find((prevCol) => { return prevCol.Name === dsField.name });
      if (previewField) {
        if (previewField.DataType.Name === dsField.dataType) {
          resultArray.push([dsField, true, ""]);
        } else {
          resultArray.push([dsField, false, "DataType Mismatch"]);
        }
      } else {
        resultArray.push([dsField, false, "Field Mismatch"]);
      }
    }

    return resultArray;

  }

  onSettingsChanged(event) {
    console.log(this.currentConnectorView);
    let uploadResObs = this.currentConnectorView.fetchMetaDataObs().pipe(concatMap(res => {

      return this.updatePreview();

    }));

    this.subs.sink = uploadResObs.subscribe(() => {
      console.log("Upload was successful");
    }, (err: Error) => {
      this.errorService.handleError(err);
    }, () => {
      this.loading = false;
    })

  }




  // handleError(error) {
  //   // GUI Error handling
  //   if (error instanceof GuiErrorException) {
  //     let typedError = <GuiErrorException>error;
  //     this.errorService.sendErrorCode(typedError.code)
  //   }
  //   // Http Error handling

  //   if (error instanceof HttpErrorResponse) {
  //     let typedError = <HttpErrorResponse>error;
  //     this.errorService.sendHttpError(typedError);
  //   }
  //   this.errorService.sendError(<Error>error);
  // }

  resetDialog() {
    this.currentConnectorView.uploadedFile = undefined;
    this.previewColumns = [];
    this.previewRows = [];
    this.previewErrorCheckResult = [];
  }

}
