<p-toast position="top-center" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
</p-toast>

<p-dialog header="{{headerText | translate}}" [baseZIndex]="1000" [(visible)]="displayChangeSettingsDialog" modal="modal" showEffect="fade"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}" (onHide)="resetDialog()">

    <div class="spinner-container" *ngIf="loading" @fade>
        <app-loading-screen></app-loading-screen>
    </div>
    <app-system-message-log></app-system-message-log>

    <div *ngIf="selectedAdapterInfo"
        [ngSwitch]="selectedAdapterInfo.Name">
        <h6 style="color: rgb(170, 170, 170)"> <span>{{selectedAdapterInfo.Name}}</span></h6>
            <div style="margin-top: 50px;">
                <app-excel-connector *ngSwitchCase="EXCEL_ADAPTER" [(currentConnectorView)]="currentConnectorView" [isChangeSettingsOnlyFlag]="isChangeSettingsOnly">
                </app-excel-connector>
                <app-excel-url-connector *ngSwitchCase="EXCEL_URL_ADAPTER"
                    [(currentConnectorView)]="currentConnectorView">
                </app-excel-url-connector>
                <app-csv-connector *ngSwitchCase="CSV_ADAPTER" [(currentConnectorView)]="currentConnectorView" [isChangeSettingsOnlyFlag]="isChangeSettingsOnly">
                </app-csv-connector>
                <app-csv-url-connector *ngSwitchCase="CSV_URL_ADAPTER"
                    [(currentConnectorView)]="currentConnectorView">
                </app-csv-url-connector>
                <app-json-connector *ngSwitchCase="JSON_ADAPTER" [(currentConnectorView)]="currentConnectorView" [isChangeSettingsOnlyFlag]="isChangeSettingsOnly">
                </app-json-connector>
                <app-jdbc-connector *ngSwitchCase="JDBC_ADAPTER" [(currentConnectorView)]="currentConnectorView">
                </app-jdbc-connector>
                <app-postgre-connector *ngSwitchCase="POSTGRE_ADAPTER"
                    [(currentConnectorView)]="currentConnectorView">
                </app-postgre-connector>
                <app-h2-embedded-connector *ngSwitchCase="H2_EMBEDDED_ADAPTER"
                    [(currentConnectorView)]="currentConnectorView">
                </app-h2-embedded-connector>
                <app-hsql-embedded-connector *ngSwitchCase="HSQL_EMBEDDED_ADAPTER"
                    [(currentConnectorView)]="currentConnectorView">
                </app-hsql-embedded-connector>
                <app-h2-server-connector *ngSwitchCase="H2_SERVER_ADAPTER"
                    [(currentConnectorView)]="currentConnectorView">
                </app-h2-server-connector>
                <app-hsql-server-connector *ngSwitchCase="HSQL_SERVER_ADAPTER"
                    [(currentConnectorView)]="currentConnectorView">
                </app-hsql-server-connector>
                <app-db2-connector *ngSwitchCase="DB2_ADAPTER" [(currentConnectorView)]="currentConnectorView">
                </app-db2-connector>
                <app-maria-db-connector *ngSwitchCase="MARIA_DB_ADAPTER"
                    [(currentConnectorView)]="currentConnectorView">
                </app-maria-db-connector>
                <app-redshift-connector *ngSwitchCase="REDSHIFT_ADAPTER"
                    [(currentConnectorView)]="currentConnectorView">
                </app-redshift-connector>
                <app-twitter-connector *ngSwitchCase="TWITTER_ADAPTER"
                    [(currentConnectorView)]="currentConnectorView">
                </app-twitter-connector>
                <app-datamarket-connector *ngSwitchCase="DATAMARKET_ADAPTER"
                    [(currentConnectorView)]="currentConnectorView">
                </app-datamarket-connector>
                <app-rest-api-connector *ngSwitchCase="REST_API_ADAPTER"
                    [(currentConnectorView)]="currentConnectorView">
                </app-rest-api-connector>
                <app-spss-connector *ngSwitchCase="SPSS_FILE_ADAPTER" [isChangeSettingsOnlyFlag]="isChangeSettingsOnly"
                [(currentConnectorView)]="currentConnectorView">
                </app-spss-connector>
                <app-postgres-sql-server-singer-connector *ngSwitchCase="MELTANOO_POSTGRE_ADAPTER"
                [(currentConnectorView)]="currentConnectorView">
                </app-postgres-sql-server-singer-connector>
                <app-sap-connector *ngSwitchCase="SAP_ADAPTER"
                [(currentConnectorView)]="currentConnectorView">
                </app-sap-connector>
            </div>
    </div>

    <p-footer>
        <button pButton pRipple type="button" icon="pi pi-upload" (click)="onChangeSettings()"
            class="p-button-rounded p-button-success p-button-text  p-button-outlined p-mr-2 p-mb-2" label="{{'Save' | translate}}"
            [icon]="loading ? 'pi pi-spin pi-spinner' : 'pi pi-upload'" [disabled]="buttonDisabled"></button>
    </p-footer>
</p-dialog>