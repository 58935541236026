export namespace RemoveDuplicatesPlugIn {
    export class RemoveDuplicatesInfo {
        ColumnNames: Array<string>;
        DropMode: string;
        DropAllCols: boolean;
        constructor(ColumnNames: Array<string>,DropMode: string,DropAllCols: boolean) {
            if(ColumnNames === undefined) throw new Error( "Class 'RemoveDuplicatesInfo': Required value 'ColumnNames' is undefined" );
            if(DropMode === undefined) throw new Error( "Class 'RemoveDuplicatesInfo': Required value 'DropMode' is undefined" );
            if(DropAllCols === undefined) throw new Error( "Class 'RemoveDuplicatesInfo': Required value 'DropAllCols' is undefined" );
            this.ColumnNames = ColumnNames;
            this.DropMode = DropMode;
            this.DropAllCols = DropAllCols;
        }
    }
    export class Settings {
        RemoveDuplicateInfos: Array<RemoveDuplicatesInfo>;
        constructor(RemoveDuplicateInfos: Array<RemoveDuplicatesInfo>) {
            if(RemoveDuplicateInfos === undefined) throw new Error( "Class 'Settings': Required value 'RemoveDuplicatesInfos' is undefined" );
            this.RemoveDuplicateInfos = RemoveDuplicateInfos;
        }
    }
}