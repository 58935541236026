import { DataStore } from "src/app/models/datastore.model";
import { PowerBiEntry } from "../../../models/api/models/powerbi-report/PowerBiEntry";
import { DropDownEntry, HeaderRow, RecordProvider, RecordView, RecordViewSorter } from "./record-provider";
import * as dss from 'src/app/models/datasource.model';
import { Observable } from "rxjs/internal/Observable";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { ObjectSearchService } from "src/app/services/object-search.service";
import { UtilFunctionsService } from "src/app/services/util-functions.service";
import { UserDetailsRow } from "src/app/models/user.model";
import { PowerbiService } from "../../reports/powerbi.service";
import { of } from "rxjs/internal/observable/of";
import { MenuItem } from "primeng/api";
import { SubSink } from "subsink";
import { DropDownModel } from "../general-search/drop-down-model";
import { FilterProvider } from "../general-search/filter-provider";
import { PowerBiPermission } from "src/app/models/api/models/powerbi-report/PowerBiPermission";
import { RichtPowerBiCake } from "src/app/models/api/models/powerbi-report/RichPowerBiCake";
import { PowerBiActionEvent, PowerBiDialogActionType } from "src/app/models/api/models/powerbi-report/PowerBiActionEvent";
import { PowerBiReportService } from "src/app/services/power-bi-report.service";
import { WorkflowDialogActionType } from "src/app/models/dialog-actions.model";

export class PowerBiRecordView implements RecordView<PowerBiEntry, dss.ObjectProtocolRow> {
    object: PowerBiEntry;
    lastUpdated?: dss.ObjectProtocolRow;
    //info: Workflow;
    //nodes: WorkflowNodeGuiInfo[]= [];
    permissions: PowerBiPermission[]= [];
    //schedulePlans: ScheduleActionPlan[]= [];
    //datasources: dss.DataSource[]= [];
    //datastores: DataStore[] = [];
    loading: boolean = false;
    image?: any;
    shared?:any[];

    constructor(object: PowerBiEntry) {
      this.object = object;

    }
    info: any;
  }

  class FilterUtil {
    static filterObjects(params: [Map<string, DropDownModel>, Map<string, FilterProvider<any>>], objList: PowerBiRecordView[]) {

      let intermediateDataSets = objList;

      for (let providerEntry of params[1].keys()) {

        const provider = params[1].get(providerEntry);
        if (provider === undefined) continue;

        // TODO: get all filters from drop downs
        //console.log("Has Entry");
        //console.log(params[0].has(providerEntry));

        console.log("Get Entry");
        const xxx = params[0].get(providerEntry);
        console.log(xxx);

        const dropDownSelection: DropDownEntry<{}>[] | undefined = params[0].get(providerEntry)?.selection;

        console.log("selection");
        console.log(dropDownSelection);

        if (dropDownSelection === undefined) continue;
        const dropDownValues = dropDownSelection.map(s => s.value);

        if (dropDownValues.length == 0) continue;

        console.log(intermediateDataSets);

        intermediateDataSets = intermediateDataSets.filter(ds => {
          const foundValues = dropDownValues.filter(va => {
            return provider.filter(va, ds)
          });
          //console.log(foundValues);
          return foundValues.length > 0
        });
      }
      return intermediateDataSets;
    }
  }

export class PowerBiViewRecord implements RecordProvider<PowerBiEntry, PowerBiRecordView, RichtPowerBiCake.PowerBiView[], dss.ObjectProtocolRow> {

    constructor(private data: RichtPowerBiCake.PowerBiView[],private usersDetails: UserDetailsRow[] ,private biService: PowerBiReportService,
      private errorService: SystemMessageLogService, private objectFilterService: ObjectSearchService, private utilService: UtilFunctionsService) {

    }

    getData(): Observable<RichtPowerBiCake.PowerBiView[]> {
      return of(this.data);
    }
    asRecord(arg: PowerBiEntry, data: RichtPowerBiCake.PowerBiView[]): PowerBiRecordView {

      const wf = data.find(d => d.Entry.id == arg.id);

      if(wf === undefined) throw new Error("The report with id " + arg.id + " does not exist");

      const view = new PowerBiRecordView(arg);

      view.object = wf.Entry;
      view.permissions = wf.Permissions.map(p => p.Entry);
      // view.datasources = wf.DataSources;
      // view.datastores = wf.DataStores;
      //view.schedulePlans = wf.SchedulePlan;
      //view.info = wf.Workflow.workflowData;


      const permSorted = view.permissions.sort((a, b) => {
        if (a.IsOwner === b.IsOwner) {
          return 0;
        }
        return a.IsOwner ? -1 : 1;
      })

    view.shared = permSorted.map((p) => {

        const userdetailinfos = this.usersDetails.find ( (detail) => {return p.Role == detail.id});

        if(userdetailinfos && userdetailinfos.avatar) {
          
          const image = this.utilService.int8ArrayToBase64Image(userdetailinfos.avatar)
          return image;
        } else {
            return undefined;
        }
    })


      // let objProtocol = wf.Protocols.map(protocol => protocol.Entry);
      // //console.log("OjbProtocol", objProtocol);
      // let latestProtocol = objProtocol.reduce((a, b) => {
      //   return new Date(a.start) > new Date(b.start) ? a : b;
      // });

      // view.lastUpdated = latestProtocol;

    //   // function to get user avatar
    //   const userFound = this.usersDetails.find((user) => { return user.id === latestProtocol?.userId});

    //   if(userFound && userFound.avatar) {
    //       view.image = this.utilService.int8ArrayToBase64Image(userFound.avatar);
    //   } else {
    //       view.image = undefined;
    //   }


      return view;
    }
    asHeader(): HeaderRow[] {
      const cols: HeaderRow[] = [
        { field: 'object.title', header: 'Report', width: "65%" },
        //{ field: 'object.runInfo.simulation.ReadOnly', header: 'Mode', width: "10%" },
        // { field: 'shared', header: 'SharedWith', width: "10%" },
        // { field: 'lastUpdated.start', header: 'LastUpdated', width: "15%" },
        // { field: 'lastUpdated.user', header: 'by', width: "10%" },
      ];

      return cols;
    }
    asActions(): MenuItem[] {
      const dialogActions: MenuItem[] = [
        {
          label: 'Change', tooltip: "Report.Edit", styleClass: "p-button-rounded p-button-text p-mr-2 p-mb-2", icon: "pi pi-info", command: () => {
              this.biService.PowerBiActionEmitter.emit(new PowerBiActionEvent(true,"Edit report",PowerBiDialogActionType.editReport,"Edit",this.selectedReport))
            //this.workflowsService.displayEditWorkflow.emit(true);

          }
        },
        {
          label: 'Delete', tooltip: "Report.Delete", styleClass: "p-button-rounded p-button-danger p-button-text p-mr-2 p-mb-2", icon: "pi pi-trash", command: () => {
            this.biService.PowerBiActionEmitter.emit(new PowerBiActionEvent(true,"Delete report",PowerBiDialogActionType.deleteReport,"Delete",this.selectedReport))

            //this.workflowsService.displayDeleteWorkflow.emit([this.selectedWorkflow,true]);

          }
        },
      ];

      return dialogActions;
    }

    subs = new SubSink;
    //usersDetails: UserDetailsRow[] = [];
    selectedReport?: PowerBiEntry;

    subscribeToEmitter(objList: PowerBiEntry[]): void {
      this.subs.sink = this.biService.PowerBiActionEmitter.subscribe((res: PowerBiActionEvent) => {

            this.selectedReport = res.report;
            console.log(this.selectedReport);
       
        
      },
        (err: Error) => {
          this.errorService.handleError(err);
        });
      this.subs.sink = this.objectFilterService.objectSearchParamsEmitter.subscribe((params) => {

        this.subs.sink = this.getData().subscribe((finalRecordResult) => {
          if (objList) {
            let objListViews: PowerBiRecordView[] = objList.map((obj) => {
              return this.asRecord(obj, finalRecordResult);
            });
            let filteredObjects = this.filterObjects(params, objListViews);

            this.objectFilterService.emitChangedFilteredObjectList(filteredObjects);

            console.log("Filtered Objects", filteredObjects);
          }
        },
          (err: Error) => {
            this.errorService.handleError(err);
          });
      });
    }
    emitObjectAction(action: string, object: PowerBiRecordView): void {
    //   if (action === "CreateNewObject") {
    //     console.log("CreateNewObject")
    //     //this.workflowsService.displayCreateWorkflow.emit(true);
    //     this.biService.workflowDialogActionSendEmitter.emit(new PowerBiActionEvent(true,"New workflow",PowerBiDialogActionType.ReportSelected,"Open", undefined))

    //     return
    //   }
    //   this.biService.selectedMenuWorkflowEmitter.emit(object.object);
    }
    filterObjects(params: [Map<string, DropDownModel>, Map<string, FilterProvider<any>>], objList: PowerBiRecordView[]) {
      return FilterUtil.filterObjects(params, objList);
    }
    sortEntries(entries: PowerBiRecordView[]): PowerBiRecordView[] {
      let sortedProtocols = entries.sort(
        (objA, objB) => RecordViewSorter.sortLastUpdate(objA,objB)
      );

      return sortedProtocols;
    }
}
