export class CreatePsaArg {
    DataSource: number;
    PsaTable?: string;
    PsaWriteMode: string;
    constructor(DataSource: number,PsaWriteMode: string,PsaTable?: string) {
        if(DataSource === undefined) throw new Error( "Class 'CreatePsaArg': Required value 'DataSource' is undefined" );
        if(PsaWriteMode === undefined) throw new Error( "Class 'CreatePsaArg': Required value 'PsaWriteMode' is undefined" );
        this.DataSource = DataSource;
        this.PsaTable = PsaTable;
        this.PsaWriteMode = PsaWriteMode;
    }
}