import { Component, OnInit, ViewChild } from '@angular/core';
import { IntegratedSourceModel } from 'src/app/models/api/models/IntegratedSourceModel';
import { IntegratedSourceService } from 'src/app/services/integrated-source.service';
import { ChooseConnectorIntComponent } from '../choose-connector-int/choose-connector-int.component';
import { ConsoleLogger, LogLevel } from 'src/app/views/designer/components/workflow-graph/logger';
import { AsAirbyteCatalog, AsBionCatalog, AsCatalog, GenCatalog } from './define-streams.model';
import { Id } from 'src/app/helper/id';
import { IntegratedSourceController } from 'src/app/models/api/controllers/IntegratedSourceController';

enum Steps {
  SelectConnector,
  CreateSource,
  DefineStreams
}

@Component({
  selector: 'app-create-datasource-int',
  templateUrl: './create-datasource-int.component.html',
  styleUrls: ['./create-datasource-int.component.scss']
})
export class CreateDatasourceIntComponent implements OnInit {

  @ViewChild('chooseConnector', { static: true }) public chooseConnectorComponent: ChooseConnectorIntComponent;

  constructor(private service_api: IntegratedSourceService) { }

  ngOnInit(): void {
    this.setLoading(true);
    this.service_api.getConnectors().subscribe(
      connectors => this.handle_connectors(connectors),
      err => this.handle_error(err),
      () => this.setLoading(false))
  }

  currentStep: Steps = Steps.SelectConnector;  // Step indizes für die NgIf statements.

  // Flow Steps
  // 1. Select Connector
  // 2.1 Get Connector Specs
  // 2.2 Create Data Source
  // 3.1 Get Catalog
  // 3.2 Define Streams

  private logger = new ConsoleLogger("CreateDatasourceIntComponent", LogLevel.Info);
  /**
   * Alle verfügbaren Konnektoren
   */
  connector_infos: IntegratedSourceController.ConnectorInfo[] = [];
  is_loading = false;                                   // API wird ausgeführt  
  selected_connector?: IntegratedSourceController.ConnectorInfo = undefined;       // Ausgewählter Konnektor
  selected_connector_spec?: IntegratedSourceModel.ConnectorSpec<string, any>; // Ausgewählte Konnektor Informationen (Konfig, Typ, ...)
  datasource_name: string = "";
  config_to_display:string = "";  // Airbyte or Bion

  // Bion und Airbyte können nicht nahtlos integriert werden, deswegen macht die Komponente einzelne Fallunterscheidungen.
  readonly BionConfig = "Bion";
  readonly AirbyteConfig = "Airbyte";

  setLoading(isLoading: boolean) {
    this.is_loading = isLoading;
  }

  // Just forward, not backward!!!!
  gotoDefineStreams(r: IntegratedSourceModel.CreateDataSourceResult<any>) {
    this.currentStep = Steps.DefineStreams;
    // Prepare streams component

    const get_cat_arg = new IntegratedSourceModel.GetCatalogArg(r.SourceKey);
    this.service_api.getCatalog(get_cat_arg).subscribe(cat => {
      switch (r.SourceKey.Origin) {
        case this.BionConfig: this.cat_tc = new AsBionCatalog();
          break;
        case this.AirbyteConfig: this.cat_tc = new AsAirbyteCatalog();
          break;
        default:
          throw new Error("No Typeclass for catalog type " + r.SourceKey.Origin + " available!");
      }

      if (this.cat_tc === undefined) throw new Error("No Typeclass for catalog type " + r.SourceKey.Origin + " available!");

      this.original_catalog = cat.OriginalResult;
      this.generic_catalog = this.cat_tc.toGenCatalog(this.original_catalog);
      // initialize streams component
    })
  }

  on_streams_defined(event: GenCatalog) {

    const tc = Id.assertSet(this.cat_tc, new Error("Catalog typeclass not set"));
    const old_cat = Id.assertSet(this.original_catalog, new Error("Original Catalog not set"));
    const ds_key = Id.assertSet(this.datasource_key, new Error("Datasource key not set"));

    // todo: use deep clones (?)
    this.logger.warn("Check if we should use a deep clone to reduce side effects.");
    const new_cat = tc.patchCatalog(event, old_cat);

    const api_arg = new IntegratedSourceModel.DefineStreamsArg(ds_key, new_cat);
    this.service_api.defineStreams(api_arg).subscribe(
      define_stream_res => this.handle_define_streams_result(define_stream_res),
      err => this.handle_error(err),
      () => this.setLoading(false)
    )
  }

  handle_define_streams_result(r: IntegratedSourceModel.DefineStreamsResult<unknown>) {
    this.logger.info("Streams successfully created");
    this.setLoading(false);
  }

  cat_tc?: AsCatalog<any> = undefined;  // Katalog-Typklasse für generischen Katalog & Kind-Komponente
  original_catalog?: any = undefined;   // Orignal Katalog von Bion oder Airbyte
  generic_catalog?: GenCatalog;         // Generischer Katalog für die Kind-Komponente

  /**
   * Alle Konnektoren wurden geholt
   * @param conns Konnektoren
   */
  handle_connectors(conns: IntegratedSourceController.ConnectorInfo[]) {
    this.connector_infos = conns;
  }

  /**
   * Konnektor Ausgewählt
   * @param con Konnektor Art und Schlüssel
   */
  on_connector_selected(con: IntegratedSourceController.ConnectorInfo) {
    this.selected_connector = con;

    this.is_loading = true;
    const sub = this.service_api.getConnectorSpecs(con.Key).subscribe(
      spec_res => this.handle_spec_result(spec_res),
      err => this.handle_error(err),
      () => this.setLoading(false)
    );

    // TODO: handle sub
    this.logger.warn("TODO: handle Subscription variable");
  }

  /**
   * Läd die Konnektor Konfiguration.
   * @param result Konnektor Spezifikationen
   */
  handle_spec_result(result: IntegratedSourceModel.ConnectorSpec<string, any>) {
    this.selected_connector_spec = result;
    this.currentStep = Steps.CreateSource;
    this.config_to_display = result.ConnectorId.Origin;
    this.setLoading(false);
  }

  // == Set Config
  on_airbyte_config_set(config:any) {

  }

  on_bion_config_set(config:any) {

  }

  // == Create Data Source

  /**
   * Erstellt die neuen Datasource
   */
  on_create_datasource() {

    try {

      // TODO: Check user input
      const con_key = this.selected_connector.Key;
      let config: any = undefined;
      switch (this.selected_connector.Key.Origin) {
        case this.BionConfig: config = this.get_bion_config();
          break;
        case this.AirbyteConfig: config = this.get_airbyte_config();
          break;
        default:
          throw new Error("Origin of " + this.selected_connector?.Key.Origin + " is unknown");
      }

      const name: string = this.datasource_name;
      this.validate_ds_name(name);

      const arg = new IntegratedSourceModel.CreateDataSourceArg(con_key, config, name);

      this.service_api.createDataSource(arg).subscribe(
        ds_result => this.handle_create_ds_result(ds_result),
        err => this.handle_error(err),
        () => this.setLoading(false)
      );
    }
    catch (e) {
      this.handle_error(e);
    } finally {
      this.setLoading(false);
    }
  }

  datasource_key?: IntegratedSourceModel.DataSourceKey<number>;

  /**
   * 
   * @param result Create Datasource Ergebnis
   */
  handle_create_ds_result(result: IntegratedSourceModel.CreateDataSourceResult<any>) {
    // TODO: Goto Select Streams Dialog
    this.logger.info("Data Source successfully created!", result);
    this.setLoading(false);
    this.datasource_key = result.SourceKey;

    this.gotoDefineStreams(result);
  }

  handle_error(e: Error) {
    this.logger.error("Error Handler", e);
    this.setLoading(false);
  }

  /**
   * Holt die Konfiguration aus dem speziellen Bion Dialog
   */
  get_bion_config(): any {
    throw new Error("Not Implemented");
  }
  /**
   * Holt die Konfiguration aus dem Formly Dialog
   */
  get_airbyte_config(): any {
    throw new Error("Not Implemented");
  }

  /**
   * Überprüft den Datasource Namen. Leere Namen sind nicht erlaubt.
   * @param name Datasource Name
   */
  validate_ds_name(name: string) {
    if (name.length == 0) throw new Error("The datasource name must not be empty");
  }
}
