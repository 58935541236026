import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { forkJoin } from "rxjs";
import { DropDownEvents, TabViewEvents } from "src/app/helper/events";
import { Id } from "src/app/helper/id";
import { WorkflowRepositoryEntry } from "src/app/models/api/models/workflow/WorkflowRepositoryEntry";
import { DataSource, DataSourceConnector } from "src/app/models/datasource.model";
import { BaseActionActionEvent, BaseActionDialogActionType } from "src/app/models/dialog-actions.model";
import {
	ExtractDataSourceAction,
	RunWorkflowAction,
	ScheduleActionPlan,
	ScheduleBaseAction
} from "src/app/models/schedule.model";
import { DatasourcesService } from "src/app/services/datasources.service";
import { SchedulesService } from "src/app/services/schedules.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { SubSink } from "subsink";

export interface ActionTypeOption {
	name: string;
	value: string;
	icon: string;
}

@Component({
	selector: "app-create-action-dialog",
	templateUrl: "./create-action-dialog.component.html",
	styleUrls: ["./create-action-dialog.component.scss"],
	providers: [MessageService]
})
export class CreateActionDialogComponent implements OnInit, OnDestroy {
	private subs = new SubSink();
	index: number = 0;
	headerText: string = "";
	isNew: boolean = true;
	savingInProgress: boolean = false;
	@Input() baseActions!: ScheduleBaseAction[];
	@Input() schedules!: ScheduleActionPlan[];
	@Input() selectedSchedule?: ScheduleActionPlan;

	selectedAction?: ScheduleBaseAction;
	displayNewAction: boolean = false;
	actionDescription?: string;
	actionTypeOptions: ActionTypeOption[] = [];
	actionType?: ActionTypeOption;

	datasources: DataSource[] = [];
	selectedDatasource?: DataSource;
	workflows: WorkflowRepositoryEntry[] = [];
	selectedWorkflow?: WorkflowRepositoryEntry;

	readonly ExtractType = "models.scheduler.ExtractDataSourceAction";
	readonly WorkflowType = "models.scheduler.RunWorkflowAction";

	constructor(
		private scheduleService: SchedulesService,
		private datasourceService: DatasourcesService,
		private workflowService: WorkflowsService,
		private systemLogService: SystemMessageLogService,
		private messageService: MessageService,
		private translate: TranslateService
	) {
		this.actionTypeOptions = <ActionTypeOption[]>[
			{ name: "Extract", value: this.ExtractType, icon: "pi pi-table" },
			{ name: "Run", value: this.WorkflowType, icon: "pi pi-share-alt" },
		];
	}
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngOnInit(): void {
		// SUBSCRIBE TO DATA
		this.initDataSources();
		this.subs.sink = this.workflowService
			.getWorkflowObjectList()
			.subscribe((wf: WorkflowRepositoryEntry[]) => {
				this.workflows = wf;
			}, (err: Error) => {
				this.systemLogService.handleError(err);
			});

		this.subs.sink = this.scheduleService.baseActionDialogActionSendEmitter.subscribe(
			(sc: BaseActionActionEvent) => {

				if(sc.actionType === BaseActionDialogActionType.createAction) {
					this.selectedAction = sc.action;
					this.displayNewAction = sc.display;
					this.headerText = sc.header;
					this.isNew = true;
					this.actionType = this.actionTypeOptions[0];
					this.selectedDatasource = this.datasources[0];
					this.index = 0;
				};
				if(sc.actionType === BaseActionDialogActionType.editAction) {
					this.selectedAction = sc.action;
					this.displayNewAction = sc.display;
					this.headerText = sc.header;
					this.isNew = false;
					this.actionDescription = this.selectedAction?.description;
					this.actionType = this.actionTypeOptions.find(
						(i) => i.value === this.selectedAction?._type
					);
					this.index = this.actionTypeOptions.findIndex(
						(item) => item === this.actionType
					);
					this.selectedSchedule = this.schedules.find((sc: ScheduleActionPlan) => sc.id === this.selectedAction?.actionPlan);

					if (this.selectedAction?._type === this.ExtractType) {
						let extractAction = <ExtractDataSourceAction>this.selectedAction;
						const datasource_opt = this.datasources.find(ds => ds.id === extractAction.dataSource);
						if (datasource_opt) {
							this.selectedDatasource = { ...datasource_opt };
						}
					}
					if (this.selectedAction?._type === this.WorkflowType) {
						let extractAction = <RunWorkflowAction>this.selectedAction;
						this.selectedWorkflow = this.workflows.find(
							(i) => i.id === extractAction.workflow
						);
					}
				};
				if (!sc.action) return;


			}, (err: Error) => {
				this.systemLogService.handleError(err);
			}
		);
		this.subs.sink = this.scheduleService.actionsChangedEmitter.subscribe(() => {
			this.subs.sink = this.scheduleService.getScheduleActions(undefined, this.selectedSchedule?.id).subscribe((res) => {
				this.baseActions = res;
			})
		}, (err: Error) => {
			this.systemLogService.handleError(err);
		});
	}

	initDataSources() {
		let datasourcesObs = this.datasourceService
			.getDatasources();
		let connectorInfosObs = this.datasourceService.getConnectorsInfo();
		let dsAdapterObs = this.datasourceService.getAdapterTypeInfo();

		this.subs.sink = forkJoin(datasourcesObs, connectorInfosObs, dsAdapterObs).subscribe((dsResults) => {
			const datasources = dsResults[0];
			const adapters = dsResults[2];
			const connectorInfos = dsResults[1];

			let pullSourceAdapters = adapters.filter((adapter) => {
				return adapter.Info.IsPullSource
			});

			let pullSourceConnectorInfos: DataSourceConnector<any>[] = [];

			for (let connector of connectorInfos) {
				for (let adapter of pullSourceAdapters) {
					if (adapter.Name === connector.Connector) {
						pullSourceConnectorInfos.push(connector)
					}
				}
			}

			const pullDataSources: DataSource[] = [];

			for (let ds of datasources) {
				for (let ci of pullSourceConnectorInfos) {
					if (ci.DataSource === ds.id) {
						pullDataSources.push(ds)
					}
				}
			}

			this.datasources = pullDataSources;
		}, (err: Error) => {
			this.systemLogService.handleError(err);
		});

	}

	resetCurrentForm() {
		this.actionType = undefined;
		this.actionDescription = undefined;
		this.selectedDatasource = undefined;
		this.selectedWorkflow = undefined;
	}
	validateCurrentForm() {
		this.savingInProgress = true;
		if (this.isNew) {
			this.createAction();
		}
		if (!this.isNew) {
			this.updateAction();
		}
	}

	onValidateCurrentForm() {
		try {
			this.validateCurrentForm();
		} catch (e) {
			console.log(e);
			const err = <Error>e;
			alert(err.message);
		}
	}


	onSelectSchedule(evt:DropDownEvents.OnChange<PointerEvent,ScheduleActionPlan>) {
		this.selectedSchedule = evt.value;
	}

	selectActionType(event: TabViewEvents.OnChange<PointerEvent>) {
		console.log(event);
		let index: number = event.index;

		//this.selectedWriteModeOption = <WriteMode>event.value;
		let selectActionType = this.actionTypeOptions[index];
		this.actionType = selectActionType;

		if (selectActionType.value === this.ExtractType) {
			if (!this.selectedDatasource) {
				this.selectedDatasource = this.datasources[0];
			}
		}
		if (selectActionType.value === this.WorkflowType) {
			if (!this.selectedWorkflow) {
				this.selectedWorkflow = this.workflows[0];
			}
		}
	}
	onSelectExtractAction(evt:DropDownEvents.OnChange<PointerEvent,DataSource>) {
		console.log("Boom!");
		this.selectedDatasource = evt.value;
	}
	onSelectWorkflowAction(evt:DropDownEvents.OnChange<PointerEvent,WorkflowRepositoryEntry>) {
		this.selectedWorkflow = evt.value;
	}

	createAction() {
		let action: ScheduleBaseAction | undefined = undefined;

		const id = -1;
		const user = -1;
		const description = this.actionDescription;
		const selected_schedule = Id.assertSet(this.selectedSchedule, new Error("No Schedule selected"));
		const action_plan = selected_schedule.id;

		// Get action index
		let indexList = this.baseActions
			.filter((action) => action.actionPlan === selected_schedule.id)
			.map((base_action) => base_action.index);

		let index: number;
		if (indexList.length == 0) {
			index = 0;
		} else {
			index = indexList.sort((a, b) => b - a)[0] + 1;
		}

		if (this.actionType?.name === "Extract") {
			const datasource = Id.assertSet(this.selectedDatasource, new Error("Data Source is not set"));
			action = new ExtractDataSourceAction(id, action_plan, index, datasource.id, user, description);
		}

		if (this.actionType?.name === "Run") {
			const workflow = Id.assertSet(this.selectedWorkflow, new Error("Workflow is not set"));
			action = new RunWorkflowAction(id, action_plan, index, workflow.id, user, description);
		}

		if (action === undefined) throw new Error("Actiontype " + this.actionType?.name + " is unknown");

		this.subs.sink = this.scheduleService
			.createScheduleActions(action)
			.subscribe(
				(res: ScheduleBaseAction) => {
					console.log(res);
					this.scheduleService.actionsChangedEmitter.emit("New Action");
					//this.scheduleService.emitNewAction(res);

					this.messageService.add({
						severity: "success",
						summary: this.translate.instant("Message.CreateActionSuccess.Title"),
						detail: this.translate.instant("Message.CreateActionSuccess.Text1") + res.id +
						this.translate.instant("Message.CreateActionSuccess.Text2"),
						// summary: "Action created!",
						// detail: "Action with id " + res.id + " was successfully created",
					});

					this.resetCurrentForm();

					this.displayNewAction = false;
				},
				(err) => {
					this.systemLogService.handleError(err);
				}, () => {
					this.savingInProgress = false;

				}
			);
	}

	updateAction() {

		const selected_action = Id.assertSet(this.selectedAction, new Error("No action selected"));

		let copy: ScheduleBaseAction = selected_action;

		if (copy._type === this.actionType?.value) {
			if (copy._type === this.ExtractType) {

				const ds_action = <ExtractDataSourceAction>copy;
				const datasource = Id.assertSet(this.selectedDatasource, new Error("Data Source is not set"));
				ds_action.dataSource = datasource.id;
				copy = ds_action;
			}
			if (copy._type === this.WorkflowType) {

				const wf_action = <RunWorkflowAction>copy;
				const workflow = Id.assertSet(this.selectedWorkflow, new Error("Workflow is not set"));
				wf_action.workflow = workflow.id;
				copy = wf_action;
			}
		} else {
			if (this.actionType?.value === this.ExtractType) {
				const datasource = Id.assertSet(this.selectedDatasource, new Error("Data Source is not set"));
				console.log("WARNING: Description could be updated falsely. Please check this!");
				copy = new ExtractDataSourceAction(copy.id, copy.actionPlan, copy.index, datasource.id, copy.user);
			}
			if (this.actionType?.value === this.WorkflowType) {

				const workflow = Id.assertSet(this.selectedWorkflow, new Error("Workflow is not set"));
				console.log("WARNING: Description could be updated falsely. Please check this!");
				copy = new RunWorkflowAction(copy.id, copy.actionPlan, copy.index, workflow.id, copy.user);
			}
		}

		copy.description = this.actionDescription;

		this.subs.sink = this.scheduleService.updateScheduleActions(copy).subscribe(
			(res) => {
				this.scheduleService.actionsChangedEmitter.emit("New Action");

				this.messageService.add({
					severity: "success",
					summary: this.translate.instant("Message.UpdateActionSuccess.Title"),
					detail: this.translate.instant("Message.UpdateActionSuccess.Text1") + res.id +
					this.translate.instant("Message.UpdateActionSuccess.Text2"),
					//summary: "Action updated!",
					//detail: "Action with id " + res.id + " was successfully upated",
				});
				this.resetCurrentForm();
				this.displayNewAction = false;
			},
			(err: Error) => {
				this.systemLogService.handleError(err);
			}, () => {
				this.savingInProgress = false;

			}
		);
	}
}
