<p-dialog [closable]="true" [closeOnEscape]="true" (onHide)="showSettingsDialog = false" header="Change Settings"
    [resizable]="false" [draggable]="true" [modal]="false" [visible]="showSettingsDialog">
    <div *ngIf="selectedEntry" [ngSwitch]="selectedSettingsType">
        <div *ngSwitchCase="'Excel'">
            <input placeholder="{{'Enter Sheetname' | translate}}" pInputText [(ngModel)]="ExcelSheetName"
                (blur)="onUpdateSettings(false)" [ngModelOptions]="{standalone: true}">
        </div>
        <div *ngSwitchCase="'CSV'">
            <input placeholder="{{'Separator' |translate }}" pInputText [(ngModel)]="CsvSeparator"
                (blur)="onUpdateSettings(false)" [ngModelOptions]="{standalone: true}">
        </div>
    </div>
    <button style="float: right; margin-top: 5px;" pButton label="{{'Save' | translate}}" icon="fa fa-plus"
        (click)="onSaveSettings(selectedEntry)"></button>
</p-dialog>




<div>
    <p-table #dt [value]="fileDownloaderList" dataKey="Name" editMode="row" [reorderableColumns]="true">
        <ng-template pTemplate="caption">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                <!-- <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="{{'LogHistory.SearchForLog' | translate}}" />
                </span> -->
                <span class="p-input-icon-right">
                    <button style="float: right" pButton label="{{'Add' | translate}}" icon="fa fa-plus"
                        (click)="onAddEntry()"></button>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th pTooltip="Output file name">{{'Output Name' |translate }}</th>
                <th pTooltip="Export as format">{{'Format' |translate }}</th>
                <th style="width: 8rem"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-column let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="column" [pReorderableRow]="ri">
                <td>
                    <input pInputText [(ngModel)]="column.Name" [style]="{'width':'100%'}"
                        (blur)="onUpdateSettings(false)" [ngModelOptions]="{standalone: true}">
                </td>
                <td>
                    <p-dropdown appendTo="body" [options]="fileExportFormats" [(ngModel)]="column.Type"
                        (onChange)="onUpdateSettings(false)" optionLabel="Name" dataKey="ID" autoWidth="false"
                        [style]="{'width':'100%'}"></p-dropdown>
                </td>
                <td>
                    <div class="ui-table-buttongroup-right">
                        <button pButton pRipple type="button" icon="pi pi-cog" class="p-button-rounded p-button-text "
                            pTooltip="Change datasource information" pTooltip="Change settings"
                            (click)="updateSettings()" tooltipPosition="bottom"></button>
                        <button pButton pRipple type="button" class="p-button-rounded p-button-text ui-button-danger"
                            icon="pi pi-trash" pTooltip="Delete entry" (click)="onDeleteEntry(ri)"
                            tooltipPosition="bottom"></button>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="3">{{'NoEntryFound' | translate}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>