<div class="p-grid" style="width:100%; margin-top:10px">

    <!-- Auth Type -->

    <div class="p-col-12 ">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown [options]="currentConnectorView.authModes"
                    [(ngModel)]="currentConnectorView.connectorSettings.AuthMode" optionLabel="_type" editable="true">
                </p-dropdown>
                <label for="inputtext">{{'Auth Type' | translate}}</label>
            </span>
        </div>
    </div>

    <div [ngSwitch]="currentConnectorView.connectorSettings.AuthMode._type">
        <div *ngSwitchCase="'com.bion.connect.rest.NoAuth'">

        </div>
        <div *ngSwitchCase="'com.bion.connect.rest.BasicAuth'">

        </div>
        <div *ngSwitchCase="'com.bion.connect.rest.BearerAuth'">

            <div class="p-col-12 p-md-12">
                <div class="p-fluid">
                    <span class="p-float-label">
                        <input type="text" id="bearerTokenText" pInputText
                            [(ngModel)]="currentConnectorView.bearerAuth.Token">
                        <label for="bearerTokenText">{{'Bearer Token' | translate}}</label>
                    </span>
                </div>
            </div>

        </div>
    </div>

    <!-- Auth Type - No Auth -->

    <!-- Auth Type - Basic Auth -->

    <!-- Auth Type - Bearer Token -->

    <!-- Access -->

    <div class="p-col-12 p-md-12">
        <div class="p-fluid">
            <span class="p-float-label">
                <input type="text" id="Userinputtext" pInputText
                    [(ngModel)]="currentConnectorView.connectorSettings.URL">
                <label for="Userinputtext">{{'URL' | translate}}</label>
            </span>
        </div>
    </div>

    <!-- Query -->
    <div class="p-col-12 p-md-12">
        <p-checkbox></p-checkbox>
    </div>

    <div class="p-col-12 p-md-12">
        <div class="p-fluid">
            <span class="p-float-label">
                <input type="text" id="Userinputtext" pInputText
                    [(ngModel)]="currentConnectorView.connectorSettings.Query.Path">
                <label for="Userinputtext">{{'Path' | translate}}</label>
            </span>
        </div>
    </div>
    <div class="p-col-6 ">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown [options]="currentConnectorView.metaInfo.ReadModes"
                    [(ngModel)]="currentConnectorView.connectorSettings.Query.ReadMode" editable="true"></p-dropdown>
                <label for="inputtext">{{'Read Mode' | translate}}</label>
            </span>
        </div>
    </div>
    <div class="p-col-6 ">
        <div class="p-fluid">
            <span class="p-float-label">
                <p-dropdown [options]="currentConnectorView.metaInfo.NestedObjectsModes"
                    [(ngModel)]="currentConnectorView.connectorSettings.Query.NestedObjectsMode" editable="true">
                </p-dropdown>
                <label for="inputtext">{{'Nested Object Mode' | translate}}</label>
            </span>
        </div>
    </div>


    <div class="p-col-6 ">
        <button pButton (click)="on_preview($event)">{{'Preview' | translate}}</button>
    </div>

    <!-- META Preview-->


    <div class="p-col-12">
        <p-divider layout="horizontal" align="center">
            <span class="p-tag">{{'Select hierarchy' | translate}}</span>
        </p-divider>
    </div>
    <div class="p-col-12">
        <p-tree [value]="jsonHierarchy" id="hierarchy" selectionMode="single" [(selection)]="selectedJsonHierarchy"
            (onNodeSelect)="onNodeSelect($event)"></p-tree>
    </div>
</div>
