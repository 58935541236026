import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { RichDataSourceCake } from 'src/app/models/api/controllers/staging/RichDataSourceCake';
import { DataTypeInfo } from 'src/app/models/datasource.model';
import { SubSink } from 'subsink';
import { FieldInfoProvider, FieldInfoView, FieldViewable } from './field-info-provider';

/**
 * Zeigt Feldinformationen an.
 * @param T Objekttyp
 * @param F Felddtyp
 */
@Component({
  selector: 'app-generic-field-infos',
  templateUrl: './generic-field-infos.component.html',
  styleUrls: ['./generic-field-infos.component.scss']
})
export class GenericFieldInfosComponent<T, F> implements OnInit, OnDestroy {
  subs = new SubSink();
  @Input() currentView!: string;
  data: [T[], F[]] = [[], []]
  fieldInfos: FieldInfoView<F, T>[] = [];
  selectedInfo?: FieldInfoView<F, T>;
  dTypes: string[] = [];
  objectTreeNodes: TreeNode[] = []
  @Input() typeClass!: FieldInfoProvider<T, F, any>;

  constructor() { }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
  }

  // /**
  //  * Initialisiert den View mit der Typklasse und ihren Daten.
  //  * Diese Funktion ersetzt setObject.
  //  * @param typeClass Abstraktion für die Field Infos
  //  * @param data Daten, enthalten Objekte und Field Infos
  //  * @returns
  //  */
  // initView<A>(typeClass: FieldInfoProvider<T, F, A>, data: [T[], F[]]): Observable<boolean> {
  //   this.typeClass = typeClass;
  //   this.data = data;

  //   this.fieldInfos = typeClass.buildGuI(this.data);
  //   this.subs.sink = this.typeClass.subscribeToEvents().subscribe(selected_obj => {
  //     this.fieldInfos = this.typeClass.buildGuI(this.data, selected_obj);
  //   })

  //   return of(true);
  // }

  // //data2:A[] = [];
  // initView2<A>(typeClass: FieldInfoProvider<T, F, A>, data: A[]) {
  //   this.typeClass = typeClass;
  //   //this.data2 = data;

  //   this.fieldInfos = typeClass.buildGui2(data);

  //   return of(true);
  // }


  // initView3<A>(input:A, viewable:FieldViewable<T,F,A>) : void {

  // }



  /**
   * Initialisiert die Komponente.
   *
   * Diese Funktion ist obsolete, denn:
   * we will not call getData, the data is provided by the mother component
   * Nutze stattdessen: initView(...)
   * @param typeClass Feldabstraktion
   * @returns
   */
  setObject(typeClass: FieldInfoProvider<T, F, any>): Observable<boolean> {

    this.typeClass = typeClass;
    return this.typeClass.getData().pipe(map((res) => {
      this.fieldInfos = this.typeClass.buildGuI(res);
      //this.fieldInfosTreeNodes = this.typeClass.
      let objects = this.fieldInfos.map((view) => { return view.obj });

      let objectSet = new Set(objects);
      let objectUniqueArray = Array.from(objectSet.values());

      let objectTreeNodes = objectUniqueArray.map((obj) => {
        return this.typeClass.objectsToTreeNodes(obj, this.fieldInfos);
      }).filter((planNode) => {
        if (planNode.children)
          return planNode.children.length != 0;
        else
          return false;
      });
      this.objectTreeNodes = objectTreeNodes;

      let dTypes = res[res.length - 1].map((type: DataTypeInfo) => { return type });
      this.dTypes = dTypes.map((type: DataTypeInfo) => { return type.Name });

      console.log("this.dTypes", this.dTypes);
      console.log("full_res");

      this.subs.sink = this.typeClass.subscribeToEvents().subscribe((obj) => {
        this.fieldInfos = this.typeClass.buildGuI(res, obj);
        let objects = this.fieldInfos.map((view) => { return view.obj });

        let objectSet = new Set(objects);
        let objectUniqueArray = Array.from(objectSet.values());


        let objectTreeNodes = dTypes.map((type) => {
          return this.typeClass.objectsToTreeNodes(type, this.fieldInfos);
        })
          .filter((planNode) => {
            return planNode.children.length !== 0
          });
        this.objectTreeNodes = objectTreeNodes;
      });
      return true
    }));

  }



  onMenuClicked(info: FieldInfoView<F, T>) {
    this.selectedInfo = info;
  }
}
