import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IReportEmbedConfiguration, models, Page, Report, service, Embed } from 'powerbi-client';
import { PowerBIReportEmbedComponent } from 'powerbi-client-angular';
import { IHttpPostMessageResponse } from 'http-post-message';


import { PowerbiService } from '../powerbi.service';
import { SubSink } from 'subsink';
import { PowerBiEntry } from '../../../models/api/models/powerbi-report/PowerBiEntry';

import { map } from 'rxjs/operators';
import { eventTarget } from '@amcharts/amcharts4/.internal/core/utils/DOM';
import { Router } from '@angular/router';
import { PowerBiReportService } from 'src/app/services/power-bi-report.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PowerBiActionEvent, PowerBiDialogActionType } from 'src/app/models/api/models/powerbi-report/PowerBiActionEvent';

// Handles the embed config response for embedding
export interface ConfigResponse {
  Id: string;
  EmbedUrl: string;
  EmbedToken: {
    Token: string;
  };
}

@Component({
  selector: 'app-powerbi',
  templateUrl: './powerbi.component.html',
  styleUrls: ['./powerbi.component.scss']
})
export class PowerbiComponent implements OnInit {
  // Wrapper object to access report properties
  @ViewChild(PowerBIReportEmbedComponent) reportObj!: PowerBIReportEmbedComponent;
  subs = new SubSink;
  reports: PowerBiEntry[] = [];
  currentReport?: PowerBiEntry;
  activeIndex: number = 0;
  // // Track Report embedding status
  isEmbedded = false;
  // httpsUrl = 'https://app.powerbi.com';
  // reportUrl = this.httpsUrl + '/reportEmbed?reportId=b1f45f2b-a6c6-4bb2-b034-68ff48e9d974&autoAuth=true&ctid=be5e3f90-196b-47fc-a72f-8c6d453e6df7';

  // //reportUrl = 'https://aka.ms/CaptureViewsReportEmbedConfig';

  // // Overall status message of embedding
  // displayMessage = 'The report is bootstrapped. Click Embed Report button to set the access token.';

  // // CSS Class to be passed to the wrapper
  // reportClass = 'report-container';

  // // Flag which specify the type of embedding
  // phasedEmbeddingFlag = false;

  // // Pass the basic embed configurations to the wrapper to bootstrap the report on first load
  // // Values for properties like embedUrl, accessToken and settings will be set on click of button
  // reportConfig: IReportEmbedConfiguration = {
  //   type: 'report',
  //   embedUrl: undefined,
  //   tokenType: models.TokenType.Embed,
  //   accessToken: undefined,
  //   settings: {
  //     filterPaneEnabled: true,
  //     navContentPaneEnabled: true,
  //     layoutType: models.LayoutType.Custom,
  //     customLayout: {
  //         displayOption: models.DisplayOption.FitToWidth
  //     }
  // }
  // };

  // /**
  //  * Map of event handlers to be applied to the embedded report
  //  */
  // // Update event handlers for the report by redefining the map using this.eventHandlersMap
  // // Set event handler to null if event needs to be removed
  // // More events can be provided from here
  // // https://docs.microsoft.com/en-us/javascript/api/overview/powerbi/handle-events#report-events
  // eventHandlersMap = new Map ([
  //   ['loaded', () => {
  //       const report = this.reportObj.getReport();
  //       report.setComponentTitle('Embedded report');
  //       console.log('Report has loaded');
  //     },
  //   ],
  //   ['rendered', () => console.log('Report has rendered')],
  //   ['error', (event?: service.ICustomEvent<any>) => {
  //       if (event) {
  //         console.error(event.detail);
  //       }
  //     },
  //   ],
  //   ['visualClicked', () => console.log('visual clicked')],
  //   ['pageChanged', (event) => console.log(event)],
  // ]) as Map<string, (event?: service.ICustomEvent<any>, embeddedEntity?: Embed) => void | null>;

  constructor(public biService: PowerbiService, private biReportService: PowerBiReportService, private element: ElementRef<HTMLDivElement>, private router: Router, private sanitizer: DomSanitizer) { }
  ngOnInit(): void {



    this.subs.sink = this.biReportService.PowerBiActionEmitter.subscribe((event: PowerBiActionEvent) => {

      if (event.actionType === PowerBiDialogActionType.ReportSelected) {
        if (event.report) {
          const v = this.biReportService.getReports().pipe(map((reports) => {
            const entries = reports.map((info) => {
              const entry = new PowerBiEntry(info.Id, info.Title, info.Url);
              return entry
            })
            this.reports = entries;

            // let reportIndex = reports.map((report,index) => {
            //   if (report.id === event.report.id) {
            //     return index
            //   } else {
            //     return -1
            //   }
            // }).find((num) => num !== -1);

            let reportIndex = this.getReportIndex(entries, event.report);

            if (reportIndex) {
              this.currentReport = this.loadReport(entries[reportIndex])
              this.activeIndex = reportIndex;
            } else {
              console.log("Report could not be found!")!
              return
            }

          }))
        } else {
          console.log("Emitted Report is undefined -> deselected")
          this.currentReport = undefined;
        }
      }

    })
    // this.subs.sink = this.biService.getReports().subscribe((reports) => {
    //   this.reports = reports;

    //   console.log("currentReport", this.currentReport)
    //   if (this.currentReport) {
    //     // Report given, means i can get the active Index

    //     this.currentReport = this.loadReport(reports[this.activeIndex]);
    //   } else {
    //     // Reportnot given, get id from url route
    //     const url = this.router.url.split("/");
    //     const idString = url[url.length - 1];
    //     const idNumber = parseInt(idString);

    //     const report = reports.find((rep) => rep.id == idNumber);
    //     const i = this.getReportIndex(reports,report);
    //     this.activeIndex = i;
    //     this.currentReport = this.loadReport(reports[i]);

    //   }
    // });
    this.subs.sink = this.biReportService.getReports().subscribe((reports) => {

      const entries = reports.map((report) => {
        const entry = new PowerBiEntry(report.Id, report.Title, this.sanitizer.bypassSecurityTrustResourceUrl(report.Url));
        return entry

      });
      this.reports = entries;

      console.log("currentReport", this.currentReport)
      if (this.currentReport) {
        // Report given, means i can get the active Index

        this.currentReport = this.loadReport(entries[this.activeIndex]);
      } else {
        // Reportnot given, get id from url route
        const url = this.router.url.split("/");
        const idString = url[url.length - 1];
        const idNumber = parseInt(idString);

        const report = reports.find((rep) => rep.Id == idNumber);
        const entry = new PowerBiEntry(report.Id, report.Title, this.sanitizer.bypassSecurityTrustResourceUrl(report.Url));
        const i = this.getReportIndex(entries, entry);
        this.activeIndex = i;
        this.currentReport = this.loadReport(entries[i]);

      }
    })

  }

  // /**
  //  * Embeds report
  //  *
  //  * @returns Promise<void>
  //  */
  // async embedReport(): Promise<void> {
  //   let reportConfigResponse: ConfigResponse;

  //   // Get the embed config from the service and set the reportConfigResponse
  //   try {
  //     reportConfigResponse = await this.biService.getEmbedConfig(this.reportUrl).toPromise();
  //   } catch (error: any) {
  //     this.displayMessage = `Failed to fetch config for report. Status: ${error.status} ${error.statusText}`;
  //     console.error(this.displayMessage);
  //     console.log(error);
  //     return;
  //   }

  //   // Update the reportConfig to embed the PowerBI report
  //   this.reportConfig = {
  //     ...this.reportConfig,
  //     id: reportConfigResponse.Id,
  //     embedUrl: reportConfigResponse.EmbedUrl,
  //     accessToken: reportConfigResponse.EmbedToken.Token,
  //   };

  //   // Update embed status
  //   this.isEmbedded = true;

  //   // Update the display message
  //   this.displayMessage = 'Use the buttons above to interact with the report using Power BI Client APIs.';
  // }

  // /**
  //  * Change Visual type
  //  *
  //  * @returns Promise<void>
  //  */
  // async changeVisualType(): Promise<void> {
  //   // Get report from the wrapper component
  //   const report: Report = this.reportObj.getReport();

  //   if (!report) {
  //     this.displayMessage = 'Report not available.';
  //     console.log(this.displayMessage);
  //     return;
  //   }

  //   // Get all the pages of the report
  //   const pages: Page[] = await report.getPages();

  //   // Check if the pages are available
  //   if (pages.length === 0) {
  //     this.displayMessage = 'No pages found.';
  //     return;
  //   }

  //   // Get active page of the report
  //   const activePage: Page | undefined = pages.find((page) => page.isActive);

  //   if (!activePage) {
  //     this.displayMessage = 'No Active page found';
  //     return;
  //   }

  //   try {
  //     // Change the visual type using powerbi-report-authoring
  //     // For more information: https://docs.microsoft.com/en-us/javascript/api/overview/powerbi/report-authoring-overview
  //     // Get the visual
  //     const visual = await activePage.getVisualByName('VisualContainer6');

  //     //const response = await visual.changeType('lineChart');

  //     this.displayMessage = `The ${visual.type} was updated to lineChart.`;

  //     console.log(this.displayMessage);

  //     //return response;
  //   } catch (error) {
  //     if (error === 'PowerBIEntityNotFound') {
  //       console.log('No Visual found with that name');
  //     } else {
  //       console.log(error);
  //     }
  //   }
  // }

  // /**
  //  * Hide Filter Pane
  //  *
  //  * @returns Promise<IHttpPostMessageResponse<void> | undefined>
  //  */
  // async hideFilterPane() {
  //   // Get report from the wrapper component
  //   const report: Report = this.reportObj.getReport();

  //   if (!report) {
  //     this.displayMessage = 'Report not available.';
  //     console.log(this.displayMessage);
  //     return undefined;
  //   }

  //   // New settings to hide filter pane
  //   const settings = {
  //     panes: {
  //       filters: {
  //         expanded: false,
  //         visible: false,
  //       },
  //     },
  //   };

  //   try {
  //     const response = await report.updateSettings(settings);
  //     this.displayMessage = 'Filter pane is hidden.';
  //     console.log(this.displayMessage);

  //     return response;
  //   } catch (error) {
  //     console.error(error);
  //     return undefined;
  //   }
  // }

  // /**
  //  * Set data selected event
  //  *
  //  * @returns void
  //  */
  // setDataSelectedEvent(): void {
  //   // Adding dataSelected event in eventHandlersMap
  //   this.eventHandlersMap = new Map<string, (event?: service.ICustomEvent<any>, embeddedEntity?: Embed) => void | null>([
  //     ...this.eventHandlersMap,
  //     ['dataSelected', (event) => console.log(event)],
  //   ]);

  //   this.displayMessage = 'Data Selected event set successfully. Select data to see event in console.';
  // }


  loadReport(entry: PowerBiEntry): PowerBiEntry {
    return entry
  }
  getReportIndex(reports: PowerBiEntry[], report: PowerBiEntry): number {
    let reportIndex = reports.map((rep, index) => {
      if (rep.id === report.id) {
        return index
      } else {
        return -1
      }
    }).find((num) => num !== -1);

    return reportIndex
  }
  onTabClicked(evt: any) {
    console.log(evt.index);
    this.currentReport = this.reports.find((rep, index) => index == evt.index);
    this.activeIndex = evt.index;
  }

  onLastPage() {

    const currentIndex = this.reports.findIndex(rep => rep.id == this.currentReport?.id);

    // const currentIndex = this.reports.map((rep, index) => {
    //   if (rep.id == this.currentReport.id) {
    //     return index
    //   } else {
    //     return undefined
    //   }
    // }).find((ent) => ent !== undefined);

    const newId = currentIndex - 1 == 0 || currentIndex - 1 < 0 ? 0 : currentIndex - 1;
    const currentReport = this.reports.find((report, index) => newId == index);

    if (currentReport) {
      this.currentReport = this.loadReport(currentReport);
    } else {
      console.log("No report found")
    }
  }
  onNextPage() {

    const currentIndex = this.reports.findIndex(rep => rep.id == this.currentReport?.id);

    // const currentIndex = this.reports.map((rep, index) => {
    //   if (rep.id == this.currentReport.id) {
    //     return index
    //   } else {
    //     return undefined
    //   }
    // }).find((ent) => ent !== undefined);

    const newId = currentIndex + 1 > this.reports.length ? 0 : currentIndex + 1;
    const currentReport = this.reports.find((report, index) => newId == index);

    if (currentReport) {
      this.currentReport = this.loadReport(currentReport);
    } else {
      console.log("No report found")
    }
  }

  handleError(err:Error) : void {
    console.error(err);
  }

}
