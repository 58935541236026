import { MenuItem } from "primeng/api/menuitem";
import { Observable } from "rxjs";
import { ObjectProtocolRow } from "src/app/models/datasource.model";
import { DropDownModel } from "../general-search/drop-down-model";
import { FilterProvider } from "../general-search/filter-provider";

export interface DropDownEntry<T> {
    name: String;
    value: T;
}

export interface HeaderRow {
    field: string;
    header: string;
    width: string;
}

export interface RecordProvider<T, V, D, P extends ObjectProtocolRow> {
    getData(): Observable<D>;
    asRecord(arg: T, data: D): V;
    asHeader(): HeaderRow[];
    asActions(): MenuItem[];
    subscribeToEmitter(objects: T[]): void;
    emitObjectAction(action: string, object: V): void;
    filterObjects(params: [Map<string, DropDownModel>, Map<string, FilterProvider<any>>], objViewList: RecordView<T,P>[]): any;
    sortEntries(entries: V[]): V[];
}

export interface RecordView<T, P extends ObjectProtocolRow> {
    object: T;
    lastUpdated?: P;
    info: any;
}

export class RecordViewSorter {

    /**
     * Sortiert die elemente nach dem letzten Update. Element, die kein letztes Update haben werden nach unten
     * verschoben.
     * 
     * a < b => -1
     * 
     * a > b => 1
     * 
     * a = b => 0
     * 
     * @param a Erstes Element
     * @param b Zweites Element
     * @returns 
     */
    static sortLastUpdate<P extends ObjectProtocolRow>(a:RecordView<any,P>, b:RecordView<any,P>) : number {
        
        if(a.lastUpdated === undefined && a.lastUpdated === undefined) return 0;
        
        if(a.lastUpdated === undefined) return -1;

        if(b.lastUpdated === undefined) return 1;

        return new Date(b.lastUpdated.end).getTime() - new Date(a.lastUpdated.end).getTime();
    }
}