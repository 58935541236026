import { Component, OnInit } from '@angular/core';
import { IntegratedSourceModel } from 'src/app/models/api/models/IntegratedSourceModel';
import { IntegratedSourceService } from 'src/app/services/integrated-source.service';
import { AsAirbyteCatalog, AsBionCatalog, AsCatalog, GenCatalog, GenericInfo } from '../create-datasource-int/define-streams.model';
import { Id } from 'src/app/helper/id';
import { ConsoleLogger, LogLevel } from 'src/app/views/designer/components/workflow-graph/logger';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, Message } from 'primeng/api';
import { concatMap, map, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

export enum DialogMode {
  create,
  read,
  update
}

@Component({
  selector: 'app-define-streams-int-page',
  templateUrl: './define-streams-int-page.component.html',
  styleUrls: ['./define-streams-int-page.component.scss']
})
export class DefineStreamsIntPageComponent implements OnInit {
  //items: MenuItem[] = [{ label: '1. Konnektor auswählen', disabled: false, routerLink: '/SourceIntegrationCreateDatasource' }, { label: '2. Datenquelle einrichten', disabled: false, routerLink: '/SourceIntegrationCreateDatasource' }, { label: '3. Tabellen auswählen', disabled: false, routerLink: '/SourceIntegrationCreateDatasource' }];
  items: MenuItem[] = [{ label: this.translate.instant('1. Konnektor auswählen'), disabled: false }, { label: this.translate.instant('2. Datenquelle einrichten'), disabled: false, routerLink: '/SourceIntegrationCreateDatasource' }, { label: this.translate.instant('3. Tabellen auswählen'), disabled: false, routerLink: '/SourceIntegrationCreateDatasource' }];




  cat_tc?: AsCatalog<any> = undefined;  // Katalog-Typklasse für generischen Katalog & Kind-Komponente
  get_catalog_result?: IntegratedSourceModel.GetCatalogResult<unknown> = undefined;
  original_catalog?: any = undefined;   // Orignal Katalog von Bion oder Airbyte
  generic_catalog?: GenCatalog;         // Generischer Katalog für die Kind-Komponente
  new_generic_catalog?: IntegratedSourceModel.GenCatalog;  // BE Version
  source_key?: IntegratedSourceModel.DataSourceKey<number>;
  private logger = new ConsoleLogger(typeof (this), LogLevel.Info);
  isLoading: boolean = false;
  progressMode: string = "determinate";
  write_mode_infos: IntegratedSourceModel.GenCatalog.WriteModeInfo[] = [];
  data_file?: File = undefined;

  messages: Message[] = [];
  dialog_mode: DialogMode = DialogMode.create;  // Default: Define Streams

  constructor(private service_api: IntegratedSourceService, private router: Router, private route: ActivatedRoute, private translate: TranslateService) {

    // Fetch file for file connectors.
    // This works only in the constructor, it will not work on ngOnInit
    // https://dev.to/muhammadawaisshaikh/navigationextras-in-angular-31a4
    const extras = this.router.getCurrentNavigation().extras;
    console.log("Extras", extras);
    const state = extras.state;
    console.log("State", state);
    const file_data = state.data;
    console.log("File Data", file_data);
    this.data_file = file_data;
  }

  ngOnInit(): void {
    this.setBreadcrumb();
    console.warn("Still need to fetch the file from the url extras");

    this.source_key = this.getUrlContext();
    const source_key = this.source_key;

    const dialog_mode = this.route.queryParamMap.subscribe(m => {
      const dlg_mode = m.get("mode");
      console.log("Dialog Mode: " + dlg_mode);
    })

    //const get_cat_arg = new IntegratedSourceModel.GetCatalogArg(source_key);
    //const cat_obs = this.service_api.getCatalog(get_cat_arg);
    const cat_obs: Observable<IntegratedSourceModel.GetCatalogResult<unknown>> = <Observable<IntegratedSourceModel.GetCatalogResult<unknown>>>this.service_api.getCatalogFile(source_key, this.data_file, false);  // file optimized

    const write_mode_obs = this.service_api.getWriteModeInfos();

    const cat_and_write_modes = cat_obs.pipe(mergeMap(cat => write_mode_obs.pipe(map(wm => [cat, wm]))));

    this.setLoading(true);
    // this.service_api.getCatalog(get_cat_arg).subscribe(cat => {
    cat_and_write_modes.subscribe(cat_and_write => {

      const pair: [IntegratedSourceModel.GetCatalogResult<unknown>, IntegratedSourceModel.GenCatalog.WriteModeInfo[]] =
        <[IntegratedSourceModel.GetCatalogResult<unknown>, IntegratedSourceModel.GenCatalog.WriteModeInfo[]]>cat_and_write;

      const cat = pair[0];
      this.write_mode_infos = pair[1];

      switch (source_key.Origin) {
        case GenericInfo.instance().BionOrigin: this.cat_tc = new AsBionCatalog();
          break;
        case GenericInfo.instance().AirbyteOrigin: this.cat_tc = new AsAirbyteCatalog();
          break;
        default:
          this.cat_tc = undefined;
      }


      if (this.cat_tc === undefined) {
        this.handle_error(new Error("No Typeclass for catalog type " + source_key.Origin + " available!"));
      } else {
        // frontend generic catalog (obsolete, will be removed soon)
        this.get_catalog_result = cat;
        this.original_catalog = this.cat_tc.selectCatalog(cat.OriginalResult);
        this.generic_catalog = this.cat_tc.toGenCatalog(this.original_catalog);

        // backend generic catalog
        this.new_generic_catalog = this.get_catalog_result.Catalog;
      }
    },
      err => this.handle_error(err),
      () => this.setLoading(false)
    )
  }

  setBreadcrumb() {

    const currentRoute = this.router.url;
    console.log("currentRoute", currentRoute);
    //this.items = <MenuItem[]>[{ label: '1. Konnektor auswählen', disabled: false, routerLink: '/SourceIntegrationChooseConnector' }, { label: '2. Datenquelle einrichten', disabled: false, routerLink: currentRoute }, { label: '3. Tabellen auswählen', disabled: false, routerLink: currentRoute }];
    this.items = <MenuItem[]>[{ label: this.translate.instant('1. Konnektor auswählen'), disabled: false, routerLink: '/SourceIntegrationChooseConnector' }, { label: this.translate.instant('2. Datenquelle einrichten'), disabled: false, routerLink: currentRoute }, { label: this.translate.instant('3. Tabellen auswählen'), disabled: false, routerLink: currentRoute }];

  }


  setLoading(loading: boolean): void {
    this.isLoading = loading;

    // https://www.primefaces.org/primeng-v14-lts/progressbar

    if (this.isLoading) {
      this.progressMode = "indeterminate";
    } else {
      this.progressMode = "determinate";
    }
  }

  handle_error(e: Error) {
    this.messages.push({ severity: 'error', summary: 'Error', detail: e.message });
    this.logger.error("Error Handler", e);
    this.setLoading(false);
  }

  /**
   * Der Datasource Schlüssel aus der Route.
   */
  getUrlContext(): IntegratedSourceModel.DataSourceKey<number> {
    const arr = this.router.url.split('/');
    const id = parseInt(arr.last());
    const origin = arr.getRight(1);
    this.logger.debug("Data Source ID: " + id);
    this.logger.debug("Data Source Origin: " + origin);
    return new IntegratedSourceModel.DataSourceKey(id, origin);
  }

  /**
   * Katalogeinstellungen geändert
   * @param event Katalog
   */
  on_streams_defined(event: GenCatalog) {

    // TODO: introduce try catch
    const tc = Id.assertSet(this.cat_tc, new Error("Catalog typeclass not set"));
    const old_cat = Id.assertSet(this.original_catalog, new Error("Original Catalog not set"));
    const ds_key = Id.assertSet(this.source_key, new Error("Datasource key not set"));

    // todo: use deep clones (?)
    this.logger.warn("Check if we should use a deep clone to reduce side effects.");
    console.log("Old Catalog", old_cat);

    const new_cat = tc.patchCatalog(event, old_cat);
    console.log("New Catalog", new_cat);

    const new_stream_schema = tc.asStreamSchema(this.get_catalog_result.OriginalResult, new_cat);
    console.log("New Stream Schema", new_stream_schema);

    const api_arg = new IntegratedSourceModel.DefineStreamsArg(ds_key, new_stream_schema);
    this.service_api.defineStreams(api_arg).subscribe(
      define_stream_res => this.handle_define_streams_result(define_stream_res),
      err => this.handle_error(err),
      () => this.setLoading(false)
    )
  }

  /**
   * Katalogeinstellungen geändert
   * @param event Katalog
   */
  on_streams_changed(event: IntegratedSourceModel.GenCatalog) {
    console.log("onStreamChanged Event", event)
  }

  /**
   * Reagiert auf das Define Streams Ergebnis bei Pull Sources.
   * @param r Ergebnis
   */
  handle_define_streams_result(r: IntegratedSourceModel.DefineStreamsResult<unknown>) {
    this.logger.info("Streams successfully created");
    this.setLoading(false);
    this.gotoDataSourceView();
  }

  /**
   * Reagiert auf das Define Streams Ergebnis bei Push (File) Sources.
   * Die Funktion wird öfter aufgerufen, denn der File-Upload observable ist ein Multi-Shot.
   * @param result Ergebnis
   */
  handle_define_streams_and_file_upload_result(result: [IntegratedSourceModel.DefineStreamsResult<unknown>, HttpEvent<Object>]) {

    const event = result[1];

    if (event.type === HttpEventType.UploadProgress) {
      const progress = Math.round(100 * event.loaded / event.total);
      // Hier kannst du den Fortschritt verarbeiten
      console.log("Progress", progress);
    } else if (event.type === HttpEventType.Response) {
      // Hier kannst du die Serverantwort verarbeiten
      console.log("Response", event);

      this.logger.info("Streams successfully created and file uploaded!");
      this.setLoading(false);
      this.gotoDataSourceView();
    }
  }

  /**
   * Läd die aktuelle Datei nach der Stream-Anlage hoch!
   */
  sync_inital_file(file: File, source_key: IntegratedSourceModel.DataSourceKey<number>) {

    this.service_api.syncFile(file, source_key)
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          const progress = Math.round(100 * event.loaded / event.total);
          // Hier kannst du den Fortschritt verarbeiten
          console.log("Progress", progress);
        } else if (event.type === HttpEventType.Response) {
          // Hier kannst du die Serverantwort verarbeiten
          console.log("Response", event);
        }
      },
        err => this.handle_error(err),
        () => this.setLoading(false));

  }

  /**
   * Make sure the catalog has at least one selected stream.
   * @param cat Catalog
   */
  assertCatalog(cat: IntegratedSourceModel.GenCatalog) {

    const streams_selected = cat.streams.filter(s => s.config.selected);

    if (streams_selected.isEmpty()) {
      throw new Error("Select at least one stream");
    }
  }

  /**
  * Katalogeinstellungen geändert - Variante für den neuen BE Catalog
  * @param event Katalog
  */
  on_streams_defined_gen_backend(event: IntegratedSourceModel.GenCatalog) {

    this.assertCatalog(event);
    console.log("Event", event);

    // TODO: introduce try catch
    const old_cat = Id.assertSet(this.original_catalog, new Error("Original Catalog not set"));
    const original_result = Id.assertSet(this.get_catalog_result.OriginalResult, new Error("Original Result not set"))
    const ds_key = Id.assertSet(this.source_key, new Error("Datasource key not set"));

    // todo: use deep clones (?)
    console.log("Old Catalog", old_cat);
    console.log("Original Result", original_result);

    // in dieser Variante geben wir den alten Katalog zusammen mit dem neuen generischen Katalog mit.
    // Das Patchen übernimmt jetzt das Backend

    console.log("WARNING - Incomplete - We need to provide the generic catalog so the backend can patch it!");

    const stop_before_call = false;

    if (stop_before_call) {
      this.handle_error(new Error("Version not implemented yet."))
      return;
    }

    // hier spielt die Musik

    const api_arg = new IntegratedSourceModel.DefineStreamsArg(ds_key, original_result, event);
    const define_streams_obs = this.service_api.defineStreams(api_arg);


    if (this.data_file) {
      const sync_file_obs = this.service_api.syncFile(this.data_file, ds_key);
      const both = define_streams_obs.pipe(concatMap(stream_result => {
        console.log("Streams successfully created!");
        return sync_file_obs.pipe(map(file_result => {
          const pair: [IntegratedSourceModel.DefineStreamsResult<unknown>, HttpEvent<Object>] = [stream_result, file_result];
          return pair;
        }))
      }))

      both.subscribe(
        both_res => this.handle_define_streams_and_file_upload_result(both_res),
        err => this.handle_error(err),
        () => this.setLoading(false)
      )

    } else {

      define_streams_obs.subscribe(
        define_stream_res => this.handle_define_streams_result(define_stream_res),
        err => this.handle_error(err),
        () => this.setLoading(false)
      )

    }
  }



  // 1. define_streams_click
  // 2. on_streams_defined_gen_backend
  // 3. defineStreams
  // 3.5
  // 4. handle_define_streams_result


  /**
   * Triggert die Streams anlage
   * @param event
   */
  define_streams_click(event: any) {
    console.log("Define Streams Click", event);

    this.setLoading(true);
    this.messages = [];

    const use_backend_catalog_mode = true;
    console.log("Use Backend Catalog Mode: " + use_backend_catalog_mode);

    try {
      if (use_backend_catalog_mode) {
        this.on_streams_defined_gen_backend(this.new_generic_catalog);
      } else {
        this.on_streams_defined(this.generic_catalog);
      }
    } catch (e) {
      this.handle_error(e);
    } finally {
      this.setLoading(false);
    }
  }

  gotoDataSourceView() {
    this.router.navigate(['/', 'SourceIntegrationSources']);
  }
}
