import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { DataStore } from "src/app/models/datastore.model";
import { DestinationActionEvent, DestinationDialogActionType } from "src/app/models/dialog-actions.model";
import { CubesService } from "src/app/services/cubes.service";
import { SystemMessageLogService } from "src/app/services/system-message-log.service";
import { SubSink } from "subsink";

@Component({
	selector: "app-delete-datastore-dialog",
	templateUrl: "./delete-datastore-dialog.component.html",
	styleUrls: ["./delete-datastore-dialog.component.scss"],
	providers: [MessageService],
})
export class DeleteDatastoreDialogComponent implements OnInit {
	subs = new SubSink();
	savingInProgress: boolean = false;
	displayDeleteDataStore: boolean = false;
	selectedDataStore?: DataStore;

	constructor(
		private cubeService: CubesService,
		private messageService: MessageService,
		private errorService: SystemMessageLogService,
		private translate: TranslateService
	) {}

	ngOnInit(): void {
		this.subs.sink = this.cubeService.destinationDialogActionSendEmitter.subscribe(
			(res: DestinationActionEvent) => {
				if(res.actionType === DestinationDialogActionType.deleteDestination) {
					this.displayDeleteDataStore = res.display;
					this.selectedDataStore = res.dest;
				}

			}, (err: Error) => {
				this.errorService.handleError(err)
			}
		);
	}
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	deleteDataStore(event: DataStore) {
		this.savingInProgress = true;
		this.cubeService.deleteDataStoreObject(event.id).then(
			(res) => {
				this.cubeService.dataStoresChangedEmitter.emit(this.selectedDataStore);
				//this.cubeService.deletedDataStoreEmitter.emit(event);
				this.savingInProgress = false;

				this.messageService.add({
					severity: "success",
					summary: this.translate.instant("Message.DeleteDataStoreSuccess.Title"),
					detail: this.translate.instant("Message.DeleteDataStoreSuccess.Text1") + event.id +
					this.translate.instant("Message.DeleteDataStoreSuccess.Text2"),
				});
				this.displayDeleteDataStore = false;
			},
			(err: Error) => {
				this.savingInProgress = false;
				this.errorService.handleError(err);
			}
		);
	}
}
