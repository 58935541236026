import { Component, OnInit } from "@angular/core";
import { WorkflowNodeSettings } from "src/app/models/api/com/bion/etl/Workflow";
import { GuiDropDown } from "src/app/models/helperClass.model";
import {
	ColumnAggregatorInfo,
	ColumnAggregatorSettings,
} from "src/app/models/nodeSettings.model";
// import { WorkflowNodeSettings } from "src/app/models/workflow.model";
import { DesignerService } from "src/app/services/designer.service";
import { WorkflowsService } from "src/app/services/workflows.service";
import { NodeConfigComponentBase } from "../node-config-component-base";

export class FlatFieldInfo {
	name: string;
	datatype: string;
	datalength: number;

    constructor(name: string, datatype: string, datalength: number) {
        this.name = name;
        this.datatype = datatype;
        this.datalength = datalength
    }
}

export class InfoItem {
	columnName: string;
	dataType: string;

    constructor(columnName: string, dataType: string) {
        this.columnName = columnName;
        this.dataType = dataType;
    }
}

export class InfoView {
	columnName: string;
	isMissing?: boolean;

    constructor(columnName: string, isMissing?: boolean) {
        this.columnName = columnName;
        this.isMissing = isMissing
    }
}

@Component({
	selector: "app-column-aggregator-node",
	templateUrl: "./column-aggregator-node.component.html",
	styleUrls: ["./column-aggregator-node.component.css"],
})
export class ColumnAggregatorNodeComponent
	extends NodeConfigComponentBase<ColumnAggregatorSettings>
	implements OnInit
{
	settingsToView(settings: WorkflowNodeSettings): void {
		// -- Get currentConfig
		let currentConfig = <ColumnAggregatorSettings>settings;

		// -- Get current Input MetaInfos
		const meta_infos = this.getCurrentWorkflowNode().Properties.MetaInfo.get(
			this.InputPort
		);

		// -- Prepare gui element (dropdown list) if meta_infos exists
		if (meta_infos != undefined && meta_infos.length > 0) {
			// -- Get fieldInfos from input port
			let fieldInfoList = meta_infos[0].FieldsInfo;

			// -- Create new InfoView Array
			let infoViewsList: InfoView[] = [];

			// -- Append existing metaInfos to full list
			fieldInfoList.map((info) => {
				let view = new InfoView(info.Name, false);
				// view.columnName = info.Name;
				// view.isMissing = false;

				infoViewsList.push(view);
			});

			let currentConfigInfos = currentConfig.ColumnAggregatorInfos;

			// -- Check if selection exists, if not assign per default
			if (currentConfigInfos === undefined || currentConfigInfos.length === 0) {
				console.log("No Selection exists, set default tables");
				this.leftTableList = infoViewsList;
				this.rightTableList = [];
				this.selectedAggregationMethod = this.aggregationMethods[0];
				this.aggregationDelimiter = "";
			} else {
				//-- if selection exists, prepare gui infos
				// -- Get currently included columns
				let currentIncludedColumns = currentConfigInfos[0].RightTable;

				// -- Prepare included List
				let includedViewList = currentIncludedColumns.map((inCol) => {

					let viewFound = infoViewsList.find((element) => {
						return element.columnName === inCol;
					});

					let view = new InfoView(inCol,!viewFound);

					return view;
				});
				this.rightTableList = includedViewList;
				console.log(this.rightTableList);

				// -- Prepare excluded List
				let excludedViewListFiltered = infoViewsList.filter((value) => {
					return !currentIncludedColumns.includes(value.columnName);
				});
				this.leftTableList = excludedViewListFiltered;

				// -- Prepare Input fields

				this.selectedAggregationMethod = this.aggregationMethods.find((i) => {
					return i.value == currentConfig.AggregationMethod;
				});
				this.aggregationDelimiter = currentConfig.AggregationDelimiter;
				this.newColName = currentConfig.NewColumnName;
				this.dropOldCol = currentConfig.DropOldColumn;
			}
		}
	}
	viewToSettings(): ColumnAggregatorSettings {
		let newSettings = <ColumnAggregatorSettings>{
			...this.getCurrentWorkflowNode().Properties.Configuration,
		};

		let newConfig = new ColumnAggregatorInfo();
		newConfig.LeftTable = this.leftTableList.map((col) => {
			return col.columnName;
		});
		newConfig.RightTable = this.rightTableList.map((col) => {
			return col.columnName;
		});

		newSettings.ColumnAggregatorInfos = [newConfig];
		console.log("Final columnAggregator Settings: ", newSettings);

		newSettings.AggregationDelimiter = this.aggregationDelimiter;
		newSettings.AggregationMethod = this.selectedAggregationMethod ? this.selectedAggregationMethod.value : newSettings.AggregationMethod;
		newSettings.NewColumnName = this.newColName;
		newSettings.DropOldColumn = this.dropOldCol;

		return newSettings;
	}
	onSettingsChanged(settings: ColumnAggregatorSettings): void {
		throw new Error("Method not implemented.");
	}

	readonly InputPort = "Input";

	aggregationDelimiter: string = "";
	aggregationMethods: GuiDropDown[] = [];
	selectedAggregationMethod?: GuiDropDown;
	newColName: string = "";
	dropOldCol: boolean = false;

	fullColumnNameList: string[] = [];
	leftTableList: InfoView[] = [];
	rightTableList: InfoView[] = [];
	draggedColumn?: InfoView;

	constructor(
		protected workflowService: WorkflowsService,
		protected designerService: DesignerService
	) {
		super(workflowService, designerService);
		this.aggregationMethods = [
			{ name: "concat", value: "concat" },
			{ name: "count", value: "count" },
		];
	}
	ngOnInit(): void {
		super.ngOnInit();
	}
    dragStart(product: InfoView) {

		console.log(product);
        this.draggedColumn = product;
    }

    drop(event?:DragEvent) {
        if (this.draggedColumn) {
            let draggedProductIndex = this.findIndex(this.draggedColumn);
            this.rightTableList = [...this.rightTableList, this.draggedColumn];
            this.leftTableList = this.leftTableList.filter((val,i) => i!=draggedProductIndex);
            this.draggedColumn = undefined;

			console.log(this.leftTableList,this.rightTableList);
			this.onUpdateSettings(false);
        }
    }

    dragEnd(event:DragEvent) {
        this.draggedColumn = undefined;
    }
	findIndex(product: InfoView) {
        let index = -1;
        for(let i = 0; i < this.leftTableList.length; i++) {
            if (product.columnName === this.leftTableList[i].columnName) {
                index = i;
                break;
            }
        }
        return index;
    }
    deleteEntry(row: InfoView) {
        this.rightTableList = this.rightTableList.filter((infoview) => { return infoview.columnName !== row.columnName});
        this.leftTableList = [...this.leftTableList, row];

        this.onUpdateSettings(false);
    }

}
