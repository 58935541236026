import { NodeGuiInfo, SchedulerNodeGuiInfo } from "src/app/models/scheduler-designer.model";
import { SchedulerNodeInfo } from "src/app/services/dummy-backend.service";

export class ScheduleNodePlugInInfos {

    static getNodeGuiInfo(): NodeGuiInfo[] {
        return []
    };
	static getSchedulerNodeGuiInfo(
		workflowNodeInfo: SchedulerNodeInfo[],
		nodeGuiInfo: NodeGuiInfo[]
	): SchedulerNodeGuiInfo[] {
        return []
    }


}