import { Component, Input, OnInit } from '@angular/core';
import { AirbyteModel } from 'src/app/models/api/models/AirbyteModel';
import { AirbyteStagingService, MetaResponse } from 'src/app/services/airbyte-staging.service';
import sourceInfoTest from "./source_info_test.json"
import { Router } from '@angular/router';
import { Connection, Sources, StreamInfo } from 'src/app/models/airbyte-api-structures';
import { concatMap } from 'rxjs/operators';
import { throwError } from 'rxjs';

/**
 * Dummy Struture
 */
export interface AirbyteStream {
  code: number;
  name: string;
  category: string;
  quantity: number;
}

@Component({
  selector: 'app-create-ab-connection',
  templateUrl: './create-ab-connection.component.html',
  styleUrls: ['./create-ab-connection.component.scss']
})
export class CreateAbConnectionComponent implements OnInit {

  constructor(private router: Router, private ab_service: AirbyteStagingService) { }

  //@Input() source!: AirbyteModel.SourceInfo;    // disabled due to testing, reactive later
  source: AirbyteModel.SourceInfo<Sources.Source> = sourceInfoTest;

  //@Input() catalog!: any;  // TODO: add catalog object

  connection_name: string = "";
  streams: AirbyteStream[] = [];
  selectedStreams: AirbyteStream[] = [];

  realStreams: StreamInfo[] = [];
  realSelectedStreams: StreamInfo[] = [];

  meta_response?: MetaResponse = undefined;

  isLoading: boolean = false;
  progressMode: string = "determinate";

  setLoading(loading: boolean): void {
    this.isLoading = loading;

    // https://www.primefaces.org/primeng-v14-lts/progressbar

    if (this.isLoading) {
      this.progressMode = "indeterminate";
    } else {
      this.progressMode = "determinate";
    }
  }


  ngOnInit(): void {

    // Source wird injected bzw. ist ausgewählt

    this.setLoading(true);

    const arr = this.router.url.split('/');
    const id = parseInt(arr[arr.length - 1]);

    const obs = this.ab_service.sourceInfos(id).pipe(concatMap(sources => {

      console.log("Sources", sources)

      if (sources.length == 0) {
        return throwError(new Error("Source Info with id " + id + " does not exist!"))
      } else {
        const source_id = sources[0].id;
        this.source = sources[0];
        const meta_arg = new AirbyteModel.ExtractMetaFromSourceArg(source_id);
        console.log("Extract Meta Arg", meta_arg);
        return this.ab_service.extractMeta(meta_arg);
      }

    }))

    // Real Schema Config
    obs.subscribe(meta_response => {

      // write catalog into components
      console.log("Extract Meta Successful!", meta_response);
      this.meta_response = meta_response;

      this.realStreams = meta_response.catalog.streams;
      this.realSelectedStreams = meta_response.catalog.streams;

    },
      err => this.handleError(err, "Extract Meta ERROR"),
      () => this.setLoading(false)
    );
  }

  onCreate() {

    // Source ID
    // Connection Name
    // Streams (optinal)

    //const name_to_use = "PostgreHardConnection";
    const name_to_use = this.connection_name;

    try {
      if (name_to_use.length == 0) {
        throw new Error("Please enter a data source connection name");
      }

      // Fetch Schema, use updated streams and put them back
      let extract_schema: MetaResponse = undefined;
      if (this.meta_response) {
        const patched_cat = this.patchCatalog(this.meta_response, this.realSelectedStreams);
        extract_schema = patched_cat;
      }

      const arg = new AirbyteModel.CreateConnectionArg(this.source.id, name_to_use, extract_schema);

      console.log("Create Data Source Argument", arg);

      this.setLoading(true);

      this.ab_service.createConnection(arg).subscribe(
        result => this.handleResult(result),
        e => this.handleError(e),
        () => this.setLoading(false)
      )

    } catch (e) {
      this.handleError(e, "Creation Failed");
    }
  }

  /**
   * Im Katalog muss bei abgewählten Streams das flag 'selected' auf 'false' gesetzt werden.
   * PrimeNGs Table-Selection macht die Auswahl aber auf Element-Ebene im Array, weswegen wir diese
   * HIlfsunktion haben.
   * ACHTUNG, die hat Seiteneffekte: Der Eingabekatalog wird aktualisiert, die Rückgabe ist dasselbe Objekt!
   * @param cat Source Schema Catalog
   * @param selected_streams Ausgewählte Streams aus PrimeNG Table Model.
   */
  patchCatalog(meta_response: MetaResponse, selected_streams: StreamInfo[]): MetaResponse {

    const cat = meta_response.catalog;

    const streams = new Set();
    for (let ss of selected_streams) {
      streams.add(ss.stream.name);
    }

    for (let s of cat.streams) {
      s.config.selected = streams.has(s.stream.name);
    }

    return meta_response;
  }

  handleResult(result: AirbyteModel.CreateConnectionResult<Connection,Sources.Source,any>) {
    console.log("Creation successful", result);

    //const id = result[2].id.toString();
    const id = result.ConnectionInfo.id;
    this.router.navigate(['/', 'testAirbyteViewConnection', id]);
  }

  handleError(e: Error, info?: string) {
    if (info) {
      console.log(info);
    }
    console.log(e);
  }
}